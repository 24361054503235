const backgroundBase = () => {
  return (
    <g>
      <rect x="0.5" y="0.5" width="999" height="1413" />
      <path d="M999,1V1413H1V1H999m1-1H0V1414H1000V0Z" />
    </g>
  );
};

export default backgroundBase;
