import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import RecordRTC, { CanvasRecorder, invokeSaveAsDialog } from "recordrtc";
import "../Styles/Main.css";
import { useStore } from "./ArtworkPropertyStore";
import FrameCounter from "./Framect";
import MainContainer from "./MainContainer";
import MainInput from "./MainInput";
import { getDateTitle } from "./Utilities";

export type FramectElement = {
  setPlusminus?: (value: 1 | -1) => void;
};

const Main = () => {
  // console.log("main component rendered");
  const [seed, setSeed] = useState("10105835101010101010");
  useStore.setState({ seed: seed });
  useStore.setState({ isAnimating: false });
  const [isRecording, setIsRecording] = useState(false);

  const [checksum, setChecksum] = useState(0);
  const frameCtRef = useRef<FramectElement>({
    setPlusminus: undefined,
  });

  const handleSubmit = (typedSeed: string) => {
    setSeed(typedSeed);
    setChecksum(checksum + 1);
    console.log("seed submitted: ", typedSeed);
    useStore.setState({ seed: typedSeed });
    useStore.setState({ isFocusGridDrawn: false });
  };

  // usememo ?

  const generateSeed = () => {
    const generatedSeed = (Math.random() * 1000000000000000000).toString();
    setSeed(generatedSeed);
    setChecksum(checksum + 1);
    console.log("seed generated: ", generatedSeed);
    useStore.setState({ seed: generatedSeed });
    useStore.setState({ isFocusGridDrawn: false });
  };

  let artworkCont = document.getElementById("artworkContainer");

  const screenshot = () => {
    // @ts-ignore: Object is possibly 'null'.
    html2canvas(artworkCont).then(function (canvas) {
      var img = canvas.toDataURL("image/png");
      var link = document.createElement("a");
      link.download = "electronic-sun-canvas.png";
      link.href = img;
      link.click();
    });
  };

  var recordedChunks: any[] = [];

  const recordingDuration = 10000;
  const record = () => {
    var canvas = document.querySelector("#artworkCanvas") as HTMLCanvasElement;
    var stream = canvas.captureStream(25);
    var bps = 5000000;

    var options = { mimeType: "video/webm; codecs=vp9", bitsPerSecond: bps };

    let mediaRecorder = new MediaRecorder(stream, options);

    frameCtRef?.current?.setPlusminus?.(1);

    mediaRecorder.start();
    mediaRecorder.ondataavailable = handleDataAvailable;

    setIsRecording(true);

    setTimeout((event) => {
      frameCtRef?.current?.setPlusminus?.(-1);
    }, recordingDuration / 2);

    setTimeout((event) => {
      console.log("stopping");
      mediaRecorder.stop();
      setIsRecording(false);
    }, recordingDuration);
  };

  function handleDataAvailable(event: BlobEvent) {
    // console.log("data-available");
    if (event.data.size > 0) {
      recordedChunks.push(event.data);
      // console.log(recordedChunks);
      download();
    } else {
      // ...
    }
  }

  function download() {
    var blob = new Blob(recordedChunks, {
      type: "video/webm",
    });
    var url = URL.createObjectURL(blob);
    var a = document.createElement("a");
    document.body.appendChild(a);
    // a.style = "display: none";
    a.href = url;

    const dateTitle = getDateTitle();
    a.download = `electronicsun-${dateTitle}`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const recordWithRTC = async () => {
    var canvas = document.querySelector("#artworkCanvas") as HTMLCanvasElement;

    var recorder = new RecordRTC(canvas, {
      disableLogs: true,
      type: "canvas",
      frameInterval: 50,
    });
    recorder.startRecording();

    setIsRecording(true);

    setTimeout((event) => {
      frameCtRef?.current?.setPlusminus?.(-1);
    }, recordingDuration / 2);

    const sleep = (m: number) => new Promise((r) => setTimeout(r, m));
    await sleep(recordingDuration);

    recorder.stopRecording(function () {
      let blob = recorder.getBlob();
      invokeSaveAsDialog(blob);
    });

    setIsRecording(false);
    frameCtRef?.current?.setPlusminus?.(1);
  };

  useEffect(() => {
    artworkCont = document.getElementById("artworkContainer");
  }, [checksum]);

  return (
    <div className="main">
      <div className="padding"></div>
      <MainContainer seed={seed} elemkey={checksum} />
      <div className="mainContainer2">
        electronic sun
        <MainInput
          generateSeed={generateSeed}
          handleSubmit={handleSubmit}
          setSeed={setSeed}
          screenshot={screenshot}
          record={record}
          isRecording={isRecording}
          recordWithRTC={recordWithRTC}
        />
        <FrameCounter framect={frameCtRef} />
      </div>
      <div className="padding"></div>
    </div>
  );
};

export default Main;
