import * as shape2d from "./Resources/Shapes2D";

type animatedShapeProps = {
  animationLayer: string;
  animationSeed: number;
  width: number;
  size: number;
  x: number;
  y: number;
  rotation: number;
  frameCount: number;
  intensity?: number;
};

type generateElementShapeProps = {
  ctx: CanvasRenderingContext2D;
  elementStyle: string;
  shapeRotation: number;
  initialPosX: number;
  initialPosY: number;
  width: number;
  height: number;
  shapeSize: number;
  animation?: string;
  frameCount?: number;
  intensity?: number;
  depth?: number;
  animationSeed?: number[];
};

export const generateElementShape = (props: generateElementShapeProps) => {
  let numberOfPoints = 3;
  let shapeDrawn = shape2d.shapePolygon;

  switch (props.elementStyle) {
    case "triangles": {
      numberOfPoints = 3;
      shapeDrawn = shape2d.shapePolygon;
      break;
    }
    case "triangles-strokes": {
      numberOfPoints = 3;
      shapeDrawn = shape2d.shapePolygon;
      break;
    }
    case "squares": {
      numberOfPoints = 4;
      shapeDrawn = shape2d.shapePolygon;
      break;
    }
    case "pentagons": {
      numberOfPoints = 5;
      shapeDrawn = shape2d.shapePolygon;
      break;
    }
    case "stars-four": {
      numberOfPoints = 4;
      shapeDrawn = shape2d.shapeStar;
      break;
    }
  }

  shapeDrawn(
    props.ctx,
    numberOfPoints,
    props.shapeRotation,
    props.initialPosX,
    props.initialPosY,
    props.width,
    props.height,
    props.shapeSize,
    props.animation,
    props.frameCount,
    props.intensity,
    props.depth,
    props.animationSeed
  );
};

export const processAnimatedShapeProps = (props: animatedShapeProps) => {
  let shapeRotation = props.rotation;
  let shapeSize = props.size;
  let shapeX = props.x;
  let shapeY = props.y;
  let shapeIntensity = props.intensity ? props.intensity * 10 : 0.5;

  let glitchTrigger = 0;

  let rotationAnim = 0;
  let sizeAnim = 0;
  let growShrink = 1;

  switch (props.animationLayer) {
    case "rotateClockwise": {
      const frameCount = props.frameCount * shapeIntensity;
      rotationAnim += (frameCount % 360) * 1;
      shapeRotation = props.rotation + rotationAnim;
      break;
    }

    case "rotateCounterClockwise": {
      const frameCount = props.frameCount * shapeIntensity;
      rotationAnim -= (frameCount % 360) * 1;
      shapeRotation = props.rotation + rotationAnim;
      break;
    }

    case "growShrink": {
      const frameCount = props.frameCount * shapeIntensity;

      sizeAnim += Math.sin(frameCount * 0.2) * 0.1 * props.size * growShrink;
      shapeSize = props.size + sizeAnim;

      break;
    }

    case "glitch": {
      const frameCount = props.frameCount * shapeIntensity;
      glitchTrigger = frameCount % 100;

      if (
        Math.abs(glitchTrigger - props.animationSeed) < 3 ||
        Math.abs(glitchTrigger - props.animationSeed) > 90
      ) {
        if (props.animationSeed % 2 === 0) {
          shapeX = props.x - (props.width / props.animationSeed) * 2;
        } else {
          shapeX = props.x + (props.width / props.animationSeed) * 2;
        }
      } else {
        shapeX = props.x;
      }
      break;
    }
  }

  return {
    shapeRotation: shapeRotation,
    shapeX: shapeX,
    shapeY: shapeY,
    size: shapeSize,
  };
};
