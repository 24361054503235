import "../Styles/Artwork.css";
import * as utils from "./Utilities";
import backgroundBase from "../Assets/backgroundBase";
import { useStore } from "./ArtworkPropertyStore";
import LayerBackgroundAccentCanvas from "./LayerBackgroundAccentCanvas";
import { backgroundDrawingsArray } from "../Assets/backgrounds";
import { useEffect, useRef, useState } from "react";

interface ArtworkBackgroundProps {
  id?: string;
  element?: string;
  // imagetoDraw: any;
}

const LayerBackground = (directProps: ArtworkBackgroundProps) => {
  const props = utils.getProps();

  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const canvasRef = useRef(null);
  const [isImage1Loaded, setIsImage1Loaded] = useState(false);
  const [isImage2Loaded, setIsImage2Loaded] = useState(false);

  const backgroundBaseColor = useStore((state) => state.backgroundBaseColor);
  const backgroundLayer1Color = useStore((state) => state.backgroundColor);
  const backgroundLayer2Color = useStore(
    (state) => state.backgroundDrawingColor
  );
  const bgBaseColorHex = utils.colorToHex(backgroundBaseColor);

  let svgElement1: SVGElement | null;
  let svgElement2: SVGElement | null;
  let image1ForCanvas = new Image();
  let image2ForCanvas = new Image();

  const backgroundCode = useStore((state) => state.backgroundCode);
  const DrawingBackground = utils.selectBackgroundBase(
    backgroundCode.toString()
  );

  const backgroundDrawing = utils.selectFromArray(
    props.position2.toString(),
    backgroundDrawingsArray
  );

  svgElement1 = document.querySelector("#backgroundLayer1");
  svgElement2 = document.querySelector("#backgroundLayer2");

  let svgString1 = "";
  let svgString2 = "";

  const serializer = new XMLSerializer();
  const b64Start = "data:image/svg+xml;base64,";

  // ------------------------------------------------------

  const imgStyleArray: {
    left: number;
    top: number;
    width: number;
    height: number;
  }[] = [];
  const imgSourceArray: HTMLImageElement[] = [];

  let numberOfImages = props.position1;
  if (numberOfImages < 2 || numberOfImages > 6) {
    numberOfImages = 4;
  }

  for (let i = 1; i < numberOfImages; i++) {
    const leftvalue = parseInt(
      utils.generateValue(2, props.position2 * i, "layer background")
    );
    const imgDistLeft = utils.clamp(leftvalue - 45, -25, 20);

    const topvalue = parseInt(
      utils.generateValue(2, props.position1 * i, "layer background")
    );
    const imgDistTop = utils.clamp(topvalue - 5, -5, 60);

    const heightvalue = Math.abs(
      parseInt(utils.generateValue(2, props.position1 * i, "layer background"))
    );
    const imgMaxHeight = utils.clamp(heightvalue, 10, 25);

    const widthvalue = Math.abs(
      parseInt(utils.generateValue(2, props.position2 * i, "layer background"))
    );
    const imgWidth = utils.clamp(widthvalue, 80, 110);

    imgStyleArray.push({
      left: imgDistLeft,
      top: imgDistTop,
      width: imgWidth,
      height: imgMaxHeight,
    });

    // ------------------------------------------------------------
    let img = document.createElement("img");
    img.src = utils.selectBackgroundImage(
      Math.abs(props.position1 * Math.sin(i)).toString()
    );
    imgSourceArray.push(img);
  }

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    // ctx.globalCompositeOperation = "source-over";
    ctx.fillStyle = bgBaseColorHex;
    ctx.fillRect(0, 0, props.width, props.height);

    if (isImage1Loaded && isImage2Loaded) {
      ctx.drawImage(image1ForCanvas, 0, 0);

      for (let i = 0; i < numberOfImages; i++) {
        if (imgSourceArray[i] !== undefined) {
          ctx.drawImage(
            imgSourceArray[i],
            0,
            0,
            imgStyleArray[i].width * 20,
            imgStyleArray[i].height * 20,
            (imgStyleArray[i].left * props.width) / 100,
            (imgStyleArray[i].top * props.height) / 100,
            (imgStyleArray[i].width * props.width) / 100,
            (imgStyleArray[i].height * props.height) / 100
          );
        }
      }

      ctx.drawImage(image2ForCanvas, 0, 0);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    // let frameCount = props.position1 * 10;
    let animationFrameId = 0;

    //Drawing happens here
    const render = () => {
      // frameCount++;
      draw(thisContext, 0);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  useEffect(() => {
    if (svgElement1 !== null && svgElement2 !== null) {
      svgString1 = serializer.serializeToString(svgElement1);
      svgString2 = serializer.serializeToString(svgElement2);
    }

    // make it base64
    var svg64_1 = btoa(svgString1);
    var image64_1 = b64Start + svg64_1;

    var svg64_2 = btoa(svgString2);
    var image64_2 = b64Start + svg64_2;

    image1ForCanvas.onload = function () {
      setIsImage1Loaded(true);
    };

    image2ForCanvas.onload = function () {
      setIsImage2Loaded(true);
    };

    image1ForCanvas.src = image64_1;
    image2ForCanvas.src = image64_2;
  }, [image1ForCanvas, image2ForCanvas]);

  return (
    <div className="backgroundLayer">
      <svg
        // className={"backgroundBase " + backgroundBaseColor}
        className={"svgLayer"}
      >
        {backgroundBase()}
      </svg>

      <svg
        viewBox={"0 0 1000 1414"}
        // className={"svgImage " + backgroundColor}
        className={"svgLayer"}
        style={{ fill: utils.colorToHex(backgroundLayer1Color) }}
        id={"backgroundLayer1"}
      >
        <DrawingBackground />
      </svg>

      {/* {formatBackgroundImages()} */}

      <svg
        viewBox={"0 0 1000 1414"}
        // className={"svgImage " + backgroundDrawingColor}
        className={"svgLayer"}
        style={{ fill: utils.colorToHex(backgroundLayer2Color) }}
        id={"backgroundLayer2"}
      >
        {backgroundDrawing()}
      </svg>

      <canvas
        className="canvas"
        id={`canvas-${directProps.id}`}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />

      <LayerBackgroundAccentCanvas
        keynumber={1}
        id={"backgroundAccent"}
        size={35}
        intensity={0.02}
      />

      <LayerBackgroundAccentCanvas
        keynumber={2}
        id={"backgroundAccent"}
        size={46}
      />
    </div>
  );
};

export default LayerBackground;
