const bgDrawingWindows = () => {
  return (
    <g>
      <polygon points="67.9,36.2 64.2,36.2 64.2,38 66,38 66,46.5 64.2,46.5 64.2,48.2 69.9,48.2 69.9,46.5 67.9,46.5 	" />
      <path
        d="M81.4,42.5c-0.2-0.6-0.5-1.2-0.9-1.6c-0.4-0.4-0.9-0.8-1.4-1c-0.6-0.2-1.2-0.4-1.9-0.4c-0.7,0-1.3,0.1-1.8,0.3
      c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8c0.2,0.5,0.6,1,1,1.4
      c0.4,0.4,0.9,0.7,1.5,0.9s1.2,0.3,1.8,0.3c0.7,0,1.4-0.1,2-0.3c0.6-0.2,1.2-0.6,1.7-1.1l-1-1.3c-0.3,0.3-0.7,0.6-1.2,0.8
      c-0.5,0.2-1,0.3-1.6,0.3c-0.8,0-1.4-0.2-1.9-0.6c-0.5-0.4-0.7-0.9-0.8-1.5h7.1C81.8,43.8,81.7,43.1,81.4,42.5z M74.7,43
      c0.1-0.5,0.4-1,0.9-1.3c0.5-0.3,1-0.5,1.6-0.5c0.6,0,1.1,0.2,1.6,0.5c0.5,0.3,0.8,0.8,0.8,1.3H74.7z"
      />
      <rect x="55.6" y="36.2" width="2.3" height="2.3" />
      <polygon
        points="42.8,48.2 44.6,48.2 44.6,42.9 49.1,42.9 49.1,41.2 44.6,41.2 44.6,38 50.4,38 50.4,36.2 42.8,36.2 	
      "
      />
      <polygon
        points="57.7,39.7 53.9,39.7 53.9,41.4 55.8,41.4 55.8,46.5 53.9,46.5 53.9,48.2 59.6,48.2 59.6,46.5 
      57.7,46.5 	"
      />
      <path d="M25,57.4h75.4V27.6H25V57.4z M28,30.6h69.4v23.8H28V30.6z" />
      <rect x="148.9" y="36.2" width="2.3" height="2.3" />
      <path
        d="M140.1,38h1.6v2.5c-0.4-0.3-0.7-0.6-1.1-0.7c-0.4-0.2-0.8-0.2-1.3-0.2c-0.5,0-1.1,0.1-1.5,0.3
      c-0.5,0.2-0.9,0.5-1.2,0.9c-0.3,0.4-0.6,0.9-0.8,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8c0.2,0.5,0.5,1,0.8,1.4
      c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.2,1,0.3,1.5,0.3c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.4,1.1-0.7v0.8h1.9V36.2h-3.5V38z M141.7,45.9
      c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.1-0.7,0.2-1,0.2c-0.7,0-1.3-0.3-1.7-0.8c-0.4-0.5-0.7-1.2-0.7-2s0.2-1.5,0.7-2
      c0.4-0.5,1-0.8,1.7-0.8c0.3,0,0.7,0.1,1,0.2c0.4,0.1,0.7,0.3,0.9,0.6V45.9z"
      />
      <polygon
        points="125.8,48.2 133.4,48.2 133.4,46.5 127.7,46.5 127.7,42.9 132.2,42.9 132.2,41.2 127.7,41.2 127.7,38 
      133.4,38 133.4,36.2 125.8,36.2 	"
      />
      <polygon
        points="151,39.7 147.2,39.7 147.2,41.4 149.1,41.4 149.1,46.5 147.2,46.5 147.2,48.2 152.9,48.2 152.9,46.5 
      151,46.5 	"
      />
      <path
        d="M162.4,46.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.7-0.4-1.2v-3.7h3.6v-1.7h-3.6
      v-3.4h-1.9v3.4H156v1.7h1.9V45c0,0.5,0.1,0.9,0.2,1.4c0.2,0.4,0.4,0.8,0.7,1s0.6,0.5,1.1,0.7c0.4,0.2,0.9,0.2,1.4,0.2
      c0.9,0,1.7-0.3,2.3-0.8c0.6-0.5,1-1.2,1.1-2.1l-1.7-0.4C162.9,45.5,162.7,45.9,162.4,46.2z"
      />
      <path d="M108.1,57.4h75.4V27.6h-75.4V57.4z M111.1,30.6h69.4v23.8h-69.4V30.6z" />
      <path
        d="M231.9,41.4h1.9v6.8c0,0.6-0.2,1-0.5,1.4c-0.3,0.4-0.8,0.5-1.4,0.5c-0.4,0-0.8-0.1-1.1-0.3
      c-0.3-0.2-0.6-0.4-0.9-0.8l-1.1,1.3c0.8,1,1.8,1.5,3.1,1.5c0.6,0,1.1-0.1,1.5-0.3c0.5-0.2,0.9-0.4,1.2-0.7c0.3-0.3,0.6-0.7,0.8-1.1
      c0.2-0.4,0.3-0.9,0.3-1.5v-8.5h-3.8V41.4z"
      />
      <path
        d="M255.3,46.4c-0.4,0.2-1,0.3-1.6,0.3c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.9-0.5c-0.2-0.2-0.4-0.5-0.6-0.8
      c-0.2-0.3-0.2-0.8-0.2-1.2c0-0.5,0.1-0.9,0.2-1.3c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,0.8-0.2
      c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.8h1.7v-4h-1.7v1.2c-0.2-0.5-0.5-0.8-0.9-1.1c-0.4-0.2-0.9-0.4-1.5-0.4
      c-0.6,0-1.1,0.1-1.6,0.3c-0.5,0.2-0.9,0.5-1.3,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8
      c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.2,0.3,1.9,0.3c0.7,0,1.4-0.1,2-0.3c0.6-0.2,1.2-0.6,1.7-1.1l-1-1.3
      C256.1,45.9,255.7,46.2,255.3,46.4z"
      />
      <path
        d="M247.6,42.5c-0.2-0.6-0.5-1.2-0.9-1.6c-0.4-0.4-0.9-0.8-1.4-1c-0.6-0.2-1.2-0.4-1.9-0.4
      c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8
      c0.2,0.5,0.6,1,1,1.4c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.2,0.3,1.8,0.3c0.7,0,1.4-0.1,2-0.3c0.6-0.2,1.2-0.6,1.7-1.1l-1-1.3
      c-0.3,0.3-0.7,0.6-1.2,0.8c-0.5,0.2-1,0.3-1.6,0.3c-0.8,0-1.4-0.2-1.9-0.6c-0.5-0.4-0.7-0.9-0.8-1.5h7.1
      C247.9,43.8,247.8,43.1,247.6,42.5z M240.9,43c0.1-0.5,0.4-1,0.9-1.3c0.5-0.3,1-0.5,1.6-0.5c0.6,0,1.1,0.2,1.6,0.5
      c0.5,0.3,0.8,0.8,0.8,1.3H240.9z"
      />
      <path
        d="M226.1,40.7c-0.3-0.4-0.7-0.7-1.2-0.9c-0.5-0.2-1-0.3-1.5-0.3c-0.5,0-0.9,0.1-1.3,0.2
      c-0.4,0.2-0.7,0.4-1.1,0.7v-4.2h-1.9v11.9h1.9v-0.8c0.4,0.3,0.7,0.6,1.1,0.7c0.4,0.2,0.8,0.3,1.3,0.3c0.5,0,1.1-0.1,1.5-0.3
      c0.5-0.2,0.9-0.5,1.2-0.9c0.3-0.4,0.6-0.9,0.8-1.4c0.2-0.5,0.3-1.1,0.3-1.8c0-0.6-0.1-1.2-0.3-1.8
      C226.7,41.6,226.4,41.1,226.1,40.7z M224.6,45.9c-0.4,0.5-1,0.8-1.7,0.8c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6v-4
      c0.2-0.2,0.5-0.4,0.9-0.6c0.4-0.1,0.7-0.2,1-0.2c0.7,0,1.3,0.3,1.7,0.8c0.5,0.5,0.7,1.2,0.7,2S225,45.4,224.6,45.9z"
      />
      <path
        d="M265.9,46.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.1-0.4c-0.3-0.3-0.4-0.7-0.4-1.2v-3.7h3.6v-1.7h-3.6
      v-3.4h-1.9v3.4h-1.9v1.7h1.9V45c0,0.5,0.1,0.9,0.2,1.4c0.2,0.4,0.4,0.8,0.7,1s0.6,0.5,1.1,0.7c0.4,0.2,0.9,0.2,1.4,0.2
      c0.9,0,1.7-0.3,2.3-0.8c0.6-0.5,1-1.2,1.1-2.1l-1.7-0.4C266.3,45.5,266.2,45.9,265.9,46.2z"
      />
      <rect x="233.6" y="36.2" width="2.3" height="2.3" />
      <path
        d="M216.2,37.9c-0.4-0.6-0.8-1-1.4-1.4c-0.6-0.3-1.3-0.5-2.1-0.5c-0.8,0-1.5,0.2-2.1,0.5c-0.6,0.3-1,0.8-1.4,1.4
      c-0.4,0.6-0.6,1.2-0.8,2c-0.2,0.7-0.3,1.5-0.3,2.3s0.1,1.6,0.2,2.3c0.2,0.7,0.4,1.4,0.8,2c0.4,0.6,0.8,1,1.4,1.4
      c0.6,0.3,1.3,0.5,2.1,0.5c0.8,0,1.5-0.2,2.1-0.5c0.6-0.3,1.1-0.8,1.4-1.4c0.4-0.6,0.6-1.2,0.8-2c0.2-0.7,0.2-1.5,0.2-2.3
      s-0.1-1.6-0.2-2.3C216.8,39.1,216.6,38.5,216.2,37.9z M215.2,43.8c-0.1,0.5-0.2,1-0.4,1.4c-0.2,0.4-0.5,0.8-0.8,1
      c-0.3,0.3-0.8,0.4-1.3,0.4c-0.5,0-1-0.1-1.3-0.4c-0.3-0.3-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.9-0.4-1.4c-0.1-0.5-0.1-1.1-0.1-1.6
      c0-1.4,0.2-2.5,0.7-3.3c0.5-0.8,1.1-1.2,2-1.2c0.5,0,1,0.1,1.3,0.4c0.4,0.3,0.6,0.6,0.8,1c0.2,0.4,0.3,0.9,0.4,1.4
      c0.1,0.5,0.1,1.1,0.1,1.6S215.3,43.3,215.2,43.8z"
      />
      <path d="M191.1,57.4h96.2V27.6h-96.2V57.4z M194.1,30.6h90.2v23.8h-90.2V30.6z" />
      <path
        d="M369.7,46.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.4,0-0.8-0.1-1.1-0.4s-0.4-0.7-0.4-1.2v-3.7h3.6v-1.7h-3.6v-3.4h-1.9
      v3.4h-1.9v1.7h1.9V45c0,0.5,0.1,0.9,0.2,1.4c0.2,0.4,0.4,0.8,0.7,1s0.6,0.5,1.1,0.7c0.4,0.2,0.9,0.2,1.4,0.2c0.9,0,1.7-0.3,2.3-0.8
      c0.6-0.5,1-1.2,1.1-2.1l-1.7-0.4C370.2,45.5,370,45.9,369.7,46.2z"
      />
      <path
        d="M359.1,46.4c-0.5,0.2-1,0.3-1.6,0.3c-0.4,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.9-0.5c-0.2-0.2-0.4-0.5-0.6-0.8
      c-0.2-0.3-0.2-0.8-0.2-1.2c0-0.5,0.1-0.9,0.2-1.3c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.2,0.5-0.4,0.8-0.5c0.3-0.1,0.6-0.2,0.8-0.2
      c0.7,0,1.2,0.2,1.6,0.7c0.4,0.4,0.6,1,0.6,1.8h1.7v-4h-1.7v1.2c-0.2-0.5-0.5-0.8-0.9-1.1c-0.4-0.2-0.9-0.4-1.5-0.4
      c-0.6,0-1.1,0.1-1.6,0.3c-0.5,0.2-0.9,0.5-1.3,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8
      c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.2,0.3,1.9,0.3c0.7,0,1.4-0.1,2-0.3c0.6-0.2,1.2-0.6,1.7-1.1l-1-1.3
      C360,45.9,359.6,46.2,359.1,46.4z"
      />
      <path
        d="M331,42.5c-0.2-0.6-0.5-1.2-0.9-1.6c-0.4-0.4-0.9-0.8-1.4-1c-0.6-0.2-1.2-0.4-1.9-0.4c-0.7,0-1.3,0.1-1.8,0.3
      c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8c0.2,0.5,0.6,1,1,1.4
      c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.2,0.3,1.8,0.3c0.7,0,1.4-0.1,2-0.3c0.6-0.2,1.2-0.6,1.7-1.1l-1-1.3c-0.3,0.3-0.7,0.6-1.2,0.8
      c-0.5,0.2-1,0.3-1.6,0.3c-0.8,0-1.4-0.2-1.9-0.6c-0.5-0.4-0.7-0.9-0.8-1.5h7.1C331.3,43.8,331.2,43.1,331,42.5z M324.3,43
      c0.1-0.5,0.4-1,0.9-1.3c0.5-0.3,1-0.5,1.6-0.5c0.6,0,1.1,0.2,1.6,0.5c0.5,0.3,0.8,0.8,0.8,1.3H324.3z"
      />
      <path
        d="M351.4,42.5c-0.2-0.6-0.5-1.2-0.9-1.6c-0.4-0.4-0.9-0.8-1.4-1c-0.6-0.2-1.2-0.4-1.9-0.4
      c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8
      c0.2,0.5,0.6,1,1,1.4c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.2,0.3,1.8,0.3c0.7,0,1.4-0.1,2-0.3c0.6-0.2,1.2-0.6,1.7-1.1l-1-1.3
      c-0.3,0.3-0.7,0.6-1.2,0.8c-0.4,0.2-1,0.3-1.6,0.3c-0.8,0-1.4-0.2-1.9-0.6c-0.5-0.4-0.7-0.9-0.8-1.5h7.1
      C351.7,43.8,351.6,43.1,351.4,42.5z M344.7,43c0.1-0.5,0.4-1,0.9-1.3c0.5-0.3,1-0.5,1.6-0.5s1.1,0.2,1.6,0.5
      c0.5,0.3,0.8,0.8,0.8,1.3H344.7z"
      />
      <path
        d="M319.3,42.3c-0.4-0.3-0.8-0.5-1.2-0.7c-0.4-0.2-0.9-0.4-1.3-0.5c-0.4-0.1-0.7-0.3-0.9-0.4
      c-0.3-0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4-0.1-0.6c0-0.5,0.2-0.8,0.5-1.1c0.4-0.3,0.8-0.4,1.4-0.4
      c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.6h1.7v-3.7h-1.7v1.1c-0.2-0.4-0.5-0.7-0.9-0.9c-0.4-0.2-0.9-0.3-1.4-0.3
      c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.8,0.3-1.1,0.6c-0.3,0.3-0.6,0.6-0.8,1c-0.2,0.4-0.3,0.8-0.3,1.4c0,0.6,0.1,1.1,0.3,1.5
      c0.2,0.4,0.5,0.7,0.8,1c0.3,0.3,0.7,0.5,1.2,0.7c0.4,0.2,0.9,0.3,1.3,0.5c0.4,0.1,0.7,0.3,1,0.4c0.3,0.1,0.5,0.3,0.7,0.4
      c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.8c0,0.6-0.2,1-0.5,1.3c-0.3,0.3-0.8,0.4-1.4,0.4c-0.3,0-0.7-0.1-0.9-0.2
      c-0.3-0.1-0.6-0.3-0.8-0.5c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.3-0.2-0.6-0.2-1h-1.7v3.9h1.7v-1.1c0.3,0.5,0.7,0.8,1.2,1
      c0.5,0.2,1,0.3,1.5,0.3c0.5,0,1-0.1,1.4-0.2c0.4-0.2,0.8-0.4,1.1-0.7c0.3-0.3,0.6-0.7,0.8-1.1c0.2-0.4,0.3-0.9,0.3-1.5
      c0-0.6-0.1-1.1-0.3-1.5C319.9,42.9,319.6,42.6,319.3,42.3z"
      />
      <polygon
        points="337.9,36.2 334.1,36.2 334.1,38 336,38 336,46.5 334.1,46.5 334.1,48.2 339.8,48.2 339.8,46.5 
      337.9,46.5 	"
      />
      <path d="M295,57.4h96.2V27.6H295V57.4z M298,30.6h90.2v23.8H298V30.6z" />
      <polygon
        points="422.1,44.3 421.2,39.7 419.4,39.7 418.6,44.3 417.5,36.2 415.5,36.2 417.4,48.2 419.3,48.2 420.3,43 
      421.4,48.2 423.3,48.2 425.2,36.2 423.2,36.2 	"
      />
      <polygon
        points="473.3,45.3 472.3,39.7 470.4,39.7 469.4,45.3 468.7,39.7 466.8,39.7 468.1,48.2 470.5,48.2 471.4,43 
      472.3,48.2 474.6,48.2 475.9,39.7 474,39.7 	"
      />
      <path
        d="M441.8,39.4c-0.7,0-1.4,0.2-1.9,0.6c-0.5,0.4-0.9,1-1,1.7v-2.1h-1.9v8.5h1.9v-3.8c0-1,0.2-1.8,0.7-2.3
      c0.4-0.6,1-0.8,1.7-0.8c0.5,0,0.8,0.1,1.1,0.4c0.2,0.3,0.4,0.7,0.4,1.4v5.2h1.9v-5.2c0-1.2-0.2-2-0.7-2.6
      C443.3,39.7,442.6,39.4,441.8,39.4z"
      />
      <polygon
        points="431.5,39.7 427.7,39.7 427.7,41.4 429.6,41.4 429.6,46.5 427.7,46.5 427.7,48.2 433.4,48.2 
      433.4,46.5 431.5,46.5 	"
      />
      <path
        d="M451.2,38h1.6v2.5c-0.4-0.3-0.7-0.6-1.1-0.7c-0.4-0.2-0.8-0.2-1.3-0.2c-0.5,0-1.1,0.1-1.5,0.3
      c-0.5,0.2-0.9,0.5-1.2,0.9c-0.3,0.4-0.6,0.9-0.8,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8c0.2,0.5,0.5,1,0.8,1.4
      c0.3,0.4,0.7,0.7,1.2,0.9c0.5,0.2,1,0.3,1.5,0.3c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.4,1.1-0.7v0.8h1.9V36.2h-3.5V38z M452.9,45.9
      c-0.2,0.2-0.5,0.4-0.9,0.6c-0.4,0.1-0.7,0.2-1,0.2c-0.7,0-1.3-0.3-1.7-0.8c-0.4-0.5-0.7-1.2-0.7-2s0.2-1.5,0.7-2
      c0.5-0.5,1-0.8,1.7-0.8c0.3,0,0.7,0.1,1,0.2c0.4,0.1,0.7,0.3,0.9,0.6V45.9z"
      />
      <rect x="429.4" y="36.2" width="2.3" height="2.3" />
      <path
        d="M464.4,40.7c-0.4-0.4-0.9-0.7-1.4-0.9c-0.5-0.2-1.2-0.3-1.8-0.3c-0.7,0-1.3,0.1-1.8,0.3
      c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8c0.2,0.5,0.5,1,0.9,1.4
      c0.4,0.4,0.9,0.7,1.4,0.9c0.5,0.2,1.2,0.3,1.8,0.3c0.7,0,1.3-0.1,1.8-0.3c0.5-0.2,1-0.5,1.4-0.9c0.4-0.4,0.7-0.9,0.9-1.4
      c0.2-0.5,0.3-1.1,0.3-1.8c0-0.6-0.1-1.2-0.3-1.8C465.1,41.6,464.8,41.1,464.4,40.7z M463.6,45c-0.1,0.3-0.3,0.6-0.5,0.9
      c-0.2,0.2-0.5,0.4-0.8,0.6c-0.3,0.1-0.7,0.2-1.1,0.2c-0.4,0-0.8-0.1-1.1-0.2c-0.3-0.1-0.6-0.3-0.8-0.6c-0.2-0.2-0.4-0.5-0.5-0.9
      c-0.1-0.3-0.2-0.7-0.2-1.1c0-0.4,0.1-0.7,0.2-1.1c0.1-0.3,0.3-0.6,0.5-0.9c0.2-0.2,0.5-0.4,0.8-0.6c0.3-0.1,0.7-0.2,1.1-0.2
      c0.4,0,0.8,0.1,1.1,0.2c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.2,0.4,0.5,0.5,0.9c0.1,0.3,0.2,0.7,0.2,1.1C463.7,44.3,463.7,44.7,463.6,45z
      "
      />
      <path d="M398.8,57.4H495V27.6h-96.2V57.4z M401.8,30.6H492v23.8h-90.2V30.6z" />
      <polygon
        points="545.5,36.2 541.8,36.2 541.8,38 543.6,38 543.6,46.5 541.8,46.5 541.8,48.2 547.5,48.2 547.5,46.5 
      545.5,46.5 	"
      />
      <path
        d="M557.9,40.7c-0.3-0.4-0.7-0.7-1.2-0.9c-0.5-0.2-1-0.3-1.5-0.3c-0.5,0-0.9,0.1-1.3,0.2
      c-0.4,0.2-0.7,0.4-1.1,0.7v-0.8H551v11.9h1.9v-4.2c0.4,0.3,0.7,0.6,1.1,0.7c0.4,0.2,0.8,0.3,1.3,0.3c0.5,0,1.1-0.1,1.5-0.3
      c0.5-0.2,0.9-0.5,1.2-0.9c0.3-0.4,0.6-0.9,0.8-1.4c0.2-0.5,0.3-1.1,0.3-1.8c0-0.6-0.1-1.2-0.3-1.8
      C558.5,41.6,558.3,41.1,557.9,40.7z M556.5,45.9c-0.4,0.5-1,0.8-1.7,0.8c-0.3,0-0.7-0.1-1-0.2c-0.3-0.1-0.6-0.3-0.9-0.6v-4
      c0.2-0.2,0.5-0.4,0.9-0.6c0.4-0.1,0.7-0.2,1-0.2c0.7,0,1.3,0.3,1.7,0.8c0.5,0.5,0.7,1.2,0.7,2S556.9,45.4,556.5,45.9z"
      />
      <path
        d="M538.6,42.5c-0.2-0.6-0.5-1.2-0.9-1.6c-0.4-0.4-0.9-0.8-1.4-1c-0.6-0.2-1.2-0.4-1.9-0.4
      c-0.7,0-1.3,0.1-1.8,0.3c-0.5,0.2-1,0.5-1.4,0.9c-0.4,0.4-0.7,0.9-0.9,1.4c-0.2,0.5-0.3,1.1-0.3,1.8c0,0.7,0.1,1.3,0.3,1.8
      c0.2,0.5,0.6,1,1,1.4c0.4,0.4,0.9,0.7,1.5,0.9c0.6,0.2,1.2,0.3,1.8,0.3c0.7,0,1.4-0.1,2-0.3c0.6-0.2,1.2-0.6,1.7-1.1l-1-1.3
      c-0.3,0.3-0.7,0.6-1.2,0.8c-0.5,0.2-1,0.3-1.6,0.3c-0.8,0-1.4-0.2-1.9-0.6c-0.5-0.4-0.7-0.9-0.8-1.5h7.1
      C539,43.8,538.9,43.1,538.6,42.5z M531.9,43c0.1-0.5,0.4-1,0.9-1.3c0.5-0.3,1-0.5,1.6-0.5s1.1,0.2,1.6,0.5c0.5,0.3,0.8,0.8,0.8,1.3
      H531.9z"
      />
      <polygon
        points="526.1,41.2 522.3,41.2 522.3,36.2 520.4,36.2 520.4,48.2 522.3,48.2 522.3,42.9 526.1,42.9 
      526.1,48.2 528,48.2 528,36.2 526.1,36.2 	"
      />
      <path d="M502.6,56.7h76.6V27.6h-76.6V56.7z M505.6,30.6h70.6v23.1h-70.6V30.6z" />
      <polygon
        points="960.2,42.5 962.5,38.3 963.4,38.3 963.4,36.6 959.6,36.6 959.6,38.3 960.4,38.3 959.1,40.9 
      957.9,38.3 958.7,38.3 958.7,36.6 954.9,36.6 954.9,38.3 955.8,38.3 958,42.5 955.7,46.8 954.7,46.8 954.7,48.5 958.5,48.5 
      958.5,46.8 957.9,46.8 959.1,44.1 960.4,46.8 959.7,46.8 959.7,48.5 963.6,48.5 963.6,46.8 962.5,46.8 	"
      />
      <path d="M945,57h30V28h-30V57z M948,31h24V54h-24V31z" />
      <path d="M10,10v65v1329h980V75V10H10z M14,14h972v57H14V14z M986,1400H14V75h972V1400z" />
      <polygon
        points="299.5,288.8 298.9,288.8 300.1,286.1 301.4,288.8 300.7,288.8 300.7,290.5 304.6,290.5 304.6,288.8 
      303.5,288.8 301.2,284.4 303.5,280.3 304.4,280.3 304.4,278.6 300.6,278.6 300.6,280.3 301.4,280.3 300.1,282.9 298.9,280.3 
      299.7,280.3 299.7,278.6 295.9,278.6 295.9,280.3 296.8,280.3 299,284.4 296.7,288.8 295.7,288.8 295.7,290.5 299.5,290.5 	"
      />
      <path d="M286,269.9V299h30v-29.1H286z M313,296h-24v-23.1h24V296z" />
      <path d="M326,309v-48H98v48v188h228V309z M102,265h220v44H102V265z M102,493V312h220v181H102z" />
      <polygon
        points="886.5,1160.8 885.9,1160.8 887.1,1158.1 888.4,1160.8 887.7,1160.8 887.7,1162.5 891.6,1162.5 
      891.6,1160.8 890.5,1160.8 888.2,1156.4 890.5,1152.3 891.4,1152.3 891.4,1150.6 887.6,1150.6 887.6,1152.3 888.4,1152.3 
      887.1,1154.9 885.9,1152.3 886.7,1152.3 886.7,1150.6 882.9,1150.6 882.9,1152.3 883.8,1152.3 886,1156.4 883.7,1160.8 
      882.7,1160.8 882.7,1162.5 886.5,1162.5 	"
      />
      <path d="M903,1141.9h-30v29.1h30V1141.9z M900,1168h-24v-23.1h24V1168z" />
      <path d="M685,1181v188h228v-188v-48H685V1181z M689,1365v-181h220v181H689z M909,1137v44H689v-44H909z" />
      <polygon
        points="627.5,1160.8 626.9,1160.8 628.1,1158.1 629.4,1160.8 628.7,1160.8 628.7,1162.5 632.6,1162.5 
      632.6,1160.8 631.5,1160.8 629.2,1156.4 631.5,1152.3 632.4,1152.3 632.4,1150.6 628.6,1150.6 628.6,1152.3 629.4,1152.3 
      628.1,1154.9 626.9,1152.3 627.7,1152.3 627.7,1150.6 623.9,1150.6 623.9,1152.3 624.8,1152.3 627,1156.4 624.7,1160.8 
      623.7,1160.8 623.7,1162.5 627.5,1162.5 	"
      />
      <path d="M614,1141.9v29.1h30v-29.1H614z M641,1168h-24v-23.1h24V1168z" />
      <path d="M654,1181v-48H426v48v188h228V1181z M430,1137h220v44H430V1137z M430,1365v-181h220v181H430z" />
      <polygon
        points="938.5,295.8 937.9,295.8 939.1,293.1 940.4,295.8 939.7,295.8 939.7,297.5 943.6,297.5 943.6,295.8 
      942.5,295.8 940.2,291.4 942.5,287.3 943.4,287.3 943.4,285.6 939.6,285.6 939.6,287.3 940.4,287.3 939.1,289.9 937.9,287.3 
      938.7,287.3 938.7,285.6 934.9,285.6 934.9,287.3 935.8,287.3 938,291.4 935.7,295.8 934.7,295.8 934.7,297.5 938.5,297.5 	"
      />
      <path d="M925,276.9V306h30v-29.1H925z M952,303h-24v-23.1h24V303z" />
      <polygon
        points="928.5,353.8 927.9,353.8 929.1,351.1 930.4,353.8 929.7,353.8 929.7,355.5 933.6,355.5 933.6,353.8 
      932.5,353.8 930.2,349.4 932.5,345.3 933.4,345.3 933.4,343.6 929.6,343.6 929.6,345.3 930.4,345.3 929.1,347.9 927.9,345.3 
      928.7,345.3 928.7,343.6 924.9,343.6 924.9,345.3 925.8,345.3 928,349.4 925.7,353.8 924.7,353.8 924.7,355.5 928.5,355.5 	"
      />
      <path d="M945,334.9h-30V364h30V334.9z M942,361h-24v-23.1h24V361z" />
      <path d="M628,374v188h327V374v-48H628V374z M952,559H631V377h321V559z M952,329v45H631v-45H952z" />
      <path d="M965,316v-48H550v48v334h415V316z M554,272h407v44H554V272z M554,646V319h407v327H554z" />
      <polygon
        points="364.5,807.8 363.9,807.8 365.1,805.1 366.4,807.8 365.7,807.8 365.7,809.5 369.6,809.5 369.6,807.8 
      368.5,807.8 366.2,803.4 368.5,799.3 369.4,799.3 369.4,797.6 365.6,797.6 365.6,799.3 366.4,799.3 365.1,801.9 363.9,799.3 
      364.7,799.3 364.7,797.6 360.9,797.6 360.9,799.3 361.8,799.3 364,803.4 361.7,807.8 360.7,807.8 360.7,809.5 364.5,809.5 	"
      />
      <path d="M351,788.9V818h30v-29.1H351z M378,815h-24v-23.1h24V815z" />
      <path d="M391,828v-48H30v48v100h361V828z M34,784h353v44H34V784z M34,924v-93h353v93H34z" />
      <polygon
        points="364.5,978.8 363.9,978.8 365.1,976.1 366.4,978.8 365.7,978.8 365.7,980.5 369.6,980.5 369.6,978.8 
      368.5,978.8 366.2,974.4 368.5,970.3 369.4,970.3 369.4,968.6 365.6,968.6 365.6,970.3 366.4,970.3 365.1,972.9 363.9,970.3 
      364.7,970.3 364.7,968.6 360.9,968.6 360.9,970.3 361.8,970.3 364,974.4 361.7,978.8 360.7,978.8 360.7,980.5 364.5,980.5 	"
      />
      <path d="M351,959.9V989h30v-29.1H351z M378,986h-24v-23.1h24V986z" />
      <path d="M391,1099V999v-48H30v48v100H391z M34,1095v-93h353v93H34z M34,955h353v44H34V955z" />
      <polygon
        points="938.5,711.8 937.9,711.8 939.1,709.1 940.4,711.8 939.7,711.8 939.7,713.5 943.6,713.5 943.6,711.8 
      942.5,711.8 940.2,707.4 942.5,703.3 943.4,703.3 943.4,701.6 939.6,701.6 939.6,703.3 940.4,703.3 939.1,705.9 937.9,703.3 
      938.7,703.3 938.7,701.6 934.9,701.6 934.9,703.3 935.8,703.3 938,707.4 935.7,711.8 934.7,711.8 934.7,713.5 938.5,713.5 	"
      />
      <path d="M955,692.9h-30V722h30V692.9z M952,719h-24v-23.1h24V719z" />
      <path
        d="M850.1,812h0.7h0.7H933h13c-1.6-1.7-8.9-2.5-10.4-2.3c-1.4,0.3-3.2-0.3-4.7-0.9c-1.4-0.7-2.2-8.7-3.5-8.8
      c-1.3-0.1-2.7,0.4-3.9,0.7s-1.7-2.5-3.2-3c-1.4-0.5-1.9,5.6-2.7,4.4c-0.8-1.2-4.3-11-5.4-14.1c-1.1-3-2.6,13.9-3.3,5.4
      c-0.6-8.5-1.3-4.5-1.9-10.7c-0.6-6.2-1.7-8.2-2.1-2.8c-0.3,5.4-1.1,3.7-1.7,6.5c-0.7,3-1.2-5-1.7,2.8c-0.4,7.8-1.5-3-2.4,1.9
      c-0.9,4.8-2.2,5.3-2.9,4.7c-0.7-0.5-1.7-3-2.2-1.8c-0.5,1.3-1.1,0.4-1.7,1c-0.7,0.5-0.2,3.2-0.6,3.5c-0.4,0.3-1.6-0.3-2.1,0.3
      c-0.4,0.4-1.1,3.1-2.3,2.5c-1.1-0.7-1.8-2.5-2.4-1.8c0-9.2-1.4-3.6-2-4.3c-0.6-0.7-2.2-7-2.6-6.7c-0.4,0.3-1.4,1.8-1.8,2.1
      c-0.4,0.3-3.3,9.3-4.1,6.2c-0.8-3.1-1.3-3.5-1.7-3.5c-0.4,0-2.2-0.4-2.3-1.5c-0.1-1.2-1.8,3.5-2.3,4c-0.4,0.4-1.6-12.8-2.3-6.8
      c-0.7,6.1-2.8,6-2.8,6s-2.5-6.3-3.1-5.4c-0.6,0.9-1.6,7.2-1.6,7.2c-2.5-5.8-2.6,1.2-4,3.7c-1.4,2.5-3.3,4.7-3.3,4.7s-1.6,1.4-3-1
      c-1.4-2.5-1.8-5.8-4.4,0c0,0-2.2-6.3-2.8-7.2c-0.6-0.9-1.9,3.1-1.9,3.1s-2.1-5.2-2.8-11.2c-0.7-6.1-1.8-10.3-2.3-10.8
      c-0.4-0.4-2.2,9.4-2.3,10.6c-0.1,1.2-1.8-5.6-2.3-5.6c-0.4,0-2.8,9.9-3.6,13c-0.8,3.1-1.3,4.5-1.7,4.3c-0.4-0.3-1.4-1.8-1.8-2.1
      c-0.4-0.3-2.1,6.1-2.6,6.7c-0.6,0.7-1.9-4.9-2.5-5.6c-0.6-0.7-1.3,1.2-2.4,1.8c-1.1,0.7-1.8-2.1-2.3-2.5s-1.7,0-2.1-0.3
      c-0.4-0.3,0.1-3-0.6-3.5c-0.7-0.5-1.2,0.3-1.7-1c-0.5-1.3-1.5,1.3-2.2,1.8s-1.9,0.1-2.9-4.7c-0.9-4.8-1.9-5.8-2.4-13.6
      c-0.4-7.8-1-11.1-1.7-14.1c-0.7-3-1.4-4.9-1.7-10.3c-0.4-5.4-1.4-3.4-2.1,2.8c-0.6,6.2-1.3,5.8-1.9,14.4
      c-0.6,8.5-2.2,14.6-3.3,17.7c-1.1,3-4.6,12.9-5.4,14.1c-0.8,1.2-1.3,0.4-2.7,0.9c-1.4,0.5-1.9,3.2-3.2,3c-1.2-0.3-2.6-0.8-3.9-0.7
      c-1.3,0.1-2.1,2.8-3.5,3.5c-1.4,0.7-3.2,1.2-4.7,0.9c-1.4-0.3-8.8,0.5-10.4,2.3h13H850.1z"
      />
      <path d="M954,742H748v78h206V742z M951,817H751v-72h200V817z" />
      <path
        d="M805.9,846.8L805.9,846.8l-0.4-0.4l0,0c-5.3-5.1-12.5-8.3-20.4-8.4v0h-0.6v0c-8,0.1-15.2,3.3-20.5,8.5
      l-0.1-0.1l-0.4,0.4l0.1,0.1c-5.2,5.3-8.4,12.5-8.5,20.5h0v0.6h0c0.1,7.9,3.3,15.1,8.4,20.4l0,0l0.4,0.4l0,0
      c5.3,5.3,12.6,8.5,20.6,8.6v0h0.6v0c16.2-0.2,29.3-13.3,29.5-29.5h0v-0.6h0C814.4,859.4,811.1,852.1,805.9,846.8z M813,867.5h-5.9
      c-0.1-6-2.5-11.4-6.4-15.4l4.2-4.2C809.8,852.9,812.9,859.8,813,867.5z M785,861.8c1.4,0.1,2.6,0.6,3.6,1.4l-3.6,3.6V861.8z
       M784.5,867.1l-3.7-3.7c1-0.9,2.3-1.5,3.7-1.5V867.1z M784,867.5h-5.2c0.1-1.4,0.6-2.7,1.5-3.7L784,867.5z M783.8,868l-3.6,3.6
      c-0.8-1-1.4-2.2-1.4-3.6H783.8z M784.5,868.2v5.4c-1.5-0.1-2.8-0.7-3.8-1.6L784.5,868.2z M785,868.5l3.7,3.7
      c-1,0.9-2.3,1.4-3.7,1.5V868.5z M785.4,868h5.3c-0.1,1.4-0.6,2.7-1.5,3.7L785.4,868z M785.2,867.5l3.8-3.8c1,1,1.6,2.3,1.6,3.8
      H785.2z M789.7,862.2c-1.2-1.1-2.9-1.8-4.6-1.9v-5.9c3.4,0.1,6.5,1.4,8.8,3.6L789.7,862.2z M784.5,860.3c-1.8,0.1-3.5,0.8-4.8,2
      l-4.2-4.2c2.3-2.2,5.5-3.7,9-3.7V860.3z M779.3,862.7c-1.2,1.3-1.9,2.9-1.9,4.7h-5.9c0.1-3.5,1.5-6.6,3.7-8.9L779.3,862.7z
       M777.3,868c0.1,1.8,0.8,3.4,1.9,4.6l-4.2,4.2c-2.2-2.3-3.5-5.4-3.6-8.8H777.3z M779.6,873.1c1.3,1.2,3,2,4.9,2.1v5.9
      c-3.5-0.1-6.7-1.5-9.1-3.8L779.6,873.1z M785,875.2c1.8-0.1,3.5-0.8,4.7-1.9l4.2,4.2c-2.3,2.2-5.5,3.6-8.9,3.7V875.2z M790.2,872.8
      c1.2-1.3,1.9-2.9,2-4.8h5.9c-0.1,3.5-1.5,6.6-3.7,9L790.2,872.8z M792.2,867.5c-0.1-1.9-0.8-3.6-2.1-4.9l4.2-4.2
      c2.3,2.3,3.7,5.5,3.8,9.1H792.2z M794.9,856.9c-2.6-2.4-6.1-4-9.9-4V847c5.5,0.1,10.4,2.3,14.1,5.8L794.9,856.9z M784.5,852.9
      c-3.9,0.1-7.4,1.6-10,4.2l-4.2-4.2c3.7-3.6,8.7-5.8,14.2-5.9V852.9z M774,857.5c-2.5,2.6-4.1,6.1-4.1,10H764
      c0.1-5.5,2.3-10.5,5.9-14.2L774,857.5z M769.9,868c0.1,3.8,1.6,7.3,4,9.9l-4.2,4.2c-3.5-3.7-5.7-8.6-5.8-14.1H769.9z M774.3,878.4
      c2.6,2.6,6.2,4.2,10.1,4.3v5.9c-5.6-0.1-10.6-2.3-14.3-6L774.3,878.4z M785,882.6c3.9-0.1,7.4-1.6,10-4.1l4.2,4.2
      c-3.7,3.6-8.7,5.8-14.2,5.9V882.6z M795.4,878.1c2.5-2.6,4.1-6.1,4.2-10h5.9c-0.1,5.5-2.3,10.5-5.9,14.2L795.4,878.1z M799.6,867.5
      c-0.1-3.9-1.7-7.5-4.3-10.1l4.2-4.2c3.6,3.7,5.9,8.7,6,14.3H799.6z M804.4,847.5l-4.2,4.2c-3.9-3.8-9.3-6.1-15.2-6.2v-5.9
      C792.5,839.6,799.4,842.6,804.4,847.5z M784.5,839.5v5.9c-5.9,0.1-11.3,2.5-15.3,6.3l-4.2-4.2C770,842.7,776.9,839.6,784.5,839.5z
       M764.6,848l4.2,4.2c-3.8,4-6.2,9.3-6.3,15.2h-5.9C756.6,859.9,759.6,853.1,764.6,848z M756.5,868h5.9c0.1,5.9,2.4,11.2,6.2,15.2
      l-4.2,4.2C759.6,882.4,756.6,875.5,756.5,868z M764.9,887.8l4.2-4.2c4,3.9,9.4,6.4,15.4,6.4v5.9
      C776.8,895.9,769.9,892.8,764.9,887.8z M785,890.1c5.9-0.1,11.3-2.5,15.2-6.3l4.2,4.2c-5,4.9-11.9,8-19.4,8.1V890.1z M804.9,887.5
      l-4.2-4.2c3.9-4,6.3-9.3,6.3-15.3h5.9C812.9,875.6,809.8,882.5,804.9,887.5z"
      />
      <path d="M824.5,897.5H947V838H824.5V897.5z M827.5,841H944v53.5H827.5V841z" />
      <path d="M747,905.5h208V830H747V905.5z M750,833h202v69.5H750V833z" />
      <path d="M737,732v188h228V732v-48H737V732z M741,916V735h220v181H741z M961,688v44H741v-44H961z" />
      <polygon
        points="228.5,677.8 227.9,677.8 229.1,675.1 230.4,677.8 229.7,677.8 229.7,679.5 233.6,679.5 233.6,677.8 
      232.5,677.8 230.2,673.4 232.5,669.3 233.4,669.3 233.4,667.6 229.6,667.6 229.6,669.3 230.4,669.3 229.1,671.9 227.9,669.3 
      228.7,669.3 228.7,667.6 224.9,667.6 224.9,669.3 225.8,669.3 228,673.4 225.7,677.8 224.7,677.8 224.7,679.5 228.5,679.5 	"
      />
      <path d="M215,658.9V688h30v-29.1H215z M242,685h-24v-23.1h24V685z" />
      <polygon points="49.5,721 49.5,733 54.5,733 54.5,726 60.5,726 60.5,733 65.5,733 65.5,721 57.5,715 	" />
      <polygon points="87.5,731 98.5,724 87.5,717 	" />
      <polygon points="119.5,730 128,724 119.5,718 	" />
      <rect x="129.5" y="718" width="2" height="12" />
      <rect x="153.5" y="718" width="2" height="12" />
      <polygon points="165.5,730 165.5,718 157,724 	" />
      <path d="M198,724c0-1.8-1-3.3-2.5-4v8.1C197,727.3,198,725.8,198,724z" />
      <polygon points="184.5,721 184.5,727 188.5,727 193.5,732 193.5,716 188.5,721 	" />
      <path d="M195.5,715.2v2.1c2.9,0.9,5,3.5,5,6.7s-2.1,5.8-5,6.7v2.1c4-0.9,7-4.5,7-8.8S199.5,716.1,195.5,715.2z" />
      <path
        d="M229.1,721.9c0.9-2.3,0.4-5-1.5-6.9c-2-2-5-2.4-7.4-1.3l4.3,4.3l-3,3l-4.4-4.3c-1.2,2.4-0.7,5.4,1.3,7.4
      c1.9,1.9,4.6,2.4,6.9,1.5l9.1,9.1c0.4,0.4,1,0.4,1.4,0l2.3-2.3c0.5-0.4,0.5-1.1,0.1-1.4L229.1,721.9z"
      />
      <path d="M255,698v-48H30v48v52h225V698z M34,654h217v44H34V654z M34,746v-45h217v45H34z" />
      <polygon
        points="859.5,162.8 858.9,162.8 860.1,160.1 861.4,162.8 860.7,162.8 860.7,164.5 864.6,164.5 864.6,162.8 
      863.5,162.8 861.2,158.4 863.5,154.3 864.4,154.3 864.4,152.6 860.6,152.6 860.6,154.3 861.4,154.3 860.1,156.9 858.9,154.3 
      859.7,154.3 859.7,152.6 855.9,152.6 855.9,154.3 856.8,154.3 859,158.4 856.7,162.8 855.7,162.8 855.7,164.5 859.5,164.5 	"
      />
      <path d="M846,143.9V173h30v-29.1H846z M873,170h-24v-23.1h24V170z" />
      <path
        d="M730,199h-8l-6,6l0,12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-16C732,199.9,731.1,199,730,199z M724,205h-2v-4h2
      V205z M727,205h-2v-4h2V205z M730,205h-2v-4h2V205z"
      />
      <path
        d="M766,199h-16c-1.1,0-2,0.9-2,2l0,18l4-4h14c1.1,0,2-0.9,2-2v-12C768,199.9,767.1,199,766,199z M755,208h-2v-2
      h2V208z M759,208h-2v-2h2V208z M763,208h-2v-2h2V208z"
      />
      <path d="M781,206l2,2c5-5,13-5,18,0l2-2C796.9,199.9,787.1,199.9,781,206z" />
      <path d="M785,210l2,2c2.8-2.8,7.2-2.8,10,0l2-2C795.1,206.1,788.9,206.1,785,210z" />
      <path d="M789,214l3,3l3-3C793.3,212.3,790.7,212.3,789,214z" />
      <path d="M838,214.3V197h-24v24h24V214.3C838,214.4,838,214.4,838,214.3C838,214.4,838,214.3,838,214.3z" />
      <path d="M862.2,209l2.3,2.3c0.3-0.7,0.4-1.5,0.4-2.3s-0.2-1.6-0.4-2.3L862.2,209z" />
      <path d="M866.3,205c0.6,1.2,1,2.6,1,4s-0.4,2.8-1,4l1.2,1.2c1-1.5,1.5-3.4,1.5-5.3c0-1.9-0.5-3.7-1.5-5.2L866.3,205z" />
      <path
        d="M863.7,204.7L858,199h-1v7.6l-4.6-4.6l-1.4,1.4l5.6,5.6l-5.6,5.6l1.4,1.4l4.6-4.6v7.6h1l5.7-5.7l-4.3-4.3
      L863.7,204.7z M859,202.8l1.9,1.9l-1.9,1.9V202.8z M860.9,213.3l-1.9,1.9v-3.8L860.9,213.3z"
      />
      <path d="M886,183v-48H698v48v52h188V183z M702,139h180v44H702V139z M702,231v-45h180v45H702z" />
      <polygon
        points="368.5,124.8 367.9,124.8 369.1,122.1 370.4,124.8 369.7,124.8 369.7,126.5 373.6,126.5 373.6,124.8 
      372.5,124.8 370.2,120.4 372.5,116.3 373.4,116.3 373.4,114.6 369.6,114.6 369.6,116.3 370.4,116.3 369.1,118.9 367.9,116.3 
      368.7,116.3 368.7,114.6 364.9,114.6 364.9,116.3 365.8,116.3 368,120.4 365.7,124.8 364.7,124.8 364.7,126.5 368.5,126.5 	"
      />
      <path d="M355,105.9V135h30v-29.1H355z M382,132h-24v-23.1h24V132z" />
      <path
        d="M195,162c-0.5,0-0.9,0-1.4,0.1c-2.8,0.5-5,2.8-5.5,5.5c-0.5,2.6,0.5,5,2.2,6.6c0.4,0.4,0.7,0.9,0.7,1.5v2.3
      c0,1.1,0.9,2,2,2h0.3c0.3,0.6,1,1,1.7,1s1.4-0.4,1.7-1h0.3c1.1,0,2-0.9,2-2v-2.3c0-0.5,0.2-1.1,0.6-1.5c1.5-1.3,2.4-3.1,2.4-5.2
      C202,165.1,198.9,162,195,162z M197,178h-4v-1h4V178z M197,176h-4v-1h4V176z M195.5,170.4v2.6h-1v-2.6l-1.8-1.8l0.7-0.7l1.6,1.6
      l1.6-1.6l0.7,0.7L195.5,170.4z"
      />
      <circle cx="232.5" cy="168.5" r="1.5" />
      <circle cx="225.5" cy="168.5" r="1.5" />
      <path d="M229,176.5c2.3,0,4.3-1.5,5.1-3.5h-10.2C224.7,175,226.7,176.5,229,176.5z" />
      <path
        d="M229,161c-5.5,0-10,4.5-10,10s4.5,10,10,10c5.5,0,10-4.5,10-10S234.5,161,229,161z M229,179c-4.4,0-8-3.6-8-8
      s3.6-8,8-8s8,3.6,8,8S233.4,179,229,179z"
      />
      <path d="M260,173c-2.7,0-8,1.3-8,4v2h16v-2C268,174.3,262.7,173,260,173z" />
      <circle cx="260" cy="167" r="4" />
      <polygon
        points="271,166 269,166 269,169 266,169 266,171 269,171 269,174 271,174 271,171 274,171 274,169 271,169 	
      "
      />
      <path
        d="M297,161c-5.5,0-10,4.5-10,10s4.5,10,10,10c5.5,0,10-4.5,10-10S302.5,161,297,161z M296,178.9
      c-4-0.5-7-3.8-7-7.9c0-0.6,0.1-1.2,0.2-1.8l4.8,4.8v1c0,1.1,0.9,2,2,2V178.9z M302.9,176.4c-0.3-0.8-1-1.4-1.9-1.4h-1v-3
      c0-0.6-0.5-1-1-1h-6v-2h2c0.5,0,1-0.4,1-1v-2h2c1.1,0,2-0.9,2-2v-0.4c2.9,1.2,5,4.1,5,7.4C305,173.1,304.2,175,302.9,176.4z"
      />
      <path d="M323,168h-2v11h2c0.5,0,1-0.4,1-1v-9C324,168.4,323.5,168,323,168z" />
      <path
        d="M339,168h-5.5l0.9-4.7c0-0.2,0-0.5-0.1-0.7c-0.2-0.4-0.5-0.9-0.9-1.2L333,161l-6.4,6.4
      c-0.4,0.4-0.6,0.9-0.6,1.4v7.8c0,1.3,1,2.3,2.3,2.3h8.1c0.7,0,1.4-0.4,1.7-1l2.7-6.2c0.1-0.2,0.2-0.5,0.2-0.8V170
      C341,168.9,340.1,168,339,168z"
      />
      <path
        d="M366.5,159.7c0,0,0.7,2.7,0.7,4.8c0,2.1-1.3,3.7-3.4,3.7c-2.1,0-3.6-1.7-3.6-3.7l0-0.4c-2,2.4-3.2,5.5-3.2,8.9
      c0,4.4,3.6,8,8,8c4.4,0,8-3.6,8-8C373,167.6,370.4,162.8,366.5,159.7z M368.9,169.2c0.4,1.3,0.6,2.7,0.6,4c0,2.7-2.2,4.8-4.8,4.8
      c-1.8,0-3.2-1.4-3.2-3.1c0-1.6,1.1-2.8,2.8-3.1C366.1,171.4,367.9,170.5,368.9,169.2z"
      />
      <path d="M395,145V97H165v48v52h230V145z M169,101h222v44H169V101z M169,193v-45h222v45H169z" />
    </g>
  );
};

export default bgDrawingWindows;
