import { useStore } from "./ArtworkPropertyStore";
import LayerCanvas from "./LayerCanvas";
import LayerSvg from "./LayerSvg";
import LayerSvgIcon from "./LayerSvgIcon";
import LayerWordBlob from "./LayerWordBlob";
import { svgIconsArray } from "./Resources/SvgIcons";
import { FocusGridType } from "./Resources/Types";
import * as utils from "./Utilities";

// interface groupAccentCanvasProps {
//   isFocusGridDrawn: boolean;
//   focusImg: HTMLImageElement;
//   focusGrid: { value: number; x: number; y: number }[][];
// }

const GroupSvgIcons = () => {
  const props = utils.getProps();

  const pos1X = props.position1;
  const pos1Y = props.position2;

  const icons = [];

  const selectSvgIcon = (iconCode: number) => {
    const objectMatchingTheme = svgIconsArray.filter((obj) => {
      return obj.theme === props.theme;
    });

    // console.log("chosen items:", textsMatchingTheme);
    const iconsMatchingTheme = objectMatchingTheme[0].icons;
    const totalNumberOfIcons = iconsMatchingTheme.length;

    for (let i = 0; i < totalNumberOfIcons; i++) {
      if (
        iconCode > Math.floor(100 / totalNumberOfIcons) * i &&
        iconCode <= Math.floor(100 / totalNumberOfIcons) * (i + 1)
      ) {
        return iconsMatchingTheme[i];
      }
    }

    return iconsMatchingTheme[0];
  };

  let numberOfIcons = Math.round((pos1X + pos1Y) / 40);
  numberOfIcons = utils.clamp(numberOfIcons, 4, 7);

  let filteredFocusPoints: FocusGridType = [];

  if (props.isFocusGridDrawn) {
    filteredFocusPoints = utils.filterFocusGridValue(
      props.focusGrid,
      "x",
      props.width * 0.95,
      "lessThan"
    );

    for (let i = 1; i < numberOfIcons + 1; i++) {
      const size =
        props.width / 40 +
        (parseInt(utils.generateValue(2, pos1X * i * 10, " group svg icons")) *
          props.width) /
          1700;

      // console.log("font size: ", size);

      filteredFocusPoints = utils.filterFocusGridIsUsed(
        filteredFocusPoints,
        false
      );

      const point = utils.pickGridPointInFilteredGrid(
        filteredFocusPoints,
        parseInt(utils.generateValue(2, pos1X * i * 20, " group svg icons"))
      );

      icons.push({
        key: i,
        x: point.x,
        y: point.y,
        color1: utils.selectPalette(props.palette)[`accent${i + 1}`],
        color2: utils.selectPalette(props.palette)[`accent${i + 2}`],

        size: size,
        svgIcon: selectSvgIcon(
          parseInt(utils.generateValue(2, pos1Y * i * 30, " group svg icons")) *
            2
        ),
      });
    }
  }

  const iconItems = icons.map((icon) => (
    <LayerSvgIcon
      key={icon.key}
      id={icon.key}
      color1={icon.color1 ? icon.color1 : "red"}
      color2={icon.color2 ? icon.color2 : "cream"}
      size={icon.size}
      x={icon.x}
      y={icon.y}
      svgIcon={icon.svgIcon}
    />
  ));

  return <div className="svgLayer">{iconItems}</div>;
};

export default GroupSvgIcons;
