import { useState } from "react";
import { useStore } from "./ArtworkPropertyStore";
import * as utils from "./Utilities";

interface ArtworkContainerProps {
  // seed: string;
}

const PropertyMaster = (props: ArtworkContainerProps) => {
  // const processedSeed = props.seed;

  const processedSeed = useStore((state) => state.seed);
  console.log("processed seed: ", processedSeed);

  const paletteCode = processedSeed
    ? parseInt(processedSeed[0] + processedSeed[1])
    : 10;
  console.log("Palette: ", utils.selectPalette(paletteCode).name);

  const drawingCode = processedSeed
    ? processedSeed[2] + processedSeed[3]
    : "10";

  const position1Code = processedSeed
    ? processedSeed[4] + processedSeed[5]
    : "10";

  const position2Code = processedSeed
    ? processedSeed[6] + processedSeed[7]
    : "10";

  const backgroundCode = processedSeed
    ? processedSeed[8] + processedSeed[9]
    : "10";

  const textCode = parseInt(
    processedSeed ? processedSeed[10] + processedSeed[11] : "10"
  );

  useStore.setState({ paletteCode: paletteCode });
  useStore.setState({ drawingCode: drawingCode });
  useStore.setState({ backgroundCode: parseInt(backgroundCode) });
  useStore.setState({ position1Code: position1Code });
  useStore.setState({ position2Code: position2Code });
  useStore.setState({ textCode: textCode });

  useStore.setState({
    backgroundColor: utils.selectPalette(paletteCode).background,
  });
  useStore.setState({
    backgroundBaseColor: utils.selectPalette(paletteCode).backgroundBase,
  });
  useStore.setState({
    backgroundDrawingColor: utils.selectPalette(paletteCode).backgroundDrawing,
  });

  //=============================================================================

  const font = utils.selectFont(parseInt(position1Code));
  useStore.setState({ textFont: font });

  const fontWeight = utils.clamp(parseInt(position2Code) * 7, 300, 700);
  useStore.setState({ textFontWeight: fontWeight });
  //   console.log("font weight: ", fontWeight);

  const letterSpacing = 0;
  useStore.setState({ textLetterSpacing: letterSpacing });

  const themeArray = ["technology", "light"];
  const theme = utils.selectFromArray(backgroundCode, themeArray);
  useStore.setState({ theme: theme });

  const elementstyleArray = [
    "triangles",
    "stars-four",
    "pentagons",
    "squares",
    "triangles-stroke",
  ];
  const elementstyle = utils.selectFromArray(drawingCode, elementstyleArray);
  useStore.setState({ elementstyle: elementstyle });

  return <></>;
};

export default PropertyMaster;
