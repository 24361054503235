export const paletteArray = [
  {
    id: 0,
    name: "burgundy",
    drawingLineart: "red-dark",
    drawingFill: "red",
    drawingFill2: "blue",
    accent0: "green",
    accent1: "blue",
    accent2: "orange",
    accent3: "green",
    accent4: "purple",
    accent5: "cream-dark",
    accent6: "purple",
    accent7: "cream-dark",
    accent8: "green",
    accent9: "green",
    face: "cream",
    accentMasked0: "green",
    accentMasked1: "blue",
    accentMasked2: "orange",
    accentMasked3: "green",
    accentMasked4: "purple",
    accentMasked5: "cream-dark",
    accentMasked6: "purple",
    accentMasked7: "cream-dark",
    accentMasked8: "green",
    accentMasked9: "green",
    background: "red-dark2",
    backgroundBase: "black",
    backgroundDrawing: "red-dark",
    backgroundAccent: "orange-dark",
    textBackground1: "black",
    textColor1: "red",
    wordblob1: "blue",
    wordblob2: "cream-dark",
    wordblob3: "green",
    wordblob4: "blue",
    wordblob5: "cream-dark",
    wordblob6: "green",
    wordblob7: "blue",
    wordblob8: "cream-dark",
    wordblob9: "blue",
  },

  {
    id: 1,
    name: "reef",
    drawingLineart: "blue-dark",
    drawingFill: "blue",
    drawingFill2: "purple",

    accent0: "green",
    accent1: "red",
    accent2: "orange",
    accent3: "green",
    accent4: "purple",
    accent5: "cream-dark",
    accent6: "purple",
    accent7: "cream-dark",
    accent8: "green",
    accent9: "green",
    face: "cream",
    accentMasked0: "green",
    accentMasked1: "red",
    accentMasked2: "orange",
    accentMasked3: "green",
    accentMasked4: "purple",
    accentMasked5: "cream-dark",
    accentMasked6: "purple",
    accentMasked7: "cream-dark",
    accentMasked8: "green",
    accentMasked9: "green",
    background: "purple-dark2",
    backgroundBase: "black",
    backgroundDrawing: "purple-dark",

    backgroundAccent: "green-dark",
    textBackground1: "purple-dark",
    textColor1: "cream",
    wordblob1: "cream-dark",
    wordblob2: "green",
    wordblob3: "purple",
    wordblob4: "cream-dark",
    wordblob5: "orange",
    wordblob6: "green",
    wordblob7: "cream-dark",
    wordblob8: "cream-dark",
    wordblob9: "cream-dark",
  },

  {
    id: 2,
    name: "nocturnal",
    drawingLineart: "purple-dark",
    drawingFill: "cream-dark",
    drawingFill2: "blue",

    accent0: "green",
    accent1: "red",
    accent2: "orange",
    accent3: "green",
    accent4: "purple",
    accent5: "red",
    accent6: "purple",
    accent7: "orange",
    accent8: "green",
    accent9: "green",
    face: "cream",
    accentMasked0: "green",
    accentMasked1: "red",
    accentMasked2: "orange",
    accentMasked3: "green",
    accentMasked4: "purple",
    accentMasked5: "red",
    accentMasked6: "purple",
    accentMasked7: "orange",
    accentMasked8: "green",
    accentMasked9: "green",
    background: "purple-dark",
    backgroundBase: "purple-dark2",
    backgroundDrawing: "purple",

    backgroundAccent: "red",
    textBackground1: "cream",
    textColor1: "black",
    wordblob1: "green",
    wordblob2: "cream-dark",
    wordblob3: "red",
    wordblob4: "orange",
    wordblob5: "purple",
    wordblob6: "cream-dark",
    wordblob7: "red",
    wordblob8: "green",
    wordblob9: "cream-dark",
  },

  {
    id: 3,
    name: "oceania",
    drawingLineart: "purple-dark2",
    drawingFill: "green",
    drawingFill2: "blue",

    accent0: "orange",
    accent1: "cream-dark",
    accent2: "cream-dark",
    accent3: "orange",
    accent4: "purple",
    accent5: "cream-dark",
    accent6: "purple",
    accent7: "cream-dark",
    accent8: "blue",
    accent9: "green",
    face: "cream",
    accentMasked0: "orange",
    accentMasked1: "cream-dark",
    accentMasked2: "cream-dark",
    accentMasked3: "orange",
    accentMasked4: "purple",
    accentMasked5: "cream-dark",
    accentMasked6: "purple",
    accentMasked7: "cream-dark",
    accentMasked8: "blue",
    accentMasked9: "green",
    background: "blue-dark",
    backgroundBase: "blue-dark2",
    backgroundDrawing: "blue",

    backgroundAccent: "orange-dark",
    textBackground1: "purple",
    textColor1: "cream-dark",
    wordblob1: "cream-dark",
    wordblob2: "purple",
    wordblob3: "orange",
    wordblob4: "green",
    wordblob5: "cream-dark",
    wordblob6: "orange",
    wordblob7: "green",
    wordblob8: "cream-dark",
    wordblob9: "cream-dark",
  },

  {
    id: 4,
    name: "gilded",
    drawingLineart: "red-dark",
    drawingFill: "cream-dark",
    drawingFill2: "brown",

    accent0: "orange",
    accent1: "cream-dark",
    accent2: "cream-dark",
    accent3: "orange",
    accent4: "red",
    accent5: "cream-dark",
    accent6: "orange",
    accent7: "cream-dark",
    accent8: "red",
    accent9: "orange",
    face: "cream",
    accentMasked0: "orange",
    accentMasked1: "orange",
    accentMasked2: "orange",
    accentMasked3: "orange",
    accentMasked4: "red",
    accentMasked5: "orange",
    accentMasked6: "orange",
    accentMasked7: "cream",
    accentMasked8: "red",
    accentMasked9: "orange",
    background: "gray-dark2",
    backgroundBase: "black",
    backgroundDrawing: "gray-dark",

    backgroundAccent: "orange-dark",
    textBackground1: "red-dark",
    textColor1: "cream-dark",
    wordblob1: "cream-dark",
    wordblob2: "purple",
    wordblob3: "orange",
    wordblob4: "green",
    wordblob5: "cream-dark",
    wordblob6: "orange",
    wordblob7: "green",
    wordblob8: "cream-dark",
    wordblob9: "cream-dark",
  },

  {
    id: 5,
    name: "dahlia",
    drawingLineart: "purple-dark",
    drawingFill: "pink",
    drawingFill2: "purple",

    accent0: "orange",
    accent1: "cream-dark",
    accent2: "cream-dark",
    accent3: "orange",
    accent4: "red",
    accent5: "cream-dark",
    accent6: "orange",
    accent7: "cream-dark",
    accent8: "red",
    accent9: "orange",
    face: "cream",
    accentMasked0: "orange",
    accentMasked1: "orange",
    accentMasked2: "orange",
    accentMasked3: "orange",
    accentMasked4: "red",
    accentMasked5: "orange",
    accentMasked6: "orange",
    accentMasked7: "cream",
    accentMasked8: "red",
    accentMasked9: "orange",
    background: "pink-dark",
    backgroundBase: "pink-dark2",
    backgroundDrawing: "pink",
    backgroundAccent: "orange-dark",
    textBackground1: "purple-dark",
    textColor1: "cream-dark",
    wordblob1: "cream-dark",
    wordblob2: "purple",
    wordblob3: "orange",
    wordblob4: "green",
    wordblob5: "cream-dark",
    wordblob6: "orange",
    wordblob7: "green",
    wordblob8: "cream-dark",
    wordblob9: "cream-dark",
  },

  {
    id: 6,
    name: "autumn",
    drawingLineart: "red-dark2",
    drawingFill: "orange",
    drawingFill2: "gray",

    accent0: "red",
    accent1: "blue",
    accent2: "red",
    accent3: "blue",
    accent4: "red",
    accent5: "blue",
    accent6: "red",
    accent7: "blue",
    accent8: "red",
    accent9: "blue",
    face: "cream-dark",
    accentMasked0: "red",
    accentMasked1: "blue",
    accentMasked2: "cream-dark",
    accentMasked3: "red",
    accentMasked4: "blue",
    accentMasked5: "cream-dark",
    accentMasked6: "red",
    accentMasked7: "blue",
    accentMasked8: "cream-dark",
    accentMasked9: "red",
    background: "orange-dark",
    backgroundBase: "orange-dark2",
    backgroundDrawing: "orange-dark",
    backgroundAccent: "orange",
    textBackground1: "gray-dark",
    textColor1: "cream-dark",
    wordblob1: "cream-dark",
    wordblob2: "orange",
    wordblob3: "red",
    wordblob4: "cream-dark",
    wordblob5: "orange",
    wordblob6: "red",
    wordblob7: "orange",
    wordblob8: "cream-dark",
    wordblob9: "cream-dark",
  },

  {
    id: 7,
    name: "festival",
    drawingLineart: "red-dark2",
    drawingFill: "blue",
    drawingFill2: "red",

    accent0: "red",
    accent1: "blue",
    accent2: "orange",
    accent3: "blue",
    accent4: "red",
    accent5: "blue",
    accent6: "red",
    accent7: "orange",
    accent8: "red",
    accent9: "blue",
    face: "cream-dark",
    accentMasked0: "orange",
    accentMasked1: "cream-dark",
    accentMasked2: "orange",
    accentMasked3: "cream-dark",
    accentMasked4: "orange",
    accentMasked5: "cream-dark",
    accentMasked6: "orange",
    accentMasked7: "cream-dark",
    accentMasked8: "orange",
    accentMasked9: "cream-dark",
    background: "cream-dark",
    backgroundBase: "cream",
    backgroundDrawing: "orange",
    backgroundAccent: "orange",
    textBackground1: "blue-dark2",
    textColor1: "cream",
    wordblob1: "cream-dark",
    wordblob2: "orange",
    wordblob3: "blue",
    wordblob4: "cream-dark",
    wordblob5: "orange",
    wordblob6: "red",
    wordblob7: "orange",
    wordblob8: "blue",
    wordblob9: "cream-dark",
  },

  {
    id: 8,
    name: "galvanized",
    drawingLineart: "purple-dark2",
    drawingFill: "orange",
    drawingFill2: "blue",

    accent0: "cream",
    accent1: "gray",
    accent2: "cream",
    accent3: "gray",
    accent4: "cream",
    accent5: "gray",
    accent6: "cream",
    accent7: "gray",
    accent8: "cream",
    accent9: "gray",
    face: "cream-dark",
    accentMasked0: "gray",
    accentMasked1: "cream-dark",
    accentMasked2: "gray",
    accentMasked3: "cream-dark",
    accentMasked4: "gray",
    accentMasked5: "cream-dark",
    accentMasked6: "gray",
    accentMasked7: "cream-dark",
    accentMasked8: "gray",
    accentMasked9: "cream-dark",
    background: "black",
    backgroundBase: "gray-dark",
    backgroundDrawing: "gray",
    backgroundAccent: "orange",
    textBackground1: "cream",
    textColor1: "gray-dark",
    wordblob1: "cream-dark",
    wordblob2: "orange",
    wordblob3: "gray",
    wordblob4: "cream-dark",
    wordblob5: "orange",
    wordblob6: "gray",
    wordblob7: "orange",
    wordblob8: "gray",
    wordblob9: "cream-dark",
  },

  {
    id: 9,
    name: "nightlife",
    drawingLineart: "pink",
    drawingFill: "purple",
    drawingFill2: "purple-dark",

    accent0: "red",
    accent1: "purple",
    accent2: "red",
    accent3: "purple",
    accent4: "red",
    accent5: "purple",
    accent6: "red",
    accent7: "purple",
    accent8: "red",
    accent9: "purple",
    face: "cream-dark",
    accentMasked0: "purple-dark2",
    accentMasked1: "orange-dark",
    accentMasked2: "red-dark",
    accentMasked3: "purple-dark2",
    accentMasked4: "orange-dark",
    accentMasked5: "red-dark",
    accentMasked6: "purple-dark2",
    accentMasked7: "orange-dark",
    accentMasked8: "red-dark",
    accentMasked9: "purple-dark2",
    background: "cream-dark",
    backgroundBase: "pink",
    backgroundDrawing: "pink-dark",
    backgroundAccent: "red",
    textBackground1: "cream",
    textColor1: "purple",
    wordblob1: "purple",
    wordblob2: "red",
    wordblob3: "pink",
    wordblob4: "purple",
    wordblob5: "red",
    wordblob6: "pink",
    wordblob7: "purple",
    wordblob8: "red",
    wordblob9: "pink",
  },

  {
    id: 10,
    name: "firering",
    drawingLineart: "orange",
    drawingFill: "orange-dark",
    drawingFill2: "red",

    accent0: "red",
    accent1: "orange",
    accent2: "red",
    accent3: "orange",
    accent4: "red",
    accent5: "orange",
    accent6: "red",
    accent7: "orange",
    accent8: "red",
    accent9: "orange",
    face: "cream-dark",
    accentMasked0: "blue-dark",
    accentMasked1: "gray",
    accentMasked2: "red-dark",
    accentMasked3: "blue-dark",
    accentMasked4: "gray",
    accentMasked5: "red-dark",
    accentMasked6: "blue-dark",
    accentMasked7: "gray",
    accentMasked8: "red-dark",
    accentMasked9: "blue",
    background: "blue",
    backgroundBase: "blue-dark",
    backgroundDrawing: "blue-dark2",
    backgroundAccent: "cream-dark",
    textBackground1: "blue-dark",
    textColor1: "cream",
    wordblob1: "cream-dark",
    wordblob2: "red",
    wordblob3: "orange",
    wordblob4: "cream-dark",
    wordblob5: "red",
    wordblob6: "orange",
    wordblob7: "cream-dark",
    wordblob8: "red",
    wordblob9: "orange",
  },

  {
    id: 11,
    name: "matsuri",
    drawingLineart: "pink",
    drawingFill: "red",
    drawingFill2: "green",

    accent0: "green",
    accent1: "orange",
    accent2: "green",
    accent3: "orange",
    accent4: "blue",
    accent5: "orange",
    accent6: "green",
    accent7: "orange",
    accent8: "green",
    accent9: "blue",
    face: "cream",
    accentMasked0: "orange",
    accentMasked1: "blue",
    accentMasked2: "cream-dark",
    accentMasked3: "orange",
    accentMasked4: "blue",
    accentMasked5: "cream-dark",
    accentMasked6: "orange",
    accentMasked7: "blue",
    accentMasked8: "cream-dark",
    accentMasked9: "blue",
    background: "cream-dark",
    backgroundBase: "cream",
    backgroundDrawing: "brown",
    backgroundAccent: "orange",
    textBackground1: "cream",
    textColor1: "red",
    wordblob1: "orange",
    wordblob2: "cream-dark",
    wordblob3: "blue",
    wordblob4: "orange",
    wordblob5: "cream-dark",
    wordblob6: "blue",
    wordblob7: "orange",
    wordblob8: "cream-dark",
    wordblob9: "blue",
  },

  {
    id: 12,
    name: "twilight",
    drawingLineart: "pink-dark",
    drawingFill: "pink",
    drawingFill2: "blue",

    accent0: "pink-dark",
    accent1: "orange",
    accent2: "pink-dark",
    accent3: "orange",
    accent4: "pink-dark",
    accent5: "orange",
    accent6: "pink-dark",
    accent7: "orange",
    accent8: "pink-dark",
    accent9: "orange",
    face: "cream",
    accentMasked0: "orange",
    accentMasked1: "pink-dark",
    accentMasked2: "cream-dark",
    accentMasked3: "orange",
    accentMasked4: "pink-dark",
    accentMasked5: "cream-dark",
    accentMasked6: "orange",
    accentMasked7: "pink-dark",
    accentMasked8: "cream-dark",
    accentMasked9: "orange",
    background: "black",
    backgroundBase: "blue-dark2",
    backgroundDrawing: "blue-dark",
    backgroundAccent: "blue",
    textBackground1: "blue-dark",
    textColor1: "cream-dark",
    wordblob1: "orange",
    wordblob2: "cream-dark",
    wordblob3: "blue",
    wordblob4: "orange",
    wordblob5: "cream-dark",
    wordblob6: "blue",
    wordblob7: "orange",
    wordblob8: "cream-dark",
    wordblob9: "blue",
  },

  {
    id: 13,
    name: "cardinal",
    drawingLineart: "red-dark",
    drawingFill: "brown",
    drawingFill2: "orange",

    accent0: "red",
    accent1: "orange",
    accent2: "gray",
    accent3: "red",
    accent4: "orange",
    accent5: "gray",
    accent6: "red",
    accent7: "orange",
    accent8: "gray",
    accent9: "orange",
    face: "cream-dark",
    accentMasked0: "red",
    accentMasked1: "cream-dark",
    accentMasked2: "gray",
    accentMasked3: "red",
    accentMasked4: "cream-dark",
    accentMasked5: "gray",
    accentMasked6: "red",
    accentMasked7: "pink-dark",
    accentMasked8: "cream-dark",
    accentMasked9: "gray",
    background: "red-dark2",
    backgroundBase: "red-dark",
    backgroundDrawing: "brown",
    backgroundAccent: "red",
    textBackground1: "red-dark",
    textColor1: "cream-dark",
    wordblob1: "red",
    wordblob2: "cream-dark",
    wordblob3: "gray",
    wordblob4: "red",
    wordblob5: "cream-dark",
    wordblob6: "gray",
    wordblob7: "red",
    wordblob8: "cream-dark",
    wordblob9: "gray",
  },

  {
    id: 14,
    name: "daybreak",
    drawingLineart: "blue-dark",
    drawingFill: "blue",
    drawingFill2: "green",

    accent0: "blue-dark",
    accent1: "green-dark",
    accent2: "cream-dark",
    accent3: "blue-dark",
    accent4: "green-dark",
    accent5: "cream-dark",
    accent6: "blue-dark",
    accent7: "green-dark",
    accent8: "blue-dark",
    accent9: "cream-dark",
    face: "cream",
    accentMasked0: "purple",
    accentMasked1: "cream-dark",
    accentMasked2: "blue-dark",
    accentMasked3: "green-dark",
    accentMasked4: "purple",
    accentMasked5: "cream-dark",
    accentMasked6: "blue-dark",
    accentMasked7: "green-dark",
    accentMasked8: "purple",
    accentMasked9: "cream-dark",
    background: "black",
    backgroundBase: "pink-dark2",
    backgroundDrawing: "pink-dark",
    backgroundAccent: "pink",
    textBackground1: "pink-dark",
    textColor1: "cream-dark",
    wordblob1: "green",
    wordblob2: "cream-dark",
    wordblob3: "purple",
    wordblob4: "green",
    wordblob5: "cream-dark",
    wordblob6: "purple",
    wordblob7: "green",
    wordblob8: "cream-dark",
    wordblob9: "purple",
  },
];
