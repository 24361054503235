const drawingMan2Fill2 = () => {
  return (
    <g>
      <polygon points="396.91 985.75 362.33 971.5 369.03 1009.45 396.91 985.75" />
      <polygon points="482.39 1086.51 458.66 1104.34 481.25 1110.46 482.39 1086.51" />
      <polygon points="610.49 1001.78 627.52 1017.85 639.82 997.27 610.49 1001.78" />
      <polygon points="466.48 940.6 459.4 1009.45 531.7 980.26 466.48 940.6" />
      <polygon points="256.03 869.1 250.72 897.31 285.46 874.01 256.03 869.1" />
      <path d="M600,1387.56c-2.16-4.66-4.15-9.4-6.39-14-1.64-3.39-1.93-7-2.63-10.63-1-5.21-1.83-10.46-2.69-15.7a46.58,46.58,0,0,1-.77-6,18.39,18.39,0,0,0-1.51-6.94c-4.76-10.6-7.72-21.75-10.31-33a34.28,34.28,0,0,0-3.63-9.16q-8.52-15.09-17-30.19a50.13,50.13,0,0,1-4.3-10.08c-1.82-6-4-11.81-5.83-17.76a29,29,0,0,0-4.19-8.29c-9.28-13-18.59-25.94-27.78-39Q504,1174,495.27,1161c-6.42-9.61-12.81-19.24-19.8-28.45l-24.91-17.46-18.32-12.62-11.94-12.12-14.84-9.73-18.06-13.52-13.83-9.2-4.36-11.2-6.82-13.79-11.56-19.48-12-18.83-17.31-20.45L302,953.05l-18.49-17L265,921.81l-20.38-9.47-14.11-4.05-12.5-2-12.63,1.39-14.13,2.74a25.56,25.56,0,0,1-5.63,2.32c-1.06.29-2.08.75-3.14,1a17.13,17.13,0,0,0-6.71,3.65c-1.27,1.05-2.67,2-3.87,3.08-3.72,3.53-8.44,5.08-13.12,6.67a23.82,23.82,0,0,1-8.13,1.19c-5.08-.11-9.69,1.73-14.36,3.31-.35.12-.66.32-1,.45a48.12,48.12,0,0,0-17.36,11.28c-2.59,2.59-5.46,4.9-8.16,7.38-8.82,8.13-19,14.37-29.07,20.74-13.47,8.54-27.1,16.84-40.6,25.34-3.72,2.34-7.24,5-10.85,7.52A205.48,205.48,0,0,0,6,1022.78a25.29,25.29,0,0,1-3.92,3c-.52.33-1.38.12-2.09.15v389.72H611.1c-.45-1.58-.8-3.2-1.38-4.73C606.76,1403,603.59,1395.21,600,1387.56ZM51.26,1065.26,71,1007.93l35.21,46Zm153.11-28.66L117.21,981l101.84-44Zm207.36,142.48-26.6-46.15,57-1.37Z" />
    </g>
  );
};

export default drawingMan2Fill2;
