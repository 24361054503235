const drawingMan7Fill2 = () => {
  return (
    <g>
      <path d="M1001,809.5c-9.46-2.33-18.92-4.68-28.38-7-8.82-2.16-17.65-4.26-26.48-6.4-.8-.2-1.59-.49-2.72-.84a22.06,22.06,0,0,1,1.88-1.54c12.48-8.08,25-16.12,37.43-24.25a5.11,5.11,0,0,1,4.55-.55c4.56,1.22,9.14,2.4,13.72,3.6Z" />
      <path d="M657.32,1044.12c-1,.73-1.56,1.19-2.18,1.61q-29.8,20.36-59.58,40.75a4.76,4.76,0,0,1-5.42.38q-31.67-14.77-63.38-29.43c-.68-.32-1.34-.68-2-1-.62-1.88.52-2.65,1.79-3.44q11.58-7.21,23.12-14.48c12-7.55,24.1-15,36.1-22.67a4.88,4.88,0,0,1,5.19-.49q31.92,13.86,63.91,27.54C655.6,1043.2,656.25,1043.57,657.32,1044.12Z" />
      <path d="M708,947.28c0-2,1.32-2.43,2.31-3.07q11.34-7.31,22.71-14.55c8.54-5.47,17.1-10.88,25.57-16.43a5.65,5.65,0,0,1,5.51-.73c20.93,7.18,41.9,14.25,62.85,21.4,1,.34,2.37.92,2.66,1.71.51,1.42-1.09,2-2.05,2.68q-23.37,16.4-46.72,32.81a4.28,4.28,0,0,1-4.42.54q-32.49-11.57-65-23C710.23,948.22,709.11,947.74,708,947.28Z" />
      <path d="M513.54,963.07c1.23-.86,2-1.41,2.73-1.9,16.77-10.49,33.57-20.93,50.29-31.51a5.74,5.74,0,0,1,6-.42c19.32,8,38.69,15.86,58,23.77.87.36,1.71.75,2.89,1.27a22.81,22.81,0,0,1-1.9,1.51q-26.33,16.28-52.65,32.56a4.32,4.32,0,0,1-4.45.44c-19.45-8.16-38.94-16.23-58.41-24.35C515.38,964.16,514.77,963.74,513.54,963.07Z" />
      <path d="M439.81,971.21c-.19,2-1.57,2.69-2.88,3.44l-26.12,15c-10,5.74-20.09,11.41-30,17.26a5.33,5.33,0,0,1-5.74.23c-16.39-8.06-32.83-16-49.26-24-.73-.36-1.4-.84-2.5-1.51,1.1-.72,1.82-1.26,2.61-1.7Q355,963.83,384,947.75a3.91,3.91,0,0,1,3.94-.07q24.82,11.24,49.69,22.39C438.42,970.41,439.13,970.85,439.81,971.21Z" />
      <path d="M720.27,898.82c-4.68,2.94-8.75,5.51-12.84,8.06C694.5,915,681.54,923,668.64,931.12a4.93,4.93,0,0,1-5,.55c-18.65-7.13-37.35-14.15-56-21.21-.26-.1-.49-.27-1-.54.66-.5,1.17-1,1.76-1.33,15.71-9.7,31.45-19.37,47.12-29.14a5.82,5.82,0,0,1,5.51-.57c18.47,6.23,37,12.35,55.47,18.51C717.56,897.74,718.59,898.18,720.27,898.82Z" />
      <path d="M348.41,949.11c-2.7,1.75-4.8,3.35-7.11,4.58Q319.39,965.41,297.39,977c-3.1,1.64-6.21,3.25-9.28,4.94a3.36,3.36,0,0,1-3.46.08q-22.36-11.14-44.77-22.24a13.37,13.37,0,0,1-1.52-1.08c.86-.53,1.46-1,2.1-1.29,17.93-9.65,35.91-19.23,53.76-29a8.46,8.46,0,0,1,8.29-.22c4.65,2.12,9.32,4.21,14,6.31L346.24,948C346.74,948.2,347.22,948.48,348.41,949.11Z" />
      <path d="M534.23,917.42c-1.11.84-1.71,1.39-2.4,1.8q-25.4,15.33-50.8,30.65a3.67,3.67,0,0,1-3.7.2q-26.49-10.94-53-21.8c-.68-.28-1.32-.64-2.26-1.1a21.25,21.25,0,0,1,1.77-1.21q26.13-14.06,52.25-28.09c1.83-1,3.47-1.48,5.61-.66,16.59,6.4,33.23,12.67,49.84,19C532.23,916.47,532.89,916.81,534.23,917.42Z" />
      <path d="M620.75,985.87a24.36,24.36,0,0,1,2.14-1.65c14.81-9.15,29.67-18.24,44.45-27.44,2.54-1.58,4.9-1.06,7.31-.17,4.17,1.55,8.28,3.24,12.42,4.87L723.76,976c.44.18.89.31,1.32.51,2.17,1,2.24,1.94.27,3.42-.37.29-.78.53-1.17.8-13.86,9.24-27.74,18.45-41.56,27.76a5.19,5.19,0,0,1-5.45.63c-17.75-7.07-35.55-14-53.33-21-1-.4-2-.91-3-1.38Z" />
      <path d="M862.46,828.71a24.65,24.65,0,0,1,1.89-1.59q20.05-13.18,40.12-26.38a4.68,4.68,0,0,1,4.23-.62q32.07,9.09,64.18,18.1a10.33,10.33,0,0,1,1.08.33c1.49.62,1.67,1.45.5,2.5a15.74,15.74,0,0,1-1.62,1.15c-12.46,8.45-25,16.86-37.36,25.39a6.44,6.44,0,0,1-6.06,1C908,842.22,886.57,836,865.15,829.72,864.36,829.49,863.6,829.14,862.46,828.71Z" />
      <path d="M783.42,836.16a36.37,36.37,0,0,1-3.17,2.74c-14,9.08-28,18.1-42,27.21a4.36,4.36,0,0,1-4.22.51q-29-9.93-58-19.8c-.68-.23-1.33-.57-2.31-1,.84-.63,1.38-1.11,2-1.49q22.15-13.89,44.29-27.78a4.45,4.45,0,0,1,4.22-.57q28.21,9.6,56.47,19.09C781.4,835.31,782.08,835.62,783.42,836.16Z" />
      <path d="M514,876.13l11.69-6.35c12.38-6.73,24.79-13.42,37.14-20.21a6.24,6.24,0,0,1,5.61-.47c16.14,5.69,32.32,11.27,48.48,16.88a18.1,18.1,0,0,1,2.39.91,1.92,1.92,0,0,1,.27,3.41,12.56,12.56,0,0,1-1.47.86q-22.8,12.72-45.58,25.48a5.25,5.25,0,0,1-4.79.41c-10.61-3.72-21.33-7.16-31.88-11.07-7.42-2.75-14.61-6.1-21.91-9.19Z" />
      <path d="M265.08,862.57c1.39-.82,2.09-1.28,2.83-1.67q24.49-12.9,49-25.8a4.62,4.62,0,0,1,4.27-.37c14.55,5.91,29.12,11.75,43.68,17.63a10.76,10.76,0,0,1,1.35.79,31.67,31.67,0,0,1-2.7,1.78q-25.32,13.16-50.64,26.29a4.52,4.52,0,0,1-4.27.11c-13.48-5.78-27-11.49-40.47-17.24C267.27,863.73,266.48,863.28,265.08,862.57Z" />
      <path d="M840.55,769.27c1.11-.86,1.72-1.4,2.39-1.84,11.2-7.27,22.42-14.49,33.59-21.8a10.77,10.77,0,0,1,5.59-1.57c6.3-.39,12.15,1.48,18.06,3.14q21.3,6,42.6,11.94c.53.15,1,.37,1.86.67-.82.58-1.39,1-2,1.41-11.77,7.42-23.56,14.8-35.3,22.27a6.56,6.56,0,0,1-5.58.91c-19.19-4.71-38.4-9.3-57.6-13.94C843.11,770.21,842.11,769.79,840.55,769.27Z" />
      <path d="M115.55,867.14c1.34-.78,2-1.25,2.76-1.59q26.12-12.24,52.22-24.46a4.84,4.84,0,0,1,4.06-.12q18.93,6.91,37.91,13.72a10.8,10.8,0,0,1,1.57.65,1.7,1.7,0,0,1,.11,3.14,16.41,16.41,0,0,1-1.76.93q-26.29,13.17-52.56,26.38a5,5,0,0,1-5,0C143,880.11,131.05,874.6,119.12,869,118.12,868.57,117.16,868,115.55,867.14Z" />
      <path d="M291.36,825.27c-1.31.73-2,1.17-2.78,1.55-16,8-32,15.9-48,23.95a7.43,7.43,0,0,1-6.7.31c-11.52-4.55-23.1-8.94-34.67-13.38-1.42-.55-2.89-1-3-3.21a22.75,22.75,0,0,1,2-1.29q21-11,41.94-22.09a4.57,4.57,0,0,1,4.29-.27,69.31,69.31,0,0,0,6.87,2.69c3.87,1.21,7.8,2.23,11.71,3.31a2.23,2.23,0,0,0,1.38.11c2.27-1,4.25,0,6.29.75l18.16,6.5C289.48,824.45,290.14,824.76,291.36,825.27Z" />
      <path d="M41.49,850.13c2.31-1.1,4.16-2,6-2.87,15.15-6.93,30.34-13.79,45.44-20.83a8.54,8.54,0,0,1,7.51-.16c10.17,4.13,20.43,8,30.64,12.06,1,.42,2.59.41,2.65,2.13s-1.39,2.1-2.54,2.66L80.69,867.8a4,4,0,0,1-4,.24c-11.23-5.7-22.51-11.3-33.78-16.94A16.08,16.08,0,0,1,41.49,850.13Z" />
      <path d="M929.16,691.52c.19,2-1.16,2.35-2.17,2.94q-10.77,6.33-21.58,12.61c-3.93,2.29-7.87,4.56-11.77,6.88a4.61,4.61,0,0,1-3.74.61q-24-5.55-48.13-10.92c-1.7-.38-2.71-1.07-3-2.81-.39-2-.23-2.66,1.62-3.73,4.5-2.61,9-5.17,13.57-7.73,4.94-2.8,10-5.49,14.81-8.42a6.9,6.9,0,0,1,5.37-.82q18.33,3.81,36.68,7.53c5.28,1.08,10.56,2.14,15.84,3.23C927.44,691.06,928.25,691.29,929.16,691.52Z" />
      <path d="M795.37,804.36c1.19-.85,1.85-1.37,2.55-1.81,9.81-6.26,19.64-12.49,29.42-18.78a5.58,5.58,0,0,1,5-.86c15.27,4.32,30.58,8.5,45.87,12.73.8.22,1.56.56,2.32.83a3.34,3.34,0,0,1-2,3c-10.39,6.2-20.81,12.37-31.17,18.63-1.36.82-2.52.46-3.78.1l-29.15-8.38Z" />
      <path d="M809.49,872.6c2.74-1.92,5-3.5,7.21-5.06,7.38-5.14,14.78-10.24,22.1-15.45a4.73,4.73,0,0,1,4.43-.85c13.64,3.86,27.31,7.61,40.95,11.46,1.15.32,2.79.16,3,2s-.88,2.52-2.11,3.34Q871.77,877,858.5,886a4.37,4.37,0,0,1-4.19.68c-14.07-4.42-28.17-8.72-42.25-13.07C811.36,873.38,810.69,873.07,809.49,872.6Z" />
      <path d="M267.61,909.9c-.93.59-1.61,1.07-2.33,1.47q-20.73,11.5-41.45,23a3.68,3.68,0,0,1-4.15.05c-9.5-5.51-19.05-10.92-28.58-16.38-.39-.22-.74-.5-1.31-.9.78-.44,1.32-.79,1.9-1.08,12.74-6.47,25.5-12.89,38.21-19.42a6.21,6.21,0,0,1,5.86-.22c9.83,4.22,19.72,8.26,29.59,12.38C266,909.1,266.68,909.45,267.61,909.9Z" />
      <path d="M704.71,1043.54c.79-.57,1.29-1,1.84-1.32,10.19-6.38,20.39-12.72,30.55-19.14a4.07,4.07,0,0,1,4.42-.39c9.81,4.48,19.69,8.8,29.54,13.18a6.81,6.81,0,0,1,.78.34c.87.49,2,.66,2.09,2a3,3,0,0,1-1.87,2.91c-10.6,6.25-21.21,12.47-31.78,18.76-1.43.86-2.49.25-3.67-.35l-27.68-13.83C707.6,1045.06,706.28,1044.35,704.71,1043.54Z" />
      <path d="M460.4,1013.85l11.94-7c7.42-4.36,14.87-8.69,22.24-13.14a4.56,4.56,0,0,1,4.77-.22c9.43,4.4,18.91,8.71,28.36,13.06,1,.45,1.91,1,3.27,1.76-1.07.8-1.69,1.33-2.38,1.76-10.39,6.42-20.79,12.8-31.16,19.24a4.17,4.17,0,0,1-4.46.44c-10.22-5-20.49-9.8-30.74-14.69C461.76,1014.8,461.33,1014.45,460.4,1013.85Z" />
      <path d="M166.79,800.83c.92-.54,1.46-.9,2-1.19,11.85-5.95,23.72-11.87,35.54-17.87a5,5,0,0,1,4.8-.23c1.89.88,3.91,1.45,5.79,2.34a5.36,5.36,0,0,1,2.32,1.9c2.47,4.12,4.8,8.33,7.35,12.8-.72.47-1.52,1.08-2.4,1.56-6.87,3.82-13.76,7.6-20.63,11.43a4.12,4.12,0,0,1-3.71.56c-10-3.63-20-7.16-29.94-10.72C167.7,801.32,167.46,801.17,166.79,800.83Z" />
      <path d="M199.16,959.82c-.77.54-1.2.89-1.67,1.16q-14.36,8.32-28.73,16.66c-1.26.73-2.34,1.22-3.92.41-7.83-4-15.74-7.87-23.61-11.79a8.09,8.09,0,0,1-.92-.65,16.16,16.16,0,0,1,1.74-1.31q13.72-7.74,27.45-15.46c1.45-.82,2.78-1.4,4.52-.6,7.9,3.63,15.85,7.17,23.78,10.75A14.22,14.22,0,0,1,199.16,959.82Z" />
      <path d="M369.78,899.13l-11.84,6.59c-6.54,3.62-13.11,7.2-19.61,10.89a4.29,4.29,0,0,1-4.69.15c-6.52-3.47-13.14-6.75-19.71-10.12-.9-.46-1.78-1-2.95-1.6a23.07,23.07,0,0,1,2.07-1.51c9.06-5.14,18.16-10.22,27.18-15.42a5.13,5.13,0,0,1,5.3-.23c7.19,3.39,14.45,6.63,21.69,9.93C367.89,898.12,368.54,898.49,369.78,899.13Z" />
      <path d="M974.3,722.9c-.91.74-1.49,1.31-2.16,1.75-5.69,3.74-11.41,7.44-17.09,11.21a5,5,0,0,1-4.23.8C939,734,927.17,731.55,915.34,729c-.55-.12-1.07-.35-2-.65a23.2,23.2,0,0,1,1.82-1.46c5.92-3.74,11.9-7.39,17.75-11.24a5.92,5.92,0,0,1,4.82-.84q14.15,3,28.31,5.91c2.13.45,4.26.88,6.38,1.37A12.38,12.38,0,0,1,974.3,722.9Z" />
      <path d="M986.59,872.13,982.46,875c-5.39,3.7-10.79,7.38-16.15,11.12a4.6,4.6,0,0,1-4,.91q-17.13-4-34.27-8c-.36-.09-.7-.25-1.34-.49.67-.54,1.17-1,1.74-1.41,5.12-3.41,10.31-6.74,15.36-10.25a8.18,8.18,0,0,1,6.81-1.55c11.51,2.28,23.07,4.26,34.6,6.35C985.51,871.72,985.76,871.84,986.59,872.13Z" />
      <path d="M129.36,773c.8-.47,1.47-.93,2.19-1.28,6.82-3.25,13.66-6.46,20.45-9.76a5.3,5.3,0,0,1,4.58-.21c4.22,1.65,8.51,3.12,12.76,4.68.84.32,1.65.73,3,1.33a20,20,0,0,1-2.7,2.17c-6.6,3.47-13.24,6.87-19.86,10.31a4.12,4.12,0,0,1-3.78.36c-5.28-2.29-10.59-4.53-15.88-6.8C129.87,773.7,129.73,773.41,129.36,773Z" />
      <path d="M810.84,685.31c1.43-2.93,2.87-5.86,4.44-9l5.42,1.65-9.35,7.86Z" />
    </g>
  );
};

export default drawingMan7Fill2;
