/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import "../Styles/Artwork.css";
import * as utils from "./Utilities";
import * as shapeUtils from "./ShapeUtilities";
import * as shape2d from "./Resources/Shapes2D";
import { useStore } from "./ArtworkPropertyStore";
import { FocusGridType } from "./Resources/Types";
import { generateElementShape } from "./ShapeUtilities";

interface ArtworkCanvasProps {
  key: number;
  elemkey: number;
  id?: string;
  color: string;
  color2: string;
  element?: string;
  size: number;
  position1: number;
  position2: number;
  intensity?: number;
}

const LayerCanvas = (directProps: ArtworkCanvasProps) => {
  const props = utils.getProps();

  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const canvasRef = useRef(null);

  const fillcolor = utils.colorToHex(directProps.color);
  const strokecolor = utils.colorToHex(directProps.color2);

  const shapeSize = directProps.size ? directProps.size : 12;
  const initialRotation = directProps.position1 + directProps.position2;
  const intensity = directProps.intensity ? directProps.intensity * 0.5 : 0.03;

  const chooseFill = (ctx: CanvasRenderingContext2D) => {
    ctx.fillStyle = fillcolor;
    ctx.fill();
  };

  const chooseStroke = (ctx: CanvasRenderingContext2D) => {
    if (props.elementstyle.includes("stroke")) {
      ctx.strokeStyle = strokecolor;
      ctx.lineWidth = 10;
      ctx.stroke();
    }
  };

  let filteredFocusPoints: FocusGridType;

  let initialPosX = props.width / 2;
  let initialPosY = props.height / 2;

  const animationSeed = utils.animationSeed[directProps.elemkey];

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    const animatedShapeProps = shapeUtils.processAnimatedShapeProps({
      animationLayer: props.animationLayer1,
      animationSeed: animationSeed,
      x: initialPosX,
      y: initialPosY,
      rotation: initialRotation,
      frameCount: frameCount,
      intensity: intensity,
      width: props.width,
      size: directProps.size,
    });

    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.globalCompositeOperation = "source-over";

    generateElementShape({
      ctx: ctx,
      elementStyle: props.elementstyle,
      shapeRotation: animatedShapeProps.shapeRotation,
      initialPosX: animatedShapeProps.shapeX,
      initialPosY: animatedShapeProps.shapeY,
      width: props.width,
      height: props.height,
      shapeSize: animatedShapeProps.size,
      animation: props.animationLayer2,
      frameCount: frameCount,
      intensity: intensity,
      depth: 1,
      animationSeed: utils.animationSeed,
    });

    chooseFill(ctx);
    chooseStroke(ctx);

    // console.log("drawing at: ", initialPosX, initialPosY);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let animationFrameId = 0;

    const framectElement = document.getElementById("framect");

    //Drawing happens here
    const render = () => {
      if (framectElement) {
        draw(thisContext, parseFloat(framectElement?.innerText));
      }

      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  useEffect(() => {
    if (props.isFocusGridDrawn) {
      filteredFocusPoints = utils.filterFocusGridValue(
        props.focusGrid,
        "value",
        64,
        "lessThan"
      );

      if (props.facePosition === "left") {
        filteredFocusPoints = filteredFocusPoints.reverse();
      }

      const point = utils.pickGridPoint(
        filteredFocusPoints,
        directProps.position1,
        directProps.position2
      );

      // const point = utils.pickGridPointInFilteredGrid(
      //   filteredFocusPoints,
      //   directProps.position1
      // );

      initialPosX = point.x;
      initialPosY = point.y;
    }
  }, [draw]);

  return (
    <div className={"canvas"}>
      <canvas
        id={`canvas-${directProps.id}`}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export default LayerCanvas;
