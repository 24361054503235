/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import "../Styles/Artwork.css";
import * as utils from "./Utilities";
import * as shape2d from "./Resources/Shapes2D";
import { useStore } from "./ArtworkPropertyStore";

interface BackgroundAccentProps {
  id: string;
  element?: string;
  size?: number;
  intensity?: number;
  keynumber?: number;
}

const LayerBackgroundAccentCanvas = (directProps: BackgroundAccentProps) => {
  const props = utils.getProps();

  const backgroundColor = useStore((state) => state.backgroundColor);
  const backgroundBaseColor = useStore((state) => state.backgroundBaseColor);

  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const canvasRef = useRef(null);

  const color = utils.selectPalette(props.palette)[`${directProps.id}`];
  const fillcolor = utils.colorToHex(color);

  const shapeSize = directProps.size ? directProps.size : 40;
  const shapeRotation = props.position1 + props.position2;
  const intensity = directProps.intensity ? directProps.intensity : 0.01;

  let pos1 = props.position1;
  let pos2 = props.position2;

  let initialPosX = 0;
  let initialPosY = 0;

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.globalCompositeOperation = "source-over";

    shape2d.shapePolygon(
      ctx,
      50,
      shapeRotation,
      props.width / 2,
      props.height / 2,
      props.width,
      props.height,
      shapeSize,
      "wiggle",
      frameCount,
      intensity,
      0.2
    );

    ctx.lineWidth = props.width / 400;
    // ctx.lineDashOffset = 20;
    ctx.setLineDash([props.width / 200, props.width / 200]);
    ctx.strokeStyle = fillcolor;
    ctx.stroke();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let animationFrameId = 0;
    const framectElement = document.getElementById("framect");

    //Drawing happens here
    const render = () => {
      if (framectElement) {
        draw(thisContext, parseFloat(framectElement?.innerText));
      }

      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return (
    <div
      className={"canvas"}
      id={"backgroundAccent"}
      key={directProps.keynumber ? directProps.keynumber : 1}
    >
      <canvas
        id={"canvas-background-accent"}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export default LayerBackgroundAccentCanvas;
