export const textArray = [
  {
    theme: "technology",
    texts: [
      [
        "dotted line trajectories,",
        "infinite amounts of birds jam-pack the sky,",
        "voices decoded from radio waves.",
      ],
      [
        "on a screen somewhere,",
        "red thoughts and green and blue ones,",
        "the world stares back as we stare at it",
      ],
      [
        "in a plastic case, on a circuit board,",
        "in stray drops of paint on the ground,",
        "a moment soldered together",
      ],
      [
        "how much further is it so far away",
        "that the things you come up with are just",
        "copies of a copy,",
      ],
      [
        "low quality silence,",
        "emotions with no names, pieces of an",
        "indivisible whole,",
      ],
      [
        "encode and decode,",
        "write and write over,",
        "our time together shapes the both of us.",
      ],
    ],
  },
  {
    theme: "light",
    texts: [
      [
        "dotted line trajectories,",
        "infinite amounts of birds jam-pack the sky,",
        "endless automobiles,",
      ],
      [
        "sunlight on your skin,",
        "the glow of the face of an old friend,",
        "neither light nor heavy,",
      ],
    ],
  },
  {
    theme: "darkness",
    texts: [
      [
        "you're either in it or looking at it,",
        "either in the process of being blown away",
        "atoms in the body of the night.",
      ],
      [
        "the night, a gift in a box given to us",
        "for standing up to incessant daylight,",
        "and gazes that push against our skin.",
      ],
      [
        "sometimes days or nights are given to you",
        "to replenish whichever one's missing,",
        "tonight we cool off our sunbeaten skin",
      ],
    ],
  },
  {
    theme: "the world",
    texts: [
      [
        "thoughts in the brain of the world,",
        "soulful electrochemical impulses,",
        "electronic soothsayer sighs,",
      ],
      [
        "cold air stampedes towards you ",
        "life happens regardless,",
        "living beings act on temporary knowledge.",
      ],
    ],
  },
  {
    theme: "people",
    texts: [
      [
        "a second sight of what might've been",
        "some things are better abandoned",
        "a fragment of an immaterial thing",
      ],
      [
        "thoughts bubble up to the surface,",
        "they turn into words that you read",
        "on people's creases in their skin,",
      ],
      [
        "human skin and atoms of people",
        "come close to colliding,",
        "the string of fate gets tied and untied",
      ],
    ],
  },
];

export const wordsArray = [
  {
    theme: "technology",
    words: [
      "copy",
      "wired",
      "베끼다",
      "save as",
      "search",
      "decode",
      "see more",
      "08:04",
      "today",
      "watch",
      "saved",
      "+",
      "-",
      "latest",
      "show",
      "more",
      "home",
      "Add",
      "send",
      "start",
      "begin",
      "delete",
      ". . .",
      "?",
      "скопировать",
      "проводной",
      "сохранить как",
      "поиска",
      "раскодировать",
      "cегодня",
      "смотреть",
      "показать",
      "идти домой",
      "отправлять",
      "начать ",
      "удалите",
      "экраны",
      "компьютер",
      "コピー",
      "有線",
      "探す",
      "今日",
      "見る",
      "もっと見せる",
      "送る",
      "始める",
      "消す",
      "スクリーン",
      "コンピューター",
      "베끼다 어",
      "유선의",
      "검색어",
      "오늘",
      "보내라",
      "시작아",
      "삭제",
      "화면",
    ],
  },
  {
    theme: "light",
    words: [
      "sun",
      "sunlight",
      "it's early",
      "day",
      "08:04",
      "19:30",
      "04:00",
      "...",
      "солнце",
      "День",
      "полдень",
      "pано",
      "небо",
      "минут",
      "секунд",
      "часов",
      "моменты",
      "日",
      "日光",
      "正午",
      "朝",
      "空",
      "分",
      "秒",
      "時間",
      "瞬間",
      "태양",
      "햇빛",
      "낮",
      "정오",
      "아침",
      "하늘",
      "분",
      "초",
      "시간",
      "순간들",
    ],
  },
  {
    theme: "darkness",
    words: [
      "midnight",
      "it's dark",
      "полночь",
      "Ночь",
      "минут",
      "секунд",
      "часов",
      "моменты",
      "очистить",
      "真夜中",
      "夜",
      "分",
      "秒",
      "時間",
      "瞬間",
      "한밤",
      "밤",
      "분",
      "초",
      "시간",
      "순간들",
    ],
  },
  {
    theme: "the world",
    words: [
      "outside",
      "inside",
      "paint",
      "mistakes",
      "streets",
      "distance",
      "focus",
      "somewhere",
      "nowhere",
      "за пределами",
      "внутри",
      "ошибки",
      "улицах",
      "расстояние",
      "фокус",
      "где-то",
      "нигде",
    ],
  },
  {
    theme: "people",
    words: [
      "the brain",
      "the body",
      "the soul",
      "people",
      "conversations",
      "words",
      "skin",
      "someone",
      "no one",
    ],
  },
];
