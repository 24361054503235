import { MutableRefObject, useEffect, useState } from "react";
import { FramectElement } from "./Main";
import "../Styles/Main.css";

interface FrameCounterProps {
  framect: MutableRefObject<FramectElement>;
}

const fpsInterval = 1000 / 30;

const FrameCounter = (props: FrameCounterProps) => {
  const [framect, setFramect] = useState(0);
  const [plusminus, setPlusminus] = useState(1);

  const doStuff = () => {
    setPlusminus(plusminus * -1);
  };

  const draw = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  const [then, setThen] = useState(Date.now());
  const [now, setNow] = useState(Date.now());
  let elapsed = 0;

  useEffect(() => {
    let animationFrameId = 0;
    //Drawing happens here
    const render = () => {
      animationFrameId = window.requestAnimationFrame(render);
      setNow(Date.now());

      elapsed = now - then;

      if (elapsed > fpsInterval) {
        // console.log("framect:", framect);

        setFramect(
          Math.round((framect + 0.5 * plusminus + Number.EPSILON) * 100) / 100
        );
        setThen(now - (elapsed % fpsInterval));
      }
    };
    render();

    props.framect.current.setPlusminus = doStuff;

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw, now]);

  return (
    <div id="framect" style={{ display: "none" }}>
      {framect}
    </div>
  );
};

export default FrameCounter;
