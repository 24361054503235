import { useStore } from "./ArtworkPropertyStore";
import LayerCanvas from "./LayerCanvas";
import * as utils from "./Utilities";

// interface groupAccentCanvasProps {
//   isFocusGridDrawn: boolean;
//   focusImg: HTMLImageElement;
//   focusGrid: { value: number; x: number; y: number }[][];
// }

const GroupAccentCanvasLayers = () => {
  const props = utils.getProps();

  const pos1X = props.position1;
  const pos1Y = props.position2;

  const shapes: {
    elemkey: number;
    x: number;
    y: number;
    color: string;
    color2: string;
    size: number;
    intensity: number;
  }[] = [];

  let numberofShapes = Math.round(pos1Y / 9);
  numberofShapes = utils.clamp(numberofShapes, 6, 9);

  for (let i = 1; i < numberofShapes + 1; i++) {
    const size = utils.clamp(
      parseInt(utils.generateValue(2, pos1X * i * 10, " group accent canvas")) /
        8,
      3,
      9
    );

    let xpos = parseInt(
      utils.generateValueSkip32(pos1X, 2, "cipher2", i * 30, "nozero")
    );
    let ypos = parseInt(
      utils.generateValueSkip32(pos1Y, 2, "cipher3", i * 30, "nozero")
    );

    shapes.push({
      elemkey: i,
      x: xpos,
      y: ypos,
      color: utils.selectPalette(props.palette)[`accent${i + 1}`],
      color2: utils.selectPalette(props.palette)[`accent${i + 2}`],

      size: size,
      intensity:
        parseInt(
          utils.generateValue(2, pos1X * i * 40, " group accent canvas")
        ) / 3000,
    });

    // console.log("shape key", i, " :", shapes[i - 1]);
  }

  const shapeItems = shapes.map((shape) => (
    <LayerCanvas
      key={shape.elemkey}
      elemkey={shape.elemkey}
      id={`accent${shape.elemkey}`}
      color={shape.color ? shape.color : "red"}
      color2={shape.color2 ? shape.color2 : "red"}
      size={shape.size}
      position1={shape.x}
      position2={shape.y}
      intensity={shape.intensity}
    />
  ));

  return <div className="canvasLayer">{shapeItems}</div>;
};

export default GroupAccentCanvasLayers;
