/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import "../Styles/Artwork.css";
import * as utils from "./Utilities";
import * as shape2d from "./Resources/Shapes2D";
import { useStore } from "./ArtworkPropertyStore";
import { textArray } from "./Resources/Texts";

interface textBoxProps {
  id?: string;
  element?: string;
  textId: number;
}

const LayerTextBoxCanvas = (directProps: textBoxProps) => {
  const props = utils.getProps();
  const textProps = utils.useTextProps();

  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const canvasRef = useRef(null);

  const textColor = utils.colorToHex(
    utils.selectPalette(props.palette).textColor1
  );
  const textBackgroundColor = utils.colorToHex(
    utils.selectPalette(props.palette).textBackground1
  );

  const versesArray = utils.selectText(directProps.textId, props.theme);

  const textBoxPosition = textProps.textBoxPosition;
  let textXPosition = 0;
  let textYPosition = 0;

  const textStyle = {
    font: textProps.textFont,
    weight: textProps.textFontWeight,
    letterSpacing: textProps.textLetterSpacing,
    lineHeight: 0.032,
  };

  switch (textBoxPosition) {
    default:
    case "top": {
      textXPosition = props.width * 0.1;
      textYPosition = props.height * 0.05;
      break;
    }
    case "bottom": {
      textXPosition = props.width * 0.1;
      textYPosition = props.height * 0.88;
      break;
    }
    case "left": {
      textXPosition = props.width * 0.1;
      textYPosition = props.height * -0.95;
      break;
    }
    case "right": {
      textXPosition = props.width * -0.5;
      textYPosition = props.height * 0.35;
      break;
    }
  }

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.globalCompositeOperation = "source-over";

    ctx.font = `bold ${props.width / 30}px ${textStyle.font}`;

    if (textBoxPosition === "left" || textBoxPosition === "right") {
      ctx.save();
      ctx.translate(props.height, props.width);
      if (textBoxPosition === "left") {
        ctx.rotate(-Math.PI / 2);
      } else {
        ctx.rotate(Math.PI / 2);
      }

      // ctx.fillText("Your Label Here", labelXposition, 0);
    }

    ctx.fillStyle = textBackgroundColor;
    ctx.fillRect(
      textXPosition - props.width / 20,
      textYPosition - props.height / 28,
      props.width * 0.9,
      props.height * 0.12
    );

    ctx.fillStyle = textColor;
    ctx.fillText(versesArray[0], textXPosition, textYPosition);
    ctx.fillText(
      versesArray[1],
      textXPosition,
      textYPosition + props.height * textStyle.lineHeight
    );
    ctx.fillText(
      versesArray[2],
      textXPosition,
      textYPosition + props.height * textStyle.lineHeight * 2
    );

    if (textBoxPosition === "left" || textBoxPosition === "right") {
      ctx.restore();
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let frameCount = 0;
    let animationFrameId = 0;
    const framectElement = document.getElementById("framect");

    //Drawing happens here
    const render = () => {
      if (framectElement) {
        draw(thisContext, parseFloat(framectElement?.innerText));
      }

      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return (
    <div className={"canvas"}>
      <canvas
        id={"canvas-textbox"}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export default LayerTextBoxCanvas;
