/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import "../Styles/Artwork.css";
import * as utils from "./Utilities";
import * as shape2d from "./Resources/Shapes2D";
import { useStore } from "./ArtworkPropertyStore";

interface WordBlobProps {
  id?: string;
  color1: string;
  color2: string;
  element?: string;
  size?: number;
  x: number;
  y: number;
  text: string;
}

const LayerWordBlob = (directProps: WordBlobProps) => {
  const props = utils.getProps();

  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const canvasRef = useRef(null);
  const fillcolor = utils.colorToHex(directProps.color1);

  let strokecolor = "";
  if (directProps.color1.endsWith("dark2")) {
    strokecolor = utils.colorToHex(
      directProps.color1.slice(0, directProps.color1.length - 6)
    );
  } else {
    if (directProps.color1.endsWith("dark")) {
      strokecolor = utils.colorToHex(directProps.color1 + "2");
    } else {
      strokecolor = utils.colorToHex(directProps.color1 + "-dark");
    }
  }

  const shapeSize = directProps.size ? directProps.size : props.width / 50;
  // const shapeRotation = directProps.x + directProps.y;

  let initialPosX = directProps.x;
  let initialPosY = directProps.y;

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.globalCompositeOperation = "source-over";

    ctx.lineJoin = "round";
    ctx.strokeStyle = strokecolor;
    ctx.lineWidth = props.width / 70;
    ctx.strokeText(directProps.text, initialPosX, initialPosY);

    ctx.fillStyle = fillcolor;
    ctx.font = `bold ${shapeSize}px "Anonymous Pro"`;
    // ctx.font = ` ${shapeSize}px "Roboto"`;
    ctx.textAlign = "center";
    ctx.fillText(directProps.text, initialPosX, initialPosY);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let animationFrameId = 0;

    const framectElement = document.getElementById("framect");

    //Drawing happens here
    const render = () => {
      if (framectElement) {
        draw(thisContext, parseFloat(framectElement?.innerText));
      }

      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return (
    <div className={"canvas"}>
      <canvas
        id={"canvas-" + directProps.id}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export default LayerWordBlob;
