const bgDrawingFaces = () => {
  return (
    <g>
      <path d="M10,10v1394h980V10H10z M987,1401H13V13h974V1401z" />
      <path
        d="M960,1113.5c-2.3-3.3-4.7-6.6-6.7-9.4c-0.9-1.3-1.8-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.7,0.3-1.4,0.5-2.1l0.2-1.1
      c0.3-1.2,0.5-2.5,0.8-3.7c0.1-0.7,0.3-1.4,0.4-2.2v-0.2c0.2-0.9,0.3-1.7,0.5-2.5c0.3-1.4,0.6-2.7,0.9-4.1v-0.1c0.5-2.3,1-4.7,1.5-7
      c0.3-1.4,0.5-2.9,0.8-4.3c0.2-1.5,0.5-3,0.8-4.4c0.1-0.6,0.2-1.1,0.4-1.7c0.5-2.1,0.9-4.2,0.7-6.4c-0.3-3.1-0.7-7.1-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-0.9-0.4-1.9-0.6-2.8v-0.1c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.3-2.3-1.9-3.4
      l-0.1-0.1c-0.7-1.2-1.4-2.6-2.2-4c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-1-1.4-1.4-2c-0.4-0.5-0.7-1-1.1-1.5c-0.4-0.7-0.9-1.4-1.3-2
      l-0.1-0.1c-0.8-1.3-1.6-2.6-2.6-3.8s-2-2.4-3-3.6c-0.9-1-1.9-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.7-3.7c-0.7-1-1.5-2.1-2.2-3.1
      c-0.6-0.8-1.3-1.6-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.7-6-8.2-8.1c-2.5-1.5-5.2-3-7.9-4.3c-2.5-1.2-5-2.3-7.5-3.5
      c-2.1-1-4.3-1.9-6.5-3c-2.2-1-4.3-2.1-6.4-3.2c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1c-2-0.4-4-0.5-6-0.6h-0.3
      c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1
      c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1c-3.3,0.2-6.7,0.5-9.8,2.1
      c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4c-2.1,0.9-4.1,2-6,3
      c-1.3,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.9,1.8-5.6,2.6c-1.9,0.9-4,1.8-5.9,2.8c-1,0.5-1.9,1-2.9,1.6c-1,0.6-2,1.1-3,1.6
      c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0.6-0.8,1.1-1.3,1.6c-0.5,0.7-1.1,1.3-1.6,2l-0.1,0.1c-0.9,1-1.8,2.1-2.6,3.2
      c-1.1,1.4-2.1,2.8-3.1,4.1l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.2-2.6,3.2c-0.9,1.1-1.8,2.2-2.7,3.4c-0.7,0.9-1.3,1.8-1.8,2.7
      c-0.3,0.5-0.6,0.9-0.9,1.4c-0.6,0.9-1.1,1.7-1.7,2.6c-1.2,1.9-2.5,3.8-3.7,5.8c-2,3.3-3.7,6.9-5.2,9.9c-1,2-1.7,3.7-2.1,5.3
      c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10-2.3,14.3c-0.3,2.2-0.5,4.4-0.8,6.6c-0.2,1.7-0.3,3.2-0.5,4.7c-0.3,2.7-0.2,5.4,0.4,8.3
      c0.2,1.1,0.5,2.2,0.7,3.4l0.1,0.4c0.4,2.1,0.9,4.2,1.3,6.3c0.2,1.2,0.4,2.4,0.6,3.6c0.2,1.3,0.4,2.6,0.7,3.9c0.3,1.5,0.7,3.1,1,4.5
      v0.2c0.3,1.2,0.6,2.4,0.8,3.7c0.3,1.5,0.6,3.1,0.9,4.5l0.1,0.3c0.1,0.7,0.3,1.4,0.4,2.2c0.1,0.4,0.2,0.7,0.2,1v0.1c0,0,0,0-0.1,0
      c-0.8,0.2-1.5,0.3-2.3,0.5l-0.7,0.1c-2.2,0.5-4.4,1-6.7,1.5c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1
      c-2.5,3.3-5.3,7.2-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3c0.8,2.9,1.7,6,3,9.7c0.5,1.3,1,2.6,1.5,3.9c0.5,1.1,0.9,2.3,1.4,3.5
      c0.7,1.9,1.3,3.8,1.9,5.7c0.4,1.2,0.8,2.4,1.2,3.6c0.2,0.5,0.3,0.9,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2c0.2,0.9,0.2,1.9,0.3,2.8
      c0,0.7,0.1,1.4,0.1,2.1l0.1,1c0.5,3.9,0.9,8,1.5,12c0.4,2.7,0.9,5.5,1.4,8.2l0.4,2.2c0,0.2,0.1,0.4,0.1,0.7
      c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6c0.8,0.8,1.8,1.5,2.7,2.2c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2
      c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7c0.2,0,0.4,0.2,0.5,0.3c0.3,0.7,0.5,1.4,0.8,2c0.3,0.7,0.5,1.4,0.8,2.1
      c0.2,0.6,0.5,1.1,0.7,1.7l0.1,0.3c1.1,2.5,2.2,5,3.4,7.5c1.3,2.6,2.7,5.3,3.9,7.7c0.9,1.7,1.9,3.5,2.8,5.3c0.8,1.7,1.7,3.5,1.7,5.6
      c0,3.3,0.1,6.7,0.1,10v0.3c0.1,2.5,0.1,5.1,0.1,7.6c0,3.1,0,6.3,0,9.4c0,1.6,0,3.1,0,4.7v1.5c0,1.8,0,3.7,0,5.6
      c0,1.5,0.1,3.1,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6c0,1.1,0,2.1,0,3.2v0.1c0,1.8,0,3.6,0.1,5.4c0.1,2.2,0.2,4.3,0.3,6.4v0.3
      c0.1,1.4,0.2,2.9,0.2,4.3c0.1,1.1,0.1,2.3,0,3.8c0,0.1,0,0.2,0,0.3v0.1c0,0.6,0,1.3-0.2,1.7c-0.5,1-1.1,2-1.7,3
      c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.9,1.3-1.3,2c-0.6,1-1.3,2.1-2.1,3.1
      s-1.6,2-2.4,3c-0.9,1-1.8,2.2-2.7,3.4l-0.1,0.1c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2c-2.5,1.4-5.6,3.1-8.5,4.8
      c-1,0.6-2,1.2-3,1.8c-1,0.6-1.9,1.2-2.9,1.8c-0.8,0.5-1.7,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2c-1.2,0.7-2.3,1.5-3.3,2.3h-0.1
      c-0.7,0.5-1.4,1-2.2,1.5c-1,0.7-2,1.3-3,1.9l-0.1,0.1c-0.4,0.3-0.9,0.5-1.3,0.8l-1,0.6l1.2,0.1c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0
      c0.1,0,0.2,0,0.2,0c1.2-0.6,2.4-1.2,3.6-1.7c2.9-1.4,6.1-3,9.3-4.6c2-1,4-2.2,6-3.4l1.2-0.7c0.7-0.4,1.4-0.9,2.1-1.3l0.1-0.1
      c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.2-1.2,3.3-1.7c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1
      l0.1-0.1c0.3-0.3,0.6-0.6,0.8-0.9l0.2-0.2c0.7-0.7,1.5-1.5,2.2-2.4c0.7-0.7,1.3-1.4,2-2.3l0.8-0.9l0.4,0.5c0.4,0.5,0.8,1,1.2,1.4
      c0.4,0.4,0.7,0.8,1.1,1.3c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8c0.7,0.6,1.3,1.2,2,1.9c0.7,0.7,1.5,1.4,2.2,2.2
      c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5c-0.1,0.3-0.3,0.6-0.4,1c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.1
      c-0.3,0.7-0.7,1.4-1,2.1v0.1c-0.8,1.7-1.6,3.4-2.3,5.1c-0.9,2.2-1.7,4.6-2.5,6.8l-0.2,0.5c-0.2,0.5-0.4,1.1-0.6,1.6l-0.4,1
      c-0.5,1.3-1,2.7-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.3-0.1,0.5l-0.1,0.4l0.4,0.1
      c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2
      c0.9-2.9,1.7-5.2,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7c0.5-1.2,1.1-2.3,1.7-3.5l0.1-0.1c0.2-0.3,0.3-0.6,0.5-1c0.4,0.2,0.8,0.5,1.2,0.7
      h0.1c1.2,0.7,2.3,1.4,3.5,2c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.3,1.3,4.8,1.8c0.9,0.3,1.7,0.6,2.6,1
      c1.1,0.4,2.2,0.8,3.2,1.2c2.2,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,1-0.1,1.9-0.2,2.9c-0.2,2.7-0.4,5.5-0.6,8.3
      c-0.1,1.4-0.1,2.8,0,4.1c0,0.6,0,1.2,0,1.8v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.3-2.6,0.3-3.8c0-0.7,0.1-1.4,0.1-2.2
      c0.1-1.1,0.2-2.1,0.3-3.2v-0.1c0.1-1.2,0.2-2.4,0.3-3.5c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0c0.6,0.1,1.3,0.1,2,0.1h0.1
      c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1
      c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.4c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1
      c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.1c0.3,3.5,0.5,7,0.9,10.6c0.1,1,0.4,1.9,0.7,2.8
      c0.1,0.4,0.3,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4c-0.1-1.8-0.3-3.6-0.4-5.3v-0.1c-0.1-1.1-0.2-2.2-0.3-3.4
      c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5
      c0.5-0.2,1.1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.7-1.6,5.5-2.3l0.3-0.1c3.2-1.4,6.4-2.8,9.6-4.3c2-0.9,4.4-2,6.8-3.2
      c1.2-0.6,2.4-1.3,3.6-2c0.4-0.3,0.9-0.5,1.3-0.8c0.3,0.6,0.6,1.2,0.8,1.7l0.1,0.3c0.9,1.8,1.8,3.6,2.5,5.5c1,2.4,1.9,4.9,2.7,7.3
      l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.9,8.1c0.4,0.6,1,1,1.6,1c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4
      c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4c-0.5-1.6-1.2-3.1-1.8-4.4l-0.2-0.5
      c-1-2.1-2-4.4-3.1-6.5c-1.1-2-1-2.1,0.6-3.6c1.5-1.4,3-2.8,4.5-4.3l0.6-0.5c0.6-0.6,1.1-1.2,1.7-1.7c0.9-1,1.9-2,2.9-2.9
      c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.2,1.3c1.7,1.8,3.3,3.6,4.9,5.3c0.1,0.1,0.1,0.2,0.2,0.3
      c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.4,0.5,0.8,0.8,1c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.3,1.3,3.4,1.9l0.1,0.1
      c1.5,0.8,3.1,1.7,4.6,2.5c1.2,0.7,2.5,1.5,3.6,2.2c1.2,0.7,2.6,1.6,3.9,2.3c2.2,1.2,4.5,2.4,6.6,3.4c1.8,0.9,3.6,1.7,5.3,2.5
      l0.2,0.1c0.5,0.2,1.1,0.5,1.6,0.7h0.1c0.3,0,0.5-0.2,0.5-0.4v-0.4h-0.3c-0.7-0.5-1.5-1-2.2-1.5l-0.3-0.2c-2-1.4-4-2.8-6.1-4.1
      c-3-1.9-6.2-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.1-1.7-4.6-2.6c-0.8-0.4-1.5-0.8-2.3-1.2l-1.5-0.9c-2.3-1.2-4.5-2.5-6.8-3.8
      c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6c-1.5-2-2.9-4.1-4.3-6.1l-0.1-0.2c-0.7-1-1.3-1.9-2-2.9
      c-0.6-0.8-1.2-1.6-1.7-2.4c-0.3-0.5-0.7-0.9-1.1-1.4c-0.7-0.9-1.4-1.9-2-2.8c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6
      c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5v-0.1c0-0.6,0-1.1-0.1-1.7v-0.2c-0.1-1.3-0.1-2.6-0.1-3.9
      c0.1-2.1,0.2-4.3,0.3-6.2c0.1-1.8,0.2-3.8,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4c0-2.5,0-5-0.1-7.4c0-1,0-2-0.1-3c0-0.8,0-1.7,0-2.5v-0.3
      c0-0.9,0-1.8,0-2.7v-1.6c0-1.5,0-3.2,0-4.8c0-2.2,0.1-4.4,0.2-6.5v-0.3c0.1-1.9,0.1-3.9,0.2-5.8v-0.4c0.1-3.2,0.1-6.1,1.7-9
      c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5,3.3-7.5l0.2-0.4c0.3-0.6,0.5-1.2,0.8-1.8c0.3-0.7,0.6-1.5,0.8-2.1c0.3-0.7,0.5-1.4,0.9-2.1
      c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.7,0,1.1-0.1c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8
      c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.2,7-6,9.9-8.7c1.3-1.2,2.9-2.6,2.9-5c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6
      c0.2-0.9,0.3-1.8,0.5-2.7l0.1-0.4c0.3-1.8,0.7-3.6,1-5.4c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2c0.4-3.4,0.8-6.7,1.1-9.4
      c0-0.4,0.1-0.8,0.1-1.2c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9c0.3-0.7,0.6-1.4,0.8-2.2c1.5-4,2.6-7.2,3.7-10.3l0.5-1.3
      c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.1,0.8-2.4,0.2-3.8C961.2,1115.3,960.6,1114.4,960,1113.5z M936.6,1100.2
      l2.4,0.6c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2c2.4,0.6,4.6,1.2,6.1,3.5c1.5,2.2,3.1,4.3,4.7,6.4
      c0.6,0.7,1.1,1.5,1.7,2.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.6-2.8-0.7c-2.8-0.3-5.3-0.5-7.5-0.6c-1.3,0-2.6,0.1-4,0.2h-0.1
      c-0.6,0.1-1.3,0.1-1.9,0.1c-0.1,0-0.2-0.1-0.2-0.1c-1.8-2.2-3.7-4.4-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5c0.4-0.9,0.6-1.9,0.7-2.8
      l0.1-0.5c0.1-0.7,0.4-0.7,0.6-0.7C936.4,1100.2,936.5,1100.2,936.6,1100.2z M943.3,1152.4l-0.1,0.8c-3-0.7-6.2-1-10.1-1
      c-2.4,0-4.7,0.1-7.1,0.3c-0.4-7.8,2.7-14.2,5.9-19.4c0.5,0,0.9,0,1.4,0.1h0.7c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6
      c0.1,0,0.2,0.2,0.2,0.3c-0.3,3.7-0.7,7.4-1,11l-0.4,4.9C943.4,1151,943.4,1151.6,943.3,1152.4z M899.7,1270.5
      c-0.1,4.4-0.3,8.9-0.5,13.2l-0.1,3.3c0,0.6,0,1.2-0.1,1.8v0.1c-0.1,2.6-0.2,5.2-0.5,7.8c-0.1,0.8-0.8,2-1.4,2.3
      c-1.5,0.6-2.5,1.6-3.5,2.6c-0.5,0.5-1.1,1.1-1.7,1.5c-0.7,0.5-1.4,1-2.2,1.7c-0.9,0.7-1.8,1.4-2.8,2.1c-1.2,0.9-2.4,1.7-3.7,2.6
      c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1,0.1-0.3,0.2-0.5,0.3l-0.1-1.5c-0.1-1.3-0.2-2.6-0.2-3.9c-0.1-1.3-0.1-2.5-0.2-3.8
      c-0.1-2-0.2-4.4-0.3-6.6c-0.2-2.2-0.4-4.5-0.6-6.7c-0.1-1.6-0.3-3.3-0.4-5c-0.2-2.1-0.3-4.3-0.5-6.4v-0.7c-0.2-2.7-0.4-5.4-0.6-8.1
      c-0.3-3.4-0.6-6.8-1-10.1v-0.1c-0.1-0.6,0.1-0.8,0.5-1.1c4.2-2.6,8.3-5.1,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3
      c0.8-1.1,1.5-2.1,2.2-3.1c0.2-0.3,0.3-0.6,0.4-0.9c0-0.2,0.1-0.3,0.2-0.5c0.8-1.8,1.6-3.6,2.4-5.4c0.8-1.8,1.6-3.6,2.3-5.3
      c0.4-0.8,0.7-1.6,1.1-2.5v0.4c0,1.5,0,2.9,0,4.2c0,2.4-0.1,4.8-0.1,7.2c0,2.2-0.1,4.5-0.1,6.7c-0.1,3.2-0.1,6.9-0.1,10.5
      c-0.1,1.6-0.1,3.1-0.1,4.5v0.2C899.7,1265.9,899.8,1268.2,899.7,1270.5z M809.4,1278.4c0.1-1.6,0.1-3.2,0-4.8c0-0.6,0-1.2,0-1.8
      c5.9,0.6,11.9,0.9,17.7,0.9c5.9,0,11.9-0.3,17.7-0.9l0.2,6.7c0.1,4.9,0.3,10.5,0.4,15.9c0.1,5.1,0.2,10.3,0.4,16l0.2,6.8
      c-0.1,0-0.1,0-0.2,0l-2,0.1c-3.1,0.2-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0c-0.7,0-1.5,0.2-2.2,0.3
      c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2c-0.3,0-0.5,0-0.8,0
      c-1.1,0-2.2,0-3.4-0.1c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.9-0.1h-0.1c0.2-1.6,0.3-3.1,0.3-4.6
      c0.1-3.6,0.3-7.2,0.3-10.9c0-1.9,0-3.8,0-5.6c0-2.1,0-4.4,0-6.5c0.1-2.1,0.2-4.2,0.4-6.2v-0.1
      C809.2,1281.7,809.3,1280,809.4,1278.4z M841.8,1269.5c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.7,0-1,0.1h-0.2
      c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3c-4.9-0.4-9.8-0.9-14.6-1.4H806c-1.5-0.1-3-0.4-4.4-0.6h-0.3
      c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5v-0.2c0.3-1,0.6-2.1,0.9-3.1
      c0.3-1.2,0.6-2.5,0.9-3.7l0.3-1.2c0.7,0,1.3,0,2,0h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0c2.7,0.1,5.9,0.2,9,0.5
      c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2h0.2c0.4,0,0.8,0,1.2,0c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0
      c0.1,0.5,0.2,1,0.4,1.5c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.7,1,3.5,1.5,5.1l0.1,0.2c0.7,2.3,1.4,4.7,2.1,7.1c0.2,0.8,0.1,1.8-0.1,2.7
      v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1C845.3,1269.1,843.5,1269.3,841.8,1269.5z M755.6,1286.9v-0.6
      c-0.1-1.5-0.2-3.1-0.2-4.6c0-1.1-0.1-2.2-0.1-3.3c-0.1-3.4-0.2-7-0.3-10.5c0-2.6,0-5.3,0.1-7.9v-0.1c0-2.3,0.1-4.7,0.1-7
      c0-4.7-0.1-9.4-0.2-13.9v-0.9c0-1.8-0.1-3.7-0.2-5.3v-0.4c0-1-0.1-2-0.1-3c0,0,0-0.1,0-0.2c0.5,1.2,1,2.3,1.5,3.5v0.1
      c1.5,3.4,3,6.8,4.3,10.3c0.5,1.3,1.3,2.3,2,3.3c0.1,0.8,0.5,1.4,0.9,2c0.3,0.5,0.9,0.8,1.5,1.1c0.2,0.1,0.5,0.2,0.7,0.4
      c0.9,0.6,1.8,1.1,2.6,1.7c2,1.3,4,2.6,6.1,3.7c2.1,1.1,2.8,2.3,2.4,4.6c-0.6,3.7-0.8,7.4-1.1,11c-0.1,1-0.2,2.1-0.2,3.1
      c-0.2,1.8-0.3,3.7-0.4,5.5v0.1c-0.1,1.2-0.1,2.4-0.2,3.5c-0.2,3.3-0.5,6.6-0.7,9.8v0.1c-0.2,2.5-0.4,5-0.5,7.5
      c-0.2,3.3-0.4,6-0.6,8.5c0,0.4,0,0.9,0,1.3v0.1l-0.3-0.2c-0.6-0.4-1.1-0.7-1.6-1.1c-0.6-0.4-1.2-0.9-1.9-1.3c-1.5-1-3-2-4.4-3.1
      c-1.3-1.1-2.6-2.2-3.8-3.4c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.2-0.6-0.4-1-0.6c-1.2-0.4-2-1.5-2-2.9c-0.1-2-0.2-4-0.3-6v-0.1
      C755.7,1288.9,755.7,1287.9,755.6,1286.9z M758,1209.9c-0.3-2.4-0.6-4.8-0.9-7.2l-0.1-0.7c-0.3-2.4-0.5-5-0.5-7.4
      c0-1.5,0.3-3.1,0.5-4.7c0.1-0.6,0.2-1.1,0.3-1.7c0.1-0.6,0.2-0.7,0.7-0.8c1.6-0.1,3.3-0.3,4.9-0.5c1.2-0.1,2.6-0.3,4-0.4
      c1.9-0.2,4-0.3,6.2-0.4c1,0,2.1,0,3.1,0c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.8,0.1,1.1v0.2c0.1,1.1,0.2,2.1,0.3,3.1c0,0.4,0,0.9,0,1.4
      v0.1c0,0.3,0,0.7,0,1v1.2c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2.1,0.4,3c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4
      c0.2,2.4,0.5,4.9,0.8,7.4c0.5,3.4,1.1,6.8,1.7,10.2l0.1,0.3c0.1,0.5,0,0.9-0.4,1.4c-1.6,1.9-3.6,3.4-5.5,4.8l-0.1,0.1
      c-0.5,0.4-1.1,0.8-1.6,1.2c-1.6,1.2-3.1,2.5-4.6,3.8l-0.2,0.1c-0.5,0.4-1,0.8-1.4,1.2c-1,0.9-2,1.7-3.1,2.6l-1.2,1.1
      c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-0.9-0.4-1.7-0.6-2.6l-0.5-2.6c-0.7-3.7-1.3-7.6-1.9-11.3c-0.4-2.7-0.8-5.5-1.1-8.2l-0.1-0.4
      c-0.1-1-0.2-2-0.4-3C758.3,1211.8,758.1,1210.9,758,1209.9L758,1209.9z M730.5,1147.5c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8
      c-0.5-3.7-1.1-7.5-1.7-11.1v-0.3c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.1-0.9-0.3-1.3c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4
      c0.5-0.1,1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.7
      c0.1,2.5,0.3,4.9,0.5,7.4v0.1c0.1,1,0.2,1.9,0.2,2.9c0.1,1.4,0.2,2.7,0.3,4.1v0.1c0.3,3.5,0.6,7.2,0.9,10.8
      c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5c0.1,1.1,0.2,2.1,0.3,3.1c-0.2-0.7-0.5-1.4-0.7-2
      c-0.3-0.9-0.7-1.8-0.9-2.8c-0.9-4-1.7-7.9-2.3-11.5c-0.5-2.9-1.2-6.7-1.8-10.5c-0.2-1.4-0.4-2.9-0.5-4.3v-0.1
      C730.7,1149.6,730.6,1148.5,730.5,1147.5z M742.9,1029.2l0.1,0.2c0.4,3.1,0.9,6.4,1.5,9.5c0.7,3.2,1.5,6.3,2.2,9.5
      c0.3,1.3,0.6,2.6,1,3.9c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5,5.3v0.1c-1.4,1.5-2.7,2.9-4,4.3c-1.5,1.6-3,3.1-4.4,4.5
      c-1.5,1.5-3.1,3.1-4.6,4.7c-1.2,1.2-2.3,2.5-3.4,3.7l-0.1,0.2c-0.5,0.5-1,1.1-1.5,1.6c0,0.1-0.1,0.1-0.2,0.2l-0.9-6
      c-0.5-3.4-1-6.6-1.4-9.9c-0.2-1.3-0.4-2.5-0.5-3.8c-0.4-2.6-0.7-5.4-1.2-8.1c-0.3-1.5,0.4-2.5,1.2-3.6c0.1-0.2,0.3-0.4,0.4-0.6
      c1.9-2.8,3.7-5.6,5.3-7.9c1.6-2.4,3.5-5.2,5.3-7.9l0.7-1c1.7-2.4,3.4-4.9,5.2-7.2c0.8-1,1.7-1.9,2.6-2.9c0.3-0.3,0.6-0.6,0.9-0.9
      c0.1,0.9,0.2,1.9,0.3,2.8v0.1C742.3,1024.2,742.6,1026.7,742.9,1029.2z M783.3,1001.2c0.1,2.2,0.3,4.5,0.5,6.7
      c0.2,2.9,0.4,5.8,0.6,8.8c0.1,2.5,0.2,5,0.3,7.4v0.2c0.1,1.8,0.1,3.6,0.2,5.3c0,0.8,0.1,1.6,0.2,2.3H785c0.1,0.6,0.1,1.2,0.2,1.9
      c0,0.2-0.1,0.5-0.1,0.6c-2.6,1.6-5.9,3.6-9.1,5.4c-2.1,1.2-4.2,2.3-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3
      c-1.5,0.8-3.1,1.7-4.6,2.5c-0.6,0.4-1.3,0.7-2,1.1l-1.1,0.6c-0.1-0.5-0.3-1.1-0.4-1.6v-0.1c-0.4-1.6-0.9-3.2-1.3-4.9l-0.2-0.7
      c-0.7-2.8-1.3-5.7-1.8-8.7c-0.5-3.3-1-6.6-1.4-9.8v-0.1c-0.2-1.3-0.4-2.7-0.5-4c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.3-1.6
      c-0.4-1.6,0.1-2.8,1.7-4c1.3-0.9,2.7-2,4-2.9c1.3-1,2.6-2,3.9-2.9c1.3-0.9,2.7-1.8,4.1-2.7h0.1c0.7-0.5,1.5-0.9,2.2-1.4
      c1.1-0.7,2.2-1.4,3.3-2.2l0.1-0.1c2-1.3,4-2.7,6.1-3.9c1.8-1.1,3.7-2.2,5.6-3.2c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5
      c0.2-0.1,0.4-0.2,0.7-0.4c0,0.6,0,1.2,0.1,1.8v0.2C783.2,997.7,783.2,999.5,783.3,1001.2z M825.9,1027.1c0,0.7-0.1,0.8-0.9,0.8
      c-0.9,0.1-1.8,0.2-2.7,0.2c-3,0.3-6.2,0.5-9.2,0.9c-5.3,0.7-10.9,1.6-17.7,2.7c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5
      l-2.3-41.1l41-10.3v3c0,2.2,0,4.4,0,6.6c0,0.5,0,1.1,0,1.7c0,0.6,0,1.2,0,1.9c0,1.6,0,3.1-0.1,4.7c0,2-0.1,3.9-0.1,5.7
      C825.9,1012.4,825.9,1019.7,825.9,1027.1z M933.9,1097.6c-0.5,2.3-1,4.6-1.5,6.9c-0.2,1-0.6,2.1-0.9,3s-0.6,2-0.9,3
      c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2c-0.3,0-0.8-0.1-1.3-0.2c-0.2-0.1-0.5-0.1-0.8-0.2c-0.5-0.1-0.9-0.3-1.4-0.5
      l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2c1.1-4.5,2.2-8.6,3.2-12.7c0.5-1.8,0.9-3.5,1.4-5.3c0.8-3.2,1.8-6.8,2.6-10.3
      c0.4-1.7,0.7-3.4,1-5.1v-0.2c0.1-0.8,0.3-1.6,0.4-2.4c0.1-0.6,0.2-1.2,0.4-1.9l0.1-0.5l0.3,0.4c0.6,0.7,1,1.3,1.5,1.9
      c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.8,0.8,1.1c1.1,0.8,1,1.7,0.7,3.3c-0.6,2.5-1.1,5.1-1.6,7.4L933.9,1097.6z M934.2,1109.1
      c1.5,1.8,2.8,3.4,4.1,4.9c0.2,0.3,0.2,0.6-0.2,1.3c-2.3,4.4-4.7,9.1-7.5,14.8c-0.6,1.2-1.1,2.3-1.6,3.5v0.1c-0.3,0.7-0.6,1.4-1,2.2
      c0,0.1-0.1,0.1-0.1,0.2l5.5-27.7C933.7,1108.7,934,1108.9,934.2,1109.1z M849.4,1238.6c-0.2-0.8-0.4-1.7-0.5-2.6
      c-0.2-1-0.4-2.1-0.7-3.2c-0.3-1.2-0.7-2.4-1.1-3.6l-0.3-0.2c-0.1-0.4-0.3-0.8-0.4-1.2c-0.2-0.7-0.2-1,0.3-1.5
      c1.4-1.3,2.8-2.6,4.1-3.8l0.2-0.2c0.8-0.8,1.6-1.5,2.4-2.2c0.8-0.8,1.7-1.5,2.5-2.2h0.1c0.6-0.5,1.2-1.1,1.8-1.6
      c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4c1.9,1.9,3.9,3.7,6,5.6c1.5,1.3,2.9,2.5,4.4,3.7c0.6,0.5,1.1,0.9,1.7,1.4
      c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1v-0.1C850,1240.8,849.6,1239.7,849.4,1238.6z M749,1059.2l0.1,0.5
      c0.5,3.4,1,7,1.5,10.5c0.5,4,1.1,8,1.6,11.9l0.1,0.7c0.2,1.5,0.4,3,0.5,4.6v0.3c0.1,1,0.1,1.9-0.3,2.8c-0.5,1.2-0.8,2.5-1.1,3.8
      c-0.1,0.5-0.2,0.9-0.3,1.4l-0.4,1.4c-0.6,2.4-1.2,4.9-1.7,7.3c-0.2,1-0.4,1.9-0.6,2.9c-0.2,0.9-0.3,1.9-0.6,2.9
      c-0.1,0.5-0.4,1-0.8,1.4c-1.2,1.2-2.5,2.3-3.7,3.4l-0.1,0.1c-1,0.9-2.1,1.9-3.1,2.9c-1.3,1.3-2.6,2.5-4,3.9c-0.5,0.5-1,0.9-1.5,1.4
      l-0.9-3.6c-0.5-1.8-0.9-3.6-1.4-5.4c-0.4-1.7-0.8-3.3-1.3-5c-0.7-2.6-1.4-5.3-2-8c-0.9-3.5-1.5-6.2-2.1-8.7
      c-0.3-1.4-0.6-2.7-0.9-4.3c-0.4-1.8-0.8-3.7-1.2-5.5c-0.2-1.1,0.1-2.1,1-3c1.5-1.5,3-3.1,4.2-4.4l0.2-0.2c1.3-1.4,2.7-2.8,4-4.2
      s2.7-2.7,4-4.1c1.7-1.7,3.5-3.6,5.3-5.5c1.1-1.1,2.2-2.4,3.2-3.5l0.1-0.1c0.5-0.6,1-1.2,1.6-1.8l0.1-0.1c0,0.2,0.1,0.5,0.1,0.7v0.1
      C748.8,1057.6,748.9,1058.4,749,1059.2z M787.2,1073.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.9,0-2.8-0.1c-1.9,0-3.9-0.1-5.9-0.1h-0.1
      c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5c-0.7,0.9-1.4,1.7-2.2,2.5c-0.6,0.6-1.2,1.3-1.8,2
      c-0.9,1.1-1.7,2.2-2.6,3.4c-0.5,0.7-1,1.3-1.5,2c-0.6,0.8-1.2,1.5-1.8,2.2l-0.5,0.6l-0.1-1.2c-0.1-1.2-0.3-2.4-0.4-3.7
      c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.9-0.4-3.9-0.7-5.9c-0.5-3.7-1-7.5-1.4-10.7c-0.3-2.3-0.7-4.4-1-6.4v-0.1
      c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.5-3.5l0.2-0.1c1.2-0.6,2.3-1.3,3.5-1.9c1.1-0.6,2.2-1.2,3.4-1.8
      c1.5-0.8,3.1-1.7,4.7-2.6c1-0.5,2-1.1,3-1.6h0.1c1.4-0.7,3-1.5,4.5-2.4c1.5-0.8,3-1.8,4.4-2.6c0.7-0.4,1.3-0.8,2-1.2
      c0.4-0.3,0.9-0.5,1.4-0.8l0.4-0.2l2,37.2L787.2,1073.6z M788,1076.5l-1.4,17.2l-0.4,5.1c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7,0-1.1,0
      c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7H770c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1c-2.9-1.9-5.8-3.9-8.7-5.9
      c-0.9-0.6-1.8-1.3-2.7-1.9c-0.3-0.2-0.6-0.5-0.9-0.7c0.4-0.6,0.9-1.1,1.3-1.7c1.1-1.5,2.2-2.9,3.4-4.3c1.1-1.3,2.3-2.7,3.5-3.9
      c0.8-0.9,1.7-1.9,2.5-2.8c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0c2.2-0.1,4.2-0.1,6.1-0.1
      c4.2-0.1,7.9-0.1,11.3-0.1c0.3,0,0.6,0.1,0.9,0.1H788L788,1076.5z M753.3,1095.7c0.2-1.1,0.5-2.1,0.8-3.2v-0.1
      c0.1-0.4,0.2-0.7,0.3-1.1l13.7,9.4l-3.7,3.7c-0.9,0.8-1.7,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.9l1.2,1
      c1.7,1.5,3.5,3.1,5.3,4.6c0.6,0.5,1.2,0.9,1.8,1.3c0.2,0.1,0.3,0.2,0.5,0.3l-4.3,7.9c-0.2-0.1-0.5-0.3-0.7-0.4
      c-3.5-2.6-7.2-5.3-10.9-8.1c-1.8-1.4-3.4-2.6-4.8-3.7c0,0-0.1-0.3-0.1-0.5C751.1,1105.7,752.2,1100.7,753.3,1095.7z M772.3,1117.4
      c0.1-0.1,0.4-0.3,0.5-0.3c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2c0.6,0,1.2,0,1.8,0c0.8,0,1.5,0,2.3,0c1,0,2,0,3,0
      c0.6,0,1.1,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0.1v9.7l-18.6-2.5l0.7-1.3C769.9,1121.6,771.1,1119.4,772.3,1117.4z M766,1139.3
      c0.1-0.9,0.2-1.8,0.4-2.7c0.2-1.6,0.6-3.1,0.9-4.4c0.1-0.6,0.2-1.1,0.4-1.7l0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1c0-0.1,0.3-0.3,0.4-0.3
      h0.1c3.1,0.3,6.5,0.7,9.9,1.1c1.5,0.2,3,0.5,4.5,0.8c1,0.2,2,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1c0.1,3.3,0.2,6.3,0.4,8.9
      c0.1,1.3,0.2,2.7,0.4,3.9c0.1,0.9,0.2,1.7,0.3,2.6c0.1,0.7,0.1,1.3,0.1,2.1v0.3c0,0.2,0,0.4,0,0.6l-24.9,6.7c0-0.2,0-0.4,0.1-0.6
      c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.6,0.6-3.2,0.9-4.8v-0.1c0.4-2.1,0.8-4.3,1.2-6.4C765.8,1141.2,765.9,1140.2,766,1139.3z
       M786.7,1115.2c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3c-1.5,0-3.1-0.1-4.6-0.3h-0.1c-0.4,0-0.7-0.1-1.1-0.1
      c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.3-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.5-0.1
      c-1.9-1.6-3.8-3.2-5.7-4.8l-1.4-1.2l0.4-0.3c0.4-0.3,0.7-0.6,1-0.9c0.4-0.3,0.7-0.6,1.1-0.9l0.2-0.1c1.1-0.9,2.2-1.9,3.2-2.9
      c1.5-1.5,3.4-1.5,5.2-1.5h0.3c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.4,0.5,3.6,1.5c1,0.8,2,1.6,3,2.4
      c0.5,0.4,1.1,0.9,1.6,1.3l0.7,0.1c0.8,0.6,1.5,1.2,2.3,1.9c0.2,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.5,0.9c-2.5,2.5-5.2,5-9.2,5
      c-0.2,0-0.4,0-0.6,0C788.1,1115.2,787.4,1115.2,786.7,1115.2z M809.2,1105.1l-5.4,5.7c-0.5-0.5-0.9-0.9-1.4-1.4
      c-1.2-1.3-2.5-2.6-3.9-3.8c-2-1.7-4.2-3.5-7.2-5.7c-0.6-0.4-1.3-0.7-2.1-0.9c-0.3-0.1-0.5-0.2-0.8-0.3l1.6-22.4
      c0.1,0,0.3,0.1,0.4,0.1h0.1c0.6,0.2,1.2,0.4,1.7,0.7c0.9,0.5,1.9,0.9,2.8,1.3c2.8,1.3,5.7,2.7,8,5c3.6,3.7,6.3,7.3,8.4,10.9
      c0.6,1,1.3,2,1.9,2.9c0.3,0.5,0.7,1,1,1.5c0.1,0.1,0.2,0.3,0.2,0.4L809.2,1105.1z M815.5,1101.8c0.1-0.1,0.2-0.2,0.3-0.3l0.1,1.1
      c0.1,1.2,0.2,2.4,0.2,3.6c0.2,2.3,0.5,5.4,0.8,8.4c0,0.6-0.1,0.8-0.6,1.1c-1.7,1-3.4,2.1-5,3.1l-0.2,0.1c-1.3,0.8-2.7,1.7-4,2.5
      c-0.5,0.3-1.2,0.6-1.8,0.9h-0.1c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1-0.6-0.2-1.1-0.4-1.6c-0.4-1.6-0.7-3-0.8-4.5c-0.1-1,0.4-2.3,1.2-3.2
      c2.3-2.7,4.8-5.3,7.2-7.8l0.5-0.6c0.5-0.5,1-1,1.5-1.5S814.9,1102.3,815.5,1101.8z M801.5,1114.7c-0.1,0.2-0.5,0.5-0.7,0.5
      c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1l3.5-3.3C802.2,1112.4,802.3,1113,801.5,1114.7z M788.5,1126c0.3-2.7,0.2-5.4,0.1-7.7
      c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1h0.1
      c1.7,0.4,3.3,0.7,5.1,1.1l1.5,0.3l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.2c0,0.1-0.2,0.4-0.3,0.5c-0.7,0.3-1.4,0.6-2.1,0.9
      c-2.9,1.2-5.9,2.4-8.9,3.5c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1C788.4,1128,788.5,1126.8,788.5,1126z M798.6,1127.6
      c1.3-0.6,2.8-1.2,4.3-1.8l3.6,11.7l-16.2,10.5l-1.5-17.2C792.3,1130.3,795.5,1128.9,798.6,1127.6z M816.7,1211.7h0.2
      c1.8-0.1,3.7-0.2,5.6-0.2c1.5,0,3,0,4.5,0.1c1.1,0,2.3,0.1,3.5,0.1h0.2c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3
      c2.1,0.1,4.2,0.1,6.2,0.6c0.7,0.2,1.4,0.2,2.2,0.2c1.5,0,3-0.3,4.4-0.6c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4
      c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1c-0.5,0.5-1,1-1.5,1.4c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4
      c-2.6,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8c-1.7,0-3.4,0.1-5.1,0.2h-0.2c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1
      s-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6c-0.4,0-0.8-0.3-1.4-0.8c-0.8-0.8-1.7-1.6-2.5-2.4c-1.2-1.2-2.4-2.4-3.7-3.5
      c-1.7-1.5-3.5-3-5.2-4.4c-1.3-1.1-2.3-1.9-3.3-2.7c-0.6-0.5-1.1-1-1.6-1.6c0.7,0.1,1.4,0.2,2.1,0.4c0.9,0.2,1.7,0.3,2.6,0.5h0.1
      c2.4,0.5,4.9,0.9,7.3,1.6c0.6,0.2,1.2,0.2,1.9,0.2c0.9,0,1.7-0.1,2.5-0.2c0.7-0.1,1.4-0.2,2.2-0.2
      C813.6,1211.9,815.2,1211.8,816.7,1211.7z M815,1208.9c0.8-0.1,1.7-0.5,2.5-0.8c0.1,0,0.1,0,0.2-0.1c0.1,0,0.3-0.1,0.5-0.2
      c0.8-0.5,1.6-0.8,2.4-0.8c1.2,0,2.3,0.5,3.6,1c0.2,0.1,0.3,0.1,0.5,0.2c0.7,0.3,1.3,0.6,2.1,0.6c1,0,2-0.3,3-0.7l0.2-0.1
      c0.7-0.2,1.4-0.5,2.1-0.8h0.1c0.4-0.2,0.7-0.3,1.1-0.5c0.1-0.1,0.3,0,0.4,0c1.7,0.7,3.5,1.4,5.3,2.1h-2.8c-1.6,0-3.2,0-4.8,0
      c-0.6,0-1.1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1c-1.3-0.1-2.7-0.1-4.4-0.1c-1.1,0-2.1,0-3.3,0
      C816.7,1208.9,815.8,1208.9,815,1208.9z M858.3,1207.8c-1,0.2-2.1,0.4-3.1,0.6l-1.5,0.3c-1.8,0.3-3.6,0.7-5.4,1
      c-0.4,0.1-0.8-0.1-1.3-0.2c-1.7-0.6-3.1-1.1-4.4-1.5c-2.6-1-5.1-2-7.4-2.9c-0.6-0.2-1.2-0.4-1.8-0.4c-0.7,0-1.5,0.2-2.1,0.6
      c-2.7,1.5-5.5,1.6-8.2,0c-0.7-0.4-1.4-0.6-2.1-0.6c-1.1,0-2.2,0.4-3.2,0.9c-0.4,0.2-0.7,0.3-1.1,0.4c-1.7,0.6-3.4,1.2-5.2,1.9
      l-0.7,0.2c-0.5,0.2-1,0.4-1.6,0.6c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.5-1.5,0.8-2.5,0.8c-0.8,0-1.6-0.2-2.5-0.3
      c-0.2,0-0.4-0.1-0.6-0.1c-1.8-0.4-3.7-0.7-5.3-1h-0.3c-0.8-0.1-1.6-0.3-2.5-0.4c-0.1,0-0.2,0-0.4-0.1l0.7-0.3
      c1.2-0.6,2.1-1.1,3.1-1.6l1.8-0.9c2-1,4-2,6.1-3c2-1,3.9-1.9,5.9-2.8c0.9-0.4,1.7-0.8,2.6-1.2c0.3-0.1,0.5-0.2,0.8-0.4
      c0.7-0.3,1.3-0.6,2-1c0.4-0.3,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,1.9,0.4l1,0.3c1.9,0.6,3.8,1.2,5.8,1.3c1.5,0.1,3.2-0.4,5.1-1.3
      c0.8-0.4,1.6-0.6,2.3-0.6c0.9,0,1.7,0.2,2.8,0.7c1.8,0.9,3.6,1.8,5.4,2.6h0.1c1.5,0.7,3.2,1.5,4.9,2.4c2.3,1.1,4.6,2.3,6.8,3.4
      c1.1,0.6,2.2,1.1,3.3,1.7c0.4,0.2,0.8,0.4,1.1,0.6L858.3,1207.8z M850.3,1167.8l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.8,0.6-1.2
      c0.7-1.4,1.4-2.7,2.1-4c0.9-1.3,2.2-1.7,3.5-2.2C849.7,1168.1,850,1168,850.3,1167.8z M848.1,1166.5c-1,0.5-1.9,1-2.9,1.3
      c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-2.2-0.3-4.3-0.7-6.5-1l-2.2-0.3c0-0.4,0.3-0.7,0.9-1
      c1.4-0.6,2.9-1.3,4.3-1.9h0.1c1.2-0.5,2.5-1.1,3.7-1.6c0.8-0.3,1.4-0.2,2,0.4c0.9,1.1,1.9,2.1,2.8,3.1l0.9,1
      C848.7,1166.2,848.4,1166.4,848.1,1166.5z M844,1169.9l-0.6,1c-0.9,1.7-1.7,3.2-2.5,4.7c0,0-0.1,0.1-0.2,0.1
      c-2.3,0.2-4.5,0.4-7,0.6l-1.1,0.1l1.5-8.1L844,1169.9z M833.3,1184.4L833.3,1184.4c0.3,2.1,0.7,4.2,1,6.3c0.1,0.7,0.2,1.5,0.2,2.3
      c0,0.3,0,0.6,0.1,1c-0.6,0.2-1.1,0.3-1.7,0.5l-0.2,0.1c-1.8,0.6-3.5,1.1-5.3,1.4c-0.2,0-0.4,0.1-0.6,0.1c-0.8,0-1.7-0.3-2.6-0.5
      l-0.2-0.1c-1.1-0.3-2.2-0.7-3.4-1.2l-0.2-0.1c-0.4-0.1-0.7-0.3-1.1-0.4l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4c0.1-0.9,0.3-1.8,0.4-2.6
      v-0.1c0.2-1.4,0.4-2.9,0.7-4.3c0.2-1,0.4-1,0.6-1c0.2,0,0.5,0.1,0.8,0.2c3,0.7,5.7,0.7,8.2-0.1c0.4-0.1,0.6-0.2,0.8-0.2
      c0.2,0,0.5,0,0.6,1.1C832.8,1181.1,833,1182.8,833.3,1184.4z M821.1,1176.3l-0.7-0.1c-1.4-0.1-2.9-0.2-4.3-0.3H816
      c-0.1,0-0.2-0.1-0.3-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3v-0.1c-0.2-0.4-0.4-0.7-0.6-1.1
      l9.8-1.6l1.5,8.1H821.1z M811.1,1175.7l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1c-0.9-2.4-1.6-4.5-2.2-6.6
      c1.7,0.5,3.3,0.9,4.2,2.4c0.8,1.3,1.4,2.6,2.1,4C810.7,1174.9,810.9,1175.3,811.1,1175.7z M818.6,1166.4c-2.5,0.4-4.8,0.8-7.1,1.1
      c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.3,0.1-0.4,0.1h-0.4c-0.6,0-1.2,0-1.7-0.2c-0.8-0.3-1.5-0.6-2.3-1c-0.2-0.1-0.4-0.2-0.7-0.3
      c0.3-0.3,0.6-0.6,0.8-1c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1c2.5,1,5.2,2.2,8.3,3.6
      c0.2,0.1,0.5,0.4,0.7,0.8c0,0,0,0.1,0.1,0.1L818.6,1166.4z M811.4,1153.9L811.4,1153.9l-0.1,0.1c-0.1,1-0.3,2.1-0.6,3
      c-0.2,0.5-0.7,1-1.2,1.4c-0.3,0.3-0.6,0.5-0.9,0.9c-2.4,2.8-4.3,4.9-5.9,6.8c-0.5,0.6-0.9,1.3-0.4,2.5c0.6,1.5,1.1,3.1,1.6,4.7
      c0.3,1,0.6,1.9,0.9,2.7c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.6,1.6-3.8,2.4l-1.1,0.7l-1-1.4c-1-1.5-2-3-3-4.5l-0.4-0.6
      c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1c1.9-1.7,3.3-3,4.6-4.3c1.9-1.8,3.8-3.6,5.6-5.4l0.3-0.3c1.2-1.1,2.3-2.2,3.5-3.4
      c0.9-0.9,1.8-1.7,2.7-2.5l1-1C811.5,1153.4,811.4,1153.7,811.4,1153.9z M800.7,1181.5c0.7-0.4,1.3-0.9,2-1.4
      c1.2-0.8,2.4-1.7,3.6-2.4c0.9-0.3,1.9-0.3,2.7-0.3c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4l1,0.1c1,0.1,2,0.2,3,0.3
      c0.3,0,0.7,0.1,1,0.1l-0.2,1.4c-0.2,1.3-0.4,2.7-0.6,4c-0.1,0.8-0.3,1.6-0.4,2.4v0.2c-0.4,2.3-0.8,4.7-1,7
      c-0.1,1.1-0.5,1.3-1.3,1.6c-0.4,0.2-0.8,0.3-1.3,0.5c-0.9,0.3-1.8,0.7-2.6,1.1c-0.3,0.2-0.6,0.2-1,0.2c-0.3,0-0.6-0.1-1-0.1
      c-0.3-0.1-0.7-0.1-1.1-0.1h-0.1c0,0-0.1-0.1-0.3-0.3c-1.2-1.9-2.3-3.8-3.5-5.7c-1.6-2.7-3.2-5.3-4.8-8
      C800.3,1182,800.3,1181.8,800.7,1181.5z M845.7,1196c-0.3,0.5-0.5,0.6-1.3,0.9c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0-1.6-0.4-2.4-0.8
      l-0.4-0.2c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.5-0.3-0.5-0.9c0-1.5-0.3-3.1-0.5-4.6v-0.2
      c-0.4-2.4-0.8-5-1.2-7.1l-0.1-0.7c-0.2-0.9-0.3-1.9-0.5-2.8c0-0.1,0-0.1,0-0.2l2.5-0.2c2.2-0.2,4.3-0.3,6.4-0.4
      c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1c0.8,0.5,1.5,1,2.4,1.6l0.1,0.1c0.9,0.6,1.8,1.2,2.7,1.8
      c0.5,0.3,0.5,0.5,0.2,0.9c-2.2,3.6-4.2,6.8-6.1,9.8c-0.6,1-1.2,2.1-1.8,3.2L845.7,1196z M849.9,1176.3l0.6-1.7
      c0.6-2,1.3-4.1,1.9-6.2c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.5-1.9-2.5-2.6
      c-1.7-1.3-2-3-2.3-4.9c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3c0.2,0.1,0.3,0.3,0.4,0.4c1.3,1.2,2.6,2.5,3.9,3.7
      c2.3,2.2,4.5,4.4,6.8,6.6l0.1,0.1c1.8,1.7,4,3.8,6.2,5.7c0.7,0.6,0.7,1,0.1,1.7c-1.5,2.2-2.9,4.2-4.2,6.1c-0.4,0.6-0.7,1.2-1.1,1.8
      c-0.1,0.1-0.1,0.3-0.2,0.4L849.9,1176.3z M861.1,1167.3c-1.5-1.3-2.9-2.5-4.3-3.8c-0.8-0.7-1.5-1.5-2.3-2.3l-0.1-0.1
      c-0.6-0.7-1.3-1.4-2-2c-2.6-2.5-5.3-5-8.1-7.5c-1.2-1.1-1.9-2.4-2.3-4c-0.6-3-1.4-6-2.1-9c-0.3-1.2-0.6-2.4-0.9-3.5
      c-0.1-0.5-0.3-1-0.4-1.6l-0.1-0.5l0.1,0.1c0.8,0.6,1.7,1.3,2.5,1.9l0.1,0.1c1.8,1.4,3.7,2.9,5.7,4.2c2.1,1.5,4.4,3,6.5,4.2
      c0.8,0.5,1.6,1,2.4,1.5c0.8,0.6,1.7,1.2,2.6,1.7c1.9,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.4,0.6,0.4,1c-0.3,4.1-0.6,8.1-0.9,12.1v0.1
      c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8C862.2,1168.2,861.6,1167.8,861.1,1167.3z M842.1,1159.2c0,0.2,0.1,0.4,0.2,0.6
      v0.1c-0.5,0.2-0.9,0.4-1.4,0.6h-0.1c-1.3,0.6-2.6,1.2-3.9,1.7c-0.5,0.2-1,0.4-1.5,0.6c-0.6,0.2-1.2,0.4-1.9,0.7
      c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1s-0.3,1.9-0.5,2.8c-0.4,1.7-0.8,3.7-1.3,5.6c-0.1,0.2-0.6,0.5-0.9,0.6
      c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1c-0.3-1.5-0.5-2.9-0.9-4.5v-0.1c-0.2-1.2-0.5-2.5-0.7-3.7c-0.7-4-0.9-4.2-4.9-5.3
      c-1.1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2c-0.5-0.2-1-0.5-1.4-0.8c-0.1-0.1-0.2-0.1-0.4-0.2c0-2,1.6-2.7,3.2-3.4c0.8-0.4,1.9-0.4,2.9-0.5
      c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.1,1.9-0.2c1.7-0.2,3.4-0.4,5.1-0.5c1.1,0,2.2,0.1,3.4,0.2c0.5,0.1,1,0.1,1.5,0.2l2,0.2
      c1.6,0.2,3.2,0.3,4.9,0.4C839.7,1156.3,841.8,1158.1,842.1,1159.2z M813.1,1145.3c-0.5,2.8-1.3,5.1-3.7,7c-2.6,2.1-5,4.5-7.4,6.9
      c-1.1,1.1-2.2,2.2-3.3,3.2c-1.5,1.5-3.1,2.9-4.6,4.3l-0.1,0.1c-0.7,0.6-1.3,1.3-2,1.9c0-0.3-0.1-0.5-0.1-0.8v-0.2
      c-0.1-1-0.3-1.9-0.4-2.9c-0.2-2.2-0.4-4.6-0.5-6.6v-0.6c-0.1-1-0.1-2-0.2-2.9c0-0.4-0.1-0.8-0.1-1.2c-0.1-0.8-0.2-1.6-0.2-2.4
      c0-0.3,0.3-0.8,0.6-1c2-1.3,4.1-2.6,5.8-3.7l0.1-0.1c3-1.9,6-3.8,9-5.8c2.3-1.6,4.6-3.3,6.8-5l0.1-0.1c1-0.7,1.9-1.5,2.9-2.2
      c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3-0.2,0.6-0.2,0.9c-0.3,1.1-0.6,2.3-0.9,3.5l-0.3,1.2l-0.1,0.1h-0.1
      C814.1,1140.8,813.5,1143.1,813.1,1145.3z M791.3,1172.1c0.4,0.5,0.7,1.1,1.1,1.6l0.3,0.4c1.2,1.7,2.2,3.2,3.2,4.9
      c0.9,1.5,1.8,3,2.6,4.5c0.6,1.1,1.3,2.3,2,3.5c0.7,1.1,1.4,2.3,2.1,3.4l0.1,0.1c0.5,0.8,1,1.5,1.5,2.3c1,1.5,1.9,3.1,2.8,4.6
      c0.3,0.6,0.4,0.9,0.3,1.2c-0.1,0.3-0.4,0.5-0.9,0.8l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2c0,0,0,0-0.1,0
      c-0.3,0-0.5-0.1-0.5-0.2l-1-1.4c-1.6-2.4-3.3-4.8-4.8-7.3c-0.5-0.9-0.9-2-1.3-3.1c-0.2-0.6-0.4-1.3-0.7-1.9c-0.9-2-1.8-4.2-3.1-6.9
      c-0.4-0.8-0.4-1.2,0.3-1.9c1.2-0.8,2.1-2,2.9-3.1c0.6-0.8,1.3-1.7,2-2.5c1.4-1.5,2.8-3,4.2-4.5l0.1-0.1
      C790.3,1173.2,790.8,1172.6,791.3,1172.1z M781.7,1194.2c0.3,0.6,0.6,1.2,0.8,1.9l0.1,0.1c1,2.2,1.9,4.4,3.1,6.5
      c0.9,1.6,2,3,3.1,4.4c0.5,0.6,1,1.3,1.5,1.9l0.1,0.1c-0.2,1.2,0.6,1.8,1.1,2.2c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7,1.4,1.3,2.1,1.9
      l0.3,0.3c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4c-0.8,0.7-1.6,1.5-2.3,2.2l-0.1,0.1c-1.2,1.1-2.5,2.3-3.8,3.5
      c-1.3,1.1-2.6,2.2-3.8,3.2c-0.4,0.4-0.9,0.7-1.3,1.1l-0.4-2.8c-1.7-11.3-3.2-22-3.2-33.1c0.1,0.3,0.2,0.7,0.4,1.1
      C780.5,1191.4,781.1,1192.8,781.7,1194.2z M784.7,1226.4l0.7-0.6c0.7-0.6,1.4-1.2,2.1-1.8c0.5-0.4,1-0.8,1.4-1.2l0.2-0.2
      c1.2-1,2.4-2.1,3.6-3.1c0.9-0.8,1.7-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.5,0.3l2.4,2.2
      c1.4,1.3,2.9,2.7,4.4,4c0.5,0.5,1.1,1,1.7,1.6l0.1,0.1c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5l-0.4,1.2
      c-0.6,1.8-1.2,3.8-1.7,5.8c-0.4,1.5-0.7,2.9-1.1,4.3c-0.2,0.7-0.3,1.4-0.5,2.1c-0.1,0.4-0.2,0.9-0.3,1.4l-0.1,0.6l-20.3-15.9
      L784.7,1226.4z M806.5,1242.8c0.3-1.3,0.6-2.5,0.9-3.7c0.2-1,0.5-2,0.7-3c0.3-1.4,0.7-3.1,1.1-4.8c0.3-1.1,0.6-2.2,0.9-3.3
      c0.1-0.3,0.2-0.5,0.7-0.5h0.1c3.6,0.1,7.2,0.2,10.8,0.2h1.7c0.9,0,1.9,0,2.8,0.1h0.1c2.9,0.1,5.9,0.1,8.8,0.1c2.4,0,5-0.1,8.4-0.4
      h0.1c0.5,0,0.6,0.2,0.7,0.8v0.1c0.7,2.9,1.5,5.9,2.3,8.9c0.4,1.5,0.7,3,1.1,4.6l0.5,2.2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0
      c-0.6,0-1.2,0-1.7,0c-0.7,0-1.5,0-2.2,0c-3.3,0-6.8,0.2-10.3,0.6c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3
      c-2.3-0.3-4.8-0.4-8-0.4c-0.9,0-1.9,0-2.8,0c-1.4,0-2.8,0-4.3,0h-0.5c-0.4,0-0.7,0-1.1,0L806.5,1242.8z M868.6,1222.7
      c-2.1-1.9-4.3-3.9-6.3-5.7l-2.5-2.2c-0.2-0.2-0.2-0.3-0.2-0.4s0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5c0.4-0.4,0.8-0.8,1.2-1.1
      c0.1-0.1,0.2-0.2,0.3-0.2c0.5-0.5,1.2-1,1-2.1c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.5,0.8-1.1,1.2-1.6
      c1-1.3,2-2.7,2.9-4.1c1.1-1.7,1.9-3.6,2.7-5.5c0.9-2,1.8-4.1,2.6-6.1l0.1-0.2c0.3-0.6,0.5-1.2,0.8-1.9c-0.1,11.7-1.8,23-3.5,35
      l-0.1,0.8c-0.2-0.2-0.4-0.3-0.6-0.5C869.9,1223.8,869.3,1223.2,868.6,1222.7z M874.1,1185.6c-0.3,0.7-0.6,1.3-0.9,2
      c-0.6,1.4-1.3,2.8-1.9,4.2c-0.3,0.8-0.7,1.6-0.9,2.4c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.6-1.8,3.2-2.8,4.8l-0.3,0.5
      c-0.4,0.7-0.9,1.3-1.3,1.9v0.1c-0.4,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.3-0.1-0.5-0.2c-2.9-1.5-5.8-3-8.6-4.4
      l-2.7-1.4c-0.9-0.5-1.8-0.9-2.8-1.4l-1.4-0.7c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5c0.7-1.2,1.4-2.4,2.1-3.4
      c1.2-1.9,2.4-3.8,3.4-5.8c2.6-4.7,5.5-9.2,8.6-13.8c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.5,1.1,0.9,1.6,1.5
      c1.8,2,3.6,4,5.3,6l0.8,1c0.3,0.4,0.6,0.8,0.9,1.1c0.6,0.7,1.2,1.5,1.8,2.1C874.4,1184.8,874.4,1185.1,874.1,1185.6z M848,1137.6
      l3.6-11.8c1.5,0.5,2.9,1.2,4.3,1.8c3.1,1.3,6.2,2.7,9.8,3.3l-1.4,17.2L848,1137.6z M851.8,1109.6L851.8,1109.6
      c-0.5,0.5-0.8,0.9-1.2,1.2l-10.9-11.5c0-0.1,0.1-0.1,0.1-0.2l0.8-1.3c1.3-2,2.6-4,4-6c2.3-3.4,5.2-7.3,9.2-10.2
      c1.4-1,3.1-1.8,4.6-2.6c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1c0.2-0.1,0.5-0.2,0.8-0.2c0,0,0,0,0.1,0l1.6,22.5
      c-2.1,0.1-3.6,1.3-4.9,2.6c-0.4,0.2-0.8,0.5-1.2,0.8l-0.2,0.2c-1.5,1.2-3.1,2.4-4.5,3.7C854,1107.3,852.9,1108.5,851.8,1109.6z
       M857.2,1114.6c-0.3,0-0.5,0-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4c-0.1,0-0.4-0.2-0.6-0.6c-0.4-0.7-0.5-1.5-0.2-1.9
      c0.2-0.4,0.6-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.2-0.2L857.2,1114.6z M841.5,1104.2c2.7,2.8,5.5,5.7,8,8.7c1.3,1.5,1.7,2.9,1.2,4.5
      c-0.4,1.2-0.5,2.4-0.7,3.8c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.1c-0.6-0.2-1.1-0.5-1.7-0.8
      c-1.6-0.9-3.6-2-5.6-3.2c-1.4-0.9-2.8-1.8-4.4-3c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.5,0.6-7,0.9-10.6l0.2-2.3c0.2,0.1,0.3,0.3,0.5,0.4
      l0.1,0.1C840.1,1102.8,840.8,1103.5,841.5,1104.2z M865.2,1128.5c-0.2,0-0.3,0-0.6-0.1c-1.8-0.6-3.5-1.1-5.2-1.8
      c-1.3-0.5-2.7-1.1-3.9-1.7l-0.2-0.1c-0.9-0.4-1.9-0.8-2.8-1.2c-0.4-0.2-0.5-0.3-0.4-0.8c0.2-1.3,0.4-2.6,0.6-3.9l0.2-1.1
      c0.5-0.1,1-0.2,1.5-0.3h0.2c1.5-0.3,3.1-0.7,4.7-1c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1c0.5,0.1,0.9,0.2,1.4,0.3h0.1
      c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2c0,1.7,0,3.5,0,5.2v0.1c0,0.8,0,1.6,0,2.3c0,0.2,0,0.4,0,0.5c0,0.4,0,0.8,0,1.2
      C865.7,1128.3,865.6,1128.5,865.2,1128.5z M865.3,1115.1L865.3,1115.1c-2.7,0-5-0.9-7-2.8c-0.2-0.2-0.5-0.4-0.8-0.6
      c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4c0.9-0.7,1.9-1.5,2.8-2.2c1.2-1,2.4-2,3.6-2.9
      c1-0.7,2.1-1.2,3-1.2c4.3-0.3,8.8-0.3,12.9-0.4l0.3-0.1c2.5,0,4.5,0.9,6.1,2.6c1,1.1,2.1,2,3.3,3.1l0.1,0.1c0.4,0.3,0.8,0.7,1.2,1
      l-1.5,1.2c-1.6,1.3-3.1,2.6-4.7,3.8c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.3
      c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.2,0h-0.1c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1
      c-2.3,0-4.8,0-6.9,0C869.1,1115.1,867.2,1115.1,865.3,1115.1z M866.7,1076.3c0.1,0,0.2,0,0.3,0c0.5-0.1,1-0.2,1.5-0.2h0.1
      c2.3,0,4.6,0.1,6.5,0.2h0.4c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1c0.7,0,1.3,0.2,1.8,0.8
      c0.5,0.6,1.1,1.2,1.7,1.7c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.4c0.5,0.7,1,1.4,1.6,2.1c-0.6,0.4-1.2,0.9-1.8,1.3h-0.1
      c-1.6,1.1-3.2,2.2-4.8,3.3c-2.4,1.6-4.8,3.2-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1c-1.1-0.1-2.2-0.2-3.3-0.3
      c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1c-0.4,0-0.8,0-1.2,0h-0.2L866.7,1076.3z
       M885.6,1123.5l0.8,1.4l-18.6,2.5v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2
      c1.3-0.1,2.6-0.2,3.9-0.2c0.1,0,0.4,0.2,0.5,0.4C883.4,1119.4,884.5,1121.4,885.6,1123.5z M867.1,1142.2c0.2-2.5,0.3-5,0.4-7.3
      l0.1-2.2c0-0.2,0-0.5,0-0.7c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6c2.3-0.5,4.4-0.9,6.4-1.2c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1
      c0.7-0.1,1.6-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5c0.3,1.8,0.6,3.5,0.9,5.3v0.2c0.3,2,0.7,4.1,1,6.1
      c0.4,2.4,0.9,4.8,1.4,7.2c0.2,0.9,0.4,1.9,0.6,2.8c-0.5-0.1-0.9-0.2-1.3-0.3H890h-0.1c-1.1-0.3-2.2-0.5-3.4-0.8
      c-2-0.5-4.1-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1c-1.9-0.5-3.8-1-5.8-1.5c-0.8-0.2-1.2-0.5-1.1-1.4
      C866.8,1146.3,866.9,1144.2,867.1,1142.2z M888.3,1123.9l-4.5-8c0.1-0.1,0.3-0.2,0.5-0.3c0.6-0.4,1.2-0.9,1.8-1.3l0.9-0.7
      c1.7-1.5,3.6-3,5.3-4.5c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.7-3.3-5.5-5c-0.1-0.1-0.1-0.1-0.2-0.3l13.7-9.4
      c0,0.1,0.1,0.2,0.1,0.3c0.2,0.5,0.4,1,0.5,1.6c0.9,3.6,1.6,6.7,2.3,9.7c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4
      c-1.6,1.2-3.3,2.4-4.9,3.6l-0.3,0.2c-1.5,1.1-2.9,2.1-4.3,3.2c-1.3,1-2.6,1.9-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9
      C888.6,1123.7,888.5,1123.8,888.3,1123.9z M904.4,1099.9l-0.2-0.9c-0.3-1.3-0.6-2.5-0.9-3.8c-0.1-0.4-0.2-0.7-0.2-1.1v-0.1
      c-0.2-1.1-0.4-2.2-0.9-3.3c-0.5-1.2-0.4-2.3-0.3-3.5v-0.1c0.1-1.2,0.2-2.4,0.3-3.7c0.1-1.5,0.3-3.1,0.5-4.7
      c0.2-1.4,0.4-2.8,0.6-4.2v-0.1c0.1-0.8,0.3-1.6,0.4-2.4c0.3-1.8,0.5-3.6,0.8-5.3c0.4-2.9,0.8-5.9,1.3-8.9c0.1-0.5,0.2-0.9,0.4-1.5
      c0-0.1,0-0.2,0.1-0.2l0.6,0.6c0.7,0.7,1.3,1.4,1.9,2c1,1.1,2.1,2.2,3.1,3.2c1.7,1.8,3.6,3.7,5.3,5.5c1.1,1.2,2.3,2.4,3.3,3.6
      l0.1,0.1c0.8,0.9,1.7,1.8,2.5,2.8c0.4,0.4,0.8,0.9,1.2,1.3c1.1,1.2,2.2,2.4,3.4,3.5c2.1,1.8,2,3.8,1.5,5.8
      c-0.6,2.3-1.1,4.8-1.6,6.9v0.1c-0.5,2.1-1,4.4-1.5,6.5c-0.4,1.7-0.9,3.4-1.3,5.1v0.1c-0.4,1.3-0.7,2.7-1.1,4
      c-0.6,2.4-1.2,4.8-1.7,7.1c-0.2,1-0.5,2-0.7,3c0,0.2-0.1,0.3-0.1,0.5v0.1c-0.1,0.3-0.2,0.7-0.3,1.1c-0.3,1.1-0.6,2.2-1,3.2
      l-0.2,1.4c-0.3-0.3-0.6-0.6-0.9-0.9l-0.2-0.2c-0.9-1-1.9-2.1-2.9-3.1c-1.4-1.4-2.9-2.8-4.3-4.1c-1.2-1.1-2.4-2.3-3.6-3.4
      c-0.5-0.5-0.9-1.1-1-1.6C906,1106.8,905.2,1103.3,904.4,1099.9z M931.6,1069.6c-0.3,2.1-0.7,4.2-1,6.4l-0.1,0.5
      c-0.1,0.6-0.2,1.3-0.3,1.9l-0.6-0.6c-0.7-0.8-1.4-1.6-2.1-2.3c-2.6-2.8-6.1-6.5-9.6-10.3c-1.4-1.5-2.9-3-4.4-4.5l-0.2-0.2
      c-1-1.1-2-2.1-3.1-3.2c-0.4-0.4-0.7-0.8-1.1-1.2c-0.7-0.7-1.4-1.5-2.1-2.2c-0.5-0.4-0.5-0.9-0.4-1.4c0.3-1.3,0.7-2.6,1-3.9
      c0.6-2.5,1.4-5.4,2-8.1c0.4-1.7,0.6-3.4,0.8-5v-0.2c0.1-0.7,0.2-1.4,0.3-2l0.4-2.3c0.3-1.9,0.6-3.8,0.8-5.7c0.1-1,0.2-2,0.3-3
      c0.1-0.7,0.1-1.5,0.2-2.2c0-0.4,0.1-0.8,0.2-1.3v-0.1c0.3,0.4,0.6,0.7,0.9,1.1c1,1.2,2,2.3,2.8,3.4c3,3.5,5.5,7,7.9,10.4
      c1,1.4,1.9,2.9,2.8,4.3c0.6,0.9,1.1,1.8,1.7,2.7c0.3,0.5,0.7,1,1,1.6c1.2,1.9,2.5,3.8,3.8,5.6c0.7,0.9,0.9,1.7,0.7,2.9
      c-0.2,1.2-0.3,2.4-0.5,3.6c-0.2,1.5-0.4,3.1-0.7,4.6c-0.2,1.3-0.4,2.7-0.5,4C932.2,1065,931.9,1067.3,931.6,1069.6z M910.3,1021
      l-0.1,0.8c-0.8,5.9-1.7,12.1-2.7,18.1c-0.5,2.7-1.1,5.4-1.8,8l-0.1,0.3c-0.3,1-0.5,2-0.7,3c-0.2,0.7-0.4,1.4-0.5,2l-0.1,0.5
      c-0.4-0.2-0.8-0.5-1.2-0.7l-1-0.5c-1.7-0.9-3.5-1.9-5.2-2.9c-1-0.6-2.1-1.1-3.1-1.6l-0.1-0.1c-1-0.5-2.3-1.2-3.6-1.9
      c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.2-0.7-2.4-1.4-3.6-2c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5
      c-1.6-0.9-3.2-1.9-4.7-2.8l-0.4-0.2c-0.5-0.3-1-0.6-1.5-0.9c0-0.1-0.1-0.2-0.3-0.2c-0.4-0.1-0.7-0.2-0.8-0.3
      c-0.2-0.2-0.1-0.7,0-1.2c0-0.2,0.1-0.4,0.1-0.6c0.1-1.9,0.2-3.7,0.3-5.6c0.1-1.6,0.2-3.1,0.2-4.7c0.1-1.2,0.1-2.3,0.2-3.5
      c0.1-1.9,0.2-3.7,0.3-5.6c0.4-6.5,0.8-13,1.1-19.5c0.3,0.2,0.6,0.3,0.8,0.5l0.5,0.3c1.2,0.7,2.5,1.4,3.8,2.2l2.1,1.1
      c2.8,1.6,5.7,3.3,8.4,5c4.5,2.8,7.8,5,10.9,7.1c1.8,1.2,3.6,2.6,5.3,3.9l0.2,0.2c1.8,1.3,3.7,2.7,5.6,4
      C910.5,1018.9,910.4,1019.8,910.3,1021z M870.2,1037.2l0.3,0.2c1.2,0.8,2.6,1.7,4,2.5c1,0.7,2.3,1.4,3.4,2l0.6,0.3
      c1.7,0.9,3.5,1.9,5.2,2.8l1.6,0.8c0.7,0.4,1.5,0.8,2.2,1.2l0.7,0.4c1.8,1,3.7,2,5.6,3c1,0.5,1.9,1.1,2.9,1.6l0.3,0.1
      c1.9,1,3.8,2.1,5.8,3.1c1.1,0.5,1.2,1.2,1,2.2c-0.2,1.3-0.4,2.5-0.6,3.8l-0.1,0.8c-0.6,4.3-1.3,8.7-1.9,13.2
      c-0.4,2.7-0.6,5.5-0.8,8.2c-0.1,1.3-0.2,2.6-0.4,3.9c0,0.2,0,0.3-0.1,0.5l-0.7-1c-0.7-0.9-1.4-1.9-2.2-2.8c-2-2.5-4.1-5.1-6.1-7.5
      l-0.4-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0c-0.4,0-0.8,0-1.2,0
      c-0.8,0-1.5,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2c-0.6,0-1.2,0.1-1.8,0.1
      c-0.3,0-0.7,0-1.1,0.1h-0.5l2-36.2l0.1-1C869.3,1036.7,869.7,1036.9,870.2,1037.2z M866.7,1036.2v0.3c-0.1,1-0.2,2.1-0.3,3.1
      c-0.1,1.7-0.2,3.4-0.2,5c-0.1,1.3-0.1,2.7-0.2,4.1c-0.1,1.9-0.2,3.9-0.3,5.8c-0.1,1.6-0.2,3.1-0.3,4.7c-0.1,1.6-0.2,3.1-0.2,4.7
      c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.5c-0.1,1.9-0.2,3.8-0.3,5.7c0,0.2-0.3,0.5-0.6,0.6c-2.4,1.1-4.8,2.1-7.1,3.2l-0.2,0.1
      c-1.1,0.5-2.1,0.9-3.2,1.4c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.2,5.5-6.1,8.2l-1,1.4c-0.8,1.2-1.6,2.5-2.3,3.7l-0.1,0.2
      c-0.4,0.7-0.7,0.9-1.2,0.9c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1c-1-0.3-1.9-0.6-2.9-1c-0.4-0.1-0.9-0.3-1.4-0.4
      c-0.2,0-0.3-0.1-0.5-0.1v-65.9c13.8,0.9,27.1,3.4,38.3,5.6C866.8,1035.7,866.8,1036,866.7,1036.2z M836.9,1123.5
      c0-1.3,0.1-2.6,0.3-4.1c0-0.5,0.1-0.9,0.1-1.4c0.4,0.2,0.7,0.5,1.1,0.7l0.1,0.1c1.2,0.8,2.4,1.6,3.6,2.2c2,1.1,4.2,2.2,6.2,3.1
      c0.9,0.4,1.1,0.7,0.8,1.6c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9c-0.3,0.9-0.5,1.7-0.7,2.6v0.1c-0.2,0.6-0.3,1.2-0.5,1.8
      c0,0.2-0.1,0.3-0.2,0.5c-0.2-0.1-0.3-0.2-0.5-0.3l-0.2-0.2c-0.8-0.6-1.5-1.1-2.2-1.6c-2-1.6-3.9-3.2-5.7-4.7
      c-0.3-0.2-0.5-0.7-0.5-1.1C836.8,1126.4,836.8,1124.9,836.9,1123.5z M837.7,1138.6c0.9,3.7,1.7,7.6,2.4,11l0.2,0.9
      c0.2,0.9,0.4,1.9,0.6,2.8c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.8-0.2-2.8-0.3
      c-2.7-0.3-5.4-0.6-8.2-0.6c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1
      c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.2c0.8-3.7,1.6-7.6,2.5-11.4c0.5-1.9,1-3.9,1.6-5.8l0.4-1.4
      c0.6-2.3,1.1-2.4,2.5-2.5c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0h2.9v-0.1
      c0.5,0,1,0.1,1.4,0.1c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.5C836.6,1134.4,837.2,1136.5,837.7,1138.6z
       M817.7,1125.3c0,0.7,0,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.5-3.7,3-6.2,4.9c-0.5,0.4-1,0.7-1.5,1.1c-0.1,0.1-0.3,0.2-0.4,0.3
      l-0.2-0.5c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3c-0.4-1.6-0.9-3.4-1.5-5.1c-0.3-0.8,0-1,0.7-1.3c0.4-0.2,0.7-0.3,1.1-0.5
      l0.3-0.1c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.3-1.3,3.2-2c0.5-0.3,1-0.7,1.5-1c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,0.9,0.1,1.3
      c0.1,1.4,0.3,2.7,0.4,4C817.7,1124,817.7,1124.7,817.7,1125.3z M817.4,1098.2c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.4-3.7-3.5-5.4
      l-0.1-0.1c-0.6-1-1.3-1.9-1.9-2.9c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.1c-0.4-0.2-0.9-0.4-1.3-0.6
      c-1.1-0.5-2.2-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.4-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7v-0.1
      c-0.1-1.8-0.1-3.6-0.2-5.5c-0.1-2-0.3-4.1-0.4-6.1v-0.1c-0.1-1.9-0.3-4-0.4-6.1c-0.1-1.1-0.1-2.2-0.1-3.3c0-1.2,0-2.3-0.1-3.5
      c-0.1-1.7-0.3-3.4-0.4-5.1v-0.3c-0.1-0.7-0.1-1.4-0.2-2.1c0-0.1,0-0.2,0-0.4c15.1-3.1,26.9-4.8,38.2-5.6v65.8l-2.1,0.7
      c-2,0.7-3.9,1.3-5.8,1.9C817.8,1098.2,817.6,1098.2,817.4,1098.2z M723,1084.3L723,1084.3c0.3,1.5,0.5,3,0.9,4.5
      c0.9,4,1.6,7.3,2.4,10.3c0.4,1.7,0.9,3.5,1.3,5.1c0.4,1.3,0.8,2.8,1.1,4.3c0.5,2.1,1,4,1.6,6.4l0.1,0.3c0.5,2,1,4,1.5,6
      c0.2,0.8,0.1,1.4-0.2,1.9c-0.4,0.6-1.2,1-2.5,1.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0
      c-0.6,0-0.9-0.2-1.1-0.7c-0.3-1.1-0.6-2.3-0.8-3.4c-0.3-1.4-0.6-2.7-0.8-4.1v-0.1c-0.5-2.5-1-5-1.6-7.5c-0.3-1.4-0.7-2.7-1-4
      c-0.3-1-0.6-2-0.8-3.1c-0.4-1.5-0.7-3-1-4.4v-0.1c-0.2-0.7-0.3-1.4-0.5-2.2l-0.4-1.7c-0.3-1.4-0.6-2.7-0.9-4
      c-0.1-0.5-0.1-0.8,0-0.8c1.1-1.4,2.3-2.9,3.3-4.2l0.6-1.3C722.8,1083.4,722.9,1083.8,723,1084.3z M720,1109.5
      c0.4-0.4,0.7-0.7,1.1-1.1l5.5,27.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.6c-0.3-0.8-0.7-1.6-1-2.3
      c-1.8-4.2-3.8-8.6-6.3-12.6l-0.2-0.3c-0.5-0.8-1.1-1.7-1.3-2.6c-0.2-0.6,0.1-1.6,0.6-2.1C717.9,1111.7,719,1110.6,720,1109.5z
       M717,1121c1.1,2.2,2.2,4.4,3.2,6.6l0.8,1.7c0.2,0.5,0.4,0.9,0.7,1.5l0.2,0.5c-0.2,0-0.4,0-0.5,0c-0.3,0-0.7,0-1,0
      c-0.5,0-0.8,0-1.2,0h-0.8c-2.3,0.1-4.8,0.2-7.2,0.4c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.8
      c-0.1-1.3-0.1-2.6-0.1-4v-0.1v-0.3c0-0.4,0-0.8,0-1.2c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.9,0.5,1.1,0.9
      C715.3,1117.8,716.1,1119.3,717,1121z M709.5,1134.1c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.6-0.8l3-0.3c3.3,5.7,6.3,11.9,5.9,19.5
      H728c-2.2-0.1-4.6-0.2-6.9-0.2c-3.8,0-7,0.3-9.9,1l-0.1-1.1c-0.1-1.1-0.2-1.9-0.3-2.9c-0.3-3.3-0.6-6.1-0.9-9
      c-0.1-1.1-0.2-2.2-0.3-3.3c-0.1-0.9-0.1-1.7-0.2-2.6C709.5,1134.1,709.5,1134.1,709.5,1134.1z M734.9,1135.4l-0.1-1.6
      c-0.5-1.7-0.6-3.6-0.6-5.4c0-1.3,0.5-2.4,1.7-3.4c1.6-1.4,3.1-2.9,4.6-4.3c1-1,1.9-1.9,2.9-2.8c1.3-1.2,2.6-2.4,3.8-3.5
      c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1c2.2,1.6,4.6,3.4,7,5.1c1.3,0.9,2.6,1.9,3.8,2.9l0.1,0.1c0.8,0.6,1.6,1.2,2.4,1.8
      c0.8,0.6,1,1.4,0.8,2.5c-0.4,2-0.8,4-1.2,6v0.2c-0.1,0.7-0.3,1.4-0.4,2.1l-0.3,1.8c-0.3,1.6-0.6,3.3-0.9,4.9
      c-0.3,1.7-0.6,3.5-0.9,5.2c-0.4,2.5-0.8,4.7-1.2,7c-0.3,1.6-0.9,1.8-1.5,1.8c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2
      c-3.1-1.3-6.1-2.7-9-4.1l-1.9-0.9c-0.2-0.1-0.4-0.4-0.4-0.7C735.4,1142.9,735.2,1139.4,734.9,1135.4z M760.4,1168.7
      c0.2-2.3,0.6-4.5,0.9-6.6c0.2-1,0.3-1.8,0.4-2.7c0.1-0.9,0.6-1.4,1.7-1.7c4.8-1.1,8.8-2.1,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1
      c1.2-0.3,2.4-0.7,3.7-1c0.6-0.2,1.3-0.3,2-0.5h0.2c0.2,0,0.4-0.1,0.5-0.1c0,0.5,0,0.9,0.1,1.4c0.1,1.3,0.1,2.6,0.2,3.9v0.2
      c0.2,3.4,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4c0.3,0.6,0.1,0.9-0.3,1.4c-0.3,0.3-0.6,0.6-0.9,0.9l-0.1,0.1c-1,0.9-2,1.9-3,2.9
      c-1,1.1-2,2.3-2.9,3.5c-0.7,0.9-1.5,1.8-2.3,2.7l-0.3,0.4c-0.7,0.8-1.4,1.6-2.2,2.1c-0.3,0.1-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0
      s-0.6,0-0.9,0H775c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3,0-1.9,0c-0.6,0-1.2,0-1.7,0.1c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4
      c0.2-1.6,0.4-3.1,0.6-4.7C759.3,1176.7,759.9,1172.7,760.4,1168.7z M775.6,1253.3l-0.1-0.1c-1.2-0.8-2.4-1.6-3.6-2.4
      c-0.9-0.6-1.8-1.1-2.7-1.7c-0.8-0.5-1.7-1-2.5-1.5s-1.5-1.1-2.1-1.7c0,0-0.1-0.2-0.1-0.5c0-0.5,0-1,0-1.4v-0.3l18-15l0.5,0.4
      c0.6,0.5,1.2,1,1.8,1.5c0.8,0.7,1.6,1.4,2.4,2.1c1.8,1.5,3.6,3,5.4,4.5c1,0.8,2,1.5,2.9,2.1c0.8,0.6,1.7,1.2,2.5,1.8
      c0.9,0.7,1.8,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2c0.4,0.3,0.4,0.5,0.2,1c-0.4,1.4-0.8,2.9-1.2,4.3c-0.6,2.2-1.2,4.5-1.8,6.8
      c-0.7,2.3-1.4,4.6-2.2,6.9l-0.5,1.5c-0.2,0.6-0.5,0.6-0.6,0.6c-0.2,0-0.4-0.1-0.6-0.2c-1.1-0.7-2.1-1.4-3.2-2
      c-1.3-0.8-2.9-1.9-4.5-2.8c-1.2-0.8-2.5-1.5-3.7-2.2l-0.2-0.1c-1.5-0.9-3.1-1.8-4.6-2.8S776.9,1254.2,775.6,1253.3z M852.7,1251.2
      l-0.1-0.3c-0.2-0.8-0.4-1.5-0.6-2.3c0-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.3-1,2.6-2,3.9-2.9
      l0.1-0.1c1.6-1.2,3.5-2.6,5.3-4c1.3-1,2.6-2.1,3.8-3.1c0.7-0.6,1.4-1.2,2.1-1.8c1.1-0.9,2.2-1.8,3.2-2.7l1.5-1.2
      c0.5,0.4,1.1,0.9,1.6,1.3c1.4,1.1,2.8,2.2,4.1,3.3c0.7,0.6,1.4,1.2,2.1,1.8c0.7,0.6,1.5,1.3,2.2,1.9c2.9,2.4,5.3,4.3,7.7,6.1
      c0.4,0.3,0.4,0.4,0.3,0.7c-0.1,0.4-0.2,0.7-0.1,0.9c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.2c-3,2-6.2,4-9.3,6
      c-1.2,0.8-2.4,1.5-3.6,2.2h-0.1c-0.7,0.4-1.4,0.8-2.1,1.2l-0.5,0.3c-1.3,0.8-2.7,1.7-4.1,2.5c-1.3,0.8-2.5,1.6-3.8,2.4l-0.6,0.4
      c-1,0.6-1.9,1.2-2.9,1.9c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.6-1.7-0.8-2.5
      c-0.7-2-1.4-4.1-2-6.1C853.7,1255.3,853.1,1253.1,852.7,1251.2z M894.2,1228.3c-0.5,3.5-1.2,7.1-1.8,10.3l-0.6,1.7
      c-0.1,0.3-0.2,0.7-0.3,1v0.1c-0.7-0.6-1.5-1.3-2.2-1.9l-0.3-0.3c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.7-2
      c-0.8-0.7-1.6-1.5-2.3-2.3l-0.1-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0.2-1,0.4-1.9,0.6-2.9v-0.2
      c0.3-1.4,0.6-2.9,0.8-4.3c0.3-1.6,0.4-3.2,0.6-4.7c0.1-0.5,0.1-1,0.2-1.5c0.3-2.7,0.6-5.6,1-9.1l0.1-1.3c0.2-2.3,0.5-4.6,0.7-6.9
      c0-0.4,0-0.7-0.1-1.1c0-0.3-0.1-0.6-0.1-0.9c0-0.7,0.1-1.3,0.2-2v-0.1c0.1-0.7,0.1-1.5,0.2-2.2c0-0.5-0.1-1-0.1-1.5v-0.1
      c0-0.1,0-0.2-0.1-0.3c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1h0.2c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4
      c1.6,0.2,3.3,0.4,5,0.5c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.4-0.7,6.3
      c-0.3,2.5-0.5,5-0.8,7.6C895.9,1217.5,895,1223.3,894.2,1228.3z M894.8,1185c-1.6-0.2-3.3-0.3-4.9-0.5l-1.8-0.2
      c-2.2-0.2-4.6-0.4-6.9-0.6c-0.8,0-1.5,0-2.3,0.1c-0.5,0-1.1,0.1-1.6,0.1c-0.2,0-0.5-0.1-0.9-0.4c-1.8-1.4-3.2-3.2-4.5-4.9
      c-0.7-0.9-1.4-1.8-2.2-2.7c-1.2-1.3-2.5-2.7-4.2-4.1c-1.2-1-1.2-1-0.8-2.6c0.4-2.2,0.6-4.5,0.8-6.5c0.2-1.8,0.3-3.5,0.4-5.3
      l0.1-1.3c0.1-1.1,0.1-2.1,0.1-3.2l0.1-1.4c0.4,0.1,0.7,0.2,1.1,0.2c1.1,0.2,2.1,0.4,3.1,0.7c2.1,0.6,4.2,1.2,6.3,1.7h0.1
      c2.5,0.7,5.3,1.5,8.1,2.2c2,0.5,4,0.9,6.2,1.3c1,0.2,1.5,0.7,1.7,1.9c0.6,4.1,1.2,8.3,1.8,12.3l0.6,3.8c0.3,2.2,0.7,4.5,1,6.8
      l0.4,2.8L894.8,1185z M894.1,1155.8c-0.3-1.6-0.6-3.2-0.9-4.8v-0.3c-0.2-1.2-0.4-2.3-0.6-3.5c-0.1-0.8-0.3-1.6-0.4-2.4v-0.1
      c-0.1-0.8-0.2-1.6-0.4-2.4c-0.2-1.1-0.4-2.1-0.6-3.2v-0.2c-0.2-1-0.4-1.9-0.5-2.8c-0.2-0.9-0.3-1.7-0.5-2.6v-0.2
      c-0.2-1-0.3-2-0.5-3.1c-0.2-1-0.4-1.9-0.7-2.7c-0.4-1.1,0.2-1.8,1.2-2.5c1.3-1,2.7-2,4-2.9c1.3-1,3-2.2,4.6-3.4
      c1.7-1.2,3.3-2.5,5-3.8l0.1-0.1c0.7-0.5,1.4-1.1,2.1-1.6c0.3,0.2,0.6,0.5,0.9,0.8c0.9,0.7,1.8,1.4,2.6,2.2c1.9,1.8,3.8,3.7,5.6,5.5
      l0.1,0.1c0.8,0.8,1.6,1.6,2.3,2.3l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2c2.6,1.4,2.8,3.8,2.6,6.2c-0.3,4.7-0.7,9.9-1.3,16.1
      c0,0.3-0.2,0.5-0.3,0.6c-0.7,0.3-1.5,0.7-2.2,1.1c-3.1,1.5-6.3,3-9.5,4.3c-2.6,1.1-5.3,2.1-7.9,3c-0.8,0.3-1.6,0.6-2.4,0.9
      c-0.5,0.2-1,0.3-1.5,0.2C894.6,1156.7,894.2,1156.4,894.1,1155.8z M919.2,1168.4c0.1-0.8,0.1-1.6,0.2-2.3c0.5-4.1,0.8-7.5,1.2-10.7
      c0.2-2.2,0.4-4.5,0.6-7.1c0.1-0.8,0.1-1.6,0.2-2.4c0.2-2.6,0.4-5.3,0.5-8c0.1-2.7,0.2-5.4,0.2-8v-0.1c0-1.1,0-2.3,0.1-3.4
      c0-0.2,0-0.4,0-0.7v-0.3l0.6,0.1c0.7,0.1,1.4,0.3,2.1,0.5c0.2,0,0.3,0.1,0.5,0.1c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.2,0,0.8-0.1,1.2
      c-0.1,0.2-0.1,0.5-0.2,0.7c-0.3,1.6-0.5,3.3-0.8,4.9v0.1c-0.5,3.4-1.1,6.9-1.6,10.3c-0.3,1.9-0.5,3.8-0.6,5.6v0.1
      c-0.2,1.5-0.3,3.1-0.5,4.6c-0.3,2.6-0.8,5.2-1.2,7.7c0,0.9-0.1,1.4-0.2,2c-0.4,2.2-0.7,4.5-1.3,6.6c-0.2,1-0.4,2-0.6,3v0.1
      c-0.5,2.5-1,5.1-2.1,7.4c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5v-0.1c0.1-2.1,0.2-4.2,0.8-6.1
      C918.8,1172.9,919,1170.6,919.2,1168.4z M944.1,1131.9c-0.1,0-0.2,0-0.2,0c-0.4-0.2-0.9-0.3-1.4-0.4c-0.6-0.1-1.3-0.2-1.9-0.2
      c-1.1,0-2.2,0-3.6,0c-0.8,0-1.6,0-2.4,0c-0.6,0-1.3,0-2,0c0.2-0.4,0.3-0.7,0.5-1.1c0.5-1.1,0.9-2.1,1.4-3.1
      c0.9-1.8,1.9-3.7,2.9-5.5l0.2-0.3c0.6-1.1,1.1-2.1,1.7-3.2c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.3-0.6,0.4-0.9
      c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2c0.3,0,0.7,0,1,0c0,0.4,0,0.9,0,1.3v0.4c0,1.4,0,2.7,0,4.1
      c-0.1,2.6-0.2,5.2-0.3,7.8l-0.1,1.6C945.3,1131.5,944.9,1131.9,944.1,1131.9z M937.7,1039c0.2-1.4,0.4-2.8,0.6-4.2l0.2,1.5
      c0.3,1.7,0.5,3.3,0.8,5l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5c0.2,1.3,0.3,2.7,0.5,4c0.2,2.1,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8
      l-0.1,0.4c-0.5,2-0.8,4-1.2,6v0.1c-0.2,1.2-0.4,2.4-0.7,3.6c-0.3,1.6-0.7,3.3-1,4.9v0.1c-0.3,1.2-0.5,2.4-0.8,3.7
      c-0.2,0.9-0.3,1.7-0.5,2.7l0.2,0.4c-0.2-0.3-0.4-0.5-0.7-0.8l-0.1-0.1c-1.1-1.3-2.2-2.6-3.2-3.9c-0.2-0.2-0.3-0.7-0.2-1.1
      c0.3-1.7,0.5-3.5,0.8-5.2c0.3-1.7,0.6-3.9,0.9-5.9c0.2-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.9,0.3-2.8c0.2-1.3,0.4-2.6,0.6-4.1v-0.3
      c0.1-0.9,0.3-1.8,0.4-2.7c0.1-0.9,0.2-1.8,0.3-2.7l0.1-1.2c0-0.1,0-0.1,0-0.2c0.7-1.1,0.6-2.3,0.6-3.4c0-0.8-0.1-1.6,0.1-2.3
      c0.4-1.4,0.6-2.8,0.7-4.2C937.6,1040.2,937.6,1039.6,937.7,1039z M914.5,999.8c0.1-1.6,0.3-3.1,0.4-4.7V995
      c0.1-1.1,0.2-2.3,0.2-3.4c0.1-0.8,0.2-1.6,0.2-2.3v-0.1c0-0.2,0.1-0.4,0.1-0.7c0.3,0.3,0.6,0.7,0.9,1c0.9,1.1,1.9,2.1,2.7,3.3
      c1.6,2,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c0.9,1.6,1.8,3.3,2.7,4.9c0.5,0.9,0.9,1.8,1.4,2.7c1.6,2.9,2.6,6.2,3.3,10.4
      c0.3,1.8,0,3.5-0.3,5.1c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.8-0.8,5.6-1.3,8.5v0.1c-0.2,0.9-0.3,1.9-0.5,2.9c-0.1-0.2-0.3-0.3-0.4-0.5
      c-0.6-1-1.2-2-1.8-3l-0.1-0.2c-1.2-2-2.5-4-3.8-6c-1.1-1.6-2.3-3.3-3.4-4.8l-0.2-0.2c-0.6-0.8-1.3-1.7-1.9-2.6
      c-0.5-0.8-1.1-1.6-1.6-2.3c-0.9-1.3-1.8-2.7-2.8-4c-1.1-1.4-2.3-2.8-3.5-4.1l-0.1-0.1c-0.6-0.7-1.3-1.5-2-2.2
      c-0.1-0.2-0.2-0.5-0.2-0.7c0.2-2.4,0.5-4.8,0.7-7.2C914,1005.2,914.2,1002.5,914.5,999.8z M869.9,954.2L869.9,954.2
      c1.1,0.5,2.1,1,3.1,1.5c1.6,0.8,3.2,1.5,4.8,2.2c2.8,1.3,5.7,2.6,8.6,4c1.4,0.7,2.7,1.4,4,2.2c1.5,0.8,3,1.7,4.5,2.4
      c3.3,1.5,6.1,3.9,9,7.7c1.3,1.6,2.6,3.3,4,5.1c1,1.3,2.1,2.6,3.1,3.9c0.2,0.3,0.5,0.7,0.7,1c0.4,0.6,0.8,1.2,1.3,1.7
      c0.7,0.2,0.6,0.8,0.5,1.6c0,0.2-0.1,0.4-0.1,0.6c-0.3,2.5-0.5,5.1-0.8,7.6v0.1c-0.1,1.1-0.2,2.1-0.3,3.2l-0.6,6.2
      c-0.3,2.9-0.6,5.9-0.9,8.8c-0.1,0.6-0.1,1.2-0.1,1.8v0.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.8-0.6-1.6-1.3-2.5-1.9
      c-0.6-0.4-1.1-0.8-1.7-1.2l-0.1-0.1c-2.1-1.5-4.3-3.1-6.5-4.6c-5.5-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7
      c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7c-0.1,0-0.2-0.3-0.2-0.5c-0.1-1-0.1-1.9,0-2.7l0.1-2.4c0.2-3.8,0.4-7.7,0.6-11.6
      c0.2-2.4-0.2-4.8-0.6-7.1v-0.1c-0.1-0.4-0.1-0.8-0.2-1.1c-0.4-2.5-0.9-4.9-1.5-7.3l-0.2-0.8c-0.2-0.8-0.4-1.6-0.6-2.3
      c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.1-0.6C869.3,953.9,869.6,954.1,869.9,954.2z M828.3,944.3c0.3,0,0.6,0,0.8,0
      c0.4,0,0.7,0,1.1,0c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2c1.4,0.1,2.9,0.2,4.3,0.3c1.5,0.1,3,0.2,4.5,0.2
      c1.7,0.1,3.3,0.2,4.8,0.3c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1c1.5,0.6,3,1.2,4.5,1.9c0.4,0.2,0.8,0.7,1,1.3
      c0.5,1.7,1,3.5,1.4,5.2c1.2,5,2.2,9.5,2.4,14.1c0.1,3-0.1,6.1-0.3,9.1v0.2c-0.1,1.2-0.2,2.5-0.2,3.7c-0.1,1.2-0.1,2.5-0.2,3.7
      l-0.1,1.3c-5.9-1.4-11.8-2.9-17.6-4.4h-0.2c-7.6-2-15.5-4-23.4-5.8V944.3z M828.4,983.1c0-0.3,0-0.6,0.1-0.9l41,10.3l-2.3,41.1
      c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.3-0.3-2.6-0.6-4-0.9c-2.9-0.6-5.7-1.1-8.1-1.5c-1.8-0.3-3.9-0.7-5.9-1
      c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3c-0.9-0.1-1.7-0.1-2.6-0.2c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9
      c0-2-0.1-4-0.1-5.8v-0.1c0-1.8-0.1-3.6-0.1-5.4c0-1.4,0-2.8,0-4.1c0-1.2,0-2.5,0-3.7c0-1,0-2.1-0.1-3.1c0-1-0.1-2.2-0.1-3.3
      C828.3,985,828.4,984,828.4,983.1z M827.4,1097.3c0.3,0,0.6,0.1,1,0.2l0.2,0.1c1.1,0.3,2.2,0.7,3.3,1v0.4c1.3,0.4,2.8,0.9,4.2,1.3
      c0.5,0.1,0.6,0.3,0.5,0.8c-0.4,4.8-0.8,9.6-1.2,14.3c-0.2,2.3-0.4,4.6-0.5,6.9v0.1c-0.1,1.3-0.2,2.7-0.3,4c0,0.1,0,0.2-0.1,0.4
      l-1.5-0.2c-0.8-0.1-1.5-0.2-2.2-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0-1.3,0-1.9,0h-0.1c-1.6,0-3.3,0.1-5,0.1c-0.9,0-1.7,0.1-2.6,0.2
      c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1c-0.1-1.2-0.1-2.3-0.2-3.4c-0.2-1.8-0.3-3.7-0.5-5.5v-0.1
      c-0.2-2.3-0.4-4.7-0.6-7c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6c3.3-1.1,6.2-2,8.8-2.8
      C827.3,1097.3,827.3,1097.3,827.4,1097.3z M785.9,961.1c0.3-1.7,0.7-3.5,1.1-5.3c0.2-1.1,0.6-2.2,1.1-3.5c0.1-0.3,0.5-0.7,0.9-0.8
      c3.8-1.6,7.6-3.2,10.8-4.6c3.2-1.4,6.8-2.1,11-2.2c2.5-0.1,5.1-0.2,7.6-0.4h0.1c1.6-0.1,3-0.2,4.4-0.2c0.3,0.2,0.7,0.1,1.1,0.1
      c0.3,0,0.6,0,0.9,0h0.2c0.3,0,0.6,0,0.9,0V980c-7.9,1.8-15.8,3.8-23.5,5.8h-0.1c-5.7,1.5-11.6,3-17.4,4.4c0-0.2-0.1-0.5-0.1-0.7
      c-0.1-0.8-0.2-1.6-0.2-2.4c-0.1-1.4-0.1-2.9-0.2-4.3c0-1.4-0.1-2.8-0.2-4.3v-0.6c-0.2-3.6-0.4-7.3,0.4-10.9
      c0.4-1.9,0.8-3.7,1.1-5.6L785.9,961.1z M741.6,985.2c0.7-0.9,1.4-1.8,2-2.7c1.1-1.5,2.2-3,3.4-4.4c3-3.6,5.7-6.7,8.4-9.4
      c0.6-0.6,1.5-1.1,2.4-1.6c0.4-0.2,0.8-0.4,1.2-0.6c5.1-3.1,10.4-5.5,14.9-7.6c2.7-1.2,5.4-2.5,8-3.8l0.3-0.2
      c1.1-0.5,2.2-1.1,3.3-1.6c0.1,0,0.1-0.1,0.2-0.1l-0.2,0.5c-0.1,0.6-0.3,1.2-0.4,1.7c-0.2,0.6-0.3,1.2-0.5,1.8l-0.1,0.2
      c-0.3,1.3-0.7,2.7-1,4.1c-0.2,1.1-0.4,2.2-0.6,3.2c-0.1,0.9-0.3,2-0.5,2.9c-0.6,3-0.5,6-0.3,8.9c0,0.4,0,0.9,0.1,1.3
      c0.1,1.8,0.2,3.7,0.3,5.5c0.1,1.5,0.2,2.9,0.2,4.4c0,1,0,1.9,0,2.8c0,0.2-0.2,0.5-0.3,0.6c-1.5,0.8-3,1.7-4.4,2.5l-0.1,0.1
      c-2.2,1.2-4.4,2.5-6.5,3.7c-1.8,1.1-3.6,2.2-5.3,3.4c-0.9,0.6-1.7,1.1-2.6,1.7c-0.7,0.5-1.5,0.9-2.2,1.4l-0.4,0.3
      c-1.6,1-3.4,2.1-5.1,3.3c-2,1.3-4,2.8-5.9,4.2l-0.6,0.4c-1.3,1-2.6,1.9-4,2.9h-0.1c-0.5,0.3-0.9,0.7-1.4,1l-0.1-0.9
      c-0.1-1.1-0.2-2.1-0.3-3.1c-0.2-2.2-0.5-4.5-0.7-6.7c-0.3-2.9-0.6-5.7-0.9-8.6c-0.1-1.3-0.2-2.6-0.3-3.9v-0.1
      c-0.1-0.9-0.2-1.9-0.2-2.8c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8C740.6,986.7,740.9,986.1,741.6,985.2z M719.8,1019
      c0.3-0.5,0.5-1.1,0.8-1.6l0.1-0.1c0.8-1.7,1.6-3.4,2.5-5.1c2-3.5,4-6.7,6.1-10.1c1.6-2.6,3.2-5.1,5-7.5c1.1-1.5,2.3-2.9,3.5-4.4
      c0.4-0.5,0.9-1.1,1.3-1.6l0.2,1.9c0.1,1.4,0.2,2.8,0.4,4.2c0.2,1.9,0.4,3.7,0.5,5.6l0.1,0.7c0.1,1.4,0.3,2.9,0.4,4.3
      c0.3,3.5,0.6,6.7,0.9,10c-0.1,0.1-0.1,0.3-0.2,0.4c-0.9,1.1-1.9,2.2-2.6,3.2l-0.1,0.1c-1.6,1.9-3.2,3.8-4.7,5.7
      c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.5-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7c-0.2,0.2-0.3,0.5-0.5,0.8c-0.1-0.8-0.2-1.6-0.4-2.4
      l-0.2-1.5c-0.5-3.6-1-6.9-1.6-10.3C716.8,1027.7,717.6,1023.5,719.8,1019z M718,1085.4L718,1085.4v-0.2c-0.1-0.6-0.2-1.2-0.3-1.8
      c-0.2-0.9-0.4-1.9-0.6-2.8v-0.1c-0.3-1.6-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4v-0.1c-0.2-1.2-0.4-2.5-0.7-3.7l-0.1-0.4
      c-0.3-1.6-0.6-3.3-1.2-4.9c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.1c0.3-2.6,0.6-5.3,1-8c0.4-2.9,0.9-5.9,1.5-8.7
      c0.2,1.2,0.4,2.3,0.5,3.5c0.3,1.7,0.5,3.5,0.8,5.2l0.4,2.6c0,0.1,0,0.2,0.1,0.2c0,0,0,0.1,0,0.2c-0.4,1.3-0.1,2.6,0.2,3.9
      c0.2,0.7,0.3,1.4,0.4,2.1c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.7,1,7c0.3,1.9,0.6,3.8,0.9,5.7c0.4,2.4,0.7,5,1,7.6
      c0,0.3-0.1,0.8-0.4,1.2c-1,1.2-2,2.4-3.1,3.7l-0.1,0.2C718.3,1085,718.1,1085.2,718,1085.4z M699.1,1111.1c1.3-1.7,2.5-3.4,3.8-5.1
      c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.5-0.7,0.8-0.9l0.1-0.1c0.6-0.5,1.3-1.1,2-1.3c3.6-1,7.4-2,11.5-3.1c0.4-0.1,0.6-0.1,0.8-0.1
      c0.2,0,0.2,0,0.5,1.1c0,0.5,0.1,0.9,0.2,1.3c0.2,0.6,0.4,1.3,0.5,1.9c0.1,0.8,0.1,1.4-0.1,1.8c-0.3,0.6-0.8,1.1-1.3,1.6
      c-0.2,0.3-0.5,0.5-0.7,0.8l-0.7,0.8c-0.9,1.1-1.9,2.2-2.7,3.4c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1c-1.2,0-2.4-0.1-3.5-0.2h-0.1
      c-1.4-0.1-2.9-0.2-4.3-0.2c-1.6,0-2.9,0.1-4.2,0.4c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0.2
      L699.1,1111.1z M705,1144.4l-0.7,0.3l-0.7-2.2c-0.6-1.9-1.4-4.1-2.1-6.2c-0.3-0.9-0.7-1.8-1-2.7c-0.3-0.7-0.6-1.6-0.9-2.4
      c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2c0,0,0.2-0.2,0.3-0.2c1.8-0.4,3.7-0.9,5.5-1.3
      l1.8-0.2c0.1,0,0.2,0,0.2-0.1c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5c0.1,1.6,0.1,3.2,0.2,4.7v0.2c0,2,0.1,4,0.2,6
      c0.1,1.8,0.4,3.7,0.6,5.4v0.1c0.1,1.1,0.3,2.2,0.4,3.2c0.2,2,0.4,4,0.4,6c0,0.1-0.3,0.3-0.6,0.4
      C706.6,1143.8,705.8,1144.1,705,1144.4z M706.8,1160l-1.5-12.8l2.9-1.5l1.1,13.5L706.8,1160z M720.4,1182.9
      c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.5-1.2-1-1.8-1.5c-1.5-1.2-3.1-2.5-4.5-3.8
      c-1.4-1.3-2.3-2.4-2.8-3.5c-0.4-0.8-0.5-1.7-0.6-2.7c0-0.4-0.1-0.9-0.2-1.4c-0.3-1.5-0.5-2.7-0.7-3.9l-0.1-0.4
      c-0.3-2-0.7-3.9-1-5.9c-0.1-0.5-0.1-1-0.1-1.4c0-0.1,0-0.3,0-0.4c0.2-0.1,0.4-0.2,0.6-0.2h0.1c0.6-0.3,1.3-0.5,1.8-0.8
      c0.4-0.2,0.6-0.2,1,0.3l2.3,2.8c2.4,2.9,4.8,5.8,7.2,8.8c0.2,0.2,0.3,0.6,0.3,0.9C720.1,1177,720.1,1179.6,720.4,1182.9z
       M718.7,1168l-0.1-0.1c-1.7-2.1-3.5-4.3-5.5-6.3c-1.4-1.4-1.5-3.2-1.6-5.2c0-0.4,0-0.7-0.1-1.1c4.1-1,8.3-1.1,11.9-1.1
      c0.7,0,1.5,0,2.2,0c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2c-0.4,0-0.8,0.1-1.2,0.1h-0.4c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4
      C720.6,1170.3,719.7,1169.1,718.7,1168z M738.2,1192.4c-0.1,0.1-0.4,0.2-0.7,0.1c-1.8-0.2-3.7-0.4-5.5-0.6l-0.8-0.1
      c-2.1-0.2-4.1-0.5-6.2-0.7c-0.6-0.1-1.1-0.2-1.6-0.3c-0.4-0.1-0.6-0.3-0.5-1v-0.3c0.1-1.8,0.2-3.7,0.1-5.5c0-1.3-0.1-2.7-0.2-4
      v-0.2c0-0.5-0.1-1.1-0.1-1.6c0-0.9-0.1-1.8-0.1-2.7v-1.1l9.6-0.9c0,0.1,0.1,0.3,0.1,0.4c0.1,0.5,0.2,0.9,0.4,1.4
      c0.8,2.3,1.6,4.5,2.3,6.8l0.1,0.2c0.7,2,1.4,3.9,2,5.9c0.4,1.2,0.8,2.4,1.1,3.5C738.3,1192,738.3,1192.3,738.2,1192.4z M739,1174.9
      c-0.1-0.7-0.4-1.4-0.6-2.1c-0.2-0.7-0.5-1.5-0.6-2.2c-0.3-2.1-0.5-4.3-0.7-6.3l-0.1-0.1c-0.1-0.8-0.2-1.7-0.2-2.5
      c-0.1-0.8-0.2-1.6-0.2-2.3l-0.1-0.9c-0.2-1.4-0.3-2.9-0.4-4.3c-0.1-1.1-0.2-2.2-0.3-3.4v-0.1c0-0.4-0.1-0.8-0.1-1.2h0.1h0.1
      c0.4,0.1,0.9,0.3,1.2,0.5c1.1,0.5,2.2,1.1,3.2,1.6c2.1,1.1,4.2,2.1,6.4,3.1c2.5,1.1,5.1,1.9,7.6,2.8l0.4,0.1
      c0.5,0.2,1.1,0.4,1.6,0.5c0.8,0.3,1.6,0.4,2.5,0.6c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.1c-0.1,1-0.3,2.1-0.5,3.2
      c-0.3,1.7-0.5,3.4-0.8,5.1l-0.1,0.7c-0.4,2.2-0.7,4.5-1.1,6.8c-0.2,1.5-0.4,3-0.6,4.4c-0.2,1.5-0.4,3.1-0.7,4.8
      c-0.1,0.4-0.2,0.4-0.2,0.4c-0.2,0-0.4-0.1-0.6-0.2h-0.1c-1.3-0.7-2.6-1.5-3.9-2.3l-0.1-0.1c-0.8-0.5-1.5-1-2.3-1.5h-0.1
      c-1-0.7-2.1-1.3-3.1-2c-1.2-0.7-2.3-1.4-3.7-2.1l-0.2-0.1c-0.5-0.3-1.1-0.6-1.6-0.9C739.3,1175.9,739.1,1175.6,739,1174.9z
       M750.6,1217.4l-0.1-0.2c-1.3-2.5-2.8-5.4-4.1-8.2c-0.6-1.3-1.2-2.7-1.8-4.1c-0.7-1.6-1.3-3.3-2.2-4.9c-1.3-2.5-1.6-5.2-1.9-7.9
      v-0.1c0-0.3-0.1-0.7-0.1-1c-0.3-3-0.6-6-0.9-9.2c-0.1-1.1-0.2-2.3-0.3-3.4l15.8,9.7c-0.7,3.7-1.3,7.6-0.5,11.8
      c0.4,2.4,0.7,4.9,0.9,7.3c0.1,1.4,0.3,2.9,0.5,4.4c0.7,5.5,1.4,10.9,2.2,16.2c0.3,2.3,0.8,4.7,1.2,6.9v0.1c0.1,0.4,0.2,0.8,0.2,1.3
      c-0.3-0.6-0.5-1.1-0.8-1.7l-0.2-0.5c-1.2-2.8-2.5-5.7-3.9-8.5C753.3,1222.7,751.9,1220,750.6,1217.4z M762.1,1339.3L762.1,1339.3
      c-0.4,1-0.8,1.8-1.2,2.7c-0.1,0.2-0.2,0.4-0.3,0.6v0.1c-7.7-7.1-13.9-13.3-19.4-19.5c0.2-0.3,0.3-0.5,0.5-0.8
      c0.5-0.8,1-1.5,1.5-2.3c0.7-0.9,1.3-1.9,2.1-2.9l0.2-0.3c0.5-0.8,1.1-1.5,1.6-2.3l0.5-0.7c0.7-1,1.4-2,2-3c1.4-2.4,2.8-4.8,4.1-7.2
      l0.1-0.2c0.7-1,1.3-2,1.9-3.1c0.2,0.2,0.5,0.4,0.7,0.6l0.1,0.1c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1
      c1.8,1.3,3.7,2.6,5.5,3.8l1.4,0.9c0.4,0.3,0.5,0.7,0.4,1.4l-0.1,0.3c-0.3,1.4-0.7,2.9-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3
      c-0.5,1.2-1.2,2.5-1.8,3.7l-0.1,0.1c-0.4,0.9-0.9,1.7-1.3,2.6C763.5,1336.3,762.8,1337.8,762.1,1339.3z M805.5,1325.2
      c-0.4,12.1-0.8,24.6-1.9,37.1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.8-0.2-1.5-0.4-2.2-0.7c-1-0.4-2.1-0.8-3.1-1.2c-0.7-0.3-1.3-0.5-2-0.8
      c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-2.6-1-4-1.5c-2.5-1-5.2-2.2-8.2-3.5c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.5-1.7-5.2-2.6l-0.3-0.2
      c-0.8-0.4-1.5-0.8-2.3-1.2c-0.2-0.1-0.5-0.2-0.7-0.4l-0.1-0.1c-0.7-0.4-1.4-0.7-2.2-1c-0.3-0.1-0.4-0.2-0.4-0.3
      c-0.1,0.2-0.1,0,0-0.2c1.3-2.7,2.5-5.5,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.7-1.5,1-2.1
      c0.6-1.3,1.2-2.6,1.8-3.9c0.6-1.7,1.2-3.4,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6c0.1-0.2,0.1-0.4,0.1-0.6c0.4,0.2,0.8,0.3,1.2,0.4
      c0.4,0.1,0.9,0.3,1.3,0.4c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4
      c1.8,0.2,3.5,0.3,5.2,0.5c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1c0.4,0.1,0.8,0.1,1.3,0.2C805.6,1321.7,805.6,1323.5,805.5,1325.2z
       M805.7,1316.3c0,0.3,0,0.7,0,1c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8.1-1c-1.6-0.2-3.2-0.3-4.7-0.5h-0.1
      c-1.5-0.1-3.1-0.3-4.6-0.4c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5c0.1-0.8,0.1-1.6,0.1-2.4c0-0.5,0-1,0-1.5
      c0.1-1.1,0.1-2.3,0.2-3.4c0.2-3.7,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.6c0.2-2.5,0.5-5.2,0.7-7.7c0.1-1.9,0.2-3.8,0.3-5.5v-0.2
      c0.1-1.1,0.1-2.3,0.2-3.4c0.1-1.7,0.2-3.5,0.4-5.2c0-0.6,0.1-1.2,0.1-1.8c0.6,0.3,1.1,0.7,1.6,1c1.5,0.9,3,1.8,4.6,2.8
      c0.9,0.6,1.8,1.1,2.7,1.7c2.5,1.5,5,3.1,7.4,4.7c2.5,1.8,5.3,2.1,8.1,2.5h0.2c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0.1,0.6,0.1
      s0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6c-0.1,1.3-0.2,2.6-0.2,3.9v0.3c-0.1,1.2-0.1,2.5-0.2,3.7
      c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3c0,1.5,0.1,3.1,0.1,4.7c0,1.6,0.1,3.2,0,4.7c-0.1,2.8-0.2,5.6-0.3,8.3v0.1
      c-0.1,1.7-0.2,3.4-0.2,5.1C805.7,1314.4,805.7,1315.3,805.7,1316.3z M848.4,1362.1c-0.1,0.1-0.2,0.1-0.4,0.1
      c-0.6,0-1.3,0.1-1.9,0.2h-0.4v-0.2h-0.4c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5c-1.6,0.1-3.2,0.1-4.7,0.1
      c-0.7,0-1.5,0-2.2,0c-0.8,0-1.5,0-2.3,0.1c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2c-2.6-0.2-5.3-0.3-7.9-0.5l-2-0.1
      c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.5-0.4-0.5c0.1-2.4,0.3-4.9,0.5-7.3c0.1-1.7,0.2-3.3,0.3-5
      c0.3-4,0.5-7.9,0.7-11.7c0.1-1.3,0.1-2.7,0.1-4v-0.1c0-1.1,0-2.1,0.1-3.1c0-1.4,0.1-2.7,0.2-4.1v-0.2c0-0.9,0.1-1.9,0.1-2.9
      c0.1-1.2,0.1-2.4,0.1-3.7c0-0.4,0.1-0.5,0.1-0.6c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1c3.2,0.1,6.5,0.2,9.7,0.5
      c2.6,0.2,5.1,0.2,7.6,0c3.2-0.3,6.5-0.4,9.7-0.5h0.2c1.6,0,3.2-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7c0.1,3.9,0.2,8,0.4,12.2
      c0.1,3.3,0.3,6.6,0.5,9.8l0.2,2.9c0.2,4.6,0.5,8.7,0.7,12.9c0.1,1.4,0.2,2.8,0.4,4C848.5,1361.8,848.5,1362,848.4,1362.1z
       M847.3,1271.4l0.6-0.1l0.1-0.3v0.1c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2c2-0.2,4-0.5,5.8-1.5c2-1.2,4-2.4,6-3.7
      l0.5-0.3c0.3-0.2,0.7-0.4,1-0.6c2.4-1.5,4.9-3.1,7.4-4.7l2.8-1.8l0.1,1.2c0.1,1.2,0.1,2.4,0.2,3.6c0.3,3.5,0.5,7,0.8,10.8l0.3,4.3
      c0.2,2.4,0.4,4.7,0.6,7.1c0.1,1.2,0.2,2.4,0.3,3.6v0.1c0.2,2.3,0.4,4.7,0.6,7c0.2,3.4,0.3,6.9,0.4,10.2c0,1.1,0.1,2.2,0.1,3.3
      c0,0.3,0,0.6,0.1,1c0.1,0.8,0.2,1.6-0.1,2c-0.3,0.4-0.9,0.6-1.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.5c-1.7,0.9-3.6,1.1-5.5,1.3
      c-1.8,0.2-3.7,0.4-5.3,0.6c-1.1,0.1-2.2,0.3-3.4,0.4c-0.9,0.1-1.8,0.2-2.7,0.3h-0.1c-1,0.1-2.1,0.2-3.2,0.3
      c-1.8,0.2-3.5,0.4-5.3,0.7l-2.1,0.3L847.3,1271.4z M890.9,1345c-1.6,0.8-3.2,1.6-4.8,2.3l-0.2,0.1c-1.3,0.6-2.6,1.3-3.9,1.9
      c-0.8,0.4-1.6,0.8-2.5,1.2l-0.4,0.2c-3,1.5-6.1,3-9.2,4.3c-1.7,0.8-3.4,1.4-5.1,2c-0.9,0.3-1.8,0.7-2.7,1c-0.8,0.3-1.6,0.6-2.3,1
      l-0.3,0.1c-1.3,0.5-2.5,1.1-3.8,1.5c-0.9,0.3-1.9,0.6-2.8,0.9l-0.2,0.1c-0.4,0.1-0.9,0.3-1.3,0.4c-0.1,0-0.2,0.1-0.3,0.1
      c-0.1,0-0.2,0-0.2-0.5c-0.2-4.3-0.5-7.9-0.7-11.4c-0.1-1.1-0.2-2.2-0.2-3.3v-0.1c-0.1-1.7-0.3-3.4-0.3-5.1c-0.1-1.3-0.1-2.8-0.1-4
      c0-0.8,0-1.7,0-2.5c0-0.7-0.1-1.5-0.1-2.2s-0.1-1.4-0.1-2.1c0-1,0-2.1-0.1-3.1v-0.2c0-1.3-0.1-2.7-0.1-4.1c0-1-0.1-2-0.2-2.9v-0.1
      c0-0.3,0-0.6-0.1-0.9l3.7-0.3c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.2-0.2,3.2-0.3c1.1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9
      c0.7-0.1,1.4-0.4,2.1-0.7h0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.7-0.3,1-0.4c0.2,0.6,0.4,1.1,0.5,1.7l0.1,0.3
      c0.5,1.7,1.1,3.5,1.7,5.3c0.5,1.5,1.2,3,2.1,5c0.5,1.2,1.1,2.3,1.7,3.4c0.5,1,1,2.1,1.5,3.2c0.8,1.7,1.6,3.5,2.3,5.2l0.2,0.4
      c0.4,0.8,0.8,1.7,1.1,2.5l0.1,0.3c0.2,0.5,0.5,1.1,0.8,1.7c0.1,0.3,0.1,0.3,0.1,0.4C891.4,1344.8,891,1345,890.9,1345z
       M913.2,1322.9l0.2,0.3c-5.4,6.2-11.4,12.2-19.4,19.6c-0.2-0.3-0.3-0.6-0.5-1l-0.3-0.5c-0.6-1.2-1.2-2.3-1.7-3.5
      c-1.5-3.2-2.6-5.8-3.6-8.1c-1.9-4.6-3.8-9-5.5-13.3c-0.4-0.8-0.5-1.5-0.4-2.1c0.1-0.5,0.5-1,0.9-1.3c1-0.7,2-1.4,3-2
      c1.6-1,3.3-2.1,4.8-3.3c1.8-1.4,3.5-3,5.2-4.6c0.7-0.7,1.5-1.4,2.2-2.1c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1
      C902.7,1308.6,908,1315.8,913.2,1322.9z M914.7,1179.7c-0.1,1.2-0.2,2.4-0.3,3.5l-0.1,0.8c-0.2,2.4-0.5,5.1-0.7,7.8
      c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6l-0.2,0.4c-0.3,0.7-0.6,1.5-1,2.2l-0.1,0.2c-0.9,2.1-1.8,4.4-2.9,6.5
      c-0.9,1.8-1.9,3.7-2.7,5.3v0.1c-0.5,1-1.1,2.1-1.7,3.1c-0.8,1.5-1.5,3-2.2,4.5c-0.8,1.7-1.6,3.4-2.4,5.1l0.2,0.6
      c-0.7,1.6-1.4,3.2-2.2,4.7c-0.7,1.5-1.4,3.1-2.3,4.7c0.2-1.2,0.4-2.5,0.6-3.7c0.2-1.3,0.5-2.5,0.7-3.8v-0.2c0.5-2.7,1-5.5,1.4-8.3
      c0.4-2.5,0.6-5.1,0.8-7.6c0.1-1.5,0.3-3,0.4-4.5c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4
      c-0.1-0.6-0.2-1.3-0.3-1.9v-0.1c0-0.1,0-0.2,0-0.3l2.2-1.4c1.6-1,3.2-2,4.8-3c0.8-0.5,1.6-1,2.4-1.4c0.8-0.4,1.7-1,2.5-1.5
      c1-0.6,1.9-1.2,2.9-1.8l0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.3L914.7,1179.7z M912.8,1177.6c-2.5,1.3-4.9,2.8-7.3,4.2l-0.9,0.6
      c-0.8,0.5-1.7,1.1-2.5,1.6c-0.6,0.4-1.2,0.7-1.7,1.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2-0.4
      c-0.2-1.8-0.5-3.6-0.8-5.3l-0.3-2c-0.2-1.1-0.3-2.1-0.5-3.2v-0.2c-0.3-2.3-0.7-4.7-1.1-7.1c-0.3-1.8-0.6-3.6-1-5.4
      c-0.2-0.9-0.3-1.8-0.5-2.7c0-0.1,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3c1.1-0.2,2.1-0.5,3.1-0.8c4.4-1.3,8.7-3.1,12.8-5.1
      c0.6-0.3,1.1-0.6,1.7-0.9l0.3-0.2c1.2-0.6,2.4-1.2,3.6-1.8c0.4-0.2,0.8-0.3,1.2-0.5l0.2-0.1l-0.2,2.4c-0.2,1.9-0.3,3.8-0.5,5.6
      c-0.1,1-0.2,2-0.3,3c-0.2,2.7-0.5,5.5-0.8,8.3c-0.1,0.7-0.3,1.3-0.5,2c-0.3,0.8-0.5,1.6-0.6,2.4
      C915.9,1175.5,914.7,1176.5,912.8,1177.6z M931.7,1175.6c0,1.2,0,2.4,0,3.6c0,1.1,0,2.1,0,3.2v0.5c0,2.1-0.1,4.4-0.1,6.7
      c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9h-0.4c-1.6,0.2-3.4,0.4-5.1,0.7c-0.5,0.1-0.8,0-0.9-0.1c-0.1,0-0.1-0.2,0-0.5
      c0.4-1.2,0.8-2.5,1.3-3.7v-0.1c0.3-0.9,0.7-1.8,1-2.8c0.9-2.7,1.8-5.4,2.7-8.2l1.1-3.4l9.3,0.7V1175.6z M932.1,1171.9
      c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1c-0.1,0-0.2,0-0.3-0.1l2.9-16.9c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0c3.7,0,7.9,0.1,12.1,1.1
      l-0.1,0.8c-0.1,1.1-0.2,2.3-0.3,3.4c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3c-2,2.3-4.1,4.6-5.8,6.5
      C932.6,1171.7,932.3,1171.9,932.1,1171.9z M947.4,1162.8c0,0.3,0,0.7,0,1c-0.3,1.6-0.6,3.2-0.9,4.8s-0.6,3.2-0.9,4.8
      c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.8-0.4,1.8-0.9,2.5c-1.4,2.1-3.5,3.8-5.4,5.3c-0.8,0.6-1.6,1.2-2.3,1.9
      c-0.6,0.5-1.2,1.1-1.9,1.6l-0.4,0.3c0-0.5,0-1.1,0-1.6c0-1.8,0-3.4-0.1-5c0-1.2,0-2.4-0.1-3.7v-0.2c0-1.1-0.1-2.3-0.1-3.4
      c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8l0.1-0.1c1.4-1.6,2.7-3,3.9-4.5c0.6-0.7,1.1-1.5,1.6-2.2l0.1-0.1
      c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.4,0.5-0.5,0.7-0.5s0.3,0.1,0.6,0.2c0.5,0.3,1.1,0.5,1.7,0.8c0.2,0.1,0.4,0.1,0.6,0.2
      C947.4,1162.7,947.4,1162.7,947.4,1162.8z M947.7,1159.9l-2.5-0.7l1.1-13.5l2.9,1.4L947.7,1159.9z M959.2,1117.6
      c-0.1,1.2-0.3,2.1-0.6,2.9c-0.6,1.7-1.2,3.4-1.8,5.1v0.1c-0.5,1.4-1,2.8-1.5,4.1c-0.7,1.9-1.4,3.9-2,5.9l-0.3,0.8
      c-0.4,1.1-0.8,2.2-1.2,3.4c-0.4,1.2-0.9,2.4-1.3,3.6l-0.4,1.3l-3-1.2c-0.5-0.1-0.6-0.3-0.6-0.7l0.1-0.5c0.5-5,1.1-10.2,1.5-15.4
      c0.1-1.6,0.1-3.3,0.1-4.9c0-0.9,0-1.9,0-2.8c0-0.7,0.1-1.5,0.1-2.3v-0.1c0-0.5,0.1-0.9,0.1-1.4c0-0.4,0.2-0.5,0.6-0.5
      c0.1,0,0.2,0,0.3,0c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.2,0.3,1.9,0.5c0.8,0.2,1.5,0.4,2.3,0.5
      C959.2,1116.8,959.3,1117.2,959.2,1117.6z"
      />
      <path
        d="M629.5,1351.4l-0.3-0.2c-2-1.4-4-2.8-6.1-4.1c-3-1.9-6.2-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.1-1.7-4.6-2.5
      c-0.8-0.4-1.5-0.8-2.3-1.2l-1.3-0.7c-2.3-1.3-4.7-2.6-7-3.9c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6
      c-1.4-2-2.8-4-4.2-6l-0.3-0.4c-0.6-0.9-1.3-1.9-1.9-2.8c-0.6-0.8-1.2-1.7-1.7-2.4c-0.3-0.4-0.7-0.9-1-1.3v-0.1
      c-0.7-0.9-1.4-1.9-2-2.9c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5
      v-0.1c0-0.6,0-1.2-0.1-1.8c-0.1-1.3-0.1-2.6-0.1-3.9c0.1-2,0.2-4.1,0.3-6.1v-0.1c0.1-1.8,0.2-3.8,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4
      c0-2.5,0-5-0.1-7.4v-0.4c0-0.9,0-1.7,0-2.6c0-0.7,0-1.4,0-2.1v-0.4c0-1,0-2,0-3c0-0.4,0-0.8,0-1.2v-0.5c0-1.5,0-3.1,0-4.7
      c0-2.2,0.1-4.4,0.2-6.5c0.1-2,0.1-4.1,0.2-6.1v-0.4c0.1-3.2,0.1-6.1,1.7-9c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5,3.3-7.5l0.1-0.3
      c0.3-0.6,0.5-1.3,0.8-1.9c0.3-0.7,0.5-1.3,0.8-2v-0.1c0.3-0.7,0.5-1.4,0.9-2.1c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1
      c0.4,0,0.7,0,1.1-0.1c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.1,7-6,9.9-8.7
      c1.3-1.2,2.9-2.6,2.9-5c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6c0.2-1,0.4-2,0.5-3v-0.2c0.3-1.8,0.7-3.6,1-5.4
      c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2c0.4-3.2,0.8-6.5,1.1-9.4c0-0.4,0.1-0.8,0.1-1.2c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9
      v-0.1c0.3-0.7,0.6-1.4,0.8-2.1c1.5-4,2.7-7.3,3.7-10.3l0.5-1.3c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5
      c0.4-1.1,0.8-2.4,0.2-3.8c-1-1-1.5-1.9-2.2-2.8c-2.3-3.3-4.7-6.6-6.7-9.4c-0.9-1.3-1.8-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.8,0.4-1.7,0.5-2.5l0.1-0.3
      c0.3-1.4,0.6-2.7,0.9-4.1c0.2-0.8,0.3-1.6,0.5-2.3v-0.1c0.2-0.8,0.3-1.6,0.5-2.4c0.3-1.4,0.6-2.7,0.9-4.1v-0.1c0.5-2.3,1-4.7,1.5-7
      c0.3-1.4,0.5-2.8,0.8-4.3c0.2-1.4,0.5-3,0.8-4.4c0.1-0.5,0.2-1,0.3-1.6v-0.1c0.5-2.1,1-4.2,0.7-6.4c-0.3-3.1-0.7-7.2-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-1-0.4-1.9-0.6-2.8c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.2-2.2-1.9-3.5
      c-0.7-1.2-1.4-2.6-2.2-4c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-0.9-1.4-1.4-2c-0.4-0.5-0.8-1.1-1.1-1.6c-0.5-0.7-0.9-1.4-1.3-2.1
      l-0.1-0.1c-0.8-1.3-1.6-2.6-2.6-3.8c-1-1.3-2-2.5-3-3.6c-0.9-1-1.8-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.8-3.8v-0.1
      c-0.7-1-1.4-2.1-2.2-3.1c-0.6-0.8-1.2-1.5-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.8-6-8.2-8.1c-2.5-1.5-5.2-3-7.9-4.3
      c-2.5-1.2-5-2.3-7.5-3.5c-2-0.9-4.3-1.9-6.5-3c-2.2-1-4.3-2.1-6.4-3.2c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1
      c-2-0.4-4.1-0.5-6-0.6h-0.3c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3
      c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1
      c-3.3,0.2-6.7,0.5-9.8,2.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4
      c-2,0.9-4.1,2-6,3c-1.3,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.8,1.8-5.6,2.6c-1.9,0.9-4,1.8-5.9,2.8c-1,0.5-1.9,1-2.9,1.6
      c-1,0.6-2,1.1-3,1.6c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0.5-0.8,1.1-1.2,1.6c-0.6,0.7-1.2,1.4-1.8,2.1
      c-0.9,1-1.8,2.1-2.6,3.2c-1.1,1.4-2.2,2.9-3.1,4.2l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.1-2.6,3.2v0.1c-0.8,1-1.8,2.2-2.7,3.4
      c-0.7,0.9-1.3,1.8-1.8,2.7c-0.3,0.4-0.6,0.9-0.8,1.3c-0.6,0.9-1.2,1.8-1.7,2.6c-1.2,1.9-2.5,3.8-3.7,5.8c-1.9,3.3-3.7,6.9-5.2,9.9
      c-1,2-1.7,3.7-2.1,5.3c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10-2.3,14.3c-0.3,2.2-0.6,4.6-0.8,6.6c-0.2,1.5-0.3,3.1-0.5,4.7
      c-0.3,2.7-0.2,5.4,0.4,8.3c0.3,1.2,0.5,2.5,0.8,3.7c0.4,2.1,0.9,4.2,1.3,6.3c0.2,1.2,0.5,2.5,0.6,3.6v0.1c0.2,1.3,0.4,2.6,0.7,3.8
      c0.3,1.5,0.6,3,1,4.5c0.3,1.2,0.6,2.5,0.8,3.8c0.3,1.5,0.6,3,0.9,4.4c0.2,0.8,0.3,1.7,0.5,2.5c0.1,0.4,0.2,0.7,0.2,1v0.1
      c0,0,0,0-0.1,0c-0.8,0.2-1.6,0.4-2.5,0.5l-0.5,0.1c-2.1,0.5-4.4,1-6.7,1.5c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1
      c-2.4,3.3-5.3,7.1-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3c0.8,2.9,1.7,5.9,3,9.7c0.4,1.3,1,2.6,1.5,3.9c0.5,1.1,0.9,2.3,1.4,3.5
      c0.7,1.9,1.3,3.8,1.9,5.7l0.1,0.3c0.4,1.1,0.7,2.3,1.1,3.4c0.2,0.5,0.3,0.9,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2
      c0.2,0.9,0.2,1.9,0.3,2.8c0,0.7,0.1,1.4,0.1,2.1l0.1,0.9c0.5,3.9,0.9,8,1.5,12c0.4,2.8,0.9,5.6,1.4,8.3v0.2
      c0.1,0.6,0.2,1.3,0.4,1.9c0,0.2,0.1,0.5,0.1,0.7c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6c0.8,0.8,1.8,1.5,2.7,2.2l0.1-0.1
      v0.1c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7c0.2,0,0.4,0.2,0.5,0.3
      c0.3,0.6,0.5,1.3,0.8,2v0.1c0.3,0.7,0.5,1.4,0.8,2.1c0.3,0.6,0.5,1.3,0.8,1.9c1.1,2.5,2.2,5,3.4,7.5s2.6,5.1,3.9,7.6v0.1
      c0.9,1.7,1.8,3.5,2.8,5.3c0.8,1.7,1.7,3.5,1.7,5.6c0,3.4,0.1,6.8,0.2,10.1c0.1,2.6,0.1,5.2,0.1,7.8c0,3.2,0,6.5,0,9.4v0.6
      c0,1.4,0,2.7,0,4.1v1.4c0,1.7,0,3.7,0,5.7c0,1.6,0.1,3.2,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6c0,1.1,0,2.1,0,3.2c0,1.8,0,3.6,0.1,5.4
      c0.1,2.2,0.2,4.3,0.3,6.4c0.1,1.5,0.2,3.1,0.3,4.6c0.1,1.1,0.1,2.3,0,3.8c0,0.1,0,0.3,0,0.4c0,0.6,0,1.3-0.2,1.7
      c-0.5,1-1.1,2-1.6,3c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.8,1.3-1.2,1.9v0.1
      c-0.7,1-1.3,2.1-2.1,3.1s-1.6,2.1-2.4,3c-0.9,1-1.8,2.2-2.7,3.4l-0.1,0.1c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2
      c-2.6,1.5-5.7,3.2-8.5,4.8c-1,0.6-2.1,1.3-3,1.8c-0.9,0.6-1.9,1.2-2.9,1.8c-0.9,0.5-1.9,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2
      s-2.3,1.5-3.3,2.3c-0.7,0.5-1.5,1-2.3,1.6c-1,0.6-1.9,1.2-3,1.9h-0.1c-0.5,0.3-1,0.6-1.5,0.9l-1,0.6l1.2,0.1c0.1,0,0.1,0,0.2,0
      s0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c1.1-0.5,2.1-1,3.2-1.6l0.4-0.2c3-1.5,6.2-3,9.3-4.6c2-1,4-2.2,6-3.4l1.2-0.7
      c0.7-0.4,1.5-0.9,2.2-1.3l0.1-0.1c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.3-1.2,3.3-1.7c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6
      c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1l0.1-0.1c0.4-0.4,0.7-0.7,1.1-1.1l0.1-0.1c0.7-0.7,1.4-1.5,2.1-2.3c0.7-0.7,1.3-1.4,2-2.3l0.8-0.9
      l0.4,0.5c0.4,0.5,0.8,1,1.2,1.4c0.4,0.4,0.7,0.9,1.1,1.3c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8l0.1,0.1
      c0.6,0.6,1.2,1.2,1.9,1.8c0.7,0.7,1.5,1.4,2.2,2.2c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5
      c-0.1,0.3-0.3,0.6-0.4,1.1c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.1c-0.3,0.7-0.7,1.5-1.1,2.2c-0.8,1.7-1.6,3.4-2.3,5.1
      c-0.9,2.3-1.8,4.6-2.6,6.9c-0.2,0.7-0.5,1.4-0.7,2c-0.1,0.3-0.2,0.7-0.4,1c-0.5,1.4-1,2.8-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7
      c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.4-0.1,0.5l-0.1,0.4l0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1
      c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2c0.9-2.9,1.7-5.3,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7
      c0.5-1.2,1.1-2.3,1.7-3.5l0.1-0.1c0.2-0.3,0.3-0.6,0.5-1c0.4,0.2,0.8,0.5,1.2,0.7h0.1c1.2,0.7,2.3,1.4,3.5,2
      c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.2,1.3,4.8,1.8l0.2,0.1c0.8,0.3,1.6,0.6,2.4,0.9
      c1.1,0.4,2.2,0.8,3.2,1.2c2.2,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,0.7-0.1,1.4-0.2,2.1l-0.1,0.7
      c-0.2,2.7-0.4,5.5-0.6,8.3c-0.1,1.4-0.1,2.8,0,4.1c0,0.6,0,1.2,0,1.8v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.3-2.6,0.3-3.8
      c0-0.8,0.1-1.5,0.1-2.2c0.1-1.1,0.2-2.1,0.3-3.2v-0.1c0.1-1.2,0.2-2.4,0.3-3.5c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0
      c0.6,0.1,1.3,0.1,2,0.1h0.1c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4
      c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.5
      c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.2
      c0.3,3.4,0.5,7,0.9,10.5c0.1,1,0.4,1.9,0.7,2.8v0.1c0.1,0.4,0.2,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4
      c-0.1-1.8-0.3-3.7-0.4-5.3c-0.1-1-0.2-2.3-0.3-3.5c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3
      c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.2,1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.7-1.6,5.5-2.4l0.3-0.1
      c3.1-1.4,6.4-2.8,9.5-4.2c2-0.9,4.4-2,6.8-3.2c1.2-0.6,2.4-1.3,3.6-2l0.1-0.1c0.4-0.2,0.8-0.5,1.2-0.7c0.3,0.6,0.6,1.3,1,2
      c0.9,1.8,1.8,3.6,2.6,5.5c1,2.4,1.9,4.9,2.7,7.3l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.8,8.1c0.4,0.6,1,1,1.6,1
      c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4
      c-0.5-1.6-1.2-3.1-1.8-4.4l-0.3-0.6c-1-2.1-2-4.3-3.1-6.4c-1.1-2-1-2.1,0.6-3.6c1.5-1.4,3-2.8,4.5-4.3l0.6-0.5
      c0.6-0.6,1.2-1.2,1.8-1.8c0.9-1,1.9-2,2.9-2.9c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.2,1.3
      c1.7,1.8,3.3,3.6,4.9,5.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.5,0.8,0.8,1
      c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.2,1.2,3.3,1.8l0.2,0.1c1.5,0.8,3.1,1.7,4.7,2.6c1.2,0.7,2.4,1.4,3.6,2.2c1.3,0.8,2.6,1.6,3.9,2.3
      c2.2,1.2,4.5,2.4,6.6,3.4c1.7,0.9,3.5,1.7,5.3,2.5c0.6,0.3,1.2,0.6,1.8,0.8h0.1c0.2,0,0.5-0.1,0.5-0.4v-0.4h-0.3
      C631,1352.4,630.3,1351.9,629.5,1351.4z M587.2,1179.8c-0.1,1.2-0.2,2.3-0.3,3.4l-0.1,0.8c-0.2,2.5-0.5,5.2-0.7,7.7
      c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6l-0.2,0.4c-0.3,0.8-0.7,1.6-1,2.4c-0.9,2-1.8,4.4-2.9,6.5
      c-0.9,1.8-1.8,3.6-2.7,5.3c-0.5,1-1.1,2.1-1.7,3.2c-0.7,1.5-1.5,3-2.2,4.5c-0.8,1.7-1.6,3.4-2.4,5.1l0.1,0.4
      c-0.8,1.7-1.6,3.4-2.3,5.1c-0.7,1.5-1.4,3.1-2.3,4.7c0.2-1.2,0.4-2.5,0.6-3.7c0.2-1.3,0.5-2.7,0.7-4.1c0.5-2.7,1-5.5,1.4-8.3
      c0.4-2.5,0.6-5.1,0.8-7.6c0.1-1.6,0.3-3,0.4-4.5c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4
      c-0.1-0.6-0.2-1.3-0.3-1.9v-0.1c0-0.1,0-0.2,0-0.3l2.1-1.3c1.6-1,3.3-2.1,5-3.1c0.8-0.5,1.6-1,2.4-1.4c0.8-0.4,1.7-1,2.5-1.5
      c1-0.6,2-1.2,2.9-1.8c0.3-0.2,0.5-0.4,0.8-0.5L587.2,1179.8z M572.1,1263.7c0,2.3,0.1,4.6,0,6.8c-0.1,4.4-0.3,8.9-0.5,13.2
      l-0.1,3.3c0,0.6,0,1.2-0.1,1.8c-0.1,2.6-0.2,5.3-0.5,7.9c-0.1,0.8-0.8,2-1.4,2.3c-1.5,0.6-2.5,1.6-3.5,2.6
      c-0.5,0.5-1.1,1.1-1.7,1.5c-0.7,0.5-1.4,1-2.2,1.7c-0.9,0.7-1.9,1.5-2.8,2.2c-1.2,0.9-2.5,1.8-3.7,2.6l-0.3,0.2
      c-0.3,0.2-0.6,0.4-0.9,0.6c-0.1,0.1-0.3,0.2-0.5,0.3l-0.1-1.4c-0.1-1.4-0.2-2.7-0.3-4.1c-0.1-1.2-0.1-2.3-0.2-3.5v-0.3
      c-0.1-2-0.2-4.4-0.3-6.6c-0.2-2.2-0.4-4.4-0.5-6.6v-0.1c-0.1-1.6-0.3-3.3-0.4-5c-0.2-2.3-0.3-4.6-0.5-6.8v-0.3
      c-0.2-2.7-0.4-5.4-0.6-8.1c-0.3-3.4-0.7-6.8-1-10.1c-0.1-0.6,0.1-0.8,0.5-1.1c4.2-2.6,8.3-5.2,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3
      c0.8-1.1,1.5-2.1,2.2-3.1c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.1-0.3,0.2-0.5c0.8-1.8,1.6-3.6,2.4-5.4l0.4-0.9
      c0.6-1.5,1.3-2.9,1.9-4.4c0.4-0.8,0.7-1.6,1.1-2.5v0.3c0,1.4,0,2.9,0,4.3c0,2.4-0.1,4.8-0.1,7.2c0,2.2-0.1,4.5-0.1,6.8
      c-0.1,3.4-0.1,7-0.1,10.5C572,1260.7,572,1262.2,572.1,1263.7z M514.1,1269.5c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.6,0-1,0.1
      h-0.3c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3c-4.9-0.4-9.8-0.9-14.6-1.4h-0.2c-1.5-0.1-3-0.4-4.4-0.6h-0.3
      c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5c0.3-1.1,0.6-2.2,0.9-3.3
      c0.4-1.3,0.7-2.6,1-3.7l0.3-1.2c0.7,0,1.3,0,1.9,0h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0c2.7,0.1,5.9,0.2,9,0.5
      c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2h0.2c0.4,0,0.8,0,1.2,0c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0
      c0.1,0.4,0.2,0.8,0.3,1.2l0.1,0.3c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.8,1.1,3.8,1.6,5.3c0.7,2.3,1.4,4.7,2.1,7.1
      c0.2,0.8,0.1,1.8-0.1,2.7v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1C517.6,1269.1,515.8,1269.3,514.1,1269.5z
       M518.5,1317c-0.1,0-0.1,0-0.2,0l-2,0.1c-2.9,0.1-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0c-0.7,0-1.5,0.2-2.2,0.3
      c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2c-0.3,0-0.5,0-0.8,0
      c-1.1,0-2.2,0-3.4-0.1c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.9-0.1h-0.1c0-1.5,0.1-3,0.2-4.5v-0.1
      c0.1-3.5,0.3-7.2,0.3-10.8c0-1.8,0-3.7,0-5.5v-0.1c0-2.1,0-4.4,0-6.6c0.1-2.1,0.2-4.2,0.4-6.2c0.1-1.5,0.2-3.3,0.3-5
      c0.1-1.5,0.1-3.1,0.1-4.8v-0.3c0-0.5,0-1,0-1.5c5.9,0.6,11.9,0.9,17.7,0.9c5.9,0,11.9-0.3,17.7-0.9l0.2,7
      c0.1,5.1,0.3,10.4,0.4,15.6c0.1,5.1,0.2,10.3,0.4,16L518.5,1317z M427.6,1281.8c0-0.9-0.1-1.8-0.1-2.7v-0.7
      c-0.1-3.4-0.2-6.9-0.3-10.4c0-2.7,0-5.4,0.1-8v-0.1c0-2.3,0.1-4.7,0.1-7c0-4.6-0.1-9.3-0.2-13.9v-1c0-1.8-0.1-3.5-0.2-5.2v-0.1
      c0-1.1-0.1-2.3-0.1-3.4v-0.1c0.5,1.2,1,2.3,1.5,3.5c1.5,3.4,3,6.9,4.4,10.4c0.2,1.4,1,2.5,1.7,3.5c0.4,0.6,0.8,1.2,1.2,1.8
      c0.3,0.5,0.9,0.8,1.4,1.1c0.2,0.1,0.5,0.2,0.7,0.3c0.9,0.6,1.8,1.1,2.6,1.7c2,1.3,4,2.6,6.1,3.7c2.1,1.1,2.8,2.3,2.4,4.6
      c-0.6,3.7-0.8,7.4-1.1,11c-0.1,1-0.2,2.1-0.2,3.1c-0.2,1.9-0.3,3.8-0.4,5.6v0.3c-0.1,1.1-0.1,2.2-0.2,3.3c-0.2,3.3-0.5,6.5-0.7,9.8
      v0.1c-0.2,2.5-0.4,5-0.5,7.5c-0.2,3.3-0.4,6-0.6,8.5c0,0.5,0,0.9,0,1.4v0.1l-0.3-0.2c-0.6-0.4-1.1-0.7-1.6-1.1
      c-0.6-0.4-1.2-0.9-1.9-1.3c-1.5-1-3-2-4.4-3.1c-1.3-1.1-2.6-2.2-3.8-3.4c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.2-0.6-0.4-1-0.6
      c-1.2-0.4-2-1.5-2-2.9c-0.1-2-0.2-4.1-0.3-6.1c-0.1-1-0.1-2-0.2-3v-0.6C427.7,1284.9,427.6,1283.3,427.6,1281.8z M430.9,1212.5
      c-0.3-0.8-0.4-1.7-0.5-2.6c-0.3-2.3-0.6-4.8-0.9-7.3l-0.1-0.7c-0.3-2.4-0.5-5-0.5-7.4c0-1.6,0.3-3.2,0.5-4.7
      c0.1-0.6,0.2-1.1,0.3-1.7s0.2-0.7,0.7-0.8c1.6-0.1,3.3-0.3,4.9-0.5c1.2-0.1,2.6-0.3,4-0.4c1.9-0.2,4-0.3,6.2-0.4c0.3,0,0.6,0,1,0
      c0.7,0,1.4,0,2.1,0c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.7,0.1,1.1v0.1c0.1,1.1,0.2,2.1,0.3,3.2c0,0.4,0,0.9,0,1.4c0,0.3,0,0.7,0,1
      c0,0.4,0,0.8,0,1.2c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2,0.4,3c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4v0.1
      c0.2,2.4,0.5,4.9,0.8,7.3c0.5,3.4,1.1,6.8,1.7,10.2l0.1,0.3c0.1,0.5,0,0.9-0.4,1.4c-1.6,1.9-3.6,3.4-5.5,4.8l-0.1,0.1
      c-0.5,0.4-1,0.7-1.5,1.1c-1.6,1.2-3.1,2.5-4.6,3.8c-0.5,0.5-1.1,0.9-1.6,1.4c-1,0.8-1.9,1.6-3,2.5l-1.4,1.2
      c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-0.9-0.4-1.7-0.6-2.6l-0.4-2.5c-0.7-3.7-1.3-7.6-1.9-11.4c-0.4-2.7-0.7-5.4-1.1-8.1v-0.1
      C431.2,1214.8,431,1213.6,430.9,1212.5z M407.8,1176.9c-0.9-4-1.7-7.9-2.3-11.5c-0.5-2.9-1.2-6.7-1.8-10.5
      c-0.2-1.4-0.4-2.9-0.5-4.3v-0.1c-0.1-1-0.2-2.1-0.3-3.1c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8c-0.6-3.7-1.1-7.5-1.7-11.1l-0.1-0.6
      c-0.1-0.4-0.1-0.9-0.3-1.3c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4c0.5-0.1,1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6
      c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.8c0.1,2.5,0.3,5,0.5,7.4c0.1,1,0.2,1.9,0.2,2.9
      c0.1,1.3,0.2,2.7,0.3,4v0.1c0.3,3.6,0.6,7.3,0.9,10.9c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5
      c0.1,1,0.2,2,0.3,2.9c-0.2-0.6-0.4-1.1-0.6-1.7v-0.1C408.4,1178.8,408,1177.9,407.8,1176.9z M381.8,1134.4c0-0.2,0-0.3,0.1-0.3
      c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.6-0.8l3-0.3c3.3,5.7,6.3,11.9,5.9,19.5h-0.4c-2.2-0.1-4.5-0.2-6.9-0.2c-3.8,0-7,0.3-9.9,1
      l-0.1-1.1c-0.1-1-0.2-1.9-0.3-2.8c-0.3-3-0.6-6-0.9-9c-0.1-1.1-0.2-2.1-0.3-3.3v-0.2C381.9,1136,381.9,1135.2,381.8,1134.4z
       M391.3,1089.1c-0.1-0.5-0.1-0.8,0-0.8c1.1-1.4,2.3-2.9,3.3-4.2l0.4-1.2c0.1,0.4,0.1,0.7,0.2,1.1l0.1,0.4c0.3,1.5,0.5,2.9,0.9,4.4
      c0.9,4,1.6,7.3,2.4,10.3c0.4,1.7,0.9,3.4,1.3,5v0.2c0.4,1.4,0.7,2.8,1.1,4.1c0.5,2.1,1.1,4.2,1.6,6.3v0.1c0.5,2,1,4.2,1.6,6.3
      c0.2,0.8,0.1,1.4-0.2,1.9c-0.4,0.6-1.2,1-2.5,1.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0
      c-0.6,0-0.9-0.2-1.1-0.7c-0.3-1.1-0.6-2.3-0.8-3.4c-0.3-1.3-0.6-2.7-0.8-4v-0.1c-0.5-2.5-1-5.1-1.6-7.6c-0.3-1.3-0.7-2.7-1-3.9
      v-0.1c-0.3-1-0.6-2-0.8-3.1c-0.4-1.5-0.7-3-1-4.5v-0.1c-0.1-0.7-0.3-1.4-0.4-2.1c-0.1-0.6-0.3-1.2-0.4-1.7v-0.1
      C391.8,1091.7,391.5,1090.4,391.3,1089.1z M455.4,1001.1c0.1,2.1,0.3,4.3,0.4,6.4v0.4c0.2,2.8,0.4,5.8,0.6,8.7
      c0.1,2.5,0.2,5,0.3,7.4c0.1,1.8,0.1,3.7,0.2,5.6c0,0.9,0.1,1.7,0.2,2.6c0.3,0.3,0.4,1,0.4,1.6c0,0.2-0.1,0.5-0.1,0.6
      c-2.8,1.7-6,3.6-9.1,5.4c-2.1,1.2-4.3,2.4-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3c-1.5,0.8-3.1,1.7-4.6,2.5
      c-0.8,0.4-1.5,0.8-2.4,1.3l-0.7,0.4c-0.2-0.6-0.3-1.1-0.5-1.7v-0.1c-0.4-1.6-0.9-3.2-1.2-4.8l-0.2-0.7c-0.7-2.8-1.3-5.8-1.8-8.7
      c-0.5-3.2-1-6.5-1.4-9.7v-0.2c-0.2-1.3-0.4-2.7-0.5-4c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.3-1.7c-0.4-1.6,0.1-2.8,1.7-4
      c1.4-1,2.7-2,4-2.9c1.3-1,2.6-2,3.9-2.9c1.3-0.9,2.7-1.8,4.1-2.7c0.8-0.5,1.6-1,2.3-1.5c1.1-0.7,2.2-1.4,3.3-2.2c2-1.3,4-2.7,6.1-4
      c1.8-1.1,3.7-2.2,5.6-3.2c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5c0.2-0.1,0.4-0.2,0.7-0.4c0,0.5,0,0.9,0,1.4v0.6
      C455.3,997.5,455.3,999.3,455.4,1001.1z M498.3,1027.1c0,0.7-0.2,0.8-0.9,0.8c-0.9,0.1-1.8,0.2-2.6,0.2h-0.1
      c-3,0.3-6.2,0.5-9.2,0.9c-5.3,0.7-10.9,1.6-17.7,2.7c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5l-2.3-41.1l41-10.3v2.5
      c0,2.4,0,4.8,0,7.1c0,0.5,0,1.1,0,1.6v0.1c0,0.6,0,1.2,0,1.9c0,1.6,0,3.1-0.1,4.7v0.2c0,1.8-0.1,3.7-0.1,5.5
      C498.3,1012.1,498.2,1019.5,498.3,1027.1z M615.6,1152.4l-0.1,0.8c-3-0.7-6.2-1-10.1-1c-2.4,0-4.7,0.1-7.1,0.3
      c-0.4-7.8,2.7-14.2,5.9-19.4c0.5,0,0.9,0,1.4,0.1h0.7c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6c0.1,0,0.2,0.2,0.2,0.3
      c-0.3,3.9-0.7,8-1.1,11.6l-0.3,4.3C615.7,1151,615.7,1151.6,615.6,1152.4z M397.3,1083c-0.3-1.3,0-2.3,0.9-3.2
      c1.5-1.5,3-3.1,4.2-4.4c1.4-1.4,2.8-2.9,4.2-4.4c1.3-1.3,2.5-2.6,3.8-3.9l0.2-0.2c1.7-1.7,3.5-3.6,5.3-5.5c1.1-1.2,2.2-2.4,3.2-3.6
      c0.5-0.6,1-1.2,1.6-1.8l0.1-0.1l0.1,0.7c0.2,0.9,0.3,1.7,0.4,2.6l0.1,0.5c0.5,3.4,1,7,1.5,10.5c0.6,4.1,1.1,8.3,1.6,12.1l0.1,0.6
      c0.2,1.5,0.4,3,0.5,4.6v0.3c0.1,1,0.1,1.9-0.3,2.8c-0.5,1.2-0.8,2.5-1.1,3.8v0.1c-0.1,0.4-0.2,0.9-0.3,1.3l-0.3,1.4
      c-0.6,2.4-1.2,4.9-1.8,7.4c-0.2,0.9-0.4,1.9-0.5,2.8v0.1c-0.2,0.9-0.3,1.9-0.6,2.9c-0.1,0.5-0.4,1-0.8,1.4
      c-1.2,1.1-2.4,2.2-3.6,3.3l-0.3,0.3c-1,0.9-2,1.8-3,2.8c-1.3,1.2-2.6,2.5-3.9,3.8l-0.1,0.1c-0.5,0.5-1,0.9-1.5,1.4l-0.6-2.5
      c-0.6-2.2-1.1-4.4-1.6-6.5c-0.4-1.7-0.8-3.3-1.3-5c-0.7-2.6-1.4-5.3-2-8c-0.9-3.5-1.5-6.2-2.1-8.7c-0.3-1.4-0.6-2.8-0.9-4.1v-0.2
      C398.1,1086.7,397.7,1084.8,397.3,1083z M459.5,1073.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.9,0-2.8-0.1c-1.9,0-3.9-0.1-5.9-0.1h-0.1
      c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5c-0.7,0.9-1.4,1.7-2.2,2.5c-0.6,0.6-1.2,1.3-1.8,2
      c-0.9,1.1-1.7,2.2-2.5,3.3v0.1c-0.5,0.7-1,1.3-1.5,2c-0.6,0.7-1.2,1.5-1.7,2.2l-0.1,0.1c-0.2,0.2-0.3,0.4-0.5,0.6l-0.2-1.7
      c-0.1-1.1-0.2-2.1-0.3-3.2c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.8-0.4-3.9-0.7-5.9c-0.5-3.7-1-7.5-1.4-10.7c-0.3-2.3-0.7-4.4-1-6.4
      v-0.1c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.5-3.5l0.4-0.2c1.1-0.6,2.2-1.2,3.3-1.8c1-0.6,2.1-1.2,3.1-1.7l0.3-0.2
      c1.5-0.8,3-1.7,4.6-2.5c1-0.5,2-1.1,3-1.6c1.4-0.7,3-1.5,4.5-2.4c1.5-0.8,3-1.8,4.4-2.6c0.7-0.4,1.3-0.8,2-1.2
      c0.4-0.2,0.7-0.4,1.1-0.7l0.6-0.4l2,37.2L459.5,1073.6z M460.3,1076.4h0.1h0.1l-1.5,17.3l-0.4,5.1c-0.2,0-0.5,0-0.7,0
      c-0.4,0-0.7,0-1.1,0c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7h-0.1c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1
      c-2.9-1.9-5.8-3.9-8.7-5.9c-0.9-0.6-1.8-1.3-2.7-1.9c-0.3-0.2-0.6-0.5-0.9-0.7c0.4-0.6,0.9-1.1,1.3-1.7c1.1-1.5,2.2-2.9,3.4-4.3
      c1.1-1.3,2.3-2.7,3.5-3.9l0.1-0.1c0.8-0.9,1.7-1.8,2.5-2.8c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0
      c2.2-0.1,4.2-0.1,6.1-0.1c4.2-0.1,7.9-0.1,11.3-0.1C459.7,1076.3,460,1076.4,460.3,1076.4z M425.7,1095.7c0.3-1.1,0.5-2.2,0.8-3.3
      v-0.1c0.1-0.3,0.2-0.6,0.2-1l13.7,9.4l-3.6,3.7c-0.8,0.8-1.6,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.9l1,0.9
      c1.8,1.5,3.6,3.2,5.4,4.7c0.6,0.5,1.2,0.9,1.8,1.3h0.1c0.2,0.1,0.3,0.2,0.5,0.3l-4.3,7.9c-0.2-0.1-0.5-0.3-0.7-0.4
      c-3.3-2.4-7.1-5.2-10.9-8.1c-1.8-1.4-3.4-2.6-4.8-3.7c0,0-0.1-0.3-0.1-0.5C423.5,1105.5,424.6,1100.5,425.7,1095.7z M444.6,1117.4
      c0.1-0.1,0.4-0.3,0.5-0.3c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2c0.6,0,1.2,0,1.8,0c0.8,0,1.6,0,2.3,0c1,0,2,0,3,0
      c0.6,0,1.1,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0.1v9.7l-18.6-2.5l0.7-1.3C442.2,1121.6,443.4,1119.4,444.6,1117.4z M438.2,1139.4
      L438.2,1139.4c0.1-1,0.2-1.9,0.4-2.8c0.4-1.6,0.7-3.1,1-4.4v-0.1c0.1-0.5,0.2-1.1,0.3-1.6l0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1
      c0-0.1,0.3-0.3,0.4-0.3h0.1c3.1,0.3,6.6,0.7,9.9,1.1c1.5,0.2,3,0.5,4.4,0.8c1,0.2,1.9,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1
      c0.1,3.4,0.2,6.3,0.4,8.9c0.1,1.3,0.2,2.7,0.4,3.9v0.1c0.1,0.8,0.2,1.7,0.3,2.5c0.1,0.7,0.1,1.3,0.1,2.1v0.2c0,0.2,0,0.5,0,0.8
      l-24.9,6.7c0-0.2,0-0.4,0.1-0.6c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.4,0.6-2.8,0.8-4.3l0.1-0.5c0.5-2.3,0.9-4.4,1.3-6.5
      C438,1141.4,438.1,1140.4,438.2,1139.4z M459,1115.1c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3c-1.5,0-3.1-0.1-4.6-0.3
      c-0.4,0-0.8-0.1-1.2-0.1c0,0,0,0,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.4-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.1
      s-0.4,0-0.5-0.1c-1.8-1.5-3.6-3-5.7-4.8l-1.4-1.2l0.4-0.3c0.4-0.3,0.7-0.6,1-0.9c0.4-0.4,0.8-0.7,1.2-1.1c1.1-0.9,2.2-1.9,3.2-2.9
      c1.5-1.5,3.4-1.5,5.2-1.5h0.3c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.4,0.5,3.6,1.5c1,0.8,2,1.6,3,2.4
      c0.5,0.4,1.1,0.9,1.6,1.3l0.9,0.4c0.7,0.6,1.4,1.2,2.1,1.8c0.2,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.5,0.9
      c-2.6,2.6-5.4,5.2-9.8,4.9C460.4,1115.1,459.7,1115.1,459,1115.1z M476,1116.4c-0.1-1,0.4-2.3,1.2-3.2c2.3-2.7,4.8-5.3,7.2-7.8
      c0.6-0.7,1.3-1.4,1.9-2.1c0.5-0.5,1-1,1.6-1.5c0.1-0.1,0.2-0.2,0.3-0.3l0.1,1.2c0.1,1.2,0.2,2.4,0.2,3.5c0.2,2.5,0.5,5.5,0.8,8.4
      c0,0.6-0.2,0.8-0.7,1.1c-1.8,1-3.6,2.2-5.1,3.2l-0.1,0.1c-1.3,0.8-2.6,1.7-4,2.5c-0.5,0.3-1.2,0.6-1.8,0.9
      c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1-0.5-0.2-1-0.3-1.6v-0.1C476.4,1119.3,476.1,1117.9,476,1116.4z M481.5,1105.1l-5.4,5.7
      c-0.5-0.5-0.9-0.9-1.4-1.4c-1.2-1.3-2.5-2.6-3.8-3.8c-2-1.7-4.2-3.5-7.2-5.7c-0.6-0.4-1.3-0.7-2.1-0.9c-0.3-0.1-0.6-0.2-0.8-0.3
      l1.6-22.4c0.1,0,0.3,0.1,0.4,0.1h0.1c0.6,0.2,1.2,0.4,1.7,0.7c0.9,0.5,1.9,0.9,2.8,1.3c2.8,1.3,5.7,2.7,8,5
      c3.6,3.7,6.3,7.3,8.4,10.9c0.6,1,1.3,2,1.9,2.9c0.3,0.5,0.7,1,1,1.5c0.1,0.1,0.2,0.2,0.2,0.4L481.5,1105.1z M473.9,1114.7
      c-0.1,0.2-0.5,0.5-0.7,0.5c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1l3.5-3.3C474.6,1112.4,474.7,1113,473.9,1114.7z M460.8,1126.1
      c0.3-2.5,0.3-4.9,0.2-7.8c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1
      h0.1c1.7,0.4,3.3,0.7,5.1,1.1l1.5,0.3l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.3c0,0.1-0.2,0.4-0.4,0.5c-0.7,0.3-1.4,0.6-2.1,0.9
      c-2.9,1.2-5.9,2.4-8.9,3.5c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1C460.7,1128.1,460.8,1126.9,460.8,1126.1z M471,1127.5
      c1.4-0.6,2.8-1.2,4.3-1.8l3.6,11.7l-3.4,2.2l-12.9,8.4l-1.5-17.2C464.7,1130.3,467.9,1128.9,471,1127.5z M575.3,1103.2
      c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4c-1.5,1.2-3.1,2.3-4.7,3.4l-0.4,0.3c-1.5,1.1-2.9,2.2-4.4,3.3
      c-1.3,1-2.6,1.9-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9c-0.2,0.1-0.3,0.2-0.5,0.3l-4.5-8c0.1-0.1,0.3-0.2,0.5-0.3
      c0.6-0.4,1.2-0.9,1.8-1.4l0.8-0.7c1.8-1.5,3.6-3,5.3-4.5c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.8-3.4-5.5-5
      c-0.1-0.1-0.1-0.2-0.2-0.3l13.7-9.4c0,0.1,0,0.1,0.1,0.2l0.1,0.2c0.2,0.5,0.4,1,0.5,1.6C573.9,1097.1,574.6,1100.3,575.3,1103.2z
       M574.1,1087.1L574.1,1087.1c0.1-1.3,0.2-2.5,0.3-3.6v-0.1c0.1-1.5,0.3-3.1,0.5-4.7c0.2-1.4,0.4-2.8,0.6-4.2
      c0.1-0.8,0.3-1.6,0.4-2.4c0.3-1.8,0.5-3.6,0.8-5.3c0.4-2.9,0.8-5.9,1.3-8.9c0.1-0.5,0.2-0.9,0.4-1.5l0.1-0.2l0.6,0.6
      c0.7,0.7,1.3,1.4,1.9,2c0.9,1,1.8,1.9,2.8,2.9l0.6,0.6c1.7,1.7,3.4,3.6,5.1,5.4c1.2,1.3,2.5,2.6,3.5,3.7l0.1,0.2
      c0.8,0.9,1.6,1.7,2.4,2.6c0.4,0.4,0.8,0.9,1.2,1.4c1.1,1.2,2.2,2.4,3.4,3.4c2.1,1.8,2,3.8,1.5,5.8c-0.6,2.3-1.1,4.7-1.6,6.9v0.1
      c-0.5,2.1-0.9,4.3-1.5,6.5c-0.4,1.7-0.9,3.4-1.3,5.1c-0.4,1.4-0.8,2.7-1.1,4.1c-0.6,2.3-1.1,4.7-1.7,6.9v0.2c-0.2,1-0.5,2-0.7,3
      c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.4-0.2,0.7-0.3,1.1c-0.3,1-0.6,2.1-0.9,3.1l-0.4,1.2c-0.4-0.3-0.7-0.7-1-1l-0.1-0.1
      c-0.9-1-1.9-2.1-2.9-3.1c-1.5-1.4-3-2.9-4.3-4.1l-0.2-0.2c-1.1-1.1-2.3-2.2-3.5-3.3c-0.5-0.5-0.9-1.1-1-1.6
      c-0.8-3.5-1.6-7-2.4-10.4c-0.4-1.6-0.7-3.1-1.1-4.7c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-1.1-0.4-2.2-0.9-3.3
      C573.9,1089.4,574,1088.3,574.1,1087.1z M563.9,1155.6c-0.5-0.1-0.9-0.2-1.3-0.3h-0.1c-1.3-0.1-2.5-0.3-3.6-0.6
      c-2-0.5-4-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1h-0.1c-1.9-0.5-3.8-1-5.7-1.5c-0.8-0.2-1.2-0.5-1.1-1.4
      c0.3-2.1,0.4-4.1,0.6-6.1c0.2-2.4,0.3-4.8,0.4-7.2l0.1-2.3c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6
      c2.3-0.5,4.4-0.9,6.4-1.2c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1c0.7-0.1,1.7-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5
      c0.3,1.7,0.6,3.4,0.9,5.1v0.2c0.3,1.8,0.7,4.1,1.1,6.2c0.4,2.3,0.9,4.6,1.4,7.1v0.1C563.5,1153.7,563.7,1154.7,563.9,1155.6z
       M540.1,1127.4v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2h0.1c1.3-0.1,2.6-0.2,3.9-0.2
      c0.1,0,0.4,0.2,0.5,0.4c1.2,2,2.3,4,3.4,6.1l0.8,1.4L540.1,1127.4z M569.2,1091.3l-0.1,0.1c-1.6,1.1-3.2,2.2-4.7,3.3
      c-2.3,1.6-4.7,3.1-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1c-1.1-0.1-2.2-0.2-3.3-0.3c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0
      c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1c-0.4,0-0.8,0-1.2,0h-0.2l-1.6-22.5c0.1-0.1,0.2-0.1,0.4-0.1c0.5-0.1,1-0.2,1.5-0.2
      c2.3,0,4.6,0.1,6.5,0.2h0.4c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1c0.7,0,1.3,0.2,1.8,0.8
      c0.5,0.6,1.1,1.2,1.7,1.7c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.4l0.2,0.3c0.4,0.6,0.9,1.2,1.3,1.8
      C570.4,1090.4,569.8,1090.9,569.2,1091.3z M551.5,1101.1l0.3-0.1c2.5,0,4.5,0.9,6.1,2.6c1,1.1,2.1,2,3.3,3.1l0.1,0.1
      c0.4,0.3,0.8,0.7,1.2,1l-1.3,1.1c-1.7,1.4-3.3,2.7-4.9,4c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4
      c-0.3,0.1-0.6,0.2-0.9,0.2c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.2,0H554c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9
      c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1c-2.3,0-4.8,0-6.9,0c-1.8,0-3.7,0-5.6,0h-0.1c-2.6,0-4.9-0.9-6.9-2.8
      c-0.2-0.2-0.5-0.4-0.8-0.6c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4c0.9-0.7,1.9-1.5,2.8-2.2
      c1.2-1,2.4-2,3.6-2.9c1-0.7,2.1-1.2,3-1.2C542.9,1101.2,547.4,1101.2,551.5,1101.1z M507.6,1181.9c-0.2-1.2-0.4-2.3-0.6-3.5
      c0-0.1,0-0.1,0-0.2l3.1-0.2c1.9-0.1,3.9-0.3,5.8-0.4c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1
      c0.8,0.5,1.6,1.1,2.4,1.6l0.1,0.1c0.9,0.6,1.8,1.2,2.7,1.8c0.5,0.3,0.5,0.5,0.2,0.9c-2.2,3.6-4.2,6.8-6.1,9.8
      c-0.7,1.1-1.3,2.2-1.9,3.2l-0.2,0.4c-0.3,0.5-0.5,0.6-1.3,0.9c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0-1.6-0.4-2.4-0.8l-0.4-0.2
      c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.5-0.3-0.5-0.9c0-1.5-0.3-3.1-0.5-4.6v-0.2
      C508.4,1186.6,508,1184.2,507.6,1181.9z M522.7,1167.8l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.7,0.5-1.1l0.1-0.1c0.7-1.4,1.4-2.7,2.1-4
      c0.8-1.3,2.1-1.7,3.5-2.2C522.1,1168.1,522.4,1168,522.7,1167.8z M522.3,1176.3l0.6-1.8c0.6-2,1.3-4.1,1.9-6.2
      c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.5-1.9-2.5-2.6c-1.7-1.3-2-3-2.3-4.9
      c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0.2,0.1,0.3,0.3,0.4,0.4c1.1,1.1,2.2,2.1,3.3,3.2l0.6,0.5c2.1,2.1,4.4,4.3,6.7,6.6
      c2,1.9,4.1,3.9,6.3,5.8c0.7,0.6,0.7,1,0.1,1.7c-1.5,2.2-2.9,4.2-4.2,6.1c-0.4,0.6-0.7,1.2-1,1.8c-0.1,0.1-0.1,0.3-0.2,0.4
      L522.3,1176.3z M517.6,1162c0.9,1.1,1.8,2.1,2.8,3.1l0.9,1c-0.3,0.1-0.5,0.3-0.8,0.4h-0.1c-1,0.5-1.9,1-2.9,1.3c-0.1,0-0.2,0-0.3,0
      c-0.3,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-2.2-0.3-4.3-0.7-6.5-1l-2.2-0.3c0-0.4,0.3-0.7,0.9-1c1.5-0.6,3-1.3,4.4-1.9
      c1.2-0.5,2.5-1.1,3.7-1.6C516.4,1161.3,517.1,1161.4,517.6,1162z M516.4,1169.8l-0.5,0.9c-0.9,1.7-1.7,3.3-2.6,4.8
      c0,0-0.1,0.1-0.2,0.1c-2.3,0.2-4.5,0.4-7,0.6l-1.1,0.1l1.5-8.1L516.4,1169.8z M505.7,1184.5c0.4,2.2,0.7,4.2,1,6.3
      c0.1,0.7,0.2,1.5,0.2,2.3c0,0.3,0,0.6,0.1,1c-0.6,0.2-1.3,0.4-1.9,0.6c-1.8,0.6-3.5,1.1-5.2,1.4c-0.2,0-0.4,0.1-0.6,0.1
      c-0.8,0-1.7-0.3-2.6-0.5l-0.2-0.1c-1.1-0.3-2.2-0.7-3.4-1.2c-0.4-0.2-0.8-0.3-1.3-0.5l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4
      c0.1-0.9,0.3-1.7,0.4-2.6l0.1-0.5c0.2-1.3,0.4-2.7,0.6-4c0.2-1,0.4-1,0.6-1c0.2,0,0.5,0.1,0.8,0.2c2.9,0.7,5.5,0.7,8.1-0.1
      c0.4-0.1,0.6-0.2,0.8-0.2c0.2,0,0.5,0,0.6,1.1C505.2,1181.1,505.4,1182.8,505.7,1184.5z M493.4,1176.3l-0.7-0.1
      c-1.4-0.1-2.9-0.2-4.3-0.3h-0.1c-0.1,0-0.2-0.1-0.3-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3v-0.1
      c-0.2-0.4-0.4-0.7-0.6-1.1l9.8-1.6l1.5,8.1H493.4z M483.4,1175.7l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1
      c-0.9-2.4-1.6-4.5-2.2-6.6c1.7,0.5,3.3,0.9,4.2,2.4c0.8,1.3,1.4,2.6,2.1,4C483,1174.9,483.2,1175.3,483.4,1175.7z M492.5,1166.1
      l-1.6,0.3c-2.5,0.4-4.8,0.8-7.1,1.1c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0.1-0.4,0.1h-0.4c-0.6,0-1.2,0-1.7-0.2
      c-0.8-0.3-1.5-0.6-2.3-1c-0.2-0.1-0.4-0.2-0.6-0.3c0.3-0.3,0.6-0.7,0.9-1c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2
      c0.2,0,0.5,0,0.6,0.1c2.5,1,5.2,2.2,8.3,3.6c0.2,0.1,0.5,0.4,0.7,0.8C492.5,1166,492.5,1166.1,492.5,1166.1z M483.6,1153.9
      L483.6,1153.9c-0.1,1.1-0.3,2.2-0.6,3.1c-0.2,0.5-0.7,1-1.3,1.4c-0.3,0.3-0.6,0.5-0.9,0.8c-2.4,2.7-4.3,4.8-5.9,6.8
      c-0.5,0.6-0.9,1.3-0.4,2.5c0.6,1.6,1.1,3.2,1.6,4.7v0.1c0.3,0.9,0.5,1.8,0.8,2.6c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.6,1.6-3.8,2.4
      l-1.1,0.7l-1-1.5c-1-1.4-2-2.9-2.9-4.4l-0.4-0.6c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1c1.9-1.7,3.3-3,4.6-4.3
      c1.9-1.8,3.8-3.6,5.6-5.4l0.8-0.7c1-1,2-1.9,3-2.9c1-0.9,1.9-1.8,2.9-2.8l0.8-0.8C483.7,1153.5,483.6,1153.7,483.6,1153.9z
       M472.9,1181.5c0.7-0.4,1.3-0.9,2-1.4c1.2-0.8,2.4-1.7,3.6-2.4c1-0.3,2.1-0.3,2.8-0.3c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4
      l1,0.1c1,0.1,2,0.2,3,0.3c0.3,0,0.7,0.1,1,0.1l-0.3,1.8c-0.2,1.2-0.4,2.4-0.6,3.6c-0.1,0.9-0.3,1.8-0.4,2.6c-0.4,2.3-0.8,4.7-1,7
      c-0.1,1.1-0.5,1.3-1.3,1.6c-0.4,0.2-0.8,0.3-1.3,0.5c-0.9,0.3-1.8,0.7-2.6,1.1c-0.3,0.2-0.6,0.2-1,0.2c-0.3,0-0.6-0.1-1-0.1
      c-0.3-0.1-0.7-0.1-1.1-0.1h-0.1c0,0-0.1-0.1-0.3-0.3c-1.2-1.9-2.3-3.8-3.5-5.7c-1.6-2.7-3.2-5.3-4.8-8
      C472.5,1182,472.5,1181.8,472.9,1181.5z M484.5,1199.2c0.9-0.5,1.8-0.9,2.7-1.3c0.3-0.1,0.6-0.3,0.9-0.4c0.7-0.3,1.3-0.6,2-1
      c0.4-0.3,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,1.9,0.4l1,0.3c1.9,0.6,3.8,1.2,5.8,1.3c1.5,0.1,3.2-0.4,5.1-1.3c0.8-0.4,1.6-0.6,2.3-0.6
      c0.9,0,1.7,0.2,2.8,0.7c1.8,0.9,3.6,1.8,5.4,2.6h0.1c1.7,0.8,3.3,1.6,4.9,2.4c2.3,1.1,4.6,2.3,6.8,3.4c1.1,0.6,2.2,1.1,3.3,1.7
      c0.3,0.2,0.7,0.3,1,0.5l0.1,0.1l-1.4,0.3c-1,0.2-2,0.4-3,0.6l-1.5,0.3c-1.8,0.3-3.6,0.7-5.4,1c-0.4,0.1-0.8-0.1-1.3-0.2
      c-1.7-0.6-3.1-1.1-4.4-1.5c-2.6-1-5.1-2-7.4-2.9c-0.6-0.2-1.2-0.4-1.8-0.4c-0.7,0-1.5,0.2-2.1,0.6c-2.7,1.5-5.5,1.6-8.2,0
      c-0.7-0.4-1.4-0.6-2.1-0.6c-1.1,0-2.2,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1.1,0.4c-1.7,0.6-3.3,1.2-5,1.8l-0.3,0.1
      c-0.7,0.3-1.5,0.6-2.2,0.8h-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.5-1.5,0.8-2.5,0.8c-0.8,0-1.6-0.2-2.5-0.3c-0.2,0-0.4-0.1-0.6-0.1
      c-1.8-0.4-3.7-0.7-5.3-1l-0.4-0.1c-0.8-0.1-1.6-0.3-2.4-0.4c-0.1,0-0.2,0-0.4-0.1l0.7-0.3c1-0.5,2-1,3-1.5l1.9-0.9
      c1.9-1,3.9-2,5.9-3S482.5,1200.1,484.5,1199.2z M511.3,1208.8h-2.8c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1,0-1.6,0.1
      c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1c-1.3-0.1-2.7-0.1-4.4-0.1c-1.1,0-2.1,0-3.3,0c-0.8,0-1.7,0-2.5,0
      c0.9-0.1,1.7-0.5,2.6-0.8c0.1,0,0.1,0,0.2-0.1c0.1,0,0.3-0.1,0.5-0.2c0.8-0.5,1.6-0.8,2.4-0.8c1.2,0,2.3,0.5,3.6,1
      c0.1,0.1,0.3,0.1,0.4,0.2c0.7,0.3,1.3,0.6,2.1,0.6c1,0,2-0.3,3-0.7l0.2-0.1c0.7-0.2,1.4-0.5,2.1-0.8h0.1c0.4-0.2,0.8-0.3,1.1-0.5
      c0.1-0.1,0.3,0,0.4,0C507.8,1207.4,509.6,1208.1,511.3,1208.8z M467.8,1210c0.9,0.2,1.8,0.4,2.6,0.5c2.4,0.5,4.9,0.9,7.3,1.6
      c0.6,0.2,1.2,0.2,1.9,0.2c0.9,0,1.7-0.1,2.5-0.2c0.7-0.1,1.4-0.2,2.2-0.2c1.6,0,3.2-0.1,4.8-0.2h0.2c1.8-0.1,3.7-0.2,5.6-0.2
      c0.3,0,0.5,0,0.8,0c1.2,0,2.6,0,3.7,0.1c1.1,0,2.3,0.1,3.5,0.1h0.2c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3c2.1,0.1,4.2,0.1,6.2,0.6
      c0.7,0.2,1.4,0.2,2.2,0.2c1.5,0,3-0.3,4.4-0.6c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1
      c-0.5,0.5-1,1-1.5,1.4c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4c-2.6,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8
      c-1.7,0-3.4,0.1-5.1,0.2h-0.2c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1c-1.9,0-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6
      c-0.4,0-0.8-0.3-1.4-0.8c-0.8-0.8-1.7-1.6-2.5-2.4c-1.2-1.2-2.4-2.4-3.7-3.5c-1.7-1.5-3.5-3-5.2-4.4c-1.2-1-2.3-1.8-3.3-2.7
      c-0.6-0.5-1.1-1-1.6-1.6C466.4,1209.7,467.1,1209.8,467.8,1210z M526,1202.2l-2.6-1.3c-1-0.5-1.9-1-2.9-1.5l-1.2-0.6
      c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5c0.7-1.1,1.4-2.3,2.1-3.4v-0.1c1.2-1.9,2.4-3.8,3.4-5.8c2.6-4.7,5.5-9.2,8.6-13.8
      c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.5,1.1,0.9,1.6,1.5c1.8,2,3.5,4,5.3,6l0.8,1c0.3,0.4,0.6,0.8,0.9,1.1
      c0.6,0.7,1.2,1.5,1.8,2.1c0.5,0.7,0.4,1,0.2,1.5c-0.3,0.6-0.6,1.2-0.9,1.9l-0.1,0.1c-0.6,1.4-1.3,2.8-1.9,4.2
      c-0.3,0.8-0.6,1.6-0.9,2.4c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.6-1.8,3.2-2.8,4.8l-0.3,0.5c-0.4,0.7-0.9,1.3-1.3,1.9
      c-0.3,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.2,0-0.5-0.2C531.8,1205.1,528.8,1203.6,526,1202.2z M537.6,1128.5
      c-0.2,0-0.3,0-0.6-0.1c-1.8-0.6-3.5-1.1-5.2-1.8c-1.3-0.5-2.6-1.1-3.9-1.7c-1.1-0.5-2-0.9-3-1.3c-0.4-0.2-0.5-0.3-0.4-0.8
      c0.2-1.3,0.4-2.7,0.6-3.9l0.2-1.1c0.5-0.1,1-0.2,1.5-0.3h0.2c1.5-0.3,3.1-0.7,4.7-1c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1
      c0.5,0.1,1,0.2,1.5,0.3c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2c0,1.8,0,3.7,0,5.3c0,0.8,0,1.6,0,2.3c0,0.2,0,0.4,0,0.5
      c0,0.4,0,0.8,0,1.2C538.1,1128.3,537.9,1128.5,537.6,1128.5z M538.1,1130.9l-1.4,17.2l-16.3-10.5l3.6-11.8c1.5,0.5,2.9,1.2,4.3,1.8
      C531.4,1128.9,534.5,1130.3,538.1,1130.9z M524.9,1112.6c0.2-0.4,0.6-0.7,0.9-1.1l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2l3.5,3.3
      c-0.2,0-0.5,0.1-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4c-0.1,0-0.4-0.2-0.6-0.6C524.7,1113.8,524.6,1113,524.9,1112.6z M533.5,1101.5
      L533.5,1101.5c-0.4,0.2-0.8,0.5-1.2,0.8l-0.2,0.1c-1.5,1.2-3.1,2.4-4.5,3.7c-1.2,1.1-2.3,2.3-3.4,3.4c-0.4,0.4-0.8,0.8-1.2,1.2
      l-10.9-11.5c0-0.1,0.1-0.1,0.1-0.2l0.8-1.3v-0.1c1.3-2,2.6-4,3.9-6c2.3-3.4,5.2-7.3,9.2-10.2c1.4-1,3.1-1.8,4.6-2.6
      c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1c0.2-0.1,0.5-0.2,0.8-0.2c0,0,0,0,0.1,0l1.6,22.5
      C536.3,1099,534.8,1100.2,533.5,1101.5z M513.8,1104.1c2.6,2.8,5.4,5.7,8,8.8c1.4,1.5,1.8,3,1.3,4.5c-0.3,1.2-0.5,2.4-0.7,3.7
      c-0.1,0.4-0.1,0.9-0.2,1.3l-0.5-0.2c-0.6-0.2-1.1-0.5-1.7-0.8c-1.7-0.9-3.7-2-5.6-3.2c-1.4-0.9-2.8-1.8-4.4-3
      c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.5,0.6-7,0.9-10.6l0.2-2.3c0.2,0.2,0.4,0.3,0.6,0.5C512.4,1102.7,513.1,1103.4,513.8,1104.1z
       M510.9,1118.8c1.2,0.8,2.4,1.6,3.6,2.2c2,1.1,4.2,2.2,6.2,3.1c0.9,0.4,1.1,0.7,0.8,1.6c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9
      c-0.3,0.8-0.5,1.7-0.7,2.5l-0.1,0.3c-0.1,0.5-0.3,1-0.4,1.6c0,0.2-0.1,0.3-0.2,0.5c-0.2-0.1-0.3-0.2-0.5-0.3l-0.3-0.2
      c-0.8-0.5-1.5-1.1-2.2-1.6c-2-1.6-3.9-3.2-5.7-4.7c-0.3-0.2-0.5-0.7-0.5-1.1c-0.1-1.6-0.1-3.1,0-4.5s0.2-2.8,0.3-4.1l0.1-0.2
      c0-0.4,0.1-0.7,0.1-1.1C510.1,1118.3,510.5,1118.5,510.9,1118.8z M511,1133.2c0.8,0.6,1.7,1.3,2.5,1.9l0.1,0.1
      c1.8,1.4,3.7,2.9,5.7,4.2c2.1,1.5,4.4,3,6.5,4.2c0.8,0.5,1.6,1,2.4,1.5s1.7,1.1,2.6,1.6c1.9,1.2,3.7,2.3,5.5,3.5
      c0.2,0.1,0.4,0.6,0.4,1c-0.3,4-0.6,8-0.9,12v0.2c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8c-0.5-0.5-1.1-0.9-1.6-1.4
      c-1.5-1.3-2.9-2.5-4.3-3.8c-0.8-0.7-1.5-1.5-2.3-2.3l-0.1-0.1c-0.6-0.7-1.3-1.4-2-2c-2.6-2.5-5.3-5-8.1-7.5c-1.2-1.1-1.9-2.4-2.3-4
      c-0.6-3-1.4-6-2.1-9c-0.3-1.2-0.6-2.4-0.9-3.5c-0.1-0.5-0.2-1-0.4-1.5V1133.2L511,1133.2z M514.4,1159.2c0,0.2,0.1,0.4,0.2,0.6v0.1
      c-0.5,0.2-0.9,0.4-1.4,0.6h-0.1c-1.3,0.6-2.6,1.2-3.9,1.7c-0.5,0.2-1,0.4-1.6,0.6c-0.6,0.2-1.2,0.4-1.9,0.7
      c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1v0.1c-0.2,0.9-0.3,1.8-0.5,2.7c-0.4,1.7-0.8,3.7-1.3,5.6
      c-0.1,0.2-0.6,0.5-0.9,0.6c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1c-0.3-1.5-0.5-2.9-0.9-4.5c-0.2-1.3-0.5-2.5-0.7-3.8
      c-0.7-4-0.9-4.2-4.9-5.3c-1.1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2c-0.5-0.2-1-0.5-1.4-0.8c-0.1-0.1-0.2-0.1-0.4-0.2
      c0.2-2,1.8-2.7,3.3-3.4c0.8-0.4,1.9-0.4,2.9-0.5c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.1,1.9-0.2c1.7-0.2,3.4-0.4,5.1-0.5
      c1.1,0,2.2,0.1,3.4,0.2c0.5,0.1,1,0.1,1.5,0.2l2,0.2c1.6,0.2,3.2,0.3,4.9,0.4C512,1156.3,514.1,1158.1,514.4,1159.2z M481.7,1152.3
      c-2.6,2.1-5,4.5-7.4,6.9c-1,1-2.2,2.1-3.3,3.2c-1.6,1.5-3.3,3.1-4.8,4.5c-0.7,0.6-1.3,1.3-2,1.9c0-0.3-0.1-0.6-0.1-1
      c-0.1-0.9-0.3-1.9-0.4-2.9c-0.2-2.2-0.4-4.6-0.5-6.6c-0.1-1.2-0.2-2.3-0.3-3.5c0-0.4-0.1-0.8-0.1-1.1v-0.1
      c-0.1-0.8-0.2-1.6-0.2-2.4c0-0.3,0.3-0.8,0.6-1c1.9-1.3,3.9-2.5,5.8-3.7c3-1.9,6.1-3.9,9.1-5.9c2.3-1.6,4.6-3.3,6.8-5l0.1-0.1
      c1-0.7,2-1.5,2.9-2.2c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3-0.2,0.6-0.2,0.9c-0.3,1.1-0.6,2.3-0.9,3.5l-0.3,1.2
      c-0.5,2.2-1.1,4.5-1.5,6.8C484.9,1148.2,484,1150.4,481.7,1152.3z M463.8,1172.1c0.4,0.6,0.9,1.3,1.3,1.9l0.1,0.1
      c1.2,1.7,2.2,3.2,3.2,4.9c0.9,1.5,1.8,3,2.6,4.5c0.6,1.1,1.3,2.4,2,3.5c0.7,1.2,1.4,2.3,2.2,3.4l0.1,0.2c0.5,0.7,0.9,1.5,1.4,2.2
      c1,1.5,1.9,3.1,2.8,4.6c0.3,0.6,0.4,0.9,0.3,1.2c-0.1,0.3-0.4,0.5-0.9,0.8l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2
      c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.5-0.2l-1-1.4c-1.6-2.4-3.3-4.8-4.8-7.3c-0.5-0.9-0.9-2-1.3-3c-0.2-0.6-0.4-1.3-0.7-1.9
      c-0.9-2-1.8-4.2-3.1-6.9c-0.4-0.8-0.4-1.2,0.2-1.9c1.1-0.9,2-2.1,2.9-3.2c0.7-0.8,1.3-1.7,2-2.5c1.4-1.5,2.8-3,4.2-4.5l0.4-0.4
      C462.9,1173,463.3,1172.5,463.8,1172.1z M454.1,1194.1c0.3,0.6,0.6,1.2,0.8,1.9l0.1,0.1c1,2.2,1.9,4.4,3.1,6.5c0.9,1.6,2,3,3.2,4.5
      c0.5,0.7,1,1.3,1.5,1.9c0,0,0,0.1,0.1,0.1c-0.2,1.2,0.6,1.8,1.1,2.2c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7,1.4,1.3,2.1,1.9l0.3,0.3
      c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4c-0.8,0.7-1.6,1.5-2.3,2.2c-1.3,1.2-2.5,2.4-3.9,3.6c-1.2,1.1-2.4,2.1-3.8,3.2l-0.1,0.1
      c-0.4,0.3-0.8,0.7-1.3,1c-0.1-0.9-0.3-1.8-0.4-2.8c-1.7-11.3-3.2-22-3.2-33c0,0.1,0.1,0.4,0.2,0.6l0.2,0.4
      C452.9,1191.4,453.5,1192.8,454.1,1194.1z M457.6,1225.9c0.8-0.7,1.5-1.3,2.2-1.9c0.5-0.5,1.1-0.9,1.6-1.4l0.1-0.1
      c1.2-1,2.4-2,3.5-3.1c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.5,0.3l2.6,2.3
      c1.4,1.3,2.8,2.5,4.2,3.8c0.5,0.5,1.1,1,1.6,1.5l0.1,0.1c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5l-0.4,1.2
      c-0.6,1.8-1.2,3.8-1.7,5.8c-0.4,1.5-0.8,3-1.1,4.3l-0.1,0.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.5-0.2,1-0.4,1.5l-0.1,0.4l-20.3-15.9
      L457.6,1225.9z M478.9,1242.8c0.3-1.3,0.6-2.5,0.9-3.7c0.2-1,0.5-2,0.7-3l0.1-0.3c0.3-1.5,0.7-3,1.1-4.5c0.3-1.1,0.6-2.3,0.9-3.3
      c0.1-0.3,0.2-0.5,0.7-0.5h0.1c3.6,0.1,7.2,0.2,10.8,0.2h1.7c0.9,0,1.9,0,2.8,0.1h0.1c2.9,0.1,5.9,0.1,8.9,0.1c2.4,0,5-0.1,8.4-0.4
      c0.6,0,0.7,0.1,0.8,0.8c0.8,3,1.5,6,2.3,9c0.4,1.5,0.8,3.1,1.2,4.8l0.5,2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0
      c-0.6,0-1.2,0-1.7,0c-0.7,0-1.5,0-2.2,0c-3.3,0-6.8,0.2-10.3,0.6c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3
      c-2.3-0.3-4.8-0.4-8-0.4c-0.9,0-1.9,0-2.8,0c-1.4,0-2.8,0-4.3,0h-0.5c-0.4,0-0.7,0-1.1,0L478.9,1242.8z M520.4,1232.6
      c-0.4-1.1-0.8-2.3-1.2-3.5c-0.2-0.4-0.3-0.9-0.5-1.3c-0.2-0.7-0.2-1,0.3-1.5c1.4-1.3,2.8-2.6,4.1-3.8l0.1-0.1
      c0.8-0.8,1.7-1.6,2.5-2.4s1.7-1.5,2.5-2.3c0.6-0.5,1.2-1.1,1.8-1.6c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4
      c1.9,1.9,3.9,3.7,6,5.6c1.4,1.3,2.9,2.5,4.4,3.7c0.6,0.5,1.1,0.9,1.7,1.4c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1.1
      c-0.3-1.1-0.7-2.3-0.9-3.4c-0.2-0.8-0.4-1.7-0.5-2.6C520.9,1234.8,520.7,1233.7,520.4,1232.6z M541.1,1222.5
      c-2.1-1.9-4.3-3.9-6.3-5.7l-2.5-2.2c-0.3-0.1-0.3-0.2-0.3-0.2c0-0.1,0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5l0.1-0.1
      c0.4-0.4,0.8-0.7,1.1-1c0.1-0.1,0.2-0.2,0.3-0.2c0.5-0.5,1.2-1,1-2.1c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4
      c0.4-0.5,0.7-1,1.1-1.5l0.1-0.1c1-1.3,2-2.7,2.9-4.2c1.1-1.7,1.9-3.6,2.7-5.5c0.9-2.1,1.8-4.3,2.6-6.3c0.3-0.6,0.5-1.2,0.8-1.9
      c-0.1,11.7-1.8,23-3.5,35l-0.1,0.8c-0.2-0.2-0.4-0.3-0.6-0.5C542.4,1223.6,541.7,1223.1,541.1,1222.5z M546.3,1222.5
      c0.3-1.4,0.6-2.9,0.8-4.4c0.3-1.6,0.4-3.2,0.6-4.7c0.1-0.5,0.1-1,0.2-1.5c0.3-3.1,0.7-6.2,1-9.1l0.1-1.3c0.2-2.3,0.5-4.6,0.7-6.9
      c0-0.4,0-0.8-0.1-1.1c0-0.3-0.1-0.6-0.1-0.9c0-0.7,0.1-1.3,0.2-2c0.1-0.7,0.1-1.5,0.2-2.3c0-0.5-0.1-1-0.1-1.5c0-0.1,0-0.3-0.1-0.4
      c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1h0.2c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4c1.6,0.2,3.3,0.4,5,0.5
      c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.2-0.7,6.3v0.1c-0.3,2.5-0.5,5-0.8,7.5
      c-0.7,5.8-1.6,11.6-2.4,16.5c-0.5,3.4-1.2,6.9-1.8,10.3l-0.5,1.8c-0.1,0.3-0.2,0.7-0.3,1c0,0,0,0,0,0.1c-0.8-0.6-1.5-1.3-2.2-1.9
      l-0.3-0.3c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.8-2.1c-0.8-0.7-1.6-1.5-2.3-2.3l-0.1-0.2
      c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.4-0.2-0.6C545.9,1224.5,546.1,1223.6,546.3,1222.5z M567.1,1185
      c-1.6-0.2-3.3-0.3-4.9-0.5l-1.8-0.2c-2.2-0.2-4.6-0.4-6.9-0.6c-0.8,0-1.5,0-2.3,0.1c-0.5,0-1.1,0.1-1.6,0.1c-0.2,0-0.5-0.1-0.9-0.4
      c-1.8-1.4-3.2-3.2-4.5-4.9c-0.7-0.9-1.4-1.8-2.2-2.7c-1.2-1.3-2.5-2.7-4.2-4.1c-1.2-1-1.2-1-0.8-2.6c0.4-2.2,0.6-4.5,0.8-6.6
      c0.2-1.8,0.3-3.6,0.4-5.4l0.1-1.2c0.1-1.1,0.1-2.2,0.2-3.4v-1.2c0.4,0.1,0.8,0.2,1.1,0.2c1.1,0.2,2.1,0.4,3.1,0.7
      c2.2,0.6,4.3,1.2,6.4,1.8c2.5,0.7,5.3,1.5,8.1,2.2c2,0.5,4,0.9,6.2,1.3c1,0.2,1.5,0.7,1.7,1.9c0.6,4,1.2,8,1.7,11.9l0.6,4.2
      c0.3,1.7,0.5,3.5,0.8,5.2l0.6,4.3L567.1,1185z M566.5,1155.7c-0.3-1.5-0.5-3.1-0.9-4.8c-0.2-1.2-0.4-2.5-0.6-3.7
      c-0.1-0.8-0.3-1.6-0.4-2.4c-0.1-0.8-0.2-1.6-0.4-2.5c-0.2-1.1-0.4-2.2-0.6-3.3c-0.2-0.9-0.4-1.9-0.5-2.8c-0.2-0.9-0.3-1.8-0.5-2.6
      v-0.2c-0.2-1-0.3-2-0.5-3.1c-0.2-1-0.4-1.9-0.7-2.7c-0.4-1.1,0.2-1.8,1.2-2.5c1.3-1,2.7-2,4-2.9l0.1-0.1c1.5-1.1,3-2.2,4.5-3.3
      c1.7-1.3,3.3-2.5,5.1-3.9l0.3-0.2c0.6-0.5,1.2-0.9,1.8-1.4c0.2,0.2,0.5,0.4,0.8,0.6l0.2,0.1c0.9,0.7,1.8,1.4,2.6,2.2
      c1.9,1.8,3.8,3.7,5.6,5.5l0.1,0.1c0.8,0.8,1.6,1.6,2.3,2.3v0.1c0.1,0.1,0.2,0.2,0.3,0.2c2.6,1.4,2.8,3.8,2.6,6.2
      c-0.3,4.8-0.7,9.9-1.3,16.1c0,0.3-0.2,0.5-0.3,0.6c-0.7,0.3-1.5,0.7-2.2,1c-3.1,1.5-6.3,3-9.5,4.3c-2.6,1.1-5.3,2.1-7.9,3l-0.2,0.1
      c-0.7,0.3-1.5,0.5-2.2,0.8c-0.5,0.2-1,0.3-1.5,0.2C567,1156.6,566.6,1156.3,566.5,1155.7z M591.6,1168.4c0.1-0.9,0.1-1.6,0.2-2.3
      c0.5-4.2,0.8-7.6,1.2-10.7c0.2-2.3,0.4-4.5,0.6-7.1c0.1-0.8,0.1-1.6,0.2-2.4v-0.1c0.2-2.6,0.4-5.3,0.5-8c0.1-2.6,0.2-5.3,0.2-7.9
      v-0.2c0-1.1,0-2.3,0.1-3.4c0-0.2,0-0.5,0-0.8v-0.2l0.6,0.1c0.7,0.1,1.4,0.3,2.1,0.5c0.2,0,0.4,0.1,0.6,0.1c0.6,0.1,1.3,0.3,1.5,0.6
      c0.1,0.2,0,0.8-0.1,1.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.2,1.6-0.5,3.3-0.8,4.9v0.1c-0.5,3.4-1.1,6.9-1.6,10.3
      c-0.3,1.9-0.5,3.8-0.6,5.7c-0.2,1.5-0.3,3.1-0.5,4.6c-0.3,2.9-0.8,5.5-1.2,8.1c-0.1,0.5-0.2,1.1-0.3,1.6c-0.4,2.2-0.7,4.4-1.3,6.6
      c-0.2,1-0.4,2.1-0.6,3.1c-0.5,2.5-1,5.1-2.1,7.4c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5v-0.1c0.1-2.1,0.2-4.2,0.8-6.1
      C591.2,1172.9,591.4,1170.6,591.6,1168.4z M616.3,1131.8c-0.4-0.1-1-0.2-1.4-0.3c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.2,0-3.6,0
      c-0.8,0-1.6,0-2.4,0c-0.6,0-1.3,0-2,0c0.1-0.3,0.3-0.6,0.4-0.9l0.1-0.2c0.5-1.1,0.9-2.1,1.4-3.1c1-2,2.1-3.9,3-5.7
      c0.6-1.1,1.2-2.3,1.8-3.4c0.1-0.2,0.2-0.5,0.3-0.7c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2
      c0.3,0,0.7,0,1,0c0,0.5,0,1.1,0,1.6c0,1.4,0,2.8,0,4.1c-0.1,2.7-0.2,5.4-0.3,8.1l-0.1,1.3C617.7,1131.5,617.3,1131.8,616.3,1131.8z
       M610.5,1115.3c-2.3,4.4-4.7,9.1-7.5,14.8c-0.6,1.2-1.1,2.4-1.6,3.6c-0.3,0.7-0.6,1.4-1,2.2c0,0.1-0.1,0.1-0.1,0.2l5.4-27.7
      c0.3,0.3,0.6,0.5,0.8,0.7c1.5,1.7,2.8,3.4,4.1,4.9C610.8,1114.3,610.9,1114.6,610.5,1115.3z M606.3,1097.4v0.2
      c-0.5,2.3-1,4.6-1.5,6.9c-0.2,1-0.5,2-0.9,3c-0.3,1-0.6,2-0.9,3c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2
      c-0.3,0-0.8-0.1-1.3-0.2c-0.2,0-0.5-0.1-0.8-0.2c-0.5-0.1-0.9-0.3-1.4-0.5l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2
      c1.1-4.5,2.2-8.6,3.2-12.7c0.5-1.8,0.9-3.5,1.4-5.3c0.9-3.4,1.8-6.8,2.6-10.3c0.4-1.7,0.7-3.4,1-5.1c0.2-0.9,0.3-1.7,0.5-2.6
      c0.1-0.6,0.3-1.3,0.4-1.9l0.1-0.4l0.4,0.4c0.5,0.7,1,1.2,1.5,1.8c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.8,0.8,1.1
      c1.1,1.1,1.1,1.9,0.7,3.5C607.4,1092.5,606.8,1095,606.3,1097.4z M603.9,1069.7c-0.3,2.1-0.7,4.2-1,6.4c-0.1,0.8-0.3,1.5-0.4,2.3
      c-0.2-0.2-0.4-0.4-0.5-0.6v-0.1c-0.7-0.8-1.4-1.6-2-2.3c-2.6-2.8-6.1-6.5-9.6-10.3c-1.5-1.6-3.1-3.3-4.6-4.7l-0.2-0.2
      c-0.9-1-1.9-1.9-2.8-2.9c-0.4-0.4-0.7-0.8-1.1-1.2l-0.1-0.1c-0.6-0.7-1.4-1.5-2.1-2.2c-0.5-0.4-0.5-0.9-0.4-1.4
      c0.3-1.3,0.7-2.6,1-3.9v-0.1c0.7-2.6,1.4-5.4,2-8.1c0.4-1.7,0.6-3.4,0.8-5.1c0.1-0.7,0.2-1.5,0.3-2.2c0.1-0.7,0.2-1.4,0.3-2.2v-0.1
      c0.3-1.9,0.6-3.8,0.8-5.7c0.1-1,0.2-2,0.3-3c0.1-0.7,0.1-1.5,0.2-2.2c0-0.4,0.1-0.8,0.2-1.3v-0.1c0.3,0.3,0.5,0.6,0.8,0.9l0.1,0.1
      c1,1.2,2,2.3,2.9,3.4c2.8,3.9,5.4,7.4,7.7,10.8c1,1.4,1.9,2.9,2.8,4.3l0.1,0.1c0.5,0.9,1.1,1.7,1.7,2.6c0.3,0.5,0.7,1,1,1.6
      c1.2,1.9,2.5,3.8,3.8,5.6c0.7,0.9,0.9,1.7,0.7,2.9c-0.2,1.2-0.3,2.4-0.5,3.6c-0.2,1.5-0.4,3.1-0.7,4.6c-0.2,1.2-0.3,2.4-0.5,3.6
      l-0.1,0.5C604.5,1065.2,604.2,1067.5,603.9,1069.7z M582.6,1020.9l-0.1,0.8c-0.8,5.9-1.7,12.1-2.7,18.1c-0.5,2.7-1.1,5.4-1.8,8v0.1
      c-0.3,1.1-0.5,2.2-0.8,3.2c-0.2,0.7-0.3,1.4-0.5,2l-0.1,0.5c-0.4-0.2-0.9-0.5-1.3-0.7l-0.7-0.4c-1.8-1-3.6-2-5.3-3
      c-1-0.6-2.1-1.1-3.1-1.6h-0.1c-1.1-0.6-2.4-1.2-3.6-1.9c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.1-0.6-2.4-1.4-3.6-2
      c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5c-1.6-0.9-3.2-1.9-4.7-2.8l-0.1-0.1c-0.6-0.4-1.2-0.7-1.8-1.1
      c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.1-0.7-0.2-0.8-0.3c-0.2-0.2-0.1-0.7,0-1.2c0-0.2,0.1-0.4,0.1-0.6c0.1-1.8,0.2-3.6,0.3-5.6
      c0.1-1.4,0.1-2.7,0.2-4.1l0.1-1.4c0-0.9,0.1-1.8,0.1-2.6c0.1-1.9,0.2-3.7,0.3-5.6c0.4-6.5,0.8-13,1.1-19.5c0.4,0.2,0.7,0.4,1.1,0.6
      l0.4,0.2c0.9,0.6,2.2,1.3,3.4,2l2.2,1.3h0.1c2.7,1.6,5.6,3.3,8.3,5c4.5,2.8,7.8,5,10.9,7.1c1.8,1.3,3.6,2.6,5.4,3.9
      c1.7,1.3,3.7,2.8,5.7,4.1C582.8,1018.8,582.7,1019.7,582.6,1020.9z M542.8,1037l0.3,0.2c1.3,0.8,2.6,1.7,4,2.5
      c0.8,0.9,2,1.6,3.2,2.2l0.6,0.3c1.7,0.9,3.4,1.8,5.1,2.7l1.8,0.9c0.7,0.4,1.4,0.7,2.1,1.1l1,0.5c1.8,1,3.6,1.9,5.4,2.9
      c1,0.5,1.9,1.1,2.9,1.6l0.3,0.1c1.9,1,3.8,2.1,5.8,3.1c1.1,0.5,1.2,1.2,1,2.2c-0.2,1.5-0.5,3-0.7,4.5c-0.6,4.3-1.3,8.7-1.9,13.2
      c-0.4,2.7-0.6,5.5-0.8,8.2v0.1c-0.1,1.3-0.2,2.6-0.4,3.9c0,0.2,0,0.3-0.1,0.5l-0.7-0.9c-0.7-0.9-1.4-1.9-2.2-2.9
      c-2-2.5-4-4.9-5.9-7.3l-0.5-0.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0
      c-0.4,0-0.8,0-1.2,0c-0.8,0-1.6,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2
      c-0.6,0-1.2,0.1-1.8,0.1c-0.3,0-0.7,0-1.1,0.1h-0.5l2.1-37.2C541.9,1036.5,542.3,1036.7,542.8,1037z M539.1,1036.4v0.2
      c-0.1,1.1-0.2,2-0.3,3.1c-0.1,1.6-0.2,3.3-0.2,4.9c-0.1,1.3-0.1,2.7-0.2,4.1c-0.1,1.9-0.2,3.9-0.3,5.8c-0.1,1.6-0.2,3.1-0.3,4.7
      c-0.1,1.5-0.1,2.9-0.2,4.4v0.3c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.6c-0.1,1.8-0.2,3.7-0.3,5.6c0,0.2-0.3,0.5-0.6,0.6
      c-2.4,1.1-4.8,2.2-7.2,3.2l-0.4,0.2c-1,0.4-1.9,0.8-2.9,1.3c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.1,5.5-6.1,8.2l-1,1.4
      c-0.9,1.2-1.6,2.5-2.3,3.7l-0.1,0.2c-0.4,0.7-0.7,0.9-1.2,0.9c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1c-1-0.3-1.9-0.6-2.9-1
      c-0.4-0.1-0.9-0.3-1.4-0.4c-0.2,0-0.3-0.1-0.5-0.1v-65.9c13.8,0.9,27.1,3.4,38.3,5.6C539.1,1035.7,539.1,1036.1,539.1,1036.4z
       M510,1138.5c0.8,3.6,1.6,7.3,2.4,10.8v0.1c0.3,1.2,0.5,2.5,0.8,3.7c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1
      c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.8-0.2-2.7-0.3h-0.1c-2.7-0.3-5.4-0.6-8.2-0.6c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2
      c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.2c0.8-3.5,1.6-7.5,2.5-11.4
      c0.5-1.9,1-3.9,1.6-5.8l0.4-1.4c0.7-2.1,1.1-2.2,2.5-2.3c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1
      c0.2,0,0.5,0,0.7,0h2.9v-0.1c0.5,0,1,0.1,1.4,0.1c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.4
      C508.9,1134.3,509.5,1136.4,510,1138.5z M490.1,1125.3c-0.1,0.7-0.1,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.4-3.7,2.9-6.2,4.9
      c-0.5,0.4-1,0.8-1.5,1.1l-0.4,0.3c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3v-0.1
      c-0.5-1.6-0.9-3.3-1.5-5c-0.3-0.8,0-1,0.7-1.3c0.4-0.2,0.7-0.4,1.1-0.5l0.2-0.1c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.2-1.3,3.2-2
      l0.2-0.1c0.5-0.3,0.9-0.6,1.4-0.9c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,0.7,0.1,1.1v0.2c0.1,1.4,0.3,2.7,0.4,4
      C490.1,1124,490.1,1124.7,490.1,1125.3z M489.7,1098.2c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.3-3.6-3.5-5.4l-0.1-0.2
      c-0.6-1-1.3-1.9-1.9-2.9c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.1l-0.1-0.1c-0.4-0.2-0.8-0.4-1.2-0.5
      c-1-0.5-2.1-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.5-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7c-0.1-1.9-0.2-3.8-0.3-5.6
      c-0.1-2-0.2-4-0.4-5.9v-0.3c-0.1-2-0.3-4-0.4-6.1c-0.1-1.1-0.1-2.2-0.1-3.3v-0.1c0-1.2,0-2.3-0.1-3.5c-0.1-1.6-0.3-3.3-0.4-4.9
      v-0.1c-0.1-0.8-0.1-1.6-0.2-2.3c0-0.1,0-0.2,0-0.3c15.1-3.1,26.9-4.8,38.2-5.6v65.8l-2.1,0.7h-0.1c-2,0.6-3.8,1.3-5.7,1.9
      C490.1,1098.2,489.9,1098.2,489.7,1098.2z M416.9,1039c0.7,3.2,1.5,6.4,2.2,9.5l0.1,0.3c0.3,1.2,0.6,2.4,0.9,3.6
      c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5,5.4l-0.1,0.1c-1.3,1.4-2.6,2.8-4,4.2c-1.5,1.6-3,3.1-4.4,4.5l-0.1,0.1
      c-1.5,1.5-3,3-4.5,4.6c-1.2,1.2-2.3,2.5-3.4,3.7c-0.5,0.6-1.1,1.2-1.6,1.8c0,0.1-0.1,0.1-0.2,0.2l-0.7-4.5v-0.3
      c-0.5-3.8-1.1-7.4-1.6-11.1c-0.2-1.2-0.3-2.5-0.5-3.8c-0.4-2.6-0.7-5.4-1.2-8.1c-0.3-1.5,0.4-2.5,1.2-3.6c0.1-0.2,0.3-0.4,0.4-0.6
      c1.8-2.7,3.6-5.4,5.3-7.9l0.1-0.2c1.6-2.4,3.4-5.1,5.2-7.7l0.7-1c1.7-2.4,3.4-4.9,5.2-7.2c0.8-1,1.7-1.9,2.6-2.9
      c0.3-0.3,0.5-0.6,0.8-0.9c0.1,0.8,0.2,1.5,0.3,2.2l0.1,0.6c0.3,2.6,0.6,5.1,0.9,7.6l0.2,0.2C415.8,1032.5,416.3,1035.8,416.9,1039z
       M392.4,1109.5c0.4-0.4,0.7-0.7,1.1-1.1l5.5,27.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.5c-0.3-0.8-0.7-1.6-1-2.3
      c-1.8-4.2-3.8-8.6-6.3-12.6l-0.2-0.3c-0.5-0.8-1.1-1.7-1.3-2.6c-0.3-0.7,0.1-1.7,0.5-2.2C390.2,1111.7,391.3,1110.6,392.4,1109.5z
       M389.3,1120.9c1.1,2.2,2.2,4.6,3.2,6.7l0.8,1.7c0.2,0.5,0.4,1,0.7,1.5l0.2,0.4c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0-1,0
      c-0.5,0-0.8,0-1.2,0h-0.8c-2.3,0.1-4.8,0.2-7.2,0.4c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.9
      c0-1.1,0-2.5,0-3.9c0-0.5,0-1,0-1.6c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.9,0.5,1.1,0.9
      C387.6,1117.7,388.4,1119.2,389.3,1120.9z M407.9,1146.7c-0.3-3.6-0.5-7.2-0.8-11.2l-0.1-1.5c-0.3-1.8-0.4-3.7-0.5-5.6
      c0-1.3,0.5-2.4,1.7-3.4c1.6-1.4,3.1-2.9,4.6-4.3c0.9-0.9,1.9-1.9,2.9-2.8c1.3-1.2,2.6-2.3,3.8-3.5c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1
      c2.4,1.8,4.7,3.4,6.9,5.1c1.3,1,2.7,2,3.9,3l0.1,0.1c0.8,0.6,1.5,1.2,2.3,1.8c0.8,0.6,1,1.4,0.8,2.5c-0.4,2-0.8,4.1-1.2,6l-0.1,0.5
      c-0.1,0.6-0.2,1.2-0.3,1.7l-0.3,1.8c-0.3,1.8-0.6,3.3-0.9,4.9c-0.3,1.6-0.6,3.2-0.8,4.8l-0.1,0.5c-0.4,2.3-0.8,4.6-1.2,7
      c-0.3,1.6-0.9,1.8-1.5,1.8c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2c-3-1.3-6.1-2.7-9-4.1l-1.9-0.9
      C408.1,1147.3,407.9,1147,407.9,1146.7z M432.7,1168.9c0.3-2.5,0.6-4.7,1-6.9c0.1-0.9,0.3-1.8,0.4-2.7c0.1-0.9,0.6-1.4,1.7-1.7
      c4.8-1.1,8.8-2,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1c1.2-0.3,2.5-0.7,3.7-1c0.6-0.2,1.3-0.3,2.1-0.5h0.1c0.2,0,0.3-0.1,0.5-0.1
      c0,0.3,0,0.6,0,0.9v0.5c0.1,1.3,0.1,2.6,0.2,3.9v0.2c0.2,3.4,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4c0.3,0.6,0.1,0.9-0.3,1.4
      c-0.3,0.3-0.7,0.6-1,1c-1,0.9-2.1,1.9-3,3c-1,1.2-2,2.4-3,3.6c-0.8,1-1.5,1.9-2.3,2.7l-0.3,0.4c-0.7,0.8-1.4,1.6-2.2,2.1
      c-0.3,0.2-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0s-0.6,0-0.9,0h-0.1c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3,0-1.9,0c-0.6,0-1.2,0-1.7,0.1
      c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4c0.2-1.1,0.3-2.2,0.5-3.3l0.3-2C431.7,1176.2,432.2,1172.6,432.7,1168.9z
       M447.8,1253.2c-1.2-0.8-2.4-1.6-3.7-2.4c-0.9-0.6-1.9-1.2-2.8-1.7c-0.8-0.5-1.7-1-2.5-1.5c-0.8-0.5-1.5-1.1-2.1-1.7
      c0,0-0.1-0.2-0.1-0.5c0.2-0.4,0.2-1,0.2-1.4c0-0.1,0-0.2,0-0.3l18-15l0.5,0.4c0.6,0.5,1.2,1,1.8,1.5c0.8,0.7,1.6,1.3,2.4,2
      c1.7,1.5,3.5,3,5.4,4.4c0.9,0.8,1.9,1.5,2.9,2.2c0.8,0.6,1.7,1.2,2.5,1.9c0.9,0.7,1.8,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2
      c0.4,0.3,0.4,0.5,0.2,1c-0.4,1.5-0.8,2.9-1.2,4.3v0.2c-0.6,2.2-1.2,4.4-1.8,6.6c-0.7,2.3-1.5,4.7-2.2,7l-0.5,1.4
      c-0.2,0.6-0.5,0.6-0.6,0.6c-0.2,0-0.4-0.1-0.6-0.2c-1.1-0.7-2.1-1.4-3.2-2c-1.4-0.9-2.9-1.9-4.5-2.8c-1.2-0.7-2.4-1.5-3.6-2.2
      l-0.3-0.2c-1.5-0.9-3.1-1.8-4.6-2.8C450.8,1255.2,449.3,1254.2,447.8,1253.2z M525,1251.3v-0.1c-0.2-0.9-0.4-1.7-0.7-2.6
      c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.3-1,2.7-2,4-3c1.5-1.2,3.5-2.6,5.3-4
      c1.3-1,2.6-2.1,3.8-3.1l0.1-0.1c0.7-0.6,1.3-1.1,2-1.7c1.1-0.9,2.2-1.8,3.2-2.7l1.5-1.2c0.4,0.3,0.7,0.6,1.1,0.9l0.7,0.6
      c1.4,1.1,2.7,2.2,4,3.3c0.7,0.6,1.4,1.2,2.1,1.8l0.1,0.1c0.7,0.6,1.4,1.2,2.2,1.8c2.9,2.4,5.3,4.3,7.7,6.1c0.4,0.3,0.4,0.4,0.3,0.7
      c-0.3,0.2-0.3,0.5-0.3,0.7c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.2c-3,2-6.2,4-9.3,6c-1.2,0.7-2.4,1.5-3.5,2.1
      l-0.2,0.1c-0.7,0.4-1.4,0.8-2.1,1.2l-0.5,0.3c-1.4,0.8-2.8,1.7-4.2,2.6c-1.3,0.8-2.7,1.7-4,2.5l-0.3,0.2c-1,0.6-2,1.3-2.9,1.9
      c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.5-1.7-0.8-2.5c-0.7-2-1.4-4-2-6.1
      C526,1255.3,525.5,1253.3,525,1251.3z M588.4,1173.3c-0.2,2.2-1.4,3.2-3.2,4.3c-2.5,1.3-4.9,2.8-7.3,4.2l-0.9,0.6
      c-0.9,0.5-1.7,1.1-2.5,1.6h-0.1c-0.6,0.4-1.1,0.7-1.7,1.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2-0.4
      c-0.2-1.7-0.5-3.5-0.7-5.2v-0.1c-0.1-0.7-0.2-1.4-0.3-2c-0.1-0.9-0.3-1.8-0.4-2.6l-0.1-0.5c-0.3-2.2-0.7-4.8-1.1-7.2
      c-0.3-1.8-0.6-3.6-0.9-5.3v-0.1c-0.2-0.9-0.3-1.8-0.5-2.7c0,0,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3c1.1-0.2,2.1-0.5,3.1-0.8
      c4.4-1.3,8.7-3.1,12.8-5.1c0.6-0.3,1.2-0.6,1.8-0.9l0.2-0.1c1.2-0.6,2.4-1.2,3.6-1.8c0.4-0.2,0.8-0.3,1.2-0.5c0.1,0,0.1,0,0.2-0.1
      l-0.3,3.2c-0.1,1.6-0.3,3.2-0.4,4.7c-0.1,1-0.2,2-0.3,3c-0.2,2.7-0.5,5.5-0.8,8.3c-0.1,0.7-0.3,1.3-0.5,2
      C588.7,1171.7,588.5,1172.5,588.4,1173.3z M604.2,1175.7c0,1.2,0,2.4,0,3.6c0,1,0,2.1,0,3.1v0.7c0,2.2-0.1,4.4-0.1,6.6
      c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9h-0.4c-1.7,0.2-3.4,0.4-5.1,0.7c-0.5,0.1-0.8,0-0.9-0.1c-0.2-0.1-0.2-0.3-0.1-0.6
      c0.4-1.2,0.9-2.5,1.3-3.7c0.3-0.9,0.7-1.8,1-2.8c0.9-2.6,1.7-5.2,2.7-8l0.1-0.2c0.4-1.1,0.7-2.2,1.1-3.4l9.3,0.7V1175.7z
       M604.5,1172c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1c-0.1,0-0.2,0-0.3-0.1l2.9-17c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0
      c3.7,0,7.9,0.1,12.1,1.1l-0.1,1c-0.1,1.1-0.2,2.2-0.3,3.3c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3
      c-2,2.4-4.1,4.6-5.8,6.5C605,1171.8,604.7,1172,604.5,1172z M619.6,1162.8L619.6,1162.8c0,0.4,0,0.8,0,1.1
      c-0.3,1.6-0.6,3.2-0.9,4.8c-0.3,1.6-0.6,3.2-0.9,4.8c-0.1,0.5-0.1,1-0.2,1.5l0.2-0.1c-0.1,0.6-0.1,1.2-0.2,1.8
      c-0.2,0.8-0.4,1.8-0.9,2.5c-1.4,2.1-3.4,3.8-5.4,5.3c-0.8,0.6-1.6,1.2-2.3,1.9c-0.6,0.5-1.2,1.1-1.9,1.6l-0.4,0.3v-1.7
      c0-1.7,0-3.3,0-5c0-1.2-0.1-2.5-0.1-3.7c0-1.3-0.1-2.5-0.1-3.6c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8l0.1-0.2
      c1.3-1.4,2.6-2.9,3.8-4.4c0.6-0.7,1.1-1.4,1.6-2.2c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.4,0.5-0.5,0.7-0.5s0.3,0.1,0.6,0.2
      c0.6,0.3,1.1,0.5,1.7,0.8c0.2,0.1,0.3,0.1,0.5,0.2C619.6,1162.7,619.6,1162.7,619.6,1162.8z M620.1,1159.9l-2.5-0.7l1.1-13.5
      l2.9,1.4L620.1,1159.9z M631.5,1117.8c-0.1,1.2-0.3,2.1-0.6,2.9c-0.6,1.8-1.3,3.6-1.9,5.2c-0.5,1.5-1,2.8-1.5,4.1
      c-0.7,2-1.4,3.9-2.1,6.1l-0.1,0.2c-0.4,1.2-0.9,2.5-1.3,3.7c-0.4,1.1-0.8,2.3-1.2,3.4l-0.5,1.5l-3-1.2c-0.4-0.2-0.5-0.4-0.4-0.8
      v-0.4c0.5-5.1,1.1-10.3,1.5-15.4c0.1-1.6,0.1-3.2,0.1-4.8v-0.1c0-0.9,0-1.8,0-2.7c0-0.8,0.1-1.6,0.1-2.3c0-0.5,0.1-1,0.1-1.5
      c0-0.4,0.2-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.2,0.3,1.9,0.5c0.8,0.2,1.5,0.4,2.3,0.5
      S631.6,1117.4,631.5,1117.8z M608.9,1100.2l2.4,0.6c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2c2.4,0.6,4.6,1.2,6.1,3.5
      c1.5,2.2,3.1,4.3,4.7,6.4l0.1,0.1c0.5,0.7,1.1,1.4,1.6,2.1c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.5-2.8-0.7
      c-2.8-0.3-5.3-0.5-7.5-0.6c-0.1,0-0.3,0-0.4,0c-1.2,0-2.4,0.1-3.6,0.2h-0.1c-0.6,0.1-1.3,0.1-1.9,0.1c-0.1,0-0.2-0.1-0.2-0.1
      c-2-2.3-4-4.8-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5c0.4-0.9,0.6-1.9,0.7-2.8l0.1-0.5c0.1-0.7,0.4-0.7,0.6-0.7
      C608.7,1100.2,608.8,1100.2,608.9,1100.2z M609.9,1041c0.1-0.6,0.1-1.2,0.2-1.7c0.2-1.1,0.3-2.1,0.5-3.2l0.2-1.1l0.2,1.5
      c0.3,1.7,0.5,3.3,0.8,5l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5c0.2,1.3,0.3,2.7,0.5,4c0.3,2.3,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8
      l-0.1,0.4c-0.5,2-0.8,4-1.2,6c-0.2,1.1-0.4,2.4-0.7,3.6c-0.3,1.7-0.7,3.4-1.1,5c-0.3,1.2-0.5,2.4-0.8,3.7c-0.2,0.9-0.3,1.7-0.5,2.6
      l-0.1,0.6l-0.5-1c-1.1-1.3-2.2-2.6-3.2-3.9c-0.2-0.2-0.3-0.7-0.2-1.1c0.3-1.8,0.5-3.5,0.8-5.2c0.3-1.8,0.6-3.9,0.9-5.9
      c0.2-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.9,0.3-2.8c0.2-1.3,0.4-2.6,0.6-4.1c0.1-1,0.3-1.9,0.4-2.9c0.1-0.9,0.2-1.9,0.3-2.7l0.1-1.1
      c0-0.1,0-0.1,0-0.2c0.7-1.1,0.6-2.3,0.6-3.4c0-0.8-0.1-1.6,0.1-2.3C609.6,1043.9,609.8,1042.5,609.9,1041L609.9,1041z M586,1007.9
      c0.3-2.7,0.5-5.4,0.8-8.1c0.2-1.6,0.3-3.3,0.4-4.8c0.1-1.1,0.2-2.3,0.2-3.4c0.1-0.8,0.2-1.6,0.3-2.4c0-0.2,0.1-0.4,0.1-0.7
      c0.3,0.3,0.6,0.7,0.9,1.1c0.9,1,1.8,2.1,2.7,3.2c1.6,2.1,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c1,1.6,1.8,3.3,2.7,4.9
      c0.5,0.9,0.9,1.8,1.4,2.7c1.6,2.9,2.6,6.2,3.3,10.4c0.2,1.8-0.1,3.5-0.3,5.1c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.9-0.8,5.7-1.3,8.5
      v0.1c-0.2,0.9-0.3,1.9-0.5,2.9c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-1.1-1.3-2.1-2-3.2c-1.2-2-2.5-4-3.8-6c-1.1-1.7-2.3-3.3-3.5-4.9
      c-0.7-0.9-1.4-1.8-2-2.8c-0.5-0.8-1.1-1.5-1.6-2.3c-0.9-1.3-1.9-2.7-2.9-4c-1.1-1.4-2.3-2.7-3.4-4c-0.7-0.8-1.4-1.5-2-2.3
      c-0.1-0.2-0.2-0.5-0.2-0.7c0.2-2.2,0.4-4.5,0.6-6.7V1007.9z M542,953.8l0.2,0.1c1,0.5,2,1,3,1.5c1.6,0.7,3.1,1.4,4.7,2.2l0.1,0.1
      c2.8,1.3,5.7,2.6,8.6,4c1.4,0.7,2.7,1.4,4,2.1h0.1c1.5,0.8,3,1.7,4.5,2.4c3.3,1.5,6.1,3.9,9,7.7c1.3,1.7,2.7,3.4,4,5.1
      c1,1.3,2.1,2.6,3.1,3.9c0.2,0.3,0.5,0.6,0.7,1c0.4,0.6,0.8,1.2,1.3,1.7c0.8,0.5,0.7,1.1,0.5,1.9c0,0.2-0.1,0.4-0.1,0.6
      c-0.3,2.6-0.5,5.1-0.8,7.6c-0.1,1.1-0.2,2.1-0.3,3.2l-0.5,5.1c-0.3,3.3-0.7,6.6-1,9.9c-0.1,0.6-0.1,1.1-0.1,1.7v0.2
      c0,0.1,0,0.2,0,0.3c-0.2-0.2-0.4-0.3-0.6-0.5l-0.2-0.2c-0.8-0.6-1.6-1.3-2.5-1.9c-0.5-0.4-1-0.7-1.5-1.1l-0.3-0.2
      c-2.1-1.5-4.3-3.1-6.5-4.6c-5.6-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7
      c-0.1,0-0.2-0.3-0.2-0.5c-0.1-1-0.1-1.9,0-2.7l0.1-2.4v-0.1c0.2-3.8,0.4-7.7,0.6-11.5c0.2-2.4-0.2-4.8-0.6-7.1
      c-0.1-0.4-0.1-0.8-0.2-1.2c-0.4-2.4-0.9-4.9-1.5-7.3l-0.2-0.9c-0.2-0.8-0.4-1.6-0.6-2.3c-0.1-0.4-0.3-0.8-0.4-1.2
      c-0.1-0.2-0.1-0.4-0.1-0.6C541.5,953.5,541.8,953.6,542,953.8z M500.8,944.2c0.3,0,0.6,0,0.8,0c0.4,0,0.7,0,1.1,0
      c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2c1.4,0.1,2.9,0.2,4.3,0.3c1.5,0.1,3,0.2,4.5,0.2c1.7,0.1,3.3,0.2,4.8,0.3
      c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1c1.5,0.6,3,1.2,4.5,1.9c0.4,0.2,0.8,0.7,1,1.3c0.5,1.7,1,3.5,1.4,5.2
      c1.2,5,2.2,9.5,2.4,14.1c0,3.1-0.2,6.2-0.4,9.1c-0.1,1.3-0.2,2.6-0.2,3.9c-0.1,1.2-0.1,2.5-0.2,3.7l-0.1,1.3
      c-5.9-1.4-11.8-2.9-17.5-4.4h-0.2c-7.6-2-15.5-4-23.4-5.8V944.2z M500.8,983.1c0-0.4,0.1-0.7,0.1-1l41,10.3l-2.3,41.1
      c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.4-0.3-2.7-0.6-4-0.9c-2.9-0.6-5.7-1.1-8.1-1.5c-1.8-0.3-3.9-0.7-5.9-1
      c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3c-0.9-0.1-1.7-0.1-2.6-0.2c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9
      c0-1.9-0.1-3.9-0.1-5.8v-0.1c0-1.7-0.1-3.6-0.1-5.5c0-1.4,0-2.8,0-4.1v-0.1c0-1.2,0-2.4,0-3.6c0-1,0-2-0.1-3V989
      c0-1.1-0.1-2.2-0.1-3.2C500.6,985,500.7,984.1,500.8,983.1z M499.5,1097.3c0.3,0,0.6,0.1,1,0.2l0.2,0.1c1,0.3,2.1,0.7,3.1,1
      l0.4,0.4c1.5,0.5,2.8,0.9,4.2,1.3c0.5,0.1,0.6,0.3,0.5,0.8c-0.4,4.7-0.8,9.5-1.2,14.3c-0.2,2.3-0.4,4.6-0.5,6.9l-0.1,0.9
      c-0.1,1.1-0.2,2.2-0.2,3.3c0,0.1,0,0.2-0.1,0.4l-0.4-0.1c-1.1-0.2-2.3-0.3-3.4-0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0-1.3,0-1.9,0h-0.1
      c-1.6,0-3.3,0.1-5,0.1c-0.9,0-1.7,0.1-2.6,0.2c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1c-0.1-1.2-0.1-2.3-0.2-3.4
      c-0.2-1.8-0.3-3.7-0.5-5.5c-0.2-2.1-0.4-4.6-0.7-7.1c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6c3.3-1.1,6.2-2,8.8-2.8
      C499.4,1097.3,499.4,1097.3,499.5,1097.3z M458.3,961.3c0.3-1.8,0.7-3.6,1.1-5.4c0.2-1.1,0.6-2.2,1.1-3.5c0.1-0.3,0.5-0.7,0.9-0.8
      c3.8-1.6,7.6-3.2,10.8-4.6s6.8-2.1,11-2.2c2.5-0.1,5.1-0.2,7.6-0.4h0.1c1.6-0.1,3-0.2,4.4-0.2c0.4,0,0.8,0,1.3,0c0.3,0,0.6,0,0.9,0
      h0.2c0.3,0,0.6,0,0.9,0v35.9h-0.2c-7.9,1.8-15.8,3.8-23.5,5.8c-5.7,1.5-11.6,3-17.5,4.4c0-0.1,0-0.3,0-0.4v-0.3
      c-0.1-0.8-0.2-1.6-0.2-2.4c-0.1-1.4-0.1-2.9-0.2-4.3c0-1.4-0.1-2.8-0.2-4.3V978c-0.2-3.6-0.4-7.3,0.4-10.9c0.4-1.9,0.8-3.8,1.1-5.7
      V961.3z M414,985.4c0.7-0.9,1.4-1.8,2-2.7c1.1-1.5,2.2-3,3.4-4.5c3-3.6,5.7-6.6,8.4-9.4c0.6-0.6,1.5-1.1,2.4-1.6
      c0.4-0.2,0.8-0.4,1.2-0.6c5.1-3.1,10.4-5.5,14.9-7.6c2.7-1.2,5.3-2.5,8-3.8l0.4-0.2c1.1-0.5,2.1-1,3.2-1.5c0.1,0,0.1-0.1,0.2-0.1
      l-0.3,0.2c-0.2,0.6-0.3,1.2-0.5,1.8c-0.2,0.7-0.4,1.3-0.5,2c-0.4,1.3-0.7,2.7-1,4.1c-0.2,1-0.4,2.1-0.6,3.2c-0.1,0.9-0.3,2-0.5,2.9
      c-0.6,3-0.5,6-0.3,9c0,0.4,0,0.9,0.1,1.3c0.1,1.9,0.2,3.9,0.3,5.5c0.1,1.5,0.2,2.9,0.2,4.4c0,1,0,1.9,0,2.8c0,0.2-0.2,0.5-0.3,0.6
      c-1.5,0.8-3,1.7-4.4,2.5c-2.2,1.2-4.4,2.5-6.6,3.8c-1.8,1.1-3.6,2.2-5.3,3.4c-0.9,0.6-1.7,1.1-2.6,1.7c-0.8,0.5-1.7,1.1-2.5,1.6
      l-0.1,0.1c-1.5,0.9-3.4,2.1-5.1,3.3c-2,1.3-4,2.8-5.9,4.2l-0.5,0.4c-1.3,1-2.6,2-4,3c-0.5,0.3-0.9,0.7-1.4,1l-0.1-0.9
      c-0.1-1.2-0.2-2.1-0.3-3.1c-0.2-2.2-0.5-4.5-0.7-6.7c-0.3-2.9-0.6-5.7-0.9-8.6c-0.1-1.3-0.2-2.6-0.4-4c-0.1-0.9-0.2-1.9-0.2-2.8
      c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8C413,986.9,413.3,986.3,414,985.4z M392.2,1019c0.3-0.6,0.6-1.2,0.8-1.7
      c0.8-1.7,1.6-3.4,2.6-5.1c2-3.4,4.1-6.9,6.1-10.1c1.6-2.6,3.2-5.1,5-7.5c1.1-1.5,2.3-3,3.5-4.4l0.1-0.1c0.4-0.5,0.8-1,1.2-1.5
      l0.1,1.6c0.1,1.6,0.2,3,0.4,4.5c0.2,1.9,0.4,3.7,0.5,5.6c0.2,1.7,0.3,3.3,0.5,5c0.3,3.5,0.6,6.8,0.9,10c0,0.1-0.1,0.3-0.1,0.4
      c-0.9,1.1-1.9,2.2-2.7,3.2c-1.6,1.9-3.2,3.8-4.7,5.8c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.6-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7
      c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2-1.3-0.4-2.6-0.6-3.9c-0.5-3.6-1-6.9-1.6-10.3C389.2,1027.7,390,1023.5,392.2,1019z M390.3,1085.4
      v-0.2c-0.1-0.6-0.2-1.2-0.3-1.8c-0.2-0.9-0.4-1.9-0.6-2.8c-0.3-1.6-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4c-0.2-1.2-0.4-2.5-0.7-3.8
      l-0.1-0.4c-0.3-1.6-0.6-3.3-1.2-4.9c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.2c0.3-2.6,0.6-5.2,1-7.8
      c0.4-2.9,0.9-5.9,1.5-8.7c0.2,1.2,0.4,2.3,0.5,3.5c0.2,1.6,0.5,3.1,0.7,4.7l0.4,3.1c0,0.1,0,0.2,0.1,0.2c0,0,0,0.1,0,0.2
      c-0.2,1.1,0.1,2.4,0.4,3.7c0.2,0.7,0.3,1.4,0.4,2.1c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.5,1,7c0.3,1.9,0.6,3.8,0.9,5.7
      c0.4,2.4,0.7,5,1,7.6c0,0.3-0.1,0.8-0.4,1.2c-1,1.2-2,2.4-3.1,3.7C390.8,1084.9,390.6,1085.1,390.3,1085.4z M371.5,1111.3
      c1.3-1.7,2.5-3.4,3.8-5.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.4,0.5-0.7,0.8-0.9l0.1-0.1c0.6-0.5,1.3-1.1,2-1.2c3.6-1,7.4-2,11.5-3.1
      c0.4-0.1,0.6-0.1,0.8-0.1c0.1-0.2,0.1-0.2,0.4,0.9c0.1,0.4,0.2,0.9,0.3,1.3h0.1l-0.1,0.1c0.2,0.6,0.4,1.3,0.5,1.9
      c0.1,0.8,0.1,1.5-0.1,1.8c-0.3,0.6-0.8,1.1-1.3,1.6c-0.2,0.3-0.5,0.5-0.7,0.8l-0.7,0.8c-0.9,1.1-1.9,2.2-2.7,3.4
      c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1c-1.2,0-2.4-0.1-3.5-0.2h-0.1c-1.4-0.1-2.9-0.2-4.3-0.2c-1.6,0-2.9,0.1-4.2,0.4
      c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.5,0.1-0.8,0.2L371.5,1111.3z M376.8,1144.8
      c-0.2-0.7-0.5-1.5-0.7-2.2c-0.7-2.2-1.4-4.2-2.1-6.2c-0.3-0.9-0.6-1.7-1-2.6v-0.1c-0.3-0.8-0.6-1.6-0.9-2.4
      c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2c0-0.1,0.2-0.2,0.3-0.2c1.8-0.4,3.7-0.9,5.5-1.3
      l1.7-0.3c0.1,0,0.2,0,0.3-0.1c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5c0.1,1.6,0.1,3.3,0.2,4.8c0,2,0.1,4.1,0.2,6.1
      c0.1,1.8,0.4,3.7,0.6,5.4c0.1,1,0.3,2.2,0.4,3.4c0.2,1.9,0.4,4,0.4,6c0,0.1-0.3,0.3-0.6,0.4c-0.7,0.3-1.5,0.6-2.2,0.9h-0.1
      C377.3,1144.6,377.1,1144.7,376.8,1144.8z M379.1,1160l-1.5-12.8l2.9-1.5l1.1,13.5L379.1,1160z M392.8,1182.6
      c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.5-1.2-1-1.8-1.5c-1.5-1.2-3.1-2.5-4.5-3.8
      c-1.4-1.3-2.3-2.4-2.8-3.5c-0.4-0.8-0.5-1.7-0.6-2.7c0-0.4-0.1-0.9-0.2-1.4c-0.2-0.9-0.3-1.8-0.5-2.7l-0.2-0.9
      c-0.4-2.1-0.7-4.2-1.1-6.3c-0.1-0.5-0.1-1-0.1-1.5v-0.4c0.1-0.1,0.3-0.2,0.6-0.3c0.7-0.3,1.3-0.5,1.9-0.8c0.4-0.2,0.6-0.2,1,0.3
      l2.1,2.5c2.4,2.9,5,6,7.4,9c0.2,0.2,0.3,0.6,0.3,0.9C392.5,1176.7,392.5,1179.3,392.8,1182.6z M391.1,1167.9
      c-1.7-2.1-3.6-4.3-5.5-6.3c-1.6-1.6-1.7-3.4-1.8-5.4c0-0.1,0-0.5,0-0.9c4.1-1,8.3-1.1,11.9-1.1c0.7,0,1.5,0,2.2,0
      c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2c-0.4,0-0.8,0.1-1.2,0.1h-0.4c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4
      C393,1170.2,392,1169,391.1,1167.9z M410.5,1192.4c-0.1,0.1-0.4,0.2-0.7,0.1c-1.8-0.2-3.6-0.4-5.5-0.6l-0.9-0.1
      c-2-0.2-4.1-0.5-6.1-0.7c-0.6-0.1-1.1-0.2-1.6-0.3c-0.4-0.1-0.6-0.3-0.5-1v-0.3c0.1-1.8,0.2-3.7,0.1-5.5c0-1.3-0.1-2.7-0.2-4v-0.2
      c0-0.5-0.1-1.1-0.1-1.6c0-0.7-0.1-1.4-0.1-2.2l-0.1-1.6l9.8-0.9c0,0.1,0.1,0.3,0.1,0.4c0.1,0.5,0.2,0.9,0.3,1.4
      c0.8,2.3,1.6,4.5,2.3,6.8l0.1,0.2c0.7,2,1.4,3.9,2,5.9c0.4,1.2,0.8,2.4,1.1,3.5C410.6,1192,410.6,1192.3,410.5,1192.4z
       M411.4,1175.1c-0.1-0.7-0.4-1.4-0.6-2.1c-0.2-0.7-0.5-1.5-0.6-2.2c-0.3-2.1-0.5-4.2-0.7-6.3c-0.2-0.9-0.3-1.8-0.4-2.7
      c-0.1-1-0.2-1.9-0.3-2.9v-0.3c-0.2-1.4-0.3-2.9-0.4-4.3c-0.1-1.2-0.2-2.4-0.3-3.5l-0.1-1.2h0.1h0.1c0.4,0.1,0.9,0.3,1.2,0.5
      c1.1,0.5,2.1,1.1,3.2,1.6h0.1c2.1,1.1,4.2,2.1,6.4,3.1c2.5,1.1,5.1,1.9,7.6,2.8l0.2,0.1c0.6,0.2,1.2,0.4,1.7,0.6
      c0.8,0.3,1.6,0.4,2.5,0.6c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.3c-0.1,1-0.3,2-0.4,3c-0.3,1.7-0.5,3.4-0.8,5.1c-0.4,2.5-0.8,5-1.2,7.5
      c-0.2,1.4-0.4,2.9-0.6,4.4c-0.2,1.5-0.4,3.1-0.7,4.8c-0.1,0.4-0.2,0.4-0.2,0.4c-0.2,0-0.4-0.1-0.6-0.2h-0.1
      c-1.3-0.7-2.6-1.5-3.9-2.3l-0.1-0.1c-0.8-0.5-1.5-1-2.3-1.5c-1-0.7-2.1-1.4-3.2-2c-1.2-0.7-2.5-1.5-3.7-2.1c-0.6-0.3-1.2-0.7-1.8-1
      C411.7,1176.1,411.5,1175.8,411.4,1175.1z M423,1217.2c-1.4-2.7-2.8-5.4-4.1-8.1c-0.6-1.3-1.2-2.7-1.8-4.1
      c-0.7-1.6-1.3-3.3-2.2-4.9c-1.3-2.5-1.6-5.2-1.9-7.9c0-0.4-0.1-0.7-0.1-1.1c-0.3-3-0.6-6.2-0.9-9.2v-0.4c-0.1-1-0.2-2-0.3-3.1
      l15.7,9.7c-0.7,3.7-1.3,7.6-0.5,11.8c0.4,2.4,0.7,4.9,0.9,7.3c0.1,1.4,0.3,2.9,0.5,4.4c0.7,5.5,1.4,10.8,2.2,16.2
      c0.3,2.3,0.8,4.7,1.2,6.9c0.1,0.5,0.2,0.9,0.3,1.4c-0.3-0.7-0.7-1.5-1-2.2c-1.2-2.8-2.5-5.7-3.9-8.5
      C425.8,1222.7,424.4,1219.9,423,1217.2z M434.7,1339.5L434.7,1339.5c-0.4,1-0.8,1.8-1.2,2.7c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.1
      c-7.7-7.1-13.9-13.3-19.4-19.5c0.1-0.2,0.2-0.4,0.4-0.6l0.2-0.2c0.5-0.8,1-1.5,1.5-2.3c0.7-0.9,1.3-1.9,2.1-2.9
      c0.6-0.9,1.2-1.7,1.8-2.6l0.5-0.7c0.7-1,1.4-2,2-3c1.3-2.4,2.7-4.9,4.1-7.4c0.6-1.1,1.2-2.1,1.8-3.2c0.3,0.3,0.5,0.5,0.8,0.8
      c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1c1.8,1.4,3.7,2.6,5.6,3.9l1.4,0.9c0.4,0.3,0.5,0.7,0.4,1.4l-0.1,0.3
      c-0.3,1.4-0.7,2.8-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3c-0.6,1.3-1.2,2.6-1.8,3.7c-0.4,0.9-0.9,1.8-1.3,2.7
      C436.1,1336.5,435.4,1338,434.7,1339.5z M477.8,1325.2c-0.4,12.1-0.8,24.7-1.9,37.2c-0.2-0.1-0.5-0.1-0.7-0.2
      c-0.8-0.2-1.5-0.4-2.2-0.7c-1.1-0.4-2.2-0.8-3.1-1.2l-0.1-0.1c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.3-1.5-0.6-2.3-0.9
      c-1.3-0.5-2.6-1-4-1.5c-2.5-1-5.2-2.2-8.2-3.5c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.5-1.7-5.2-2.6l-0.3-0.2c-0.8-0.4-1.5-0.8-2.3-1.2
      c-0.2-0.1-0.5-0.2-0.7-0.4h-0.1c-0.7-0.3-1.4-0.7-2.2-1.1c-0.3-0.1-0.4-0.2-0.4-0.3c-0.1,0.1-0.2-0.1,0-0.3
      c1.3-2.8,2.6-5.6,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.6-1.4,0.9-2v-0.1c0.6-1.3,1.2-2.6,1.8-3.9
      c0.7-1.7,1.2-3.5,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6c0.1-0.2,0.1-0.4,0.1-0.6h0.1c0.4,0.2,0.8,0.3,1.1,0.4c0.4,0.1,0.9,0.3,1.3,0.4
      c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4h0.1c1.7,0.2,3.4,0.3,5.1,0.5
      c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1c0.4,0.1,0.9,0.1,1.3,0.2C477.9,1321.7,477.9,1323.5,477.8,1325.2z M478.1,1313.6
      c0,0.9,0,1.8,0,2.8c0,0.3,0,0.7,0,1c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8-1c-1.6-0.2-3.2-0.3-4.7-0.5H462
      c-1.5-0.1-3.1-0.3-4.6-0.4c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5c0.1-0.8,0.1-1.7,0.1-2.4c0-0.5,0-1,0-1.5
      c0.1-1.1,0.1-2.2,0.2-3.3v-0.1c0.2-3.5,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.5v-0.3c0.2-2.4,0.4-5,0.6-7.5
      c0.1-1.8,0.2-3.7,0.3-5.5v-0.2c0.1-1.1,0.1-2.3,0.2-3.4c0.1-1.7,0.2-3.3,0.4-5.2v-0.4c0-0.5,0.1-0.9,0.1-1.4c0.6,0.3,1.1,0.7,1.6,1
      l0.1,0.1c1.5,0.9,3,1.8,4.4,2.7c0.9,0.5,1.7,1,2.6,1.6l0.2,0.1c2.5,1.5,5,3.1,7.4,4.7c2.5,1.8,5.3,2.1,8.1,2.5h0.2
      c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6
      c-0.1,1.4-0.2,2.8-0.2,4.2v0.4c-0.1,1.1-0.1,2.2-0.2,3.4c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3c0,1.3,0,2.9,0.1,4.6
      c0,1.5,0.1,3.1,0,4.7c-0.1,2.9-0.2,5.8-0.3,8.4C478.2,1310.2,478.1,1311.9,478.1,1313.6z M520.7,1362.5c0,0-0.1,0.1-0.4,0.1
      c-0.6,0-1.3,0.1-1.9,0.2H518v-0.2h-0.4c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5c-1.6,0.1-3.2,0.1-4.7,0.1
      c-0.7,0-1.5,0-2.2,0s-1.5,0-2.3,0.1c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2c-2.7-0.2-5.3-0.3-7.9-0.5l-2-0.1
      c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.5-0.4-0.5c0.2-2.7,0.3-5.1,0.5-7.4v-0.3c0.1-1.6,0.2-3.3,0.3-4.9
      c0.3-4,0.5-7.8,0.7-11.7c0.1-1.4,0.1-2.8,0.1-4.1v-0.1c0-1,0-2,0.1-2.9c0-1.4,0.1-2.8,0.2-4.2c0-0.9,0.1-1.9,0.1-2.9
      c0-1.1,0.1-2.4,0.1-3.6c0-0.4,0.1-0.5,0.1-0.6c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1c3.2,0.1,6.5,0.2,9.7,0.5
      c2.6,0.2,5.1,0.2,7.6,0c3.2-0.3,6.5-0.4,9.7-0.5h0.2c1.5,0,3.2-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7c0.1,3.9,0.2,8,0.4,12.2
      c0.1,3.4,0.3,6.7,0.5,10l0.2,3c0.2,4.1,0.4,8.4,0.7,12.6c0.1,1.4,0.2,2.8,0.4,4C520.8,1362.2,520.8,1362.4,520.7,1362.5z
       M519.7,1271.2l0.6-0.1l0.1-0.1v0.1c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2c2-0.2,4-0.5,5.8-1.5c2-1.2,4.1-2.4,6-3.7
      l0.2-0.1c0.4-0.3,0.9-0.5,1.3-0.8c2.3-1.4,4.6-2.9,6.9-4.4l3.2-2.1c0,0.4,0,0.8,0.1,1.2c0.1,1.2,0.1,2.4,0.2,3.6
      c0.3,3.6,0.5,7.3,0.8,10.8l0.3,3.7c0.2,2.6,0.4,5.1,0.6,7.7c0.1,1.2,0.2,2.4,0.3,3.6c0.2,2.2,0.4,4.7,0.6,7
      c0.2,3.4,0.3,6.9,0.4,10.2c0,1.1,0.1,2.2,0.1,3.3c0,0.3,0,0.6,0.1,0.9c0.1,0.8,0.2,1.6-0.1,2c-0.2,0.4-0.9,0.6-1.6,0.9
      c-0.4,0.1-0.8,0.3-1.2,0.5c-1.7,0.9-3.6,1.1-5.5,1.3c-1.8,0.2-3.7,0.4-5.3,0.6c-1.1,0.1-2.2,0.3-3.4,0.4c-0.9,0.1-1.8,0.2-2.7,0.3
      c-1,0.1-2.1,0.2-3.2,0.3c-1.8,0.2-3.5,0.4-5.3,0.7l-2.1,0.3L519.7,1271.2z M563.1,1345c-1.6,0.8-3.2,1.5-4.7,2.3l-0.3,0.2
      c-1.3,0.6-2.6,1.3-3.9,1.9c-1,0.5-1.9,0.9-2.8,1.4h-0.1c-3,1.4-6.1,2.9-9.2,4.3c-1.7,0.8-3.4,1.4-5.1,2c-0.9,0.3-1.8,0.7-2.7,1
      c-0.8,0.3-1.6,0.6-2.3,1l-0.2,0.1c-1.3,0.5-2.6,1.1-3.9,1.6c-0.9,0.3-1.9,0.6-2.8,0.9H525c-0.4,0.1-0.9,0.3-1.3,0.4
      c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.5c-0.2-4.2-0.5-7.8-0.7-11.4c-0.1-1.1-0.2-2.2-0.2-3.3c-0.1-1.6-0.3-3.4-0.3-5.2
      c-0.1-1.3-0.1-2.6-0.1-3.9v-0.2c0-0.8,0-1.6,0-2.4c0-0.7,0-1.4-0.1-2.1v-0.1c0-0.7-0.1-1.4-0.1-2.1c0-1,0-2.1-0.1-3.1
      c0-1.5-0.1-2.9-0.1-4.3c0-1-0.1-2-0.2-2.9v-0.1c0-0.3,0-0.6-0.1-0.9l3.7-0.4c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.2-0.2,3.2-0.3
      c1.1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9c0.7-0.1,1.4-0.4,2.1-0.7h0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.7-0.3,1-0.4
      c0.2,0.5,0.3,1.1,0.5,1.6l0.1,0.3c0.6,1.8,1.1,3.6,1.7,5.3c0.5,1.5,1.2,3,2.1,5c0.5,1.1,1.1,2.3,1.6,3.4v0.1c0.5,1,1,2.1,1.5,3.2
      c0.8,1.8,1.7,3.7,2.4,5.3l0.1,0.3c0.4,0.8,0.8,1.7,1.1,2.5l0.1,0.3c0.2,0.5,0.5,1.1,0.8,1.7c0.1,0.2,0.1,0.3,0.1,0.4
      C563.6,1344.8,563.2,1345,563.1,1345z M566.3,1342.8c-0.2-0.5-0.5-0.9-0.7-1.4c-0.6-1.2-1.2-2.4-1.7-3.6c-1.5-3.2-2.6-5.8-3.6-8.1
      c-1.9-4.5-3.8-9-5.5-13.3c-0.5-0.8-0.6-1.5-0.5-2.1c0.1-0.5,0.5-1,0.9-1.3c0.9-0.7,1.9-1.3,2.9-1.9l0.1-0.1c1.6-1,3.2-2.1,4.8-3.3
      c1.8-1.4,3.5-3,5.2-4.6l0.1-0.1c0.7-0.7,1.4-1.3,2.1-2c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1c3.8,8.3,9.1,15.5,14.3,22.6l0.2,0.3
      C580.3,1329.4,574.3,1335.4,566.3,1342.8z"
      />
      <path
        d="M304.7,1113.5c-2.3-3.3-4.6-6.5-6.7-9.4c-0.9-1.3-1.9-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.8,0.3-1.5,0.5-2.3l0.1-0.5
      c0.3-1.4,0.6-2.7,0.9-4.1c0.2-0.8,0.3-1.5,0.5-2.3c0.2-0.8,0.3-1.7,0.5-2.5c0.3-1.4,0.6-2.8,0.9-4.2v-0.1c0.5-2.3,1-4.6,1.5-7
      c0.3-1.4,0.5-2.8,0.8-4.3c0.2-1.5,0.5-3,0.8-4.4c0.1-0.5,0.2-1.1,0.4-1.6c0.5-2.1,0.9-4.2,0.7-6.4c-0.3-3.1-0.7-7.1-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-0.9-0.4-1.9-0.6-2.8c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.3-2.3-1.9-3.4
      l-0.1-0.1c-0.7-1.3-1.4-2.6-2.2-3.9c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-1-1.4-1.4-2c-0.3-0.5-0.8-1-1.1-1.6
      c-0.5-0.7-0.9-1.4-1.3-2.1v-0.1c-0.8-1.3-1.6-2.6-2.6-3.8c-1-1.3-2-2.5-3-3.6c-0.9-1-1.9-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.8-3.8
      c-0.7-1-1.5-2.1-2.2-3.1c-0.6-0.8-1.2-1.5-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.7-6-8.2-8c-2.5-1.5-5.2-3-7.9-4.3
      c-2.5-1.2-5-2.3-7.5-3.5c-2-0.9-4.3-1.9-6.5-3c-2.1-1-4.2-2.1-6.3-3.1l-0.1-0.1c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1
      c-2-0.4-4.1-0.5-6-0.6h-0.3c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3
      c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1
      c-3.3,0.2-6.7,0.5-9.8,2.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4
      c-2,0.9-4.1,2-6,3c-1.4,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.9,1.8-5.6,2.6h-0.1c-1.9,0.9-3.9,1.8-5.9,2.7c-1,0.5-1.9,1-2.9,1.6
      c-1,0.6-2,1.1-3,1.6c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1.1-1.2,1.5c-0.4,0.5-0.8,1.1-1.2,1.6c-0.6,0.7-1.2,1.4-1.8,2.1
      c-0.9,1-1.8,2.1-2.6,3.2c-1.1,1.4-2.2,2.9-3.1,4.2l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.2-2.6,3.2c-0.9,1.1-1.8,2.2-2.7,3.4
      c-0.7,0.9-1.3,1.8-1.8,2.7c-0.3,0.4-0.6,0.9-0.8,1.3c-0.6,0.9-1.2,1.8-1.7,2.6c-1.3,1.9-2.5,3.8-3.7,5.8c-1.9,3.3-3.7,6.9-5.2,9.9
      c-1,2-1.7,3.7-2.1,5.3c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10.1-2.3,14.3c-0.3,2.2-0.5,4.4-0.8,6.6v0.1
      c-0.2,1.5-0.3,3.1-0.5,4.6c-0.3,2.7-0.2,5.4,0.4,8.3c0.3,1.2,0.5,2.5,0.8,3.7v0.1c0.4,2,0.9,4.1,1.3,6.2c0.2,1.2,0.4,2.4,0.6,3.6
      c0.2,1.1,0.4,2.6,0.7,3.9c0.3,1.5,0.7,3.1,1,4.5v0.1c0.3,1.2,0.6,2.5,0.8,3.7c0.3,1.5,0.6,3.1,0.9,4.5l0.1,0.4
      c0.1,0.7,0.3,1.4,0.4,2.1c0.1,0.3,0.1,0.7,0.2,1v0.1c0,0,0,0-0.1,0c-0.8,0.2-1.6,0.4-2.4,0.5l-0.6,0.1c-2.2,0.5-4.4,1-6.7,1.5
      c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1c-2.5,3.3-5.3,7.2-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3
      c0.8,2.9,1.7,5.9,3,9.7c0.5,1.3,1,2.6,1.5,3.9v0.1c0.5,1.1,0.9,2.3,1.3,3.4c0.7,1.9,1.3,4,1.9,5.8c0.4,1.3,0.8,2.4,1.2,3.6
      c0.2,0.5,0.4,1,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2c0.2,0.9,0.2,1.9,0.3,2.8c0,0.6,0.1,1.4,0.1,2.1l0.1,0.9c0.5,4,0.9,8,1.5,12
      c0.4,2.8,0.9,5.5,1.4,8.2c0.1,0.7,0.3,1.4,0.4,2.1c0,0.2,0.1,0.4,0.1,0.7c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6
      c0.8,0.8,1.8,1.5,2.7,2.2c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7
      c0.2,0,0.4,0.2,0.5,0.3c0.3,0.7,0.5,1.4,0.8,2c0.3,0.7,0.5,1.4,0.8,2.1c0.3,0.6,0.6,1.3,0.8,1.9v0.1c1.1,2.5,2.1,5,3.3,7.5
      c1.3,2.6,2.7,5.3,3.9,7.7l0.1,0.2c0.9,1.7,1.8,3.4,2.7,5.2c0.8,1.7,1.7,3.5,1.7,5.6c0,3.4,0.1,6.8,0.2,10.1
      c0.1,2.6,0.1,5.2,0.1,7.8c0,3.1,0,6.3,0,9.4c0,1.6,0,3.1,0,4.7v1.5c0,1.8,0,3.7,0,5.6c0,1.6,0.1,3.2,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6
      c0,1.1,0,2.1,0,3.2v0.1c0,1.8,0,3.6,0.1,5.4c0.1,2.1,0.2,4.2,0.3,6.2v0.2c0.1,1.5,0.2,3.1,0.3,4.6c0.1,1.1,0.1,2.3,0,3.8
      c0,0.1,0,0.2,0,0.3v0.1c0,0.6,0,1.3-0.2,1.7c-0.5,1-1.1,2-1.7,3c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6
      c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.8,1.3-1.2,1.9v0.1c-0.6,1-1.3,2.1-2,3.1c-0.8,1-1.6,2-2.4,3c-0.9,1.1-1.8,2.2-2.7,3.4l-0.1,0.1
      c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2c-2.8,1.6-5.7,3.2-8.5,4.8c-1,0.6-2.1,1.3-3,1.8c-1,0.6-1.9,1.2-2.9,1.8
      c-0.9,0.5-1.8,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2c-1.2,0.7-2.3,1.5-3.4,2.3c-0.7,0.5-1.5,1-2.3,1.6c-1,0.6-2,1.3-3,1.9l-0.1,0.1
      c-0.4,0.3-0.9,0.5-1.3,0.8l-1,0.6l1.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c1-0.5,2-1,3-1.5l0.6-0.3
      c3-1.5,6.2-3,9.2-4.6c2.1-1,4.1-2.3,6-3.4l1.2-0.7c0.8-0.5,1.5-0.9,2.2-1.4c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.3-1.2,3.4-1.7
      c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1l0.1-0.1c0.3-0.4,0.7-0.7,1.1-1.1l0.2-0.2
      c0.7-0.7,1.4-1.4,2.1-2.2c0.7-0.7,1.3-1.5,2-2.3l0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.7l0.3,0.4c0.4,0.5,0.8,1,1.3,1.5
      c0.3,0.4,0.6,0.8,1,1.2l0.1,0.1c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8c0.7,0.6,1.3,1.2,2,1.9s1.5,1.4,2.2,2.2
      c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5v0.1c-0.1,0.3-0.2,0.6-0.4,1c0,0.1-0.1,0.1-0.1,0.2
      l-0.1,0.2c-0.3,0.7-0.7,1.4-1,2.1v0.1c-0.8,1.7-1.6,3.4-2.3,5.1c-0.9,2.2-1.7,4.6-2.5,6.8l-0.2,0.5c-0.2,0.5-0.4,1.1-0.6,1.6
      c-0.1,0.3-0.2,0.7-0.4,1c-0.5,1.4-1,2.8-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.3-0.1,0.5l-0.1,0.4
      l0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2
      c0.9-2.9,1.7-5.2,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7c0.5-1.2,1.1-2.4,1.7-3.5l0.1-0.3c0.1-0.3,0.3-0.5,0.4-0.8c0.4,0.3,0.9,0.5,1.3,0.8
      c1.2,0.7,2.3,1.4,3.5,2c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.2,1.3,4.8,1.8l0.2,0.1c0.8,0.3,1.6,0.6,2.4,0.9
      c1.1,0.4,2.1,0.8,3.2,1.2c2.3,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,1-0.1,1.9-0.2,2.9v0.1c-0.2,2.7-0.4,5.5-0.6,8.2
      c-0.1,1.4-0.1,2.8,0,4.1v0.2c0,0.6,0,1.1,0,1.7v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.2-2.6,0.3-3.8v-0.1
      c0-0.7,0.1-1.4,0.1-2.1c0.1-1,0.2-2.1,0.3-3.2c0.1-1.3,0.2-2.5,0.3-3.7c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0
      c0.6,0.1,1.3,0.1,2,0.1h0.1c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4
      c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.5
      c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.2
      c0.3,3.4,0.5,7,0.9,10.5c0.1,1,0.4,1.9,0.7,2.8v0.1c0.1,0.4,0.2,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4
      c-0.1-1.8-0.3-3.6-0.4-5.3c-0.1-1-0.2-2.3-0.3-3.5c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3
      c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.2,1.1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.8-1.6,5.6-2.4
      l0.3-0.1c3.1-1.4,6.4-2.8,9.5-4.2c2-0.9,4.4-2,6.8-3.2c1.2-0.6,2.4-1.3,3.6-2l0.1-0.1c0.4-0.2,0.8-0.5,1.2-0.7c0.3,0.6,0.6,1.3,1,2
      c0.9,1.8,1.8,3.6,2.5,5.5c1,2.4,1.9,4.9,2.7,7.3l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.9,8.1c0.4,0.6,1,1,1.6,1
      c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4
      c-0.5-1.6-1.2-3.1-1.8-4.4l-0.2-0.5c-1-2.1-2-4.3-3.1-6.5c-1.1-2-1-2.1,0.6-3.6c1.6-1.4,3.1-2.8,4.5-4.3l0.6-0.5
      c0.6-0.6,1.1-1.2,1.7-1.7c0.9-1,1.9-2,2.9-2.9c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.3,1.4
      c1.7,1.8,3.2,3.5,4.8,5.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.4,0.5,0.8,0.8,1
      c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.2,1.2,3.3,1.8l0.2,0.1c1.5,0.8,3.1,1.7,4.7,2.6c1.2,0.7,2.4,1.4,3.6,2.2c1.2,0.7,2.6,1.6,3.9,2.3
      c2.2,1.2,4.4,2.4,6.6,3.4c1.8,0.9,3.7,1.7,5.3,2.5l0.4,0.2c0.5,0.2,0.9,0.4,1.4,0.7h0.1c0.3,0,0.5-0.2,0.5-0.4v-0.4h-0.3
      c-0.8-0.6-1.7-1.1-2.5-1.7c-2-1.4-4-2.8-6.1-4.1c-3-1.9-6.1-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.2-1.8-4.6-2.6l-0.1-0.1
      c-0.7-0.4-1.4-0.8-2.1-1.2l-1.3-0.7c-2.3-1.3-4.7-2.6-7-3.9c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6
      c-1.5-2-2.9-4.1-4.3-6.1l-0.2-0.3c-0.6-0.9-1.3-1.9-1.9-2.8c-0.6-0.8-1.2-1.6-1.7-2.4c-0.3-0.4-0.7-0.9-1.1-1.4
      c-0.7-0.9-1.4-1.9-2-2.8c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5
      v-0.1c0-0.6,0-1.2-0.1-1.8c-0.1-1.3-0.1-2.6-0.1-3.9c0.1-2.1,0.2-4.2,0.3-6.2c0.1-1.9,0.2-3.9,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4
      c0-2.5,0-5-0.1-7.4c0-1,0-2-0.1-3c0-0.7,0-1.4,0-2v-0.5c0-1,0-2,0-3c0-0.5,0-1.1,0-1.6c0-1.6,0-3.2,0-4.8c0-2.2,0.1-4.4,0.2-6.5
      v-0.3c0.1-1.9,0.1-3.9,0.2-5.8v-0.4c0.1-3.2,0.1-6.1,1.7-9c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5.2,3.3-7.5c0.3-0.7,0.6-1.5,1-2.2
      c0.3-0.7,0.6-1.5,0.8-2.1c0.3-0.7,0.5-1.4,0.9-2.1c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.7,0,1.1-0.1
      c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.2,7-6,9.9-8.7c1.3-1.2,2.9-2.6,2.9-5
      c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6c0.2-1,0.4-2,0.6-3.1v-0.1c0.3-1.8,0.7-3.6,1-5.4c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2
      c0.4-3.2,0.7-6.4,1.1-9.4c0-0.4,0.1-0.9,0.1-1.3c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9c0.3-0.8,0.6-1.5,0.8-2.2
      c1.4-3.9,2.6-7.2,3.7-10.3l0.5-1.3c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.1,0.8-2.4,0.2-3.8
      C305.9,1115.3,305.3,1114.4,304.7,1113.5z M281.3,1100.2l2.4,0.6c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2
      c2.4,0.6,4.6,1.2,6.1,3.5c1.4,2.2,3.1,4.3,4.7,6.4c0.6,0.7,1.1,1.5,1.7,2.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.5-2.8-0.7
      c-2.8-0.3-5.3-0.5-7.5-0.6c-0.1,0-0.3,0-0.4,0c-1.2,0-2.4,0.1-3.6,0.2h-0.1c-0.6,0.1-1.3,0.1-1.9,0.1c-0.1,0-0.2-0.1-0.2-0.1
      c-1.9-2.3-3.9-4.8-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5c0.4-0.9,0.6-1.9,0.7-2.8l0.1-0.5c0.1-0.7,0.4-0.7,0.6-0.7
      C281.1,1100.2,281.2,1100.2,281.3,1100.2z M244.4,1270.5c-0.1,4.4-0.3,8.9-0.5,13.2l-0.1,3.3c0,0.6,0,1.2-0.1,1.8
      c-0.1,2.6-0.2,5.3-0.5,7.9c-0.1,0.8-0.8,2-1.4,2.3c-1.5,0.6-2.5,1.6-3.5,2.6c-0.5,0.5-1.1,1.1-1.7,1.6c-0.7,0.5-1.5,1.1-2.2,1.7
      c-0.9,0.7-1.8,1.4-2.8,2.1c-1.2,0.9-2.4,1.7-3.6,2.6c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1,0.1-0.3,0.2-0.5,0.3l-0.1-1.4
      c-0.1-1.4-0.2-2.7-0.3-4.1c-0.1-1.3-0.1-2.5-0.2-3.8c-0.1-2-0.2-4.4-0.3-6.6c-0.2-2.2-0.4-4.4-0.6-6.6v-0.1c-0.1-1.6-0.3-3.3-0.4-5
      c-0.2-2.2-0.3-4.4-0.5-6.8v-0.3c-0.2-2.7-0.4-5.4-0.6-8.1c-0.3-3.4-0.6-6.8-1-10.1v-0.1c-0.1-0.6,0.1-0.8,0.5-1.1
      c4.2-2.6,8.3-5.2,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3c0.8-1.1,1.5-2.1,2.2-3.1c0.2-0.3,0.3-0.6,0.4-0.9c0-0.2,0.1-0.3,0.2-0.5
      c0.8-1.8,1.6-3.6,2.4-5.4c0.8-1.8,1.6-3.6,2.3-5.3c0.4-0.8,0.7-1.6,1.1-2.5v0.3c0,1.4,0,2.9,0,4.3c0,2.4-0.1,4.8-0.1,7.2
      c0,2.2-0.1,4.5-0.1,6.8c-0.1,3.4-0.1,7-0.1,10.5c-0.1,1.6-0.1,3.3-0.1,4.6C244.4,1265.9,244.5,1268.2,244.4,1270.5z M154.2,1278.4
      c0.1-1.6,0.1-3.2,0-4.8c0-0.6,0-1.2,0-1.8c5.9,0.6,11.9,0.9,17.7,0.9c5.9,0,11.9-0.3,17.7-0.9l0.2,7c0.1,5.1,0.3,10.4,0.4,15.6
      c0.1,5.2,0.2,10.3,0.4,16l0.2,6.8c-0.1,0-0.1,0-0.2,0l-2,0.1c-3.1,0.2-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0
      c-0.7,0-1.5,0.2-2.2,0.3c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2
      c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0-3.4-0.1c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.8-0.1H153
      c0.1-1.6,0.1-3.1,0.2-4.6v-0.3c0.1-3.5,0.3-7.1,0.3-10.6c0-1.8,0-3.7,0-5.5v-0.1c0-2.1,0-4.4,0-6.5c0.1-2.1,0.2-4.2,0.4-6.2v-0.1
      C154,1281.7,154.1,1280,154.2,1278.4z M186.5,1269.5c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.7,0-1,0.1h-0.2
      c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3c-4.9-0.4-9.8-0.9-14.6-1.4h-0.2c-1.5-0.1-3-0.4-4.4-0.6H146
      c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5c0.3-1.1,0.6-2.2,0.9-3.3
      c0.3-1.2,0.6-2.4,0.9-3.5l0.1-0.2c0.1-0.4,0.2-0.8,0.3-1.2c0.7,0,1.3,0,1.9,0h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0
      c2.7,0.1,5.9,0.2,9,0.5c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2h0.2c0.4,0,0.8,0,1.2,0
      c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0c0.1,0.4,0.2,0.8,0.3,1.2l0.1,0.3c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.7,1,3.5,1.5,5.2v0.1
      c0.7,2.2,1.4,4.7,2.1,7.1c0.2,0.8,0.1,1.8-0.1,2.7v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1
      C190,1269.1,188.2,1269.3,186.5,1269.5z M100.3,1287.3v-0.6c-0.1-1.5-0.2-3.1-0.2-4.6c0-1.1-0.1-2.2-0.1-3.3
      c-0.1-3.4-0.2-7-0.3-10.5c0-2.6,0-5.2,0.1-7.8v-0.2c0-2.3,0.1-4.7,0.1-7c0-4.6-0.1-9.2-0.2-13.7v-1.1c0-1.7-0.1-3.4-0.2-5.3
      c0-1.1-0.1-2.3-0.1-3.4v-0.1c0.5,1.1,0.9,2.2,1.4,3.2l0.1,0.2c1.5,3.4,3,6.9,4.4,10.4c0.1,1,0.8,2.1,1.6,3.1
      c0.4,0.6,0.8,1.2,1.2,1.8c0.3,0.5,0.9,0.8,1.5,1.1c0.2,0.1,0.5,0.2,0.7,0.4c0.8,0.5,1.7,1.1,2.5,1.6l0.1,0.1c2,1.3,4,2.6,6.1,3.7
      c2.1,1.1,2.8,2.3,2.4,4.6c-0.6,3.6-0.8,7.4-1.1,11c-0.1,1-0.2,2.1-0.2,3.1c-0.2,1.9-0.3,3.9-0.4,5.6c-0.1,1.2-0.1,2.4-0.2,3.5
      c-0.2,3.3-0.5,6.6-0.7,9.8v0.1c-0.2,2.5-0.4,5-0.5,7.5c-0.2,3.3-0.4,6-0.6,8.5c0,0.5,0,0.9,0,1.4v0.1l-0.4-0.2
      c-0.5-0.4-1-0.7-1.5-1c-0.5-0.4-1.1-0.8-1.7-1.1l-0.2-0.1c-1.4-0.9-3-2-4.4-3.1c-1.3-1-2.6-2.2-3.8-3.3c-0.6-0.6-1.3-1.2-1.9-1.8
      c-0.3-0.2-0.6-0.4-1-0.6c-1.2-0.4-2-1.5-2-2.9c-0.1-2-0.2-4.1-0.3-6.1v-0.4C100.4,1289,100.4,1288.2,100.3,1287.3z M89.2,1039
      c0.7,3.2,1.4,6.3,2.2,9.4c0.3,1.3,0.6,2.6,1,3.9c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5.1,5.4l-0.1,0.1
      c-1.3,1.4-2.6,2.8-4,4.2c-1.4,1.5-2.9,3-4.4,4.5c-1.6,1.7-3.1,3.2-4.6,4.7c-1.1,1.2-2.3,2.5-3.3,3.7c-0.5,0.6-1.1,1.2-1.6,1.8
      c0,0.1-0.1,0.1-0.2,0.2l-0.7-4.8c-0.5-3.8-1.1-7.4-1.6-11.1c-0.2-1.3-0.4-2.5-0.5-3.8c-0.4-2.7-0.7-5.4-1.2-8.1
      c-0.3-1.5,0.4-2.5,1.2-3.6l0.1-0.1c0.1-0.2,0.2-0.3,0.4-0.5c1.9-2.8,3.7-5.6,5.3-7.9c1.6-2.4,3.5-5.2,5.3-7.9l0.8-1.1
      c1.7-2.4,3.4-4.8,5.1-7.2c0.8-1,1.7-1.9,2.6-2.9c0.3-0.3,0.6-0.6,0.9-0.9c0.1,0.8,0.2,1.5,0.3,2.3l0.1,0.5c0.3,2.6,0.6,5.1,0.9,7.7
      v0.4C88.1,1032.6,88.6,1035.9,89.2,1039z M170.6,1027.1c0,0.7-0.1,0.8-0.9,0.8c-0.9,0.1-1.8,0.2-2.7,0.2c-3,0.3-6.2,0.5-9.2,0.9
      c-5.3,0.7-10.9,1.6-17.7,2.7c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5l-2.3-41.1l41-10.3v2.5c0,2.4,0,4.8,0,7.1
      c0,0.6,0,1.1,0,1.7v0.1c0,0.6,0,1.2,0,1.8c0,1.6,0,3.1-0.1,4.7c0,2-0.1,3.9-0.1,5.7C170.6,1012.5,170.6,1019.8,170.6,1027.1z
       M273.4,1040.6c0.3,0.5,0.7,1,1,1.6c1.2,1.9,2.5,3.8,3.8,5.6c0.7,0.9,0.8,1.7,0.7,2.9c-0.2,1.2-0.3,2.4-0.5,3.6
      c-0.2,1.5-0.4,3.1-0.7,4.6c-0.2,1.3-0.4,2.7-0.5,4v0.3c-0.3,2.1-0.6,4.3-0.9,6.5c-0.3,2.1-0.7,4.2-1,6.5l-0.1,0.5
      c-0.1,0.6-0.2,1.2-0.3,1.8c-0.1-0.1-0.2-0.3-0.4-0.4l-0.2-0.3c-0.7-0.8-1.4-1.6-2-2.3c-2.8-3-6.2-6.6-9.6-10.3
      c-1.5-1.5-2.9-3-4.6-4.7l-0.3-0.3c-0.9-1-1.9-1.9-2.8-2.9c-0.4-0.4-0.8-0.8-1.1-1.2c-0.7-0.7-1.4-1.5-2.1-2.2
      c-0.5-0.4-0.5-0.9-0.4-1.4c0.3-1.3,0.7-2.6,1-3.9v-0.1c0.7-2.6,1.4-5.3,2-8c0.4-1.7,0.6-3.4,0.8-5v-0.1c0.1-0.7,0.2-1.4,0.3-2.1
      c0.1-0.7,0.2-1.5,0.3-2.2c0.3-1.9,0.6-3.9,0.9-5.8c0.1-1,0.2-2,0.3-3c0.1-0.8,0.1-1.5,0.2-2.2c0-0.4,0.1-0.8,0.2-1.3v-0.1
      c0.3,0.4,0.6,0.7,0.9,1.1c1,1.2,2,2.3,2.9,3.4c2.8,3.5,5.3,7,7.7,10.4c1,1.4,1.9,2.9,2.8,4.3v0.1
      C272.2,1038.9,272.8,1039.8,273.4,1040.6z M278.7,1097.6c-0.5,2.3-1,4.6-1.5,6.9c-0.2,1-0.5,2-0.9,3c-0.3,1-0.6,2-0.9,3
      c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2c-0.3,0-0.8-0.1-1.3-0.2c-0.2,0-0.5-0.1-0.8-0.2c-0.5-0.1-0.9-0.3-1.4-0.5
      l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2c1.1-4.5,2.2-8.7,3.2-12.7c0.5-1.8,0.9-3.5,1.4-5.3c0.9-3.4,1.8-6.8,2.6-10.3
      c0.4-1.7,0.7-3.4,1-5.1c0.2-0.9,0.3-1.8,0.5-2.6c0.1-0.6,0.3-1.2,0.4-1.9l0.1-0.4l0.3,0.4c0.6,0.7,1,1.2,1.5,1.8
      c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.5,0.8,0.8,1.1c1.1,0.8,1,1.7,0.7,3.3c-0.6,2.5-1.1,5.1-1.6,7.4L278.7,1097.6z M279,1109.1
      c1.5,1.7,2.8,3.3,4.1,4.9c0.1,0.3,0.1,0.6-0.2,1.3c-2.3,4.4-4.7,9.1-7.5,14.8c-0.6,1.2-1.2,2.5-1.6,3.6c-0.3,0.7-0.6,1.4-1,2.2
      c0,0.1-0.1,0.1-0.1,0.2l5.5-27.7C278.5,1108.7,278.8,1108.9,279,1109.1z M213.4,1222.7c-2.1-1.9-4.2-3.8-6.2-5.6l-2.6-2.3
      c-0.2-0.2-0.2-0.3-0.2-0.4s0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5c0.4-0.4,0.8-0.8,1.2-1.1c0.1-0.1,0.2-0.2,0.3-0.2
      c0.5-0.5,1.2-1,1-2.1c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.5,0.8-1.1,1.2-1.6c1-1.3,2-2.7,2.9-4.2
      c1.1-1.7,1.9-3.6,2.7-5.5c0.9-2.1,1.8-4.2,2.7-6.3l0.1-0.3c0.2-0.5,0.4-1,0.6-1.5c-0.1,11.7-1.8,23-3.5,35l-0.1,0.8
      c-0.2-0.2-0.4-0.3-0.6-0.5C214.7,1223.8,214,1223.2,213.4,1222.7z M131.9,1073.6c-0.2,0-0.3,0-0.5,0c-0.9,0-1.9,0-2.8-0.1
      c-1.9,0-3.9-0.1-5.9-0.1h-0.1c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5c-0.7,0.9-1.4,1.7-2.2,2.5
      c-0.6,0.6-1.2,1.3-1.8,2c-0.9,1.1-1.8,2.3-2.6,3.4l-0.1,0.1c-0.5,0.6-0.9,1.3-1.4,1.9c-0.6,0.7-1.2,1.5-1.8,2.2l-0.5,0.6l-0.1-1.1
      c-0.1-1.2-0.3-2.5-0.4-3.7c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.9-0.4-4-0.7-5.9c-0.5-3.8-1-7.5-1.4-10.7c-0.3-2.3-0.7-4.4-1-6.4v-0.1
      c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.4-3.5l0.3-0.2c1.2-0.6,2.3-1.3,3.5-1.9c1.1-0.6,2.2-1.2,3.4-1.8
      c1.5-0.8,3.1-1.7,4.7-2.6c1-0.5,2-1.1,3-1.6h0.1c1.4-0.7,3-1.6,4.5-2.4c1.5-0.8,2.9-1.7,4.4-2.6l0.1-0.1c0.6-0.4,1.2-0.8,1.8-1.1
      c0.4-0.2,0.7-0.5,1.2-0.7l0.6-0.4l2,37.2H131.9z M132.7,1076.5h0.2l-1.9,22.3c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7,0-1.1,0
      c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7h-0.1c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1
      c-2.9-1.9-5.9-3.9-8.7-5.9c-1-0.7-1.8-1.3-2.7-1.9l-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.5c0.4-0.6,0.9-1.1,1.3-1.7
      c1.1-1.5,2.2-2.9,3.4-4.3c1.1-1.3,2.3-2.7,3.5-3.9l0.1-0.1c0.8-0.9,1.7-1.8,2.5-2.8c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1
      c0.2,0,0.5,0,0.7,0c2.2-0.1,4.2-0.1,6.1-0.1c4.2-0.1,7.9-0.1,11.3-0.1C132.1,1076.4,132.4,1076.5,132.7,1076.5z M96.8,1082.2
      l0.1,0.6c0.2,1.5,0.4,3,0.5,4.6v0.3c0.1,1,0.1,1.9-0.3,2.8c-0.5,1.2-0.8,2.5-1.1,3.8c-0.1,0.5-0.2,0.9-0.3,1.4l-0.3,1.4
      c-0.6,2.4-1.2,4.9-1.7,7.3c-0.2,1-0.4,1.9-0.6,2.9c-0.2,0.9-0.3,1.9-0.6,2.9c-0.1,0.5-0.4,1-0.8,1.4c-1.2,1.2-2.5,2.3-3.7,3.4
      l-0.2,0.2c-1,0.9-2,1.9-3,2.8c-1.3,1.3-2.7,2.6-4,3.9l-0.1,0.1c-0.4,0.4-0.9,0.9-1.4,1.3l-0.6-2.5c-0.6-2.2-1.1-4.4-1.6-6.5
      c-0.4-1.7-0.8-3.3-1.3-5l-0.1-0.5c-0.6-2.5-1.3-5-1.9-7.5c-0.9-3.5-1.5-6.2-2.1-8.7c-0.3-1.4-0.6-2.8-0.9-4.2
      c-0.4-1.8-0.8-3.7-1.2-5.5c-0.3-1.2,0-2.2,1-3.1c1.3-1.4,2.7-2.8,4-4.2l0.2-0.2c1.5-1.6,2.8-3,4.2-4.4c1.3-1.4,2.7-2.7,4-4.1
      c1.7-1.8,3.5-3.6,5.3-5.5c1.1-1.2,2.2-2.5,3.2-3.6l0.1-0.1c0.5-0.5,1-1.1,1.4-1.6l0.1-0.1l0.1,0.7c0.2,0.9,0.3,1.7,0.4,2.6l0.1,0.6
      c0.5,3.4,1,6.9,1.5,10.3C95.8,1074.3,96.3,1078.5,96.8,1082.2z M98,1095.7c0.2-1.1,0.5-2.2,0.8-3.3l0.1-0.3
      c0.1-0.3,0.1-0.6,0.2-0.8l13.7,9.4l-3.7,3.7c-0.9,0.8-1.7,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.9l1.1,0.9
      c1.8,1.5,3.6,3.1,5.4,4.6c0.6,0.5,1.2,0.9,1.8,1.3c0.2,0.1,0.3,0.2,0.5,0.4l-4.3,7.9c-0.2-0.1-0.5-0.3-0.7-0.4
      c-3.5-2.6-7.2-5.3-10.9-8.1c-1.8-1.3-3.4-2.5-4.8-3.7c0,0-0.1-0.3-0.1-0.5C95.8,1105.6,96.9,1100.6,98,1095.7z M117,1117.3
      c0.1-0.1,0.4-0.3,0.5-0.3c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2c0.6,0,1.2,0,1.8,0c0.8,0,1.6,0,2.3,0c1,0,2,0,3,0
      c0.6,0,1.1,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0.1v9.8l-18.6-2.5l0.7-1.3C114.6,1121.5,115.8,1119.4,117,1117.3z M110.5,1139.3
      c0.1-0.9,0.2-1.8,0.4-2.6c0.5-1.7,0.8-3.2,1.1-4.5c0.1-0.6,0.2-1.1,0.4-1.7c0-0.2,0.1-0.4,0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1
      c0-0.1,0.3-0.3,0.4-0.3h0.1c3.2,0.3,6.6,0.7,9.8,1.1c1.5,0.2,3,0.5,4.4,0.8h0.1c1,0.2,1.9,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1
      c0.1,3.4,0.2,6.3,0.4,8.9c0.1,1.3,0.2,2.6,0.4,3.8v0.1c0.1,0.9,0.2,1.8,0.3,2.6c0.1,0.7,0.1,1.4,0.1,2.1c0,0.3,0,0.6,0,0.9
      l-24.9,6.7c0-0.1,0-0.3,0-0.4v-0.2c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.6,0.6-3.2,0.9-4.8v-0.1c0.4-2.1,0.8-4.3,1.2-6.4
      C110.3,1141.2,110.4,1140.2,110.5,1139.3z M131.4,1115.2c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3c-1.5,0-3.1-0.1-4.6-0.3
      h-0.1c-0.4,0-0.7-0.1-1.1-0.1c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.3-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.1
      s-0.4,0-0.5-0.1c-1.8-1.5-3.6-3-5.5-4.6l-1.6-1.4l0.4-0.3c0.4-0.3,0.7-0.6,1.1-0.9c0.4-0.4,0.8-0.7,1.2-1.1
      c1.1-0.9,2.2-1.9,3.2-2.9c1.5-1.5,3.4-1.5,5.2-1.5h0.3c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.4,0.5,3.6,1.5
      c1,0.8,2,1.6,3,2.4l0.3,0.2c0.6,0.2,1,0.5,1.5,0.9l0.6,0.5c0.7,0.6,1.5,1.2,2.2,1.8c0.2,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.5,0.9
      c-2.5,2.5-5.2,5-9.2,5c-0.2,0-0.4,0-0.6,0C132.8,1115.2,132.1,1115.2,131.4,1115.2z M159,1098.7c0.1,0.1,0.2,0.3,0.2,0.4
      l-10.7,11.7c-0.5-0.5-0.9-1-1.4-1.4c-1.2-1.3-2.5-2.6-3.9-3.8c-2-1.7-4.2-3.5-7.2-5.7c-0.6-0.4-1.3-0.7-2.1-0.9
      c-0.3-0.1-0.5-0.2-0.8-0.3l1.6-22.4c0.1,0,0.3,0.1,0.4,0.1h0.1c0.6,0.2,1.2,0.4,1.7,0.7c0.9,0.5,1.9,0.9,2.8,1.3
      c2.8,1.3,5.7,2.7,8,5c3.6,3.7,6.3,7.3,8.4,10.9c0.6,1,1.3,2,1.9,2.9v0.1C158.3,1097.8,158.7,1098.2,159,1098.7z M161,1115.7
      c-1.7,1-3.5,2.1-5.1,3.2l-0.1,0.1c-1.3,0.8-2.6,1.7-4,2.5c-0.5,0.3-1.2,0.6-1.8,0.9c-0.2,0.1-0.4,0.2-0.5,0.2
      c-0.1-0.6-0.2-1.1-0.4-1.6c-0.3-1.6-0.7-3-0.8-4.5c-0.1-1,0.4-2.3,1.2-3.2c2.3-2.7,4.8-5.3,7.2-7.8l-0.1-0.1h0.1
      c0.6-0.7,1.3-1.3,1.9-2c0.5-0.5,1-1,1.6-1.5l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.1,1.1c0.1,1.2,0.2,2.4,0.2,3.6
      c0.2,2.3,0.5,5.4,0.8,8.4C161.6,1115.2,161.5,1115.4,161,1115.7z M161.7,1118.1c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,0.9,0.1,1.3
      c0.1,1.4,0.3,2.7,0.4,4c0,0.7,0,1.3,0,2c0,0.6,0,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.5-3.8,3-6.2,4.9c-0.5,0.4-1,0.7-1.5,1.1
      l-0.4,0.3l-0.2-0.5c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3c-0.4-1.6-0.9-3.4-1.5-5.1c-0.3-0.8,0-1,0.7-1.3
      c0.4-0.2,0.9-0.4,1.3-0.6c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.3-1.3,3.2-2l0.1-0.1C160.7,1118.7,161.2,1118.4,161.7,1118.1z
       M146.2,1114.7c-0.1,0.2-0.5,0.5-0.7,0.5c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1l3.5-3.3C146.9,1112.4,147,1113,146.2,1114.7z
       M133.1,1126.1c0.4-2.5,0.3-4.9,0.2-7.8c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0
      c0.1,0,0.3,0,0.4,0.1h0.1c1.5,0.3,3,0.7,4.7,1l1.9,0.4l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.3c0,0.1-0.2,0.4-0.4,0.5l-2.1,0.9
      c-2.9,1.2-5.9,2.4-8.9,3.5c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1C133,1128.1,133.1,1126.9,133.1,1126.1z M143.3,1127.6
      c1.3-0.6,2.8-1.2,4.3-1.8l3.6,11.7l-16.3,10.5l-1.5-17.2C137.1,1130.3,140.3,1128.9,143.3,1127.6z M135.6,1150.4
      c1.9-1.2,3.8-2.5,5.7-3.6l0.1-0.1c3-1.9,6.1-3.9,9.1-5.9c2.3-1.6,4.6-3.3,6.8-5c1-0.8,2-1.5,3-2.3c0.1-0.1,0.3-0.2,0.4-0.3
      l-0.2,0.7c-0.3,1.1-0.6,2.3-0.9,3.5l-0.3,1.2c-0.5,2.2-1.1,4.5-1.5,6.8c-0.5,2.8-1.3,5.1-3.7,7c-2.6,2.1-5,4.5-7.4,6.9
      c-1,1-2.2,2.2-3.3,3.2c-1.6,1.5-3.3,3.1-4.8,4.5l-0.1,0.1c-0.7,0.6-1.3,1.2-1.9,1.8c0-0.3-0.1-0.6-0.1-1c-0.1-0.9-0.3-1.9-0.4-2.9
      c-0.2-2.2-0.4-4.6-0.5-6.6c-0.1-1.1-0.2-2.3-0.3-3.4c0-0.4-0.1-0.8-0.1-1.2c-0.1-0.8-0.2-1.6-0.2-2.4
      C135,1151.1,135.3,1150.6,135.6,1150.4z M161.3,1211.6h0.2c1.8-0.1,3.7-0.2,5.6-0.2c0.3,0,0.5,0,0.8,0c1.2,0,2.6,0,3.7,0.1
      c1.1,0,2.3,0.1,3.5,0.1h0.2c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3c2.1,0.1,4.2,0.1,6.2,0.6c0.7,0.2,1.4,0.2,2.2,0.2
      c1.5,0,3-0.3,4.4-0.6c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1c-0.5,0.5-1,1-1.5,1.4
      c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4c-2.5,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8c-1.7,0-3.4,0.1-5.1,0.2h-0.2
      c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1c-1.9,0-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6c-0.4,0-0.8-0.3-1.4-0.8
      c-0.8-0.8-1.6-1.5-2.4-2.3l-0.1-0.1c-1.1-1.1-2.4-2.4-3.7-3.5c-1.7-1.5-3.5-3-5.2-4.4c-1.2-1-2.2-1.8-3.2-2.7
      c-0.6-0.5-1.1-1-1.6-1.6c0.7,0.1,1.4,0.2,2.1,0.4c0.9,0.2,1.8,0.4,2.6,0.5h0.1c2.4,0.5,4.9,0.9,7.2,1.6c0.6,0.2,1.2,0.2,1.9,0.2
      c0.9,0,1.7-0.1,2.5-0.2c0.7-0.1,1.4-0.2,2.1-0.2C158.1,1211.8,159.7,1211.7,161.3,1211.6z M159.9,1208.9c0.6-0.1,1.5-0.5,2.3-0.8
      c0.1,0,0.1,0,0.2-0.1c0.2,0,0.3-0.1,0.5-0.2c0.8-0.5,1.6-0.8,2.4-0.8c1.2,0,2.3,0.5,3.6,1c0.1,0.1,0.3,0.1,0.4,0.2h0.1
      c0.7,0.3,1.3,0.6,2.1,0.6c1,0,2-0.3,3-0.7l0.2-0.1c0.7-0.2,1.4-0.5,2.1-0.8h0.1c0.4-0.2,0.8-0.3,1.2-0.5c0.1-0.1,0.3,0,0.4,0
      c1.8,0.7,3.6,1.4,5.4,2.1h-2.8c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1
      c-1.3-0.1-2.7-0.1-4.4-0.1c-1.1,0-2.1,0-3.3,0C161.6,1208.9,160.7,1208.9,159.9,1208.9z M203,1208c-1,0.2-2,0.4-3,0.6l-1.5,0.3
      c-1.8,0.3-3.6,0.7-5.4,1c-0.4,0.1-0.9-0.1-1.3-0.2c-1.7-0.6-3.1-1.1-4.4-1.5c-2.7-1-5.1-2-7.4-2.9c-0.6-0.2-1.2-0.4-1.8-0.4
      c-0.7,0-1.5,0.2-2.1,0.6c-2.7,1.5-5.5,1.6-8.2,0c-0.7-0.4-1.4-0.6-2.1-0.6c-1.1,0-2.2,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1.1,0.4
      c-1.7,0.6-3.4,1.2-5,1.8l-0.2,0.1c-0.7,0.3-1.5,0.6-2.2,0.8H154c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.5-1.5,0.8-2.5,0.8
      c-0.8,0-1.6-0.2-2.5-0.3c-0.2,0-0.4-0.1-0.6-0.1c-1.8-0.4-3.7-0.7-5.3-1c-0.9-0.2-1.8-0.3-2.7-0.5c-0.1,0-0.2,0-0.4-0.1l0.7-0.3
      c1-0.5,2-1,3-1.5l1.7-0.9c2-1,4-2,6.1-3c1.9-0.9,3.9-1.8,5.7-2.7l0.4-0.2c0.6-0.6,1.4-0.9,2.2-1.3c0.3-0.1,0.6-0.3,0.9-0.4
      c0.7-0.3,1.3-0.6,2-1c0.4-0.3,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,1.9,0.4l1,0.3c1.9,0.6,3.8,1.2,5.8,1.3c1.5,0.1,3.2-0.4,5.1-1.3
      c0.8-0.4,1.6-0.6,2.3-0.6c0.9,0,1.7,0.2,2.8,0.7c1.8,0.9,3.7,1.8,5.5,2.6l0.2,0.1c1.5,0.7,3.1,1.5,4.7,2.3c2.3,1.1,4.6,2.3,6.9,3.5
      c1.1,0.6,2.2,1.1,3.3,1.7c0.4,0.2,0.8,0.4,1.1,0.6L203,1208z M195.2,1167.8l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.8,0.6-1.2
      c0.7-1.4,1.4-2.7,2.1-4c0.7-1.3,2-1.7,3.4-2.2C194.5,1168.1,194.8,1168,195.2,1167.8z M192.8,1166.5c-1,0.5-1.9,1-2.9,1.3
      c-0.3,0.1-0.8,0-1.4-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-1.8-0.3-3.7-0.6-5.5-0.9l-3.2-0.5c0-0.4,0.3-0.7,0.9-1c1.4-0.6,2.8-1.2,4.2-1.8
      l0.2-0.1c1.2-0.5,2.5-1.1,3.7-1.6c0.8-0.2,1.4-0.1,2,0.5c0.9,1.1,1.8,2.1,2.8,3.1c0.3,0.3,0.6,0.6,0.9,1
      C193.4,1166.2,193.1,1166.4,192.8,1166.5z M188.8,1169.9l-0.6,0.9c-0.9,1.7-1.7,3.3-2.5,4.8c0,0-0.1,0.1-0.2,0.1
      c-2.2,0.2-4.5,0.4-7,0.6l-1.1,0.1l1.5-8.1L188.8,1169.9z M178.1,1184.5l0.1,0.5c0.3,1.9,0.6,3.8,0.9,5.7c0.1,0.8,0.2,1.6,0.2,2.3
      v0.3c0,0.2,0,0.5,0.1,0.7c-0.6,0.2-1.1,0.3-1.7,0.5l-0.2,0.1c-1.8,0.6-3.5,1.1-5.3,1.4c-0.2,0-0.4,0.1-0.6,0.1
      c-0.8,0-1.7-0.3-2.6-0.5l-0.2-0.1c-1.1-0.3-2.2-0.7-3.4-1.2c-0.4-0.2-0.8-0.3-1.3-0.5l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4
      c0.1-0.9,0.3-1.8,0.4-2.8c0.2-1.4,0.4-2.9,0.7-4.3c0.2-1,0.4-1,0.6-1s0.5,0.1,0.8,0.2c2.9,0.8,5.6,0.8,8.2,0
      c0.4-0.1,0.6-0.2,0.8-0.2s0.5,0,0.6,1.1C177.6,1181.1,177.8,1182.8,178.1,1184.5z M165.8,1176.3l-0.7-0.1c-1.4-0.1-2.9-0.2-4.3-0.3
      h-0.1c-0.1,0-0.2-0.1-0.3-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3l-0.1-0.1c-0.2-0.3-0.3-0.7-0.5-1
      l9.8-1.6l1.5,8.1L165.8,1176.3z M155.8,1175.7l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1c-0.9-2.4-1.6-4.5-2.2-6.6
      c1.7,0.5,3.3,0.9,4.2,2.4c0.8,1.3,1.5,2.7,2.1,4l0.1,0.2C155.5,1175,155.6,1175.4,155.8,1175.7z M162.4,1166.5
      c-2.2,0.3-4.2,0.7-6.2,1c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.3,0.1-0.4,0.1H155c-0.6,0-1.2,0-1.7-0.2c-0.8-0.3-1.5-0.6-2.3-1
      c-0.2-0.1-0.4-0.2-0.7-0.3c0.3-0.3,0.6-0.6,0.9-1c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1
      c2.5,1,5.2,2.2,8.3,3.6c0.1,0.1,0.3,0.4,0.6,0.8L162.4,1166.5z M156,1154c-0.1,1-0.3,2.1-0.6,3c-0.2,0.5-0.7,1-1.3,1.4
      c-0.3,0.3-0.6,0.5-0.9,0.8c-2.4,2.7-4.2,4.8-5.9,6.8c-0.5,0.6-0.9,1.3-0.4,2.5c0.6,1.5,1.1,3.1,1.6,4.7c0.3,1,0.6,1.9,0.9,2.7
      c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.5,1.6-3.8,2.4c-0.3,0.2-0.7,0.4-1,0.7h-0.1l-1-1.5c-1-1.4-1.9-2.9-2.9-4.4l-0.4-0.6
      c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1c1.9-1.7,3.3-3,4.6-4.3c2-1.9,3.9-3.8,5.9-5.7c1.2-1.1,2.3-2.2,3.5-3.3
      c1-0.9,1.9-1.8,2.9-2.8l0.8-0.8C156,1153.5,156,1153.8,156,1154z M145.5,1181.7c0.7-0.5,1.3-0.9,2-1.4c1.2-0.8,2.4-1.7,3.6-2.4
      c0.7-0.4,1.8-0.5,2.5-0.5c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4l1,0.1c1,0.1,2,0.2,3,0.3l1,0.1v1.6c-0.2,1.3-0.4,2.6-0.6,3.9
      c-0.1,0.8-0.3,1.6-0.4,2.5v0.2c-0.4,2.3-0.8,4.6-1,7c-0.1,1.1-0.5,1.3-1.3,1.6c-0.4,0.2-0.8,0.3-1.3,0.5c-0.9,0.3-1.8,0.7-2.6,1.1
      c-0.3,0.2-0.6,0.2-1,0.2c-0.3,0-0.6-0.1-1-0.1c-0.3-0.1-0.7-0.1-1.1-0.1H154c0,0-0.1-0.1-0.3-0.3c-1.2-1.9-2.3-3.8-3.5-5.7
      l-0.4-0.7c-1.5-2.4-3-4.9-4.4-7.3C145.1,1182.2,145.1,1182,145.5,1181.7z M190.4,1196c-0.3,0.5-0.5,0.6-1.3,0.9
      c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0-1.6-0.4-2.4-0.8l-0.4-0.2c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4
      c-0.4-0.1-0.5-0.3-0.5-0.9c0-1.5-0.3-3.1-0.5-4.6v-0.2c-0.4-2.4-0.8-5-1.2-7.1c-0.2-1.2-0.4-2.3-0.6-3.5c0-0.1,0-0.1,0-0.2l3.1-0.2
      c2-0.1,3.9-0.3,5.8-0.4c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1c0.8,0.5,1.5,1,2.3,1.6l0.1,0.1
      c0.8,0.6,1.8,1.3,2.8,1.9c0.5,0.3,0.5,0.5,0.2,0.9c-2.2,3.5-4.2,6.7-6.1,9.8c-0.6,1-1.2,2.1-1.8,3.1L190.4,1196z M194.6,1176.3
      l0.6-1.9c0.6-2,1.3-4.1,1.9-6.1c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2c-0.2-0.3-0.5-0.6-0.7-0.9
      c-0.8-0.9-1.5-1.9-2.5-2.6c-1.7-1.3-2-3-2.3-4.9c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0.2,0.1,0.3,0.3,0.4,0.4
      c1.1,1,2.2,2.1,3.2,3.1l0.7,0.6c2.5,2.4,4.6,4.4,6.8,6.6c2,1.9,4.1,3.9,6.3,5.8c0.7,0.6,0.7,1,0.1,1.7c-1.5,2.1-2.9,4.1-4.2,6.1
      c-0.4,0.6-0.7,1.2-1.1,1.8c-0.1,0.1-0.1,0.3-0.2,0.4L194.6,1176.3z M205.7,1167.2c-1.5-1.3-2.9-2.5-4.3-3.8
      c-0.8-0.7-1.6-1.6-2.3-2.3c-0.6-0.7-1.3-1.4-2-2c-2.6-2.5-5.4-5-8.1-7.5c-1.2-1.1-1.9-2.4-2.3-4c-0.6-3-1.4-6-2.1-9
      c-0.3-1.2-0.6-2.4-0.9-3.5c-0.1-0.6-0.3-1.2-0.5-1.8l-0.1-0.2l0.1,0.1c0.8,0.6,1.7,1.3,2.5,1.9l0.2,0.1c1.8,1.4,3.7,2.9,5.7,4.2
      c2.1,1.5,4.3,2.9,6.5,4.2c0.8,0.5,1.6,1,2.4,1.5s1.7,1.1,2.6,1.6c1.7,1.1,3.6,2.3,5.4,3.5c0.2,0.1,0.4,0.6,0.4,1
      c-0.3,4-0.6,8.1-0.9,12v0.1c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8C206.8,1168.1,206.2,1167.7,205.7,1167.2z
       M186.8,1159.2c0,0.2,0.1,0.4,0.2,0.6v0.1c-0.5,0.2-0.9,0.4-1.4,0.6c-1.2,0.5-2.6,1.2-4,1.7c-0.5,0.2-1,0.4-1.6,0.6
      c-0.6,0.2-1.2,0.4-1.8,0.7c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1s-0.3,1.9-0.5,2.8c-0.4,1.7-0.8,3.7-1.3,5.6
      c-0.1,0.2-0.6,0.5-0.9,0.6c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1c-0.3-1.5-0.6-3-0.9-4.5c-0.2-1.3-0.5-2.5-0.7-3.8
      c-0.7-4-0.9-4.2-4.9-5.3c-1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2c-0.5-0.2-1-0.5-1.4-0.8l-0.1-0.1c-0.1-0.1-0.2-0.1-0.3-0.2
      c0.1-1.9,1.6-2.6,3.2-3.3c0.8-0.4,1.9-0.4,2.9-0.5c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.1,1.9-0.2c1.7-0.2,3.4-0.4,5.1-0.5
      c1.1,0,2.2,0.1,3.4,0.2c0.5,0.1,1,0.1,1.5,0.2l2,0.2c1.5,0.1,3.2,0.3,4.9,0.4C184.4,1156.3,186.5,1158.1,186.8,1159.2z
       M136.1,1172.1c0.4,0.6,0.9,1.3,1.3,1.9l0.1,0.1c1.2,1.7,2.2,3.2,3.2,4.9c0.9,1.5,1.8,3,2.6,4.5c0.6,1.1,1.3,2.3,2,3.5
      c0.7,1.1,1.4,2.3,2.1,3.4v0.1c0.5,0.8,1,1.6,1.5,2.4c0.9,1.5,1.9,3.1,2.8,4.6c0.3,0.6,0.4,0.9,0.3,1.2c-0.1,0.3-0.4,0.5-0.9,0.8
      l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.5-0.2l-1-1.5c-1.6-2.4-3.3-4.8-4.7-7.3
      c-0.5-0.9-0.9-2-1.3-3.1c-0.2-0.6-0.4-1.3-0.7-1.9c-0.9-2-1.8-4.2-3.1-6.9c-0.4-0.8-0.4-1.2,0.2-1.9c1.2-0.8,2.1-2,3-3.1
      c0.6-0.8,1.3-1.7,2-2.5c1.3-1.5,2.7-3,4.2-4.5l0.4-0.4C135.3,1173,135.7,1172.5,136.1,1172.1z M126.4,1194.2c0.3,0.7,0.6,1.3,0.9,2
      c1,2.2,1.9,4.4,3.1,6.5c0.9,1.6,2.1,3,3.2,4.5c0.5,0.6,1,1.3,1.5,1.9l0.1,0.1c-0.2,1.2,0.6,1.8,1.2,2.2c0.2,0.2,0.4,0.3,0.6,0.5
      c0.6,0.7,1.4,1.3,2.1,1.9l0.3,0.3c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4c-0.8,0.7-1.6,1.5-2.3,2.2c-1.2,1.1-2.5,2.4-3.9,3.6
      c-1.2,1.1-2.4,2.1-3.8,3.2l-0.1,0.1c-0.4,0.3-0.8,0.7-1.3,1l-0.4-2.8c-1.7-11.3-3.2-22-3.2-33.1c0,0.2,0.1,0.6,0.3,1
      C125.2,1191.4,125.8,1192.8,126.4,1194.2z M129.5,1226.3c1-0.8,1.8-1.6,2.7-2.4c0.5-0.5,1.1-0.9,1.6-1.4c1.2-1,2.4-2.1,3.6-3.1
      c0.9-0.8,1.7-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.5,0.3l2.2,2c1.5,1.4,3.1,2.8,4.6,4.2
      c0.5,0.5,1.1,1,1.7,1.6l0.1,0.1c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5l-0.4,1.2c-0.6,1.9-1.2,3.8-1.7,5.8
      c-0.4,1.4-0.7,2.9-1.1,4.3l-0.1,0.3c-0.1,0.6-0.3,1.2-0.4,1.8c-0.1,0.5-0.2,1-0.4,1.5l-0.1,0.4l-20.3-15.9L129.5,1226.3z
       M151.3,1242.4c0.3-1.1,0.5-2.2,0.8-3.3c0.2-0.9,0.4-1.7,0.6-2.6l0.1-0.4c0.4-1.6,0.7-3.2,1.1-4.8c0.3-1.1,0.6-2.2,0.9-3.3
      c0.1-0.3,0.2-0.5,0.7-0.5h0.1c3.6,0.1,7.2,0.2,10.8,0.2h1.7c0.9,0,1.9,0,2.8,0.1h0.1c2.9,0.1,5.9,0.1,8.8,0.1c2.4,0,5-0.1,8.4-0.4
      h0.1c0.5,0,0.6,0.2,0.7,0.8v0.1c0.7,2.9,1.5,5.9,2.3,8.9c0.4,1.6,0.8,3.1,1.2,4.8l0.5,2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0
      c-0.6,0-1.2,0-1.7,0c-0.7,0-1.5,0-2.2,0c-3.3,0-6.8,0.2-10.3,0.6c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3
      c-2.3-0.3-4.8-0.4-8-0.4c-0.9,0-1.9,0-2.8,0c-1.4,0-2.8,0-4.3,0h-0.5c-0.4,0-0.7,0-1.1,0L151.3,1242.4z M192.8,1232.6
      c-0.3-1.2-0.7-2.4-1.1-3.6c-0.3-0.3-0.5-0.7-0.6-1.2c-0.2-0.7-0.2-1,0.3-1.5c1.3-1.2,2.7-2.5,4-3.7l0.1-0.1
      c0.9-0.8,1.8-1.7,2.6-2.5c0.9-0.8,1.8-1.6,2.5-2.3l0.1-0.1c0.6-0.5,1.1-1,1.7-1.5c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4
      c1.9,1.8,3.9,3.7,6,5.6c1.5,1.3,2.9,2.5,4.4,3.7c0.6,0.5,1.1,0.9,1.7,1.4c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1.1
      c-0.3-1.2-0.7-2.3-0.9-3.4c-0.2-0.9-0.4-1.8-0.5-2.6C193.3,1234.8,193.1,1233.7,192.8,1232.6z M218.9,1185.6
      c-0.3,0.7-0.6,1.3-0.9,2c-0.6,1.3-1.3,2.7-1.9,4.2c-0.3,0.8-0.6,1.6-0.9,2.4c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.7-1.8,3.2-2.8,4.8
      l-0.3,0.5c-0.4,0.7-0.9,1.3-1.3,1.9l-0.1,0.1c-0.4,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.3-0.1-0.5-0.2
      c-2.9-1.5-5.8-3-8.6-4.4l-2.7-1.4c-0.9-0.5-1.8-0.9-2.8-1.4l-1.4-0.7c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5
      c0.7-1.2,1.4-2.4,2.1-3.4c1.2-1.9,2.4-3.8,3.5-5.8c2.6-4.7,5.5-9.2,8.6-13.8c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4
      c0.6,0.5,1.1,0.9,1.6,1.5c1.8,2,3.6,4.1,5.3,6l0.8,0.9c0.3,0.4,0.6,0.8,0.9,1.1c0.6,0.7,1.2,1.5,1.8,2.1
      C219.1,1184.8,219.1,1185.1,218.9,1185.6z M192.7,1137.5l3.6-11.7c1.5,0.6,3,1.2,4.3,1.8c3.1,1.3,6.2,2.7,9.8,3.3L209,1148
      L192.7,1137.5z M196.6,1109.6l-0.1,0.1c-0.4,0.4-0.7,0.8-1.1,1.1l-10.9-11.5c0-0.1,0.1-0.1,0.1-0.2l0.8-1.3c1.3-2,2.6-4,4-6
      c2.3-3.4,5.2-7.3,9.2-10.2c1.4-1,3.1-1.8,4.6-2.6c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1c0.2-0.1,0.5-0.2,0.8-0.2
      c0,0,0,0,0.1,0l1.6,22.5c-2.2,0.1-3.6,1.3-5,2.5c-0.4,0.3-0.7,0.6-1.1,0.9l-0.2,0.2c-1.5,1.2-3.1,2.4-4.5,3.7
      C198.8,1107.3,197.7,1108.5,196.6,1109.6z M201.9,1114.6c-0.2,0-0.5,0-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4c-0.1,0-0.4-0.2-0.6-0.6
      c-0.4-0.7-0.5-1.5-0.2-1.9c0.2-0.4,0.6-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.2-0.2L201.9,1114.6z M186.2,1104.2c2.6,2.8,5.4,5.7,7.9,8.7
      c1.4,1.5,1.8,3,1.4,4.5c-0.3,1.2-0.5,2.4-0.7,3.8v0.1c-0.1,0.4-0.1,0.8-0.2,1.3l-0.5-0.2c-0.6-0.2-1.1-0.5-1.7-0.8
      c-1.7-0.9-3.7-2-5.6-3.2c-1.4-0.9-2.8-1.8-4.4-3c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.4,0.6-6.7,0.9-10.3l0.2-2.7
      c0.1,0.1,0.3,0.2,0.4,0.4l0.2,0.1C184.8,1102.8,185.5,1103.5,186.2,1104.2z M209.9,1128.5c-0.2,0-0.3,0-0.6-0.1
      c-1.8-0.6-3.5-1.1-5.2-1.8c-1.3-0.5-2.6-1.1-3.9-1.6l-0.1-0.1c-1-0.4-1.9-0.9-2.9-1.3c-0.4-0.2-0.5-0.3-0.4-0.8
      c0.2-1.3,0.4-2.6,0.6-3.9l0.2-1.1c0.5-0.1,1-0.2,1.5-0.3l0.2-0.1c1.6-0.4,3.1-0.7,4.6-1c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1
      c0.5,0.1,1,0.2,1.5,0.3c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2c0,1.8,0,3.7,0,5.3v0.4c0,0.7,0,1.3,0,2c0,0.2,0,0.4,0,0.5
      c0,0.4,0,0.8,0,1.2S210.3,1128.5,209.9,1128.5z M210.1,1115.1H210c-2.6,0-4.9-0.9-6.9-2.8c-0.2-0.2-0.5-0.4-0.8-0.6
      c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4c0.9-0.7,1.9-1.5,2.8-2.2h0.1c1.2-1,2.4-2,3.6-2.9
      c1-0.7,2.1-1.2,3-1.2c4.3-0.3,8.8-0.3,12.9-0.4l0.1-0.1c2.5,0,4.5,0.9,6.1,2.6c1,1.1,2.1,2,3.3,3c0.4,0.4,0.8,0.7,1.3,1.1l-1.5,1.3
      c-1.6,1.3-3.1,2.6-4.6,3.8c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.2,0-0.3,0.1
      c0,0-0.1,0-0.2,0h-0.1c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1c-2.3,0-4.8,0-6.9,0
      C213.9,1115.1,212,1115.1,210.1,1115.1z M211.1,1076.3c0.4,0,0.5,0,0.6,0c0.5-0.1,1-0.2,1.4-0.2c2.3,0,4.6,0.1,6.5,0.2h0.4
      c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1c0.7,0,1.3,0.2,1.8,0.8c0.5,0.6,1.1,1.2,1.7,1.7
      c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.3l0.2,0.3c0.5,0.6,0.9,1.3,1.4,1.9c-0.6,0.4-1.2,0.9-1.9,1.3h-0.1
      c-1.6,1.1-3.2,2.2-4.8,3.3c-2.3,1.6-4.7,3.1-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1c-1.1-0.1-2.2-0.2-3.3-0.3
      c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1c-0.4,0-0.8,0-1.2,0h-0.2L211.1,1076.3z
       M230.3,1123.5l0.8,1.4l-7.9,1l-10.7,1.5v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2
      c1.3-0.1,2.6-0.2,3.9-0.2c0.1,0,0.4,0.2,0.5,0.4C228.1,1119.4,229.2,1121.4,230.3,1123.5z M211.8,1142.2c0.2-2.5,0.3-5,0.4-7.3
      l0.1-2.2c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6c2.3-0.5,4.4-0.9,6.4-1.2c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1
      c0.7-0.1,1.7-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5c0.3,1.8,0.6,3.6,0.9,5.3c0.3,2,0.7,4.1,1.1,6.2
      c0.4,2.4,0.9,4.7,1.4,7.1l0.1,0.4c0.2,0.8,0.3,1.6,0.5,2.5c-0.4-0.1-0.9-0.2-1.3-0.3h-0.1c-1.2-0.1-2.4-0.3-3.6-0.6
      c-2-0.5-4.1-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1c-1.9-0.5-3.8-1-5.8-1.5c-0.8-0.2-1.2-0.5-1.1-1.4
      C211.5,1146.2,211.6,1144.2,211.8,1142.2z M232.9,1123.9l-4.4-8l0.5-0.3c0.6-0.4,1.2-0.9,1.8-1.4l0.8-0.7c1.8-1.5,3.6-3,5.3-4.5
      c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.7-3.3-5.5-5c-0.1-0.1-0.1-0.2-0.2-0.3l13.7-9.4c0,0.1,0.1,0.2,0.1,0.3
      v0.1c0.2,0.5,0.4,1,0.5,1.6c0.9,3.6,1.6,6.8,2.3,9.7c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4c-1.5,1.2-3.1,2.3-4.7,3.4
      l-0.2,0.2c-1.5,1.1-3.1,2.3-4.6,3.4c-1.3,1-2.6,2-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9C233.2,1123.7,233.1,1123.8,232.9,1123.9z
       M249.1,1099.9v-0.2c-0.4-1.6-0.7-3.1-1.1-4.7c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-1.1-0.4-2.2-0.9-3.2c-0.5-1.2-0.4-2.3-0.3-3.5v-0.1
      c0.1-1.2,0.2-2.5,0.4-3.7v-0.1c0.1-1.5,0.3-3.1,0.5-4.6c0.2-1.4,0.4-2.9,0.6-4.2c0.1-0.8,0.3-1.6,0.4-2.5c0.3-1.8,0.5-3.5,0.8-5.3
      l0.1-0.5c0.4-2.8,0.8-5.6,1.2-8.4c0.1-0.5,0.2-1,0.4-1.5l0.1-0.2l0.7,0.7c0.6,0.7,1.2,1.3,1.8,1.9c0.9,1,1.9,1.9,2.8,2.9l0.3,0.4
      c1.6,1.7,3.5,3.6,5.3,5.5c1.2,1.2,2.3,2.5,3.4,3.7l0.1,0.1c0.8,0.9,1.7,1.8,2.6,2.8c0.4,0.4,0.8,0.9,1.2,1.4
      c1.1,1.2,2.2,2.4,3.4,3.4c1.9,1.8,1.8,3.8,1.3,5.8c-0.6,2.3-1.1,4.6-1.6,6.9v0.1c-0.5,2.1-1,4.4-1.5,6.5c-0.4,1.7-0.9,3.4-1.3,5.1
      c-0.4,1.3-0.8,2.7-1.1,4.1c-0.6,2.4-1.2,4.8-1.7,7.1c-0.2,1-0.5,2-0.7,3c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.4-0.2,0.7-0.3,1.1
      c-0.3,1.1-0.6,2.2-1,3.2l-0.3,1.2h-0.1c-0.3-0.4-0.7-0.8-1-1.2c-0.9-1-1.9-2.1-2.9-3.1c-1.4-1.4-3-2.8-4.3-4.1l-0.1-0.1
      c-1.2-1.1-2.4-2.2-3.5-3.3c-0.5-0.5-0.9-1.1-1-1.6C250.7,1106.7,249.9,1103.2,249.1,1099.9z M255,1021l-0.1,0.8
      c-0.8,5.9-1.7,12.1-2.7,18.2c-0.5,2.7-1.1,5.4-1.8,8c-0.3,1.1-0.5,2.2-0.8,3.3c-0.2,0.7-0.3,1.4-0.5,2l-0.1,0.5
      c-0.7-0.4-1.3-0.7-2-1.1l-0.2-0.1c-1.7-0.9-3.4-1.9-5.2-2.9c-1-0.6-2.1-1.1-3.2-1.7h-0.1c-1.1-0.6-2.3-1.2-3.5-1.8
      c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.2-0.7-2.4-1.4-3.6-2c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5
      c-1.6-0.9-3.1-1.9-4.7-2.8c-0.6-0.4-1.2-0.8-1.9-1.2c0-0.1-0.1-0.2-0.3-0.2c-0.4-0.1-0.7-0.2-0.8-0.3c-0.2-0.2-0.1-0.7,0-1.2
      c0-0.2,0.1-0.4,0.1-0.6c0.1-1.9,0.2-3.7,0.3-5.6c0.1-1.6,0.2-3.1,0.2-4.7c0.1-1.2,0.1-2.3,0.2-3.5c0.1-1.9,0.2-3.7,0.3-5.6
      c0.4-6.5,0.8-13,1.1-19.5c0.3,0.2,0.6,0.3,0.8,0.5l0.5,0.3c1.2,0.7,2.5,1.4,3.8,2.2l2.1,1c2.8,1.6,5.7,3.3,8.4,5
      c4.5,2.8,7.8,5,10.9,7.1c1.8,1.2,3.6,2.6,5.3,3.9l0.2,0.1c1.8,1.4,3.7,2.8,5.6,4.1C255.2,1018.9,255.1,1019.8,255,1021z
       M215.1,1037.4L215.1,1037.4c1.4,0.9,2.7,1.8,4,2.6c1.2,0.7,2.4,1.4,3.5,2l0.6,0.2c1.8,1,3.6,1.9,5.4,2.9l1.4,0.8
      c1,0.5,1.9,1,2.9,1.5c2,1.1,3.8,2,5.6,3c1.1,0.6,2.1,1.2,3.2,1.8c1.8,1,3.8,2.1,5.8,3.1c1.1,0.5,1.2,1.2,1,2.2
      c-0.2,1.3-0.4,2.6-0.6,3.9l-0.1,0.7c-0.6,4.3-1.3,8.7-1.9,13.2c-0.4,2.7-0.6,5.5-0.8,8.2c-0.1,1.3-0.2,2.6-0.4,3.9
      c0,0.2,0,0.3-0.1,0.5l-0.7-0.9c-0.7-0.9-1.5-1.9-2.2-2.9c-1.9-2.4-3.9-4.9-5.9-7.3l-0.6-0.7c-0.1-0.1-0.1-0.2-0.2-0.3
      c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0c-0.4,0-0.8,0-1.2,0c-0.8,0-1.5,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2
      c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2c-0.6,0-1.2,0.1-1.8,0.1c-0.3,0-0.7,0-1.1,0.1h-0.5l2.1-37.2
      C214.1,1036.7,214.6,1037,215.1,1037.4z M211.4,1036.5c-0.1,1.1-0.2,2.1-0.3,3.1c-0.1,1.7-0.2,3.4-0.2,5c-0.1,1.3-0.1,2.7-0.2,4.1
      c-0.1,1.8-0.2,3.7-0.3,5.4v0.3c-0.1,1.6-0.2,3.1-0.3,4.7c-0.1,1.6-0.2,3.1-0.2,4.7c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.5
      c-0.1,1.9-0.2,3.8-0.3,5.7c0,0.2-0.3,0.5-0.6,0.6c-2.4,1.1-4.8,2.1-7.1,3.1l-0.2,0.1c-1.1,0.5-2.1,0.9-3.2,1.4
      c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.1,5.5-6.1,8.2l-1,1.4c-0.8,1.2-1.6,2.5-2.3,3.7l-0.1,0.3c-0.4,0.7-0.7,0.9-1.2,0.9
      c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1s-1.9-0.6-2.9-1c-0.4-0.1-0.9-0.3-1.4-0.4h-0.1c-0.2,0-0.3-0.1-0.5-0.1v-65.9
      c13.8,0.9,27.1,3.4,38.3,5.6C211.5,1035.8,211.5,1036.2,211.4,1036.5z M181.6,1123.4c0.1-1.3,0.2-2.6,0.3-4.1v-0.3
      c0-0.3,0.1-0.7,0.1-1c0.4,0.3,0.8,0.5,1.2,0.8c1.2,0.8,2.4,1.6,3.6,2.2c2,1.1,4.2,2.2,6.2,3.1c0.9,0.4,1.1,0.7,0.8,1.6
      c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9c-0.3,0.9-0.5,1.8-0.7,2.6c-0.2,0.6-0.3,1.2-0.5,1.8c0,0.2-0.1,0.3-0.2,0.5
      c-0.2-0.2-0.5-0.3-0.7-0.5c-0.8-0.6-1.5-1.1-2.2-1.6c-2-1.6-3.9-3.2-5.7-4.7c-0.3-0.2-0.5-0.7-0.5-1.1
      C181.5,1126.3,181.5,1124.8,181.6,1123.4z M182.4,1138.6c0.9,3.7,1.7,7.6,2.4,11l0.2,0.9c0.2,0.9,0.4,1.9,0.6,2.8
      c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.8-0.2-2.8-0.3c-2.7-0.3-5.4-0.6-8.2-0.6
      c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0.1-0.8,0-0.9-0.1
      c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.4c0.8-3.7,1.6-7.5,2.5-11.2c0.5-2,1-3.9,1.6-5.8l0.4-1.4c0.7-2.3,1.1-2.4,2.5-2.5
      c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0h2.9v-0.1c0.5,0,1,0.1,1.4,0.1
      c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.5C181.3,1134.4,181.9,1136.5,182.4,1138.6z M162.1,1098.2
      c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.3-3.6-3.5-5.4c-0.7-1-1.3-2-2-3c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.2
      c-0.4-0.2-0.9-0.4-1.3-0.6c-1-0.5-2.1-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.4-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7v-0.1
      c-0.1-1.8-0.1-3.6-0.2-5.5c-0.1-2.1-0.3-4.3-0.4-6.2v-0.3c-0.1-1.9-0.3-3.9-0.4-5.9c-0.1-1.1-0.1-2.2-0.1-3.3c0-1.2,0-2.4-0.1-3.6
      c-0.1-1.7-0.3-3.5-0.4-5.1c-0.1-0.8-0.1-1.6-0.2-2.3c0-0.1,0-0.2,0-0.4c15.1-3.1,26.9-4.8,38.2-5.6v65.8l-2.1,0.7
      c-2,0.7-3.9,1.3-5.8,1.9C162.5,1098.2,162.3,1098.2,162.1,1098.2z M129.7,1032.3L129.7,1032.3c0.1,0.4,0.1,1,0.2,1.5
      c0,0.2-0.1,0.5-0.1,0.6c-2.8,1.7-6,3.6-9.1,5.4c-2.1,1.2-4.2,2.3-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3
      c-1.5,0.8-3.1,1.7-4.6,2.5c-0.7,0.4-1.4,0.8-2.2,1.2l-0.9,0.5c-0.2-0.6-0.3-1.1-0.5-1.7c-0.4-1.6-0.9-3.2-1.3-4.8l-0.2-0.7
      c-0.7-2.8-1.3-5.8-1.8-8.7c-0.5-3.3-1-6.6-1.4-9.8c-0.2-1.4-0.4-2.7-0.6-4.1c0-0.2-0.1-0.5-0.1-0.7v-0.1c-0.1-0.5-0.1-1.1-0.3-1.6
      c-0.4-1.6,0.1-2.8,1.7-4c1.3-0.9,2.7-2,4-2.9c1.2-0.9,2.6-2,4-2.9c1.3-0.9,2.7-1.8,4.1-2.7h0.1c0.7-0.5,1.5-0.9,2.2-1.4
      c1.1-0.7,2.2-1.4,3.3-2.2c2-1.3,4.1-2.7,6.2-4c1.8-1.1,3.7-2.2,5.6-3.3c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5c0.2-0.1,0.4-0.2,0.7-0.4
      c0,0.7,0,1.3,0.1,2c0.1,1.8,0.1,3.6,0.2,5.4c0.1,2.2,0.3,4.5,0.5,6.7c0.2,2.9,0.4,5.8,0.6,8.8c0.1,2.5,0.2,5,0.3,7.4
      c0.1,1.6,0.1,3.6,0.2,5.6C129.5,1030.7,129.6,1031.5,129.7,1032.3z M67.7,1084.3c0.3,1.5,0.5,3,0.9,4.4c0.9,4,1.6,7.3,2.4,10.3
      c0.4,1.7,0.9,3.4,1.3,5c0.4,1.5,0.8,2.9,1.1,4.3c0.5,2,1,4.1,1.5,6.1l0.1,0.3c0.5,2.1,1,4.2,1.6,6.3c0.2,0.8,0.1,1.4-0.2,1.9
      c-0.4,0.6-1.2,1-2.5,1.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0c-0.6,0-0.9-0.2-1.1-0.7
      c-0.3-1.2-0.6-2.4-0.8-3.4c-0.3-1.3-0.6-2.7-0.8-4v-0.2c-0.5-2.5-1-5-1.6-7.5c-0.3-1.4-0.7-2.7-1-4c-0.3-1-0.6-2.1-0.8-3.1
      c-0.4-1.5-0.7-3.1-1-4.5l-0.1-0.3c-0.1-0.6-0.3-1.2-0.4-1.8c-0.1-0.4-0.2-0.9-0.3-1.3l-0.1-0.4c-0.3-1.3-0.6-2.7-0.9-4
      c-0.1-0.5-0.1-0.8,0-0.8c1.1-1.4,2.3-2.9,3.3-4.2l0.6-1.2C67.5,1083.4,67.6,1083.9,67.7,1084.3z M64.8,1109.5
      c0.4-0.4,0.7-0.7,1.1-1.1l5.5,27.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.6c-0.3-0.6-0.6-1.3-0.9-1.9l-0.2-0.4
      c-1.8-4.2-3.8-8.6-6.3-12.6l-0.2-0.3c-0.5-0.8-1.1-1.7-1.3-2.6c-0.2-0.6,0.2-1.6,0.6-2.1C62.6,1111.7,63.7,1110.6,64.8,1109.5z
       M61.7,1121c1.1,2.2,2.2,4.6,3.2,6.7l0.8,1.7c0.2,0.5,0.4,1,0.7,1.5l0.2,0.4c-0.2,0-0.4,0-0.6,0c-0.3,0-0.7,0-1,0
      c-0.5,0-0.8,0-1.2,0H63c-2.3,0.1-4.8,0.2-7.2,0.4c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.9
      c0-1.2,0-2.6,0-4v-0.3c0-0.4,0-0.8,0-1.2c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.8,0.5,1.1,0.9
      C60,1117.8,60.8,1119.3,61.7,1121z M54.2,1134.1c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.7-0.8l2.9-0.2c3.3,5.6,6.3,11.9,5.9,19.5
      h-0.4c-2.2-0.1-4.6-0.2-6.9-0.2c-3.8,0-7,0.3-9.9,1c0-0.4-0.1-0.7-0.1-1.1c-0.1-1-0.2-1.9-0.3-2.9c-0.3-2.9-0.6-6-0.9-9
      c-0.1-1.1-0.2-2.1-0.3-3.3c-0.1-0.9-0.1-1.7-0.2-2.6C54.2,1134.2,54.2,1134.1,54.2,1134.1z M72.6,1129.5v-0.3c0-0.1,0-0.2,0-0.3
      c-0.1-0.4-0.1-0.9-0.3-1.3c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4c0.5-0.1,1.1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6
      c0.2-0.1,0.4,0,0.5,0.1c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.8c0.1,2.5,0.3,5.1,0.5,7.4c0.1,1,0.2,1.9,0.2,2.9
      c0.1,1,0.2,2.1,0.2,3.1l0.1,1c0.3,3.4,0.6,7.2,0.9,10.9c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5
      c0.1,1.3,0.3,2.6,0.3,3.8c-0.1-0.2-0.1-0.4-0.2-0.6c-0.2-0.7-0.5-1.5-0.7-2.1c-0.2-1.1-0.5-2-0.7-2.9c-0.9-3.9-1.6-7.8-2.3-11.5
      c-0.5-2.9-1.2-6.7-1.8-10.5c-0.2-1.5-0.4-3-0.5-4.3c-0.1-1-0.2-2.1-0.3-3.1c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8
      C73.8,1136.9,73.2,1133.1,72.6,1129.5z M79.4,1134c-0.3-1.8-0.5-3.7-0.5-5.6c0-1.3,0.5-2.4,1.7-3.4c1.6-1.4,3.1-2.9,4.6-4.3
      c0.9-0.9,1.9-1.8,2.9-2.8c1.3-1.2,2.6-2.4,3.8-3.5c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1c2.5,1.8,4.7,3.5,7,5.1c1.3,1,2.7,2,3.9,3
      c0.8,0.6,1.6,1.2,2.4,1.8c0.8,0.6,1,1.4,0.8,2.5c-0.4,2-0.8,4-1.2,6c-0.1,0.8-0.3,1.5-0.4,2.3l-0.4,2c-0.3,1.6-0.6,3.2-0.9,4.8
      c-0.3,1.7-0.6,3.5-0.9,5.3c-0.4,2.5-0.8,4.7-1.2,6.9c-0.3,1.6-0.9,1.8-1.5,1.8c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2
      c-3-1.3-6.1-2.7-9-4.1l-1.9-0.9c-0.2-0.1-0.4-0.4-0.4-0.7c-0.3-3.7-0.5-7.2-0.8-11.2L79.4,1134z M105.2,1168.8
      c0.1-2.4,0.5-4.6,0.8-6.8c0.1-0.9,0.3-1.8,0.4-2.7c0.1-0.9,0.6-1.4,1.7-1.7c4.8-1.1,8.8-2,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1
      c1.2-0.3,2.5-0.7,3.7-1c0.7-0.2,1.3-0.3,2.1-0.5c0.2,0,0.4-0.1,0.7-0.1l0.1,1.4c0.1,1.3,0.1,2.6,0.2,3.9v0.2
      c0.2,3.2,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4c0.3,0.6,0.1,0.9-0.3,1.4c-0.3,0.3-0.6,0.6-0.9,0.9l-0.1,0.1c-1,0.9-2,1.9-3,2.9
      c-1,1.2-2,2.4-3,3.6v0.1c-0.7,0.9-1.5,1.8-2.2,2.7l-0.3,0.4c-0.7,0.8-1.4,1.6-2.2,2.1c-0.3,0.1-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0
      s-0.6,0-0.9,0h-0.1c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3,0-1.9,0s-1.2,0-1.7,0.1c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4
      c0.2-1.6,0.4-3.1,0.6-4.7C104.2,1176.6,104.7,1172.8,105.2,1168.8z M107.6,1186.8c1.2-0.1,2.6-0.3,4-0.4c1.9-0.2,4-0.3,6.2-0.4
      c1.1,0,2.1,0,3.1,0h0.1c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.8,0.1,1.2c0.1,1.2,0.2,2.1,0.3,3.2c0,0.4,0,0.9,0,1.4c0,0.3,0,0.7,0,1
      c0,0.4,0,0.8,0,1.2c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2,0.4,3c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4v0.1
      c0.2,2.4,0.5,4.9,0.8,7.3c0.5,3.4,1.1,6.8,1.7,10.1l0.1,0.4c0.1,0.6,0,0.9-0.4,1.4c-1.6,1.9-3.6,3.4-5.5,4.8
      c-0.6,0.4-1.1,0.8-1.7,1.2c-1.6,1.2-3.1,2.5-4.6,3.8c-0.5,0.5-1.1,0.9-1.6,1.4c-1.1,0.9-2.1,1.8-3.2,2.7l-1.1,1
      c0-0.2-0.1-0.4-0.1-0.6v-0.1c-0.2-0.9-0.4-1.7-0.5-2.5l-0.4-2.5c-0.6-3.5-1.3-7.6-1.9-11.4c-0.4-2.7-0.8-5.5-1.1-8.2l-0.1-0.5
      c-0.1-1-0.2-2-0.4-3c-0.4-0.8-0.5-1.7-0.7-2.6c-0.3-2.3-0.6-4.8-0.9-7.3l-0.1-0.7c-0.3-2.4-0.5-5-0.5-7.4c0-1.6,0.3-3.1,0.5-4.7
      c0.1-0.6,0.2-1.2,0.3-1.7c0.1-0.6,0.2-0.7,0.7-0.8C104.3,1187.2,106,1187,107.6,1186.8z M120.4,1253.3c-1.1-0.7-2.4-1.6-3.7-2.4
      c-0.9-0.6-1.8-1.1-2.8-1.7c-0.8-0.5-1.7-1-2.5-1.5c-0.8-0.5-1.5-1.1-2.1-1.7c0,0-0.1-0.2-0.1-0.5c0-0.5,0-1,0-1.4l-0.1-0.4l18-15
      l0.5,0.4c0.6,0.5,1.2,1,1.8,1.5c0.7,0.6,1.4,1.2,2.2,1.8l0.2,0.2c1.7,1.5,3.5,3,5.4,4.5c0.9,0.8,1.9,1.5,2.9,2.1
      c0.8,0.6,1.7,1.2,2.5,1.9c0.9,0.7,1.7,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2c0.4,0.3,0.4,0.5,0.2,1c-0.4,1.4-0.8,2.9-1.1,4.3v0.1
      c-0.6,2.2-1.2,4.5-1.8,6.7c-0.7,2.3-1.5,4.7-2.2,7l-0.5,1.4c-0.2,0.6-0.5,0.6-0.6,0.6c-0.2,0-0.4-0.1-0.6-0.2
      c-1.1-0.7-2.1-1.4-3.2-2c-1.4-0.9-2.9-1.9-4.4-2.8c-1.2-0.8-2.5-1.5-3.7-2.2l-0.2-0.1c-1.5-0.9-3.1-1.8-4.6-2.8
      C123.3,1255.3,121.8,1254.3,120.4,1253.3z M197.4,1251.4l-0.1-0.3c-0.2-0.8-0.4-1.5-0.6-2.3c-0.1-0.2-0.1-0.4-0.1-0.6
      c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.4-1,2.8-2.1,4-3c1.6-1.2,3.5-2.6,5.3-4c1.3-1,2.6-2.1,3.8-3.1l0.1-0.1
      c0.7-0.6,1.3-1.1,2-1.7c1.1-0.9,2.3-1.9,3.4-2.8l1.3-1.1c0.6,0.4,1.1,0.9,1.6,1.3c1.4,1.2,2.8,2.2,4.2,3.4c0.7,0.6,1.4,1.2,2.1,1.8
      l0.1,0.1c0.7,0.6,1.4,1.2,2.2,1.8c2.9,2.4,5.4,4.4,7.7,6.1c0.4,0.3,0.4,0.4,0.3,0.7c-0.3,0.1-0.4,0.4-0.3,0.6
      c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.4,0.3c-3,2-6.1,4-9.2,5.9c-1.2,0.8-2.5,1.5-3.7,2.2l-0.3,0.2
      c-0.6,0.3-1.2,0.7-1.8,1.1l-0.6,0.4c-1.3,0.8-2.7,1.6-4,2.5c-1.3,0.8-2.7,1.7-4,2.5c-1.1,0.7-2.2,1.4-3.3,2.1
      c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.6-1.7-0.8-2.5c-0.7-2-1.4-4.1-2-6.1
      C198.4,1255.4,197.8,1253.2,197.4,1251.4z M238.7,1228.3c-0.5,3.5-1.2,7.1-1.8,10.3l-0.4,1.7c-0.1,0.3-0.2,0.7-0.3,1.1v0.1
      c-0.9-0.7-1.7-1.5-2.6-2.2c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.8-2.1c-0.8-0.7-1.6-1.5-2.3-2.3
      c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.4-0.2-0.6c0.2-1,0.4-1.9,0.6-2.9v-0.2c0.3-1.4,0.6-2.9,0.8-4.3c0.3-1.6,0.4-3.2,0.6-4.7
      c0.1-0.5,0.1-1,0.2-1.5c0.3-2.7,0.6-5.6,1-9.1l0.1-1.4c0.2-2.2,0.5-4.6,0.7-6.8c0-0.4,0-0.7-0.1-1.1c0-0.3-0.1-0.6-0.1-0.9
      c0-0.7,0.1-1.4,0.2-2c0.1-0.7,0.1-1.5,0.2-2.2c0-0.5-0.1-1-0.1-1.5c0-0.2,0-0.3-0.1-0.5c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1
      h0.2c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4c1.6,0.2,3.3,0.4,5,0.5c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2
      c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.4-0.7,6.3c-0.3,2.5-0.5,5-0.8,7.6C240.4,1217.5,239.5,1223.3,238.7,1228.3z M239.4,1185
      c-1.6-0.2-3.2-0.3-4.8-0.5l-1.8-0.2c-2.3-0.2-4.6-0.4-6.9-0.6c-0.2,0-0.4,0-0.6,0c-0.6,0-1.1,0-1.7,0.1c-0.5,0-1.1,0.1-1.6,0.1
      c-0.2,0-0.5-0.1-0.9-0.4c-1.8-1.4-3.2-3.2-4.5-4.9c-0.7-0.9-1.4-1.8-2.2-2.7c-1.2-1.3-2.5-2.7-4.2-4.1c-1.2-1-1.2-1-0.8-2.6
      c0.4-2.2,0.6-4.5,0.8-6.6c0.2-1.8,0.3-3.6,0.4-5.4l0.1-1.2c0.1-1.2,0.1-2.4,0.2-3.4v-1.2c0.4,0.1,0.8,0.2,1.1,0.2
      c1.1,0.2,2.1,0.4,3.1,0.7c2.2,0.6,4.3,1.2,6.4,1.8c2.5,0.7,5.3,1.5,8.1,2.2c2,0.5,4,0.9,6.2,1.3c1,0.2,1.5,0.7,1.7,1.9
      c0.6,4.1,1.2,8.3,1.8,12.3l0.6,3.8c0.3,2,0.6,3.9,0.9,5.9l0.5,3.6L239.4,1185z M238.9,1155.8c-0.3-1.6-0.6-3.2-0.9-4.8
      c-0.2-1.3-0.5-2.6-0.7-3.8c-0.1-0.8-0.2-1.6-0.4-2.4v-0.1c-0.1-0.8-0.2-1.7-0.4-2.5c-0.2-1.1-0.4-2.2-0.6-3.3
      c-0.2-1-0.4-1.9-0.5-2.8c-0.1-0.9-0.3-1.9-0.5-2.8c-0.2-1-0.3-2-0.5-3.1c-0.2-1-0.4-1.9-0.7-2.7c-0.4-1.1,0.2-1.8,1.2-2.5
      c1.3-1,2.6-1.9,4-2.9c1.4-1,3-2.2,4.6-3.4c1.7-1.3,3.3-2.5,5.1-3.9c0.7-0.5,1.4-1.1,2.1-1.6c0.3,0.2,0.6,0.5,0.9,0.8
      c0.9,0.7,1.8,1.4,2.6,2.2c1.9,1.8,3.8,3.7,5.7,5.6l0.4,0.4c0.7,0.7,1.3,1.3,2,2l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2
      c2.6,1.4,2.8,3.8,2.6,6.2c-0.3,4.7-0.7,9.9-1.3,16.1c0,0.3-0.2,0.5-0.3,0.6c-0.7,0.3-1.5,0.7-2.2,1c-3.1,1.5-6.3,3-9.5,4.3
      c-2.6,1.1-5.3,2.1-7.9,3c-0.8,0.3-1.6,0.6-2.4,0.9c-0.5,0.2-1,0.3-1.5,0.2C239.4,1156.7,239,1156.4,238.9,1155.8z M263.9,1168.4
      L263.9,1168.4c0.1-0.8,0.1-1.6,0.2-2.3c0.5-4.2,0.8-7.6,1.2-10.7c0.2-2.3,0.4-4.5,0.6-7.1c0.1-0.8,0.1-1.6,0.2-2.4v-0.1
      c0.2-2.6,0.4-5.2,0.5-7.9c0.1-2.7,0.2-5.5,0.2-8.1c0-1.1,0-2.3,0.1-3.4c0-0.2,0-0.5,0-0.8v-0.2l0.6,0.1c0.7,0.1,1.4,0.3,2.1,0.5
      c0.2,0,0.4,0.1,0.6,0.1c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.2,0,0.8-0.1,1.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.2,1.5-0.5,2.9-0.7,4.4
      l-0.1,0.5c-0.5,3.4-1.1,6.9-1.6,10.4c-0.3,1.9-0.5,3.8-0.6,5.6v0.1c-0.2,1.5-0.3,3.1-0.5,4.6c-0.3,2.6-0.8,5.2-1.2,7.7
      c0,0.7-0.1,1.1-0.2,1.5l-0.1,0.4c-0.4,2.2-0.7,4.4-1.3,6.6c-0.2,1-0.4,2-0.6,3v0.1c-0.5,2.5-1,5.1-2.1,7.4
      c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5v-0.1c0.1-2.1,0.2-4.2,0.8-6.1C263.5,1172.9,263.7,1170.6,263.9,1168.4z M276.6,1133.1
      c0.5,0,0.9,0,1.4,0.1h0.7c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6c0.1,0,0.2,0.2,0.2,0.3c-0.3,3.8-0.7,7.6-1.1,11.4
      l-0.3,4.5c-0.1,0.7-0.1,1.3-0.2,2.1l-0.1,0.8c-3-0.7-6.2-1-10.1-1c-2.4,0-4.7,0.1-7.1,0.3C270.3,1144.7,273.4,1138.3,276.6,1133.1z
       M288.8,1131.6c-0.1,0-0.2,0-0.2,0c-0.4,0.1-0.9,0-1.4-0.1c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.2,0-3.6,0c-0.8,0-1.6,0-2.4,0
      c-0.6,0-1.3,0-2,0c0.1-0.3,0.3-0.6,0.4-0.9l0.1-0.2c0.5-1.1,0.9-2.1,1.4-3.1c1-1.9,2-3.8,2.9-5.6l0.1-0.1c0.6-1.1,1.2-2.3,1.8-3.4
      c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.4-0.9c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2c0.3,0,0.7,0,1,0
      c0,0.5,0,1.1,0,1.6c0,1.4,0,2.7,0,4.1c-0.1,2.6-0.2,5.3-0.3,7.9l-0.1,1.4C290,1131.2,289.6,1131.6,288.8,1131.6z M282.5,1039.3
      c0.2-1.4,0.4-2.8,0.6-4.2v-0.1l0.1,0.8c0.3,1.9,0.6,3.8,0.9,5.7l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5c0.2,1.3,0.3,2.7,0.5,4
      c0.2,2.1,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8l-0.1,0.4c-0.5,2-0.8,4-1.2,6v0.1c-0.2,1.2-0.4,2.4-0.7,3.6
      c-0.3,1.6-0.7,3.1-1,4.7l-0.1,0.3c-0.3,1.2-0.5,2.5-0.8,3.7c-0.2,0.9-0.3,1.7-0.5,2.6l-0.1,0.6l-0.4-1.1c-1.1-1.3-2.2-2.6-3.2-3.9
      c-0.2-0.2-0.3-0.7-0.2-1.1c0.3-1.7,0.5-3.5,0.8-5.2c0.3-1.8,0.6-3.9,0.9-5.9c0.2-1.2,0.3-2.3,0.4-3.5v-0.1c0.1-0.9,0.2-1.9,0.3-2.8
      c0.2-1.4,0.4-2.8,0.6-4.1v-0.3c0.1-0.9,0.3-1.8,0.4-2.6c0.1-0.9,0.2-1.9,0.3-2.7l0.1-1.1c0-0.1,0-0.1,0-0.2
      c0.7-1.1,0.6-2.3,0.6-3.4c0-0.8-0.1-1.6,0.1-2.3c0.4-1.4,0.6-2.8,0.7-4.2C282.4,1040.5,282.4,1039.9,282.5,1039.3z M259.1,999.6
      c0.1-1.6,0.3-3.1,0.4-4.7v-0.1c0.1-1.1,0.2-2.2,0.2-3.4c0.1-0.8,0.2-1.6,0.3-2.4v-0.2c0-0.1,0-0.3,0.1-0.4c0.3,0.3,0.6,0.7,0.9,1
      c0.9,1.1,1.9,2.1,2.7,3.3c1.6,2.1,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c1,1.6,1.8,3.3,2.7,4.9c0.4,0.8,0.9,1.8,1.4,2.7
      c1.6,2.9,2.6,6.2,3.3,10.4c0.3,1.9,0,3.6-0.3,5.2c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.8-0.8,5.6-1.3,8.5v0.1c-0.2,0.9-0.3,1.9-0.5,2.9
      c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-1.1-1.3-2.1-2-3.2c-1.2-2-2.5-4-3.8-6c-1.1-1.7-2.3-3.3-3.5-4.9l-0.1-0.1c-0.6-0.9-1.3-1.8-1.9-2.7
      c-0.5-0.8-1-1.5-1.6-2.3c-0.9-1.3-1.9-2.7-2.9-4c-1.1-1.4-2.3-2.7-3.4-4l-0.1-0.1c-0.7-0.7-1.4-1.5-2-2.3c-0.1-0.2-0.2-0.5-0.2-0.7
      c0.2-2.4,0.5-4.8,0.7-7.2C258.6,1005.1,258.8,1002.4,259.1,999.6z M214.5,954c1.1,0.5,2,1,3.1,1.5c1.6,0.8,3.2,1.5,4.8,2.2
      c2.8,1.3,5.7,2.6,8.6,4c1.4,0.7,2.7,1.4,4,2.1h0.1c1.4,0.8,3,1.7,4.5,2.4c3.3,1.5,6.1,3.9,9,7.7c1.3,1.6,2.6,3.3,3.9,4.9l0.1,0.2
      c1,1.3,2.1,2.6,3.1,3.9c0.2,0.3,0.5,0.6,0.7,0.9c0.4,0.6,0.8,1.2,1.3,1.7c0.7,0.6,0.6,1.2,0.5,2c0,0.2-0.1,0.4-0.1,0.6
      c-0.3,2.5-0.5,5-0.8,7.5l-0.1,0.7c-0.1,0.9-0.2,1.8-0.3,2.7l-0.5,5.1c-0.3,3.3-0.7,6.6-1,9.9c-0.1,0.6-0.1,1.2-0.1,1.7
      c0,0.2,0,0.3,0,0.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.8-0.6-1.6-1.3-2.5-1.9c-0.5-0.4-1-0.7-1.5-1.1l-0.3-0.2c-2.1-1.5-4.3-3.1-6.5-4.6
      c-5.6-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7c-0.1,0-0.2-0.3-0.2-0.5
      c-0.1-1-0.1-1.9,0-2.7l0.1-2.5c0.2-3.8,0.4-7.7,0.6-11.6c0.2-2.4-0.2-4.8-0.6-7.1c-0.1-0.4-0.1-0.8-0.2-1.2c-0.4-2.5-0.9-5-1.5-7.3
      l-0.2-0.8c-0.2-0.8-0.4-1.6-0.6-2.3c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.1-0.6L214.5,954z M173.1,944.2c0.3,0,0.6,0,0.8,0
      c0.4,0,0.7,0,1.1,0c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2c1.4,0.1,2.9,0.2,4.3,0.3c1.5,0.1,3,0.2,4.5,0.2
      c1.7,0.1,3.3,0.2,4.8,0.3c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1c1.5,0.6,3,1.2,4.5,1.9c0.4,0.2,0.8,0.7,1,1.3
      c0.5,1.7,1,3.5,1.4,5.2c1.2,5,2.2,9.5,2.4,14.1c0,3.1-0.2,6.2-0.4,9.2c-0.1,1.1-0.2,2.6-0.2,3.9c-0.1,1.2-0.1,2.5-0.2,3.7l-0.1,1.3
      c-5.9-1.4-11.8-2.9-17.5-4.4l-0.2-0.1c-7.6-2-15.5-4-23.4-5.8V944.2z M173.2,983.1c0-0.3,0-0.6,0.1-0.9l41,10.3l-2.3,41.1
      c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.4-0.3-2.7-0.6-4-0.9c-2.9-0.6-5.7-1.1-8.1-1.5c-1.8-0.3-3.9-0.7-5.9-1
      c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3c-0.9-0.1-1.7-0.1-2.6-0.2c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9
      c0-1.9-0.1-3.9-0.1-5.8v-0.1c0-1.7-0.1-3.6-0.1-5.5c0-1.3,0-2.7,0-4v-0.1c0-1.2,0-2.5,0-3.7c0-1,0-2.1-0.1-3.1
      c0-1.1-0.1-2.2-0.1-3.3C173,985,173.1,984,173.2,983.1z M172,1097.3c0.3,0,0.6,0.1,1,0.2l0.2,0.1c1.1,0.3,2.1,0.7,3.2,1l0.2,0.4
      c1.5,0.5,2.9,0.9,4.2,1.3c0.5,0.1,0.6,0.3,0.5,0.8c-0.4,4.7-0.8,9.5-1.2,14.3c-0.2,2.3-0.4,4.6-0.5,6.9c-0.1,1.4-0.2,2.8-0.3,4.1
      c0,0.1,0,0.2-0.1,0.4l-0.9-0.1c-1-0.1-2-0.3-2.9-0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0-1.2,0-1.9,0h-0.1c-1.6,0-3.3,0.1-5,0.1
      c-0.9,0-1.7,0.1-2.6,0.2c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1c-0.1-1.2-0.1-2.3-0.2-3.4c-0.2-1.8-0.3-3.7-0.5-5.5
      v-0.1c-0.2-2.3-0.4-4.7-0.6-7c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6c3.3-1.1,6.2-2,8.8-2.8
      C171.9,1097.3,171.9,1097.3,172,1097.3z M132.7,952.3c0.1-0.3,0.5-0.7,0.9-0.8c3.8-1.6,7.6-3.2,10.8-4.6c3.2-1.4,6.8-2.1,11-2.2
      c2.5-0.1,5.1-0.2,7.6-0.4h0.1c1.6-0.1,3-0.2,4.4-0.2c0.4,0.2,0.8,0.1,1.3,0.1c0.3,0,0.6,0,0.9,0h0.2c0.3,0,0.6,0,0.9,0V980
      c-7.9,1.8-15.8,3.8-23.5,5.8h-0.1c-5.7,1.5-11.6,3-17.5,4.4c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.8-0.2-1.6-0.2-2.4
      c-0.1-1.4-0.1-2.9-0.2-4.3c0-1.4-0.1-2.8-0.2-4.3v-0.6c-0.2-3.6-0.4-7.3,0.4-10.9c0.4-1.9,0.8-3.7,1.1-5.6v-0.1
      c0.3-1.7,0.7-3.6,1.1-5.5C131.8,954.7,132.2,953.6,132.7,952.3z M86.4,985.1c0.6-0.8,1.3-1.7,1.9-2.5l0.1-0.1
      c1.1-1.5,2.2-3,3.4-4.5c3-3.6,5.7-6.6,8.4-9.4c0.6-0.6,1.5-1.1,2.4-1.6c0.4-0.2,0.8-0.4,1.2-0.6c5.1-3.1,10.4-5.5,14.9-7.6
      c2.7-1.2,5.4-2.5,8-3.8l0.3-0.2c1.1-0.5,2.2-1.1,3.3-1.6c0.1,0,0.1-0.1,0.2-0.1l-0.3,0.5c-0.2,0.6-0.3,1.2-0.5,1.8
      c-0.2,0.7-0.3,1.3-0.5,1.9c-0.4,1.3-0.7,2.7-1,4.1c-0.2,1.1-0.4,2.2-0.6,3.2v0.1c-0.1,0.9-0.3,1.9-0.5,2.9c-0.6,3-0.5,6-0.3,8.9
      c0,0.4,0,0.9,0.1,1.3c0.1,1.8,0.2,3.7,0.3,5.5v0.6c0.1,1.3,0.1,2.6,0.2,3.8c0,1,0,1.9,0,2.8c0,0.2-0.2,0.5-0.3,0.6
      c-1.4,0.8-2.9,1.6-4.4,2.5c-2.1,1.2-4.4,2.5-6.6,3.8c-1.8,1.1-3.6,2.2-5.3,3.3c-0.8,0.6-1.7,1.1-2.6,1.7c-0.9,0.6-1.7,1.1-2.6,1.7
      l-0.1,0.1c-1.7,1-3.4,2.1-5,3.2c-2,1.3-4,2.8-5.9,4.2L94,1012c-1.3,0.9-2.5,1.9-3.8,2.9L90,1015c-0.5,0.3-0.9,0.7-1.4,1
      c0-0.2,0-0.4-0.1-0.7v-0.3c-0.1-1.1-0.2-2.1-0.3-3.1c-0.2-2.2-0.5-4.5-0.7-6.7l-0.1-0.6c-0.3-2.7-0.6-5.3-0.8-8
      c-0.1-1.3-0.2-2.6-0.3-3.9v-0.1c-0.1-0.9-0.2-1.9-0.2-2.8c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8C85.4,986.6,85.7,986,86.4,985.1
      z M64.5,1019c0.3-0.6,0.5-1.2,0.8-1.7c0.8-1.7,1.6-3.4,2.6-5.1c2-3.4,4.1-6.9,6.1-10.1c1.6-2.6,3.3-5.2,5-7.5
      c1.1-1.5,2.3-3,3.5-4.4c0.4-0.5,0.9-1.1,1.3-1.6l0.1,1.7c0.1,1.5,0.2,3,0.4,4.4c0.2,1.9,0.4,3.7,0.5,5.6v0.1
      c0.2,1.6,0.3,3.3,0.5,4.9c0.3,3.6,0.6,6.9,0.9,10c0.1,0.1,0,0.3-0.1,0.4c-0.9,1.1-1.8,2.1-2.7,3.2c-1.6,1.9-3.2,3.8-4.7,5.8
      c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.5-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2-1.3-0.4-2.6-0.6-3.9
      c-0.5-3.5-1-6.9-1.6-10.3C61.5,1027.7,62.3,1023.5,64.5,1019z M62.8,1085.3v-0.1c-0.1-0.7-0.2-1.3-0.4-1.9
      c-0.2-0.9-0.4-1.8-0.6-2.7v-0.1c-0.3-1.5-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4v-0.1c-0.2-1.2-0.4-2.5-0.7-3.7l-0.1-0.4
      c-0.3-1.6-0.6-3.3-1.2-4.9c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.1c0.3-2.6,0.6-5.3,1-8c0.4-2.9,0.9-5.9,1.5-8.7
      c0.2,1.2,0.4,2.3,0.5,3.5c0.3,1.7,0.5,3.5,0.8,5.2l0.4,2.6c0,0.1,0,0.1,0,0.2l0.1-0.1c0,0,0,0.1,0,0.1c-0.3,1.4,0,2.7,0.3,4
      c0.2,0.7,0.3,1.4,0.4,2.1c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.7,1,7l0.1,0.6c0.3,1.7,0.5,3.4,0.8,5.1c0.4,2.4,0.7,5,1,7.6
      c0,0.3-0.1,0.8-0.4,1.2c-1,1.2-2,2.4-3.1,3.7l-0.1,0.2C63.1,1084.9,62.9,1085.1,62.8,1085.3z M43.6,1111.5c1.4-1.8,2.7-3.7,4.1-5.5
      c0.1-0.1,0.2-0.3,0.3-0.5c0.2-0.4,0.5-0.7,0.8-0.9l0.1-0.1c0.6-0.5,1.3-1.1,2-1.3c3.6-1,7.4-2,11.5-3.1c0.4-0.1,0.6-0.1,0.8-0.1
      s0.2,0,0.5,1.1c-0.1,0.6,0,1,0.2,1.4c0.2,0.6,0.4,1.3,0.5,1.9c0.1,0.8,0.1,1.4-0.1,1.8c-0.3,0.6-0.8,1.1-1.3,1.6
      c-0.2,0.2-0.5,0.5-0.7,0.8l-0.7,0.8c-0.9,1.1-1.9,2.2-2.7,3.4c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1c-1.2,0-2.4-0.1-3.5-0.2h-0.1
      c-1.4-0.1-2.9-0.2-4.3-0.2c-1.6,0-2.9,0.1-4.2,0.4c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0.2
      L43.6,1111.5z M49.8,1144.6c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2-0.7-0.5-1.5-0.8-2.2c-0.7-2-1.4-4.1-2.1-6.2c-0.3-0.9-0.7-1.8-1-2.7
      c-0.3-0.7-0.6-1.6-0.9-2.4c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2c0,0,0.2-0.2,0.3-0.2
      c1.9-0.5,3.8-0.9,5.7-1.3l1.7-0.4c0.1,0,0.2,0,0.2-0.1c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5c0.1,1.6,0.1,3.2,0.2,4.7v0.2
      c0,2,0.1,4,0.2,6c0.1,1.8,0.4,3.7,0.6,5.5c0.1,1.1,0.3,2.2,0.4,3.4c0.2,1.9,0.4,4,0.4,6c0,0.1-0.3,0.3-0.6,0.4
      C51.4,1144,50.6,1144.3,49.8,1144.6z M51.5,1160l-1.5-12.8l1.4-0.8l1.5-0.7l1.1,13.5L51.5,1160z M65,1182.6
      c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.5-1.1-0.9-1.7-1.4l-0.2-0.1
      c-1.4-1.2-3.1-2.5-4.5-3.8c-1.4-1.3-2.3-2.4-2.8-3.5c-0.4-0.8-0.5-1.7-0.6-2.7c0-0.5-0.1-0.9-0.2-1.4l-0.6-3.6
      c-0.4-2.1-0.7-4.2-1.1-6.3c-0.1-0.5-0.1-1-0.1-1.5c0-0.1,0-0.3,0-0.4c0.3-0.1,0.4-0.2,0.6-0.2l0.1-0.1c0.6-0.2,1.2-0.5,1.8-0.8
      c0.4-0.2,0.6-0.2,1,0.3l2.1,2.5c2.4,2.9,5,6,7.4,9c0.2,0.2,0.3,0.6,0.3,0.9C64.7,1176.7,64.7,1179.3,65,1182.6z M63.4,1167.9
      c-1.7-2.1-3.5-4.3-5.5-6.2c-1.5-1.5-1.6-3.3-1.7-5.3c0-0.4,0-0.7-0.1-1.1c4.1-1,8.3-1.1,11.9-1.1c0.7,0,1.4,0,2.2,0
      c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2c-0.4,0-0.8,0.1-1.2,0.1h-0.4c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4
      C65.3,1170.2,64.2,1169,63.4,1167.9z M82.8,1192.4c-0.1,0.1-0.4,0.2-0.7,0.1c-2.1-0.2-4.1-0.5-6.2-0.7l-0.9-0.1
      c-1.8-0.2-3.6-0.4-5.4-0.6c-0.6-0.1-1.1-0.2-1.6-0.3c-0.4-0.1-0.6-0.3-0.5-1v-0.3c0.1-1.8,0.2-3.7,0.1-5.5c0-1.4-0.1-2.8-0.2-4
      c0-0.6-0.1-1.2-0.1-1.9c0-0.9-0.1-1.8-0.1-2.7v-1l9.7-0.9c0,0.1,0,0.1,0,0.2v0.2c0.1,0.5,0.2,0.9,0.4,1.4c0.8,2.2,1.5,4.4,2.3,6.6
      l0.1,0.4c0.7,2,1.4,3.9,2,5.9c0.4,1.2,0.8,2.4,1.1,3.5C82.9,1192,82.9,1192.3,82.8,1192.4z M83.4,1175c-0.1-0.7-0.3-1.4-0.6-2
      c-0.2-0.7-0.5-1.5-0.6-2.2c0-2.1-0.2-4.2-0.4-6.3c-0.1-0.9-0.2-1.8-0.3-2.8s-0.2-1.9-0.3-2.9c-0.2-1.5-0.3-3.1-0.5-4.6
      c-0.1-1.2-0.2-2.4-0.3-3.5l-0.1-1.2h0.1c0.4,0.1,0.9,0.3,1.2,0.5c1.1,0.5,2.2,1.1,3.2,1.6c2.1,1.1,4.2,2.1,6.4,3.1
      c2.5,1.1,5.1,1.9,7.6,2.8l0.3,0.1c0.5,0.2,1.1,0.4,1.6,0.5c0.8,0.3,1.6,0.4,2.5,0.6c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.1
      c-0.2,1.1-0.3,2.2-0.5,3.2c-0.3,1.7-0.5,3.4-0.8,5.1c-0.4,2.5-0.8,5-1.2,7.5c-0.2,1.4-0.4,2.9-0.6,4.4s-0.4,3.1-0.7,4.8
      c-0.1,0.4-0.2,0.4-0.2,0.4c-0.2,0-0.4-0.1-0.6-0.2h-0.1c-1.3-0.7-2.6-1.5-3.8-2.2l-0.2-0.1c-0.7-0.4-1.4-0.9-2.2-1.4l-0.1-0.1
      c-1-0.6-2.1-1.3-3.2-2c-1.2-0.7-2.5-1.5-3.7-2.1c-0.6-0.3-1.2-0.7-1.8-1C83.7,1176,83.5,1175.7,83.4,1175z M95.3,1217.3l-0.1-0.1
      c-1.4-2.7-2.8-5.4-4.1-8.1c-0.6-1.3-1.2-2.7-1.7-4c-0.7-1.6-1.3-3.3-2.2-4.9c-1.3-2.5-1.6-5.2-1.9-7.9c0-0.4-0.1-0.7-0.1-1.1
      c-0.3-3-0.6-5.9-0.9-9v-0.1c-0.1-1.1-0.2-2.3-0.3-3.4l15.7,9.4c-0.7,3.7-1.3,7.6-0.5,11.8c0.4,2.4,0.7,4.9,0.9,7.3v0.1
      c0.1,1.4,0.3,2.9,0.5,4.3c0.7,5.5,1.4,10.9,2.2,16.2c0.3,2.3,0.8,4.7,1.2,6.9c0.1,0.5,0.2,0.9,0.3,1.4c-0.3-0.6-0.5-1.2-0.8-1.8
      l-0.2-0.4c-1.2-2.8-2.5-5.7-3.9-8.5C98.1,1222.7,96.7,1219.9,95.3,1217.3z M107.1,1339.4l-0.1,0.2c-0.4,0.9-0.8,1.7-1.2,2.6
      c-0.1,0.2-0.2,0.4-0.3,0.6v0.1c-7.7-7.1-13.9-13.3-19.4-19.5c0.2-0.3,0.3-0.5,0.5-0.8c0.5-0.8,1-1.5,1.5-2.3c0.7-1,1.4-1.9,2.1-2.9
      c0.6-0.9,1.2-1.7,1.8-2.6l0.5-0.7c0.7-1,1.4-2,2-3c1.4-2.4,2.8-4.9,4.2-7.3v-0.2c0.6-1.1,1.2-2.1,1.8-3.2c0.2,0.2,0.4,0.4,0.7,0.7
      l0.1,0.1c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1c1.8,1.4,3.8,2.7,5.6,3.9l1.3,0.9c0.4,0.3,0.5,0.7,0.4,1.4l-0.1,0.3
      c-0.3,1.4-0.7,2.8-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3c-0.6,1.3-1.2,2.5-1.8,3.7c-0.4,0.8-0.9,1.8-1.3,2.7
      C108.4,1336.4,107.8,1337.9,107.1,1339.4z M150.1,1325.2c-0.4,12.1-0.8,24.6-1.9,37.1c-0.2-0.1-0.5-0.1-0.7-0.2
      c-0.8-0.2-1.5-0.4-2.2-0.7c-1-0.4-2.1-0.8-3.1-1.2c-0.7-0.3-1.3-0.5-2-0.8c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-2.6-1-4-1.5
      c-2.5-1-5.2-2.2-8.2-3.5c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.4-1.7-5.1-2.5l-0.1-0.1c-0.9-0.4-1.7-0.9-2.6-1.3
      c-0.2-0.1-0.5-0.2-0.7-0.4l-0.1-0.1c-0.7-0.4-1.4-0.7-2.2-1.1c-0.3-0.1-0.4-0.2-0.4-0.3c0-0.1,0-0.1,0.1-0.2
      c1.3-2.7,2.5-5.5,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.6-1.4,1-2.1c0.6-1.3,1.2-2.6,1.8-3.9
      c0.7-1.7,1.2-3.5,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6c0.1-0.2,0.1-0.4,0.1-0.6c0.4,0.2,0.8,0.3,1.2,0.4c0.4,0.1,0.9,0.3,1.3,0.4
      c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4h0.1c1.7,0.2,3.4,0.3,5.1,0.5
      c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1c0.4,0.1,0.8,0.1,1.2,0.2C150.2,1321.7,150.2,1323.5,150.1,1325.2z M150.6,1308.3L150.6,1308.3
      c-0.1,1.8-0.2,3.5-0.2,5.2c0,0.9,0,1.8,0,2.8v0.3c0,0.2,0,0.5,0,0.7c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8.1-1
      c-1.6-0.2-3.2-0.3-4.7-0.5h-0.1c-1.5-0.1-3.1-0.3-4.6-0.4c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5
      c0.1-0.8,0.1-1.6,0.1-2.4c0-0.5,0-1,0-1.5c0-0.9,0.1-1.7,0.1-2.6v-0.8c0.2-3.7,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.5
      c0.2-2.3,0.5-5.1,0.7-7.8c0.1-1.9,0.2-3.8,0.3-5.5c0.1-1.2,0.1-2.4,0.2-3.6c0.1-1.7,0.2-3.3,0.4-5.2v-0.4c0-0.5,0.1-0.9,0.1-1.4
      c0.5,0.3,1.1,0.7,1.6,1c1.5,0.9,3.1,1.8,4.6,2.8c0.9,0.6,1.8,1.1,2.7,1.7c2.5,1.5,5,3,7.4,4.7c2.5,1.8,5.4,2.1,8.1,2.5h0.3
      c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0.1,0.6,0.1s0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6
      c-0.1,1.3-0.2,2.6-0.2,3.9v0.3c-0.1,1.2-0.1,2.5-0.2,3.7c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3
      c0.1,1.5,0.1,3.1,0.1,4.7c0,1.6,0.1,3.2,0,4.7C150.8,1302.8,150.7,1305.6,150.6,1308.3z M193.2,1362.2c-0.1,0.1-0.2,0.1-0.4,0.1
      c-0.6,0-1.3,0.1-1.9,0.2h-0.4v-0.2h-0.4c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5c-1.6,0.1-3.2,0.1-4.7,0.1
      c-0.7,0-1.5,0-2.2,0c-0.8,0-1.5,0-2.3,0.1c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2c-2.7-0.2-5.3-0.3-7.9-0.5l-2-0.1
      c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.5-0.4-0.5c0-2.5,0.2-5,0.4-7.4c0.1-1.7,0.2-3.3,0.3-5
      c0.3-4,0.5-7.9,0.7-11.7c0.1-1.3,0.1-2.7,0.1-4v-0.1c0-1.1,0-2.1,0.1-3.1c0-1.4,0.1-2.9,0.2-4.2c0-0.9,0.1-1.9,0.1-2.9
      c0.1-1.2,0.1-2.4,0.1-3.7c0-0.4,0.1-0.5,0.1-0.6c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1c3.2,0.1,6.5,0.2,9.7,0.5
      c2.6,0.2,5.1,0.2,7.6,0c3.2-0.3,6.5-0.4,9.7-0.5h0.3c1.5,0,3.1-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7c0.1,3.9,0.2,8,0.4,12.2
      c0.1,3.3,0.3,6.6,0.5,9.8l0.2,2.9c0.2,4.2,0.5,8.5,0.7,12.9c0.1,1.4,0.2,2.8,0.4,4C193.3,1361.9,193.3,1362.1,193.2,1362.2z
       M192.3,1271.4l0.5-0.2c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2c2-0.2,4-0.5,5.8-1.5c2-1.2,4.1-2.4,6-3.7
      c0.5-0.3,1-0.6,1.5-1c2.5-1.6,5.1-3.2,7.4-4.7l2.8-1.8c0,0.4,0,0.8,0.1,1.2v0.1c0.1,1.2,0.1,2.3,0.2,3.5c0.3,3.6,0.5,7.3,0.8,10.8
      l0.3,3.7c0.2,2.6,0.4,5.1,0.6,7.7c0.1,1.2,0.2,2.4,0.3,3.6v0.1c0.2,2.3,0.4,4.7,0.6,7c0.2,3.4,0.3,6.9,0.4,10.2v0.1
      c0,1.1,0.1,2.2,0.1,3.2c0,0.3,0,0.6,0.1,1c0.1,0.8,0.2,1.6-0.1,2c-0.3,0.4-0.9,0.6-1.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.5
      c-1.7,0.9-3.6,1.1-5.5,1.3c-1.8,0.2-3.7,0.4-5.3,0.6c-1.1,0.1-2.2,0.3-3.3,0.4c-0.9,0.1-1.8,0.2-2.7,0.3c-1,0.1-2.1,0.2-3.2,0.3
      c-1.7,0.2-3.5,0.4-5.3,0.7l-2.1,0.3L192.3,1271.4z M235.7,1344.9c-1.7,0.8-3.4,1.6-5.1,2.4c-1.3,0.6-2.6,1.3-3.9,1.9
      c-0.9,0.5-1.9,0.9-2.8,1.4c-2.9,1.4-6.1,2.9-9.2,4.4c-1.6,0.7-3.4,1.4-5,2h-0.1c-0.9,0.3-1.8,0.7-2.6,1s-1.6,0.6-2.3,1l-0.2,0.1
      c-1.3,0.5-2.6,1.1-3.9,1.6c-0.9,0.3-1.9,0.6-2.8,0.9h-0.1c-0.5,0.1-0.9,0.3-1.4,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.5
      c-0.2-4.3-0.5-7.9-0.7-11.4c-0.1-1.1-0.2-2.2-0.2-3.3v-0.1c-0.1-1.7-0.3-3.4-0.3-5.1c-0.1-1.3-0.1-2.8-0.1-4c0-0.8,0-1.7,0-2.5
      c0-0.7,0-1.4-0.1-2.2v-0.1c0-0.6-0.1-1.3-0.1-1.9c0-1,0-2.1-0.1-3.1c0-1.4-0.1-2.9-0.1-4.3c0-1-0.1-2.1-0.2-3v-0.1
      c0-0.3,0-0.6-0.1-0.9l3.5-0.3c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.1-0.2,3.2-0.3c1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9
      c0.7-0.1,1.4-0.4,2.1-0.7c0.3-0.1,0.5-0.2,0.8-0.3c0.4-0.1,0.7-0.3,1-0.4c0.2,0.5,0.3,1,0.5,1.5l0.1,0.4c0.5,1.7,1.1,3.5,1.7,5.3
      c0.5,1.5,1.2,3.1,2.1,5c0.5,1.2,1.1,2.3,1.7,3.4c0.5,1,1,2.1,1.5,3.2c0.8,1.7,1.6,3.5,2.4,5.3l0.1,0.2c0.4,0.9,0.8,1.8,1.2,2.6
      l0.1,0.3c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.2,0.1,0.3,0.1,0.4S235.8,1344.9,235.7,1344.9z M257.9,1322.9l0.2,0.3
      c-5.4,6.2-11.4,12.2-19.4,19.6c-0.2-0.5-0.5-0.9-0.7-1.4l-0.1-0.1c-0.6-1.2-1.2-2.3-1.7-3.5c-1.5-3.2-2.6-5.8-3.6-8.1
      c-1.9-4.6-3.8-9-5.5-13.3c-0.4-0.8-0.5-1.5-0.4-2.1c0.1-0.5,0.5-1,0.9-1.3c1-0.7,2-1.3,3-2c1.6-1,3.3-2.1,4.8-3.3
      c1.8-1.4,3.5-3.1,5.2-4.6c0.7-0.7,1.5-1.4,2.2-2.1c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1C247.4,1308.6,252.7,1315.8,257.9,1322.9z
       M259.5,1179.7c-0.1,1.2-0.2,2.4-0.3,3.5l-0.1,0.8c-0.2,2.5-0.5,5.2-0.7,7.8c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6
      l-0.2,0.4c-0.3,0.7-0.6,1.3-0.8,2l-0.2,0.4c-0.9,2.2-1.8,4.4-2.9,6.5c-0.9,1.8-1.9,3.7-2.7,5.3l-0.1,0.2c-0.5,1-1.1,2-1.6,3
      c-0.8,1.5-1.5,3-2.2,4.5c-0.7,1.6-1.5,3.2-2.2,4.9l-0.2,0.5c-0.6,1.6-1.3,3.3-2.1,5.1c-0.7,1.5-1.4,3.1-2.3,4.7
      c0.2-1.3,0.4-2.5,0.6-3.7c0.2-1.4,0.5-2.7,0.7-4.1c0.5-2.7,1-5.5,1.4-8.3c0.4-2.5,0.6-5,0.8-7.5v-0.1c0.1-1.5,0.3-3,0.4-4.5
      c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4c-0.1-0.6-0.2-1.3-0.3-1.9c0-0.1,0-0.3,0-0.4l2-1.2c1.6-1,3.4-2.1,5-3.1
      c0.8-0.5,1.6-1,2.4-1.4s1.7-1,2.5-1.5c0.9-0.6,1.9-1.2,2.9-1.8l0.2-0.2c0.2-0.1,0.4-0.3,0.6-0.4L259.5,1179.7z M257.5,1177.6
      c-2.4,1.3-4.9,2.8-7.2,4.2l-1,0.6c-0.8,0.5-1.7,1-2.5,1.6c-0.6,0.4-1.2,0.7-1.7,1.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0
      s-0.1,0-0.2-0.4c-0.2-1.7-0.5-3.4-0.8-5.3c-0.1-0.7-0.2-1.4-0.3-2c-0.2-1.1-0.3-2.1-0.5-3.2v-0.2c-0.3-2.3-0.7-4.7-1.1-7.1
      c-0.3-1.7-0.6-3.5-0.9-5.2v-0.2c-0.2-0.9-0.3-1.8-0.5-2.7c0-0.1,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3c1.1-0.2,2.1-0.5,3.1-0.8
      c4.4-1.3,8.7-3.1,12.8-5.1c0.7-0.3,1.3-0.7,2-1c1.3-0.6,2.4-1.2,3.6-1.8c0.4-0.2,0.8-0.3,1.2-0.5l0.2-0.1l-0.2,2.2
      c-0.2,2-0.4,3.9-0.5,5.8c-0.1,1-0.2,2-0.3,3v0.2c-0.2,2.7-0.5,5.5-0.8,8.2c-0.1,0.7-0.3,1.3-0.5,2c-0.3,0.8-0.5,1.6-0.6,2.4
      C260.6,1175.5,259.4,1176.5,257.5,1177.6z M276.4,1175.7c0,1.2,0,2.4,0,3.6c0,1.2,0,2.4,0,3.7c0,2.2-0.1,4.5-0.1,6.7
      c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9H267c-1.7,0.2-3.4,0.4-5.1,0.7c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.6
      c0.4-1.3,0.9-2.6,1.3-3.7v-0.1c0.3-0.9,0.7-1.8,1-2.7c0.9-2.7,1.8-5.4,2.7-8.2l1.1-3.4l9.3,0.7V1175.7z M276.8,1172
      c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1c-0.1,0-0.2,0-0.3-0.1l2.9-17c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0c3.7,0,7.9,0.1,12.1,1.1
      l-0.1,0.8c-0.1,1.1-0.2,2.3-0.3,3.5c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3c-2,2.3-4.1,4.6-5.8,6.5
      C277.3,1171.8,277,1172,276.8,1172z M292.1,1162.6c0,0.3,0,0.7,0,1c-0.3,1.5-0.5,3-0.8,4.5l-0.1,0.3c-0.3,1.6-0.6,3.2-0.9,4.8
      c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.8-0.4,1.8-0.9,2.5c-1.4,2.1-3.5,3.8-5.4,5.3c-0.8,0.6-1.6,1.2-2.3,1.9
      c-0.6,0.5-1.2,1-1.8,1.5l-0.4,0.3c0-0.5,0-1.1,0-1.6v-0.1c-0.1-1.3-0.1-3-0.1-4.6c0-1.2,0-2.4-0.1-3.6v-0.2c0-1.3-0.1-2.5-0.1-3.6
      c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8l0.1-0.2c1.3-1.5,2.6-3,3.9-4.5c0.6-0.7,1.1-1.4,1.6-2.2c0.2-0.3,0.4-0.6,0.6-0.9
      c0.4-0.6,0.7-0.6,1.3-0.4c0.5,0.3,1.1,0.5,1.7,0.8c0.2,0.1,0.4,0.1,0.6,0.2C292.1,1162.5,292.1,1162.5,292.1,1162.6z M292.4,1159.9
      l-2.5-0.7l1.1-13.5l2.9,1.4L292.4,1159.9z M303.9,1117.8c-0.1,1.2-0.3,2.1-0.6,2.9c-0.6,1.7-1.2,3.4-1.8,5.1l-0.1,0.2
      c-0.5,1.3-1,2.7-1.5,4.1c-0.7,2-1.4,4.1-2.1,6.1c-0.4,1.3-0.9,2.6-1.4,3.9c-0.4,1.2-0.8,2.4-1.2,3.5l-0.5,1.4l-3-1.2
      c-0.4-0.3-0.5-0.5-0.4-0.9v-0.4c0.5-5.1,1.1-10.3,1.5-15.4c0.1-1.6,0.1-3.3,0.1-4.9c0-0.9,0-1.9,0-2.8c0-0.7,0.1-1.5,0.1-2.2v-0.1
      c0-0.5,0.1-0.9,0.1-1.4c0-0.4,0.2-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.3,0.3,1.9,0.5
      c0.8,0.2,1.5,0.4,2.3,0.5C303.9,1117,304,1117.4,303.9,1117.8z"
      />
      <path
        d="M960,657.2c-2.3-3.3-4.7-6.6-6.7-9.4c-0.9-1.3-1.8-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.7,0.3-1.4,0.5-2.1l0.2-1.1
      c0.3-1.2,0.5-2.5,0.8-3.7c0.1-0.7,0.3-1.4,0.4-2.2v-0.2c0.2-0.9,0.3-1.7,0.5-2.5c0.3-1.4,0.6-2.7,0.9-4.1v-0.1c0.5-2.3,1-4.7,1.5-7
      c0.3-1.4,0.5-2.9,0.8-4.3c0.2-1.5,0.5-3,0.8-4.4c0.1-0.6,0.2-1.1,0.4-1.7c0.5-2.1,0.9-4.2,0.7-6.4c-0.3-3.1-0.7-7.1-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-0.9-0.4-1.9-0.6-2.8v-0.1c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.3-2.3-1.9-3.4
      l-0.1-0.1c-0.7-1.2-1.4-2.6-2.2-4c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-1-1.4-1.4-2c-0.4-0.5-0.7-1-1.1-1.5c-0.4-0.7-0.9-1.4-1.3-2
      l-0.1-0.1c-0.8-1.3-1.6-2.6-2.6-3.8c-1-1.2-2-2.4-3-3.6c-0.9-1-1.9-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.7-3.7c-0.7-1-1.5-2.1-2.2-3.1
      c-0.6-0.8-1.3-1.6-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.7-6-8.2-8.1c-2.5-1.5-5.2-3-7.9-4.3c-2.5-1.2-5-2.3-7.5-3.5
      c-2.1-1-4.3-1.9-6.5-3c-2.2-1-4.3-2.1-6.4-3.2c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1c-2-0.4-4-0.5-6-0.6h-0.3
      c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1
      c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1c-3.3,0.2-6.7,0.5-9.8,2.1
      c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4c-2.1,0.9-4.1,2-6,3
      c-1.3,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.9,1.8-5.6,2.6c-1.9,0.9-4,1.8-5.9,2.8c-1,0.5-1.9,1-2.9,1.6c-1,0.6-2,1.1-3,1.6
      c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0.6-0.8,1.1-1.3,1.6c-0.5,0.7-1.1,1.3-1.6,2l-0.1,0.1c-0.9,1-1.8,2.1-2.6,3.2
      c-1.1,1.4-2.1,2.8-3.1,4.1l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.2-2.6,3.2c-0.9,1.1-1.8,2.2-2.7,3.4c-0.7,0.9-1.3,1.8-1.8,2.7
      c-0.3,0.5-0.6,0.9-0.9,1.4c-0.6,0.9-1.1,1.7-1.7,2.6c-1.2,1.9-2.5,3.8-3.7,5.8c-2,3.3-3.7,6.9-5.2,9.9c-1,2-1.7,3.7-2.1,5.3
      c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10-2.3,14.3c-0.3,2.2-0.5,4.4-0.8,6.6c-0.2,1.7-0.3,3.2-0.5,4.7c-0.3,2.7-0.2,5.4,0.4,8.3
      c0.2,1.1,0.5,2.2,0.7,3.4l0.1,0.4c0.4,2.1,0.9,4.2,1.3,6.3c0.2,1.2,0.4,2.4,0.6,3.6c0.2,1.3,0.4,2.6,0.7,3.9c0.3,1.5,0.7,3.1,1,4.5
      v0.2c0.3,1.2,0.6,2.4,0.8,3.7c0.3,1.5,0.6,3.1,0.9,4.5l0.1,0.3c0.1,0.7,0.3,1.4,0.4,2.2c0.1,0.4,0.2,0.7,0.2,1v0.1c0,0,0,0-0.1,0
      c-0.8,0.2-1.5,0.3-2.3,0.5l-0.7,0.1c-2.2,0.5-4.4,1-6.7,1.5c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1
      c-2.5,3.3-5.3,7.2-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3c0.8,2.9,1.7,6,3,9.7c0.5,1.3,1,2.6,1.5,3.9c0.5,1.1,0.9,2.3,1.4,3.5
      c0.7,1.9,1.3,3.8,1.9,5.7c0.4,1.2,0.8,2.4,1.2,3.6c0.2,0.5,0.3,0.9,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2c0.2,0.9,0.2,1.9,0.3,2.8
      c0,0.7,0.1,1.4,0.1,2.1l0.1,1c0.5,3.9,0.9,8,1.5,12c0.4,2.7,0.9,5.5,1.4,8.2l0.4,2.2c0,0.2,0.1,0.4,0.1,0.7
      c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6c0.8,0.8,1.8,1.5,2.7,2.2c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2
      c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7c0.2,0,0.4,0.2,0.5,0.3c0.3,0.7,0.5,1.4,0.8,2c0.3,0.7,0.5,1.4,0.8,2.1
      c0.2,0.6,0.5,1.1,0.7,1.7l0.1,0.3c1.1,2.5,2.2,5,3.4,7.5c1.3,2.6,2.7,5.3,3.9,7.7c0.9,1.7,1.9,3.5,2.8,5.3c0.8,1.7,1.7,3.5,1.7,5.6
      c0,3.3,0.1,6.7,0.1,10v0.3c0.1,2.5,0.1,5.1,0.1,7.6c0,3.1,0,6.3,0,9.4c0,1.6,0,3.1,0,4.7v1.5c0,1.8,0,3.7,0,5.6
      c0,1.5,0.1,3.1,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6c0,1.1,0,2.1,0,3.2v0.1c0,1.8,0,3.6,0.1,5.4c0.1,2.2,0.2,4.3,0.3,6.4v0.3
      c0.1,1.4,0.2,2.9,0.2,4.3c0.1,1.1,0.1,2.3,0,3.8c0,0.1,0,0.2,0,0.3v0.1c0,0.6,0,1.3-0.2,1.7c-0.5,1-1.1,2-1.7,3
      c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.9,1.3-1.3,2c-0.6,1-1.3,2.1-2.1,3.1
      s-1.6,2-2.4,3c-0.9,1-1.8,2.2-2.7,3.4l-0.1,0.1c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2c-2.5,1.4-5.6,3.1-8.5,4.8
      c-1,0.6-2,1.2-3,1.8c-1,0.6-1.9,1.2-2.9,1.8c-0.8,0.5-1.7,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2c-1.2,0.7-2.3,1.5-3.3,2.3h-0.1
      c-0.7,0.5-1.4,1-2.2,1.5c-1,0.7-2,1.3-3,1.9l-0.1,0.1c-0.4,0.3-0.9,0.5-1.3,0.8l-1,0.6l1.2,0.1c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0
      c0.1,0,0.2,0,0.2,0c1.2-0.6,2.4-1.2,3.6-1.7c2.9-1.4,6.1-3,9.3-4.6c2-1,4-2.2,6-3.4l1.2-0.7c0.7-0.4,1.4-0.9,2.1-1.3l0.1-0.1
      c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.2-1.2,3.3-1.7c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1
      l0.1-0.1c0.3-0.3,0.6-0.6,0.8-0.9l0.2-0.2c0.7-0.7,1.5-1.5,2.2-2.4c0.7-0.7,1.3-1.4,2-2.3l0.8-0.9l0.4,0.5c0.4,0.5,0.8,1,1.2,1.4
      c0.4,0.4,0.7,0.8,1.1,1.3c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8c0.7,0.6,1.3,1.2,2,1.9c0.7,0.7,1.5,1.4,2.2,2.2
      c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5c-0.1,0.3-0.3,0.6-0.4,1c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.1
      c-0.3,0.7-0.7,1.4-1,2.1v0.1c-0.8,1.7-1.6,3.4-2.3,5.1c-0.9,2.2-1.7,4.6-2.5,6.8l-0.2,0.5c-0.2,0.5-0.4,1.1-0.6,1.6l-0.4,1
      c-0.5,1.3-1,2.7-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.3-0.1,0.5l-0.1,0.4l0.4,0.1
      c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2
      c0.9-2.9,1.7-5.2,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7c0.5-1.2,1.1-2.3,1.7-3.5l0.1-0.1c0.2-0.3,0.3-0.6,0.5-1c0.4,0.2,0.8,0.5,1.2,0.7
      h0.1c1.2,0.7,2.3,1.4,3.5,2c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.3,1.3,4.8,1.8c0.9,0.3,1.7,0.6,2.6,1
      c1.1,0.4,2.2,0.8,3.2,1.2c2.2,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,1-0.1,1.9-0.2,2.9c-0.2,2.7-0.4,5.5-0.6,8.3
      c-0.1,1.4-0.1,2.8,0,4.1c0,0.6,0,1.2,0,1.8v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.3-2.6,0.3-3.8c0-0.7,0.1-1.4,0.1-2.2
      c0.1-1.1,0.2-2.1,0.3-3.2v-0.1c0.1-1.2,0.2-2.4,0.3-3.5c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0c0.6,0.1,1.3,0.1,2,0.1h0.1
      c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1
      c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.4c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1
      c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.1c0.3,3.5,0.5,7,0.9,10.6c0.1,1,0.4,1.9,0.7,2.8
      c0.1,0.4,0.3,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4c-0.1-1.8-0.3-3.6-0.4-5.3v-0.1c-0.1-1.1-0.2-2.2-0.3-3.4
      c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5
      c0.5-0.2,1.1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.7-1.6,5.5-2.3l0.3-0.1c3.2-1.4,6.4-2.8,9.6-4.3c2-0.9,4.4-2,6.8-3.2
      c1.2-0.6,2.4-1.3,3.6-2c0.4-0.3,0.9-0.5,1.3-0.8c0.3,0.6,0.6,1.2,0.8,1.7l0.1,0.3c0.9,1.8,1.8,3.6,2.5,5.5c1,2.4,1.9,4.9,2.7,7.3
      l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.9,8.1c0.4,0.6,1,1,1.6,1c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4
      c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4c-0.5-1.6-1.2-3.1-1.8-4.4l-0.2-0.5
      c-1-2.1-2-4.4-3.1-6.5c-1.1-2-1-2.1,0.6-3.6c1.5-1.4,3-2.8,4.5-4.3l0.6-0.5c0.6-0.6,1.1-1.2,1.7-1.7c0.9-1,1.9-2,2.9-2.9
      c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.2,1.3c1.7,1.8,3.3,3.6,4.9,5.3c0.1,0.1,0.1,0.2,0.2,0.3
      c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.4,0.5,0.8,0.8,1c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.3,1.3,3.4,1.9l0.1,0.1
      c1.5,0.8,3.1,1.7,4.6,2.5c1.2,0.7,2.5,1.5,3.6,2.2c1.2,0.7,2.6,1.6,3.9,2.3c2.2,1.2,4.5,2.4,6.6,3.4c1.8,0.9,3.6,1.7,5.3,2.5
      l0.2,0.1c0.5,0.2,1.1,0.5,1.6,0.7h0.1c0.3,0,0.5-0.2,0.5-0.4v-0.4h-0.3c-0.7-0.5-1.5-1-2.2-1.5l-0.3-0.2c-2-1.4-4-2.8-6.1-4.1
      c-3-1.9-6.2-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.1-1.7-4.6-2.6c-0.8-0.4-1.5-0.8-2.3-1.2l-1.5-0.9c-2.3-1.2-4.5-2.5-6.8-3.8
      c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6c-1.5-2-2.9-4.1-4.3-6.1l-0.1-0.2c-0.7-1-1.3-1.9-2-2.9
      c-0.6-0.8-1.2-1.6-1.7-2.4c-0.3-0.5-0.7-0.9-1.1-1.4c-0.7-0.9-1.4-1.9-2-2.8c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6
      c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5v-0.1c0-0.6,0-1.1-0.1-1.7v-0.2c-0.1-1.3-0.1-2.6-0.1-3.9
      c0.1-2.1,0.2-4.3,0.3-6.2c0.1-1.8,0.2-3.8,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4c0-2.5,0-5-0.1-7.4c0-1,0-2-0.1-3c0-0.8,0-1.7,0-2.5v-0.3
      c0-0.9,0-1.8,0-2.7v-1.6c0-1.5,0-3.2,0-4.8c0-2.2,0.1-4.4,0.2-6.5v-0.3c0.1-1.9,0.1-3.9,0.2-5.8v-0.4c0.1-3.2,0.1-6.1,1.7-9
      c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5,3.3-7.5l0.2-0.4c0.3-0.6,0.5-1.2,0.8-1.8c0.3-0.7,0.6-1.5,0.8-2.1c0.3-0.7,0.5-1.4,0.9-2.1
      c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.7,0,1.1-0.1c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8
      c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.2,7-6,9.9-8.7c1.3-1.2,2.9-2.6,2.9-5c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6
      c0.2-0.9,0.3-1.8,0.5-2.7l0.1-0.4c0.3-1.8,0.7-3.6,1-5.4c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2c0.4-3.4,0.8-6.7,1.1-9.4
      c0-0.4,0.1-0.8,0.1-1.2c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9c0.3-0.7,0.6-1.4,0.8-2.2c1.5-4,2.6-7.2,3.7-10.3l0.5-1.3
      c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.1,0.8-2.4,0.2-3.8C961.2,659,960.6,658.1,960,657.2z M936.6,643.9l2.4,0.6
      c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2c2.4,0.6,4.6,1.2,6.1,3.5c1.5,2.2,3.1,4.3,4.7,6.4c0.6,0.7,1.1,1.5,1.7,2.2
      c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.6-2.8-0.7c-2.8-0.3-5.3-0.5-7.5-0.6c-1.3,0-2.6,0.1-4,0.2h-0.1c-0.6,0.1-1.3,0.1-1.9,0.1
      c-0.1,0-0.2-0.1-0.2-0.1c-1.8-2.2-3.7-4.4-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5c0.4-0.9,0.6-1.9,0.7-2.8l0.1-0.5
      c0.1-0.7,0.4-0.7,0.6-0.7C936.4,643.9,936.5,643.9,936.6,643.9z M943.3,696.1l-0.1,0.8c-3-0.7-6.2-1-10.1-1c-2.4,0-4.7,0.1-7.1,0.3
      c-0.4-7.8,2.7-14.2,5.9-19.4c0.5,0,0.9,0,1.4,0.1h0.7c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6c0.1,0,0.2,0.2,0.2,0.3
      c-0.3,3.7-0.7,7.4-1,11l-0.4,4.9C943.4,694.7,943.4,695.3,943.3,696.1z M899.7,814.2c-0.1,4.4-0.3,8.9-0.5,13.2l-0.1,3.3
      c0,0.6,0,1.2-0.1,1.8v0.1c-0.1,2.6-0.2,5.2-0.5,7.8c-0.1,0.8-0.8,2-1.4,2.3c-1.5,0.6-2.5,1.6-3.5,2.6c-0.5,0.5-1.1,1.1-1.7,1.5
      c-0.7,0.5-1.4,1-2.2,1.7c-0.9,0.7-1.8,1.4-2.8,2.1c-1.2,0.9-2.4,1.7-3.7,2.6c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1,0.1-0.3,0.2-0.5,0.3
      l-0.1-1.5c-0.1-1.3-0.2-2.6-0.2-3.9c-0.1-1.3-0.1-2.5-0.2-3.8c-0.1-2-0.2-4.4-0.3-6.6c-0.2-2.2-0.4-4.5-0.6-6.7
      c-0.1-1.6-0.3-3.3-0.4-5c-0.2-2.1-0.3-4.3-0.5-6.4v-0.7c-0.2-2.7-0.4-5.4-0.6-8.1c-0.3-3.4-0.6-6.8-1-10.1v-0.1
      c-0.1-0.6,0.1-0.8,0.5-1.1c4.2-2.6,8.3-5.1,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3c0.8-1.1,1.5-2.1,2.2-3.1c0.2-0.3,0.3-0.6,0.4-0.9
      c0-0.2,0.1-0.3,0.2-0.5c0.8-1.8,1.6-3.6,2.4-5.4c0.8-1.8,1.6-3.6,2.3-5.3c0.4-0.8,0.7-1.6,1.1-2.5v0.4c0,1.5,0,2.9,0,4.2
      c0,2.4-0.1,4.8-0.1,7.2c0,2.2-0.1,4.5-0.1,6.7c-0.1,3.2-0.1,6.9-0.1,10.5c-0.1,1.6-0.1,3.1-0.1,4.5v0.2
      C899.7,809.6,899.8,811.9,899.7,814.2z M809.4,822.1c0.1-1.6,0.1-3.2,0-4.8c0-0.6,0-1.2,0-1.8c5.9,0.6,11.9,0.9,17.7,0.9
      c5.9,0,11.9-0.3,17.7-0.9l0.2,6.7c0.1,4.9,0.3,10.5,0.4,15.9c0.1,5.1,0.2,10.3,0.4,16l0.2,6.8c-0.1,0-0.1,0-0.2,0l-2,0.1
      c-3.1,0.2-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0c-0.7,0-1.5,0.2-2.2,0.3c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2
      c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0-3.4-0.1
      c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.9-0.1h-0.1c0.2-1.6,0.3-3.1,0.3-4.6c0.1-3.6,0.3-7.2,0.3-10.9
      c0-1.9,0-3.8,0-5.6c0-2.1,0-4.4,0-6.5c0.1-2.1,0.2-4.2,0.4-6.2V827C809.2,825.4,809.3,823.7,809.4,822.1z M841.8,813.2
      c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.7,0-1,0.1h-0.2c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3
      c-4.9-0.4-9.8-0.9-14.6-1.4H806c-1.5-0.1-3-0.4-4.4-0.6h-0.3c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7
      c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5v-0.2c0.3-1,0.6-2.1,0.9-3.1c0.3-1.2,0.6-2.5,0.9-3.7l0.3-1.2c0.7,0,1.3,0,2,0
      h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0c2.7,0.1,5.9,0.2,9,0.5c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2
      h0.2c0.4,0,0.8,0,1.2,0c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0c0.1,0.5,0.2,1,0.4,1.5c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.7,1,3.5,1.5,5.1
      l0.1,0.2c0.7,2.3,1.4,4.7,2.1,7.1c0.2,0.8,0.1,1.8-0.1,2.7v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1
      C845.3,812.8,843.5,813,841.8,813.2z M755.6,830.6V830c-0.1-1.5-0.2-3.1-0.2-4.6c0-1.1-0.1-2.2-0.1-3.3c-0.1-3.4-0.2-7-0.3-10.5
      c0-2.6,0-5.3,0.1-7.9v-0.1c0-2.3,0.1-4.7,0.1-7c0-4.7-0.1-9.4-0.2-13.9v-0.9c0-1.8-0.1-3.7-0.2-5.3v-0.4c0-1-0.1-2-0.1-3
      c0,0,0-0.1,0-0.2c0.5,1.2,1,2.3,1.5,3.5v0.1c1.5,3.4,3,6.8,4.3,10.3c0.5,1.3,1.3,2.3,2,3.3c0.1,0.8,0.5,1.4,0.9,2
      c0.3,0.5,0.9,0.8,1.5,1.1c0.2,0.1,0.5,0.2,0.7,0.4c0.9,0.6,1.8,1.1,2.6,1.7c2,1.3,4,2.6,6.1,3.7c2.1,1.1,2.8,2.3,2.4,4.6
      c-0.6,3.7-0.8,7.4-1.1,11c-0.1,1-0.2,2.1-0.2,3.1c-0.2,1.8-0.3,3.7-0.4,5.5v0.1c-0.1,1.2-0.1,2.4-0.2,3.5c-0.2,3.3-0.5,6.6-0.7,9.8
      v0.1c-0.2,2.5-0.4,5-0.5,7.5c-0.2,3.3-0.4,6-0.6,8.5c0,0.4,0,0.9,0,1.3v0.1l-0.3-0.2c-0.6-0.4-1.1-0.7-1.6-1.1
      c-0.6-0.4-1.2-0.9-1.9-1.3c-1.5-1-3-2-4.4-3.1c-1.3-1.1-2.6-2.2-3.8-3.4c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.2-0.6-0.4-1-0.6
      c-1.2-0.4-2-1.5-2-2.9c-0.1-2-0.2-4-0.3-6v-0.1C755.7,832.6,755.7,831.6,755.6,830.6z M758,753.6c-0.3-2.4-0.6-4.8-0.9-7.2
      l-0.1-0.7c-0.3-2.4-0.5-5-0.5-7.4c0-1.5,0.3-3.1,0.5-4.7c0.1-0.6,0.2-1.1,0.3-1.7c0.1-0.6,0.2-0.7,0.7-0.8c1.6-0.1,3.3-0.3,4.9-0.5
      c1.2-0.1,2.6-0.3,4-0.4c1.9-0.2,4-0.3,6.2-0.4c1,0,2.1,0,3.1,0c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.8,0.1,1.1v0.2
      c0.1,1.1,0.2,2.1,0.3,3.1c0,0.4,0,0.9,0,1.4v0.1c0,0.3,0,0.7,0,1v1.2c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2.1,0.4,3
      c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4c0.2,2.4,0.5,4.9,0.8,7.4c0.5,3.4,1.1,6.8,1.7,10.2l0.1,0.3c0.1,0.5,0,0.9-0.4,1.4
      c-1.6,1.9-3.6,3.4-5.5,4.8l-0.1,0.1c-0.5,0.4-1.1,0.8-1.6,1.2c-1.6,1.2-3.1,2.5-4.6,3.8l-0.2,0.1c-0.5,0.4-1,0.8-1.4,1.2
      c-1,0.9-2,1.7-3.1,2.6l-1.2,1.1c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-0.9-0.4-1.7-0.6-2.6l-0.5-2.6c-0.7-3.7-1.3-7.6-1.9-11.3
      c-0.4-2.7-0.8-5.5-1.1-8.2l-0.1-0.4c-0.1-1-0.2-2-0.4-3C758.3,755.5,758.1,754.6,758,753.6L758,753.6z M730.5,691.2
      c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8c-0.5-3.7-1.1-7.5-1.7-11.1v-0.3c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.1-0.9-0.3-1.3
      c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4c0.5-0.1,1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6c0.1,0,0.1,0,0.2,0
      c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.7c0.1,2.5,0.3,4.9,0.5,7.4v0.1c0.1,1,0.2,1.9,0.2,2.9
      c0.1,1.4,0.2,2.7,0.3,4.1v0.1c0.3,3.5,0.6,7.2,0.9,10.8c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5
      c0.1,1.1,0.2,2.1,0.3,3.1c-0.2-0.7-0.5-1.4-0.7-2c-0.3-0.9-0.7-1.8-0.9-2.8c-0.9-4-1.7-7.9-2.3-11.5c-0.5-2.9-1.2-6.7-1.8-10.5
      c-0.2-1.4-0.4-2.9-0.5-4.3v-0.1C730.7,693.3,730.6,692.2,730.5,691.2z M742.9,572.9l0.1,0.2c0.4,3.1,0.9,6.4,1.5,9.5
      c0.7,3.2,1.5,6.3,2.2,9.5c0.3,1.3,0.6,2.6,1,3.9c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5,5.3v0.1c-1.4,1.5-2.7,2.9-4,4.3
      c-1.5,1.6-3,3.1-4.4,4.5c-1.5,1.5-3.1,3.1-4.6,4.7c-1.2,1.2-2.3,2.5-3.4,3.7l-0.1,0.2c-0.5,0.5-1,1.1-1.5,1.6
      c0,0.1-0.1,0.1-0.2,0.2l-0.9-6c-0.5-3.4-1-6.6-1.4-9.9c-0.2-1.3-0.4-2.5-0.5-3.8c-0.4-2.6-0.7-5.4-1.2-8.1
      c-0.3-1.5,0.4-2.5,1.2-3.6c0.1-0.2,0.3-0.4,0.4-0.6c1.9-2.8,3.7-5.6,5.3-7.9c1.6-2.4,3.5-5.2,5.3-7.9l0.7-1
      c1.7-2.4,3.4-4.9,5.2-7.2c0.8-1,1.7-1.9,2.6-2.9c0.3-0.3,0.6-0.6,0.9-0.9c0.1,0.9,0.2,1.9,0.3,2.8v0.1
      C742.3,567.9,742.6,570.4,742.9,572.9z M783.3,544.9c0.1,2.2,0.3,4.5,0.5,6.7c0.2,2.9,0.4,5.8,0.6,8.8c0.1,2.5,0.2,5,0.3,7.4v0.2
      c0.1,1.8,0.1,3.6,0.2,5.3c0,0.8,0.1,1.6,0.2,2.3H785c0.1,0.6,0.1,1.2,0.2,1.9c0,0.2-0.1,0.5-0.1,0.6c-2.6,1.6-5.9,3.6-9.1,5.4
      c-2.1,1.2-4.2,2.3-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3c-1.5,0.8-3.1,1.7-4.6,2.5c-0.6,0.4-1.3,0.7-2,1.1
      l-1.1,0.6c-0.1-0.5-0.3-1.1-0.4-1.6v-0.1c-0.4-1.6-0.9-3.2-1.3-4.9l-0.2-0.7c-0.7-2.8-1.3-5.7-1.8-8.7c-0.5-3.3-1-6.6-1.4-9.8v-0.1
      c-0.2-1.3-0.4-2.7-0.5-4c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.3-1.6c-0.4-1.6,0.1-2.8,1.7-4c1.3-0.9,2.7-2,4-2.9
      c1.3-1,2.6-2,3.9-2.9c1.3-0.9,2.7-1.8,4.1-2.7h0.1c0.7-0.5,1.5-0.9,2.2-1.4c1.1-0.7,2.2-1.4,3.3-2.2l0.1-0.1c2-1.3,4-2.7,6.1-3.9
      c1.8-1.1,3.7-2.2,5.6-3.2c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5c0.2-0.1,0.4-0.2,0.7-0.4c0,0.6,0,1.2,0.1,1.8v0.2
      C783.2,541.4,783.2,543.2,783.3,544.9z M825.9,570.8c0,0.7-0.1,0.8-0.9,0.8c-0.9,0.1-1.8,0.2-2.7,0.2c-3,0.3-6.2,0.5-9.2,0.9
      c-5.3,0.7-10.9,1.6-17.7,2.7c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5l-2.3-41.1l41-10.3v3c0,2.2,0,4.4,0,6.6
      c0,0.5,0,1.1,0,1.7s0,1.2,0,1.9c0,1.6,0,3.1-0.1,4.7c0,2-0.1,3.9-0.1,5.7C825.9,556.1,825.9,563.4,825.9,570.8z M933.9,641.3
      c-0.5,2.3-1,4.6-1.5,6.9c-0.2,1-0.6,2.1-0.9,3s-0.6,2-0.9,3c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2
      c-0.3,0-0.8-0.1-1.3-0.2c-0.2-0.1-0.5-0.1-0.8-0.2c-0.5-0.1-0.9-0.3-1.4-0.5l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2
      c1.1-4.5,2.2-8.6,3.2-12.7c0.5-1.8,0.9-3.5,1.4-5.3c0.8-3.2,1.8-6.8,2.6-10.3c0.4-1.7,0.7-3.4,1-5.1v-0.2c0.1-0.8,0.3-1.6,0.4-2.4
      c0.1-0.6,0.2-1.2,0.4-1.9l0.1-0.5l0.3,0.4c0.6,0.7,1,1.3,1.5,1.9c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.8,0.8,1.1
      c1.1,0.8,1,1.7,0.7,3.3c-0.6,2.5-1.1,5.1-1.6,7.4L933.9,641.3z M934.2,652.8c1.5,1.8,2.8,3.4,4.1,4.9c0.2,0.3,0.2,0.6-0.2,1.3
      c-2.3,4.4-4.7,9.1-7.5,14.8c-0.6,1.2-1.1,2.3-1.6,3.5v0.1c-0.3,0.7-0.6,1.4-1,2.2c0,0.1-0.1,0.1-0.1,0.2l5.5-27.7
      C933.7,652.4,934,652.6,934.2,652.8z M849.4,782.3c-0.2-0.8-0.4-1.7-0.5-2.6c-0.2-1-0.4-2.1-0.7-3.2c-0.3-1.2-0.7-2.4-1.1-3.6
      l-0.3-0.2c-0.1-0.4-0.3-0.8-0.4-1.2c-0.2-0.7-0.2-1,0.3-1.5c1.4-1.3,2.8-2.6,4.1-3.8l0.2-0.2c0.8-0.8,1.6-1.5,2.4-2.2
      c0.8-0.8,1.7-1.5,2.5-2.2h0.1c0.6-0.5,1.2-1.1,1.8-1.6c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4c1.9,1.9,3.9,3.7,6,5.6
      c1.5,1.3,2.9,2.5,4.4,3.7c0.6,0.5,1.1,0.9,1.7,1.4c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1v-0.1
      C850,784.5,849.6,783.4,849.4,782.3z M749,602.9l0.1,0.5c0.5,3.4,1,7,1.5,10.5c0.5,4,1.1,8,1.6,11.9l0.1,0.7c0.2,1.5,0.4,3,0.5,4.6
      v0.3c0.1,1,0.1,1.9-0.3,2.8c-0.5,1.2-0.8,2.5-1.1,3.8c-0.1,0.5-0.2,0.9-0.3,1.4l-0.4,1.4c-0.6,2.4-1.2,4.9-1.7,7.3
      c-0.2,1-0.4,1.9-0.6,2.9c-0.2,0.9-0.3,1.9-0.6,2.9c-0.1,0.5-0.4,1-0.8,1.4c-1.2,1.2-2.5,2.3-3.7,3.4l-0.1,0.1
      c-1,0.9-2.1,1.9-3.1,2.9c-1.3,1.3-2.6,2.5-4,3.9c-0.5,0.5-1,0.9-1.5,1.4l-0.9-3.6c-0.5-1.8-0.9-3.6-1.4-5.4c-0.4-1.7-0.8-3.3-1.3-5
      c-0.7-2.6-1.4-5.3-2-8c-0.9-3.5-1.5-6.2-2.1-8.7c-0.3-1.4-0.6-2.7-0.9-4.3c-0.4-1.8-0.8-3.7-1.2-5.5c-0.2-1.1,0.1-2.1,1-3
      c1.5-1.5,3-3.1,4.2-4.4l0.2-0.2c1.3-1.4,2.7-2.8,4-4.2s2.7-2.7,4-4.1c1.7-1.7,3.5-3.6,5.3-5.5c1.1-1.1,2.2-2.4,3.2-3.5l0.1-0.1
      c0.5-0.6,1-1.2,1.6-1.8l0.1-0.1c0,0.2,0.1,0.5,0.1,0.7v0.1C748.8,601.3,748.9,602.1,749,602.9z M787.2,617.3c-0.2,0-0.3,0-0.5,0
      c-0.9,0-1.9,0-2.8-0.1c-1.9,0-3.9-0.1-5.9-0.1h-0.1c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5
      c-0.7,0.9-1.4,1.7-2.2,2.5c-0.6,0.6-1.2,1.3-1.8,2c-0.9,1.1-1.7,2.2-2.6,3.4c-0.5,0.7-1,1.3-1.5,2c-0.6,0.8-1.2,1.5-1.8,2.2
      l-0.5,0.6l-0.1-1.2c-0.1-1.2-0.3-2.4-0.4-3.7c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.9-0.4-3.9-0.7-5.9c-0.5-3.7-1-7.5-1.4-10.7
      c-0.3-2.3-0.7-4.4-1-6.4v-0.1c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.5-3.5l0.2-0.1c1.2-0.6,2.3-1.3,3.5-1.9
      c1.1-0.6,2.2-1.2,3.4-1.8c1.5-0.8,3.1-1.7,4.7-2.6c1-0.5,2-1.1,3-1.6h0.1c1.4-0.7,3-1.5,4.5-2.4c1.5-0.8,3-1.8,4.4-2.6
      c0.7-0.4,1.3-0.8,2-1.2c0.4-0.3,0.9-0.5,1.4-0.8l0.4-0.2l2,37.2L787.2,617.3z M788,620.2l-1.4,17.2l-0.4,5.1c-0.2,0-0.5,0-0.7,0
      c-0.4,0-0.7,0-1.1,0c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7H770c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1
      c-2.9-1.9-5.8-3.9-8.7-5.9c-0.9-0.6-1.8-1.3-2.7-1.9c-0.3-0.2-0.6-0.5-0.9-0.7c0.4-0.6,0.9-1.1,1.3-1.7c1.1-1.5,2.2-2.9,3.4-4.3
      c1.1-1.3,2.3-2.7,3.5-3.9c0.8-0.9,1.7-1.9,2.5-2.8c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0
      c2.2-0.1,4.2-0.1,6.1-0.1c4.2-0.1,7.9-0.1,11.3-0.1c0.3,0,0.6,0.1,0.9,0.1H788L788,620.2z M753.3,639.4c0.2-1.1,0.5-2.1,0.8-3.2
      v-0.1c0.1-0.4,0.2-0.7,0.3-1.1l13.7,9.4l-3.7,3.7c-0.9,0.8-1.7,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.9l1.2,1
      c1.7,1.5,3.5,3.1,5.3,4.6c0.6,0.5,1.2,0.9,1.8,1.3c0.2,0.1,0.3,0.2,0.5,0.3l-4.3,7.9c-0.2-0.1-0.5-0.3-0.7-0.4
      c-3.5-2.6-7.2-5.3-10.9-8.1c-1.8-1.4-3.4-2.6-4.8-3.7c0,0-0.1-0.3-0.1-0.5C751.1,649.4,752.2,644.4,753.3,639.4z M772.3,661.1
      c0.1-0.1,0.4-0.3,0.5-0.3c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2c0.6,0,1.2,0,1.8,0c0.8,0,1.5,0,2.3,0c1,0,2,0,3,0
      c0.6,0,1.1,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0.1v9.7l-18.6-2.5l0.7-1.3C769.9,665.3,771.1,663.1,772.3,661.1z M766,683
      c0.1-0.9,0.2-1.8,0.4-2.7c0.2-1.6,0.6-3.1,0.9-4.4c0.1-0.6,0.2-1.1,0.4-1.7l0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1c0-0.1,0.3-0.3,0.4-0.3
      h0.1c3.1,0.3,6.5,0.7,9.9,1.1c1.5,0.2,3,0.5,4.5,0.8c1,0.2,2,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1c0.1,3.3,0.2,6.3,0.4,8.9
      c0.1,1.3,0.2,2.7,0.4,3.9c0.1,0.9,0.2,1.7,0.3,2.6c0.1,0.7,0.1,1.3,0.1,2.1v0.3c0,0.2,0,0.4,0,0.6l-24.9,6.7c0-0.2,0-0.4,0.1-0.6
      c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.6,0.6-3.2,0.9-4.8v-0.1c0.4-2.1,0.8-4.3,1.2-6.4C765.8,684.9,765.9,683.9,766,683z M786.7,658.9
      c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3c-1.5,0-3.1-0.1-4.6-0.3h-0.1c-0.4,0-0.7-0.1-1.1-0.1c0,0,0-0.1-0.1-0.1
      c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.3-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.5-0.1c-1.9-1.6-3.8-3.2-5.7-4.8l-1.4-1.2
      l0.4-0.3c0.4-0.3,0.7-0.6,1-0.9c0.4-0.3,0.7-0.6,1.1-0.9l0.2-0.1c1.1-0.9,2.2-1.9,3.2-2.9c1.5-1.5,3.4-1.5,5.2-1.5h0.3
      c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.4,0.5,3.6,1.5c1,0.8,2,1.6,3,2.4c0.5,0.4,1.1,0.9,1.6,1.3l0.7,0.1
      c0.8,0.6,1.5,1.2,2.3,1.9c0.2,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.5,0.9c-2.5,2.5-5.2,5-9.2,5c-0.2,0-0.4,0-0.6,0
      C788.1,658.9,787.4,658.9,786.7,658.9z M809.2,648.8l-5.4,5.7c-0.5-0.5-0.9-0.9-1.4-1.4c-1.2-1.3-2.5-2.6-3.9-3.8
      c-2-1.7-4.2-3.5-7.2-5.7c-0.6-0.4-1.3-0.7-2.1-0.9c-0.3-0.1-0.5-0.2-0.8-0.3L790,620c0.1,0,0.3,0.1,0.4,0.1h0.1
      c0.6,0.2,1.2,0.4,1.7,0.7c0.9,0.5,1.9,0.9,2.8,1.3c2.8,1.3,5.7,2.7,8,5c3.6,3.7,6.3,7.3,8.4,10.9c0.6,1,1.3,2,1.9,2.9
      c0.3,0.5,0.7,1,1,1.5c0.1,0.1,0.2,0.3,0.2,0.4L809.2,648.8z M815.5,645.5c0.1-0.1,0.2-0.2,0.3-0.3l0.1,1.1c0.1,1.2,0.2,2.4,0.2,3.6
      c0.2,2.3,0.5,5.4,0.8,8.4c0,0.6-0.1,0.8-0.6,1.1c-1.7,1-3.4,2.1-5,3.1l-0.2,0.1c-1.3,0.8-2.7,1.7-4,2.5c-0.5,0.3-1.2,0.6-1.8,0.9
      h-0.1c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1-0.6-0.2-1.1-0.4-1.6c-0.4-1.6-0.7-3-0.8-4.5c-0.1-1,0.4-2.3,1.2-3.2c2.3-2.7,4.8-5.3,7.2-7.8
      l0.5-0.6c0.5-0.5,1-1,1.5-1.5S814.9,646,815.5,645.5z M801.5,658.4c-0.1,0.2-0.5,0.5-0.7,0.5c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1
      l3.5-3.3C802.2,656.1,802.3,656.7,801.5,658.4z M788.5,669.7c0.3-2.7,0.2-5.4,0.1-7.7c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1
      c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1h0.1c1.7,0.4,3.3,0.7,5.1,1.1l1.5,0.3l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.2
      c0,0.1-0.2,0.4-0.3,0.5c-0.7,0.3-1.4,0.6-2.1,0.9c-2.9,1.2-5.9,2.4-8.9,3.5c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1
      C788.4,671.7,788.5,670.5,788.5,669.7z M798.6,671.3c1.3-0.6,2.8-1.2,4.3-1.8l3.6,11.7l-16.2,10.5l-1.5-17.2
      C792.3,674,795.5,672.6,798.6,671.3z M816.7,755.4h0.2c1.8-0.1,3.7-0.2,5.6-0.2c1.5,0,3,0,4.5,0.1c1.1,0,2.3,0.1,3.5,0.1h0.2
      c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3c2.1,0.1,4.2,0.1,6.2,0.6c0.7,0.2,1.4,0.2,2.2,0.2c1.5,0,3-0.3,4.4-0.6
      c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1c-0.5,0.5-1,1-1.5,1.4
      c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4c-2.6,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8c-1.7,0-3.4,0.1-5.1,0.2h-0.2
      c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1s-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6c-0.4,0-0.8-0.3-1.4-0.8
      c-0.8-0.8-1.7-1.6-2.5-2.4c-1.2-1.2-2.4-2.4-3.7-3.5c-1.7-1.5-3.5-3-5.2-4.4c-1.3-1.1-2.3-1.9-3.3-2.7c-0.6-0.5-1.1-1-1.6-1.6
      c0.7,0.1,1.4,0.2,2.1,0.4c0.9,0.2,1.7,0.3,2.6,0.5h0.1c2.4,0.5,4.9,0.9,7.3,1.6c0.6,0.2,1.2,0.2,1.9,0.2c0.9,0,1.7-0.1,2.5-0.2
      c0.7-0.1,1.4-0.2,2.2-0.2C813.6,755.6,815.2,755.5,816.7,755.4z M815,752.6c0.8-0.1,1.7-0.5,2.5-0.8c0.1,0,0.1,0,0.2-0.1
      c0.1,0,0.3-0.1,0.5-0.2c0.8-0.5,1.6-0.8,2.4-0.8c1.2,0,2.3,0.5,3.6,1c0.2,0.1,0.3,0.1,0.5,0.2c0.7,0.3,1.3,0.6,2.1,0.6
      c1,0,2-0.3,3-0.7l0.2-0.1c0.7-0.2,1.4-0.5,2.1-0.8h0.1c0.4-0.2,0.7-0.3,1.1-0.5c0.1-0.1,0.3,0,0.4,0c1.7,0.7,3.5,1.4,5.3,2.1h-2.8
      c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1c-1.3-0.1-2.7-0.1-4.4-0.1
      c-1.1,0-2.1,0-3.3,0C816.7,752.6,815.8,752.6,815,752.6z M858.3,751.5c-1,0.2-2.1,0.4-3.1,0.6l-1.5,0.3c-1.8,0.3-3.6,0.7-5.4,1
      c-0.4,0.1-0.8-0.1-1.3-0.2c-1.7-0.6-3.1-1.1-4.4-1.5c-2.6-1-5.1-2-7.4-2.9c-0.6-0.2-1.2-0.4-1.8-0.4c-0.7,0-1.5,0.2-2.1,0.6
      c-2.7,1.5-5.5,1.6-8.2,0c-0.7-0.4-1.4-0.6-2.1-0.6c-1.1,0-2.2,0.4-3.2,0.9c-0.4,0.2-0.7,0.3-1.1,0.4c-1.7,0.6-3.4,1.2-5.2,1.9
      l-0.7,0.2c-0.5,0.2-1,0.4-1.6,0.6c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.5-1.5,0.8-2.5,0.8c-0.8,0-1.6-0.2-2.5-0.3
      c-0.2,0-0.4-0.1-0.6-0.1c-1.8-0.4-3.7-0.7-5.3-1h-0.3c-0.8-0.1-1.6-0.3-2.5-0.4c-0.1,0-0.2,0-0.4-0.1l0.7-0.3
      c1.2-0.6,2.1-1.1,3.1-1.6l1.8-0.9c2-1,4-2,6.1-3c2-1,3.9-1.9,5.9-2.8c0.9-0.4,1.7-0.8,2.6-1.2c0.3-0.1,0.5-0.2,0.8-0.4
      c0.7-0.3,1.3-0.6,2-1c0.4-0.3,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,1.9,0.4l1,0.3c1.9,0.6,3.8,1.2,5.8,1.3c1.5,0.1,3.2-0.4,5.1-1.3
      c0.8-0.4,1.6-0.6,2.3-0.6c0.9,0,1.7,0.2,2.8,0.7c1.8,0.9,3.6,1.8,5.4,2.6h0.1c1.5,0.7,3.2,1.5,4.9,2.4c2.3,1.1,4.6,2.3,6.8,3.4
      c1.1,0.6,2.2,1.1,3.3,1.7c0.4,0.2,0.8,0.4,1.1,0.6L858.3,751.5z M850.3,711.5l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.8,0.6-1.2
      c0.7-1.4,1.4-2.7,2.1-4c0.9-1.3,2.2-1.7,3.5-2.2C849.7,711.8,850,711.7,850.3,711.5z M848.1,710.2c-1,0.5-1.9,1-2.9,1.3
      c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-2.2-0.3-4.3-0.7-6.5-1l-2.2-0.3c0-0.4,0.3-0.7,0.9-1
      c1.4-0.6,2.9-1.3,4.3-1.9h0.1c1.2-0.5,2.5-1.1,3.7-1.6c0.8-0.3,1.4-0.2,2,0.4c0.9,1.1,1.9,2.1,2.8,3.1l0.9,1
      C848.7,709.9,848.4,710.1,848.1,710.2z M844,713.6l-0.6,1c-0.9,1.7-1.7,3.2-2.5,4.7c0,0-0.1,0.1-0.2,0.1c-2.3,0.2-4.5,0.4-7,0.6
      l-1.1,0.1l1.5-8.1L844,713.6z M833.3,728.1L833.3,728.1c0.3,2.1,0.7,4.2,1,6.3c0.1,0.7,0.2,1.5,0.2,2.3c0,0.3,0,0.6,0.1,1
      c-0.6,0.2-1.1,0.3-1.7,0.5l-0.2,0.1c-1.8,0.6-3.5,1.1-5.3,1.4c-0.2,0-0.4,0.1-0.6,0.1c-0.8,0-1.7-0.3-2.6-0.5l-0.2-0.1
      c-1.1-0.3-2.2-0.7-3.4-1.2l-0.2-0.1c-0.4-0.1-0.7-0.3-1.1-0.4l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4c0.1-0.9,0.3-1.8,0.4-2.6v-0.1
      c0.2-1.4,0.4-2.9,0.7-4.3c0.2-1,0.4-1,0.6-1c0.2,0,0.5,0.1,0.8,0.2c3,0.7,5.7,0.7,8.2-0.1c0.4-0.1,0.6-0.2,0.8-0.2
      c0.2,0,0.5,0,0.6,1.1C832.8,724.8,833,726.5,833.3,728.1z M821.1,720l-0.7-0.1c-1.4-0.1-2.9-0.2-4.3-0.3H816
      c-0.1,0-0.2-0.1-0.3-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3v-0.1c-0.2-0.4-0.4-0.7-0.6-1.1
      l9.8-1.6l1.5,8.1H821.1z M811.1,719.4l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1c-0.9-2.4-1.6-4.5-2.2-6.6
      c1.7,0.5,3.3,0.9,4.2,2.4c0.8,1.3,1.4,2.6,2.1,4C810.7,718.6,810.9,719,811.1,719.4z M818.6,710.1c-2.5,0.4-4.8,0.8-7.1,1.1
      c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.3,0.1-0.4,0.1h-0.4c-0.6,0-1.2,0-1.7-0.2c-0.8-0.3-1.5-0.6-2.3-1c-0.2-0.1-0.4-0.2-0.7-0.3
      c0.3-0.3,0.6-0.6,0.8-1c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1c2.5,1,5.2,2.2,8.3,3.6
      c0.2,0.1,0.5,0.4,0.7,0.8c0,0,0,0.1,0.1,0.1L818.6,710.1z M811.4,697.6L811.4,697.6l-0.1,0.1c-0.1,1-0.3,2.1-0.6,3
      c-0.2,0.5-0.7,1-1.2,1.4c-0.3,0.3-0.6,0.5-0.9,0.9c-2.4,2.8-4.3,4.9-5.9,6.8c-0.5,0.6-0.9,1.3-0.4,2.5c0.6,1.5,1.1,3.1,1.6,4.7
      c0.3,1,0.6,1.9,0.9,2.7c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.6,1.6-3.8,2.4l-1.1,0.7l-1-1.4c-1-1.5-2-3-3-4.5l-0.4-0.6
      c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1c1.9-1.7,3.3-3,4.6-4.3c1.9-1.8,3.8-3.6,5.6-5.4l0.3-0.3c1.2-1.1,2.3-2.2,3.5-3.4
      c0.9-0.9,1.8-1.7,2.7-2.5l1-1C811.5,697.1,811.4,697.4,811.4,697.6z M800.7,725.2c0.7-0.4,1.3-0.9,2-1.4c1.2-0.8,2.4-1.7,3.6-2.4
      c0.9-0.3,1.9-0.3,2.7-0.3c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4l1,0.1c1,0.1,2,0.2,3,0.3c0.3,0,0.7,0.1,1,0.1l-0.2,1.4
      c-0.2,1.3-0.4,2.7-0.6,4c-0.1,0.8-0.3,1.6-0.4,2.4v0.2c-0.4,2.3-0.8,4.7-1,7c-0.1,1.1-0.5,1.3-1.3,1.6c-0.4,0.2-0.8,0.3-1.3,0.5
      c-0.9,0.3-1.8,0.7-2.6,1.1c-0.3,0.2-0.6,0.2-1,0.2c-0.3,0-0.6-0.1-1-0.1c-0.3-0.1-0.7-0.1-1.1-0.1h-0.1c0,0-0.1-0.1-0.3-0.3
      c-1.2-1.9-2.3-3.8-3.5-5.7c-1.6-2.7-3.2-5.3-4.8-8C800.3,725.7,800.3,725.5,800.7,725.2z M845.7,739.7c-0.3,0.5-0.5,0.6-1.3,0.9
      c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0-1.6-0.4-2.4-0.8l-0.4-0.2c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4
      c-0.4-0.1-0.5-0.3-0.5-0.9c0-1.5-0.3-3.1-0.5-4.6v-0.2c-0.4-2.4-0.8-5-1.2-7.1l-0.1-0.7c-0.2-0.9-0.3-1.9-0.5-2.8
      c0-0.1,0-0.1,0-0.2l2.5-0.2c2.2-0.2,4.3-0.3,6.4-0.4c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1
      c0.8,0.5,1.5,1,2.4,1.6l0.1,0.1c0.9,0.6,1.8,1.2,2.7,1.8c0.5,0.3,0.5,0.5,0.2,0.9c-2.2,3.6-4.2,6.8-6.1,9.8c-0.6,1-1.2,2.1-1.8,3.2
      L845.7,739.7z M849.9,720l0.6-1.7c0.6-2,1.3-4.1,1.9-6.2c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2
      c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.5-1.9-2.5-2.6c-1.7-1.3-2-3-2.3-4.9c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3
      c0.2,0.1,0.3,0.3,0.4,0.4c1.3,1.2,2.6,2.5,3.9,3.7c2.3,2.2,4.5,4.4,6.8,6.6l0.1,0.1c1.8,1.7,4,3.8,6.2,5.7c0.7,0.6,0.7,1,0.1,1.7
      c-1.5,2.2-2.9,4.2-4.2,6.1c-0.4,0.6-0.7,1.2-1.1,1.8c-0.1,0.1-0.1,0.3-0.2,0.4L849.9,720z M861.1,711c-1.5-1.3-2.9-2.5-4.3-3.8
      c-0.8-0.7-1.5-1.5-2.3-2.3l-0.1-0.1c-0.6-0.7-1.3-1.4-2-2c-2.6-2.5-5.3-5-8.1-7.5c-1.2-1.1-1.9-2.4-2.3-4c-0.6-3-1.4-6-2.1-9
      c-0.3-1.2-0.6-2.4-0.9-3.5c-0.1-0.5-0.3-1-0.4-1.6l-0.1-0.5l0.1,0.1c0.8,0.6,1.7,1.3,2.5,1.9l0.1,0.1c1.8,1.4,3.7,2.9,5.7,4.2
      c2.1,1.5,4.4,3,6.5,4.2c0.8,0.5,1.6,1,2.4,1.5c0.8,0.6,1.7,1.2,2.6,1.7c1.9,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.4,0.6,0.4,1
      c-0.3,4.1-0.6,8.1-0.9,12.1v0.1c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8C862.2,711.9,861.6,711.5,861.1,711z M842.1,702.9
      c0,0.2,0.1,0.4,0.2,0.6v0.1c-0.5,0.2-0.9,0.4-1.4,0.6h-0.1c-1.3,0.6-2.6,1.2-3.9,1.7c-0.5,0.2-1,0.4-1.5,0.6
      c-0.6,0.2-1.2,0.4-1.9,0.7c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1s-0.3,1.9-0.5,2.8c-0.4,1.7-0.8,3.7-1.3,5.6
      c-0.1,0.2-0.6,0.5-0.9,0.6c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1c-0.3-1.5-0.5-2.9-0.9-4.5V715c-0.2-1.2-0.5-2.5-0.7-3.7
      c-0.7-4-0.9-4.2-4.9-5.3c-1.1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2c-0.5-0.2-1-0.5-1.4-0.8c-0.1-0.1-0.2-0.1-0.4-0.2c0-2,1.6-2.7,3.2-3.4
      c0.8-0.4,1.9-0.4,2.9-0.5c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.1,1.9-0.2c1.7-0.2,3.4-0.4,5.1-0.5c1.1,0,2.2,0.1,3.4,0.2
      c0.5,0.1,1,0.1,1.5,0.2l2,0.2c1.6,0.2,3.2,0.3,4.9,0.4C839.7,700,841.8,701.8,842.1,702.9z M813.1,689c-0.5,2.8-1.3,5.1-3.7,7
      c-2.6,2.1-5,4.5-7.4,6.9c-1.1,1.1-2.2,2.2-3.3,3.2c-1.5,1.5-3.1,2.9-4.6,4.3l-0.1,0.1c-0.7,0.6-1.3,1.3-2,1.9
      c0-0.3-0.1-0.5-0.1-0.8v-0.2c-0.1-1-0.3-1.9-0.4-2.9c-0.2-2.2-0.4-4.6-0.5-6.6v-0.6c-0.1-1-0.1-2-0.2-2.9c0-0.4-0.1-0.8-0.1-1.2
      c-0.1-0.8-0.2-1.6-0.2-2.4c0-0.3,0.3-0.8,0.6-1c2-1.3,4.1-2.6,5.8-3.7l0.1-0.1c3-1.9,6-3.8,9-5.8c2.3-1.6,4.6-3.3,6.8-5l0.1-0.1
      c1-0.7,1.9-1.5,2.9-2.2c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3-0.2,0.6-0.2,0.9c-0.3,1.1-0.6,2.3-0.9,3.5l-0.3,1.2l-0.1,0.1h-0.1
      C814.1,684.5,813.5,686.8,813.1,689z M791.3,715.8c0.4,0.5,0.7,1.1,1.1,1.6l0.3,0.4c1.2,1.7,2.2,3.2,3.2,4.9c0.9,1.5,1.8,3,2.6,4.5
      c0.6,1.1,1.3,2.3,2,3.5c0.7,1.1,1.4,2.3,2.1,3.4l0.1,0.1c0.5,0.8,1,1.5,1.5,2.3c1,1.5,1.9,3.1,2.8,4.6c0.3,0.6,0.4,0.9,0.3,1.2
      c-0.1,0.3-0.4,0.5-0.9,0.8l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.5-0.2l-1-1.4
      c-1.6-2.4-3.3-4.8-4.8-7.3c-0.5-0.9-0.9-2-1.3-3.1c-0.2-0.6-0.4-1.3-0.7-1.9c-0.9-2-1.8-4.2-3.1-6.9c-0.4-0.8-0.4-1.2,0.3-1.9
      c1.2-0.8,2.1-2,2.9-3.1c0.6-0.8,1.3-1.7,2-2.5c1.4-1.5,2.8-3,4.2-4.5l0.1-0.1C790.3,716.9,790.8,716.3,791.3,715.8z M781.7,737.9
      c0.3,0.6,0.6,1.2,0.8,1.9l0.1,0.1c1,2.2,1.9,4.4,3.1,6.5c0.9,1.6,2,3,3.1,4.4c0.5,0.6,1,1.3,1.5,1.9l0.1,0.1
      c-0.2,1.2,0.6,1.8,1.1,2.2c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7,1.4,1.3,2.1,1.9l0.3,0.3c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4
      c-0.8,0.7-1.6,1.5-2.3,2.2l-0.1,0.1c-1.2,1.1-2.5,2.3-3.8,3.5c-1.3,1.1-2.6,2.2-3.8,3.2c-0.4,0.4-0.9,0.7-1.3,1.1l-0.4-2.8
      c-1.7-11.3-3.2-22-3.2-33.1c0.1,0.3,0.2,0.7,0.4,1.1C780.5,735.1,781.1,736.5,781.7,737.9z M784.7,770.1l0.7-0.6
      c0.7-0.6,1.4-1.2,2.1-1.8c0.5-0.4,1-0.8,1.4-1.2l0.2-0.2c1.2-1,2.4-2.1,3.6-3.1c0.9-0.8,1.7-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-1
      c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.5,0.3l2.4,2.2c1.4,1.3,2.9,2.7,4.4,4c0.5,0.5,1.1,1,1.7,1.6l0.1,0.1
      c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5l-0.4,1.2c-0.6,1.8-1.2,3.8-1.7,5.8c-0.4,1.5-0.7,2.9-1.1,4.3
      c-0.2,0.7-0.3,1.4-0.5,2.1c-0.1,0.4-0.2,0.9-0.3,1.4l-0.1,0.6l-20.3-15.9L784.7,770.1z M806.5,786.5c0.3-1.3,0.6-2.5,0.9-3.7
      c0.2-1,0.5-2,0.7-3c0.3-1.4,0.7-3.1,1.1-4.8c0.3-1.1,0.6-2.2,0.9-3.3c0.1-0.3,0.2-0.5,0.7-0.5h0.1c3.6,0.1,7.2,0.2,10.8,0.2h1.7
      c0.9,0,1.9,0,2.8,0.1h0.1c2.9,0.1,5.9,0.1,8.8,0.1c2.4,0,5-0.1,8.4-0.4h0.1c0.5,0,0.6,0.2,0.7,0.8v0.1c0.7,2.9,1.5,5.9,2.3,8.9
      c0.4,1.5,0.7,3,1.1,4.6l0.5,2.2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.7,0c-0.7,0-1.5,0-2.2,0
      c-3.3,0-6.8,0.2-10.3,0.6c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3c-2.3-0.3-4.8-0.4-8-0.4c-0.9,0-1.9,0-2.8,0
      c-1.4,0-2.8,0-4.3,0h-0.5c-0.4,0-0.7,0-1.1,0L806.5,786.5z M868.6,766.4c-2.1-1.9-4.3-3.9-6.3-5.7l-2.5-2.2
      c-0.2-0.2-0.2-0.3-0.2-0.4s0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5c0.4-0.4,0.8-0.8,1.2-1.1c0.1-0.1,0.2-0.2,0.3-0.2
      c0.5-0.5,1.2-1,1-2.1c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.5,0.8-1.1,1.2-1.6c1-1.3,2-2.7,2.9-4.1
      c1.1-1.7,1.9-3.6,2.7-5.5c0.9-2,1.8-4.1,2.6-6.1l0.1-0.2c0.3-0.6,0.5-1.2,0.8-1.9c-0.1,11.7-1.8,23-3.5,35l-0.1,0.8
      c-0.2-0.2-0.4-0.3-0.6-0.5C869.9,767.5,869.3,766.9,868.6,766.4z M874.1,729.3c-0.3,0.7-0.6,1.3-0.9,2c-0.6,1.4-1.3,2.8-1.9,4.2
      c-0.3,0.8-0.7,1.6-0.9,2.4c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.6-1.8,3.2-2.8,4.8l-0.3,0.5c-0.4,0.7-0.9,1.3-1.3,1.9v0.1
      c-0.4,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.3-0.1-0.5-0.2c-2.9-1.5-5.8-3-8.6-4.4l-2.7-1.4
      c-0.9-0.5-1.8-0.9-2.8-1.4l-1.4-0.7c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5c0.7-1.2,1.4-2.4,2.1-3.4
      c1.2-1.9,2.4-3.8,3.4-5.8c2.6-4.7,5.5-9.2,8.6-13.8c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.5,1.1,0.9,1.6,1.5
      c1.8,2,3.6,4,5.3,6l0.8,1c0.3,0.4,0.6,0.8,0.9,1.1c0.6,0.7,1.2,1.5,1.8,2.1C874.4,728.5,874.4,728.8,874.1,729.3z M848,681.3
      l3.6-11.8c1.5,0.5,2.9,1.2,4.3,1.8c3.1,1.3,6.2,2.7,9.8,3.3l-1.4,17.2L848,681.3z M851.8,653.3L851.8,653.3
      c-0.5,0.5-0.8,0.9-1.2,1.2L839.7,643c0-0.1,0.1-0.1,0.1-0.2l0.8-1.3c1.3-2,2.6-4,4-6c2.3-3.4,5.2-7.3,9.2-10.2
      c1.4-1,3.1-1.8,4.6-2.6c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1c0.2-0.1,0.5-0.2,0.8-0.2c0,0,0,0,0.1,0l1.6,22.5
      c-2.1,0.1-3.6,1.3-4.9,2.6c-0.4,0.2-0.8,0.5-1.2,0.8l-0.2,0.2c-1.5,1.2-3.1,2.4-4.5,3.7C854,651,852.9,652.2,851.8,653.3z
       M857.2,658.3c-0.3,0-0.5,0-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4c-0.1,0-0.4-0.2-0.6-0.6c-0.4-0.7-0.5-1.5-0.2-1.9
      c0.2-0.4,0.6-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.2-0.2L857.2,658.3z M841.5,647.9c2.7,2.8,5.5,5.7,8,8.7c1.3,1.5,1.7,2.9,1.2,4.5
      c-0.4,1.2-0.5,2.4-0.7,3.8c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.1c-0.6-0.2-1.1-0.5-1.7-0.8
      c-1.6-0.9-3.6-2-5.6-3.2c-1.4-0.9-2.8-1.8-4.4-3c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.5,0.6-7,0.9-10.6l0.2-2.3c0.2,0.1,0.3,0.3,0.5,0.4
      l0.1,0.1C840.1,646.5,840.8,647.2,841.5,647.9z M865.2,672.2c-0.2,0-0.3,0-0.6-0.1c-1.8-0.6-3.5-1.1-5.2-1.8
      c-1.3-0.5-2.7-1.1-3.9-1.7l-0.2-0.1c-0.9-0.4-1.9-0.8-2.8-1.2c-0.4-0.2-0.5-0.3-0.4-0.8c0.2-1.3,0.4-2.6,0.6-3.9l0.2-1.1
      c0.5-0.1,1-0.2,1.5-0.3h0.2c1.5-0.3,3.1-0.7,4.7-1c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1c0.5,0.1,0.9,0.2,1.4,0.3h0.1
      c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2c0,1.7,0,3.5,0,5.2v0.1c0,0.8,0,1.6,0,2.3c0,0.2,0,0.4,0,0.5c0,0.4,0,0.8,0,1.2
      C865.7,672,865.6,672.2,865.2,672.2z M865.3,658.8L865.3,658.8c-2.7,0-5-0.9-7-2.8c-0.2-0.2-0.5-0.4-0.8-0.6
      c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4c0.9-0.7,1.9-1.5,2.8-2.2c1.2-1,2.4-2,3.6-2.9
      c1-0.7,2.1-1.2,3-1.2c4.3-0.3,8.8-0.3,12.9-0.4l0.3-0.1c2.5,0,4.5,0.9,6.1,2.6c1,1.1,2.1,2,3.3,3.1l0.1,0.1c0.4,0.3,0.8,0.7,1.2,1
      l-1.5,1.2c-1.6,1.3-3.1,2.6-4.7,3.8c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.3
      c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.2,0h-0.1c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1
      c-2.3,0-4.8,0-6.9,0C869.1,658.8,867.2,658.8,865.3,658.8z M866.7,620c0.1,0,0.2,0,0.3,0c0.5-0.1,1-0.2,1.5-0.2h0.1
      c2.3,0,4.6,0.1,6.5,0.2h0.4c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1c0.7,0,1.3,0.2,1.8,0.8
      c0.5,0.6,1.1,1.2,1.7,1.7c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.4c0.5,0.7,1,1.4,1.6,2.1c-0.6,0.4-1.2,0.9-1.8,1.3h-0.1
      c-1.6,1.1-3.2,2.2-4.8,3.3c-2.4,1.6-4.8,3.2-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1c-1.1-0.1-2.2-0.2-3.3-0.3
      c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1c-0.4,0-0.8,0-1.2,0h-0.2L866.7,620z
       M885.6,667.2l0.8,1.4l-18.6,2.5v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2
      c1.3-0.1,2.6-0.2,3.9-0.2c0.1,0,0.4,0.2,0.5,0.4C883.4,663.1,884.5,665.1,885.6,667.2z M867.1,685.9c0.2-2.5,0.3-5,0.4-7.3l0.1-2.2
      c0-0.2,0-0.5,0-0.7c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6c2.3-0.5,4.4-0.9,6.4-1.2c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1
      c0.7-0.1,1.6-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5c0.3,1.8,0.6,3.5,0.9,5.3v0.2c0.3,2,0.7,4.1,1,6.1
      c0.4,2.4,0.9,4.8,1.4,7.2c0.2,0.9,0.4,1.9,0.6,2.8c-0.5-0.1-0.9-0.2-1.3-0.3H890h-0.1c-1.1-0.3-2.2-0.5-3.4-0.8
      c-2-0.5-4.1-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1c-1.9-0.5-3.8-1-5.8-1.5c-0.8-0.2-1.2-0.5-1.1-1.4
      C866.8,690,866.9,687.9,867.1,685.9z M888.3,667.6l-4.5-8c0.1-0.1,0.3-0.2,0.5-0.3c0.6-0.4,1.2-0.9,1.8-1.3l0.9-0.7
      c1.7-1.5,3.6-3,5.3-4.5c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.7-3.3-5.5-5c-0.1-0.1-0.1-0.1-0.2-0.3l13.7-9.4
      c0,0.1,0.1,0.2,0.1,0.3c0.2,0.5,0.4,1,0.5,1.6c0.9,3.6,1.6,6.7,2.3,9.7c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4
      c-1.6,1.2-3.3,2.4-4.9,3.6l-0.3,0.2c-1.5,1.1-2.9,2.1-4.3,3.2c-1.3,1-2.6,1.9-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9
      C888.6,667.4,888.5,667.5,888.3,667.6z M904.4,643.6l-0.2-0.9c-0.3-1.3-0.6-2.5-0.9-3.8c-0.1-0.4-0.2-0.7-0.2-1.1v-0.1
      c-0.2-1.1-0.4-2.2-0.9-3.3c-0.5-1.2-0.4-2.3-0.3-3.5v-0.1c0.1-1.2,0.2-2.4,0.3-3.7c0.1-1.5,0.3-3.1,0.5-4.7
      c0.2-1.4,0.4-2.8,0.6-4.2v-0.1c0.1-0.8,0.3-1.6,0.4-2.4c0.3-1.8,0.5-3.6,0.8-5.3c0.4-2.9,0.8-5.9,1.3-8.9c0.1-0.5,0.2-0.9,0.4-1.5
      c0-0.1,0-0.2,0.1-0.2l0.6,0.6c0.7,0.7,1.3,1.4,1.9,2c1,1.1,2.1,2.2,3.1,3.2c1.7,1.8,3.6,3.7,5.3,5.5c1.1,1.2,2.3,2.4,3.3,3.6
      l0.1,0.1c0.8,0.9,1.7,1.8,2.5,2.8c0.4,0.4,0.8,0.9,1.2,1.3c1.1,1.2,2.2,2.4,3.4,3.5c2.1,1.8,2,3.8,1.5,5.8
      c-0.6,2.3-1.1,4.8-1.6,6.9v0.1c-0.5,2.1-1,4.4-1.5,6.5c-0.4,1.7-0.9,3.4-1.3,5.1v0.1c-0.4,1.3-0.7,2.7-1.1,4
      c-0.6,2.4-1.2,4.8-1.7,7.1c-0.2,1-0.5,2-0.7,3c0,0.2-0.1,0.3-0.1,0.5v0.1c-0.1,0.3-0.2,0.7-0.3,1.1c-0.3,1.1-0.6,2.2-1,3.2
      l-0.2,1.4c-0.3-0.3-0.6-0.6-0.9-0.9l-0.2-0.2c-0.9-1-1.9-2.1-2.9-3.1c-1.4-1.4-2.9-2.8-4.3-4.1c-1.2-1.1-2.4-2.3-3.6-3.4
      c-0.5-0.5-0.9-1.1-1-1.6C906,650.5,905.2,647,904.4,643.6z M931.6,613.3c-0.3,2.1-0.7,4.2-1,6.4l-0.1,0.5c-0.1,0.6-0.2,1.3-0.3,1.9
      l-0.6-0.6c-0.7-0.8-1.4-1.6-2.1-2.3c-2.6-2.8-6.1-6.5-9.6-10.3c-1.4-1.5-2.9-3-4.4-4.5l-0.2-0.2c-1-1.1-2-2.1-3.1-3.2
      c-0.4-0.4-0.7-0.8-1.1-1.2c-0.7-0.7-1.4-1.5-2.1-2.2c-0.5-0.4-0.5-0.9-0.4-1.4c0.3-1.3,0.7-2.6,1-3.9c0.6-2.5,1.4-5.4,2-8.1
      c0.4-1.7,0.6-3.4,0.8-5V579c0.1-0.7,0.2-1.4,0.3-2l0.4-2.3c0.3-1.9,0.6-3.8,0.8-5.7c0.1-1,0.2-2,0.3-3c0.1-0.7,0.1-1.5,0.2-2.2
      c0-0.4,0.1-0.8,0.2-1.3v-0.1c0.3,0.4,0.6,0.7,0.9,1.1c1,1.2,2,2.3,2.8,3.4c3,3.5,5.5,7,7.9,10.4c1,1.4,1.9,2.9,2.8,4.3
      c0.6,0.9,1.1,1.8,1.7,2.7c0.3,0.5,0.7,1,1,1.6c1.2,1.9,2.5,3.8,3.8,5.6c0.7,0.9,0.9,1.7,0.7,2.9c-0.2,1.2-0.3,2.4-0.5,3.6
      c-0.2,1.5-0.4,3.1-0.7,4.6c-0.2,1.3-0.4,2.7-0.5,4C932.2,608.7,931.9,611,931.6,613.3z M910.3,564.7l-0.1,0.8
      c-0.8,5.9-1.7,12.1-2.7,18.1c-0.5,2.7-1.1,5.4-1.8,8l-0.1,0.3c-0.3,1-0.5,2-0.7,3c-0.2,0.7-0.4,1.4-0.5,2l-0.1,0.5
      c-0.4-0.2-0.8-0.5-1.2-0.7l-1-0.5c-1.7-0.9-3.5-1.9-5.2-2.9c-1-0.6-2.1-1.1-3.1-1.6l-0.1-0.1c-1-0.5-2.3-1.2-3.6-1.9
      c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.2-0.7-2.4-1.4-3.6-2c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5
      c-1.6-0.9-3.2-1.9-4.7-2.8l-0.4-0.2c-0.5-0.3-1-0.6-1.5-0.9c0-0.1-0.1-0.2-0.3-0.2c-0.4-0.1-0.7-0.2-0.8-0.3
      c-0.2-0.2-0.1-0.7,0-1.2c0-0.2,0.1-0.4,0.1-0.6c0.1-1.9,0.2-3.7,0.3-5.6c0.1-1.6,0.2-3.1,0.2-4.7c0.1-1.2,0.1-2.3,0.2-3.5
      c0.1-1.9,0.2-3.7,0.3-5.6c0.4-6.5,0.8-13,1.1-19.5c0.3,0.2,0.6,0.3,0.8,0.5l0.5,0.3c1.2,0.7,2.5,1.4,3.8,2.2l2.1,1.1
      c2.8,1.6,5.7,3.3,8.4,5c4.5,2.8,7.8,5,10.9,7.1c1.8,1.2,3.6,2.6,5.3,3.9l0.2,0.2c1.8,1.3,3.7,2.7,5.6,4
      C910.5,562.6,910.4,563.5,910.3,564.7z M870.2,580.9l0.3,0.2c1.2,0.8,2.6,1.7,4,2.5c1,0.7,2.3,1.4,3.4,2l0.6,0.3
      c1.7,0.9,3.5,1.9,5.2,2.8l1.6,0.8c0.7,0.4,1.5,0.8,2.2,1.2l0.7,0.4c1.8,1,3.7,2,5.6,3c1,0.5,1.9,1.1,2.9,1.6l0.3,0.1
      c1.9,1,3.8,2.1,5.8,3.1c1.1,0.5,1.2,1.2,1,2.2c-0.2,1.3-0.4,2.5-0.6,3.8l-0.1,0.8c-0.6,4.3-1.3,8.7-1.9,13.2
      c-0.4,2.7-0.6,5.5-0.8,8.2c-0.1,1.3-0.2,2.6-0.4,3.9c0,0.2,0,0.3-0.1,0.5l-0.7-1c-0.7-0.9-1.4-1.9-2.2-2.8c-2-2.5-4.1-5.1-6.1-7.5
      l-0.4-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0c-0.4,0-0.8,0-1.2,0
      c-0.8,0-1.5,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2c-0.6,0-1.2,0.1-1.8,0.1
      c-0.3,0-0.7,0-1.1,0.1h-0.5l2-36.2l0.1-1C869.3,580.4,869.7,580.6,870.2,580.9z M866.7,579.9v0.3c-0.1,1-0.2,2.1-0.3,3.1
      c-0.1,1.7-0.2,3.4-0.2,5c-0.1,1.3-0.1,2.7-0.2,4.1c-0.1,1.9-0.2,3.9-0.3,5.8c-0.1,1.6-0.2,3.1-0.3,4.7c-0.1,1.6-0.2,3.1-0.2,4.7
      c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.5c-0.1,1.9-0.2,3.8-0.3,5.7c0,0.2-0.3,0.5-0.6,0.6c-2.4,1.1-4.8,2.1-7.1,3.2l-0.2,0.1
      c-1.1,0.5-2.1,0.9-3.2,1.4c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.2,5.5-6.1,8.2l-1,1.4c-0.8,1.2-1.6,2.5-2.3,3.7l-0.1,0.2
      c-0.4,0.7-0.7,0.9-1.2,0.9c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1c-1-0.3-1.9-0.6-2.9-1c-0.4-0.1-0.9-0.3-1.4-0.4
      c-0.2,0-0.3-0.1-0.5-0.1v-65.9c13.8,0.9,27.1,3.4,38.3,5.6C866.8,579.4,866.8,579.7,866.7,579.9z M836.9,667.2
      c0-1.3,0.1-2.6,0.3-4.1c0-0.5,0.1-0.9,0.1-1.4c0.4,0.2,0.7,0.5,1.1,0.7l0.1,0.1c1.2,0.8,2.4,1.6,3.6,2.2c2,1.1,4.2,2.2,6.2,3.1
      c0.9,0.4,1.1,0.7,0.8,1.6c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9c-0.3,0.9-0.5,1.7-0.7,2.6v0.1c-0.2,0.6-0.3,1.2-0.5,1.8
      c0,0.2-0.1,0.3-0.2,0.5c-0.2-0.1-0.3-0.2-0.5-0.3l-0.2-0.2c-0.8-0.6-1.5-1.1-2.2-1.6c-2-1.6-3.9-3.2-5.7-4.7
      c-0.3-0.2-0.5-0.7-0.5-1.1C836.8,670.1,836.8,668.6,836.9,667.2z M837.7,682.3c0.9,3.7,1.7,7.6,2.4,11l0.2,0.9
      c0.2,0.9,0.4,1.9,0.6,2.8c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.8-0.2-2.8-0.3
      c-2.7-0.3-5.4-0.6-8.2-0.6c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1
      c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.2c0.8-3.7,1.6-7.6,2.5-11.4c0.5-1.9,1-3.9,1.6-5.8l0.4-1.4
      c0.6-2.3,1.1-2.4,2.5-2.5c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0h2.9v-0.1
      c0.5,0,1,0.1,1.4,0.1c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.5C836.6,678.1,837.2,680.2,837.7,682.3z M817.7,669
      c0,0.7,0,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.5-3.7,3-6.2,4.9c-0.5,0.4-1,0.7-1.5,1.1c-0.1,0.1-0.3,0.2-0.4,0.3l-0.2-0.5
      c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3c-0.4-1.6-0.9-3.4-1.5-5.1c-0.3-0.8,0-1,0.7-1.3c0.4-0.2,0.7-0.3,1.1-0.5l0.3-0.1
      c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.3-1.3,3.2-2c0.5-0.3,1-0.7,1.5-1c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,0.9,0.1,1.3
      c0.1,1.4,0.3,2.7,0.4,4C817.7,667.7,817.7,668.4,817.7,669z M817.4,641.9c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.4-3.7-3.5-5.4
      l-0.1-0.1c-0.6-1-1.3-1.9-1.9-2.9c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.1c-0.4-0.2-0.9-0.4-1.3-0.6
      c-1.1-0.5-2.2-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.4-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7v-0.1
      c-0.1-1.8-0.1-3.6-0.2-5.5c-0.1-2-0.3-4.1-0.4-6.1v-0.1c-0.1-1.9-0.3-4-0.4-6.1c-0.1-1.1-0.1-2.2-0.1-3.3c0-1.2,0-2.3-0.1-3.5
      c-0.1-1.7-0.3-3.4-0.4-5.1v-0.3c-0.1-0.7-0.1-1.4-0.2-2.1c0-0.1,0-0.2,0-0.4c15.1-3.1,26.9-4.8,38.2-5.6v65.8l-2.1,0.7
      c-2,0.7-3.9,1.3-5.8,1.9C817.8,641.9,817.6,641.9,817.4,641.9z M723,628L723,628c0.3,1.5,0.5,3,0.9,4.5c0.9,4,1.6,7.3,2.4,10.3
      c0.4,1.7,0.9,3.5,1.3,5.1c0.4,1.3,0.8,2.8,1.1,4.3c0.5,2.1,1,4,1.6,6.4l0.1,0.3c0.5,2,1,4,1.5,6c0.2,0.8,0.1,1.4-0.2,1.9
      c-0.4,0.6-1.2,1-2.5,1.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0c-0.6,0-0.9-0.2-1.1-0.7
      c-0.3-1.1-0.6-2.3-0.8-3.4c-0.3-1.4-0.6-2.7-0.8-4.1v-0.1c-0.5-2.5-1-5-1.6-7.5c-0.3-1.4-0.7-2.7-1-4c-0.3-1-0.6-2-0.8-3.1
      c-0.4-1.5-0.7-3-1-4.4v-0.1c-0.2-0.7-0.3-1.4-0.5-2.2l-0.4-1.7c-0.3-1.4-0.6-2.7-0.9-4c-0.1-0.5-0.1-0.8,0-0.8
      c1.1-1.4,2.3-2.9,3.3-4.2l0.6-1.3C722.8,627.1,722.9,627.5,723,628z M720,653.2c0.4-0.4,0.7-0.7,1.1-1.1l5.5,27.5
      c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.6c-0.3-0.8-0.7-1.6-1-2.3c-1.8-4.2-3.8-8.6-6.3-12.6l-0.2-0.3
      c-0.5-0.8-1.1-1.7-1.3-2.6c-0.2-0.6,0.1-1.6,0.6-2.1C717.9,655.4,719,654.3,720,653.2z M717,664.7c1.1,2.2,2.2,4.4,3.2,6.6l0.8,1.7
      c0.2,0.5,0.4,0.9,0.7,1.5l0.2,0.5c-0.2,0-0.4,0-0.5,0c-0.3,0-0.7,0-1,0c-0.5,0-0.8,0-1.2,0h-0.8c-2.3,0.1-4.8,0.2-7.2,0.4
      c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.8c-0.1-1.3-0.1-2.6-0.1-4v-0.1v-0.3c0-0.4,0-0.8,0-1.2
      c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.9,0.5,1.1,0.9C715.3,661.5,716.1,663,717,664.7z M709.5,677.8
      c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.6-0.8l3-0.3c3.3,5.7,6.3,11.9,5.9,19.5H728c-2.2-0.1-4.6-0.2-6.9-0.2c-3.8,0-7,0.3-9.9,1
      l-0.1-1.1c-0.1-1.1-0.2-1.9-0.3-2.9c-0.3-3.3-0.6-6.1-0.9-9c-0.1-1.1-0.2-2.2-0.3-3.3c-0.1-0.9-0.1-1.7-0.2-2.6
      C709.5,677.8,709.5,677.8,709.5,677.8z M734.9,679.1l-0.1-1.6c-0.5-1.7-0.6-3.6-0.6-5.4c0-1.3,0.5-2.4,1.7-3.4
      c1.6-1.4,3.1-2.9,4.6-4.3c1-1,1.9-1.9,2.9-2.8c1.3-1.2,2.6-2.4,3.8-3.5c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1c2.2,1.6,4.6,3.4,7,5.1
      c1.3,0.9,2.6,1.9,3.8,2.9l0.1,0.1c0.8,0.6,1.6,1.2,2.4,1.8c0.8,0.6,1,1.4,0.8,2.5c-0.4,2-0.8,4-1.2,6v0.2c-0.1,0.7-0.3,1.4-0.4,2.1
      l-0.3,1.8c-0.3,1.6-0.6,3.3-0.9,4.9c-0.3,1.7-0.6,3.5-0.9,5.2c-0.4,2.5-0.8,4.7-1.2,7c-0.3,1.6-0.9,1.8-1.5,1.8
      c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2c-3.1-1.3-6.1-2.7-9-4.1l-1.9-0.9c-0.2-0.1-0.4-0.4-0.4-0.7
      C735.4,686.6,735.2,683.1,734.9,679.1z M760.4,712.4c0.2-2.3,0.6-4.5,0.9-6.6c0.2-1,0.3-1.8,0.4-2.7c0.1-0.9,0.6-1.4,1.7-1.7
      c4.8-1.1,8.8-2.1,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1c1.2-0.3,2.4-0.7,3.7-1c0.6-0.2,1.3-0.3,2-0.5h0.2c0.2,0,0.4-0.1,0.5-0.1
      c0,0.5,0,0.9,0.1,1.4c0.1,1.3,0.1,2.6,0.2,3.9v0.2c0.2,3.4,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4c0.3,0.6,0.1,0.9-0.3,1.4
      c-0.3,0.3-0.6,0.6-0.9,0.9l-0.1,0.1c-1,0.9-2,1.9-3,2.9c-1,1.1-2,2.3-2.9,3.5c-0.7,0.9-1.5,1.8-2.3,2.7l-0.3,0.4
      c-0.7,0.8-1.4,1.6-2.2,2.1c-0.3,0.1-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0s-0.6,0-0.9,0H775c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3,0-1.9,0
      c-0.6,0-1.2,0-1.7,0.1c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4c0.2-1.6,0.4-3.1,0.6-4.7C759.3,720.4,759.9,716.4,760.4,712.4z
       M775.6,797l-0.1-0.1c-1.2-0.8-2.4-1.6-3.6-2.4c-0.9-0.6-1.8-1.1-2.7-1.7c-0.8-0.5-1.7-1-2.5-1.5s-1.5-1.1-2.1-1.7
      c0,0-0.1-0.2-0.1-0.5c0-0.5,0-1,0-1.4v-0.3l18-15l0.5,0.4c0.6,0.5,1.2,1,1.8,1.5c0.8,0.7,1.6,1.4,2.4,2.1c1.8,1.5,3.6,3,5.4,4.5
      c1,0.8,2,1.5,2.9,2.1c0.8,0.6,1.7,1.2,2.5,1.8c0.9,0.7,1.8,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2c0.4,0.3,0.4,0.5,0.2,1
      c-0.4,1.4-0.8,2.9-1.2,4.3c-0.6,2.2-1.2,4.5-1.8,6.8c-0.7,2.3-1.4,4.6-2.2,6.9l-0.5,1.5c-0.2,0.6-0.5,0.6-0.6,0.6
      c-0.2,0-0.4-0.1-0.6-0.2c-1.1-0.7-2.1-1.4-3.2-2c-1.3-0.8-2.9-1.9-4.5-2.8c-1.2-0.8-2.5-1.5-3.7-2.2l-0.2-0.1
      c-1.5-0.9-3.1-1.8-4.6-2.8S776.9,797.9,775.6,797z M852.7,794.9l-0.1-0.3c-0.2-0.8-0.4-1.5-0.6-2.3c0-0.2-0.1-0.4-0.1-0.6
      c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.3-1,2.6-2,3.9-2.9l0.1-0.1c1.6-1.2,3.5-2.6,5.3-4c1.3-1,2.6-2.1,3.8-3.1
      c0.7-0.6,1.4-1.2,2.1-1.8c1.1-0.9,2.2-1.8,3.2-2.7l1.5-1.2c0.5,0.4,1.1,0.9,1.6,1.3c1.4,1.1,2.8,2.2,4.1,3.3
      c0.7,0.6,1.4,1.2,2.1,1.8c0.7,0.6,1.5,1.3,2.2,1.9c2.9,2.4,5.3,4.3,7.7,6.1c0.4,0.3,0.4,0.4,0.3,0.7c-0.1,0.4-0.2,0.7-0.1,0.9
      c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.2c-3,2-6.2,4-9.3,6c-1.2,0.8-2.4,1.5-3.6,2.2h-0.1
      c-0.7,0.4-1.4,0.8-2.1,1.2l-0.5,0.3c-1.3,0.8-2.7,1.7-4.1,2.5c-1.3,0.8-2.5,1.6-3.8,2.4l-0.6,0.4c-1,0.6-1.9,1.2-2.9,1.9
      c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.6-1.7-0.8-2.5c-0.7-2-1.4-4.1-2-6.1
      C853.7,799,853.1,796.8,852.7,794.9z M894.2,772c-0.5,3.5-1.2,7.1-1.8,10.3l-0.6,1.7c-0.1,0.3-0.2,0.7-0.3,1v0.1
      c-0.7-0.6-1.5-1.3-2.2-1.9l-0.3-0.3c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.7-2c-0.8-0.7-1.6-1.5-2.3-2.3
      l-0.1-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0.2-1,0.4-1.9,0.6-2.9v-0.2c0.3-1.4,0.6-2.9,0.8-4.3
      c0.3-1.6,0.4-3.2,0.6-4.7c0.1-0.5,0.1-1,0.2-1.5c0.3-2.7,0.6-5.6,1-9.1l0.1-1.3c0.2-2.3,0.5-4.6,0.7-6.9c0-0.4,0-0.7-0.1-1.1
      c0-0.3-0.1-0.6-0.1-0.9c0-0.7,0.1-1.3,0.2-2v-0.1c0.1-0.7,0.1-1.5,0.2-2.2c0-0.5-0.1-1-0.1-1.5v-0.1c0-0.1,0-0.2-0.1-0.3
      c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1h0.2c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4c1.6,0.2,3.3,0.4,5,0.5
      c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.4-0.7,6.3c-0.3,2.5-0.5,5-0.8,7.6
      C895.9,761.2,895,767,894.2,772z M894.8,728.7c-1.6-0.2-3.3-0.3-4.9-0.5l-1.8-0.2c-2.2-0.2-4.6-0.4-6.9-0.6c-0.8,0-1.5,0-2.3,0.1
      c-0.5,0-1.1,0.1-1.6,0.1c-0.2,0-0.5-0.1-0.9-0.4c-1.8-1.4-3.2-3.2-4.5-4.9c-0.7-0.9-1.4-1.8-2.2-2.7c-1.2-1.3-2.5-2.7-4.2-4.1
      c-1.2-1-1.2-1-0.8-2.6c0.4-2.2,0.6-4.5,0.8-6.5c0.2-1.8,0.3-3.5,0.4-5.3l0.1-1.3c0.1-1.1,0.1-2.1,0.1-3.2l0.1-1.4
      c0.4,0.1,0.7,0.2,1.1,0.2c1.1,0.2,2.1,0.4,3.1,0.7c2.1,0.6,4.2,1.2,6.3,1.7h0.1c2.5,0.7,5.3,1.5,8.1,2.2c2,0.5,4,0.9,6.2,1.3
      c1,0.2,1.5,0.7,1.7,1.9c0.6,4.1,1.2,8.3,1.8,12.3l0.6,3.8c0.3,2.2,0.7,4.5,1,6.8l0.4,2.8L894.8,728.7z M894.1,699.5
      c-0.3-1.6-0.6-3.2-0.9-4.8v-0.3c-0.2-1.2-0.4-2.3-0.6-3.5c-0.1-0.8-0.3-1.6-0.4-2.4v-0.1c-0.1-0.8-0.2-1.6-0.4-2.4
      c-0.2-1.1-0.4-2.1-0.6-3.2v-0.2c-0.2-1-0.4-1.9-0.5-2.8c-0.2-0.9-0.3-1.7-0.5-2.6V677c-0.2-1-0.3-2-0.5-3.1c-0.2-1-0.4-1.9-0.7-2.7
      c-0.4-1.1,0.2-1.8,1.2-2.5c1.3-1,2.7-2,4-2.9c1.3-1,3-2.2,4.6-3.4c1.7-1.2,3.3-2.5,5-3.8l0.1-0.1c0.7-0.5,1.4-1.1,2.1-1.6
      c0.3,0.2,0.6,0.5,0.9,0.8c0.9,0.7,1.8,1.4,2.6,2.2c1.9,1.8,3.8,3.7,5.6,5.5l0.1,0.1c0.8,0.8,1.6,1.6,2.3,2.3l0.1,0.1
      c0.1,0.1,0.2,0.2,0.3,0.2c2.6,1.4,2.8,3.8,2.6,6.2c-0.3,4.7-0.7,9.9-1.3,16.1c0,0.3-0.2,0.5-0.3,0.6c-0.7,0.3-1.5,0.7-2.2,1.1
      c-3.1,1.5-6.3,3-9.5,4.3c-2.6,1.1-5.3,2.1-7.9,3c-0.8,0.3-1.6,0.6-2.4,0.9c-0.5,0.2-1,0.3-1.5,0.2
      C894.6,700.4,894.2,700.1,894.1,699.5z M919.2,712.1c0.1-0.8,0.1-1.6,0.2-2.3c0.5-4.1,0.8-7.5,1.2-10.7c0.2-2.2,0.4-4.5,0.6-7.1
      c0.1-0.8,0.1-1.6,0.2-2.4c0.2-2.6,0.4-5.3,0.5-8c0.1-2.7,0.2-5.4,0.2-8v-0.1c0-1.1,0-2.3,0.1-3.4c0-0.2,0-0.4,0-0.7v-0.3l0.6,0.1
      c0.7,0.1,1.4,0.3,2.1,0.5c0.2,0,0.3,0.1,0.5,0.1c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.2,0,0.8-0.1,1.2c-0.1,0.2-0.1,0.5-0.2,0.7
      c-0.3,1.6-0.5,3.3-0.8,4.9v0.1c-0.5,3.4-1.1,6.9-1.6,10.3c-0.3,1.9-0.5,3.8-0.6,5.6v0.1c-0.2,1.5-0.3,3.1-0.5,4.6
      c-0.3,2.6-0.8,5.2-1.2,7.7c0,0.9-0.1,1.4-0.2,2c-0.4,2.2-0.7,4.5-1.3,6.6c-0.2,1-0.4,2-0.6,3v0.1c-0.5,2.5-1,5.1-2.1,7.4
      c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5V725c0.1-2.1,0.2-4.2,0.8-6.1C918.8,716.6,919,714.3,919.2,712.1z M944.1,675.6
      c-0.1,0-0.2,0-0.2,0c-0.4-0.2-0.9-0.3-1.4-0.4c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.2,0-3.6,0c-0.8,0-1.6,0-2.4,0c-0.6,0-1.3,0-2,0
      c0.2-0.4,0.3-0.7,0.5-1.1c0.5-1.1,0.9-2.1,1.4-3.1c0.9-1.8,1.9-3.7,2.9-5.5l0.2-0.3c0.6-1.1,1.1-2.1,1.7-3.2
      c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2c0.3,0,0.7,0,1,0
      c0,0.4,0,0.9,0,1.3v0.4c0,1.4,0,2.7,0,4.1c-0.1,2.6-0.2,5.2-0.3,7.8l-0.1,1.6C945.3,675.2,944.9,675.6,944.1,675.6z M937.7,582.7
      c0.2-1.4,0.4-2.8,0.6-4.2l0.2,1.5c0.3,1.7,0.5,3.3,0.8,5l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5c0.2,1.3,0.3,2.7,0.5,4
      c0.2,2.1,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8l-0.1,0.4c-0.5,2-0.8,4-1.2,6v0.1c-0.2,1.2-0.4,2.4-0.7,3.6
      c-0.3,1.6-0.7,3.3-1,4.9v0.1c-0.3,1.2-0.5,2.4-0.8,3.7c-0.2,0.9-0.3,1.7-0.5,2.7l0.2,0.4c-0.2-0.3-0.4-0.5-0.7-0.8l-0.1-0.1
      c-1.1-1.3-2.2-2.6-3.2-3.9c-0.2-0.2-0.3-0.7-0.2-1.1c0.3-1.7,0.5-3.5,0.8-5.2c0.3-1.7,0.6-3.9,0.9-5.9c0.2-1.2,0.3-2.4,0.4-3.6
      c0.1-0.9,0.2-1.9,0.3-2.8c0.2-1.3,0.4-2.6,0.6-4.1v-0.3c0.1-0.9,0.3-1.8,0.4-2.7c0.1-0.9,0.2-1.8,0.3-2.7l0.1-1.2
      c0-0.1,0-0.1,0-0.2c0.7-1.1,0.6-2.3,0.6-3.4c0-0.8-0.1-1.6,0.1-2.3c0.4-1.4,0.6-2.8,0.7-4.2C937.6,583.9,937.6,583.3,937.7,582.7z
       M914.5,543.5c0.1-1.6,0.3-3.1,0.4-4.7v-0.1c0.1-1.1,0.2-2.3,0.2-3.4c0.1-0.8,0.2-1.6,0.2-2.3v-0.1c0-0.2,0.1-0.4,0.1-0.7
      c0.3,0.3,0.6,0.7,0.9,1c0.9,1.1,1.9,2.1,2.7,3.3c1.6,2,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c0.9,1.6,1.8,3.3,2.7,4.9
      c0.5,0.9,0.9,1.8,1.4,2.7c1.6,2.9,2.6,6.2,3.3,10.4c0.3,1.8,0,3.5-0.3,5.1c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.8-0.8,5.6-1.3,8.5v0.1
      c-0.2,0.9-0.3,1.9-0.5,2.9c-0.1-0.2-0.3-0.3-0.4-0.5c-0.6-1-1.2-2-1.8-3l-0.1-0.2c-1.2-2-2.5-4-3.8-6c-1.1-1.6-2.3-3.3-3.4-4.8
      l-0.2-0.2c-0.6-0.8-1.3-1.7-1.9-2.6c-0.5-0.8-1.1-1.6-1.6-2.3c-0.9-1.3-1.8-2.7-2.8-4c-1.1-1.4-2.3-2.8-3.5-4.1l-0.1-0.1
      c-0.6-0.7-1.3-1.5-2-2.2c-0.1-0.2-0.2-0.5-0.2-0.7c0.2-2.4,0.5-4.8,0.7-7.2C914,548.9,914.2,546.2,914.5,543.5z M869.9,497.9
      L869.9,497.9c1.1,0.5,2.1,1,3.1,1.5c1.6,0.8,3.2,1.5,4.8,2.2c2.8,1.3,5.7,2.6,8.6,4c1.4,0.7,2.7,1.4,4,2.2c1.5,0.8,3,1.7,4.5,2.4
      c3.3,1.5,6.1,3.9,9,7.7c1.3,1.6,2.6,3.3,4,5.1c1,1.3,2.1,2.6,3.1,3.9c0.2,0.3,0.5,0.7,0.7,1c0.4,0.6,0.8,1.2,1.3,1.7
      c0.7,0.2,0.6,0.8,0.5,1.6c0,0.2-0.1,0.4-0.1,0.6c-0.3,2.5-0.5,5.1-0.8,7.6v0.1c-0.1,1.1-0.2,2.1-0.3,3.2l-0.6,6.2
      c-0.3,2.9-0.6,5.9-0.9,8.8c-0.1,0.6-0.1,1.2-0.1,1.8v0.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.8-0.6-1.6-1.3-2.5-1.9
      c-0.6-0.4-1.1-0.8-1.7-1.2l-0.1-0.1c-2.1-1.5-4.3-3.1-6.5-4.6c-5.5-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7
      c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7c-0.1,0-0.2-0.3-0.2-0.5c-0.1-1-0.1-1.9,0-2.7l0.1-2.4c0.2-3.8,0.4-7.7,0.6-11.6
      c0.2-2.4-0.2-4.8-0.6-7.1v-0.1c-0.1-0.4-0.1-0.8-0.2-1.1c-0.4-2.5-0.9-4.9-1.5-7.3l-0.2-0.8c-0.2-0.8-0.4-1.6-0.6-2.3
      c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.1-0.6C869.3,497.6,869.6,497.8,869.9,497.9z M828.3,488c0.3,0,0.6,0,0.8,0
      c0.4,0,0.7,0,1.1,0c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2c1.4,0.1,2.9,0.2,4.3,0.3c1.5,0.1,3,0.2,4.5,0.2
      c1.7,0.1,3.3,0.2,4.8,0.3c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1c1.5,0.6,3,1.2,4.5,1.9c0.4,0.2,0.8,0.7,1,1.3
      c0.5,1.7,1,3.5,1.4,5.2c1.2,5,2.2,9.5,2.4,14.1c0.1,3-0.1,6.1-0.3,9.1v0.2c-0.1,1.2-0.2,2.5-0.2,3.7c-0.1,1.2-0.1,2.5-0.2,3.7
      l-0.1,1.3c-5.9-1.4-11.8-2.9-17.6-4.4h-0.2c-7.6-2-15.5-4-23.4-5.8V488z M828.4,526.8c0-0.3,0-0.6,0.1-0.9l41,10.3l-2.3,41.1
      c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.3-0.3-2.6-0.6-4-0.9c-2.9-0.6-5.7-1.1-8.1-1.5c-1.8-0.3-3.9-0.7-5.9-1
      c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3c-0.9-0.1-1.7-0.1-2.6-0.2c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9
      c0-2-0.1-4-0.1-5.8v-0.1c0-1.8-0.1-3.6-0.1-5.4c0-1.4,0-2.8,0-4.1c0-1.2,0-2.5,0-3.7c0-1,0-2.1-0.1-3.1c0-1-0.1-2.2-0.1-3.3
      C828.3,528.7,828.4,527.7,828.4,526.8z M827.4,641c0.3,0,0.6,0.1,1,0.2l0.2,0.1c1.1,0.3,2.2,0.7,3.3,1v0.4c1.3,0.4,2.8,0.9,4.2,1.3
      c0.5,0.1,0.6,0.3,0.5,0.8c-0.4,4.8-0.8,9.6-1.2,14.3c-0.2,2.3-0.4,4.6-0.5,6.9v0.1c-0.1,1.3-0.2,2.7-0.3,4c0,0.1,0,0.2-0.1,0.4
      l-1.5-0.2c-0.8-0.1-1.5-0.2-2.2-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0-1.3,0-1.9,0h-0.1c-1.6,0-3.3,0.1-5,0.1c-0.9,0-1.7,0.1-2.6,0.2
      c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1c-0.1-1.2-0.1-2.3-0.2-3.4c-0.2-1.8-0.3-3.7-0.5-5.5v-0.1
      c-0.2-2.3-0.4-4.7-0.6-7c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6c3.3-1.1,6.2-2,8.8-2.8C827.3,641,827.3,641,827.4,641z
       M785.9,504.8c0.3-1.7,0.7-3.5,1.1-5.3c0.2-1.1,0.6-2.2,1.1-3.5c0.1-0.3,0.5-0.7,0.9-0.8c3.8-1.6,7.6-3.2,10.8-4.6
      c3.2-1.4,6.8-2.1,11-2.2c2.5-0.1,5.1-0.2,7.6-0.4h0.1c1.6-0.1,3-0.2,4.4-0.2c0.3,0.2,0.7,0.1,1.1,0.1c0.3,0,0.6,0,0.9,0h0.2
      c0.3,0,0.6,0,0.9,0v35.8c-7.9,1.8-15.8,3.8-23.5,5.8h-0.1c-5.7,1.5-11.6,3-17.4,4.4c0-0.2-0.1-0.5-0.1-0.7
      c-0.1-0.8-0.2-1.6-0.2-2.4c-0.1-1.4-0.1-2.9-0.2-4.3c0-1.4-0.1-2.8-0.2-4.3v-0.6c-0.2-3.6-0.4-7.3,0.4-10.9
      c0.4-1.9,0.8-3.7,1.1-5.6L785.9,504.8z M741.6,528.9c0.7-0.9,1.4-1.8,2-2.7c1.1-1.5,2.2-3,3.4-4.4c3-3.6,5.7-6.7,8.4-9.4
      c0.6-0.6,1.5-1.1,2.4-1.6c0.4-0.2,0.8-0.4,1.2-0.6c5.1-3.1,10.4-5.5,14.9-7.6c2.7-1.2,5.4-2.5,8-3.8l0.3-0.2
      c1.1-0.5,2.2-1.1,3.3-1.6c0.1,0,0.1-0.1,0.2-0.1l-0.2,0.5c-0.1,0.6-0.3,1.2-0.4,1.7c-0.2,0.6-0.3,1.2-0.5,1.8l-0.1,0.2
      c-0.3,1.3-0.7,2.7-1,4.1c-0.2,1.1-0.4,2.2-0.6,3.2c-0.1,0.9-0.3,2-0.5,2.9c-0.6,3-0.5,6-0.3,8.9c0,0.4,0,0.9,0.1,1.3
      c0.1,1.8,0.2,3.7,0.3,5.5c0.1,1.5,0.2,2.9,0.2,4.4c0,1,0,1.9,0,2.8c0,0.2-0.2,0.5-0.3,0.6c-1.5,0.8-3,1.7-4.4,2.5l-0.1,0.1
      c-2.2,1.2-4.4,2.5-6.5,3.7c-1.8,1.1-3.6,2.2-5.3,3.4c-0.9,0.6-1.7,1.1-2.6,1.7c-0.7,0.5-1.5,0.9-2.2,1.4l-0.4,0.3
      c-1.6,1-3.4,2.1-5.1,3.3c-2,1.3-4,2.8-5.9,4.2l-0.6,0.4c-1.3,1-2.6,1.9-4,2.9h-0.1c-0.5,0.3-0.9,0.7-1.4,1l-0.1-0.9
      c-0.1-1.1-0.2-2.1-0.3-3.1c-0.2-2.2-0.5-4.5-0.7-6.7c-0.3-2.9-0.6-5.7-0.9-8.6c-0.1-1.3-0.2-2.6-0.3-3.9v-0.1
      c-0.1-0.9-0.2-1.9-0.2-2.8c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8C740.6,530.4,740.9,529.8,741.6,528.9z M719.8,562.7
      c0.3-0.5,0.5-1.1,0.8-1.6l0.1-0.1c0.8-1.7,1.6-3.4,2.5-5.1c2-3.5,4-6.7,6.1-10.1c1.6-2.6,3.2-5.1,5-7.5c1.1-1.5,2.3-2.9,3.5-4.4
      c0.4-0.5,0.9-1.1,1.3-1.6l0.2,1.9c0.1,1.4,0.2,2.8,0.4,4.2c0.2,1.9,0.4,3.7,0.5,5.6l0.1,0.7c0.1,1.4,0.3,2.9,0.4,4.3
      c0.3,3.5,0.6,6.7,0.9,10c-0.1,0.1-0.1,0.3-0.2,0.4c-0.9,1.1-1.9,2.2-2.6,3.2l-0.1,0.1c-1.6,1.9-3.2,3.8-4.7,5.7
      c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.5-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7c-0.2,0.2-0.3,0.5-0.5,0.8c-0.1-0.8-0.2-1.6-0.4-2.4
      l-0.2-1.5c-0.5-3.6-1-6.9-1.6-10.3C716.8,571.4,717.6,567.2,719.8,562.7z M718,629.1L718,629.1v-0.2c-0.1-0.6-0.2-1.2-0.3-1.8
      c-0.2-0.9-0.4-1.9-0.6-2.8v-0.1c-0.3-1.6-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4v-0.1c-0.2-1.2-0.4-2.5-0.7-3.7l-0.1-0.4
      c-0.3-1.6-0.6-3.3-1.2-4.9c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.1c0.3-2.6,0.6-5.3,1-8c0.4-2.9,0.9-5.9,1.5-8.7
      c0.2,1.2,0.4,2.3,0.5,3.5c0.3,1.7,0.5,3.5,0.8,5.2l0.4,2.6c0,0.1,0,0.2,0.1,0.2c0,0,0,0.1,0,0.2c-0.4,1.3-0.1,2.6,0.2,3.9
      c0.2,0.7,0.3,1.4,0.4,2.1c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.7,1,7c0.3,1.9,0.6,3.8,0.9,5.7c0.4,2.4,0.7,5,1,7.6
      c0,0.3-0.1,0.8-0.4,1.2c-1,1.2-2,2.4-3.1,3.7l-0.1,0.2C718.3,628.7,718.1,628.9,718,629.1z M699.1,654.8c1.3-1.7,2.5-3.4,3.8-5.1
      c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.5-0.7,0.8-0.9l0.1-0.1c0.6-0.5,1.3-1.1,2-1.3c3.6-1,7.4-2,11.5-3.1c0.4-0.1,0.6-0.1,0.8-0.1
      c0.2,0,0.2,0,0.5,1.1c0,0.5,0.1,0.9,0.2,1.3c0.2,0.6,0.4,1.3,0.5,1.9c0.1,0.8,0.1,1.4-0.1,1.8c-0.3,0.6-0.8,1.1-1.3,1.6
      c-0.2,0.3-0.5,0.5-0.7,0.8l-0.7,0.8c-0.9,1.1-1.9,2.2-2.7,3.4c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1c-1.2,0-2.4-0.1-3.5-0.2h-0.1
      c-1.4-0.1-2.9-0.2-4.3-0.2c-1.6,0-2.9,0.1-4.2,0.4c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0.2
      L699.1,654.8z M705,688.1l-0.7,0.3l-0.7-2.2c-0.6-1.9-1.4-4.1-2.1-6.2c-0.3-0.9-0.7-1.8-1-2.7c-0.3-0.7-0.6-1.6-0.9-2.4
      c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2c0,0,0.2-0.2,0.3-0.2c1.8-0.4,3.7-0.9,5.5-1.3
      l1.8-0.2c0.1,0,0.2,0,0.2-0.1c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5c0.1,1.6,0.1,3.2,0.2,4.7v0.2c0,2,0.1,4,0.2,6
      c0.1,1.8,0.4,3.7,0.6,5.4v0.1c0.1,1.1,0.3,2.2,0.4,3.2c0.2,2,0.4,4,0.4,6c0,0.1-0.3,0.3-0.6,0.4C706.6,687.5,705.8,687.8,705,688.1
      z M706.8,703.7l-1.5-12.8l2.9-1.5l1.1,13.5L706.8,703.7z M720.4,726.6c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2
      c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.5-1.2-1-1.8-1.5c-1.5-1.2-3.1-2.5-4.5-3.8c-1.4-1.3-2.3-2.4-2.8-3.5c-0.4-0.8-0.5-1.7-0.6-2.7
      c0-0.4-0.1-0.9-0.2-1.4c-0.3-1.5-0.5-2.7-0.7-3.9l-0.1-0.4c-0.3-2-0.7-3.9-1-5.9c-0.1-0.5-0.1-1-0.1-1.4c0-0.1,0-0.3,0-0.4
      c0.2-0.1,0.4-0.2,0.6-0.2h0.1c0.6-0.3,1.3-0.5,1.8-0.8c0.4-0.2,0.6-0.2,1,0.3l2.3,2.8c2.4,2.9,4.8,5.8,7.2,8.8
      c0.2,0.2,0.3,0.6,0.3,0.9C720.1,720.7,720.1,723.3,720.4,726.6z M718.7,711.7l-0.1-0.1c-1.7-2.1-3.5-4.3-5.5-6.3
      c-1.4-1.4-1.5-3.2-1.6-5.2c0-0.4,0-0.7-0.1-1.1c4.1-1,8.3-1.1,11.9-1.1c0.7,0,1.5,0,2.2,0c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2
      c-0.4,0-0.8,0.1-1.2,0.1h-0.4c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4C720.6,714,719.7,712.8,718.7,711.7z M738.2,736.1
      c-0.1,0.1-0.4,0.2-0.7,0.1c-1.8-0.2-3.7-0.4-5.5-0.6l-0.8-0.1c-2.1-0.2-4.1-0.5-6.2-0.7c-0.6-0.1-1.1-0.2-1.6-0.3
      c-0.4-0.1-0.6-0.3-0.5-1v-0.3c0.1-1.8,0.2-3.7,0.1-5.5c0-1.3-0.1-2.7-0.2-4v-0.2c0-0.5-0.1-1.1-0.1-1.6c0-0.9-0.1-1.8-0.1-2.7v-1.1
      l9.6-0.9c0,0.1,0.1,0.3,0.1,0.4c0.1,0.5,0.2,0.9,0.4,1.4c0.8,2.3,1.6,4.5,2.3,6.8l0.1,0.2c0.7,2,1.4,3.9,2,5.9
      c0.4,1.2,0.8,2.4,1.1,3.5C738.3,735.7,738.3,736,738.2,736.1z M739,718.6c-0.1-0.7-0.4-1.4-0.6-2.1c-0.2-0.7-0.5-1.5-0.6-2.2
      c-0.3-2.1-0.5-4.3-0.7-6.3l-0.1-0.1c-0.1-0.8-0.2-1.7-0.2-2.5c-0.1-0.8-0.2-1.6-0.2-2.3l-0.1-0.9c-0.2-1.4-0.3-2.9-0.4-4.3
      c-0.1-1.1-0.2-2.2-0.3-3.4v-0.1c0-0.4-0.1-0.8-0.1-1.2h0.1h0.1c0.4,0.1,0.9,0.3,1.2,0.5c1.1,0.5,2.2,1.1,3.2,1.6
      c2.1,1.1,4.2,2.1,6.4,3.1c2.5,1.1,5.1,1.9,7.6,2.8l0.4,0.1c0.5,0.2,1.1,0.4,1.6,0.5c0.8,0.3,1.6,0.4,2.5,0.6
      c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.1c-0.1,1-0.3,2.1-0.5,3.2c-0.3,1.7-0.5,3.4-0.8,5.1l-0.1,0.7c-0.4,2.2-0.7,4.5-1.1,6.8
      c-0.2,1.5-0.4,3-0.6,4.4c-0.2,1.5-0.4,3.1-0.7,4.8c-0.1,0.4-0.2,0.4-0.2,0.4c-0.2,0-0.4-0.1-0.6-0.2h-0.1c-1.3-0.7-2.6-1.5-3.9-2.3
      l-0.1-0.1c-0.8-0.5-1.5-1-2.3-1.5h-0.1c-1-0.7-2.1-1.3-3.1-2c-1.2-0.7-2.3-1.4-3.7-2.1l-0.2-0.1c-0.5-0.3-1.1-0.6-1.6-0.9
      C739.3,719.6,739.1,719.3,739,718.6z M750.6,761.1l-0.1-0.2c-1.3-2.5-2.8-5.4-4.1-8.2c-0.6-1.3-1.2-2.7-1.8-4.1
      c-0.7-1.6-1.3-3.3-2.2-4.9c-1.3-2.5-1.6-5.2-1.9-7.9v-0.1c0-0.3-0.1-0.7-0.1-1c-0.3-3-0.6-6-0.9-9.2c-0.1-1.1-0.2-2.3-0.3-3.4
      l15.8,9.7c-0.7,3.7-1.3,7.6-0.5,11.8c0.4,2.4,0.7,4.9,0.9,7.3c0.1,1.4,0.3,2.9,0.5,4.4c0.7,5.5,1.4,10.9,2.2,16.2
      c0.3,2.3,0.8,4.7,1.2,6.9v0.1c0.1,0.4,0.2,0.8,0.2,1.3c-0.3-0.6-0.5-1.1-0.8-1.7l-0.2-0.5c-1.2-2.8-2.5-5.7-3.9-8.5
      C753.3,766.4,751.9,763.7,750.6,761.1z M762.1,883L762.1,883c-0.4,1-0.8,1.8-1.2,2.7c-0.1,0.2-0.2,0.4-0.3,0.6v0.1
      c-7.7-7.1-13.9-13.3-19.4-19.5c0.2-0.3,0.3-0.5,0.5-0.8c0.5-0.8,1-1.5,1.5-2.3c0.7-0.9,1.3-1.9,2.1-2.9l0.2-0.3
      c0.5-0.8,1.1-1.5,1.6-2.3l0.5-0.7c0.7-1,1.4-2,2-3c1.4-2.4,2.8-4.8,4.1-7.2l0.1-0.2c0.7-1,1.3-2,1.9-3.1c0.2,0.2,0.5,0.4,0.7,0.6
      l0.1,0.1c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1c1.8,1.3,3.7,2.6,5.5,3.8l1.4,0.9c0.4,0.3,0.5,0.7,0.4,1.4l-0.1,0.3
      c-0.3,1.4-0.7,2.9-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3c-0.5,1.2-1.2,2.5-1.8,3.7l-0.1,0.1c-0.4,0.9-0.9,1.7-1.3,2.6
      C763.5,880,762.8,881.5,762.1,883z M805.5,868.9c-0.4,12.1-0.8,24.6-1.9,37.1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.8-0.2-1.5-0.4-2.2-0.7
      c-1-0.4-2.1-0.8-3.1-1.2c-0.7-0.3-1.3-0.5-2-0.8c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-2.6-1-4-1.5c-2.5-1-5.2-2.2-8.2-3.5
      c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.5-1.7-5.2-2.6l-0.3-0.2c-0.8-0.4-1.5-0.8-2.3-1.2c-0.2-0.1-0.5-0.2-0.7-0.4l-0.1-0.1
      c-0.7-0.4-1.4-0.7-2.2-1c-0.3-0.1-0.4-0.2-0.4-0.3c-0.1,0.2-0.1,0,0-0.2c1.3-2.7,2.5-5.5,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8
      c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.7-1.5,1-2.1c0.6-1.3,1.2-2.6,1.8-3.9c0.6-1.7,1.2-3.4,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6
      c0.1-0.2,0.1-0.4,0.1-0.6c0.4,0.2,0.8,0.3,1.2,0.4c0.4,0.1,0.9,0.3,1.3,0.4c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8
      c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4c1.8,0.2,3.5,0.3,5.2,0.5c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1c0.4,0.1,0.8,0.1,1.3,0.2
      C805.6,865.4,805.6,867.2,805.5,868.9z M805.7,860c0,0.3,0,0.7,0,1c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8.1-1
      c-1.6-0.2-3.2-0.3-4.7-0.5h-0.1c-1.5-0.1-3.1-0.3-4.6-0.4c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5
      c0.1-0.8,0.1-1.6,0.1-2.4c0-0.5,0-1,0-1.5c0.1-1.1,0.1-2.3,0.2-3.4c0.2-3.7,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.6
      c0.2-2.5,0.5-5.2,0.7-7.7c0.1-1.9,0.2-3.8,0.3-5.5v-0.2c0.1-1.1,0.1-2.3,0.2-3.4c0.1-1.7,0.2-3.5,0.4-5.2c0-0.6,0.1-1.2,0.1-1.8
      c0.6,0.3,1.1,0.7,1.6,1c1.5,0.9,3,1.8,4.6,2.8c0.9,0.6,1.8,1.1,2.7,1.7c2.5,1.5,5,3.1,7.4,4.7c2.5,1.8,5.3,2.1,8.1,2.5h0.2
      c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0.1,0.6,0.1s0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6
      c-0.1,1.3-0.2,2.6-0.2,3.9v0.3c-0.1,1.2-0.1,2.5-0.2,3.7c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3c0,1.5,0.1,3.1,0.1,4.7
      c0,1.6,0.1,3.2,0,4.7c-0.1,2.8-0.2,5.6-0.3,8.3v0.1c-0.1,1.7-0.2,3.4-0.2,5.1C805.7,858.1,805.7,859,805.7,860z M848.4,905.8
      c-0.1,0.1-0.2,0.1-0.4,0.1c-0.6,0-1.3,0.1-1.9,0.2h-0.4v-0.2h-0.4c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5
      c-1.6,0.1-3.2,0.1-4.7,0.1c-0.7,0-1.5,0-2.2,0c-0.8,0-1.5,0-2.3,0.1c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2
      c-2.6-0.2-5.3-0.3-7.9-0.5l-2-0.1c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.5-0.4-0.5c0.1-2.4,0.3-4.9,0.5-7.3
      c0.1-1.7,0.2-3.3,0.3-5c0.3-4,0.5-7.9,0.7-11.7c0.1-1.3,0.1-2.7,0.1-4v-0.1c0-1.1,0-2.1,0.1-3.1c0-1.4,0.1-2.7,0.2-4.1V870
      c0-0.9,0.1-1.9,0.1-2.9c0.1-1.2,0.1-2.4,0.1-3.7c0-0.4,0.1-0.5,0.1-0.6c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1
      c3.2,0.1,6.5,0.2,9.7,0.5c2.6,0.2,5.1,0.2,7.6,0c3.2-0.3,6.5-0.4,9.7-0.5h0.2c1.6,0,3.2-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7
      c0.1,3.9,0.2,8,0.4,12.2c0.1,3.3,0.3,6.6,0.5,9.8l0.2,2.9c0.2,4.6,0.5,8.7,0.7,12.9c0.1,1.4,0.2,2.8,0.4,4
      C848.5,905.5,848.5,905.7,848.4,905.8z M847.3,815.1l0.6-0.1l0.1-0.3v0.1c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2
      c2-0.2,4-0.5,5.8-1.5c2-1.2,4-2.4,6-3.7l0.5-0.3c0.3-0.2,0.7-0.4,1-0.6c2.4-1.5,4.9-3.1,7.4-4.7l2.8-1.8l0.1,1.2
      c0.1,1.2,0.1,2.4,0.2,3.6c0.3,3.5,0.5,7,0.8,10.8l0.3,4.3c0.2,2.4,0.4,4.7,0.6,7.1c0.1,1.2,0.2,2.4,0.3,3.6v0.1
      c0.2,2.3,0.4,4.7,0.6,7c0.2,3.4,0.3,6.9,0.4,10.2c0,1.1,0.1,2.2,0.1,3.3c0,0.3,0,0.6,0.1,1c0.1,0.8,0.2,1.6-0.1,2
      c-0.3,0.4-0.9,0.6-1.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.5c-1.7,0.9-3.6,1.1-5.5,1.3c-1.8,0.2-3.7,0.4-5.3,0.6c-1.1,0.1-2.2,0.3-3.4,0.4
      c-0.9,0.1-1.8,0.2-2.7,0.3h-0.1c-1,0.1-2.1,0.2-3.2,0.3c-1.8,0.2-3.5,0.4-5.3,0.7l-2.1,0.3L847.3,815.1z M890.9,888.7
      c-1.6,0.8-3.2,1.6-4.8,2.3l-0.2,0.1c-1.3,0.6-2.6,1.3-3.9,1.9c-0.8,0.4-1.6,0.8-2.5,1.2l-0.4,0.2c-3,1.5-6.1,3-9.2,4.3
      c-1.7,0.8-3.4,1.4-5.1,2c-0.9,0.3-1.8,0.7-2.7,1c-0.8,0.3-1.6,0.6-2.3,1l-0.3,0.1c-1.3,0.5-2.5,1.1-3.8,1.5
      c-0.9,0.3-1.9,0.6-2.8,0.9l-0.2,0.1c-0.4,0.1-0.9,0.3-1.3,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.5
      c-0.2-4.3-0.5-7.9-0.7-11.4c-0.1-1.1-0.2-2.2-0.2-3.3v-0.1c-0.1-1.7-0.3-3.4-0.3-5.1c-0.1-1.3-0.1-2.8-0.1-4c0-0.8,0-1.7,0-2.5
      c0-0.7-0.1-1.5-0.1-2.2s-0.1-1.4-0.1-2.1c0-1,0-2.1-0.1-3.1v-0.2c0-1.3-0.1-2.7-0.1-4.1c0-1-0.1-2-0.2-2.9v-0.1
      c0-0.3,0-0.6-0.1-0.9l3.7-0.3c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.2-0.2,3.2-0.3c1.1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9
      c0.7-0.1,1.4-0.4,2.1-0.7h0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.7-0.3,1-0.4c0.2,0.6,0.4,1.1,0.5,1.7l0.1,0.3
      c0.5,1.7,1.1,3.5,1.7,5.3c0.5,1.5,1.2,3,2.1,5c0.5,1.2,1.1,2.3,1.7,3.4c0.5,1,1,2.1,1.5,3.2c0.8,1.7,1.6,3.5,2.3,5.2l0.2,0.4
      c0.4,0.8,0.8,1.7,1.1,2.5l0.1,0.3c0.2,0.5,0.5,1.1,0.8,1.7c0.1,0.3,0.1,0.3,0.1,0.4C891.4,888.5,891,888.7,890.9,888.7z
       M913.2,866.6l0.2,0.3c-5.4,6.2-11.4,12.2-19.4,19.6c-0.2-0.3-0.3-0.6-0.5-1l-0.3-0.5c-0.6-1.2-1.2-2.3-1.7-3.5
      c-1.5-3.2-2.6-5.8-3.6-8.1c-1.9-4.6-3.8-9-5.5-13.3c-0.4-0.8-0.5-1.5-0.4-2.1c0.1-0.5,0.5-1,0.9-1.3c1-0.7,2-1.4,3-2
      c1.6-1,3.3-2.1,4.8-3.3c1.8-1.4,3.5-3,5.2-4.6c0.7-0.7,1.5-1.4,2.2-2.1c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1
      C902.7,852.3,908,859.5,913.2,866.6z M914.7,723.4c-0.1,1.2-0.2,2.4-0.3,3.5l-0.1,0.8c-0.2,2.4-0.5,5.1-0.7,7.8
      c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6l-0.2,0.4c-0.3,0.7-0.6,1.5-1,2.2l-0.1,0.2c-0.9,2.1-1.8,4.4-2.9,6.5
      c-0.9,1.8-1.9,3.7-2.7,5.3v0.1c-0.5,1-1.1,2.1-1.7,3.1c-0.8,1.5-1.5,3-2.2,4.5c-0.8,1.7-1.6,3.4-2.4,5.1l0.2,0.6
      c-0.7,1.6-1.4,3.2-2.2,4.7c-0.7,1.5-1.4,3.1-2.3,4.7c0.2-1.2,0.4-2.5,0.6-3.7c0.2-1.3,0.5-2.5,0.7-3.8v-0.2c0.5-2.7,1-5.5,1.4-8.3
      c0.4-2.5,0.6-5.1,0.8-7.6c0.1-1.5,0.3-3,0.4-4.5c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4
      c-0.1-0.6-0.2-1.3-0.3-1.9v-0.1c0-0.1,0-0.2,0-0.3l2.2-1.4c1.6-1,3.2-2,4.8-3c0.8-0.5,1.6-1,2.4-1.4c0.8-0.4,1.7-1,2.5-1.5
      c1-0.6,1.9-1.2,2.9-1.8l0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.3L914.7,723.4z M912.8,721.3c-2.5,1.3-4.9,2.8-7.3,4.2l-0.9,0.6
      c-0.8,0.5-1.7,1.1-2.5,1.6c-0.6,0.4-1.2,0.7-1.7,1.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2-0.4
      c-0.2-1.8-0.5-3.6-0.8-5.3l-0.3-2c-0.2-1.1-0.3-2.1-0.5-3.2v-0.2c-0.3-2.3-0.7-4.7-1.1-7.1c-0.3-1.8-0.6-3.6-1-5.4
      c-0.2-0.9-0.3-1.8-0.5-2.7c0-0.1,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3c1.1-0.2,2.1-0.5,3.1-0.8c4.4-1.3,8.7-3.1,12.8-5.1
      c0.6-0.3,1.1-0.6,1.7-0.9l0.3-0.2c1.2-0.6,2.4-1.2,3.6-1.8c0.4-0.2,0.8-0.3,1.2-0.5l0.2-0.1l-0.2,2.4c-0.2,1.9-0.3,3.8-0.5,5.6
      c-0.1,1-0.2,2-0.3,3c-0.2,2.7-0.5,5.5-0.8,8.3c-0.1,0.7-0.3,1.3-0.5,2c-0.3,0.8-0.5,1.6-0.6,2.4
      C915.9,719.2,914.7,720.2,912.8,721.3z M931.7,719.3c0,1.2,0,2.4,0,3.6c0,1.1,0,2.1,0,3.2v0.5c0,2.1-0.1,4.4-0.1,6.7
      c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9h-0.4c-1.6,0.2-3.4,0.4-5.1,0.7c-0.5,0.1-0.8,0-0.9-0.1c-0.1,0-0.1-0.2,0-0.5
      c0.4-1.2,0.8-2.5,1.3-3.7v-0.1c0.3-0.9,0.7-1.8,1-2.8c0.9-2.7,1.8-5.4,2.7-8.2l1.1-3.4l9.3,0.7V719.3z M932.1,715.6
      c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1c-0.1,0-0.2,0-0.3-0.1l2.9-16.9c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0c3.7,0,7.9,0.1,12.1,1.1
      l-0.1,0.8c-0.1,1.1-0.2,2.3-0.3,3.4c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3c-2,2.3-4.1,4.6-5.8,6.5
      C932.6,715.4,932.3,715.6,932.1,715.6z M947.4,706.5c0,0.3,0,0.7,0,1c-0.3,1.6-0.6,3.2-0.9,4.8s-0.6,3.2-0.9,4.8
      c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.8-0.4,1.8-0.9,2.5c-1.4,2.1-3.5,3.8-5.4,5.3c-0.8,0.6-1.6,1.2-2.3,1.9
      c-0.6,0.5-1.2,1.1-1.9,1.6l-0.4,0.3c0-0.5,0-1.1,0-1.6c0-1.8,0-3.4-0.1-5c0-1.2,0-2.4-0.1-3.7v-0.2c0-1.1-0.1-2.3-0.1-3.4
      c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8l0.1-0.1c1.4-1.6,2.7-3,3.9-4.5c0.6-0.7,1.1-1.5,1.6-2.2l0.1-0.1
      c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.4,0.5-0.5,0.7-0.5s0.3,0.1,0.6,0.2c0.5,0.3,1.1,0.5,1.7,0.8c0.2,0.1,0.4,0.1,0.6,0.2
      C947.4,706.4,947.4,706.4,947.4,706.5z M947.7,703.6l-2.5-0.7l1.1-13.5l2.9,1.4L947.7,703.6z M959.2,661.3
      c-0.1,1.2-0.3,2.1-0.6,2.9c-0.6,1.7-1.2,3.4-1.8,5.1v0.1c-0.5,1.4-1,2.8-1.5,4.1c-0.7,1.9-1.4,3.9-2,5.9l-0.3,0.8
      c-0.4,1.1-0.8,2.2-1.2,3.4c-0.4,1.2-0.9,2.4-1.3,3.6l-0.4,1.3l-3-1.2c-0.5-0.1-0.6-0.3-0.6-0.7l0.1-0.5c0.5-5,1.1-10.2,1.5-15.4
      c0.1-1.6,0.1-3.3,0.1-4.9c0-0.9,0-1.9,0-2.8c0-0.7,0.1-1.5,0.1-2.3v-0.1c0-0.5,0.1-0.9,0.1-1.4c0-0.4,0.2-0.5,0.6-0.5
      c0.1,0,0.2,0,0.3,0c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.2,0.3,1.9,0.5c0.8,0.2,1.5,0.4,2.3,0.5C959.2,660.5,959.3,660.9,959.2,661.3
      z"
      />
      <path
        d="M629.5,895.1l-0.3-0.2c-2-1.4-4-2.8-6.1-4.1c-3-1.9-6.2-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.1-1.7-4.6-2.5
      c-0.8-0.4-1.5-0.8-2.3-1.2l-1.3-0.7c-2.3-1.3-4.7-2.6-7-3.9c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6
      c-1.4-2-2.8-4-4.2-6l-0.3-0.4c-0.6-0.9-1.3-1.9-1.9-2.8c-0.6-0.8-1.2-1.7-1.7-2.4c-0.3-0.4-0.7-0.9-1-1.3V856
      c-0.7-0.9-1.4-1.9-2-2.9c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5
      v-0.1c0-0.6,0-1.2-0.1-1.8c-0.1-1.3-0.1-2.6-0.1-3.9c0.1-2,0.2-4.1,0.3-6.1v-0.1c0.1-1.8,0.2-3.8,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4
      c0-2.5,0-5-0.1-7.4v-0.4c0-0.9,0-1.7,0-2.6c0-0.7,0-1.4,0-2.1v-0.4c0-1,0-2,0-3c0-0.4,0-0.8,0-1.2v-0.5c0-1.5,0-3.1,0-4.7
      c0-2.2,0.1-4.4,0.2-6.5c0.1-2,0.1-4.1,0.2-6.1v-0.4c0.1-3.2,0.1-6.1,1.7-9c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5,3.3-7.5l0.1-0.3
      c0.3-0.6,0.5-1.3,0.8-1.9c0.3-0.7,0.5-1.3,0.8-2v-0.1c0.3-0.7,0.5-1.4,0.9-2.1c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1
      c0.4,0,0.7,0,1.1-0.1c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.1,7-6,9.9-8.7
      c1.3-1.2,2.9-2.6,2.9-5c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6c0.2-1,0.4-2,0.5-3V715c0.3-1.8,0.7-3.6,1-5.4
      c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2c0.4-3.2,0.8-6.5,1.1-9.4c0-0.4,0.1-0.8,0.1-1.2c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9
      v-0.1c0.3-0.7,0.6-1.4,0.8-2.1c1.5-4,2.7-7.3,3.7-10.3l0.5-1.3c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5
      c0.4-1.1,0.8-2.4,0.2-3.8c-1-1-1.5-1.9-2.2-2.8c-2.3-3.3-4.7-6.6-6.7-9.4c-0.9-1.3-1.8-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.8,0.4-1.7,0.5-2.5l0.1-0.3
      c0.3-1.4,0.6-2.7,0.9-4.1c0.2-0.8,0.3-1.6,0.5-2.3v-0.1c0.2-0.8,0.3-1.6,0.5-2.4c0.3-1.4,0.6-2.7,0.9-4.1v-0.1c0.5-2.3,1-4.7,1.5-7
      c0.3-1.4,0.5-2.8,0.8-4.3c0.2-1.4,0.5-3,0.8-4.4c0.1-0.5,0.2-1,0.3-1.6v-0.1c0.5-2.1,1-4.2,0.7-6.4c-0.3-3.1-0.7-7.2-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-1-0.4-1.9-0.6-2.8c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.2-2.2-1.9-3.5
      c-0.7-1.2-1.4-2.6-2.2-4c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-0.9-1.4-1.4-2c-0.4-0.5-0.8-1.1-1.1-1.6c-0.5-0.7-0.9-1.4-1.3-2.1
      l-0.1-0.1c-0.8-1.3-1.6-2.6-2.6-3.8c-1-1.3-2-2.5-3-3.6c-0.9-1-1.8-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.8-3.8v-0.1
      c-0.7-1-1.4-2.1-2.2-3.1c-0.6-0.8-1.2-1.5-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.8-6-8.2-8.1c-2.5-1.5-5.2-3-7.9-4.3
      c-2.5-1.2-5-2.3-7.5-3.5c-2-0.9-4.3-1.9-6.5-3c-2.2-1-4.3-2.1-6.4-3.2c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1
      c-2-0.4-4.1-0.5-6-0.6h-0.3c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3
      c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1
      c-3.3,0.2-6.7,0.5-9.8,2.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4
      c-2,0.9-4.1,2-6,3c-1.3,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.8,1.8-5.6,2.6c-1.9,0.9-4,1.8-5.9,2.8c-1,0.5-1.9,1-2.9,1.6
      c-1,0.6-2,1.1-3,1.6c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0.5-0.8,1.1-1.2,1.6c-0.6,0.7-1.2,1.4-1.8,2.1
      c-0.9,1-1.8,2.1-2.6,3.2c-1.1,1.4-2.2,2.9-3.1,4.2l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.1-2.6,3.2v0.1c-0.8,1-1.8,2.2-2.7,3.4
      c-0.7,0.9-1.3,1.8-1.8,2.7c-0.3,0.4-0.6,0.9-0.8,1.3c-0.6,0.9-1.2,1.8-1.7,2.6c-1.2,1.9-2.5,3.8-3.7,5.8c-1.9,3.3-3.7,6.9-5.2,9.9
      c-1,2-1.7,3.7-2.1,5.3c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10-2.3,14.3c-0.3,2.2-0.6,4.6-0.8,6.6c-0.2,1.5-0.3,3.1-0.5,4.7
      c-0.3,2.7-0.2,5.4,0.4,8.3c0.3,1.2,0.5,2.5,0.8,3.7c0.4,2.1,0.9,4.2,1.3,6.3c0.2,1.2,0.5,2.5,0.6,3.6v0.1c0.2,1.3,0.4,2.6,0.7,3.8
      c0.3,1.5,0.6,3,1,4.5c0.3,1.2,0.6,2.5,0.8,3.8c0.3,1.5,0.6,3,0.9,4.4c0.2,0.8,0.3,1.7,0.5,2.5c0.1,0.4,0.2,0.7,0.2,1v0.1
      c0,0,0,0-0.1,0c-0.8,0.2-1.6,0.4-2.5,0.5l-0.5,0.1c-2.1,0.5-4.4,1-6.7,1.5c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1
      c-2.4,3.3-5.3,7.1-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3c0.8,2.9,1.7,5.9,3,9.7c0.4,1.3,1,2.6,1.5,3.9c0.5,1.1,0.9,2.3,1.4,3.5
      c0.7,1.9,1.3,3.8,1.9,5.7l0.1,0.3c0.4,1.1,0.7,2.3,1.1,3.4c0.2,0.5,0.3,0.9,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2
      c0.2,0.9,0.2,1.9,0.3,2.8c0,0.7,0.1,1.4,0.1,2.1l0.1,0.9c0.5,3.9,0.9,8,1.5,12c0.4,2.8,0.9,5.6,1.4,8.3v0.2
      c0.1,0.6,0.2,1.3,0.4,1.9c0,0.2,0.1,0.5,0.1,0.7c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6c0.8,0.8,1.8,1.5,2.7,2.2l0.1-0.1
      v0.1c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7c0.2,0,0.4,0.2,0.5,0.3
      c0.3,0.6,0.5,1.3,0.8,2v0.1c0.3,0.7,0.5,1.4,0.8,2.1c0.3,0.6,0.5,1.3,0.8,1.9c1.1,2.5,2.2,5,3.4,7.5s2.6,5.1,3.9,7.6v0.1
      c0.9,1.7,1.8,3.5,2.8,5.3c0.8,1.7,1.7,3.5,1.7,5.6c0,3.4,0.1,6.8,0.2,10.1c0.1,2.6,0.1,5.2,0.1,7.8c0,3.2,0,6.5,0,9.4v0.6
      c0,1.4,0,2.7,0,4.1v1.4c0,1.7,0,3.7,0,5.7c0,1.6,0.1,3.2,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6c0,1.1,0,2.1,0,3.2c0,1.8,0,3.6,0.1,5.4
      c0.1,2.2,0.2,4.3,0.3,6.4c0.1,1.5,0.2,3.1,0.3,4.6c0.1,1.1,0.1,2.3,0,3.8c0,0.1,0,0.3,0,0.4c0,0.6,0,1.3-0.2,1.7
      c-0.5,1-1.1,2-1.6,3c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.8,1.3-1.2,1.9v0.1
      c-0.7,1-1.3,2.1-2.1,3.1s-1.6,2.1-2.4,3c-0.9,1-1.8,2.2-2.7,3.4l-0.1,0.1c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2
      c-2.6,1.5-5.7,3.2-8.5,4.8c-1,0.6-2.1,1.3-3,1.8c-0.9,0.6-1.9,1.2-2.9,1.8c-0.9,0.5-1.9,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2
      s-2.3,1.5-3.3,2.3c-0.7,0.5-1.5,1-2.3,1.6c-1,0.6-1.9,1.2-3,1.9h-0.1c-0.5,0.3-1,0.6-1.5,0.9l-1,0.6l1.2,0.1c0.1,0,0.1,0,0.2,0
      s0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c1.1-0.5,2.1-1,3.2-1.6l0.4-0.2c3-1.5,6.2-3,9.3-4.6c2-1,4-2.2,6-3.4l1.2-0.7
      c0.7-0.4,1.5-0.9,2.2-1.3l0.1-0.1c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.3-1.2,3.3-1.7c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6
      c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1l0.1-0.1c0.4-0.4,0.7-0.7,1.1-1.1l0.1-0.1c0.7-0.7,1.4-1.5,2.1-2.3c0.7-0.7,1.3-1.4,2-2.3l0.8-0.9
      l0.4,0.5c0.4,0.5,0.8,1,1.2,1.4c0.4,0.4,0.7,0.9,1.1,1.3c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8l0.1,0.1
      c0.6,0.6,1.2,1.2,1.9,1.8c0.7,0.7,1.5,1.4,2.2,2.2c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5
      c-0.1,0.3-0.3,0.6-0.4,1.1c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.1c-0.3,0.7-0.7,1.5-1.1,2.2c-0.8,1.7-1.6,3.4-2.3,5.1
      c-0.9,2.3-1.8,4.6-2.6,6.9c-0.2,0.7-0.5,1.4-0.7,2c-0.1,0.3-0.2,0.7-0.4,1c-0.5,1.4-1,2.8-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7
      c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.4-0.1,0.5l-0.1,0.4l0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1
      c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2c0.9-2.9,1.7-5.3,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7
      c0.5-1.2,1.1-2.3,1.7-3.5l0.1-0.1c0.2-0.3,0.3-0.6,0.5-1c0.4,0.2,0.8,0.5,1.2,0.7h0.1c1.2,0.7,2.3,1.4,3.5,2
      c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.2,1.3,4.8,1.8l0.2,0.1c0.8,0.3,1.6,0.6,2.4,0.9
      c1.1,0.4,2.2,0.8,3.2,1.2c2.2,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,0.7-0.1,1.4-0.2,2.1l-0.1,0.7
      c-0.2,2.7-0.4,5.5-0.6,8.3c-0.1,1.4-0.1,2.8,0,4.1c0,0.6,0,1.2,0,1.8v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.3-2.6,0.3-3.8
      c0-0.8,0.1-1.5,0.1-2.2c0.1-1.1,0.2-2.1,0.3-3.2v-0.1c0.1-1.2,0.2-2.4,0.3-3.5c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0
      c0.6,0.1,1.3,0.1,2,0.1h0.1c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4
      c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.5
      c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.2
      c0.3,3.4,0.5,7,0.9,10.5c0.1,1,0.4,1.9,0.7,2.8v0.1c0.1,0.4,0.2,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4
      c-0.1-1.8-0.3-3.7-0.4-5.3c-0.1-1-0.2-2.3-0.3-3.5c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3
      c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.2,1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.7-1.6,5.5-2.4l0.3-0.1
      c3.1-1.4,6.4-2.8,9.5-4.2c2-0.9,4.4-2,6.8-3.2c1.2-0.6,2.4-1.3,3.6-2l0.1-0.1c0.4-0.2,0.8-0.5,1.2-0.7c0.3,0.6,0.6,1.3,1,2
      c0.9,1.8,1.8,3.6,2.6,5.5c1,2.4,1.9,4.9,2.7,7.3l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.8,8.1c0.4,0.6,1,1,1.6,1
      c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4
      c-0.5-1.6-1.2-3.1-1.8-4.4l-0.3-0.6c-1-2.1-2-4.3-3.1-6.4c-1.1-2-1-2.1,0.6-3.6c1.5-1.4,3-2.8,4.5-4.3l0.6-0.5
      c0.6-0.6,1.2-1.2,1.8-1.8c0.9-1,1.9-2,2.9-2.9c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.2,1.3
      c1.7,1.8,3.3,3.6,4.9,5.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.5,0.8,0.8,1
      c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.2,1.2,3.3,1.8l0.2,0.1c1.5,0.8,3.1,1.7,4.7,2.6c1.2,0.7,2.4,1.4,3.6,2.2c1.3,0.8,2.6,1.6,3.9,2.3
      c2.2,1.2,4.5,2.4,6.6,3.4c1.7,0.9,3.5,1.7,5.3,2.5c0.6,0.3,1.2,0.6,1.8,0.8h0.1c0.2,0,0.5-0.1,0.5-0.4v-0.4h-0.3
      C631,896.1,630.3,895.6,629.5,895.1z M587.2,723.5c-0.1,1.2-0.2,2.3-0.3,3.4l-0.1,0.8c-0.2,2.5-0.5,5.2-0.7,7.7
      c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6l-0.2,0.4c-0.3,0.8-0.7,1.6-1,2.4c-0.9,2-1.8,4.4-2.9,6.5
      c-0.9,1.8-1.8,3.6-2.7,5.3c-0.5,1-1.1,2.1-1.7,3.2c-0.7,1.5-1.5,3-2.2,4.5c-0.8,1.7-1.6,3.4-2.4,5.1l0.1,0.4
      c-0.8,1.7-1.6,3.4-2.3,5.1c-0.7,1.5-1.4,3.1-2.3,4.7c0.2-1.2,0.4-2.5,0.6-3.7c0.2-1.3,0.5-2.7,0.7-4.1c0.5-2.7,1-5.5,1.4-8.3
      c0.4-2.5,0.6-5.1,0.8-7.6c0.1-1.6,0.3-3,0.4-4.5c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4
      c-0.1-0.6-0.2-1.3-0.3-1.9v-0.1c0-0.1,0-0.2,0-0.3l2.1-1.3c1.6-1,3.3-2.1,5-3.1c0.8-0.5,1.6-1,2.4-1.4c0.8-0.4,1.7-1,2.5-1.5
      c1-0.6,2-1.2,2.9-1.8c0.3-0.2,0.5-0.4,0.8-0.5L587.2,723.5z M572.1,807.4c0,2.3,0.1,4.6,0,6.8c-0.1,4.4-0.3,8.9-0.5,13.2l-0.1,3.3
      c0,0.6,0,1.2-0.1,1.8c-0.1,2.6-0.2,5.3-0.5,7.9c-0.1,0.8-0.8,2-1.4,2.3c-1.5,0.6-2.5,1.6-3.5,2.6c-0.5,0.5-1.1,1.1-1.7,1.5
      c-0.7,0.5-1.4,1-2.2,1.7c-0.9,0.7-1.9,1.5-2.8,2.2c-1.2,0.9-2.5,1.8-3.7,2.6l-0.3,0.2c-0.3,0.2-0.6,0.4-0.9,0.6
      c-0.1,0.1-0.3,0.2-0.5,0.3l-0.1-1.4c-0.1-1.4-0.2-2.7-0.3-4.1c-0.1-1.2-0.1-2.3-0.2-3.5v-0.3c-0.1-2-0.2-4.4-0.3-6.6
      c-0.2-2.2-0.4-4.4-0.5-6.6v-0.1c-0.1-1.6-0.3-3.3-0.4-5c-0.2-2.3-0.3-4.6-0.5-6.8v-0.3c-0.2-2.7-0.4-5.4-0.6-8.1
      c-0.3-3.4-0.7-6.8-1-10.1c-0.1-0.6,0.1-0.8,0.5-1.1c4.2-2.6,8.3-5.2,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3c0.8-1.1,1.5-2.1,2.2-3.1
      c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.1-0.3,0.2-0.5c0.8-1.8,1.6-3.6,2.4-5.4l0.4-0.9c0.6-1.5,1.3-2.9,1.9-4.4
      c0.4-0.8,0.7-1.6,1.1-2.5v0.3c0,1.4,0,2.9,0,4.3c0,2.4-0.1,4.8-0.1,7.2c0,2.2-0.1,4.5-0.1,6.8c-0.1,3.4-0.1,7-0.1,10.5
      C572,804.4,572,805.9,572.1,807.4z M514.1,813.2c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.6,0-1,0.1h-0.3
      c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3c-4.9-0.4-9.8-0.9-14.6-1.4h-0.2c-1.5-0.1-3-0.4-4.4-0.6h-0.3
      c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5c0.3-1.1,0.6-2.2,0.9-3.3
      c0.4-1.3,0.7-2.6,1-3.7l0.3-1.2c0.7,0,1.3,0,1.9,0h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0c2.7,0.1,5.9,0.2,9,0.5
      c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2h0.2c0.4,0,0.8,0,1.2,0c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0
      c0.1,0.4,0.2,0.8,0.3,1.2l0.1,0.3c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.8,1.1,3.8,1.6,5.3c0.7,2.3,1.4,4.7,2.1,7.1
      c0.2,0.8,0.1,1.8-0.1,2.7v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1C517.6,812.8,515.8,813,514.1,813.2z
       M518.5,860.7c-0.1,0-0.1,0-0.2,0l-2,0.1c-2.9,0.1-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0
      c-0.7,0-1.5,0.2-2.2,0.3c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2
      c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0-3.4-0.1c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.9-0.1h-0.1
      c0-1.5,0.1-3,0.2-4.5v-0.1c0.1-3.5,0.3-7.2,0.3-10.8c0-1.8,0-3.7,0-5.5v-0.1c0-2.1,0-4.4,0-6.6c0.1-2.1,0.2-4.2,0.4-6.2
      c0.1-1.5,0.2-3.3,0.3-5c0.1-1.5,0.1-3.1,0.1-4.8v-0.3c0-0.5,0-1,0-1.5c5.9,0.6,11.9,0.9,17.7,0.9c5.9,0,11.9-0.3,17.7-0.9l0.2,7
      c0.1,5.1,0.3,10.4,0.4,15.6c0.1,5.1,0.2,10.3,0.4,16L518.5,860.7z M427.6,825.5c0-0.9-0.1-1.8-0.1-2.7v-0.7
      c-0.1-3.4-0.2-6.9-0.3-10.4c0-2.7,0-5.4,0.1-8v-0.1c0-2.3,0.1-4.7,0.1-7c0-4.6-0.1-9.3-0.2-13.9v-1c0-1.8-0.1-3.5-0.2-5.2v-0.1
      c0-1.1-0.1-2.3-0.1-3.4v-0.1c0.5,1.2,1,2.3,1.5,3.5c1.5,3.4,3,6.9,4.4,10.4c0.2,1.4,1,2.5,1.7,3.5c0.4,0.6,0.8,1.2,1.2,1.8
      c0.3,0.5,0.9,0.8,1.4,1.1c0.2,0.1,0.5,0.2,0.7,0.3c0.9,0.6,1.8,1.1,2.6,1.7c2,1.3,4,2.6,6.1,3.7c2.1,1.1,2.8,2.3,2.4,4.6
      c-0.6,3.7-0.8,7.4-1.1,11c-0.1,1-0.2,2.1-0.2,3.1c-0.2,1.9-0.3,3.8-0.4,5.6v0.3c-0.1,1.1-0.1,2.2-0.2,3.3c-0.2,3.3-0.5,6.5-0.7,9.8
      v0.1c-0.2,2.5-0.4,5-0.5,7.5c-0.2,3.3-0.4,6-0.6,8.5c0,0.5,0,0.9,0,1.4v0.1l-0.3-0.2c-0.6-0.4-1.1-0.7-1.6-1.1
      c-0.6-0.4-1.2-0.9-1.9-1.3c-1.5-1-3-2-4.4-3.1c-1.3-1.1-2.6-2.2-3.8-3.4c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.2-0.6-0.4-1-0.6
      c-1.2-0.4-2-1.5-2-2.9c-0.1-2-0.2-4.1-0.3-6.1c-0.1-1-0.1-2-0.2-3v-0.6C427.7,828.6,427.6,827,427.6,825.5z M430.9,756.2
      c-0.3-0.8-0.4-1.7-0.5-2.6c-0.3-2.3-0.6-4.8-0.9-7.3l-0.1-0.7c-0.3-2.4-0.5-5-0.5-7.4c0-1.6,0.3-3.2,0.5-4.7
      c0.1-0.6,0.2-1.1,0.3-1.7s0.2-0.7,0.7-0.8c1.6-0.1,3.3-0.3,4.9-0.5c1.2-0.1,2.6-0.3,4-0.4c1.9-0.2,4-0.3,6.2-0.4c0.3,0,0.6,0,1,0
      c0.7,0,1.4,0,2.1,0c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.7,0.1,1.1v0.1c0.1,1.1,0.2,2.1,0.3,3.2c0,0.4,0,0.9,0,1.4c0,0.3,0,0.7,0,1
      c0,0.4,0,0.8,0,1.2c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2,0.4,3c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4v0.1
      c0.2,2.4,0.5,4.9,0.8,7.3c0.5,3.4,1.1,6.8,1.7,10.2l0.1,0.3c0.1,0.5,0,0.9-0.4,1.4c-1.6,1.9-3.6,3.4-5.5,4.8l-0.1,0.1
      c-0.5,0.4-1,0.7-1.5,1.1c-1.6,1.2-3.1,2.5-4.6,3.8c-0.5,0.5-1.1,0.9-1.6,1.4c-1,0.8-1.9,1.6-3,2.5l-1.4,1.2
      c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-0.9-0.4-1.7-0.6-2.6l-0.4-2.5c-0.7-3.7-1.3-7.6-1.9-11.4c-0.4-2.7-0.7-5.4-1.1-8.1v-0.1
      C431.2,758.5,431,757.3,430.9,756.2z M407.8,720.6c-0.9-4-1.7-7.9-2.3-11.5c-0.5-2.9-1.2-6.7-1.8-10.5c-0.2-1.4-0.4-2.9-0.5-4.3
      v-0.1c-0.1-1-0.2-2.1-0.3-3.1c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8c-0.6-3.7-1.1-7.5-1.7-11.1l-0.1-0.6c-0.1-0.4-0.1-0.9-0.3-1.3
      c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4c0.5-0.1,1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6c0.1,0,0.1,0,0.2,0
      c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.8c0.1,2.5,0.3,5,0.5,7.4c0.1,1,0.2,1.9,0.2,2.9
      c0.1,1.3,0.2,2.7,0.3,4v0.1c0.3,3.6,0.6,7.3,0.9,10.9c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5
      c0.1,1,0.2,2,0.3,2.9c-0.2-0.6-0.4-1.1-0.6-1.7v-0.1C408.4,722.5,408,721.6,407.8,720.6z M381.8,678.1c0-0.2,0-0.3,0.1-0.3
      c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.6-0.8l3-0.3c3.3,5.7,6.3,11.9,5.9,19.5h-0.4c-2.2-0.1-4.5-0.2-6.9-0.2c-3.8,0-7,0.3-9.9,1
      l-0.1-1.1c-0.1-1-0.2-1.9-0.3-2.8c-0.3-3-0.6-6-0.9-9c-0.1-1.1-0.2-2.1-0.3-3.3v-0.2C381.9,679.7,381.9,678.9,381.8,678.1z
       M391.3,632.8c-0.1-0.5-0.1-0.8,0-0.8c1.1-1.4,2.3-2.9,3.3-4.2l0.4-1.2c0.1,0.4,0.1,0.7,0.2,1.1l0.1,0.4c0.3,1.5,0.5,2.9,0.9,4.4
      c0.9,4,1.6,7.3,2.4,10.3c0.4,1.7,0.9,3.4,1.3,5v0.2c0.4,1.4,0.7,2.8,1.1,4.1c0.5,2.1,1.1,4.2,1.6,6.3v0.1c0.5,2,1,4.2,1.6,6.3
      c0.2,0.8,0.1,1.4-0.2,1.9c-0.4,0.6-1.2,1-2.5,1.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0
      c-0.6,0-0.9-0.2-1.1-0.7c-0.3-1.1-0.6-2.3-0.8-3.4c-0.3-1.3-0.6-2.7-0.8-4v-0.1c-0.5-2.5-1-5.1-1.6-7.6c-0.3-1.3-0.7-2.7-1-3.9
      v-0.1c-0.3-1-0.6-2-0.8-3.1c-0.4-1.5-0.7-3-1-4.5v-0.1c-0.1-0.7-0.3-1.4-0.4-2.1c-0.1-0.6-0.3-1.2-0.4-1.7v-0.1
      C391.8,635.4,391.5,634.1,391.3,632.8z M455.4,544.8c0.1,2.1,0.3,4.3,0.4,6.4v0.4c0.2,2.8,0.4,5.8,0.6,8.7c0.1,2.5,0.2,5,0.3,7.4
      c0.1,1.8,0.1,3.7,0.2,5.6c0,0.9,0.1,1.7,0.2,2.6c0.3,0.3,0.4,1,0.4,1.6c0,0.2-0.1,0.5-0.1,0.6c-2.8,1.7-6,3.6-9.1,5.4
      c-2.1,1.2-4.3,2.4-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3c-1.5,0.8-3.1,1.7-4.6,2.5c-0.8,0.4-1.5,0.8-2.4,1.3
      l-0.7,0.4c-0.2-0.6-0.3-1.1-0.5-1.7v-0.1c-0.4-1.6-0.9-3.2-1.2-4.8l-0.2-0.7c-0.7-2.8-1.3-5.8-1.8-8.7c-0.5-3.2-1-6.5-1.4-9.7v-0.2
      c-0.2-1.3-0.4-2.7-0.5-4c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.3-1.7c-0.4-1.6,0.1-2.8,1.7-4c1.4-1,2.7-2,4-2.9
      c1.3-1,2.6-2,3.9-2.9c1.3-0.9,2.7-1.8,4.1-2.7c0.8-0.5,1.6-1,2.3-1.5c1.1-0.7,2.2-1.4,3.3-2.2c2-1.3,4-2.7,6.1-4
      c1.8-1.1,3.7-2.2,5.6-3.2c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5c0.2-0.1,0.4-0.2,0.7-0.4c0,0.5,0,0.9,0,1.4v0.6
      C455.3,541.2,455.3,543,455.4,544.8z M498.3,570.8c0,0.7-0.2,0.8-0.9,0.8c-0.9,0.1-1.8,0.2-2.6,0.2h-0.1c-3,0.3-6.2,0.5-9.2,0.9
      c-5.3,0.7-10.9,1.6-17.7,2.7c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5l-2.3-41.1l41-10.3v2.5c0,2.4,0,4.8,0,7.1
      c0,0.5,0,1.1,0,1.6v0.1c0,0.6,0,1.2,0,1.9c0,1.6,0,3.1-0.1,4.7v0.2c0,1.8-0.1,3.7-0.1,5.5C498.3,555.8,498.2,563.2,498.3,570.8z
       M615.6,696.1l-0.1,0.8c-3-0.7-6.2-1-10.1-1c-2.4,0-4.7,0.1-7.1,0.3c-0.4-7.8,2.7-14.2,5.9-19.4c0.5,0,0.9,0,1.4,0.1h0.7
      c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6c0.1,0,0.2,0.2,0.2,0.3c-0.3,3.9-0.7,8-1.1,11.6l-0.3,4.3
      C615.7,694.7,615.7,695.3,615.6,696.1z M397.3,626.7c-0.3-1.3,0-2.3,0.9-3.2c1.5-1.5,3-3.1,4.2-4.4c1.4-1.4,2.8-2.9,4.2-4.4
      c1.3-1.3,2.5-2.6,3.8-3.9l0.2-0.2c1.7-1.7,3.5-3.6,5.3-5.5c1.1-1.2,2.2-2.4,3.2-3.6c0.5-0.6,1-1.2,1.6-1.8l0.1-0.1l0.1,0.7
      c0.2,0.9,0.3,1.7,0.4,2.6l0.1,0.5c0.5,3.4,1,7,1.5,10.5c0.6,4.1,1.1,8.3,1.6,12.1l0.1,0.6c0.2,1.5,0.4,3,0.5,4.6v0.3
      c0.1,1,0.1,1.9-0.3,2.8c-0.5,1.2-0.8,2.5-1.1,3.8v0.1c-0.1,0.4-0.2,0.9-0.3,1.3l-0.3,1.4c-0.6,2.4-1.2,4.9-1.8,7.4
      c-0.2,0.9-0.4,1.9-0.5,2.8v0.1c-0.2,0.9-0.3,1.9-0.6,2.9c-0.1,0.5-0.4,1-0.8,1.4c-1.2,1.1-2.4,2.2-3.6,3.3l-0.3,0.3
      c-1,0.9-2,1.8-3,2.8c-1.3,1.2-2.6,2.5-3.9,3.8l-0.1,0.1c-0.5,0.5-1,0.9-1.5,1.4l-0.6-2.5c-0.6-2.2-1.1-4.4-1.6-6.5
      c-0.4-1.7-0.8-3.3-1.3-5c-0.7-2.6-1.4-5.3-2-8c-0.9-3.5-1.5-6.2-2.1-8.7c-0.3-1.4-0.6-2.8-0.9-4.1v-0.2
      C398.1,630.4,397.7,628.5,397.3,626.7z M459.5,617.3c-0.2,0-0.3,0-0.5,0c-0.9,0-1.9,0-2.8-0.1c-1.9,0-3.9-0.1-5.9-0.1h-0.1
      c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5c-0.7,0.9-1.4,1.7-2.2,2.5c-0.6,0.6-1.2,1.3-1.8,2
      c-0.9,1.1-1.7,2.2-2.5,3.3v0.1c-0.5,0.7-1,1.3-1.5,2c-0.6,0.7-1.2,1.5-1.7,2.2l-0.1,0.1c-0.2,0.2-0.3,0.4-0.5,0.6l-0.2-1.7
      c-0.1-1.1-0.2-2.1-0.3-3.2c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.8-0.4-3.9-0.7-5.9c-0.5-3.7-1-7.5-1.4-10.7c-0.3-2.3-0.7-4.4-1-6.4
      v-0.1c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.5-3.5l0.4-0.2c1.1-0.6,2.2-1.2,3.3-1.8c1-0.6,2.1-1.2,3.1-1.7l0.3-0.2
      c1.5-0.8,3-1.7,4.6-2.5c1-0.5,2-1.1,3-1.6c1.4-0.7,3-1.5,4.5-2.4c1.5-0.8,3-1.8,4.4-2.6c0.7-0.4,1.3-0.8,2-1.2
      c0.4-0.2,0.7-0.4,1.1-0.7l0.6-0.4l2,37.2L459.5,617.3z M460.3,620.1h0.1h0.1l-1.5,17.3l-0.4,5.1c-0.2,0-0.5,0-0.7,0
      c-0.4,0-0.7,0-1.1,0c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7h-0.1c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1
      c-2.9-1.9-5.8-3.9-8.7-5.9c-0.9-0.6-1.8-1.3-2.7-1.9c-0.3-0.2-0.6-0.5-0.9-0.7c0.4-0.6,0.9-1.1,1.3-1.7c1.1-1.5,2.2-2.9,3.4-4.3
      c1.1-1.3,2.3-2.7,3.5-3.9l0.1-0.1c0.8-0.9,1.7-1.8,2.5-2.8c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0
      c2.2-0.1,4.2-0.1,6.1-0.1c4.2-0.1,7.9-0.1,11.3-0.1C459.7,620,460,620.1,460.3,620.1z M425.7,639.4c0.3-1.1,0.5-2.2,0.8-3.3V636
      c0.1-0.3,0.2-0.6,0.2-1l13.7,9.4l-3.6,3.7c-0.8,0.8-1.6,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.9l1,0.9
      c1.8,1.5,3.6,3.2,5.4,4.7c0.6,0.5,1.2,0.9,1.8,1.3h0.1c0.2,0.1,0.3,0.2,0.5,0.3l-4.3,7.9c-0.2-0.1-0.5-0.3-0.7-0.4
      c-3.3-2.4-7.1-5.2-10.9-8.1c-1.8-1.4-3.4-2.6-4.8-3.7c0,0-0.1-0.3-0.1-0.5C423.5,649.2,424.6,644.2,425.7,639.4z M444.6,661.1
      c0.1-0.1,0.4-0.3,0.5-0.3c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2c0.6,0,1.2,0,1.8,0c0.8,0,1.6,0,2.3,0c1,0,2,0,3,0
      c0.6,0,1.1,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0.1v9.7l-18.6-2.5l0.7-1.3C442.2,665.3,443.4,663.1,444.6,661.1z M438.2,683.1L438.2,683.1
      c0.1-1,0.2-1.9,0.4-2.8c0.4-1.6,0.7-3.1,1-4.4v-0.1c0.1-0.5,0.2-1.1,0.3-1.6l0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1
      c0-0.1,0.3-0.3,0.4-0.3h0.1c3.1,0.3,6.6,0.7,9.9,1.1c1.5,0.2,3,0.5,4.4,0.8c1,0.2,1.9,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1
      c0.1,3.4,0.2,6.3,0.4,8.9c0.1,1.3,0.2,2.7,0.4,3.9v0.1c0.1,0.8,0.2,1.7,0.3,2.5c0.1,0.7,0.1,1.3,0.1,2.1v0.2c0,0.2,0,0.5,0,0.8
      l-24.9,6.7c0-0.2,0-0.4,0.1-0.6c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.4,0.6-2.8,0.8-4.3l0.1-0.5c0.5-2.3,0.9-4.4,1.3-6.5
      C438,685.1,438.1,684.1,438.2,683.1z M459,658.8c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3c-1.5,0-3.1-0.1-4.6-0.3
      c-0.4,0-0.8-0.1-1.2-0.1c0,0,0,0,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.4-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.1
      s-0.4,0-0.5-0.1c-1.8-1.5-3.6-3-5.7-4.8l-1.4-1.2l0.4-0.3c0.4-0.3,0.7-0.6,1-0.9c0.4-0.4,0.8-0.7,1.2-1.1c1.1-0.9,2.2-1.9,3.2-2.9
      c1.5-1.5,3.4-1.5,5.2-1.5h0.3c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.4,0.5,3.6,1.5c1,0.8,2,1.6,3,2.4
      c0.5,0.4,1.1,0.9,1.6,1.3l0.9,0.4c0.7,0.6,1.4,1.2,2.1,1.8c0.2,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.5,0.9
      c-2.6,2.6-5.4,5.2-9.8,4.9C460.4,658.8,459.7,658.8,459,658.8z M476,660.1c-0.1-1,0.4-2.3,1.2-3.2c2.3-2.7,4.8-5.3,7.2-7.8
      c0.6-0.7,1.3-1.4,1.9-2.1c0.5-0.5,1-1,1.6-1.5c0.1-0.1,0.2-0.2,0.3-0.3l0.1,1.2c0.1,1.2,0.2,2.4,0.2,3.5c0.2,2.5,0.5,5.5,0.8,8.4
      c0,0.6-0.2,0.8-0.7,1.1c-1.8,1-3.6,2.2-5.1,3.2l-0.1,0.1c-1.3,0.8-2.6,1.7-4,2.5c-0.5,0.3-1.2,0.6-1.8,0.9
      c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1-0.5-0.2-1-0.3-1.6v-0.1C476.4,663,476.1,661.6,476,660.1z M481.5,648.8l-5.4,5.7
      c-0.5-0.5-0.9-0.9-1.4-1.4c-1.2-1.3-2.5-2.6-3.8-3.8c-2-1.7-4.2-3.5-7.2-5.7c-0.6-0.4-1.3-0.7-2.1-0.9c-0.3-0.1-0.6-0.2-0.8-0.3
      l1.6-22.4c0.1,0,0.3,0.1,0.4,0.1h0.1c0.6,0.2,1.2,0.4,1.7,0.7c0.9,0.5,1.9,0.9,2.8,1.3c2.8,1.3,5.7,2.7,8,5
      c3.6,3.7,6.3,7.3,8.4,10.9c0.6,1,1.3,2,1.9,2.9c0.3,0.5,0.7,1,1,1.5c0.1,0.1,0.2,0.2,0.2,0.4L481.5,648.8z M473.9,658.4
      c-0.1,0.2-0.5,0.5-0.7,0.5c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1l3.5-3.3C474.6,656.1,474.7,656.7,473.9,658.4z M460.8,669.8
      c0.3-2.5,0.3-4.9,0.2-7.8c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1
      h0.1c1.7,0.4,3.3,0.7,5.1,1.1l1.5,0.3l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.3c0,0.1-0.2,0.4-0.4,0.5c-0.7,0.3-1.4,0.6-2.1,0.9
      c-2.9,1.2-5.9,2.4-8.9,3.5c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1C460.7,671.8,460.8,670.6,460.8,669.8z M471,671.2
      c1.4-0.6,2.8-1.2,4.3-1.8l3.6,11.7l-3.4,2.2l-12.9,8.4l-1.5-17.2C464.7,674,467.9,672.6,471,671.2z M575.3,646.9
      c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4c-1.5,1.2-3.1,2.3-4.7,3.4l-0.4,0.3c-1.5,1.1-2.9,2.2-4.4,3.3
      c-1.3,1-2.6,1.9-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9c-0.2,0.1-0.3,0.2-0.5,0.3l-4.5-8c0.1-0.1,0.3-0.2,0.5-0.3
      c0.6-0.4,1.2-0.9,1.8-1.4l0.8-0.7c1.8-1.5,3.6-3,5.3-4.5c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.8-3.4-5.5-5
      c-0.1-0.1-0.1-0.2-0.2-0.3l13.7-9.4c0,0.1,0,0.1,0.1,0.2l0.1,0.2c0.2,0.5,0.4,1,0.5,1.6C573.9,640.8,574.6,644,575.3,646.9z
       M574.1,630.8L574.1,630.8c0.1-1.3,0.2-2.5,0.3-3.6v-0.1c0.1-1.5,0.3-3.1,0.5-4.7c0.2-1.4,0.4-2.8,0.6-4.2c0.1-0.8,0.3-1.6,0.4-2.4
      c0.3-1.8,0.5-3.6,0.8-5.3c0.4-2.9,0.8-5.9,1.3-8.9c0.1-0.5,0.2-0.9,0.4-1.5l0.1-0.2l0.6,0.6c0.7,0.7,1.3,1.4,1.9,2
      c0.9,1,1.8,1.9,2.8,2.9l0.6,0.6c1.7,1.7,3.4,3.6,5.1,5.4c1.2,1.3,2.5,2.6,3.5,3.7l0.1,0.2c0.8,0.9,1.6,1.7,2.4,2.6
      c0.4,0.4,0.8,0.9,1.2,1.4c1.1,1.2,2.2,2.4,3.4,3.4c2.1,1.8,2,3.8,1.5,5.8c-0.6,2.3-1.1,4.7-1.6,6.9v0.1c-0.5,2.1-0.9,4.3-1.5,6.5
      c-0.4,1.7-0.9,3.4-1.3,5.1c-0.4,1.4-0.8,2.7-1.1,4.1c-0.6,2.3-1.1,4.7-1.7,6.9v0.2c-0.2,1-0.5,2-0.7,3c0,0.2-0.1,0.4-0.1,0.6
      c-0.1,0.4-0.2,0.7-0.3,1.1c-0.3,1-0.6,2.1-0.9,3.1l-0.4,1.2c-0.4-0.3-0.7-0.7-1-1l-0.1-0.1c-0.9-1-1.9-2.1-2.9-3.1
      c-1.5-1.4-3-2.9-4.3-4.1l-0.2-0.2c-1.1-1.1-2.3-2.2-3.5-3.3c-0.5-0.5-0.9-1.1-1-1.6c-0.8-3.5-1.6-7-2.4-10.4
      c-0.4-1.6-0.7-3.1-1.1-4.7c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-1.1-0.4-2.2-0.9-3.3C573.9,633.1,574,632,574.1,630.8z M563.9,699.3
      c-0.5-0.1-0.9-0.2-1.3-0.3h-0.1c-1.3-0.1-2.5-0.3-3.6-0.6c-2-0.5-4-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1
      h-0.1c-1.9-0.5-3.8-1-5.7-1.5c-0.8-0.2-1.2-0.5-1.1-1.4c0.3-2.1,0.4-4.1,0.6-6.1c0.2-2.4,0.3-4.8,0.4-7.2l0.1-2.3
      c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6c2.3-0.5,4.4-0.9,6.4-1.2c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1
      c0.7-0.1,1.7-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5c0.3,1.7,0.6,3.4,0.9,5.1v0.2c0.3,1.8,0.7,4.1,1.1,6.2
      c0.4,2.3,0.9,4.6,1.4,7.1v0.1C563.5,697.4,563.7,698.4,563.9,699.3z M540.1,671.1v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0
      c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2h0.1c1.3-0.1,2.6-0.2,3.9-0.2c0.1,0,0.4,0.2,0.5,0.4c1.2,2,2.3,4,3.4,6.1l0.8,1.4
      L540.1,671.1z M569.2,635l-0.1,0.1c-1.6,1.1-3.2,2.2-4.7,3.3c-2.3,1.6-4.7,3.1-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1
      c-1.1-0.1-2.2-0.2-3.3-0.3c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1
      c-0.4,0-0.8,0-1.2,0h-0.2l-1.6-22.5c0.1-0.1,0.2-0.1,0.4-0.1c0.5-0.1,1-0.2,1.5-0.2c2.3,0,4.6,0.1,6.5,0.2h0.4
      c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1c0.7,0,1.3,0.2,1.8,0.8c0.5,0.6,1.1,1.2,1.7,1.7
      c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.4l0.2,0.3c0.4,0.6,0.9,1.2,1.3,1.8C570.4,634.1,569.8,634.6,569.2,635z
       M551.5,644.8l0.3-0.1c2.5,0,4.5,0.9,6.1,2.6c1,1.1,2.1,2,3.3,3.1l0.1,0.1c0.4,0.3,0.8,0.7,1.2,1l-1.3,1.1c-1.7,1.4-3.3,2.7-4.9,4
      c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.2,0H554
      c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1c-2.3,0-4.8,0-6.9,0c-1.8,0-3.7,0-5.6,0h-0.1
      c-2.6,0-4.9-0.9-6.9-2.8c-0.2-0.2-0.5-0.4-0.8-0.6c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4
      c0.9-0.7,1.9-1.5,2.8-2.2c1.2-1,2.4-2,3.6-2.9c1-0.7,2.1-1.2,3-1.2C542.9,644.9,547.4,644.9,551.5,644.8z M507.6,725.6
      c-0.2-1.2-0.4-2.3-0.6-3.5c0-0.1,0-0.1,0-0.2l3.1-0.2c1.9-0.1,3.9-0.3,5.8-0.4c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1
      c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1c0.8,0.5,1.6,1.1,2.4,1.6l0.1,0.1c0.9,0.6,1.8,1.2,2.7,1.8c0.5,0.3,0.5,0.5,0.2,0.9
      c-2.2,3.6-4.2,6.8-6.1,9.8c-0.7,1.1-1.3,2.2-1.9,3.2l-0.2,0.4c-0.3,0.5-0.5,0.6-1.3,0.9c-0.3,0.1-0.6,0.1-0.9,0.1
      c-0.9,0-1.6-0.4-2.4-0.8l-0.4-0.2c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.5-0.3-0.5-0.9
      c0-1.5-0.3-3.1-0.5-4.6v-0.2C508.4,730.3,508,727.9,507.6,725.6z M522.7,711.5l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.7,0.5-1.1l0.1-0.1
      c0.7-1.4,1.4-2.7,2.1-4c0.8-1.3,2.1-1.7,3.5-2.2C522.1,711.8,522.4,711.7,522.7,711.5z M522.3,720l0.6-1.8c0.6-2,1.3-4.1,1.9-6.2
      c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.5-1.9-2.5-2.6c-1.7-1.3-2-3-2.3-4.9
      c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0.2,0.1,0.3,0.3,0.4,0.4c1.1,1.1,2.2,2.1,3.3,3.2l0.6,0.5c2.1,2.1,4.4,4.3,6.7,6.6
      c2,1.9,4.1,3.9,6.3,5.8c0.7,0.6,0.7,1,0.1,1.7c-1.5,2.2-2.9,4.2-4.2,6.1c-0.4,0.6-0.7,1.2-1,1.8c-0.1,0.1-0.1,0.3-0.2,0.4
      L522.3,720z M517.6,705.7c0.9,1.1,1.8,2.1,2.8,3.1l0.9,1c-0.3,0.1-0.5,0.3-0.8,0.4h-0.1c-1,0.5-1.9,1-2.9,1.3c-0.1,0-0.2,0-0.3,0
      c-0.3,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-2.2-0.3-4.3-0.7-6.5-1l-2.2-0.3c0-0.4,0.3-0.7,0.9-1c1.5-0.6,3-1.3,4.4-1.9
      c1.2-0.5,2.5-1.1,3.7-1.6C516.4,705,517.1,705.1,517.6,705.7z M516.4,713.5l-0.5,0.9c-0.9,1.7-1.7,3.3-2.6,4.8c0,0-0.1,0.1-0.2,0.1
      c-2.3,0.2-4.5,0.4-7,0.6L505,720l1.5-8.1L516.4,713.5z M505.7,728.2c0.4,2.2,0.7,4.2,1,6.3c0.1,0.7,0.2,1.5,0.2,2.3
      c0,0.3,0,0.6,0.1,1c-0.6,0.2-1.3,0.4-1.9,0.6c-1.8,0.6-3.5,1.1-5.2,1.4c-0.2,0-0.4,0.1-0.6,0.1c-0.8,0-1.7-0.3-2.6-0.5l-0.2-0.1
      c-1.1-0.3-2.2-0.7-3.4-1.2c-0.4-0.2-0.8-0.3-1.3-0.5l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4c0.1-0.9,0.3-1.7,0.4-2.6l0.1-0.5
      c0.2-1.3,0.4-2.7,0.6-4c0.2-1,0.4-1,0.6-1c0.2,0,0.5,0.1,0.8,0.2c2.9,0.7,5.5,0.7,8.1-0.1c0.4-0.1,0.6-0.2,0.8-0.2
      c0.2,0,0.5,0,0.6,1.1C505.2,724.8,505.4,726.5,505.7,728.2z M493.4,720l-0.7-0.1c-1.4-0.1-2.9-0.2-4.3-0.3h-0.1
      c-0.1,0-0.2-0.1-0.3-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3v-0.1c-0.2-0.4-0.4-0.7-0.6-1.1
      l9.8-1.6l1.5,8.1H493.4z M483.4,719.4l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1c-0.9-2.4-1.6-4.5-2.2-6.6
      c1.7,0.5,3.3,0.9,4.2,2.4c0.8,1.3,1.4,2.6,2.1,4C483,718.6,483.2,719,483.4,719.4z M492.5,709.8l-1.6,0.3c-2.5,0.4-4.8,0.8-7.1,1.1
      c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0.1-0.4,0.1h-0.4c-0.6,0-1.2,0-1.7-0.2c-0.8-0.3-1.5-0.6-2.3-1c-0.2-0.1-0.4-0.2-0.6-0.3
      c0.3-0.3,0.6-0.7,0.9-1c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1c2.5,1,5.2,2.2,8.3,3.6
      c0.2,0.1,0.5,0.4,0.7,0.8C492.5,709.7,492.5,709.8,492.5,709.8z M483.6,697.6L483.6,697.6c-0.1,1.1-0.3,2.2-0.6,3.1
      c-0.2,0.5-0.7,1-1.3,1.4c-0.3,0.3-0.6,0.5-0.9,0.8c-2.4,2.7-4.3,4.8-5.9,6.8c-0.5,0.6-0.9,1.3-0.4,2.5c0.6,1.6,1.1,3.2,1.6,4.7v0.1
      c0.3,0.9,0.5,1.8,0.8,2.6c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.6,1.6-3.8,2.4l-1.1,0.7l-1-1.5c-1-1.4-2-2.9-2.9-4.4l-0.4-0.6
      c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1c1.9-1.7,3.3-3,4.6-4.3c1.9-1.8,3.8-3.6,5.6-5.4l0.8-0.7c1-1,2-1.9,3-2.9
      c1-0.9,1.9-1.8,2.9-2.8l0.8-0.8C483.7,697.2,483.6,697.4,483.6,697.6z M472.9,725.2c0.7-0.4,1.3-0.9,2-1.4c1.2-0.8,2.4-1.7,3.6-2.4
      c1-0.3,2.1-0.3,2.8-0.3c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4l1,0.1c1,0.1,2,0.2,3,0.3c0.3,0,0.7,0.1,1,0.1l-0.3,1.8
      c-0.2,1.2-0.4,2.4-0.6,3.6c-0.1,0.9-0.3,1.8-0.4,2.6c-0.4,2.3-0.8,4.7-1,7c-0.1,1.1-0.5,1.3-1.3,1.6c-0.4,0.2-0.8,0.3-1.3,0.5
      c-0.9,0.3-1.8,0.7-2.6,1.1c-0.3,0.2-0.6,0.2-1,0.2c-0.3,0-0.6-0.1-1-0.1c-0.3-0.1-0.7-0.1-1.1-0.1h-0.1c0,0-0.1-0.1-0.3-0.3
      c-1.2-1.9-2.3-3.8-3.5-5.7c-1.6-2.7-3.2-5.3-4.8-8C472.5,725.7,472.5,725.5,472.9,725.2z M484.5,742.9c0.9-0.5,1.8-0.9,2.7-1.3
      c0.3-0.1,0.6-0.3,0.9-0.4c0.7-0.3,1.3-0.6,2-1c0.4-0.3,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,1.9,0.4l1,0.3c1.9,0.6,3.8,1.2,5.8,1.3
      c1.5,0.1,3.2-0.4,5.1-1.3c0.8-0.4,1.6-0.6,2.3-0.6c0.9,0,1.7,0.2,2.8,0.7c1.8,0.9,3.6,1.8,5.4,2.6h0.1c1.7,0.8,3.3,1.6,4.9,2.4
      c2.3,1.1,4.6,2.3,6.8,3.4c1.1,0.6,2.2,1.1,3.3,1.7c0.3,0.2,0.7,0.3,1,0.5l0.1,0.1l-1.4,0.3c-1,0.2-2,0.4-3,0.6l-1.5,0.3
      c-1.8,0.3-3.6,0.7-5.4,1c-0.4,0.1-0.8-0.1-1.3-0.2c-1.7-0.6-3.1-1.1-4.4-1.5c-2.6-1-5.1-2-7.4-2.9c-0.6-0.2-1.2-0.4-1.8-0.4
      c-0.7,0-1.5,0.2-2.1,0.6c-2.7,1.5-5.5,1.6-8.2,0c-0.7-0.4-1.4-0.6-2.1-0.6c-1.1,0-2.2,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1.1,0.4
      c-1.7,0.6-3.3,1.2-5,1.8l-0.3,0.1c-0.7,0.3-1.5,0.6-2.2,0.8h-0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.5-1.5,0.8-2.5,0.8
      c-0.8,0-1.6-0.2-2.5-0.3c-0.2,0-0.4-0.1-0.6-0.1c-1.8-0.4-3.7-0.7-5.3-1l-0.4-0.1c-0.8-0.1-1.6-0.3-2.4-0.4c-0.1,0-0.2,0-0.4-0.1
      l0.7-0.3c1-0.5,2-1,3-1.5l1.9-0.9c1.9-1,3.9-2,5.9-3S482.5,743.8,484.5,742.9z M511.3,752.5h-2.8c-1.6,0-3.2,0-4.8,0
      c-0.6,0-1.1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1c-1.3-0.1-2.7-0.1-4.4-0.1c-1.1,0-2.1,0-3.3,0
      c-0.8,0-1.7,0-2.5,0c0.9-0.1,1.7-0.5,2.6-0.8c0.1,0,0.1,0,0.2-0.1c0.1,0,0.3-0.1,0.5-0.2c0.8-0.5,1.6-0.8,2.4-0.8
      c1.2,0,2.3,0.5,3.6,1c0.1,0.1,0.3,0.1,0.4,0.2c0.7,0.3,1.3,0.6,2.1,0.6c1,0,2-0.3,3-0.7l0.2-0.1c0.7-0.2,1.4-0.5,2.1-0.8h0.1
      c0.4-0.2,0.8-0.3,1.1-0.5c0.1-0.1,0.3,0,0.4,0C507.8,751.1,509.6,751.8,511.3,752.5z M467.8,753.7c0.9,0.2,1.8,0.4,2.6,0.5
      c2.4,0.5,4.9,0.9,7.3,1.6c0.6,0.2,1.2,0.2,1.9,0.2c0.9,0,1.7-0.1,2.5-0.2c0.7-0.1,1.4-0.2,2.2-0.2c1.6,0,3.2-0.1,4.8-0.2h0.2
      c1.8-0.1,3.7-0.2,5.6-0.2c0.3,0,0.5,0,0.8,0c1.2,0,2.6,0,3.7,0.1c1.1,0,2.3,0.1,3.5,0.1h0.2c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3
      c2.1,0.1,4.2,0.1,6.2,0.6c0.7,0.2,1.4,0.2,2.2,0.2c1.5,0,3-0.3,4.4-0.6c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4
      c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1c-0.5,0.5-1,1-1.5,1.4c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4
      c-2.6,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8c-1.7,0-3.4,0.1-5.1,0.2h-0.2c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1
      c-1.9,0-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6c-0.4,0-0.8-0.3-1.4-0.8c-0.8-0.8-1.7-1.6-2.5-2.4c-1.2-1.2-2.4-2.4-3.7-3.5
      c-1.7-1.5-3.5-3-5.2-4.4c-1.2-1-2.3-1.8-3.3-2.7c-0.6-0.5-1.1-1-1.6-1.6C466.4,753.4,467.1,753.5,467.8,753.7z M526,745.9l-2.6-1.3
      c-1-0.5-1.9-1-2.9-1.5l-1.2-0.6c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5c0.7-1.1,1.4-2.3,2.1-3.4v-0.1
      c1.2-1.9,2.4-3.8,3.4-5.8c2.6-4.7,5.5-9.2,8.6-13.8c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.5,1.1,0.9,1.6,1.5
      c1.8,2,3.5,4,5.3,6l0.8,1c0.3,0.4,0.6,0.8,0.9,1.1c0.6,0.7,1.2,1.5,1.8,2.1c0.5,0.7,0.4,1,0.2,1.5c-0.3,0.6-0.6,1.2-0.9,1.9
      l-0.1,0.1c-0.6,1.4-1.3,2.8-1.9,4.2c-0.3,0.8-0.6,1.6-0.9,2.4c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.6-1.8,3.2-2.8,4.8l-0.3,0.5
      c-0.4,0.7-0.9,1.3-1.3,1.9c-0.3,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.2,0-0.5-0.2
      C531.8,748.8,528.8,747.3,526,745.9z M537.6,672.2c-0.2,0-0.3,0-0.6-0.1c-1.8-0.6-3.5-1.1-5.2-1.8c-1.3-0.5-2.6-1.1-3.9-1.7
      c-1.1-0.5-2-0.9-3-1.3c-0.4-0.2-0.5-0.3-0.4-0.8c0.2-1.3,0.4-2.7,0.6-3.9l0.2-1.1c0.5-0.1,1-0.2,1.5-0.3h0.2c1.5-0.3,3.1-0.7,4.7-1
      c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1c0.5,0.1,1,0.2,1.5,0.3c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2
      c0,1.8,0,3.7,0,5.3c0,0.8,0,1.6,0,2.3c0,0.2,0,0.4,0,0.5c0,0.4,0,0.8,0,1.2C538.1,672,537.9,672.2,537.6,672.2z M538.1,674.6
      l-1.4,17.2l-16.3-10.5l3.6-11.8c1.5,0.5,2.9,1.2,4.3,1.8C531.4,672.6,534.5,674,538.1,674.6z M524.9,656.3c0.2-0.4,0.6-0.7,0.9-1.1
      l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2l3.5,3.3c-0.2,0-0.5,0.1-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4c-0.1,0-0.4-0.2-0.6-0.6
      C524.7,657.5,524.6,656.7,524.9,656.3z M533.5,645.2L533.5,645.2c-0.4,0.2-0.8,0.5-1.2,0.8l-0.2,0.1c-1.5,1.2-3.1,2.4-4.5,3.7
      c-1.2,1.1-2.3,2.3-3.4,3.4c-0.4,0.4-0.8,0.8-1.2,1.2l-10.9-11.5c0-0.1,0.1-0.1,0.1-0.2l0.8-1.3v-0.1c1.3-2,2.6-4,3.9-6
      c2.3-3.4,5.2-7.3,9.2-10.2c1.4-1,3.1-1.8,4.6-2.6c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1c0.2-0.1,0.5-0.2,0.8-0.2
      c0,0,0,0,0.1,0l1.6,22.5C536.3,642.7,534.8,643.9,533.5,645.2z M513.8,647.8c2.6,2.8,5.4,5.7,8,8.8c1.4,1.5,1.8,3,1.3,4.5
      c-0.3,1.2-0.5,2.4-0.7,3.7c-0.1,0.4-0.1,0.9-0.2,1.3l-0.5-0.2c-0.6-0.2-1.1-0.5-1.7-0.8c-1.7-0.9-3.7-2-5.6-3.2
      c-1.4-0.9-2.8-1.8-4.4-3c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.5,0.6-7,0.9-10.6l0.2-2.3c0.2,0.2,0.4,0.3,0.6,0.5
      C512.4,646.4,513.1,647.1,513.8,647.8z M510.9,662.5c1.2,0.8,2.4,1.6,3.6,2.2c2,1.1,4.2,2.2,6.2,3.1c0.9,0.4,1.1,0.7,0.8,1.6
      c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9c-0.3,0.8-0.5,1.7-0.7,2.5l-0.1,0.3c-0.1,0.5-0.3,1-0.4,1.6c0,0.2-0.1,0.3-0.2,0.5
      c-0.2-0.1-0.3-0.2-0.5-0.3l-0.3-0.2c-0.8-0.5-1.5-1.1-2.2-1.6c-2-1.6-3.9-3.2-5.7-4.7c-0.3-0.2-0.5-0.7-0.5-1.1
      c-0.1-1.6-0.1-3.1,0-4.5s0.2-2.8,0.3-4.1l0.1-0.2c0-0.4,0.1-0.7,0.1-1.1C510.1,662,510.5,662.2,510.9,662.5z M511,676.9
      c0.8,0.6,1.7,1.3,2.5,1.9l0.1,0.1c1.8,1.4,3.7,2.9,5.7,4.2c2.1,1.5,4.4,3,6.5,4.2c0.8,0.5,1.6,1,2.4,1.5s1.7,1.1,2.6,1.6
      c1.9,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.4,0.6,0.4,1c-0.3,4-0.6,8-0.9,12v0.2c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8
      c-0.5-0.5-1.1-0.9-1.6-1.4c-1.5-1.3-2.9-2.5-4.3-3.8c-0.8-0.7-1.5-1.5-2.3-2.3l-0.1-0.1c-0.6-0.7-1.3-1.4-2-2
      c-2.6-2.5-5.3-5-8.1-7.5c-1.2-1.1-1.9-2.4-2.3-4c-0.6-3-1.4-6-2.1-9c-0.3-1.2-0.6-2.4-0.9-3.5c-0.1-0.5-0.2-1-0.4-1.5V676.9
      L511,676.9z M514.4,702.9c0,0.2,0.1,0.4,0.2,0.6v0.1c-0.5,0.2-0.9,0.4-1.4,0.6h-0.1c-1.3,0.6-2.6,1.2-3.9,1.7
      c-0.5,0.2-1,0.4-1.6,0.6c-0.6,0.2-1.2,0.4-1.9,0.7c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1v0.1c-0.2,0.9-0.3,1.8-0.5,2.7
      c-0.4,1.7-0.8,3.7-1.3,5.6c-0.1,0.2-0.6,0.5-0.9,0.6c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1c-0.3-1.5-0.5-2.9-0.9-4.5
      c-0.2-1.3-0.5-2.5-0.7-3.8c-0.7-4-0.9-4.2-4.9-5.3c-1.1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2c-0.5-0.2-1-0.5-1.4-0.8
      c-0.1-0.1-0.2-0.1-0.4-0.2c0.2-2,1.8-2.7,3.3-3.4c0.8-0.4,1.9-0.4,2.9-0.5c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.1,1.9-0.2
      c1.7-0.2,3.4-0.4,5.1-0.5c1.1,0,2.2,0.1,3.4,0.2c0.5,0.1,1,0.1,1.5,0.2l2,0.2c1.6,0.2,3.2,0.3,4.9,0.4
      C512,700,514.1,701.8,514.4,702.9z M481.7,696c-2.6,2.1-5,4.5-7.4,6.9c-1,1-2.2,2.1-3.3,3.2c-1.6,1.5-3.3,3.1-4.8,4.5
      c-0.7,0.6-1.3,1.3-2,1.9c0-0.3-0.1-0.6-0.1-1c-0.1-0.9-0.3-1.9-0.4-2.9c-0.2-2.2-0.4-4.6-0.5-6.6c-0.1-1.2-0.2-2.3-0.3-3.5
      c0-0.4-0.1-0.8-0.1-1.1v-0.1c-0.1-0.8-0.2-1.6-0.2-2.4c0-0.3,0.3-0.8,0.6-1c1.9-1.3,3.9-2.5,5.8-3.7c3-1.9,6.1-3.9,9.1-5.9
      c2.3-1.6,4.6-3.3,6.8-5l0.1-0.1c1-0.7,2-1.5,2.9-2.2c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3-0.2,0.6-0.2,0.9c-0.3,1.1-0.6,2.3-0.9,3.5
      l-0.3,1.2c-0.5,2.2-1.1,4.5-1.5,6.8C484.9,691.9,484,694.1,481.7,696z M463.8,715.8c0.4,0.6,0.9,1.3,1.3,1.9l0.1,0.1
      c1.2,1.7,2.2,3.2,3.2,4.9c0.9,1.5,1.8,3,2.6,4.5c0.6,1.1,1.3,2.4,2,3.5c0.7,1.2,1.4,2.3,2.2,3.4l0.1,0.2c0.5,0.7,0.9,1.5,1.4,2.2
      c1,1.5,1.9,3.1,2.8,4.6c0.3,0.6,0.4,0.9,0.3,1.2c-0.1,0.3-0.4,0.5-0.9,0.8l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2
      c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.5-0.2l-1-1.4c-1.6-2.4-3.3-4.8-4.8-7.3c-0.5-0.9-0.9-2-1.3-3c-0.2-0.6-0.4-1.3-0.7-1.9
      c-0.9-2-1.8-4.2-3.1-6.9c-0.4-0.8-0.4-1.2,0.2-1.9c1.1-0.9,2-2.1,2.9-3.2c0.7-0.8,1.3-1.7,2-2.5c1.4-1.5,2.8-3,4.2-4.5l0.4-0.4
      C462.9,716.7,463.3,716.2,463.8,715.8z M454.1,737.8c0.3,0.6,0.6,1.2,0.8,1.9l0.1,0.1c1,2.2,1.9,4.4,3.1,6.5c0.9,1.6,2,3,3.2,4.5
      c0.5,0.7,1,1.3,1.5,1.9c0,0,0,0.1,0.1,0.1c-0.2,1.2,0.6,1.8,1.1,2.2c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7,1.4,1.3,2.1,1.9l0.3,0.3
      c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4c-0.8,0.7-1.6,1.5-2.3,2.2c-1.3,1.2-2.5,2.4-3.9,3.6c-1.2,1.1-2.4,2.1-3.8,3.2l-0.1,0.1
      c-0.4,0.3-0.8,0.7-1.3,1c-0.1-0.9-0.3-1.8-0.4-2.8c-1.7-11.3-3.2-22-3.2-33c0,0.1,0.1,0.4,0.2,0.6l0.2,0.4
      C452.9,735.1,453.5,736.5,454.1,737.8z M457.6,769.6c0.8-0.7,1.5-1.3,2.2-1.9c0.5-0.5,1.1-0.9,1.6-1.4l0.1-0.1c1.2-1,2.4-2,3.5-3.1
      c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.5,0.3l2.6,2.3c1.4,1.3,2.8,2.5,4.2,3.8
      c0.5,0.5,1.1,1,1.6,1.5l0.1,0.1c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5l-0.4,1.2c-0.6,1.8-1.2,3.8-1.7,5.8
      c-0.4,1.5-0.8,3-1.1,4.3l-0.1,0.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.5-0.2,1-0.4,1.5l-0.1,0.4l-20.3-15.9L457.6,769.6z M478.9,786.5
      c0.3-1.3,0.6-2.5,0.9-3.7c0.2-1,0.5-2,0.7-3l0.1-0.3c0.3-1.5,0.7-3,1.1-4.5c0.3-1.1,0.6-2.3,0.9-3.3c0.1-0.3,0.2-0.5,0.7-0.5h0.1
      c3.6,0.1,7.2,0.2,10.8,0.2h1.7c0.9,0,1.9,0,2.8,0.1h0.1c2.9,0.1,5.9,0.1,8.9,0.1c2.4,0,5-0.1,8.4-0.4c0.6,0,0.7,0.1,0.8,0.8
      c0.8,3,1.5,6,2.3,9c0.4,1.5,0.8,3.1,1.2,4.8l0.5,2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.7,0c-0.7,0-1.5,0-2.2,0
      c-3.3,0-6.8,0.2-10.3,0.6c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3c-2.3-0.3-4.8-0.4-8-0.4c-0.9,0-1.9,0-2.8,0
      c-1.4,0-2.8,0-4.3,0h-0.5c-0.4,0-0.7,0-1.1,0L478.9,786.5z M520.4,776.3c-0.4-1.1-0.8-2.3-1.2-3.5c-0.2-0.4-0.3-0.9-0.5-1.3
      c-0.2-0.7-0.2-1,0.3-1.5c1.4-1.3,2.8-2.6,4.1-3.8l0.1-0.1c0.8-0.8,1.7-1.6,2.5-2.4s1.7-1.5,2.5-2.3c0.6-0.5,1.2-1.1,1.8-1.6
      c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4c1.9,1.9,3.9,3.7,6,5.6c1.4,1.3,2.9,2.5,4.4,3.7c0.6,0.5,1.1,0.9,1.7,1.4
      c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1.1c-0.3-1.1-0.7-2.3-0.9-3.4c-0.2-0.8-0.4-1.7-0.5-2.6
      C520.9,778.5,520.7,777.4,520.4,776.3z M541.1,766.2c-2.1-1.9-4.3-3.9-6.3-5.7l-2.5-2.2c-0.3-0.1-0.3-0.2-0.3-0.2
      c0-0.1,0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5l0.1-0.1c0.4-0.4,0.8-0.7,1.1-1c0.1-0.1,0.2-0.2,0.3-0.2c0.5-0.5,1.2-1,1-2.1
      c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.5,0.7-1,1.1-1.5l0.1-0.1c1-1.3,2-2.7,2.9-4.2c1.1-1.7,1.9-3.6,2.7-5.5
      c0.9-2.1,1.8-4.3,2.6-6.3c0.3-0.6,0.5-1.2,0.8-1.9c-0.1,11.7-1.8,23-3.5,35l-0.1,0.8c-0.2-0.2-0.4-0.3-0.6-0.5
      C542.4,767.3,541.7,766.8,541.1,766.2z M546.3,766.2c0.3-1.4,0.6-2.9,0.8-4.4c0.3-1.6,0.4-3.2,0.6-4.7c0.1-0.5,0.1-1,0.2-1.5
      c0.3-3.1,0.7-6.2,1-9.1l0.1-1.3c0.2-2.3,0.5-4.6,0.7-6.9c0-0.4,0-0.8-0.1-1.1c0-0.3-0.1-0.6-0.1-0.9c0-0.7,0.1-1.3,0.2-2
      c0.1-0.7,0.1-1.5,0.2-2.3c0-0.5-0.1-1-0.1-1.5c0-0.1,0-0.3-0.1-0.4c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1h0.2
      c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4c1.6,0.2,3.3,0.4,5,0.5c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2
      c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.2-0.7,6.3v0.1c-0.3,2.5-0.5,5-0.8,7.5c-0.7,5.8-1.6,11.6-2.4,16.5
      c-0.5,3.4-1.2,6.9-1.8,10.3l-0.5,1.8c-0.1,0.3-0.2,0.7-0.3,1c0,0,0,0,0,0.1c-0.8-0.6-1.5-1.3-2.2-1.9l-0.3-0.3
      c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.8-2.1c-0.8-0.7-1.6-1.5-2.3-2.3l-0.1-0.2c-0.1-0.1-0.3-0.3-0.4-0.4
      c-0.1-0.2-0.2-0.4-0.2-0.6C545.9,768.2,546.1,767.3,546.3,766.2z M567.1,728.7c-1.6-0.2-3.3-0.3-4.9-0.5l-1.8-0.2
      c-2.2-0.2-4.6-0.4-6.9-0.6c-0.8,0-1.5,0-2.3,0.1c-0.5,0-1.1,0.1-1.6,0.1c-0.2,0-0.5-0.1-0.9-0.4c-1.8-1.4-3.2-3.2-4.5-4.9
      c-0.7-0.9-1.4-1.8-2.2-2.7c-1.2-1.3-2.5-2.7-4.2-4.1c-1.2-1-1.2-1-0.8-2.6c0.4-2.2,0.6-4.5,0.8-6.6c0.2-1.8,0.3-3.6,0.4-5.4
      l0.1-1.2c0.1-1.1,0.1-2.2,0.2-3.4v-1.2c0.4,0.1,0.8,0.2,1.1,0.2c1.1,0.2,2.1,0.4,3.1,0.7c2.2,0.6,4.3,1.2,6.4,1.8
      c2.5,0.7,5.3,1.5,8.1,2.2c2,0.5,4,0.9,6.2,1.3c1,0.2,1.5,0.7,1.7,1.9c0.6,4,1.2,8,1.7,11.9l0.6,4.2c0.3,1.7,0.5,3.5,0.8,5.2
      l0.6,4.3L567.1,728.7z M566.5,699.4c-0.3-1.5-0.5-3.1-0.9-4.8c-0.2-1.2-0.4-2.5-0.6-3.7c-0.1-0.8-0.3-1.6-0.4-2.4
      c-0.1-0.8-0.2-1.6-0.4-2.5c-0.2-1.1-0.4-2.2-0.6-3.3c-0.2-0.9-0.4-1.9-0.5-2.8c-0.2-0.9-0.3-1.8-0.5-2.6v-0.2c-0.2-1-0.3-2-0.5-3.1
      c-0.2-1-0.4-1.9-0.7-2.7c-0.4-1.1,0.2-1.8,1.2-2.5c1.3-1,2.7-2,4-2.9l0.1-0.1c1.5-1.1,3-2.2,4.5-3.3c1.7-1.3,3.3-2.5,5.1-3.9
      l0.3-0.2c0.6-0.5,1.2-0.9,1.8-1.4c0.2,0.2,0.5,0.4,0.8,0.6l0.2,0.1c0.9,0.7,1.8,1.4,2.6,2.2c1.9,1.8,3.8,3.7,5.6,5.5l0.1,0.1
      c0.8,0.8,1.6,1.6,2.3,2.3v0.1c0.1,0.1,0.2,0.2,0.3,0.2c2.6,1.4,2.8,3.8,2.6,6.2c-0.3,4.8-0.7,9.9-1.3,16.1c0,0.3-0.2,0.5-0.3,0.6
      c-0.7,0.3-1.5,0.7-2.2,1c-3.1,1.5-6.3,3-9.5,4.3c-2.6,1.1-5.3,2.1-7.9,3l-0.2,0.1c-0.7,0.3-1.5,0.5-2.2,0.8c-0.5,0.2-1,0.3-1.5,0.2
      C567,700.3,566.6,700,566.5,699.4z M591.6,712.1c0.1-0.9,0.1-1.6,0.2-2.3c0.5-4.2,0.8-7.6,1.2-10.7c0.2-2.3,0.4-4.5,0.6-7.1
      c0.1-0.8,0.1-1.6,0.2-2.4v-0.1c0.2-2.6,0.4-5.3,0.5-8c0.1-2.6,0.2-5.3,0.2-7.9v-0.2c0-1.1,0-2.3,0.1-3.4c0-0.2,0-0.5,0-0.8V669
      l0.6,0.1c0.7,0.1,1.4,0.3,2.1,0.5c0.2,0,0.4,0.1,0.6,0.1c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.2,0,0.8-0.1,1.3c-0.1,0.2-0.1,0.5-0.2,0.7
      c-0.2,1.6-0.5,3.3-0.8,4.9v0.1c-0.5,3.4-1.1,6.9-1.6,10.3c-0.3,1.9-0.5,3.8-0.6,5.7c-0.2,1.5-0.3,3.1-0.5,4.6
      c-0.3,2.9-0.8,5.5-1.2,8.1c-0.1,0.5-0.2,1.1-0.3,1.6c-0.4,2.2-0.7,4.4-1.3,6.6c-0.2,1-0.4,2.1-0.6,3.1c-0.5,2.5-1,5.1-2.1,7.4
      c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5V725c0.1-2.1,0.2-4.2,0.8-6.1C591.2,716.6,591.4,714.3,591.6,712.1z M616.3,675.5
      c-0.4-0.1-1-0.2-1.4-0.3c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.2,0-3.6,0c-0.8,0-1.6,0-2.4,0c-0.6,0-1.3,0-2,0
      c0.1-0.3,0.3-0.6,0.4-0.9l0.1-0.2c0.5-1.1,0.9-2.1,1.4-3.1c1-2,2.1-3.9,3-5.7c0.6-1.1,1.2-2.3,1.8-3.4c0.1-0.2,0.2-0.5,0.3-0.7
      c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2c0.3,0,0.7,0,1,0c0,0.5,0,1.1,0,1.6c0,1.4,0,2.8,0,4.1
      c-0.1,2.7-0.2,5.4-0.3,8.1l-0.1,1.3C617.7,675.2,617.3,675.5,616.3,675.5z M610.5,659c-2.3,4.4-4.7,9.1-7.5,14.8
      c-0.6,1.2-1.1,2.4-1.6,3.6c-0.3,0.7-0.6,1.4-1,2.2c0,0.1-0.1,0.1-0.1,0.2l5.4-27.7c0.3,0.3,0.6,0.5,0.8,0.7
      c1.5,1.7,2.8,3.4,4.1,4.9C610.8,658,610.9,658.3,610.5,659z M606.3,641.1v0.2c-0.5,2.3-1,4.6-1.5,6.9c-0.2,1-0.5,2-0.9,3
      c-0.3,1-0.6,2-0.9,3c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2c-0.3,0-0.8-0.1-1.3-0.2c-0.2,0-0.5-0.1-0.8-0.2
      c-0.5-0.1-0.9-0.3-1.4-0.5l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2c1.1-4.5,2.2-8.6,3.2-12.7c0.5-1.8,0.9-3.5,1.4-5.3
      c0.9-3.4,1.8-6.8,2.6-10.3c0.4-1.7,0.7-3.4,1-5.1c0.2-0.9,0.3-1.7,0.5-2.6c0.1-0.6,0.3-1.3,0.4-1.9l0.1-0.4l0.4,0.4
      c0.5,0.7,1,1.2,1.5,1.8c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.8,0.8,1.1c1.1,1.1,1.1,1.9,0.7,3.5
      C607.4,636.2,606.8,638.7,606.3,641.1z M603.9,613.4c-0.3,2.1-0.7,4.2-1,6.4c-0.1,0.8-0.3,1.5-0.4,2.3c-0.2-0.2-0.4-0.4-0.5-0.6
      v-0.1c-0.7-0.8-1.4-1.6-2-2.3c-2.6-2.8-6.1-6.5-9.6-10.3c-1.5-1.6-3.1-3.3-4.6-4.7l-0.2-0.2c-0.9-1-1.9-1.9-2.8-2.9
      c-0.4-0.4-0.7-0.8-1.1-1.2l-0.1-0.1c-0.6-0.7-1.4-1.5-2.1-2.2c-0.5-0.4-0.5-0.9-0.4-1.4c0.3-1.3,0.7-2.6,1-3.9v-0.1
      c0.7-2.6,1.4-5.4,2-8.1c0.4-1.7,0.6-3.4,0.8-5.1c0.1-0.7,0.2-1.5,0.3-2.2c0.1-0.7,0.2-1.4,0.3-2.2v-0.1c0.3-1.9,0.6-3.8,0.8-5.7
      c0.1-1,0.2-2,0.3-3c0.1-0.7,0.1-1.5,0.2-2.2c0-0.4,0.1-0.8,0.2-1.3v-0.1c0.3,0.3,0.5,0.6,0.8,0.9l0.1,0.1c1,1.2,2,2.3,2.9,3.4
      c2.8,3.9,5.4,7.4,7.7,10.8c1,1.4,1.9,2.9,2.8,4.3l0.1,0.1c0.5,0.9,1.1,1.7,1.7,2.6c0.3,0.5,0.7,1,1,1.6c1.2,1.9,2.5,3.8,3.8,5.6
      c0.7,0.9,0.9,1.7,0.7,2.9c-0.2,1.2-0.3,2.4-0.5,3.6c-0.2,1.5-0.4,3.1-0.7,4.6c-0.2,1.2-0.3,2.4-0.5,3.6l-0.1,0.5
      C604.5,608.9,604.2,611.2,603.9,613.4z M582.6,564.6l-0.1,0.8c-0.8,5.9-1.7,12.1-2.7,18.1c-0.5,2.7-1.1,5.4-1.8,8v0.1
      c-0.3,1.1-0.5,2.2-0.8,3.2c-0.2,0.7-0.3,1.4-0.5,2l-0.1,0.5c-0.4-0.2-0.9-0.5-1.3-0.7l-0.7-0.4c-1.8-1-3.6-2-5.3-3
      c-1-0.6-2.1-1.1-3.1-1.6h-0.1c-1.1-0.6-2.4-1.2-3.6-1.9c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.1-0.6-2.4-1.4-3.6-2
      c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5c-1.6-0.9-3.2-1.9-4.7-2.8l-0.1-0.1c-0.6-0.4-1.2-0.7-1.8-1.1
      c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.1-0.7-0.2-0.8-0.3c-0.2-0.2-0.1-0.7,0-1.2c0-0.2,0.1-0.4,0.1-0.6c0.1-1.8,0.2-3.6,0.3-5.6
      c0.1-1.4,0.1-2.7,0.2-4.1l0.1-1.4c0-0.9,0.1-1.8,0.1-2.6c0.1-1.9,0.2-3.7,0.3-5.6c0.4-6.5,0.8-13,1.1-19.5c0.4,0.2,0.7,0.4,1.1,0.6
      l0.4,0.2c0.9,0.6,2.2,1.3,3.4,2l2.2,1.3h0.1c2.7,1.6,5.6,3.3,8.3,5c4.5,2.8,7.8,5,10.9,7.1c1.8,1.3,3.6,2.6,5.4,3.9
      c1.7,1.3,3.7,2.8,5.7,4.1C582.8,562.5,582.7,563.4,582.6,564.6z M542.8,580.7l0.3,0.2c1.3,0.8,2.6,1.7,4,2.5c0.8,0.9,2,1.6,3.2,2.2
      l0.6,0.3c1.7,0.9,3.4,1.8,5.1,2.7l1.8,0.9c0.7,0.4,1.4,0.7,2.1,1.1l1,0.5c1.8,1,3.6,1.9,5.4,2.9c1,0.5,1.9,1.1,2.9,1.6l0.3,0.1
      c1.9,1,3.8,2.1,5.8,3.1c1.1,0.5,1.2,1.2,1,2.2c-0.2,1.5-0.5,3-0.7,4.5c-0.6,4.3-1.3,8.7-1.9,13.2c-0.4,2.7-0.6,5.5-0.8,8.2v0.1
      c-0.1,1.3-0.2,2.6-0.4,3.9c0,0.2,0,0.3-0.1,0.5l-0.7-0.9c-0.7-0.9-1.4-1.9-2.2-2.9c-2-2.5-4-4.9-5.9-7.3l-0.5-0.7
      c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0c-0.4,0-0.8,0-1.2,0
      c-0.8,0-1.6,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2c-0.6,0-1.2,0.1-1.8,0.1
      c-0.3,0-0.7,0-1.1,0.1h-0.5l2.1-37.2C541.9,580.2,542.3,580.4,542.8,580.7z M539.1,580.1v0.2c-0.1,1.1-0.2,2-0.3,3.1
      c-0.1,1.6-0.2,3.3-0.2,4.9c-0.1,1.3-0.1,2.7-0.2,4.1c-0.1,1.9-0.2,3.9-0.3,5.8c-0.1,1.6-0.2,3.1-0.3,4.7c-0.1,1.5-0.1,2.9-0.2,4.4
      v0.3c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.6c-0.1,1.8-0.2,3.7-0.3,5.6c0,0.2-0.3,0.5-0.6,0.6c-2.4,1.1-4.8,2.2-7.2,3.2l-0.4,0.2
      c-1,0.4-1.9,0.8-2.9,1.3c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.1,5.5-6.1,8.2l-1,1.4c-0.9,1.2-1.6,2.5-2.3,3.7l-0.1,0.2
      c-0.4,0.7-0.7,0.9-1.2,0.9c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1c-1-0.3-1.9-0.6-2.9-1c-0.4-0.1-0.9-0.3-1.4-0.4
      c-0.2,0-0.3-0.1-0.5-0.1v-65.9c13.8,0.9,27.1,3.4,38.3,5.6C539.1,579.4,539.1,579.8,539.1,580.1z M510,682.2
      c0.8,3.6,1.6,7.3,2.4,10.8v0.1c0.3,1.2,0.5,2.5,0.8,3.7c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1c-0.1,0-0.2,0-0.4,0
      c-0.9-0.1-1.8-0.2-2.7-0.3h-0.1c-2.7-0.3-5.4-0.6-8.2-0.6c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2
      c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.2c0.8-3.5,1.6-7.5,2.5-11.4
      c0.5-1.9,1-3.9,1.6-5.8l0.4-1.4c0.7-2.1,1.1-2.2,2.5-2.3c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1
      c0.2,0,0.5,0,0.7,0h2.9v-0.1c0.5,0,1,0.1,1.4,0.1c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.4
      C508.9,678,509.5,680.1,510,682.2z M490.1,669c-0.1,0.7-0.1,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.4-3.7,2.9-6.2,4.9
      c-0.5,0.4-1,0.8-1.5,1.1l-0.4,0.3c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3v-0.1
      c-0.5-1.6-0.9-3.3-1.5-5c-0.3-0.8,0-1,0.7-1.3c0.4-0.2,0.7-0.4,1.1-0.5l0.2-0.1c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.2-1.3,3.2-2
      l0.2-0.1c0.5-0.3,0.9-0.6,1.4-0.9c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,0.7,0.1,1.1v0.2c0.1,1.4,0.3,2.7,0.4,4
      C490.1,667.7,490.1,668.4,490.1,669z M489.7,641.9c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.3-3.6-3.5-5.4l-0.1-0.2
      c-0.6-1-1.3-1.9-1.9-2.9c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.1l-0.1-0.1c-0.4-0.2-0.8-0.4-1.2-0.5
      c-1-0.5-2.1-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.5-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7c-0.1-1.9-0.2-3.8-0.3-5.6
      c-0.1-2-0.2-4-0.4-5.9v-0.3c-0.1-2-0.3-4-0.4-6.1c-0.1-1.1-0.1-2.2-0.1-3.3v-0.1c0-1.2,0-2.3-0.1-3.5c-0.1-1.6-0.3-3.3-0.4-4.9
      v-0.1c-0.1-0.8-0.1-1.6-0.2-2.3c0-0.1,0-0.2,0-0.3c15.1-3.1,26.9-4.8,38.2-5.6v65.8l-2.1,0.7h-0.1c-2,0.6-3.8,1.3-5.7,1.9
      C490.1,641.9,489.9,641.9,489.7,641.9z M416.9,582.7c0.7,3.2,1.5,6.4,2.2,9.5l0.1,0.3c0.3,1.2,0.6,2.4,0.9,3.6
      c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5,5.4l-0.1,0.1c-1.3,1.4-2.6,2.8-4,4.2c-1.5,1.6-3,3.1-4.4,4.5l-0.1,0.1
      c-1.5,1.5-3,3-4.5,4.6c-1.2,1.2-2.3,2.5-3.4,3.7c-0.5,0.6-1.1,1.2-1.6,1.8c0,0.1-0.1,0.1-0.2,0.2l-0.7-4.5v-0.3
      c-0.5-3.8-1.1-7.4-1.6-11.1c-0.2-1.2-0.3-2.5-0.5-3.8c-0.4-2.6-0.7-5.4-1.2-8.1c-0.3-1.5,0.4-2.5,1.2-3.6c0.1-0.2,0.3-0.4,0.4-0.6
      c1.8-2.7,3.6-5.4,5.3-7.9l0.1-0.2c1.6-2.4,3.4-5.1,5.2-7.7l0.7-1c1.7-2.4,3.4-4.9,5.2-7.2c0.8-1,1.7-1.9,2.6-2.9
      c0.3-0.3,0.5-0.6,0.8-0.9c0.1,0.8,0.2,1.5,0.3,2.2l0.1,0.6c0.3,2.6,0.6,5.1,0.9,7.6l0.2,0.2C415.8,576.2,416.3,579.5,416.9,582.7z
       M392.4,653.2c0.4-0.4,0.7-0.7,1.1-1.1l5.5,27.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.5c-0.3-0.8-0.7-1.6-1-2.3
      c-1.8-4.2-3.8-8.6-6.3-12.6l-0.2-0.3c-0.5-0.8-1.1-1.7-1.3-2.6c-0.3-0.7,0.1-1.7,0.5-2.2C390.2,655.4,391.3,654.3,392.4,653.2z
       M389.3,664.6c1.1,2.2,2.2,4.6,3.2,6.7l0.8,1.7c0.2,0.5,0.4,1,0.7,1.5l0.2,0.4c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0-1,0
      c-0.5,0-0.8,0-1.2,0h-0.8c-2.3,0.1-4.8,0.2-7.2,0.4c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.9
      c0-1.1,0-2.5,0-3.9c0-0.5,0-1,0-1.6c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.9,0.5,1.1,0.9
      C387.6,661.4,388.4,662.9,389.3,664.6z M407.9,690.4c-0.3-3.6-0.5-7.2-0.8-11.2l-0.1-1.5c-0.3-1.8-0.4-3.7-0.5-5.6
      c0-1.3,0.5-2.4,1.7-3.4c1.6-1.4,3.1-2.9,4.6-4.3c0.9-0.9,1.9-1.9,2.9-2.8c1.3-1.2,2.6-2.3,3.8-3.5c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1
      c2.4,1.8,4.7,3.4,6.9,5.1c1.3,1,2.7,2,3.9,3l0.1,0.1c0.8,0.6,1.5,1.2,2.3,1.8c0.8,0.6,1,1.4,0.8,2.5c-0.4,2-0.8,4.1-1.2,6l-0.1,0.5
      c-0.1,0.6-0.2,1.2-0.3,1.7l-0.3,1.8c-0.3,1.8-0.6,3.3-0.9,4.9c-0.3,1.6-0.6,3.2-0.8,4.8l-0.1,0.5c-0.4,2.3-0.8,4.6-1.2,7
      c-0.3,1.6-0.9,1.8-1.5,1.8c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2c-3-1.3-6.1-2.7-9-4.1l-1.9-0.9
      C408.1,691,407.9,690.7,407.9,690.4z M432.7,712.6c0.3-2.5,0.6-4.7,1-6.9c0.1-0.9,0.3-1.8,0.4-2.7c0.1-0.9,0.6-1.4,1.7-1.7
      c4.8-1.1,8.8-2,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1c1.2-0.3,2.5-0.7,3.7-1c0.6-0.2,1.3-0.3,2.1-0.5h0.1c0.2,0,0.3-0.1,0.5-0.1
      c0,0.3,0,0.6,0,0.9v0.5c0.1,1.3,0.1,2.6,0.2,3.9v0.2c0.2,3.4,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4c0.3,0.6,0.1,0.9-0.3,1.4
      c-0.3,0.3-0.7,0.6-1,1c-1,0.9-2.1,1.9-3,3c-1,1.2-2,2.4-3,3.6c-0.8,1-1.5,1.9-2.3,2.7l-0.3,0.4c-0.7,0.8-1.4,1.6-2.2,2.1
      c-0.3,0.2-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0s-0.6,0-0.9,0h-0.1c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3,0-1.9,0c-0.6,0-1.2,0-1.7,0.1
      c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4c0.2-1.1,0.3-2.2,0.5-3.3l0.3-2C431.7,719.9,432.2,716.3,432.7,712.6z M447.8,796.9
      c-1.2-0.8-2.4-1.6-3.7-2.4c-0.9-0.6-1.9-1.2-2.8-1.7c-0.8-0.5-1.7-1-2.5-1.5c-0.8-0.5-1.5-1.1-2.1-1.7c0,0-0.1-0.2-0.1-0.5
      c0.2-0.4,0.2-1,0.2-1.4c0-0.1,0-0.2,0-0.3l18-15l0.5,0.4c0.6,0.5,1.2,1,1.8,1.5c0.8,0.7,1.6,1.3,2.4,2c1.7,1.5,3.5,3,5.4,4.4
      c0.9,0.8,1.9,1.5,2.9,2.2c0.8,0.6,1.7,1.2,2.5,1.9c0.9,0.7,1.8,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2c0.4,0.3,0.4,0.5,0.2,1
      c-0.4,1.5-0.8,2.9-1.2,4.3v0.2c-0.6,2.2-1.2,4.4-1.8,6.6c-0.7,2.3-1.5,4.7-2.2,7l-0.5,1.4c-0.2,0.6-0.5,0.6-0.6,0.6
      c-0.2,0-0.4-0.1-0.6-0.2c-1.1-0.7-2.1-1.4-3.2-2c-1.4-0.9-2.9-1.9-4.5-2.8c-1.2-0.7-2.4-1.5-3.6-2.2l-0.3-0.2
      c-1.5-0.9-3.1-1.8-4.6-2.8C450.8,798.9,449.3,797.9,447.8,796.9z M525,795v-0.1c-0.2-0.9-0.4-1.7-0.7-2.6c-0.1-0.2-0.1-0.4-0.1-0.6
      c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.3-1,2.7-2,4-3c1.5-1.2,3.5-2.6,5.3-4c1.3-1,2.6-2.1,3.8-3.1l0.1-0.1
      c0.7-0.6,1.3-1.1,2-1.7c1.1-0.9,2.2-1.8,3.2-2.7l1.5-1.2c0.4,0.3,0.7,0.6,1.1,0.9l0.7,0.6c1.4,1.1,2.7,2.2,4,3.3
      c0.7,0.6,1.4,1.2,2.1,1.8l0.1,0.1c0.7,0.6,1.4,1.2,2.2,1.8c2.9,2.4,5.3,4.3,7.7,6.1c0.4,0.3,0.4,0.4,0.3,0.7
      c-0.3,0.2-0.3,0.5-0.3,0.7c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.2c-3,2-6.2,4-9.3,6c-1.2,0.7-2.4,1.5-3.5,2.1
      l-0.2,0.1c-0.7,0.4-1.4,0.8-2.1,1.2l-0.5,0.3c-1.4,0.8-2.8,1.7-4.2,2.6c-1.3,0.8-2.7,1.7-4,2.5l-0.3,0.2c-1,0.6-2,1.3-2.9,1.9
      c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.5-1.7-0.8-2.5c-0.7-2-1.4-4-2-6.1
      C526,799,525.5,797,525,795z M588.4,717c-0.2,2.2-1.4,3.2-3.2,4.3c-2.5,1.3-4.9,2.8-7.3,4.2l-0.9,0.6c-0.9,0.5-1.7,1.1-2.5,1.6
      h-0.1c-0.6,0.4-1.1,0.7-1.7,1.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2-0.4c-0.2-1.7-0.5-3.5-0.7-5.2v-0.1
      c-0.1-0.7-0.2-1.4-0.3-2c-0.1-0.9-0.3-1.8-0.4-2.6l-0.1-0.5c-0.3-2.2-0.7-4.8-1.1-7.2c-0.3-1.8-0.6-3.6-0.9-5.3v-0.1
      c-0.2-0.9-0.3-1.8-0.5-2.7c0,0,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3c1.1-0.2,2.1-0.5,3.1-0.8c4.4-1.3,8.7-3.1,12.8-5.1
      c0.6-0.3,1.2-0.6,1.8-0.9l0.2-0.1c1.2-0.6,2.4-1.2,3.6-1.8c0.4-0.2,0.8-0.3,1.2-0.5c0.1,0,0.1,0,0.2-0.1l-0.3,3.2
      c-0.1,1.6-0.3,3.2-0.4,4.7c-0.1,1-0.2,2-0.3,3c-0.2,2.7-0.5,5.5-0.8,8.3c-0.1,0.7-0.3,1.3-0.5,2C588.7,715.4,588.5,716.2,588.4,717
      z M604.2,719.4c0,1.2,0,2.4,0,3.6c0,1,0,2.1,0,3.1v0.7c0,2.2-0.1,4.4-0.1,6.6c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9h-0.4
      c-1.7,0.2-3.4,0.4-5.1,0.7c-0.5,0.1-0.8,0-0.9-0.1c-0.2-0.1-0.2-0.3-0.1-0.6c0.4-1.2,0.9-2.5,1.3-3.7c0.3-0.9,0.7-1.8,1-2.8
      c0.9-2.6,1.7-5.2,2.7-8l0.1-0.2c0.4-1.1,0.7-2.2,1.1-3.4l9.3,0.7V719.4z M604.5,715.7c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1
      c-0.1,0-0.2,0-0.3-0.1l2.9-17c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0c3.7,0,7.9,0.1,12.1,1.1l-0.1,1c-0.1,1.1-0.2,2.2-0.3,3.3
      c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3c-2,2.4-4.1,4.6-5.8,6.5C605,715.5,604.7,715.7,604.5,715.7z
       M619.6,706.5L619.6,706.5c0,0.4,0,0.8,0,1.1c-0.3,1.6-0.6,3.2-0.9,4.8c-0.3,1.6-0.6,3.2-0.9,4.8c-0.1,0.5-0.1,1-0.2,1.5l0.2-0.1
      c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.8-0.4,1.8-0.9,2.5c-1.4,2.1-3.4,3.8-5.4,5.3c-0.8,0.6-1.6,1.2-2.3,1.9c-0.6,0.5-1.2,1.1-1.9,1.6
      l-0.4,0.3v-1.7c0-1.7,0-3.3,0-5c0-1.2-0.1-2.5-0.1-3.7c0-1.3-0.1-2.5-0.1-3.6c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8
      l0.1-0.2c1.3-1.4,2.6-2.9,3.8-4.4c0.6-0.7,1.1-1.4,1.6-2.2c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.4,0.5-0.5,0.7-0.5s0.3,0.1,0.6,0.2
      c0.6,0.3,1.1,0.5,1.7,0.8c0.2,0.1,0.3,0.1,0.5,0.2C619.6,706.4,619.6,706.4,619.6,706.5z M620.1,703.6l-2.5-0.7l1.1-13.5l2.9,1.4
      L620.1,703.6z M631.5,661.5c-0.1,1.2-0.3,2.1-0.6,2.9c-0.6,1.8-1.3,3.6-1.9,5.2c-0.5,1.5-1,2.8-1.5,4.1c-0.7,2-1.4,3.9-2.1,6.1
      l-0.1,0.2c-0.4,1.2-0.9,2.5-1.3,3.7c-0.4,1.1-0.8,2.3-1.2,3.4l-0.5,1.5l-3-1.2c-0.4-0.2-0.5-0.4-0.4-0.8v-0.4
      c0.5-5.1,1.1-10.3,1.5-15.4c0.1-1.6,0.1-3.2,0.1-4.8v-0.1c0-0.9,0-1.8,0-2.7c0-0.8,0.1-1.6,0.1-2.3c0-0.5,0.1-1,0.1-1.5
      c0-0.4,0.2-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.2,0.3,1.9,0.5c0.8,0.2,1.5,0.4,2.3,0.5
      S631.6,661.1,631.5,661.5z M608.9,643.9l2.4,0.6c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2c2.4,0.6,4.6,1.2,6.1,3.5
      c1.5,2.2,3.1,4.3,4.7,6.4l0.1,0.1c0.5,0.7,1.1,1.4,1.6,2.1c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.5-2.8-0.7
      c-2.8-0.3-5.3-0.5-7.5-0.6c-0.1,0-0.3,0-0.4,0c-1.2,0-2.4,0.1-3.6,0.2h-0.1c-0.6,0.1-1.3,0.1-1.9,0.1c-0.1,0-0.2-0.1-0.2-0.1
      c-2-2.3-4-4.8-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5c0.4-0.9,0.6-1.9,0.7-2.8l0.1-0.5c0.1-0.7,0.4-0.7,0.6-0.7
      C608.7,643.9,608.8,643.9,608.9,643.9z M609.9,584.7c0.1-0.6,0.1-1.2,0.2-1.7c0.2-1.1,0.3-2.1,0.5-3.2l0.2-1.1l0.2,1.5
      c0.3,1.7,0.5,3.3,0.8,5l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5c0.2,1.3,0.3,2.7,0.5,4c0.3,2.3,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8
      l-0.1,0.4c-0.5,2-0.8,4-1.2,6c-0.2,1.1-0.4,2.4-0.7,3.6c-0.3,1.7-0.7,3.4-1.1,5c-0.3,1.2-0.5,2.4-0.8,3.7c-0.2,0.9-0.3,1.7-0.5,2.6
      l-0.1,0.6l-0.5-1c-1.1-1.3-2.2-2.6-3.2-3.9c-0.2-0.2-0.3-0.7-0.2-1.1c0.3-1.8,0.5-3.5,0.8-5.2c0.3-1.8,0.6-3.9,0.9-5.9
      c0.2-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.9,0.3-2.8c0.2-1.3,0.4-2.6,0.6-4.1c0.1-1,0.3-1.9,0.4-2.9c0.1-0.9,0.2-1.9,0.3-2.7l0.1-1.1
      c0-0.1,0-0.1,0-0.2c0.7-1.1,0.6-2.3,0.6-3.4c0-0.8-0.1-1.6,0.1-2.3C609.6,587.6,609.8,586.2,609.9,584.7L609.9,584.7z M586,551.6
      c0.3-2.7,0.5-5.4,0.8-8.1c0.2-1.6,0.3-3.3,0.4-4.8c0.1-1.1,0.2-2.3,0.2-3.4c0.1-0.8,0.2-1.6,0.3-2.4c0-0.2,0.1-0.4,0.1-0.7
      c0.3,0.3,0.6,0.7,0.9,1.1c0.9,1,1.8,2.1,2.7,3.2c1.6,2.1,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c1,1.6,1.8,3.3,2.7,4.9
      c0.5,0.9,0.9,1.8,1.4,2.7c1.6,2.9,2.6,6.2,3.3,10.4c0.2,1.8-0.1,3.5-0.3,5.1c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.9-0.8,5.7-1.3,8.5
      v0.1c-0.2,0.9-0.3,1.9-0.5,2.9c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-1.1-1.3-2.1-2-3.2c-1.2-2-2.5-4-3.8-6c-1.1-1.7-2.3-3.3-3.5-4.9
      c-0.7-0.9-1.4-1.8-2-2.8c-0.5-0.8-1.1-1.5-1.6-2.3c-0.9-1.3-1.9-2.7-2.9-4c-1.1-1.4-2.3-2.7-3.4-4c-0.7-0.8-1.4-1.5-2-2.3
      c-0.1-0.2-0.2-0.5-0.2-0.7c0.2-2.2,0.4-4.5,0.6-6.7V551.6z M542,497.5l0.2,0.1c1,0.5,2,1,3,1.5c1.6,0.7,3.1,1.4,4.7,2.2l0.1,0.1
      c2.8,1.3,5.7,2.6,8.6,4c1.4,0.7,2.7,1.4,4,2.1h0.1c1.5,0.8,3,1.7,4.5,2.4c3.3,1.5,6.1,3.9,9,7.7c1.3,1.7,2.7,3.4,4,5.1
      c1,1.3,2.1,2.6,3.1,3.9c0.2,0.3,0.5,0.6,0.7,1c0.4,0.6,0.8,1.2,1.3,1.7c0.8,0.5,0.7,1.1,0.5,1.9c0,0.2-0.1,0.4-0.1,0.6
      c-0.3,2.6-0.5,5.1-0.8,7.6c-0.1,1.1-0.2,2.1-0.3,3.2l-0.5,5.1c-0.3,3.3-0.7,6.6-1,9.9c-0.1,0.6-0.1,1.1-0.1,1.7v0.2
      c0,0.1,0,0.2,0,0.3c-0.2-0.2-0.4-0.3-0.6-0.5l-0.2-0.2c-0.8-0.6-1.6-1.3-2.5-1.9c-0.5-0.4-1-0.7-1.5-1.1l-0.3-0.2
      c-2.1-1.5-4.3-3.1-6.5-4.6c-5.6-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7
      c-0.1,0-0.2-0.3-0.2-0.5c-0.1-1-0.1-1.9,0-2.7l0.1-2.4v-0.1c0.2-3.8,0.4-7.7,0.6-11.5c0.2-2.4-0.2-4.8-0.6-7.1
      c-0.1-0.4-0.1-0.8-0.2-1.2c-0.4-2.4-0.9-4.9-1.5-7.3l-0.2-0.9c-0.2-0.8-0.4-1.6-0.6-2.3c-0.1-0.4-0.3-0.8-0.4-1.2
      c-0.1-0.2-0.1-0.4-0.1-0.6C541.5,497.2,541.8,497.3,542,497.5z M500.8,487.9c0.3,0,0.6,0,0.8,0c0.4,0,0.7,0,1.1,0
      c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2c1.4,0.1,2.9,0.2,4.3,0.3c1.5,0.1,3,0.2,4.5,0.2c1.7,0.1,3.3,0.2,4.8,0.3
      c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1c1.5,0.6,3,1.2,4.5,1.9c0.4,0.2,0.8,0.7,1,1.3c0.5,1.7,1,3.5,1.4,5.2
      c1.2,5,2.2,9.5,2.4,14.1c0,3.1-0.2,6.2-0.4,9.1c-0.1,1.3-0.2,2.6-0.2,3.9c-0.1,1.2-0.1,2.5-0.2,3.7l-0.1,1.3
      c-5.9-1.4-11.8-2.9-17.5-4.4h-0.2c-7.6-2-15.5-4-23.4-5.8V487.9z M500.8,526.8c0-0.4,0.1-0.7,0.1-1l41,10.3l-2.3,41.1
      c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.4-0.3-2.7-0.6-4-0.9c-2.9-0.6-5.7-1.1-8.1-1.5c-1.8-0.3-3.9-0.7-5.9-1
      c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3c-0.9-0.1-1.7-0.1-2.6-0.2c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9
      c0-1.9-0.1-3.9-0.1-5.8v-0.1c0-1.7-0.1-3.6-0.1-5.5c0-1.4,0-2.8,0-4.1v-0.1c0-1.2,0-2.4,0-3.6c0-1,0-2-0.1-3v-0.1
      c0-1.1-0.1-2.2-0.1-3.2C500.6,528.7,500.7,527.8,500.8,526.8z M499.5,641c0.3,0,0.6,0.1,1,0.2l0.2,0.1c1,0.3,2.1,0.7,3.1,1l0.4,0.4
      c1.5,0.5,2.8,0.9,4.2,1.3c0.5,0.1,0.6,0.3,0.5,0.8c-0.4,4.7-0.8,9.5-1.2,14.3c-0.2,2.3-0.4,4.6-0.5,6.9l-0.1,0.9
      c-0.1,1.1-0.2,2.2-0.2,3.3c0,0.1,0,0.2-0.1,0.4l-0.4-0.1c-1.1-0.2-2.3-0.3-3.4-0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0-1.3,0-1.9,0h-0.1
      c-1.6,0-3.3,0.1-5,0.1c-0.9,0-1.7,0.1-2.6,0.2c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1c-0.1-1.2-0.1-2.3-0.2-3.4
      c-0.2-1.8-0.3-3.7-0.5-5.5c-0.2-2.1-0.4-4.6-0.7-7.1c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6c3.3-1.1,6.2-2,8.8-2.8
      C499.4,641,499.4,641,499.5,641z M458.3,505c0.3-1.8,0.7-3.6,1.1-5.4c0.2-1.1,0.6-2.2,1.1-3.5c0.1-0.3,0.5-0.7,0.9-0.8
      c3.8-1.6,7.6-3.2,10.8-4.6s6.8-2.1,11-2.2c2.5-0.1,5.1-0.2,7.6-0.4h0.1c1.6-0.1,3-0.2,4.4-0.2c0.4,0,0.8,0,1.3,0c0.3,0,0.6,0,0.9,0
      h0.2c0.3,0,0.6,0,0.9,0v35.9h-0.2c-7.9,1.8-15.8,3.8-23.5,5.8c-5.7,1.5-11.6,3-17.5,4.4c0-0.1,0-0.3,0-0.4v-0.3
      c-0.1-0.8-0.2-1.6-0.2-2.4c-0.1-1.4-0.1-2.9-0.2-4.3c0-1.4-0.1-2.8-0.2-4.3v-0.6c-0.2-3.6-0.4-7.3,0.4-10.9
      c0.4-1.9,0.8-3.8,1.1-5.7V505z M414,529.1c0.7-0.9,1.4-1.8,2-2.7c1.1-1.5,2.2-3,3.4-4.5c3-3.6,5.7-6.6,8.4-9.4
      c0.6-0.6,1.5-1.1,2.4-1.6c0.4-0.2,0.8-0.4,1.2-0.6c5.1-3.1,10.4-5.5,14.9-7.6c2.7-1.2,5.3-2.5,8-3.8l0.4-0.2c1.1-0.5,2.1-1,3.2-1.5
      c0.1,0,0.1-0.1,0.2-0.1l-0.3,0.2c-0.2,0.6-0.3,1.2-0.5,1.8c-0.2,0.7-0.4,1.3-0.5,2c-0.4,1.3-0.7,2.7-1,4.1c-0.2,1-0.4,2.1-0.6,3.2
      c-0.1,0.9-0.3,2-0.5,2.9c-0.6,3-0.5,6-0.3,9c0,0.4,0,0.9,0.1,1.3c0.1,1.9,0.2,3.9,0.3,5.5c0.1,1.5,0.2,2.9,0.2,4.4c0,1,0,1.9,0,2.8
      c0,0.2-0.2,0.5-0.3,0.6c-1.5,0.8-3,1.7-4.4,2.5c-2.2,1.2-4.4,2.5-6.6,3.8c-1.8,1.1-3.6,2.2-5.3,3.4c-0.9,0.6-1.7,1.1-2.6,1.7
      c-0.8,0.5-1.7,1.1-2.5,1.6l-0.1,0.1c-1.5,0.9-3.4,2.1-5.1,3.3c-2,1.3-4,2.8-5.9,4.2l-0.5,0.4c-1.3,1-2.6,2-4,3
      c-0.5,0.3-0.9,0.7-1.4,1l-0.1-0.9c-0.1-1.2-0.2-2.1-0.3-3.1c-0.2-2.2-0.5-4.5-0.7-6.7c-0.3-2.9-0.6-5.7-0.9-8.6
      c-0.1-1.3-0.2-2.6-0.4-4c-0.1-0.9-0.2-1.9-0.2-2.8c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8C413,530.6,413.3,530,414,529.1z
       M392.2,562.7c0.3-0.6,0.6-1.2,0.8-1.7c0.8-1.7,1.6-3.4,2.6-5.1c2-3.4,4.1-6.9,6.1-10.1c1.6-2.6,3.2-5.1,5-7.5
      c1.1-1.5,2.3-3,3.5-4.4l0.1-0.1c0.4-0.5,0.8-1,1.2-1.5l0.1,1.6c0.1,1.6,0.2,3,0.4,4.5c0.2,1.9,0.4,3.7,0.5,5.6
      c0.2,1.7,0.3,3.3,0.5,5c0.3,3.5,0.6,6.8,0.9,10c0,0.1-0.1,0.3-0.1,0.4c-0.9,1.1-1.9,2.2-2.7,3.2c-1.6,1.9-3.2,3.8-4.7,5.8
      c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.6-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2-1.3-0.4-2.6-0.6-3.9
      c-0.5-3.6-1-6.9-1.6-10.3C389.2,571.4,390,567.2,392.2,562.7z M390.3,629.1v-0.2c-0.1-0.6-0.2-1.2-0.3-1.8
      c-0.2-0.9-0.4-1.9-0.6-2.8c-0.3-1.6-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4c-0.2-1.2-0.4-2.5-0.7-3.8l-0.1-0.4
      c-0.3-1.6-0.6-3.3-1.2-4.9c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.2c0.3-2.6,0.6-5.2,1-7.8c0.4-2.9,0.9-5.9,1.5-8.7
      c0.2,1.2,0.4,2.3,0.5,3.5c0.2,1.6,0.5,3.1,0.7,4.7l0.4,3.1c0,0.1,0,0.2,0.1,0.2c0,0,0,0.1,0,0.2c-0.2,1.1,0.1,2.4,0.4,3.7
      c0.2,0.7,0.3,1.4,0.4,2.1c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.5,1,7c0.3,1.9,0.6,3.8,0.9,5.7c0.4,2.4,0.7,5,1,7.6
      c0,0.3-0.1,0.8-0.4,1.2c-1,1.2-2,2.4-3.1,3.7C390.8,628.6,390.6,628.8,390.3,629.1z M371.5,655c1.3-1.7,2.5-3.4,3.8-5.1
      c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.4,0.5-0.7,0.8-0.9l0.1-0.1c0.6-0.5,1.3-1.1,2-1.2c3.6-1,7.4-2,11.5-3.1c0.4-0.1,0.6-0.1,0.8-0.1
      c0.1-0.2,0.1-0.2,0.4,0.9c0.1,0.4,0.2,0.9,0.3,1.3h0.1l-0.1,0.1c0.2,0.6,0.4,1.3,0.5,1.9c0.1,0.8,0.1,1.5-0.1,1.8
      c-0.3,0.6-0.8,1.1-1.3,1.6c-0.2,0.3-0.5,0.5-0.7,0.8l-0.7,0.8c-0.9,1.1-1.9,2.2-2.7,3.4c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1
      c-1.2,0-2.4-0.1-3.5-0.2h-0.1c-1.4-0.1-2.9-0.2-4.3-0.2c-1.6,0-2.9,0.1-4.2,0.4c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3
      c-0.3,0.1-0.5,0.1-0.8,0.2L371.5,655z M376.8,688.5c-0.2-0.7-0.5-1.5-0.7-2.2c-0.7-2.2-1.4-4.2-2.1-6.2c-0.3-0.9-0.6-1.7-1-2.6
      v-0.1c-0.3-0.8-0.6-1.6-0.9-2.4c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2
      c0-0.1,0.2-0.2,0.3-0.2c1.8-0.4,3.7-0.9,5.5-1.3l1.7-0.3c0.1,0,0.2,0,0.3-0.1c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5
      c0.1,1.6,0.1,3.3,0.2,4.8c0,2,0.1,4.1,0.2,6.1c0.1,1.8,0.4,3.7,0.6,5.4c0.1,1,0.3,2.2,0.4,3.4c0.2,1.9,0.4,4,0.4,6
      c0,0.1-0.3,0.3-0.6,0.4c-0.7,0.3-1.5,0.6-2.2,0.9h-0.1C377.3,688.3,377.1,688.4,376.8,688.5z M379.1,703.7l-1.5-12.8l2.9-1.5
      l1.1,13.5L379.1,703.7z M392.8,726.3c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2c-0.4-0.3-0.8-0.6-1.2-1
      c-0.6-0.5-1.2-1-1.8-1.5c-1.5-1.2-3.1-2.5-4.5-3.8c-1.4-1.3-2.3-2.4-2.8-3.5c-0.4-0.8-0.5-1.7-0.6-2.7c0-0.4-0.1-0.9-0.2-1.4
      c-0.2-0.9-0.3-1.8-0.5-2.7l-0.2-0.9c-0.4-2.1-0.7-4.2-1.1-6.3c-0.1-0.5-0.1-1-0.1-1.5v-0.4c0.1-0.1,0.3-0.2,0.6-0.3
      c0.7-0.3,1.3-0.5,1.9-0.8c0.4-0.2,0.6-0.2,1,0.3l2.1,2.5c2.4,2.9,5,6,7.4,9c0.2,0.2,0.3,0.6,0.3,0.9
      C392.5,720.4,392.5,723,392.8,726.3z M391.1,711.6c-1.7-2.1-3.6-4.3-5.5-6.3c-1.6-1.6-1.7-3.4-1.8-5.4c0-0.1,0-0.5,0-0.9
      c4.1-1,8.3-1.1,11.9-1.1c0.7,0,1.5,0,2.2,0c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2c-0.4,0-0.8,0.1-1.2,0.1h-0.4
      c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4C393,713.9,392,712.7,391.1,711.6z M410.5,736.1c-0.1,0.1-0.4,0.2-0.7,0.1
      c-1.8-0.2-3.6-0.4-5.5-0.6l-0.9-0.1c-2-0.2-4.1-0.5-6.1-0.7c-0.6-0.1-1.1-0.2-1.6-0.3c-0.4-0.1-0.6-0.3-0.5-1v-0.3
      c0.1-1.8,0.2-3.7,0.1-5.5c0-1.3-0.1-2.7-0.2-4v-0.2c0-0.5-0.1-1.1-0.1-1.6c0-0.7-0.1-1.4-0.1-2.2l-0.1-1.6l9.8-0.9
      c0,0.1,0.1,0.3,0.1,0.4c0.1,0.5,0.2,0.9,0.3,1.4c0.8,2.3,1.6,4.5,2.3,6.8l0.1,0.2c0.7,2,1.4,3.9,2,5.9c0.4,1.2,0.8,2.4,1.1,3.5
      C410.6,735.7,410.6,736,410.5,736.1z M411.4,718.8c-0.1-0.7-0.4-1.4-0.6-2.1c-0.2-0.7-0.5-1.5-0.6-2.2c-0.3-2.1-0.5-4.2-0.7-6.3
      c-0.2-0.9-0.3-1.8-0.4-2.7c-0.1-1-0.2-1.9-0.3-2.9v-0.3c-0.2-1.4-0.3-2.9-0.4-4.3c-0.1-1.2-0.2-2.4-0.3-3.5l-0.1-1.2h0.1h0.1
      c0.4,0.1,0.9,0.3,1.2,0.5c1.1,0.5,2.1,1.1,3.2,1.6h0.1c2.1,1.1,4.2,2.1,6.4,3.1c2.5,1.1,5.1,1.9,7.6,2.8l0.2,0.1
      c0.6,0.2,1.2,0.4,1.7,0.6c0.8,0.3,1.6,0.4,2.5,0.6c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.3c-0.1,1-0.3,2-0.4,3c-0.3,1.7-0.5,3.4-0.8,5.1
      c-0.4,2.5-0.8,5-1.2,7.5c-0.2,1.4-0.4,2.9-0.6,4.4c-0.2,1.5-0.4,3.1-0.7,4.8c-0.1,0.4-0.2,0.4-0.2,0.4c-0.2,0-0.4-0.1-0.6-0.2h-0.1
      c-1.3-0.7-2.6-1.5-3.9-2.3l-0.1-0.1c-0.8-0.5-1.5-1-2.3-1.5c-1-0.7-2.1-1.4-3.2-2c-1.2-0.7-2.5-1.5-3.7-2.1c-0.6-0.3-1.2-0.7-1.8-1
      C411.7,719.8,411.5,719.5,411.4,718.8z M423,760.9c-1.4-2.7-2.8-5.4-4.1-8.1c-0.6-1.3-1.2-2.7-1.8-4.1c-0.7-1.6-1.3-3.3-2.2-4.9
      c-1.3-2.5-1.6-5.2-1.9-7.9c0-0.4-0.1-0.7-0.1-1.1c-0.3-3-0.6-6.2-0.9-9.2v-0.4c-0.1-1-0.2-2-0.3-3.1l15.7,9.7
      c-0.7,3.7-1.3,7.6-0.5,11.8c0.4,2.4,0.7,4.9,0.9,7.3c0.1,1.4,0.3,2.9,0.5,4.4c0.7,5.5,1.4,10.8,2.2,16.2c0.3,2.3,0.8,4.7,1.2,6.9
      c0.1,0.5,0.2,0.9,0.3,1.4c-0.3-0.7-0.7-1.5-1-2.2c-1.2-2.8-2.5-5.7-3.9-8.5C425.8,766.4,424.4,763.6,423,760.9z M434.7,883.2
      L434.7,883.2c-0.4,1-0.8,1.8-1.2,2.7c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.1c-7.7-7.1-13.9-13.3-19.4-19.5c0.1-0.2,0.2-0.4,0.4-0.6
      l0.2-0.2c0.5-0.8,1-1.5,1.5-2.3c0.7-0.9,1.3-1.9,2.1-2.9c0.6-0.9,1.2-1.7,1.8-2.6l0.5-0.7c0.7-1,1.4-2,2-3c1.3-2.4,2.7-4.9,4.1-7.4
      c0.6-1.1,1.2-2.1,1.8-3.2c0.3,0.3,0.5,0.5,0.8,0.8c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1c1.8,1.4,3.7,2.6,5.6,3.9l1.4,0.9
      c0.4,0.3,0.5,0.7,0.4,1.4l-0.1,0.3c-0.3,1.4-0.7,2.8-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3c-0.6,1.3-1.2,2.6-1.8,3.7
      c-0.4,0.9-0.9,1.8-1.3,2.7C436.1,880.2,435.4,881.7,434.7,883.2z M477.8,868.9c-0.4,12.1-0.8,24.7-1.9,37.2
      c-0.2-0.1-0.5-0.1-0.7-0.2c-0.8-0.2-1.5-0.4-2.2-0.7c-1.1-0.4-2.2-0.8-3.1-1.2l-0.1-0.1c-0.6-0.2-1.2-0.5-1.8-0.7
      c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-2.6-1-4-1.5c-2.5-1-5.2-2.2-8.2-3.5c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.5-1.7-5.2-2.6l-0.3-0.2
      c-0.8-0.4-1.5-0.8-2.3-1.2c-0.2-0.1-0.5-0.2-0.7-0.4h-0.1c-0.7-0.3-1.4-0.7-2.2-1.1c-0.3-0.1-0.4-0.2-0.4-0.3
      c-0.1,0.1-0.2-0.1,0-0.3c1.3-2.8,2.6-5.6,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.6-1.4,0.9-2v-0.1
      c0.6-1.3,1.2-2.6,1.8-3.9c0.7-1.7,1.2-3.5,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6c0.1-0.2,0.1-0.4,0.1-0.6h0.1c0.4,0.2,0.8,0.3,1.1,0.4
      c0.4,0.1,0.9,0.3,1.3,0.4c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4h0.1
      c1.7,0.2,3.4,0.3,5.1,0.5c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1c0.4,0.1,0.9,0.1,1.3,0.2C477.9,865.4,477.9,867.2,477.8,868.9z
       M478.1,857.3c0,0.9,0,1.8,0,2.8c0,0.3,0,0.7,0,1c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8-1c-1.6-0.2-3.2-0.3-4.7-0.5H462
      c-1.5-0.1-3.1-0.3-4.6-0.4c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5c0.1-0.8,0.1-1.7,0.1-2.4c0-0.5,0-1,0-1.5
      c0.1-1.1,0.1-2.2,0.2-3.3v-0.1c0.2-3.5,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.5v-0.3c0.2-2.4,0.4-5,0.6-7.5
      c0.1-1.8,0.2-3.7,0.3-5.5v-0.2c0.1-1.1,0.1-2.3,0.2-3.4c0.1-1.7,0.2-3.3,0.4-5.2v-0.4c0-0.5,0.1-0.9,0.1-1.4c0.6,0.3,1.1,0.7,1.6,1
      l0.1,0.1c1.5,0.9,3,1.8,4.4,2.7c0.9,0.5,1.7,1,2.6,1.6l0.2,0.1c2.5,1.5,5,3.1,7.4,4.7c2.5,1.8,5.3,2.1,8.1,2.5h0.2
      c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6
      c-0.1,1.4-0.2,2.8-0.2,4.2v0.4c-0.1,1.1-0.1,2.2-0.2,3.4c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3c0,1.3,0,2.9,0.1,4.6
      c0,1.5,0.1,3.1,0,4.7c-0.1,2.9-0.2,5.8-0.3,8.4C478.2,853.9,478.1,855.6,478.1,857.3z M520.7,906.2c0,0-0.1,0.1-0.4,0.1
      c-0.6,0-1.3,0.1-1.9,0.2H518v-0.2h-0.4c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5c-1.6,0.1-3.2,0.1-4.7,0.1
      c-0.7,0-1.5,0-2.2,0s-1.5,0-2.3,0.1c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2c-2.7-0.2-5.3-0.3-7.9-0.5l-2-0.1
      c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.5-0.4-0.5c0.2-2.7,0.3-5.1,0.5-7.4v-0.3c0.1-1.6,0.2-3.3,0.3-4.9
      c0.3-4,0.5-7.8,0.7-11.7c0.1-1.4,0.1-2.8,0.1-4.1v-0.1c0-1,0-2,0.1-2.9c0-1.4,0.1-2.8,0.2-4.2c0-0.9,0.1-1.9,0.1-2.9
      c0-1.1,0.1-2.4,0.1-3.6c0-0.4,0.1-0.5,0.1-0.6c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1c3.2,0.1,6.5,0.2,9.7,0.5
      c2.6,0.2,5.1,0.2,7.6,0c3.2-0.3,6.5-0.4,9.7-0.5h0.2c1.5,0,3.2-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7c0.1,3.9,0.2,8,0.4,12.2
      c0.1,3.4,0.3,6.7,0.5,10l0.2,3c0.2,4.1,0.4,8.4,0.7,12.6c0.1,1.4,0.2,2.8,0.4,4C520.8,905.9,520.8,906.1,520.7,906.2z M519.7,814.9
      l0.6-0.1l0.1-0.1v0.1c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2c2-0.2,4-0.5,5.8-1.5c2-1.2,4.1-2.4,6-3.7l0.2-0.1
      c0.4-0.3,0.9-0.5,1.3-0.8c2.3-1.4,4.6-2.9,6.9-4.4l3.2-2.1c0,0.4,0,0.8,0.1,1.2c0.1,1.2,0.1,2.4,0.2,3.6c0.3,3.6,0.5,7.3,0.8,10.8
      l0.3,3.7c0.2,2.6,0.4,5.1,0.6,7.7c0.1,1.2,0.2,2.4,0.3,3.6c0.2,2.2,0.4,4.7,0.6,7c0.2,3.4,0.3,6.9,0.4,10.2c0,1.1,0.1,2.2,0.1,3.3
      c0,0.3,0,0.6,0.1,0.9c0.1,0.8,0.2,1.6-0.1,2c-0.2,0.4-0.9,0.6-1.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.5c-1.7,0.9-3.6,1.1-5.5,1.3
      c-1.8,0.2-3.7,0.4-5.3,0.6c-1.1,0.1-2.2,0.3-3.4,0.4c-0.9,0.1-1.8,0.2-2.7,0.3c-1,0.1-2.1,0.2-3.2,0.3c-1.8,0.2-3.5,0.4-5.3,0.7
      l-2.1,0.3L519.7,814.9z M563.1,888.7c-1.6,0.8-3.2,1.5-4.7,2.3l-0.3,0.2c-1.3,0.6-2.6,1.3-3.9,1.9c-1,0.5-1.9,0.9-2.8,1.4h-0.1
      c-3,1.4-6.1,2.9-9.2,4.3c-1.7,0.8-3.4,1.4-5.1,2c-0.9,0.3-1.8,0.7-2.7,1c-0.8,0.3-1.6,0.6-2.3,1l-0.2,0.1c-1.3,0.5-2.6,1.1-3.9,1.6
      c-0.9,0.3-1.9,0.6-2.8,0.9H525c-0.4,0.1-0.9,0.3-1.3,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.5c-0.2-4.2-0.5-7.8-0.7-11.4
      c-0.1-1.1-0.2-2.2-0.2-3.3c-0.1-1.6-0.3-3.4-0.3-5.2c-0.1-1.3-0.1-2.6-0.1-3.9v-0.2c0-0.8,0-1.6,0-2.4c0-0.7,0-1.4-0.1-2.1v-0.1
      c0-0.7-0.1-1.4-0.1-2.1c0-1,0-2.1-0.1-3.1c0-1.5-0.1-2.9-0.1-4.3c0-1-0.1-2-0.2-2.9v-0.1c0-0.3,0-0.6-0.1-0.9l3.7-0.4
      c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.2-0.2,3.2-0.3c1.1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9c0.7-0.1,1.4-0.4,2.1-0.7h0.1
      c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.7-0.3,1-0.4c0.2,0.5,0.3,1.1,0.5,1.6l0.1,0.3c0.6,1.8,1.1,3.6,1.7,5.3c0.5,1.5,1.2,3,2.1,5
      c0.5,1.1,1.1,2.3,1.6,3.4v0.1c0.5,1,1,2.1,1.5,3.2c0.8,1.8,1.7,3.7,2.4,5.3l0.1,0.3c0.4,0.8,0.8,1.7,1.1,2.5l0.1,0.3
      c0.2,0.5,0.5,1.1,0.8,1.7c0.1,0.2,0.1,0.3,0.1,0.4C563.6,888.5,563.2,888.7,563.1,888.7z M566.3,886.5c-0.2-0.5-0.5-0.9-0.7-1.4
      c-0.6-1.2-1.2-2.4-1.7-3.6c-1.5-3.2-2.6-5.8-3.6-8.1c-1.9-4.5-3.8-9-5.5-13.3c-0.5-0.8-0.6-1.5-0.5-2.1c0.1-0.5,0.5-1,0.9-1.3
      c0.9-0.7,1.9-1.3,2.9-1.9l0.1-0.1c1.6-1,3.2-2.1,4.8-3.3c1.8-1.4,3.5-3,5.2-4.6l0.1-0.1c0.7-0.7,1.4-1.3,2.1-2
      c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1c3.8,8.3,9.1,15.5,14.3,22.6l0.2,0.3C580.3,873.1,574.3,879.1,566.3,886.5z"
      />
      <path
        d="M304.7,657.2c-2.3-3.3-4.6-6.5-6.7-9.4c-0.9-1.3-1.9-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.8,0.3-1.5,0.5-2.3l0.1-0.5
      c0.3-1.4,0.6-2.7,0.9-4.1c0.2-0.8,0.3-1.5,0.5-2.3c0.2-0.8,0.3-1.7,0.5-2.5c0.3-1.4,0.6-2.8,0.9-4.2v-0.1c0.5-2.3,1-4.6,1.5-7
      c0.3-1.4,0.5-2.8,0.8-4.3c0.2-1.5,0.5-3,0.8-4.4c0.1-0.5,0.2-1.1,0.4-1.6c0.5-2.1,0.9-4.2,0.7-6.4c-0.3-3.1-0.7-7.1-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-0.9-0.4-1.9-0.6-2.8c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.3-2.3-1.9-3.4
      l-0.1-0.1c-0.7-1.3-1.4-2.6-2.2-3.9c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-1-1.4-1.4-2c-0.3-0.5-0.8-1-1.1-1.6
      c-0.5-0.7-0.9-1.4-1.3-2.1v-0.1c-0.8-1.3-1.6-2.6-2.6-3.8c-1-1.3-2-2.5-3-3.6c-0.9-1-1.9-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.8-3.8
      c-0.7-1-1.5-2.1-2.2-3.1c-0.6-0.8-1.2-1.5-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.7-6-8.2-8c-2.5-1.5-5.2-3-7.9-4.3
      c-2.5-1.2-5-2.3-7.5-3.5c-2-0.9-4.3-1.9-6.5-3c-2.1-1-4.2-2.1-6.3-3.1l-0.1-0.1c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1
      c-2-0.4-4.1-0.5-6-0.6h-0.3c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3
      c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1
      c-3.3,0.2-6.7,0.5-9.8,2.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4
      c-2,0.9-4.1,2-6,3c-1.4,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.9,1.8-5.6,2.6h-0.1c-1.9,0.9-3.9,1.8-5.9,2.7c-1,0.5-1.9,1-2.9,1.6
      c-1,0.6-2,1.1-3,1.6c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1.1-1.2,1.5c-0.4,0.5-0.8,1.1-1.2,1.6c-0.6,0.7-1.2,1.4-1.8,2.1
      c-0.9,1-1.8,2.1-2.6,3.2c-1.1,1.4-2.2,2.9-3.1,4.2l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.2-2.6,3.2c-0.9,1.1-1.8,2.2-2.7,3.4
      c-0.7,0.9-1.3,1.8-1.8,2.7c-0.3,0.4-0.6,0.9-0.8,1.3c-0.6,0.9-1.2,1.8-1.7,2.6c-1.3,1.9-2.5,3.8-3.7,5.8c-1.9,3.3-3.7,6.9-5.2,9.9
      c-1,2-1.7,3.7-2.1,5.3c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10.1-2.3,14.3c-0.3,2.2-0.5,4.4-0.8,6.6v0.1
      c-0.2,1.5-0.3,3.1-0.5,4.6c-0.3,2.7-0.2,5.4,0.4,8.3c0.3,1.2,0.5,2.5,0.8,3.7v0.1c0.4,2,0.9,4.1,1.3,6.2c0.2,1.2,0.4,2.4,0.6,3.6
      c0.2,1.1,0.4,2.6,0.7,3.9c0.3,1.5,0.7,3.1,1,4.5v0.1c0.3,1.2,0.6,2.5,0.8,3.7c0.3,1.5,0.6,3.1,0.9,4.5l0.1,0.4
      c0.1,0.7,0.3,1.4,0.4,2.1c0.1,0.3,0.1,0.7,0.2,1v0.1c0,0,0,0-0.1,0c-0.8,0.2-1.6,0.4-2.4,0.5l-0.6,0.1c-2.2,0.5-4.4,1-6.7,1.5
      c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1c-2.5,3.3-5.3,7.2-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3
      c0.8,2.9,1.7,5.9,3,9.7c0.5,1.3,1,2.6,1.5,3.9v0.1c0.5,1.1,0.9,2.3,1.3,3.4c0.7,1.9,1.3,4,1.9,5.8c0.4,1.3,0.8,2.4,1.2,3.6
      c0.2,0.5,0.4,1,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2c0.2,0.9,0.2,1.9,0.3,2.8c0,0.6,0.1,1.4,0.1,2.1l0.1,0.9c0.5,4,0.9,8,1.5,12
      c0.4,2.8,0.9,5.5,1.4,8.2c0.1,0.7,0.3,1.4,0.4,2.1c0,0.2,0.1,0.4,0.1,0.7c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6
      c0.8,0.8,1.8,1.5,2.7,2.2c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7
      c0.2,0,0.4,0.2,0.5,0.3c0.3,0.7,0.5,1.4,0.8,2c0.3,0.7,0.5,1.4,0.8,2.1c0.3,0.6,0.6,1.3,0.8,1.9v0.1c1.1,2.5,2.1,5,3.3,7.5
      c1.3,2.6,2.7,5.3,3.9,7.7l0.1,0.2c0.9,1.7,1.8,3.4,2.7,5.2c0.8,1.7,1.7,3.5,1.7,5.6c0,3.4,0.1,6.8,0.2,10.1
      c0.1,2.6,0.1,5.2,0.1,7.8c0,3.1,0,6.3,0,9.4c0,1.6,0,3.1,0,4.7v1.5c0,1.8,0,3.7,0,5.6c0,1.6,0.1,3.2,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6
      c0,1.1,0,2.1,0,3.2v0.1c0,1.8,0,3.6,0.1,5.4c0.1,2.1,0.2,4.2,0.3,6.2v0.2c0.1,1.5,0.2,3.1,0.3,4.6c0.1,1.1,0.1,2.3,0,3.8
      c0,0.1,0,0.2,0,0.3v0.1c0,0.6,0,1.3-0.2,1.7c-0.5,1-1.1,2-1.7,3c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6
      c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.8,1.3-1.2,1.9v0.1c-0.6,1-1.3,2.1-2,3.1c-0.8,1-1.6,2-2.4,3c-0.9,1.1-1.8,2.2-2.7,3.4l-0.1,0.1
      c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2c-2.8,1.6-5.7,3.2-8.5,4.8c-1,0.6-2.1,1.3-3,1.8c-1,0.6-1.9,1.2-2.9,1.8
      c-0.9,0.5-1.8,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2c-1.2,0.7-2.3,1.5-3.4,2.3c-0.7,0.5-1.5,1-2.3,1.6c-1,0.6-2,1.3-3,1.9l-0.1,0.1
      c-0.4,0.3-0.9,0.5-1.3,0.8l-1,0.6l1.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c1-0.5,2-1,3-1.5l0.6-0.3
      c3-1.5,6.2-3,9.2-4.6c2.1-1,4.1-2.3,6-3.4l1.2-0.7c0.8-0.5,1.5-0.9,2.2-1.4c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.3-1.2,3.4-1.7
      c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1l0.1-0.1c0.3-0.4,0.7-0.7,1.1-1.1l0.2-0.2
      c0.7-0.7,1.4-1.4,2.1-2.2c0.7-0.7,1.3-1.5,2-2.3l0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.7l0.3,0.4c0.4,0.5,0.8,1,1.3,1.5
      c0.3,0.4,0.6,0.8,1,1.2l0.1,0.1c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8c0.7,0.6,1.3,1.2,2,1.9s1.5,1.4,2.2,2.2
      c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5v0.1c-0.1,0.3-0.2,0.6-0.4,1c0,0.1-0.1,0.1-0.1,0.2
      l-0.1,0.2c-0.3,0.7-0.7,1.4-1,2.1v0.1c-0.8,1.7-1.6,3.4-2.3,5.1c-0.9,2.2-1.7,4.6-2.5,6.8l-0.2,0.5c-0.2,0.5-0.4,1.1-0.6,1.6
      c-0.1,0.3-0.2,0.7-0.4,1c-0.5,1.4-1,2.8-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.3-0.1,0.5l-0.1,0.4
      l0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2
      c0.9-2.9,1.7-5.2,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7c0.5-1.2,1.1-2.4,1.7-3.5l0.1-0.3c0.1-0.3,0.3-0.5,0.4-0.8c0.4,0.3,0.9,0.5,1.3,0.8
      c1.2,0.7,2.3,1.4,3.5,2c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.2,1.3,4.8,1.8l0.2,0.1c0.8,0.3,1.6,0.6,2.4,0.9
      c1.1,0.4,2.1,0.8,3.2,1.2c2.3,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,1-0.1,1.9-0.2,2.9v0.1c-0.2,2.7-0.4,5.5-0.6,8.2
      c-0.1,1.4-0.1,2.8,0,4.1v0.2c0,0.6,0,1.1,0,1.7v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.2-2.6,0.3-3.8v-0.1
      c0-0.7,0.1-1.4,0.1-2.1c0.1-1,0.2-2.1,0.3-3.2c0.1-1.3,0.2-2.5,0.3-3.7c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0
      c0.6,0.1,1.3,0.1,2,0.1h0.1c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4
      c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.5
      c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.2
      c0.3,3.4,0.5,7,0.9,10.5c0.1,1,0.4,1.9,0.7,2.8v0.1c0.1,0.4,0.2,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4
      c-0.1-1.8-0.3-3.6-0.4-5.3c-0.1-1-0.2-2.3-0.3-3.5c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3
      c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.2,1.1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.8-1.6,5.6-2.4
      l0.3-0.1c3.1-1.4,6.4-2.8,9.5-4.2c2-0.9,4.4-2,6.8-3.2c1.2-0.6,2.4-1.3,3.6-2l0.1-0.1c0.4-0.2,0.8-0.5,1.2-0.7c0.3,0.6,0.6,1.3,1,2
      c0.9,1.8,1.8,3.6,2.5,5.5c1,2.4,1.9,4.9,2.7,7.3l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.9,8.1c0.4,0.6,1,1,1.6,1
      c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4
      c-0.5-1.6-1.2-3.1-1.8-4.4l-0.2-0.5c-1-2.1-2-4.3-3.1-6.5c-1.1-2-1-2.1,0.6-3.6c1.6-1.4,3.1-2.8,4.5-4.3l0.6-0.5
      c0.6-0.6,1.1-1.2,1.7-1.7c0.9-1,1.9-2,2.9-2.9c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.3,1.4
      c1.7,1.8,3.2,3.5,4.8,5.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.4,0.5,0.8,0.8,1
      c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.2,1.2,3.3,1.8l0.2,0.1c1.5,0.8,3.1,1.7,4.7,2.6c1.2,0.7,2.4,1.4,3.6,2.2c1.2,0.7,2.6,1.6,3.9,2.3
      c2.2,1.2,4.4,2.4,6.6,3.4c1.8,0.9,3.7,1.7,5.3,2.5l0.4,0.2c0.5,0.2,0.9,0.4,1.4,0.7h0.1c0.3,0,0.5-0.2,0.5-0.4v-0.4h-0.3
      c-0.8-0.6-1.7-1.1-2.5-1.7c-2-1.4-4-2.8-6.1-4.1c-3-1.9-6.1-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.2-1.8-4.6-2.6l-0.1-0.1
      c-0.7-0.4-1.4-0.8-2.1-1.2l-1.3-0.7c-2.3-1.3-4.7-2.6-7-3.9c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6
      c-1.5-2-2.9-4.1-4.3-6.1l-0.2-0.3c-0.6-0.9-1.3-1.9-1.9-2.8c-0.6-0.8-1.2-1.6-1.7-2.4c-0.3-0.4-0.7-0.9-1.1-1.4
      c-0.7-0.9-1.4-1.9-2-2.8c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5
      v-0.1c0-0.6,0-1.2-0.1-1.8c-0.1-1.3-0.1-2.6-0.1-3.9c0.1-2.1,0.2-4.2,0.3-6.2c0.1-1.9,0.2-3.9,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4
      c0-2.5,0-5-0.1-7.4c0-1,0-2-0.1-3c0-0.7,0-1.4,0-2v-0.5c0-1,0-2,0-3c0-0.5,0-1.1,0-1.6c0-1.6,0-3.2,0-4.8c0-2.2,0.1-4.4,0.2-6.5
      v-0.3c0.1-1.9,0.1-3.9,0.2-5.8V775c0.1-3.2,0.1-6.1,1.7-9c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5.2,3.3-7.5c0.3-0.7,0.6-1.5,1-2.2
      c0.3-0.7,0.6-1.5,0.8-2.1c0.3-0.7,0.5-1.4,0.9-2.1c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.7,0,1.1-0.1
      c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.2,7-6,9.9-8.7c1.3-1.2,2.9-2.6,2.9-5
      c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6c0.2-1,0.4-2,0.6-3.1v-0.1c0.3-1.8,0.7-3.6,1-5.4c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2
      c0.4-3.2,0.7-6.4,1.1-9.4c0-0.4,0.1-0.9,0.1-1.3c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9c0.3-0.8,0.6-1.5,0.8-2.2
      c1.4-3.9,2.6-7.2,3.7-10.3l0.5-1.3c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.1,0.8-2.4,0.2-3.8
      C305.9,659,305.3,658.1,304.7,657.2z M281.3,643.9l2.4,0.6c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2
      c2.4,0.6,4.6,1.2,6.1,3.5c1.4,2.2,3.1,4.3,4.7,6.4c0.6,0.7,1.1,1.5,1.7,2.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.5-2.8-0.7
      c-2.8-0.3-5.3-0.5-7.5-0.6c-0.1,0-0.3,0-0.4,0c-1.2,0-2.4,0.1-3.6,0.2h-0.1c-0.6,0.1-1.3,0.1-1.9,0.1c-0.1,0-0.2-0.1-0.2-0.1
      c-1.9-2.3-3.9-4.8-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5c0.4-0.9,0.6-1.9,0.7-2.8l0.1-0.5c0.1-0.7,0.4-0.7,0.6-0.7
      C281.1,643.9,281.2,643.9,281.3,643.9z M244.4,814.2c-0.1,4.4-0.3,8.9-0.5,13.2l-0.1,3.3c0,0.6,0,1.2-0.1,1.8
      c-0.1,2.6-0.2,5.3-0.5,7.9c-0.1,0.8-0.8,2-1.4,2.3c-1.5,0.6-2.5,1.6-3.5,2.6c-0.5,0.5-1.1,1.1-1.7,1.6c-0.7,0.5-1.5,1.1-2.2,1.7
      c-0.9,0.7-1.8,1.4-2.8,2.1c-1.2,0.9-2.4,1.7-3.6,2.6c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1,0.1-0.3,0.2-0.5,0.3l-0.1-1.4
      c-0.1-1.4-0.2-2.7-0.3-4.1c-0.1-1.3-0.1-2.5-0.2-3.8c-0.1-2-0.2-4.4-0.3-6.6c-0.2-2.2-0.4-4.4-0.6-6.6v-0.1c-0.1-1.6-0.3-3.3-0.4-5
      c-0.2-2.2-0.3-4.4-0.5-6.8v-0.3c-0.2-2.7-0.4-5.4-0.6-8.1c-0.3-3.4-0.6-6.8-1-10.1v-0.1c-0.1-0.6,0.1-0.8,0.5-1.1
      c4.2-2.6,8.3-5.2,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3c0.8-1.1,1.5-2.1,2.2-3.1c0.2-0.3,0.3-0.6,0.4-0.9c0-0.2,0.1-0.3,0.2-0.5
      c0.8-1.8,1.6-3.6,2.4-5.4c0.8-1.8,1.6-3.6,2.3-5.3c0.4-0.8,0.7-1.6,1.1-2.5v0.3c0,1.4,0,2.9,0,4.3c0,2.4-0.1,4.8-0.1,7.2
      c0,2.2-0.1,4.5-0.1,6.8c-0.1,3.4-0.1,7-0.1,10.5c-0.1,1.6-0.1,3.3-0.1,4.6C244.4,809.6,244.5,811.9,244.4,814.2z M154.2,822.1
      c0.1-1.6,0.1-3.2,0-4.8c0-0.6,0-1.2,0-1.8c5.9,0.6,11.9,0.9,17.7,0.9c5.9,0,11.9-0.3,17.7-0.9l0.2,7c0.1,5.1,0.3,10.4,0.4,15.6
      c0.1,5.2,0.2,10.3,0.4,16l0.2,6.8c-0.1,0-0.1,0-0.2,0l-2,0.1c-3.1,0.2-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0
      c-0.7,0-1.5,0.2-2.2,0.3c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2
      c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0-3.4-0.1c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.8-0.1H153
      c0.1-1.6,0.1-3.1,0.2-4.6V856c0.1-3.5,0.3-7.1,0.3-10.6c0-1.8,0-3.7,0-5.5v-0.1c0-2.1,0-4.4,0-6.5c0.1-2.1,0.2-4.2,0.4-6.2V827
      C154,825.4,154.1,823.7,154.2,822.1z M186.5,813.2c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.7,0-1,0.1h-0.2
      c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3c-4.9-0.4-9.8-0.9-14.6-1.4h-0.2c-1.5-0.1-3-0.4-4.4-0.6H146
      c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5c0.3-1.1,0.6-2.2,0.9-3.3
      c0.3-1.2,0.6-2.4,0.9-3.5l0.1-0.2c0.1-0.4,0.2-0.8,0.3-1.2c0.7,0,1.3,0,1.9,0h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0
      c2.7,0.1,5.9,0.2,9,0.5c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2h0.2c0.4,0,0.8,0,1.2,0
      c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0c0.1,0.4,0.2,0.8,0.3,1.2l0.1,0.3c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.7,1,3.5,1.5,5.2v0.1
      c0.7,2.2,1.4,4.7,2.1,7.1c0.2,0.8,0.1,1.8-0.1,2.7v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1
      C190,812.8,188.2,813,186.5,813.2z M100.3,831v-0.6c-0.1-1.5-0.2-3.1-0.2-4.6c0-1.1-0.1-2.2-0.1-3.3c-0.1-3.4-0.2-7-0.3-10.5
      c0-2.6,0-5.2,0.1-7.8V804c0-2.3,0.1-4.7,0.1-7c0-4.6-0.1-9.2-0.2-13.7v-1.1c0-1.7-0.1-3.4-0.2-5.3c0-1.1-0.1-2.3-0.1-3.4v-0.1
      c0.5,1.1,0.9,2.2,1.4,3.2l0.1,0.2c1.5,3.4,3,6.9,4.4,10.4c0.1,1,0.8,2.1,1.6,3.1c0.4,0.6,0.8,1.2,1.2,1.8c0.3,0.5,0.9,0.8,1.5,1.1
      c0.2,0.1,0.5,0.2,0.7,0.4c0.8,0.5,1.7,1.1,2.5,1.6l0.1,0.1c2,1.3,4,2.6,6.1,3.7c2.1,1.1,2.8,2.3,2.4,4.6c-0.6,3.6-0.8,7.4-1.1,11
      c-0.1,1-0.2,2.1-0.2,3.1c-0.2,1.9-0.3,3.9-0.4,5.6c-0.1,1.2-0.1,2.4-0.2,3.5c-0.2,3.3-0.5,6.6-0.7,9.8v0.1c-0.2,2.5-0.4,5-0.5,7.5
      c-0.2,3.3-0.4,6-0.6,8.5c0,0.5,0,0.9,0,1.4v0.1l-0.4-0.2c-0.5-0.4-1-0.7-1.5-1c-0.5-0.4-1.1-0.8-1.7-1.1l-0.2-0.1
      c-1.4-0.9-3-2-4.4-3.1c-1.3-1-2.6-2.2-3.8-3.3c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.2-0.6-0.4-1-0.6c-1.2-0.4-2-1.5-2-2.9
      c-0.1-2-0.2-4.1-0.3-6.1v-0.4C100.4,832.7,100.4,831.9,100.3,831z M89.2,582.7c0.7,3.2,1.4,6.3,2.2,9.4c0.3,1.3,0.6,2.6,1,3.9
      c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5.1,5.4l-0.1,0.1c-1.3,1.4-2.6,2.8-4,4.2c-1.4,1.5-2.9,3-4.4,4.5
      c-1.6,1.7-3.1,3.2-4.6,4.7c-1.1,1.2-2.3,2.5-3.3,3.7c-0.5,0.6-1.1,1.2-1.6,1.8c0,0.1-0.1,0.1-0.2,0.2l-0.7-4.8
      c-0.5-3.8-1.1-7.4-1.6-11.1c-0.2-1.3-0.4-2.5-0.5-3.8c-0.4-2.7-0.7-5.4-1.2-8.1c-0.3-1.5,0.4-2.5,1.2-3.6l0.1-0.1
      c0.1-0.2,0.2-0.3,0.4-0.5c1.9-2.8,3.7-5.6,5.3-7.9c1.6-2.4,3.5-5.2,5.3-7.9l0.8-1.1c1.7-2.4,3.4-4.8,5.1-7.2c0.8-1,1.7-1.9,2.6-2.9
      c0.3-0.3,0.6-0.6,0.9-0.9c0.1,0.8,0.2,1.5,0.3,2.3l0.1,0.5c0.3,2.6,0.6,5.1,0.9,7.7v0.4C88.1,576.3,88.6,579.6,89.2,582.7z
       M170.6,570.8c0,0.7-0.1,0.8-0.9,0.8c-0.9,0.1-1.8,0.2-2.7,0.2c-3,0.3-6.2,0.5-9.2,0.9c-5.3,0.7-10.9,1.6-17.7,2.7
      c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5l-2.3-41.1l41-10.3v2.5c0,2.4,0,4.8,0,7.1c0,0.6,0,1.1,0,1.7v0.1
      c0,0.6,0,1.2,0,1.8c0,1.6,0,3.1-0.1,4.7c0,2-0.1,3.9-0.1,5.7C170.6,556.2,170.6,563.5,170.6,570.8z M273.4,584.3
      c0.3,0.5,0.7,1,1,1.6c1.2,1.9,2.5,3.8,3.8,5.6c0.7,0.9,0.8,1.7,0.7,2.9c-0.2,1.2-0.3,2.4-0.5,3.6c-0.2,1.5-0.4,3.1-0.7,4.6
      c-0.2,1.3-0.4,2.7-0.5,4v0.3c-0.3,2.1-0.6,4.3-0.9,6.5c-0.3,2.1-0.7,4.2-1,6.5l-0.1,0.5c-0.1,0.6-0.2,1.2-0.3,1.8
      c-0.1-0.1-0.2-0.3-0.4-0.4l-0.2-0.3c-0.7-0.8-1.4-1.6-2-2.3c-2.8-3-6.2-6.6-9.6-10.3c-1.5-1.5-2.9-3-4.6-4.7l-0.3-0.3
      c-0.9-1-1.9-1.9-2.8-2.9c-0.4-0.4-0.8-0.8-1.1-1.2c-0.7-0.7-1.4-1.5-2.1-2.2c-0.5-0.4-0.5-0.9-0.4-1.4c0.3-1.3,0.7-2.6,1-3.9v-0.1
      c0.7-2.6,1.4-5.3,2-8c0.4-1.7,0.6-3.4,0.8-5v-0.1c0.1-0.7,0.2-1.4,0.3-2.1c0.1-0.7,0.2-1.5,0.3-2.2c0.3-1.9,0.6-3.9,0.9-5.8
      c0.1-1,0.2-2,0.3-3c0.1-0.8,0.1-1.5,0.2-2.2c0-0.4,0.1-0.8,0.2-1.3v-0.1c0.3,0.4,0.6,0.7,0.9,1.1c1,1.2,2,2.3,2.9,3.4
      c2.8,3.5,5.3,7,7.7,10.4c1,1.4,1.9,2.9,2.8,4.3v0.1C272.2,582.6,272.8,583.5,273.4,584.3z M278.7,641.3c-0.5,2.3-1,4.6-1.5,6.9
      c-0.2,1-0.5,2-0.9,3c-0.3,1-0.6,2-0.9,3c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2c-0.3,0-0.8-0.1-1.3-0.2
      c-0.2,0-0.5-0.1-0.8-0.2c-0.5-0.1-0.9-0.3-1.4-0.5l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2c1.1-4.5,2.2-8.7,3.2-12.7
      c0.5-1.8,0.9-3.5,1.4-5.3c0.9-3.4,1.8-6.8,2.6-10.3c0.4-1.7,0.7-3.4,1-5.1c0.2-0.9,0.3-1.8,0.5-2.6c0.1-0.6,0.3-1.2,0.4-1.9
      l0.1-0.4l0.3,0.4c0.6,0.7,1,1.2,1.5,1.8c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.5,0.8,0.8,1.1c1.1,0.8,1,1.7,0.7,3.3
      c-0.6,2.5-1.1,5.1-1.6,7.4L278.7,641.3z M279,652.8c1.5,1.7,2.8,3.3,4.1,4.9c0.1,0.3,0.1,0.6-0.2,1.3c-2.3,4.4-4.7,9.1-7.5,14.8
      c-0.6,1.2-1.2,2.5-1.6,3.6c-0.3,0.7-0.6,1.4-1,2.2c0,0.1-0.1,0.1-0.1,0.2l5.5-27.7C278.5,652.4,278.8,652.6,279,652.8z
       M213.4,766.4c-2.1-1.9-4.2-3.8-6.2-5.6l-2.6-2.3c-0.2-0.2-0.2-0.3-0.2-0.4s0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5
      c0.4-0.4,0.8-0.8,1.2-1.1c0.1-0.1,0.2-0.2,0.3-0.2c0.5-0.5,1.2-1,1-2.1c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4
      c0.4-0.5,0.8-1.1,1.2-1.6c1-1.3,2-2.7,2.9-4.2c1.1-1.7,1.9-3.6,2.7-5.5c0.9-2.1,1.8-4.2,2.7-6.3l0.1-0.3c0.2-0.5,0.4-1,0.6-1.5
      c-0.1,11.7-1.8,23-3.5,35l-0.1,0.8c-0.2-0.2-0.4-0.3-0.6-0.5C214.7,767.5,214,766.9,213.4,766.4z M131.9,617.3c-0.2,0-0.3,0-0.5,0
      c-0.9,0-1.9,0-2.8-0.1c-1.9,0-3.9-0.1-5.9-0.1h-0.1c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5
      c-0.7,0.9-1.4,1.7-2.2,2.5c-0.6,0.6-1.2,1.3-1.8,2c-0.9,1.1-1.8,2.3-2.6,3.4l-0.1,0.1c-0.5,0.6-0.9,1.3-1.4,1.9
      c-0.6,0.7-1.2,1.5-1.8,2.2l-0.5,0.6l-0.1-1.1c-0.1-1.2-0.3-2.5-0.4-3.7c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.9-0.4-4-0.7-5.9
      c-0.5-3.8-1-7.5-1.4-10.7c-0.3-2.3-0.7-4.4-1-6.4v-0.1c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.4-3.5l0.3-0.2
      c1.2-0.6,2.3-1.3,3.5-1.9c1.1-0.6,2.2-1.2,3.4-1.8c1.5-0.8,3.1-1.7,4.7-2.6c1-0.5,2-1.1,3-1.6h0.1c1.4-0.7,3-1.6,4.5-2.4
      c1.5-0.8,2.9-1.7,4.4-2.6l0.1-0.1c0.6-0.4,1.2-0.8,1.8-1.1c0.4-0.2,0.7-0.5,1.2-0.7l0.6-0.4l2,37.2H131.9z M132.7,620.2h0.2
      l-1.9,22.3c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7,0-1.1,0c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7h-0.1
      c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1c-2.9-1.9-5.9-3.9-8.7-5.9c-1-0.7-1.8-1.3-2.7-1.9l-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.5
      c0.4-0.6,0.9-1.1,1.3-1.7c1.1-1.5,2.2-2.9,3.4-4.3c1.1-1.3,2.3-2.7,3.5-3.9l0.1-0.1c0.8-0.9,1.7-1.8,2.5-2.8
      c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0c2.2-0.1,4.2-0.1,6.1-0.1c4.2-0.1,7.9-0.1,11.3-0.1
      C132.1,620.1,132.4,620.2,132.7,620.2z M96.8,625.9l0.1,0.6c0.2,1.5,0.4,3,0.5,4.6v0.3c0.1,1,0.1,1.9-0.3,2.8
      c-0.5,1.2-0.8,2.5-1.1,3.8c-0.1,0.5-0.2,0.9-0.3,1.4l-0.3,1.4c-0.6,2.4-1.2,4.9-1.7,7.3c-0.2,1-0.4,1.9-0.6,2.9
      c-0.2,0.9-0.3,1.9-0.6,2.9c-0.1,0.5-0.4,1-0.8,1.4c-1.2,1.2-2.5,2.3-3.7,3.4l-0.2,0.2c-1,0.9-2,1.9-3,2.8c-1.3,1.3-2.7,2.6-4,3.9
      l-0.1,0.1c-0.4,0.4-0.9,0.9-1.4,1.3l-0.6-2.5c-0.6-2.2-1.1-4.4-1.6-6.5c-0.4-1.7-0.8-3.3-1.3-5l-0.1-0.5c-0.6-2.5-1.3-5-1.9-7.5
      c-0.9-3.5-1.5-6.2-2.1-8.7c-0.3-1.4-0.6-2.8-0.9-4.2c-0.4-1.8-0.8-3.7-1.2-5.5c-0.3-1.2,0-2.2,1-3.1c1.3-1.4,2.7-2.8,4-4.2l0.2-0.2
      c1.5-1.6,2.8-3,4.2-4.4c1.3-1.4,2.7-2.7,4-4.1c1.7-1.8,3.5-3.6,5.3-5.5c1.1-1.2,2.2-2.5,3.2-3.6l0.1-0.1c0.5-0.5,1-1.1,1.4-1.6
      l0.1-0.1l0.1,0.7c0.2,0.9,0.3,1.7,0.4,2.6l0.1,0.6c0.5,3.4,1,6.9,1.5,10.3C95.8,618,96.3,622.2,96.8,625.9z M98,639.4
      c0.2-1.1,0.5-2.2,0.8-3.3l0.1-0.3c0.1-0.3,0.1-0.6,0.2-0.8l13.7,9.4l-3.7,3.7c-0.9,0.8-1.7,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1
      c0,0.3,0.2,0.6,0.5,0.9l1.1,0.9c1.8,1.5,3.6,3.1,5.4,4.6c0.6,0.5,1.2,0.9,1.8,1.3c0.2,0.1,0.3,0.2,0.5,0.4l-4.3,7.9
      c-0.2-0.1-0.5-0.3-0.7-0.4c-3.5-2.6-7.2-5.3-10.9-8.1c-1.8-1.3-3.4-2.5-4.8-3.7c0,0-0.1-0.3-0.1-0.5
      C95.8,649.3,96.9,644.3,98,639.4z M117,661c0.1-0.1,0.4-0.3,0.5-0.3c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2
      c0.6,0,1.2,0,1.8,0c0.8,0,1.6,0,2.3,0c1,0,2,0,3,0c0.6,0,1.1,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0.1v9.8l-18.6-2.5l0.7-1.3
      C114.6,665.2,115.8,663.1,117,661z M110.5,683c0.1-0.9,0.2-1.8,0.4-2.6c0.5-1.7,0.8-3.2,1.1-4.5c0.1-0.6,0.2-1.1,0.4-1.7
      c0-0.2,0.1-0.4,0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1c0-0.1,0.3-0.3,0.4-0.3h0.1c3.2,0.3,6.6,0.7,9.8,1.1c1.5,0.2,3,0.5,4.4,0.8h0.1
      c1,0.2,1.9,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1c0.1,3.4,0.2,6.3,0.4,8.9c0.1,1.3,0.2,2.6,0.4,3.8v0.1c0.1,0.9,0.2,1.8,0.3,2.6
      c0.1,0.7,0.1,1.4,0.1,2.1c0,0.3,0,0.6,0,0.9l-24.9,6.7c0-0.1,0-0.3,0-0.4v-0.2c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.6,0.6-3.2,0.9-4.8
      v-0.1c0.4-2.1,0.8-4.3,1.2-6.4C110.3,684.9,110.4,683.9,110.5,683z M131.4,658.9c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3
      c-1.5,0-3.1-0.1-4.6-0.3h-0.1c-0.4,0-0.7-0.1-1.1-0.1c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.3-0.9-0.4
      c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.5-0.1c-1.8-1.5-3.6-3-5.5-4.6l-1.6-1.4l0.4-0.3c0.4-0.3,0.7-0.6,1.1-0.9
      c0.4-0.4,0.8-0.7,1.2-1.1c1.1-0.9,2.2-1.9,3.2-2.9c1.5-1.5,3.4-1.5,5.2-1.5h0.3c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1
      c1.2,0,2.4,0.5,3.6,1.5c1,0.8,2,1.6,3,2.4l0.3,0.2c0.6,0.2,1,0.5,1.5,0.9l0.6,0.5c0.7,0.6,1.5,1.2,2.2,1.8c0.2,0.1,0.2,0.3,0.2,0.4
      c0,0.3-0.4,0.7-0.5,0.9c-2.5,2.5-5.2,5-9.2,5c-0.2,0-0.4,0-0.6,0C132.8,658.9,132.1,658.9,131.4,658.9z M159,642.4
      c0.1,0.1,0.2,0.3,0.2,0.4l-10.7,11.7c-0.5-0.5-0.9-1-1.4-1.4c-1.2-1.3-2.5-2.6-3.9-3.8c-2-1.7-4.2-3.5-7.2-5.7
      c-0.6-0.4-1.3-0.7-2.1-0.9c-0.3-0.1-0.5-0.2-0.8-0.3l1.6-22.4c0.1,0,0.3,0.1,0.4,0.1h0.1c0.6,0.2,1.2,0.4,1.7,0.7
      c0.9,0.5,1.9,0.9,2.8,1.3c2.8,1.3,5.7,2.7,8,5c3.6,3.7,6.3,7.3,8.4,10.9c0.6,1,1.3,2,1.9,2.9v0.1
      C158.3,641.5,158.7,641.9,159,642.4z M161,659.4c-1.7,1-3.5,2.1-5.1,3.2l-0.1,0.1c-1.3,0.8-2.6,1.7-4,2.5c-0.5,0.3-1.2,0.6-1.8,0.9
      c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1-0.6-0.2-1.1-0.4-1.6c-0.3-1.6-0.7-3-0.8-4.5c-0.1-1,0.4-2.3,1.2-3.2c2.3-2.7,4.8-5.3,7.2-7.8
      l-0.1-0.1h0.1c0.6-0.7,1.3-1.3,1.9-2c0.5-0.5,1-1,1.6-1.5l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.1,1.1c0.1,1.2,0.2,2.4,0.2,3.6
      c0.2,2.3,0.5,5.4,0.8,8.4C161.6,658.9,161.5,659.1,161,659.4z M161.7,661.8c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,0.9,0.1,1.3
      c0.1,1.4,0.3,2.7,0.4,4c0,0.7,0,1.3,0,2c0,0.6,0,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.5-3.8,3-6.2,4.9c-0.5,0.4-1,0.7-1.5,1.1
      l-0.4,0.3l-0.2-0.5c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3c-0.4-1.6-0.9-3.4-1.5-5.1c-0.3-0.8,0-1,0.7-1.3
      c0.4-0.2,0.9-0.4,1.3-0.6c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.3-1.3,3.2-2l0.1-0.1C160.7,662.4,161.2,662.1,161.7,661.8z
       M146.2,658.4c-0.1,0.2-0.5,0.5-0.7,0.5c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1l3.5-3.3C146.9,656.1,147,656.7,146.2,658.4z M133.1,669.8
      c0.4-2.5,0.3-4.9,0.2-7.8c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1
      h0.1c1.5,0.3,3,0.7,4.7,1l1.9,0.4l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.3c0,0.1-0.2,0.4-0.4,0.5l-2.1,0.9c-2.9,1.2-5.9,2.4-8.9,3.5
      c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1C133,671.8,133.1,670.6,133.1,669.8z M143.3,671.3c1.3-0.6,2.8-1.2,4.3-1.8
      l3.6,11.7l-16.3,10.5l-1.5-17.2C137.1,674,140.3,672.6,143.3,671.3z M135.6,694.1c1.9-1.2,3.8-2.5,5.7-3.6l0.1-0.1
      c3-1.9,6.1-3.9,9.1-5.9c2.3-1.6,4.6-3.3,6.8-5c1-0.8,2-1.5,3-2.3c0.1-0.1,0.3-0.2,0.4-0.3l-0.2,0.7c-0.3,1.1-0.6,2.3-0.9,3.5
      l-0.3,1.2c-0.5,2.2-1.1,4.5-1.5,6.8c-0.5,2.8-1.3,5.1-3.7,7c-2.6,2.1-5,4.5-7.4,6.9c-1,1-2.2,2.2-3.3,3.2c-1.6,1.5-3.3,3.1-4.8,4.5
      l-0.1,0.1c-0.7,0.6-1.3,1.2-1.9,1.8c0-0.3-0.1-0.6-0.1-1c-0.1-0.9-0.3-1.9-0.4-2.9c-0.2-2.2-0.4-4.6-0.5-6.6
      c-0.1-1.1-0.2-2.3-0.3-3.4c0-0.4-0.1-0.8-0.1-1.2c-0.1-0.8-0.2-1.6-0.2-2.4C135,694.8,135.3,694.3,135.6,694.1z M161.3,755.3h0.2
      c1.8-0.1,3.7-0.2,5.6-0.2c0.3,0,0.5,0,0.8,0c1.2,0,2.6,0,3.7,0.1c1.1,0,2.3,0.1,3.5,0.1h0.2c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3
      c2.1,0.1,4.2,0.1,6.2,0.6c0.7,0.2,1.4,0.2,2.2,0.2c1.5,0,3-0.3,4.4-0.6c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4
      c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1c-0.5,0.5-1,1-1.5,1.4c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4
      c-2.5,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8c-1.7,0-3.4,0.1-5.1,0.2h-0.2c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1
      c-1.9,0-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6c-0.4,0-0.8-0.3-1.4-0.8c-0.8-0.8-1.6-1.5-2.4-2.3l-0.1-0.1
      c-1.1-1.1-2.4-2.4-3.7-3.5c-1.7-1.5-3.5-3-5.2-4.4c-1.2-1-2.2-1.8-3.2-2.7c-0.6-0.5-1.1-1-1.6-1.6c0.7,0.1,1.4,0.2,2.1,0.4
      c0.9,0.2,1.8,0.4,2.6,0.5h0.1c2.4,0.5,4.9,0.9,7.2,1.6c0.6,0.2,1.2,0.2,1.9,0.2c0.9,0,1.7-0.1,2.5-0.2c0.7-0.1,1.4-0.2,2.1-0.2
      C158.1,755.5,159.7,755.4,161.3,755.3z M159.9,752.6c0.6-0.1,1.5-0.5,2.3-0.8c0.1,0,0.1,0,0.2-0.1c0.2,0,0.3-0.1,0.5-0.2
      c0.8-0.5,1.6-0.8,2.4-0.8c1.2,0,2.3,0.5,3.6,1c0.1,0.1,0.3,0.1,0.4,0.2h0.1c0.7,0.3,1.3,0.6,2.1,0.6c1,0,2-0.3,3-0.7l0.2-0.1
      c0.7-0.2,1.4-0.5,2.1-0.8h0.1c0.4-0.2,0.8-0.3,1.2-0.5c0.1-0.1,0.3,0,0.4,0c1.8,0.7,3.6,1.4,5.4,2.1h-2.8c-1.6,0-3.2,0-4.8,0
      c-0.6,0-1.1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1c-1.3-0.1-2.7-0.1-4.4-0.1c-1.1,0-2.1,0-3.3,0
      C161.6,752.6,160.7,752.6,159.9,752.6z M203,751.7c-1,0.2-2,0.4-3,0.6l-1.5,0.3c-1.8,0.3-3.6,0.7-5.4,1c-0.4,0.1-0.9-0.1-1.3-0.2
      c-1.7-0.6-3.1-1.1-4.4-1.5c-2.7-1-5.1-2-7.4-2.9c-0.6-0.2-1.2-0.4-1.8-0.4c-0.7,0-1.5,0.2-2.1,0.6c-2.7,1.5-5.5,1.6-8.2,0
      c-0.7-0.4-1.4-0.6-2.1-0.6c-1.1,0-2.2,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1.1,0.4c-1.7,0.6-3.4,1.2-5,1.8l-0.2,0.1
      c-0.7,0.3-1.5,0.6-2.2,0.8H154c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.5-1.5,0.8-2.5,0.8c-0.8,0-1.6-0.2-2.5-0.3c-0.2,0-0.4-0.1-0.6-0.1
      c-1.8-0.4-3.7-0.7-5.3-1c-0.9-0.2-1.8-0.3-2.7-0.5c-0.1,0-0.2,0-0.4-0.1l0.7-0.3c1-0.5,2-1,3-1.5l1.7-0.9c2-1,4-2,6.1-3
      c1.9-0.9,3.9-1.8,5.7-2.7l0.4-0.2c0.6-0.6,1.4-0.9,2.2-1.3c0.3-0.1,0.6-0.3,0.9-0.4c0.7-0.3,1.3-0.6,2-1c0.4-0.3,0.9-0.4,1.4-0.4
      c0.6,0,1.3,0.2,1.9,0.4l1,0.3c1.9,0.6,3.8,1.2,5.8,1.3c1.5,0.1,3.2-0.4,5.1-1.3c0.8-0.4,1.6-0.6,2.3-0.6c0.9,0,1.7,0.2,2.8,0.7
      c1.8,0.9,3.7,1.8,5.5,2.6l0.2,0.1c1.5,0.7,3.1,1.5,4.7,2.3c2.3,1.1,4.6,2.3,6.9,3.5c1.1,0.6,2.2,1.1,3.3,1.7
      c0.4,0.2,0.8,0.4,1.1,0.6L203,751.7z M195.2,711.5l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.8,0.6-1.2c0.7-1.4,1.4-2.7,2.1-4
      c0.7-1.3,2-1.7,3.4-2.2C194.5,711.8,194.8,711.7,195.2,711.5z M192.8,710.2c-1,0.5-1.9,1-2.9,1.3c-0.3,0.1-0.8,0-1.4-0.2
      c-0.3-0.1-0.5-0.1-0.8-0.2c-1.8-0.3-3.7-0.6-5.5-0.9l-3.2-0.5c0-0.4,0.3-0.7,0.9-1c1.4-0.6,2.8-1.2,4.2-1.8l0.2-0.1
      c1.2-0.5,2.5-1.1,3.7-1.6c0.8-0.2,1.4-0.1,2,0.5c0.9,1.1,1.8,2.1,2.8,3.1c0.3,0.3,0.6,0.6,0.9,1
      C193.4,709.9,193.1,710.1,192.8,710.2z M188.8,713.6l-0.6,0.9c-0.9,1.7-1.7,3.3-2.5,4.8c0,0-0.1,0.1-0.2,0.1
      c-2.2,0.2-4.5,0.4-7,0.6l-1.1,0.1l1.5-8.1L188.8,713.6z M178.1,728.2l0.1,0.5c0.3,1.9,0.6,3.8,0.9,5.7c0.1,0.8,0.2,1.6,0.2,2.3v0.3
      c0,0.2,0,0.5,0.1,0.7c-0.6,0.2-1.1,0.3-1.7,0.5l-0.2,0.1c-1.8,0.6-3.5,1.1-5.3,1.4c-0.2,0-0.4,0.1-0.6,0.1c-0.8,0-1.7-0.3-2.6-0.5
      l-0.2-0.1c-1.1-0.3-2.2-0.7-3.4-1.2c-0.4-0.2-0.8-0.3-1.3-0.5l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4c0.1-0.9,0.3-1.8,0.4-2.8
      c0.2-1.4,0.4-2.9,0.7-4.3c0.2-1,0.4-1,0.6-1s0.5,0.1,0.8,0.2c2.9,0.8,5.6,0.8,8.2,0c0.4-0.1,0.6-0.2,0.8-0.2s0.5,0,0.6,1.1
      C177.6,724.8,177.8,726.5,178.1,728.2z M165.8,720l-0.7-0.1c-1.4-0.1-2.9-0.2-4.3-0.3h-0.1c-0.1,0-0.2-0.1-0.3-0.1h-0.1
      c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3l-0.1-0.1c-0.2-0.3-0.3-0.7-0.5-1l9.8-1.6l1.5,8.1L165.8,720z
       M155.8,719.4l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1c-0.9-2.4-1.6-4.5-2.2-6.6c1.7,0.5,3.3,0.9,4.2,2.4
      c0.8,1.3,1.5,2.7,2.1,4l0.1,0.2C155.5,718.7,155.6,719.1,155.8,719.4z M162.4,710.2c-2.2,0.3-4.2,0.7-6.2,1c-0.1,0-0.3,0.1-0.4,0.1
      c-0.2,0.1-0.3,0.1-0.4,0.1H155c-0.6,0-1.2,0-1.7-0.2c-0.8-0.3-1.5-0.6-2.3-1c-0.2-0.1-0.4-0.2-0.7-0.3c0.3-0.3,0.6-0.6,0.9-1
      c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1c2.5,1,5.2,2.2,8.3,3.6c0.1,0.1,0.3,0.4,0.6,0.8L162.4,710.2
      z M156,697.7c-0.1,1-0.3,2.1-0.6,3c-0.2,0.5-0.7,1-1.3,1.4c-0.3,0.3-0.6,0.5-0.9,0.8c-2.4,2.7-4.2,4.8-5.9,6.8
      c-0.5,0.6-0.9,1.3-0.4,2.5c0.6,1.5,1.1,3.1,1.6,4.7c0.3,1,0.6,1.9,0.9,2.7c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.5,1.6-3.8,2.4
      c-0.3,0.2-0.7,0.4-1,0.7h-0.1l-1-1.5c-1-1.4-1.9-2.9-2.9-4.4l-0.4-0.6c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1
      c1.9-1.7,3.3-3,4.6-4.3c2-1.9,3.9-3.8,5.9-5.7c1.2-1.1,2.3-2.2,3.5-3.3c1-0.9,1.9-1.8,2.9-2.8l0.8-0.8
      C156,697.2,156,697.5,156,697.7z M145.5,725.4c0.7-0.5,1.3-0.9,2-1.4c1.2-0.8,2.4-1.7,3.6-2.4c0.7-0.4,1.8-0.5,2.5-0.5
      c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4l1,0.1c1,0.1,2,0.2,3,0.3l1,0.1v1.6c-0.2,1.3-0.4,2.6-0.6,3.9c-0.1,0.8-0.3,1.6-0.4,2.5
      v0.2c-0.4,2.3-0.8,4.6-1,7c-0.1,1.1-0.5,1.3-1.3,1.6c-0.4,0.2-0.8,0.3-1.3,0.5c-0.9,0.3-1.8,0.7-2.6,1.1c-0.3,0.2-0.6,0.2-1,0.2
      c-0.3,0-0.6-0.1-1-0.1c-0.3-0.1-0.7-0.1-1.1-0.1H154c0,0-0.1-0.1-0.3-0.3c-1.2-1.9-2.3-3.8-3.5-5.7l-0.4-0.7
      c-1.5-2.4-3-4.9-4.4-7.3C145.1,725.9,145.1,725.7,145.5,725.4z M190.4,739.7c-0.3,0.5-0.5,0.6-1.3,0.9c-0.3,0.1-0.6,0.1-0.9,0.1
      c-0.9,0-1.6-0.4-2.4-0.8l-0.4-0.2c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.5-0.3-0.5-0.9
      c0-1.5-0.3-3.1-0.5-4.6v-0.2c-0.4-2.4-0.8-5-1.2-7.1c-0.2-1.2-0.4-2.3-0.6-3.5c0-0.1,0-0.1,0-0.2l3.1-0.2c2-0.1,3.9-0.3,5.8-0.4
      c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1c0.8,0.5,1.5,1,2.3,1.6l0.1,0.1c0.8,0.6,1.8,1.3,2.8,1.9
      c0.5,0.3,0.5,0.5,0.2,0.9c-2.2,3.5-4.2,6.7-6.1,9.8c-0.6,1-1.2,2.1-1.8,3.1L190.4,739.7z M194.6,720l0.6-1.9c0.6-2,1.3-4.1,1.9-6.1
      c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.5-1.9-2.5-2.6c-1.7-1.3-2-3-2.3-4.9
      c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0.2,0.1,0.3,0.3,0.4,0.4c1.1,1,2.2,2.1,3.2,3.1l0.7,0.6c2.5,2.4,4.6,4.4,6.8,6.6
      c2,1.9,4.1,3.9,6.3,5.8c0.7,0.6,0.7,1,0.1,1.7c-1.5,2.1-2.9,4.1-4.2,6.1c-0.4,0.6-0.7,1.2-1.1,1.8c-0.1,0.1-0.1,0.3-0.2,0.4
      L194.6,720z M205.7,710.9c-1.5-1.3-2.9-2.5-4.3-3.8c-0.8-0.7-1.6-1.6-2.3-2.3c-0.6-0.7-1.3-1.4-2-2c-2.6-2.5-5.4-5-8.1-7.5
      c-1.2-1.1-1.9-2.4-2.3-4c-0.6-3-1.4-6-2.1-9c-0.3-1.2-0.6-2.4-0.9-3.5c-0.1-0.6-0.3-1.2-0.5-1.8l-0.1-0.2l0.1,0.1
      c0.8,0.6,1.7,1.3,2.5,1.9l0.2,0.1c1.8,1.4,3.7,2.9,5.7,4.2c2.1,1.5,4.3,2.9,6.5,4.2c0.8,0.5,1.6,1,2.4,1.5s1.7,1.1,2.6,1.6
      c1.7,1.1,3.6,2.3,5.4,3.5c0.2,0.1,0.4,0.6,0.4,1c-0.3,4-0.6,8.1-0.9,12v0.1c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8
      C206.8,711.8,206.2,711.4,205.7,710.9z M186.8,702.9c0,0.2,0.1,0.4,0.2,0.6v0.1c-0.5,0.2-0.9,0.4-1.4,0.6c-1.2,0.5-2.6,1.2-4,1.7
      c-0.5,0.2-1,0.4-1.6,0.6c-0.6,0.2-1.2,0.4-1.8,0.7c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1s-0.3,1.9-0.5,2.8
      c-0.4,1.7-0.8,3.7-1.3,5.6c-0.1,0.2-0.6,0.5-0.9,0.6c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1c-0.3-1.5-0.6-3-0.9-4.5
      c-0.2-1.3-0.5-2.5-0.7-3.8c-0.7-4-0.9-4.2-4.9-5.3c-1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2c-0.5-0.2-1-0.5-1.4-0.8l-0.1-0.1
      c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-1.9,1.6-2.6,3.2-3.3c0.8-0.4,1.9-0.4,2.9-0.5c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.1,1.9-0.2
      c1.7-0.2,3.4-0.4,5.1-0.5c1.1,0,2.2,0.1,3.4,0.2c0.5,0.1,1,0.1,1.5,0.2l2,0.2c1.5,0.1,3.2,0.3,4.9,0.4
      C184.4,700,186.5,701.8,186.8,702.9z M136.1,715.8c0.4,0.6,0.9,1.3,1.3,1.9l0.1,0.1c1.2,1.7,2.2,3.2,3.2,4.9c0.9,1.5,1.8,3,2.6,4.5
      c0.6,1.1,1.3,2.3,2,3.5c0.7,1.1,1.4,2.3,2.1,3.4v0.1c0.5,0.8,1,1.6,1.5,2.4c0.9,1.5,1.9,3.1,2.8,4.6c0.3,0.6,0.4,0.9,0.3,1.2
      c-0.1,0.3-0.4,0.5-0.9,0.8l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.5-0.2l-1-1.5
      c-1.6-2.4-3.3-4.8-4.7-7.3c-0.5-0.9-0.9-2-1.3-3.1c-0.2-0.6-0.4-1.3-0.7-1.9c-0.9-2-1.8-4.2-3.1-6.9c-0.4-0.8-0.4-1.2,0.2-1.9
      c1.2-0.8,2.1-2,3-3.1c0.6-0.8,1.3-1.7,2-2.5c1.3-1.5,2.7-3,4.2-4.5l0.4-0.4C135.3,716.7,135.7,716.2,136.1,715.8z M126.4,737.9
      c0.3,0.7,0.6,1.3,0.9,2c1,2.2,1.9,4.4,3.1,6.5c0.9,1.6,2.1,3,3.2,4.5c0.5,0.6,1,1.3,1.5,1.9l0.1,0.1c-0.2,1.2,0.6,1.8,1.2,2.2
      c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7,1.4,1.3,2.1,1.9l0.3,0.3c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4c-0.8,0.7-1.6,1.5-2.3,2.2
      c-1.2,1.1-2.5,2.4-3.9,3.6c-1.2,1.1-2.4,2.1-3.8,3.2l-0.1,0.1c-0.4,0.3-0.8,0.7-1.3,1l-0.4-2.8c-1.7-11.3-3.2-22-3.2-33.1
      c0,0.2,0.1,0.6,0.3,1C125.2,735.1,125.8,736.5,126.4,737.9z M129.5,770c1-0.8,1.8-1.6,2.7-2.4c0.5-0.5,1.1-0.9,1.6-1.4
      c1.2-1,2.4-2.1,3.6-3.1c0.9-0.8,1.7-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.5,0.3l2.2,2
      c1.5,1.4,3.1,2.8,4.6,4.2c0.5,0.5,1.1,1,1.7,1.6l0.1,0.1c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5l-0.4,1.2
      c-0.6,1.9-1.2,3.8-1.7,5.8c-0.4,1.4-0.7,2.9-1.1,4.3l-0.1,0.3c-0.1,0.6-0.3,1.2-0.4,1.8c-0.1,0.5-0.2,1-0.4,1.5l-0.1,0.4L128.4,771
      L129.5,770z M151.3,786.1c0.3-1.1,0.5-2.2,0.8-3.3c0.2-0.9,0.4-1.7,0.6-2.6l0.1-0.4c0.4-1.6,0.7-3.2,1.1-4.8
      c0.3-1.1,0.6-2.2,0.9-3.3c0.1-0.3,0.2-0.5,0.7-0.5h0.1c3.6,0.1,7.2,0.2,10.8,0.2h1.7c0.9,0,1.9,0,2.8,0.1h0.1
      c2.9,0.1,5.9,0.1,8.8,0.1c2.4,0,5-0.1,8.4-0.4h0.1c0.5,0,0.6,0.2,0.7,0.8v0.1c0.7,2.9,1.5,5.9,2.3,8.9c0.4,1.6,0.8,3.1,1.2,4.8
      l0.5,2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.7,0c-0.7,0-1.5,0-2.2,0c-3.3,0-6.8,0.2-10.3,0.6
      c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3c-2.3-0.3-4.8-0.4-8-0.4c-0.9,0-1.9,0-2.8,0c-1.4,0-2.8,0-4.3,0h-0.5
      c-0.4,0-0.7,0-1.1,0L151.3,786.1z M192.8,776.3c-0.3-1.2-0.7-2.4-1.1-3.6c-0.3-0.3-0.5-0.7-0.6-1.2c-0.2-0.7-0.2-1,0.3-1.5
      c1.3-1.2,2.7-2.5,4-3.7l0.1-0.1c0.9-0.8,1.8-1.7,2.6-2.5c0.9-0.8,1.8-1.6,2.5-2.3l0.1-0.1c0.6-0.5,1.1-1,1.7-1.5
      c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4c1.9,1.8,3.9,3.7,6,5.6c1.5,1.3,2.9,2.5,4.4,3.7c0.6,0.5,1.1,0.9,1.7,1.4
      c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1.1c-0.3-1.2-0.7-2.3-0.9-3.4c-0.2-0.9-0.4-1.8-0.5-2.6
      C193.3,778.5,193.1,777.4,192.8,776.3z M218.9,729.3c-0.3,0.7-0.6,1.3-0.9,2c-0.6,1.3-1.3,2.7-1.9,4.2c-0.3,0.8-0.6,1.6-0.9,2.4
      c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.7-1.8,3.2-2.8,4.8l-0.3,0.5c-0.4,0.7-0.9,1.3-1.3,1.9l-0.1,0.1c-0.4,0.5-0.7,1-1.1,1.5
      c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.3-0.1-0.5-0.2c-2.9-1.5-5.8-3-8.6-4.4l-2.7-1.4c-0.9-0.5-1.8-0.9-2.8-1.4l-1.4-0.7
      c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5c0.7-1.2,1.4-2.4,2.1-3.4c1.2-1.9,2.4-3.8,3.5-5.8c2.6-4.7,5.5-9.2,8.6-13.8
      c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.5,1.1,0.9,1.6,1.5c1.8,2,3.6,4.1,5.3,6l0.8,0.9c0.3,0.4,0.6,0.8,0.9,1.1
      c0.6,0.7,1.2,1.5,1.8,2.1C219.1,728.5,219.1,728.8,218.9,729.3z M192.7,681.2l3.6-11.7c1.5,0.6,3,1.2,4.3,1.8
      c3.1,1.3,6.2,2.7,9.8,3.3l-1.4,17.1L192.7,681.2z M196.6,653.3l-0.1,0.1c-0.4,0.4-0.7,0.8-1.1,1.1L184.5,643c0-0.1,0.1-0.1,0.1-0.2
      l0.8-1.3c1.3-2,2.6-4,4-6c2.3-3.4,5.2-7.3,9.2-10.2c1.4-1,3.1-1.8,4.6-2.6c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1
      c0.2-0.1,0.5-0.2,0.8-0.2c0,0,0,0,0.1,0l1.6,22.5c-2.2,0.1-3.6,1.3-5,2.5c-0.4,0.3-0.7,0.6-1.1,0.9l-0.2,0.2
      c-1.5,1.2-3.1,2.4-4.5,3.7C198.8,651,197.7,652.2,196.6,653.3z M201.9,658.3c-0.2,0-0.5,0-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4
      c-0.1,0-0.4-0.2-0.6-0.6c-0.4-0.7-0.5-1.5-0.2-1.9c0.2-0.4,0.6-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.2-0.2L201.9,658.3z M186.2,647.9
      c2.6,2.8,5.4,5.7,7.9,8.7c1.4,1.5,1.8,3,1.4,4.5c-0.3,1.2-0.5,2.4-0.7,3.8v0.1c-0.1,0.4-0.1,0.8-0.2,1.3l-0.5-0.2
      c-0.6-0.2-1.1-0.5-1.7-0.8c-1.7-0.9-3.7-2-5.6-3.2c-1.4-0.9-2.8-1.8-4.4-3c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.4,0.6-6.7,0.9-10.3
      l0.2-2.7c0.1,0.1,0.3,0.2,0.4,0.4l0.2,0.1C184.8,646.5,185.5,647.2,186.2,647.9z M209.9,672.2c-0.2,0-0.3,0-0.6-0.1
      c-1.8-0.6-3.5-1.1-5.2-1.8c-1.3-0.5-2.6-1.1-3.9-1.6l-0.1-0.1c-1-0.4-1.9-0.9-2.9-1.3c-0.4-0.2-0.5-0.3-0.4-0.8
      c0.2-1.3,0.4-2.6,0.6-3.9l0.2-1.1c0.5-0.1,1-0.2,1.5-0.3l0.2-0.1c1.6-0.4,3.1-0.7,4.6-1c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1
      c0.5,0.1,1,0.2,1.5,0.3c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2c0,1.8,0,3.7,0,5.3v0.4c0,0.7,0,1.3,0,2c0,0.2,0,0.4,0,0.5
      c0,0.4,0,0.8,0,1.2S210.3,672.2,209.9,672.2z M210.1,658.8H210c-2.6,0-4.9-0.9-6.9-2.8c-0.2-0.2-0.5-0.4-0.8-0.6
      c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4c0.9-0.7,1.9-1.5,2.8-2.2h0.1c1.2-1,2.4-2,3.6-2.9
      c1-0.7,2.1-1.2,3-1.2c4.3-0.3,8.8-0.3,12.9-0.4l0.1-0.1c2.5,0,4.5,0.9,6.1,2.6c1,1.1,2.1,2,3.3,3c0.4,0.4,0.8,0.7,1.3,1.1l-1.5,1.3
      c-1.6,1.3-3.1,2.6-4.6,3.8c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.2,0-0.3,0.1
      c0,0-0.1,0-0.2,0h-0.1c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1c-2.3,0-4.8,0-6.9,0
      C213.9,658.8,212,658.8,210.1,658.8z M211.1,620c0.4,0,0.5,0,0.6,0c0.5-0.1,1-0.2,1.4-0.2c2.3,0,4.6,0.1,6.5,0.2h0.4
      c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1c0.7,0,1.3,0.2,1.8,0.8c0.5,0.6,1.1,1.2,1.7,1.7
      c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.3l0.2,0.3c0.5,0.6,0.9,1.3,1.4,1.9c-0.6,0.4-1.2,0.9-1.9,1.3h-0.1
      c-1.6,1.1-3.2,2.2-4.8,3.3c-2.3,1.6-4.7,3.1-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1c-1.1-0.1-2.2-0.2-3.3-0.3
      c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1c-0.4,0-0.8,0-1.2,0h-0.2L211.1,620z
       M230.3,667.2l0.8,1.4l-7.9,1l-10.7,1.5v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2
      c1.3-0.1,2.6-0.2,3.9-0.2c0.1,0,0.4,0.2,0.5,0.4C228.1,663.1,229.2,665.1,230.3,667.2z M211.8,685.9c0.2-2.5,0.3-5,0.4-7.3l0.1-2.2
      c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6c2.3-0.5,4.4-0.9,6.4-1.2c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1
      c0.7-0.1,1.7-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5c0.3,1.8,0.6,3.6,0.9,5.3c0.3,2,0.7,4.1,1.1,6.2
      c0.4,2.4,0.9,4.7,1.4,7.1l0.1,0.4c0.2,0.8,0.3,1.6,0.5,2.5c-0.4-0.1-0.9-0.2-1.3-0.3h-0.1c-1.2-0.1-2.4-0.3-3.6-0.6
      c-2-0.5-4.1-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1c-1.9-0.5-3.8-1-5.8-1.5c-0.8-0.2-1.2-0.5-1.1-1.4
      C211.5,689.9,211.6,687.9,211.8,685.9z M232.9,667.6l-4.4-8l0.5-0.3c0.6-0.4,1.2-0.9,1.8-1.4l0.8-0.7c1.8-1.5,3.6-3,5.3-4.5
      c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.7-3.3-5.5-5c-0.1-0.1-0.1-0.2-0.2-0.3l13.7-9.4c0,0.1,0.1,0.2,0.1,0.3
      v0.1c0.2,0.5,0.4,1,0.5,1.6c0.9,3.6,1.6,6.8,2.3,9.7c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4c-1.5,1.2-3.1,2.3-4.7,3.4
      l-0.2,0.2c-1.5,1.1-3.1,2.3-4.6,3.4c-1.3,1-2.6,2-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9C233.2,667.4,233.1,667.5,232.9,667.6z
       M249.1,643.6v-0.2c-0.4-1.6-0.7-3.1-1.1-4.7c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-1.1-0.4-2.2-0.9-3.2c-0.5-1.2-0.4-2.3-0.3-3.5v-0.1
      c0.1-1.2,0.2-2.5,0.4-3.7v-0.1c0.1-1.5,0.3-3.1,0.5-4.6c0.2-1.4,0.4-2.9,0.6-4.2c0.1-0.8,0.3-1.6,0.4-2.5c0.3-1.8,0.5-3.5,0.8-5.3
      l0.1-0.5c0.4-2.8,0.8-5.6,1.2-8.4c0.1-0.5,0.2-1,0.4-1.5l0.1-0.2l0.7,0.7c0.6,0.7,1.2,1.3,1.8,1.9c0.9,1,1.9,1.9,2.8,2.9l0.3,0.4
      c1.6,1.7,3.5,3.6,5.3,5.5c1.2,1.2,2.3,2.5,3.4,3.7l0.1,0.1c0.8,0.9,1.7,1.8,2.6,2.8c0.4,0.4,0.8,0.9,1.2,1.4
      c1.1,1.2,2.2,2.4,3.4,3.4c1.9,1.8,1.8,3.8,1.3,5.8c-0.6,2.3-1.1,4.6-1.6,6.9v0.1c-0.5,2.1-1,4.4-1.5,6.5c-0.4,1.7-0.9,3.4-1.3,5.1
      c-0.4,1.3-0.8,2.7-1.1,4.1c-0.6,2.4-1.2,4.8-1.7,7.1c-0.2,1-0.5,2-0.7,3c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.4-0.2,0.7-0.3,1.1
      c-0.3,1.1-0.6,2.2-1,3.2l-0.3,1.2h-0.1c-0.3-0.4-0.7-0.8-1-1.2c-0.9-1-1.9-2.1-2.9-3.1c-1.4-1.4-3-2.8-4.3-4.1l-0.1-0.1
      c-1.2-1.1-2.4-2.2-3.5-3.3c-0.5-0.5-0.9-1.1-1-1.6C250.7,650.4,249.9,646.9,249.1,643.6z M255,564.7l-0.1,0.8
      c-0.8,5.9-1.7,12.1-2.7,18.2c-0.5,2.7-1.1,5.4-1.8,8c-0.3,1.1-0.5,2.2-0.8,3.3c-0.2,0.7-0.3,1.4-0.5,2l-0.1,0.5
      c-0.7-0.4-1.3-0.7-2-1.1l-0.2-0.1c-1.7-0.9-3.4-1.9-5.2-2.9c-1-0.6-2.1-1.1-3.2-1.7h-0.1c-1.1-0.6-2.3-1.2-3.5-1.8
      c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.2-0.7-2.4-1.4-3.6-2c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5
      c-1.6-0.9-3.1-1.9-4.7-2.8c-0.6-0.4-1.2-0.8-1.9-1.2c0-0.1-0.1-0.2-0.3-0.2c-0.4-0.1-0.7-0.2-0.8-0.3c-0.2-0.2-0.1-0.7,0-1.2
      c0-0.2,0.1-0.4,0.1-0.6c0.1-1.9,0.2-3.7,0.3-5.6c0.1-1.6,0.2-3.1,0.2-4.7c0.1-1.2,0.1-2.3,0.2-3.5c0.1-1.9,0.2-3.7,0.3-5.6
      c0.4-6.5,0.8-13,1.1-19.5c0.3,0.2,0.6,0.3,0.8,0.5l0.5,0.3c1.2,0.7,2.5,1.4,3.8,2.2l2.1,1c2.8,1.6,5.7,3.3,8.4,5
      c4.5,2.8,7.8,5,10.9,7.1c1.8,1.2,3.6,2.6,5.3,3.9l0.2,0.1c1.8,1.4,3.7,2.8,5.6,4.1C255.2,562.6,255.1,563.5,255,564.7z
       M215.1,581.1L215.1,581.1c1.4,0.9,2.7,1.8,4,2.6c1.2,0.7,2.4,1.4,3.5,2l0.6,0.2c1.8,1,3.6,1.9,5.4,2.9l1.4,0.8
      c1,0.5,1.9,1,2.9,1.5c2,1.1,3.8,2,5.6,3c1.1,0.6,2.1,1.2,3.2,1.8c1.8,1,3.8,2.1,5.8,3.1c1.1,0.5,1.2,1.2,1,2.2
      c-0.2,1.3-0.4,2.6-0.6,3.9l-0.1,0.7c-0.6,4.3-1.3,8.7-1.9,13.2c-0.4,2.7-0.6,5.5-0.8,8.2c-0.1,1.3-0.2,2.6-0.4,3.9
      c0,0.2,0,0.3-0.1,0.5l-0.7-0.9c-0.7-0.9-1.5-1.9-2.2-2.9c-1.9-2.4-3.9-4.9-5.9-7.3l-0.6-0.7c-0.1-0.1-0.1-0.2-0.2-0.3
      c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0c-0.4,0-0.8,0-1.2,0c-0.8,0-1.5,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2
      c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2c-0.6,0-1.2,0.1-1.8,0.1c-0.3,0-0.7,0-1.1,0.1h-0.5l2.1-37.2
      C214.1,580.4,214.6,580.7,215.1,581.1z M211.4,580.2c-0.1,1.1-0.2,2.1-0.3,3.1c-0.1,1.7-0.2,3.4-0.2,5c-0.1,1.3-0.1,2.7-0.2,4.1
      c-0.1,1.8-0.2,3.7-0.3,5.4v0.3c-0.1,1.6-0.2,3.1-0.3,4.7c-0.1,1.6-0.2,3.1-0.2,4.7c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.5
      c-0.1,1.9-0.2,3.8-0.3,5.7c0,0.2-0.3,0.5-0.6,0.6c-2.4,1.1-4.8,2.1-7.1,3.1l-0.2,0.1c-1.1,0.5-2.1,0.9-3.2,1.4
      c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.1,5.5-6.1,8.2l-1,1.4c-0.8,1.2-1.6,2.5-2.3,3.7l-0.1,0.3c-0.4,0.7-0.7,0.9-1.2,0.9
      c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1s-1.9-0.6-2.9-1c-0.4-0.1-0.9-0.3-1.4-0.4h-0.1c-0.2,0-0.3-0.1-0.5-0.1v-65.9
      c13.8,0.9,27.1,3.4,38.3,5.6C211.5,579.5,211.5,579.9,211.4,580.2z M181.6,667.1c0.1-1.3,0.2-2.6,0.3-4.1v-0.3c0-0.3,0.1-0.7,0.1-1
      c0.4,0.3,0.8,0.5,1.2,0.8c1.2,0.8,2.4,1.6,3.6,2.2c2,1.1,4.2,2.2,6.2,3.1c0.9,0.4,1.1,0.7,0.8,1.6c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9
      c-0.3,0.9-0.5,1.8-0.7,2.6c-0.2,0.6-0.3,1.2-0.5,1.8c0,0.2-0.1,0.3-0.2,0.5c-0.2-0.2-0.5-0.3-0.7-0.5c-0.8-0.6-1.5-1.1-2.2-1.6
      c-2-1.6-3.9-3.2-5.7-4.7c-0.3-0.2-0.5-0.7-0.5-1.1C181.5,670,181.5,668.5,181.6,667.1z M182.4,682.3c0.9,3.7,1.7,7.6,2.4,11
      l0.2,0.9c0.2,0.9,0.4,1.9,0.6,2.8c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.8-0.2-2.8-0.3
      c-2.7-0.3-5.4-0.6-8.2-0.6c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1
      c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.4c0.8-3.7,1.6-7.5,2.5-11.2c0.5-2,1-3.9,1.6-5.8l0.4-1.4
      c0.7-2.3,1.1-2.4,2.5-2.5c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0h2.9v-0.1
      c0.5,0,1,0.1,1.4,0.1c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.5C181.3,678.1,181.9,680.2,182.4,682.3z M162.1,641.9
      c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.3-3.6-3.5-5.4c-0.7-1-1.3-2-2-3c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.2
      c-0.4-0.2-0.9-0.4-1.3-0.6c-1-0.5-2.1-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.4-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7v-0.1
      c-0.1-1.8-0.1-3.6-0.2-5.5c-0.1-2.1-0.3-4.3-0.4-6.2v-0.3c-0.1-1.9-0.3-3.9-0.4-5.9c-0.1-1.1-0.1-2.2-0.1-3.3c0-1.2,0-2.4-0.1-3.6
      c-0.1-1.7-0.3-3.5-0.4-5.1c-0.1-0.8-0.1-1.6-0.2-2.3c0-0.1,0-0.2,0-0.4c15.1-3.1,26.9-4.8,38.2-5.6V639l-2.1,0.7
      c-2,0.7-3.9,1.3-5.8,1.9C162.5,641.9,162.3,641.9,162.1,641.9z M129.7,576L129.7,576c0.1,0.4,0.1,1,0.2,1.5c0,0.2-0.1,0.5-0.1,0.6
      c-2.8,1.7-6,3.6-9.1,5.4c-2.1,1.2-4.2,2.3-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3c-1.5,0.8-3.1,1.7-4.6,2.5
      c-0.7,0.4-1.4,0.8-2.2,1.2l-0.9,0.5c-0.2-0.6-0.3-1.1-0.5-1.7c-0.4-1.6-0.9-3.2-1.3-4.8l-0.2-0.7c-0.7-2.8-1.3-5.8-1.8-8.7
      c-0.5-3.3-1-6.6-1.4-9.8c-0.2-1.4-0.4-2.7-0.6-4.1c0-0.2-0.1-0.5-0.1-0.7v-0.1c-0.1-0.5-0.1-1.1-0.3-1.6c-0.4-1.6,0.1-2.8,1.7-4
      c1.3-0.9,2.7-2,4-2.9c1.2-0.9,2.6-2,4-2.9c1.3-0.9,2.7-1.8,4.1-2.7h0.1c0.7-0.5,1.5-0.9,2.2-1.4c1.1-0.7,2.2-1.4,3.3-2.2
      c2-1.3,4.1-2.7,6.2-4c1.8-1.1,3.7-2.2,5.6-3.3c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5c0.2-0.1,0.4-0.2,0.7-0.4c0,0.7,0,1.3,0.1,2
      c0.1,1.8,0.1,3.6,0.2,5.4c0.1,2.2,0.3,4.5,0.5,6.7c0.2,2.9,0.4,5.8,0.6,8.8c0.1,2.5,0.2,5,0.3,7.4c0.1,1.6,0.1,3.6,0.2,5.6
      C129.5,574.4,129.6,575.2,129.7,576z M67.7,628c0.3,1.5,0.5,3,0.9,4.4c0.9,4,1.6,7.3,2.4,10.3c0.4,1.7,0.9,3.4,1.3,5
      c0.4,1.5,0.8,2.9,1.1,4.3c0.5,2,1,4.1,1.5,6.1l0.1,0.3c0.5,2.1,1,4.2,1.6,6.3c0.2,0.8,0.1,1.4-0.2,1.9c-0.4,0.6-1.2,1-2.5,1.2
      c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0c-0.6,0-0.9-0.2-1.1-0.7c-0.3-1.2-0.6-2.4-0.8-3.4
      c-0.3-1.3-0.6-2.7-0.8-4v-0.2c-0.5-2.5-1-5-1.6-7.5c-0.3-1.4-0.7-2.7-1-4c-0.3-1-0.6-2.1-0.8-3.1c-0.4-1.5-0.7-3.1-1-4.5l-0.1-0.3
      c-0.1-0.6-0.3-1.2-0.4-1.8c-0.1-0.4-0.2-0.9-0.3-1.3l-0.1-0.4c-0.3-1.3-0.6-2.7-0.9-4c-0.1-0.5-0.1-0.8,0-0.8
      c1.1-1.4,2.3-2.9,3.3-4.2l0.6-1.2C67.5,627.1,67.6,627.6,67.7,628z M64.8,653.2c0.4-0.4,0.7-0.7,1.1-1.1l5.5,27.5
      c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.6c-0.3-0.6-0.6-1.3-0.9-1.9l-0.2-0.4c-1.8-4.2-3.8-8.6-6.3-12.6l-0.2-0.3
      c-0.5-0.8-1.1-1.7-1.3-2.6c-0.2-0.6,0.2-1.6,0.6-2.1C62.6,655.4,63.7,654.3,64.8,653.2z M61.7,664.7c1.1,2.2,2.2,4.6,3.2,6.7
      l0.8,1.7c0.2,0.5,0.4,1,0.7,1.5l0.2,0.4c-0.2,0-0.4,0-0.6,0c-0.3,0-0.7,0-1,0c-0.5,0-0.8,0-1.2,0H63c-2.3,0.1-4.8,0.2-7.2,0.4
      c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.9c0-1.2,0-2.6,0-4v-0.3c0-0.4,0-0.8,0-1.2
      c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.8,0.5,1.1,0.9C60,661.5,60.8,663,61.7,664.7z M54.2,677.8
      c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.7-0.8l2.9-0.2c3.3,5.6,6.3,11.9,5.9,19.5h-0.4c-2.2-0.1-4.6-0.2-6.9-0.2
      c-3.8,0-7,0.3-9.9,1c0-0.4-0.1-0.7-0.1-1.1c-0.1-1-0.2-1.9-0.3-2.9c-0.3-2.9-0.6-6-0.9-9c-0.1-1.1-0.2-2.1-0.3-3.3
      c-0.1-0.9-0.1-1.7-0.2-2.6C54.2,677.9,54.2,677.8,54.2,677.8z M72.6,673.2v-0.3c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.1-0.9-0.3-1.3
      c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4c0.5-0.1,1.1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6c0.2-0.1,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.8c0.1,2.5,0.3,5.1,0.5,7.4c0.1,1,0.2,1.9,0.2,2.9c0.1,1,0.2,2.1,0.2,3.1l0.1,1
      c0.3,3.4,0.6,7.2,0.9,10.9c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5c0.1,1.3,0.3,2.6,0.3,3.8
      c-0.1-0.2-0.1-0.4-0.2-0.6c-0.2-0.7-0.5-1.5-0.7-2.1c-0.2-1.1-0.5-2-0.7-2.9c-0.9-3.9-1.6-7.8-2.3-11.5c-0.5-2.9-1.2-6.7-1.8-10.5
      c-0.2-1.5-0.4-3-0.5-4.3c-0.1-1-0.2-2.1-0.3-3.1c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8C73.8,680.6,73.2,676.8,72.6,673.2z M79.4,677.7
      c-0.3-1.8-0.5-3.7-0.5-5.6c0-1.3,0.5-2.4,1.7-3.4c1.6-1.4,3.1-2.9,4.6-4.3c0.9-0.9,1.9-1.8,2.9-2.8c1.3-1.2,2.6-2.4,3.8-3.5
      c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1c2.5,1.8,4.7,3.5,7,5.1c1.3,1,2.7,2,3.9,3c0.8,0.6,1.6,1.2,2.4,1.8c0.8,0.6,1,1.4,0.8,2.5
      c-0.4,2-0.8,4-1.2,6c-0.1,0.8-0.3,1.5-0.4,2.3l-0.4,2c-0.3,1.6-0.6,3.2-0.9,4.8c-0.3,1.7-0.6,3.5-0.9,5.3c-0.4,2.5-0.8,4.7-1.2,6.9
      c-0.3,1.6-0.9,1.8-1.5,1.8c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2c-3-1.3-6.1-2.7-9-4.1l-1.9-0.9
      c-0.2-0.1-0.4-0.4-0.4-0.7c-0.3-3.7-0.5-7.2-0.8-11.2L79.4,677.7z M105.2,712.5c0.1-2.4,0.5-4.6,0.8-6.8c0.1-0.9,0.3-1.8,0.4-2.7
      c0.1-0.9,0.6-1.4,1.7-1.7c4.8-1.1,8.8-2,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1c1.2-0.3,2.5-0.7,3.7-1c0.7-0.2,1.3-0.3,2.1-0.5
      c0.2,0,0.4-0.1,0.7-0.1l0.1,1.4c0.1,1.3,0.1,2.6,0.2,3.9v0.2c0.2,3.2,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4
      c0.3,0.6,0.1,0.9-0.3,1.4c-0.3,0.3-0.6,0.6-0.9,0.9l-0.1,0.1c-1,0.9-2,1.9-3,2.9c-1,1.2-2,2.4-3,3.6v0.1c-0.7,0.9-1.5,1.8-2.2,2.7
      l-0.3,0.4c-0.7,0.8-1.4,1.6-2.2,2.1c-0.3,0.1-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0s-0.6,0-0.9,0h-0.1c-0.6,0-1.3,0-1.9,0
      c-0.6,0-1.3,0-1.9,0s-1.2,0-1.7,0.1c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4c0.2-1.6,0.4-3.1,0.6-4.7
      C104.2,720.3,104.7,716.5,105.2,712.5z M107.6,730.5c1.2-0.1,2.6-0.3,4-0.4c1.9-0.2,4-0.3,6.2-0.4c1.1,0,2.1,0,3.1,0h0.1
      c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.8,0.1,1.2c0.1,1.2,0.2,2.1,0.3,3.2c0,0.4,0,0.9,0,1.4c0,0.3,0,0.7,0,1c0,0.4,0,0.8,0,1.2
      c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2,0.4,3c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4v0.1c0.2,2.4,0.5,4.9,0.8,7.3
      c0.5,3.4,1.1,6.8,1.7,10.1l0.1,0.4c0.1,0.6,0,0.9-0.4,1.4c-1.6,1.9-3.6,3.4-5.5,4.8c-0.6,0.4-1.1,0.8-1.7,1.2
      c-1.6,1.2-3.1,2.5-4.6,3.8c-0.5,0.5-1.1,0.9-1.6,1.4c-1.1,0.9-2.1,1.8-3.2,2.7l-1.1,1c0-0.2-0.1-0.4-0.1-0.6v-0.1
      c-0.2-0.9-0.4-1.7-0.5-2.5l-0.4-2.5c-0.6-3.5-1.3-7.6-1.9-11.4c-0.4-2.7-0.8-5.5-1.1-8.2l-0.1-0.5c-0.1-1-0.2-2-0.4-3
      c-0.4-0.8-0.5-1.7-0.7-2.6c-0.3-2.3-0.6-4.8-0.9-7.3l-0.1-0.7c-0.3-2.4-0.5-5-0.5-7.4c0-1.6,0.3-3.1,0.5-4.7
      c0.1-0.6,0.2-1.2,0.3-1.7c0.1-0.6,0.2-0.7,0.7-0.8C104.3,730.9,106,730.7,107.6,730.5z M120.4,797c-1.1-0.7-2.4-1.6-3.7-2.4
      c-0.9-0.6-1.8-1.1-2.8-1.7c-0.8-0.5-1.7-1-2.5-1.5c-0.8-0.5-1.5-1.1-2.1-1.7c0,0-0.1-0.2-0.1-0.5c0-0.5,0-1,0-1.4l-0.1-0.4l18-15
      l0.5,0.4c0.6,0.5,1.2,1,1.8,1.5c0.7,0.6,1.4,1.2,2.2,1.8l0.2,0.2c1.7,1.5,3.5,3,5.4,4.5c0.9,0.8,1.9,1.5,2.9,2.1
      c0.8,0.6,1.7,1.2,2.5,1.9c0.9,0.7,1.7,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2c0.4,0.3,0.4,0.5,0.2,1c-0.4,1.4-0.8,2.9-1.1,4.3v0.1
      c-0.6,2.2-1.2,4.5-1.8,6.7c-0.7,2.3-1.5,4.7-2.2,7l-0.5,1.4c-0.2,0.6-0.5,0.6-0.6,0.6c-0.2,0-0.4-0.1-0.6-0.2
      c-1.1-0.7-2.1-1.4-3.2-2c-1.4-0.9-2.9-1.9-4.4-2.8c-1.2-0.8-2.5-1.5-3.7-2.2l-0.2-0.1c-1.5-0.9-3.1-1.8-4.6-2.8
      C123.3,799,121.8,798,120.4,797z M197.4,795.1l-0.1-0.3c-0.2-0.8-0.4-1.5-0.6-2.3c-0.1-0.2-0.1-0.4-0.1-0.6
      c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.4-1,2.8-2.1,4-3c1.6-1.2,3.5-2.6,5.3-4c1.3-1,2.6-2.1,3.8-3.1l0.1-0.1
      c0.7-0.6,1.3-1.1,2-1.7c1.1-0.9,2.3-1.9,3.4-2.8l1.3-1.1c0.6,0.4,1.1,0.9,1.6,1.3c1.4,1.2,2.8,2.2,4.2,3.4c0.7,0.6,1.4,1.2,2.1,1.8
      l0.1,0.1c0.7,0.6,1.4,1.2,2.2,1.8c2.9,2.4,5.4,4.4,7.7,6.1c0.4,0.3,0.4,0.4,0.3,0.7c-0.3,0.1-0.4,0.4-0.3,0.6
      c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.4,0.3c-3,2-6.1,4-9.2,5.9c-1.2,0.8-2.5,1.5-3.7,2.2l-0.3,0.2
      c-0.6,0.3-1.2,0.7-1.8,1.1l-0.6,0.4c-1.3,0.8-2.7,1.6-4,2.5c-1.3,0.8-2.7,1.7-4,2.5c-1.1,0.7-2.2,1.4-3.3,2.1
      c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.6-1.7-0.8-2.5c-0.7-2-1.4-4.1-2-6.1
      C198.4,799.1,197.8,796.9,197.4,795.1z M238.7,772c-0.5,3.5-1.2,7.1-1.8,10.3l-0.4,1.7c-0.1,0.3-0.2,0.7-0.3,1.1v0.1
      c-0.9-0.7-1.7-1.5-2.6-2.2c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.8-2.1c-0.8-0.7-1.6-1.5-2.3-2.3
      c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.4-0.2-0.6c0.2-1,0.4-1.9,0.6-2.9v-0.2c0.3-1.4,0.6-2.9,0.8-4.3c0.3-1.6,0.4-3.2,0.6-4.7
      c0.1-0.5,0.1-1,0.2-1.5c0.3-2.7,0.6-5.6,1-9.1l0.1-1.4c0.2-2.2,0.5-4.6,0.7-6.8c0-0.4,0-0.7-0.1-1.1c0-0.3-0.1-0.6-0.1-0.9
      c0-0.7,0.1-1.4,0.2-2c0.1-0.7,0.1-1.5,0.2-2.2c0-0.5-0.1-1-0.1-1.5c0-0.2,0-0.3-0.1-0.5c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1
      h0.2c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4c1.6,0.2,3.3,0.4,5,0.5c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2
      c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.4-0.7,6.3c-0.3,2.5-0.5,5-0.8,7.6C240.4,761.2,239.5,767,238.7,772z M239.4,728.7
      c-1.6-0.2-3.2-0.3-4.8-0.5l-1.8-0.2c-2.3-0.2-4.6-0.4-6.9-0.6c-0.2,0-0.4,0-0.6,0c-0.6,0-1.1,0-1.7,0.1c-0.5,0-1.1,0.1-1.6,0.1
      c-0.2,0-0.5-0.1-0.9-0.4c-1.8-1.4-3.2-3.2-4.5-4.9c-0.7-0.9-1.4-1.8-2.2-2.7c-1.2-1.3-2.5-2.7-4.2-4.1c-1.2-1-1.2-1-0.8-2.6
      c0.4-2.2,0.6-4.5,0.8-6.6c0.2-1.8,0.3-3.6,0.4-5.4l0.1-1.2c0.1-1.2,0.1-2.4,0.2-3.4v-1.2c0.4,0.1,0.8,0.2,1.1,0.2
      c1.1,0.2,2.1,0.4,3.1,0.7c2.2,0.6,4.3,1.2,6.4,1.8c2.5,0.7,5.3,1.5,8.1,2.2c2,0.5,4,0.9,6.2,1.3c1,0.2,1.5,0.7,1.7,1.9
      c0.6,4.1,1.2,8.3,1.8,12.3l0.6,3.8c0.3,2,0.6,3.9,0.9,5.9l0.5,3.6L239.4,728.7z M238.9,699.5c-0.3-1.6-0.6-3.2-0.9-4.8
      c-0.2-1.3-0.5-2.6-0.7-3.8c-0.1-0.8-0.2-1.6-0.4-2.4v-0.1c-0.1-0.8-0.2-1.7-0.4-2.5c-0.2-1.1-0.4-2.2-0.6-3.3
      c-0.2-1-0.4-1.9-0.5-2.8c-0.1-0.9-0.3-1.9-0.5-2.8c-0.2-1-0.3-2-0.5-3.1c-0.2-1-0.4-1.9-0.7-2.7c-0.4-1.1,0.2-1.8,1.2-2.5
      c1.3-1,2.6-1.9,4-2.9c1.4-1,3-2.2,4.6-3.4c1.7-1.3,3.3-2.5,5.1-3.9c0.7-0.5,1.4-1.1,2.1-1.6c0.3,0.2,0.6,0.5,0.9,0.8
      c0.9,0.7,1.8,1.4,2.6,2.2c1.9,1.8,3.8,3.7,5.7,5.6l0.4,0.4c0.7,0.7,1.3,1.3,2,2l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2
      c2.6,1.4,2.8,3.8,2.6,6.2c-0.3,4.7-0.7,9.9-1.3,16.1c0,0.3-0.2,0.5-0.3,0.6c-0.7,0.3-1.5,0.7-2.2,1c-3.1,1.5-6.3,3-9.5,4.3
      c-2.6,1.1-5.3,2.1-7.9,3c-0.8,0.3-1.6,0.6-2.4,0.9c-0.5,0.2-1,0.3-1.5,0.2C239.4,700.4,239,700.1,238.9,699.5z M263.9,712.1
      L263.9,712.1c0.1-0.8,0.1-1.6,0.2-2.3c0.5-4.2,0.8-7.6,1.2-10.7c0.2-2.3,0.4-4.5,0.6-7.1c0.1-0.8,0.1-1.6,0.2-2.4v-0.1
      c0.2-2.6,0.4-5.2,0.5-7.9c0.1-2.7,0.2-5.5,0.2-8.1c0-1.1,0-2.3,0.1-3.4c0-0.2,0-0.5,0-0.8v-0.2l0.6,0.1c0.7,0.1,1.4,0.3,2.1,0.5
      c0.2,0,0.4,0.1,0.6,0.1c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.2,0,0.8-0.1,1.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.2,1.5-0.5,2.9-0.7,4.4
      l-0.1,0.5c-0.5,3.4-1.1,6.9-1.6,10.4c-0.3,1.9-0.5,3.8-0.6,5.6v0.1c-0.2,1.5-0.3,3.1-0.5,4.6c-0.3,2.6-0.8,5.2-1.2,7.7
      c0,0.7-0.1,1.1-0.2,1.5l-0.1,0.4c-0.4,2.2-0.7,4.4-1.3,6.6c-0.2,1-0.4,2-0.6,3v0.1c-0.5,2.5-1,5.1-2.1,7.4
      c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5V725c0.1-2.1,0.2-4.2,0.8-6.1C263.5,716.6,263.7,714.3,263.9,712.1z M276.6,676.8
      c0.5,0,0.9,0,1.4,0.1h0.7c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6c0.1,0,0.2,0.2,0.2,0.3c-0.3,3.8-0.7,7.6-1.1,11.4
      l-0.3,4.5c-0.1,0.7-0.1,1.3-0.2,2.1l-0.1,0.8c-3-0.7-6.2-1-10.1-1c-2.4,0-4.7,0.1-7.1,0.3C270.3,688.4,273.4,682,276.6,676.8z
       M288.8,675.3c-0.1,0-0.2,0-0.2,0c-0.4,0.1-0.9,0-1.4-0.1c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.2,0-3.6,0c-0.8,0-1.6,0-2.4,0
      c-0.6,0-1.3,0-2,0c0.1-0.3,0.3-0.6,0.4-0.9l0.1-0.2c0.5-1.1,0.9-2.1,1.4-3.1c1-1.9,2-3.8,2.9-5.6l0.1-0.1c0.6-1.1,1.2-2.3,1.8-3.4
      c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.4-0.9c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2c0.3,0,0.7,0,1,0
      c0,0.5,0,1.1,0,1.6c0,1.4,0,2.7,0,4.1c-0.1,2.6-0.2,5.3-0.3,7.9L290,674C290,674.9,289.6,675.3,288.8,675.3z M282.5,583
      c0.2-1.4,0.4-2.8,0.6-4.2v-0.1l0.1,0.8c0.3,1.9,0.6,3.8,0.9,5.7l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5c0.2,1.3,0.3,2.7,0.5,4
      c0.2,2.1,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8l-0.1,0.4c-0.5,2-0.8,4-1.2,6v0.1c-0.2,1.2-0.4,2.4-0.7,3.6
      c-0.3,1.6-0.7,3.1-1,4.7l-0.1,0.3c-0.3,1.2-0.5,2.5-0.8,3.7c-0.2,0.9-0.3,1.7-0.5,2.6l-0.1,0.6l-0.4-1.1c-1.1-1.3-2.2-2.6-3.2-3.9
      c-0.2-0.2-0.3-0.7-0.2-1.1c0.3-1.7,0.5-3.5,0.8-5.2c0.3-1.8,0.6-3.9,0.9-5.9c0.2-1.2,0.3-2.3,0.4-3.5v-0.1c0.1-0.9,0.2-1.9,0.3-2.8
      c0.2-1.4,0.4-2.8,0.6-4.1v-0.3c0.1-0.9,0.3-1.8,0.4-2.6c0.1-0.9,0.2-1.9,0.3-2.7l0.1-1.1c0-0.1,0-0.1,0-0.2
      c0.7-1.1,0.6-2.3,0.6-3.4c0-0.8-0.1-1.6,0.1-2.3c0.4-1.4,0.6-2.8,0.7-4.2C282.4,584.2,282.4,583.6,282.5,583z M259.1,543.3
      c0.1-1.6,0.3-3.1,0.4-4.7v-0.1c0.1-1.1,0.2-2.2,0.2-3.4c0.1-0.8,0.2-1.6,0.3-2.4v-0.2c0-0.1,0-0.3,0.1-0.4c0.3,0.3,0.6,0.7,0.9,1
      c0.9,1.1,1.9,2.1,2.7,3.3c1.6,2.1,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c1,1.6,1.8,3.3,2.7,4.9c0.4,0.8,0.9,1.8,1.4,2.7
      c1.6,2.9,2.6,6.2,3.3,10.4c0.3,1.9,0,3.6-0.3,5.2c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.8-0.8,5.6-1.3,8.5v0.1c-0.2,0.9-0.3,1.9-0.5,2.9
      c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-1.1-1.3-2.1-2-3.2c-1.2-2-2.5-4-3.8-6c-1.1-1.7-2.3-3.3-3.5-4.9l-0.1-0.1c-0.6-0.9-1.3-1.8-1.9-2.7
      c-0.5-0.8-1-1.5-1.6-2.3c-0.9-1.3-1.9-2.7-2.9-4c-1.1-1.4-2.3-2.7-3.4-4l-0.1-0.1c-0.7-0.7-1.4-1.5-2-2.3c-0.1-0.2-0.2-0.5-0.2-0.7
      c0.2-2.4,0.5-4.8,0.7-7.2C258.6,548.8,258.8,546.1,259.1,543.3z M214.5,497.7c1.1,0.5,2,1,3.1,1.5c1.6,0.8,3.2,1.5,4.8,2.2
      c2.8,1.3,5.7,2.6,8.6,4c1.4,0.7,2.7,1.4,4,2.1h0.1c1.4,0.8,3,1.7,4.5,2.4c3.3,1.5,6.1,3.9,9,7.7c1.3,1.6,2.6,3.3,3.9,4.9l0.1,0.2
      c1,1.3,2.1,2.6,3.1,3.9c0.2,0.3,0.5,0.6,0.7,0.9c0.4,0.6,0.8,1.2,1.3,1.7c0.7,0.6,0.6,1.2,0.5,2c0,0.2-0.1,0.4-0.1,0.6
      c-0.3,2.5-0.5,5-0.8,7.5l-0.1,0.7c-0.1,0.9-0.2,1.8-0.3,2.7l-0.5,5.1c-0.3,3.3-0.7,6.6-1,9.9c-0.1,0.6-0.1,1.2-0.1,1.7
      c0,0.2,0,0.3,0,0.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.8-0.6-1.6-1.3-2.5-1.9c-0.5-0.4-1-0.7-1.5-1.1l-0.3-0.2c-2.1-1.5-4.3-3.1-6.5-4.6
      c-5.6-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7c-0.1,0-0.2-0.3-0.2-0.5
      c-0.1-1-0.1-1.9,0-2.7l0.1-2.5c0.2-3.8,0.4-7.7,0.6-11.6c0.2-2.4-0.2-4.8-0.6-7.1c-0.1-0.4-0.1-0.8-0.2-1.2c-0.4-2.5-0.9-5-1.5-7.3
      l-0.2-0.8c-0.2-0.8-0.4-1.6-0.6-2.3c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.1-0.6L214.5,497.7z M173.1,487.9
      c0.3,0,0.6,0,0.8,0c0.4,0,0.7,0,1.1,0c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2c1.4,0.1,2.9,0.2,4.3,0.3
      c1.5,0.1,3,0.2,4.5,0.2c1.7,0.1,3.3,0.2,4.8,0.3c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1c1.5,0.6,3,1.2,4.5,1.9
      c0.4,0.2,0.8,0.7,1,1.3c0.5,1.7,1,3.5,1.4,5.2c1.2,5,2.2,9.5,2.4,14.1c0,3.1-0.2,6.2-0.4,9.2c-0.1,1.1-0.2,2.6-0.2,3.9
      c-0.1,1.2-0.1,2.5-0.2,3.7l-0.1,1.3c-5.9-1.4-11.8-2.9-17.5-4.4l-0.2-0.1c-7.6-2-15.5-4-23.4-5.8V487.9z M173.2,526.8
      c0-0.3,0-0.6,0.1-0.9l41,10.3l-2.3,41.1c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.4-0.3-2.7-0.6-4-0.9c-2.9-0.6-5.7-1.1-8.1-1.5
      c-1.8-0.3-3.9-0.7-5.9-1c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3c-0.9-0.1-1.7-0.1-2.6-0.2
      c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9c0-1.9-0.1-3.9-0.1-5.8v-0.1c0-1.7-0.1-3.6-0.1-5.5c0-1.3,0-2.7,0-4v-0.1
      c0-1.2,0-2.5,0-3.7c0-1,0-2.1-0.1-3.1c0-1.1-0.1-2.2-0.1-3.3C173,528.7,173.1,527.7,173.2,526.8z M172,641c0.3,0,0.6,0.1,1,0.2
      l0.2,0.1c1.1,0.3,2.1,0.7,3.2,1l0.2,0.4c1.5,0.5,2.9,0.9,4.2,1.3c0.5,0.1,0.6,0.3,0.5,0.8c-0.4,4.7-0.8,9.5-1.2,14.3
      c-0.2,2.3-0.4,4.6-0.5,6.9c-0.1,1.4-0.2,2.8-0.3,4.1c0,0.1,0,0.2-0.1,0.4l-0.9-0.1c-1-0.1-2-0.3-2.9-0.4c-0.1,0-0.2-0.1-0.3-0.1
      c-0.6,0-1.2,0-1.9,0h-0.1c-1.6,0-3.3,0.1-5,0.1c-0.9,0-1.7,0.1-2.6,0.2c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1
      c-0.1-1.2-0.1-2.3-0.2-3.4c-0.2-1.8-0.3-3.7-0.5-5.5v-0.1c-0.2-2.3-0.4-4.7-0.6-7c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6
      c3.3-1.1,6.2-2,8.8-2.8C171.9,641,171.9,641,172,641z M132.7,496c0.1-0.3,0.5-0.7,0.9-0.8c3.8-1.6,7.6-3.2,10.8-4.6
      c3.2-1.4,6.8-2.1,11-2.2c2.5-0.1,5.1-0.2,7.6-0.4h0.1c1.6-0.1,3-0.2,4.4-0.2c0.4,0.2,0.8,0.1,1.3,0.1c0.3,0,0.6,0,0.9,0h0.2
      c0.3,0,0.6,0,0.9,0v35.8c-7.9,1.8-15.8,3.8-23.5,5.8h-0.1c-5.7,1.5-11.6,3-17.5,4.4c0-0.2-0.1-0.5-0.1-0.7
      c-0.1-0.8-0.2-1.6-0.2-2.4c-0.1-1.4-0.1-2.9-0.2-4.3c0-1.4-0.1-2.8-0.2-4.3v-0.6c-0.2-3.6-0.4-7.3,0.4-10.9
      c0.4-1.9,0.8-3.7,1.1-5.6V505c0.3-1.7,0.7-3.6,1.1-5.5C131.8,498.4,132.2,497.3,132.7,496z M86.4,528.8c0.6-0.8,1.3-1.7,1.9-2.5
      l0.1-0.1c1.1-1.5,2.2-3,3.4-4.5c3-3.6,5.7-6.6,8.4-9.4c0.6-0.6,1.5-1.1,2.4-1.6c0.4-0.2,0.8-0.4,1.2-0.6c5.1-3.1,10.4-5.5,14.9-7.6
      c2.7-1.2,5.4-2.5,8-3.8l0.3-0.2c1.1-0.5,2.2-1.1,3.3-1.6c0.1,0,0.1-0.1,0.2-0.1l-0.3,0.5c-0.2,0.6-0.3,1.2-0.5,1.8
      c-0.2,0.7-0.3,1.3-0.5,1.9c-0.4,1.3-0.7,2.7-1,4.1c-0.2,1.1-0.4,2.2-0.6,3.2v0.1c-0.1,0.9-0.3,1.9-0.5,2.9c-0.6,3-0.5,6-0.3,8.9
      c0,0.4,0,0.9,0.1,1.3c0.1,1.8,0.2,3.7,0.3,5.5v0.6c0.1,1.3,0.1,2.6,0.2,3.8c0,1,0,1.9,0,2.8c0,0.2-0.2,0.5-0.3,0.6
      c-1.4,0.8-2.9,1.6-4.4,2.5c-2.1,1.2-4.4,2.5-6.6,3.8c-1.8,1.1-3.6,2.2-5.3,3.3c-0.8,0.6-1.7,1.1-2.6,1.7c-0.9,0.6-1.7,1.1-2.6,1.7
      l-0.1,0.1c-1.7,1-3.4,2.1-5,3.2c-2,1.3-4,2.8-5.9,4.2l-0.6,0.4c-1.3,0.9-2.5,1.9-3.8,2.9l-0.2,0.1c-0.5,0.3-0.9,0.7-1.4,1
      c0-0.2,0-0.4-0.1-0.7v-0.3c-0.1-1.1-0.2-2.1-0.3-3.1c-0.2-2.2-0.5-4.5-0.7-6.7l-0.1-0.6c-0.3-2.7-0.6-5.3-0.8-8
      c-0.1-1.3-0.2-2.6-0.3-3.9v-0.1c-0.1-0.9-0.2-1.9-0.2-2.8c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8
      C85.4,530.3,85.7,529.7,86.4,528.8z M64.5,562.7c0.3-0.6,0.5-1.2,0.8-1.7c0.8-1.7,1.6-3.4,2.6-5.1c2-3.4,4.1-6.9,6.1-10.1
      c1.6-2.6,3.3-5.2,5-7.5c1.1-1.5,2.3-3,3.5-4.4c0.4-0.5,0.9-1.1,1.3-1.6l0.1,1.7c0.1,1.5,0.2,3,0.4,4.4c0.2,1.9,0.4,3.7,0.5,5.6v0.1
      c0.2,1.6,0.3,3.3,0.5,4.9c0.3,3.6,0.6,6.9,0.9,10c0.1,0.1,0,0.3-0.1,0.4c-0.9,1.1-1.8,2.1-2.7,3.2c-1.6,1.9-3.2,3.8-4.7,5.8
      c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.5-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2-1.3-0.4-2.6-0.6-3.9
      c-0.5-3.5-1-6.9-1.6-10.3C61.5,571.4,62.3,567.2,64.5,562.7z M62.8,629v-0.1c-0.1-0.7-0.2-1.3-0.4-1.9c-0.2-0.9-0.4-1.8-0.6-2.7
      v-0.1c-0.3-1.5-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4v-0.1c-0.2-1.2-0.4-2.5-0.7-3.7l-0.1-0.4c-0.3-1.6-0.6-3.3-1.2-4.9
      c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.1c0.3-2.6,0.6-5.3,1-8c0.4-2.9,0.9-5.9,1.5-8.7c0.2,1.2,0.4,2.3,0.5,3.5
      c0.3,1.7,0.5,3.5,0.8,5.2l0.4,2.6c0,0.1,0,0.1,0,0.2l0.1-0.1c0,0,0,0.1,0,0.1c-0.3,1.4,0,2.7,0.3,4c0.2,0.7,0.3,1.4,0.4,2.1
      c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.7,1,7l0.1,0.6c0.3,1.7,0.5,3.4,0.8,5.1c0.4,2.4,0.7,5,1,7.6c0,0.3-0.1,0.8-0.4,1.2
      c-1,1.2-2,2.4-3.1,3.7l-0.1,0.2C63.1,628.6,62.9,628.8,62.8,629z M43.6,655.2c1.4-1.8,2.7-3.7,4.1-5.5c0.1-0.1,0.2-0.3,0.3-0.5
      c0.2-0.4,0.5-0.7,0.8-0.9l0.1-0.1c0.6-0.5,1.3-1.1,2-1.3c3.6-1,7.4-2,11.5-3.1c0.4-0.1,0.6-0.1,0.8-0.1s0.2,0,0.5,1.1
      c-0.1,0.6,0,1,0.2,1.4c0.2,0.6,0.4,1.3,0.5,1.9c0.1,0.8,0.1,1.4-0.1,1.8c-0.3,0.6-0.8,1.1-1.3,1.6c-0.2,0.2-0.5,0.5-0.7,0.8
      l-0.7,0.8c-0.9,1.1-1.9,2.2-2.7,3.4c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1c-1.2,0-2.4-0.1-3.5-0.2h-0.1c-1.4-0.1-2.9-0.2-4.3-0.2
      c-1.6,0-2.9,0.1-4.2,0.4c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0.2L43.6,655.2z M49.8,688.3
      c-0.2,0.1-0.4,0.2-0.7,0.3c-0.2-0.7-0.5-1.5-0.8-2.2c-0.7-2-1.4-4.1-2.1-6.2c-0.3-0.9-0.7-1.8-1-2.7c-0.3-0.7-0.6-1.6-0.9-2.4
      c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2c0,0,0.2-0.2,0.3-0.2c1.9-0.5,3.8-0.9,5.7-1.3
      l1.7-0.4c0.1,0,0.2,0,0.2-0.1c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5c0.1,1.6,0.1,3.2,0.2,4.7v0.2c0,2,0.1,4,0.2,6
      c0.1,1.8,0.4,3.7,0.6,5.5c0.1,1.1,0.3,2.2,0.4,3.4c0.2,1.9,0.4,4,0.4,6c0,0.1-0.3,0.3-0.6,0.4C51.4,687.7,50.6,688,49.8,688.3z
       M51.5,703.7L50,690.9l1.4-0.8l1.5-0.7l1.1,13.5L51.5,703.7z M65,726.3c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2
      c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.5-1.1-0.9-1.7-1.4l-0.2-0.1c-1.4-1.2-3.1-2.5-4.5-3.8c-1.4-1.3-2.3-2.4-2.8-3.5
      c-0.4-0.8-0.5-1.7-0.6-2.7c0-0.5-0.1-0.9-0.2-1.4l-0.6-3.6c-0.4-2.1-0.7-4.2-1.1-6.3c-0.1-0.5-0.1-1-0.1-1.5c0-0.1,0-0.3,0-0.4
      c0.3-0.1,0.4-0.2,0.6-0.2l0.1-0.1c0.6-0.2,1.2-0.5,1.8-0.8c0.4-0.2,0.6-0.2,1,0.3l2.1,2.5c2.4,2.9,5,6,7.4,9
      c0.2,0.2,0.3,0.6,0.3,0.9C64.7,720.4,64.7,723,65,726.3z M63.4,711.6c-1.7-2.1-3.5-4.3-5.5-6.2c-1.5-1.5-1.6-3.3-1.7-5.3
      c0-0.4,0-0.7-0.1-1.1c4.1-1,8.3-1.1,11.9-1.1c0.7,0,1.4,0,2.2,0c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2c-0.4,0-0.8,0.1-1.2,0.1h-0.4
      c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4C65.3,713.9,64.2,712.7,63.4,711.6z M82.8,736.1c-0.1,0.1-0.4,0.2-0.7,0.1
      c-2.1-0.2-4.1-0.5-6.2-0.7l-0.9-0.1c-1.8-0.2-3.6-0.4-5.4-0.6c-0.6-0.1-1.1-0.2-1.6-0.3c-0.4-0.1-0.6-0.3-0.5-1v-0.3
      c0.1-1.8,0.2-3.7,0.1-5.5c0-1.4-0.1-2.8-0.2-4c0-0.6-0.1-1.2-0.1-1.9c0-0.9-0.1-1.8-0.1-2.7v-1l9.7-0.9c0,0.1,0,0.1,0,0.2v0.2
      c0.1,0.5,0.2,0.9,0.4,1.4c0.8,2.2,1.5,4.4,2.3,6.6l0.1,0.4c0.7,2,1.4,3.9,2,5.9c0.4,1.2,0.8,2.4,1.1,3.5
      C82.9,735.7,82.9,736,82.8,736.1z M83.4,718.7c-0.1-0.7-0.3-1.4-0.6-2c-0.2-0.7-0.5-1.5-0.6-2.2c0-2.1-0.2-4.2-0.4-6.3
      c-0.1-0.9-0.2-1.8-0.3-2.8s-0.2-1.9-0.3-2.9c-0.2-1.5-0.3-3.1-0.5-4.6c-0.1-1.2-0.2-2.4-0.3-3.5l-0.1-1.2h0.1
      c0.4,0.1,0.9,0.3,1.2,0.5c1.1,0.5,2.2,1.1,3.2,1.6c2.1,1.1,4.2,2.1,6.4,3.1c2.5,1.1,5.1,1.9,7.6,2.8l0.3,0.1
      c0.5,0.2,1.1,0.4,1.6,0.5c0.8,0.3,1.6,0.4,2.5,0.6c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.1c-0.2,1.1-0.3,2.2-0.5,3.2
      c-0.3,1.7-0.5,3.4-0.8,5.1c-0.4,2.5-0.8,5-1.2,7.5c-0.2,1.4-0.4,2.9-0.6,4.4s-0.4,3.1-0.7,4.8c-0.1,0.4-0.2,0.4-0.2,0.4
      c-0.2,0-0.4-0.1-0.6-0.2h-0.1c-1.3-0.7-2.6-1.5-3.8-2.2l-0.2-0.1c-0.7-0.4-1.4-0.9-2.2-1.4l-0.1-0.1c-1-0.6-2.1-1.3-3.2-2
      c-1.2-0.7-2.5-1.5-3.7-2.1c-0.6-0.3-1.2-0.7-1.8-1C83.7,719.7,83.5,719.4,83.4,718.7z M95.3,761l-0.1-0.1c-1.4-2.7-2.8-5.4-4.1-8.1
      c-0.6-1.3-1.2-2.7-1.7-4c-0.7-1.6-1.3-3.3-2.2-4.9c-1.3-2.5-1.6-5.2-1.9-7.9c0-0.4-0.1-0.7-0.1-1.1c-0.3-3-0.6-5.9-0.9-9v-0.1
      c-0.1-1.1-0.2-2.3-0.3-3.4l15.7,9.4c-0.7,3.7-1.3,7.6-0.5,11.8c0.4,2.4,0.7,4.9,0.9,7.3v0.1c0.1,1.4,0.3,2.9,0.5,4.3
      c0.7,5.5,1.4,10.9,2.2,16.2c0.3,2.3,0.8,4.7,1.2,6.9c0.1,0.5,0.2,0.9,0.3,1.4c-0.3-0.6-0.5-1.2-0.8-1.8l-0.2-0.4
      c-1.2-2.8-2.5-5.7-3.9-8.5C98.1,766.4,96.7,763.6,95.3,761z M107.1,883.1l-0.1,0.2c-0.4,0.9-0.8,1.7-1.2,2.6
      c-0.1,0.2-0.2,0.4-0.3,0.6v0.1c-7.7-7.1-13.9-13.3-19.4-19.5c0.2-0.3,0.3-0.5,0.5-0.8c0.5-0.8,1-1.5,1.5-2.3c0.7-1,1.4-1.9,2.1-2.9
      c0.6-0.9,1.2-1.7,1.8-2.6l0.5-0.7c0.7-1,1.4-2,2-3c1.4-2.4,2.8-4.9,4.2-7.3v-0.2c0.6-1.1,1.2-2.1,1.8-3.2c0.2,0.2,0.4,0.4,0.7,0.7
      l0.1,0.1c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1c1.8,1.4,3.8,2.7,5.6,3.9l1.3,0.9c0.4,0.3,0.5,0.7,0.4,1.4l-0.1,0.3
      c-0.3,1.4-0.7,2.8-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3c-0.6,1.3-1.2,2.5-1.8,3.7c-0.4,0.8-0.9,1.8-1.3,2.7
      C108.4,880.1,107.8,881.6,107.1,883.1z M150.1,868.9c-0.4,12.1-0.8,24.6-1.9,37.1c-0.2-0.1-0.5-0.1-0.7-0.2
      c-0.8-0.2-1.5-0.4-2.2-0.7c-1-0.4-2.1-0.8-3.1-1.2c-0.7-0.3-1.3-0.5-2-0.8c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-2.6-1-4-1.5
      c-2.5-1-5.2-2.2-8.2-3.5c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.4-1.7-5.1-2.5l-0.1-0.1c-0.9-0.4-1.7-0.9-2.6-1.3
      c-0.2-0.1-0.5-0.2-0.7-0.4l-0.1-0.1c-0.7-0.4-1.4-0.7-2.2-1.1c-0.3-0.1-0.4-0.2-0.4-0.3c0-0.1,0-0.1,0.1-0.2
      c1.3-2.7,2.5-5.5,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.6-1.4,1-2.1c0.6-1.3,1.2-2.6,1.8-3.9
      c0.7-1.7,1.2-3.5,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6c0.1-0.2,0.1-0.4,0.1-0.6c0.4,0.2,0.8,0.3,1.2,0.4c0.4,0.1,0.9,0.3,1.3,0.4
      c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4h0.1c1.7,0.2,3.4,0.3,5.1,0.5
      c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1c0.4,0.1,0.8,0.1,1.2,0.2C150.2,865.4,150.2,867.2,150.1,868.9z M150.6,852L150.6,852
      c-0.1,1.8-0.2,3.5-0.2,5.2c0,0.9,0,1.8,0,2.8v0.3c0,0.2,0,0.5,0,0.7c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8.1-1
      c-1.6-0.2-3.2-0.3-4.7-0.5h-0.1c-1.5-0.1-3.1-0.3-4.6-0.4c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5
      c0.1-0.8,0.1-1.6,0.1-2.4c0-0.5,0-1,0-1.5c0-0.9,0.1-1.7,0.1-2.6v-0.8c0.2-3.7,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.5
      c0.2-2.3,0.5-5.1,0.7-7.8c0.1-1.9,0.2-3.8,0.3-5.5c0.1-1.2,0.1-2.4,0.2-3.6c0.1-1.7,0.2-3.3,0.4-5.2v-0.4c0-0.5,0.1-0.9,0.1-1.4
      c0.5,0.3,1.1,0.7,1.6,1c1.5,0.9,3.1,1.8,4.6,2.8c0.9,0.6,1.8,1.1,2.7,1.7c2.5,1.5,5,3,7.4,4.7c2.5,1.8,5.4,2.1,8.1,2.5h0.3
      c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0.1,0.6,0.1s0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6
      c-0.1,1.3-0.2,2.6-0.2,3.9v0.3c-0.1,1.2-0.1,2.5-0.2,3.7c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3
      c0.1,1.5,0.1,3.1,0.1,4.7c0,1.6,0.1,3.2,0,4.7C150.8,846.5,150.7,849.3,150.6,852z M193.2,905.9c-0.1,0.1-0.2,0.1-0.4,0.1
      c-0.6,0-1.3,0.1-1.9,0.2h-0.4V906h-0.4c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5c-1.6,0.1-3.2,0.1-4.7,0.1
      c-0.7,0-1.5,0-2.2,0c-0.8,0-1.5,0-2.3,0.1c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2c-2.7-0.2-5.3-0.3-7.9-0.5l-2-0.1
      c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.5-0.4-0.5c0-2.5,0.2-5,0.4-7.4c0.1-1.7,0.2-3.3,0.3-5
      c0.3-4,0.5-7.9,0.7-11.7c0.1-1.3,0.1-2.7,0.1-4v-0.1c0-1.1,0-2.1,0.1-3.1c0-1.4,0.1-2.9,0.2-4.2c0-0.9,0.1-1.9,0.1-2.9
      c0.1-1.2,0.1-2.4,0.1-3.7c0-0.4,0.1-0.5,0.1-0.6c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1c3.2,0.1,6.5,0.2,9.7,0.5
      c2.6,0.2,5.1,0.2,7.6,0c3.2-0.3,6.5-0.4,9.7-0.5h0.3c1.5,0,3.1-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7c0.1,3.9,0.2,8,0.4,12.2
      c0.1,3.3,0.3,6.6,0.5,9.8l0.2,2.9c0.2,4.2,0.5,8.5,0.7,12.9c0.1,1.4,0.2,2.8,0.4,4C193.3,905.6,193.3,905.8,193.2,905.9z
       M192.3,815.1l0.5-0.2c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2c2-0.2,4-0.5,5.8-1.5c2-1.2,4.1-2.4,6-3.7
      c0.5-0.3,1-0.6,1.5-1c2.5-1.6,5.1-3.2,7.4-4.7l2.8-1.8c0,0.4,0,0.8,0.1,1.2v0.1c0.1,1.2,0.1,2.3,0.2,3.5c0.3,3.6,0.5,7.3,0.8,10.8
      l0.3,3.7c0.2,2.6,0.4,5.1,0.6,7.7c0.1,1.2,0.2,2.4,0.3,3.6v0.1c0.2,2.3,0.4,4.7,0.6,7c0.2,3.4,0.3,6.9,0.4,10.2v0.1
      c0,1.1,0.1,2.2,0.1,3.2c0,0.3,0,0.6,0.1,1c0.1,0.8,0.2,1.6-0.1,2c-0.3,0.4-0.9,0.6-1.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.5
      c-1.7,0.9-3.6,1.1-5.5,1.3c-1.8,0.2-3.7,0.4-5.3,0.6c-1.1,0.1-2.2,0.3-3.3,0.4c-0.9,0.1-1.8,0.2-2.7,0.3c-1,0.1-2.1,0.2-3.2,0.3
      c-1.7,0.2-3.5,0.4-5.3,0.7l-2.1,0.3L192.3,815.1z M235.7,888.6c-1.7,0.8-3.4,1.6-5.1,2.4c-1.3,0.6-2.6,1.3-3.9,1.9
      c-0.9,0.5-1.9,0.9-2.8,1.4c-2.9,1.4-6.1,2.9-9.2,4.4c-1.6,0.7-3.4,1.4-5,2h-0.1c-0.9,0.3-1.8,0.7-2.6,1s-1.6,0.6-2.3,1l-0.2,0.1
      c-1.3,0.5-2.6,1.1-3.9,1.6c-0.9,0.3-1.9,0.6-2.8,0.9h-0.1c-0.5,0.1-0.9,0.3-1.4,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.5
      c-0.2-4.3-0.5-7.9-0.7-11.4c-0.1-1.1-0.2-2.2-0.2-3.3v-0.1c-0.1-1.7-0.3-3.4-0.3-5.1c-0.1-1.3-0.1-2.8-0.1-4c0-0.8,0-1.7,0-2.5
      c0-0.7,0-1.4-0.1-2.2v-0.1c0-0.6-0.1-1.3-0.1-1.9c0-1,0-2.1-0.1-3.1c0-1.4-0.1-2.9-0.1-4.3c0-1-0.1-2.1-0.2-3v-0.1
      c0-0.3,0-0.6-0.1-0.9l3.5-0.3c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.1-0.2,3.2-0.3c1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9
      c0.7-0.1,1.4-0.4,2.1-0.7c0.3-0.1,0.5-0.2,0.8-0.3c0.4-0.1,0.7-0.3,1-0.4c0.2,0.5,0.3,1,0.5,1.5l0.1,0.4c0.5,1.7,1.1,3.5,1.7,5.3
      c0.5,1.5,1.2,3.1,2.1,5c0.5,1.2,1.1,2.3,1.7,3.4c0.5,1,1,2.1,1.5,3.2c0.8,1.7,1.6,3.5,2.4,5.3l0.1,0.2c0.4,0.9,0.8,1.8,1.2,2.6
      l0.1,0.3c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.2,0.1,0.3,0.1,0.4S235.8,888.6,235.7,888.6z M257.9,866.6l0.2,0.3
      c-5.4,6.2-11.4,12.2-19.4,19.6c-0.2-0.5-0.5-0.9-0.7-1.4l-0.1-0.1c-0.6-1.2-1.2-2.3-1.7-3.5c-1.5-3.2-2.6-5.8-3.6-8.1
      c-1.9-4.6-3.8-9-5.5-13.3c-0.4-0.8-0.5-1.5-0.4-2.1c0.1-0.5,0.5-1,0.9-1.3c1-0.7,2-1.3,3-2c1.6-1,3.3-2.1,4.8-3.3
      c1.8-1.4,3.5-3.1,5.2-4.6c0.7-0.7,1.5-1.4,2.2-2.1c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1C247.4,852.3,252.7,859.5,257.9,866.6z
       M259.5,723.4c-0.1,1.2-0.2,2.4-0.3,3.5l-0.1,0.8c-0.2,2.5-0.5,5.2-0.7,7.8c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6
      l-0.2,0.4c-0.3,0.7-0.6,1.3-0.8,2l-0.2,0.4c-0.9,2.2-1.8,4.4-2.9,6.5c-0.9,1.8-1.9,3.7-2.7,5.3l-0.1,0.2c-0.5,1-1.1,2-1.6,3
      c-0.8,1.5-1.5,3-2.2,4.5c-0.7,1.6-1.5,3.2-2.2,4.9l-0.2,0.5c-0.6,1.6-1.3,3.3-2.1,5.1c-0.7,1.5-1.4,3.1-2.3,4.7
      c0.2-1.3,0.4-2.5,0.6-3.7c0.2-1.4,0.5-2.7,0.7-4.1c0.5-2.7,1-5.5,1.4-8.3c0.4-2.5,0.6-5,0.8-7.5v-0.1c0.1-1.5,0.3-3,0.4-4.5
      c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4c-0.1-0.6-0.2-1.3-0.3-1.9c0-0.1,0-0.3,0-0.4l2-1.2c1.6-1,3.4-2.1,5-3.1
      c0.8-0.5,1.6-1,2.4-1.4s1.7-1,2.5-1.5c0.9-0.6,1.9-1.2,2.9-1.8l0.2-0.2c0.2-0.1,0.4-0.3,0.6-0.4L259.5,723.4z M257.5,721.3
      c-2.4,1.3-4.9,2.8-7.2,4.2l-1,0.6c-0.8,0.5-1.7,1-2.5,1.6c-0.6,0.4-1.2,0.7-1.7,1.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0
      s-0.1,0-0.2-0.4c-0.2-1.7-0.5-3.4-0.8-5.3c-0.1-0.7-0.2-1.4-0.3-2c-0.2-1.1-0.3-2.1-0.5-3.2v-0.2c-0.3-2.3-0.7-4.7-1.1-7.1
      c-0.3-1.7-0.6-3.5-0.9-5.2v-0.2c-0.2-0.9-0.3-1.8-0.5-2.7c0-0.1,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3c1.1-0.2,2.1-0.5,3.1-0.8
      c4.4-1.3,8.7-3.1,12.8-5.1c0.7-0.3,1.3-0.7,2-1c1.3-0.6,2.4-1.2,3.6-1.8c0.4-0.2,0.8-0.3,1.2-0.5l0.2-0.1l-0.2,2.2
      c-0.2,2-0.4,3.9-0.5,5.8c-0.1,1-0.2,2-0.3,3v0.2c-0.2,2.7-0.5,5.5-0.8,8.2c-0.1,0.7-0.3,1.3-0.5,2c-0.3,0.8-0.5,1.6-0.6,2.4
      C260.6,719.2,259.4,720.2,257.5,721.3z M276.4,719.4c0,1.2,0,2.4,0,3.6c0,1.2,0,2.4,0,3.7c0,2.2-0.1,4.5-0.1,6.7
      c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9H267c-1.7,0.2-3.4,0.4-5.1,0.7c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.6
      c0.4-1.3,0.9-2.6,1.3-3.7v-0.1c0.3-0.9,0.7-1.8,1-2.7c0.9-2.7,1.8-5.4,2.7-8.2l1.1-3.4l9.3,0.7V719.4z M276.8,715.7
      c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1c-0.1,0-0.2,0-0.3-0.1l2.9-17c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0c3.7,0,7.9,0.1,12.1,1.1
      l-0.1,0.8c-0.1,1.1-0.2,2.3-0.3,3.5c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3c-2,2.3-4.1,4.6-5.8,6.5
      C277.3,715.5,277,715.7,276.8,715.7z M292.1,706.3c0,0.3,0,0.7,0,1c-0.3,1.5-0.5,3-0.8,4.5l-0.1,0.3c-0.3,1.6-0.6,3.2-0.9,4.8
      c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.8-0.4,1.8-0.9,2.5c-1.4,2.1-3.5,3.8-5.4,5.3c-0.8,0.6-1.6,1.2-2.3,1.9
      c-0.6,0.5-1.2,1-1.8,1.5l-0.4,0.3c0-0.5,0-1.1,0-1.6V730c-0.1-1.3-0.1-3-0.1-4.6c0-1.2,0-2.4-0.1-3.6v-0.2c0-1.3-0.1-2.5-0.1-3.6
      c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8l0.1-0.2c1.3-1.5,2.6-3,3.9-4.5c0.6-0.7,1.1-1.4,1.6-2.2c0.2-0.3,0.4-0.6,0.6-0.9
      c0.4-0.6,0.7-0.6,1.3-0.4c0.5,0.3,1.1,0.5,1.7,0.8c0.2,0.1,0.4,0.1,0.6,0.2C292.1,706.2,292.1,706.2,292.1,706.3z M292.4,703.6
      l-2.5-0.7l1.1-13.5l2.9,1.4L292.4,703.6z M303.9,661.5c-0.1,1.2-0.3,2.1-0.6,2.9c-0.6,1.7-1.2,3.4-1.8,5.1l-0.1,0.2
      c-0.5,1.3-1,2.7-1.5,4.1c-0.7,2-1.4,4.1-2.1,6.1c-0.4,1.3-0.9,2.6-1.4,3.9c-0.4,1.2-0.8,2.4-1.2,3.5l-0.5,1.4l-3-1.2
      c-0.4-0.3-0.5-0.5-0.4-0.9v-0.4c0.5-5.1,1.1-10.3,1.5-15.4c0.1-1.6,0.1-3.3,0.1-4.9c0-0.9,0-1.9,0-2.8c0-0.7,0.1-1.5,0.1-2.2v-0.1
      c0-0.5,0.1-0.9,0.1-1.4c0-0.4,0.2-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.3,0.3,1.9,0.5
      c0.8,0.2,1.5,0.4,2.3,0.5C303.9,660.7,304,661.1,303.9,661.5z"
      />
      <path
        d="M960,200.6c-2.3-3.3-4.7-6.6-6.7-9.4c-0.9-1.3-1.8-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.7,0.3-1.4,0.5-2.1l0.2-1.1
      c0.3-1.2,0.5-2.5,0.8-3.7c0.1-0.7,0.3-1.4,0.4-2.2v-0.2c0.2-0.9,0.3-1.7,0.5-2.5c0.3-1.4,0.6-2.7,0.9-4.1V169c0.5-2.3,1-4.7,1.5-7
      c0.3-1.4,0.5-2.9,0.8-4.3c0.2-1.5,0.5-3,0.8-4.4c0.1-0.6,0.2-1.1,0.4-1.7c0.5-2.1,0.9-4.2,0.7-6.4c-0.3-3.1-0.7-7.1-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-0.9-0.4-1.9-0.6-2.8v-0.1c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.3-2.3-1.9-3.4
      l-0.1-0.1c-0.7-1.2-1.4-2.6-2.2-4c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-1-1.4-1.4-2c-0.4-0.5-0.7-1-1.1-1.5c-0.4-0.7-0.9-1.4-1.3-2
      l-0.1-0.1c-0.8-1.3-1.6-2.6-2.6-3.8s-2-2.4-3-3.6c-0.9-1-1.9-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.7-3.7c-0.7-1-1.5-2.1-2.2-3.1
      c-0.6-0.8-1.3-1.6-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.7-6-8.2-8.1c-2.5-1.5-5.2-3-7.9-4.3c-2.5-1.2-5-2.3-7.5-3.5
      c-2.1-1-4.3-1.9-6.5-3c-2.2-1-4.3-2.1-6.4-3.2c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1c-2-0.4-4-0.5-6-0.6h-0.3
      c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1
      c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1c-3.3,0.2-6.7,0.5-9.8,2.1
      c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4c-2.1,0.9-4.1,2-6,3
      c-1.3,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.9,1.8-5.6,2.6c-1.9,0.9-4,1.8-5.9,2.8c-1,0.5-1.9,1-2.9,1.6c-1,0.6-2,1.1-3,1.6
      c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0.6-0.8,1.1-1.3,1.6c-0.5,0.7-1.1,1.3-1.6,2l-0.1,0.1c-0.9,1-1.8,2.1-2.6,3.2
      c-1.1,1.4-2.1,2.8-3.1,4.1l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.2-2.6,3.2c-0.9,1.1-1.8,2.2-2.7,3.4c-0.7,0.9-1.3,1.8-1.8,2.7
      c-0.3,0.5-0.6,0.9-0.9,1.4c-0.6,0.9-1.1,1.7-1.7,2.6c-1.2,1.9-2.5,3.8-3.7,5.8c-2,3.3-3.7,6.9-5.2,9.9c-1,2-1.7,3.7-2.1,5.3
      c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10-2.3,14.3c-0.3,2.2-0.5,4.4-0.8,6.6c-0.2,1.7-0.3,3.2-0.5,4.7c-0.3,2.7-0.2,5.4,0.4,8.3
      c0.2,1.1,0.5,2.2,0.7,3.4l0.1,0.4c0.4,2.1,0.9,4.2,1.3,6.3c0.2,1.2,0.4,2.4,0.6,3.6c0.2,1.3,0.4,2.6,0.7,3.9c0.3,1.5,0.7,3.1,1,4.5
      v0.2c0.3,1.2,0.6,2.4,0.8,3.7c0.3,1.5,0.6,3.1,0.9,4.5l0.1,0.3c0.1,0.7,0.3,1.4,0.4,2.2c0.1,0.4,0.2,0.7,0.2,1v0.1c0,0,0,0-0.1,0
      c-0.8,0.2-1.5,0.3-2.3,0.5l-0.7,0.1c-2.2,0.5-4.4,1-6.7,1.5c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1
      c-2.5,3.3-5.3,7.2-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3c0.8,2.9,1.7,6,3,9.7c0.5,1.3,1,2.6,1.5,3.9c0.5,1.1,0.9,2.3,1.4,3.5
      c0.7,1.9,1.3,3.8,1.9,5.7c0.4,1.2,0.8,2.4,1.2,3.6c0.2,0.5,0.3,0.9,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2c0.2,0.9,0.2,1.9,0.3,2.8
      c0,0.7,0.1,1.4,0.1,2.1l0.1,1c0.5,3.9,0.9,8,1.5,12c0.4,2.7,0.9,5.5,1.4,8.2l0.4,2.2c0,0.2,0.1,0.4,0.1,0.7
      c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6c0.8,0.8,1.8,1.5,2.7,2.2c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2
      c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7c0.2,0,0.4,0.2,0.5,0.3c0.3,0.7,0.5,1.4,0.8,2c0.3,0.7,0.5,1.4,0.8,2.1
      c0.2,0.6,0.5,1.1,0.7,1.7l0.1,0.3c1.1,2.5,2.2,5,3.4,7.5c1.3,2.6,2.7,5.3,3.9,7.7c0.9,1.7,1.9,3.5,2.8,5.3c0.8,1.7,1.7,3.5,1.7,5.6
      c0,3.3,0.1,6.7,0.1,10v0.3c0.1,2.5,0.1,5.1,0.1,7.6c0,3.1,0,6.3,0,9.4c0,1.6,0,3.1,0,4.7v1.5c0,1.8,0,3.7,0,5.6
      c0,1.5,0.1,3.1,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6c0,1.1,0,2.1,0,3.2v0.1c0,1.8,0,3.6,0.1,5.4c0.1,2.2,0.2,4.3,0.3,6.4v0.3
      c0.1,1.4,0.2,2.9,0.2,4.3c0.1,1.1,0.1,2.3,0,3.8c0,0.1,0,0.2,0,0.3v0.1c0,0.6,0,1.3-0.2,1.7c-0.5,1-1.1,2-1.7,3
      c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.9,1.3-1.3,2c-0.6,1-1.3,2.1-2.1,3.1
      s-1.6,2-2.4,3c-0.9,1-1.8,2.2-2.7,3.4l-0.1,0.1c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2c-2.5,1.4-5.6,3.1-8.5,4.8
      c-1,0.6-2,1.2-3,1.8c-1,0.6-1.9,1.2-2.9,1.8c-0.8,0.5-1.7,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2c-1.2,0.7-2.3,1.5-3.3,2.3h-0.1
      c-0.7,0.5-1.4,1-2.2,1.5c-1,0.7-2,1.3-3,1.9l-0.1,0.1c-0.4,0.3-0.9,0.5-1.3,0.8l-1,0.6l1.2,0.1c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0
      c0.1,0,0.2,0,0.2,0c1.2-0.6,2.4-1.2,3.6-1.7c2.9-1.4,6.1-3,9.3-4.6c2-1,4-2.2,6-3.4l1.2-0.7c0.7-0.4,1.4-0.9,2.1-1.3l0.1-0.1
      c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.2-1.2,3.3-1.7c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1
      l0.1-0.1c0.3-0.3,0.6-0.6,0.8-0.9l0.2-0.2c0.7-0.7,1.5-1.5,2.2-2.4c0.7-0.7,1.3-1.4,2-2.3l0.8-0.9l0.4,0.5c0.4,0.5,0.8,1,1.2,1.4
      c0.4,0.4,0.7,0.8,1.1,1.3c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8c0.7,0.6,1.3,1.2,2,1.9c0.7,0.7,1.5,1.4,2.2,2.2
      c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5c-0.1,0.3-0.3,0.6-0.4,1c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.1
      c-0.3,0.7-0.7,1.4-1,2.1v0.1c-0.8,1.7-1.6,3.4-2.3,5.1c-0.9,2.2-1.7,4.6-2.5,6.8l-0.2,0.5c-0.2,0.5-0.4,1.1-0.6,1.6l-0.4,1
      c-0.5,1.3-1,2.7-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.3-0.1,0.5l-0.1,0.4l0.4,0.1
      c0.1,0,0.2,0.1,0.2,0.1c0.1,0,0.2,0.1,0.4,0.1c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2
      c0.9-2.9,1.7-5.2,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7c0.5-1.2,1.1-2.3,1.7-3.5l0.1-0.1c0.2-0.3,0.3-0.6,0.5-1c0.4,0.2,0.8,0.5,1.2,0.7
      h0.1c1.2,0.7,2.3,1.4,3.5,2c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.3,1.3,4.8,1.8c0.9,0.3,1.7,0.6,2.6,1
      c1.1,0.4,2.2,0.8,3.2,1.2c2.2,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,1-0.1,1.9-0.2,2.9c-0.2,2.7-0.4,5.5-0.6,8.3
      c-0.1,1.4-0.1,2.8,0,4.1c0,0.6,0,1.2,0,1.8v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.3-2.6,0.3-3.8c0-0.7,0.1-1.4,0.1-2.2
      c0.1-1.1,0.2-2.1,0.3-3.2v-0.1c0.1-1.2,0.2-2.4,0.3-3.5c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0c0.6,0.1,1.3,0.1,2,0.1h0.1
      c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1
      c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.4c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1
      c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.1c0.3,3.5,0.5,7,0.9,10.6c0.1,1,0.4,1.9,0.7,2.8
      c0.1,0.4,0.3,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4c-0.1-1.8-0.3-3.6-0.4-5.3v-0.1c-0.1-1.1-0.2-2.2-0.3-3.4
      c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5
      c0.5-0.2,1.1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.7-1.6,5.5-2.3l0.3-0.1c3.2-1.4,6.4-2.8,9.6-4.3c2-0.9,4.4-2,6.8-3.2
      c1.2-0.6,2.4-1.3,3.6-2c0.4-0.3,0.9-0.5,1.3-0.8c0.3,0.6,0.6,1.2,0.8,1.7l0.1,0.3c0.9,1.8,1.8,3.6,2.5,5.5c1,2.4,1.9,4.9,2.7,7.3
      l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.9,8.1c0.4,0.6,1,1,1.6,1c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4
      c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4c-0.5-1.6-1.2-3.1-1.8-4.4l-0.2-0.5
      c-1-2.1-2-4.4-3.1-6.5c-1.1-2-1-2.1,0.6-3.6c1.5-1.4,3-2.8,4.5-4.3l0.6-0.5c0.6-0.6,1.1-1.2,1.7-1.7c0.9-1,1.9-2,2.9-2.9
      c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.2,1.3c1.7,1.8,3.3,3.6,4.9,5.3c0.1,0.1,0.1,0.2,0.2,0.3
      c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.4,0.5,0.8,0.8,1c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.3,1.3,3.4,1.9l0.1,0.1
      c1.5,0.8,3.1,1.7,4.6,2.5c1.2,0.7,2.5,1.5,3.6,2.2c1.2,0.7,2.6,1.6,3.9,2.3c2.2,1.2,4.5,2.4,6.6,3.4c1.8,0.9,3.6,1.7,5.3,2.5
      l0.2,0.1c0.5,0.2,1.1,0.5,1.6,0.7h0.1c0.3,0,0.5-0.2,0.5-0.4v-0.4h-0.3c-0.7-0.5-1.5-1-2.2-1.5l-0.3-0.2c-2-1.4-4-2.8-6.1-4.1
      c-3-1.9-6.2-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.1-1.7-4.6-2.6c-0.8-0.4-1.5-0.8-2.3-1.2l-1.5-0.9c-2.3-1.2-4.5-2.5-6.8-3.8
      c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6c-1.5-2-2.9-4.1-4.3-6.1l-0.1-0.2c-0.7-1-1.3-1.9-2-2.9
      c-0.6-0.8-1.2-1.6-1.7-2.4c-0.3-0.5-0.7-0.9-1.1-1.4c-0.7-0.9-1.4-1.9-2-2.8c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6
      c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5v-0.1c0-0.6,0-1.1-0.1-1.7v-0.2c-0.1-1.3-0.1-2.6-0.1-3.9
      c0.1-2.1,0.2-4.3,0.3-6.2c0.1-1.8,0.2-3.8,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4c0-2.5,0-5-0.1-7.4c0-1,0-2-0.1-3c0-0.8,0-1.7,0-2.5v-0.3
      c0-0.9,0-1.8,0-2.7v-1.6c0-1.5,0-3.2,0-4.8c0-2.2,0.1-4.4,0.2-6.5v-0.3c0.1-1.9,0.1-3.9,0.2-5.8V318c0.1-3.2,0.1-6.1,1.7-9
      c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5,3.3-7.5l0.2-0.4c0.3-0.6,0.5-1.2,0.8-1.8c0.3-0.7,0.6-1.5,0.8-2.1c0.3-0.7,0.5-1.4,0.9-2.1
      c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.7,0,1.1-0.1c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8
      c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.2,7-6,9.9-8.7c1.3-1.2,2.9-2.6,2.9-5c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6
      c0.2-0.9,0.3-1.8,0.5-2.7l0.1-0.4c0.3-1.8,0.7-3.6,1-5.4c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2c0.4-3.4,0.8-6.7,1.1-9.4
      c0-0.4,0.1-0.8,0.1-1.2c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9c0.3-0.7,0.6-1.4,0.8-2.2c1.5-4,2.6-7.2,3.7-10.3l0.5-1.3
      c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.1,0.8-2.4,0.2-3.8C961.2,202.4,960.6,201.5,960,200.6z M936.6,187.3l2.4,0.6
      c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2c2.4,0.6,4.6,1.2,6.1,3.5c1.5,2.2,3.1,4.3,4.7,6.4c0.6,0.7,1.1,1.5,1.7,2.2
      c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.6-2.8-0.7c-2.8-0.3-5.3-0.5-7.5-0.6c-1.3,0-2.6,0.1-4,0.2h-0.1c-0.6,0.1-1.3,0.1-1.9,0.1
      c-0.1,0-0.2-0.1-0.2-0.1c-1.8-2.2-3.7-4.4-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5c0.4-0.9,0.6-1.9,0.7-2.8l0.1-0.5
      c0.1-0.7,0.4-0.7,0.6-0.7C936.4,187.3,936.5,187.3,936.6,187.3z M943.3,239.5l-0.1,0.8c-3-0.7-6.2-1-10.1-1c-2.4,0-4.7,0.1-7.1,0.3
      c-0.4-7.8,2.7-14.2,5.9-19.4c0.5,0,0.9,0,1.4,0.1h0.7c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6c0.1,0,0.2,0.2,0.2,0.3
      c-0.3,3.7-0.7,7.4-1,11l-0.4,4.9C943.4,238.1,943.4,238.7,943.3,239.5z M899.7,357.6c-0.1,4.4-0.3,8.9-0.5,13.2l-0.1,3.3
      c0,0.6,0,1.2-0.1,1.8v0.1c-0.1,2.6-0.2,5.2-0.5,7.8c-0.1,0.8-0.8,2-1.4,2.3c-1.5,0.6-2.5,1.6-3.5,2.6c-0.5,0.5-1.1,1.1-1.7,1.5
      c-0.7,0.5-1.4,1-2.2,1.7c-0.9,0.7-1.8,1.4-2.8,2.1c-1.2,0.9-2.4,1.7-3.7,2.6c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1,0.1-0.3,0.2-0.5,0.3
      l-0.1-1.5c-0.1-1.3-0.2-2.6-0.2-3.9c-0.1-1.3-0.1-2.5-0.2-3.8c-0.1-2-0.2-4.4-0.3-6.6c-0.2-2.2-0.4-4.5-0.6-6.7
      c-0.1-1.6-0.3-3.3-0.4-5c-0.2-2.1-0.3-4.3-0.5-6.4v-0.7c-0.2-2.7-0.4-5.4-0.6-8.1c-0.3-3.4-0.6-6.8-1-10.1v-0.1
      c-0.1-0.6,0.1-0.8,0.5-1.1c4.2-2.6,8.3-5.1,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3c0.8-1.1,1.5-2.1,2.2-3.1c0.2-0.3,0.3-0.6,0.4-0.9
      c0-0.2,0.1-0.3,0.2-0.5c0.8-1.8,1.6-3.6,2.4-5.4c0.8-1.8,1.6-3.6,2.3-5.3c0.4-0.8,0.7-1.6,1.1-2.5v0.4c0,1.5,0,2.9,0,4.2
      c0,2.4-0.1,4.8-0.1,7.2c0,2.2-0.1,4.5-0.1,6.7c-0.1,3.2-0.1,6.9-0.1,10.5c-0.1,1.6-0.1,3.1-0.1,4.5v0.2
      C899.7,353,899.8,355.3,899.7,357.6z M809.4,365.5c0.1-1.6,0.1-3.2,0-4.8c0-0.6,0-1.2,0-1.8c5.9,0.6,11.9,0.9,17.7,0.9
      c5.9,0,11.9-0.3,17.7-0.9l0.2,6.7c0.1,4.9,0.3,10.5,0.4,15.9c0.1,5.1,0.2,10.3,0.4,16l0.2,6.8c-0.1,0-0.1,0-0.2,0l-2,0.1
      c-3.1,0.2-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0c-0.7,0-1.5,0.2-2.2,0.3c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2
      c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0-3.4-0.1
      c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.9-0.1h-0.1c0.2-1.6,0.3-3.1,0.3-4.6c0.1-3.6,0.3-7.2,0.3-10.9
      c0-1.9,0-3.8,0-5.6c0-2.1,0-4.4,0-6.5c0.1-2.1,0.2-4.2,0.4-6.2v-0.1C809.2,368.8,809.3,367.1,809.4,365.5z M841.8,356.6
      c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.7,0-1,0.1h-0.2c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3
      c-4.9-0.4-9.8-0.9-14.6-1.4H806c-1.5-0.1-3-0.4-4.4-0.6h-0.3c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7
      c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5v-0.2c0.3-1,0.6-2.1,0.9-3.1c0.3-1.2,0.6-2.5,0.9-3.7l0.3-1.2c0.7,0,1.3,0,2,0
      h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0c2.7,0.1,5.9,0.2,9,0.5c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2
      h0.2c0.4,0,0.8,0,1.2,0c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0c0.1,0.5,0.2,1,0.4,1.5c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.7,1,3.5,1.5,5.1
      l0.1,0.2c0.7,2.3,1.4,4.7,2.1,7.1c0.2,0.8,0.1,1.8-0.1,2.7v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1
      C845.3,356.2,843.5,356.4,841.8,356.6z M755.6,374v-0.6c-0.1-1.5-0.2-3.1-0.2-4.6c0-1.1-0.1-2.2-0.1-3.3c-0.1-3.4-0.2-7-0.3-10.5
      c0-2.6,0-5.3,0.1-7.9V347c0-2.3,0.1-4.7,0.1-7c0-4.7-0.1-9.4-0.2-13.9v-0.9c0-1.8-0.1-3.7-0.2-5.3v-0.4c0-1-0.1-2-0.1-3
      c0,0,0-0.1,0-0.2c0.5,1.2,1,2.3,1.5,3.5v0.1c1.5,3.4,3,6.8,4.3,10.3c0.5,1.3,1.3,2.3,2,3.3c0.1,0.8,0.5,1.4,0.9,2
      c0.3,0.5,0.9,0.8,1.5,1.1c0.2,0.1,0.5,0.2,0.7,0.4c0.9,0.6,1.8,1.1,2.6,1.7c2,1.3,4,2.6,6.1,3.7c2.1,1.1,2.8,2.3,2.4,4.6
      c-0.6,3.7-0.8,7.4-1.1,11c-0.1,1-0.2,2.1-0.2,3.1c-0.2,1.8-0.3,3.7-0.4,5.5v0.1c-0.1,1.2-0.1,2.4-0.2,3.5c-0.2,3.3-0.5,6.6-0.7,9.8
      v0.1c-0.2,2.5-0.4,5-0.5,7.5c-0.2,3.3-0.4,6-0.6,8.5c0,0.4,0,0.9,0,1.3v0.1l-0.3-0.2c-0.6-0.4-1.1-0.7-1.6-1.1
      c-0.6-0.4-1.2-0.9-1.9-1.3c-1.5-1-3-2-4.4-3.1c-1.3-1.1-2.6-2.2-3.8-3.4c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.2-0.6-0.4-1-0.6
      c-1.2-0.4-2-1.5-2-2.9c-0.1-2-0.2-4-0.3-6V377C755.7,376,755.7,375,755.6,374z M758,297c-0.3-2.4-0.6-4.8-0.9-7.2l-0.1-0.7
      c-0.3-2.4-0.5-5-0.5-7.4c0-1.5,0.3-3.1,0.5-4.7c0.1-0.6,0.2-1.1,0.3-1.7c0.1-0.6,0.2-0.7,0.7-0.8c1.6-0.1,3.3-0.3,4.9-0.5
      c1.2-0.1,2.6-0.3,4-0.4c1.9-0.2,4-0.3,6.2-0.4c1,0,2.1,0,3.1,0c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.8,0.1,1.1v0.2
      c0.1,1.1,0.2,2.1,0.3,3.1c0,0.4,0,0.9,0,1.4v0.1c0,0.3,0,0.7,0,1v1.2c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2.1,0.4,3
      c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4c0.2,2.4,0.5,4.9,0.8,7.4c0.5,3.4,1.1,6.8,1.7,10.2l0.1,0.3c0.1,0.5,0,0.9-0.4,1.4
      c-1.6,1.9-3.6,3.4-5.5,4.8l-0.1,0.1c-0.5,0.4-1.1,0.8-1.6,1.2c-1.6,1.2-3.1,2.5-4.6,3.8l-0.2,0.1c-0.5,0.4-1,0.8-1.4,1.2
      c-1,0.9-2,1.7-3.1,2.6l-1.2,1.1c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-0.9-0.4-1.7-0.6-2.6l-0.5-2.6c-0.7-3.7-1.3-7.6-1.9-11.3
      c-0.4-2.7-0.8-5.5-1.1-8.2l-0.1-0.4c-0.1-1-0.2-2-0.4-3C758.3,298.9,758.1,298,758,297L758,297z M730.5,234.6
      c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8c-0.5-3.7-1.1-7.5-1.7-11.1v-0.3c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.1-0.9-0.3-1.3
      c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4c0.5-0.1,1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6c0.1,0,0.1,0,0.2,0
      c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.7c0.1,2.5,0.3,4.9,0.5,7.4v0.1c0.1,1,0.2,1.9,0.2,2.9
      c0.1,1.4,0.2,2.7,0.3,4.1v0.1c0.3,3.5,0.6,7.2,0.9,10.8c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5
      c0.1,1.1,0.2,2.1,0.3,3.1c-0.2-0.7-0.5-1.4-0.7-2c-0.3-0.9-0.7-1.8-0.9-2.8c-0.9-4-1.7-7.9-2.3-11.5c-0.5-2.9-1.2-6.7-1.8-10.5
      c-0.2-1.4-0.4-2.9-0.5-4.3v-0.1C730.7,236.7,730.6,235.6,730.5,234.6z M742.9,116.3l0.1,0.2c0.4,3.1,0.9,6.4,1.5,9.5
      c0.7,3.2,1.5,6.3,2.2,9.5c0.3,1.3,0.6,2.6,1,3.9c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5,5.3v0.1c-1.4,1.5-2.7,2.9-4,4.3
      c-1.5,1.6-3,3.1-4.4,4.5c-1.5,1.5-3.1,3.1-4.6,4.7c-1.2,1.2-2.3,2.5-3.4,3.7l-0.1,0.2c-0.5,0.5-1,1.1-1.5,1.6
      c0,0.1-0.1,0.1-0.2,0.2l-0.9-6c-0.5-3.4-1-6.6-1.4-9.9c-0.2-1.3-0.4-2.5-0.5-3.8c-0.4-2.6-0.7-5.4-1.2-8.1
      c-0.3-1.5,0.4-2.5,1.2-3.6c0.1-0.2,0.3-0.4,0.4-0.6c1.9-2.8,3.7-5.6,5.3-7.9c1.6-2.4,3.5-5.2,5.3-7.9l0.7-1
      c1.7-2.4,3.4-4.9,5.2-7.2c0.8-1,1.7-1.9,2.6-2.9c0.3-0.3,0.6-0.6,0.9-0.9c0.1,0.9,0.2,1.9,0.3,2.8v0.1
      C742.3,111.3,742.6,113.8,742.9,116.3z M783.3,88.3c0.1,2.2,0.3,4.5,0.5,6.7c0.2,2.9,0.4,5.8,0.6,8.8c0.1,2.5,0.2,5,0.3,7.4v0.2
      c0.1,1.8,0.1,3.6,0.2,5.3c0,0.8,0.1,1.6,0.2,2.3H785c0.1,0.6,0.1,1.2,0.2,1.9c0,0.2-0.1,0.5-0.1,0.6c-2.6,1.6-5.9,3.6-9.1,5.4
      c-2.1,1.2-4.2,2.3-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3c-1.5,0.8-3.1,1.7-4.6,2.5c-0.6,0.4-1.3,0.7-2,1.1
      l-1.1,0.6c-0.1-0.5-0.3-1.1-0.4-1.6v-0.1c-0.4-1.6-0.9-3.2-1.3-4.9l-0.2-0.7c-0.7-2.8-1.3-5.7-1.8-8.7c-0.5-3.3-1-6.6-1.4-9.8V115
      c-0.2-1.3-0.4-2.7-0.5-4c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.3-1.6c-0.4-1.6,0.1-2.8,1.7-4c1.3-0.9,2.7-2,4-2.9
      c1.3-1,2.6-2,3.9-2.9c1.3-0.9,2.7-1.8,4.1-2.7h0.1c0.7-0.5,1.5-0.9,2.2-1.4c1.1-0.7,2.2-1.4,3.3-2.2l0.1-0.1c2-1.3,4-2.7,6.1-3.9
      c1.8-1.1,3.7-2.2,5.6-3.2c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5c0.2-0.1,0.4-0.2,0.7-0.4c0,0.6,0,1.2,0.1,1.8V83
      C783.2,84.8,783.2,86.6,783.3,88.3z M825.9,114.2c0,0.7-0.1,0.8-0.9,0.8c-0.9,0.1-1.8,0.2-2.7,0.2c-3,0.3-6.2,0.5-9.2,0.9
      c-5.3,0.7-10.9,1.6-17.7,2.7c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5l-2.3-41.1l41-10.3v3c0,2.2,0,4.4,0,6.6
      c0,0.5,0,1.1,0,1.7c0,0.6,0,1.2,0,1.9c0,1.6,0,3.1-0.1,4.7c0,2-0.1,3.9-0.1,5.7C825.9,99.5,825.9,106.8,825.9,114.2z M933.9,184.7
      c-0.5,2.3-1,4.6-1.5,6.9c-0.2,1-0.6,2.1-0.9,3s-0.6,2-0.9,3c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2
      c-0.3,0-0.8-0.1-1.3-0.2c-0.2-0.1-0.5-0.1-0.8-0.2c-0.5-0.1-0.9-0.3-1.4-0.5l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2
      c1.1-4.5,2.2-8.6,3.2-12.7c0.5-1.8,0.9-3.5,1.4-5.3c0.8-3.2,1.8-6.8,2.6-10.3c0.4-1.7,0.7-3.4,1-5.1v-0.2c0.1-0.8,0.3-1.6,0.4-2.4
      c0.1-0.6,0.2-1.2,0.4-1.9l0.1-0.5l0.3,0.4c0.6,0.7,1,1.3,1.5,1.9c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.8,0.8,1.1
      c1.1,0.8,1,1.7,0.7,3.3c-0.6,2.5-1.1,5.1-1.6,7.4L933.9,184.7z M934.2,196.2c1.5,1.8,2.8,3.4,4.1,4.9c0.2,0.3,0.2,0.6-0.2,1.3
      c-2.3,4.4-4.7,9.1-7.5,14.8c-0.6,1.2-1.1,2.3-1.6,3.5v0.1c-0.3,0.7-0.6,1.4-1,2.2c0,0.1-0.1,0.1-0.1,0.2l5.5-27.7
      C933.7,195.8,934,196,934.2,196.2z M849.4,325.7c-0.2-0.8-0.4-1.7-0.5-2.6c-0.2-1-0.4-2.1-0.7-3.2c-0.3-1.2-0.7-2.4-1.1-3.6
      l-0.3-0.2c-0.1-0.4-0.3-0.8-0.4-1.2c-0.2-0.7-0.2-1,0.3-1.5c1.4-1.3,2.8-2.6,4.1-3.8l0.2-0.2c0.8-0.8,1.6-1.5,2.4-2.2
      c0.8-0.8,1.7-1.5,2.5-2.2h0.1c0.6-0.5,1.2-1.1,1.8-1.6c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4c1.9,1.9,3.9,3.7,6,5.6
      c1.5,1.3,2.9,2.5,4.4,3.7c0.6,0.5,1.1,0.9,1.7,1.4c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1v-0.1
      C850,327.9,849.6,326.8,849.4,325.7z M749,146.3l0.1,0.5c0.5,3.4,1,7,1.5,10.5c0.5,4,1.1,8,1.6,11.9l0.1,0.7c0.2,1.5,0.4,3,0.5,4.6
      v0.3c0.1,1,0.1,1.9-0.3,2.8c-0.5,1.2-0.8,2.5-1.1,3.8c-0.1,0.5-0.2,0.9-0.3,1.4l-0.4,1.4c-0.6,2.4-1.2,4.9-1.7,7.3
      c-0.2,1-0.4,1.9-0.6,2.9c-0.2,0.9-0.3,1.9-0.6,2.9c-0.1,0.5-0.4,1-0.8,1.4c-1.2,1.2-2.5,2.3-3.7,3.4l-0.1,0.1
      c-1,0.9-2.1,1.9-3.1,2.9c-1.3,1.3-2.6,2.5-4,3.9c-0.5,0.5-1,0.9-1.5,1.4l-0.9-3.6c-0.5-1.8-0.9-3.6-1.4-5.4c-0.4-1.7-0.8-3.3-1.3-5
      c-0.7-2.6-1.4-5.3-2-8c-0.9-3.5-1.5-6.2-2.1-8.7c-0.3-1.4-0.6-2.7-0.9-4.3c-0.4-1.8-0.8-3.7-1.2-5.5c-0.2-1.1,0.1-2.1,1-3
      c1.5-1.5,3-3.1,4.2-4.4l0.2-0.2c1.3-1.4,2.7-2.8,4-4.2s2.7-2.7,4-4.1c1.7-1.7,3.5-3.6,5.3-5.5c1.1-1.1,2.2-2.4,3.2-3.5l0.1-0.1
      c0.5-0.6,1-1.2,1.6-1.8l0.1-0.1c0,0.2,0.1,0.5,0.1,0.7v0.1C748.8,144.7,748.9,145.5,749,146.3z M787.2,160.7c-0.2,0-0.3,0-0.5,0
      c-0.9,0-1.9,0-2.8-0.1c-1.9,0-3.9-0.1-5.9-0.1h-0.1c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5
      c-0.7,0.9-1.4,1.7-2.2,2.5c-0.6,0.6-1.2,1.3-1.8,2c-0.9,1.1-1.7,2.2-2.6,3.4c-0.5,0.7-1,1.3-1.5,2c-0.6,0.8-1.2,1.5-1.8,2.2
      l-0.5,0.6l-0.1-1.2c-0.1-1.2-0.3-2.4-0.4-3.7c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.9-0.4-3.9-0.7-5.9c-0.5-3.7-1-7.5-1.4-10.7
      c-0.3-2.3-0.7-4.4-1-6.4v-0.1c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.5-3.5l0.2-0.1c1.2-0.6,2.3-1.3,3.5-1.9
      c1.1-0.6,2.2-1.2,3.4-1.8c1.5-0.8,3.1-1.7,4.7-2.6c1-0.5,2-1.1,3-1.6h0.1c1.4-0.7,3-1.5,4.5-2.4c1.5-0.8,3-1.8,4.4-2.6
      c0.7-0.4,1.3-0.8,2-1.2c0.4-0.3,0.9-0.5,1.4-0.8l0.4-0.2l2,37.2L787.2,160.7z M788,163.6l-1.4,17.2l-0.4,5.1c-0.2,0-0.5,0-0.7,0
      c-0.4,0-0.7,0-1.1,0c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7H770c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1
      c-2.9-1.9-5.8-3.9-8.7-5.9c-0.9-0.6-1.8-1.3-2.7-1.9c-0.3-0.2-0.6-0.5-0.9-0.7c0.4-0.6,0.9-1.1,1.3-1.7c1.1-1.5,2.2-2.9,3.4-4.3
      c1.1-1.3,2.3-2.7,3.5-3.9c0.8-0.9,1.7-1.9,2.5-2.8c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0
      c2.2-0.1,4.2-0.1,6.1-0.1c4.2-0.1,7.9-0.1,11.3-0.1c0.3,0,0.6,0.1,0.9,0.1H788L788,163.6z M753.3,182.8c0.2-1.1,0.5-2.1,0.8-3.2
      v-0.1c0.1-0.4,0.2-0.7,0.3-1.1l13.7,9.4l-3.7,3.7c-0.9,0.8-1.7,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.9l1.2,1
      c1.7,1.5,3.5,3.1,5.3,4.6c0.6,0.5,1.2,0.9,1.8,1.3c0.2,0.1,0.3,0.2,0.5,0.3l-4.3,7.9c-0.2-0.1-0.5-0.3-0.7-0.4
      c-3.5-2.6-7.2-5.3-10.9-8.1c-1.8-1.4-3.4-2.6-4.8-3.7c0,0-0.1-0.3-0.1-0.5C751.1,192.8,752.2,187.8,753.3,182.8z M772.3,204.5
      c0.1-0.1,0.4-0.3,0.5-0.3c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2c0.6,0,1.2,0,1.8,0c0.8,0,1.5,0,2.3,0c1,0,2,0,3,0
      c0.6,0,1.1,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0.1v9.7l-18.6-2.5l0.7-1.3C769.9,208.7,771.1,206.5,772.3,204.5z M766,226.4
      c0.1-0.9,0.2-1.8,0.4-2.7c0.2-1.6,0.6-3.1,0.9-4.4c0.1-0.6,0.2-1.1,0.4-1.7l0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1c0-0.1,0.3-0.3,0.4-0.3
      h0.1c3.1,0.3,6.5,0.7,9.9,1.1c1.5,0.2,3,0.5,4.5,0.8c1,0.2,2,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1c0.1,3.3,0.2,6.3,0.4,8.9
      c0.1,1.3,0.2,2.7,0.4,3.9c0.1,0.9,0.2,1.7,0.3,2.6c0.1,0.7,0.1,1.3,0.1,2.1v0.3c0,0.2,0,0.4,0,0.6l-24.9,6.7c0-0.2,0-0.4,0.1-0.6
      c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.6,0.6-3.2,0.9-4.8v-0.1c0.4-2.1,0.8-4.3,1.2-6.4C765.8,228.3,765.9,227.3,766,226.4z M786.7,202.3
      c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3c-1.5,0-3.1-0.1-4.6-0.3h-0.1c-0.4,0-0.7-0.1-1.1-0.1c0,0,0-0.1-0.1-0.1
      c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.3-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.5-0.1c-1.9-1.6-3.8-3.2-5.7-4.8l-1.4-1.2
      l0.4-0.3c0.4-0.3,0.7-0.6,1-0.9c0.4-0.3,0.7-0.6,1.1-0.9l0.2-0.1c1.1-0.9,2.2-1.9,3.2-2.9c1.5-1.5,3.4-1.5,5.2-1.5h0.3
      c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.4,0.5,3.6,1.5c1,0.8,2,1.6,3,2.4c0.5,0.4,1.1,0.9,1.6,1.3l0.7,0.1
      c0.8,0.6,1.5,1.2,2.3,1.9c0.2,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.5,0.9c-2.5,2.5-5.2,5-9.2,5c-0.2,0-0.4,0-0.6,0
      C788.1,202.3,787.4,202.3,786.7,202.3z M809.2,192.2l-5.4,5.7c-0.5-0.5-0.9-0.9-1.4-1.4c-1.2-1.3-2.5-2.6-3.9-3.8
      c-2-1.7-4.2-3.5-7.2-5.7c-0.6-0.4-1.3-0.7-2.1-0.9c-0.3-0.1-0.5-0.2-0.8-0.3l1.6-22.4c0.1,0,0.3,0.1,0.4,0.1h0.1
      c0.6,0.2,1.2,0.4,1.7,0.7c0.9,0.5,1.9,0.9,2.8,1.3c2.8,1.3,5.7,2.7,8,5c3.6,3.7,6.3,7.3,8.4,10.9c0.6,1,1.3,2,1.9,2.9
      c0.3,0.5,0.7,1,1,1.5c0.1,0.1,0.2,0.3,0.2,0.4L809.2,192.2z M815.5,188.9c0.1-0.1,0.2-0.2,0.3-0.3l0.1,1.1c0.1,1.2,0.2,2.4,0.2,3.6
      c0.2,2.3,0.5,5.4,0.8,8.4c0,0.6-0.1,0.8-0.6,1.1c-1.7,1-3.4,2.1-5,3.1l-0.2,0.1c-1.3,0.8-2.7,1.7-4,2.5c-0.5,0.3-1.2,0.6-1.8,0.9
      h-0.1c-0.2,0.1-0.3,0.1-0.5,0.2c-0.1-0.6-0.2-1.1-0.4-1.6c-0.4-1.6-0.7-3-0.8-4.5c-0.1-1,0.4-2.3,1.2-3.2c2.3-2.7,4.8-5.3,7.2-7.8
      l0.5-0.6c0.5-0.5,1-1,1.5-1.5S814.9,189.4,815.5,188.9z M801.5,201.8c-0.1,0.2-0.5,0.5-0.7,0.5c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1
      l3.5-3.3C802.2,199.5,802.3,200.1,801.5,201.8z M788.5,213.1c0.3-2.7,0.2-5.4,0.1-7.7c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1
      c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1h0.1c1.7,0.4,3.3,0.7,5.1,1.1l1.5,0.3l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.2
      c0,0.1-0.2,0.4-0.3,0.5c-0.7,0.3-1.4,0.6-2.1,0.9c-2.9,1.2-5.9,2.4-8.9,3.5c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1
      C788.4,215.1,788.5,213.9,788.5,213.1z M798.6,214.7c1.3-0.6,2.8-1.2,4.3-1.8l3.6,11.7l-16.2,10.5l-1.5-17.2
      C792.3,217.4,795.5,216,798.6,214.7z M816.7,298.8h0.2c1.8-0.1,3.7-0.2,5.6-0.2c1.5,0,3,0,4.5,0.1c1.1,0,2.3,0.1,3.5,0.1h0.2
      c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3c2.1,0.1,4.2,0.1,6.2,0.6c0.7,0.2,1.4,0.2,2.2,0.2c1.5,0,3-0.3,4.4-0.6
      c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1c-0.5,0.5-1,1-1.5,1.4
      c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4c-2.6,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8c-1.7,0-3.4,0.1-5.1,0.2h-0.2
      c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1s-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6c-0.4,0-0.8-0.3-1.4-0.8
      c-0.8-0.8-1.7-1.6-2.5-2.4c-1.2-1.2-2.4-2.4-3.7-3.5c-1.7-1.5-3.5-3-5.2-4.4c-1.3-1.1-2.3-1.9-3.3-2.7c-0.6-0.5-1.1-1-1.6-1.6
      c0.7,0.1,1.4,0.2,2.1,0.4c0.9,0.2,1.7,0.3,2.6,0.5h0.1c2.4,0.5,4.9,0.9,7.3,1.6c0.6,0.2,1.2,0.2,1.9,0.2c0.9,0,1.7-0.1,2.5-0.2
      c0.7-0.1,1.4-0.2,2.2-0.2C813.6,299,815.2,298.9,816.7,298.8z M815,296c0.8-0.1,1.7-0.5,2.5-0.8c0.1,0,0.1,0,0.2-0.1
      c0.1,0,0.3-0.1,0.5-0.2c0.8-0.5,1.6-0.8,2.4-0.8c1.2,0,2.3,0.5,3.6,1c0.2,0.1,0.3,0.1,0.5,0.2c0.7,0.3,1.3,0.6,2.1,0.6
      c1,0,2-0.3,3-0.7l0.2-0.1c0.7-0.2,1.4-0.5,2.1-0.8h0.1c0.4-0.2,0.7-0.3,1.1-0.5c0.1-0.1,0.3,0,0.4,0c1.7,0.7,3.5,1.4,5.3,2.1h-2.8
      c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1c-1.3-0.1-2.7-0.1-4.4-0.1
      c-1.1,0-2.1,0-3.3,0C816.7,296,815.8,296,815,296z M858.3,294.9c-1,0.2-2.1,0.4-3.1,0.6l-1.5,0.3c-1.8,0.3-3.6,0.7-5.4,1
      c-0.4,0.1-0.8-0.1-1.3-0.2c-1.7-0.6-3.1-1.1-4.4-1.5c-2.6-1-5.1-2-7.4-2.9c-0.6-0.2-1.2-0.4-1.8-0.4c-0.7,0-1.5,0.2-2.1,0.6
      c-2.7,1.5-5.5,1.6-8.2,0c-0.7-0.4-1.4-0.6-2.1-0.6c-1.1,0-2.2,0.4-3.2,0.9c-0.4,0.2-0.7,0.3-1.1,0.4c-1.7,0.6-3.4,1.2-5.2,1.9
      l-0.7,0.2c-0.5,0.2-1,0.4-1.6,0.6c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.5-1.5,0.8-2.5,0.8c-0.8,0-1.6-0.2-2.5-0.3
      c-0.2,0-0.4-0.1-0.6-0.1c-1.8-0.4-3.7-0.7-5.3-1h-0.3c-0.8-0.1-1.6-0.3-2.5-0.4c-0.1,0-0.2,0-0.4-0.1l0.7-0.3
      c1.2-0.6,2.1-1.1,3.1-1.6l1.8-0.9c2-1,4-2,6.1-3c2-1,3.9-1.9,5.9-2.8c0.9-0.4,1.7-0.8,2.6-1.2c0.3-0.1,0.5-0.2,0.8-0.4
      c0.7-0.3,1.3-0.6,2-1c0.4-0.3,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,1.9,0.4l1,0.3c1.9,0.6,3.8,1.2,5.8,1.3c1.5,0.1,3.2-0.4,5.1-1.3
      c0.8-0.4,1.6-0.6,2.3-0.6c0.9,0,1.7,0.2,2.8,0.7c1.8,0.9,3.6,1.8,5.4,2.6h0.1c1.5,0.7,3.2,1.5,4.9,2.4c2.3,1.1,4.6,2.3,6.8,3.4
      c1.1,0.6,2.2,1.1,3.3,1.7c0.4,0.2,0.8,0.4,1.1,0.6L858.3,294.9z M850.3,254.9l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.8,0.6-1.2
      c0.7-1.4,1.4-2.7,2.1-4c0.9-1.3,2.2-1.7,3.5-2.2C849.7,255.2,850,255.1,850.3,254.9z M848.1,253.6c-1,0.5-1.9,1-2.9,1.3
      c-0.1,0-0.2,0-0.3,0c-0.3,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-2.2-0.3-4.3-0.7-6.5-1l-2.2-0.3c0-0.4,0.3-0.7,0.9-1
      c1.4-0.6,2.9-1.3,4.3-1.9h0.1c1.2-0.5,2.5-1.1,3.7-1.6c0.8-0.3,1.4-0.2,2,0.4c0.9,1.1,1.9,2.1,2.8,3.1l0.9,1
      C848.7,253.3,848.4,253.5,848.1,253.6z M844,257l-0.6,1c-0.9,1.7-1.7,3.2-2.5,4.7c0,0-0.1,0.1-0.2,0.1c-2.3,0.2-4.5,0.4-7,0.6
      l-1.1,0.1l1.5-8.1L844,257z M833.3,271.5L833.3,271.5c0.3,2.1,0.7,4.2,1,6.3c0.1,0.7,0.2,1.5,0.2,2.3c0,0.3,0,0.6,0.1,1
      c-0.6,0.2-1.1,0.3-1.7,0.5l-0.2,0.1c-1.8,0.6-3.5,1.1-5.3,1.4c-0.2,0-0.4,0.1-0.6,0.1c-0.8,0-1.7-0.3-2.6-0.5l-0.2-0.1
      c-1.1-0.3-2.2-0.7-3.4-1.2l-0.2-0.1c-0.4-0.1-0.7-0.3-1.1-0.4l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4c0.1-0.9,0.3-1.8,0.4-2.6v-0.1
      c0.2-1.4,0.4-2.9,0.7-4.3c0.2-1,0.4-1,0.6-1c0.2,0,0.5,0.1,0.8,0.2c3,0.7,5.7,0.7,8.2-0.1c0.4-0.1,0.6-0.2,0.8-0.2
      c0.2,0,0.5,0,0.6,1.1C832.8,268.2,833,269.9,833.3,271.5z M821.1,263.4l-0.7-0.1c-1.4-0.1-2.9-0.2-4.3-0.3H816
      c-0.1,0-0.2-0.1-0.3-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3V258c-0.2-0.4-0.4-0.7-0.6-1.1l9.8-1.6
      l1.5,8.1H821.1z M811.1,262.8l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1c-0.9-2.4-1.6-4.5-2.2-6.6
      c1.7,0.5,3.3,0.9,4.2,2.4c0.8,1.3,1.4,2.6,2.1,4C810.7,262,810.9,262.4,811.1,262.8z M818.6,253.5c-2.5,0.4-4.8,0.8-7.1,1.1
      c-0.1,0-0.3,0.1-0.4,0.1c-0.2,0.1-0.3,0.1-0.4,0.1h-0.4c-0.6,0-1.2,0-1.7-0.2c-0.8-0.3-1.5-0.6-2.3-1c-0.2-0.1-0.4-0.2-0.7-0.3
      c0.3-0.3,0.6-0.6,0.8-1c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1c2.5,1,5.2,2.2,8.3,3.6
      c0.2,0.1,0.5,0.4,0.7,0.8c0,0,0,0.1,0.1,0.1L818.6,253.5z M811.4,241L811.4,241l-0.1,0.1c-0.1,1-0.3,2.1-0.6,3
      c-0.2,0.5-0.7,1-1.2,1.4c-0.3,0.3-0.6,0.5-0.9,0.9c-2.4,2.8-4.3,4.9-5.9,6.8c-0.5,0.6-0.9,1.3-0.4,2.5c0.6,1.5,1.1,3.1,1.6,4.7
      c0.3,1,0.6,1.9,0.9,2.7c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.6,1.6-3.8,2.4l-1.1,0.7l-1-1.4c-1-1.5-2-3-3-4.5l-0.4-0.6
      c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1c1.9-1.7,3.3-3,4.6-4.3c1.9-1.8,3.8-3.6,5.6-5.4l0.3-0.3c1.2-1.1,2.3-2.2,3.5-3.4
      c0.9-0.9,1.8-1.7,2.7-2.5l1-1C811.5,240.5,811.4,240.8,811.4,241z M800.7,268.6c0.7-0.4,1.3-0.9,2-1.4c1.2-0.8,2.4-1.7,3.6-2.4
      c0.9-0.3,1.9-0.3,2.7-0.3c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4l1,0.1c1,0.1,2,0.2,3,0.3c0.3,0,0.7,0.1,1,0.1l-0.2,1.4
      c-0.2,1.3-0.4,2.7-0.6,4c-0.1,0.8-0.3,1.6-0.4,2.4v0.2c-0.4,2.3-0.8,4.7-1,7c-0.1,1.1-0.5,1.3-1.3,1.6c-0.4,0.2-0.8,0.3-1.3,0.5
      c-0.9,0.3-1.8,0.7-2.6,1.1c-0.3,0.2-0.6,0.2-1,0.2c-0.3,0-0.6-0.1-1-0.1c-0.3-0.1-0.7-0.1-1.1-0.1h-0.1c0,0-0.1-0.1-0.3-0.3
      c-1.2-1.9-2.3-3.8-3.5-5.7c-1.6-2.7-3.2-5.3-4.8-8C800.3,269.1,800.3,268.9,800.7,268.6z M845.7,283.1c-0.3,0.5-0.5,0.6-1.3,0.9
      c-0.3,0.1-0.6,0.1-0.9,0.1c-0.9,0-1.6-0.4-2.4-0.8l-0.4-0.2c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4
      c-0.4-0.1-0.5-0.3-0.5-0.9c0-1.5-0.3-3.1-0.5-4.6v-0.2c-0.4-2.4-0.8-5-1.2-7.1l-0.1-0.7c-0.2-0.9-0.3-1.9-0.5-2.8
      c0-0.1,0-0.1,0-0.2l2.5-0.2c2.2-0.2,4.3-0.3,6.4-0.4c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1
      c0.8,0.5,1.5,1,2.4,1.6l0.1,0.1c0.9,0.6,1.8,1.2,2.7,1.8c0.5,0.3,0.5,0.5,0.2,0.9c-2.2,3.6-4.2,6.8-6.1,9.8c-0.6,1-1.2,2.1-1.8,3.2
      L845.7,283.1z M849.9,263.4l0.6-1.7c0.6-2,1.3-4.1,1.9-6.2c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2
      c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.5-1.9-2.5-2.6c-1.7-1.3-2-3-2.3-4.9c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0-0.3
      c0.2,0.1,0.3,0.3,0.4,0.4c1.3,1.2,2.6,2.5,3.9,3.7c2.3,2.2,4.5,4.4,6.8,6.6l0.1,0.1c1.8,1.7,4,3.8,6.2,5.7c0.7,0.6,0.7,1,0.1,1.7
      c-1.5,2.2-2.9,4.2-4.2,6.1c-0.4,0.6-0.7,1.2-1.1,1.8c-0.1,0.1-0.1,0.3-0.2,0.4L849.9,263.4z M861.1,254.4c-1.5-1.3-2.9-2.5-4.3-3.8
      c-0.8-0.7-1.5-1.5-2.3-2.3l-0.1-0.1c-0.6-0.7-1.3-1.4-2-2c-2.6-2.5-5.3-5-8.1-7.5c-1.2-1.1-1.9-2.4-2.3-4c-0.6-3-1.4-6-2.1-9
      c-0.3-1.2-0.6-2.4-0.9-3.5c-0.1-0.5-0.3-1-0.4-1.6l-0.1-0.5l0.1,0.1c0.8,0.6,1.7,1.3,2.5,1.9l0.1,0.1c1.8,1.4,3.7,2.9,5.7,4.2
      c2.1,1.5,4.4,3,6.5,4.2c0.8,0.5,1.6,1,2.4,1.5c0.8,0.6,1.7,1.2,2.6,1.7c1.9,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.4,0.6,0.4,1
      c-0.3,4.1-0.6,8.1-0.9,12.1v0.1c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8C862.2,255.3,861.6,254.9,861.1,254.4z
       M842.1,246.3c0,0.2,0.1,0.4,0.2,0.6v0.1c-0.5,0.2-0.9,0.4-1.4,0.6h-0.1c-1.3,0.6-2.6,1.2-3.9,1.7c-0.5,0.2-1,0.4-1.5,0.6
      c-0.6,0.2-1.2,0.4-1.9,0.7c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1s-0.3,1.9-0.5,2.8c-0.4,1.7-0.8,3.7-1.3,5.6
      c-0.1,0.2-0.6,0.5-0.9,0.6c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1c-0.3-1.5-0.5-2.9-0.9-4.5v-0.1c-0.2-1.2-0.5-2.5-0.7-3.7
      c-0.7-4-0.9-4.2-4.9-5.3c-1.1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2c-0.5-0.2-1-0.5-1.4-0.8c-0.1-0.1-0.2-0.1-0.4-0.2c0-2,1.6-2.7,3.2-3.4
      c0.8-0.4,1.9-0.4,2.9-0.5c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.1,1.9-0.2c1.7-0.2,3.4-0.4,5.1-0.5c1.1,0,2.2,0.1,3.4,0.2
      c0.5,0.1,1,0.1,1.5,0.2l2,0.2c1.6,0.2,3.2,0.3,4.9,0.4C839.7,243.4,841.8,245.2,842.1,246.3z M813.1,232.4c-0.5,2.8-1.3,5.1-3.7,7
      c-2.6,2.1-5,4.5-7.4,6.9c-1.1,1.1-2.2,2.2-3.3,3.2c-1.5,1.5-3.1,2.9-4.6,4.3l-0.1,0.1c-0.7,0.6-1.3,1.3-2,1.9
      c0-0.3-0.1-0.5-0.1-0.8v-0.2c-0.1-1-0.3-1.9-0.4-2.9c-0.2-2.2-0.4-4.6-0.5-6.6v-0.6c-0.1-1-0.1-2-0.2-2.9c0-0.4-0.1-0.8-0.1-1.2
      c-0.1-0.8-0.2-1.6-0.2-2.4c0-0.3,0.3-0.8,0.6-1c2-1.3,4.1-2.6,5.8-3.7l0.1-0.1c3-1.9,6-3.8,9-5.8c2.3-1.6,4.6-3.3,6.8-5l0.1-0.1
      c1-0.7,1.9-1.5,2.9-2.2c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3-0.2,0.6-0.2,0.9c-0.3,1.1-0.6,2.3-0.9,3.5l-0.3,1.2l-0.1,0.1h-0.1
      C814.1,227.9,813.5,230.2,813.1,232.4z M791.3,259.2c0.4,0.5,0.7,1.1,1.1,1.6l0.3,0.4c1.2,1.7,2.2,3.2,3.2,4.9
      c0.9,1.5,1.8,3,2.6,4.5c0.6,1.1,1.3,2.3,2,3.5c0.7,1.1,1.4,2.3,2.1,3.4l0.1,0.1c0.5,0.8,1,1.5,1.5,2.3c1,1.5,1.9,3.1,2.8,4.6
      c0.3,0.6,0.4,0.9,0.3,1.2c-0.1,0.3-0.4,0.5-0.9,0.8l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2c0,0,0,0-0.1,0
      c-0.3,0-0.5-0.1-0.5-0.2l-1-1.4c-1.6-2.4-3.3-4.8-4.8-7.3c-0.5-0.9-0.9-2-1.3-3.1c-0.2-0.6-0.4-1.3-0.7-1.9c-0.9-2-1.8-4.2-3.1-6.9
      c-0.4-0.8-0.4-1.2,0.3-1.9c1.2-0.8,2.1-2,2.9-3.1c0.6-0.8,1.3-1.7,2-2.5c1.4-1.5,2.8-3,4.2-4.5l0.1-0.1
      C790.3,260.3,790.8,259.7,791.3,259.2z M781.7,281.3c0.3,0.6,0.6,1.2,0.8,1.9l0.1,0.1c1,2.2,1.9,4.4,3.1,6.5c0.9,1.6,2,3,3.1,4.4
      c0.5,0.6,1,1.3,1.5,1.9l0.1,0.1c-0.2,1.2,0.6,1.8,1.1,2.2c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7,1.4,1.3,2.1,1.9l0.3,0.3
      c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4c-0.8,0.7-1.6,1.5-2.3,2.2l-0.1,0.1c-1.2,1.1-2.5,2.3-3.8,3.5c-1.3,1.1-2.6,2.2-3.8,3.2
      c-0.4,0.4-0.9,0.7-1.3,1.1l-0.4-2.8c-1.7-11.3-3.2-22-3.2-33.1c0.1,0.3,0.2,0.7,0.4,1.1C780.5,278.5,781.1,279.9,781.7,281.3z
       M784.7,313.5l0.7-0.6c0.7-0.6,1.4-1.2,2.1-1.8c0.5-0.4,1-0.8,1.4-1.2l0.2-0.2c1.2-1,2.4-2.1,3.6-3.1c0.9-0.8,1.7-1.6,2.4-2.4
      c0.3-0.3,0.6-0.6,0.9-1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.5,0.3l2.4,2.2c1.4,1.3,2.9,2.7,4.4,4c0.5,0.5,1.1,1,1.7,1.6
      l0.1,0.1c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5l-0.4,1.2c-0.6,1.8-1.2,3.8-1.7,5.8c-0.4,1.5-0.7,2.9-1.1,4.3
      c-0.2,0.7-0.3,1.4-0.5,2.1c-0.1,0.4-0.2,0.9-0.3,1.4l-0.1,0.6l-20.3-15.9L784.7,313.5z M806.5,329.9c0.3-1.3,0.6-2.5,0.9-3.7
      c0.2-1,0.5-2,0.7-3c0.3-1.4,0.7-3.1,1.1-4.8c0.3-1.1,0.6-2.2,0.9-3.3c0.1-0.3,0.2-0.5,0.7-0.5h0.1c3.6,0.1,7.2,0.2,10.8,0.2h1.7
      c0.9,0,1.9,0,2.8,0.1h0.1c2.9,0.1,5.9,0.1,8.8,0.1c2.4,0,5-0.1,8.4-0.4h0.1c0.5,0,0.6,0.2,0.7,0.8v0.1c0.7,2.9,1.5,5.9,2.3,8.9
      c0.4,1.5,0.7,3,1.1,4.6l0.5,2.2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.7,0c-0.7,0-1.5,0-2.2,0
      c-3.3,0-6.8,0.2-10.3,0.6c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3c-2.3-0.3-4.8-0.4-8-0.4c-0.9,0-1.9,0-2.8,0
      c-1.4,0-2.8,0-4.3,0h-0.5c-0.4,0-0.7,0-1.1,0L806.5,329.9z M868.6,309.8c-2.1-1.9-4.3-3.9-6.3-5.7l-2.5-2.2
      c-0.2-0.2-0.2-0.3-0.2-0.4s0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5c0.4-0.4,0.8-0.8,1.2-1.1c0.1-0.1,0.2-0.2,0.3-0.2
      c0.5-0.5,1.2-1,1-2.1c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.5,0.8-1.1,1.2-1.6c1-1.3,2-2.7,2.9-4.1
      c1.1-1.7,1.9-3.6,2.7-5.5c0.9-2,1.8-4.1,2.6-6.1l0.1-0.2c0.3-0.6,0.5-1.2,0.8-1.9c-0.1,11.7-1.8,23-3.5,35l-0.1,0.8
      c-0.2-0.2-0.4-0.3-0.6-0.5C869.9,310.9,869.3,310.3,868.6,309.8z M874.1,272.7c-0.3,0.7-0.6,1.3-0.9,2c-0.6,1.4-1.3,2.8-1.9,4.2
      c-0.3,0.8-0.7,1.6-0.9,2.4c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.6-1.8,3.2-2.8,4.8l-0.3,0.5c-0.4,0.7-0.9,1.3-1.3,1.9v0.1
      c-0.4,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.3-0.1-0.5-0.2c-2.9-1.5-5.8-3-8.6-4.4l-2.7-1.4
      c-0.9-0.5-1.8-0.9-2.8-1.4l-1.4-0.7c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5c0.7-1.2,1.4-2.4,2.1-3.4
      c1.2-1.9,2.4-3.8,3.4-5.8c2.6-4.7,5.5-9.2,8.6-13.8c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.5,1.1,0.9,1.6,1.5
      c1.8,2,3.6,4,5.3,6l0.8,1c0.3,0.4,0.6,0.8,0.9,1.1c0.6,0.7,1.2,1.5,1.8,2.1C874.4,271.9,874.4,272.2,874.1,272.7z M848,224.7
      l3.6-11.8c1.5,0.5,2.9,1.2,4.3,1.8c3.1,1.3,6.2,2.7,9.8,3.3l-1.4,17.2L848,224.7z M851.8,196.7L851.8,196.7
      c-0.5,0.5-0.8,0.9-1.2,1.2l-10.9-11.5c0-0.1,0.1-0.1,0.1-0.2l0.8-1.3c1.3-2,2.6-4,4-6c2.3-3.4,5.2-7.3,9.2-10.2
      c1.4-1,3.1-1.8,4.6-2.6c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1c0.2-0.1,0.5-0.2,0.8-0.2c0,0,0,0,0.1,0L866,186
      c-2.1,0.1-3.6,1.3-4.9,2.6c-0.4,0.2-0.8,0.5-1.2,0.8l-0.2,0.2c-1.5,1.2-3.1,2.4-4.5,3.7C854,194.4,852.9,195.6,851.8,196.7z
       M857.2,201.7c-0.3,0-0.5,0-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4c-0.1,0-0.4-0.2-0.6-0.6c-0.4-0.7-0.5-1.5-0.2-1.9
      c0.2-0.4,0.6-0.7,0.9-1.1c0.1-0.1,0.1-0.2,0.2-0.2L857.2,201.7z M841.5,191.3c2.7,2.8,5.5,5.7,8,8.7c1.3,1.5,1.7,2.9,1.2,4.5
      c-0.4,1.2-0.5,2.4-0.7,3.8c-0.1,0.4-0.1,0.9-0.2,1.3c-0.1,0-0.2-0.1-0.3-0.1l-0.1-0.1c-0.6-0.2-1.1-0.5-1.7-0.8
      c-1.6-0.9-3.6-2-5.6-3.2c-1.4-0.9-2.8-1.8-4.4-3c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.5,0.6-7,0.9-10.6l0.2-2.3c0.2,0.1,0.3,0.3,0.5,0.4
      l0.1,0.1C840.1,189.9,840.8,190.6,841.5,191.3z M865.2,215.6c-0.2,0-0.3,0-0.6-0.1c-1.8-0.6-3.5-1.1-5.2-1.8
      c-1.3-0.5-2.7-1.1-3.9-1.7l-0.2-0.1c-0.9-0.4-1.9-0.8-2.8-1.2c-0.4-0.2-0.5-0.3-0.4-0.8c0.2-1.3,0.4-2.6,0.6-3.9l0.2-1.1
      c0.5-0.1,1-0.2,1.5-0.3h0.2c1.5-0.3,3.1-0.7,4.7-1c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1c0.5,0.1,0.9,0.2,1.4,0.3h0.1
      c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2c0,1.7,0,3.5,0,5.2v0.1c0,0.8,0,1.6,0,2.3c0,0.2,0,0.4,0,0.5c0,0.4,0,0.8,0,1.2
      C865.7,215.4,865.6,215.6,865.2,215.6z M865.3,202.2L865.3,202.2c-2.7,0-5-0.9-7-2.8c-0.2-0.2-0.5-0.4-0.8-0.6
      c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4c0.9-0.7,1.9-1.5,2.8-2.2c1.2-1,2.4-2,3.6-2.9
      c1-0.7,2.1-1.2,3-1.2c4.3-0.3,8.8-0.3,12.9-0.4l0.3-0.1c2.5,0,4.5,0.9,6.1,2.6c1,1.1,2.1,2,3.3,3.1l0.1,0.1c0.4,0.3,0.8,0.7,1.2,1
      l-1.5,1.2c-1.6,1.3-3.1,2.6-4.7,3.8c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.3
      c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.2,0h-0.1c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1
      c-2.3,0-4.8,0-6.9,0C869.1,202.2,867.2,202.2,865.3,202.2z M866.7,163.4c0.1,0,0.2,0,0.3,0c0.5-0.1,1-0.2,1.5-0.2h0.1
      c2.3,0,4.6,0.1,6.5,0.2h0.4c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1c0.7,0,1.3,0.2,1.8,0.8
      c0.5,0.6,1.1,1.2,1.7,1.7c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.4c0.5,0.7,1,1.4,1.6,2.1c-0.6,0.4-1.2,0.9-1.8,1.3h-0.1
      c-1.6,1.1-3.2,2.2-4.8,3.3c-2.4,1.6-4.8,3.2-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1c-1.1-0.1-2.2-0.2-3.3-0.3
      c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1c-0.4,0-0.8,0-1.2,0h-0.2L866.7,163.4z
       M885.6,210.6l0.8,1.4l-18.6,2.5v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2
      c1.3-0.1,2.6-0.2,3.9-0.2c0.1,0,0.4,0.2,0.5,0.4C883.4,206.5,884.5,208.5,885.6,210.6z M867.1,229.3c0.2-2.5,0.3-5,0.4-7.3l0.1-2.2
      c0-0.2,0-0.5,0-0.7c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6c2.3-0.5,4.4-0.9,6.4-1.2c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1
      c0.7-0.1,1.6-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5c0.3,1.8,0.6,3.5,0.9,5.3v0.2c0.3,2,0.7,4.1,1,6.1
      c0.4,2.4,0.9,4.8,1.4,7.2c0.2,0.9,0.4,1.9,0.6,2.8c-0.5-0.1-0.9-0.2-1.3-0.3H890h-0.1c-1.1-0.3-2.2-0.5-3.4-0.8
      c-2-0.5-4.1-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1c-1.9-0.5-3.8-1-5.8-1.5c-0.8-0.2-1.2-0.5-1.1-1.4
      C866.8,233.4,866.9,231.3,867.1,229.3z M888.3,211l-4.5-8c0.1-0.1,0.3-0.2,0.5-0.3c0.6-0.4,1.2-0.9,1.8-1.3l0.9-0.7
      c1.7-1.5,3.6-3,5.3-4.5c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.7-3.3-5.5-5c-0.1-0.1-0.1-0.1-0.2-0.3l13.7-9.4
      c0,0.1,0.1,0.2,0.1,0.3c0.2,0.5,0.4,1,0.5,1.6c0.9,3.6,1.6,6.7,2.3,9.7c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4
      c-1.6,1.2-3.3,2.4-4.9,3.6l-0.3,0.2c-1.5,1.1-2.9,2.1-4.3,3.2c-1.3,1-2.6,1.9-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9
      C888.6,210.8,888.5,210.9,888.3,211z M904.4,187l-0.2-0.9c-0.3-1.3-0.6-2.5-0.9-3.8c-0.1-0.4-0.2-0.7-0.2-1.1v-0.1
      c-0.2-1.1-0.4-2.2-0.9-3.3c-0.5-1.2-0.4-2.3-0.3-3.5v-0.1c0.1-1.2,0.2-2.4,0.3-3.7c0.1-1.5,0.3-3.1,0.5-4.7
      c0.2-1.4,0.4-2.8,0.6-4.2v-0.1c0.1-0.8,0.3-1.6,0.4-2.4c0.3-1.8,0.5-3.6,0.8-5.3c0.4-2.9,0.8-5.9,1.3-8.9c0.1-0.5,0.2-0.9,0.4-1.5
      c0-0.1,0-0.2,0.1-0.2l0.6,0.6c0.7,0.7,1.3,1.4,1.9,2c1,1.1,2.1,2.2,3.1,3.2c1.7,1.8,3.6,3.7,5.3,5.5c1.1,1.2,2.3,2.4,3.3,3.6
      l0.1,0.1c0.8,0.9,1.7,1.8,2.5,2.8c0.4,0.4,0.8,0.9,1.2,1.3c1.1,1.2,2.2,2.4,3.4,3.5c2.1,1.8,2,3.8,1.5,5.8
      c-0.6,2.3-1.1,4.8-1.6,6.9v0.1c-0.5,2.1-1,4.4-1.5,6.5c-0.4,1.7-0.9,3.4-1.3,5.1v0.1c-0.4,1.3-0.7,2.7-1.1,4
      c-0.6,2.4-1.2,4.8-1.7,7.1c-0.2,1-0.5,2-0.7,3c0,0.2-0.1,0.3-0.1,0.5v0.1c-0.1,0.3-0.2,0.7-0.3,1.1c-0.3,1.1-0.6,2.2-1,3.2
      l-0.2,1.4c-0.3-0.3-0.6-0.6-0.9-0.9l-0.2-0.2c-0.9-1-1.9-2.1-2.9-3.1c-1.4-1.4-2.9-2.8-4.3-4.1c-1.2-1.1-2.4-2.3-3.6-3.4
      c-0.5-0.5-0.9-1.1-1-1.6C906,193.9,905.2,190.4,904.4,187z M931.6,156.7c-0.3,2.1-0.7,4.2-1,6.4l-0.1,0.5c-0.1,0.6-0.2,1.3-0.3,1.9
      l-0.6-0.6c-0.7-0.8-1.4-1.6-2.1-2.3c-2.6-2.8-6.1-6.5-9.6-10.3c-1.4-1.5-2.9-3-4.4-4.5l-0.2-0.2c-1-1.1-2-2.1-3.1-3.2
      c-0.4-0.4-0.7-0.8-1.1-1.2c-0.7-0.7-1.4-1.5-2.1-2.2c-0.5-0.4-0.5-0.9-0.4-1.4c0.3-1.3,0.7-2.6,1-3.9c0.6-2.5,1.4-5.4,2-8.1
      c0.4-1.7,0.6-3.4,0.8-5v-0.2c0.1-0.7,0.2-1.4,0.3-2l0.4-2.3c0.3-1.9,0.6-3.8,0.8-5.7c0.1-1,0.2-2,0.3-3c0.1-0.7,0.1-1.5,0.2-2.2
      c0-0.4,0.1-0.8,0.2-1.3v-0.1c0.3,0.4,0.6,0.7,0.9,1.1c1,1.2,2,2.3,2.8,3.4c3,3.5,5.5,7,7.9,10.4c1,1.4,1.9,2.9,2.8,4.3
      c0.6,0.9,1.1,1.8,1.7,2.7c0.3,0.5,0.7,1,1,1.6c1.2,1.9,2.5,3.8,3.8,5.6c0.7,0.9,0.9,1.7,0.7,2.9c-0.2,1.2-0.3,2.4-0.5,3.6
      c-0.2,1.5-0.4,3.1-0.7,4.6c-0.2,1.3-0.4,2.7-0.5,4C932.2,152.1,931.9,154.4,931.6,156.7z M910.3,108.1l-0.1,0.8
      c-0.8,5.9-1.7,12.1-2.7,18.1c-0.5,2.7-1.1,5.4-1.8,8l-0.1,0.3c-0.3,1-0.5,2-0.7,3c-0.2,0.7-0.4,1.4-0.5,2l-0.1,0.5
      c-0.4-0.2-0.8-0.5-1.2-0.7l-1-0.5c-1.7-0.9-3.5-1.9-5.2-2.9c-1-0.6-2.1-1.1-3.1-1.6l-0.1-0.1c-1-0.5-2.3-1.2-3.6-1.9
      c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.2-0.7-2.4-1.4-3.6-2c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5
      c-1.6-0.9-3.2-1.9-4.7-2.8l-0.4-0.2c-0.5-0.3-1-0.6-1.5-0.9c0-0.1-0.1-0.2-0.3-0.2c-0.4-0.1-0.7-0.2-0.8-0.3
      c-0.2-0.2-0.1-0.7,0-1.2c0-0.2,0.1-0.4,0.1-0.6c0.1-1.9,0.2-3.7,0.3-5.6c0.1-1.6,0.2-3.1,0.2-4.7c0.1-1.2,0.1-2.3,0.2-3.5
      c0.1-1.9,0.2-3.7,0.3-5.6c0.4-6.5,0.8-13,1.1-19.5c0.3,0.2,0.6,0.3,0.8,0.5l0.5,0.3c1.2,0.7,2.5,1.4,3.8,2.2L879,85
      c2.8,1.6,5.7,3.3,8.4,5c4.5,2.8,7.8,5,10.9,7.1c1.8,1.2,3.6,2.6,5.3,3.9l0.2,0.2c1.8,1.3,3.7,2.7,5.6,4
      C910.5,106,910.4,106.9,910.3,108.1z M870.2,124.3l0.3,0.2c1.2,0.8,2.6,1.7,4,2.5c1,0.7,2.3,1.4,3.4,2l0.6,0.3
      c1.7,0.9,3.5,1.9,5.2,2.8l1.6,0.8c0.7,0.4,1.5,0.8,2.2,1.2l0.7,0.4c1.8,1,3.7,2,5.6,3c1,0.5,1.9,1.1,2.9,1.6l0.3,0.1
      c1.9,1,3.8,2.1,5.8,3.1c1.1,0.5,1.2,1.2,1,2.2c-0.2,1.3-0.4,2.5-0.6,3.8l-0.1,0.8c-0.6,4.3-1.3,8.7-1.9,13.2
      c-0.4,2.7-0.6,5.5-0.8,8.2c-0.1,1.3-0.2,2.6-0.4,3.9c0,0.2,0,0.3-0.1,0.5l-0.7-1c-0.7-0.9-1.4-1.9-2.2-2.8c-2-2.5-4.1-5.1-6.1-7.5
      l-0.4-0.4c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0c-0.4,0-0.8,0-1.2,0
      c-0.8,0-1.5,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2c-0.6,0-1.2,0.1-1.8,0.1
      c-0.3,0-0.7,0-1.1,0.1h-0.5l2-36.2l0.1-1C869.3,123.8,869.7,124,870.2,124.3z M866.7,123.3v0.3c-0.1,1-0.2,2.1-0.3,3.1
      c-0.1,1.7-0.2,3.4-0.2,5c-0.1,1.3-0.1,2.7-0.2,4.1c-0.1,1.9-0.2,3.9-0.3,5.8c-0.1,1.6-0.2,3.1-0.3,4.7c-0.1,1.6-0.2,3.1-0.2,4.7
      c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.5c-0.1,1.9-0.2,3.8-0.3,5.7c0,0.2-0.3,0.5-0.6,0.6c-2.4,1.1-4.8,2.1-7.1,3.2l-0.2,0.1
      c-1.1,0.5-2.1,0.9-3.2,1.4c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.2,5.5-6.1,8.2l-1,1.4c-0.8,1.2-1.6,2.5-2.3,3.7l-0.1,0.2
      c-0.4,0.7-0.7,0.9-1.2,0.9c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1c-1-0.3-1.9-0.6-2.9-1c-0.4-0.1-0.9-0.3-1.4-0.4
      c-0.2,0-0.3-0.1-0.5-0.1v-65.9c13.8,0.9,27.1,3.4,38.3,5.6C866.8,122.8,866.8,123.1,866.7,123.3z M836.9,210.6
      c0-1.3,0.1-2.6,0.3-4.1c0-0.5,0.1-0.9,0.1-1.4c0.4,0.2,0.7,0.5,1.1,0.7l0.1,0.1c1.2,0.8,2.4,1.6,3.6,2.2c2,1.1,4.2,2.2,6.2,3.1
      c0.9,0.4,1.1,0.7,0.8,1.6c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9c-0.3,0.9-0.5,1.7-0.7,2.6v0.1c-0.2,0.6-0.3,1.2-0.5,1.8
      c0,0.2-0.1,0.3-0.2,0.5c-0.2-0.1-0.3-0.2-0.5-0.3l-0.2-0.2c-0.8-0.6-1.5-1.1-2.2-1.6c-2-1.6-3.9-3.2-5.7-4.7
      c-0.3-0.2-0.5-0.7-0.5-1.1C836.8,213.5,836.8,212,836.9,210.6z M837.7,225.7c0.9,3.7,1.7,7.6,2.4,11l0.2,0.9
      c0.2,0.9,0.4,1.9,0.6,2.8c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.8-0.2-2.8-0.3
      c-2.7-0.3-5.4-0.6-8.2-0.6c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1
      c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.2c0.8-3.7,1.6-7.6,2.5-11.4c0.5-1.9,1-3.9,1.6-5.8l0.4-1.4
      c0.6-2.3,1.1-2.4,2.5-2.5c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0h2.9v-0.1
      c0.5,0,1,0.1,1.4,0.1c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.5C836.6,221.5,837.2,223.6,837.7,225.7z M817.7,212.4
      c0,0.7,0,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.5-3.7,3-6.2,4.9c-0.5,0.4-1,0.7-1.5,1.1c-0.1,0.1-0.3,0.2-0.4,0.3l-0.2-0.5
      c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3c-0.4-1.6-0.9-3.4-1.5-5.1c-0.3-0.8,0-1,0.7-1.3c0.4-0.2,0.7-0.3,1.1-0.5l0.3-0.1
      c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.3-1.3,3.2-2c0.5-0.3,1-0.7,1.5-1c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,0.9,0.1,1.3
      c0.1,1.4,0.3,2.7,0.4,4C817.7,211.1,817.7,211.8,817.7,212.4z M817.4,185.3c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.4-3.7-3.5-5.4
      l-0.1-0.1c-0.6-1-1.3-1.9-1.9-2.9c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.1c-0.4-0.2-0.9-0.4-1.3-0.6
      c-1.1-0.5-2.2-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.4-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7v-0.1
      c-0.1-1.8-0.1-3.6-0.2-5.5c-0.1-2-0.3-4.1-0.4-6.1v-0.1c-0.1-1.9-0.3-4-0.4-6.1c-0.1-1.1-0.1-2.2-0.1-3.3c0-1.2,0-2.3-0.1-3.5
      c-0.1-1.7-0.3-3.4-0.4-5.1v-0.3c-0.1-0.7-0.1-1.4-0.2-2.1c0-0.1,0-0.2,0-0.4c15.1-3.1,26.9-4.8,38.2-5.6v65.8l-2.1,0.7
      c-2,0.7-3.9,1.3-5.8,1.9C817.8,185.3,817.6,185.3,817.4,185.3z M723,171.4L723,171.4c0.3,1.5,0.5,3,0.9,4.5c0.9,4,1.6,7.3,2.4,10.3
      c0.4,1.7,0.9,3.5,1.3,5.1c0.4,1.3,0.8,2.8,1.1,4.3c0.5,2.1,1,4,1.6,6.4l0.1,0.3c0.5,2,1,4,1.5,6c0.2,0.8,0.1,1.4-0.2,1.9
      c-0.4,0.6-1.2,1-2.5,1.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0c-0.6,0-0.9-0.2-1.1-0.7
      c-0.3-1.1-0.6-2.3-0.8-3.4c-0.3-1.4-0.6-2.7-0.8-4.1v-0.1c-0.5-2.5-1-5-1.6-7.5c-0.3-1.4-0.7-2.7-1-4c-0.3-1-0.6-2-0.8-3.1
      c-0.4-1.5-0.7-3-1-4.4v-0.1c-0.2-0.7-0.3-1.4-0.5-2.2l-0.4-1.7c-0.3-1.4-0.6-2.7-0.9-4c-0.1-0.5-0.1-0.8,0-0.8
      c1.1-1.4,2.3-2.9,3.3-4.2l0.6-1.3C722.8,170.5,722.9,170.9,723,171.4z M720,196.6c0.4-0.4,0.7-0.7,1.1-1.1l5.5,27.5
      c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.6c-0.3-0.8-0.7-1.6-1-2.3c-1.8-4.2-3.8-8.6-6.3-12.6l-0.2-0.3
      c-0.5-0.8-1.1-1.7-1.3-2.6c-0.2-0.6,0.1-1.6,0.6-2.1C717.9,198.8,719,197.7,720,196.6z M717,208.1c1.1,2.2,2.2,4.4,3.2,6.6l0.8,1.7
      c0.2,0.5,0.4,0.9,0.7,1.5l0.2,0.5c-0.2,0-0.4,0-0.5,0c-0.3,0-0.7,0-1,0c-0.5,0-0.8,0-1.2,0h-0.8c-2.3,0.1-4.8,0.2-7.2,0.4
      c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.8c-0.1-1.3-0.1-2.6-0.1-4V204v-0.3c0-0.4,0-0.8,0-1.2
      c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.9,0.5,1.1,0.9C715.3,204.9,716.1,206.4,717,208.1z M709.5,221.2
      c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.6-0.8l3-0.3c3.3,5.7,6.3,11.9,5.9,19.5H728c-2.2-0.1-4.6-0.2-6.9-0.2c-3.8,0-7,0.3-9.9,1
      l-0.1-1.1c-0.1-1.1-0.2-1.9-0.3-2.9c-0.3-3.3-0.6-6.1-0.9-9c-0.1-1.1-0.2-2.2-0.3-3.3c-0.1-0.9-0.1-1.7-0.2-2.6
      C709.5,221.2,709.5,221.2,709.5,221.2z M734.9,222.5l-0.1-1.6c-0.5-1.7-0.6-3.6-0.6-5.4c0-1.3,0.5-2.4,1.7-3.4
      c1.6-1.4,3.1-2.9,4.6-4.3c1-1,1.9-1.9,2.9-2.8c1.3-1.2,2.6-2.4,3.8-3.5c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1c2.2,1.6,4.6,3.4,7,5.1
      c1.3,0.9,2.6,1.9,3.8,2.9l0.1,0.1c0.8,0.6,1.6,1.2,2.4,1.8c0.8,0.6,1,1.4,0.8,2.5c-0.4,2-0.8,4-1.2,6v0.2c-0.1,0.7-0.3,1.4-0.4,2.1
      l-0.3,1.8c-0.3,1.6-0.6,3.3-0.9,4.9c-0.3,1.7-0.6,3.5-0.9,5.2c-0.4,2.5-0.8,4.7-1.2,7c-0.3,1.6-0.9,1.8-1.5,1.8
      c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2c-3.1-1.3-6.1-2.7-9-4.1l-1.9-0.9c-0.2-0.1-0.4-0.4-0.4-0.7
      C735.4,230,735.2,226.5,734.9,222.5z M760.4,255.8c0.2-2.3,0.6-4.5,0.9-6.6c0.2-1,0.3-1.8,0.4-2.7c0.1-0.9,0.6-1.4,1.7-1.7
      c4.8-1.1,8.8-2.1,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1c1.2-0.3,2.4-0.7,3.7-1c0.6-0.2,1.3-0.3,2-0.5h0.2c0.2,0,0.4-0.1,0.5-0.1
      c0,0.5,0,0.9,0.1,1.4c0.1,1.3,0.1,2.6,0.2,3.9v0.2c0.2,3.4,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4c0.3,0.6,0.1,0.9-0.3,1.4
      c-0.3,0.3-0.6,0.6-0.9,0.9l-0.1,0.1c-1,0.9-2,1.9-3,2.9c-1,1.1-2,2.3-2.9,3.5c-0.7,0.9-1.5,1.8-2.3,2.7l-0.3,0.4
      c-0.7,0.8-1.4,1.6-2.2,2.1c-0.3,0.1-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0s-0.6,0-0.9,0H775c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3,0-1.9,0
      c-0.6,0-1.2,0-1.7,0.1c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4c0.2-1.6,0.4-3.1,0.6-4.7C759.3,263.8,759.9,259.8,760.4,255.8z
       M775.6,340.4l-0.1-0.1c-1.2-0.8-2.4-1.6-3.6-2.4c-0.9-0.6-1.8-1.1-2.7-1.7c-0.8-0.5-1.7-1-2.5-1.5s-1.5-1.1-2.1-1.7
      c0,0-0.1-0.2-0.1-0.5c0-0.5,0-1,0-1.4v-0.3l18-15l0.5,0.4c0.6,0.5,1.2,1,1.8,1.5c0.8,0.7,1.6,1.4,2.4,2.1c1.8,1.5,3.6,3,5.4,4.5
      c1,0.8,2,1.5,2.9,2.1c0.8,0.6,1.7,1.2,2.5,1.8c0.9,0.7,1.8,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2c0.4,0.3,0.4,0.5,0.2,1
      c-0.4,1.4-0.8,2.9-1.2,4.3c-0.6,2.2-1.2,4.5-1.8,6.8c-0.7,2.3-1.4,4.6-2.2,6.9l-0.5,1.5c-0.2,0.6-0.5,0.6-0.6,0.6
      c-0.2,0-0.4-0.1-0.6-0.2c-1.1-0.7-2.1-1.4-3.2-2c-1.3-0.8-2.9-1.9-4.5-2.8c-1.2-0.8-2.5-1.5-3.7-2.2l-0.2-0.1
      c-1.5-0.9-3.1-1.8-4.6-2.8S776.9,341.3,775.6,340.4z M852.7,338.3l-0.1-0.3c-0.2-0.8-0.4-1.5-0.6-2.3c0-0.2-0.1-0.4-0.1-0.6
      c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.3-1,2.6-2,3.9-2.9l0.1-0.1c1.6-1.2,3.5-2.6,5.3-4c1.3-1,2.6-2.1,3.8-3.1
      c0.7-0.6,1.4-1.2,2.1-1.8c1.1-0.9,2.2-1.8,3.2-2.7l1.5-1.2c0.5,0.4,1.1,0.9,1.6,1.3c1.4,1.1,2.8,2.2,4.1,3.3
      c0.7,0.6,1.4,1.2,2.1,1.8c0.7,0.6,1.5,1.3,2.2,1.9c2.9,2.4,5.3,4.3,7.7,6.1c0.4,0.3,0.4,0.4,0.3,0.7c-0.1,0.4-0.2,0.7-0.1,0.9
      c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.2c-3,2-6.2,4-9.3,6c-1.2,0.8-2.4,1.5-3.6,2.2h-0.1
      c-0.7,0.4-1.4,0.8-2.1,1.2l-0.5,0.3c-1.3,0.8-2.7,1.7-4.1,2.5c-1.3,0.8-2.5,1.6-3.8,2.4l-0.6,0.4c-1,0.6-1.9,1.2-2.9,1.9
      c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.6-1.7-0.8-2.5c-0.7-2-1.4-4.1-2-6.1
      C853.7,342.4,853.1,340.2,852.7,338.3z M894.2,315.4c-0.5,3.5-1.2,7.1-1.8,10.3l-0.6,1.7c-0.1,0.3-0.2,0.7-0.3,1v0.1
      c-0.7-0.6-1.5-1.3-2.2-1.9l-0.3-0.3c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.7-2c-0.8-0.7-1.6-1.5-2.3-2.3
      l-0.1-0.2c-0.1-0.1-0.3-0.3-0.4-0.4c-0.1-0.2-0.2-0.4-0.2-0.6c0.2-1,0.4-1.9,0.6-2.9v-0.2c0.3-1.4,0.6-2.9,0.8-4.3
      c0.3-1.6,0.4-3.2,0.6-4.7c0.1-0.5,0.1-1,0.2-1.5c0.3-2.7,0.6-5.6,1-9.1l0.1-1.3c0.2-2.3,0.5-4.6,0.7-6.9c0-0.4,0-0.7-0.1-1.1
      c0-0.3-0.1-0.6-0.1-0.9c0-0.7,0.1-1.3,0.2-2v-0.1c0.1-0.7,0.1-1.5,0.2-2.2c0-0.5-0.1-1-0.1-1.5v-0.1c0-0.1,0-0.2-0.1-0.3
      c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1h0.2c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4c1.6,0.2,3.3,0.4,5,0.5
      c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.4-0.7,6.3c-0.3,2.5-0.5,5-0.8,7.6
      C895.9,304.6,895,310.4,894.2,315.4z M894.8,272.1c-1.6-0.2-3.3-0.3-4.9-0.5l-1.8-0.2c-2.2-0.2-4.6-0.4-6.9-0.6
      c-0.8,0-1.5,0-2.3,0.1c-0.5,0-1.1,0.1-1.6,0.1c-0.2,0-0.5-0.1-0.9-0.4c-1.8-1.4-3.2-3.2-4.5-4.9c-0.7-0.9-1.4-1.8-2.2-2.7
      c-1.2-1.3-2.5-2.7-4.2-4.1c-1.2-1-1.2-1-0.8-2.6c0.4-2.2,0.6-4.5,0.8-6.5c0.2-1.8,0.3-3.5,0.4-5.3l0.1-1.3c0.1-1.1,0.1-2.1,0.1-3.2
      l0.1-1.4c0.4,0.1,0.7,0.2,1.1,0.2c1.1,0.2,2.1,0.4,3.1,0.7c2.1,0.6,4.2,1.2,6.3,1.7h0.1c2.5,0.7,5.3,1.5,8.1,2.2
      c2,0.5,4,0.9,6.2,1.3c1,0.2,1.5,0.7,1.7,1.9c0.6,4.1,1.2,8.3,1.8,12.3l0.6,3.8c0.3,2.2,0.7,4.5,1,6.8l0.4,2.8L894.8,272.1z
       M894.1,242.9c-0.3-1.6-0.6-3.2-0.9-4.8v-0.3c-0.2-1.2-0.4-2.3-0.6-3.5c-0.1-0.8-0.3-1.6-0.4-2.4v-0.1c-0.1-0.8-0.2-1.6-0.4-2.4
      c-0.2-1.1-0.4-2.1-0.6-3.2V226c-0.2-1-0.4-1.9-0.5-2.8c-0.2-0.9-0.3-1.7-0.5-2.6v-0.2c-0.2-1-0.3-2-0.5-3.1c-0.2-1-0.4-1.9-0.7-2.7
      c-0.4-1.1,0.2-1.8,1.2-2.5c1.3-1,2.7-2,4-2.9c1.3-1,3-2.2,4.6-3.4c1.7-1.2,3.3-2.5,5-3.8l0.1-0.1c0.7-0.5,1.4-1.1,2.1-1.6
      c0.3,0.2,0.6,0.5,0.9,0.8c0.9,0.7,1.8,1.4,2.6,2.2c1.9,1.8,3.8,3.7,5.6,5.5l0.1,0.1c0.8,0.8,1.6,1.6,2.3,2.3l0.1,0.1
      c0.1,0.1,0.2,0.2,0.3,0.2c2.6,1.4,2.8,3.8,2.6,6.2c-0.3,4.7-0.7,9.9-1.3,16.1c0,0.3-0.2,0.5-0.3,0.6c-0.7,0.3-1.5,0.7-2.2,1.1
      c-3.1,1.5-6.3,3-9.5,4.3c-2.6,1.1-5.3,2.1-7.9,3c-0.8,0.3-1.6,0.6-2.4,0.9c-0.5,0.2-1,0.3-1.5,0.2
      C894.6,243.8,894.2,243.5,894.1,242.9z M919.2,255.5c0.1-0.8,0.1-1.6,0.2-2.3c0.5-4.1,0.8-7.5,1.2-10.7c0.2-2.2,0.4-4.5,0.6-7.1
      c0.1-0.8,0.1-1.6,0.2-2.4c0.2-2.6,0.4-5.3,0.5-8c0.1-2.7,0.2-5.4,0.2-8v-0.1c0-1.1,0-2.3,0.1-3.4c0-0.2,0-0.4,0-0.7v-0.3l0.6,0.1
      c0.7,0.1,1.4,0.3,2.1,0.5c0.2,0,0.3,0.1,0.5,0.1c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.2,0,0.8-0.1,1.2c-0.1,0.2-0.1,0.5-0.2,0.7
      c-0.3,1.6-0.5,3.3-0.8,4.9v0.1c-0.5,3.4-1.1,6.9-1.6,10.3c-0.3,1.9-0.5,3.8-0.6,5.6v0.1c-0.2,1.5-0.3,3.1-0.5,4.6
      c-0.3,2.6-0.8,5.2-1.2,7.7c0,0.9-0.1,1.4-0.2,2c-0.4,2.2-0.7,4.5-1.3,6.6c-0.2,1-0.4,2-0.6,3v0.1c-0.5,2.5-1,5.1-2.1,7.4
      c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5v-0.1c0.1-2.1,0.2-4.2,0.8-6.1C918.8,260,919,257.7,919.2,255.5z M944.1,219
      c-0.1,0-0.2,0-0.2,0c-0.4-0.2-0.9-0.3-1.4-0.4c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.2,0-3.6,0c-0.8,0-1.6,0-2.4,0c-0.6,0-1.3,0-2,0
      c0.2-0.4,0.3-0.7,0.5-1.1c0.5-1.1,0.9-2.1,1.4-3.1c0.9-1.8,1.9-3.7,2.9-5.5l0.2-0.3c0.6-1.1,1.1-2.1,1.7-3.2
      c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2c0.3,0,0.7,0,1,0
      c0,0.4,0,0.9,0,1.3v0.4c0,1.4,0,2.7,0,4.1c-0.1,2.6-0.2,5.2-0.3,7.8l-0.1,1.6C945.3,218.6,944.9,219,944.1,219z M937.7,126.1
      c0.2-1.4,0.4-2.8,0.6-4.2l0.2,1.5c0.3,1.7,0.5,3.3,0.8,5l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5c0.2,1.3,0.3,2.7,0.5,4
      c0.2,2.1,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8l-0.1,0.4c-0.5,2-0.8,4-1.2,6v0.1c-0.2,1.2-0.4,2.4-0.7,3.6
      c-0.3,1.6-0.7,3.3-1,4.9v0.1c-0.3,1.2-0.5,2.4-0.8,3.7c-0.2,0.9-0.3,1.7-0.5,2.7l0.2,0.4c-0.2-0.3-0.4-0.5-0.7-0.8l-0.1-0.1
      c-1.1-1.3-2.2-2.6-3.2-3.9c-0.2-0.2-0.3-0.7-0.2-1.1c0.3-1.7,0.5-3.5,0.8-5.2c0.3-1.7,0.6-3.9,0.9-5.9c0.2-1.2,0.3-2.4,0.4-3.6
      c0.1-0.9,0.2-1.9,0.3-2.8c0.2-1.3,0.4-2.6,0.6-4.1v-0.3c0.1-0.9,0.3-1.8,0.4-2.7c0.1-0.9,0.2-1.8,0.3-2.7l0.1-1.2
      c0-0.1,0-0.1,0-0.2c0.7-1.1,0.6-2.3,0.6-3.4c0-0.8-0.1-1.6,0.1-2.3c0.4-1.4,0.6-2.8,0.7-4.2C937.6,127.3,937.6,126.7,937.7,126.1z
       M914.5,86.9c0.1-1.6,0.3-3.1,0.4-4.7v-0.1c0.1-1.1,0.2-2.3,0.2-3.4c0.1-0.8,0.2-1.6,0.2-2.3v-0.1c0-0.2,0.1-0.4,0.1-0.7
      c0.3,0.3,0.6,0.7,0.9,1c0.9,1.1,1.9,2.1,2.7,3.3c1.6,2,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c0.9,1.6,1.8,3.3,2.7,4.9
      c0.5,0.9,0.9,1.8,1.4,2.7c1.6,2.9,2.6,6.2,3.3,10.4c0.3,1.8,0,3.5-0.3,5.1c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.8-0.8,5.6-1.3,8.5v0.1
      c-0.2,0.9-0.3,1.9-0.5,2.9c-0.1-0.2-0.3-0.3-0.4-0.5c-0.6-1-1.2-2-1.8-3l-0.1-0.2c-1.2-2-2.5-4-3.8-6c-1.1-1.6-2.3-3.3-3.4-4.8
      l-0.2-0.2c-0.6-0.8-1.3-1.7-1.9-2.6c-0.5-0.8-1.1-1.6-1.6-2.3c-0.9-1.3-1.8-2.7-2.8-4c-1.1-1.4-2.3-2.8-3.5-4.1l-0.1-0.1
      c-0.6-0.7-1.3-1.5-2-2.2c-0.1-0.2-0.2-0.5-0.2-0.7c0.2-2.4,0.5-4.8,0.7-7.2C914,92.3,914.2,89.6,914.5,86.9z M869.9,41.3
      L869.9,41.3c1.1,0.5,2.1,1,3.1,1.5c1.6,0.8,3.2,1.5,4.8,2.2c2.8,1.3,5.7,2.6,8.6,4c1.4,0.7,2.7,1.4,4,2.2c1.5,0.8,3,1.7,4.5,2.4
      c3.3,1.5,6.1,3.9,9,7.7c1.3,1.6,2.6,3.3,4,5.1c1,1.3,2.1,2.6,3.1,3.9c0.2,0.3,0.5,0.7,0.7,1c0.4,0.6,0.8,1.2,1.3,1.7
      c0.7,0.2,0.6,0.8,0.5,1.6c0,0.2-0.1,0.4-0.1,0.6c-0.3,2.5-0.5,5.1-0.8,7.6v0.1c-0.1,1.1-0.2,2.1-0.3,3.2l-0.6,6.2
      c-0.3,2.9-0.6,5.9-0.9,8.8c-0.1,0.6-0.1,1.2-0.1,1.8v0.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.8-0.6-1.6-1.3-2.5-1.9
      c-0.6-0.4-1.1-0.8-1.7-1.2l-0.1-0.1c-2.1-1.5-4.3-3.1-6.5-4.6c-5.5-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7
      c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7c-0.1,0-0.2-0.3-0.2-0.5c-0.1-1-0.1-1.9,0-2.7L872,73c0.2-3.8,0.4-7.7,0.6-11.6
      c0.2-2.4-0.2-4.8-0.6-7.1v-0.1c-0.1-0.4-0.1-0.8-0.2-1.1c-0.4-2.5-0.9-4.9-1.5-7.3l-0.2-0.8c-0.2-0.8-0.4-1.6-0.6-2.3
      c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.1-0.6C869.3,41,869.6,41.2,869.9,41.3z M828.3,31.4c0.3,0,0.6,0,0.8,0
      c0.4,0,0.7,0,1.1,0c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2c1.4,0.1,2.9,0.2,4.3,0.3c1.5,0.1,3,0.2,4.5,0.2
      c1.7,0.1,3.3,0.2,4.8,0.3c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1c1.5,0.6,3,1.2,4.5,1.9c0.4,0.2,0.8,0.7,1,1.3
      c0.5,1.7,1,3.5,1.4,5.2c1.2,5,2.2,9.5,2.4,14.1c0.1,3-0.1,6.1-0.3,9.1v0.2c-0.1,1.2-0.2,2.5-0.2,3.7c-0.1,1.2-0.1,2.5-0.2,3.7
      l-0.1,1.3c-5.9-1.4-11.8-2.9-17.6-4.4h-0.2c-7.6-2-15.5-4-23.4-5.8V31.4z M828.4,70.2c0-0.3,0-0.6,0.1-0.9l41,10.3l-2.3,41.1
      c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.3-0.3-2.6-0.6-4-0.9c-2.9-0.6-5.7-1.1-8.1-1.5c-1.8-0.3-3.9-0.7-5.9-1
      c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3c-0.9-0.1-1.7-0.1-2.6-0.2c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9
      c0-2-0.1-4-0.1-5.8v-0.1c0-1.8-0.1-3.6-0.1-5.4c0-1.4,0-2.8,0-4.1c0-1.2,0-2.5,0-3.7c0-1,0-2.1-0.1-3.1c0-1-0.1-2.2-0.1-3.3
      C828.3,72.1,828.4,71.1,828.4,70.2z M827.4,184.4c0.3,0,0.6,0.1,1,0.2l0.2,0.1c1.1,0.3,2.2,0.7,3.3,1v0.4c1.3,0.4,2.8,0.9,4.2,1.3
      c0.5,0.1,0.6,0.3,0.5,0.8c-0.4,4.8-0.8,9.6-1.2,14.3c-0.2,2.3-0.4,4.6-0.5,6.9v0.1c-0.1,1.3-0.2,2.7-0.3,4c0,0.1,0,0.2-0.1,0.4
      l-1.5-0.2c-0.8-0.1-1.5-0.2-2.2-0.3c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0-1.3,0-1.9,0h-0.1c-1.6,0-3.3,0.1-5,0.1c-0.9,0-1.7,0.1-2.6,0.2
      c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1c-0.1-1.2-0.1-2.3-0.2-3.4c-0.2-1.8-0.3-3.7-0.5-5.5v-0.1
      c-0.2-2.3-0.4-4.7-0.6-7c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6c3.3-1.1,6.2-2,8.8-2.8
      C827.3,184.4,827.3,184.4,827.4,184.4z M785.9,48.2c0.3-1.7,0.7-3.5,1.1-5.3c0.2-1.1,0.6-2.2,1.1-3.5c0.1-0.3,0.5-0.7,0.9-0.8
      c3.8-1.6,7.6-3.2,10.8-4.6c3.2-1.4,6.8-2.1,11-2.2c2.5-0.1,5.1-0.2,7.6-0.4h0.1c1.6-0.1,3-0.2,4.4-0.2c0.3,0.2,0.7,0.1,1.1,0.1
      c0.3,0,0.6,0,0.9,0h0.2c0.3,0,0.6,0,0.9,0v35.8c-7.9,1.8-15.8,3.8-23.5,5.8h-0.1c-5.7,1.5-11.6,3-17.4,4.4c0-0.2-0.1-0.5-0.1-0.7
      c-0.1-0.8-0.2-1.6-0.2-2.4c-0.1-1.4-0.1-2.9-0.2-4.3c0-1.4-0.1-2.8-0.2-4.3V65c-0.2-3.6-0.4-7.3,0.4-10.9c0.4-1.9,0.8-3.7,1.1-5.6
      L785.9,48.2z M741.6,72.3c0.7-0.9,1.4-1.8,2-2.7c1.1-1.5,2.2-3,3.4-4.4c3-3.6,5.7-6.7,8.4-9.4c0.6-0.6,1.5-1.1,2.4-1.6
      c0.4-0.2,0.8-0.4,1.2-0.6c5.1-3.1,10.4-5.5,14.9-7.6c2.7-1.2,5.4-2.5,8-3.8l0.3-0.2c1.1-0.5,2.2-1.1,3.3-1.6c0.1,0,0.1-0.1,0.2-0.1
      l-0.2,0.5c-0.1,0.6-0.3,1.2-0.4,1.7c-0.2,0.6-0.3,1.2-0.5,1.8l-0.1,0.2c-0.3,1.3-0.7,2.7-1,4.1c-0.2,1.1-0.4,2.2-0.6,3.2
      c-0.1,0.9-0.3,2-0.5,2.9c-0.6,3-0.5,6-0.3,8.9c0,0.4,0,0.9,0.1,1.3c0.1,1.8,0.2,3.7,0.3,5.5c0.1,1.5,0.2,2.9,0.2,4.4
      c0,1,0,1.9,0,2.8c0,0.2-0.2,0.5-0.3,0.6c-1.5,0.8-3,1.7-4.4,2.5l-0.1,0.1c-2.2,1.2-4.4,2.5-6.5,3.7c-1.8,1.1-3.6,2.2-5.3,3.4
      c-0.9,0.6-1.7,1.1-2.6,1.7c-0.7,0.5-1.5,0.9-2.2,1.4l-0.4,0.3c-1.6,1-3.4,2.1-5.1,3.3c-2,1.3-4,2.8-5.9,4.2l-0.6,0.4
      c-1.3,1-2.6,1.9-4,2.9h-0.1c-0.5,0.3-0.9,0.7-1.4,1l-0.1-0.9c-0.1-1.1-0.2-2.1-0.3-3.1c-0.2-2.2-0.5-4.5-0.7-6.7
      c-0.3-2.9-0.6-5.7-0.9-8.6c-0.1-1.3-0.2-2.6-0.3-3.9v-0.1c-0.1-0.9-0.2-1.9-0.2-2.8c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8
      C740.6,73.8,740.9,73.2,741.6,72.3z M719.8,106.1c0.3-0.5,0.5-1.1,0.8-1.6l0.1-0.1c0.8-1.7,1.6-3.4,2.5-5.1c2-3.5,4-6.7,6.1-10.1
      c1.6-2.6,3.2-5.1,5-7.5c1.1-1.5,2.3-2.9,3.5-4.4c0.4-0.5,0.9-1.1,1.3-1.6l0.2,1.9c0.1,1.4,0.2,2.8,0.4,4.2c0.2,1.9,0.4,3.7,0.5,5.6
      l0.1,0.7c0.1,1.4,0.3,2.9,0.4,4.3c0.3,3.5,0.6,6.7,0.9,10c-0.1,0.1-0.1,0.3-0.2,0.4c-0.9,1.1-1.9,2.2-2.6,3.2l-0.1,0.1
      c-1.6,1.9-3.2,3.8-4.7,5.7c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.5-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7c-0.2,0.2-0.3,0.5-0.5,0.8
      c-0.1-0.8-0.2-1.6-0.4-2.4l-0.2-1.5c-0.5-3.6-1-6.9-1.6-10.3C716.8,114.8,717.6,110.6,719.8,106.1z M718,172.5L718,172.5v-0.2
      c-0.1-0.6-0.2-1.2-0.3-1.8c-0.2-0.9-0.4-1.9-0.6-2.8v-0.1c-0.3-1.6-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4v-0.1
      c-0.2-1.2-0.4-2.5-0.7-3.7l-0.1-0.4c-0.3-1.6-0.6-3.3-1.2-4.9c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.1
      c0.3-2.6,0.6-5.3,1-8c0.4-2.9,0.9-5.9,1.5-8.7c0.2,1.2,0.4,2.3,0.5,3.5c0.3,1.7,0.5,3.5,0.8,5.2l0.4,2.6c0,0.1,0,0.2,0.1,0.2
      c0,0,0,0.1,0,0.2c-0.4,1.3-0.1,2.6,0.2,3.9c0.2,0.7,0.3,1.4,0.4,2.1c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.7,1,7
      c0.3,1.9,0.6,3.8,0.9,5.7c0.4,2.4,0.7,5,1,7.6c0,0.3-0.1,0.8-0.4,1.2c-1,1.2-2,2.4-3.1,3.7l-0.1,0.2
      C718.3,172.1,718.1,172.3,718,172.5z M699.1,198.2c1.3-1.7,2.5-3.4,3.8-5.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.3,0.5-0.7,0.8-0.9
      l0.1-0.1c0.6-0.5,1.3-1.1,2-1.3c3.6-1,7.4-2,11.5-3.1c0.4-0.1,0.6-0.1,0.8-0.1c0.2,0,0.2,0,0.5,1.1c0,0.5,0.1,0.9,0.2,1.3
      c0.2,0.6,0.4,1.3,0.5,1.9c0.1,0.8,0.1,1.4-0.1,1.8c-0.3,0.6-0.8,1.1-1.3,1.6c-0.2,0.3-0.5,0.5-0.7,0.8l-0.7,0.8
      c-0.9,1.1-1.9,2.2-2.7,3.4c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1c-1.2,0-2.4-0.1-3.5-0.2h-0.1c-1.4-0.1-2.9-0.2-4.3-0.2
      c-1.6,0-2.9,0.1-4.2,0.4c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0.2L699.1,198.2z M705,231.5
      l-0.7,0.3l-0.7-2.2c-0.6-1.9-1.4-4.1-2.1-6.2c-0.3-0.9-0.7-1.8-1-2.7c-0.3-0.7-0.6-1.6-0.9-2.4c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1
      c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2c0,0,0.2-0.2,0.3-0.2c1.8-0.4,3.7-0.9,5.5-1.3l1.8-0.2c0.1,0,0.2,0,0.2-0.1
      c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5c0.1,1.6,0.1,3.2,0.2,4.7v0.2c0,2,0.1,4,0.2,6c0.1,1.8,0.4,3.7,0.6,5.4v0.1
      c0.1,1.1,0.3,2.2,0.4,3.2c0.2,2,0.4,4,0.4,6c0,0.1-0.3,0.3-0.6,0.4C706.6,230.9,705.8,231.2,705,231.5z M706.8,247.1l-1.5-12.8
      l2.9-1.5l1.1,13.5L706.8,247.1z M720.4,270c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2c-0.4-0.3-0.8-0.6-1.2-1
      c-0.6-0.5-1.2-1-1.8-1.5c-1.5-1.2-3.1-2.5-4.5-3.8c-1.4-1.3-2.3-2.4-2.8-3.5c-0.4-0.8-0.5-1.7-0.6-2.7c0-0.4-0.1-0.9-0.2-1.4
      c-0.3-1.5-0.5-2.7-0.7-3.9l-0.1-0.4c-0.3-2-0.7-3.9-1-5.9c-0.1-0.5-0.1-1-0.1-1.4c0-0.1,0-0.3,0-0.4c0.2-0.1,0.4-0.2,0.6-0.2h0.1
      c0.6-0.3,1.3-0.5,1.8-0.8c0.4-0.2,0.6-0.2,1,0.3l2.3,2.8c2.4,2.9,4.8,5.8,7.2,8.8c0.2,0.2,0.3,0.6,0.3,0.9
      C720.1,264.1,720.1,266.7,720.4,270z M718.7,255.1l-0.1-0.1c-1.7-2.1-3.5-4.3-5.5-6.3c-1.4-1.4-1.5-3.2-1.6-5.2
      c0-0.4,0-0.7-0.1-1.1c4.1-1,8.3-1.1,11.9-1.1c0.7,0,1.5,0,2.2,0c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2c-0.4,0-0.8,0.1-1.2,0.1h-0.4
      c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4C720.6,257.4,719.7,256.2,718.7,255.1z M738.2,279.5c-0.1,0.1-0.4,0.2-0.7,0.1
      c-1.8-0.2-3.7-0.4-5.5-0.6l-0.8-0.1c-2.1-0.2-4.1-0.5-6.2-0.7c-0.6-0.1-1.1-0.2-1.6-0.3c-0.4-0.1-0.6-0.3-0.5-1v-0.3
      c0.1-1.8,0.2-3.7,0.1-5.5c0-1.3-0.1-2.7-0.2-4v-0.2c0-0.5-0.1-1.1-0.1-1.6c0-0.9-0.1-1.8-0.1-2.7v-1.1l9.6-0.9
      c0,0.1,0.1,0.3,0.1,0.4c0.1,0.5,0.2,0.9,0.4,1.4c0.8,2.3,1.6,4.5,2.3,6.8l0.1,0.2c0.7,2,1.4,3.9,2,5.9c0.4,1.2,0.8,2.4,1.1,3.5
      C738.3,279.1,738.3,279.4,738.2,279.5z M739,262c-0.1-0.7-0.4-1.4-0.6-2.1c-0.2-0.7-0.5-1.5-0.6-2.2c-0.3-2.1-0.5-4.3-0.7-6.3
      l-0.1-0.1c-0.1-0.8-0.2-1.7-0.2-2.5c-0.1-0.8-0.2-1.6-0.2-2.3l-0.1-0.9c-0.2-1.4-0.3-2.9-0.4-4.3c-0.1-1.1-0.2-2.2-0.3-3.4v-0.1
      c0-0.4-0.1-0.8-0.1-1.2h0.1h0.1c0.4,0.1,0.9,0.3,1.2,0.5c1.1,0.5,2.2,1.1,3.2,1.6c2.1,1.1,4.2,2.1,6.4,3.1c2.5,1.1,5.1,1.9,7.6,2.8
      l0.4,0.1c0.5,0.2,1.1,0.4,1.6,0.5c0.8,0.3,1.6,0.4,2.5,0.6c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.1c-0.1,1-0.3,2.1-0.5,3.2
      c-0.3,1.7-0.5,3.4-0.8,5.1l-0.1,0.7c-0.4,2.2-0.7,4.5-1.1,6.8c-0.2,1.5-0.4,3-0.6,4.4c-0.2,1.5-0.4,3.1-0.7,4.8
      c-0.1,0.4-0.2,0.4-0.2,0.4c-0.2,0-0.4-0.1-0.6-0.2h-0.1c-1.3-0.7-2.6-1.5-3.9-2.3l-0.1-0.1c-0.8-0.5-1.5-1-2.3-1.5h-0.1
      c-1-0.7-2.1-1.3-3.1-2c-1.2-0.7-2.3-1.4-3.7-2.1l-0.2-0.1c-0.5-0.3-1.1-0.6-1.6-0.9C739.3,263,739.1,262.7,739,262z M750.6,304.5
      l-0.1-0.2c-1.3-2.5-2.8-5.4-4.1-8.2c-0.6-1.3-1.2-2.7-1.8-4.1c-0.7-1.6-1.3-3.3-2.2-4.9c-1.3-2.5-1.6-5.2-1.9-7.9v-0.1
      c0-0.3-0.1-0.7-0.1-1c-0.3-3-0.6-6-0.9-9.2c-0.1-1.1-0.2-2.3-0.3-3.4l15.8,9.7c-0.7,3.7-1.3,7.6-0.5,11.8c0.4,2.4,0.7,4.9,0.9,7.3
      c0.1,1.4,0.3,2.9,0.5,4.4c0.7,5.5,1.4,10.9,2.2,16.2c0.3,2.3,0.8,4.7,1.2,6.9v0.1c0.1,0.4,0.2,0.8,0.2,1.3
      c-0.3-0.6-0.5-1.1-0.8-1.7l-0.2-0.5c-1.2-2.8-2.5-5.7-3.9-8.5C753.3,309.8,751.9,307.1,750.6,304.5z M762.1,426.4L762.1,426.4
      c-0.4,1-0.8,1.8-1.2,2.7c-0.1,0.2-0.2,0.4-0.3,0.6v0.1c-7.7-7.1-13.9-13.3-19.4-19.5c0.2-0.3,0.3-0.5,0.5-0.8
      c0.5-0.8,1-1.5,1.5-2.3c0.7-0.9,1.3-1.9,2.1-2.9l0.2-0.3c0.5-0.8,1.1-1.5,1.6-2.3l0.5-0.7c0.7-1,1.4-2,2-3c1.4-2.4,2.8-4.8,4.1-7.2
      l0.1-0.2c0.7-1,1.3-2,1.9-3.1c0.2,0.2,0.5,0.4,0.7,0.6l0.1,0.1c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1
      c1.8,1.3,3.7,2.6,5.5,3.8l1.4,0.9c0.4,0.3,0.5,0.7,0.4,1.4l-0.1,0.3c-0.3,1.4-0.7,2.9-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3
      c-0.5,1.2-1.2,2.5-1.8,3.7l-0.1,0.1c-0.4,0.9-0.9,1.7-1.3,2.6C763.5,423.4,762.8,424.9,762.1,426.4z M805.5,412.3
      c-0.4,12.1-0.8,24.6-1.9,37.1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.8-0.2-1.5-0.4-2.2-0.7c-1-0.4-2.1-0.8-3.1-1.2c-0.7-0.3-1.3-0.5-2-0.8
      c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-2.6-1-4-1.5c-2.5-1-5.2-2.2-8.2-3.5c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.5-1.7-5.2-2.6l-0.3-0.2
      c-0.8-0.4-1.5-0.8-2.3-1.2c-0.2-0.1-0.5-0.2-0.7-0.4l-0.1-0.1c-0.7-0.4-1.4-0.7-2.2-1c-0.3-0.1-0.4-0.2-0.4-0.3
      c-0.1,0.2-0.1,0,0-0.2c1.3-2.7,2.5-5.5,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.7-1.5,1-2.1
      c0.6-1.3,1.2-2.6,1.8-3.9c0.6-1.7,1.2-3.4,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6c0.1-0.2,0.1-0.4,0.1-0.6c0.4,0.2,0.8,0.3,1.2,0.4
      c0.4,0.1,0.9,0.3,1.3,0.4c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4
      c1.8,0.2,3.5,0.3,5.2,0.5c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1c0.4,0.1,0.8,0.1,1.3,0.2C805.6,408.8,805.6,410.6,805.5,412.3z
       M805.7,403.4c0,0.3,0,0.7,0,1c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8.1-1c-1.6-0.2-3.2-0.3-4.7-0.5h-0.1
      c-1.5-0.1-3.1-0.3-4.6-0.4c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5c0.1-0.8,0.1-1.6,0.1-2.4c0-0.5,0-1,0-1.5
      c0.1-1.1,0.1-2.3,0.2-3.4c0.2-3.7,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.6c0.2-2.5,0.5-5.2,0.7-7.7c0.1-1.9,0.2-3.8,0.3-5.5v-0.2
      c0.1-1.1,0.1-2.3,0.2-3.4c0.1-1.7,0.2-3.5,0.4-5.2c0-0.6,0.1-1.2,0.1-1.8c0.6,0.3,1.1,0.7,1.6,1c1.5,0.9,3,1.8,4.6,2.8
      c0.9,0.6,1.8,1.1,2.7,1.7c2.5,1.5,5,3.1,7.4,4.7c2.5,1.8,5.3,2.1,8.1,2.5h0.2c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0.1,0.6,0.1
      s0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6c-0.1,1.3-0.2,2.6-0.2,3.9v0.3c-0.1,1.2-0.1,2.5-0.2,3.7
      c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3c0,1.5,0.1,3.1,0.1,4.7c0,1.6,0.1,3.2,0,4.7c-0.1,2.8-0.2,5.6-0.3,8.3v0.1
      c-0.1,1.7-0.2,3.4-0.2,5.1C805.7,401.5,805.7,402.4,805.7,403.4z M848.4,449.2c-0.1,0.1-0.2,0.1-0.4,0.1c-0.6,0-1.3,0.1-1.9,0.2
      h-0.4v-0.2h-0.4c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5c-1.6,0.1-3.2,0.1-4.7,0.1c-0.7,0-1.5,0-2.2,0
      c-0.8,0-1.5,0-2.3,0.1c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2c-2.6-0.2-5.3-0.3-7.9-0.5l-2-0.1c-0.1,0-0.2,0-0.2,0
      c-0.3,0-0.6,0-0.8-0.1c-0.2-0.1-0.4-0.5-0.4-0.5c0.1-2.4,0.3-4.9,0.5-7.3c0.1-1.7,0.2-3.3,0.3-5c0.3-4,0.5-7.9,0.7-11.7
      c0.1-1.3,0.1-2.7,0.1-4v-0.1c0-1.1,0-2.1,0.1-3.1c0-1.4,0.1-2.7,0.2-4.1v-0.2c0-0.9,0.1-1.9,0.1-2.9c0.1-1.2,0.1-2.4,0.1-3.7
      c0-0.4,0.1-0.5,0.1-0.6c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1c3.2,0.1,6.5,0.2,9.7,0.5c2.6,0.2,5.1,0.2,7.6,0
      c3.2-0.3,6.5-0.4,9.7-0.5h0.2c1.6,0,3.2-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7c0.1,3.9,0.2,8,0.4,12.2c0.1,3.3,0.3,6.6,0.5,9.8
      l0.2,2.9c0.2,4.6,0.5,8.7,0.7,12.9c0.1,1.4,0.2,2.8,0.4,4C848.5,448.9,848.5,449.1,848.4,449.2z M847.3,358.5l0.6-0.1l0.1-0.3v0.1
      c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2c2-0.2,4-0.5,5.8-1.5c2-1.2,4-2.4,6-3.7l0.5-0.3c0.3-0.2,0.7-0.4,1-0.6
      c2.4-1.5,4.9-3.1,7.4-4.7l2.8-1.8l0.1,1.2c0.1,1.2,0.1,2.4,0.2,3.6c0.3,3.5,0.5,7,0.8,10.8l0.3,4.3c0.2,2.4,0.4,4.7,0.6,7.1
      c0.1,1.2,0.2,2.4,0.3,3.6v0.1c0.2,2.3,0.4,4.7,0.6,7c0.2,3.4,0.3,6.9,0.4,10.2c0,1.1,0.1,2.2,0.1,3.3c0,0.3,0,0.6,0.1,1
      c0.1,0.8,0.2,1.6-0.1,2c-0.3,0.4-0.9,0.6-1.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.5c-1.7,0.9-3.6,1.1-5.5,1.3c-1.8,0.2-3.7,0.4-5.3,0.6
      c-1.1,0.1-2.2,0.3-3.4,0.4c-0.9,0.1-1.8,0.2-2.7,0.3h-0.1c-1,0.1-2.1,0.2-3.2,0.3c-1.8,0.2-3.5,0.4-5.3,0.7l-2.1,0.3L847.3,358.5z
       M890.9,432.1c-1.6,0.8-3.2,1.6-4.8,2.3l-0.2,0.1c-1.3,0.6-2.6,1.3-3.9,1.9c-0.8,0.4-1.6,0.8-2.5,1.2l-0.4,0.2
      c-3,1.5-6.1,3-9.2,4.3c-1.7,0.8-3.4,1.4-5.1,2c-0.9,0.3-1.8,0.7-2.7,1c-0.8,0.3-1.6,0.6-2.3,1l-0.3,0.1c-1.3,0.5-2.5,1.1-3.8,1.5
      c-0.9,0.3-1.9,0.6-2.8,0.9l-0.2,0.1c-0.4,0.1-0.9,0.3-1.3,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.5
      c-0.2-4.3-0.5-7.9-0.7-11.4c-0.1-1.1-0.2-2.2-0.2-3.3v-0.1c-0.1-1.7-0.3-3.4-0.3-5.1c-0.1-1.3-0.1-2.8-0.1-4c0-0.8,0-1.7,0-2.5
      c0-0.7-0.1-1.5-0.1-2.2s-0.1-1.4-0.1-2.1c0-1,0-2.1-0.1-3.1v-0.2c0-1.3-0.1-2.7-0.1-4.1c0-1-0.1-2-0.2-2.9v-0.1
      c0-0.3,0-0.6-0.1-0.9l3.7-0.3c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.2-0.2,3.2-0.3c1.1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9
      c0.7-0.1,1.4-0.4,2.1-0.7h0.1c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.7-0.3,1-0.4c0.2,0.6,0.4,1.1,0.5,1.7l0.1,0.3
      c0.5,1.7,1.1,3.5,1.7,5.3c0.5,1.5,1.2,3,2.1,5c0.5,1.2,1.1,2.3,1.7,3.4c0.5,1,1,2.1,1.5,3.2c0.8,1.7,1.6,3.5,2.3,5.2l0.2,0.4
      c0.4,0.8,0.8,1.7,1.1,2.5l0.1,0.3c0.2,0.5,0.5,1.1,0.8,1.7c0.1,0.3,0.1,0.3,0.1,0.4C891.4,431.9,891,432.1,890.9,432.1z M913.2,410
      l0.2,0.3c-5.4,6.2-11.4,12.2-19.4,19.6c-0.2-0.3-0.3-0.6-0.5-1l-0.3-0.5c-0.6-1.2-1.2-2.3-1.7-3.5c-1.5-3.2-2.6-5.8-3.6-8.1
      c-1.9-4.6-3.8-9-5.5-13.3c-0.4-0.8-0.5-1.5-0.4-2.1c0.1-0.5,0.5-1,0.9-1.3c1-0.7,2-1.4,3-2c1.6-1,3.3-2.1,4.8-3.3
      c1.8-1.4,3.5-3,5.2-4.6c0.7-0.7,1.5-1.4,2.2-2.1c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1C902.7,395.7,908,402.9,913.2,410z M914.7,266.8
      c-0.1,1.2-0.2,2.4-0.3,3.5l-0.1,0.8c-0.2,2.4-0.5,5.1-0.7,7.8c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6l-0.2,0.4
      c-0.3,0.7-0.6,1.5-1,2.2l-0.1,0.2c-0.9,2.1-1.8,4.4-2.9,6.5c-0.9,1.8-1.9,3.7-2.7,5.3v0.1c-0.5,1-1.1,2.1-1.7,3.1
      c-0.8,1.5-1.5,3-2.2,4.5c-0.8,1.7-1.6,3.4-2.4,5.1l0.2,0.6c-0.7,1.6-1.4,3.2-2.2,4.7c-0.7,1.5-1.4,3.1-2.3,4.7
      c0.2-1.2,0.4-2.5,0.6-3.7c0.2-1.3,0.5-2.5,0.7-3.8v-0.2c0.5-2.7,1-5.5,1.4-8.3c0.4-2.5,0.6-5.1,0.8-7.6c0.1-1.5,0.3-3,0.4-4.5
      c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4c-0.1-0.6-0.2-1.3-0.3-1.9v-0.1c0-0.1,0-0.2,0-0.3l2.2-1.4
      c1.6-1,3.2-2,4.8-3c0.8-0.5,1.6-1,2.4-1.4c0.8-0.4,1.7-1,2.5-1.5c1-0.6,1.9-1.2,2.9-1.8l0.3-0.2c0.2-0.1,0.3-0.2,0.5-0.3
      L914.7,266.8z M912.8,264.7c-2.5,1.3-4.9,2.8-7.3,4.2l-0.9,0.6c-0.8,0.5-1.7,1.1-2.5,1.6c-0.6,0.4-1.2,0.7-1.7,1.1
      c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2-0.4c-0.2-1.8-0.5-3.6-0.8-5.3l-0.3-2c-0.2-1.1-0.3-2.1-0.5-3.2v-0.2
      c-0.3-2.3-0.7-4.7-1.1-7.1c-0.3-1.8-0.6-3.6-1-5.4c-0.2-0.9-0.3-1.8-0.5-2.7c0-0.1,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3
      c1.1-0.2,2.1-0.5,3.1-0.8c4.4-1.3,8.7-3.1,12.8-5.1c0.6-0.3,1.1-0.6,1.7-0.9l0.3-0.2c1.2-0.6,2.4-1.2,3.6-1.8
      c0.4-0.2,0.8-0.3,1.2-0.5l0.2-0.1l-0.2,2.4c-0.2,1.9-0.3,3.8-0.5,5.6c-0.1,1-0.2,2-0.3,3c-0.2,2.7-0.5,5.5-0.8,8.3
      c-0.1,0.7-0.3,1.3-0.5,2c-0.3,0.8-0.5,1.6-0.6,2.4C915.9,262.6,914.7,263.6,912.8,264.7z M931.7,262.7c0,1.2,0,2.4,0,3.6
      c0,1.1,0,2.1,0,3.2v0.5c0,2.1-0.1,4.4-0.1,6.7c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9h-0.4c-1.6,0.2-3.4,0.4-5.1,0.7
      c-0.5,0.1-0.8,0-0.9-0.1c-0.1,0-0.1-0.2,0-0.5c0.4-1.2,0.8-2.5,1.3-3.7V275c0.3-0.9,0.7-1.8,1-2.8c0.9-2.7,1.8-5.4,2.7-8.2l1.1-3.4
      l9.3,0.7V262.7z M932.1,259c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1c-0.1,0-0.2,0-0.3-0.1l2.9-16.9c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0
      c3.7,0,7.9,0.1,12.1,1.1l-0.1,0.8c-0.1,1.1-0.2,2.3-0.3,3.4c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3
      c-2,2.3-4.1,4.6-5.8,6.5C932.6,258.8,932.3,259,932.1,259z M947.4,249.9c0,0.3,0,0.7,0,1c-0.3,1.6-0.6,3.2-0.9,4.8
      s-0.6,3.2-0.9,4.8c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.8-0.4,1.8-0.9,2.5c-1.4,2.1-3.5,3.8-5.4,5.3
      c-0.8,0.6-1.6,1.2-2.3,1.9c-0.6,0.5-1.2,1.1-1.9,1.6l-0.4,0.3c0-0.5,0-1.1,0-1.6c0-1.8,0-3.4-0.1-5c0-1.2,0-2.4-0.1-3.7v-0.2
      c0-1.1-0.1-2.3-0.1-3.4c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8l0.1-0.1c1.4-1.6,2.7-3,3.9-4.5c0.6-0.7,1.1-1.5,1.6-2.2
      l0.1-0.1c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.4,0.5-0.5,0.7-0.5s0.3,0.1,0.6,0.2c0.5,0.3,1.1,0.5,1.7,0.8c0.2,0.1,0.4,0.1,0.6,0.2
      C947.4,249.8,947.4,249.8,947.4,249.9z M947.7,247l-2.5-0.7l1.1-13.5l2.9,1.4L947.7,247z M959.2,204.7c-0.1,1.2-0.3,2.1-0.6,2.9
      c-0.6,1.7-1.2,3.4-1.8,5.1v0.1c-0.5,1.4-1,2.8-1.5,4.1c-0.7,1.9-1.4,3.9-2,5.9l-0.3,0.8c-0.4,1.1-0.8,2.2-1.2,3.4
      c-0.4,1.2-0.9,2.4-1.3,3.6l-0.4,1.3l-3-1.2c-0.5-0.1-0.6-0.3-0.6-0.7l0.1-0.5c0.5-5,1.1-10.2,1.5-15.4c0.1-1.6,0.1-3.3,0.1-4.9
      c0-0.9,0-1.9,0-2.8c0-0.7,0.1-1.5,0.1-2.3V204c0-0.5,0.1-0.9,0.1-1.4c0-0.4,0.2-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0
      c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.2,0.3,1.9,0.5c0.8,0.2,1.5,0.4,2.3,0.5C959.2,203.9,959.3,204.3,959.2,204.7z"
      />
      <path
        d="M629.5,438.5l-0.3-0.2c-2-1.4-4-2.8-6.1-4.1c-3-1.9-6.2-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.1-1.7-4.6-2.5
      c-0.8-0.4-1.5-0.8-2.3-1.2l-1.3-0.7c-2.3-1.3-4.7-2.6-7-3.9c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6
      c-1.4-2-2.8-4-4.2-6l-0.3-0.4c-0.6-0.9-1.3-1.9-1.9-2.8c-0.6-0.8-1.2-1.7-1.7-2.4c-0.3-0.4-0.7-0.9-1-1.3v-0.1
      c-0.7-0.9-1.4-1.9-2-2.9c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5V384
      c0-0.6,0-1.2-0.1-1.8c-0.1-1.3-0.1-2.6-0.1-3.9c0.1-2,0.2-4.1,0.3-6.1v-0.1c0.1-1.8,0.2-3.8,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4
      c0-2.5,0-5-0.1-7.4v-0.4c0-0.9,0-1.7,0-2.6c0-0.7,0-1.4,0-2.1V340c0-1,0-2,0-3c0-0.4,0-0.8,0-1.2v-0.5c0-1.5,0-3.1,0-4.7
      c0-2.2,0.1-4.4,0.2-6.5c0.1-2,0.1-4.1,0.2-6.1v-0.4c0.1-3.2,0.1-6.1,1.7-9c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5,3.3-7.5l0.1-0.3
      c0.3-0.6,0.5-1.3,0.8-1.9c0.3-0.7,0.5-1.3,0.8-2v-0.1c0.3-0.7,0.5-1.4,0.9-2.1c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1
      c0.4,0,0.7,0,1.1-0.1c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.1,7-6,9.9-8.7
      c1.3-1.2,2.9-2.6,2.9-5c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6c0.2-1,0.4-2,0.5-3v-0.2c0.3-1.8,0.7-3.6,1-5.4
      c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2c0.4-3.2,0.8-6.5,1.1-9.4c0-0.4,0.1-0.8,0.1-1.2c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9
      v-0.1c0.3-0.7,0.6-1.4,0.8-2.1c1.5-4,2.7-7.3,3.7-10.3l0.5-1.3c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5
      c0.4-1.1,0.8-2.4,0.2-3.8c-1-1-1.5-1.9-2.2-2.8c-2.3-3.3-4.7-6.6-6.7-9.4c-0.9-1.3-1.8-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.8,0.4-1.7,0.5-2.5l0.1-0.3
      c0.3-1.4,0.6-2.7,0.9-4.1c0.2-0.8,0.3-1.6,0.5-2.3v-0.1c0.2-0.8,0.3-1.6,0.5-2.4c0.3-1.4,0.6-2.7,0.9-4.1v-0.1c0.5-2.3,1-4.7,1.5-7
      c0.3-1.4,0.5-2.8,0.8-4.3c0.2-1.4,0.5-3,0.8-4.4c0.1-0.5,0.2-1,0.3-1.6v-0.1c0.5-2.1,1-4.2,0.7-6.4c-0.3-3.1-0.7-7.2-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-1-0.4-1.9-0.6-2.8c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.2-2.2-1.9-3.5
      c-0.7-1.2-1.4-2.6-2.2-4c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-0.9-1.4-1.4-2c-0.4-0.5-0.8-1.1-1.1-1.6c-0.5-0.7-0.9-1.4-1.3-2.1
      l-0.1-0.1c-0.8-1.3-1.6-2.6-2.6-3.8c-1-1.3-2-2.5-3-3.6c-0.9-1-1.8-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.8-3.8v-0.1
      c-0.7-1-1.4-2.1-2.2-3.1c-0.6-0.8-1.2-1.5-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.8-6-8.2-8.1c-2.5-1.5-5.2-3-7.9-4.3
      c-2.5-1.2-5-2.3-7.5-3.5c-2-0.9-4.3-1.9-6.5-3c-2.2-1-4.3-2.1-6.4-3.2c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1
      c-2-0.4-4.1-0.5-6-0.6h-0.3c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3
      c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1
      c-3.3,0.2-6.7,0.5-9.8,2.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4
      c-2,0.9-4.1,2-6,3c-1.3,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.8,1.8-5.6,2.6c-1.9,0.9-4,1.8-5.9,2.8c-1,0.5-1.9,1-2.9,1.6
      c-1,0.6-2,1.1-3,1.6c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1-1.2,1.5c-0.4,0.5-0.8,1.1-1.2,1.6c-0.6,0.7-1.2,1.4-1.8,2.1
      c-0.9,1-1.8,2.1-2.6,3.2c-1.1,1.4-2.2,2.9-3.1,4.2l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.1-2.6,3.2v0.1c-0.8,1-1.8,2.2-2.7,3.4
      c-0.7,0.9-1.3,1.8-1.8,2.7c-0.3,0.4-0.6,0.9-0.8,1.3c-0.6,0.9-1.2,1.8-1.7,2.6c-1.2,1.9-2.5,3.8-3.7,5.8c-1.9,3.3-3.7,6.9-5.2,9.9
      c-1,2-1.7,3.7-2.1,5.3c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10-2.3,14.3c-0.3,2.2-0.6,4.6-0.8,6.6c-0.2,1.5-0.3,3.1-0.5,4.7
      c-0.3,2.7-0.2,5.4,0.4,8.3c0.3,1.2,0.5,2.5,0.8,3.7c0.4,2.1,0.9,4.2,1.3,6.3c0.2,1.2,0.5,2.5,0.6,3.6v0.1c0.2,1.3,0.4,2.6,0.7,3.8
      c0.3,1.5,0.6,3,1,4.5c0.3,1.2,0.6,2.5,0.8,3.8c0.3,1.5,0.6,3,0.9,4.4c0.2,0.8,0.3,1.7,0.5,2.5c0.1,0.4,0.2,0.7,0.2,1v0.1
      c0,0,0,0-0.1,0c-0.8,0.2-1.6,0.4-2.5,0.5l-0.5,0.1c-2.1,0.5-4.4,1-6.7,1.5c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1
      c-2.4,3.3-5.3,7.1-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3c0.8,2.9,1.7,5.9,3,9.7c0.4,1.3,1,2.6,1.5,3.9c0.5,1.1,0.9,2.3,1.4,3.5
      c0.7,1.9,1.3,3.8,1.9,5.7l0.1,0.3c0.4,1.1,0.7,2.3,1.1,3.4c0.2,0.5,0.3,0.9,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2
      c0.2,0.9,0.2,1.9,0.3,2.8c0,0.7,0.1,1.4,0.1,2.1l0.1,0.9c0.5,3.9,0.9,8,1.5,12c0.4,2.8,0.9,5.6,1.4,8.3v0.2
      c0.1,0.6,0.2,1.3,0.4,1.9c0,0.2,0.1,0.5,0.1,0.7c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6c0.8,0.8,1.8,1.5,2.7,2.2l0.1-0.1
      v0.1c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7c0.2,0,0.4,0.2,0.5,0.3
      c0.3,0.6,0.5,1.3,0.8,2v0.1c0.3,0.7,0.5,1.4,0.8,2.1c0.3,0.6,0.5,1.3,0.8,1.9c1.1,2.5,2.2,5,3.4,7.5s2.6,5.1,3.9,7.6v0.1
      c0.9,1.7,1.8,3.5,2.8,5.3c0.8,1.7,1.7,3.5,1.7,5.6c0,3.4,0.1,6.8,0.2,10.1c0.1,2.6,0.1,5.2,0.1,7.8c0,3.2,0,6.5,0,9.4v0.6
      c0,1.4,0,2.7,0,4.1v1.4c0,1.7,0,3.7,0,5.7c0,1.6,0.1,3.2,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6c0,1.1,0,2.1,0,3.2c0,1.8,0,3.6,0.1,5.4
      c0.1,2.2,0.2,4.3,0.3,6.4c0.1,1.5,0.2,3.1,0.3,4.6c0.1,1.1,0.1,2.3,0,3.8c0,0.1,0,0.3,0,0.4c0,0.6,0,1.3-0.2,1.7
      c-0.5,1-1.1,2-1.6,3c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.8,1.3-1.2,1.9v0.1
      c-0.7,1-1.3,2.1-2.1,3.1s-1.6,2.1-2.4,3c-0.9,1-1.8,2.2-2.7,3.4L406,417c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2
      c-2.6,1.5-5.7,3.2-8.5,4.8c-1,0.6-2.1,1.3-3,1.8c-0.9,0.6-1.9,1.2-2.9,1.8c-0.9,0.5-1.9,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2
      s-2.3,1.5-3.3,2.3c-0.7,0.5-1.5,1-2.3,1.6c-1,0.6-1.9,1.2-3,1.9h-0.1c-0.5,0.3-1,0.6-1.5,0.9l-1,0.6l1.2,0.1c0.1,0,0.1,0,0.2,0
      s0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c1.1-0.5,2.1-1,3.2-1.6l0.4-0.2c3-1.5,6.2-3,9.3-4.6c2-1,4-2.2,6-3.4l1.2-0.7
      c0.7-0.4,1.5-0.9,2.2-1.3l0.1-0.1c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.3-1.2,3.3-1.7c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6
      c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1l0.1-0.1c0.4-0.4,0.7-0.7,1.1-1.1l0.1-0.1c0.7-0.7,1.4-1.5,2.1-2.3c0.7-0.7,1.3-1.4,2-2.3l0.8-0.9
      l0.4,0.5c0.4,0.5,0.8,1,1.2,1.4c0.4,0.4,0.7,0.9,1.1,1.3c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8l0.1,0.1
      c0.6,0.6,1.2,1.2,1.9,1.8c0.7,0.7,1.5,1.4,2.2,2.2c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5
      c-0.1,0.3-0.3,0.6-0.4,1.1c0,0.1-0.1,0.2-0.1,0.2l-0.1,0.1c-0.3,0.7-0.7,1.5-1.1,2.2c-0.8,1.7-1.6,3.4-2.3,5.1
      c-0.9,2.3-1.8,4.6-2.6,6.9c-0.2,0.7-0.5,1.4-0.7,2c-0.1,0.3-0.2,0.7-0.4,1c-0.5,1.4-1,2.8-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7
      c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.4-0.1,0.5l-0.1,0.4l0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1
      c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2c0.9-2.9,1.7-5.3,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7
      c0.5-1.2,1.1-2.3,1.7-3.5l0.1-0.1c0.2-0.3,0.3-0.6,0.5-1c0.4,0.2,0.8,0.5,1.2,0.7h0.1c1.2,0.7,2.3,1.4,3.5,2
      c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.2,1.3,4.8,1.8l0.2,0.1c0.8,0.3,1.6,0.6,2.4,0.9
      c1.1,0.4,2.2,0.8,3.2,1.2c2.2,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,0.7-0.1,1.4-0.2,2.1l-0.1,0.7
      c-0.2,2.7-0.4,5.5-0.6,8.3c-0.1,1.4-0.1,2.8,0,4.1c0,0.6,0,1.2,0,1.8v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.3-2.6,0.3-3.8
      c0-0.8,0.1-1.5,0.1-2.2c0.1-1.1,0.2-2.1,0.3-3.2v-0.1c0.1-1.2,0.2-2.4,0.3-3.5c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0
      c0.6,0.1,1.3,0.1,2,0.1h0.1c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4
      c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.5
      c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.2
      c0.3,3.4,0.5,7,0.9,10.5c0.1,1,0.4,1.9,0.7,2.8v0.1c0.1,0.4,0.2,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4
      c-0.1-1.8-0.3-3.7-0.4-5.3c-0.1-1-0.2-2.3-0.3-3.5c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3
      c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.2,1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.7-1.6,5.5-2.4l0.3-0.1
      c3.1-1.4,6.4-2.8,9.5-4.2c2-0.9,4.4-2,6.8-3.2c1.2-0.6,2.4-1.3,3.6-2l0.1-0.1c0.4-0.2,0.8-0.5,1.2-0.7c0.3,0.6,0.6,1.3,1,2
      c0.9,1.8,1.8,3.6,2.6,5.5c1,2.4,1.9,4.9,2.7,7.3l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.8,8.1c0.4,0.6,1,1,1.6,1
      c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4
      c-0.5-1.6-1.2-3.1-1.8-4.4l-0.3-0.6c-1-2.1-2-4.3-3.1-6.4c-1.1-2-1-2.1,0.6-3.6c1.5-1.4,3-2.8,4.5-4.3l0.6-0.5
      c0.6-0.6,1.2-1.2,1.8-1.8c0.9-1,1.9-2,2.9-2.9c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.2,1.3
      c1.7,1.8,3.3,3.6,4.9,5.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.4,0.5,0.8,0.8,1
      c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.2,1.2,3.3,1.8l0.2,0.1c1.5,0.8,3.1,1.7,4.7,2.6c1.2,0.7,2.4,1.4,3.6,2.2c1.3,0.8,2.6,1.6,3.9,2.3
      c2.2,1.2,4.5,2.4,6.6,3.4c1.7,0.9,3.5,1.7,5.3,2.5c0.6,0.3,1.2,0.6,1.8,0.8h0.1c0.2,0,0.5-0.1,0.5-0.4V440h-0.3
      C631,439.5,630.3,439,629.5,438.5z M587.2,266.9c-0.1,1.2-0.2,2.3-0.3,3.4l-0.1,0.8c-0.2,2.5-0.5,5.2-0.7,7.7
      c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6l-0.2,0.4c-0.3,0.8-0.7,1.6-1,2.4c-0.9,2-1.8,4.4-2.9,6.5
      c-0.9,1.8-1.8,3.6-2.7,5.3c-0.5,1-1.1,2.1-1.7,3.2c-0.7,1.5-1.5,3-2.2,4.5c-0.8,1.7-1.6,3.4-2.4,5.1l0.1,0.4
      c-0.8,1.7-1.6,3.4-2.3,5.1c-0.7,1.5-1.4,3.1-2.3,4.7c0.2-1.2,0.4-2.5,0.6-3.7c0.2-1.3,0.5-2.7,0.7-4.1c0.5-2.7,1-5.5,1.4-8.3
      c0.4-2.5,0.6-5.1,0.8-7.6c0.1-1.6,0.3-3,0.4-4.5c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4
      c-0.1-0.6-0.2-1.3-0.3-1.9v-0.1c0-0.1,0-0.2,0-0.3l2.1-1.3c1.6-1,3.3-2.1,5-3.1c0.8-0.5,1.6-1,2.4-1.4c0.8-0.4,1.7-1,2.5-1.5
      c1-0.6,2-1.2,2.9-1.8c0.3-0.2,0.5-0.4,0.8-0.5L587.2,266.9z M572.1,350.8c0,2.3,0.1,4.6,0,6.8c-0.1,4.4-0.3,8.9-0.5,13.2l-0.1,3.3
      c0,0.6,0,1.2-0.1,1.8c-0.1,2.6-0.2,5.3-0.5,7.9c-0.1,0.8-0.8,2-1.4,2.3c-1.5,0.6-2.5,1.6-3.5,2.6c-0.5,0.5-1.1,1.1-1.7,1.5
      c-0.7,0.5-1.4,1-2.2,1.7c-0.9,0.7-1.9,1.5-2.8,2.2c-1.2,0.9-2.5,1.8-3.7,2.6l-0.3,0.2c-0.3,0.2-0.6,0.4-0.9,0.6
      c-0.1,0.1-0.3,0.2-0.5,0.3l-0.1-1.4c-0.1-1.4-0.2-2.7-0.3-4.1c-0.1-1.2-0.1-2.3-0.2-3.5v-0.3c-0.1-2-0.2-4.4-0.3-6.6
      c-0.2-2.2-0.4-4.4-0.5-6.6v-0.1c-0.1-1.6-0.3-3.3-0.4-5c-0.2-2.3-0.3-4.6-0.5-6.8v-0.3c-0.2-2.7-0.4-5.4-0.6-8.1
      c-0.3-3.4-0.7-6.8-1-10.1c-0.1-0.6,0.1-0.8,0.5-1.1c4.2-2.6,8.3-5.2,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3c0.8-1.1,1.5-2.1,2.2-3.1
      c0.2-0.3,0.3-0.6,0.4-0.9c0.1-0.2,0.1-0.3,0.2-0.5c0.8-1.8,1.6-3.6,2.4-5.4l0.4-0.9c0.6-1.5,1.3-2.9,1.9-4.4
      c0.4-0.8,0.7-1.6,1.1-2.5v0.3c0,1.4,0,2.9,0,4.3c0,2.4-0.1,4.8-0.1,7.2c0,2.2-0.1,4.5-0.1,6.8c-0.1,3.4-0.1,7-0.1,10.5
      C572,347.8,572,349.3,572.1,350.8z M514.1,356.6c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.6,0-1,0.1h-0.3
      c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3c-4.9-0.4-9.8-0.9-14.6-1.4h-0.2c-1.5-0.1-3-0.4-4.4-0.6h-0.3
      c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5c0.3-1.1,0.6-2.2,0.9-3.3
      c0.4-1.3,0.7-2.6,1-3.7l0.3-1.2c0.7,0,1.3,0,1.9,0h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0c2.7,0.1,5.9,0.2,9,0.5
      c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2h0.2c0.4,0,0.8,0,1.2,0c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0
      c0.1,0.4,0.2,0.8,0.3,1.2l0.1,0.3c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.8,1.1,3.8,1.6,5.3c0.7,2.3,1.4,4.7,2.1,7.1
      c0.2,0.8,0.1,1.8-0.1,2.7v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1C517.6,356.2,515.8,356.4,514.1,356.6z
       M518.5,404.1c-0.1,0-0.1,0-0.2,0l-2,0.1c-2.9,0.1-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0
      c-0.7,0-1.5,0.2-2.2,0.3c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2
      c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0-3.4-0.1c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.9-0.1h-0.1
      c0-1.5,0.1-3,0.2-4.5v-0.1c0.1-3.5,0.3-7.2,0.3-10.8c0-1.8,0-3.7,0-5.5v-0.1c0-2.1,0-4.4,0-6.6c0.1-2.1,0.2-4.2,0.4-6.2
      c0.1-1.5,0.2-3.3,0.3-5c0.1-1.5,0.1-3.1,0.1-4.8v-0.3c0-0.5,0-1,0-1.5c5.9,0.6,11.9,0.9,17.7,0.9c5.9,0,11.9-0.3,17.7-0.9l0.2,7
      c0.1,5.1,0.3,10.4,0.4,15.6c0.1,5.1,0.2,10.3,0.4,16L518.5,404.1z M427.6,368.9c0-0.9-0.1-1.8-0.1-2.7v-0.7
      c-0.1-3.4-0.2-6.9-0.3-10.4c0-2.7,0-5.4,0.1-8V347c0-2.3,0.1-4.7,0.1-7c0-4.6-0.1-9.3-0.2-13.9v-1c0-1.8-0.1-3.5-0.2-5.2v-0.1
      c0-1.1-0.1-2.3-0.1-3.4v-0.1c0.5,1.2,1,2.3,1.5,3.5c1.5,3.4,3,6.9,4.4,10.4c0.2,1.4,1,2.5,1.7,3.5c0.4,0.6,0.8,1.2,1.2,1.8
      c0.3,0.5,0.9,0.8,1.4,1.1c0.2,0.1,0.5,0.2,0.7,0.3c0.9,0.6,1.8,1.1,2.6,1.7c2,1.3,4,2.6,6.1,3.7c2.1,1.1,2.8,2.3,2.4,4.6
      c-0.6,3.7-0.8,7.4-1.1,11c-0.1,1-0.2,2.1-0.2,3.1c-0.2,1.9-0.3,3.8-0.4,5.6v0.3c-0.1,1.1-0.1,2.2-0.2,3.3c-0.2,3.3-0.5,6.5-0.7,9.8
      v0.1c-0.2,2.5-0.4,5-0.5,7.5c-0.2,3.3-0.4,6-0.6,8.5c0,0.5,0,0.9,0,1.4v0.1l-0.3-0.2c-0.6-0.4-1.1-0.7-1.6-1.1
      c-0.6-0.4-1.2-0.9-1.9-1.3c-1.5-1-3-2-4.4-3.1c-1.3-1.1-2.6-2.2-3.8-3.4c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.2-0.6-0.4-1-0.6
      c-1.2-0.4-2-1.5-2-2.9c-0.1-2-0.2-4.1-0.3-6.1c-0.1-1-0.1-2-0.2-3v-0.6C427.7,372,427.6,370.4,427.6,368.9z M430.9,299.6
      c-0.3-0.8-0.4-1.7-0.5-2.6c-0.3-2.3-0.6-4.8-0.9-7.3l-0.1-0.7c-0.3-2.4-0.5-5-0.5-7.4c0-1.6,0.3-3.2,0.5-4.7
      c0.1-0.6,0.2-1.1,0.3-1.7s0.2-0.7,0.7-0.8c1.6-0.1,3.3-0.3,4.9-0.5c1.2-0.1,2.6-0.3,4-0.4c1.9-0.2,4-0.3,6.2-0.4c0.3,0,0.6,0,1,0
      c0.7,0,1.4,0,2.1,0c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.7,0.1,1.1v0.1c0.1,1.1,0.2,2.1,0.3,3.2c0,0.4,0,0.9,0,1.4c0,0.3,0,0.7,0,1
      c0,0.4,0,0.8,0,1.2c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2,0.4,3c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4v0.1
      c0.2,2.4,0.5,4.9,0.8,7.3c0.5,3.4,1.1,6.8,1.7,10.2l0.1,0.3c0.1,0.5,0,0.9-0.4,1.4c-1.6,1.9-3.6,3.4-5.5,4.8l-0.1,0.1
      c-0.5,0.4-1,0.7-1.5,1.1c-1.6,1.2-3.1,2.5-4.6,3.8c-0.5,0.5-1.1,0.9-1.6,1.4c-1,0.8-1.9,1.6-3,2.5l-1.4,1.2
      c-0.1-0.2-0.1-0.5-0.2-0.7c-0.2-0.9-0.4-1.7-0.6-2.6l-0.4-2.5c-0.7-3.7-1.3-7.6-1.9-11.4c-0.4-2.7-0.7-5.4-1.1-8.1V303
      C431.2,301.9,431,300.7,430.9,299.6z M407.8,264c-0.9-4-1.7-7.9-2.3-11.5c-0.5-2.9-1.2-6.7-1.8-10.5c-0.2-1.4-0.4-2.9-0.5-4.3v-0.1
      c-0.1-1-0.2-2.1-0.3-3.1c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8c-0.6-3.7-1.1-7.5-1.7-11.1l-0.1-0.6c-0.1-0.4-0.1-0.9-0.3-1.3
      c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4c0.5-0.1,1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6c0.1,0,0.1,0,0.2,0
      c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.8c0.1,2.5,0.3,5,0.5,7.4c0.1,1,0.2,1.9,0.2,2.9
      c0.1,1.3,0.2,2.7,0.3,4v0.1c0.3,3.6,0.6,7.3,0.9,10.9c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5
      c0.1,1,0.2,2,0.3,2.9c-0.2-0.6-0.4-1.1-0.6-1.7v-0.1C408.4,265.9,408,265,407.8,264z M381.8,221.5c0-0.2,0-0.3,0.1-0.3
      c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.6-0.8l3-0.3c3.3,5.7,6.3,11.9,5.9,19.5h-0.4c-2.2-0.1-4.5-0.2-6.9-0.2c-3.8,0-7,0.3-9.9,1
      l-0.1-1.1c-0.1-1-0.2-1.9-0.3-2.8c-0.3-3-0.6-6-0.9-9c-0.1-1.1-0.2-2.1-0.3-3.3v-0.2C381.9,223.1,381.9,222.3,381.8,221.5z
       M391.3,176.2c-0.1-0.5-0.1-0.8,0-0.8c1.1-1.4,2.3-2.9,3.3-4.2l0.4-1.2c0.1,0.4,0.1,0.7,0.2,1.1l0.1,0.4c0.3,1.5,0.5,2.9,0.9,4.4
      c0.9,4,1.6,7.3,2.4,10.3c0.4,1.7,0.9,3.4,1.3,5v0.2c0.4,1.4,0.7,2.8,1.1,4.1c0.5,2.1,1.1,4.2,1.6,6.3v0.1c0.5,2,1,4.2,1.6,6.3
      c0.2,0.8,0.1,1.4-0.2,1.9c-0.4,0.6-1.2,1-2.5,1.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0
      c-0.6,0-0.9-0.2-1.1-0.7c-0.3-1.1-0.6-2.3-0.8-3.4c-0.3-1.3-0.6-2.7-0.8-4v-0.1c-0.5-2.5-1-5.1-1.6-7.6c-0.3-1.3-0.7-2.7-1-3.9
      v-0.1c-0.3-1-0.6-2-0.8-3.1c-0.4-1.5-0.7-3-1-4.5V184c-0.1-0.7-0.3-1.4-0.4-2.1c-0.1-0.6-0.3-1.2-0.4-1.7v-0.1
      C391.8,178.8,391.5,177.5,391.3,176.2z M455.4,88.2c0.1,2.1,0.3,4.3,0.4,6.4V95c0.2,2.8,0.4,5.8,0.6,8.7c0.1,2.5,0.2,5,0.3,7.4
      c0.1,1.8,0.1,3.7,0.2,5.6c0,0.9,0.1,1.7,0.2,2.6c0.3,0.3,0.4,1,0.4,1.6c0,0.2-0.1,0.5-0.1,0.6c-2.8,1.7-6,3.6-9.1,5.4
      c-2.1,1.2-4.3,2.4-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3c-1.5,0.8-3.1,1.7-4.6,2.5c-0.8,0.4-1.5,0.8-2.4,1.3
      l-0.7,0.4c-0.2-0.6-0.3-1.1-0.5-1.7v-0.1c-0.4-1.6-0.9-3.2-1.2-4.8l-0.2-0.7c-0.7-2.8-1.3-5.8-1.8-8.7c-0.5-3.2-1-6.5-1.4-9.7V115
      c-0.2-1.3-0.4-2.7-0.5-4c0-0.3-0.1-0.5-0.1-0.8c-0.1-0.5-0.1-1.1-0.3-1.7c-0.4-1.6,0.1-2.8,1.7-4c1.4-1,2.7-2,4-2.9
      c1.3-1,2.6-2,3.9-2.9c1.3-0.9,2.7-1.8,4.1-2.7c0.8-0.5,1.6-1,2.3-1.5c1.1-0.7,2.2-1.4,3.3-2.2c2-1.3,4-2.7,6.1-4
      c1.8-1.1,3.7-2.2,5.6-3.2c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5c0.2-0.1,0.4-0.2,0.7-0.4c0,0.5,0,0.9,0,1.4v0.6
      C455.3,84.6,455.3,86.4,455.4,88.2z M498.3,114.2c0,0.7-0.2,0.8-0.9,0.8c-0.9,0.1-1.8,0.2-2.6,0.2h-0.1c-3,0.3-6.2,0.5-9.2,0.9
      c-5.3,0.7-10.9,1.6-17.7,2.7c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5l-2.3-41.1l41-10.3v2.5c0,2.4,0,4.8,0,7.1
      c0,0.5,0,1.1,0,1.6v0.1c0,0.6,0,1.2,0,1.9c0,1.6,0,3.1-0.1,4.7v0.2c0,1.8-0.1,3.7-0.1,5.5C498.3,99.2,498.2,106.6,498.3,114.2z
       M615.6,239.5l-0.1,0.8c-3-0.7-6.2-1-10.1-1c-2.4,0-4.7,0.1-7.1,0.3c-0.4-7.8,2.7-14.2,5.9-19.4c0.5,0,0.9,0,1.4,0.1h0.7
      c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6c0.1,0,0.2,0.2,0.2,0.3c-0.3,3.9-0.7,8-1.1,11.6l-0.3,4.3
      C615.7,238.1,615.7,238.7,615.6,239.5z M397.3,170.1c-0.3-1.3,0-2.3,0.9-3.2c1.5-1.5,3-3.1,4.2-4.4c1.4-1.4,2.8-2.9,4.2-4.4
      c1.3-1.3,2.5-2.6,3.8-3.9l0.2-0.2c1.7-1.7,3.5-3.6,5.3-5.5c1.1-1.2,2.2-2.4,3.2-3.6c0.5-0.6,1-1.2,1.6-1.8l0.1-0.1l0.1,0.7
      c0.2,0.9,0.3,1.7,0.4,2.6l0.1,0.5c0.5,3.4,1,7,1.5,10.5c0.6,4.1,1.1,8.3,1.6,12.1l0.1,0.6c0.2,1.5,0.4,3,0.5,4.6v0.3
      c0.1,1,0.1,1.9-0.3,2.8c-0.5,1.2-0.8,2.5-1.1,3.8v0.1c-0.1,0.4-0.2,0.9-0.3,1.3l-0.3,1.4c-0.6,2.4-1.2,4.9-1.8,7.4
      c-0.2,0.9-0.4,1.9-0.5,2.8v0.1c-0.2,0.9-0.3,1.9-0.6,2.9c-0.1,0.5-0.4,1-0.8,1.4c-1.2,1.1-2.4,2.2-3.6,3.3l-0.3,0.3
      c-1,0.9-2,1.8-3,2.8c-1.3,1.2-2.6,2.5-3.9,3.8l-0.1,0.1c-0.5,0.5-1,0.9-1.5,1.4l-0.6-2.5c-0.6-2.2-1.1-4.4-1.6-6.5
      c-0.4-1.7-0.8-3.3-1.3-5c-0.7-2.6-1.4-5.3-2-8c-0.9-3.5-1.5-6.2-2.1-8.7c-0.3-1.4-0.6-2.8-0.9-4.1v-0.2
      C398.1,173.8,397.7,171.9,397.3,170.1z M459.5,160.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.9,0-2.8-0.1c-1.9,0-3.9-0.1-5.9-0.1h-0.1
      c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5c-0.7,0.9-1.4,1.7-2.2,2.5c-0.6,0.6-1.2,1.3-1.8,2
      c-0.9,1.1-1.7,2.2-2.5,3.3v0.1c-0.5,0.7-1,1.3-1.5,2c-0.6,0.7-1.2,1.5-1.7,2.2l-0.1,0.1c-0.2,0.2-0.3,0.4-0.5,0.6l-0.2-1.7
      c-0.1-1.1-0.2-2.1-0.3-3.2c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.8-0.4-3.9-0.7-5.9c-0.5-3.7-1-7.5-1.4-10.7c-0.3-2.3-0.7-4.4-1-6.4
      v-0.1c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.5-3.5l0.4-0.2c1.1-0.6,2.2-1.2,3.3-1.8c1-0.6,2.1-1.2,3.1-1.7l0.3-0.2
      c1.5-0.8,3-1.7,4.6-2.5c1-0.5,2-1.1,3-1.6c1.4-0.7,3-1.5,4.5-2.4c1.5-0.8,3-1.8,4.4-2.6c0.7-0.4,1.3-0.8,2-1.2
      c0.4-0.2,0.7-0.4,1.1-0.7l0.6-0.4l2,37.2L459.5,160.7z M460.3,163.5h0.1h0.1l-1.5,17.3l-0.4,5.1c-0.2,0-0.5,0-0.7,0
      c-0.4,0-0.7,0-1.1,0c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7h-0.1c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1
      c-2.9-1.9-5.8-3.9-8.7-5.9c-0.9-0.6-1.8-1.3-2.7-1.9c-0.3-0.2-0.6-0.5-0.9-0.7c0.4-0.6,0.9-1.1,1.3-1.7c1.1-1.5,2.2-2.9,3.4-4.3
      c1.1-1.3,2.3-2.7,3.5-3.9l0.1-0.1c0.8-0.9,1.7-1.8,2.5-2.8c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0
      c2.2-0.1,4.2-0.1,6.1-0.1c4.2-0.1,7.9-0.1,11.3-0.1C459.7,163.4,460,163.5,460.3,163.5z M425.7,182.8c0.3-1.1,0.5-2.2,0.8-3.3v-0.1
      c0.1-0.3,0.2-0.6,0.2-1l13.7,9.4l-3.6,3.7c-0.8,0.8-1.6,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.9l1,0.9
      c1.8,1.5,3.6,3.2,5.4,4.7c0.6,0.5,1.2,0.9,1.8,1.3h0.1c0.2,0.1,0.3,0.2,0.5,0.3l-4.3,7.9c-0.2-0.1-0.5-0.3-0.7-0.4
      c-3.3-2.4-7.1-5.2-10.9-8.1c-1.8-1.4-3.4-2.6-4.8-3.7c0,0-0.1-0.3-0.1-0.5C423.5,192.6,424.6,187.6,425.7,182.8z M444.6,204.5
      c0.1-0.1,0.4-0.3,0.5-0.3c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2c0.6,0,1.2,0,1.8,0c0.8,0,1.6,0,2.3,0c1,0,2,0,3,0
      c0.6,0,1.1,0.1,1.8,0.1c0.2,0,0.4,0,0.6,0.1v9.7l-18.6-2.5l0.7-1.3C442.2,208.7,443.4,206.5,444.6,204.5z M438.2,226.5L438.2,226.5
      c0.1-1,0.2-1.9,0.4-2.8c0.4-1.6,0.7-3.1,1-4.4v-0.1c0.1-0.5,0.2-1.1,0.3-1.6l0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1
      c0-0.1,0.3-0.3,0.4-0.3h0.1c3.1,0.3,6.6,0.7,9.9,1.1c1.5,0.2,3,0.5,4.4,0.8c1,0.2,1.9,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1
      c0.1,3.4,0.2,6.3,0.4,8.9c0.1,1.3,0.2,2.7,0.4,3.9v0.1c0.1,0.8,0.2,1.7,0.3,2.5c0.1,0.7,0.1,1.3,0.1,2.1v0.2c0,0.2,0,0.5,0,0.8
      l-24.9,6.7c0-0.2,0-0.4,0.1-0.6c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.4,0.6-2.8,0.8-4.3l0.1-0.5c0.5-2.3,0.9-4.4,1.3-6.5
      C438,228.5,438.1,227.5,438.2,226.5z M459,202.2c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3c-1.5,0-3.1-0.1-4.6-0.3
      c-0.4,0-0.8-0.1-1.2-0.1c0,0,0,0,0-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.4-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.1
      s-0.4,0-0.5-0.1c-1.8-1.5-3.6-3-5.7-4.8l-1.4-1.2l0.4-0.3c0.4-0.3,0.7-0.6,1-0.9c0.4-0.4,0.8-0.7,1.2-1.1c1.1-0.9,2.2-1.9,3.2-2.9
      c1.5-1.5,3.4-1.5,5.2-1.5h0.3c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.4,0.5,3.6,1.5c1,0.8,2,1.6,3,2.4
      c0.5,0.4,1.1,0.9,1.6,1.3l0.9,0.4c0.7,0.6,1.4,1.2,2.1,1.8c0.2,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.5,0.9
      c-2.6,2.6-5.4,5.2-9.8,4.9C460.4,202.2,459.7,202.2,459,202.2z M476,203.5c-0.1-1,0.4-2.3,1.2-3.2c2.3-2.7,4.8-5.3,7.2-7.8
      c0.6-0.7,1.3-1.4,1.9-2.1c0.5-0.5,1-1,1.6-1.5c0.1-0.1,0.2-0.2,0.3-0.3l0.1,1.2c0.1,1.2,0.2,2.4,0.2,3.5c0.2,2.5,0.5,5.5,0.8,8.4
      c0,0.6-0.2,0.8-0.7,1.1c-1.8,1-3.6,2.2-5.1,3.2l-0.1,0.1c-1.3,0.8-2.6,1.7-4,2.5c-0.5,0.3-1.2,0.6-1.8,0.9
      c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1-0.5-0.2-1-0.3-1.6V208C476.4,206.4,476.1,205,476,203.5z M481.5,192.2l-5.4,5.7
      c-0.5-0.5-0.9-0.9-1.4-1.4c-1.2-1.3-2.5-2.6-3.8-3.8c-2-1.7-4.2-3.5-7.2-5.7c-0.6-0.4-1.3-0.7-2.1-0.9c-0.3-0.1-0.6-0.2-0.8-0.3
      l1.6-22.4c0.1,0,0.3,0.1,0.4,0.1h0.1c0.6,0.2,1.2,0.4,1.7,0.7c0.9,0.5,1.9,0.9,2.8,1.3c2.8,1.3,5.7,2.7,8,5
      c3.6,3.7,6.3,7.3,8.4,10.9c0.6,1,1.3,2,1.9,2.9c0.3,0.5,0.7,1,1,1.5c0.1,0.1,0.2,0.2,0.2,0.4L481.5,192.2z M473.9,201.8
      c-0.1,0.2-0.5,0.5-0.7,0.5c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1l3.5-3.3C474.6,199.5,474.7,200.1,473.9,201.8z M460.8,213.2
      c0.3-2.5,0.3-4.9,0.2-7.8c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1
      h0.1c1.7,0.4,3.3,0.7,5.1,1.1l1.5,0.3l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.3c0,0.1-0.2,0.4-0.4,0.5c-0.7,0.3-1.4,0.6-2.1,0.9
      c-2.9,1.2-5.9,2.4-8.9,3.5c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1C460.7,215.2,460.8,214,460.8,213.2z M471,214.6
      c1.4-0.6,2.8-1.2,4.3-1.8l3.6,11.7l-3.4,2.2l-12.9,8.4l-1.5-17.2C464.7,217.4,467.9,216,471,214.6z M575.3,190.3
      c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4c-1.5,1.2-3.1,2.3-4.7,3.4l-0.4,0.3c-1.5,1.1-2.9,2.2-4.4,3.3
      c-1.3,1-2.6,1.9-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9c-0.2,0.1-0.3,0.2-0.5,0.3l-4.5-8c0.1-0.1,0.3-0.2,0.5-0.3
      c0.6-0.4,1.2-0.9,1.8-1.4l0.8-0.7c1.8-1.5,3.6-3,5.3-4.5c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.8-3.4-5.5-5
      c-0.1-0.1-0.1-0.2-0.2-0.3l13.7-9.4c0,0.1,0,0.1,0.1,0.2l0.1,0.2c0.2,0.5,0.4,1,0.5,1.6C573.9,184.2,574.6,187.4,575.3,190.3z
       M574.1,174.2L574.1,174.2c0.1-1.3,0.2-2.5,0.3-3.6v-0.1c0.1-1.5,0.3-3.1,0.5-4.7c0.2-1.4,0.4-2.8,0.6-4.2c0.1-0.8,0.3-1.6,0.4-2.4
      c0.3-1.8,0.5-3.6,0.8-5.3c0.4-2.9,0.8-5.9,1.3-8.9c0.1-0.5,0.2-0.9,0.4-1.5l0.1-0.2l0.6,0.6c0.7,0.7,1.3,1.4,1.9,2
      c0.9,1,1.8,1.9,2.8,2.9l0.6,0.6c1.7,1.7,3.4,3.6,5.1,5.4c1.2,1.3,2.5,2.6,3.5,3.7l0.1,0.2c0.8,0.9,1.6,1.7,2.4,2.6
      c0.4,0.4,0.8,0.9,1.2,1.4c1.1,1.2,2.2,2.4,3.4,3.4c2.1,1.8,2,3.8,1.5,5.8c-0.6,2.3-1.1,4.7-1.6,6.9v0.1c-0.5,2.1-0.9,4.3-1.5,6.5
      c-0.4,1.7-0.9,3.4-1.3,5.1c-0.4,1.4-0.8,2.7-1.1,4.1c-0.6,2.3-1.1,4.7-1.7,6.9v0.2c-0.2,1-0.5,2-0.7,3c0,0.2-0.1,0.4-0.1,0.6
      c-0.1,0.4-0.2,0.7-0.3,1.1c-0.3,1-0.6,2.1-0.9,3.1l-0.4,1.2c-0.4-0.3-0.7-0.7-1-1l-0.1-0.1c-0.9-1-1.9-2.1-2.9-3.1
      c-1.5-1.4-3-2.9-4.3-4.1l-0.2-0.2c-1.1-1.1-2.3-2.2-3.5-3.3c-0.5-0.5-0.9-1.1-1-1.6c-0.8-3.5-1.6-7-2.4-10.4
      c-0.4-1.6-0.7-3.1-1.1-4.7c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-1.1-0.4-2.2-0.9-3.3C573.9,176.5,574,175.4,574.1,174.2z M563.9,242.7
      c-0.5-0.1-0.9-0.2-1.3-0.3h-0.1c-1.3-0.1-2.5-0.3-3.6-0.6c-2-0.5-4-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1
      h-0.1c-1.9-0.5-3.8-1-5.7-1.5c-0.8-0.2-1.2-0.5-1.1-1.4c0.3-2.1,0.4-4.1,0.6-6.1c0.2-2.4,0.3-4.8,0.4-7.2l0.1-2.3
      c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6c2.3-0.5,4.4-0.9,6.4-1.2c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1
      c0.7-0.1,1.7-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5c0.3,1.7,0.6,3.4,0.9,5.1v0.2c0.3,1.8,0.7,4.1,1.1,6.2
      c0.4,2.3,0.9,4.6,1.4,7.1v0.1C563.5,240.8,563.7,241.8,563.9,242.7z M540.1,214.5v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0
      c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2h0.1c1.3-0.1,2.6-0.2,3.9-0.2c0.1,0,0.4,0.2,0.5,0.4c1.2,2,2.3,4,3.4,6.1l0.8,1.4
      L540.1,214.5z M569.2,178.4l-0.1,0.1c-1.6,1.1-3.2,2.2-4.7,3.3c-2.3,1.6-4.7,3.1-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1
      c-1.1-0.1-2.2-0.2-3.3-0.3c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1
      c-0.4,0-0.8,0-1.2,0h-0.2l-1.6-22.5c0.1-0.1,0.2-0.1,0.4-0.1c0.5-0.1,1-0.2,1.5-0.2c2.3,0,4.6,0.1,6.5,0.2h0.4
      c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1c0.7,0,1.3,0.2,1.8,0.8c0.5,0.6,1.1,1.2,1.7,1.7
      c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.4l0.2,0.3c0.4,0.6,0.9,1.2,1.3,1.8C570.4,177.5,569.8,178,569.2,178.4z
       M551.5,188.2l0.3-0.1c2.5,0,4.5,0.9,6.1,2.6c1,1.1,2.1,2,3.3,3.1l0.1,0.1c0.4,0.3,0.8,0.7,1.2,1l-1.3,1.1c-1.7,1.4-3.3,2.7-4.9,4
      c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4c-0.3,0.1-0.6,0.2-0.9,0.2c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.2,0H554
      c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1c-2.3,0-4.8,0-6.9,0c-1.8,0-3.7,0-5.6,0h-0.1
      c-2.6,0-4.9-0.9-6.9-2.8c-0.2-0.2-0.5-0.4-0.8-0.6c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4
      c0.9-0.7,1.9-1.5,2.8-2.2c1.2-1,2.4-2,3.6-2.9c1-0.7,2.1-1.2,3-1.2C542.9,188.3,547.4,188.3,551.5,188.2z M507.6,269
      c-0.2-1.2-0.4-2.3-0.6-3.5c0-0.1,0-0.1,0-0.2l3.1-0.2c1.9-0.1,3.9-0.3,5.8-0.4c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1
      c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1c0.8,0.5,1.6,1.1,2.4,1.6l0.1,0.1c0.9,0.6,1.8,1.2,2.7,1.8c0.5,0.3,0.5,0.5,0.2,0.9
      c-2.2,3.6-4.2,6.8-6.1,9.8c-0.7,1.1-1.3,2.2-1.9,3.2l-0.2,0.4c-0.3,0.5-0.5,0.6-1.3,0.9c-0.3,0.1-0.6,0.1-0.9,0.1
      c-0.9,0-1.6-0.4-2.4-0.8l-0.4-0.2c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.5-0.3-0.5-0.9
      c0-1.5-0.3-3.1-0.5-4.6V276C508.4,273.7,508,271.3,507.6,269z M522.7,254.9l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.7,0.5-1.1l0.1-0.1
      c0.7-1.4,1.4-2.7,2.1-4c0.8-1.3,2.1-1.7,3.5-2.2C522.1,255.2,522.4,255.1,522.7,254.9z M522.3,263.4l0.6-1.8c0.6-2,1.3-4.1,1.9-6.2
      c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.5-1.9-2.5-2.6c-1.7-1.3-2-3-2.3-4.9
      c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0.2,0.1,0.3,0.3,0.4,0.4c1.1,1.1,2.2,2.1,3.3,3.2l0.6,0.5c2.1,2.1,4.4,4.3,6.7,6.6
      c2,1.9,4.1,3.9,6.3,5.8c0.7,0.6,0.7,1,0.1,1.7c-1.5,2.2-2.9,4.2-4.2,6.1c-0.4,0.6-0.7,1.2-1,1.8c-0.1,0.1-0.1,0.3-0.2,0.4
      L522.3,263.4z M517.6,249.1c0.9,1.1,1.8,2.1,2.8,3.1l0.9,1c-0.3,0.1-0.5,0.3-0.8,0.4h-0.1c-1,0.5-1.9,1-2.9,1.3c-0.1,0-0.2,0-0.3,0
      c-0.3,0-0.7-0.1-1.1-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-2.2-0.3-4.3-0.7-6.5-1l-2.2-0.3c0-0.4,0.3-0.7,0.9-1c1.5-0.6,3-1.3,4.4-1.9
      c1.2-0.5,2.5-1.1,3.7-1.6C516.4,248.4,517.1,248.5,517.6,249.1z M516.4,256.9l-0.5,0.9c-0.9,1.7-1.7,3.3-2.6,4.8
      c0,0-0.1,0.1-0.2,0.1c-2.3,0.2-4.5,0.4-7,0.6l-1.1,0.1l1.5-8.1L516.4,256.9z M505.7,271.6c0.4,2.2,0.7,4.2,1,6.3
      c0.1,0.7,0.2,1.5,0.2,2.3c0,0.3,0,0.6,0.1,1c-0.6,0.2-1.3,0.4-1.9,0.6c-1.8,0.6-3.5,1.1-5.2,1.4c-0.2,0-0.4,0.1-0.6,0.1
      c-0.8,0-1.7-0.3-2.6-0.5l-0.2-0.1c-1.1-0.3-2.2-0.7-3.4-1.2c-0.4-0.2-0.8-0.3-1.3-0.5l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4
      c0.1-0.9,0.3-1.7,0.4-2.6l0.1-0.5c0.2-1.3,0.4-2.7,0.6-4c0.2-1,0.4-1,0.6-1c0.2,0,0.5,0.1,0.8,0.2c2.9,0.7,5.5,0.7,8.1-0.1
      c0.4-0.1,0.6-0.2,0.8-0.2c0.2,0,0.5,0,0.6,1.1C505.2,268.2,505.4,269.9,505.7,271.6z M493.4,263.4l-0.7-0.1
      c-1.4-0.1-2.9-0.2-4.3-0.3h-0.1c-0.1,0-0.2-0.1-0.3-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3V258
      c-0.2-0.4-0.4-0.7-0.6-1.1l9.8-1.6l1.5,8.1H493.4z M483.4,262.8l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1
      c-0.9-2.4-1.6-4.5-2.2-6.6c1.7,0.5,3.3,0.9,4.2,2.4c0.8,1.3,1.4,2.6,2.1,4C483,262,483.2,262.4,483.4,262.8z M492.5,253.2l-1.6,0.3
      c-2.5,0.4-4.8,0.8-7.1,1.1c-0.2,0-0.3,0.1-0.4,0.1c-0.2,0-0.3,0.1-0.4,0.1h-0.4c-0.6,0-1.2,0-1.7-0.2c-0.8-0.3-1.5-0.6-2.3-1
      c-0.2-0.1-0.4-0.2-0.6-0.3c0.3-0.3,0.6-0.7,0.9-1c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1
      c2.5,1,5.2,2.2,8.3,3.6c0.2,0.1,0.5,0.4,0.7,0.8C492.5,253.1,492.5,253.2,492.5,253.2z M483.6,241L483.6,241
      c-0.1,1.1-0.3,2.2-0.6,3.1c-0.2,0.5-0.7,1-1.3,1.4c-0.3,0.3-0.6,0.5-0.9,0.8c-2.4,2.7-4.3,4.8-5.9,6.8c-0.5,0.6-0.9,1.3-0.4,2.5
      c0.6,1.6,1.1,3.2,1.6,4.7v0.1c0.3,0.9,0.5,1.8,0.8,2.6c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.6,1.6-3.8,2.4l-1.1,0.7l-1-1.5
      c-1-1.4-2-2.9-2.9-4.4l-0.4-0.6c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1c1.9-1.7,3.3-3,4.6-4.3c1.9-1.8,3.8-3.6,5.6-5.4
      l0.8-0.7c1-1,2-1.9,3-2.9c1-0.9,1.9-1.8,2.9-2.8l0.8-0.8C483.7,240.6,483.6,240.8,483.6,241z M472.9,268.6c0.7-0.4,1.3-0.9,2-1.4
      c1.2-0.8,2.4-1.7,3.6-2.4c1-0.3,2.1-0.3,2.8-0.3c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4l1,0.1c1,0.1,2,0.2,3,0.3
      c0.3,0,0.7,0.1,1,0.1l-0.3,1.8c-0.2,1.2-0.4,2.4-0.6,3.6c-0.1,0.9-0.3,1.8-0.4,2.6c-0.4,2.3-0.8,4.7-1,7c-0.1,1.1-0.5,1.3-1.3,1.6
      c-0.4,0.2-0.8,0.3-1.3,0.5c-0.9,0.3-1.8,0.7-2.6,1.1c-0.3,0.2-0.6,0.2-1,0.2c-0.3,0-0.6-0.1-1-0.1c-0.3-0.1-0.7-0.1-1.1-0.1h-0.1
      c0,0-0.1-0.1-0.3-0.3c-1.2-1.9-2.3-3.8-3.5-5.7c-1.6-2.7-3.2-5.3-4.8-8C472.5,269.1,472.5,268.9,472.9,268.6z M484.5,286.3
      c0.9-0.5,1.8-0.9,2.7-1.3c0.3-0.1,0.6-0.3,0.9-0.4c0.7-0.3,1.3-0.6,2-1c0.4-0.3,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,1.9,0.4l1,0.3
      c1.9,0.6,3.8,1.2,5.8,1.3c1.5,0.1,3.2-0.4,5.1-1.3c0.8-0.4,1.6-0.6,2.3-0.6c0.9,0,1.7,0.2,2.8,0.7c1.8,0.9,3.6,1.8,5.4,2.6h0.1
      c1.7,0.8,3.3,1.6,4.9,2.4c2.3,1.1,4.6,2.3,6.8,3.4c1.1,0.6,2.2,1.1,3.3,1.7c0.3,0.2,0.7,0.3,1,0.5l0.1,0.1l-1.4,0.3
      c-1,0.2-2,0.4-3,0.6l-1.5,0.3c-1.8,0.3-3.6,0.7-5.4,1c-0.4,0.1-0.8-0.1-1.3-0.2c-1.7-0.6-3.1-1.1-4.4-1.5c-2.6-1-5.1-2-7.4-2.9
      c-0.6-0.2-1.2-0.4-1.8-0.4c-0.7,0-1.5,0.2-2.1,0.6c-2.7,1.5-5.5,1.6-8.2,0c-0.7-0.4-1.4-0.6-2.1-0.6c-1.1,0-2.2,0.4-3.2,0.9
      c-0.4,0.1-0.7,0.3-1.1,0.4c-1.7,0.6-3.3,1.2-5,1.8l-0.3,0.1c-0.7,0.3-1.5,0.6-2.2,0.8h-0.1c-0.1,0-0.2,0.1-0.3,0.1
      c-0.7,0.5-1.5,0.8-2.5,0.8c-0.8,0-1.6-0.2-2.5-0.3c-0.2,0-0.4-0.1-0.6-0.1c-1.8-0.4-3.7-0.7-5.3-1l-0.4-0.1
      c-0.8-0.1-1.6-0.3-2.4-0.4c-0.1,0-0.2,0-0.4-0.1l0.7-0.3c1-0.5,2-1,3-1.5l1.9-0.9c1.9-1,3.9-2,5.9-3S482.5,287.2,484.5,286.3z
       M511.3,295.9h-2.8c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1,0-1.6,0.1c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1
      c-1.3-0.1-2.7-0.1-4.4-0.1c-1.1,0-2.1,0-3.3,0c-0.8,0-1.7,0-2.5,0c0.9-0.1,1.7-0.5,2.6-0.8c0.1,0,0.1,0,0.2-0.1
      c0.1,0,0.3-0.1,0.5-0.2c0.8-0.5,1.6-0.8,2.4-0.8c1.2,0,2.3,0.5,3.6,1c0.1,0.1,0.3,0.1,0.4,0.2c0.7,0.3,1.3,0.6,2.1,0.6
      c1,0,2-0.3,3-0.7l0.2-0.1c0.7-0.2,1.4-0.5,2.1-0.8h0.1c0.4-0.2,0.8-0.3,1.1-0.5c0.1-0.1,0.3,0,0.4,0
      C507.8,294.5,509.6,295.2,511.3,295.9z M467.8,297.1c0.9,0.2,1.8,0.4,2.6,0.5c2.4,0.5,4.9,0.9,7.3,1.6c0.6,0.2,1.2,0.2,1.9,0.2
      c0.9,0,1.7-0.1,2.5-0.2c0.7-0.1,1.4-0.2,2.2-0.2c1.6,0,3.2-0.1,4.8-0.2h0.2c1.8-0.1,3.7-0.2,5.6-0.2c0.3,0,0.5,0,0.8,0
      c1.2,0,2.6,0,3.7,0.1c1.1,0,2.3,0.1,3.5,0.1h0.2c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3c2.1,0.1,4.2,0.1,6.2,0.6
      c0.7,0.2,1.4,0.2,2.2,0.2c1.5,0,3-0.3,4.4-0.6c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1
      c-0.5,0.5-1,1-1.5,1.4c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4c-2.6,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8
      c-1.7,0-3.4,0.1-5.1,0.2h-0.2c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1c-1.9,0-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6
      c-0.4,0-0.8-0.3-1.4-0.8c-0.8-0.8-1.7-1.6-2.5-2.4c-1.2-1.2-2.4-2.4-3.7-3.5c-1.7-1.5-3.5-3-5.2-4.4c-1.2-1-2.3-1.8-3.3-2.7
      c-0.6-0.5-1.1-1-1.6-1.6C466.4,296.8,467.1,296.9,467.8,297.1z M526,289.3l-2.6-1.3c-1-0.5-1.9-1-2.9-1.5l-1.2-0.6
      c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5c0.7-1.1,1.4-2.3,2.1-3.4V279c1.2-1.9,2.4-3.8,3.4-5.8c2.6-4.7,5.5-9.2,8.6-13.8
      c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.5,1.1,0.9,1.6,1.5c1.8,2,3.5,4,5.3,6l0.8,1c0.3,0.4,0.6,0.8,0.9,1.1
      c0.6,0.7,1.2,1.5,1.8,2.1c0.5,0.7,0.4,1,0.2,1.5c-0.3,0.6-0.6,1.2-0.9,1.9l-0.1,0.1c-0.6,1.4-1.3,2.8-1.9,4.2
      c-0.3,0.8-0.6,1.6-0.9,2.4c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.6-1.8,3.2-2.8,4.8l-0.3,0.5c-0.4,0.7-0.9,1.3-1.3,1.9
      c-0.3,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.2,0-0.5-0.2C531.8,292.2,528.8,290.7,526,289.3z M537.6,215.6
      c-0.2,0-0.3,0-0.6-0.1c-1.8-0.6-3.5-1.1-5.2-1.8c-1.3-0.5-2.6-1.1-3.9-1.7c-1.1-0.5-2-0.9-3-1.3c-0.4-0.2-0.5-0.3-0.4-0.8
      c0.2-1.3,0.4-2.7,0.6-3.9l0.2-1.1c0.5-0.1,1-0.2,1.5-0.3h0.2c1.5-0.3,3.1-0.7,4.7-1c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1
      c0.5,0.1,1,0.2,1.5,0.3c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2c0,1.8,0,3.7,0,5.3c0,0.8,0,1.6,0,2.3c0,0.2,0,0.4,0,0.5
      c0,0.4,0,0.8,0,1.2C538.1,215.4,537.9,215.6,537.6,215.6z M538.1,218l-1.4,17.2l-16.3-10.5l3.6-11.8c1.5,0.5,2.9,1.2,4.3,1.8
      C531.4,216,534.5,217.4,538.1,218z M524.9,199.7c0.2-0.4,0.6-0.7,0.9-1.1l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2l3.5,3.3
      c-0.2,0-0.5,0.1-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4c-0.1,0-0.4-0.2-0.6-0.6C524.7,200.9,524.6,200.1,524.9,199.7z M533.5,188.6
      L533.5,188.6c-0.4,0.2-0.8,0.5-1.2,0.8l-0.2,0.1c-1.5,1.2-3.1,2.4-4.5,3.7c-1.2,1.1-2.3,2.3-3.4,3.4c-0.4,0.4-0.8,0.8-1.2,1.2
      l-10.9-11.5c0-0.1,0.1-0.1,0.1-0.2l0.8-1.3v-0.1c1.3-2,2.6-4,3.9-6c2.3-3.4,5.2-7.3,9.2-10.2c1.4-1,3.1-1.8,4.6-2.6
      c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1c0.2-0.1,0.5-0.2,0.8-0.2c0,0,0,0,0.1,0l1.6,22.5
      C536.3,186.1,534.8,187.3,533.5,188.6z M513.8,191.2c2.6,2.8,5.4,5.7,8,8.8c1.4,1.5,1.8,3,1.3,4.5c-0.3,1.2-0.5,2.4-0.7,3.7
      c-0.1,0.4-0.1,0.9-0.2,1.3l-0.5-0.2c-0.6-0.2-1.1-0.5-1.7-0.8c-1.7-0.9-3.7-2-5.6-3.2c-1.4-0.9-2.8-1.8-4.4-3
      c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.5,0.6-7,0.9-10.6l0.2-2.3c0.2,0.2,0.4,0.3,0.6,0.5C512.4,189.8,513.1,190.5,513.8,191.2z
       M510.9,205.9c1.2,0.8,2.4,1.6,3.6,2.2c2,1.1,4.2,2.2,6.2,3.1c0.9,0.4,1.1,0.7,0.8,1.6c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9
      c-0.3,0.8-0.5,1.7-0.7,2.5l-0.1,0.3c-0.1,0.5-0.3,1-0.4,1.6c0,0.2-0.1,0.3-0.2,0.5c-0.2-0.1-0.3-0.2-0.5-0.3l-0.3-0.2
      c-0.8-0.5-1.5-1.1-2.2-1.6c-2-1.6-3.9-3.2-5.7-4.7c-0.3-0.2-0.5-0.7-0.5-1.1c-0.1-1.6-0.1-3.1,0-4.5s0.2-2.8,0.3-4.1l0.1-0.2
      c0-0.4,0.1-0.7,0.1-1.1C510.1,205.4,510.5,205.6,510.9,205.9z M511,220.3c0.8,0.6,1.7,1.3,2.5,1.9l0.1,0.1c1.8,1.4,3.7,2.9,5.7,4.2
      c2.1,1.5,4.4,3,6.5,4.2c0.8,0.5,1.6,1,2.4,1.5s1.7,1.1,2.6,1.6c1.9,1.2,3.7,2.3,5.5,3.5c0.2,0.1,0.4,0.6,0.4,1c-0.3,4-0.6,8-0.9,12
      v0.2c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8c-0.5-0.5-1.1-0.9-1.6-1.4c-1.5-1.3-2.9-2.5-4.3-3.8c-0.8-0.7-1.5-1.5-2.3-2.3
      l-0.1-0.1c-0.6-0.7-1.3-1.4-2-2c-2.6-2.5-5.3-5-8.1-7.5c-1.2-1.1-1.9-2.4-2.3-4c-0.6-3-1.4-6-2.1-9c-0.3-1.2-0.6-2.4-0.9-3.5
      c-0.1-0.5-0.2-1-0.4-1.5V220.3L511,220.3z M514.4,246.3c0,0.2,0.1,0.4,0.2,0.6v0.1c-0.5,0.2-0.9,0.4-1.4,0.6h-0.1
      c-1.3,0.6-2.6,1.2-3.9,1.7c-0.5,0.2-1,0.4-1.6,0.6c-0.6,0.2-1.2,0.4-1.9,0.7c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1v0.1
      c-0.2,0.9-0.3,1.8-0.5,2.7c-0.4,1.7-0.8,3.7-1.3,5.6c-0.1,0.2-0.6,0.5-0.9,0.6c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1
      c-0.3-1.5-0.5-2.9-0.9-4.5c-0.2-1.3-0.5-2.5-0.7-3.8c-0.7-4-0.9-4.2-4.9-5.3c-1.1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2
      c-0.5-0.2-1-0.5-1.4-0.8c-0.1-0.1-0.2-0.1-0.4-0.2c0.2-2,1.8-2.7,3.3-3.4c0.8-0.4,1.9-0.4,2.9-0.5c0.4,0,0.9,0,1.3-0.1
      c0.6-0.1,1.2-0.1,1.9-0.2c1.7-0.2,3.4-0.4,5.1-0.5c1.1,0,2.2,0.1,3.4,0.2c0.5,0.1,1,0.1,1.5,0.2l2,0.2c1.6,0.2,3.2,0.3,4.9,0.4
      C512,243.4,514.1,245.2,514.4,246.3z M481.7,239.4c-2.6,2.1-5,4.5-7.4,6.9c-1,1-2.2,2.1-3.3,3.2c-1.6,1.5-3.3,3.1-4.8,4.5
      c-0.7,0.6-1.3,1.3-2,1.9c0-0.3-0.1-0.6-0.1-1c-0.1-0.9-0.3-1.9-0.4-2.9c-0.2-2.2-0.4-4.6-0.5-6.6c-0.1-1.2-0.2-2.3-0.3-3.5
      c0-0.4-0.1-0.8-0.1-1.1v-0.1c-0.1-0.8-0.2-1.6-0.2-2.4c0-0.3,0.3-0.8,0.6-1c1.9-1.3,3.9-2.5,5.8-3.7c3-1.9,6.1-3.9,9.1-5.9
      c2.3-1.6,4.6-3.3,6.8-5l0.1-0.1c1-0.7,2-1.5,2.9-2.2c0.1-0.1,0.3-0.2,0.4-0.3c-0.1,0.3-0.2,0.6-0.2,0.9c-0.3,1.1-0.6,2.3-0.9,3.5
      l-0.3,1.2c-0.5,2.2-1.1,4.5-1.5,6.8C484.9,235.3,484,237.5,481.7,239.4z M463.8,259.2c0.4,0.6,0.9,1.3,1.3,1.9l0.1,0.1
      c1.2,1.7,2.2,3.2,3.2,4.9c0.9,1.5,1.8,3,2.6,4.5c0.6,1.1,1.3,2.4,2,3.5c0.7,1.2,1.4,2.3,2.2,3.4l0.1,0.2c0.5,0.7,0.9,1.5,1.4,2.2
      c1,1.5,1.9,3.1,2.8,4.6c0.3,0.6,0.4,0.9,0.3,1.2c-0.1,0.3-0.4,0.5-0.9,0.8l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2
      c0,0,0,0-0.1,0c-0.3,0-0.5-0.1-0.5-0.2l-1-1.4c-1.6-2.4-3.3-4.8-4.8-7.3c-0.5-0.9-0.9-2-1.3-3c-0.2-0.6-0.4-1.3-0.7-1.9
      c-0.9-2-1.8-4.2-3.1-6.9c-0.4-0.8-0.4-1.2,0.2-1.9c1.1-0.9,2-2.1,2.9-3.2c0.7-0.8,1.3-1.7,2-2.5c1.4-1.5,2.8-3,4.2-4.5l0.4-0.4
      C462.9,260.1,463.3,259.6,463.8,259.2z M454.1,281.2c0.3,0.6,0.6,1.2,0.8,1.9l0.1,0.1c1,2.2,1.9,4.4,3.1,6.5c0.9,1.6,2,3,3.2,4.5
      c0.5,0.7,1,1.3,1.5,1.9c0,0,0,0.1,0.1,0.1c-0.2,1.2,0.6,1.8,1.1,2.2c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7,1.4,1.3,2.1,1.9l0.3,0.3
      c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4c-0.8,0.7-1.6,1.5-2.3,2.2c-1.3,1.2-2.5,2.4-3.9,3.6c-1.2,1.1-2.4,2.1-3.8,3.2l-0.1,0.1
      c-0.4,0.3-0.8,0.7-1.3,1c-0.1-0.9-0.3-1.8-0.4-2.8c-1.7-11.3-3.2-22-3.2-33c0,0.1,0.1,0.4,0.2,0.6l0.2,0.4
      C452.9,278.5,453.5,279.9,454.1,281.2z M457.6,313c0.8-0.7,1.5-1.3,2.2-1.9c0.5-0.5,1.1-0.9,1.6-1.4l0.1-0.1c1.2-1,2.4-2,3.5-3.1
      c0.8-0.8,1.6-1.6,2.4-2.4c0.3-0.3,0.6-0.6,0.9-1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0.1,0.5,0.3l2.6,2.3c1.4,1.3,2.8,2.5,4.2,3.8
      c0.5,0.5,1.1,1,1.6,1.5l0.1,0.1c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5L480,316c-0.6,1.8-1.2,3.8-1.7,5.8
      c-0.4,1.5-0.8,3-1.1,4.3l-0.1,0.2c-0.1,0.6-0.3,1.2-0.4,1.9c-0.1,0.5-0.2,1-0.4,1.5l-0.1,0.4l-20.3-15.9L457.6,313z M478.9,329.9
      c0.3-1.3,0.6-2.5,0.9-3.7c0.2-1,0.5-2,0.7-3l0.1-0.3c0.3-1.5,0.7-3,1.1-4.5c0.3-1.1,0.6-2.3,0.9-3.3c0.1-0.3,0.2-0.5,0.7-0.5h0.1
      c3.6,0.1,7.2,0.2,10.8,0.2h1.7c0.9,0,1.9,0,2.8,0.1h0.1c2.9,0.1,5.9,0.1,8.9,0.1c2.4,0,5-0.1,8.4-0.4c0.6,0,0.7,0.1,0.8,0.8
      c0.8,3,1.5,6,2.3,9c0.4,1.5,0.8,3.1,1.2,4.8l0.5,2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.7,0c-0.7,0-1.5,0-2.2,0
      c-3.3,0-6.8,0.2-10.3,0.6c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3c-2.3-0.3-4.8-0.4-8-0.4c-0.9,0-1.9,0-2.8,0
      c-1.4,0-2.8,0-4.3,0h-0.5c-0.4,0-0.7,0-1.1,0L478.9,329.9z M520.4,319.7c-0.4-1.1-0.8-2.3-1.2-3.5c-0.2-0.4-0.3-0.9-0.5-1.3
      c-0.2-0.7-0.2-1,0.3-1.5c1.4-1.3,2.8-2.6,4.1-3.8l0.1-0.1c0.8-0.8,1.7-1.6,2.5-2.4s1.7-1.5,2.5-2.3c0.6-0.5,1.2-1.1,1.8-1.6
      c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4c1.9,1.9,3.9,3.7,6,5.6c1.4,1.3,2.9,2.5,4.4,3.7c0.6,0.5,1.1,0.9,1.7,1.4
      c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1.1c-0.3-1.1-0.7-2.3-0.9-3.4c-0.2-0.8-0.4-1.7-0.5-2.6
      C520.9,321.9,520.7,320.8,520.4,319.7z M541.1,309.6c-2.1-1.9-4.3-3.9-6.3-5.7l-2.5-2.2c-0.3-0.1-0.3-0.2-0.3-0.2
      c0-0.1,0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5l0.1-0.1c0.4-0.4,0.8-0.7,1.1-1c0.1-0.1,0.2-0.2,0.3-0.2c0.5-0.5,1.2-1,1-2.1
      c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.5,0.7-1,1.1-1.5l0.1-0.1c1-1.3,2-2.7,2.9-4.2c1.1-1.7,1.9-3.6,2.7-5.5
      c0.9-2.1,1.8-4.3,2.6-6.3c0.3-0.6,0.5-1.2,0.8-1.9c-0.1,11.7-1.8,23-3.5,35l-0.1,0.8c-0.2-0.2-0.4-0.3-0.6-0.5
      C542.4,310.7,541.7,310.2,541.1,309.6z M546.3,309.6c0.3-1.4,0.6-2.9,0.8-4.4c0.3-1.6,0.4-3.2,0.6-4.7c0.1-0.5,0.1-1,0.2-1.5
      c0.3-3.1,0.7-6.2,1-9.1l0.1-1.3c0.2-2.3,0.5-4.6,0.7-6.9c0-0.4,0-0.8-0.1-1.1c0-0.3-0.1-0.6-0.1-0.9c0-0.7,0.1-1.3,0.2-2
      c0.1-0.7,0.1-1.5,0.2-2.3c0-0.5-0.1-1-0.1-1.5c0-0.1,0-0.3-0.1-0.4c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1h0.2
      c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4c1.6,0.2,3.3,0.4,5,0.5c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2
      c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.2-0.7,6.3v0.1c-0.3,2.5-0.5,5-0.8,7.5c-0.7,5.8-1.6,11.6-2.4,16.5
      c-0.5,3.4-1.2,6.9-1.8,10.3l-0.5,1.8c-0.1,0.3-0.2,0.7-0.3,1c0,0,0,0,0,0.1c-0.8-0.6-1.5-1.3-2.2-1.9l-0.3-0.3
      c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.8-2.1c-0.8-0.7-1.6-1.5-2.3-2.3l-0.1-0.2c-0.1-0.1-0.3-0.3-0.4-0.4
      c-0.1-0.2-0.2-0.4-0.2-0.6C545.9,311.6,546.1,310.7,546.3,309.6z M567.1,272.1c-1.6-0.2-3.3-0.3-4.9-0.5l-1.8-0.2
      c-2.2-0.2-4.6-0.4-6.9-0.6c-0.8,0-1.5,0-2.3,0.1c-0.5,0-1.1,0.1-1.6,0.1c-0.2,0-0.5-0.1-0.9-0.4c-1.8-1.4-3.2-3.2-4.5-4.9
      c-0.7-0.9-1.4-1.8-2.2-2.7c-1.2-1.3-2.5-2.7-4.2-4.1c-1.2-1-1.2-1-0.8-2.6c0.4-2.2,0.6-4.5,0.8-6.6c0.2-1.8,0.3-3.6,0.4-5.4
      l0.1-1.2c0.1-1.1,0.1-2.2,0.2-3.4v-1.2c0.4,0.1,0.8,0.2,1.1,0.2c1.1,0.2,2.1,0.4,3.1,0.7c2.2,0.6,4.3,1.2,6.4,1.8
      c2.5,0.7,5.3,1.5,8.1,2.2c2,0.5,4,0.9,6.2,1.3c1,0.2,1.5,0.7,1.7,1.9c0.6,4,1.2,8,1.7,11.9l0.6,4.2c0.3,1.7,0.5,3.5,0.8,5.2
      l0.6,4.3L567.1,272.1z M566.5,242.8c-0.3-1.5-0.5-3.1-0.9-4.8c-0.2-1.2-0.4-2.5-0.6-3.7c-0.1-0.8-0.3-1.6-0.4-2.4
      c-0.1-0.8-0.2-1.6-0.4-2.5c-0.2-1.1-0.4-2.2-0.6-3.3c-0.2-0.9-0.4-1.9-0.5-2.8c-0.2-0.9-0.3-1.8-0.5-2.6v-0.2c-0.2-1-0.3-2-0.5-3.1
      c-0.2-1-0.4-1.9-0.7-2.7c-0.4-1.1,0.2-1.8,1.2-2.5c1.3-1,2.7-2,4-2.9l0.1-0.1c1.5-1.1,3-2.2,4.5-3.3c1.7-1.3,3.3-2.5,5.1-3.9
      l0.3-0.2c0.6-0.5,1.2-0.9,1.8-1.4c0.2,0.2,0.5,0.4,0.8,0.6l0.2,0.1c0.9,0.7,1.8,1.4,2.6,2.2c1.9,1.8,3.8,3.7,5.6,5.5l0.1,0.1
      c0.8,0.8,1.6,1.6,2.3,2.3v0.1c0.1,0.1,0.2,0.2,0.3,0.2c2.6,1.4,2.8,3.8,2.6,6.2c-0.3,4.8-0.7,9.9-1.3,16.1c0,0.3-0.2,0.5-0.3,0.6
      c-0.7,0.3-1.5,0.7-2.2,1c-3.1,1.5-6.3,3-9.5,4.3c-2.6,1.1-5.3,2.1-7.9,3l-0.2,0.1c-0.7,0.3-1.5,0.5-2.2,0.8c-0.5,0.2-1,0.3-1.5,0.2
      C567,243.7,566.6,243.4,566.5,242.8z M591.6,255.5c0.1-0.9,0.1-1.6,0.2-2.3c0.5-4.2,0.8-7.6,1.2-10.7c0.2-2.3,0.4-4.5,0.6-7.1
      c0.1-0.8,0.1-1.6,0.2-2.4v-0.1c0.2-2.6,0.4-5.3,0.5-8c0.1-2.6,0.2-5.3,0.2-7.9v-0.2c0-1.1,0-2.3,0.1-3.4c0-0.2,0-0.5,0-0.8v-0.2
      l0.6,0.1c0.7,0.1,1.4,0.3,2.1,0.5c0.2,0,0.4,0.1,0.6,0.1c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.2,0,0.8-0.1,1.3c-0.1,0.2-0.1,0.5-0.2,0.7
      c-0.2,1.6-0.5,3.3-0.8,4.9v0.1c-0.5,3.4-1.1,6.9-1.6,10.3c-0.3,1.9-0.5,3.8-0.6,5.7c-0.2,1.5-0.3,3.1-0.5,4.6
      c-0.3,2.9-0.8,5.5-1.2,8.1c-0.1,0.5-0.2,1.1-0.3,1.6c-0.4,2.2-0.7,4.4-1.3,6.6c-0.2,1-0.4,2.1-0.6,3.1c-0.5,2.5-1,5.1-2.1,7.4
      c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5v-0.1c0.1-2.1,0.2-4.2,0.8-6.1C591.2,260,591.4,257.7,591.6,255.5z M616.3,218.9
      c-0.4-0.1-1-0.2-1.4-0.3c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.2,0-3.6,0c-0.8,0-1.6,0-2.4,0c-0.6,0-1.3,0-2,0
      c0.1-0.3,0.3-0.6,0.4-0.9l0.1-0.2c0.5-1.1,0.9-2.1,1.4-3.1c1-2,2.1-3.9,3-5.7c0.6-1.1,1.2-2.3,1.8-3.4c0.1-0.2,0.2-0.5,0.3-0.7
      c0.1-0.3,0.3-0.6,0.4-0.9c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2c0.3,0,0.7,0,1,0c0,0.5,0,1.1,0,1.6c0,1.4,0,2.8,0,4.1
      c-0.1,2.7-0.2,5.4-0.3,8.1l-0.1,1.3C617.7,218.6,617.3,218.9,616.3,218.9z M610.5,202.4c-2.3,4.4-4.7,9.1-7.5,14.8
      c-0.6,1.2-1.1,2.4-1.6,3.6c-0.3,0.7-0.6,1.4-1,2.2c0,0.1-0.1,0.1-0.1,0.2l5.4-27.7c0.3,0.3,0.6,0.5,0.8,0.7
      c1.5,1.7,2.8,3.4,4.1,4.9C610.8,201.4,610.9,201.7,610.5,202.4z M606.3,184.5v0.2c-0.5,2.3-1,4.6-1.5,6.9c-0.2,1-0.5,2-0.9,3
      c-0.3,1-0.6,2-0.9,3c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2c-0.3,0-0.8-0.1-1.3-0.2c-0.2,0-0.5-0.1-0.8-0.2
      c-0.5-0.1-0.9-0.3-1.4-0.5l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2c1.1-4.5,2.2-8.6,3.2-12.7c0.5-1.8,0.9-3.5,1.4-5.3
      c0.9-3.4,1.8-6.8,2.6-10.3c0.4-1.7,0.7-3.4,1-5.1c0.2-0.9,0.3-1.7,0.5-2.6c0.1-0.6,0.3-1.3,0.4-1.9l0.1-0.4l0.4,0.4
      c0.5,0.7,1,1.2,1.5,1.8c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.8,0.8,1.1c1.1,1.1,1.1,1.9,0.7,3.5
      C607.4,179.6,606.8,182.1,606.3,184.5z M603.9,156.8c-0.3,2.1-0.7,4.2-1,6.4c-0.1,0.8-0.3,1.5-0.4,2.3c-0.2-0.2-0.4-0.4-0.5-0.6
      v-0.1c-0.7-0.8-1.4-1.6-2-2.3c-2.6-2.8-6.1-6.5-9.6-10.3c-1.5-1.6-3.1-3.3-4.6-4.7l-0.2-0.2c-0.9-1-1.9-1.9-2.8-2.9
      c-0.4-0.4-0.7-0.8-1.1-1.2l-0.1-0.1c-0.6-0.7-1.4-1.5-2.1-2.2c-0.5-0.4-0.5-0.9-0.4-1.4c0.3-1.3,0.7-2.6,1-3.9v-0.1
      c0.7-2.6,1.4-5.4,2-8.1c0.4-1.7,0.6-3.4,0.8-5.1c0.1-0.7,0.2-1.5,0.3-2.2c0.1-0.7,0.2-1.4,0.3-2.2v-0.1c0.3-1.9,0.6-3.8,0.8-5.7
      c0.1-1,0.2-2,0.3-3c0.1-0.7,0.1-1.5,0.2-2.2c0-0.4,0.1-0.8,0.2-1.3v-0.1c0.3,0.3,0.5,0.6,0.8,0.9l0.1,0.1c1,1.2,2,2.3,2.9,3.4
      c2.8,3.9,5.4,7.4,7.7,10.8c1,1.4,1.9,2.9,2.8,4.3l0.1,0.1c0.5,0.9,1.1,1.7,1.7,2.6c0.3,0.5,0.7,1,1,1.6c1.2,1.9,2.5,3.8,3.8,5.6
      c0.7,0.9,0.9,1.7,0.7,2.9c-0.2,1.2-0.3,2.4-0.5,3.6c-0.2,1.5-0.4,3.1-0.7,4.6c-0.2,1.2-0.3,2.4-0.5,3.6l-0.1,0.5
      C604.5,152.3,604.2,154.6,603.9,156.8z M582.6,108l-0.1,0.8c-0.8,5.9-1.7,12.1-2.7,18.1c-0.5,2.7-1.1,5.4-1.8,8v0.1
      c-0.3,1.1-0.5,2.2-0.8,3.2c-0.2,0.7-0.3,1.4-0.5,2l-0.1,0.5c-0.4-0.2-0.9-0.5-1.3-0.7l-0.7-0.4c-1.8-1-3.6-2-5.3-3
      c-1-0.6-2.1-1.1-3.1-1.6h-0.1c-1.1-0.6-2.4-1.2-3.6-1.9c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.1-0.6-2.4-1.4-3.6-2
      c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5c-1.6-0.9-3.2-1.9-4.7-2.8l-0.1-0.1c-0.6-0.4-1.2-0.7-1.8-1.1
      c-0.1-0.1-0.2-0.2-0.3-0.2c-0.4-0.1-0.7-0.2-0.8-0.3c-0.2-0.2-0.1-0.7,0-1.2c0-0.2,0.1-0.4,0.1-0.6c0.1-1.8,0.2-3.6,0.3-5.6
      c0.1-1.4,0.1-2.7,0.2-4.1l0.1-1.4c0-0.9,0.1-1.8,0.1-2.6c0.1-1.9,0.2-3.7,0.3-5.6c0.4-6.5,0.8-13,1.1-19.5c0.4,0.2,0.7,0.4,1.1,0.6
      l0.4,0.2c0.9,0.6,2.2,1.3,3.4,2l2.2,1.3h0.1c2.7,1.6,5.6,3.3,8.3,5c4.5,2.8,7.8,5,10.9,7.1c1.8,1.3,3.6,2.6,5.4,3.9
      c1.7,1.3,3.7,2.8,5.7,4.1C582.8,105.9,582.7,106.8,582.6,108z M542.8,124.1l0.3,0.2c1.3,0.8,2.6,1.7,4,2.5c0.8,0.9,2,1.6,3.2,2.2
      l0.6,0.3c1.7,0.9,3.4,1.8,5.1,2.7l1.8,0.9c0.7,0.4,1.4,0.7,2.1,1.1l1,0.5c1.8,1,3.6,1.9,5.4,2.9c1,0.5,1.9,1.1,2.9,1.6l0.3,0.1
      c1.9,1,3.8,2.1,5.8,3.1c1.1,0.5,1.2,1.2,1,2.2c-0.2,1.5-0.5,3-0.7,4.5c-0.6,4.3-1.3,8.7-1.9,13.2c-0.4,2.7-0.6,5.5-0.8,8.2v0.1
      c-0.1,1.3-0.2,2.6-0.4,3.9c0,0.2,0,0.3-0.1,0.5l-0.7-0.9c-0.7-0.9-1.4-1.9-2.2-2.9c-2-2.5-4-4.9-5.9-7.3l-0.5-0.7
      c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0c-0.4,0-0.8,0-1.2,0
      c-0.8,0-1.6,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2c-0.6,0-1.2,0.1-1.8,0.1
      c-0.3,0-0.7,0-1.1,0.1h-0.5l2.1-37.2C541.9,123.6,542.3,123.8,542.8,124.1z M539.1,123.5v0.2c-0.1,1.1-0.2,2-0.3,3.1
      c-0.1,1.6-0.2,3.3-0.2,4.9c-0.1,1.3-0.1,2.7-0.2,4.1c-0.1,1.9-0.2,3.9-0.3,5.8c-0.1,1.6-0.2,3.1-0.3,4.7c-0.1,1.5-0.1,2.9-0.2,4.4
      v0.3c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.6c-0.1,1.8-0.2,3.7-0.3,5.6c0,0.2-0.3,0.5-0.6,0.6c-2.4,1.1-4.8,2.2-7.2,3.2l-0.4,0.2
      c-1,0.4-1.9,0.8-2.9,1.3c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.1,5.5-6.1,8.2l-1,1.4c-0.9,1.2-1.6,2.5-2.3,3.7l-0.1,0.2
      c-0.4,0.7-0.7,0.9-1.2,0.9c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1c-1-0.3-1.9-0.6-2.9-1c-0.4-0.1-0.9-0.3-1.4-0.4
      c-0.2,0-0.3-0.1-0.5-0.1v-65.9c13.8,0.9,27.1,3.4,38.3,5.6C539.1,122.8,539.1,123.2,539.1,123.5z M510,225.6
      c0.8,3.6,1.6,7.3,2.4,10.8v0.1c0.3,1.2,0.5,2.5,0.8,3.7c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1c-0.1,0-0.2,0-0.4,0
      c-0.9-0.1-1.8-0.2-2.7-0.3h-0.1c-2.7-0.3-5.4-0.6-8.2-0.6c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2
      c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.2c0.8-3.5,1.6-7.5,2.5-11.4
      c0.5-1.9,1-3.9,1.6-5.8l0.4-1.4c0.7-2.1,1.1-2.2,2.5-2.3c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1
      c0.2,0,0.5,0,0.7,0h2.9v-0.1c0.5,0,1,0.1,1.4,0.1c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.4
      C508.9,221.4,509.5,223.5,510,225.6z M490.1,212.4c-0.1,0.7-0.1,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.4-3.7,2.9-6.2,4.9
      c-0.5,0.4-1,0.8-1.5,1.1l-0.4,0.3c-0.1-0.2-0.1-0.3-0.2-0.5c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3v-0.1
      c-0.5-1.6-0.9-3.3-1.5-5c-0.3-0.8,0-1,0.7-1.3c0.4-0.2,0.7-0.4,1.1-0.5l0.2-0.1c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.2-1.3,3.2-2
      l0.2-0.1c0.5-0.3,0.9-0.6,1.4-0.9c0,0,0.1-0.1,0.2-0.1c0,0.4,0.1,0.7,0.1,1.1v0.2c0.1,1.4,0.3,2.7,0.4,4
      C490.1,211.1,490.1,211.8,490.1,212.4z M489.7,185.3c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.3-3.6-3.5-5.4l-0.1-0.2
      c-0.6-1-1.3-1.9-1.9-2.9c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.1l-0.1-0.1c-0.4-0.2-0.8-0.4-1.2-0.5
      c-1-0.5-2.1-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.5-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7c-0.1-1.9-0.2-3.8-0.3-5.6
      c-0.1-2-0.2-4-0.4-5.9v-0.3c-0.1-2-0.3-4-0.4-6.1c-0.1-1.1-0.1-2.2-0.1-3.3v-0.1c0-1.2,0-2.3-0.1-3.5c-0.1-1.6-0.3-3.3-0.4-4.9
      v-0.1c-0.1-0.8-0.1-1.6-0.2-2.3c0-0.1,0-0.2,0-0.3c15.1-3.1,26.9-4.8,38.2-5.6v65.8l-2.1,0.7h-0.1c-2,0.6-3.8,1.3-5.7,1.9
      C490.1,185.3,489.9,185.3,489.7,185.3z M416.9,126.1c0.7,3.2,1.5,6.4,2.2,9.5l0.1,0.3c0.3,1.2,0.6,2.4,0.9,3.6
      c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5,5.4l-0.1,0.1c-1.3,1.4-2.6,2.8-4,4.2c-1.5,1.6-3,3.1-4.4,4.5l-0.1,0.1
      c-1.5,1.5-3,3-4.5,4.6c-1.2,1.2-2.3,2.5-3.4,3.7c-0.5,0.6-1.1,1.2-1.6,1.8c0,0.1-0.1,0.1-0.2,0.2l-0.7-4.5v-0.3
      c-0.5-3.8-1.1-7.4-1.6-11.1c-0.2-1.2-0.3-2.5-0.5-3.8c-0.4-2.6-0.7-5.4-1.2-8.1c-0.3-1.5,0.4-2.5,1.2-3.6c0.1-0.2,0.3-0.4,0.4-0.6
      c1.8-2.7,3.6-5.4,5.3-7.9l0.1-0.2c1.6-2.4,3.4-5.1,5.2-7.7l0.7-1c1.7-2.4,3.4-4.9,5.2-7.2c0.8-1,1.7-1.9,2.6-2.9
      c0.3-0.3,0.5-0.6,0.8-0.9c0.1,0.8,0.2,1.5,0.3,2.2l0.1,0.6c0.3,2.6,0.6,5.1,0.9,7.6l0.2,0.2C415.8,119.6,416.3,122.9,416.9,126.1z
       M392.4,196.6c0.4-0.4,0.7-0.7,1.1-1.1L399,223c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.5c-0.3-0.8-0.7-1.6-1-2.3
      c-1.8-4.2-3.8-8.6-6.3-12.6l-0.2-0.3c-0.5-0.8-1.1-1.7-1.3-2.6c-0.3-0.7,0.1-1.7,0.5-2.2C390.2,198.8,391.3,197.7,392.4,196.6z
       M389.3,208c1.1,2.2,2.2,4.6,3.2,6.7l0.8,1.7c0.2,0.5,0.4,1,0.7,1.5l0.2,0.4c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0-1,0
      c-0.5,0-0.8,0-1.2,0h-0.8c-2.3,0.1-4.8,0.2-7.2,0.4c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.9
      c0-1.1,0-2.5,0-3.9c0-0.5,0-1,0-1.6c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.9,0.5,1.1,0.9
      C387.6,204.8,388.4,206.3,389.3,208z M407.9,233.8c-0.3-3.6-0.5-7.2-0.8-11.2l-0.1-1.5c-0.3-1.8-0.4-3.7-0.5-5.6
      c0-1.3,0.5-2.4,1.7-3.4c1.6-1.4,3.1-2.9,4.6-4.3c0.9-0.9,1.9-1.9,2.9-2.8c1.3-1.2,2.6-2.3,3.8-3.5c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1
      c2.4,1.8,4.7,3.4,6.9,5.1c1.3,1,2.7,2,3.9,3l0.1,0.1c0.8,0.6,1.5,1.2,2.3,1.8c0.8,0.6,1,1.4,0.8,2.5c-0.4,2-0.8,4.1-1.2,6l-0.1,0.5
      c-0.1,0.6-0.2,1.2-0.3,1.7l-0.3,1.8c-0.3,1.8-0.6,3.3-0.9,4.9c-0.3,1.6-0.6,3.2-0.8,4.8l-0.1,0.5c-0.4,2.3-0.8,4.6-1.2,7
      c-0.3,1.6-0.9,1.8-1.5,1.8c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2c-3-1.3-6.1-2.7-9-4.1l-1.9-0.9
      C408.1,234.4,407.9,234.1,407.9,233.8z M432.7,256c0.3-2.5,0.6-4.7,1-6.9c0.1-0.9,0.3-1.8,0.4-2.7c0.1-0.9,0.6-1.4,1.7-1.7
      c4.8-1.1,8.8-2,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1c1.2-0.3,2.5-0.7,3.7-1c0.6-0.2,1.3-0.3,2.1-0.5h0.1c0.2,0,0.3-0.1,0.5-0.1
      c0,0.3,0,0.6,0,0.9v0.5c0.1,1.3,0.1,2.6,0.2,3.9v0.2c0.2,3.4,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4c0.3,0.6,0.1,0.9-0.3,1.4
      c-0.3,0.3-0.7,0.6-1,1c-1,0.9-2.1,1.9-3,3c-1,1.2-2,2.4-3,3.6c-0.8,1-1.5,1.9-2.3,2.7l-0.3,0.4c-0.7,0.8-1.4,1.6-2.2,2.1
      c-0.3,0.2-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0s-0.6,0-0.9,0h-0.1c-0.6,0-1.3,0-1.9,0c-0.6,0-1.3,0-1.9,0c-0.6,0-1.2,0-1.7,0.1
      c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4c0.2-1.1,0.3-2.2,0.5-3.3l0.3-2C431.7,263.3,432.2,259.7,432.7,256z M447.8,340.3
      c-1.2-0.8-2.4-1.6-3.7-2.4c-0.9-0.6-1.9-1.2-2.8-1.7c-0.8-0.5-1.7-1-2.5-1.5c-0.8-0.5-1.5-1.1-2.1-1.7c0,0-0.1-0.2-0.1-0.5
      c0.2-0.4,0.2-1,0.2-1.4c0-0.1,0-0.2,0-0.3l18-15l0.5,0.4c0.6,0.5,1.2,1,1.8,1.5c0.8,0.7,1.6,1.3,2.4,2c1.7,1.5,3.5,3,5.4,4.4
      c0.9,0.8,1.9,1.5,2.9,2.2c0.8,0.6,1.7,1.2,2.5,1.9c0.9,0.7,1.8,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2c0.4,0.3,0.4,0.5,0.2,1
      c-0.4,1.5-0.8,2.9-1.2,4.3v0.2c-0.6,2.2-1.2,4.4-1.8,6.6c-0.7,2.3-1.5,4.7-2.2,7l-0.5,1.4c-0.2,0.6-0.5,0.6-0.6,0.6
      c-0.2,0-0.4-0.1-0.6-0.2c-1.1-0.7-2.1-1.4-3.2-2c-1.4-0.9-2.9-1.9-4.5-2.8c-1.2-0.7-2.4-1.5-3.6-2.2l-0.3-0.2
      c-1.5-0.9-3.1-1.8-4.6-2.8C450.8,342.3,449.3,341.3,447.8,340.3z M525,338.4v-0.1c-0.2-0.9-0.4-1.7-0.7-2.6
      c-0.1-0.2-0.1-0.4-0.1-0.6c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.3-1,2.7-2,4-3c1.5-1.2,3.5-2.6,5.3-4
      c1.3-1,2.6-2.1,3.8-3.1l0.1-0.1c0.7-0.6,1.3-1.1,2-1.7c1.1-0.9,2.2-1.8,3.2-2.7l1.5-1.2c0.4,0.3,0.7,0.6,1.1,0.9l0.7,0.6
      c1.4,1.1,2.7,2.2,4,3.3c0.7,0.6,1.4,1.2,2.1,1.8l0.1,0.1c0.7,0.6,1.4,1.2,2.2,1.8c2.9,2.4,5.3,4.3,7.7,6.1c0.4,0.3,0.4,0.4,0.3,0.7
      c-0.3,0.2-0.3,0.5-0.3,0.7c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.3,0.2c-3,2-6.2,4-9.3,6c-1.2,0.7-2.4,1.5-3.5,2.1
      l-0.2,0.1c-0.7,0.4-1.4,0.8-2.1,1.2l-0.5,0.3c-1.4,0.8-2.8,1.7-4.2,2.6c-1.3,0.8-2.7,1.7-4,2.5l-0.3,0.2c-1,0.6-2,1.3-2.9,1.9
      c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.5-1.7-0.8-2.5c-0.7-2-1.4-4-2-6.1
      C526,342.4,525.5,340.4,525,338.4z M588.4,260.4c-0.2,2.2-1.4,3.2-3.2,4.3c-2.5,1.3-4.9,2.8-7.3,4.2l-0.9,0.6
      c-0.9,0.5-1.7,1.1-2.5,1.6h-0.1c-0.6,0.4-1.1,0.7-1.7,1.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0s-0.1,0-0.2-0.4
      c-0.2-1.7-0.5-3.5-0.7-5.2V267c-0.1-0.7-0.2-1.4-0.3-2c-0.1-0.9-0.3-1.8-0.4-2.6l-0.1-0.5c-0.3-2.2-0.7-4.8-1.1-7.2
      c-0.3-1.8-0.6-3.6-0.9-5.3v-0.1c-0.2-0.9-0.3-1.8-0.5-2.7c0,0,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3c1.1-0.2,2.1-0.5,3.1-0.8
      c4.4-1.3,8.7-3.1,12.8-5.1c0.6-0.3,1.2-0.6,1.8-0.9l0.2-0.1c1.2-0.6,2.4-1.2,3.6-1.8c0.4-0.2,0.8-0.3,1.2-0.5c0.1,0,0.1,0,0.2-0.1
      L591,240c-0.1,1.6-0.3,3.2-0.4,4.7c-0.1,1-0.2,2-0.3,3c-0.2,2.7-0.5,5.5-0.8,8.3c-0.1,0.7-0.3,1.3-0.5,2
      C588.7,258.8,588.5,259.6,588.4,260.4z M604.2,262.8c0,1.2,0,2.4,0,3.6c0,1,0,2.1,0,3.1v0.7c0,2.2-0.1,4.4-0.1,6.6
      c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9h-0.4c-1.7,0.2-3.4,0.4-5.1,0.7c-0.5,0.1-0.8,0-0.9-0.1c-0.2-0.1-0.2-0.3-0.1-0.6
      c0.4-1.2,0.9-2.5,1.3-3.7c0.3-0.9,0.7-1.8,1-2.8c0.9-2.6,1.7-5.2,2.7-8l0.1-0.2c0.4-1.1,0.7-2.2,1.1-3.4l9.3,0.7V262.8z
       M604.5,259.1c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1c-0.1,0-0.2,0-0.3-0.1l2.9-17c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0
      c3.7,0,7.9,0.1,12.1,1.1l-0.1,1c-0.1,1.1-0.2,2.2-0.3,3.3c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3
      c-2,2.4-4.1,4.6-5.8,6.5C605,258.9,604.7,259.1,604.5,259.1z M619.6,249.9L619.6,249.9c0,0.4,0,0.8,0,1.1c-0.3,1.6-0.6,3.2-0.9,4.8
      c-0.3,1.6-0.6,3.2-0.9,4.8c-0.1,0.5-0.1,1-0.2,1.5l0.2-0.1c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.8-0.4,1.8-0.9,2.5
      c-1.4,2.1-3.4,3.8-5.4,5.3c-0.8,0.6-1.6,1.2-2.3,1.9c-0.6,0.5-1.2,1.1-1.9,1.6l-0.4,0.3v-1.7c0-1.7,0-3.3,0-5
      c0-1.2-0.1-2.5-0.1-3.7c0-1.3-0.1-2.5-0.1-3.6c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8l0.1-0.2c1.3-1.4,2.6-2.9,3.8-4.4
      c0.6-0.7,1.1-1.4,1.6-2.2c0.2-0.3,0.4-0.6,0.6-0.9c0.2-0.4,0.5-0.5,0.7-0.5s0.3,0.1,0.6,0.2c0.6,0.3,1.1,0.5,1.7,0.8
      c0.2,0.1,0.3,0.1,0.5,0.2C619.6,249.8,619.6,249.8,619.6,249.9z M620.1,247l-2.5-0.7l1.1-13.5l2.9,1.4L620.1,247z M631.5,204.9
      c-0.1,1.2-0.3,2.1-0.6,2.9c-0.6,1.8-1.3,3.6-1.9,5.2c-0.5,1.5-1,2.8-1.5,4.1c-0.7,2-1.4,3.9-2.1,6.1l-0.1,0.2
      c-0.4,1.2-0.9,2.5-1.3,3.7c-0.4,1.1-0.8,2.3-1.2,3.4l-0.5,1.5l-3-1.2c-0.4-0.2-0.5-0.4-0.4-0.8v-0.4c0.5-5.1,1.1-10.3,1.5-15.4
      c0.1-1.6,0.1-3.2,0.1-4.8v-0.1c0-0.9,0-1.8,0-2.7c0-0.8,0.1-1.6,0.1-2.3c0-0.5,0.1-1,0.1-1.5c0-0.4,0.2-0.5,0.6-0.5
      c0.1,0,0.2,0,0.3,0c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.2,0.3,1.9,0.5c0.8,0.2,1.5,0.4,2.3,0.5S631.6,204.5,631.5,204.9z
       M608.9,187.3l2.4,0.6c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2c2.4,0.6,4.6,1.2,6.1,3.5c1.5,2.2,3.1,4.3,4.7,6.4
      l0.1,0.1c0.5,0.7,1.1,1.4,1.6,2.1c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.5-2.8-0.7c-2.8-0.3-5.3-0.5-7.5-0.6c-0.1,0-0.3,0-0.4,0
      c-1.2,0-2.4,0.1-3.6,0.2h-0.1c-0.6,0.1-1.3,0.1-1.9,0.1c-0.1,0-0.2-0.1-0.2-0.1c-2-2.3-4-4.8-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5
      c0.4-0.9,0.6-1.9,0.7-2.8l0.1-0.5c0.1-0.7,0.4-0.7,0.6-0.7C608.7,187.3,608.8,187.3,608.9,187.3z M609.9,128.1
      c0.1-0.6,0.1-1.2,0.2-1.7c0.2-1.1,0.3-2.1,0.5-3.2l0.2-1.1l0.2,1.5c0.3,1.7,0.5,3.3,0.8,5l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5
      c0.2,1.3,0.3,2.7,0.5,4c0.3,2.3,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8l-0.1,0.4c-0.5,2-0.8,4-1.2,6c-0.2,1.1-0.4,2.4-0.7,3.6
      c-0.3,1.7-0.7,3.4-1.1,5c-0.3,1.2-0.5,2.4-0.8,3.7c-0.2,0.9-0.3,1.7-0.5,2.6l-0.1,0.6l-0.5-1c-1.1-1.3-2.2-2.6-3.2-3.9
      c-0.2-0.2-0.3-0.7-0.2-1.1c0.3-1.8,0.5-3.5,0.8-5.2c0.3-1.8,0.6-3.9,0.9-5.9c0.2-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.9,0.3-2.8
      c0.2-1.3,0.4-2.6,0.6-4.1c0.1-1,0.3-1.9,0.4-2.9c0.1-0.9,0.2-1.9,0.3-2.7l0.1-1.1c0-0.1,0-0.1,0-0.2c0.7-1.1,0.6-2.3,0.6-3.4
      c0-0.8-0.1-1.6,0.1-2.3C609.6,131,609.8,129.6,609.9,128.1L609.9,128.1z M586,95c0.3-2.7,0.5-5.4,0.8-8.1c0.2-1.6,0.3-3.3,0.4-4.8
      c0.1-1.1,0.2-2.3,0.2-3.4c0.1-0.8,0.2-1.6,0.3-2.4c0-0.2,0.1-0.4,0.1-0.7c0.3,0.3,0.6,0.7,0.9,1.1c0.9,1,1.8,2.1,2.7,3.2
      c1.6,2.1,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c1,1.6,1.8,3.3,2.7,4.9c0.5,0.9,0.9,1.8,1.4,2.7c1.6,2.9,2.6,6.2,3.3,10.4
      c0.2,1.8-0.1,3.5-0.3,5.1c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.9-0.8,5.7-1.3,8.5v0.1c-0.2,0.9-0.3,1.9-0.5,2.9
      c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-1.1-1.3-2.1-2-3.2c-1.2-2-2.5-4-3.8-6c-1.1-1.7-2.3-3.3-3.5-4.9c-0.7-0.9-1.4-1.8-2-2.8
      c-0.5-0.8-1.1-1.5-1.6-2.3c-0.9-1.3-1.9-2.7-2.9-4c-1.1-1.4-2.3-2.7-3.4-4c-0.7-0.8-1.4-1.5-2-2.3c-0.1-0.2-0.2-0.5-0.2-0.7
      c0.2-2.2,0.4-4.5,0.6-6.7V95z M542,40.9l0.2,0.1c1,0.5,2,1,3,1.5c1.6,0.7,3.1,1.4,4.7,2.2l0.1,0.1c2.8,1.3,5.7,2.6,8.6,4
      c1.4,0.7,2.7,1.4,4,2.1h0.1c1.5,0.8,3,1.7,4.5,2.4c3.3,1.5,6.1,3.9,9,7.7c1.3,1.7,2.7,3.4,4,5.1c1,1.3,2.1,2.6,3.1,3.9
      c0.2,0.3,0.5,0.6,0.7,1c0.4,0.6,0.8,1.2,1.3,1.7c0.8,0.5,0.7,1.1,0.5,1.9c0,0.2-0.1,0.4-0.1,0.6c-0.3,2.6-0.5,5.1-0.8,7.6
      c-0.1,1.1-0.2,2.1-0.3,3.2l-0.5,5.1c-0.3,3.3-0.7,6.6-1,9.9c-0.1,0.6-0.1,1.1-0.1,1.7v0.2c0,0.1,0,0.2,0,0.3
      c-0.2-0.2-0.4-0.3-0.6-0.5l-0.2-0.2c-0.8-0.6-1.6-1.3-2.5-1.9c-0.5-0.4-1-0.7-1.5-1.1l-0.3-0.2c-2.1-1.5-4.3-3.1-6.5-4.6
      c-5.6-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7c-0.1,0-0.2-0.3-0.2-0.5
      c-0.1-1-0.1-1.9,0-2.7l0.1-2.4v-0.1c0.2-3.8,0.4-7.7,0.6-11.5c0.2-2.4-0.2-4.8-0.6-7.1c-0.1-0.4-0.1-0.8-0.2-1.2
      c-0.4-2.4-0.9-4.9-1.5-7.3l-0.2-0.9c-0.2-0.8-0.4-1.6-0.6-2.3c-0.1-0.4-0.3-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.1-0.6
      C541.5,40.6,541.8,40.7,542,40.9z M500.8,31.3c0.3,0,0.6,0,0.8,0c0.4,0,0.7,0,1.1,0c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2
      c1.4,0.1,2.9,0.2,4.3,0.3c1.5,0.1,3,0.2,4.5,0.2c1.7,0.1,3.3,0.2,4.8,0.3c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1
      c1.5,0.6,3,1.2,4.5,1.9c0.4,0.2,0.8,0.7,1,1.3c0.5,1.7,1,3.5,1.4,5.2c1.2,5,2.2,9.5,2.4,14.1c0,3.1-0.2,6.2-0.4,9.1
      c-0.1,1.3-0.2,2.6-0.2,3.9c-0.1,1.2-0.1,2.5-0.2,3.7l-0.1,1.3c-5.9-1.4-11.8-2.9-17.5-4.4h-0.2c-7.6-2-15.5-4-23.4-5.8V31.3z
       M500.8,70.2c0-0.4,0.1-0.7,0.1-1l41,10.3l-2.3,41.1c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.4-0.3-2.7-0.6-4-0.9
      c-2.9-0.6-5.7-1.1-8.1-1.5c-1.8-0.3-3.9-0.7-5.9-1c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3
      c-0.9-0.1-1.7-0.1-2.6-0.2c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9c0-1.9-0.1-3.9-0.1-5.8v-0.1c0-1.7-0.1-3.6-0.1-5.5
      c0-1.4,0-2.8,0-4.1v-0.1c0-1.2,0-2.4,0-3.6c0-1,0-2-0.1-3v-0.1c0-1.1-0.1-2.2-0.1-3.2C500.6,72.1,500.7,71.2,500.8,70.2z
       M499.5,184.4c0.3,0,0.6,0.1,1,0.2l0.2,0.1c1,0.3,2.1,0.7,3.1,1l0.4,0.4c1.5,0.5,2.8,0.9,4.2,1.3c0.5,0.1,0.6,0.3,0.5,0.8
      c-0.4,4.7-0.8,9.5-1.2,14.3c-0.2,2.3-0.4,4.6-0.5,6.9l-0.1,0.9c-0.1,1.1-0.2,2.2-0.2,3.3c0,0.1,0,0.2-0.1,0.4l-0.4-0.1
      c-1.1-0.2-2.3-0.3-3.4-0.5c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0-1.3,0-1.9,0h-0.1c-1.6,0-3.3,0.1-5,0.1c-0.9,0-1.7,0.1-2.6,0.2
      c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1c-0.1-1.2-0.1-2.3-0.2-3.4c-0.2-1.8-0.3-3.7-0.5-5.5c-0.2-2.1-0.4-4.6-0.7-7.1
      c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6c3.3-1.1,6.2-2,8.8-2.8C499.4,184.4,499.4,184.4,499.5,184.4z M458.3,48.4
      c0.3-1.8,0.7-3.6,1.1-5.4c0.2-1.1,0.6-2.2,1.1-3.5c0.1-0.3,0.5-0.7,0.9-0.8c3.8-1.6,7.6-3.2,10.8-4.6s6.8-2.1,11-2.2
      c2.5-0.1,5.1-0.2,7.6-0.4h0.1c1.6-0.1,3-0.2,4.4-0.2c0.4,0,0.8,0,1.3,0c0.3,0,0.6,0,0.9,0h0.2c0.3,0,0.6,0,0.9,0v35.9h-0.2
      c-7.9,1.8-15.8,3.8-23.5,5.8c-5.7,1.5-11.6,3-17.5,4.4c0-0.1,0-0.3,0-0.4v-0.3c-0.1-0.8-0.2-1.6-0.2-2.4c-0.1-1.4-0.1-2.9-0.2-4.3
      c0-1.4-0.1-2.8-0.2-4.3v-0.6c-0.2-3.6-0.4-7.3,0.4-10.9c0.4-1.9,0.8-3.8,1.1-5.7V48.4z M414,72.5c0.7-0.9,1.4-1.8,2-2.7
      c1.1-1.5,2.2-3,3.4-4.5c3-3.6,5.7-6.6,8.4-9.4c0.6-0.6,1.5-1.1,2.4-1.6c0.4-0.2,0.8-0.4,1.2-0.6c5.1-3.1,10.4-5.5,14.9-7.6
      c2.7-1.2,5.3-2.5,8-3.8l0.4-0.2c1.1-0.5,2.1-1,3.2-1.5c0.1,0,0.1-0.1,0.2-0.1l-0.3,0.2c-0.2,0.6-0.3,1.2-0.5,1.8
      c-0.2,0.7-0.4,1.3-0.5,2c-0.4,1.3-0.7,2.7-1,4.1c-0.2,1-0.4,2.1-0.6,3.2c-0.1,0.9-0.3,2-0.5,2.9c-0.6,3-0.5,6-0.3,9
      c0,0.4,0,0.9,0.1,1.3c0.1,1.9,0.2,3.9,0.3,5.5c0.1,1.5,0.2,2.9,0.2,4.4c0,1,0,1.9,0,2.8c0,0.2-0.2,0.5-0.3,0.6
      c-1.5,0.8-3,1.7-4.4,2.5c-2.2,1.2-4.4,2.5-6.6,3.8c-1.8,1.1-3.6,2.2-5.3,3.4c-0.9,0.6-1.7,1.1-2.6,1.7c-0.8,0.5-1.7,1.1-2.5,1.6
      l-0.1,0.1c-1.5,0.9-3.4,2.1-5.1,3.3c-2,1.3-4,2.8-5.9,4.2l-0.5,0.4c-1.3,1-2.6,2-4,3c-0.5,0.3-0.9,0.7-1.4,1l-0.1-0.9
      c-0.1-1.2-0.2-2.1-0.3-3.1c-0.2-2.2-0.5-4.5-0.7-6.7c-0.3-2.9-0.6-5.7-0.9-8.6c-0.1-1.3-0.2-2.6-0.4-4c-0.1-0.9-0.2-1.9-0.2-2.8
      c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8C413,74,413.3,73.4,414,72.5z M392.2,106.1c0.3-0.6,0.6-1.2,0.8-1.7
      c0.8-1.7,1.6-3.4,2.6-5.1c2-3.4,4.1-6.9,6.1-10.1c1.6-2.6,3.2-5.1,5-7.5c1.1-1.5,2.3-3,3.5-4.4l0.1-0.1c0.4-0.5,0.8-1,1.2-1.5
      l0.1,1.6c0.1,1.6,0.2,3,0.4,4.5c0.2,1.9,0.4,3.7,0.5,5.6c0.2,1.7,0.3,3.3,0.5,5c0.3,3.5,0.6,6.8,0.9,10c0,0.1-0.1,0.3-0.1,0.4
      c-0.9,1.1-1.9,2.2-2.7,3.2c-1.6,1.9-3.2,3.8-4.7,5.8c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.6-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7
      c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2-1.3-0.4-2.6-0.6-3.9c-0.5-3.6-1-6.9-1.6-10.3C389.2,114.8,390,110.6,392.2,106.1z M390.3,172.5
      v-0.2c-0.1-0.6-0.2-1.2-0.3-1.8c-0.2-0.9-0.4-1.9-0.6-2.8c-0.3-1.6-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4c-0.2-1.2-0.4-2.5-0.7-3.8
      l-0.1-0.4c-0.3-1.6-0.6-3.3-1.2-4.9c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.2c0.3-2.6,0.6-5.2,1-7.8
      c0.4-2.9,0.9-5.9,1.5-8.7c0.2,1.2,0.4,2.3,0.5,3.5c0.2,1.6,0.5,3.1,0.7,4.7l0.4,3.1c0,0.1,0,0.2,0.1,0.2c0,0,0,0.1,0,0.2
      c-0.2,1.1,0.1,2.4,0.4,3.7c0.2,0.7,0.3,1.4,0.4,2.1c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.5,1,7c0.3,1.9,0.6,3.8,0.9,5.7
      c0.4,2.4,0.7,5,1,7.6c0,0.3-0.1,0.8-0.4,1.2c-1,1.2-2,2.4-3.1,3.7C390.8,172,390.6,172.2,390.3,172.5z M371.5,198.4
      c1.3-1.7,2.5-3.4,3.8-5.1c0.1-0.1,0.2-0.3,0.3-0.4c0.2-0.4,0.5-0.7,0.8-0.9l0.1-0.1c0.6-0.5,1.3-1.1,2-1.2c3.6-1,7.4-2,11.5-3.1
      c0.4-0.1,0.6-0.1,0.8-0.1c0.1-0.2,0.1-0.2,0.4,0.9c0.1,0.4,0.2,0.9,0.3,1.3h0.1l-0.1,0.1c0.2,0.6,0.4,1.3,0.5,1.9
      c0.1,0.8,0.1,1.5-0.1,1.8c-0.3,0.6-0.8,1.1-1.3,1.6c-0.2,0.3-0.5,0.5-0.7,0.8l-0.7,0.8c-0.9,1.1-1.9,2.2-2.7,3.4
      c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1c-1.2,0-2.4-0.1-3.5-0.2h-0.1c-1.4-0.1-2.9-0.2-4.3-0.2c-1.6,0-2.9,0.1-4.2,0.4
      c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.5,0.1-0.8,0.2L371.5,198.4z M376.8,231.9c-0.2-0.7-0.5-1.5-0.7-2.2
      c-0.7-2.2-1.4-4.2-2.1-6.2c-0.3-0.9-0.6-1.7-1-2.6v-0.1c-0.3-0.8-0.6-1.6-0.9-2.4c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1
      c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2c0-0.1,0.2-0.2,0.3-0.2c1.8-0.4,3.7-0.9,5.5-1.3l1.7-0.3c0.1,0,0.2,0,0.3-0.1
      c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5c0.1,1.6,0.1,3.3,0.2,4.8c0,2,0.1,4.1,0.2,6.1c0.1,1.8,0.4,3.7,0.6,5.4
      c0.1,1,0.3,2.2,0.4,3.4c0.2,1.9,0.4,4,0.4,6c0,0.1-0.3,0.3-0.6,0.4c-0.7,0.3-1.5,0.6-2.2,0.9h-0.1
      C377.3,231.7,377.1,231.8,376.8,231.9z M379.1,247.1l-1.5-12.8l2.9-1.5l1.1,13.5L379.1,247.1z M392.8,269.7
      c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2c-0.4-0.3-0.8-0.6-1.2-1c-0.6-0.5-1.2-1-1.8-1.5c-1.5-1.2-3.1-2.5-4.5-3.8
      c-1.4-1.3-2.3-2.4-2.8-3.5c-0.4-0.8-0.5-1.7-0.6-2.7c0-0.4-0.1-0.9-0.2-1.4c-0.2-0.9-0.3-1.8-0.5-2.7l-0.2-0.9
      c-0.4-2.1-0.7-4.2-1.1-6.3c-0.1-0.5-0.1-1-0.1-1.5v-0.4c0.1-0.1,0.3-0.2,0.6-0.3c0.7-0.3,1.3-0.5,1.9-0.8c0.4-0.2,0.6-0.2,1,0.3
      l2.1,2.5c2.4,2.9,5,6,7.4,9c0.2,0.2,0.3,0.6,0.3,0.9C392.5,263.8,392.5,266.4,392.8,269.7z M391.1,255c-1.7-2.1-3.6-4.3-5.5-6.3
      c-1.6-1.6-1.7-3.4-1.8-5.4c0-0.1,0-0.5,0-0.9c4.1-1,8.3-1.1,11.9-1.1c0.7,0,1.5,0,2.2,0c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2
      c-0.4,0-0.8,0.1-1.2,0.1h-0.4c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4C393,257.3,392,256.1,391.1,255z M410.5,279.5
      c-0.1,0.1-0.4,0.2-0.7,0.1c-1.8-0.2-3.6-0.4-5.5-0.6l-0.9-0.1c-2-0.2-4.1-0.5-6.1-0.7c-0.6-0.1-1.1-0.2-1.6-0.3
      c-0.4-0.1-0.6-0.3-0.5-1v-0.3c0.1-1.8,0.2-3.7,0.1-5.5c0-1.3-0.1-2.7-0.2-4v-0.2c0-0.5-0.1-1.1-0.1-1.6c0-0.7-0.1-1.4-0.1-2.2
      l-0.1-1.6l9.8-0.9c0,0.1,0.1,0.3,0.1,0.4c0.1,0.5,0.2,0.9,0.3,1.4c0.8,2.3,1.6,4.5,2.3,6.8l0.1,0.2c0.7,2,1.4,3.9,2,5.9
      c0.4,1.2,0.8,2.4,1.1,3.5C410.6,279.1,410.6,279.4,410.5,279.5z M411.4,262.2c-0.1-0.7-0.4-1.4-0.6-2.1c-0.2-0.7-0.5-1.5-0.6-2.2
      c-0.3-2.1-0.5-4.2-0.7-6.3c-0.2-0.9-0.3-1.8-0.4-2.7c-0.1-1-0.2-1.9-0.3-2.9v-0.3c-0.2-1.4-0.3-2.9-0.4-4.3
      c-0.1-1.2-0.2-2.4-0.3-3.5l-0.1-1.2h0.1h0.1c0.4,0.1,0.9,0.3,1.2,0.5c1.1,0.5,2.1,1.1,3.2,1.6h0.1c2.1,1.1,4.2,2.1,6.4,3.1
      c2.5,1.1,5.1,1.9,7.6,2.8l0.2,0.1c0.6,0.2,1.2,0.4,1.7,0.6c0.8,0.3,1.6,0.4,2.5,0.6c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.3
      c-0.1,1-0.3,2-0.4,3c-0.3,1.7-0.5,3.4-0.8,5.1c-0.4,2.5-0.8,5-1.2,7.5c-0.2,1.4-0.4,2.9-0.6,4.4c-0.2,1.5-0.4,3.1-0.7,4.8
      c-0.1,0.4-0.2,0.4-0.2,0.4c-0.2,0-0.4-0.1-0.6-0.2h-0.1c-1.3-0.7-2.6-1.5-3.9-2.3l-0.1-0.1c-0.8-0.5-1.5-1-2.3-1.5
      c-1-0.7-2.1-1.4-3.2-2c-1.2-0.7-2.5-1.5-3.7-2.1c-0.6-0.3-1.2-0.7-1.8-1C411.7,263.2,411.5,262.9,411.4,262.2z M423,304.3
      c-1.4-2.7-2.8-5.4-4.1-8.1c-0.6-1.3-1.2-2.7-1.8-4.1c-0.7-1.6-1.3-3.3-2.2-4.9c-1.3-2.5-1.6-5.2-1.9-7.9c0-0.4-0.1-0.7-0.1-1.1
      c-0.3-3-0.6-6.2-0.9-9.2v-0.4c-0.1-1-0.2-2-0.3-3.1l15.7,9.7c-0.7,3.7-1.3,7.6-0.5,11.8c0.4,2.4,0.7,4.9,0.9,7.3
      c0.1,1.4,0.3,2.9,0.5,4.4c0.7,5.5,1.4,10.8,2.2,16.2c0.3,2.3,0.8,4.7,1.2,6.9c0.1,0.5,0.2,0.9,0.3,1.4c-0.3-0.7-0.7-1.5-1-2.2
      c-1.2-2.8-2.5-5.7-3.9-8.5C425.8,309.8,424.4,307,423,304.3z M434.7,426.6L434.7,426.6c-0.4,1-0.8,1.8-1.2,2.7
      c-0.1,0.2-0.2,0.3-0.3,0.5l-0.1,0.1c-7.7-7.1-13.9-13.3-19.4-19.5c0.1-0.2,0.2-0.4,0.4-0.6l0.2-0.2c0.5-0.8,1-1.5,1.5-2.3
      c0.7-0.9,1.3-1.9,2.1-2.9c0.6-0.9,1.2-1.7,1.8-2.6l0.5-0.7c0.7-1,1.4-2,2-3c1.3-2.4,2.7-4.9,4.1-7.4c0.6-1.1,1.2-2.1,1.8-3.2
      c0.3,0.3,0.5,0.5,0.8,0.8c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1c1.8,1.4,3.7,2.6,5.6,3.9l1.4,0.9c0.4,0.3,0.5,0.7,0.4,1.4
      l-0.1,0.3c-0.3,1.4-0.7,2.8-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3c-0.6,1.3-1.2,2.6-1.8,3.7c-0.4,0.9-0.9,1.8-1.3,2.7
      C436.1,423.6,435.4,425.1,434.7,426.6z M477.8,412.3c-0.4,12.1-0.8,24.7-1.9,37.2c-0.2-0.1-0.5-0.1-0.7-0.2
      c-0.8-0.2-1.5-0.4-2.2-0.7c-1.1-0.4-2.2-0.8-3.1-1.2l-0.1-0.1c-0.6-0.2-1.2-0.5-1.8-0.7c-0.8-0.3-1.5-0.6-2.3-0.9
      c-1.3-0.5-2.6-1-4-1.5c-2.5-1-5.2-2.2-8.2-3.5c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.5-1.7-5.2-2.6l-0.3-0.2c-0.8-0.4-1.5-0.8-2.3-1.2
      c-0.2-0.1-0.5-0.2-0.7-0.4h-0.1c-0.7-0.3-1.4-0.7-2.2-1.1c-0.3-0.1-0.4-0.2-0.4-0.3c-0.1,0.1-0.2-0.1,0-0.3
      c1.3-2.8,2.6-5.6,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.6-1.4,0.9-2v-0.1c0.6-1.3,1.2-2.6,1.8-3.9
      c0.7-1.7,1.2-3.5,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6c0.1-0.2,0.1-0.4,0.1-0.6h0.1c0.4,0.2,0.8,0.3,1.1,0.4c0.4,0.1,0.9,0.3,1.3,0.4
      c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4h0.1c1.7,0.2,3.4,0.3,5.1,0.5
      c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1c0.4,0.1,0.9,0.1,1.3,0.2C477.9,408.8,477.9,410.6,477.8,412.3z M478.1,400.7c0,0.9,0,1.8,0,2.8
      c0,0.3,0,0.7,0,1c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8-1c-1.6-0.2-3.2-0.3-4.7-0.5H462c-1.5-0.1-3.1-0.3-4.6-0.4
      c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5c0.1-0.8,0.1-1.7,0.1-2.4c0-0.5,0-1,0-1.5c0.1-1.1,0.1-2.2,0.2-3.3v-0.1
      c0.2-3.5,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.5V369c0.2-2.4,0.4-5,0.6-7.5c0.1-1.8,0.2-3.7,0.3-5.5v-0.2
      c0.1-1.1,0.1-2.3,0.2-3.4c0.1-1.7,0.2-3.3,0.4-5.2v-0.4c0-0.5,0.1-0.9,0.1-1.4c0.6,0.3,1.1,0.7,1.6,1l0.1,0.1
      c1.5,0.9,3,1.8,4.4,2.7c0.9,0.5,1.7,1,2.6,1.6l0.2,0.1c2.5,1.5,5,3.1,7.4,4.7c2.5,1.8,5.3,2.1,8.1,2.5h0.2c0.3,0,0.6,0.1,0.9,0.1
      c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6c-0.1,1.4-0.2,2.8-0.2,4.2v0.4
      c-0.1,1.1-0.1,2.2-0.2,3.4c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3c0,1.3,0,2.9,0.1,4.6c0,1.5,0.1,3.1,0,4.7
      c-0.1,2.9-0.2,5.8-0.3,8.4C478.2,397.3,478.1,399,478.1,400.7z M520.7,449.6c0,0-0.1,0.1-0.4,0.1c-0.6,0-1.3,0.1-1.9,0.2H518v-0.2
      h-0.4c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5c-1.6,0.1-3.2,0.1-4.7,0.1c-0.7,0-1.5,0-2.2,0s-1.5,0-2.3,0.1
      c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2c-2.7-0.2-5.3-0.3-7.9-0.5l-2-0.1c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.8-0.1
      c-0.2-0.1-0.4-0.5-0.4-0.5c0.2-2.7,0.3-5.1,0.5-7.4v-0.3c0.1-1.6,0.2-3.3,0.3-4.9c0.3-4,0.5-7.8,0.7-11.7c0.1-1.4,0.1-2.8,0.1-4.1
      v-0.1c0-1,0-2,0.1-2.9c0-1.4,0.1-2.8,0.2-4.2c0-0.9,0.1-1.9,0.1-2.9c0-1.1,0.1-2.4,0.1-3.6c0-0.4,0.1-0.5,0.1-0.6
      c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1c3.2,0.1,6.5,0.2,9.7,0.5c2.6,0.2,5.1,0.2,7.6,0c3.2-0.3,6.5-0.4,9.7-0.5
      h0.2c1.5,0,3.2-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7c0.1,3.9,0.2,8,0.4,12.2c0.1,3.4,0.3,6.7,0.5,10l0.2,3
      c0.2,4.1,0.4,8.4,0.7,12.6c0.1,1.4,0.2,2.8,0.4,4C520.8,449.3,520.8,449.5,520.7,449.6z M519.7,358.3l0.6-0.1l0.1-0.1v0.1
      c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2c2-0.2,4-0.5,5.8-1.5c2-1.2,4.1-2.4,6-3.7l0.2-0.1c0.4-0.3,0.9-0.5,1.3-0.8
      c2.3-1.4,4.6-2.9,6.9-4.4l3.2-2.1c0,0.4,0,0.8,0.1,1.2c0.1,1.2,0.1,2.4,0.2,3.6c0.3,3.6,0.5,7.3,0.8,10.8l0.3,3.7
      c0.2,2.6,0.4,5.1,0.6,7.7c0.1,1.2,0.2,2.4,0.3,3.6c0.2,2.2,0.4,4.7,0.6,7c0.2,3.4,0.3,6.9,0.4,10.2c0,1.1,0.1,2.2,0.1,3.3
      c0,0.3,0,0.6,0.1,0.9c0.1,0.8,0.2,1.6-0.1,2c-0.2,0.4-0.9,0.6-1.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.5c-1.7,0.9-3.6,1.1-5.5,1.3
      c-1.8,0.2-3.7,0.4-5.3,0.6c-1.1,0.1-2.2,0.3-3.4,0.4c-0.9,0.1-1.8,0.2-2.7,0.3c-1,0.1-2.1,0.2-3.2,0.3c-1.8,0.2-3.5,0.4-5.3,0.7
      l-2.1,0.3L519.7,358.3z M563.1,432.1c-1.6,0.8-3.2,1.5-4.7,2.3l-0.3,0.2c-1.3,0.6-2.6,1.3-3.9,1.9c-1,0.5-1.9,0.9-2.8,1.4h-0.1
      c-3,1.4-6.1,2.9-9.2,4.3c-1.7,0.8-3.4,1.4-5.1,2c-0.9,0.3-1.8,0.7-2.7,1c-0.8,0.3-1.6,0.6-2.3,1l-0.2,0.1c-1.3,0.5-2.6,1.1-3.9,1.6
      c-0.9,0.3-1.9,0.6-2.8,0.9H525c-0.4,0.1-0.9,0.3-1.3,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.5c-0.2-4.2-0.5-7.8-0.7-11.4
      c-0.1-1.1-0.2-2.2-0.2-3.3c-0.1-1.6-0.3-3.4-0.3-5.2c-0.1-1.3-0.1-2.6-0.1-3.9v-0.2c0-0.8,0-1.6,0-2.4c0-0.7,0-1.4-0.1-2.1v-0.1
      c0-0.7-0.1-1.4-0.1-2.1c0-1,0-2.1-0.1-3.1c0-1.5-0.1-2.9-0.1-4.3c0-1-0.1-2-0.2-2.9v-0.1c0-0.3,0-0.6-0.1-0.9l3.7-0.4
      c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.2-0.2,3.2-0.3c1.1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9c0.7-0.1,1.4-0.4,2.1-0.7h0.1
      c0.2-0.1,0.5-0.2,0.7-0.3c0.4-0.1,0.7-0.3,1-0.4c0.2,0.5,0.3,1.1,0.5,1.6l0.1,0.3c0.6,1.8,1.1,3.6,1.7,5.3c0.5,1.5,1.2,3,2.1,5
      c0.5,1.1,1.1,2.3,1.6,3.4v0.1c0.5,1,1,2.1,1.5,3.2c0.8,1.8,1.7,3.7,2.4,5.3l0.1,0.3c0.4,0.8,0.8,1.7,1.1,2.5l0.1,0.3
      c0.2,0.5,0.5,1.1,0.8,1.7c0.1,0.2,0.1,0.3,0.1,0.4C563.6,431.9,563.2,432.1,563.1,432.1z M566.3,429.9c-0.2-0.5-0.5-0.9-0.7-1.4
      c-0.6-1.2-1.2-2.4-1.7-3.6c-1.5-3.2-2.6-5.8-3.6-8.1c-1.9-4.5-3.8-9-5.5-13.3c-0.5-0.8-0.6-1.5-0.5-2.1c0.1-0.5,0.5-1,0.9-1.3
      c0.9-0.7,1.9-1.3,2.9-1.9l0.1-0.1c1.6-1,3.2-2.1,4.8-3.3c1.8-1.4,3.5-3,5.2-4.6l0.1-0.1c0.7-0.7,1.4-1.3,2.1-2
      c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1c3.8,8.3,9.1,15.5,14.3,22.6l0.2,0.3C580.3,416.5,574.3,422.5,566.3,429.9z"
      />
      <path
        d="M304.7,200.6c-2.3-3.3-4.6-6.5-6.7-9.4c-0.9-1.3-1.9-2.4-3.7-2.7c-1.9-0.4-3.9-0.9-5.7-1.5h-0.1
      c-1.1-0.3-2.2-0.6-3.4-0.9c-1-0.2-2-0.4-3-0.6c-0.3-0.1-0.7-0.1-1-0.2c0-0.1,0-0.2,0-0.3c0.2-0.8,0.3-1.5,0.5-2.3l0.1-0.5
      c0.3-1.4,0.6-2.7,0.9-4.1c0.2-0.8,0.3-1.5,0.5-2.3c0.2-0.8,0.3-1.7,0.5-2.5c0.3-1.4,0.6-2.8,0.9-4.2V169c0.5-2.3,1-4.6,1.5-7
      c0.3-1.4,0.5-2.8,0.8-4.3c0.2-1.5,0.5-3,0.8-4.4c0.1-0.5,0.2-1.1,0.4-1.6c0.5-2.1,0.9-4.2,0.7-6.4c-0.3-3.1-0.7-7.1-1.2-11.2
      c-0.5-4.2-1.1-8.4-1.7-12.3c-0.1-0.9-0.4-1.9-0.6-2.8c-0.2-1-0.5-2-0.6-3c-0.6-4-1.5-9.2-4.2-13.9c-0.6-1.1-1.3-2.3-1.9-3.4
      l-0.1-0.1c-0.7-1.3-1.4-2.6-2.2-3.9c-1.1-1.9-2.3-3.9-3.7-6.1c-0.5-0.7-1-1.4-1.4-2c-0.3-0.5-0.8-1-1.1-1.6
      c-0.5-0.7-0.9-1.4-1.3-2.1v-0.1c-0.8-1.3-1.6-2.6-2.6-3.8c-1-1.3-2-2.5-3-3.6c-0.9-1-1.9-2.1-2.7-3.2c-1-1.2-1.9-2.5-2.8-3.8
      c-0.7-1-1.5-2.1-2.2-3.1c-0.6-0.8-1.2-1.5-1.9-2.3c-0.6-0.7-1.2-1.4-1.8-2.2c-2.3-3-4.7-6-8.2-8c-2.5-1.5-5.2-3-7.9-4.3
      c-2.5-1.2-5-2.3-7.5-3.5c-2-0.9-4.3-1.9-6.5-3c-2.1-1-4.2-2.1-6.3-3.1l-0.1-0.1c-1.9-1-4.1-2.1-6.3-3.1c-4.5-2.1-8.9-4.1-13.7-5.1
      c-2-0.4-4.1-0.5-6-0.6h-0.3c-0.5,0-0.9-0.1-1.4-0.1c-1-0.1-2.1-0.1-3-0.1c-1.1,0-2.2-0.1-3.3-0.1c-0.8-0.1-1.6-0.2-2.4-0.3
      c-1.2-0.2-2.4-0.3-3.6-0.3h-0.1c-0.6,0-1.1,0-1.7,0c-2,0-4.1,0-6.1,0.3c-3.2,0.4-6.5,0.5-9.7,0.7c-1.1,0-2.2,0.1-3.2,0.1
      c-3.3,0.2-6.7,0.5-9.8,2.1c0,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0.1c-1.1,0.4-2.2,0.9-3.3,1.3l-0.3,0.1c-2.7,1.1-5.5,2.2-8.2,3.4
      c-2,0.9-4.1,2-6,3c-1.4,0.7-2.8,1.5-4.3,2.2c-1.9,0.9-3.9,1.8-5.6,2.6h-0.1c-1.9,0.9-3.9,1.8-5.9,2.7c-1,0.5-1.9,1-2.9,1.6
      c-1,0.6-2,1.1-3,1.6c-4,1.9-7.2,4.3-9.7,7.4c-0.4,0.5-0.8,1.1-1.2,1.5c-0.4,0.5-0.8,1.1-1.2,1.6c-0.6,0.7-1.2,1.4-1.8,2.1
      c-0.9,1-1.8,2.1-2.6,3.2C87,68.2,85.9,69.7,85,71l-0.1,0.1c-1,1.3-2,2.7-3,4c-0.8,1.1-1.7,2.2-2.6,3.2c-0.9,1.1-1.8,2.2-2.7,3.4
      c-0.7,0.9-1.3,1.8-1.8,2.7c-0.3,0.4-0.6,0.9-0.8,1.3c-0.6,0.9-1.2,1.8-1.7,2.6c-1.3,1.9-2.5,3.8-3.7,5.8c-1.9,3.3-3.7,6.9-5.2,9.9
      c-1,2-1.7,3.7-2.1,5.3c-0.8,2.9-1.3,5.9-1.7,8.4c-0.9,5.5-1.7,10.1-2.3,14.3c-0.3,2.2-0.5,4.4-0.8,6.6v0.1
      c-0.2,1.5-0.3,3.1-0.5,4.6c-0.3,2.7-0.2,5.4,0.4,8.3c0.3,1.2,0.5,2.5,0.8,3.7v0.1c0.4,2,0.9,4.1,1.3,6.2c0.2,1.2,0.4,2.4,0.6,3.6
      c0.2,1.1,0.4,2.6,0.7,3.9c0.3,1.5,0.7,3.1,1,4.5v0.1c0.3,1.2,0.6,2.5,0.8,3.7c0.3,1.5,0.6,3.1,0.9,4.5l0.1,0.4
      c0.1,0.7,0.3,1.4,0.4,2.1c0.1,0.3,0.1,0.7,0.2,1v0.1c0,0,0,0-0.1,0c-0.8,0.2-1.6,0.4-2.4,0.5l-0.6,0.1c-2.2,0.5-4.4,1-6.7,1.5
      c-1.6,0.4-3.1,0.9-4.5,1.3c-0.8,0.3-1.3,0.6-1.6,1c-2.5,3.3-5.3,7.2-7.9,11l-0.2,0.3c-0.9,1.2-1.9,2.6-1.4,4.3
      c0.8,2.9,1.7,5.9,3,9.7c0.5,1.3,1,2.6,1.5,3.9v0.1c0.5,1.1,0.9,2.3,1.3,3.4c0.7,1.9,1.3,4,1.9,5.8c0.4,1.3,0.8,2.4,1.2,3.6
      c0.2,0.5,0.4,1,0.5,1.4c0.3,0.8,0.6,1.5,0.8,2.2c0.2,0.9,0.2,1.9,0.3,2.8c0,0.6,0.1,1.4,0.1,2.1l0.1,0.9c0.5,4,0.9,8,1.5,12
      c0.4,2.8,0.9,5.5,1.4,8.2c0.1,0.7,0.3,1.4,0.4,2.1c0,0.2,0.1,0.4,0.1,0.7c0.1,0.8,0.2,1.7,0.7,2.3c1.7,2.1,3.6,4.1,5.6,6
      c0.8,0.8,1.8,1.5,2.7,2.2c1,0.7,2,1.5,2.9,2.4c1.9,1.9,4.2,2.9,7.1,3.2c1.5,0.1,3,0.3,4.4,0.4c2.4,0.2,4.8,0.5,7.3,0.7
      c0.2,0,0.4,0.2,0.5,0.3c0.3,0.7,0.5,1.4,0.8,2c0.3,0.7,0.5,1.4,0.8,2.1c0.3,0.6,0.6,1.3,0.8,1.9v0.1c1.1,2.5,2.1,5,3.3,7.5
      c1.3,2.6,2.7,5.3,3.9,7.7l0.1,0.2c0.9,1.7,1.8,3.4,2.7,5.2c0.8,1.7,1.7,3.5,1.7,5.6c0,3.4,0.1,6.8,0.2,10.1
      c0.1,2.6,0.1,5.2,0.1,7.8c0,3.1,0,6.3,0,9.4c0,1.6,0,3.1,0,4.7v1.5c0,1.8,0,3.7,0,5.6c0,1.6,0.1,3.2,0.1,4.8c0,1.2,0.1,2.4,0.1,3.6
      c0,1.1,0,2.1,0,3.2v0.1c0,1.8,0,3.6,0.1,5.4c0.1,2.1,0.2,4.2,0.3,6.2v0.2c0.1,1.5,0.2,3.1,0.3,4.6c0.1,1.1,0.1,2.3,0,3.8
      c0,0.1,0,0.2,0,0.3v0.1c0,0.6,0,1.3-0.2,1.7c-0.5,1-1.1,2-1.7,3c-0.5,0.9-1.1,1.8-1.5,2.8c-1.7,3.3-3.7,6.4-6.3,9.6
      c-0.7,0.9-1.5,1.9-2.2,3c-0.4,0.6-0.8,1.3-1.2,1.9v0.1c-0.6,1-1.3,2.1-2,3.1c-0.8,1-1.6,2-2.4,3c-0.9,1.1-1.8,2.2-2.7,3.4l-0.1,0.1
      c-0.8,1-1.5,2-2.7,2.5c-0.7,0.3-1.4,0.7-2,1l-2.1,1.2c-2.8,1.6-5.7,3.2-8.5,4.8c-1,0.6-2.1,1.3-3,1.8c-1,0.6-1.9,1.2-2.9,1.8
      c-0.9,0.5-1.8,1-2.7,1.5c-1.2,0.6-2.4,1.3-3.6,2c-1.2,0.7-2.3,1.5-3.4,2.3c-0.7,0.5-1.5,1-2.3,1.6c-1,0.6-2,1.3-3,1.9l-0.1,0.1
      c-0.4,0.3-0.9,0.5-1.3,0.8l-1,0.6l1.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0s0.1,0,0.2,0c1-0.5,2-1,3-1.5l0.6-0.3
      c3-1.5,6.2-3,9.2-4.6c2.1-1,4.1-2.3,6-3.4l1.2-0.7c0.8-0.5,1.5-0.9,2.2-1.4c1-0.6,1.9-1.2,2.9-1.8c1.1-0.6,2.3-1.2,3.4-1.7
      c1.6-0.8,3.3-1.7,4.9-2.6c0.3-0.2,0.7-0.4,1.1-0.6c1.3-0.7,2.8-1.4,3.2-3.3l0.1-0.1l0.1-0.1c0.3-0.4,0.7-0.7,1.1-1.1l0.2-0.2
      c0.7-0.7,1.4-1.4,2.1-2.2c0.7-0.7,1.3-1.5,2-2.3l0.2-0.2c0.2-0.2,0.4-0.4,0.6-0.7l0.3,0.4c0.4,0.5,0.8,1,1.3,1.5
      c0.3,0.4,0.6,0.8,1,1.2l0.1,0.1c0.9,1.1,1.8,2.2,2.8,3.2c1.3,1.3,2.6,2.6,3.9,3.8c0.7,0.6,1.3,1.2,2,1.9s1.5,1.4,2.2,2.2
      c1.4,1.4,2.9,2.8,4.4,4.2c0.3,0.3,0.5,0.5,0.7,0.7c0.8,0.7,0.8,0.7,0.4,1.5v0.1c-0.1,0.3-0.2,0.6-0.4,1c0,0.1-0.1,0.1-0.1,0.2
      l-0.1,0.2c-0.3,0.7-0.7,1.4-1,2.1v0.1c-0.8,1.7-1.6,3.4-2.3,5.1c-0.9,2.2-1.7,4.6-2.5,6.8l-0.2,0.5c-0.2,0.5-0.4,1.1-0.6,1.6
      c-0.1,0.3-0.2,0.7-0.4,1c-0.5,1.4-1,2.8-1.4,4.2c-0.9,3.2-2.1,6-3.8,8.7c-0.2,0.4-0.3,0.8-0.4,1.2c0,0.2-0.1,0.3-0.1,0.5l-0.1,0.4
      l0.4,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.4,0.1c1.6,0,2.1-1.1,2.6-2c0.1-0.2,0.2-0.4,0.3-0.6c1.9-3.1,3-6.6,4.1-9.9l0.4-1.2
      c0.9-2.9,1.7-5.2,2.6-7.7c0.8-2.1,1.7-4.4,2.9-7c0.5-1.2,1.1-2.4,1.7-3.5l0.1-0.3c0.1-0.3,0.3-0.5,0.4-0.8c0.4,0.3,0.9,0.5,1.3,0.8
      c1.2,0.7,2.3,1.4,3.5,2c2.7,1.4,5.6,2.7,8.7,4.2c2.7,1.2,6.3,2.9,9.9,4.4c1.6,0.7,3.2,1.3,4.8,1.8l0.2,0.1c0.8,0.3,1.6,0.6,2.4,0.9
      c1.1,0.4,2.1,0.8,3.2,1.2c2.3,0.9,4.6,1.7,6.9,2.6c0.4,0.2,0.5,0.3,0.4,0.6c-0.1,1-0.1,1.9-0.2,2.9v0.1c-0.2,2.7-0.4,5.5-0.6,8.2
      c-0.1,1.4-0.1,2.8,0,4.1v0.2c0,0.6,0,1.1,0,1.7v1.7l0.7-1.6c0.6-1.2,1.1-2.5,1.3-3.9c0.2-1.3,0.2-2.6,0.3-3.8v-0.1
      c0-0.7,0.1-1.4,0.1-2.1c0.1-1,0.2-2.1,0.3-3.2c0.1-1.3,0.2-2.5,0.3-3.7c0.1-1,0.3-1.1,0.8-1.1c0.1,0,0.3,0,0.5,0
      c0.6,0.1,1.3,0.1,2,0.1h0.1c0.5,0,0.9,0,1.4,0c0.8,0,1.6,0,2.3,0c1.7,0.1,3.4,0.2,5.1,0.3c2.2,0.1,4.8,0.3,7.3,0.4
      c0.7,0,1.4,0,2.2,0c1.2,0,2.6,0,3.6-0.1c0.5,0,1,0,1.5,0c1.1,0,2.3-0.1,3.4-0.1l1.1-0.1c1,0,1.9-0.1,2.9-0.2h0.5
      c1.2-0.1,2.4-0.1,3.6-0.2c0.6,0,1.1,0,1.7-0.1c0.8,0,1.7-0.1,2.5-0.1c0.1,0,0.2,0,0.3,0c0.6,0,0.7,0,0.8,1l0.2,2.2
      c0.3,3.4,0.5,7,0.9,10.5c0.1,1,0.4,1.9,0.7,2.8v0.1c0.1,0.4,0.2,0.8,0.4,1.2l0.6,2.1l0.1-2.2c0.2-3.4,0.3-6,0.2-8.4
      c-0.1-1.8-0.3-3.6-0.4-5.3c-0.1-1-0.2-2.3-0.3-3.5c0-0.1,0.2-0.4,0.3-0.5c1.5-0.5,3-1.1,4.5-1.6c1.4-0.5,2.6-0.9,3.8-1.3
      c0.4-0.2,0.8-0.4,1.2-0.6c0.3-0.2,0.7-0.4,1.1-0.5c0.5-0.2,1.1-0.4,1.6-0.5c0.6-0.2,1.2-0.4,1.9-0.6c1.9-0.8,3.8-1.6,5.6-2.4
      l0.3-0.1c3.1-1.4,6.4-2.8,9.5-4.2c2-0.9,4.4-2,6.8-3.2c1.2-0.6,2.4-1.3,3.6-2l0.1-0.1c0.4-0.2,0.8-0.5,1.2-0.7c0.3,0.6,0.6,1.3,1,2
      c0.9,1.8,1.8,3.6,2.5,5.5c1,2.4,1.9,4.9,2.7,7.3l0.4,1.2c1.1,3.2,1.9,5.6,2.6,8c0.8,2.7,2.1,5.4,3.9,8.1c0.4,0.6,1,1,1.6,1
      c0.3,0,0.5-0.1,0.7-0.2c0.5-0.3,0.9-1-0.1-2.4c-1.7-2.4-2.7-5.2-3.6-7.9c-0.6-1.7-1.1-3.4-1.6-5.1v-0.1c-0.5-1.7-1.1-3.6-1.7-5.4
      c-0.5-1.6-1.2-3.1-1.8-4.4l-0.2-0.5c-1-2.1-2-4.3-3.1-6.5c-1.1-2-1-2.1,0.6-3.6c1.6-1.4,3.1-2.8,4.5-4.3l0.6-0.5
      c0.6-0.6,1.1-1.2,1.7-1.7c0.9-1,1.9-2,2.9-2.9c2.8-2.4,5-4.7,6.7-7.1c0.4-0.5,0.8-0.9,1.3-1.3c0.1-0.1,0.2-0.2,0.3-0.3l1.3,1.4
      c1.7,1.8,3.2,3.5,4.8,5.2c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.1,0.2,0.2,0.4c0.2,0.4,0.5,0.8,0.8,1
      c2,1.2,4.2,2.5,6.8,4c1.1,0.6,2.2,1.2,3.3,1.8l0.2,0.1c1.5,0.8,3.1,1.7,4.7,2.6c1.2,0.7,2.4,1.4,3.6,2.2c1.2,0.7,2.6,1.6,3.9,2.3
      c2.2,1.2,4.4,2.4,6.6,3.4c1.8,0.9,3.7,1.7,5.3,2.5l0.4,0.2c0.5,0.2,0.9,0.4,1.4,0.7h0.1c0.3,0,0.5-0.2,0.5-0.4v-0.4h-0.3
      c-0.8-0.6-1.7-1.1-2.5-1.7c-2-1.4-4-2.8-6.1-4.1c-3-1.9-6.1-3.7-9.2-5.5l-0.4-0.2c-1.5-0.9-3.2-1.8-4.6-2.6l-0.1-0.1
      c-0.7-0.4-1.4-0.8-2.1-1.2l-1.3-0.7c-2.3-1.3-4.7-2.6-7-3.9c-0.3-0.2-0.6-0.3-0.9-0.5c-0.7-0.4-1.5-0.7-2-1.3c-1.8-2-3.4-4.2-4.8-6
      c-1.5-2-2.9-4.1-4.3-6.1l-0.2-0.3c-0.6-0.9-1.3-1.9-1.9-2.8c-0.6-0.8-1.2-1.6-1.7-2.4c-0.3-0.4-0.7-0.9-1.1-1.4
      c-0.7-0.9-1.4-1.9-2-2.8c-1.5-2.5-2.9-5.1-4.4-7.9c-0.1-0.2-0.2-0.4-0.4-0.6c-0.6-1.1-1.2-2.2-0.6-3.4c0.1-0.2,0.1-0.4,0.1-0.5
      v-0.1c0-0.6,0-1.2-0.1-1.8c-0.1-1.3-0.1-2.6-0.1-3.9c0.1-2.1,0.2-4.2,0.3-6.2c0.1-1.9,0.2-3.9,0.3-5.8c0.2-5,0.3-9.4,0.4-13.4
      c0-2.5,0-5-0.1-7.4c0-1,0-2-0.1-3c0-0.7,0-1.4,0-2v-0.5c0-1,0-2,0-3c0-0.5,0-1.1,0-1.6c0-1.6,0-3.2,0-4.8c0-2.2,0.1-4.4,0.2-6.5
      v-0.3c0.1-1.9,0.1-3.9,0.2-5.8v-0.4c0.1-3.2,0.1-6.1,1.7-9c2.1-3.8,4.3-8,6.6-12.7c1.2-2.5,2.3-5.2,3.3-7.5c0.3-0.7,0.6-1.5,1-2.2
      c0.3-0.7,0.6-1.5,0.8-2.1c0.3-0.7,0.5-1.4,0.9-2.1c0-0.1,0.3-0.3,0.5-0.3c0.4-0.1,0.8-0.1,1.2-0.1c0.4,0,0.7,0,1.1-0.1
      c1.3-0.2,2.6-0.3,3.9-0.5c2.3-0.3,4.6-0.6,7-0.8c1.7-0.2,3.2-0.8,4.6-2.1c3.7-3.2,7-6,9.9-8.7c1.3-1.2,2.9-2.6,2.9-5
      c0-0.7,0.2-1.5,0.3-2.2c0-0.2,0.1-0.4,0.1-0.6c0.2-1,0.4-2,0.6-3.1v-0.1c0.3-1.8,0.7-3.6,1-5.4c0.3-1.6,0.4-3.2,0.6-4.7l0.1-1.2
      c0.4-3.2,0.7-6.4,1.1-9.4c0-0.4,0.1-0.9,0.1-1.3c0-0.8,0.1-1.6,0.3-2.4c0.4-1.3,0.9-2.6,1.4-3.9c0.3-0.8,0.6-1.5,0.8-2.2
      c1.4-3.9,2.6-7.2,3.7-10.3l0.5-1.3c1-2.8,2.1-5.8,3-8.7c0.1-0.2,0.1-0.3,0.2-0.5c0.4-1.1,0.8-2.4,0.2-3.8
      C305.9,202.4,305.3,201.5,304.7,200.6z M281.3,187.3l2.4,0.6c1.2,0.3,2.3,0.6,3.5,0.9c0.8,0.2,1.6,0.4,2.7,0.7h0.2
      c2.4,0.6,4.6,1.2,6.1,3.5c1.4,2.2,3.1,4.3,4.7,6.4c0.6,0.7,1.1,1.5,1.7,2.2c-0.2-0.1-0.4-0.1-0.6-0.2c-0.9-0.3-1.8-0.5-2.8-0.7
      c-2.8-0.3-5.3-0.5-7.5-0.6c-0.1,0-0.3,0-0.4,0c-1.2,0-2.4,0.1-3.6,0.2h-0.1c-0.6,0.1-1.3,0.1-1.9,0.1c-0.1,0-0.2-0.1-0.2-0.1
      c-1.9-2.3-3.9-4.8-5.4-6.5c-0.7-0.8-0.8-1.6-0.5-2.5c0.4-0.9,0.6-1.9,0.7-2.8l0.1-0.5c0.1-0.7,0.4-0.7,0.6-0.7
      C281.1,187.3,281.2,187.3,281.3,187.3z M244.4,357.6c-0.1,4.4-0.3,8.9-0.5,13.2l-0.1,3.3c0,0.6,0,1.2-0.1,1.8
      c-0.1,2.6-0.2,5.3-0.5,7.9c-0.1,0.8-0.8,2-1.4,2.3c-1.5,0.6-2.5,1.6-3.5,2.6c-0.5,0.5-1.1,1.1-1.7,1.6c-0.7,0.5-1.5,1.1-2.2,1.7
      c-0.9,0.7-1.8,1.4-2.8,2.1c-1.2,0.9-2.4,1.7-3.6,2.6c-0.4,0.3-0.8,0.5-1.2,0.8c-0.1,0.1-0.3,0.2-0.5,0.3l-0.1-1.4
      c-0.1-1.4-0.2-2.7-0.3-4.1c-0.1-1.3-0.1-2.5-0.2-3.8c-0.1-2-0.2-4.4-0.3-6.6c-0.2-2.2-0.4-4.4-0.6-6.6v-0.1c-0.1-1.6-0.3-3.3-0.4-5
      c-0.2-2.2-0.3-4.4-0.5-6.8v-0.3c-0.2-2.7-0.4-5.4-0.6-8.1c-0.3-3.4-0.6-6.8-1-10.1v-0.1c-0.1-0.6,0.1-0.8,0.5-1.1
      c4.2-2.6,8.3-5.2,12.2-7.6c0.5-0.3,0.9-0.8,1.2-1.3c0.8-1.1,1.5-2.1,2.2-3.1c0.2-0.3,0.3-0.6,0.4-0.9c0-0.2,0.1-0.3,0.2-0.5
      c0.8-1.8,1.6-3.6,2.4-5.4c0.8-1.8,1.6-3.6,2.3-5.3c0.4-0.8,0.7-1.6,1.1-2.5v0.3c0,1.4,0,2.9,0,4.3c0,2.4-0.1,4.8-0.1,7.2
      c0,2.2-0.1,4.5-0.1,6.8c-0.1,3.4-0.1,7-0.1,10.5c-0.1,1.6-0.1,3.3-0.1,4.6C244.4,353,244.5,355.3,244.4,357.6z M154.2,365.5
      c0.1-1.6,0.1-3.2,0-4.8c0-0.6,0-1.2,0-1.8c5.9,0.6,11.9,0.9,17.7,0.9c5.9,0,11.9-0.3,17.7-0.9l0.2,7c0.1,5.1,0.3,10.4,0.4,15.6
      c0.1,5.2,0.2,10.3,0.4,16l0.2,6.8c-0.1,0-0.1,0-0.2,0l-2,0.1c-3.1,0.2-6.3,0.3-9.5,0.4c-0.5,0-1.1,0-1.6,0c-0.6,0-1.2-0.1-1.8,0
      c-0.7,0-1.5,0.2-2.2,0.3c-0.4,0.1-0.7,0.1-1.1,0.2h-0.2c-0.2,0-0.5,0.1-0.6,0c-1-0.4-2-0.6-3.4-0.6c-0.6,0-1.2,0-1.8,0.1h-0.2
      c-0.3,0-0.5,0-0.8,0c-1.1,0-2.2,0-3.4-0.1c-0.6-0.1-1.3-0.1-1.9-0.1c-1.4-0.1-2.8-0.1-4.2-0.2c-1,0-1.9-0.1-2.8-0.1H153
      c0.1-1.6,0.1-3.1,0.2-4.6v-0.3c0.1-3.5,0.3-7.1,0.3-10.6c0-1.8,0-3.7,0-5.5v-0.1c0-2.1,0-4.4,0-6.5c0.1-2.1,0.2-4.2,0.4-6.2v-0.1
      C154,368.8,154.1,367.1,154.2,365.5z M186.5,356.6c-2.7,0.2-5.4,0.4-8,0.6l-1.1,0.1c-0.3,0-0.7,0-1,0.1h-0.2
      c-1.2,0.1-2.6,0.2-3.9,0.2h-0.2c-2.1,0-4.4-0.1-6.6-0.3c-4.9-0.4-9.8-0.9-14.6-1.4h-0.2c-1.5-0.1-3-0.4-4.4-0.6H146
      c-0.6-0.1-0.8-0.3-1-1.1c-0.3-1.4,0.2-2.5,0.6-3.7c0.2-0.6,0.5-1.2,0.6-1.9c0.5-2.2,1.1-4.4,1.7-6.5c0.3-1.1,0.6-2.2,0.9-3.3
      c0.3-1.2,0.6-2.4,0.9-3.5l0.1-0.2c0.1-0.4,0.2-0.8,0.3-1.2c0.7,0,1.3,0,1.9,0h0.3c0.8,0,1.6,0,2.4,0c1.2,0,2.1,0,3,0
      c2.7,0.1,5.9,0.2,9,0.5c1.5,0.2,3,0.2,4.6,0.2c2.4,0,5.1-0.2,8.1-0.6c1.6-0.2,3.3-0.2,5-0.2h0.2c0.4,0,0.8,0,1.2,0
      c1.9,0,3.7,0,5.6,0c0.7,0,1.5,0,2.3,0c0.1,0.4,0.2,0.8,0.3,1.2l0.1,0.3c0.3,1.4,0.7,2.9,1.1,4.4c0.5,1.7,1,3.5,1.5,5.2v0.1
      c0.7,2.2,1.4,4.7,2.1,7.1c0.2,0.8,0.1,1.8-0.1,2.7v0.1c-0.1,0.1-0.4,0.3-0.8,0.3c-2,0.3-4,0.6-5.8,0.8l-0.6,0.1
      C190,356.2,188.2,356.4,186.5,356.6z M100.3,374.4v-0.6c-0.1-1.5-0.2-3.1-0.2-4.6c0-1.1-0.1-2.2-0.1-3.3c-0.1-3.4-0.2-7-0.3-10.5
      c0-2.6,0-5.2,0.1-7.8v-0.2c0-2.3,0.1-4.7,0.1-7c0-4.6-0.1-9.2-0.2-13.7v-1.1c0-1.7-0.1-3.4-0.2-5.3c0-1.1-0.1-2.3-0.1-3.4v-0.1
      c0.5,1.1,0.9,2.2,1.4,3.2l0.1,0.2c1.5,3.4,3,6.9,4.4,10.4c0.1,1,0.8,2.1,1.6,3.1c0.4,0.6,0.8,1.2,1.2,1.8c0.3,0.5,0.9,0.8,1.5,1.1
      c0.2,0.1,0.5,0.2,0.7,0.4c0.8,0.5,1.7,1.1,2.5,1.6l0.1,0.1c2,1.3,4,2.6,6.1,3.7c2.1,1.1,2.8,2.3,2.4,4.6c-0.6,3.6-0.8,7.4-1.1,11
      c-0.1,1-0.2,2.1-0.2,3.1c-0.2,1.9-0.3,3.9-0.4,5.6c-0.1,1.2-0.1,2.4-0.2,3.5c-0.2,3.3-0.5,6.6-0.7,9.8v0.1c-0.2,2.5-0.4,5-0.5,7.5
      c-0.2,3.3-0.4,6-0.6,8.5c0,0.5,0,0.9,0,1.4v0.1l-0.4-0.2c-0.5-0.4-1-0.7-1.5-1c-0.5-0.4-1.1-0.8-1.7-1.1l-0.2-0.1
      c-1.4-0.9-3-2-4.4-3.1c-1.3-1-2.6-2.2-3.8-3.3c-0.6-0.6-1.3-1.2-1.9-1.8c-0.3-0.2-0.6-0.4-1-0.6c-1.2-0.4-2-1.5-2-2.9
      c-0.1-2-0.2-4.1-0.3-6.1V377C100.4,376.1,100.4,375.3,100.3,374.4z M89.2,126.1c0.7,3.2,1.4,6.3,2.2,9.4c0.3,1.3,0.6,2.6,1,3.9
      c0.2,0.6,0.1,1.1-0.4,1.6c-1.7,1.8-3.4,3.6-5.1,5.4l-0.1,0.1c-1.3,1.4-2.6,2.8-4,4.2c-1.4,1.5-2.9,3-4.4,4.5
      c-1.6,1.7-3.1,3.2-4.6,4.7c-1.1,1.2-2.3,2.5-3.3,3.7c-0.5,0.6-1.1,1.2-1.6,1.8c0,0.1-0.1,0.1-0.2,0.2l-0.7-4.8
      c-0.5-3.8-1.1-7.4-1.6-11.1c-0.2-1.3-0.4-2.5-0.5-3.8c-0.4-2.7-0.7-5.4-1.2-8.1c-0.3-1.5,0.4-2.5,1.2-3.6l0.1-0.1
      c0.1-0.2,0.2-0.3,0.4-0.5c1.9-2.8,3.7-5.6,5.3-7.9c1.6-2.4,3.5-5.2,5.3-7.9l0.8-1.1c1.7-2.4,3.4-4.8,5.1-7.2c0.8-1,1.7-1.9,2.6-2.9
      c0.3-0.3,0.6-0.6,0.9-0.9c0.1,0.8,0.2,1.5,0.3,2.3l0.1,0.5c0.3,2.6,0.6,5.1,0.9,7.7v0.4C88.1,119.7,88.6,123,89.2,126.1z
       M170.6,114.2c0,0.7-0.1,0.8-0.9,0.8c-0.9,0.1-1.8,0.2-2.7,0.2c-3,0.3-6.2,0.5-9.2,0.9c-5.3,0.7-10.9,1.6-17.7,2.7
      c-1.9,0.3-3.8,0.8-5.8,1.2c-0.8,0.2-1.6,0.4-2.4,0.5l-2.3-41.1l41-10.3v2.5c0,2.4,0,4.8,0,7.1c0,0.6,0,1.1,0,1.7v0.1
      c0,0.6,0,1.2,0,1.8c0,1.6,0,3.1-0.1,4.7c0,2-0.1,3.9-0.1,5.7C170.6,99.6,170.6,106.9,170.6,114.2z M273.4,127.7
      c0.3,0.5,0.7,1,1,1.6c1.2,1.9,2.5,3.8,3.8,5.6c0.7,0.9,0.8,1.7,0.7,2.9c-0.2,1.2-0.3,2.4-0.5,3.6c-0.2,1.5-0.4,3.1-0.7,4.6
      c-0.2,1.3-0.4,2.7-0.5,4v0.3c-0.3,2.1-0.6,4.3-0.9,6.5c-0.3,2.1-0.7,4.2-1,6.5l-0.1,0.5c-0.1,0.6-0.2,1.2-0.3,1.8
      c-0.1-0.1-0.2-0.3-0.4-0.4l-0.2-0.3c-0.7-0.8-1.4-1.6-2-2.3c-2.8-3-6.2-6.6-9.6-10.3c-1.5-1.5-2.9-3-4.6-4.7l-0.3-0.3
      c-0.9-1-1.9-1.9-2.8-2.9c-0.4-0.4-0.8-0.8-1.1-1.2c-0.7-0.7-1.4-1.5-2.1-2.2c-0.5-0.4-0.5-0.9-0.4-1.4c0.3-1.3,0.7-2.6,1-3.9v-0.1
      c0.7-2.6,1.4-5.3,2-8c0.4-1.7,0.6-3.4,0.8-5v-0.1c0.1-0.7,0.2-1.4,0.3-2.1c0.1-0.7,0.2-1.5,0.3-2.2c0.3-1.9,0.6-3.9,0.9-5.8
      c0.1-1,0.2-2,0.3-3c0.1-0.8,0.1-1.5,0.2-2.2c0-0.4,0.1-0.8,0.2-1.3v-0.1c0.3,0.4,0.6,0.7,0.9,1.1c1,1.2,2,2.3,2.9,3.4
      c2.8,3.5,5.3,7,7.7,10.4c1,1.4,1.9,2.9,2.8,4.3v0.1C272.2,126,272.8,126.9,273.4,127.7z M278.7,184.7c-0.5,2.3-1,4.6-1.5,6.9
      c-0.2,1-0.5,2-0.9,3c-0.3,1-0.6,2-0.9,3c-1,4.3-1.7,8.1-2.4,11.5c-0.4,2.2-1,2.2-1.4,2.2c-0.3,0-0.8-0.1-1.3-0.2
      c-0.2,0-0.5-0.1-0.8-0.2c-0.5-0.1-0.9-0.3-1.4-0.5l-0.3-0.1c-0.6-0.2-0.8-1.1-0.5-2.2c1.1-4.5,2.2-8.7,3.2-12.7
      c0.5-1.8,0.9-3.5,1.4-5.3c0.9-3.4,1.8-6.8,2.6-10.3c0.4-1.7,0.7-3.4,1-5.1c0.2-0.9,0.3-1.8,0.5-2.6c0.1-0.6,0.3-1.2,0.4-1.9
      l0.1-0.4l0.3,0.4c0.6,0.7,1,1.2,1.5,1.8c0.1,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.5,0.8,0.8,1.1c1.1,0.8,1,1.7,0.7,3.3
      c-0.6,2.5-1.1,5.1-1.6,7.4L278.7,184.7z M279,196.2c1.5,1.7,2.8,3.3,4.1,4.9c0.1,0.3,0.1,0.6-0.2,1.3c-2.3,4.4-4.7,9.1-7.5,14.8
      c-0.6,1.2-1.2,2.5-1.6,3.6c-0.3,0.7-0.6,1.4-1,2.2c0,0.1-0.1,0.1-0.1,0.2l5.5-27.7C278.5,195.8,278.8,196,279,196.2z M213.4,309.8
      c-2.1-1.9-4.2-3.8-6.2-5.6l-2.6-2.3c-0.2-0.2-0.2-0.3-0.2-0.4s0-0.2,0.3-0.4c0.6-0.5,1.1-1,1.6-1.5c0.4-0.4,0.8-0.8,1.2-1.1
      c0.1-0.1,0.2-0.2,0.3-0.2c0.5-0.5,1.2-1,1-2.1c0,0,0.1-0.1,0.3-0.3c0.1-0.1,0.3-0.3,0.4-0.4c0.4-0.5,0.8-1.1,1.2-1.6
      c1-1.3,2-2.7,2.9-4.2c1.1-1.7,1.9-3.6,2.7-5.5c0.9-2.1,1.8-4.2,2.7-6.3l0.1-0.3c0.2-0.5,0.4-1,0.6-1.5c-0.1,11.7-1.8,23-3.5,35
      l-0.1,0.8c-0.2-0.2-0.4-0.3-0.6-0.5C214.7,310.9,214,310.3,213.4,309.8z M131.9,160.7c-0.2,0-0.3,0-0.5,0c-0.9,0-1.9,0-2.8-0.1
      c-1.9,0-3.9-0.1-5.9-0.1h-0.1c-1.7,0-3.4,0-5,0.1c-1.4,0-3.1,0.1-4.8,0.1c-1.5,0-2.4,0.5-3.2,1.5c-0.7,0.9-1.4,1.7-2.2,2.5
      c-0.6,0.6-1.2,1.3-1.8,2c-0.9,1.1-1.8,2.3-2.6,3.4l-0.1,0.1c-0.5,0.6-0.9,1.3-1.4,1.9c-0.6,0.7-1.2,1.5-1.8,2.2l-0.5,0.6l-0.1-1.1
      c-0.1-1.2-0.3-2.5-0.4-3.7c-0.1-0.7-0.2-1.5-0.2-2.2c-0.2-1.9-0.4-4-0.7-5.9c-0.5-3.8-1-7.5-1.4-10.7c-0.3-2.3-0.7-4.4-1-6.4v-0.1
      c-0.3-1.6-0.3-1.6,1.1-2.4c2.1-1.2,4.3-2.4,6.4-3.5l0.3-0.2c1.2-0.6,2.3-1.3,3.5-1.9c1.1-0.6,2.2-1.2,3.4-1.8
      c1.5-0.8,3.1-1.7,4.7-2.6c1-0.5,2-1.1,3-1.6h0.1c1.4-0.7,3-1.6,4.5-2.4c1.5-0.8,2.9-1.7,4.4-2.6l0.1-0.1c0.6-0.4,1.2-0.8,1.8-1.1
      c0.4-0.2,0.7-0.5,1.2-0.7l0.6-0.4l2,37.2H131.9z M132.7,163.6h0.2l-1.9,22.3c-0.2,0-0.5,0-0.7,0c-0.4,0-0.7,0-1.1,0
      c-0.5,0-0.9,0-1.3,0c-1.7-0.1-3.3-0.2-4.8-0.2c-3,0-5.7,0.2-8.2,0.7h-0.1c-0.5,0-1.2-0.5-1.8-0.8l-0.1-0.1
      c-2.9-1.9-5.9-3.9-8.7-5.9c-1-0.7-1.8-1.3-2.7-1.9l-0.2-0.1c-0.2-0.2-0.5-0.4-0.7-0.5c0.4-0.6,0.9-1.1,1.3-1.7
      c1.1-1.5,2.2-2.9,3.4-4.3c1.1-1.3,2.3-2.7,3.5-3.9l0.1-0.1c0.8-0.9,1.7-1.8,2.5-2.8c0.5-0.6,1.1-0.6,1.6-0.6c0.2,0,0.4,0,0.6,0h0.1
      c0.2,0,0.5,0,0.7,0c2.2-0.1,4.2-0.1,6.1-0.1c4.2-0.1,7.9-0.1,11.3-0.1C132.1,163.5,132.4,163.6,132.7,163.6z M96.8,169.3l0.1,0.6
      c0.2,1.5,0.4,3,0.5,4.6v0.3c0.1,1,0.1,1.9-0.3,2.8c-0.5,1.2-0.8,2.5-1.1,3.8c-0.1,0.5-0.2,0.9-0.3,1.4l-0.3,1.4
      c-0.6,2.4-1.2,4.9-1.7,7.3c-0.2,1-0.4,1.9-0.6,2.9c-0.2,0.9-0.3,1.9-0.6,2.9c-0.1,0.5-0.4,1-0.8,1.4c-1.2,1.2-2.5,2.3-3.7,3.4
      l-0.2,0.2c-1,0.9-2,1.9-3,2.8c-1.3,1.3-2.7,2.6-4,3.9l-0.1,0.1c-0.4,0.4-0.9,0.9-1.4,1.3l-0.6-2.5c-0.6-2.2-1.1-4.4-1.6-6.5
      c-0.4-1.7-0.8-3.3-1.3-5l-0.1-0.5c-0.6-2.5-1.3-5-1.9-7.5c-0.9-3.5-1.5-6.2-2.1-8.7c-0.3-1.4-0.6-2.8-0.9-4.2
      c-0.4-1.8-0.8-3.7-1.2-5.5c-0.3-1.2,0-2.2,1-3.1c1.3-1.4,2.7-2.8,4-4.2l0.2-0.2c1.5-1.6,2.8-3,4.2-4.4c1.3-1.4,2.7-2.7,4-4.1
      c1.7-1.8,3.5-3.6,5.3-5.5c1.1-1.2,2.2-2.5,3.2-3.6l0.1-0.1c0.5-0.5,1-1.1,1.4-1.6l0.1-0.1l0.1,0.7c0.2,0.9,0.3,1.7,0.4,2.6l0.1,0.6
      c0.5,3.4,1,6.9,1.5,10.3C95.8,161.4,96.3,165.6,96.8,169.3z M98,182.8c0.2-1.1,0.5-2.2,0.8-3.3l0.1-0.3c0.1-0.3,0.1-0.6,0.2-0.8
      l13.7,9.4l-3.7,3.7c-0.9,0.8-1.7,1.6-2.5,2.3c-0.3,0.3-0.5,0.6-0.5,1c0,0.3,0.2,0.6,0.5,0.9l1.1,0.9c1.8,1.5,3.6,3.1,5.4,4.6
      c0.6,0.5,1.2,0.9,1.8,1.3c0.2,0.1,0.3,0.2,0.5,0.4l-4.3,7.9c-0.2-0.1-0.5-0.3-0.7-0.4c-3.5-2.6-7.2-5.3-10.9-8.1
      c-1.8-1.3-3.4-2.5-4.8-3.7c0,0-0.1-0.3-0.1-0.5C95.8,192.7,96.9,187.7,98,182.8z M117,204.4c0.1-0.1,0.4-0.3,0.5-0.3
      c0.6,0,1.3,0.1,1.9,0.2c0.8,0.1,1.7,0.2,2.5,0.2c0.6,0,1.2,0,1.8,0c0.8,0,1.6,0,2.3,0c1,0,2,0,3,0c0.6,0,1.1,0.1,1.8,0.1
      c0.2,0,0.4,0,0.6,0.1v9.8l-18.6-2.5l0.7-1.3C114.6,208.6,115.8,206.5,117,204.4z M110.5,226.4c0.1-0.9,0.2-1.8,0.4-2.6
      c0.5-1.7,0.8-3.2,1.1-4.5c0.1-0.6,0.2-1.1,0.4-1.7c0-0.2,0.1-0.4,0.1-0.6c0.1-0.7,0.3-1.4,0.5-2.1c0-0.1,0.3-0.3,0.4-0.3h0.1
      c3.2,0.3,6.6,0.7,9.8,1.1c1.5,0.2,3,0.5,4.4,0.8h0.1c1,0.2,1.9,0.4,2.9,0.5c0.6,0.1,0.8,0.3,0.8,1c0.1,3.4,0.2,6.3,0.4,8.9
      c0.1,1.3,0.2,2.6,0.4,3.8v0.1c0.1,0.9,0.2,1.8,0.3,2.6c0.1,0.7,0.1,1.4,0.1,2.1c0,0.3,0,0.6,0,0.9l-24.9,6.7c0-0.1,0-0.3,0-0.4
      v-0.2c0.1-0.7,0.1-1.3,0.2-1.9c0.3-1.6,0.6-3.2,0.9-4.8v-0.1c0.4-2.1,0.8-4.3,1.2-6.4C110.3,228.3,110.4,227.3,110.5,226.4z
       M131.4,202.3c-1.2,0-2.3,0-3.5,0.1c-1.1,0-2.3,0.1-3.4,0.1h-0.3c-1.5,0-3.1-0.1-4.6-0.3h-0.1c-0.4,0-0.7-0.1-1.1-0.1
      c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.2-0.6-0.3-0.9-0.4c-0.2-0.1-0.4-0.1-0.6-0.1s-0.4,0-0.5-0.1
      c-1.8-1.5-3.6-3-5.5-4.6l-1.6-1.4l0.4-0.3c0.4-0.3,0.7-0.6,1.1-0.9c0.4-0.4,0.8-0.7,1.2-1.1c1.1-0.9,2.2-1.9,3.2-2.9
      c1.5-1.5,3.4-1.5,5.2-1.5h0.3c2.7,0,5.4,0.1,8,0.2h0.1c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.4,0.5,3.6,1.5c1,0.8,2,1.6,3,2.4l0.3,0.2
      c0.6,0.2,1,0.5,1.5,0.9l0.6,0.5c0.7,0.6,1.5,1.2,2.2,1.8c0.2,0.1,0.2,0.3,0.2,0.4c0,0.3-0.4,0.7-0.5,0.9c-2.5,2.5-5.2,5-9.2,5
      c-0.2,0-0.4,0-0.6,0C132.8,202.3,132.1,202.3,131.4,202.3z M159,185.8c0.1,0.1,0.2,0.3,0.2,0.4l-10.7,11.7c-0.5-0.5-0.9-1-1.4-1.4
      c-1.2-1.3-2.5-2.6-3.9-3.8c-2-1.7-4.2-3.5-7.2-5.7c-0.6-0.4-1.3-0.7-2.1-0.9c-0.3-0.1-0.5-0.2-0.8-0.3l1.6-22.4
      c0.1,0,0.3,0.1,0.4,0.1h0.1c0.6,0.2,1.2,0.4,1.7,0.7c0.9,0.5,1.9,0.9,2.8,1.3c2.8,1.3,5.7,2.7,8,5c3.6,3.7,6.3,7.3,8.4,10.9
      c0.6,1,1.3,2,1.9,2.9v0.1C158.3,184.9,158.7,185.3,159,185.8z M161,202.8c-1.7,1-3.5,2.1-5.1,3.2l-0.1,0.1c-1.3,0.8-2.6,1.7-4,2.5
      c-0.5,0.3-1.2,0.6-1.8,0.9c-0.2,0.1-0.4,0.2-0.5,0.2c-0.1-0.6-0.2-1.1-0.4-1.6c-0.3-1.6-0.7-3-0.8-4.5c-0.1-1,0.4-2.3,1.2-3.2
      c2.3-2.7,4.8-5.3,7.2-7.8l-0.1-0.1h0.1c0.6-0.7,1.3-1.3,1.9-2c0.5-0.5,1-1,1.6-1.5l0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.1,1.1
      c0.1,1.2,0.2,2.4,0.2,3.6c0.2,2.3,0.5,5.4,0.8,8.4C161.6,202.3,161.5,202.5,161,202.8z M161.7,205.2c0,0,0.1-0.1,0.2-0.1
      c0,0.4,0.1,0.9,0.1,1.3c0.1,1.4,0.3,2.7,0.4,4c0,0.7,0,1.3,0,2c0,0.6,0,1.3,0,1.9c0.1,1-0.3,1.7-1,2.3c-1.8,1.5-3.8,3-6.2,4.9
      c-0.5,0.4-1,0.7-1.5,1.1l-0.4,0.3l-0.2-0.5c-0.2-0.6-0.5-1.2-0.6-1.8c-0.3-1-0.6-2-0.9-3c-0.4-1.6-0.9-3.4-1.5-5.1
      c-0.3-0.8,0-1,0.7-1.3c0.4-0.2,0.9-0.4,1.3-0.6c1.6-0.8,3.2-1.6,4.8-2.4c1.1-0.6,2.3-1.3,3.2-2l0.1-0.1
      C160.7,205.8,161.2,205.5,161.7,205.2z M146.2,201.8c-0.1,0.2-0.5,0.5-0.7,0.5c-1-0.1-2-0.2-3.1-0.4l-0.5-0.1l3.5-3.3
      C146.9,199.5,147,200.1,146.2,201.8z M133.1,213.2c0.4-2.5,0.3-4.9,0.2-7.8c0-0.4,0-0.6,0.1-0.7c0.1-0.1,0.3-0.1,0.5-0.1
      c1.8,0,3.5-0.4,5.1-1.1c0,0,0,0,0.1,0c0.1,0,0.3,0,0.4,0.1h0.1c1.5,0.3,3,0.7,4.7,1l1.9,0.4l0.1,0.9c0.2,1.3,0.4,2.8,0.6,4.3
      c0,0.1-0.2,0.4-0.4,0.5l-2.1,0.9c-2.9,1.2-5.9,2.4-8.9,3.5c-1,0.3-1.5,0.5-1.9,0.5c-0.2,0-0.3-0.1-0.3-0.1
      C133,215.2,133.1,214,133.1,213.2z M143.3,214.7c1.3-0.6,2.8-1.2,4.3-1.8l3.6,11.7l-16.3,10.5l-1.5-17.2
      C137.1,217.4,140.3,216,143.3,214.7z M135.6,237.5c1.9-1.2,3.8-2.5,5.7-3.6l0.1-0.1c3-1.9,6.1-3.9,9.1-5.9c2.3-1.6,4.6-3.3,6.8-5
      c1-0.8,2-1.5,3-2.3c0.1-0.1,0.3-0.2,0.4-0.3l-0.2,0.7c-0.3,1.1-0.6,2.3-0.9,3.5l-0.3,1.2c-0.5,2.2-1.1,4.5-1.5,6.8
      c-0.5,2.8-1.3,5.1-3.7,7c-2.6,2.1-5,4.5-7.4,6.9c-1,1-2.2,2.2-3.3,3.2c-1.6,1.5-3.3,3.1-4.8,4.5l-0.1,0.1c-0.7,0.6-1.3,1.2-1.9,1.8
      c0-0.3-0.1-0.6-0.1-1c-0.1-0.9-0.3-1.9-0.4-2.9c-0.2-2.2-0.4-4.6-0.5-6.6c-0.1-1.1-0.2-2.3-0.3-3.4c0-0.4-0.1-0.8-0.1-1.2
      c-0.1-0.8-0.2-1.6-0.2-2.4C135,238.2,135.3,237.7,135.6,237.5z M161.3,298.7h0.2c1.8-0.1,3.7-0.2,5.6-0.2c0.3,0,0.5,0,0.8,0
      c1.2,0,2.6,0,3.7,0.1c1.1,0,2.3,0.1,3.5,0.1h0.2c1,0,2,0,3-0.1c1.3,0,2.7-0.1,4.1,0h0.3c2.1,0.1,4.2,0.1,6.2,0.6
      c0.7,0.2,1.4,0.2,2.2,0.2c1.5,0,3-0.3,4.4-0.6c0.6-0.1,1.1-0.2,1.7-0.3c2.2-0.4,4.5-0.9,6.7-1.4c0.7-0.2,1.4-0.3,2.2-0.5l-0.1,0.1
      c-0.5,0.5-1,1-1.5,1.4c-0.9,0.7-1.8,1.5-2.6,2.1c-1.4,1.1-2.8,2.2-4.1,3.4c-2.5,2.3-5,4.7-7.1,6.8c-1.2,1.2-2.5,1.8-4,1.8
      c-1.7,0-3.4,0.1-5.1,0.2h-0.2c-2.1,0.1-4.3,0.2-6.4,0.2c-2.5,0-4.5,0.1-6.4,0.1c-1.9,0-3.7,0-5.5-0.1c-2.4-0.1-4.8-0.3-7.5-0.6
      c-0.4,0-0.8-0.3-1.4-0.8c-0.8-0.8-1.6-1.5-2.4-2.3l-0.1-0.1c-1.1-1.1-2.4-2.4-3.7-3.5c-1.7-1.5-3.5-3-5.2-4.4
      c-1.2-1-2.2-1.8-3.2-2.7c-0.6-0.5-1.1-1-1.6-1.6c0.7,0.1,1.4,0.2,2.1,0.4c0.9,0.2,1.8,0.4,2.6,0.5h0.1c2.4,0.5,4.9,0.9,7.2,1.6
      c0.6,0.2,1.2,0.2,1.9,0.2c0.9,0,1.7-0.1,2.5-0.2c0.7-0.1,1.4-0.2,2.1-0.2C158.1,298.9,159.7,298.8,161.3,298.7z M159.9,296
      c0.6-0.1,1.5-0.5,2.3-0.8c0.1,0,0.1,0,0.2-0.1c0.2,0,0.3-0.1,0.5-0.2c0.8-0.5,1.6-0.8,2.4-0.8c1.2,0,2.3,0.5,3.6,1
      c0.1,0.1,0.3,0.1,0.4,0.2h0.1c0.7,0.3,1.3,0.6,2.1,0.6c1,0,2-0.3,3-0.7l0.2-0.1c0.7-0.2,1.4-0.5,2.1-0.8h0.1
      c0.4-0.2,0.8-0.3,1.2-0.5c0.1-0.1,0.3,0,0.4,0c1.8,0.7,3.6,1.4,5.4,2.1h-2.8c-1.6,0-3.2,0-4.8,0c-0.6,0-1.1,0-1.6,0.1
      c-0.8,0.1-1.5,0.2-2.5,0.2c-0.7,0-1.5,0-2.1-0.1c-1.3-0.1-2.7-0.1-4.4-0.1c-1.1,0-2.1,0-3.3,0C161.6,296,160.7,296,159.9,296z
       M203,295.1c-1,0.2-2,0.4-3,0.6l-1.5,0.3c-1.8,0.3-3.6,0.7-5.4,1c-0.4,0.1-0.9-0.1-1.3-0.2c-1.7-0.6-3.1-1.1-4.4-1.5
      c-2.7-1-5.1-2-7.4-2.9c-0.6-0.2-1.2-0.4-1.8-0.4c-0.7,0-1.5,0.2-2.1,0.6c-2.7,1.5-5.5,1.6-8.2,0c-0.7-0.4-1.4-0.6-2.1-0.6
      c-1.1,0-2.2,0.4-3.2,0.9c-0.4,0.1-0.7,0.3-1.1,0.4c-1.7,0.6-3.4,1.2-5,1.8l-0.2,0.1c-0.7,0.3-1.5,0.6-2.2,0.8H154
      c-0.1,0-0.2,0.1-0.3,0.1c-0.7,0.5-1.5,0.8-2.5,0.8c-0.8,0-1.6-0.2-2.5-0.3c-0.2,0-0.4-0.1-0.6-0.1c-1.8-0.4-3.7-0.7-5.3-1
      c-0.9-0.2-1.8-0.3-2.7-0.5c-0.1,0-0.2,0-0.4-0.1l0.7-0.3c1-0.5,2-1,3-1.5l1.7-0.9c2-1,4-2,6.1-3c1.9-0.9,3.9-1.8,5.7-2.7l0.4-0.2
      c0.6-0.6,1.4-0.9,2.2-1.3c0.3-0.1,0.6-0.3,0.9-0.4c0.7-0.3,1.3-0.6,2-1c0.4-0.3,0.9-0.4,1.4-0.4c0.6,0,1.3,0.2,1.9,0.4l1,0.3
      c1.9,0.6,3.8,1.2,5.8,1.3c1.5,0.1,3.2-0.4,5.1-1.3c0.8-0.4,1.6-0.6,2.3-0.6c0.9,0,1.7,0.2,2.8,0.7c1.8,0.9,3.7,1.8,5.5,2.6l0.2,0.1
      c1.5,0.7,3.1,1.5,4.7,2.3c2.3,1.1,4.6,2.3,6.9,3.5c1.1,0.6,2.2,1.1,3.3,1.7c0.4,0.2,0.8,0.4,1.1,0.6L203,295.1z M195.2,254.9
      l-2.3,6.9l-4.8,0.9c0.2-0.4,0.4-0.8,0.6-1.2c0.7-1.4,1.4-2.7,2.1-4c0.7-1.3,2-1.7,3.4-2.2C194.5,255.2,194.8,255.1,195.2,254.9z
       M192.8,253.6c-1,0.5-1.9,1-2.9,1.3c-0.3,0.1-0.8,0-1.4-0.2c-0.3-0.1-0.5-0.1-0.8-0.2c-1.8-0.3-3.7-0.6-5.5-0.9l-3.2-0.5
      c0-0.4,0.3-0.7,0.9-1c1.4-0.6,2.8-1.2,4.2-1.8l0.2-0.1c1.2-0.5,2.5-1.1,3.7-1.6c0.8-0.2,1.4-0.1,2,0.5c0.9,1.1,1.8,2.1,2.8,3.1
      c0.3,0.3,0.6,0.6,0.9,1C193.4,253.3,193.1,253.5,192.8,253.6z M188.8,257l-0.6,0.9c-0.9,1.7-1.7,3.3-2.5,4.8c0,0-0.1,0.1-0.2,0.1
      c-2.2,0.2-4.5,0.4-7,0.6l-1.1,0.1l1.5-8.1L188.8,257z M178.1,271.6l0.1,0.5c0.3,1.9,0.6,3.8,0.9,5.7c0.1,0.8,0.2,1.6,0.2,2.3v0.3
      c0,0.2,0,0.5,0.1,0.7c-0.6,0.2-1.1,0.3-1.7,0.5l-0.2,0.1c-1.8,0.6-3.5,1.1-5.3,1.4c-0.2,0-0.4,0.1-0.6,0.1c-0.8,0-1.7-0.3-2.6-0.5
      l-0.2-0.1c-1.1-0.3-2.2-0.7-3.4-1.2c-0.4-0.2-0.8-0.3-1.3-0.5l0.3-2c0.3-1.8,0.5-3.6,0.8-5.4c0.1-0.9,0.3-1.8,0.4-2.8
      c0.2-1.4,0.4-2.9,0.7-4.3c0.2-1,0.4-1,0.6-1s0.5,0.1,0.8,0.2c2.9,0.8,5.6,0.8,8.2,0c0.4-0.1,0.6-0.2,0.8-0.2s0.5,0,0.6,1.1
      C177.6,268.2,177.8,269.9,178.1,271.6z M165.8,263.4l-0.7-0.1c-1.4-0.1-2.9-0.2-4.3-0.3h-0.1c-0.1,0-0.2-0.1-0.3-0.1h-0.1
      c-0.2,0-0.4,0.1-0.6,0.1c-1,0-1.7-0.6-2.3-1.9c-0.5-1-1-2-1.5-3l-0.1-0.1c-0.2-0.3-0.3-0.7-0.5-1l9.8-1.6l1.5,8.1L165.8,263.4z
       M155.8,262.8l-0.4-0.2c-1.4-0.2-2.8-0.5-4.1-0.7c0,0-0.1-0.1-0.2-0.1c-0.9-2.4-1.6-4.5-2.2-6.6c1.7,0.5,3.3,0.9,4.2,2.4
      c0.8,1.3,1.5,2.7,2.1,4l0.1,0.2C155.5,262.1,155.6,262.5,155.8,262.8z M162.4,253.6c-2.2,0.3-4.2,0.7-6.2,1c-0.1,0-0.3,0.1-0.4,0.1
      c-0.2,0.1-0.3,0.1-0.4,0.1H155c-0.6,0-1.2,0-1.7-0.2c-0.8-0.3-1.5-0.6-2.3-1c-0.2-0.1-0.4-0.2-0.7-0.3c0.3-0.3,0.6-0.6,0.9-1
      c1.1-1.2,2.1-2.4,3.2-3.5c0.1-0.1,0.4-0.2,0.9-0.2c0.2,0,0.5,0,0.6,0.1c2.5,1,5.2,2.2,8.3,3.6c0.1,0.1,0.3,0.4,0.6,0.8L162.4,253.6
      z M156,241.1c-0.1,1-0.3,2.1-0.6,3c-0.2,0.5-0.7,1-1.3,1.4c-0.3,0.3-0.6,0.5-0.9,0.8c-2.4,2.7-4.2,4.8-5.9,6.8
      c-0.5,0.6-0.9,1.3-0.4,2.5c0.6,1.5,1.1,3.1,1.6,4.7c0.3,1,0.6,1.9,0.9,2.7c0.1,0.4,0.1,0.5-0.4,0.8c-1.3,0.8-2.5,1.6-3.8,2.4
      c-0.3,0.2-0.7,0.4-1,0.7h-0.1l-1-1.5c-1-1.4-1.9-2.9-2.9-4.4l-0.4-0.6c-0.5-0.7-1-1.5-1.5-2.2c-0.3-0.4-0.3-0.7,0.2-1.1
      c1.9-1.7,3.3-3,4.6-4.3c2-1.9,3.9-3.8,5.9-5.7c1.2-1.1,2.3-2.2,3.5-3.3c1-0.9,1.9-1.8,2.9-2.8l0.8-0.8
      C156,240.6,156,240.9,156,241.1z M145.5,268.8c0.7-0.5,1.3-0.9,2-1.4c1.2-0.8,2.4-1.7,3.6-2.4c0.7-0.4,1.8-0.5,2.5-0.5
      c0.2,0,0.3,0,0.5,0c1.8,0,3.6,0.2,5.3,0.4l1,0.1c1,0.1,2,0.2,3,0.3l1,0.1v1.6c-0.2,1.3-0.4,2.6-0.6,3.9c-0.1,0.8-0.3,1.6-0.4,2.5
      v0.2c-0.4,2.3-0.8,4.6-1,7c-0.1,1.1-0.5,1.3-1.3,1.6c-0.4,0.2-0.8,0.3-1.3,0.5c-0.9,0.3-1.8,0.7-2.6,1.1c-0.3,0.2-0.6,0.2-1,0.2
      c-0.3,0-0.6-0.1-1-0.1c-0.3-0.1-0.7-0.1-1.1-0.1H154c0,0-0.1-0.1-0.3-0.3c-1.2-1.9-2.3-3.8-3.5-5.7l-0.4-0.7
      c-1.5-2.4-3-4.9-4.4-7.3C145.1,269.3,145.1,269.1,145.5,268.8z M190.4,283.1c-0.3,0.5-0.5,0.6-1.3,0.9c-0.3,0.1-0.6,0.1-0.9,0.1
      c-0.9,0-1.6-0.4-2.4-0.8l-0.4-0.2c-0.7-0.4-1.5-0.6-2.2-0.9c-0.4-0.1-0.7-0.2-1.1-0.4c-0.4-0.1-0.5-0.3-0.5-0.9
      c0-1.5-0.3-3.1-0.5-4.6v-0.2c-0.4-2.4-0.8-5-1.2-7.1c-0.2-1.2-0.4-2.3-0.6-3.5c0-0.1,0-0.1,0-0.2l3.1-0.2c2-0.1,3.9-0.3,5.8-0.4
      c0.2,0,0.5,0,0.7-0.1c1-0.1,2-0.1,2.9-0.1c0.4,0,0.8,0.3,1.2,0.6l0.2,0.1c0.8,0.5,1.5,1,2.3,1.6l0.1,0.1c0.8,0.6,1.8,1.3,2.8,1.9
      c0.5,0.3,0.5,0.5,0.2,0.9c-2.2,3.5-4.2,6.7-6.1,9.8c-0.6,1-1.2,2.1-1.8,3.1L190.4,283.1z M194.6,263.4l0.6-1.9
      c0.6-2,1.3-4.1,1.9-6.1c0.2-0.5,0.1-1.3-0.2-1.8c-1.1-1.5-2.3-2.9-3.4-4.2c-0.2-0.3-0.5-0.6-0.7-0.9c-0.8-0.9-1.5-1.9-2.5-2.6
      c-1.7-1.3-2-3-2.3-4.9c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0.2,0.1,0.3,0.3,0.4,0.4c1.1,1,2.2,2.1,3.2,3.1l0.7,0.6
      c2.5,2.4,4.6,4.4,6.8,6.6c2,1.9,4.1,3.9,6.3,5.8c0.7,0.6,0.7,1,0.1,1.7c-1.5,2.1-2.9,4.1-4.2,6.1c-0.4,0.6-0.7,1.2-1.1,1.8
      c-0.1,0.1-0.1,0.3-0.2,0.4L194.6,263.4z M205.7,254.3c-1.5-1.3-2.9-2.5-4.3-3.8c-0.8-0.7-1.6-1.6-2.3-2.3c-0.6-0.7-1.3-1.4-2-2
      c-2.6-2.5-5.4-5-8.1-7.5c-1.2-1.1-1.9-2.4-2.3-4c-0.6-3-1.4-6-2.1-9c-0.3-1.2-0.6-2.4-0.9-3.5c-0.1-0.6-0.3-1.2-0.5-1.8l-0.1-0.2
      l0.1,0.1c0.8,0.6,1.7,1.3,2.5,1.9l0.2,0.1c1.8,1.4,3.7,2.9,5.7,4.2c2.1,1.5,4.3,2.9,6.5,4.2c0.8,0.5,1.6,1,2.4,1.5s1.7,1.1,2.6,1.6
      c1.7,1.1,3.6,2.3,5.4,3.5c0.2,0.1,0.4,0.6,0.4,1c-0.3,4-0.6,8.1-0.9,12v0.1c-0.1,1.7-0.3,3.1-0.5,4.5c0,0.2-0.1,0.5-0.2,0.8
      C206.8,255.2,206.2,254.8,205.7,254.3z M186.8,246.3c0,0.2,0.1,0.4,0.2,0.6v0.1c-0.5,0.2-0.9,0.4-1.4,0.6c-1.2,0.5-2.6,1.2-4,1.7
      c-0.5,0.2-1,0.4-1.6,0.6c-0.6,0.2-1.2,0.4-1.8,0.7c-0.5,0.2-0.8,0.8-0.9,1.3c-0.2,1-0.4,2.1-0.6,3.1s-0.3,1.9-0.5,2.8
      c-0.4,1.7-0.8,3.7-1.3,5.6c-0.1,0.2-0.6,0.5-0.9,0.6c-1.2,0.1-2.5,0.1-4,0c-0.7,0-1-0.4-1.2-1c-0.3-1.5-0.6-3-0.9-4.5
      c-0.2-1.3-0.5-2.5-0.7-3.8c-0.7-4-0.9-4.2-4.9-5.3c-1-0.3-2.1-0.8-3.1-1.2l-0.4-0.2c-0.5-0.2-1-0.5-1.4-0.8l-0.1-0.1
      c-0.1-0.1-0.2-0.1-0.3-0.2c0.1-1.9,1.6-2.6,3.2-3.3c0.8-0.4,1.9-0.4,2.9-0.5c0.4,0,0.9,0,1.3-0.1c0.6-0.1,1.2-0.1,1.9-0.2
      c1.7-0.2,3.4-0.4,5.1-0.5c1.1,0,2.2,0.1,3.4,0.2c0.5,0.1,1,0.1,1.5,0.2l2,0.2c1.5,0.1,3.2,0.3,4.9,0.4
      C184.4,243.4,186.5,245.2,186.8,246.3z M136.1,259.2c0.4,0.6,0.9,1.3,1.3,1.9l0.1,0.1c1.2,1.7,2.2,3.2,3.2,4.9
      c0.9,1.5,1.8,3,2.6,4.5c0.6,1.1,1.3,2.3,2,3.5c0.7,1.1,1.4,2.3,2.1,3.4v0.1c0.5,0.8,1,1.6,1.5,2.4c0.9,1.5,1.9,3.1,2.8,4.6
      c0.3,0.6,0.4,0.9,0.3,1.2c-0.1,0.3-0.4,0.5-0.9,0.8l-3.9,2c-2.6,1.3-5.3,2.7-7.9,4c-0.8,0.4-1.8,0.9-2.8,1.2c0,0,0,0-0.1,0
      c-0.3,0-0.5-0.1-0.5-0.2l-1-1.5c-1.6-2.4-3.3-4.8-4.7-7.3c-0.5-0.9-0.9-2-1.3-3.1c-0.2-0.6-0.4-1.3-0.7-1.9c-0.9-2-1.8-4.2-3.1-6.9
      c-0.4-0.8-0.4-1.2,0.2-1.9c1.2-0.8,2.1-2,3-3.1c0.6-0.8,1.3-1.7,2-2.5c1.3-1.5,2.7-3,4.2-4.5l0.4-0.4
      C135.3,260.1,135.7,259.6,136.1,259.2z M126.4,281.3c0.3,0.7,0.6,1.3,0.9,2c1,2.2,1.9,4.4,3.1,6.5c0.9,1.6,2.1,3,3.2,4.5
      c0.5,0.6,1,1.3,1.5,1.9l0.1,0.1c-0.2,1.2,0.6,1.8,1.2,2.2c0.2,0.2,0.4,0.3,0.6,0.5c0.6,0.7,1.4,1.3,2.1,1.9l0.3,0.3
      c0.2,0.2,0.3,0.3,0.3,0.4s0,0.2-0.3,0.4c-0.8,0.7-1.6,1.5-2.3,2.2c-1.2,1.1-2.5,2.4-3.9,3.6c-1.2,1.1-2.4,2.1-3.8,3.2l-0.1,0.1
      c-0.4,0.3-0.8,0.7-1.3,1l-0.4-2.8c-1.7-11.3-3.2-22-3.2-33.1c0,0.2,0.1,0.6,0.3,1C125.2,278.5,125.8,279.9,126.4,281.3z
       M129.5,313.4c1-0.8,1.8-1.6,2.7-2.4c0.5-0.5,1.1-0.9,1.6-1.4c1.2-1,2.4-2.1,3.6-3.1c0.9-0.8,1.7-1.6,2.4-2.4
      c0.3-0.3,0.6-0.6,0.9-1c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2,0.1,0.5,0.3l2.2,2c1.5,1.4,3.1,2.8,4.6,4.2c0.5,0.5,1.1,1,1.7,1.6
      l0.1,0.1c0.8,0.8,1.7,1.6,2.6,2.4c0.5,0.4,0.4,0.8,0.2,1.5l-0.4,1.2c-0.6,1.9-1.2,3.8-1.7,5.8c-0.4,1.4-0.7,2.9-1.1,4.3l-0.1,0.3
      c-0.1,0.6-0.3,1.2-0.4,1.8c-0.1,0.5-0.2,1-0.4,1.5l-0.1,0.4l-20.3-15.9L129.5,313.4z M151.3,329.5c0.3-1.1,0.5-2.2,0.8-3.3
      c0.2-0.9,0.4-1.7,0.6-2.6l0.1-0.4c0.4-1.6,0.7-3.2,1.1-4.8c0.3-1.1,0.6-2.2,0.9-3.3c0.1-0.3,0.2-0.5,0.7-0.5h0.1
      c3.6,0.1,7.2,0.2,10.8,0.2h1.7c0.9,0,1.9,0,2.8,0.1h0.1c2.9,0.1,5.9,0.1,8.8,0.1c2.4,0,5-0.1,8.4-0.4h0.1c0.5,0,0.6,0.2,0.7,0.8
      v0.1c0.7,2.9,1.5,5.9,2.3,8.9c0.4,1.6,0.8,3.1,1.2,4.8l0.5,2c-0.6,0-1.1,0-1.6,0c-0.9,0-1.7,0-2.5,0c-0.6,0-1.2,0-1.7,0
      c-0.7,0-1.5,0-2.2,0c-3.3,0-6.8,0.2-10.3,0.6c-0.8,0.1-1.7,0.1-2.6,0.1c-1.4,0-2.9-0.1-4.6-0.3c-2.3-0.3-4.8-0.4-8-0.4
      c-0.9,0-1.9,0-2.8,0c-1.4,0-2.8,0-4.3,0h-0.5c-0.4,0-0.7,0-1.1,0L151.3,329.5z M192.8,319.7c-0.3-1.2-0.7-2.4-1.1-3.6
      c-0.3-0.3-0.5-0.7-0.6-1.2c-0.2-0.7-0.2-1,0.3-1.5c1.3-1.2,2.7-2.5,4-3.7l0.1-0.1c0.9-0.8,1.8-1.7,2.6-2.5c0.9-0.8,1.8-1.6,2.5-2.3
      l0.1-0.1c0.6-0.5,1.1-1,1.7-1.5c0.2-0.2,0.3-0.2,0.4-0.2c0.1,0,0.4,0.2,0.5,0.4c1.9,1.8,3.9,3.7,6,5.6c1.5,1.3,2.9,2.5,4.4,3.7
      c0.6,0.5,1.1,0.9,1.7,1.4c-7.1,6.2-14.2,11.5-20.2,15.9c-0.1-0.4-0.2-0.7-0.3-1.1c-0.3-1.2-0.7-2.3-0.9-3.4
      c-0.2-0.9-0.4-1.8-0.5-2.6C193.3,321.9,193.1,320.8,192.8,319.7z M218.9,272.7c-0.3,0.7-0.6,1.3-0.9,2c-0.6,1.3-1.3,2.7-1.9,4.2
      c-0.3,0.8-0.6,1.6-0.9,2.4c-0.4,1.1-0.8,2.3-1.4,3.3c-0.8,1.7-1.8,3.2-2.8,4.8l-0.3,0.5c-0.4,0.7-0.9,1.3-1.3,1.9l-0.1,0.1
      c-0.4,0.5-0.7,1-1.1,1.5c-0.3,0.4-0.4,0.6-0.6,0.6c-0.1,0-0.3-0.1-0.5-0.2c-2.9-1.5-5.8-3-8.6-4.4l-2.7-1.4
      c-0.9-0.5-1.8-0.9-2.8-1.4l-1.4-0.7c0.2-0.3,0.3-0.6,0.5-0.9c0.4-0.9,0.8-1.7,1.3-2.5c0.7-1.2,1.4-2.4,2.1-3.4
      c1.2-1.9,2.4-3.8,3.5-5.8c2.6-4.7,5.5-9.2,8.6-13.8c0.1-0.1,0.1-0.2,0.2-0.3c0.2,0.1,0.3,0.3,0.5,0.4c0.6,0.5,1.1,0.9,1.6,1.5
      c1.8,2,3.6,4.1,5.3,6l0.8,0.9c0.3,0.4,0.6,0.8,0.9,1.1c0.6,0.7,1.2,1.5,1.8,2.1C219.1,271.9,219.1,272.2,218.9,272.7z M192.7,224.6
      l3.6-11.7c1.5,0.6,3,1.2,4.3,1.8c3.1,1.3,6.2,2.7,9.8,3.3l-1.4,17.1L192.7,224.6z M196.6,196.7l-0.1,0.1c-0.4,0.4-0.7,0.8-1.1,1.1
      l-10.9-11.5c0-0.1,0.1-0.1,0.1-0.2l0.8-1.3c1.3-2,2.6-4,4-6c2.3-3.4,5.2-7.3,9.2-10.2c1.4-1,3.1-1.8,4.6-2.6
      c0.6-0.3,1.2-0.6,1.8-0.9c1.1-0.5,2.2-1,3.2-1.5h0.1c0.2-0.1,0.5-0.2,0.8-0.2c0,0,0,0,0.1,0l1.6,22.5c-2.2,0.1-3.6,1.3-5,2.5
      c-0.4,0.3-0.7,0.6-1.1,0.9l-0.2,0.2c-1.5,1.2-3.1,2.4-4.5,3.7C198.8,194.4,197.7,195.6,196.6,196.7z M201.9,201.7
      c-0.2,0-0.5,0-0.7,0.1c-1,0.1-2.1,0.3-3.1,0.4c-0.1,0-0.4-0.2-0.6-0.6c-0.4-0.7-0.5-1.5-0.2-1.9c0.2-0.4,0.6-0.7,0.9-1.1
      c0.1-0.1,0.1-0.2,0.2-0.2L201.9,201.7z M186.2,191.3c2.6,2.8,5.4,5.7,7.9,8.7c1.4,1.5,1.8,3,1.4,4.5c-0.3,1.2-0.5,2.4-0.7,3.8v0.1
      c-0.1,0.4-0.1,0.8-0.2,1.3l-0.5-0.2c-0.6-0.2-1.1-0.5-1.7-0.8c-1.7-0.9-3.7-2-5.6-3.2c-1.4-0.9-2.8-1.8-4.4-3
      c-0.1-0.1-0.1-0.4-0.1-0.8c0.3-3.4,0.6-6.7,0.9-10.3l0.2-2.7c0.1,0.1,0.3,0.2,0.4,0.4l0.2,0.1C184.8,189.9,185.5,190.6,186.2,191.3
      z M209.9,215.6c-0.2,0-0.3,0-0.6-0.1c-1.8-0.6-3.5-1.1-5.2-1.8c-1.3-0.5-2.6-1.1-3.9-1.6l-0.1-0.1c-1-0.4-1.9-0.9-2.9-1.3
      c-0.4-0.2-0.5-0.3-0.4-0.8c0.2-1.3,0.4-2.6,0.6-3.9l0.2-1.1c0.5-0.1,1-0.2,1.5-0.3l0.2-0.1c1.6-0.4,3.1-0.7,4.6-1
      c0.3-0.1,0.7,0,1.1,0.1c0.1,0,0.2,0,0.3,0.1c0.5,0.1,1,0.2,1.5,0.3c0.8,0.2,1.7,0.3,2.5,0.4c1.1,0.1,1.1,0.1,1.1,1.2
      c0,1.8,0,3.7,0,5.3v0.4c0,0.7,0,1.3,0,2c0,0.2,0,0.4,0,0.5c0,0.4,0,0.8,0,1.2S210.3,215.6,209.9,215.6z M210.1,202.2H210
      c-2.6,0-4.9-0.9-6.9-2.8c-0.2-0.2-0.5-0.4-0.8-0.6c-0.4-0.3-0.7-0.5-1-0.8c-0.7-0.8-1-1.3-1-1.7c0-0.3,0.5-0.7,1.4-1.4
      c0.9-0.7,1.9-1.5,2.8-2.2h0.1c1.2-1,2.4-2,3.6-2.9c1-0.7,2.1-1.2,3-1.2c4.3-0.3,8.8-0.3,12.9-0.4l0.1-0.1c2.5,0,4.5,0.9,6.1,2.6
      c1,1.1,2.1,2,3.3,3c0.4,0.4,0.8,0.7,1.3,1.1l-1.5,1.3c-1.6,1.3-3.1,2.6-4.6,3.8c-0.1,0.1-0.3,0.2-0.4,0.4c-0.2,0.2-0.3,0.3-0.4,0.4
      c-0.3,0.1-0.6,0.2-0.9,0.3c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.2,0h-0.1c-0.1,0-0.3,0-0.5,0.2c-0.5,0.7-1.1,0.9-1.8,0.9
      c-0.2,0-0.5,0-0.7,0s-0.5,0-0.8,0h-0.1c-2.3,0-4.8,0-6.9,0C213.9,202.2,212,202.2,210.1,202.2z M211.1,163.4c0.4,0,0.5,0,0.6,0
      c0.5-0.1,1-0.2,1.4-0.2c2.3,0,4.6,0.1,6.5,0.2h0.4c1.4,0,2.7,0.1,4.1,0.1h0.4c1.3,0,2.7,0.1,4.1,0.1c0.8,0,1.5,0,2.2,0h0.1
      c0.7,0,1.3,0.2,1.8,0.8c0.5,0.6,1.1,1.2,1.7,1.7c1.1,1.1,2.2,2.3,3.2,3.5c1.4,1.7,2.7,3.5,4.1,5.3l0.2,0.3c0.5,0.6,0.9,1.3,1.4,1.9
      c-0.6,0.4-1.2,0.9-1.9,1.3h-0.1c-1.6,1.1-3.2,2.2-4.8,3.3c-2.3,1.6-4.7,3.1-6.8,4.5c-0.2,0.1-0.5,0.2-0.7,0.2h-0.1
      c-1.1-0.1-2.2-0.2-3.3-0.3c-1.9-0.2-3.8-0.3-5.7-0.4c-0.3,0-0.5,0-0.8,0c-1,0-2.1,0.1-3.1,0.1c-0.6,0-1.3,0.1-1.9,0.1
      c-0.4,0-0.8,0-1.2,0h-0.2L211.1,163.4z M230.3,210.6l0.8,1.4l-7.9,1l-10.7,1.5v-10c0.5,0,1,0,1.5,0h0.3c0.5,0,1.1,0,1.6,0
      c0.9,0,1.6,0,2.3,0c1.5-0.1,3-0.1,4.3-0.2c1.3-0.1,2.6-0.2,3.9-0.2c0.1,0,0.4,0.2,0.5,0.4C228.1,206.5,229.2,208.5,230.3,210.6z
       M211.8,229.3c0.2-2.5,0.3-5,0.4-7.3l0.1-2.2c0-0.3,0-0.5,0-0.8c0-0.5,0-1,0.1-1.5c0-0.2,0.4-0.5,0.6-0.6c2.3-0.5,4.4-0.9,6.4-1.2
      c2.7-0.4,5.4-0.7,8-1.1l0.5-0.1c0.7-0.1,1.7-0.2,2.6-0.2c0.1,0,0.4,0.3,0.4,0.4c0.6,2.5,1,4.5,1.3,6.5c0.3,1.8,0.6,3.6,0.9,5.3
      c0.3,2,0.7,4.1,1.1,6.2c0.4,2.4,0.9,4.7,1.4,7.1l0.1,0.4c0.2,0.8,0.3,1.6,0.5,2.5c-0.4-0.1-0.9-0.2-1.3-0.3h-0.1
      c-1.2-0.1-2.4-0.3-3.6-0.6c-2-0.5-4.1-1.1-5.9-1.6c-1.2-0.3-2.3-0.6-3.5-0.9c-1.2-0.3-2.5-0.7-3.7-1c-1.9-0.5-3.8-1-5.8-1.5
      c-0.8-0.2-1.2-0.5-1.1-1.4C211.5,233.3,211.6,231.3,211.8,229.3z M232.9,211l-4.4-8l0.5-0.3c0.6-0.4,1.2-0.9,1.8-1.4l0.8-0.7
      c1.8-1.5,3.6-3,5.3-4.5c0.6-0.5,0.8-0.9,0.8-1.3c0-0.5-0.4-0.9-1-1.5c-1.9-1.7-3.7-3.3-5.5-5c-0.1-0.1-0.1-0.2-0.2-0.3l13.7-9.4
      c0,0.1,0.1,0.2,0.1,0.3v0.1c0.2,0.5,0.4,1,0.5,1.6c0.9,3.6,1.6,6.8,2.3,9.7c0.5,2.4,1.1,4.9,1.7,8.2c0,0.1-0.1,0.3-0.2,0.4
      c-1.5,1.2-3.1,2.3-4.7,3.4l-0.2,0.2c-1.5,1.1-3.1,2.3-4.6,3.4c-1.3,1-2.6,2-3.8,2.9c-0.8,0.6-1.6,1.3-2.4,1.9
      C233.2,210.8,233.1,210.9,232.9,211z M249.1,187v-0.2c-0.4-1.6-0.7-3.1-1.1-4.7c-0.1-0.4-0.2-0.8-0.2-1.2c-0.2-1.1-0.4-2.2-0.9-3.2
      c-0.5-1.2-0.4-2.3-0.3-3.5v-0.1c0.1-1.2,0.2-2.5,0.4-3.7v-0.1c0.1-1.5,0.3-3.1,0.5-4.6c0.2-1.4,0.4-2.9,0.6-4.2
      c0.1-0.8,0.3-1.6,0.4-2.5c0.3-1.8,0.5-3.5,0.8-5.3l0.1-0.5c0.4-2.8,0.8-5.6,1.2-8.4c0.1-0.5,0.2-1,0.4-1.5l0.1-0.2l0.7,0.7
      c0.6,0.7,1.2,1.3,1.8,1.9c0.9,1,1.9,1.9,2.8,2.9l0.3,0.4c1.6,1.7,3.5,3.6,5.3,5.5c1.2,1.2,2.3,2.5,3.4,3.7l0.1,0.1
      c0.8,0.9,1.7,1.8,2.6,2.8c0.4,0.4,0.8,0.9,1.2,1.4c1.1,1.2,2.2,2.4,3.4,3.4c1.9,1.8,1.8,3.8,1.3,5.8c-0.6,2.3-1.1,4.6-1.6,6.9v0.1
      c-0.5,2.1-1,4.4-1.5,6.5c-0.4,1.7-0.9,3.4-1.3,5.1c-0.4,1.3-0.8,2.7-1.1,4.1c-0.6,2.4-1.2,4.8-1.7,7.1c-0.2,1-0.5,2-0.7,3
      c0,0.2-0.1,0.4-0.1,0.6c-0.1,0.4-0.2,0.7-0.3,1.1c-0.3,1.1-0.6,2.2-1,3.2l-0.3,1.2h-0.1c-0.3-0.4-0.7-0.8-1-1.2
      c-0.9-1-1.9-2.1-2.9-3.1c-1.4-1.4-3-2.8-4.3-4.1l-0.1-0.1c-1.2-1.1-2.4-2.2-3.5-3.3c-0.5-0.5-0.9-1.1-1-1.6
      C250.7,193.8,249.9,190.3,249.1,187z M255,108.1l-0.1,0.8c-0.8,5.9-1.7,12.1-2.7,18.2c-0.5,2.7-1.1,5.4-1.8,8
      c-0.3,1.1-0.5,2.2-0.8,3.3c-0.2,0.7-0.3,1.4-0.5,2l-0.1,0.5c-0.7-0.4-1.3-0.7-2-1.1l-0.2-0.1c-1.7-0.9-3.4-1.9-5.2-2.9
      c-1-0.6-2.1-1.1-3.2-1.7h-0.1c-1.1-0.6-2.3-1.2-3.5-1.8c-1.4-0.7-2.7-1.5-4-2.3l-0.1-0.1c-1.2-0.7-2.4-1.4-3.6-2
      c-0.8-0.4-1.6-0.8-2.3-1.2c-0.9-0.5-1.9-1-2.8-1.5c-1.6-0.9-3.1-1.9-4.7-2.8c-0.6-0.4-1.2-0.8-1.9-1.2c0-0.1-0.1-0.2-0.3-0.2
      c-0.4-0.1-0.7-0.2-0.8-0.3c-0.2-0.2-0.1-0.7,0-1.2c0-0.2,0.1-0.4,0.1-0.6c0.1-1.9,0.2-3.7,0.3-5.6c0.1-1.6,0.2-3.1,0.2-4.7
      c0.1-1.2,0.1-2.3,0.2-3.5c0.1-1.9,0.2-3.7,0.3-5.6c0.4-6.5,0.8-13,1.1-19.5c0.3,0.2,0.6,0.3,0.8,0.5l0.5,0.3
      c1.2,0.7,2.5,1.4,3.8,2.2l2.1,1c2.8,1.6,5.7,3.3,8.4,5c4.5,2.8,7.8,5,10.9,7.1c1.8,1.2,3.6,2.6,5.3,3.9l0.2,0.1
      c1.8,1.4,3.7,2.8,5.6,4.1C255.2,106,255.1,106.9,255,108.1z M215.1,124.5L215.1,124.5c1.4,0.9,2.7,1.8,4,2.6c1.2,0.7,2.4,1.4,3.5,2
      l0.6,0.2c1.8,1,3.6,1.9,5.4,2.9l1.4,0.8c1,0.5,1.9,1,2.9,1.5c2,1.1,3.8,2,5.6,3c1.1,0.6,2.1,1.2,3.2,1.8c1.8,1,3.8,2.1,5.8,3.1
      c1.1,0.5,1.2,1.2,1,2.2c-0.2,1.3-0.4,2.6-0.6,3.9l-0.1,0.7c-0.6,4.3-1.3,8.7-1.9,13.2c-0.4,2.7-0.6,5.5-0.8,8.2
      c-0.1,1.3-0.2,2.6-0.4,3.9c0,0.2,0,0.3-0.1,0.5l-0.7-0.9c-0.7-0.9-1.5-1.9-2.2-2.9c-1.9-2.4-3.9-4.9-5.9-7.3l-0.6-0.7
      c-0.1-0.1-0.1-0.2-0.2-0.3c-0.3-0.4-0.7-0.8-1.1-1.2c-0.6-0.5-1.3-0.9-2-1c-0.5,0-1.1,0-1.8,0c-0.4,0-0.8,0-1.2,0
      c-0.8,0-1.5,0-2.3,0c-1.1,0-2.2-0.1-3.2-0.2c-1.4-0.1-2.9-0.2-4.3-0.2h-0.3c-1.3,0-2.7,0.1-3.9,0.2c-0.6,0-1.2,0.1-1.8,0.1
      c-0.3,0-0.7,0-1.1,0.1h-0.5l2.1-37.2C214.1,123.8,214.6,124.1,215.1,124.5z M211.4,123.6c-0.1,1.1-0.2,2.1-0.3,3.1
      c-0.1,1.7-0.2,3.4-0.2,5c-0.1,1.3-0.1,2.7-0.2,4.1c-0.1,1.8-0.2,3.7-0.3,5.4v0.3c-0.1,1.6-0.2,3.1-0.3,4.7
      c-0.1,1.6-0.2,3.1-0.2,4.7c0,0.9-0.1,1.8-0.1,2.7l-0.1,1.5c-0.1,1.9-0.2,3.8-0.3,5.7c0,0.2-0.3,0.5-0.6,0.6
      c-2.4,1.1-4.8,2.1-7.1,3.1l-0.2,0.1c-1.1,0.5-2.1,0.9-3.2,1.4c-2.4,1.1-4.2,3-5.6,4.7c-2.1,2.7-4.1,5.5-6.1,8.2l-1,1.4
      c-0.8,1.2-1.6,2.5-2.3,3.7l-0.1,0.3c-0.4,0.7-0.7,0.9-1.2,0.9c-0.2,0-0.4,0-0.7-0.1c-1-0.3-2.1-0.7-3.1-1s-1.9-0.6-2.9-1
      c-0.4-0.1-0.9-0.3-1.4-0.4h-0.1c-0.2,0-0.3-0.1-0.5-0.1v-65.9c13.8,0.9,27.1,3.4,38.3,5.6C211.5,122.9,211.5,123.3,211.4,123.6z
       M181.6,210.5c0.1-1.3,0.2-2.6,0.3-4.1v-0.3c0-0.3,0.1-0.7,0.1-1c0.4,0.3,0.8,0.5,1.2,0.8c1.2,0.8,2.4,1.6,3.6,2.2
      c2,1.1,4.2,2.2,6.2,3.1c0.9,0.4,1.1,0.7,0.8,1.6c-0.5,1.4-1,2.9-1.4,4.3l-0.3,0.9c-0.3,0.9-0.5,1.8-0.7,2.6
      c-0.2,0.6-0.3,1.2-0.5,1.8c0,0.2-0.1,0.3-0.2,0.5c-0.2-0.2-0.5-0.3-0.7-0.5c-0.8-0.6-1.5-1.1-2.2-1.6c-2-1.6-3.9-3.2-5.7-4.7
      c-0.3-0.2-0.5-0.7-0.5-1.1C181.5,213.4,181.5,211.9,181.6,210.5z M182.4,225.7c0.9,3.7,1.7,7.6,2.4,11l0.2,0.9
      c0.2,0.9,0.4,1.9,0.6,2.8c0.1,0.6,0,0.8,0,0.8c-0.1,0.1-0.2,0.1-0.6,0.1c-0.1,0-0.2,0-0.4,0c-0.9-0.1-1.8-0.2-2.8-0.3
      c-2.7-0.3-5.4-0.6-8.2-0.6c-2.8-0.1-5.6,0.1-8.3,0.3c-0.8,0.1-1.6,0.1-2.4,0.2c-1,0.1-1.9,0.2-2.8,0.4c-0.3,0-0.6,0.1-0.9,0.1
      c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.9l0.7-3.4c0.8-3.7,1.6-7.5,2.5-11.2c0.5-2,1-3.9,1.6-5.8l0.4-1.4
      c0.7-2.3,1.1-2.4,2.5-2.5c0.3,0,0.7-0.1,1.2-0.1c0.3,0,0.6-0.1,1-0.1c0.2,0,0.4,0,0.6,0h0.1c0.2,0,0.5,0,0.7,0h2.9v-0.1
      c0.5,0,1,0.1,1.4,0.1c1.3,0.1,2.8,0.2,4.3,0.2c1.1,0,1.8,0.6,2.1,1.8l0.4,1.5C181.3,221.5,181.9,223.6,182.4,225.7z M162.1,185.3
      c-0.5,0-0.9-0.3-1.3-0.9c-1.1-1.8-2.3-3.6-3.5-5.4c-0.7-1-1.3-2-2-3c-2.5-3.9-4.9-6.6-7.6-8.6c-1.8-1.3-3.8-2.2-5.8-3.2
      c-0.4-0.2-0.9-0.4-1.3-0.6c-1-0.5-2.1-0.9-3.2-1.3c-0.8-0.3-1.6-0.6-2.4-1c-0.2-0.1-0.5-0.5-0.5-0.7c-0.1-1.9-0.2-3.9-0.3-5.7v-0.1
      c-0.1-1.8-0.1-3.6-0.2-5.5c-0.1-2.1-0.3-4.3-0.4-6.2v-0.3c-0.1-1.9-0.3-3.9-0.4-5.9c-0.1-1.1-0.1-2.2-0.1-3.3c0-1.2,0-2.4-0.1-3.6
      c-0.1-1.7-0.3-3.5-0.4-5.1c-0.1-0.8-0.1-1.6-0.2-2.3c0-0.1,0-0.2,0-0.4c15.1-3.1,26.9-4.8,38.2-5.6v65.8l-2.1,0.7
      c-2,0.7-3.9,1.3-5.8,1.9C162.5,185.3,162.3,185.3,162.1,185.3z M129.7,119.4L129.7,119.4c0.1,0.4,0.1,1,0.2,1.5
      c0,0.2-0.1,0.5-0.1,0.6c-2.8,1.7-6,3.6-9.1,5.4c-2.1,1.2-4.2,2.3-6.3,3.4c-1.3,0.7-2.6,1.4-3.8,2.1c-2.6,1.4-5.2,2.8-7.8,4.3
      c-1.5,0.8-3.1,1.7-4.6,2.5c-0.7,0.4-1.4,0.8-2.2,1.2l-0.9,0.5c-0.2-0.6-0.3-1.1-0.5-1.7c-0.4-1.6-0.9-3.2-1.3-4.8l-0.2-0.7
      c-0.7-2.8-1.3-5.8-1.8-8.7c-0.5-3.3-1-6.6-1.4-9.8c-0.2-1.4-0.4-2.7-0.6-4.1c0-0.2-0.1-0.5-0.1-0.7v-0.1c-0.1-0.5-0.1-1.1-0.3-1.6
      c-0.4-1.6,0.1-2.8,1.7-4c1.3-0.9,2.7-2,4-2.9c1.2-0.9,2.6-2,4-2.9c1.3-0.9,2.7-1.8,4.1-2.7h0.1c0.7-0.5,1.5-0.9,2.2-1.4
      c1.1-0.7,2.2-1.4,3.3-2.2c2-1.3,4.1-2.7,6.2-4c1.8-1.1,3.7-2.2,5.6-3.3c0.8-0.5,1.7-1,2.5-1.4l4.3-2.5c0.2-0.1,0.4-0.2,0.7-0.4
      c0,0.7,0,1.3,0.1,2c0.1,1.8,0.1,3.6,0.2,5.4c0.1,2.2,0.3,4.5,0.5,6.7c0.2,2.9,0.4,5.8,0.6,8.8c0.1,2.5,0.2,5,0.3,7.4
      c0.1,1.6,0.1,3.6,0.2,5.6C129.5,117.8,129.6,118.6,129.7,119.4z M67.7,171.4c0.3,1.5,0.5,3,0.9,4.4c0.9,4,1.6,7.3,2.4,10.3
      c0.4,1.7,0.9,3.4,1.3,5c0.4,1.5,0.8,2.9,1.1,4.3c0.5,2,1,4.1,1.5,6.1l0.1,0.3c0.5,2.1,1,4.2,1.6,6.3c0.2,0.8,0.1,1.4-0.2,1.9
      c-0.4,0.6-1.2,1-2.5,1.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.5,0.1-0.8,0.1c-0.1,0-0.2,0-0.2,0c-0.6,0-0.9-0.2-1.1-0.7
      c-0.3-1.2-0.6-2.4-0.8-3.4c-0.3-1.3-0.6-2.7-0.8-4v-0.2c-0.5-2.5-1-5-1.6-7.5c-0.3-1.4-0.7-2.7-1-4c-0.3-1-0.6-2.1-0.8-3.1
      c-0.4-1.5-0.7-3.1-1-4.5l-0.1-0.3c-0.1-0.6-0.3-1.2-0.4-1.8c-0.1-0.4-0.2-0.9-0.3-1.3l-0.1-0.4c-0.3-1.3-0.6-2.7-0.9-4
      c-0.1-0.5-0.1-0.8,0-0.8c1.1-1.4,2.3-2.9,3.3-4.2l0.6-1.2C67.5,170.5,67.6,171,67.7,171.4z M64.8,196.6c0.4-0.4,0.7-0.7,1.1-1.1
      l5.5,27.5c-0.1-0.2-0.2-0.3-0.2-0.5c-0.4-0.8-0.9-1.7-1.3-2.6c-0.3-0.6-0.6-1.3-0.9-1.9l-0.2-0.4C67,213.4,65,209,62.5,205
      l-0.2-0.3c-0.5-0.8-1.1-1.7-1.3-2.6c-0.2-0.6,0.2-1.6,0.6-2.1C62.6,198.8,63.7,197.7,64.8,196.6z M61.7,208.1
      c1.1,2.2,2.2,4.6,3.2,6.7l0.8,1.7c0.2,0.5,0.4,1,0.7,1.5l0.2,0.4c-0.2,0-0.4,0-0.6,0c-0.3,0-0.7,0-1,0c-0.5,0-0.8,0-1.2,0H63
      c-2.3,0.1-4.8,0.2-7.2,0.4c-0.2,0-0.5,0-0.6,0c-1,0-1.4-0.1-1.5-1.8l-0.1-1.1c-0.1-2.6-0.3-5.2-0.3-7.9c0-1.2,0-2.6,0-4v-0.3
      c0-0.4,0-0.8,0-1.2c0.4,0,0.7,0,1.1,0h0.1c1.2,0,2.4,0.1,3.6,0.2c0.4,0,0.8,0.5,1.1,0.9C60,204.9,60.8,206.4,61.7,208.1z
       M54.2,221.2c0,0,0.3-0.1,0.4-0.1c3.2-0.3,6.5-0.5,9.7-0.8l2.9-0.2c3.3,5.6,6.3,11.9,5.9,19.5h-0.4c-2.2-0.1-4.6-0.2-6.9-0.2
      c-3.8,0-7,0.3-9.9,1c0-0.4-0.1-0.7-0.1-1.1c-0.1-1-0.2-1.9-0.3-2.9c-0.3-2.9-0.6-6-0.9-9c-0.1-1.1-0.2-2.1-0.3-3.3
      c-0.1-0.9-0.1-1.7-0.2-2.6C54.2,221.3,54.2,221.2,54.2,221.2z M72.6,216.6v-0.3c0-0.1,0-0.2,0-0.3c-0.1-0.4-0.1-0.9-0.3-1.3
      c-0.1-0.3-0.1-0.5-0.1-0.6c0.1-0.1,0.3-0.3,0.7-0.4c0.5-0.1,1.1-0.3,1.5-0.5c0.7-0.2,1.3-0.4,2-0.6c0.2-0.1,0.4,0,0.5,0.1
      c0.1,0.1,0.2,0.3,0.2,0.6c0,3.2,0,6.5,0.1,9.8c0.1,2.5,0.3,5.1,0.5,7.4c0.1,1,0.2,1.9,0.2,2.9c0.1,1,0.2,2.1,0.2,3.1l0.1,1
      c0.3,3.4,0.6,7.2,0.9,10.9c0.4,4.2,0.9,8.4,1.4,12.6c0.1,0.8,0.3,1.6,0.5,2.4c0.2,0.8,0.4,1.6,0.5,2.5c0.1,1.3,0.3,2.6,0.3,3.8
      c-0.1-0.2-0.1-0.4-0.2-0.6c-0.2-0.7-0.5-1.5-0.7-2.1c-0.2-1.1-0.5-2-0.7-2.9c-0.9-3.9-1.6-7.8-2.3-11.5c-0.5-2.9-1.2-6.7-1.8-10.5
      c-0.2-1.5-0.4-3-0.5-4.3c-0.1-1-0.2-2.1-0.3-3.1c-0.3-2.1-0.6-4.3-0.9-6.2l-0.1-0.8C73.8,224,73.2,220.2,72.6,216.6z M79.4,221.1
      c-0.3-1.8-0.5-3.7-0.5-5.6c0-1.3,0.5-2.4,1.7-3.4c1.6-1.4,3.1-2.9,4.6-4.3c0.9-0.9,1.9-1.8,2.9-2.8c1.3-1.2,2.6-2.4,3.8-3.5
      c0.5-0.4,0.9-0.8,1.4-1.2l2.9,2.1c2.5,1.8,4.7,3.5,7,5.1c1.3,1,2.7,2,3.9,3c0.8,0.6,1.6,1.2,2.4,1.8c0.8,0.6,1,1.4,0.8,2.5
      c-0.4,2-0.8,4-1.2,6c-0.1,0.8-0.3,1.5-0.4,2.3l-0.4,2c-0.3,1.6-0.6,3.2-0.9,4.8c-0.3,1.7-0.6,3.5-0.9,5.3c-0.4,2.5-0.8,4.7-1.2,6.9
      c-0.3,1.6-0.9,1.8-1.5,1.8c-0.3,0-0.7-0.1-1.1-0.2c-3.1-1.1-7.1-2.5-11.1-4.2c-3-1.3-6.1-2.7-9-4.1l-1.9-0.9
      c-0.2-0.1-0.4-0.4-0.4-0.7c-0.3-3.7-0.5-7.2-0.8-11.2L79.4,221.1z M105.2,255.9c0.1-2.4,0.5-4.6,0.8-6.8c0.1-0.9,0.3-1.8,0.4-2.7
      c0.1-0.9,0.6-1.4,1.7-1.7c4.8-1.1,8.8-2,12.8-3.1c1.9-0.5,3.8-1,5.6-1.6h0.1c1.2-0.3,2.5-0.7,3.7-1c0.7-0.2,1.3-0.3,2.1-0.5
      c0.2,0,0.4-0.1,0.7-0.1l0.1,1.4c0.1,1.3,0.1,2.6,0.2,3.9v0.2c0.2,3.2,0.4,6.9,0.7,10.4c0.1,0.8,0.3,1.6,0.7,2.4
      c0.3,0.6,0.1,0.9-0.3,1.4c-0.3,0.3-0.6,0.6-0.9,0.9l-0.1,0.1c-1,0.9-2,1.9-3,2.9c-1,1.2-2,2.4-3,3.6v0.1c-0.7,0.9-1.5,1.8-2.2,2.7
      l-0.3,0.4c-0.7,0.8-1.4,1.6-2.2,2.1c-0.3,0.1-0.7,0.2-1.2,0.2c-0.3,0-0.5,0-0.8,0s-0.6,0-0.9,0h-0.1c-0.6,0-1.3,0-1.9,0
      c-0.6,0-1.3,0-1.9,0s-1.2,0-1.7,0.1c-2.8,0.2-5.4,0.6-8.3,0.9c-1,0.1-2,0.2-3,0.4c0.2-1.6,0.4-3.1,0.6-4.7
      C104.2,263.7,104.7,259.9,105.2,255.9z M107.6,273.9c1.2-0.1,2.6-0.3,4-0.4c1.9-0.2,4-0.3,6.2-0.4c1.1,0,2.1,0,3.1,0h0.1
      c0.3,0,0.6,0,0.8,0c0,0.4,0.1,0.8,0.1,1.2c0.1,1.2,0.2,2.1,0.3,3.2c0,0.4,0,0.9,0,1.4c0,0.3,0,0.7,0,1c0,0.4,0,0.8,0,1.2
      c0,1.4,0,2.8,0.1,4.2c0.1,1,0.2,2,0.4,3c0.1,0.6,0.2,1.3,0.3,1.9c0.1,1.3,0.3,2.7,0.4,4v0.1c0.2,2.4,0.5,4.9,0.8,7.3
      c0.5,3.4,1.1,6.8,1.7,10.1l0.1,0.4c0.1,0.6,0,0.9-0.4,1.4c-1.6,1.9-3.6,3.4-5.5,4.8c-0.6,0.4-1.1,0.8-1.7,1.2
      c-1.6,1.2-3.1,2.5-4.6,3.8c-0.5,0.5-1.1,0.9-1.6,1.4c-1.1,0.9-2.1,1.8-3.2,2.7l-1.1,1c0-0.2-0.1-0.4-0.1-0.6v-0.1
      c-0.2-0.9-0.4-1.7-0.5-2.5l-0.4-2.5c-0.6-3.5-1.3-7.6-1.9-11.4c-0.4-2.7-0.8-5.5-1.1-8.2l-0.1-0.5c-0.1-1-0.2-2-0.4-3
      c-0.4-0.8-0.5-1.7-0.7-2.6c-0.3-2.3-0.6-4.8-0.9-7.3l-0.1-0.7c-0.3-2.4-0.5-5-0.5-7.4c0-1.6,0.3-3.1,0.5-4.7
      c0.1-0.6,0.2-1.2,0.3-1.7c0.1-0.6,0.2-0.7,0.7-0.8C104.3,274.3,106,274.1,107.6,273.9z M120.4,340.4c-1.1-0.7-2.4-1.6-3.7-2.4
      c-0.9-0.6-1.8-1.1-2.8-1.7c-0.8-0.5-1.7-1-2.5-1.5c-0.8-0.5-1.5-1.1-2.1-1.7c0,0-0.1-0.2-0.1-0.5c0-0.5,0-1,0-1.4l-0.1-0.4l18-15
      l0.5,0.4c0.6,0.5,1.2,1,1.8,1.5c0.7,0.6,1.4,1.2,2.2,1.8l0.2,0.2c1.7,1.5,3.5,3,5.4,4.5c0.9,0.8,1.9,1.5,2.9,2.1
      c0.8,0.6,1.7,1.2,2.5,1.9c0.9,0.7,1.7,1.4,2.5,2.1c0.8,0.7,1.6,1.3,2.4,2c0.4,0.3,0.4,0.5,0.2,1c-0.4,1.4-0.8,2.9-1.1,4.3v0.1
      c-0.6,2.2-1.2,4.5-1.8,6.7c-0.7,2.3-1.5,4.7-2.2,7l-0.5,1.4c-0.2,0.6-0.5,0.6-0.6,0.6c-0.2,0-0.4-0.1-0.6-0.2
      c-1.1-0.7-2.1-1.4-3.2-2c-1.4-0.9-2.9-1.9-4.4-2.8c-1.2-0.8-2.5-1.5-3.7-2.2l-0.2-0.1c-1.5-0.9-3.1-1.8-4.6-2.8
      C123.3,342.4,121.8,341.4,120.4,340.4z M197.4,338.5l-0.1-0.3c-0.2-0.8-0.4-1.5-0.6-2.3c-0.1-0.2-0.1-0.4-0.1-0.6
      c-0.1-0.4-0.2-0.9-0.4-1.3c-0.5-0.9-0.3-1.6,0.7-2.3c1.4-1,2.8-2.1,4-3c1.6-1.2,3.5-2.6,5.3-4c1.3-1,2.6-2.1,3.8-3.1l0.1-0.1
      c0.7-0.6,1.3-1.1,2-1.7c1.1-0.9,2.3-1.9,3.4-2.8l1.3-1.1c0.6,0.4,1.1,0.9,1.6,1.3c1.4,1.2,2.8,2.2,4.2,3.4c0.7,0.6,1.4,1.2,2.1,1.8
      l0.1,0.1c0.7,0.6,1.4,1.2,2.2,1.8c2.9,2.4,5.4,4.4,7.7,6.1c0.4,0.3,0.4,0.4,0.3,0.7c-0.3,0.1-0.4,0.4-0.3,0.6
      c0.3,1.5-0.6,2.1-1.7,2.8c-0.2,0.1-0.3,0.2-0.5,0.3l-0.4,0.3c-3,2-6.1,4-9.2,5.9c-1.2,0.8-2.5,1.5-3.7,2.2l-0.3,0.2
      c-0.6,0.3-1.2,0.7-1.8,1.1l-0.6,0.4c-1.3,0.8-2.7,1.6-4,2.5c-1.3,0.8-2.7,1.7-4,2.5c-1.1,0.7-2.2,1.4-3.3,2.1
      c-0.9,0.6-1.6,1-2.3,1.4c-0.2,0.1-0.4,0.2-0.6,0.2c-0.1,0-0.3,0-0.5-0.5c-0.3-0.8-0.6-1.7-0.8-2.5c-0.7-2-1.4-4.1-2-6.1
      C198.4,342.5,197.8,340.3,197.4,338.5z M238.7,315.4c-0.5,3.5-1.2,7.1-1.8,10.3l-0.4,1.7c-0.1,0.3-0.2,0.7-0.3,1.1v0.1
      c-0.9-0.7-1.7-1.5-2.6-2.2c-2.4-2-4.6-4-6.9-5.9c-1-0.8-2-1.5-3-2.2c-0.9-0.7-1.9-1.3-2.8-2.1c-0.8-0.7-1.6-1.5-2.3-2.3
      c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.2-0.2-0.4-0.2-0.6c0.2-1,0.4-1.9,0.6-2.9v-0.2c0.3-1.4,0.6-2.9,0.8-4.3c0.3-1.6,0.4-3.2,0.6-4.7
      c0.1-0.5,0.1-1,0.2-1.5c0.3-2.7,0.6-5.6,1-9.1l0.1-1.4c0.2-2.2,0.5-4.6,0.7-6.8c0-0.4,0-0.7-0.1-1.1c0-0.3-0.1-0.6-0.1-0.9
      c0-0.7,0.1-1.4,0.2-2c0.1-0.7,0.1-1.5,0.2-2.2c0-0.5-0.1-1-0.1-1.5c0-0.2,0-0.3-0.1-0.5c0.2,0,0.5,0,0.7-0.1c0.7-0.1,1.3-0.1,2-0.1
      h0.2c2,0,4.3,0.1,6.6,0.3c1.1,0.1,2.3,0.3,3.4,0.4c1.6,0.2,3.3,0.4,5,0.5c1.6,0.1,2.1,0.4,2.3,2.1c0.1,0.7,0.2,1.3,0.2,2
      c0.3,2.1,0.5,4.2,0.3,6.3c-0.3,2.1-0.5,4.4-0.7,6.3c-0.3,2.5-0.5,5-0.8,7.6C240.4,304.6,239.5,310.4,238.7,315.4z M239.4,272.1
      c-1.6-0.2-3.2-0.3-4.8-0.5l-1.8-0.2c-2.3-0.2-4.6-0.4-6.9-0.6c-0.2,0-0.4,0-0.6,0c-0.6,0-1.1,0-1.7,0.1c-0.5,0-1.1,0.1-1.6,0.1
      c-0.2,0-0.5-0.1-0.9-0.4c-1.8-1.4-3.2-3.2-4.5-4.9c-0.7-0.9-1.4-1.8-2.2-2.7c-1.2-1.3-2.5-2.7-4.2-4.1c-1.2-1-1.2-1-0.8-2.6
      c0.4-2.2,0.6-4.5,0.8-6.6c0.2-1.8,0.3-3.6,0.4-5.4l0.1-1.2c0.1-1.2,0.1-2.4,0.2-3.4v-1.2c0.4,0.1,0.8,0.2,1.1,0.2
      c1.1,0.2,2.1,0.4,3.1,0.7c2.2,0.6,4.3,1.2,6.4,1.8c2.5,0.7,5.3,1.5,8.1,2.2c2,0.5,4,0.9,6.2,1.3c1,0.2,1.5,0.7,1.7,1.9
      c0.6,4.1,1.2,8.3,1.8,12.3l0.6,3.8c0.3,2,0.6,3.9,0.9,5.9l0.5,3.6L239.4,272.1z M238.9,242.9c-0.3-1.6-0.6-3.2-0.9-4.8
      c-0.2-1.3-0.5-2.6-0.7-3.8c-0.1-0.8-0.2-1.6-0.4-2.4v-0.1c-0.1-0.8-0.2-1.7-0.4-2.5c-0.2-1.1-0.4-2.2-0.6-3.3
      c-0.2-1-0.4-1.9-0.5-2.8c-0.1-0.9-0.3-1.9-0.5-2.8c-0.2-1-0.3-2-0.5-3.1c-0.2-1-0.4-1.9-0.7-2.7c-0.4-1.1,0.2-1.8,1.2-2.5
      c1.3-1,2.6-1.9,4-2.9c1.4-1,3-2.2,4.6-3.4c1.7-1.3,3.3-2.5,5.1-3.9c0.7-0.5,1.4-1.1,2.1-1.6c0.3,0.2,0.6,0.5,0.9,0.8
      c0.9,0.7,1.8,1.4,2.6,2.2c1.9,1.8,3.8,3.7,5.7,5.6l0.4,0.4c0.7,0.7,1.3,1.3,2,2l0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.2
      c2.6,1.4,2.8,3.8,2.6,6.2c-0.3,4.7-0.7,9.9-1.3,16.1c0,0.3-0.2,0.5-0.3,0.6c-0.7,0.3-1.5,0.7-2.2,1c-3.1,1.5-6.3,3-9.5,4.3
      c-2.6,1.1-5.3,2.1-7.9,3c-0.8,0.3-1.6,0.6-2.4,0.9c-0.5,0.2-1,0.3-1.5,0.2C239.4,243.8,239,243.5,238.9,242.9z M263.9,255.5
      L263.9,255.5c0.1-0.8,0.1-1.6,0.2-2.3c0.5-4.2,0.8-7.6,1.2-10.7c0.2-2.3,0.4-4.5,0.6-7.1c0.1-0.8,0.1-1.6,0.2-2.4v-0.1
      c0.2-2.6,0.4-5.2,0.5-7.9c0.1-2.7,0.2-5.5,0.2-8.1c0-1.1,0-2.3,0.1-3.4c0-0.2,0-0.5,0-0.8v-0.2l0.6,0.1c0.7,0.1,1.4,0.3,2.1,0.5
      c0.2,0,0.4,0.1,0.6,0.1c0.6,0.1,1.3,0.3,1.5,0.6c0.1,0.2,0,0.8-0.1,1.3c-0.1,0.2-0.1,0.5-0.2,0.7c-0.2,1.5-0.5,2.9-0.7,4.4
      l-0.1,0.5c-0.5,3.4-1.1,6.9-1.6,10.4c-0.3,1.9-0.5,3.8-0.6,5.6v0.1c-0.2,1.5-0.3,3.1-0.5,4.6c-0.3,2.6-0.8,5.2-1.2,7.7
      c0,0.7-0.1,1.1-0.2,1.5l-0.1,0.4c-0.4,2.2-0.7,4.4-1.3,6.6c-0.2,1-0.4,2-0.6,3v0.1c-0.5,2.5-1,5.1-2.1,7.4
      c-0.1,0.3-0.3,0.6-0.4,0.9c0-0.2,0-0.3,0-0.5v-0.1c0.1-2.1,0.2-4.2,0.8-6.1C263.5,260,263.7,257.7,263.9,255.5z M276.6,220.2
      c0.5,0,0.9,0,1.4,0.1h0.7c1.6,0.1,3.3,0.2,4.9,0.3c1.7,0.1,3.5,0.3,5.8,0.6c0.1,0,0.2,0.2,0.2,0.3c-0.3,3.8-0.7,7.6-1.1,11.4
      l-0.3,4.5c-0.1,0.7-0.1,1.3-0.2,2.1l-0.1,0.8c-3-0.7-6.2-1-10.1-1c-2.4,0-4.7,0.1-7.1,0.3C270.3,231.8,273.4,225.4,276.6,220.2z
       M288.8,218.7c-0.1,0-0.2,0-0.2,0c-0.4,0.1-0.9,0-1.4-0.1c-0.6-0.1-1.3-0.2-1.9-0.2c-1.1,0-2.2,0-3.6,0c-0.8,0-1.6,0-2.4,0
      c-0.6,0-1.3,0-2,0c0.1-0.3,0.3-0.6,0.4-0.9l0.1-0.2c0.5-1.1,0.9-2.1,1.4-3.1c1-1.9,2-3.8,2.9-5.6l0.1-0.1c0.6-1.1,1.2-2.3,1.8-3.4
      c0.1-0.2,0.2-0.5,0.3-0.8c0.1-0.3,0.2-0.6,0.4-0.9c0.3-0.4,0.7-0.8,0.9-0.8c1.3-0.1,2.6-0.2,3.8-0.2c0.3,0,0.7,0,1,0
      c0,0.5,0,1.1,0,1.6c0,1.4,0,2.7,0,4.1c-0.1,2.6-0.2,5.3-0.3,7.9l-0.1,1.4C290,218.3,289.6,218.7,288.8,218.7z M282.5,126.4
      c0.2-1.4,0.4-2.8,0.6-4.2v-0.1l0.1,0.8c0.3,1.9,0.6,3.8,0.9,5.7l0.1,0.9c0.2,1.1,0.4,2.3,0.5,3.5c0.2,1.3,0.3,2.7,0.5,4
      c0.2,2.1,0.5,4.6,0.9,6.9c0.3,2.4-0.2,4.5-0.7,6.8l-0.1,0.4c-0.5,2-0.8,4-1.2,6v0.1c-0.2,1.2-0.4,2.4-0.7,3.6
      c-0.3,1.6-0.7,3.1-1,4.7l-0.1,0.3c-0.3,1.2-0.5,2.5-0.8,3.7c-0.2,0.9-0.3,1.7-0.5,2.6l-0.1,0.6l-0.4-1.1c-1.1-1.3-2.2-2.6-3.2-3.9
      c-0.2-0.2-0.3-0.7-0.2-1.1c0.3-1.7,0.5-3.5,0.8-5.2c0.3-1.8,0.6-3.9,0.9-5.9c0.2-1.2,0.3-2.3,0.4-3.5v-0.1c0.1-0.9,0.2-1.9,0.3-2.8
      c0.2-1.4,0.4-2.8,0.6-4.1v-0.3c0.1-0.9,0.3-1.8,0.4-2.6c0.1-0.9,0.2-1.9,0.3-2.7l0.1-1.1c0-0.1,0-0.1,0-0.2
      c0.7-1.1,0.6-2.3,0.6-3.4c0-0.8-0.1-1.6,0.1-2.3c0.4-1.4,0.6-2.8,0.7-4.2C282.4,127.6,282.4,127,282.5,126.4z M259.1,86.7
      c0.1-1.6,0.3-3.1,0.4-4.7v-0.1c0.1-1.1,0.2-2.2,0.2-3.4c0.1-0.8,0.2-1.6,0.3-2.4v-0.2c0-0.1,0-0.3,0.1-0.4c0.3,0.3,0.6,0.7,0.9,1
      c0.9,1.1,1.9,2.1,2.7,3.3c1.6,2.1,2.9,4,4.2,5.9c2.5,4,4.7,7.5,6.6,10.7c1,1.6,1.8,3.3,2.7,4.9c0.4,0.8,0.9,1.8,1.4,2.7
      c1.6,2.9,2.6,6.2,3.3,10.4c0.3,1.9,0,3.6-0.3,5.2c-0.1,0.6-0.2,1.2-0.3,1.8c-0.4,2.8-0.8,5.6-1.3,8.5v0.1c-0.2,0.9-0.3,1.9-0.5,2.9
      c-0.1-0.2-0.3-0.3-0.4-0.5c-0.7-1.1-1.3-2.1-2-3.2c-1.2-2-2.5-4-3.8-6c-1.1-1.7-2.3-3.3-3.5-4.9l-0.1-0.1c-0.6-0.9-1.3-1.8-1.9-2.7
      c-0.5-0.8-1-1.5-1.6-2.3c-0.9-1.3-1.9-2.7-2.9-4c-1.1-1.4-2.3-2.7-3.4-4l-0.1-0.1c-0.7-0.7-1.4-1.5-2-2.3c-0.1-0.2-0.2-0.5-0.2-0.7
      c0.2-2.4,0.5-4.8,0.7-7.2C258.6,92.2,258.8,89.5,259.1,86.7z M214.5,41.1c1.1,0.5,2,1,3.1,1.5c1.6,0.8,3.2,1.5,4.8,2.2
      c2.8,1.3,5.7,2.6,8.6,4c1.4,0.7,2.7,1.4,4,2.1h0.1c1.4,0.8,3,1.7,4.5,2.4c3.3,1.5,6.1,3.9,9,7.7c1.3,1.6,2.6,3.3,3.9,4.9l0.1,0.2
      c1,1.3,2.1,2.6,3.1,3.9c0.2,0.3,0.5,0.6,0.7,0.9c0.4,0.6,0.8,1.2,1.3,1.7c0.7,0.6,0.6,1.2,0.5,2c0,0.2-0.1,0.4-0.1,0.6
      c-0.3,2.5-0.5,5-0.8,7.5l-0.1,0.7c-0.1,0.9-0.2,1.8-0.3,2.7l-0.5,5.1c-0.3,3.3-0.7,6.6-1,9.9c-0.1,0.6-0.1,1.2-0.1,1.7
      c0,0.2,0,0.3,0,0.5c-0.3-0.2-0.5-0.4-0.8-0.6c-0.8-0.6-1.6-1.3-2.5-1.9c-0.5-0.4-1-0.7-1.5-1.1l-0.3-0.2c-2.1-1.5-4.3-3.1-6.5-4.6
      c-5.6-3.7-10.2-6.7-14.7-9.5c-1.6-1-3.3-1.9-4.9-2.7c-0.9-0.5-1.9-1-2.9-1.5c-1.4-0.8-2.8-1.6-4.5-2.7c-0.1,0-0.2-0.3-0.2-0.5
      c-0.1-1-0.1-1.9,0-2.7l0.1-2.5c0.2-3.8,0.4-7.7,0.6-11.6c0.2-2.4-0.2-4.8-0.6-7.1c-0.1-0.4-0.1-0.8-0.2-1.2c-0.4-2.5-0.9-5-1.5-7.3
      l-0.2-0.8c-0.2-0.8-0.4-1.6-0.6-2.3c-0.1-0.4-0.2-0.8-0.4-1.2c-0.1-0.2-0.1-0.4-0.1-0.6L214.5,41.1z M173.1,31.3c0.3,0,0.6,0,0.8,0
      c0.4,0,0.7,0,1.1,0c0.5,0,0.9,0,1.2,0c1.4,0.1,2.8,0.2,4.1,0.3h0.2c1.4,0.1,2.9,0.2,4.3,0.3c1.5,0.1,3,0.2,4.5,0.2
      c1.7,0.1,3.3,0.2,4.8,0.3c0.5,0,1.1,0.2,1.7,0.5c3.9,1.5,7,2.7,9.9,3.9h0.1c1.5,0.6,3,1.2,4.5,1.9c0.4,0.2,0.8,0.7,1,1.3
      c0.5,1.7,1,3.5,1.4,5.2c1.2,5,2.2,9.5,2.4,14.1c0,3.1-0.2,6.2-0.4,9.2c-0.1,1.1-0.2,2.6-0.2,3.9c-0.1,1.2-0.1,2.5-0.2,3.7l-0.1,1.3
      c-5.9-1.4-11.8-2.9-17.5-4.4l-0.2-0.1c-7.6-2-15.5-4-23.4-5.8V31.3z M173.2,70.2c0-0.3,0-0.6,0.1-0.9l41,10.3l-2.3,41.1
      c-0.4-0.1-0.7-0.2-1.1-0.2l-0.4-0.1c-1.4-0.3-2.7-0.6-4-0.9c-2.9-0.6-5.7-1.1-8.1-1.5c-1.8-0.3-3.9-0.7-5.9-1
      c-3.8-0.5-7.1-0.9-10.3-1.3c-1.8-0.2-3.7-0.4-5.4-0.5h-0.3c-0.9-0.1-1.7-0.1-2.6-0.2c-0.4,0-0.5-0.1-0.5-0.6c0-5.7,0-10.9,0-15.9
      c0-1.9-0.1-3.9-0.1-5.8v-0.1c0-1.7-0.1-3.6-0.1-5.5c0-1.3,0-2.7,0-4V83c0-1.2,0-2.5,0-3.7c0-1,0-2.1-0.1-3.1c0-1.1-0.1-2.2-0.1-3.3
      C173,72.1,173.1,71.1,173.2,70.2z M172,184.4c0.3,0,0.6,0.1,1,0.2l0.2,0.1c1.1,0.3,2.1,0.7,3.2,1l0.2,0.4c1.5,0.5,2.9,0.9,4.2,1.3
      c0.5,0.1,0.6,0.3,0.5,0.8c-0.4,4.7-0.8,9.5-1.2,14.3c-0.2,2.3-0.4,4.6-0.5,6.9c-0.1,1.4-0.2,2.8-0.3,4.1c0,0.1,0,0.2-0.1,0.4
      l-0.9-0.1c-1-0.1-2-0.3-2.9-0.4c-0.1,0-0.2-0.1-0.3-0.1c-0.6,0-1.2,0-1.9,0h-0.1c-1.6,0-3.3,0.1-5,0.1c-0.9,0-1.7,0.1-2.6,0.2
      c-0.3,0-0.6,0.1-0.9,0.1c0-0.4-0.1-0.9-0.1-1.3v-0.1c-0.1-1.2-0.1-2.3-0.2-3.4c-0.2-1.8-0.3-3.7-0.5-5.5v-0.1
      c-0.2-2.3-0.4-4.7-0.6-7c-0.2-2.6-0.4-5.4-0.6-8.5c0-0.1,0.2-0.5,0.4-0.6c3.3-1.1,6.2-2,8.8-2.8C171.9,184.4,171.9,184.4,172,184.4
      z M132.7,39.4c0.1-0.3,0.5-0.7,0.9-0.8c3.8-1.6,7.6-3.2,10.8-4.6c3.2-1.4,6.8-2.1,11-2.2c2.5-0.1,5.1-0.2,7.6-0.4h0.1
      c1.6-0.1,3-0.2,4.4-0.2c0.4,0.2,0.8,0.1,1.3,0.1c0.3,0,0.6,0,0.9,0h0.2c0.3,0,0.6,0,0.9,0v35.8c-7.9,1.8-15.8,3.8-23.5,5.8h-0.1
      c-5.7,1.5-11.6,3-17.5,4.4c0-0.2-0.1-0.5-0.1-0.7c-0.1-0.8-0.2-1.6-0.2-2.4c-0.1-1.4-0.1-2.9-0.2-4.3c0-1.4-0.1-2.8-0.2-4.3V65
      c-0.2-3.6-0.4-7.3,0.4-10.9c0.4-1.9,0.8-3.7,1.1-5.6v-0.1c0.3-1.7,0.7-3.6,1.1-5.5C131.8,41.8,132.2,40.7,132.7,39.4z M86.4,72.2
      c0.6-0.8,1.3-1.7,1.9-2.5l0.1-0.1c1.1-1.5,2.2-3,3.4-4.5c3-3.6,5.7-6.6,8.4-9.4c0.6-0.6,1.5-1.1,2.4-1.6c0.4-0.2,0.8-0.4,1.2-0.6
      c5.1-3.1,10.4-5.5,14.9-7.6c2.7-1.2,5.4-2.5,8-3.8l0.3-0.2c1.1-0.5,2.2-1.1,3.3-1.6c0.1,0,0.1-0.1,0.2-0.1l-0.3,0.5
      c-0.2,0.6-0.3,1.2-0.5,1.8c-0.2,0.7-0.3,1.3-0.5,1.9c-0.4,1.3-0.7,2.7-1,4.1c-0.2,1.1-0.4,2.2-0.6,3.2v0.1
      c-0.1,0.9-0.3,1.9-0.5,2.9c-0.6,3-0.5,6-0.3,8.9c0,0.4,0,0.9,0.1,1.3c0.1,1.8,0.2,3.7,0.3,5.5V71c0.1,1.3,0.1,2.6,0.2,3.8
      c0,1,0,1.9,0,2.8c0,0.2-0.2,0.5-0.3,0.6c-1.4,0.8-2.9,1.6-4.4,2.5c-2.1,1.2-4.4,2.5-6.6,3.8c-1.8,1.1-3.6,2.2-5.3,3.3
      c-0.8,0.6-1.7,1.1-2.6,1.7c-0.9,0.6-1.7,1.1-2.6,1.7l-0.1,0.1c-1.7,1-3.4,2.1-5,3.2c-2,1.3-4,2.8-5.9,4.2L94,99.1
      c-1.3,0.9-2.5,1.9-3.8,2.9l-0.2,0.1c-0.5,0.3-0.9,0.7-1.4,1c0-0.2,0-0.4-0.1-0.7v-0.3c-0.1-1.1-0.2-2.1-0.3-3.1
      c-0.2-2.2-0.5-4.5-0.7-6.7l-0.1-0.6c-0.3-2.7-0.6-5.3-0.8-8c-0.1-1.3-0.2-2.6-0.3-3.9v-0.1c-0.1-0.9-0.2-1.9-0.2-2.8
      c0-0.2,0-0.4,0-0.5c0-0.6-0.1-1.2-0.3-1.8C85.4,73.7,85.7,73.1,86.4,72.2z M64.5,106.1c0.3-0.6,0.5-1.2,0.8-1.7
      c0.8-1.7,1.6-3.4,2.6-5.1c2-3.4,4.1-6.9,6.1-10.1c1.6-2.6,3.3-5.2,5-7.5c1.1-1.5,2.3-3,3.5-4.4c0.4-0.5,0.9-1.1,1.3-1.6l0.1,1.7
      c0.1,1.5,0.2,3,0.4,4.4c0.2,1.9,0.4,3.7,0.5,5.6v0.1c0.2,1.6,0.3,3.3,0.5,4.9c0.3,3.6,0.6,6.9,0.9,10c0.1,0.1,0,0.3-0.1,0.4
      c-0.9,1.1-1.8,2.1-2.7,3.2c-1.6,1.9-3.2,3.8-4.7,5.8c-2.5,3.2-4.7,6.2-6.6,9.1c-1.7,2.5-3.3,5.2-4.9,7.7c-0.8,1.2-1.5,2.5-2.3,3.7
      c-0.2,0.2-0.3,0.5-0.5,0.8c-0.2-1.3-0.4-2.6-0.6-3.9c-0.5-3.5-1-6.9-1.6-10.3C61.5,114.8,62.3,110.6,64.5,106.1z M62.8,172.4v-0.1
      c-0.1-0.7-0.2-1.3-0.4-1.9c-0.2-0.9-0.4-1.8-0.6-2.7v-0.1c-0.3-1.5-0.7-3.2-1-4.8c-0.3-1.3-0.5-2.7-0.7-4v-0.1
      c-0.2-1.2-0.4-2.5-0.7-3.7l-0.1-0.4c-0.3-1.6-0.6-3.3-1.2-4.9c-0.6-1.8-0.7-3.6-0.5-5.5c0.2-1.6,0.4-3.3,0.6-4.9v-0.1
      c0.3-2.6,0.6-5.3,1-8c0.4-2.9,0.9-5.9,1.5-8.7c0.2,1.2,0.4,2.3,0.5,3.5c0.3,1.7,0.5,3.5,0.8,5.2l0.4,2.6c0,0.1,0,0.1,0,0.2l0.1-0.1
      c0,0,0,0.1,0,0.1c-0.3,1.4,0,2.7,0.3,4c0.2,0.7,0.3,1.4,0.4,2.1c0.2,2,0.4,4.1,0.8,6.3c0.3,2.3,0.7,4.7,1,7l0.1,0.6
      c0.3,1.7,0.5,3.4,0.8,5.1c0.4,2.4,0.7,5,1,7.6c0,0.3-0.1,0.8-0.4,1.2c-1,1.2-2,2.4-3.1,3.7l-0.1,0.2
      C63.1,172,62.9,172.2,62.8,172.4z M43.6,198.6c1.4-1.8,2.7-3.7,4.1-5.5c0.1-0.1,0.2-0.3,0.3-0.5c0.2-0.4,0.5-0.7,0.8-0.9l0.1-0.1
      c0.6-0.5,1.3-1.1,2-1.3c3.6-1,7.4-2,11.5-3.1c0.4-0.1,0.6-0.1,0.8-0.1s0.2,0,0.5,1.1c-0.1,0.6,0,1,0.2,1.4c0.2,0.6,0.4,1.3,0.5,1.9
      c0.1,0.8,0.1,1.4-0.1,1.8c-0.3,0.6-0.8,1.1-1.3,1.6c-0.2,0.2-0.5,0.5-0.7,0.8l-0.7,0.8c-0.9,1.1-1.9,2.2-2.7,3.4
      c-0.3,0.4-0.4,0.5-0.9,0.5h-0.1c-1.2,0-2.4-0.1-3.5-0.2h-0.1c-1.4-0.1-2.9-0.2-4.3-0.2c-1.6,0-2.9,0.1-4.2,0.4
      c-0.8,0.2-1.6,0.4-2.4,0.6c-0.4,0.1-0.8,0.2-1.2,0.3c-0.3,0.1-0.6,0.1-0.8,0.2L43.6,198.6z M49.8,231.7c-0.2,0.1-0.4,0.2-0.7,0.3
      c-0.2-0.7-0.5-1.5-0.8-2.2c-0.7-2-1.4-4.1-2.1-6.2c-0.3-0.9-0.7-1.8-1-2.7c-0.3-0.7-0.6-1.6-0.9-2.4c-1.2-3.2-2.3-6.5-3.4-9.6v-0.1
      c-0.3-0.8-0.6-1.7-0.8-2.6c-0.1-0.6-0.1-1.3,0-2c0,0,0.2-0.2,0.3-0.2c1.9-0.5,3.8-0.9,5.7-1.3l1.7-0.4c0.1,0,0.2,0,0.2-0.1
      c0.8-0.1,1.3-0.1,1.7-0.1c0.9,0,1,0.1,1.2,2.5c0.1,1.6,0.1,3.2,0.2,4.7v0.2c0,2,0.1,4,0.2,6c0.1,1.8,0.4,3.7,0.6,5.5
      c0.1,1.1,0.3,2.2,0.4,3.4c0.2,1.9,0.4,4,0.4,6c0,0.1-0.3,0.3-0.6,0.4C51.4,231.1,50.6,231.4,49.8,231.7z M51.5,247.1L50,234.3
      l1.4-0.8l1.5-0.7l1.1,13.5L51.5,247.1z M65,269.7c0.1,1.4,0.1,2.9,0.1,4.4c0,0.5,0,1,0,1.5l-0.3-0.2c-0.4-0.3-0.8-0.6-1.2-1
      c-0.6-0.5-1.1-0.9-1.7-1.4l-0.2-0.1c-1.4-1.2-3.1-2.5-4.5-3.8c-1.4-1.3-2.3-2.4-2.8-3.5c-0.4-0.8-0.5-1.7-0.6-2.7
      c0-0.5-0.1-0.9-0.2-1.4l-0.6-3.6c-0.4-2.1-0.7-4.2-1.1-6.3c-0.1-0.5-0.1-1-0.1-1.5c0-0.1,0-0.3,0-0.4c0.3-0.1,0.4-0.2,0.6-0.2
      l0.1-0.1c0.6-0.2,1.2-0.5,1.8-0.8c0.4-0.2,0.6-0.2,1,0.3l2.1,2.5c2.4,2.9,5,6,7.4,9c0.2,0.2,0.3,0.6,0.3,0.9
      C64.7,263.8,64.7,266.4,65,269.7z M63.4,255c-1.7-2.1-3.5-4.3-5.5-6.2c-1.5-1.5-1.6-3.3-1.7-5.3c0-0.4,0-0.7-0.1-1.1
      c4.1-1,8.3-1.1,11.9-1.1c0.7,0,1.4,0,2.2,0c1.1,0,2.2,0,3.2,0l2.8,17l-2.8,0.2c-0.4,0-0.8,0.1-1.2,0.1h-0.4
      c-1.4,0.1-2.9,0.2-4.5,0.3c-0.4,0-0.8-0.2-1-0.4C65.3,257.3,64.2,256.1,63.4,255z M82.8,279.5c-0.1,0.1-0.4,0.2-0.7,0.1
      c-2.1-0.2-4.1-0.5-6.2-0.7l-0.9-0.1c-1.8-0.2-3.6-0.4-5.4-0.6c-0.6-0.1-1.1-0.2-1.6-0.3c-0.4-0.1-0.6-0.3-0.5-1v-0.3
      c0.1-1.8,0.2-3.7,0.1-5.5c0-1.4-0.1-2.8-0.2-4c0-0.6-0.1-1.2-0.1-1.9c0-0.9-0.1-1.8-0.1-2.7v-1l9.7-0.9c0,0.1,0,0.1,0,0.2v0.2
      c0.1,0.5,0.2,0.9,0.4,1.4c0.8,2.2,1.5,4.4,2.3,6.6l0.1,0.4c0.7,2,1.4,3.9,2,5.9c0.4,1.2,0.8,2.4,1.1,3.5
      C82.9,279.1,82.9,279.4,82.8,279.5z M83.4,262.1c-0.1-0.7-0.3-1.4-0.6-2c-0.2-0.7-0.5-1.5-0.6-2.2c0-2.1-0.2-4.2-0.4-6.3
      c-0.1-0.9-0.2-1.8-0.3-2.8s-0.2-1.9-0.3-2.9c-0.2-1.5-0.3-3.1-0.5-4.6c-0.1-1.2-0.2-2.4-0.3-3.5l-0.1-1.2h0.1
      c0.4,0.1,0.9,0.3,1.2,0.5c1.1,0.5,2.2,1.1,3.2,1.6c2.1,1.1,4.2,2.1,6.4,3.1c2.5,1.1,5.1,1.9,7.6,2.8l0.3,0.1
      c0.5,0.2,1.1,0.4,1.6,0.5c0.8,0.3,1.6,0.4,2.5,0.6c0.3,0.1,0.6,0.1,0.9,0.2l-0.2,1.1c-0.2,1.1-0.3,2.2-0.5,3.2
      c-0.3,1.7-0.5,3.4-0.8,5.1c-0.4,2.5-0.8,5-1.2,7.5c-0.2,1.4-0.4,2.9-0.6,4.4s-0.4,3.1-0.7,4.8c-0.1,0.4-0.2,0.4-0.2,0.4
      c-0.2,0-0.4-0.1-0.6-0.2h-0.1c-1.3-0.7-2.6-1.5-3.8-2.2l-0.2-0.1c-0.7-0.4-1.4-0.9-2.2-1.4l-0.1-0.1c-1-0.6-2.1-1.3-3.2-2
      c-1.2-0.7-2.5-1.5-3.7-2.1c-0.6-0.3-1.2-0.7-1.8-1C83.7,263.1,83.5,262.8,83.4,262.1z M95.3,304.4l-0.1-0.1
      c-1.4-2.7-2.8-5.4-4.1-8.1c-0.6-1.3-1.2-2.7-1.7-4c-0.7-1.6-1.3-3.3-2.2-4.9c-1.3-2.5-1.6-5.2-1.9-7.9c0-0.4-0.1-0.7-0.1-1.1
      c-0.3-3-0.6-5.9-0.9-9v-0.1c-0.1-1.1-0.2-2.3-0.3-3.4l15.7,9.4c-0.7,3.7-1.3,7.6-0.5,11.8c0.4,2.4,0.7,4.9,0.9,7.3v0.1
      c0.1,1.4,0.3,2.9,0.5,4.3c0.7,5.5,1.4,10.9,2.2,16.2c0.3,2.3,0.8,4.7,1.2,6.9c0.1,0.5,0.2,0.9,0.3,1.4c-0.3-0.6-0.5-1.2-0.8-1.8
      l-0.2-0.4c-1.2-2.8-2.5-5.7-3.9-8.5C98.1,309.8,96.7,307,95.3,304.4z M107.1,426.5l-0.1,0.2c-0.4,0.9-0.8,1.7-1.2,2.6
      c-0.1,0.2-0.2,0.4-0.3,0.6v0.1c-7.7-7.1-13.9-13.3-19.4-19.5c0.2-0.3,0.3-0.5,0.5-0.8c0.5-0.8,1-1.5,1.5-2.3c0.7-1,1.4-1.9,2.1-2.9
      c0.6-0.9,1.2-1.7,1.8-2.6l0.5-0.7c0.7-1,1.4-2,2-3c1.4-2.4,2.8-4.9,4.2-7.3v-0.2c0.6-1.1,1.2-2.1,1.8-3.2c0.2,0.2,0.4,0.4,0.7,0.7
      l0.1,0.1c0.8,0.8,1.6,1.6,2.4,2.3c2,1.7,4.1,3.5,6.3,5.1c1.8,1.4,3.8,2.7,5.6,3.9l1.3,0.9c0.4,0.3,0.5,0.7,0.4,1.4l-0.1,0.3
      c-0.3,1.4-0.7,2.8-1.2,4.2c-1.2,3.1-2.5,6.2-3.8,9.3c-0.6,1.3-1.2,2.5-1.8,3.7c-0.4,0.8-0.9,1.8-1.3,2.7
      C108.4,423.5,107.8,425,107.1,426.5z M150.1,412.3c-0.4,12.1-0.8,24.6-1.9,37.1c-0.2-0.1-0.5-0.1-0.7-0.2c-0.8-0.2-1.5-0.4-2.2-0.7
      c-1-0.4-2.1-0.8-3.1-1.2c-0.7-0.3-1.3-0.5-2-0.8c-0.8-0.3-1.5-0.6-2.3-0.9c-1.3-0.5-2.6-1-4-1.5c-2.5-1-5.2-2.2-8.2-3.5
      c-2-0.9-4.2-1.9-6.8-3.2c-1.7-0.8-3.4-1.7-5.1-2.5l-0.1-0.1c-0.9-0.4-1.7-0.9-2.6-1.3c-0.2-0.1-0.5-0.2-0.7-0.4l-0.1-0.1
      c-0.7-0.4-1.4-0.7-2.2-1.1c-0.3-0.1-0.4-0.2-0.4-0.3c0-0.1,0-0.1,0.1-0.2c1.3-2.7,2.5-5.5,3.8-8.2c0.6-1.3,1.1-2.5,1.7-3.8
      c0.5-1.2,1.1-2.4,1.6-3.6c0.3-0.7,0.6-1.4,1-2.1c0.6-1.3,1.2-2.6,1.8-3.9c0.7-1.7,1.2-3.5,1.7-5.2c0.2-0.5,0.3-1.1,0.5-1.6
      c0.1-0.2,0.1-0.4,0.1-0.6c0.4,0.2,0.8,0.3,1.2,0.4c0.4,0.1,0.9,0.3,1.3,0.4c1.2,0.4,2.5,0.8,3.8,1c2.3,0.4,4.7,0.6,7.1,0.8
      c0.7,0.1,1.3,0.1,2,0.2c1.3,0.1,2.6,0.2,3.9,0.4h0.1c1.7,0.2,3.4,0.3,5.1,0.5c1.4,0.2,2.8,0.4,4.2,0.6l0.4,0.1
      c0.4,0.1,0.8,0.1,1.2,0.2C150.2,408.8,150.2,410.6,150.1,412.3z M150.6,395.4L150.6,395.4c-0.1,1.8-0.2,3.5-0.2,5.2
      c0,0.9,0,1.8,0,2.8v0.3c0,0.2,0,0.5,0,0.7c-1.1-0.1-2.2-0.3-3.3-0.4c-2.8-0.4-5.4-0.7-8.1-1c-1.6-0.2-3.2-0.3-4.7-0.5h-0.1
      c-1.5-0.1-3.1-0.3-4.6-0.4c-3.2-0.4-6-0.8-8.6-2.3c-1.4-0.8-1.4-0.9-1.2-2.5c0.1-0.8,0.1-1.6,0.1-2.4c0-0.5,0-1,0-1.5
      c0-0.9,0.1-1.7,0.1-2.6V390c0.2-3.7,0.4-7.5,0.7-11.3c0.2-3.2,0.5-6.6,0.8-9.5c0.2-2.3,0.5-5.1,0.7-7.8c0.1-1.9,0.2-3.8,0.3-5.5
      c0.1-1.2,0.1-2.4,0.2-3.6c0.1-1.7,0.2-3.3,0.4-5.2v-0.4c0-0.5,0.1-0.9,0.1-1.4c0.5,0.3,1.1,0.7,1.6,1c1.5,0.9,3.1,1.8,4.6,2.8
      c0.9,0.6,1.8,1.1,2.7,1.7c2.5,1.5,5,3,7.4,4.7c2.5,1.8,5.4,2.1,8.1,2.5h0.3c0.3,0,0.6,0.1,0.9,0.1c0.2,0,0.4,0.1,0.6,0.1
      s0.4,0.1,0.6,0.1c0.9,0.1,1.5,0.2,1.7,0.4c0.2,0.2,0.1,0.7,0.1,1.6c-0.1,1.3-0.2,2.6-0.2,3.9v0.3c-0.1,1.2-0.1,2.5-0.2,3.7
      c-0.1,1.1-0.1,2.1-0.2,3.2c-0.2,2.1-0.3,4.2-0.4,6.3c0.1,1.5,0.1,3.1,0.1,4.7c0,1.6,0.1,3.2,0,4.7
      C150.8,389.9,150.7,392.7,150.6,395.4z M193.2,449.3c-0.1,0.1-0.2,0.1-0.4,0.1c-0.6,0-1.3,0.1-1.9,0.2h-0.4v-0.2h-0.4
      c-0.7,0.1-1.4,0.1-2.1,0.2h-0.3c-1.8,0.2-3.7,0.4-5.6,0.5c-1.6,0.1-3.2,0.1-4.7,0.1c-0.7,0-1.5,0-2.2,0c-0.8,0-1.5,0-2.3,0.1
      c-1.4,0-2.8,0.1-4.3,0.1h-0.4c-1.8,0-3.8-0.1-6.2-0.2c-2.7-0.2-5.3-0.3-7.9-0.5l-2-0.1c-0.1,0-0.2,0-0.2,0c-0.3,0-0.6,0-0.8-0.1
      c-0.2-0.1-0.4-0.5-0.4-0.5c0-2.5,0.2-5,0.4-7.4c0.1-1.7,0.2-3.3,0.3-5c0.3-4,0.5-7.9,0.7-11.7c0.1-1.3,0.1-2.7,0.1-4v-0.1
      c0-1.1,0-2.1,0.1-3.1c0-1.4,0.1-2.9,0.2-4.2c0-0.9,0.1-1.9,0.1-2.9c0.1-1.2,0.1-2.4,0.1-3.7c0-0.4,0.1-0.5,0.1-0.6
      c0.1-0.1,0.2-0.1,0.5-0.1h0.1c1.6,0.1,3.3,0.1,4.9,0.2h0.1c3.2,0.1,6.5,0.2,9.7,0.5c2.6,0.2,5.1,0.2,7.6,0c3.2-0.3,6.5-0.4,9.7-0.5
      h0.3c1.5,0,3.1-0.1,4.7-0.2h0.1c0.5,0,0.6,0.1,0.6,0.7c0.1,3.9,0.2,8,0.4,12.2c0.1,3.3,0.3,6.6,0.5,9.8l0.2,2.9
      c0.2,4.2,0.5,8.5,0.7,12.9c0.1,1.4,0.2,2.8,0.4,4C193.3,449,193.3,449.2,193.2,449.3z M192.3,358.5l0.5-0.2
      c0.8-0.1,1.6-0.2,2.5-0.3c0.5-0.1,1.1-0.2,1.7-0.2c2-0.2,4-0.5,5.8-1.5c2-1.2,4.1-2.4,6-3.7c0.5-0.3,1-0.6,1.5-1
      c2.5-1.6,5.1-3.2,7.4-4.7l2.8-1.8c0,0.4,0,0.8,0.1,1.2v0.1c0.1,1.2,0.1,2.3,0.2,3.5c0.3,3.6,0.5,7.3,0.8,10.8l0.3,3.7
      c0.2,2.6,0.4,5.1,0.6,7.7c0.1,1.2,0.2,2.4,0.3,3.6v0.1c0.2,2.3,0.4,4.7,0.6,7c0.2,3.4,0.3,6.9,0.4,10.2v0.1c0,1.1,0.1,2.2,0.1,3.2
      c0,0.3,0,0.6,0.1,1c0.1,0.8,0.2,1.6-0.1,2c-0.3,0.4-0.9,0.6-1.6,0.9c-0.4,0.1-0.8,0.3-1.2,0.5c-1.7,0.9-3.6,1.1-5.5,1.3
      c-1.8,0.2-3.7,0.4-5.3,0.6c-1.1,0.1-2.2,0.3-3.3,0.4c-0.9,0.1-1.8,0.2-2.7,0.3c-1,0.1-2.1,0.2-3.2,0.3c-1.7,0.2-3.5,0.4-5.3,0.7
      l-2.1,0.3L192.3,358.5z M235.7,432c-1.7,0.8-3.4,1.6-5.1,2.4c-1.3,0.6-2.6,1.3-3.9,1.9c-0.9,0.5-1.9,0.9-2.8,1.4
      c-2.9,1.4-6.1,2.9-9.2,4.4c-1.6,0.7-3.4,1.4-5,2h-0.1c-0.9,0.3-1.8,0.7-2.6,1s-1.6,0.6-2.3,1l-0.2,0.1c-1.3,0.5-2.6,1.1-3.9,1.6
      c-0.9,0.3-1.9,0.6-2.8,0.9h-0.1c-0.5,0.1-0.9,0.3-1.4,0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.2-0.5c-0.2-4.3-0.5-7.9-0.7-11.4
      c-0.1-1.1-0.2-2.2-0.2-3.3v-0.1c-0.1-1.7-0.3-3.4-0.3-5.1c-0.1-1.3-0.1-2.8-0.1-4c0-0.8,0-1.7,0-2.5c0-0.7,0-1.4-0.1-2.2V420
      c0-0.6-0.1-1.3-0.1-1.9c0-1,0-2.1-0.1-3.1c0-1.4-0.1-2.9-0.1-4.3c0-1-0.1-2.1-0.2-3v-0.1c0-0.3,0-0.6-0.1-0.9l3.5-0.3
      c3-0.4,6.1-0.7,9.3-1.1c1.1-0.1,2.1-0.2,3.2-0.3c1-0.1,2.2-0.2,3.4-0.3c1.9-0.2,4.4-0.5,6.8-0.9c0.7-0.1,1.4-0.4,2.1-0.7
      c0.3-0.1,0.5-0.2,0.8-0.3c0.4-0.1,0.7-0.3,1-0.4c0.2,0.5,0.3,1,0.5,1.5l0.1,0.4c0.5,1.7,1.1,3.5,1.7,5.3c0.5,1.5,1.2,3.1,2.1,5
      c0.5,1.2,1.1,2.3,1.7,3.4c0.5,1,1,2.1,1.5,3.2c0.8,1.7,1.6,3.5,2.4,5.3l0.1,0.2c0.4,0.9,0.8,1.8,1.2,2.6l0.1,0.3
      c0.3,0.6,0.5,1.2,0.8,1.7c0.1,0.2,0.1,0.3,0.1,0.4S235.8,432,235.7,432z M257.9,410l0.2,0.3c-5.4,6.2-11.4,12.2-19.4,19.6
      c-0.2-0.5-0.5-0.9-0.7-1.4l-0.1-0.1c-0.6-1.2-1.2-2.3-1.7-3.5c-1.5-3.2-2.6-5.8-3.6-8.1c-1.9-4.6-3.8-9-5.5-13.3
      c-0.4-0.8-0.5-1.5-0.4-2.1c0.1-0.5,0.5-1,0.9-1.3c1-0.7,2-1.3,3-2c1.6-1,3.3-2.1,4.8-3.3c1.8-1.4,3.5-3.1,5.2-4.6
      c0.7-0.7,1.5-1.4,2.2-2.1c0.2-0.2,0.4-0.4,0.7-0.6l0.1-0.1C247.4,395.7,252.7,402.9,257.9,410z M259.5,266.8
      c-0.1,1.2-0.2,2.4-0.3,3.5l-0.1,0.8c-0.2,2.5-0.5,5.2-0.7,7.8c-0.2,1.6-0.4,3.3-0.6,4.9c-0.1,0.5-0.3,1.1-0.6,1.6l-0.2,0.4
      c-0.3,0.7-0.6,1.3-0.8,2l-0.2,0.4c-0.9,2.2-1.8,4.4-2.9,6.5c-0.9,1.8-1.9,3.7-2.7,5.3l-0.1,0.2c-0.5,1-1.1,2-1.6,3
      c-0.8,1.5-1.5,3-2.2,4.5c-0.7,1.6-1.5,3.2-2.2,4.9l-0.2,0.5c-0.6,1.6-1.3,3.3-2.1,5.1c-0.7,1.5-1.4,3.1-2.3,4.7
      c0.2-1.3,0.4-2.5,0.6-3.7c0.2-1.4,0.5-2.7,0.7-4.1c0.5-2.7,1-5.5,1.4-8.3c0.4-2.5,0.6-5,0.8-7.5v-0.1c0.1-1.5,0.3-3,0.4-4.5
      c0.3-2.6,0.6-5.3,0.9-7.8l0.2-1.9c0.3-2.3,0.1-4.7-0.4-7.4c-0.1-0.6-0.2-1.3-0.3-1.9c0-0.1,0-0.3,0-0.4l2-1.2c1.6-1,3.4-2.1,5-3.1
      c0.8-0.5,1.6-1,2.4-1.4s1.7-1,2.5-1.5c0.9-0.6,1.9-1.2,2.9-1.8l0.2-0.2c0.2-0.1,0.4-0.3,0.6-0.4L259.5,266.8z M257.5,264.7
      c-2.4,1.3-4.9,2.8-7.2,4.2l-1,0.6c-0.8,0.5-1.7,1-2.5,1.6c-0.6,0.4-1.2,0.7-1.7,1.1c-0.3,0.2-0.7,0.4-1.1,0.5c-0.1,0-0.2,0-0.2,0
      s-0.1,0-0.2-0.4c-0.2-1.7-0.5-3.4-0.8-5.3c-0.1-0.7-0.2-1.4-0.3-2c-0.2-1.1-0.3-2.1-0.5-3.2v-0.2c-0.3-2.3-0.7-4.7-1.1-7.1
      c-0.3-1.7-0.6-3.5-0.9-5.2v-0.2c-0.2-0.9-0.3-1.8-0.5-2.7c0-0.1,0-0.1,0-0.2c0.4-0.1,0.8-0.2,1.2-0.3c1.1-0.2,2.1-0.5,3.1-0.8
      c4.4-1.3,8.7-3.1,12.8-5.1c0.7-0.3,1.3-0.7,2-1c1.3-0.6,2.4-1.2,3.6-1.8c0.4-0.2,0.8-0.3,1.2-0.5l0.2-0.1l-0.2,2.2
      c-0.2,2-0.4,3.9-0.5,5.8c-0.1,1-0.2,2-0.3,3v0.2c-0.2,2.7-0.5,5.5-0.8,8.2c-0.1,0.7-0.3,1.3-0.5,2c-0.3,0.8-0.5,1.6-0.6,2.4
      C260.6,262.6,259.4,263.6,257.5,264.7z M276.4,262.8c0,1.2,0,2.4,0,3.6c0,1.2,0,2.4,0,3.7c0,2.2-0.1,4.5-0.1,6.7
      c0,0.9-0.2,1.1-0.8,1.1c-2.7,0.3-5.4,0.6-8.1,0.9H267c-1.7,0.2-3.4,0.4-5.1,0.7c-0.5,0.1-0.8,0-0.9-0.1c-0.1-0.1-0.1-0.3,0-0.6
      c0.4-1.3,0.9-2.6,1.3-3.7V275c0.3-0.9,0.7-1.8,1-2.7c0.9-2.7,1.8-5.4,2.7-8.2l1.1-3.4l9.3,0.7V262.8z M276.8,259.1
      c-2.7-0.1-5.4-0.4-7.9-0.5l-1.1-0.1c-0.1,0-0.2,0-0.3-0.1l2.9-17c1,0,2,0,3,0h0.4c0.6,0,1.3,0,1.9,0c3.7,0,7.9,0.1,12.1,1.1
      l-0.1,0.8c-0.1,1.1-0.2,2.3-0.3,3.5c0,0.2-0.1,0.5-0.3,0.7l-0.3,0.4c-1.1,1.4-2.3,2.9-3.5,4.3c-2,2.3-4.1,4.6-5.8,6.5
      C277.3,258.9,277,259.1,276.8,259.1z M292.1,249.7c0,0.3,0,0.7,0,1c-0.3,1.5-0.5,3-0.8,4.5l-0.1,0.3c-0.3,1.6-0.6,3.2-0.9,4.8
      c-0.1,0.5-0.1,1-0.2,1.5c-0.1,0.6-0.1,1.2-0.2,1.8c-0.2,0.8-0.4,1.8-0.9,2.5c-1.4,2.1-3.5,3.8-5.4,5.3c-0.8,0.6-1.6,1.2-2.3,1.9
      c-0.6,0.5-1.2,1-1.8,1.5l-0.4,0.3c0-0.5,0-1.1,0-1.6v-0.1c-0.1-1.3-0.1-3-0.1-4.6c0-1.2,0-2.4-0.1-3.6V265c0-1.3-0.1-2.5-0.1-3.6
      c0-0.4,0.1-0.8,0.2-0.9c1.1-1.3,2.2-2.5,3.3-3.8l0.1-0.2c1.3-1.5,2.6-3,3.9-4.5c0.6-0.7,1.1-1.4,1.6-2.2c0.2-0.3,0.4-0.6,0.6-0.9
      c0.4-0.6,0.7-0.6,1.3-0.4c0.5,0.3,1.1,0.5,1.7,0.8c0.2,0.1,0.4,0.1,0.6,0.2C292.1,249.6,292.1,249.6,292.1,249.7z M292.4,247
      l-2.5-0.7l1.1-13.5l2.9,1.4L292.4,247z M303.9,204.9c-0.1,1.2-0.3,2.1-0.6,2.9c-0.6,1.7-1.2,3.4-1.8,5.1l-0.1,0.2
      c-0.5,1.3-1,2.7-1.5,4.1c-0.7,2-1.4,4.1-2.1,6.1c-0.4,1.3-0.9,2.6-1.4,3.9c-0.4,1.2-0.8,2.4-1.2,3.5l-0.5,1.4l-3-1.2
      c-0.4-0.3-0.5-0.5-0.4-0.9v-0.4c0.5-5.1,1.1-10.3,1.5-15.4c0.1-1.6,0.1-3.3,0.1-4.9c0-0.9,0-1.9,0-2.8c0-0.7,0.1-1.5,0.1-2.2v-0.1
      c0-0.5,0.1-0.9,0.1-1.4c0-0.4,0.2-0.5,0.6-0.5c0.1,0,0.2,0,0.3,0c1.8,0.2,3.4,0.4,4.9,0.7c0.6,0.1,1.3,0.3,1.9,0.5
      c0.8,0.2,1.5,0.4,2.3,0.5C303.9,204.1,304,204.5,303.9,204.9z"
      />
      <path d="M360.3,263.6l-48-30l12.1,38.7L360.3,263.6z M352.7,262.4l-26.2,6.3l-8.9-28.3L352.7,262.4z" />
      <path d="M730.9,295.4l-38.2,41.8l40.3-5L730.9,295.4z M728.3,302.7l1.5,26.9l-29.4,3.6L728.3,302.7z" />
      <path d="M970.9,40l-38.2,41.8l40.3-5L970.9,40z M968.3,47.2l1.5,26.9l-29.4,3.6L968.3,47.2z" />
      <path d="M81.8,53.9L28.5,35l20.2,35.2L81.8,53.9z M74.1,54.3L49.9,66.2L35.1,40.5L74.1,54.3z" />
      <path d="M594.3,1289.6l27.8-24.3l-40.5-10.4L594.3,1289.6z M595.7,1284.4l-9.3-25.3l29.5,7.6L595.7,1284.4z" />
      <path d="M402.5,1263.4l-39.7,19l32.8,19L402.5,1263.4z M369.2,1282.6l29.3-14l-5.1,28L369.2,1282.6z" />
      <path d="M45,1010.9l25.2-36.1l-37.9-0.4L45,1010.9z M64.5,977.7l-18.6,26.6l-9.3-26.9L64.5,977.7z" />
      <path d="M354.6,466.2l-57.7,16.5l33.5,41.7L354.6,466.2z M302.1,484.3l47.3-13.5l-19.8,47.8L302.1,484.3z" />
      <path d="M86.5,1247.5l-2.8-0.7l-9.5,19.6l12.4,10.3V1247.5z M83.5,1270.3l-5.6-4.7l5.6-11.6V1270.3z" />
      <path d="M625.2,962.9l34.8-36l-61.5,18L625.2,962.9z M624.8,959.1l-19.3-13l44.5-13L624.8,959.1z" />
      <path
        d="M724.4,1286.8c0.1,1.5,0.5,1.6,1.1,1.8c0.1,0,0.3,0.1,0.4,0.1c0.6,0,1.2-0.3,1.5-1c0.2-0.4,0.4-0.9,0-1.7
      c0.4-5.5,4-32,7.6-57.2l0.3-2.2l-72.1,19l3,1.8C692.6,1263.6,721.3,1282.7,724.4,1286.8z M670.7,1246.6l60.9-16
      c-1,6.6-3.1,21.3-4.8,34.5c-1.2,9-1.9,14.5-2.3,17.8c-2.9-2.6-8.6-6.8-19.2-14C692.3,1260.1,677.8,1251,670.7,1246.6z"
      />
      <path
        d="M645.2,623.4c-1.5-0.1-1.6,0.3-1.9,0.9c-0.1,0.1-0.1,0.3-0.1,0.4c-0.1,0.6,0.2,1.2,0.9,1.6
      c0.4,0.2,0.8,0.5,1.7,0.2c5.5,0.9,31.4,7.3,56.1,13.6l2.2,0.6l-11.3-73.7l-2.1,2.8C671.6,594.2,649.7,620.8,645.2,623.4z
       M690.9,574.3l9.6,62.3c-6.5-1.6-20.8-5.3-33.8-8.4c-8.8-2.1-14.2-3.4-17.5-4.1c2.9-2.6,7.7-7.8,15.9-17.7
      C675.2,594.3,685.8,580.8,690.9,574.3z"
      />
      <path d="M623.5,1308h12.2l-12.2-19.8V1308z M626.5,1298.8l3.8,6.2h-3.8V1298.8z" />
      <path d="M329.3,44.4l-16.2,34.7L360,62.9L329.3,44.4z M330.7,48.6L353,62.1l-34.1,11.8L330.7,48.6z" />
      <path d="M83.2,1288.1L16,1256.9l14.5,80L83.2,1288.1z M77.8,1288.9L32.5,1331l-12.5-69L77.8,1288.9z" />
      <path d="M746.8,43.1L695.4,62l21.3,16.5L746.8,43.1z M701.6,63l35.6-13.1l-20.9,24.6L701.6,63z" />
      <path d="M81,308.5l-2.8-0.7L46,369.1l35,19.4V308.5z M78,383.5l-28-15.6l28-53.3V383.5z" />
      <path d="M611.9,798.7l69.7-68.1l-1.7-2.4l-79.7,37.6L611.9,798.7z M613.1,793.3l-9.3-26l69.2-32.6L613.1,793.3z" />
      <path d="M669.3,1214.6l35.4,6.6l-24.6-30.6L669.3,1214.6z M697.3,1216.8l-23.6-4.4l7.2-16L697.3,1216.8z" />
      <path d="M929.7,990.9l35.4,6.6l-24.6-30.6L929.7,990.9z M957.7,993l-23.6-4.4l7.2-16L957.7,993z" />
      <path d="M944.2,546.3l35.4,6.6L955,522.3L944.2,546.3z M972.2,548.4l-23.6-4.4l7.2-16L972.2,548.4z" />
      <path d="M931.7,832l13-51.3L914,807.8L931.7,832z M930.3,825L918,808.2l21.3-18.9L930.3,825z" />
      <path d="M397.6,520.9l2.4,1.7l15.8-14.2l-6.2-16.5L397.6,520.9z M402.9,516l6.5-15.9l2.8,7.5L402.9,516z" />
      <path d="M672.2,466.7l-8.2-98.4l-2.9-0.4l-26.9,76.7L672.2,466.7z M661.6,375.6l7.2,85.6l-30.9-17.9L661.6,375.6z" />
      <path d="M287.7,289.3L309.4,407l49.3-110.3L287.7,289.3z M291.3,292.7l62.9,6.6L310.6,397L291.3,292.7z" />
      <path d="M955.3,1284.8l5.2-47.3l-33.5,32.9L955.3,1284.8z M952.7,1280.2l-20.7-10.6l24.5-24.1L952.7,1280.2z" />
      <path d="M620.6,339l15.2,44.6l10.5-22.9l-23.2-23.2L620.6,339z M642.7,361.3l-6.5,14.1l-10.7-31.3L642.7,361.3z" />
      <path d="M940,89.3l15.2,44.6l10.5-22.9l-23.2-23.2L940,89.3z M962.1,111.7l-6.5,14.1l-10.7-31.3L962.1,111.7z" />
      <path d="M305.4,550.2l6.7-31.2L287,530L305.4,550.2z M303.6,543.8L292,531l15.9-7L303.6,543.8z" />
      <path d="M304.8,827.1l24.3-55.5l-41.6,30.1L304.8,827.1z M304.2,820.9l-12.7-18.6l30.4-21.9L304.2,820.9z" />
      <path d="M24,748.1l53.3,28.9l-26.5-44L24,748.1z M30.2,748l19.5-11.1L69.1,769L30.2,748z" />
      <path d="M346.4,852.3l45.9-56.7l-55.1-31.9L346.4,852.3z M348.6,844.7l-7.8-75.5l46.9,27.1L348.6,844.7z" />
      <path
        d="M400.5,349.7v-40.2l-2.8,4.2c-0.4,0.6-9.6,14.6-11.1,18.2c-1.8,4.1,7.4,12.4,11.4,15.7L400.5,349.7z
       M397.5,343.2c-5.1-4.7-8.4-9-8.1-10.1c0.8-1.9,4.7-8.1,8.1-13.5V343.2z"
      />
      <path
        d="M41.3,115.6l21.9-33.8l-4.6,2c-0.7,0.3-16,7.1-19.2,9.3c-3.7,2.5-0.5,14.4,1,19.4L41.3,115.6z M42.4,108.4
      c-1.7-6.7-2.2-12.1-1.3-12.9c1.7-1.2,8.3-4.3,14.2-6.9L42.4,108.4z"
      />
      <path
        d="M89.5,843.3l-39,10.1l4.8,1.6c0.7,0.2,16.6,5.6,20.4,6.2c4.4,0.7,10.1-10.3,12.4-15L89.5,843.3z M84,847.8
      c-3.2,6.1-6.6,10.4-7.7,10.4c-2.1-0.3-9.1-2.5-15.1-4.5L84,847.8z"
      />
      <path d="M909.2,320.8l47.4,5.3L922,295.6L909.2,320.8z M947.4,322l-33.6-3.7l9.1-17.8L947.4,322z" />
      <path d="M927.3,510l17.7-45.2l-50.3,31.4L927.3,510z M925.7,506l-24.4-10.3l37.7-23.6L925.7,506z" />
      <path d="M302.7,1071.5l46.8-13.1l-35.4-83.3L302.7,1071.5z M345.5,1056.5l-39.2,10.9l9.6-80.7L345.5,1056.5z" />
    </g>
  );
};

export default bgDrawingFaces;
