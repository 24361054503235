import React, { useEffect, useRef, useState } from "react";
import "../Styles/Artwork.css";
import "../Styles/Main.css";
import { useStore } from "./ArtworkPropertyStore";
import * as utils from "./Utilities";

export const totalXPoints = 20;
export const totalYPoints = 28;
export const width = 800;
export const height = width * 1.414;

var focusGrid: {
  value: number;
  x: number;
  y: number;
  isUsed: boolean;
  keyX: number;
  keyY: number;
}[][];
focusGrid = [];
for (let i = 1; i < totalXPoints; i += 1) {
  focusGrid[i] = [];
  for (let j = 1; j < totalYPoints; j += 1) {
    focusGrid[i][j] = { value: 0, x: 0, y: 0, isUsed: false, keyX: i, keyY: j };
  }
}

const FocusGrid = () => {
  const props = utils.getProps();

  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const [areFocusPointsDrawn, setAreFocusPointsDrawn] = useState(false);
  const [isDrawn, setIsDrawn] = useState(false);
  const canvasRef = useRef(null);

  const position1Code = useStore((state) => state.position1Code);
  const position2Code = useStore((state) => state.position2Code);

  const focusImg = props.focusImg;
  const draw = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = "#000000";
    ctx.drawImage(focusImg, 0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    // let frameCount = 0;
    let animationFrameId = 0;

    //Drawing happens here
    const render = () => {
      // frameCount++;
      draw(thisContext);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();
    setIsDrawn(true);

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    if (isDrawn) {
      for (let i = 1; i < props.totalXPoints; i += 1) {
        for (let j = 1; j < props.totalYPoints; j += 1) {
          const data1 = thisContext?.getImageData(
            Math.round((props.width / props.totalXPoints) * i),
            Math.round((props.height / props.totalYPoints) * j),
            1,
            1
          ).data;
          focusGrid[i][j] = {
            value: data1 ? data1[0] : 0,
            x: Math.round((props.width / totalXPoints) * i),
            y: Math.round((props.height / totalYPoints) * j),
            isUsed: false,
            keyX: i,
            keyY: j,
          };
        }
      }

      let localFocusGrid = focusGrid;
      for (let i = 1; i < totalXPoints; i++) {
        for (let j = 1; j < totalYPoints; j++) {
          if (localFocusGrid[i][j] && localFocusGrid[i][j].value >= 64) {
            localFocusGrid[i][j].isUsed = true;

            utils.markPointsAroundAsUsed(focusGrid, i, j, 2);
          }
        }
      }

      const textBoxPosition = utils.getTextBoxPosition(
        parseInt(position1Code),
        parseInt(position2Code),
        focusGrid
      );
      useStore.setState({ textBoxPosition: textBoxPosition });
      // console.log("text box position: ", textBoxPosition);

      focusGrid = utils.markTextBoxSpaceUsed(
        textBoxPosition,
        totalXPoints,
        totalYPoints,
        focusGrid
      );

      const facePosition = utils.getFacePosition(focusGrid);
      useStore.setState({ facePosition: facePosition });

      console.log("FOCUS GRID:", focusGrid);

      if (!areFocusPointsDrawn) {
        useStore.setState({ focusGrid: localFocusGrid });
        useStore.setState({ isFocusGridDrawn: true });
        console.log("setting state focus grid");

        setAreFocusPointsDrawn(true);
      }
    }
  }, [draw]);

  return (
    <div className={"focusPointCanvas"}>
      <canvas
        id={"canvas-focusgrid"}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export default FocusGrid;
