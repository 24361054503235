import { useEffect, useRef, useState } from "react";
import "../Styles/Artwork.css";
import { useStore } from "./ArtworkPropertyStore";
import * as utils from "./Utilities";

interface ArtworkSvgProps {
  id?: string;
  element: string;
  // imagetoDraw: () => JSX.Element;
}

const LayerSvg = (directProps: ArtworkSvgProps) => {
  const props = utils.getProps();
  const color = utils.selectPalette(props.palette)[`${directProps.id}`];
  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const canvasRef = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const drawingCode = useStore((state) => state.drawingCode);
  const drawingFill = utils.selectDrawing(drawingCode, "fill");
  const drawingFill2 = utils.selectDrawing(drawingCode, "fill2");
  const drawingLineart = utils.selectDrawing(drawingCode, "lineart");

  let ImageToDraw = utils.selectDrawing(drawingCode, "fill");
  let svgElement: SVGElement | null;
  let imageForCanvas = new Image();

  let svgString = "";
  const serializer = new XMLSerializer();

  switch (directProps.element) {
    case "drawingColor": {
      ImageToDraw = drawingFill;
      svgElement = document.querySelector("#drawingFill");
      break;
    }

    case "drawingColor2": {
      ImageToDraw = drawingFill2;
      svgElement = document.querySelector("#drawingFill2");
      break;
    }

    case "drawingLineart": {
      ImageToDraw = drawingLineart;
      svgElement = document.querySelector("#drawingLineart");
      break;
    }
  }

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.globalCompositeOperation = "source-over";

    if (isImageLoaded) {
      ctx.drawImage(imageForCanvas, 0, 0);
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let animationFrameId = 0;

    const framectElement = document.getElementById("framect");

    //Drawing happens here
    const render = () => {
      if (framectElement) {
        draw(thisContext, parseFloat(framectElement?.innerText));
      }

      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  useEffect(() => {
    if (svgElement !== null) {
      svgString = serializer.serializeToString(svgElement);
    }

    // make it base64
    var svg64 = btoa(svgString);
    var b64Start = "data:image/svg+xml;base64,";
    var image64 = b64Start + svg64;

    imageForCanvas.onload = function () {
      setIsImageLoaded(true);
    };

    imageForCanvas.src = image64;
    // console.log("image for canvas", svgElement?.id);
    // }
  }, [imageForCanvas]);

  return (
    <div className={"canvas"}>
      <div className="svgLayer">
        <svg
          viewBox={"0 0 1000 1414"}
          style={{ fill: utils.colorToHex(color) }}
          id={directProps.id ? directProps.id : ""}
        >
          <ImageToDraw />
        </svg>
      </div>

      <canvas
        id={`canvas-${directProps.id}`}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export default LayerSvg;
