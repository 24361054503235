import man1Focusfill from "./drawing-man1-focusfill.svg";
import man2Focusfill from "./drawing-man2-focusfill.svg";
import man3Focusfill from "./drawing-man3-focusfill.svg";
import man4Focusfill from "./drawing-man4-focusfill.svg";
import man5Focusfill from "./drawing-man5-focusfill.svg";
import man6Focusfill from "./drawing-man6-focusfill.svg";
import man7Focusfill from "./drawing-man7-focusfill.svg";
import man8Focusfill from "./drawing-man8-focusfill.svg";
import man9Focusfill from "./drawing-man9-focusfill.svg";
import man10Focusfill from "./drawing-man10-focusfill.svg";

import woman1Focusfill from "./drawing-woman1-focusfill.svg";
import woman2Focusfill from "./drawing-woman2-focusfill.svg";
import woman3Focusfill from "./drawing-woman3-focusfill.svg";
import woman4Focusfill from "./drawing-woman4-focusfill.svg";
import woman5Focusfill from "./drawing-woman5-focusfill.svg";
import woman6Focusfill from "./drawing-woman6-focusfill.svg";
import woman7Focusfill from "./drawing-woman7-focusfill.svg";
import woman8Focusfill from "./drawing-woman8-focusfill.svg";
import woman9Focusfill from "./drawing-woman9-focusfill.svg";
import woman10Focusfill from "./drawing-woman10-focusfill.svg";
import woman11Focusfill from "./drawing-woman11-focusfill.svg";
import woman12Focusfill from "./drawing-woman12-focusfill.svg";
import woman13Focusfill from "./drawing-woman13-focusfill.svg";
import woman14Focusfill from "./drawing-woman14-focusfill.svg";

const focusfillArray = [
  woman1Focusfill,
  man1Focusfill,

  woman2Focusfill,
  man2Focusfill,

  woman3Focusfill,
  man3Focusfill,

  woman4Focusfill,
  man4Focusfill,

  woman5Focusfill,
  man5Focusfill,

  woman6Focusfill,
  man6Focusfill,

  woman7Focusfill,
  man7Focusfill,

  woman8Focusfill,
  man8Focusfill,

  woman9Focusfill,
  man9Focusfill,

  woman10Focusfill,
  man10Focusfill,

  woman11Focusfill,

  woman12Focusfill,

  woman13Focusfill,

  woman14Focusfill,
];

export { focusfillArray };
