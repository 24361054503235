const drawingWoman12Fill2 = () => {
  return (
    <g>
      <path d="M678.84,544.39c.25,2.13.47,4.27.77,6.39.39,2.72.68,3,3.5,3.13,5.36.26,10.73.69,16.09.62a13.85,13.85,0,0,1,9.38,3.52c5,4.19,10,8.51,15,12.73a3.91,3.91,0,0,1,1.62,3.63c0,.11,0,.21,0,.32-.3,10.13.62,7.3-5.53,14.23-2.14,2.41-4.26,4.84-6.53,7.13a20.16,20.16,0,0,1-3.28,2.26l1.71.43v11.11h-12.4c-.26-1.23-.5-2.35-.79-3.75-1-.08-1.87-.23-2.8-.23q-24.81.09-49.62.23a7.26,7.26,0,0,1-4.52-1.38c-2.71-1.93-5.47-3.79-8.23-5.66-.86-.58-1.78-1.1-3.06-1.88-.27,1.79-.47,3.2-.74,5-5.43.34-10.83.19-16.64.08-.39-5.33-.18-10.41-.11-15.64a13.23,13.23,0,0,1,2.37-.52c4.08,0,8.17-.08,12.25,0a11,11,0,0,1,2.88.9,20.11,20.11,0,0,1,1.88-2.79c2.84-3.08,5.68-6.16,8.69-9.08a7.46,7.46,0,0,0,2.42-5.69c0-6.66.14-13.32.21-20,0-1.27,0-2.55,0-4.2,1.4-.14,2.52-.34,3.65-.36,4.51-.06,9-.05,13.53-.13a29.59,29.59,0,0,0,3.48-.45Z" />
      <path d="M58,1289c1.28-.06,2.57-.16,3.85-.17h60.83c.13,1.72.31,3.07.31,4.42q0,28,0,56c0,1.39.07,2.79.1,4.18H58Z" />
      <path d="M967,1332.19c-6.22-.08-12.44-.13-18.65-.23-1.35,0-2.7-.21-4.24-.33v-23.12c7.75-.33,15.33,0,22.89-.18Z" />
      <path d="M365.89,1058.46H500.56c.08,1.36.23,2.5.23,3.65q0,63.65,0,127.27c0,.65,0,1.29,0,1.93a1.79,1.79,0,0,1-2,2c-.86,0-1.72,0-2.58,0H369.26c-3.51-.06-3.62-.13-3.62-3.66q0-63.95,0-127.91C365.64,1060.75,365.79,1059.71,365.89,1058.46Z" />
      <path d="M702.92,1170.65c.73,3.32.65,72.08-.1,74.92H627.39c-.87-2.57-1-71.81-.13-74.84C629.75,1170.05,699.45,1169.93,702.92,1170.65Z" />
      <path d="M552,1226.72c.68,2.65.87,54.73.2,59.1a27.14,27.14,0,0,1-3.25.36q-26.1,0-52.21,0c-1,0-2.09-.16-3.05-.23-.79-3-.78-56.21,0-59.23Z" />
      <path d="M889,1289.77c-.06-17,0-33.44,0-50.15h50a18.33,18.33,0,0,1,.37,2.69q0,22.56,0,45.11c0,.64-.12,1.27-.22,2.26a33.42,33.42,0,0,1-3.39.37q-21.59,0-43.18,0C891.5,1290.05,890.46,1289.89,889,1289.77Z" />
      <path d="M818.59,1067.34v47c-2.55.74-47.65.93-51.51.27-.77-2.25-1-42.33-.24-47.17a29.39,29.39,0,0,1,3.26-.34q22.53,0,45.07,0C816.21,1067.06,817.25,1067.23,818.59,1067.34Z" />
      <path d="M382.42,915.21V873.1c2.63-.93,42.37-1.08,45.61-.24.71,2.43.84,38.86.16,42.27C425.8,915.88,386.15,916,382.42,915.21Z" />
      <path d="M616,870.14V835.57c2.93-1.12,5.83-.48,8.66-.58s5.79,0,8.68,0H651c.34,12,.1,23.49.17,34.88C648.59,870.73,620.19,870.94,616,870.14Z" />
      <path d="M708.62,659.29V628.87c2.65-.81,25.79-.91,29.18-.12.08,1,.22,2,.23,3q0,12.08,0,24.14c0,3.5-.11,3.61-3.64,3.63q-11.26,0-22.53,0C710.91,659.55,710,659.4,708.62,659.29Z" />
      <path d="M595.36,1126.34v26.31c-2.59.91-23.2,1.07-26.65.28-.8-2.29-1-22.76-.24-26.43C570.77,1125.79,591.14,1125.61,595.36,1126.34Z" />
      <path d="M612.92,546.72c.09,7.79.05,15.46,0,23-2.61,1-20.14,1.26-23.86.42-.74-2.41-.86-19.95-.15-23.24C591.25,546.24,608,546,612.92,546.72Z" />
      <path d="M637.66,504.71c.06,5.63,0,11.18,0,16.64-1.82.76-14,.91-16.64.3-.52-1.74-.6-14.44-.11-16.81C622.54,504.36,634.19,504.21,637.66,504.71Z" />
      <path d="M750.88,567.06c0,1.5,0,3,0,4.43a16.92,16.92,0,0,1-4.29.08,26.55,26.55,0,0,1,0-4.47A23.42,23.42,0,0,1,750.88,567.06Z" />
      <path d="M771.41,508.23c0,2,0,3.89,0,5.78a21.72,21.72,0,0,1-5.61.11,32.5,32.5,0,0,1,0-5.85A30.63,30.63,0,0,1,771.41,508.23Z" />
      <path d="M134.24,1258.3h22.38c.88,2.23,1.15,18.13.41,22.67-2.38.92-19.36,1-22.79.11Z" />
      <path d="M595.81,1292.63H573c-.8-3-.8-19,0-22.07H595.8C596.6,1273.55,596.61,1289.59,595.81,1292.63Z" />
      <path d="M863.69,1127.62c-2.66.79-18,.89-21.21.19-.74-2.29-.93-16.77-.21-21a17.38,17.38,0,0,1,2.62-.38q7.89-.06,15.77,0c3.1,0,3.24.18,3.26,3.34,0,5.15,0,10.3,0,15.44A23.43,23.43,0,0,1,863.69,1127.62Z" />
      <path d="M462.66,925.81H444.79c-.83-2.37-1-14.24-.31-18.91,5.81-.81,11.72-.19,18-.36C462.94,913.14,462.77,919.36,462.66,925.81Z" />
      <path d="M798.39,1169.51a138.17,138.17,0,0,1,.11-17.26c2.47,0,4.69-.09,6.91-.1,2,0,4.09,0,6.13,0,.93,0,1.77.37,1.81,1.43.2,5.23.16,10.45,0,15.88Z" />
      <path d="M609.73,803.63v14.15c-3.85.74-7.61.29-11.67.25a73,73,0,0,1-.07-14.4Z" />
      <path d="M731.55,821.31v17.31c-6,.9-11.95.36-18.32.31a69.3,69.3,0,0,1-.1-17.62Z" />
      <path d="M664.91,613.59h12.3a88.16,88.16,0,0,1,.07,12.51c-4.08.28-8,.17-12.28.07A89.23,89.23,0,0,1,664.91,613.59Z" />
      <path d="M761,611v11.14H750.67c-.65-3.67-.28-7.23-.24-11A49,49,0,0,1,761,611Z" />
      <path d="M607.33,612.27h11c.72,3.33.37,6.58.23,10.16a41.56,41.56,0,0,1-11.09.13C607,619.12,607.24,615.88,607.33,612.27Z" />
      <path d="M647.17,625.14h5a22.58,22.58,0,0,1,.1,5.08,16.61,16.61,0,0,1-5,.07A30.4,30.4,0,0,1,647.17,625.14Z" />
      <path d="M724.1,600.61V592.4h7.37v8.21Z" />{" "}
    </g>
  );
};

export default drawingWoman12Fill2;
