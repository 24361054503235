import glitch01 from "./glitch01.png";
import glitch02 from "./glitch02.png";
import glitch03 from "./glitch03.png";
import glitch04 from "./glitch04.png";
import glitch05 from "./glitch05.png";
import glitch06 from "./glitch06.png";
import glitch07 from "./glitch07.png";
import glitch08 from "./glitch08.png";
import glitch09 from "./glitch09.png";
import glitch10 from "./glitch10.png";
import glitch11 from "./glitch11.png";
import glitch13 from "./glitch13.png";
import glitch14 from "./glitch14.png";
import glitch15 from "./glitch15.png";
import glitch16 from "./glitch16.png";
import glitch18 from "./glitch18.png";
import glitch19 from "./glitch19.png";
import glitch20 from "./glitch20.png";
import glitch21 from "./glitch21.png";
import glitch22 from "./glitch22.png";
import glitch24 from "./glitch24.png";
import glitch25 from "./glitch25.png";
import glitch26 from "./glitch26.png";
import glitch28 from "./glitch28.png";
import glitch29 from "./glitch29.png";
import glitch30 from "./glitch30.png";
import glitch31 from "./glitch31.png";
import glitch32 from "./glitch32.png";
import glitch33 from "./glitch33.png";
import glitch34 from "./glitch34.png";
import glitch36 from "./glitch36.png";
import glitch37 from "./glitch37.png";
import glitch39 from "./glitch39.png";
import glitch40 from "./glitch40.png";

import glitch001 from "./glitch001.png";
import glitch002 from "./glitch002.png";
import glitch003 from "./glitch003.png";

const glitchArray = [
  glitch01,
  glitch02,
  glitch03,
  glitch04,
  glitch05,
  glitch06,
  glitch07,
  glitch08,
  glitch09,
  glitch10,
  glitch11,

  glitch13,
  glitch14,
  glitch15,
  glitch16,

  glitch18,
  glitch19,
  glitch20,
  glitch21,
  glitch22,

  glitch24,
  glitch25,
  glitch26,

  glitch28,
  glitch29,
  glitch30,
  glitch31,
  glitch32,
  glitch33,
  glitch34,

  glitch36,
  glitch37,

  glitch39,
  glitch40,
  glitch001,
  glitch002,
  glitch003,
];

export {
  glitch01,
  glitch02,
  glitch03,
  glitch04,
  glitch05,
  glitch06,
  glitch07,
  glitch08,
  glitch09,
  glitch10,
  glitch11,
  glitch13,
  glitch14,
  glitch15,
  glitch16,
  glitch18,
  glitch19,
  glitch20,
  glitch21,
  glitch22,
  glitch24,
  glitch25,
  glitch26,
  glitch28,
  glitch29,
  glitch30,
  glitch31,
  glitch32,
  glitch33,
  glitch34,
  glitch36,
  glitch37,
  glitch39,
  glitch40,
  glitch001,
  glitch002,
  glitch003,
  glitchArray,
};
