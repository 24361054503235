import html2canvas from "html2canvas";
import { getElementById } from "htmlparser2/node_modules/domutils";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "../Styles/Main.css";
import ArtworkContainer from "./ArtworkContainer";
import { useStore } from "./ArtworkPropertyStore";
import PropertyMaster from "./PropertyMaster";
import { getProps } from "./Utilities";

interface MainInputProps {
  setSeed: Dispatch<SetStateAction<string>>;
  //   handleChange: Function;
  handleSubmit: (newSeed: string) => void;
  generateSeed: () => void;
  screenshot: () => void;
  record: () => void;
  recordWithRTC: () => void;
  isRecording: boolean;
}
const MainInput = (props: MainInputProps) => {
  const stateprops = getProps();
  const [typedSeed, setTypedSeed] = useState("");

  const handleChange = (e: any) => {
    setTypedSeed(e.target.value);
    console.log(typedSeed);
  };

  const handleSubmit = () => {
    props.handleSubmit(typedSeed);
  };

  const generateSeed = () => {
    props.generateSeed();
  };

  const screenshot = () => {
    props.screenshot();
  };

  const record = () => {
    props.record();
  };

  const recordWithRTC = () => {
    props.recordWithRTC();
  };

  const handleAnimate = () => {
    useStore.setState({ isAnimating: !stateprops.isAnimating });
  };

  return (
    <div className="buttonContainer">
      <input
        id="seed"
        type="number"
        value={typedSeed}
        onChange={handleChange}
      />
      <button id="submit" onClick={handleSubmit}>
        submit
      </button>
      <button id="randomize" onClick={generateSeed}>
        randomize
      </button>
      <button id="screenshot" onClick={screenshot}>
        download screenshot
      </button>
      {/* <button id="animate" onClick={handleAnimate}>
        pause anim.
      </button> */}
      {/* <button
        id="record"
        // className={props.isRecording ? "recording" : "notRecording"}
        onClick={record}
      >
        record
      </button> */}

      <button
        id="recordRTC"
        // className={props.isRecording ? "recording" : "notRecording"}
        onClick={recordWithRTC}
      >
        {props.isRecording ? "recording" : "record RTC"}
      </button>
    </div>
  );
};

export default MainInput;
