import create from "zustand";
import { FocusGridType } from "./Resources/Types";

interface PropertyTypes {
  seed: number | string;
  isAnimating: boolean;

  width: number;
  height: number;

  totalXPoints: number;
  totalYPoints: number;

  theme: string;
  elementstyle: string;
  animationLayer1: string;
  animationLayer2: string;

  focusImg: HTMLImageElement;
  focusGrid: FocusGridType;
  isFocusGridDrawn: boolean;
  textBoxPosition: string;
  facePosition: string;

  paletteCode: number;
  drawingCode: string;
  backgroundCode: number;
  position1Code: string;
  position2Code: string;

  backgroundColor: string;
  backgroundBaseColor: string;
  backgroundDrawingColor: string;

  textCode: number;
  textFont: string;
  textFontWeight: number;
  textLetterSpacing: number | string;
}

export const useStore = create<PropertyTypes>((set: Function) => ({
  seed: "10105835101010101010",
  isAnimating: false,

  width: 1000,
  height: 1414,

  totalXPoints: 20,
  totalYPoints: 28,

  focusImg: new Image(),
  focusGrid: [[{ x: 0, y: 0, value: 0, isUsed: false, keyX: 0, keyY: 0 }]],
  isFocusGridDrawn: false,
  textBoxPosition: "top",
  facePosition: "right",

  theme: "technology",
  elementstyle: "stars-four",
  animationLayer1: "growShrink",
  animationLayer2: "wiggle",

  paletteCode: 10,
  drawingCode: "10",
  backgroundCode: 10,
  position1Code: "10",
  position2Code: "10",

  backgroundColor: "red",
  backgroundBaseColor: "darkred",
  backgroundDrawingColor: "red",

  textCode: 10,
  textFont: ` "Anonymous Pro", monospace`,
  textFontWeight: 500,
  textLetterSpacing: "normal",
}));
