import { parse } from "path";
import { useStore } from "./ArtworkPropertyStore";
import LayerCanvas from "./LayerCanvas";
import LayerMaskedCanvas from "./LayerMaskedCanvas";
import * as utils from "./Utilities";

// interface groupMaskedCanvasProps {
//   isFocusGridDrawn: boolean;
//   focusImg: HTMLImageElement;
//   focusGrid: { value: number; x: number; y: number }[][];
// }

const GroupMaskedCanvasLayers = () => {
  const props = utils.getProps();

  const pos1X = Math.floor(props.position1 / 10);
  const pos1Y = props.position1 % 10;
  const pos2X = Math.floor(props.position2 / 10);
  const pos2Y = props.position2 % 10;
  const shapes = [];

  let numberofShapes = Math.round((pos1X + pos1Y) / 1.5);
  numberofShapes = utils.clamp(numberofShapes, 4, 8);

  // for (let i = 1; i < numberofShapes + 1; i++) {
  //   shapes.push({
  //     key: i,
  //     x:
  //       Math.abs(
  //         Math.sin(i) * (props.width / 100) +
  //           Math.cos(pos1X) * (props.height / 150) +
  //           pos2X
  //       ) * 10,
  //     y:
  //       Math.abs(
  //         Math.cos(i) * (props.width / 100) +
  //           Math.sin(pos1Y) * (props.height / 150) +
  //           pos2Y
  //       ) * 10,
  //     color: utils.selectPalette(props.palette)[`accent${i + 1}`],
  //   });
  // }

  for (let i = 1; i < numberofShapes + 1; i++) {
    const size = utils.clamp(
      parseInt(utils.generateValue(2, pos1X * i * 10, " group accent canvas")) /
        3,
      15,
      27
    );
    shapes.push({
      size: size,
      key: i,
      x: parseInt(utils.generateValue(2, i * pos1X * 16)) * 0.01 * props.width,
      y:
        parseInt(utils.generateValue(2, i * pos1Y * 16)) * 0.006 * props.width +
        props.width * 0.2,

      color: utils.selectPalette(props.palette)[`accent${i + 1}`],
    });
  }

  const shapeItems = shapes.map((shape) => (
    <LayerMaskedCanvas
      size={shape.size}
      key={shape.key}
      focusImg={props.focusImg}
      id={`accentMasked${shape.key}`}
      position1={shape.x}
      position2={shape.y}
    />
  ));
  return <div className="canvasLayer">{shapeItems}</div>;
};

export default GroupMaskedCanvasLayers;
