const drawingWoman1Fill = () => {
  return (
    <g>
      <path
        // id="drawing-fill"
        className="st0"
        d="M493.5,239.6h10.8c3.4,1.5,6.9,2.7,10.1,4.5c6.7,3.8,13.2,7.9,19.9,11.8
	c3.9,2.2,8.4,3.1,12.9,2.6c8.2-0.7,16.4-1.6,24.6-2c13.2-0.7,25.9,2.4,38.5,5.2c7.6,1.7,15.3,3.3,22.7,5.7
	c8.2,2.7,16.1,6.3,24.2,9.5c0.9,0.4,1.6,1.4,2.5,1.8c1.5,0.5,3,0.9,4.5,1.3c1.1,0.3,2.1,0.5,3.2,0.8c1,0.4,2.1,0.7,3.2,0.8
	c1,0.1,2.1-0.4,3.1-0.4c7.8,0.3,15.6,0.7,23.4,1.1c0.9,0.2,1.8,0.5,2.6,1c1.5,0.8,2.8,1.9,4.4,2.4c10.3,3.4,17.7,10.1,23.4,19.3
	c3,4.7,5.4,10.5,11.5,12.6c0.7,0.2,1.2,1.2,1.7,1.9c10,13,18.7,26.8,26.2,41.4c2.3,4.5,5,8.8,7.4,13.3c0.4,0.8,0.7,1.5,0.8,2.4
	c1,3.2,2.2,6.3,3,9.6c0.6,2.7,1,5.5,1.1,8.3c0.1,1.6-0.7,3.3-0.2,4.7c0.8,2.2,1.8,4.8,3.5,5.9c2.2,1.2,3.7,3.4,4.2,5.9
	c0.4,2.3,1,4.5,1.5,6.8c2.9,11.4,2.6,22.6-2.4,33.5c-2.9,6.4-6.2,12.5-9,18.9c-0.8,1.9-1,4-0.7,6.1c0.7,4.5,2,8.9,2.8,13.3
	c3.8,19.1,4.9,38.4,4.5,57.8c-0.2,6,0.4,12,1.6,17.9c0.9,3.2,0.2,6.5-1.8,9.1c-2.3,3-5,5.7-7.4,8.7c-3.4,4.5-6.7,9.1-10.3,14.1
	c-0.2-0.6-0.2-0.3,0,0c3,4.3,2.5,6.6-1.8,9.7c-0.6,0.4-1,1-1.3,1.6c-1,2.6-2.1,5.3-2.9,8c-0.4,1.1-0.4,2.3,0,3.4
	c6.4,11.6,12.1,23.6,16.8,36c3.2,8.3,7.1,16.2,8.8,25.1c1,4.5,0.2,9.3-2.3,13.2c-2.4,4.3-9.5,6.8-13.9,5.3
	c-3.9-1.3-9.7,0.2-12.1,3.2c-0.5,0.6-0.7,1.3-0.6,2c2.2,9.1,4.1,18.3,6.8,27.3c1.3,4.4,1.1,8.3-1.5,11.7c-2.1,2.5-4.6,4.6-7.4,6.3
	c-4,2.6-4.6,4.5-1.7,8.2c5,6.6,5.3,12.4,0.9,19.4c-2.2,3.6-4.9,6.9-7,10.6c-1.7,3-3.6,6.3-3.8,9.5c-0.2,6.2,0.1,12.5,0.9,18.6
	c0.8,7.3-0.6,13.8-5.3,19.6c-2.2,2.6-4.1,5.5-6.6,7.7c-6.4,5.3-13.2,10.3-22.2,10.1c-0.6,0-1.2,0.7-1.9,1c-2.8,1.5-5.6,2.8-8.3,4.5
	c-3.6,2.1-7.1,4.4-10.4,7c-1.4,1.5-2.2,3.6-2.2,5.7c0,2.8,1,5.7,1.6,8.5c0.9,3.9,3.3,6.8,5.5,10c5.1,7.4,6.3,16.3,6.2,25.2
	c0,6.5-0.9,13-1.7,19.5c-1.4,11.8-4.9,22.9-11.6,32.9c-3.6,5.3-5.7,10.8-4.3,17.5c1.6,8.6,1.3,17.4-0.8,25.9
	c-0.8,3.4-2.7,6.5-4.3,9.7c-1,2-2.2,3.9-3.4,5.9l-1.3-0.1c4.1,7.9,8.1,15.8,12.2,23.6c2,4,4.2,7.8,6.2,11.8
	c3.8,7.3,7,14.9,12.2,21.4c1.8,2.5,3.3,5.2,4.5,8c2.8,6.3,5.5,12.6,8,19c0.7,1.8,0.3,4.1,0.5,6.1c0,1.4,0.2,2.8,0.7,4.2
	c2.9,6.4,7,12.1,12.1,16.9c9.6,9,18.6,18.9,27.8,28.3c0.9,0.8,2,1.5,3.1,2c2.2,1.1,5,1.5,6.7,3.1c7.9,7.5,15.8,15.1,23.2,23
	c8.9,9.5,17.4,19.4,26.2,29c6.9,7.4,14.3,14.1,21,21.7c9.5,10.7,18.2,22,27.8,32.7c4.8,5.3,10.6,9.7,15.8,14.7
	c8.1,7.9,15.9,16.1,23.9,24.1c2,2,4.8,3.4,6.3,5.7c8.2,12.2,16.3,24.5,19.8,39.2c2.6,11,6,21.8,9.2,32.7c1,3.4,2.1,7,6.4,7.9
	c0.3,0.1,0.6,0.3,0.7,0.6c1.1,3.7,2.1,7.4,3.2,11.5H6c-1.9,0-3.8,0.1-5.7,0.2v-301.1c0.4-0.2,0.8-0.3,1-0.6c2.9-5,5.8-10.1,8.6-15.2
	c2.6-4.8,4.4-10,7.5-14.3c5.4-7.1,9.7-14.9,12.7-23.3c2.1-5.7,3.9-11.6,5.3-17.6c1-4.7,1-9.7,1.5-14.6c0-1.3,0.4-2.5,1.1-3.6
	c1.9-2.2,1.4-4.4,0.8-6.8c-3.1-13.6-6.2-27.2-9.2-40.8c-0.9-3.7-1.4-7.5-1.7-11.3c-0.4-7.3-0.4-14.5-2.5-21.6
	c-1.5-4.8-1.6-10.1-3.4-14.8c-2.4-6.7-3.4-13.8-2.8-20.8c0.2-3.7,0.8-7.6-0.2-11c-0.8-2.5-1-4.4-0.1-6.8
	c6.9-18.3,19.4-32.3,34.1-44.7c4.4-3.7,8.6-7.5,13-11.2c3.6-3,6.9-6.5,10.9-8.5c20.6-9.9,42.5-16,64.9-20.5c1.7-0.3,3.3-1.2,4.4-2.6
	c4.8-7.1,12-12.6,14.8-21.1c0.1-0.3,0.3-0.6,0.5-0.8c4.6-6.8,11.2-11.6,18.2-15.6c8.6-4.9,17.5-9.4,26.2-14.2
	c1.1-0.7,2.1-1.6,2.8-2.8c1.5-2.7,2.7-5.5,4.1-8.2c1.1-1.8,2-3.7,2.8-5.6c1.6-4.9,2.7-10,4.4-14.8c3-9.3,9.2-17.2,17.5-22.3
	c5.9-3.7,12-8,19.7-4.8c0.5,0.1,1.1,0.1,1.6,0.1c4.2-0.2,8.5-0.3,12.7-0.6c0.7,0,1.4-0.3,1.9-0.8c2.6-4.8,5.1-9.6,7.8-14.7
	c-2.1-0.4-4.3-0.6-6.3-1.2c-14-4.2-25-12.8-33.6-24.4c-4.1-5.4-6.8-11.9-7.6-18.7c-1-8.9-2-17.9-1.9-26.9c0.3-12.8,3.3-25.3,7-37.6
	c0.3-1,0.5-1.9,0.9-3.6c-1.9,0.9-3.1,1.4-4.3,2.1c-14,7.8-26.1,18.7-35.3,31.9c-4.4,6.3-9.5,12.4-11.1,20.3
	c-1.1,5.2-2.5,10.2-4,15.3c-0.3,0.9-1.5,1.6-2.3,2.4c-0.5-1-0.9-1.9-1.4-2.9c-0.1-0.2,0-0.4,0-0.6c0.6-5,1.2-10.1,1.8-15.1
	c0.1-0.7,0.1-1.7-0.2-2.1c-2.3-2.2-1.6-4.9-1.2-7.5c0.6-3.8,1.4-7.7,2.2-11.5c4.3-20,12.2-37.8,29.7-50c4.7-3.3,8.9-7.4,13.3-11.2
	c6.2-5.3,12.7-10,20.4-13.3c11-4.7,19.8-12.2,24-23.9c2.1-5.9,3.4-12,5-18c0.4-1.4,1.5-3.2-0.9-3.9c-2.1-0.6-3.9-0.7-5,2.1
	c-1.2,3.2-2.7,6.7-5.2,8.9c-7.8,7.1-17.1,12.2-27.3,15c-1,0.1-1.9,0-2.9-0.3c0-1,0.2-2,0.5-3c0.4-0.8,1.1-1.6,1.9-2.1
	c5-3.9,10-7.8,15-11.7c2.2-1.6,4.4-3.3,6.4-5.1c0.9-1.1,1.6-2.4,2.1-3.7c-2.9-2-5-3.6-7.3-5c-0.9-0.5-2-0.6-2.9-0.2
	c-2.2,1.3-4.3,2.8-6.2,4.3c-3.1,2.4-6.5,4.1-10.8,4.1c0.1-0.5,0.3-1,0.5-1.4c1.6-2.2,3.4-4.3,4.9-6.6c0.5-1.1,0.9-2.2,1.1-3.4
	c-1.1,0-2.1,0-3.2,0.1c-1,0.2-2,0.4-3,0.7l-0.8-1.2l3.9-2.3l-0.5-1l-7.6,1.1c0.3-1,0.5-1.9,0.6-2.9c0.1-3.6,0.5-7.1-2.1-10.4
	c-1.9-2.4-3.7-0.8-5.1-0.3c-3.8,1.2-8.2,2.2-10.9,4.9c-4.1,4.3-6.2,10.1-5.9,16c0,1-0.8,2.1-1.2,3.2c-0.6-1-1.3-1.9-1.9-3
	c-0.1-0.4-0.1-0.8-0.1-1.3c-0.1-9.5,3.6-17.5,10.5-24c1.8-1.4,3.6-2.7,5.5-3.9c-1.1-0.9-1.9-1.7-2.8-2.4c-2.7-2-5.8-3.6-8-6
	c-15.2-16.7-22.9-37.6-31.9-57.8c-1.7-3.8-1.8-8.1-0.4-12.1c1.1-3.2,0.6-6.9,0.8-10.4c0.1-1.7-0.1-3.6,0.4-5.2
	c6-18.4,18-31.2,36.1-38.4c10-4,20.6-5.8,30.3-10.7c9-4.5,19.3-4.3,29.3-4.1c5.8,0.1,8.2-3,7.3-8.7c-0.2-1.2,0.1-2.4,0.8-3.5
	c2.1-3,4.1-6.2,6.7-8.8c14.3-13.9,31.5-19.3,51.2-17c0.7,0.1,1.6,0.4,2.1,0.1c3.1-2.1,6.3-1.5,9.5-0.6c3.6,1.1,7.2,2.3,10.7,3.5
	c5.2,1.7,9.4,5.5,11.5,10.6c1.4,3.4,4.2,6,7.7,7.1c1.7,0.6,3.3,1.6,4.5,2.9c3.1,3.7,6.8,6.8,11,9.2c1.5-4,2.1-8.4,4.5-11.1
	c6.4-7.3,13.2-14.3,20.4-20.9c4.6-4.3,11-4.7,16.6-3.1c9.8,2.7,18.1,8.4,25.1,16.4c2.4-1.3,5.2-2.7,7.9-4.4c0.7-0.7,1-1.6,0.9-2.6
	c-0.5-3.9,0.1-7.3,2.9-10.3c2.2-2.3,3.9-5,6.2-7.2c2.3-2.2,4.7-4.1,7.4-5.8C488.9,241.1,491.3,240.5,493.5,239.6z M188.6,379.3
	c0.6,3.5,1.2,6.6,1.7,9.7c3.4,18.8,13.5,33.7,27.6,46.1c2.3,2,5.4,3.1,8.4,4.2c2,0.7,4.2,1,6.3,1c1.6,0,3.9,0.1,4.6-0.9
	s0.2-3.2-0.4-4.6c-1-2.5-2.9-4.8-3.7-7.3c-2-6.2-3.7-12.5-5.4-18.9c-0.7-2.6-0.8-5.3-1.6-7.9c-0.5-1.5-1.6-2.8-3-3.6
	c-1.8-0.8-3.9-0.5-5.9-1c-2.2-0.5-4.4-1.2-6.5-2.1c-4.8-2.1-6.4-7-9.2-11c-1-1.5-2.1-2.8-3.5-4c-1.7-1.3-3.6-2.3-5.6-3.2
	c-0.7-0.3-2-0.4-2.4,0.1C189.6,377,189.1,378.1,188.6,379.3z M438.5,272.9c-2.8,0.3-5,0.5-7.2,0.8c-12.9,1.9-23,10-25.8,23.3
	c-0.2,0.7-0.3,1.5-0.2,2.2c0.3,0.8,0.8,2,1.5,2.2c5,1.5,10.1,3,15.3,4.1c1.7,0.2,3.4-0.2,4.8-1.2c3.8-3,7.2-6.5,10.8-9.8
	c4.6-4.2,9.3-8.3,13.8-12.7c1-1,1.5-2.4,1.4-3.9c-0.5-1.5-1.6-2.6-3-3.1C445.9,273.9,441.9,273.4,438.5,272.9z M209.2,334.6
	c4.3-0.1,6.7-2,8.2-6c0.5-1.5,1.3-2.9,1.8-4.4c0.3-1.3,0.6-2.6,0.8-3.9c-1.4-0.1-3.5-0.8-4.3-0.1c-3.5,2.9-6.7,6.1-9.5,9.6
	C204.1,332.5,205.8,334.7,209.2,334.6z M660,1002.4l1-0.3c0.1-0.4,0.3-0.8,0.2-1.2c-1.1-3.5-2.2-7.1-3.5-10.6
	c-0.3-0.8-1.8-1-2.8-1.5l-0.5,0.9L660,1002.4z"
      />
    </g>
  );
};

export default drawingWoman1Fill;
