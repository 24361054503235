import { useStore } from "./ArtworkPropertyStore";
import LayerCanvas from "./LayerCanvas";
import LayerWordBlob from "./LayerWordBlob";
import { wordsArray } from "./Resources/Texts";
import { FocusGridType } from "./Resources/Types";
import * as utils from "./Utilities";

// interface groupAccentCanvasProps {
//   isFocusGridDrawn: boolean;
//   focusImg: HTMLImageElement;
//   focusGrid: { value: number; x: number; y: number }[][];
// }

const GroupWordBlobs = () => {
  const props = utils.getProps();

  const pos1X = props.position1;
  const pos1Y = props.position2;

  const words = [];

  const selectWord = (wordCode: number) => {
    const objectMatchingTheme = wordsArray.filter((obj) => {
      return obj.theme === props.theme;
    });

    // console.log("chosen items:", textsMatchingTheme);
    const wordsMatchingTheme = objectMatchingTheme[0].words;
    const numberOfWords = wordsMatchingTheme.length;

    for (let i = 0; i < numberOfWords; i++) {
      if (
        wordCode > Math.floor(100 / numberOfWords) * i &&
        wordCode <= Math.floor(100 / numberOfWords) * (i + 1)
      ) {
        return wordsMatchingTheme[i];
      }
    }

    return wordsMatchingTheme[0];
  };

  let numberofWords = Math.round((pos1X + pos1Y) / 40);
  numberofWords = utils.clamp(numberofWords, 4, 7);

  let filteredFocusPoints: FocusGridType = [];

  if (props.isFocusGridDrawn) {
    filteredFocusPoints = utils.filterFocusGridValue(
      props.focusGrid,
      "x",
      props.width * 0.95,
      "lessThan"
    );

    for (let i = 1; i < numberofWords + 1; i++) {
      const size =
        props.width / 40 +
        (parseInt(
          utils.generateValue(
            2,
            (pos1Y * i * 5).toString(),
            " group word blobs"
          )
        ) *
          props.width) /
          1800;

      // console.log("font size: ", size);

      filteredFocusPoints = utils.filterFocusGridIsUsed(
        filteredFocusPoints,
        false
      );

      // filteredFocusPoints = filteredFocusPoints.reverse();
      // console.log("filtered fp array: ", filteredFocusPoints);

      const point = utils.pickGridPointInFilteredGrid(
        filteredFocusPoints,
        parseInt(
          utils.generateValue(
            2,
            (pos1Y * i * 15).toString(),
            " group word blobs"
          )
        )
      );

      // console.log("point picked: ", point);
      // console.log(pos1X);
      // console.log(pos1Y);

      utils.markPointsAroundAsUsed(props.focusGrid, point.keyX, point.keyY, 2);

      words.push({
        key: i,
        x: point.x,
        y: point.y,
        color1: utils.selectPalette(props.palette)[`wordblob${i + 1}`],
        color2: utils.selectPalette(props.palette)[`wordblob${i + 2}`],

        size: size,
        text: selectWord(
          parseInt(
            utils.generateValue(
              2,
              (pos1Y * i * 25).toString(),
              " group word blobs"
            )
          )
        ),
      });
    }
  }

  const wordItems = words.map((word) => (
    <LayerWordBlob
      key={word.key}
      id={"wordblob-" + word.key}
      color1={word.color1 ? word.color1 : "red"}
      color2={word.color2 ? word.color2 : "cream"}
      size={word.size}
      x={word.x}
      y={word.y}
      text={word.text}
    />
  ));

  return <div className="canvasLayer">{wordItems}</div>;
};

export default GroupWordBlobs;
