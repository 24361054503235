import React, { useEffect, useRef, useState } from "react";
import { findDOMNode } from "react-dom";
import "../Styles/Artwork.css";
import "../Styles/Main.css";
import { useStore } from "./ArtworkPropertyStore";
import FocusGrid from "./FocusGrid";
import GroupAccentCanvasLayers from "./GroupAccentCanvasLayers";
import GroupMaskedCanvasLayers from "./GroupMaskedCanvasLayers";
import GroupSvgIconLine from "./GroupSvgIconLine";
import GroupSvgIcons from "./GroupSvgIcons";
import GroupWordBlobs from "./GroupWordBlobs";
import LayerBackground from "./LayerBackground";
import LayerCanvas from "./LayerCanvas";
import LayerMaskedCanvasFace from "./LayerMaskedCanvasFace";
import LayerSvg from "./LayerSvg";
import LayerTextBoxCanvas from "./LayerTextBoxCanvas";
import * as utils from "./Utilities";

export const totalXPoints = 20;
export const totalYPoints = 28;
export const width = 1000;
export const height = width * 1.414;

interface ArtworkContainerProps {
  seed: string;
}

const ArtworkContainer = (props: ArtworkContainerProps) => {
  useStore.setState({ width: width });
  useStore.setState({ height: height });
  useStore.setState({ totalXPoints: totalXPoints });
  useStore.setState({ totalYPoints: totalYPoints });

  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const canvasRef = useRef(null);

  //drawing
  const drawingCode = useStore((state) => state.drawingCode);
  // const drawingFill = utils.selectDrawing(drawingCode, "fill");
  // const drawingLineart = utils.selectDrawing(drawingCode, "lineart");

  const [isFocusImgLoaded, setIsFocusImgLoaded] = useState(false);

  const focusImg = new Image();
  focusImg.onload = () => setIsFocusImgLoaded(true);
  focusImg.src = utils.selectFocusDrawing(drawingCode);
  useStore.setState({ focusImg: focusImg });
  const isFocusGridDrawn = useStore((state) => state.isFocusGridDrawn);

  const textCode = useStore((state) => state.textCode);

  //=========================================================================

  let renderedCanvases = document.getElementsByTagName("canvas");
  console.log("rendered canvases: ", renderedCanvases);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const draw = (ctx: CanvasRenderingContext2D) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = "#000000";
    ctx.drawImage(focusImg, 0, 0, ctx.canvas.width, ctx.canvas.height);
    if (renderedCanvases !== null) {
      for (let i = 0; i < renderedCanvases.length; i++)
        ctx.drawImage(
          renderedCanvases[i],
          0,
          0,
          ctx.canvas.width,
          ctx.canvas.height
        );
    }
  };

  useEffect(() => {
    if (isFocusGridDrawn && isFocusImgLoaded) {
      console.log("getting rendered canvases...");
      renderedCanvases = document.getElementsByTagName("canvas");
    }
  }, [isFocusImgLoaded, isFocusGridDrawn]);

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let animationFrameId = 0;

    //Drawing happens here
    const render = () => {
      draw(thisContext);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  //=========================================================================

  return (
    <div className={"artworkContainer"} id="artworkContainer">
      <FocusGrid />
      {isFocusGridDrawn && isFocusImgLoaded ? (
        <div>
          <LayerBackground id={"background"} element={"background"} />
          <LayerSvg id={"drawingFill"} element={"drawingColor"} />
          <LayerSvg id={"drawingFill2"} element={"drawingColor2"} />
          <GroupMaskedCanvasLayers />
          <LayerMaskedCanvasFace element={"face"} />
          <LayerSvg id={"drawingLineart"} element={"drawingLineart"} />
          <GroupAccentCanvasLayers />
          <GroupSvgIconLine />
          <GroupWordBlobs />
          <GroupSvgIcons />
          <LayerTextBoxCanvas element={"accent"} textId={textCode} />
        </div>
      ) : null}
      <div className={"artworkCanvas"}>
        <canvas
          ref={canvasRef}
          width={width}
          height={height}
          id={"artworkCanvas"}
        />
      </div>
    </div>
  );
};

export default ArtworkContainer;
