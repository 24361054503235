import "../Styles/Main.css";
import ArtworkContainer from "./ArtworkContainer";
import PropertyMaster from "./PropertyMaster";

interface MainInputProps {
  seed: string;
  elemkey: number;
}

const MainContainer = (props: MainInputProps) => {
  return (
    <div className="mainContainer">
      <ArtworkContainer seed={props.seed} key={props.elemkey} />
      <PropertyMaster />
    </div>
  );
};

export default MainContainer;
