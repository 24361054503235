/* eslint-disable react-hooks/exhaustive-deps */
import { parse } from "querystring";
import { useEffect, useRef, useState } from "react";
import "../Styles/Artwork.css";
import * as utils from "./Utilities";
import * as shape2d from "./Resources/Shapes2D";
import { useStore } from "./ArtworkPropertyStore";

interface ArtworkMaskedCanvasProps {
  id?: string;
  element?: string;
}

const LayerMaskedCanvasFace = (directProps: ArtworkMaskedCanvasProps) => {
  const props = utils.getProps();

  const color = utils.selectPalette(props.palette)["face"];

  const [context, setContext] = useState<CanvasRenderingContext2D>();

  const canvasRef = useRef(null);

  const fillcolor = utils.colorToHex(color);

  let facePoints = [{ x: 0, y: 0, value: 0 }];
  let initialPosX = 0;
  let initialPosY = 0;

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.globalCompositeOperation = "source-over";

    ctx.drawImage(props.focusImg, 0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.fillStyle = fillcolor;
    ctx.globalCompositeOperation = "source-in";

    shape2d.shapePolygon(
      ctx,
      12,
      0,
      initialPosX,
      initialPosY,
      props.width,
      props.height,
      20,
      "wiggle",
      frameCount * 0.5,
      0.02
    );

    ctx.fill();

    // shape2d.shapeWave(
    //   ctx,
    //   25,
    //   (width * 1) / 6,
    //   height / 2,
    //   (width * 5) / 6,
    //   height / 2,
    //   width,
    //   height,
    //   20,
    //   "growShrinkRandom",
    //   frameCount
    // );

    // ctx.strokeStyle = fillcolor;
    // ctx.lineWidth = 20;
    // ctx.stroke();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let animationFrameId = 0;

    const framectElement = document.getElementById("framect");

    //Drawing happens here
    const render = () => {
      if (framectElement) {
        draw(thisContext, parseFloat(framectElement?.innerText));
      }

      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  useEffect(() => {
    if (props.isFocusGridDrawn) {
      for (let i = 1; i < props.totalXPoints; i++) {
        for (let j = 1; j < props.totalYPoints; j++) {
          if (props.focusGrid[i][j] && props.focusGrid[i][j].value >= 128) {
            facePoints.push(props.focusGrid[i][j]);
          }
        }
      }

      let faceAverageX = 0;
      let faceAverageY = 0;

      for (let i = 1; i < facePoints.length; i++) {
        faceAverageX += facePoints[i].x;
        faceAverageY += facePoints[i].y;
      }

      faceAverageX = faceAverageX / (facePoints.length - 1);
      faceAverageY = faceAverageY / (facePoints.length - 1);

      initialPosX = faceAverageX;
      initialPosY = faceAverageY;
    }
  }, [draw]);

  return (
    <div className={"maskCanvas"}>
      <canvas ref={canvasRef} width={props.width} height={props.height} />
    </div>
  );
};

export default LayerMaskedCanvasFace;
