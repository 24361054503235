import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";

import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";

import SquareIcon from "@mui/icons-material/Square";
import SignalCellular4BarIcon from "@mui/icons-material/SignalCellular4Bar";
import CircleIcon from "@mui/icons-material/Circle";
import HexagonIcon from "@mui/icons-material/Hexagon";
import PentagonIcon from "@mui/icons-material/Pentagon";
import RectangleIcon from "@mui/icons-material/Rectangle";
import GradeIcon from "@mui/icons-material/Grade"; // star

import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"; //idtag
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import CallIcon from "@mui/icons-material/Call";
import CommentIcon from "@mui/icons-material/Comment";
import ExtensionIcon from "@mui/icons-material/Extension";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import HomeIcon from "@mui/icons-material/Home";
import WifiIcon from "@mui/icons-material/Wifi";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VideoCameraFrontIcon from "@mui/icons-material/VideoCameraFront";
import TodayIcon from "@mui/icons-material/Today";
import SdCardIcon from "@mui/icons-material/SdCard";
import RoomIcon from "@mui/icons-material/Room"; // location
import PublicIcon from "@mui/icons-material/Public"; // globe
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PasswordIcon from "@mui/icons-material/Password";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import BackspaceIcon from "@mui/icons-material/Backspace";
import MedicationIcon from "@mui/icons-material/Medication";

import NightlightIcon from "@mui/icons-material/Nightlight"; //moon
import LightModeIcon from "@mui/icons-material/LightMode"; //sun
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import WbTwilightIcon from "@mui/icons-material/WbTwilight";
import Brightness4Icon from "@mui/icons-material/Brightness4"; //sun moon
import ExposureIcon from "@mui/icons-material/Exposure";

export const svgIconsArray = [
  {
    theme: "technology",
    icons: [
      AddIcon,
      CameraAltIcon,
      AccessTimeFilledIcon,
      ArrowForwardIcon,
      ArrowBackIcon,
      ArrowUpwardIcon,
      ArrowDownwardIcon,
      ClearIcon,
      DownloadIcon,
      InsertLinkIcon,
      InsertPhotoIcon,
      HelpCenterIcon,
      AssignmentIndIcon,
      BluetoothIcon,
      CallIcon,
      FolderSharedIcon,
      HomeIcon,
      WifiIcon,
      VolumeUpIcon,
      VideoCameraFrontIcon,
      SdCardIcon,
      RoomIcon,
      PersonAddIcon,
      NotificationsActiveIcon,
      PasswordIcon,
      VisibilityIcon,
      VisibilityOffIcon,
      BackspaceIcon,
      MedicationIcon,
    ],
  },

  {
    theme: "light",
    icons: [
      AddIcon,
      CameraAltIcon,
      AccessTimeFilledIcon,
      ClearIcon,
      DownloadIcon,
      InsertPhotoIcon,
      HelpCenterIcon,
      LightModeIcon,
      TodayIcon,
      PublicIcon,
      WbTwilightIcon,
      Brightness4Icon,
      ExposureIcon,
      VisibilityIcon,
      VisibilityOffIcon,
    ],
  },

  {
    theme: "darkness",
    icons: [
      AddIcon,
      CameraAltIcon,
      AccessTimeFilledIcon,
      ClearIcon,
      DownloadIcon,
      InsertPhotoIcon,
      HelpCenterIcon,
      TodayIcon,
      PublicIcon,
      NightlightIcon,
      WbTwilightIcon,
      Brightness4Icon,
      ExposureIcon,
      VisibilityIcon,
      VisibilityOffIcon,
    ],
  },

  {
    theme: "the world",
    icons: [
      AddIcon,
      CameraAltIcon,
      AccessTimeFilledIcon,
      ClearIcon,
      DownloadIcon,
      InsertPhotoIcon,
      HelpCenterIcon,
      ExtensionIcon,
      FavoriteIcon,
      HomeIcon,
      WifiIcon,
      VolumeUpIcon,
      VideoCameraFrontIcon,
      TodayIcon,
      RoomIcon,
      PublicIcon,
      NotificationsActiveIcon,
      MedicationIcon,
    ],
  },

  {
    theme: "people",
    icons: [
      AddIcon,
      CameraAltIcon,
      AccessTimeFilledIcon,
      ClearIcon,
      HelpCenterIcon,
      CommentIcon,
      FavoriteIcon,
      FolderSharedIcon,
      PersonAddIcon,
      VisibilityIcon,
      VisibilityOffIcon,
      MedicationIcon,
    ],
  },
];

export const svgIconsAbstract = [
  SquareIcon,
  SignalCellular4BarIcon,
  CircleIcon,
  HexagonIcon,
  PentagonIcon,
  RectangleIcon,
  GradeIcon,
];
