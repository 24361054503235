import backgroundApartments from "./background-apartments";
import backgroundWaves2 from "./background-waves2";
import backgroundFlowers from "./background-flowers";
import backgroundSwirls from "./background-swirls";
import backgroundAds from "./background-ads";
import backgroundTrain from "./background-train";

import bgDrawingInterface from "../background-drawings/bgdrawing-interface";
import bgDrawingFaces from "../background-drawings/bgdrawing-faces";
import bgDrawingWindows from "../background-drawings/bgdrawing-windows";
import bgDrawingCitylights from "../background-drawings/bgdrawing-citylights";
import backgroundCircuit from "./background-circuit";
import backgroundQuilt from "./background-quilt";
import backgroundMosaic from "./background-mosaic";

const backgroundArray = [
  backgroundWaves2,
  backgroundApartments,
  backgroundFlowers,
  backgroundSwirls,
  backgroundAds,
  backgroundTrain,
  backgroundCircuit,
  backgroundQuilt,
  backgroundMosaic,
];

const backgroundDrawingsArray = [
  bgDrawingInterface,
  bgDrawingFaces,
  bgDrawingCitylights,
  bgDrawingWindows,
];

export { backgroundArray, backgroundDrawingsArray };
