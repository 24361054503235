const backgroundCircuit = () => {
  return (
    <g>
      <path d="M257.56,1365v-35.63l76.15-76.14.58-.59.59-.58V1059.47L379.3,1015l.58-.58.59-.59V932.4a13.18,13.18,0,1,0-14.12,0V1008l-44.42,44.42-.59.59-.58.58v192.67l-76.14,76-.59.59-.59.58V1365a13.18,13.18,0,1,0,14.12,0Z" />
      <path d="M384.15,865.87H544.48l.59-.58.59-.59L733.12,677.24a13.31,13.31,0,0,0,2.88.32,13.16,13.16,0,0,0,9.36-3.9,13.34,13.34,0,0,0,0-18.67,12.87,12.87,0,0,0-9.3-3.93h0a13.48,13.48,0,0,0-9.41,3.85,13.23,13.23,0,0,0-3.62,12.3L538.72,851.75H378.28l-.58.59-.59.59L319,911.06l-.58.59-.59.58V947h-1.53v28.4h17.28V947h-1.63V918.1Z" />
      <path d="M28,1052.11l-1.42,1.42,1.42,1.41,1.41,1.41,37.84,37.84.59.59.59.59H96.51l.59-.59.59-.59,65.57-65.57a13.25,13.25,0,0,0,12.29-22.25,12.89,12.89,0,0,0-9.31-3.93,13.43,13.43,0,0,0-9.4,3.85,13.21,13.21,0,0,0-3.63,12.29l-62.56,62.56H74.28l-27.62-27.61L160.1,940.09a13.25,13.25,0,0,0,12.29-22.25,12.87,12.87,0,0,0-9.31-3.93,13.39,13.39,0,0,0-9.4,3.85,13.21,13.21,0,0,0-3.63,12.29L29.4,1050.7Z" />
      <polygon points="306.5 996.79 297.22 996.79 295.22 996.79 293.22 996.79 293.22 998.79 293.22 1000.79 293.22 1021.19 293.22 1023.19 293.22 1025.19 295.22 1025.19 297.22 1025.19 306.5 1025.19 308.5 1025.19 310.5 1025.19 310.5 1023.19 310.5 1021.19 310.5 1000.79 310.5 998.79 310.5 996.79 308.5 996.79 306.5 996.79" />
      <path d="M249.88,1202.6l35-35,.58-.58.59-.59V1025.19h1.63v-28.4H270.37v28.4H272V1160.6l-32,32a13.46,13.46,0,0,0-3.09-.37,13.1,13.1,0,0,0-9.29,3.87,13.37,13.37,0,0,0,0,18.68,12.9,12.9,0,0,0,9.31,3.92,13.42,13.42,0,0,0,9.4-3.84A13.23,13.23,0,0,0,249.88,1202.6Z" />
      <polygon points="320.27 1025.19 329.55 1025.19 331.55 1025.19 333.55 1025.19 333.55 1023.19 333.55 1021.19 333.55 1000.79 333.55 998.79 333.55 996.79 331.55 996.79 329.55 996.79 320.27 996.79 318.27 996.79 316.27 996.79 316.27 998.79 316.27 1000.79 316.27 1021.19 316.27 1023.19 316.27 1025.19 318.27 1025.19 320.27 1025.19" />
      <path d="M667.47,751.29h-1.66l-.59.58-.58.59L542.8,874.4H443.63a13.18,13.18,0,1,0,0,14.12h105l.58-.59.59-.59L671.78,765.41h60.89l.58-.59.59-.59,100-100a12.79,12.79,0,0,0,2.95.35,13,13,0,0,0,9.25-3.87,13.36,13.36,0,0,0,0-18.67,12.87,12.87,0,0,0-9.31-3.93h0a13.43,13.43,0,0,0-9.41,3.85,13.23,13.23,0,0,0-3.62,12.29l-97,97Z" />
      <polygon points="262.28 1147.05 262.87 1146.47 262.87 1145.64 262.87 1144.81 262.87 1025.19 264.5 1025.19 264.5 1023.19 264.5 1021.19 264.5 1000.79 264.5 998.79 264.5 996.79 262.5 996.79 260.5 996.79 251.22 996.79 249.22 996.79 247.22 996.79 247.22 998.79 247.22 1000.79 247.22 1021.19 247.22 1023.19 247.22 1025.19 248.75 1025.19 248.75 1140.71 171.48 1217.97 170.9 1218.56 170.31 1219.14 170.31 1219.97 170.31 1220.8 170.31 1420 170.31 1422 170.31 1424 172.31 1424 174.31 1424 180.43 1424 182.43 1424 184.43 1424 184.43 1422 184.43 1420 184.43 1225.01 261.7 1147.64 262.28 1147.05" />
      <path d="M489.48,776.12h1.66l.59-.59.58-.59,108-108,.58-.59.59-.58V456.2l-.59-.58-.59-.59-74.81-74.61v-3.19a13.18,13.18,0,1,0-14.12,0v9.06l.59.58.58.59,74.61,74.61V659.9L485.17,762H406l-.58.58-.59.59L284.51,883.51a13.39,13.39,0,0,0-2.89-.31,13.17,13.17,0,0,0-9.36,3.89,13.35,13.35,0,0,0,0,18.68,12.87,12.87,0,0,0,9.3,3.92,13.43,13.43,0,0,0,9.41-3.84,13.2,13.2,0,0,0,3.62-12.3L412,776.12Z" />
      <polygon points="0 1126.78 18.95 1126.78 52.05 1159.78 52.64 1160.37 53.22 1160.95 54.05 1160.95 54.87 1160.95 111.48 1160.95 112.31 1160.95 113.14 1160.95 113.72 1160.36 114.31 1159.78 215.7 1058.39 216.28 1057.81 216.87 1057.22 216.87 1056.39 216.87 1055.57 216.87 1025.19 218.5 1025.19 218.5 1023.19 218.5 1021.19 218.5 1000.79 218.5 998.79 218.5 996.79 216.5 996.79 214.5 996.79 205.12 996.79 203.12 996.79 201.12 996.79 201.12 998.79 201.12 1000.79 201.12 1021.19 201.12 1023.19 201.12 1025.19 202.75 1025.19 202.75 1051.46 107.38 1146.83 58.98 1146.83 25.98 1113.83 25.4 1113.25 24.81 1112.66 23.98 1112.66 23.15 1112.66 0 1112.66 -2 1112.66 -4 1112.66 -4 1114.66 -4 1116.66 -4 1122.78 -4 1124.78 -4 1126.78 -2 1126.78 0 1126.78" />
      <path d="M137,1271.11l-49.77-49.77-.59-.59-.58-.58H58.59a13.2,13.2,0,0,0-24.34,7.06,13.2,13.2,0,0,0,24.34,7.06H80.15L124,1278.05v146h14.12V1272.28l-.59-.58Z" />
      <polygon points="228.17 947.02 226.17 947.02 224.17 947.02 224.17 949.02 224.17 951.02 224.17 971.42 224.17 973.42 224.17 975.42 226.17 975.42 228.17 975.42 237.45 975.42 239.45 975.42 241.45 975.42 241.45 973.42 241.45 971.42 241.45 951.02 241.45 949.02 241.45 947.02 239.45 947.02 237.45 947.02 228.17 947.02" />
      <polygon points="251.22 947.02 249.22 947.02 247.22 947.02 247.22 949.02 247.22 951.02 247.22 971.42 247.22 973.42 247.22 975.42 249.22 975.42 251.22 975.42 260.5 975.42 262.5 975.42 264.5 975.42 264.5 973.42 264.5 971.42 264.5 951.02 264.5 949.02 264.5 947.02 262.5 947.02 260.5 947.02 251.22 947.02" />
      <path d="M353.78,472.14l-.59.59v189.2l-14.12,14.13a12.92,12.92,0,0,0-2.91-.33,13.26,13.26,0,0,0-9.48,22.57,12.87,12.87,0,0,0,9.31,3.93,13.43,13.43,0,0,0,9.41-3.85A13.23,13.23,0,0,0,349,686.09L366.14,669l.59-.59.58-.58V478.7l85-85a13.25,13.25,0,0,0,12.29-22.25,13.12,13.12,0,0,0-9.36-3.89,13.24,13.24,0,0,0-13,16.1l-87.91,87.91Z" />
      <polygon points="274.27 947.02 272.27 947.02 270.27 947.02 270.27 949.02 270.27 951.02 270.27 971.42 270.27 973.42 270.27 975.42 272.27 975.42 274.27 975.42 283.55 975.42 285.55 975.42 287.55 975.42 287.55 973.42 287.55 971.42 287.55 951.02 287.55 949.02 287.55 947.02 285.55 947.02 283.55 947.02 274.27 947.02" />
      <polygon points="310.5 973.42 310.5 971.42 310.5 951.02 310.5 949.02 310.5 947.02 308.5 947.02 306.5 947.02 297.22 947.02 295.22 947.02 293.22 947.02 293.22 949.02 293.22 951.02 293.22 971.42 293.22 973.42 293.22 975.42 295.22 975.42 297.22 975.42 306.5 975.42 308.5 975.42 310.5 975.42 310.5 973.42" />
      <path d="M381.77,760.31h1.66l.59-.59.58-.59L438.76,705l.59-.58.58-.59V479.23a13.18,13.18,0,1,0-14.12,0V698l-48.24,48.25h-51.2l-.59.58-.59.59L203.72,868.84l-.59.58-.59.59v77h-1.32v28.4H218.5V947h-1.84V875.87L332.23,760.31Z" />
      <polygon points="996.74 865.98 861.9 1000.82 861.32 1001.41 860.73 1001.99 860.73 1002.82 860.73 1003.65 860.73 1094.2 733.89 1221.13 733.31 1221.72 732.72 1222.31 732.72 1223.13 732.72 1223.96 732.72 1339.6 688 1384.33 687.41 1384.91 686.82 1385.5 686.82 1386.33 686.82 1387.16 686.82 1420 686.82 1422 686.82 1424 688.82 1424 690.82 1424 696.94 1424 698.94 1424 700.94 1424 700.94 1422 700.94 1420 700.94 1391.26 745.67 1346.53 746.26 1345.95 746.84 1345.36 746.84 1344.54 746.84 1343.71 746.84 1228.07 873.68 1101.23 874.26 1100.65 874.85 1100.06 874.85 1099.23 874.85 1098.4 874.85 1007.75 1002.4 880.31 1002.98 879.72 1003.57 879.14 1003.57 878.31 1003.57 877.48 1003.57 868.81 1003.57 863.98 1003.57 859.15 1000.15 862.57 996.74 865.98" />
      <path d="M1002.4,1260.5l-66.46-66.45v-25.33a13.17,13.17,0,1,0-14.12,0v31.2l.59.58.59.59,73.74,73.74,3.41,3.41,3.42,3.42v-20l-.59-.59Z" />
      <path d="M950.28,1417.17l-62-62v-48.66l-.58-.58-.59-.59-76.07-76.07a13.24,13.24,0,0,0-12.91-16.11,13.39,13.39,0,0,0-9.35,3.81,13.25,13.25,0,0,0,9.32,22.65,12.8,12.8,0,0,0,2.89-.32l73.17,73.08v48.65l.59.59.58.58L936,1422.83l.59.58.58.59h20l-3.41-3.41Z" />
      <path d="M736.06,587.71l-.58.58v18.34a13.18,13.18,0,1,0,14.12,0V594.26L782,561.85a13.17,13.17,0,0,0,10.94-13c0-.19,0-.39,0-.59h4.4V530.87H769V541.1a12.9,12.9,0,0,0-2.44,7.64,13.21,13.21,0,0,0,1.79,6.65l-31.73,31.73Z" />
      <polygon points="996.74 477.58 905.32 568.99 870 533.67 869.42 533.09 868.83 532.5 868 532.5 867.18 532.5 847.21 532.5 847.21 530.87 845.21 530.87 843.21 530.87 822.81 530.87 820.81 530.87 818.81 530.87 818.81 532.87 818.81 534.87 818.81 544.25 818.81 546.25 818.81 548.25 820.81 548.25 822.81 548.25 843.21 548.25 845.21 548.25 847.21 548.25 847.21 546.62 863.07 546.62 902.5 586.05 903.91 587.46 905.32 588.88 906.74 587.46 908.15 586.05 1002.4 491.8 1002.98 491.22 1003.57 490.63 1003.57 489.8 1003.57 488.97 1003.57 480.4 1003.57 475.58 1003.57 470.75 1000.15 474.16 996.74 477.58" />
      <polygon points="996.74 540.3 585.9 951.15 585.31 951.73 584.73 952.32 584.73 953.15 584.73 953.98 584.73 1001.89 536.84 1049.78 536.25 1050.36 535.66 1050.95 535.66 1051.78 535.66 1052.61 535.66 1168.25 490.94 1212.97 490.35 1213.56 489.77 1214.14 489.77 1214.97 489.77 1215.8 489.77 1420 489.77 1422 489.77 1424 491.77 1424 493.77 1424 499.89 1424 501.89 1424 503.89 1424 503.89 1422 503.89 1420 503.89 1219.91 548.61 1175.18 549.2 1174.6 549.78 1174.01 549.78 1173.18 549.78 1172.35 549.78 1056.71 597.67 1008.82 598.26 1008.24 598.85 1007.65 598.85 1006.82 598.85 1005.99 598.85 958.18 1002.4 554.63 1002.98 554.05 1003.57 553.46 1003.57 552.63 1003.57 551.8 1003.57 543.13 1003.57 538.3 1003.57 533.48 1000.15 536.89 996.74 540.3" />
      <polygon points="996.74 717.88 913.36 801.26 829.34 801.26 828.51 801.26 827.68 801.26 827.09 801.85 826.51 802.44 671.88 957.06 671.29 957.65 670.71 958.23 670.71 959.06 670.71 959.89 670.71 1021.17 593.14 1098.74 592.55 1099.32 591.97 1099.91 591.97 1100.74 591.97 1101.57 591.97 1217.2 547.24 1261.93 546.65 1262.52 546.07 1263.1 546.07 1263.93 546.07 1264.76 546.07 1420 546.07 1422 546.07 1424 548.07 1424 550.07 1424 556.19 1424 558.19 1424 560.19 1424 560.19 1422 560.19 1420 560.19 1268.87 604.92 1224.14 605.5 1223.55 606.09 1222.97 606.09 1222.14 606.09 1221.31 606.09 1105.67 683.65 1028.2 684.24 1027.62 684.83 1027.03 684.83 1026.2 684.83 1025.37 684.83 964.1 833.54 815.38 917.56 815.38 918.39 815.38 919.22 815.38 919.81 814.8 920.39 814.21 1002.4 732.21 1002.98 731.62 1003.57 731.04 1003.57 730.21 1003.57 729.38 1003.57 720.71 1003.57 715.88 1003.57 711.05 1000.15 714.47 996.74 717.88" />
      <path d="M880.92,1417.17,822.78,1359l-.59-.58-.58-.59H780.25l-.59.59-.58.58-49.35,49.35a13,13,0,0,0-2.85-.31,13.24,13.24,0,0,0-9.25,3.75,13.05,13.05,0,0,0-3.75,7.76l-.24,2.21-.23,2.21h27.17l-.25-2.22-.24-2.22a11.14,11.14,0,0,0-.22-1.24L786.12,1372h29.63l50.84,50.85.59.58.58.59h20l-3.42-3.41Z" />
      <polygon points="769.03 463.82 769.03 465.82 769.03 475.1 769.03 477.1 769.03 479.1 771.03 479.1 773.03 479.1 793.43 479.1 795.43 479.1 797.43 479.1 797.43 477.1 797.43 475.1 797.43 465.82 797.43 463.82 797.43 461.82 795.43 461.82 793.43 461.82 773.03 461.82 771.03 461.82 769.03 461.82 769.03 463.82" />
      <polygon points="129.96 1194.4 130.78 1194.4 131.37 1193.82 131.96 1193.23 238.75 1086.55 239.33 1085.96 239.92 1085.37 239.92 1084.55 239.92 1083.72 239.92 1025.19 241.45 1025.19 241.45 1023.19 241.45 1021.19 241.45 1000.79 241.45 998.79 241.45 996.79 239.45 996.79 237.45 996.79 228.17 996.79 226.17 996.79 224.17 996.79 224.17 998.79 224.17 1000.79 224.17 1021.19 224.17 1023.19 224.17 1025.19 225.8 1025.19 225.8 1079.51 125.02 1180.29 0 1180.29 -2 1180.29 -4 1180.29 -4 1182.29 -4 1184.29 -4 1190.4 -4 1192.4 -4 1194.4 -2 1194.4 0 1194.4 129.13 1194.4 129.96 1194.4" />
      <path d="M508.12,808.1h-61a13.18,13.18,0,1,0,0,14.12H514l.58-.59.59-.58,175-175,.59-.59.59-.58V495.44a13.15,13.15,0,1,0-14.23.11V639.09Z" />
      <polygon points="769.03 486.87 769.03 488.87 769.03 498.15 769.03 500.15 769.03 502.15 771.03 502.15 773.03 502.15 793.43 502.15 795.43 502.15 797.43 502.15 797.43 500.15 797.43 498.15 797.43 488.87 797.43 486.87 797.43 484.87 795.43 484.87 793.43 484.87 773.03 484.87 771.03 484.87 769.03 484.87 769.03 486.87" />
      <path d="M2.83,1047.79,296.27,754.34l.59-.58.58-.59V478.59L496.29,279.75l.59-.59.58-.58V161.28l44.73-44.72.58-.59.59-.59V67.77a13.18,13.18,0,1,0-14.12,0v41.75l-44.73,44.73-.58.58-.59.59V272.71l-198,197.93-.92.92-.58.58-.59.59V747.41l-286,286.05-.58.59-.59.58v20l3.41-3.41Z" />
      <path d="M0,825.18H86.42l.58-.59.59-.59L120,791.57l.59-.59.58-.58V689.52l125.3-125.29A13.25,13.25,0,0,0,258.78,542a12.85,12.85,0,0,0-9.31-3.93,13.43,13.43,0,0,0-9.4,3.85,13.21,13.21,0,0,0-3.63,12.29l-128.2,128.3-.58.58-.59.59V784.53L80.55,811.06H-4v14.12H0Z" />
      <path d="M2.83,976.39,179.9,799.32l0,0,0,0,0,0,0,0,.92-1,.51-.57L182,797V727.26l31.56-31.56a13.4,13.4,0,0,0,2.89.32,13.13,13.13,0,0,0,9.36-3.9,13.34,13.34,0,0,0,0-18.67,12.87,12.87,0,0,0-9.3-3.93h0a13.43,13.43,0,0,0-9.4,3.85,13.22,13.22,0,0,0-3.62,12.29l-34.57,34.57-.58.58-.59.59v69.87L-2.83,962.06l-.59.59-.58.59v20l3.41-3.42Z" />
      <polygon points="769.03 509.92 769.03 511.92 769.03 521.2 769.03 523.2 769.03 525.2 771.03 525.2 773.03 525.2 793.43 525.2 795.43 525.2 797.43 525.2 797.43 523.2 797.43 521.2 797.43 511.92 797.43 509.92 797.43 507.92 795.43 507.92 793.43 507.92 773.03 507.92 771.03 507.92 769.03 507.92 769.03 509.92" />
      <path d="M512,179.31l-.59.59V303.06l.59.58.58.59L633.89,425.56V606.63a13.18,13.18,0,1,0,14.12,0V419.69l-.59-.58-.58-.59L525.51,297.19V185.76L570.24,141l.58-.58.59-.59V-12H557.29V134l-44.73,44.73Z" />
      <path d="M455.67,130.35l-.58.59v99.83a13.18,13.18,0,1,0,14.12,0v-94l44.72-44.72.59-.59.59-.58V-12H501V85l-44.73,44.73Z" />
      <polygon points="2.83 539.84 63.47 479.2 147.49 479.2 148.32 479.2 149.14 479.2 149.73 478.62 150.32 478.03 304.94 323.4 305.53 322.82 306.12 322.23 306.12 321.4 306.12 320.58 306.12 259.4 383.69 181.83 384.27 181.25 384.86 180.66 384.86 179.83 384.86 179 384.86 63.37 429.58 18.64 430.17 18.05 430.75 17.47 430.75 16.64 430.75 15.81 430.75 -7.96 430.75 -9.96 430.75 -11.96 428.75 -11.96 426.75 -11.96 420.63 -11.96 418.63 -11.96 416.63 -11.96 416.63 -9.96 416.63 -7.96 416.63 11.6 371.91 56.33 371.32 56.91 370.74 57.5 370.74 58.33 370.74 59.16 370.74 174.8 293.17 252.37 292.58 252.95 292 253.54 292 254.37 292 255.2 292 316.47 143.28 465.08 59.26 465.08 58.43 465.08 57.6 465.08 57.02 465.67 56.43 466.25 -2.83 525.51 -3.41 526.1 -4 526.69 -4 527.51 -4 528.34 -4 537.01 -4 541.84 -4 546.67 -0.59 543.26 2.83 539.84" />
      <path d="M399.47,81.39l-.58.59v117.3L335,263.18l-.58.58-.59.59v57.32L113.46,541.93a13.4,13.4,0,0,0-2.89-.32,13.13,13.13,0,0,0-9.36,3.9,13.34,13.34,0,0,0,0,18.67,12.89,12.89,0,0,0,9.31,3.93,13.43,13.43,0,0,0,9.4-3.85,13.23,13.23,0,0,0,3.63-12.3L345.84,329.63l.92-.92.59-.59.58-.58V270.21l63.91-63.9.58-.58.59-.59V87.84l44.72-44.72.59-.59.59-.58V-12H444.79v48L400.06,80.81Z" />
      <polygon points="2.83 717.42 390.01 330.34 390.93 329.42 391.51 328.84 392.1 328.25 392.1 327.42 392.1 326.59 392.1 278.68 439.99 230.79 440.57 230.21 441.16 229.62 441.16 228.79 441.16 227.96 441.16 112.32 485.88 67.6 486.47 67.01 487.06 66.42 487.06 65.6 487.06 64.77 487.06 -7.96 487.06 -9.96 487.06 -11.96 485.06 -11.96 483.06 -11.96 476.94 -11.96 474.94 -11.96 472.94 -11.96 472.94 -9.96 472.94 -7.96 472.94 60.56 428.21 105.29 427.63 105.87 427.04 106.46 427.04 107.29 427.04 108.12 427.04 223.76 379.15 271.64 378.56 272.23 377.98 272.82 377.98 273.64 377.98 274.47 377.98 322.39 -2.83 703.19 -3.41 703.78 -4 704.37 -4 705.19 -4 706.02 -4 714.59 -4 719.42 -4 724.25 -0.59 720.83 2.83 717.42" />
      <polygon points="773.03 433.1 793.43 433.1 795.43 433.1 797.43 433.1 797.43 431.1 797.43 429.1 797.43 419.82 797.43 417.82 797.43 415.82 795.43 415.82 793.43 415.82 773.03 415.82 771.03 415.82 769.03 415.82 769.03 417.82 769.03 419.82 769.03 429.1 769.03 431.1 769.03 433.1 771.03 433.1 773.03 433.1" />
      <path d="M604.41,85.52V333.45l.59.59.58.58,65.89,65.89.59.59.58.59h53.09l.59-.59.58-.59L814,313.41l.58-.59.59-.58V59.51a13.18,13.18,0,1,0-14.12,0v247l-81.19,81.2H678.51l-60-60.08V85.52a13.18,13.18,0,1,0-14.12,0Z" />
      <polygon points="818.81 415.82 818.81 417.82 818.81 419.82 818.81 429.1 818.81 431.1 818.81 433.1 820.81 433.1 822.81 433.1 843.21 433.1 845.21 433.1 847.21 433.1 847.21 431.47 864.63 431.47 865.45 431.47 866.28 431.47 866.86 430.89 867.45 430.31 883.56 414.29 884.15 413.7 884.74 413.12 884.74 412.29 884.74 411.45 884.74 -7.96 884.74 -9.96 884.74 -11.96 882.74 -11.96 880.74 -11.96 874.62 -11.96 872.62 -11.96 870.62 -11.96 870.62 -9.96 870.62 -7.96 870.62 407.25 860.52 417.35 847.21 417.35 847.21 415.82 845.21 415.82 843.21 415.82 822.81 415.82 820.81 415.82 818.81 415.82" />
      <path d="M669.77,337.05a13.4,13.4,0,0,0,9.36-3.81,13.23,13.23,0,0,0,3.62-12.3l36.5-36.5.59-.58.59-.59V-12H706.31V277.3l-33.6,33.6a13.39,13.39,0,0,0-2.89-.31,13.17,13.17,0,0,0-9.36,3.89,13.35,13.35,0,0,0,0,18.68A13.14,13.14,0,0,0,669.77,337.05Z" />
      <polygon points="2.83 433.36 136.24 299.94 137.16 299.03 137.74 298.44 138.33 297.86 138.33 297.03 138.33 296.2 138.33 205.65 265.17 78.71 265.75 78.13 266.34 77.54 266.34 76.71 266.34 75.89 266.34 -7.96 266.34 -9.96 266.34 -11.96 264.34 -11.96 262.34 -11.96 256.22 -11.96 254.22 -11.96 252.22 -11.96 252.22 -9.96 252.22 -7.96 252.22 71.68 126.3 197.7 125.38 198.62 124.8 199.2 124.21 199.79 124.21 200.62 124.21 201.44 124.21 291.99 -2.83 419.03 -3.41 419.62 -4 420.2 -4 421.03 -4 421.86 -4 430.53 -4 435.36 -4 440.19 -0.59 436.77 2.83 433.36" />
      <path d="M166.21,210.32l-.59.58v51.39a13.22,13.22,0,1,0,14.12,0V216.77L293.32,103.19l.58-.58.59-.59V-12H280.37V96.16L166.79,209.73Z" />
      <path d="M0,328.55H28l.59-.58.58-.59L78.5,278a13.37,13.37,0,0,0,2.88.31,13.17,13.17,0,0,0,9.37-3.89,13.36,13.36,0,0,0,0-18.68,13.17,13.17,0,0,0-9.36-3.89,13.37,13.37,0,0,0-9.35,3.81A13.23,13.23,0,0,0,68.45,268L22.11,314.43H-4v14.12H0Z" />
      <path d="M72.84,185.84a13.18,13.18,0,0,0-9.37,3.9,13.35,13.35,0,0,0,0,18.67,13.11,13.11,0,0,0,9.36,3.89,13.35,13.35,0,0,0,9.35-3.81,13.21,13.21,0,0,0,3.63-12.29L208.35,73.61l.59-.58.58-.59V-12H195.4V66.58L75.72,186.16A13.38,13.38,0,0,0,72.84,185.84Z" />
      <path d="M209.56,219.6l-.59.59V353.91L128.9,434.08H78a13.18,13.18,0,1,0,0,14.12h56.69l.59-.59.58-.59,86.09-86.08.58-.59.59-.58V226.05l98.38-98.38.58-.58.59-.59V9.2L337-5.13l3.41-3.41L343.8-12h-20l-.58.59-.59.59-12.95,13-.59.58-.58.59v117.3L210.14,219Z" />
      <path d="M2.83,185l61.91-61.91.59-.58.58-.59V85.09l15-15a13.31,13.31,0,0,0,2.88.32,13.16,13.16,0,0,0,9.37-3.9,13.36,13.36,0,0,0,0-18.67,13.17,13.17,0,0,0-9.36-3.89A13.24,13.24,0,0,0,70.9,60l-18,18-.58.58-.59.59v36.82L-2.83,170.67l-.59.59-.58.58v20l3.41-3.41Z" />
      <path d="M337.36,27.23l-.59.59v117.3l-45.13,45.13a13.26,13.26,0,0,0-12.39,22.24,13.14,13.14,0,0,0,9.36,3.89,13.22,13.22,0,0,0,13-16.1l48.13-48.13.59-.58.58-.59V33.68L389.7-5.13l3.42-3.41L396.53-12h-20l-.59.59-.58.59L337.94,26.65Z" />
      <path d="M492.9,1125.64l.58-.59v-99.84a13.06,13.06,0,0,0,6.12-11.16,13.18,13.18,0,1,0-20.24,11.16v94l-44.72,44.72-.59.59-.59.58V1424h14.12V1171l44.73-44.73Z" />
      <path d="M581.28,1353.78a13.2,13.2,0,0,0-7.06,24.34V1424h14.12v-45.88a13.2,13.2,0,0,0-7.06-24.34Z" />
      <path d="M419.43,1384.36V1146.57l44.73-44.73.59-.58.58-.59V1000.8a13.22,13.22,0,1,0-20.24-11.23,13.12,13.12,0,0,0,6.12,11.17v94l-44.73,44.72-.58.59-.59.59v243.75a13.18,13.18,0,1,0,14.12,0Z" />
      <path d="M407.16,1102.07a13.16,13.16,0,0,0,9.37-3.9,13.36,13.36,0,0,0,0-18.67,12.91,12.91,0,0,0-9.31-3.93h0a13.48,13.48,0,0,0-9.41,3.85,13.24,13.24,0,0,0-3.62,12.29l-20.18,20.18-.59.59-.58.59v167.28l-9.54,9.62a13.58,13.58,0,0,0-2.91-.32,13.15,13.15,0,0,0-9.37,22.47,12.89,12.89,0,0,0,9.31,3.93,13.43,13.43,0,0,0,9.4-3.85,13.21,13.21,0,0,0,3.63-12.29l12.53-12.53.58-.59.59-.59V1118.93l17.18-17.18A13.31,13.31,0,0,0,407.16,1102.07Z" />
      <path d="M661.8,1272.51l.59-.58v-117.3l45.13-45.13a13.25,13.25,0,0,0,12.28-22.25,12.87,12.87,0,0,0-9.3-3.93,13.44,13.44,0,0,0-9.41,3.85,13.23,13.23,0,0,0-3.62,12.3l-48.13,48.12-.59.59-.58.58v117.3l-44.73,44.73-.58.58-.59.59v112h14.12V1317.93l44.83-44.83Z" />
      <path d="M577.35,1199.07l.59-.58v-117.3l63.9-63.9.58-.59.59-.58V958.79L863.26,738.55a13.3,13.3,0,0,0,3,.34,13,13,0,0,0,9.24-3.83,13.34,13.34,0,0,0,0-18.67,12.87,12.87,0,0,0-9.3-3.93,13.44,13.44,0,0,0-9.41,3.85,13.23,13.23,0,0,0-3.62,12.3L630.06,951.86l-.58.59-.59.58v57.33l-63.9,63.9-.59.58-.58.59v117.3l-44.73,44.72-.59.59-.58.58V1424H532V1244.39l44.72-44.73Z" />
      <path d="M371.14,1375.05l-44.83-44.83v-40l36.87-36.87.59-.59.58-.58V1071.81l49.53-49.52.58-.59.59-.58V964.2l36.72-36.72H559.48l.58-.59.59-.58,100.05-100A13.25,13.25,0,0,0,673,804a12.87,12.87,0,0,0-9.31-3.93,13.48,13.48,0,0,0-9.41,3.85,13.23,13.23,0,0,0-3.62,12.29l-97,97H445.91l-.59.58-.59.59L402.1,957.06l-.59.59-.58.58v56.92l-49.52,49.52-.59.59-.59.58v180.43l-36.87,36.88-.58.58-.59.59V1336l.59.59.58.58L358.19,1382v42h14.12v-47.78l-.59-.59Z" />
      <path d="M475.74,1245.54v-50.11l44.72-44.73.59-.58.58-.59v-99.84a13.2,13.2,0,0,0-7.06-24.34,13.2,13.2,0,0,0-7.06,24.34v94l-44.72,44.73-.59.59-.58.58v56a13.17,13.17,0,1,0,14.12,0Z" />
      <path d="M690,1297l.59-.59V1179.11l98.38-98.38.58-.58.59-.59V945.84l80.17-80.17h50.83a13.18,13.18,0,1,0,0-14.12H864.3l-.59.59-.59.58L777,938.81l-.59.58-.58.59V1073.8l-98.38,98.38-.59.58-.58.59v117.29l-44.73,44.73-.58.58-.59.59V1424h14.12v-81.59l44.83-44.83Z" />
      <polygon points="996.74 382.82 893.16 486.4 847.21 486.4 847.21 484.87 845.21 484.87 843.21 484.87 822.81 484.87 820.81 484.87 818.81 484.87 818.81 486.87 818.81 488.87 818.81 498.15 818.81 500.15 818.81 502.15 820.81 502.15 822.81 502.15 843.21 502.15 845.21 502.15 847.21 502.15 847.21 500.52 897.27 500.52 898.09 500.52 898.92 500.52 899.51 499.94 900.09 499.35 1002.4 397.15 1002.98 396.56 1003.57 395.98 1003.57 395.15 1003.57 394.32 1003.57 385.65 1003.57 380.82 1003.57 375.99 1000.15 379.41 996.74 382.82" />
      <path d="M588.34,1300.71v-7.26l44.73-44.73.58-.59.59-.58v-117.3l95.11-95.11.59-.59.58-.58v-33.13a13.18,13.18,0,1,0-14.12,0v27.36l-95.11,95.12-.59.58-.58.59v117.3l-44.73,44.72-.58.59-.59.58v13.14a13.1,13.1,0,1,0,14.12-.11Z" />
      <polygon points="847.21 440.4 847.21 438.77 845.21 438.77 843.21 438.77 822.81 438.77 820.81 438.77 818.81 438.77 818.81 440.77 818.81 442.77 818.81 452.15 818.81 454.15 818.81 456.15 820.81 456.15 822.81 456.15 843.21 456.15 845.21 456.15 847.21 456.15 847.21 454.52 872.79 454.52 873.62 454.52 874.44 454.52 875.03 453.93 875.62 453.35 906.32 422.65 906.9 422.06 907.49 421.48 907.49 420.65 907.49 419.82 907.49 241.25 950.48 198.25 951.07 197.67 951.65 197.08 951.65 196.25 951.65 195.43 951.65 -7.96 951.65 -9.96 951.65 -11.96 949.65 -11.96 947.65 -11.96 941.53 -11.96 939.53 -11.96 937.53 -11.96 937.53 -9.96 937.53 -7.96 937.53 191.22 894.54 234.32 893.95 234.9 893.37 235.49 893.37 236.31 893.37 237.14 893.37 415.71 868.68 440.4 847.21 440.4" />
      <path d="M1001.49,265.91l-2.08.08c-17.51.7-36.44,2.46-38.75,2.77l-1,.1-.72.06-.72.07-.51.51-.51.51-28.56,28.56-.59.59-.59.58V416.22l-47.12,47.13h-33.1v-1.63h-28.4V479h28.4v-1.63h39l.59-.59.58-.58,53-53,.59-.59.58-.58V305.41l23.1-22.9c6.48-.59,21.42-1.87,35.08-2.41l1.92-.07,1.92-.08V265.82Z" />
      <path d="M996.72,431.59c-24.35,24.66-51.57,52.57-66.2,67.57l-10,10.29H847.21v-1.63h-28.4V525.1h28.4v-1.63h79.25l.59-.59.58-.58.92-.92,3-3.12.21-.21c10.26-10.54,41.49-42.61,70.62-72.17l.57-.58.58-.59v-20l-3.42,3.47Z" />
      <polygon points="769.03 440.87 769.03 442.87 769.03 452.15 769.03 454.15 769.03 456.15 771.03 456.15 773.03 456.15 793.43 456.15 795.43 456.15 797.43 456.15 797.43 454.15 797.43 452.15 797.43 442.87 797.43 440.87 797.43 438.87 795.43 438.87 793.43 438.87 773.03 438.87 771.03 438.87 769.03 438.87 769.03 440.87" />
      <path d="M718.11,1321.47l.58-.59V1203.59L832.27,1090l.58-.58.59-.59v-51.39a13.18,13.18,0,1,0-14.12,0V1083L705.74,1196.66l-.58.58-.59.59v117.29l-44.73,44.73-.58.58-.59.59v63h14.12v-57.22l44.73-44.72Z" />
    </g>
  );
};

export default backgroundCircuit;
