const drawingWoman1Lineart = () => {
  return (
    <g>
      <path
        d="M1.2,1194c5.7-8.1,11.6-16.1,17.2-24.3c7.8-11.5,15.5-23.1,22.9-34.9c6.2-9.6,11.1-19.9,14.8-30.7c1.4-4.3-0.3-7.8-1.5-11.3
	c-5.2-15.4-8.3-31.2-11.5-47c-2.7-13.5-5.9-27-8.5-40.6c-3.5-18.8-6.7-37.7-9.9-56.5c-1.7-9.8-3.2-19.6-4.9-29.4
	c-0.3-1.6-1.1-3.4,1.9-4.1c0.5,1.2,0.9,2.5,1.2,3.8c2.1,12.1,4.1,24.2,6.3,36.3c2,11.3,4.3,22.6,6.5,33.9
	c2.4,11.9,4.8,23.8,7.4,35.7c2.2,9.9,4.5,19.8,6.8,29.6s4.4,19.8,7.2,29.5c4.6,16.9,11.7,33,21.2,47.7c1.6,2.5,3.1,5.1,4.4,7.7
	c3.9,7.8,11.5,11.9,17.4,17.7c4,3.7,7.7,7.8,11,12.2c7.2,10.2,14,20.6,20.7,31c6.4,10,10,21,12.2,32.7c0.9,4.8,1.6,9.5,0.2,14.5
	c-1.3-3.9-3.2-7.7-3.9-11.8c-2.1-13.3-7.1-26-14.8-37.1c-8.3-12-16.6-23.9-25.1-35.7c-2-2.7-5-4.7-8.1-7.4c-0.3,6.4-0.5,11.9-1,17.4
	c-1.1,14.6-3.3,29.2-6.6,43.5c-3.1,13.4-6.7,26.7-12.5,39.2c-5.9,12.6-7.8,25.8-7.2,39.4c0.3,7.7,0.2,15.4,0.6,23
	c0.3,5.9,1.1,11.8,5.3,16.6l-0.7,0.6c-0.9-0.7-1.7-1.5-2.3-2.4c-5.5-9.8-9.7-19.9-8.6-31.5c0.6-6,0.4-12.1,1.6-17.9
	c1.7-8.1,4.3-16,6.7-24c3.1-10.2,6.7-20.2,9.5-30.5c3.1-11.1,5.5-22.3,7.4-33.7c2.1-13.4,3-27.1,4.3-40.6c0-1-0.5-1.9-1.3-2.6
	c-8.5-6.7-12.8-16.3-18.1-25.3c-2-3.4-4-6.8-6.3-10.9c-0.2,3-0.4,5.3-0.6,7.7c-1.1,12.8-4.8,25-9.2,37.1
	c-4.1,11.5-9.6,22.4-16.4,32.5c-6.8,10.1-13.8,20.2-20.9,30.1c-2.4,3.4-5.4,6.4-8,9.8c-1.6,2.3-3.1,4.8-4.4,7.3
	c-1,1.7-1.8,3.6-2.6,5.4v-9.6c1-1.6,1.9-3.2,3-4.8c9.6-13.1,19.5-26,28.6-39.4c8.5-12.5,14.8-26.3,19.7-40.7c3.8-11,6.3-22.4,7.3-34
	c0.2-2.9-0.1-5.9-0.2-8.7c-4.2,9-7.8,18.1-12.6,26.4c-7.2,12.3-15,24.2-23,35.9c-7.3,10.7-15.1,21-22.8,31.5L1.2,1194z"
      />
      <path
        d="M1.2,1108.2c5.4-8.6,10.9-17.1,16.3-25.7c8.5-13.3,15.3-27.2,17.4-43.1c0.5-3.6,0.1-7.4,0.2-11.1c0.2-1.7,0.4-3.3,0.7-5h1.5
	c0.4,5.3,1.3,10.7,1.2,16c-0.1,9.7-3.8,18.6-7.5,27.3c-5.5,12.9-13.9,24.1-21.5,35.7c-2.9,4.4-5.6,8.9-8.3,13.3L1.2,1108.2z"
      />
      <path
        d="M504.2,236.9c2.9,1.3,5.8,2.4,8.6,3.9c4.3,2.3,6.7,6.2,8.5,10.6l-0.6,0.9c-1.8-1.3-3.6-2.7-5.5-3.9c-2.9-2.1-5.9-4-9-5.7
	c-5.4-2.6-10.8-0.7-15.4,1.9c-5.4,3.2-10.6,6.9-15.4,11c-2.1,1.7-3.4,4.5-5.1,6.8c-1.8-0.8-2.4-1.7-1-3.8c2.1-3.4,4.6-6.5,7.3-9.4
	c6.4-6.6,14.7-10.8,23.8-12.2L504.2,236.9z"
      />
      <path
        d="M1.2,1147.6c11.9-12.9,21.5-27.5,30.8-42.4c3.6-5.7,7.8-11,11.7-16.5c0.1-0.2,0.5-0.2,1.8-0.7c-1.7,3.7-2.8,6.9-4.5,9.6
	c-8.5,13.1-17,26.2-25.9,39c-4,5.8-9.2,10.8-13.8,16.1L1.2,1147.6z"
      />
      <path d="M1.2,1249.3l11.2,1.1l0.2,0.4l-11.5,1.7V1249.3z" />
      <path
        d="M441,420.9c-0.2,0.5-0.6,1.1-0.5,1.5c3.3,11.3,5.5,22.9,9.8,34c3.5,8.9,8.6,17.1,14.9,24.3c4.3,4.8,9.7,8.7,15,12.5
	c5.5,3.9,11.2,7.4,17.2,10.5c7.4,3.7,15.2,6.7,22.9,9.8c6,2.4,12,4.6,18,6.8c6.7,2.3,13.4,4.4,20,6.8c4.4,1.6,8.6,3.6,12.9,5.2
	c9.7,3.5,19.1,8,29.4,9.3c8.8,1.2,17.7,2,26.7,2.4c3.7,0.2,7.6-0.7,11.3-1.5c0.7-0.2,1.4-2,1.5-3.1c0.5-16.7,3.2-33.3,8-49.3
	c4.1-13.8,10.9-26.3,18.8-38.3c1.5-2.6,2.8-5.2,3.9-7.9c1.1,2.5,0.9,5.4-0.5,7.8c-2.7,5-6.1,9.7-8.4,14.9c-4.3,9.4-8,19-11.3,28.9
	c-4.1,12.9-5.6,26.4-6.5,40c-0.1,1.9,0,3.8,0,6.2c3-0.8,5.8-1.7,8.7-2.9c1.4-0.8,2.6-2,3.2-3.5c4.6-12.8,11.4-24.2,19.3-35.2
	c7-9.7,13.5-19.7,17-31.2c2.8-8.9,1.6-17.8,0.5-26.6c-0.2-2-0.5-4-0.8-5.8c-17.2-2.6-31.3-11.1-45-20.6
	c-13.2-9.1-24.6-20.2-35.3-32.1c-9.9-11.1-19.6-22.4-30.1-32.9c-13.3-13.4-30.1-21-47.9-26.8c-13-4.3-26.2-4.9-39.7-5.1
	c-8.7-0.1-17.5-1-25.5-1.6c3.4,2.7,7.7,5.7,11.5,9.2c4,3.6,7.8,7.5,11.2,11.6c2.2,2.7,5.2,4.5,8.6,5.4c17.3,5,34.7,9.7,51.6,15.5
	c22.7,7.7,44,18.9,63.2,33.4c10.6,8,20.4,16.9,28,27.9c1.2,1.5,2,3.2,2.5,5.1c-1.9-2.3-3.8-4.6-5.6-7
	c-13.6-17.3-31.2-29.6-50.1-40.3c-23-13-47.7-21.6-73.1-28.6l-19.8-5.4c3.8,5.2,7.3,9.7,10.4,14.4c9.8,14.8,21.9,27.1,37.1,36.4
	c5,3,9.7,6.5,14.9,9c3.2,1.6,7.1,1.9,10.6,2.8c1.8,0.4,3.6,0.8,5.4,1.1c0,0.4,0,0.7-0.1,1.1c-1,0-1.9,0.1-2.8,0.1
	c-8.1-0.1-15.2-2.9-21.7-7.8c-7.6-5.7-15.8-10.5-23.3-16.4c-9.3-7.4-16-17.2-22.6-26.9c-1.4-2.1-3.2-4-4.3-6.3
	c-4.3-9.1-13-10.1-21.2-12.3c-8.5-2.2-16.9-4.6-25.4-7c-1.8-0.6-3.4-1.4-5-2.4l42.4,11.2l0.5-0.6c-2.9-3.2-5.8-6.4-8.8-9.6
	c-10.3-11-23.2-17.6-37.5-21.7c-5.3-1.5-10.8-2-15.6,0.5c0.4-1.3,0.7-2.7,0.8-4.1c0-2.1,1-2.5,3.3-2.2c-0.5,1.5-0.9,2.7-1.2,3.6
	l11.3,0.7c0.4,0,1.1,0.2,1.2,0c2-3.7,4.1-0.8,5.9,0c11.1,4.7,23.1,7.1,35.1,7.3c11.6,0.2,23.3,0.2,34.8,1.6c16.5,2,31.4,8.8,45.7,17
	s25.5,20.3,36.4,32.5c9.1,10.2,18.3,20.4,28.2,29.9c7.4,7,15.7,13.1,24,19c6.2,4.4,13.2,7.8,19.8,11.7c0.2-0.3,0.4-0.7,0.6-1
	c-5.9-4.3-12-8.5-17.8-13c-8.6-6.7-17.9-12.8-25.2-20.6c-6.3-6.8-10.6-15.7-15.3-23.9c-9.4-17.2-22.6-32.2-38.5-43.7
	c-10.3-7.3-21.5-13-33.5-16.9c-12.1-4-24.3-7.1-37-8.2c-15.5-1.4-31.1-1.3-46.5,0.5c-0.9,0.1-1.8-0.1-2.6-0.6
	c0.5-0.4,1.1-0.7,1.6-0.8c14.4-1.3,28.9-3.3,43.3-3.7c8.8-0.2,17.8,1.7,26.6,3.3c12.8,2.4,24.9,7.2,36.4,13.2
	c13.3,7,25.3,16.3,35.3,27.5c4.1,4.4,7.9,9.1,11.3,14c3.5,5.1,6.5,10.4,9.1,15.9c6.8,15,17.6,27.8,31.3,37
	c11.2,7.6,21.8,16,32.7,24.1c1.2,0.9,2.4,1.6,3.7,2.5c0.5-3.7,0.6-7.3,1.5-10.6c2.4-8.6,8.2-15,15.6-19.7c3-1.9,6.7-2.9,10.1-4
	c0.7-0.2,1.9,1,2.9,1.5c-0.7,0.7-1.4,1.3-2.3,1.7c-11.3,4.1-19.6,11.4-22.6,23.2c-1,4,0.2,8.6,0.5,12.9c0.6,7.8,2,15.5,1.7,23.3
	c-0.4,11.4-5.6,21.3-11.7,30.7c-5.1,7.9-10.7,15.4-15.6,23.4c-3.7,6-6.5,12.4-10.1,19.4c1.9-0.3,2.7-0.6,3.5-0.4
	c0.8,0.1,1.5,0.5,2,1.2c0.2,0.6-0.2,2-0.6,2.2c-8.4,3.1-8.8,11-10.4,17.9c-1.7,7.5-2.7,15.2-3.9,22.8c-0.1,1,0,1.9,0.4,2.8
	c2.7,5.5,5.4,11.1,8.2,16.6l1.3-0.4c-0.4-3.8-0.7-7.7-1.2-11.4c-2.5-17,1-32.8,9-47.8c4.3-8.1,8.5-16.3,12.7-24.5
	c0.6-1.2,0.3-2.9,0.4-4.5l1.6,0.8c4.9-7.7,7.2-16.4,8.7-25.4l1.2,0.3c-1.4,5.5-2.6,10.9-4.2,16.3c-0.8,2.5-1.9,5-3.3,7.2
	c-1.7,2.6-1.3,4.6,0.8,6.6c0.7,0.7,1.4,1.5,2,2.3c3.2-4.4,6.6-8.5,9.2-13.1c1.1-1.9,0.6-4.9,0.3-7.3c-1.5-12.6-1-25.1,2.7-37.3
	c0.3-1,1.4-1.8,2.1-2.7c0.3,1.1,1,2.4,0.8,3.4c-2.5,10.7-3.1,21.8-1.8,32.8c0.1,0.6,0.1,1.3,0.2,1.9s0.3,1.4,0.5,2.6
	c4.8-6.2,7.7-13.7,8.6-21.5c1-8.4-0.3-16.7-2.3-24.9c-2.6-10.5-2.1-20.8,2.3-30.8c1-2,2.1-3.9,3.5-5.6c2-2.6,4.5-4.1,8-3.6l0-0.1
	c-7.4,2.7-10,9.1-12,15.9c-2.7,9.4-1.2,18.6,1,27.9c2.5,10.5,2.8,21-1,31.2c-1.4,3.8-3.7,7.3-5.6,11c-0.7,1.4-1.6,2.6-2.2,4.1
	c5.8-4.9,12.2-16,12.2-23.4c0-11.6,0.1-23.3-1.1-34.8c-1.4-12.5,2.3-18.7,14.4-25.7c3.4-2.2,7.7-2.2,11.2-0.1c1.1,0.9,2,2,2.4,3.4
	c-9.3-4-15,1.3-20.6,6.9c-5.9,6-3,13-2.5,19.9c6.4-13.4,15.1-23.6,31.1-23.6l-1.8-4.8l-0.1,0.1c1,0.8,2,1.6,3,2.3
	c2.7,1.7,5.4,3.2,8.1,4.9c2.9,1.8,3.2,1.6,4.5-1.4c1.1-2.9,1.3-6,0.5-9c-0.8-4-1.4-8.1-1.8-12.2c-0.4-3.3,0.2-3.7,4.4-2.8
	c-1.6-8.8-2.8-17.3-4.7-25.7c-1.6-7.5-3.7-14.9-6.4-22.1c-4.7-11.8-10.4-23-20.2-31.7c-6.5-5.8-13.2-11.3-20.8-15.4
	c-6.1-3.3-12.5-5.9-18.8-8.8c-3-1.4-6-2.6-8.9-4c-1.1-0.5-1.9-1.4-2.4-2.4c14.5,0.3,27,6.3,39.1,13.4C716.8,293,707,287.4,695,286
	c-0.6,0.3-1.2,0.9-1.7,0.8c-3.2-0.8-6.5-1.4-9.8-1.6l5.3,3c-0.1,0.2-0.2,0.4-0.3,0.6l-19.7-5.2l-1.2,1.6c0.8,0.2,1.6,0.5,2.4,0.9
	c9.7,5.4,18.5,12.2,26,20.4c5.1,5.6,9.4,11.9,12.6,18.7c5.4,11.2,9.8,22.9,14.4,34.4c0.9,2.6,1.4,5.3,1.5,8c0.1,1.4-0.5,2.8-1,4.7
	c-5-17.2-12.4-32.5-20.4-47.6c-5.9-11.1-15.2-18.8-24.9-26c-5.2-3.9-10.9-7.1-16.4-10.6s-11.2-6.8-16.7-10.4
	c-11.2-7.3-23.8-12-37-13.8c-8-1.1-16-3.1-24.1-3.8c-11.5-1-23.2-1.2-34.7,0.8c-3,0.5-5.9,1.9-8.9,1.8c-5.6-0.1-11.3-1-16.9-1.3
	c-5-0.2-10.1-0.1-15.2,0.1c-4.3,0-8.6,0.3-12.8,0.9c-8.4,1.5-16.6,3.9-23.8,9c-0.9,0.6-1.9,0.8-2.8,1.4c-0.9,0.6-1.7,1.3-2.4,2
	l3.2,1.5l0.1,0.7l-6.9,0.9l5.4,2.4l0.1,0.8c-2.8,0.3-5.7,0.6-8.5,1c-1.4,0.2-3.2,0.3-4.2,1.2c-6,5.3-11.8,10.8-17.7,16.3
	c-0.3,0.3-0.6,0.4-1.6,0.1l13.9-19.4c-20.5-6.6-27.7-8.5-45.3,13.6c0-1.2-0.5-2.7,0.1-3.7c6.2-11,16.5-20.3,30.9-18.4
	c5.9,0.8,11.7,2.6,17.5,3.8c1.1,0.1,2.2-0.1,3.2-0.7c-5.9-5.4-11.1-11.4-18.7-13.6c-10.3-3.1-18.5,1.2-25.8,8.2
	c-5.9,5.7-10,12.5-13.8,19.5c-0.6,1.1-1.4,2.1-2.6,3.9l19.4,11.7l-0.4,1.2c-1.9-0.2-3.8-0.3-5.6-0.7c-5.5-1.3-9.9-4.7-14-8.4
	c-1.6-1.4-3-1.9-5.1-1.1c-1.2,0.3-2.5,0.1-3.6-0.6l2.5-1.8c-5.7-6.7-11.3-13.4-16.9-20.1c-0.4-0.5-0.3-1.4-0.6-2.6l21.2,19.9
	c2.2-3.7,4.1-7,6-10.1c4.6-7.4,9.5-14.6,17.1-19.2c12.5-7.6,23.5-6.8,34.8,2.4c2.8,2.3,5.7,4.4,8.4,6.7c1.3,1.1,2.2,1.1,3.8,0.1
	c9.6-6.1,19.4-12.2,31.2-13c10.3-0.7,20.6-1,30.9-1.3c2.2-0.1,4.3,1.1,6.5,1.1c4.4,0.1,9,0.5,13.2-0.6c10.2-2.8,20.4-2.8,30.8-2.7
	c14.1,0.1,27.8,2.8,41.5,5.6c13.2,2.7,24.6,8.9,35.8,15.9c4.8,3,9.5,5.8,15.8,5.6c6-0.2,12,1.4,18.1,1.9c3.8,0.3,7.7,0.1,11.5,0.5
	l-3-0.2l-0.2,0.5c0.7,0.3,1.5,0.6,2.3,0.9c10.7,1.9,20.2,7.9,26.5,16.8c3.3,4.6,7.6,8.1,11.9,11.7c10.4,8.6,17.4,19.5,22.8,31.8
	c4.8,10.9,7.3,22.5,9.9,33.9c1.6,7.1,1.7,14.6,2.2,22c0.5,7.6,1,15.3,1.1,23c0.1,5.3,0.1,10.6-3.5,15.1c-0.5,0.6,0,2,0.2,3
	c3,9.9,6.3,19.8,8.8,29.8c1.7,6.8,2.1,13.8,3.1,20.7c1.2,9,2.5,18,3.6,27.1c0.4,3.3,0.1,6.8,0.5,10.2c1,9.5,2,19.1,3.4,28.6
	c0.4,2.5,1.9,4.9,3,7.2c0.7,1.5,1.3,2.7,0,4.3c-0.9,1.4-1.5,3-1.8,4.7c-0.7,3.1-2.9,5.7-6,6.7c-1.9,0.7-3.2,3.2-4.8,4.8
	c-1.3,1.3-2.7,2.4-4.3,3.8l-7.6,11.1l11.5,7l-6.2,0.8c-1.2,1.4-1.9,2.9-2.8,3c-1.9,0.2-3.8-0.2-6.4-0.5l-1.2,10.2
	c1.8,3.3,3.2,7.3,5.8,10.2c4.6,5.3,5,12.3,8.2,18.2c3.3,5.8,6.1,11.8,8.6,18c2.6,6.7,4.3,13.5,3.5,21c-0.7,6.4-8.5,13.5-14.2,14
	c-3.5,0.3-6.9,0.8-10.3,1.5c-4.2,0.8-5.4,3-4.3,7.1c1.9,6.7,4,13.3,5.9,20c0.7,2.4,1.4,5,1.2,7.4c-0.5,6.5-5.4,10.2-9.9,14
	c-1.4,1-3,1.9-4.6,2.6c4.5,4.1,6.2,9.4,6.6,15.3c0.2,3.1-0.9,6.1-3.1,8.3c-3.5,3.9-7.4,7.9-9.4,12.5c-1.7,3.9-1.5,9-1.1,13.4
	c0.6,7.9,1.4,15.7,0.3,23.7c-1.2,8.8-5.3,15.7-12.7,20.2c-5.5,3.3-11.8,5.4-17.9,7.9c-6.3,2.6-12.8,4.8-19.2,7.4
	c-1.8,0.7-3.3,2.3-4.8,3.6c-2.2,1.8-3,3.7-1.7,6.9c1.2,2.9,0.8,6.4,1.2,10.1c-5.2-2.7-7.3-6.9-9.3-11.3c-2.2-4.9-4.1-10-7-14.5
	c-2.5-4-6-7.5-9.2-11.2c-2.2-2.5-4.5-4.9-7.1-7.8c-1.8,4.8-3.4,9.1-5,13.5c-2.3,6-4.4,12.1-6.9,18.1c-1.2,2.8-1.1,6,0.4,8.7
	c5.4,11.5,10.4,23.1,15.7,34.6c1,2.1,1.8,4.4,3.3,6.1c5.3,6,8,13.3,10.2,20.9c0.1,0.4,0.3,0.8,0.5,1.2c0.2,0.4,0.5,0.7,1.1,1.6
	c-1.2-6.6,2.2-11.3,4.7-16c5.5-10.2,7.7-21.1,8.3-32.6c0.2-3,1.2-5.9-0.1-8.9c-0.2-0.7,0.1-1.4,0.8-1.6c0.5-0.2,1.1,0,1.4,0.4
	c1.2,1.3,2.3,2.7,3.2,4.2c1.4,2.5,2.5,5.2,3.3,7.9c1.2,4.6,1.5,9.4,2.9,13.9c1.8,5.7,1.6,11.8-0.5,17.4c-1.3,3.9-1.4,8.2-2.4,12.2
	c-1.5,6-3.2,12-5,18c-0.5,1.3-1.2,2.5-1.9,3.6c-1.6,3.2-3,6.4-4.7,9.5c-0.7,1-1.5,2-2.4,2.9c-0.4-1.3-1.4-2.6-1.2-3.8
	c0.6-2.6,1.4-5.1,2.4-7.5c3.2-8.4,6.5-16.7,9.6-25.1c0.6-1.8,0.8-3.7,0.8-5.6c0.3-7.3,0.9-14.5,0.6-21.8c-0.1-3.8-1.8-7.5-3.2-11.1
	c-0.9,5.3-1.5,10.8-2.9,15.9c-1.4,5.4-3.4,10.6-5.9,15.6c-2.4,4.7-2,11-7.4,14.1c0.7,3.4,1.9,6.9,2.2,10.5s-0.3,7.2-0.5,9.8
	c-2.7-2.2-5.8-4.7-8.7-7.3c-0.9-0.9-1.6-2-1.9-3.2c-2.7-7.8-5.4-15.5-7.8-23.3c-1-3.5-0.5-7.6-2-10.8c-3.4-7.7-7.5-15.1-11.5-22.5
	c-2.1-3.9-4.5-7.6-7-12c-1.5,4.7-3,9-4.2,13.3c-3.7,13.8-6.5,27.8-6,42.3c0.1,1.3,0.5,2.6,1.2,3.8c5.8,8.6,11.8,17.1,17.7,25.7
	c3.6,5.2,7.4,10.2,10.6,15.6c4.1,6.8,7.8,13.9,11,21.2c5.8,13.8,10.8,27.9,16.6,41.7c3.7,8.7,8.3,16.9,12.3,25.4
	c2.1,4.4,3.9,9,6.1,14.3v-4.2l0.6-0.3c0.6,0.7,1.1,1.4,1.6,2.2c5,10.6,9.7,21.4,15.1,31.8c2,3.9,5.6,7,8.7,10.3
	c7.1,7.5,14.2,15,21.5,22.2c3.8,3.8,8.1,7,12,10.6c4.1,3.8,8,7.7,12.1,11.6c3.2,3,6.6,5.9,9.8,9c4.8,4.5,9.7,9,14.5,13.6
	c3.8,3.6,7.8,7.1,11.3,11c5.8,6.2,11.3,12.7,16.9,19c4.1,4.6,8.4,9,12.5,13.7s7.6,9.8,11.7,14.4c7.6,8.4,15.6,16.6,23.3,24.9
	c4.8,5.2,9.4,10.8,14.1,16.1c5.9,6.7,11.8,13.3,19,18.6c5,3.6,9.2,8.2,12.2,13.6l-0.6,0.9c-4.3-2.8-9.1-5.2-12.8-8.6
	c-14.9-13.8-29.7-27.9-44.3-42.2c-8-7.9-15.6-16.2-23.1-24.6c-6.5-7.2-12.5-14.8-18.8-22.1c-5.4-6.2-10.7-12.4-16.5-18.3
	c-6.6-6.8-13.6-13.1-20.4-19.7c-6.1-5.8-12.1-11.7-18.2-17.5c-5.9-5.7-12.1-11.1-17.9-16.9c-6.5-6.5-12.8-13.2-19.1-19.9
	c-6.9-7.3-13.7-14.6-20.5-22c-5-5.4-9.8-11.1-14.8-16.4c-6.4-6.8-13-13.5-19.5-20.2c-7.9-8.1-15.6-16.3-23.7-24.2
	c-6-5.9-12.4-11.4-18.8-17c-4.5-3.9-9.2-7.3-13.8-11.1c-3.5-2.9-7.7-2.3-11.5-1.4s-7.2,2.8-11,3.9c-1.8,0.5-3.7,0.7-5.6,0.4
	c-6.8-0.8-14.9,5.7-15.5,13.3c0.1,1,0.7,1.8,1.5,2.3c4.8,3.2,9.6,6.2,13,11c1,1.1,2.3,1.9,3.8,2c9.8,0.7,15.6,7.3,21.5,13.9
	c3,3.4,6.2,6.6,9.5,9.7c0.6,0.5,2.6,0.2,3.6-0.4c2.8-1.8,5.7-3.5,8.9-1.9c3.9,2,6.9,2.5,10-1.4c1.2-1.5,3.4-0.3,4.5,1.4
	c2.2,3.3,1.7,8.8-1.3,11.4c-1.7,1.4-1.5,2.7-1,4.5c1.9,6.8,6.8,10.7,13.2,12.5c4.2,1.2,7,2.9,9.2,7.1c3.2,6.1,7.2,11.7,11.9,16.8
	c4.2,4.5,9.8,7.7,14.4,11.9c2.4,2.2,4.3,4.9,5.6,7.9c2.8,6.5,6.1,12.6,11.7,17.1c0.9,0.9,2,1.5,3.2,2c5.6,1.6,8.8,5.9,12,10.5
	c-0.5-0.3-1-0.6-1.5-1c-3.6-3.4-7.7-6.2-12.1-8.5c-7.1-3.6-11.1-10.4-13.7-17.7c-1.6-4.5-4.5-8.4-8.4-11.2
	c-4.3-3.3-9.1-6.1-12.6-10.1c-4.7-5.4-8.5-11.6-12.3-17.7c-1.5-2.5-2.8-4.2-5.9-5c-7.2-1.9-13.1-5.8-15.8-13.3
	c-0.9-2.6-1.2-4.8,1.3-6.8s2.6-5.6,1.3-8.5c-1-2.3-2.4-2.5-4.1-0.9c-0.5,0.5-1,1.1-1.5,1.6c-1.6,1.8-3.3,2.1-5.3,0.5
	c-3.1-2.5-6.4-2.7-9.6-0.4c-3,2.1-5.7,2.4-8.4-0.5c-3.9-4.2-8-8.2-11.8-12.4c-4.2-4.7-9.1-8.2-15.6-8.9c-3.2-0.4-5.4-2.3-7.6-4.7
	c-3.1-3.4-6.9-6.1-10.7-9.3c-0.8,1.4-1.3,2.3-1.8,3.3c-2.4-0.4-3.7-1.5-3.8-4.1c-0.2-1.2-0.8-2.2-1.8-2.9
	c-5.7-3.7-11.7-6.8-17.2-10.8c-5.8-4.2-9.8-10.5-11.2-17.5c-0.8-3.8,0.6-6.6,4.1-7.9c5.8-2.3,12.3-2.5,18.2-0.5c-0.7,0-1.4,0-2.1,0
	c-0.7-0.1-1.5-0.1-2.2-0.1c-4.2,0.3-8.3,0.7-12.4,1.4c-4,0.8-5.3,3.4-4.4,7.4c1.6,7,5.8,13.1,11.8,17.1c5.4,3.7,11.2,6.8,17,10.2
	c1.8-4.8,3.4-9.5,8-12.4c4.3-2.8,8.7-4.8,14-3.8c1,0.1,2-0.1,2.9-0.6c4.2-1.7,8.3-3.4,13-5.3c-5.2-3.2-10.5-6.5-15.9-9.7
	c-5.5-3.3-11.1-6.6-16.7-9.7c-5.5-3.1-11.2-6-16.5-9.3c-5.2-3.3-11.4-4.8-16.2-8.7l0.6-1.4c2.4,0.5,4.7,1.2,7,2.1
	c6.2,2.7,12.3,5.6,18.5,8.4c0.5,0.2,1,0.3,1.5,0.4l-0.1-0.1c5.2,3,10.4,6.1,15.6,9.1c3.3,1.9,6.6,3.7,10.1,5.7
	c4.8-5.1,11.2-4.4,17.5-4.1c3,0.1,6,0.5,8.9,0.7c1.7-9,4.1-11.5,11.6-12.3c6.4-0.7,11.8,0.8,13,3.9c-5.1-2.7-11.2-3.3-16.7-1.5
	c-4.6,1.5-6.4,5-5.3,10.2c5.7-1.1,11.4-1.8,16.2-5.4c1.4-1,3.1-2,4.5-0.2s0.2,3.5-1.5,4.4c-2.8,1.4-5.7,2.5-8.7,3.5
	c-2.7,0.9-5.5,1.4-9.2,2.3c3.1,3.1,5.7,5.8,8.4,8.5c0.5,0.4,1.1,0.5,1.8,0.4c6-1.4,12.1-2.8,18.1-4.4c1.5-0.4,2.7-1.6,4-2.5
	c1.5-1.1,3.1-1.1,3.7,0.5c0.4,1-0.2,3.3-0.9,3.7c-5.1,2.2-10.3,4-15.6,5.8c-1.6,0.5-3.3,0.5-6,1c8.4,7.5,16.1,14.5,24,21.4
	c2.4,2.1,5,4,7.6,5.7c2.5,1.7,4,1.1,4.7-1.7c1.2-5.3,1.2-10.4-1.8-15.6c-4.5-7.7-7.9-16.1-12-24c-3.1-6-6.3-11.9-9.8-17.7
	c-4.5-7.3-9.2-14.4-13.9-21.4c-2.7-3.9-5.6-7.7-8.8-11.2c0.9,5.3,1.8,10.7,2.7,16.1c0.2,0.7,0.4,1.4,0.4,2.2
	c-0.2,1.1-0.7,2.1-1.4,2.8c-1,0.2-2-0.1-2.7-0.8c-1.5-1.8-2.7-3.8-3.7-5.9c-4.4-10.3-8.8-20.6-9.5-32c-0.2-1.4-0.8-2.8-1.8-3.8
	c-9.1-11.5-20.1-21.5-27.4-34.3c-4.2-7.2-7.6-14.8-11.8-22c-5-8.8-10.4-17.5-15.8-26.2c-1.8-2.8-3.8-5.6-5.9-8.2
	c-2-2.7-4.2-5.2-6.6-7.6c-5.4-5.2-10.8-10.2-16.4-15.2c-6.4-5.8-12.7-11.5-19.3-16.8c-9.7-7.8-20.5-14.2-32-19
	c-6.8-2.9-12.8-7.6-19.1-11.7c-4-2.6-7.6-5.8-11.8-7.9c-5.8-2.9-12-5.2-18-7.5c-9.4-3.7-19.3-4.1-29.2-4.7c-9-0.6-17.9-1.3-26.9-2
	c-2-0.2-4-0.5-6.3-0.8c-0.9,7.1-2.1,13.9-2.6,20.8c-0.7,9.7-1,19.4-1.3,29.1c0,3.5,0.2,7,0.5,10.4c0.1,0.6,0.4,1.2,0.9,1.5
	c2.9,1.7,5.9,3.3,9.3,5.1c1.8-3.6,4.1-6.8,5.1-10.3c3.3-11.3,10.5-18.2,21.7-21.5c1.9-0.6,3.4-2.4,5.2-3.4c1.4-0.8,3-1.3,4.6-1.5
	c0.6-0.1,1.4,1.3,2,2c-0.7,0.5-1.5,1-2.2,1.4c-0.5,0.3-1.2,0.1-1.7,0.4c-4.2,2.4-8.2,5-12.5,7.3c-5.9,3-10.3,8.3-12.3,14.6
	c-1.4,4.5-4.1,8.5-6.3,13c2.4,1.2,5,2.7,7.7,3.9c11.4,5.1,21.2,13.1,28.5,23.2c5.7,7.8,13.7,12.8,22.1,17.2c2,1.1,4.9,0.7,7.3,0.8
	c0.9,0,1.9-0.5,2.8-0.6c4.6-0.5,8.5-1.2,7.9-7.5c-0.2-2.6,2.6-4.1,5.7-3.3c8.8,2,12.3,7,9,16.8c-0.4,0.7-0.7,1.5-1,2.3
	c-0.3,1.8-0.1,3.3,2.3,3.1c1.8-0.1,3.6-0.3,5.3-0.7c3.9-1.1,4.6-2.6,4-6.5c-0.3-2-0.5-4-0.6-6c-0.7-7.2-3.5-13-10.4-16.2
	c-1.8-0.8-3.7-1.9-2.4-4.9c-1.6,0-3.1,0.1-4.5,0c-0.6-0.1-1.1-0.4-1.6-0.7c0.3-0.6,0.5-1.4,0.9-1.8c5.4-4.4,14.4-4.3,19.8,0.2
	s8.7,10.3,11.5,16.6c1.4,3.2,2.6,6.5,3.6,9.8c2.7,9.5-1.2,18.2-10.2,22.4c-3.4,1.6-6.9,3-10.6,5c1.1,0.2,2.1,0.3,3.2,0.2
	c2.2-0.4,4.4-0.9,6.5-1.5c4-1.3,8.4,0.2,10.6,3.8c1.8,2.5,3.7,3.3,6.8,2.7c5.9-1.1,11.6,0.3,16.9,3c3.2,1.7,4,4.1,2.4,7.4
	c-0.4,0.8-0.8,1.5-1.3,2.2c-2.7,3.8-2.5,7.6-0.1,11.6s5.7,6,10.2,5.6c7.9-0.7,14.8,1.7,21.1,6.2c1.4,1,2.9,1.8,4.3,2.8
	c5.3,3.6,10.7,6,17.5,3.4c2.8-1.1,6.2-0.9,9.4-1c2.7-0.1,4.5,1.4,5.3,4.1c0.3,1.1,0.8,2.2,1.5,3.1c5.8,6.8,11.6,13.6,17.4,20.4
	l-0.9,1c-1.1-0.6-2-1.3-2.9-2.2c-5-6-9.8-12.2-14.7-18.3c-0.8-0.9-1.3-1.9-1.6-3c-0.6-2.9-2.5-3.9-5.1-3.6c-3.9,0.3-7.8,1-11.6,1.9
	c-3.4,1-7,0.5-10-1.4c-4.3-2.6-8.8-5.1-13-7.8c-4.7-3.1-10.4-4.4-16-3.9c-5.8,0.5-9.9-2.1-12.7-7.1c-2.3-3.7-2.3-8.4,0.2-12
	c3.6-6,3-7.1-3.5-9.5c-4.4-1.7-9.1-2.2-13.7-1.3c-2.3,0.6-4.8-0.4-6-2.5c-2.8-4.4-6.8-5.3-11.6-3.9c-2,0.7-4.1,1.1-6.2,1.2
	c-1.2,0-2.5-1.3-3.7-2.1c0.9-0.9,1.8-1.6,2.8-2.2c3.6-1.7,7.5-3,10.9-5c6.7-4,9.2-10.5,7.4-18.3c-2.1-9.3-6.5-17.9-12.9-24.9
	c-5.5-6-12.7-6.8-19.3-2.1l7.6,0.5l-3.3,3.1c0.8,0.7,1.7,1.4,2.7,2c6.4,3.2,9.3,8.7,10.5,15.3c0.5,2.9,0.6,5.9,1.1,8.8
	c0.5,3.2-0.8,5.2-3.7,6.2c-2.2,0.8-4.5,1.2-6.8,1.3c-4.2,0.1-5.3-1.8-3.9-5.7c0.7-1.9,1.2-3.8,1.6-5.8c0.8-5.1-2.7-10-7.8-10.8
	c-0.4-0.1-0.8-0.1-1.2-0.1c-2.7-0.1-3.9,1-4.1,3.7c-0.5,5.6-0.7,5-6.2,6.8c-7.9,2.7-14.3,0.6-20.7-4c-6.2-4.4-12.2-8.9-16.5-15.1
	c-8.5-12-20.8-18.7-33.7-24.6c-0.8-0.2-1.7-0.1-2.4,0.3c-1,0.6-1.7,1.7-2.6,2.3c-2,1.3-4,2.3-6.8,4c2.9,13.7,5.9,28.2,8.9,42.7
	l-0.4,0.3c-1.3-2.2-2.5-4.5-3.5-6.9c-1.4-4-2.6-8-3.6-12.1c-2-9-3.9-17.9-5.7-26.9c-0.8-4-1.5-8.1-2.2-12.3l-10.9-0.8
	c1.8,7.3,3.5,14.1,5.2,21.1h8l0.1,0.8l-7,1.6l5.9,16.1l-0.5,0.2c-2.6-5.4-5.2-10.8-7.9-16.2c0-0.1-0.6,0.1-1.4,0.2
	c5.7,22.1,13,43,33.7,56.8l-5.8-9.9l0.6-0.4l23.2,19c-2.9-0.8-5.6-1.2-8-2.3c-5.9-2.8-11.6-5.8-17.3-8.9c-5.9-3-10.9-7.4-14.5-12.9
	c-2.5-3.9-6.4-5.8-10.1-7.9c-6-3.5-12.1-6.6-18.1-9.9c-1.2-0.9-2.3-1.9-3.3-3l0.7-1.1l24.6,12.1c-1.2-3.4-2.1-6.4-3.3-9.3
	c-4.8-11.5-7.8-23.7-9.1-36.1c-0.3-2.9-1-5.8-1.1-8.8c-0.1-2.2-1.2-2.8-3-3.4c-12.3-3.8-22.6-12.4-28.5-23.8
	c-2.2-4.1-3.6-8.4-2.9-13.1c0.3-1.3,1.1-2.5,2.2-3.3c-1.8,2.7-1.4,5.7-0.9,8.5c2.5,13.4,18.8,28.9,32.4,31
	c-0.5-19.5-0.9-39.1-1.4-58.5c-4.9,0-10-0.1-15.2,0c-7.8,0.2-15.7,0.8-23.5,0.8s-15.8-0.8-23.6-0.7c-9.7,0.1-19.4,1-28.7,4.3
	c-7.2,2.6-14.3,5.6-21.4,8.5c-0.7,0.3-1.5,0.5-2.3,0.7l-0.6-0.9c1.1-1.1,2.3-2.2,3.6-3.2c6.9-4.9,15-7.2,23-9.7
	c4-1.2,8-2.3,12.6-3.6c-7.7-3.6-14.8-7.2-22.8-8.2c-5.8-0.8-11.7-1.3-17.6-1.5c-4.8,0-8.3,3-10.8,6.8c-4.9,7.5-12.3,11.3-20.4,14.2
	c-12.6,4.5-23.4,11.2-30.7,22.9c-4.1,6.5-8.9,12.5-13.5,18.6c-0.9,1.2-2.5,1.8-3.8,2.7l-0.7-0.7c1.6-3.1,3.2-6.2,4.8-9.3
	c4.6-9.2,10-18,17.2-25.5c5.2-5.2,11.6-9.2,18.6-11.7c8.2-3.1,16.3-6.5,22.9-12.5c1.7-1.6,3.3-3.3,4.8-5.1c0.4-0.8,0.5-1.8,0.2-2.7
	c-1.7-7.5-0.1-14.5,3.1-21.2c4.4-8.9,11-16.6,19.1-22.3c6.6-4.7,13.9-5,21.3-0.7c-11.4,0.7-20.2,5.8-27.6,14.3s-12.3,17.5-12.4,28.5
	c4.9-0.5,9.3-1.2,13.7-1.3c8.5-0.3,16.1,2.9,23.7,6.2c5.7,2.5,11.4,5.1,17.1,7.5c1.1,0.5,2.5,0.2,3.7,0.2c10,0,20.1,0,30.1-0.2
	c12.1-0.2,24.2-0.7,36.5-1.1c0.8-9.3-0.1-18.7-2.6-27.7c-0.2-0.9-0.5-1.8-0.8-2.7c-0.8-2.6-2.1-4.7-4.9-5.8
	c-7.7-3.2-15.3-6.6-23-9.8c-1.4-0.4-2.9-0.6-4.4-0.7l-0.5,1c3.8,3,7.8,6,11.5,9.2c1.4,1.4,2.6,2.8,3.7,4.4c-1.9,0.4-3.7,1.2-5.5,1.2
	c-8.8-0.3-17.5-0.7-26.2-1.5c-5.1-0.5-10.2-2-14.3-5.5c-3.3-2.8-2.9-5.8,1.1-7.6c1.8-0.8,3.7-1.4,5.6-2.1c0.7-5.1,0.7-5.1,3.5-6.2
	c-0.3,1.9-0.6,3.6-1,5.9c3-0.5,5.6-1,8.2-1.5c2.2-0.5,2.7-2.1,1.6-3.9c-2.4-3.9-4.7-7.8-7.4-11.5c-0.6-0.8-2.4-0.7-4.2-1.2
	c0.2,2.1,0.4,3.6,0.6,5.5l-3.1-1.6l-3.5,12.1c-0.7-0.7-1.6-1.6-2.6-2.5c-6.2,1.6-10.4,0.6-13.4-4l13.2,2.5l3.3-12.3
	c-7.8-1.8-15.2-5.2-21.5-10.1c-5.9-4.5-10.9-10-14.8-16.2c-5.2-8.5-8.1-17.6-9.8-27.1c-0.9-5.1-0.9-10.3-1.3-15.4
	c-0.7-9.7,2.6-18.9,4.1-28.3c0.6-3.8,1.1-7.7,1.9-12.3c-1.8,1.5-3.2,2.4-4.2,3.7c-6.1,8.1-12,16.4-18,24.6c-0.9,1.3-2,2.4-3,3.7
	l-0.9-0.7l15.1-23.9l-0.4-0.6c-4,3.5-8,7.1-12.1,10.5c-6.5,5.3-9.2,12.4-9.6,20.5c-0.1,1.1-0.5,2.1-1.3,2.9c-0.6-2-1.2-4.1-1.9-6.6
	c-3.7,5.9-6.4,12.2-8.1,18.9c-1.5,5.4-2.9,10.8-4.4,16.2c-0.5,1.1-1.1,2.1-1.8,3.1c-4.1-10-4.8-20-3.9-30c0.7-9.6,2.3-19.1,4.6-28.4
	c3.7-14,11.9-25.5,22.4-35.5c9-8.5,18.3-16.7,29-23c4.1-2.4,8.4-4.5,12.7-6.5c12.6-5.7,19.8-15.5,22.8-28.9c1.8-8,4.2-15.9,6.4-24
	c-1.8,0.6-4.1,1.7-5.7-1c0.5-0.3,0.8-0.7,1.1-0.7c5.1,0.5,7.3-2.8,9.8-6.5c3.4-5.1,8.8-7.7,14.6-9.4c2.2-0.6,4.6-0.8,6.7-1.6
	c1.4-0.6,2.5-1.6,3.1-3c1.8-5.7,3.3-11.6,4.7-17.4c1.7-6.9,3.6-13.7,4.7-20.6c1.5-9.7,2.2-19.6,0.8-29.4c-1-7.3-2-14.6-3.7-21.8
	c-1.5-6.2-4-12.2-6.4-19.2c1.4,0.9,2.9,1.2,3.3,2.1c2.6,5.1,5.4,10.3,7.4,15.7c5,14,7.2,28.7,6.5,43.5c-0.5,13-3,25.8-8.8,37.6
	c-1.9,3.9-4,7.6-6.5,12l10.9-1.6c0.1,2.8-1.9,3.6-3.9,4.3c-1.3,0.4-2.7,0.6-4.1,0.6c-3.4-0.1-6.5,0.5-8.4,3.8
	c-0.5,0.5-1.1,0.7-1.8,0.6c0.5-1,0.9-1.9,1.6-3.3l-3,0.7c4.6,13.1-0.4,24.4-5.6,35.5c-6.2,13.1-16.1,23.4-26.9,32.9
	c-9.7,8.5-20.2,16.1-29.5,25c-10.9,10.4-21.8,21-29,34.6c-0.7,1.4-1.3,2.8-1.8,3.7c6.4-4.7,13.1-9.8,19.8-14.7
	c3.8-2.8,7.7-5.4,11.8-7.8c1.1-0.7,2.9-0.2,4.2-0.7c4.4-1.7,8.7-3.6,13-5.5c7.9-3.5,15.8-7.1,23.6-10.8c2.6-1.2,5-2.8,8-3.7
	c-0.5,0.7-1.1,1.3-1.6,1.9c-6.1,6.1-13.4,11-21.4,14.4c-7.6,3.4-15.2,6.8-22.6,10.6c-1.7,0.9-2.7,3.2-4.1,4.9l0.8,0.8
	c1.1-0.7,2.2-1.3,3.4-1.9c0.9-0.2,1.9-0.3,2.8-0.2c-0.3,0.8-0.7,1.6-1.2,2.3c-1,1.2-2.1,2.3-3.2,3.5c-4.8,5.2-4,11.7-3.4,17.8
	c0.9,9,2.9,18,7.4,26c6.4,11.4,13.2,22.6,23.8,30.8c0.3,0.3,0.5,0.7,0.7,1.1c-13-1.4-30.3-23.5-35.8-45.2
	c-0.9,31.2,11.8,55.7,42.5,68.1c0.4-2.3,0.8-4.7,1.3-7c0.5-2.1,1.1-4.2,1.7-6.6c1-0.4,1.9-0.9,2.7-1.5c3.4-3,6.5-6.3,11.9-4.9
	c1.4,0.4,3.3-1,5.1-1.7c-1-2.5-2.1-5-3.4-7.4c-7.3-12.3-10-26.1-10.9-40c-1.3-21.2,0.9-42.1,8-62.2c4.4-12.3,10.8-23.6,17.6-34.6
	c8.9-14.3,16-29.6,21.2-45.6c4.2-13.1,6.8-26.6,8.6-40.2c1-7.6,1.2-15.4,1.8-23.5c-5,2.5-8.7,6.6-14,7.7c3.7-3.6,7-7.4,10.9-10.5
	c4.6-3.6,2.8-9.3,4.6-13.8l0.9,0.1c0.2,1.6,0.4,3.2,0.8,6c2.7-3.7,5.1-6.6,7-9.8s3.3-6.7,5.8-9.9c0,2.1,0.4,4.3-0.1,6.2
	c-0.8,2.8-2.4,5.4-3.3,8.2c-0.6,1.8-1.2,3.9-0.8,5.6c1.6,7,6.3,12.8,12.8,15.8c8.5,3.9,17.5,6.7,26.4,10.1c0.2-1.9,0.5-3.8,0.9-5.6
	c0.2-0.6,1.3-1,2-1.5c0.4,0.7,0.6,1.4,0.8,2.1c0,0.8-0.1,1.7-0.2,2.5c-0.4,2.8,0.5,4.8,3.1,6.5c2.3,1.6,4.1,4.2,5.4,6.8
	c2.6,5,4.9,10.1,6.9,15.3c1.3,3.8,2,7.7,2.3,11.7c0.7,7.2,0.8,14.4,1.4,21.5c0.2,2,0.9,3.9,2.2,5.4c4.3,4.8,8.5,9.8,13.5,13.8
	c4,3.2,8.9,5.3,13.6,7.3c7.5,3.1,15.2,5.7,21.2,7.9c-8.1,0.8-16.4-4.1-25-8l-0.4,0.7c3.6,2.5,7.3,4.9,10.9,7.4
	c5.6,4.1,11.5,7.9,16.7,12.5c6,5.3,11.5,11.1,16.4,17.4c4.1,5.5,7.3,11.6,9.7,18c6.1,16.3,10.4,33.3,12.9,50.5
	c2.3,15.9,0.5,31.3-3.8,46.6c-2.8,9.6-5.6,19.2-8.5,28.8c-1,3.2-2,6.4-3,9.6c-0.5,1.6-0.1,2.5,1.4,3.2c6,3,12.4,5.6,17.9,9.3
	c3.7,2.5,6,1.4,9-0.3c13.6-7.6,13.6-7.5,16.1-22.8c0.3-2.1-0.2-4.3-0.3-6.5c-0.2-3.9-1-7.9-0.2-11.7c2.5-12.8-0.8-24.5-5.5-36.1
	c-4.2-10.3-8.4-20.6-12.6-30.8c-0.1-0.3-0.1-0.6,0-0.9c7.9,14.5,13.5,30,19.4,45.6c0-1.9,0-3.8,0-5.6s-0.2-4-0.4-6
	c-1.3-15.8-6.5-30.5-12.7-45c-5.1-12-10.5-23.8-12.7-36.8c-1.3-7.5-1.7-14.9,1.8-21.3c-0.5,3.9-1.4,8.4-1.4,12.9
	c-0.1,10.5,3.6,20.1,7.4,29.7c4.2,10.7,8.8,21.3,12.9,32.1c4.5,11.9,6.2,24.3,6.6,37c0.1,5.4,0.6,10.7,1.3,16c0.9,5.6,0.9,11.4,0,17
	c-0.8,4.7-0.5,9.6-0.9,14.4c-0.2,2-0.6,3.9-1.2,5.8c-1.5,4.1-3.3,8.2-4.7,12.3c-1.5,4.5-3.8,8.1-9.4,8.4
	c13.2,10.5,26.7,19.7,41.9,26.9c2.3-5.6,2.5-11.2,1.8-16.9c-1-8.7-2.8-17.4-3.3-26.1c-0.4-5.3,0.6-10.7,1.5-16
	c1.1-6.3,2.2-12.4-0.1-18.7c-0.5-1.4,0.9-3.5,0.5-5c-1.8-7.6-4-15.1-5.9-22.6c-4.1-16.1-13.3-29.8-21-44.3
	c-6.5-12.4-14.3-24.3-17.9-38.1c-2.6-10.3-2.6-21.2,0.2-31.5c-1.8-0.8-3.8-1.6-5.8-2.5c-7-3.1-14.3-5.8-21.1-9.4
	c-7.8-4.1-15.6-8.4-22.6-13.7c-6-4.6-11-10.6-16.2-16.2c-5.4-6.1-9.5-13.2-12-20.9c-2.5-7.7-4.7-15.6-6.4-23.5
	c-1.9-8.1-3.4-16.3-5-24.5c-0.2-0.6-0.5-1.1-1-1.5c-5.3-4.7-10.6-9.3-15.7-13.7c-3.6,2.5-6.9,4.8-10.2,7c-0.6,0.4-1.3,0.7-2.1,0.7
	c-1.2-0.1-3-0.3-3.3-1c-0.3-1.2-0.1-2.6,0.6-3.6c3.1-4.2,6.4-8.1,9.6-12.3c1-1.4,1.7-2.9,2.1-4.6c-3,2-5.9,4.1-8.9,6.1
	c-0.6,0.5-1.3,0.8-2,0.9c-1.1-0.2-2.1-0.5-3.1-0.9c0.5-0.9,1.1-1.7,1.8-2.5c5.5-4.5,11.2-8.9,16.7-13.5c0.7-0.6,1-1.8,1.4-2.8
	c0.9-2,2-3.9,2.8-6c0.3-1.1,0.4-2.2,0.2-3.3c-0.1-1.3-0.5-2.6-0.5-3.9c4.6,6.8,8.6,22.8-3.7,37c5.1,2.1,8.7,5.8,11.6,10.4
	c0.7,1.1,2.2,1.5,3,2.6c1.9,2.6,3.6,5.3,5.1,8.1c0.6,1.4,1.7,2.5,3,3.2c3.7,2,7.1,4.5,10.2,7.3c5.7,5.7,10.4,12.3,16.3,17.7
	c11.8,11,25.5,19.6,39.1,28.3c12,7.6,23.6,15.7,35.7,23.2c7.3,4.5,15,8.2,22.5,12.7c-1-0.3-1.9-0.6-2.9-1
	c-10.6-5.8-21.4-11.2-31.6-17.5c-14.9-9.3-29.3-19.3-43.9-29.2c-4.6-3.1-9-6.7-13.9-9.7c1.6,1.8,3.2,3.6,4.9,5.3
	c9.9,10.1,19.7,20.4,29.9,30.3c17,16.5,38.2,28,61.2,33.2c13.8,3.3,27.8,5.5,41.7,8.3c0.5,0.1,0.9,0.4,1.1,0.9
	c-7.9-1.1-15.9-2-23.8-3.4c-15.4-2.9-30.5-6.9-45-13.2c-15.9-7-29.6-17.2-41.8-29.4c-10.3-10.3-20.6-20.8-30.1-31.8
	c-7.6-8.8-15.9-16.8-25-24.1c-0.8-0.7-1.5-1.5-2.1-2.3l9.4,6.7c-3.5-4.6-7.4-8.9-13.3-10.5L441,420.9z M596.8,597.1
	c-0.2-3.3-0.2-6.6-0.5-9.9c-0.9-9.2-10.3-18.3-20.2-19.1c-13.3-1-25,2.3-32.9,14.1c-1.3,2-1.6,3.3-0.4,5.3c2.6,4.2,5,8.6,7.5,12.9
	c0.7,1.2,1.5,2.3,2.6,3.9c1.5-15.9,8.6-24.1,22.8-26.9c6.2-1.3,13.5,1.6,16.3,6.6c2.4,4.1,2.8,9.1,1.1,13.5
	c-2.7,7.6-8,14.3-9.5,22.4c-0.2,0.6-0.6,1.1-1,1.6c-2.8,3.7-8.4,4.8-12,2.2c1.2-0.9,2.4-1.7,3.8-2.4c5-1.9,7-5.7,7.8-10.6
	c0.4-2.6,1.5-5,3-7.1c2.9-3.5,4.4-8,4.2-12.6c-0.3-5.2-2.8-8.2-7.8-9.4c-6.3-1.6-17.1,2.4-20.4,8.2c-2.3,4-3.6,8.5-5.4,12.8
	c-0.5,1.2-1.1,2.4-1.2,2.8c3.4,9,6.6,17.6,10,26.1c1,2.6,0.9,6,4.2,7.5c1.8-1.7,3.4-3.3,5.1-4.8c3.2-2.9,6.8-4.2,11-2.3
	c5.5,2.5,7.2,5,6.9,10.8c-0.2,2.9,0.2,5.4,2.8,7.3c0.7,0.5,0.7,2,1,3c-1.2,0.2-2.6,0.8-3.5,0.4c-3.1-1.3-4.6-4-4.8-7.3
	c-0.2-2,0-4-0.2-6c-0.4-3.9-5.2-6.6-8.5-4.7c-2.7,1.8-5.3,3.7-7.8,5.8c0.4,0.5,0.7,0.9,1.2,1.3c12.9,10.6,25.7,21.4,38.8,31.7
	c16,12.5,29.5,27.1,38.5,45.3c8.9,18.1,13.8,37.9,14.4,58.1c0.5,16.6,0.6,33.2-3.4,49.6c-0.1,1.1,0.3,2.2,1,3.1
	c7.4,10.1,15,20.2,22.6,30.4c7.1-5.7,16-7.7,25.2-9.2c10.3-1.7,19.6-5.7,25.7-14.9c1.5-2,2.9-4.2,4.1-6.4c1.1-2.1,2.3-4.4,2.3-6.7
	c0.2-5.5,0.1-11-0.4-16.5c-0.4-4,1.3-8.2-0.8-12c2.2-5.3,3.7-11,8.8-14.3c1.8-1.2,2.7-2.3,1.6-4.5c-0.6-1.4-0.8-2.9-0.5-4.3
	c0.9-5.5-0.5-10.7-2.3-15.8c-0.4-1.5-0.3-3,0.4-4.4c1.9-3.5,4.5-6.7,6.2-10.3c2-4.4,4.2-9,4.7-13.7s-1-9.6-1.6-14.3
	c0-0.2-0.3-0.5-0.4-0.7c-0.5-0.9-1.5-1.9-1.5-2.8c0.1-4.9,0.7-9.7,0.6-14.6c-0.3-14.1-0.8-28.1-1.2-42.2c0-1.6,0.2-3.2,0.3-5.3
	c1,0.6,1.5,0.7,1.5,0.9c1.3,11.2,2.6,22.3,4,33.6c4.7-2.1,9.4,0,14.1,1.9c3.7-2.8,3.7-6.8,4-10.7c0.4,1.9,0.7,3.8,0.7,5.7
	c0,4.8-3.6,6.9-8.1,5.5c-2.9-0.8-5.9-1.3-8.9-1.3c-1.2,0.3-2.2,1.2-2.6,2.4c-0.3,4.7-0.1,9.4-0.1,14.4c2.4-1.9,4.9-3.7,7.5-5.3
	c4-2.3,6-2,8.6,0.7l-8.2,3.8c2.1,0.4,4.2,0.4,6.2-0.1c5-1,11.2-7.1,10.8-11.2c-0.4-5.1-1.5-10.2-3.1-15.1c-2.4-6.5-5.8-12.7-8.7-19
	c-3.5-7.5-7.1-15-10.6-22.6c-0.7-1.5-1.1-3.3-2.2-4.5c-4.5-5.1-9.2-10-13.9-15l-5.7,2.7l-0.7-0.9c2.2-2.2,4-4.8,6.5-6.5
	c6-3.9,12.4-7.2,18.4-11.1c2.8-1.8,4.9-4.5,7.4-6.8c-7.3-0.4-13.2,2.1-18.9,5.6c-2.3,1.4-4.7,2.7-7,4l-0.9-1.5
	c4.3-3.1,8.5-6.4,12.9-9.3c3.4-2.4,7.7-2.9,11.6-1.3c3.4,1.4,4.4-0.3,5.6-3c-2.1-0.3-4-0.2-5.6-0.8c-1.3-0.5-2.4-1.6-3-2.8
	c-0.7-1.9-0.6-4.1-0.8-6.8l-3.1,2.2c0.6,2.3,1.2,4.4,2,7.1c-3.2,1.6-6.5,3.4-10,5c-2.2,1-2.7,0-2.2-2.3l-7.1,3.1
	c0.3-3.8,3-3.1,4.6-3.6c0.3-1,0.3-2.1,0.9-2.7c4.5-4.6,8.9-9.3,13.7-13.6c3.9-3.5,8.4-6.3,12.6-9.5l2.7-2c0.2,1.2,0.3,2.3,0.5,3.5
	c0.2,0.4,0.5,0.9,0.8,1.3c0.5-2,1.1-3.3,1-4.5c-1-10-2.2-20-3.1-29.9c-2.1-21.6-3.9-43.3-8.8-64.6c-2.3-9.9-5.2-19.5-11.3-27.8
	c-0.8-1.2-1.7-2.4-2.7-3.4c-5-4.3-10.7-4.1-15.9-1c-5.3,3.1-10.2,6.8-14.6,11c-3,3.1-5.2,6.8-6.5,11c-1,3-0.3,6.7,0.1,10.1
	c1.7,14.3-1.1,27.2-10.8,38.2c-4,4.5-3.6,9.1-1.9,14.3c2.8,8.1,5.1,16.4,6.9,24.8c3.3,17.3,1,34.3-5.5,50.6
	c-1.1,2.8-2.7,5.5-4.1,8.3l-0.8-0.3c0.5-3.8,1-7.6,1.6-11.4c1.4-8.7,3.1-17.3,4.1-26c1.3-12.6-0.5-25-4.4-37.1c-1-3.3-2-6.5-3-9.9
	c-2.4,2.6-4.4,5-6.7,7.2c-1.5,1.3-1.4,2.3-0.6,4c2.2,4.9,5.2,9.7,6,14.9c3.3,19.8,2,39.5-2,59c-1.1,5.6-2.6,11.2-3.9,17.1
	c0.9-1.1,1.7-1.9,2.6-2.9c0.2,5.5-12.1,21.9-16.5,22.1c0.8-1.5,1.5-3,2.3-4.3c9.5-15,12.5-31.9,14.1-49.2c0.7-7.8,0.8-15.7,1.9-23.4
	c1.8-12.1-1.2-22.7-8.7-33.1c-0.9,1.2-1.6,2.2-2.4,3.2c-7.6,9.4-12.8,20.6-15.1,32.5c-2,9.6-3,19.5-4.7,29.1
	c-2.8,16.1-3,32.1,2.1,47.7c3.6,11,8.1,21.8,12.3,32.7c4.1,10.8,6.2,22.3,6.1,33.8c-0.1,3-1.2,6-3.2,8.2c-0.6-3.4-1-6.4-1.5-9.3
	c-1.7-9.1-3.3-18.3-5.2-27.3c-1.5-7.2-6.3-12.8-9.8-19c-1.5-2.8-3-5.6-4.3-8.4c-2.6-5.3-5.7-10.4-7.4-16c-2.7-9-5-18.1-6.8-27.3
	c-1.5-7.7-3.4-15.8-2.8-23.5c0.8-11.4,3.5-22.7,5.5-34c0.2-1.2,0.5-2.4,0.7-3.8c-9.2,2.3-9.2,2.3-8.8,11.2c0,1.5,0.2,3,0.3,4.4
	c0.3,3.2,0.8,6.3,1.1,9.5l-0.8,0.2c-1-2.9-2-5.8-3-8.7c-2.6,4.9-4.8,9.7-7.7,14.1c-3.5,5.4-8,9.7-15.1,10.2c-4.3,0.3-8.6,1-12.8,1.2
	c-1,0-2.1-1.3-3.2-2c5.1-1.1,9.5-2,13.9-3.1c3.7-1,7.9-1.4,10.4-4.8c3.9-5.4,7.6-10.9,11.1-16.5c2.3-3.5,2.7-7.9,1.2-11.8
	c-0.4-0.1-0.9-0.1-1.3-0.1c-10.4,2.1-21,2.3-31.5,0.7c-9.4-1.3-18.6-3.6-27.5-6.9c-6.8-2.6-13.5-5.2-20.3-7.7
	c-7.9-2.9-15.8-5.7-23.8-8.5c-3.7-1.3-7.5-2.6-11.3-3.9c-2.6,32.9,2.7,35.3,15.6,59.2c0.4-0.7,0.9-1.2,1.3-1.8
	c8.4-12.6,20.7-16.7,35.1-16c9.8,0.5,16.9,6,22.5,13.7C602.9,582.9,602.5,592.1,596.8,597.1z M409.3,667.7c0.8,0.6,1,0.8,1.1,0.9
	c8.1,2.3,16.2,4.7,24.4,6.9c1,0,1.9-0.4,2.4-1.2c4.5-8.5,9.6-16.7,13.2-25.6c5-12.7,6.5-26.2,7.7-39.8c2-23.1-1.3-45.2-12.5-65.8
	c-0.7-1.6-1.7-3.1-2.9-4.5c-4-3.9-8.3-7.5-12.4-11.4c-8.2-7.9-16.8-15.5-24.3-24.1c-11.3-12.8-17.3-28.4-21.1-44.9
	c-2.4-10.5-6.2-20.2-12.6-28.8c-0.6-0.9-1.1-1.9-1.5-2.9l1-0.8c2,2.3,4,4.6,5.9,6.9c6.9,8.6,9.3,19.1,12.1,29.4
	c3.7,13.7,9.1,26.7,18.5,37.5c6.3,7.2,13.5,13.6,20.4,20.3c2.5,2.4,5.1,4.6,8.1,7.2c-0.9-3.8-1.6-7-2.3-10.1l0.9-0.2
	c0.1,1.2,0.4,2.5,0.8,3.7c2,4,3.8,8.2,6.3,11.9c1.6,2.3,4.5,3.8,6.6,5.8c7.7,7.4,15.6,14.6,22.9,22.4c8.3,8.9,14.8,19.1,20.4,29.9
	c3.7,7.1,7,14.5,6.7,22.5c-0.5,12.9-1.5,25.7-3.2,38.5c-1.3,9.3-4.1,18.5-6.2,27.7c-1,4.3-1.8,8.6-2.8,12.9c0.9-0.7,1.4-1.6,1.7-2.7
	c2.7-8.7,5.5-17.3,8-26c2.3-8.1,4.7-16.2,6.2-24.5c2.2-13.2,1.8-26.6-1.3-39.6c-2.8-12.1-6.4-24.1-9.9-36
	c-3.9-13.4-11.7-24.6-21.9-33.8c-7.7-7-16.4-12.9-24.5-19.4c-7.7-6.1-15.8-11.9-22.7-18.7c-7.1-7.1-12.9-15.4-17-24.6
	c-6.7-15-10.8-30.7-11.2-47.2c0-1.9-0.5-2.9-2.3-3.5c-3.7-1.3-7.4-2.6-11-4c-4.7-1.8-9.7-3.2-14.1-5.6c-7.1-3.8-11.6-9.9-13.6-18.2
	c-4,4.8-8.7,8.5-7.4,15.5c0.5,2.5-0.3,5.3-0.4,7.9c-0.5,13.9-2.4,27.7-5.7,41.1c-4.9,20.6-13.3,39.8-24.2,58c-0.5,0.8-1.2,1.9-1,2.6
	c1.3,4.5-0.4,8.6-1.5,12.7c-0.7,2.8-1.4,5.5-2.1,8.3c-0.7,2.7-1.3,5.5-1.9,8.2c0.5-0.2,0.8-0.5,1-1c5.1-13.8,11.9-26.8,19-39.6
	c5.1-9,10.4-17.9,15.1-27.1c5.8-11.4,8-23.8,9.9-36.3c0.5-3.4,0.6-6.8,1-10.2h1.2c2.6,6.9,0.9,14-0.4,21s-3.2,14.1-4.8,21.2
	c3.2,0.5,3.6,3.5,3.7,5.9c0.4,4.7,0.3,9.5-0.3,14.2c-2.4,15.8-5.4,31.5-7.7,47.2c-2.2,13.8-1.5,28,2,41.5c2.3,8.9,5.2,17.6,8.8,26.1
	c3.8,8.4,8.1,16.5,13,24.3c3.3,5.6,7.5,10.7,11.2,16.1c4.5,6.4,9,12.8,13.5,19.2c2.1,3.2,5.7,5,9.5,4.6l-7.9-3.9l0.4-1.1l8.3,2
	l10.2-22.7c-2.1-1.9-4.4-3.9-6.5-6c-0.4-0.4-0.3-1.2-0.5-1.9c0.6,0.1,1.4,0,1.9,0.4c2.2,1.6,4.4,3.4,7.1,5.5
	c3.8-6.4,8.5-11.9,10.6-18.3c2.9-8.9,4.8-18.2,5.8-27.5c2.3-20.4,0.2-40.5-4.8-60.4c-0.4-1.7-2.5-3.5-0.1-5.3c1,1.6,2.4,3.1,2.9,4.8
	c5.4,17.1,7.5,35.1,6.3,52.9c-0.9,10.5-2.4,21-4.4,31.3c-1.4,7.7-6.3,14-10.4,20.5c-1.1,1.7-1.7,3.6-1.8,5.5
	c-0.5,6.7-2.9,13.1-7,18.4c-0.5,0.6-1.2,1.2-1.8,1.7l-0.6-0.5l6.9-16.7l-1-0.4L409.3,667.7z M447.1,723.7l-0.9,0.3
	c-0.3-0.7-0.6-1.5-1-2.2c-3.7-8.3-7.1-16.7-11.3-24.7c-4.6-8.6-10.3-16.4-21.5-18.4c-1.9,11.6-3.8,23.4-5.8,35.1
	c-1.8-12.4,0.3-24.5,3.2-36.3c-1.9-1.1-2.8-0.8-3.1,1.2c-0.6,4.1-1.2,8.1-1.9,12.2c-0.1,0.9-0.3,1.8-0.5,2.7l-1-0.1v-17.8L351,652.2
	c0.3,0.6,0.5,1,0.7,1.3c5.6,8.2,11.1,16.4,14.6,25.8c4.9,12.9,9,26.1,6.7,40.1c-0.8,4.9,1.1,6.8,4.7,9.3c7.1,4.8,14.4,8.6,23.2,9.3
	c5.2,0.5,10.3,1.4,15.3,2.7c2.9,0.7,5.6,2.2,8.4,3.4c-8.5-1.4-16.7-3.4-25-4.5c-8-1-15.1-3.6-21.5-8.7c-1.9-1.3-3.8-2.4-5.9-3.3
	c-1.2,7-2.2,13.4-3.4,20.2c3.2,0.2,6.2,0.3,9.3,0.5c11.4,0.9,22.7,2,34.1,2.8c7.4,0.5,14.6,2.2,21.4,5.1c6,2.6,11.5,6.1,17.2,9.3
	c3,1.6,5.9,3.3,8.8,5c6.2,3.8,12,8.7,18.6,11.3c13.1,5.3,25.8,11,36.2,19c2.2-1,3.8-2,5.5-2.3c4.4-1,8.6-2.6,11.7-5.9
	c5.5-5.8,10.7-11.9,16-17.9c0.1-0.2,0.4-0.4,0.3-0.5c-1.5-2.9,1.5-4.6,1.8-7c-13.4-13.2-59.5-47.3-67-49.8
	c-1.1,15.1-1.8,30.1,6.1,44c-0.7-9.2-0.1-18.1,5.1-26.1c-2.2,13.3-3.9,26.5-0.8,40c-1.3-0.8-2.7-1.8-2.5-2.5
	c1.3-6.4-4.2-9.7-6.1-14.4l-1.6,1.1c-0.9-3.4-1.6-6.5-2.5-9.5c-2.8-10.5-3.8-21.3-2.7-32.1c0.3-3.7-0.7-5.2-3.5-6.9
	c-8.9-5.3-17.5-11-26.4-16.3c-5.3-3.2-10.9-5.8-16.4-8.7C439.3,697.9,446.3,709.5,447.1,723.7L447.1,723.7z M649.7,818.3
	c0-3.8,0.2-6.9,0-10.1c-1-11.5-2.1-23.1-3.5-34.6c-0.6-6.4-1.8-12.8-3.4-19c-3.4-12.3-9.2-23.9-16.9-34.1c-4.5-5.9-9.6-11.5-14.7-17
	c-4.3-4.8-8.7-9.4-13.4-13.8c-3.7-3.5-7.9-6.4-11.9-9.5c-4.4-3.4-8.7-6.8-13.1-10.2c-0.8,4.7-1.5,8.8-2.2,12.9
	c-0.5,3.2-1.6,6.5-1.4,9.7c0.6,8.6,1.9,17.2,2.6,25.9c0.6,7.7,0.7,15.4,1.1,23c0.2,0.9,0.9,1.7,1.7,2.1c4.9,2.2,9.9,4.2,14.9,6.2
	c19.5,7.7,33.4,20.9,40.9,40.6c0.9,2.9,1.7,5.9,2.3,8.9l-1.3,0.5c-2.3-4.2-4.8-8.2-6.9-12.5c-3.7-7.5-7.7-14.8-14.1-20.4
	c-11.2-9.7-24.5-15.2-38.9-20.7c-0.3,2.8-1.1,5.5-0.6,7.9c0.3,1.7,2.4,3.1,3.6,4.7c8.5,10.9,18.7,20.4,30.3,27.9
	c7.4,4.8,14.9,9.6,22.2,14.5c4.2,2.8,8.2,5.8,12.2,8.8C642.8,812.6,646,815.3,649.7,818.3L649.7,818.3z M615.3,926.9
	c0.6-4.6,1-8.8,1.7-13c2.5-13.7,7.7-26.6,13.2-39.3c0.6-1.4,0.6-2.9-0.1-4.3c-5.8-8.3-11.3-16.8-17.8-24.6
	c-9-10.9-20.4-19.7-28.2-31.7c-4.9-7.5-9.9-14.9-14.7-22.5s-9.7-14.6-16.1-20.7c-0.5-0.5-1.3-1-1.8-0.9s-1.1,1-1.2,1.6
	c-0.9,2.9-1.6,5.8-2.4,8.7c-0.3,1.1-0.4,2.3-0.6,3.4c-0.8,4.8-3.3,8.9-5.5,13l-0.9-0.3c0.7-2.6,1.5-5.1,2.2-7.7
	c-7,7.9-14.7,14-26.7,14.1c15,12,29.4,23.4,41.1,37.5s20.1,30.4,29,47.3c5.7-2.9,11.2-5.3,16.2-8.5c4.1-2.6,7.5-6.2,11.3-9.2
	c2.2-1.8,4.5-3.3,6.8-5c-7.2,13.4-17.3,23-32.9,25.3C594.3,904.7,605,915.5,615.3,926.9z M350.6,471.8c-0.8,1.3-1.3,2-1.7,2.7
	c-3.4,6.2-6.8,12.4-10.1,18.7c-7.5,14.4-15.1,28.8-22.1,43.5c-4.8,9.7-7.4,20.4-7.5,31.2c0,12.2,1.1,24.3,5.3,35.8
	c2.9,8,6.3,15.9,10.1,23.5c4.6,8.9,13.5,13.4,21.6,18c11,6.2,22.8,11,34.3,16.4c3.9,1.8,8,3.3,12.6,5.2c-4.3-5-8.1-9.4-11.8-13.8
	c-7.5-9.1-14.7-18.5-20.4-28.9c-6.7-12.3-11.9-25.4-15.4-39c-4-15.2-5.3-30.6-2.9-46.2c2.4-15.2,5.4-30.4,8-45.5
	C351.8,486.3,353,479.4,350.6,471.8z M490.8,701.4c-1.2,3.6-2.4,6.6-3.3,9.8c-0.1,1,0.3,2,1,2.7c2.8,2.1,5.7,4,8.6,5.9
	c17.3,11,33.7,23.3,49.2,36.8c5.7,5,11.6,10,16.3,15.9c7.2,9.1,13.5,18.9,20.1,28.4c7.2,10.4,16.3,19,25.4,27.6
	c4.9,4.6,9.3,9.7,13.8,14.8c2.8,3.2,5.4,6.6,8.5,10.4c3-7.5,5.8-14.5,8.6-21.4l1.4,0.5c2.9,9.8-4,15.9-8.9,23c2.1,3.4,4.5,7,7,10.9
	c1.2-2.2,2.3-4.3,3.4-6.5c4.1-8.6,5.8-17.8,6.9-27.2c0.1-0.8-0.1-1.6-0.6-2.2c-3.1-3.6-6.4-7.2-9.5-10.8l-1,0.8v4.1
	c-0.7-0.4-1.2-1-1.2-1.5c0.2-4.5-2.5-7.2-5.6-9.7c-0.8-0.7-1.8-1.1-2.6-1.7c-9.7-7.5-19.1-15.3-29.1-22.4
	c-8.6-6-16.4-12.9-23.4-20.6c-3-3.3-5.8-6.8-9-10.7l-7.4,7.5c0.5-3.2,0.4-6.7,1.5-9.7c1.3-3.5-0.7-5.4-2.4-7.4
	c-1.9-2.4-4.1-4.5-6.5-6.5c-6.6-5.4-13.1-11-20.1-15.8c-10.8-7.5-21.9-14.6-33-21.7C496.5,703.5,493.7,702.7,490.8,701.4
	L490.8,701.4z M459.1,681.9c6.6,2.7,13.4,5.5,20.3,8.2c1.2,0.5,2.5,1.1,2.9-0.9c2.2-10.6,4.6-21.2,6.7-31.9
	c2.6-13,4.8-26.2,4.5-39.5c-0.1-6.2-0.9-12.9-3.3-18.5c-4-9.1-9-17.7-14.8-25.7c-6.6-8.9-14.6-16.7-22-24.9
	c-0.3-0.3-0.8-0.4-1.9-0.9c0.8,1.3,1.2,1.9,1.6,2.5c7.5,10.6,12.9,22.4,16.1,35c5.7,23,3.6,46.1-1.2,69
	C466,663.7,462.1,672.8,459.1,681.9z M314.2,515.2l-1.3-0.5c-2.4,4.3-5,8.5-7.1,12.9c-3.4,7.3-7.1,14.5-9.4,22.1
	c-5,16.6-6.7,33.7-6.1,51c0.6,16.3,4.2,31.7,12,46.1c2.4,4.6,5.2,7.6,10.1,8.9c3.2,0.9,5.2,3,5.2,6.6l11-1.7l-2.9-1.2V659h10
	c-1.8-3.2-3.6-5.9-4.8-8.7c-3.5-8-7.8-15.2-16.5-18.5c-1.2-0.6-2.4-1.4-3.4-2.3c-0.5-0.5-1-1.1-1.5-1.7l0.5-0.7l10.6,4.9
	c-0.4-1.3-0.6-2.1-0.9-2.8c-3.3-8-6.9-15.9-9.9-24c-5.3-14.5-7.7-29.6-5.3-45c1.9-12.3,5.1-24.4,7.8-36.6
	C312.9,520.8,313.6,518,314.2,515.2z M185.4,631.3c0.2-1,0.6-2,0.8-3c1.7-9.5,5.2-18.6,10.2-26.8c1.7-2.9,3.3-5.8,4.8-8.9
	c1.6-3.5,2.5-7.2,4.1-10.6c7.4-15.7,19.5-27.8,31.9-39.4c10.7-10,22.3-19.1,33.4-28.7c13.5-11.8,23.4-26.1,28.4-43.5
	c1.6-5.7,2.7-11.5,0.6-17.6c-9.2,2.8-14.9,8.8-17.6,17.6c-2.2,7.2-4.3,14.4-6,21.7c-3.1,13.8-10.4,24-23.2,30.3
	c-6.3,3.1-12.7,6.2-18.6,10.1c-11.3,7.5-21.5,16.5-30.3,26.7c-7.3,8.3-12.6,18.1-15.4,28.8C184.7,602.1,183.7,616.8,185.4,631.3z
	 M655.5,825.3l1-0.2c0.4-1.1,0.6-2.2,0.8-3.3c0.9-7.5,2-14.9,2.4-22.4c0.8-17.2,0.2-34.2-4.1-51c-3.6-14.1-8.8-27.4-16.7-39.6
	c-8-12.5-18.5-22.7-30.1-31.9c-10.1-8-20.1-16.2-30-24.5c-3.7-3.1-7-6.6-9.7-9.2c0.9,6.1,2.1,12.9,2.9,19.8c0.4,3.8,0.4,4.2,4,5.2
	l-2.9-3.7l0.9-1l10,5.7L584,670l-4,0.9c4.9,3.4,9.5,6.5,14,9.9c5.4,4.2,11,8.5,16.1,13.2c4.1,3.8,7.5,8.3,11.1,12.5
	c4.4,5.1,9.2,10,12.8,15.6c4.5,7.2,8.3,14.8,11.6,22.7c2.2,5.6,3.8,11.4,4.8,17.3c1.4,7.6,2.3,15.3,2.8,23
	c0.7,12.1,0.9,24.2,1.4,36.3C654.7,822.8,655,824,655.5,825.3L655.5,825.3z M679.4,1041.7h-0.6c-0.1,1.1-0.2,2.3-0.3,3.4
	c-0.3,2.6-0.4,6.1-3.2,6.7c-2,0.5-5-0.8-6.8-2.3c-8.5-6.8-17.5-12.9-24.4-21.7c-2.1-2.8-5.1-6.3-10-4c-1,0.4-2.4,0-3.6,0
	c0.5-1.2,1-2.3,1.7-3.4c0.2-0.4,0.8-0.6,1.2-0.8c-2.6-3-5-5.9-7.6-8.7c-0.7-0.6-1.6-0.9-2.5-1c-4.2-0.5-8.4-1.2-12.6-1.3
	c-3.6-0.1-7.5-0.8-10.6,1.8c9.5,7.3,20.6,12,29.6,19.9c9.5,8.4,19.2,16.6,28.6,25.2c5.3,4.8,10.1,10.2,15.1,15.2l12.8,13.1
	c5,5,9.8,10.1,14.9,15.1c4,3.9,8.2,7.6,11.8,10.9c-3.8-5-3.6-12.7-9-18c-2.4-2.3-3.4-6-4.9-9.2
	C692.6,1068.9,686,1055.3,679.4,1041.7L679.4,1041.7z M317.4,714.2l0.3-0.8c-3.7-2.9-7.5-5.7-11.1-8.8c-1.3-1.2-1.8-3.2-2.7-4.8
	c1.7-0.4,3.6-1.5,5.1-1.1c4.3,1.1,8.5,2.5,12.6,4.2c4.3,1.8,8.5,3.9,13.1,6c-2.8-5.2-5.4-10-8-14.8c-1.6-3.3-3.4-6.5-5.4-9.6
	c-5.3-7.4-10.9-14.8-16.3-22.1c-2.1-2.8-3.8-3.4-6.7-1.2c-3,2.2-5.7,4.8-8.1,7.7c-2.5,3-4.3,6.4-6.3,9.5l6.6,2.3l-3,1.5
	c2.5,3.5,4.9,6.8,7.1,10.3c2.4,3.6,1.6,5.9-2.6,7.2c-2,0.6-4.2,0.7-6.2,1.2c-1.1,0.5-2.2,1-3.2,1.7c-1.7-1.1-6.1-0.4-7.4,1.7
	c-0.4,0.9-0.3,1.9,0.3,2.7c1.7,1.4,3.6,2.6,5.6,3.5C292.9,714.8,305.2,713.4,317.4,714.2L317.4,714.2z M452,550.2
	c14.6,44.4,10.8,86.1-13.3,126l17.8,5c4.5-9.4,8.4-18.9,10.1-29s3-20.5,3.7-30.8c1.1-17-0.3-33.7-7.4-49.4
	C459.5,564.6,455.6,557.5,452,550.2z M367.6,723.7c2.1-8.2,1.6-18.6-1.2-29.6c-4.3-17-13-31.8-24.1-45.2c-3.4-4.1-8.3-5.6-13.3-8.7
	c2.8,5,5.5,8.8,7.1,13.2c1.9,5,5,7.2,10.2,6.7c0,0,0.1,0.2,0.3,0.4l-5.9,0.3c0,0.6-0.1,0.8,0,1c7.8,17.6,12.5,36.4,14.1,55.6
	c0.2,1,0.7,1.8,1.6,2.3C360.1,721,363.9,722.3,367.6,723.7z M312.4,661.8l-0.8,0.8c1.2,1.7,2.5,3.4,3.7,5.1c2.7,3.5,5.8,6.8,8,10.6
	c5.3,9.2,10.2,18.6,15.3,28c1.2,2.3,2,5.2,3.9,6.7c2.4,2,5.7,3,9.4,4.8c-0.8-5.8-1.4-10.8-2.4-15.9c-2.6-13.4-5.8-26.6-11.7-39
	c-0.8-1.8-1.8-2.5-3.6-2.3c-1.6,0.2-3.1,0.5-4.6,0.8c-5.1,1.1-10.1,2.2-14.3,3.1C313.9,663.3,313.2,662.6,312.4,661.8z M351.1,788.5
	l-0.8-0.1c0.4-10.7,0.7-21.3,1.1-32c0.2-4.4,0-4.6-4.8-3.8c0,2.3,0,4.8,0,7.2c0.4,16.2,0.7,32.3,1.2,48.5c0.1,1.6,1.2,4.3,2.3,4.7
	c3,0.8,6.4,0.7,9.7,0.9c-1.7-20.8,0.4-40.8,3.3-61.1c-1.8,0-3.6-0.2-5.3-0.4c-2.8-0.6-3.5,0.9-3.7,3.3c-0.2,3.8-0.5,7.6-0.9,11.4
	C352.7,774.1,351.9,781.3,351.1,788.5z M683.5,512.8c-0.5,0.7-1,1.4-1.4,2.1c-3.6,7-7.1,13.9-10.7,20.9
	c-5.9,11.4-10.5,23.2-10.2,36.2c0.2,7.5,1,14.9,1.5,22.4c1.8-7.9,3.2-15.8,4.1-23.7c1.4-11.8,4.5-23,10.5-33.4
	c3-5.1,6.2-10.1,9.2-15.2c0.5-0.8,0.6-1.7,0.4-2.6C685.8,517.4,684.7,515.3,683.5,512.8z M397.6,419.4c0,17.5,8.6,46.1,16.9,55.2
	c-0.2-7.8-0.4-15.8-0.8-23.8c-0.2-2.6-0.8-5.2-1.7-7.7c-2.1-5.5-4.6-10.9-6.9-16.3C403.7,423.5,401,420.9,397.6,419.4z M625.2,938.4
	c0.3-4.4,0.3-8.3,0.8-12.2c1.7-14.3,5.2-28.3,10.3-41.7c0.8-2,0.7-3.8-1.6-5.3c-2.3,3.6-5.2,6.8-6.6,10.6
	c-4.2,10.9-7.7,21.9-8.6,33.7C619,929.3,619.2,934.7,625.2,938.4L625.2,938.4z M364.2,747.5c1.1-6.4,2.2-12.6,3.2-18.9
	c0.1-1-0.2-2.7-0.9-3c-3.6-1.5-7.4-2.8-11.5-4.2v25.4L364.2,747.5z M453.8,692l25.1,15.5c0.5-3.1,0.8-5.8,1.5-8.4
	c0.5-1.8,0-2.5-1.5-3.1c-6-2.5-12-5-18-7.5C456,686.4,455.5,686.7,453.8,692z M667.7,646.7c-1.4-5.6-3.1-10.3-3.8-15.2
	c-1.2-9.2-1.8-18.5-2.7-27.7c-0.1-1.3,0-3-0.7-4c-2.7-3.7-5.7-7.2-8.7-10.9C651.6,598.8,662.3,638.5,667.7,646.7L667.7,646.7z
	 M351.8,746.7c0-8.6,0-16.9-0.1-25.2c-0.1-0.9-0.5-1.7-1.2-2.2c-2.2-1.2-4.6-2.2-6.4-3c1.2,10.1,2.4,20.2,3.6,30.5L351.8,746.7z
	 M454.4,686.3l-41.8-13.6l-0.1,0.2l40.5,18.2L454.4,686.3z M533.9,699.4l-16.1,9.7c3,2.7,6.4,4,10,2.2
	C532.4,709,532.7,704,533.9,699.4z M749.4,609.6l7.3,7.8l2.5-3.5l-3-0.7c0.7-2.8,1.4-5.6,2.2-8.9L749.4,609.6z M770.7,569.4
	c1,1.8,1.8,3,2.4,4.4c0.3,0.6,0.2,1.4-0.1,2c-0.3,0.3-1.3,0.3-1.9,0.2c-2.7-0.6-2.9,0.9-2.8,3l6.8,0.8c0.3-4.2,0.5-8.1,0.9-12.8
	L770.7,569.4z M621.1,945.4l-1.1,0.5c2,6.5,4.1,12.9,6.1,19.4l0.9-0.2c-0.4-3.3-1.3-6.7-1.1-10C626.1,950.7,623.2,948.4,621.1,945.4
	L621.1,945.4z M777.2,566.7c2,4.5,2.1,9.7,0.2,14.2c2-2.7,6.4-3.8,4.5-8.7c-0.7-1.8-0.1-4.1-0.1-6.4L777.2,566.7z M421.3,374.5
	c-2.2,4-4.3,8.1-6.8,12s-5.3,7.3-7.9,10.9C414.4,391.6,420.2,384.5,421.3,374.5z M699.4,505.2c-5.6,5.3-9.5,12-8.4,14.1
	C696.4,515.8,700.5,509,699.4,505.2z M373.4,821l-8.6-4.8c0.5,4,1,7.2,1.5,11L373.4,821z M537.3,675.6h1.2c1.7-6.6,2.4-13.3-0.1-20
	L537.3,675.6z M753,751.1c4.9-5.1,10.9-8.6,9.1-16.7L753,751.1z M558.6,739.7c-0.2,0.2-0.3,0.5-0.5,0.8c1.9,1.9,3.8,3.7,5.7,5.6
	l0.8-0.8C564.7,741.3,560.1,742.1,558.6,739.7z M765.7,434.2l1.5,0.1v-8.6l-0.8-0.1C766.2,428.4,765.9,431.3,765.7,434.2z
	 M539.8,683.8l-1.3-0.1l-1.9,9.4l1.2,0.2C538.5,690.1,539.1,687,539.8,683.8z M350.1,826.1c-0.7,5.6,0.4,8.9,2.4,9
	C351.7,832.1,350.9,829.1,350.1,826.1z"
      />
      <path
        d="M202,807.1c-1.2-5.4-2.5-11-3.7-16.7c-0.1-0.4,0.4-0.9,1.3-2.3c1.3,2.7,2.6,4.6,3.2,6.7c2.4,8.6,4.5,17.2,6.7,25.8
	c2.5,9.7,5,19.5,7.4,29.2c1.8,7.1,4.9,13.7,9.2,19.7c2.3,3.2,3.9,6.9,5.6,10.5c0.4,0.9,0.4,2.9,0.1,3c-1.9,0.8-2.9,4-5.6,2.3
	s-5.4-2.2-7.9,0.3c-2.1,2-3.4,4.7-3.5,7.5c-0.1,1.6-1.6,3.2-2.4,4.7l14.6,19.3l-1,1c-3-2.4-6.3-4.6-9-7.3c-4-4-7.6-8.5-11.4-12.7
	c-0.5-0.6-1.2-1-2-1.1c-1.9,0.3-3.7,0.6-5.6,1.1c-2.2,0.5-2.6,2.1-2.9,4c-0.1,0.7-1.4,1.4-2.3,1.7c-7.3,2.5-11.9,9.8-10.9,17.4
	c0.3,1.2,1.2,2.2,2.4,2.7c13.1,4.4,26.2,8.5,39.3,12.8c10.2,3.4,20.3,6.8,30.4,10.4c10.2,3.6,20.3,7.5,30.5,11.3
	c6.6,2.5,13.3,4.9,19.8,7.5c5.6,2.3,11.1,4.8,16.7,7.2c8.2,3.5,16.5,6.9,24.6,10.5c6.6,3,13.2,6.2,19.8,9.3c6,2.8,12.2,5.2,18.1,8.3
	c9,4.7,18,9.6,26.8,14.9c12.3,7.4,24.5,15.2,36.6,23c10,6.4,19.7,13.3,30,19.2s21,10.7,31.6,15.9c8.6,4.2,17.5,7.9,25.8,12.6
	c10.9,6,21.5,12.6,32.1,19.1c13.3,8.1,26.5,16.3,39.8,24.4c12.8,7.9,25.7,15.8,38.5,23.7c11.3,6.8,22.8,13.3,33.9,20.4
	c12.1,7.9,23.8,16.3,35.6,24.7c9,6.4,18,12.9,26.8,19.7c12.2,9.4,24.6,18.7,34.3,30.8c4.9,6.1,9.2,12.6,14,18.8
	c5.2,6.8,10.7,13.4,15.7,20.3c4.3,5.9,8.1,12.1,12.3,18c5.9,8.2,12.1,16.2,18,24.5c1.9,3,3.7,6,5.3,9.2l-0.8,0.7
	c-2.3-2-5.1-3.6-6.8-6c-7.4-10-14.5-20.3-21.8-30.3c-3.1-4.2-6.7-8.1-9.9-12.2c-3-3.8-5.9-7.7-8.8-11.6c-6.3-8.4-12.7-16.8-19-25.3
	c-6.7-8.9-14.7-16.9-23.7-23.6c-12.5-9.6-25.2-19.1-38-28.4c-9.9-7.2-20-14-30.2-20.8c-7-4.7-14-9.3-21.2-13.7s-14.5-8.1-21.7-12.4
	c-8.8-5.2-17.4-10.7-26.1-16c-5.3-3.2-10.8-6.2-16.1-9.4c-6-3.7-12-7.5-18-11.2c-12.6-7.7-25-15.8-37.9-22.9
	c-11.5-6.3-23.6-11.5-35.3-17.5c-9.4-4.8-18.7-9.8-27.8-15.1c-12.4-7.2-24.4-15-36.8-22.2c-10.5-6.1-21.3-11.5-31.9-17.4
	c-7-3.9-13.4-8.7-20.5-12.4c-9.4-4.9-19.1-9.1-28.7-13.7c-3.2-1.5-6.4-3.2-9.7-4.7c-5.5-2.6-11.1-5.1-16.7-7.6
	c-6.1-2.7-12.1-5.3-18.2-7.8c-9.4-3.8-18.8-7.5-28.2-11.2c-6.4-2.5-12.7-5-19.2-7.3c-8.2-3-16.6-5.7-24.8-8.5
	c-5.1-1.8-10-3.8-15.1-5.5c-14-4.8-28.1-9.4-42.2-14.1c-10.4-3.5-20.8-7.1-31.2-10.4c-6.6-2.1-13.4-3.8-20.1-5.8
	c-16.8-4.9-33.4-10.1-49.4-17.5c-12.7-6-24.7-13.3-35.9-21.8c-1.9-1.4-3.8-2.7-5.5-4.2c-0.6-0.5-0.8-1.6-1.1-2.4
	c0.9,0,2.1-0.4,2.8,0c5.1,3.2,10,6.8,15.2,9.8c8.1,4.7,16.1,9.7,24.7,13.4c11.5,4.9,23.2,9.1,35.1,12.8c9.9,3.1,20.2,5.1,30.3,7.7
	c2.9,0.7,5.6,1.8,8.5,2.7c0.6-2.6,1.1-5,1.7-7.4l1.2,0.1c0.1,2.1,0.1,4.2,0.4,6.3c0.1,0.8,0.7,2.1,1.3,2.3c4.6,1.5,9.3,2.8,13.1,4
	c1.4-4.7,2.5-9.4,4.3-13.8c1.4-3.6,4.5-6.3,8.3-7.3c0.8-0.2,1.8-0.6,2-1.2c1.6-4.2,5.2-5.3,9.1-5.8c0.5-0.1,1-0.3,1.4-0.6
	c-7.3-12.3-12.5-25.2-11.9-40.7c-1.2,1.4-2.4,2.8-3.7,4.1c-5.4,4.7-10.7,9.5-16.2,14.1c-2.7,2.1-4.3,5.3-4.4,8.8
	c-0.5,6.6-0.7,13.3-1.2,19.9c-0.2,1.9-1.2,3.6-1.8,5.5l-1.1-0.1c-0.5-2.6-1.5-5.2-1.5-7.9c-0.1-5.8,0.2-11.6,0.6-17.4
	c0.2-4.7,2.5-9.1,6.2-12c0.6-0.6,1.1-1.4,1.2-2.3c0.3-1.7,0.3-3.5,0.6-5.2c0.9-5.9,2.4-11.7,2.8-17.7c0.8-11-7.9-19.2-18.9-19
	c-7.9,0.2-15.9,0.6-23.3-3.1c-1.8-0.9-3.2-2.6-4.6-4.2c-0.3-0.3,0.1-1.5,0.5-2.2c0.6-0.1,1.3,0,1.8,0.3c3.5,4.2,8.6,4.8,13.5,5.2
	s9.7,0.1,14.6,0.2c11.8,0.4,18.8,8.2,18.2,19.9c-0.2,3.7,0.4,7.3,0.3,11c0,2.3-0.4,4.6-0.9,6.8c-0.6,2.3-1.7,4.4-2.3,6.8
	c8.1-5,15.2-10.8,19.1-19.6c1-2.2,0.6-5,1.3-7.4c1.9-6.2,4.2-12.4,6.3-18.6c0.9-2.6,1.7-5.2,2.7-7.8
	C200.5,809.5,201.2,808.3,202,807.1z M228.7,880.7c-6.6-7.9-12.3-15.3-14.6-24.7s-4.6-18.8-6.9-28.2c-1.3-5.2-2.6-10.4-4.1-16.2
	c-1.2,3.9-1.7,7.3-3.2,10.3c-6.7,13.9-7.4,28.5-4.8,43.4c1.7,10,6.7,18.6,12.3,26.9c0.5,0.7,1.4,1.1,2.3,1.1c0.6-0.1,1-1.3,1.3-2.1
	c0.4-1.2,0.6-2.5,1-3.6c1.7-4.4,7.8-8.3,11.5-7.5C225,880.3,226.5,880.4,228.7,880.7L228.7,880.7z"
      />
      <path
        d="M110.8,946c2.9-3.6,6.8-6.8,8.5-10.8c2-5,5.8-5.6,9.9-7c5-1.7,9.7-4.1,14.7-6c1.2-0.5,2.7-0.2,4.1-0.3l0.2,1l-14.4,6.9
	c8.4,1.9,15.5,4.5,21.5,9.4c-1.6-0.8-3.1-1.6-4.7-2.2c-4.7-1.8-9.5-3.6-14.2-5.3c-2.8-1.1-6.1-0.4-8.2,1.8c-1,1-2.1,1.8-3.2,2.6
	c-1.8,1.1-1.6,2.3-0.7,4c3.4,6.3,8.5,10.4,15.6,11.8c1.8,0.3,3.4,1.2,5.2,1.4c2.6,0.4,5.2,0.3,7.7-0.1c3.6-0.9,4.4-3.7,2.1-6.7
	c-0.3-0.5-0.6-1-0.9-1.5l0.9-0.6c0.9,1.5,2.8,3.1,2.6,4.5c-0.2,1.9-1.6,4.7-3.1,5.2c-2.8,1-6.1,0.6-9.2,0.6
	c-0.7-0.1-1.4-0.4-2.1-0.7c-13-4-13-4-21-15c-4.4,3.3-6.7,8-8.5,13c-0.2,0.6,0.8,1.8,1.5,2.4c0.7,0.5,1.4,0.9,2.2,1.1
	c6.2,2.2,7.8,4.5,7.4,11c-0.1,2-0.3,4-0.6,6c-1.2,5.2-0.9,10.6,0.8,15.7c2.7,8.2,1.3,16.3,0.1,24.5c-0.6,4-0.9,8.1-1.3,12.2l0.9,0.4
	c1.2-1.1,2.6-2,3.4-3.3c5.5-9.6,11-19.3,16.1-29c0.9-1.6,0.7-4.2,0.1-6c-1.6-5.2,1.4-9.5,6.8-8.9c7,0.7,12.7,3.7,15.9,10.5
	c1.1,2.4,3.2,4.3,4.2,6.6c3.2,7.1,0.9,13.4-3.4,19.2c-0.9,1.3-1.9,2.5-2.9,3.7l0.6,1c2.3-1,4.9-1.8,7-3.2c3.4-2.3,6.2-5.4,10.3-7.2
	c2.3-1,3.3-4.9,5.1-7.3c1.6-2.1,3.4-4.5,5.6-5.7c3.3-1.8,6.4-0.7,9.4,3c2.2,2.7,4.1,5.6,5.7,8.8c1.4,2.9,3.6,3.8,6.9,4
	c0-0.6-0.1-1.2-0.3-1.8c-1.6-3.2-1.6-5.9-0.4-9.5c1.1-3.4-0.6-7.7-1.1-11.4c-0.2-0.1,0.6,0.1,0.9,0.5c1.8,3.5,3.6,7.6,1.8,11.2
	c-2.1,4.1-0.6,7,1.1,10.6c5.6-2,9.8-5.5,12.3-10.8c3.4-7.2,7.1-8.7,14.4-5.2c4.2,2,8.2,4.3,12,7c7.9,5.7,17,8.9,25.9,12.5l2.9,1.2
	c-0.5-2.5-1.6-5.1-1.5-7.8c0.1-3.6,3.7-3.8,5.8-4.6c-0.3-2.8-0.3-5.5-1-7.9c-0.6-1.7-1.7-3.3-3.1-4.5c-4.5-4.1-11.8-3.5-16.3,0.6
	c-2,1.7-4.3,3-6.8,3.7c-2,0.6-3-0.7-2.1-2.8c2.3-5.8,0.8-9.3-5-11.7c-1.9-0.8-3.1-3.9-5.7-1.9c-1.7,1.3-3,3.1-5.2,5.5
	c1.5-3.4,2.4-6.1,5.5-7.2c0.6-0.2,1.4-0.6,1.8-0.4c3.1,1.7,6.7,3,8.9,5.5c1.6,1.9,1.6,5.5,1.9,8.3c0.1,1-0.9,2-1.5,3.3
	c2.3,0.5,4.7-0.3,6.1-2.2c2.6-3.5,6.9-5.5,11.2-5.2c4.6,0.2,7.9,2.6,10.6,5.7c3.1-2.3,5.8-5.1,9.1-6.7c7-3.4,11-1.3,13.5,6
	c1.4,4.1,4.6,6.4,8.7,7.9c1.9-2.9,3.8-6,6-8.8c0.7-0.9,2.4-1.1,3.6-1.6c-0.2,1.4,0,3-0.6,4.2c-1.4,2.4-3.3,4.6-4.8,7
	c-0.4,0.6-0.1,1.7-0.1,2.6c-2.5-0.9-3.6,2-5.3,3.7c-3.3,3.3-7,5.8-11.7,6.5c-5.3,0.7-7.6-2.2-5.1-7c1.4-2.7,3.7-5,5.9-7.3
	s2.2-2.7-0.7-4.1c-5.1-2.5-9.5-0.2-13.7,2.2c-0.5,0.3-0.5,2.1-0.1,3c2,4.7,1.8,5.5-2.7,7.3c-3.8,1.6-4.1,3.5-1,6.6
	c1.9,1.8,3.3,4.2,5.3,5.8c2.3,1.6,4.8,3.1,7.3,4.3c5.2,3,10.4,6,15.6,9c6-4.5,7.4-4.7,8.9-1.2c-1.2-0.2-2.6-0.9-3.8-0.6
	s-2.3,1.6-3.7,2.6c0.8,0.6,1.1,0.9,1.6,1.1l30.9,18.7c2.6-5.9,1.7-9.1-3.5-12c-5.6-3.2-11.3-6.3-16.8-9.6c-1.5-1-2.7-2.3-3.7-3.8
	c7.2,4.1,14.4,8.1,21.6,12.2c5.2,3,6.1,4.7,4.9,10.6c-0.6,2.8,0.1,4.2,2.5,5.4c2.2,1,4.1,2.8,6.3,3.7c2.4,1.2,5.1,1.9,7.8,2.1
	c5.8,0.2,10.5,2.9,14.7,6.2c6.2,4.9,11.8,10.5,17.7,15.8c1.8,1.6,3.7,3.1,5.8,4.3c6.1,3.2,12.2,6.2,18.4,9.3c0.6,0.2,1.1,0.6,1.6,1
	c5.9,6.3,14.1,7.9,21.6,11c9.3,3.8,18.4,8,27.5,12.1c2.1,0.8,4.1,1.8,6,3c2.5,1.7,3.7,0.5,4.9-1.6c3.2-5.7,5.1-6,10.4-2.1
	c5,3.7,8.6,8.2,9.6,13.5c-3.2-3.7-6.6-8.3-10.7-12.1s-5.1-3.3-7.8,1.5c-0.5,0.9-1.1,1.8-1.9,3c3.3,1.4,4.4,4.1,4.1,7.4
	c-0.2,1.9,0.9,2.7,2.6,2.8c4.1,0.3,8.2,0.3,12.3,0.7c3.6,0.3,7.3,1.1,10.9,1.4c2.3,0.2,4.6,0,6.9-0.4c11.1-2.2,19.9,1.5,26.9,10.1
	c2.1,2.5,4.3,4.9,6.3,7.2c8.1-8.3,14.9-17.8,27-20.9c1.1,1.2,1,2.5-1.3,3.7s-4.5,1.7-6.7,2.8c-1.5,0.7-2.9,1.7-4,2.9
	c-3.4,4-6.6,8.2-10,12.3c-1.7,2.1-1.3,3.5,0.8,4.9c4.9,3.2,9.9,4.6,15.5,2c7-3.3,13.3-2.2,19.5,2.6c4.1,3.2,9.2,5,13.6,8
	c4.6,3.1,8.9,6.6,13.3,10c9.2,7.1,18.2,14.5,27.6,21.3c3.9,2.8,8.5,4.7,12.8,6.9s8.1,4.9,9,9.9c1.4,7.2,6.3,11.4,12.1,14.6
	c11.2,6.2,19.5,15.3,27.1,25.3c3.6,4.8,7.9,9.1,12.5,12.8c3.9,3.1,6,8.5,8.8,13c0.6,1.2,1.6,2.2,3,2.5c3.3,1.2,6.3,2.8,9.1,4.9
	c3.9,2.9,7.5,6.2,11.2,9.3s3.5,7.2,3.9,11.5c0.6,6.7,1.6,13.4,3.2,19.9c2.4,10.4,5.5,20.6,7.9,31c0.4,2.5-0.1,5-1.4,7.2
	c-3.2,5.9-5,11.8-4.2,18.8c0.6,5.2-0.8,10.7-1.3,16c-0.2,1.6-0.4,3.1-0.6,4.9h-3.5c0.6-5.3,1.2-10.5,1.7-15.6
	c0.2-1.9,0.3-3.7,0.1-5.6c-0.8-6.3,0-12.3,3.5-17.6c2.8-4.3,2.4-8.6,1.1-13.1c-2.3-8.2-4.6-16.5-6.9-24.7c-2.1-7.5-3.8-15-3.2-22.8
	c0.4-3.3-1.2-6.6-3.9-8.5c-4.9-3.5-9.5-7.3-14.3-11c-0.6-0.4-1.8-1-2.2-0.7c-3.3,2.4-4.3-0.4-5.5-2.4c-1.9-3.3-3.3-6.8-5.4-10
	c-1.3-1.9-3.3-3.3-5.1-5c-2.1-1.9-4.4-3.6-6.3-5.7c-6.6-7.4-12.7-15.3-19.7-22.3c-4.1-4.1-9.5-7-14.3-10.4s-9.5-7-10.6-13.5
	c-1-6.3-5.9-9.1-11-11.3c-12.5-5.4-22.7-14.1-32.9-22.9c-4.6-3.9-9-8.1-13.8-11.7c-2.7-2-6.1-2.8-9-4.4c-3.2-1.8-6.3-3.8-9.3-6
	c-4.5-3.5-9.1-5-14.4-2.3c-7,3.6-13.5,2.4-19.7-1.7c-0.6-0.4-1.3-0.8-2.1-0.9c3.4,2.6,6.7,5.5,4.3,10.2c-1.8,3.3-4.9,5.5-10.1,5.7
	c1.5-2.1,3-4.1,4.7-6.1c2.7-2.8,2.8-3.7-0.3-5.9c-2.5-1.8-3.6-3.6-2.5-6.7c0.3-0.9-0.5-2.4-1.2-3.3c-2.6-3.2-5.3-6.3-8.2-9.3
	c-6.1-6.3-13.7-7.7-22.2-6.4c-3.2,0.5-6.7,0-10-0.2c-6.3-0.4-12.6-0.8-19-1.3c-1.1,0-2.1-0.2-3.1-0.4c-2.4-0.8-4.1-2.1-3.8-5.1
	c0.1-1,0-1.9-0.1-2.9c-4.1,4.4-8.2,9.1-12.7,13.4c-4.1,4-8.3,3.6-12.7,0c-7.9-6.5-13.2-14.8-18.1-23.5c-1.9-3.4-3.7-6.6-7-8.8
	c-1.5-0.9-2.6-2.2-3.4-3.7c-1.9-4.5-5.5-4.1-9.1-3.5c-2.4,0.5-4.7,1.3-6.9,2.3c-8,3.5-13.5-1.2-16.6-6.7c-0.5-1,0.2-2.8,0.6-4.2
	c0.6-2,2.5-4.4,1.9-5.7c-1.3-2.5-3.3-4.6-5.7-6.1c-2.2-1.3-4.7-1.9-7.2-1.9c-6.5,0.5-12.9-1.7-17.8-6c-1-0.7-2.3-0.9-3.5-0.6
	c-3.6,1-6.8,4.9-11,1.2c-5.3-4.6-10-9.6-8.7-17.2c0.6-3.5-0.4-4.9-3.3-6.6c-10.4-5.9-20.5-12.3-30.9-18.5
	c-6.4,6.8-14.2,3.3-21.5,2.8c-2.1-0.1-4.1-2.7-6-4.3c-2.5-2.2-4.8-4.6-7.2-6.9l-0.9,0.3c0,2.2-0.1,4.4,0,6.5c0.1,2.9-1.2,4.9-4,5.7
	s-5.9,1.1-8.6,2.2c-1.9,0.7-3.5,2-4.6,3.7c-1.2,2.2,0.3,3.7,2.8,3c1.6-0.4,3.1-1,4.6-1.6l0.4,1.2c-2.2,0.6-4.4,1.5-6.6,1.6
	c-1,0-2.7-1.4-3.1-2.6c-0.8-2.5-0.5-5.4,2-6.8c2.5-1.3,5.2-2.3,7.9-3c6.2-1.7,6.7-2.4,5.7-8.7c-0.5-3.3-2.2-4-4.7-1.9
	c-4.6,3.7-10.1,6.5-13.2,12c-2.5,4.4-3.8,8.8-3,13.8c0.3,1.8,0.2,3.6-1.8,4.2c-6.3,1.7-7.6,6.9-8.5,12.2c-0.7,3.8-0.7,7.7-1,11.6
	l-1,0.3c-0.9-3.3-2.5-6.6-2.5-9.9c0-7,2.1-13.4,8.6-17.3c0.7-0.5,1.1-1.3,1.2-2.2c0-12,5.6-20.4,16.1-25.9c0.8-0.4,1.4-0.9,2-1.5
	c0.9-1,1.8-2,2.8-3.2c-6.5-3.1-13.4-5.5-20.8-5.7c-2.1,0-4.5,1.6-6.3,3c-2.1,1.7-3.7,4.1-5.7,5.9c-3.7,3.4-8.3,3.6-12.1,0.3
	c-3.2-2.8-5.9-6-8.9-9c-0.6-0.7-1.6-1.5-2.4-1.4c-5.9,0.7-9.2-2.5-11.8-7.2c-1.5-2.9-3.3-5.6-5.4-8.1c-2-2.4-5-2.1-6.7,0.5
	c-2.1,3-3.8,6.3-5.8,9.4c-0.8,1-1.7,1.8-2.8,2.4c-2.6,1.8-5.3,3.7-8.1,5.3c-3.1,1.8-6.3,3.3-9.6,4.6c-1.3,0.5-3.1-0.5-4.7-0.8
	c0.3-1.5,0.3-3.2,1-4.4c1.9-3.5,4.5-6.7,6.1-10.3c2.1-5,1.3-9.7-2.8-13.7c-0.6-0.6-1-1.3-1.3-2.1c-2.1-6-7-8.2-12.7-9.2
	c-3.1-0.6-5,1-4,3.4c2.4,5.5-0.3,9.9-2.7,14.3c-4.5,8-9,16-13.7,23.9c-1.4,2.1-3.1,4-5.2,5.5c-2.4,1.8-4.7,0.8-4.9-2.3
	c-0.1-4-0.1-8,0.3-12c0.4-6.7,1.3-13.4,1.4-20.2c0-3.2-1.6-6.3-2-9.6c-0.3-3.2-0.3-6.5,0-9.7c0.2-3.8,0.6-7.6,0.7-11.3
	c0.1-2.4-1.3-3.8-3.7-4.5c-2-0.7-4-1.6-6-2.5l-1.5,1.7c-0.8-3.6-1.4-7-2.3-10.3c-0.8-3-1.9-5.8-3-8.7s-2.2-5.8,0.8-8.8
	c-5.1-1.9-9.9-3.5-14.5-5.5c-9.1-3.8-18.2-7.6-28-8.9c-4.7-0.7-9.5-0.7-14.2,0c-11,1.8-19.4-2.3-26.5-10.2c-2.6,2-3.6,1.6-4.1-1.7
	c-1.2-9.1,0.6-17.7,4.5-25.9c4.2-9,11.5-15.5,18.8-22c6.2-5.5,12-11.6,17.9-17.4c6.1-6.1,13-11.1,20.7-14.9c5.9-2.9,12-5.7,18.1-8.2
	c8.6-3.5,17.2-6.9,26-9.7c6.6-2.2,13.5-2.5,20.3,0c1.4,0.5,3,0.7,4.6,1.3c-11.6,3.2-24,3.4-35.7,9.8c3,1.9,5.4,3.7,8,5
	c6.5,3.3,13.7,3.4,20.6,4.8c4.1,0.8,8.1,2.2,11.9,4c3.7,1.8,5.9,5.2,6.5,10.1c-0.7-0.3-1.3-0.8-1.9-1.3c-5.3-7.1-13-9-21.1-9.9
	c-7.4-0.8-14.5-2.6-20.8-6.9c-0.6-0.4-1.4-0.8-1.7-1.3c-2.2-4.4-5.3-3.3-9.1-2.1c-11,3.8-21.7,8.1-31.5,14.4
	c-4.5,2.9-8.3,6.8-12.3,10.3c-7.2,6.5-14.3,13-21.5,19.5c-9.5,8.6-16.7,18.4-18.1,31.7c-0.3,3-0.1,5.7,1.9,8
	c6.3,6.9,13.5,11.4,23.4,9.7c13.7-2.4,26.4,1.7,38.9,6.5c6,2.3,12,4.7,18,6.9c3.9,1.4,4.4,2.8,2.7,6.6c-0.4,1.2-0.4,2.4,0,3.6
	C108.4,939.8,109.7,943,110.8,946z M292.7,1034.3l-0.9-0.5c0.1-3.1,0.1-6.2-0.1-9.3c-0.3-1.3-1.1-2.3-2.3-2.9
	c-4.1-1.9-8.4-3.4-12.5-5.3c-7.4-3.5-14.7-7-21.9-10.8c-4.4-2.3-8.4-5.2-12.8-7.4c-5-2.5-6.9-1.6-9.3,3.3c-0.2,0.4-0.4,0.7-0.6,1.1
	c-3,5.5-7.6,9.2-14.4,11.9c4.1,3.8,7.6,7.5,11.4,10.6c2.4,2,6.1,1.4,8.5-0.8c1.9-1.8,3.8-3.7,5.5-5.7c2.5-3.3,6.7-4.8,10.8-3.9
	c13,2.2,24.8,7,33.8,17c4,4.4,8.3,7.8,14.8,6.6c1.1,0,2.1,0.2,3.1,0.5c3.6,0.9,6.4-0.3,9.2-3.4l-17.9-10.3
	C295.5,1028.4,294.1,1031.4,292.7,1034.3L292.7,1034.3z M435.8,1108.3l-0.8,0.9c1.6,1.1,3.2,2.3,4.6,3.7c2.2,2.1,4.1,4.5,5.7,7
	c5.4,9.3,10.8,18.5,19.4,25.3c3,2.4,6.8,2.8,9.5,0.1c3.8-3.7,7.3-7.6,10.8-11.6c1.6-1.8,1.5-3.4-1.1-4.7
	c-9.7-4.5-19.1-9.5-28.9-13.8C448.9,1112.4,442.3,1110.6,435.8,1108.3L435.8,1108.3z M352.8,1058.5c-2.3,6.3-0.6,11.8,5,17.2
	c3.9,3.7,4.2,3.7,9,1.4c1-0.5,2.1-1.3,3.2-1.4c1.4-0.1,3.4-0.1,4.2,0.7c6.1,5.8,13.5,6.7,21.8,6c-2.5-2.4-4.7-4.6-7.1-6.7
	c-6.3-5.3-12.6-10.2-21.5-10.3c-2.2,0-4.4-1.3-6.4-2.3C358.2,1061.8,355.6,1060.1,352.8,1058.5L352.8,1058.5z M409.2,1090.7
	c-1,2.8-2,5.3-2.9,7.9c-0.3,0.8-0.9,1.8-0.6,2.6c0.8,2.9,5.4,5.9,8.5,5.3c2.1-0.4,4.1-1.6,6.2-2.1c3.2-0.8,6.4-1.4,9.7-2.1l0.4-0.7
	L409.2,1090.7z M313.2,997.8c-2.4,4.3-8.2,5.9-8.6,11.9c6.2-1.1,10.2-4.6,14-8.9L313.2,997.8z M307.7,993.5c-1-3.2-2-5.3-4.9-5.4
	c-3.4-0.2-8.2,3.2-9.5,7.1C297.8,992,302.3,990.8,307.7,993.5L307.7,993.5z"
      />
      <path
        d="M441.4,1414.6H437c-0.5-5.5-1.1-10.9-1.5-16.4c-0.6-6.8-2.6-13.3-5.8-19.3c-3.2-6.3-7-12.3-10.3-18.5
	c-3.6-6.8-6.7-13.9-10.3-20.7c-7.1-13.4-14.5-26.5-24.3-38.1c-10.3-12.2-17.1-26.3-22.5-41.3c-2.3-6.2-5.3-12.2-8.2-18.2
	c-0.8-1.5-1.9-2.8-3.2-3.9c-7.9-7.8-13.6-17.2-18.9-26.8c-6.6-11.9-13-24-19.8-35.8c-6.4-11-16.9-16.6-28.8-19.7
	c-7.5-2-15.2-3.6-22.9-5.1c-7.4-1.3-14.9-1.2-22.2,0.3c1.4,3.2,3.2,6,4.1,9.2c1.2,4.4-1.3,8.1-5.8,9.3c-1.7,0.5-3.5,0.8-5.3,0.9
	c-4.9,0.3-6.1,0.5-6.9,5.4c-1.2,7.2-1.6,14.6-1.2,21.9c0.7,8.8,7.2,14.4,13.8,19.6c4.2,3.4,9.1,3.9,14.3,4.4
	c5.2,0.5,10.4,1.8,15.2,3.8c4.5,1.8,5,6.7,5.5,11.1c0.3,3,1,6,1.3,9c0.6,5.2,1,10.5,1.1,15.7c-0.1,4-2.7,7.2-5.5,10.1
	c-5.4,5.5-10.8,11-16.1,16.5c-2.4,2.5-4.5,5.1-7.2,8.1c0-6.2,1.5-11.2,5.4-15.3s8.7-8.1,12-13c4.4-6.6,8.3-13.6,5.9-22.3
	c-0.9-3.4-1-7.1-1.2-10.6c-0.2-3.3-1.9-5.6-4.8-6.4c-4.6-1.2-9.2-2.2-13.9-2.9c-7.2-1.2-13.9-3.6-19.6-8c-5.5-4-9.4-9.9-10.9-16.6
	c-0.6-3.3-0.9-6.7-0.7-10.1c0.2-6.4,0.3-12.8,2.6-19c1.8-4.7,4.6-6.9,9.5-6.6c0.5,0,1.1,0.1,1.6,0.1c6.8,0,9.1-3.5,6.5-9.8
	c-0.5-1.3-1.1-2.5-1.7-3.9c-1.8,0.5-3.5,1-5.2,1.5c-3.8,1.2-7.9,0.7-11.3-1.4c-4.9-3-10.9-3.8-16.4-2.2c-6.9,1.8-14,3-21.1,3.8
	c-6.2,0.6-11.4-2.2-13.6-9.8c-4.2-14.6-5.6-29.4-5.5-44.5c0-4.7-1.1-9.4-1.8-14.1c-0.1-0.9-0.3-1.7-0.8-2.4
	c-4.3-4.7-3.2-10.3-2.7-15.7s0-10-4.7-13.6c-1.3-1-2.1-3.1-2.6-4.8c-1.5-6.2-3.4-7.4-9.4-5.3c-5,1.6-9.5,4.2-13.3,7.8
	c-5.4,5.3-14.2,5.1-20.4-0.8c-4.5-4.3-8.1-9.4-12.1-14.2c-0.5-0.6-0.9-1.2-1.5-2c-1.8,4-5.2,5.1-8.7,6.7c-5.9,2.7-11.6,6-16.8,9.9
	c-2,1.4-3,4.6-3.8,7.1c-0.9,2.9-1.4,5.9-1.6,9c0.1,3,0.3,6,0.8,9l-0.8,1c-0.2-0.7-0.3-1.3-0.6-2c-3.8-9.3-4.5-18.3,2.3-26.6
	c0.3-0.4,0.6-0.9,1-1.2c5.2-3.4,10.3-6.9,15.5-10.2c1.2-0.7,2.5-1.2,3.8-1.6c5.3-2.1,6.3-5.5,2.4-9.6c-7-7.4-12.5-15.7-14-26.1
	c-1-7.4-1.6-14.7,1.6-21.8c1.8-4,5.3-6,8.8-7.9c2.5-1.4,2.2-2.6,1-5c-3.7-6.9-7.1-14-10.4-21.2c-1-2.1-1.8-3.2-4.2-3.3
	c-11.3-0.7-22.6-1.7-33.8-2.6c-2.9-0.2-3.7-0.8-4.6-2.7c11.2,0.8,22.5,1.6,33.8,2.3c2.2,0.2,4.4,0.3,6.7,0.2c2.1-0.2,4,1.2,4.4,3.3
	c2.5,8.8,7.5,16.4,12.1,24.2c1.2,2.1,1.3,4-0.8,5.6c-0.6,0.5-1.1,1.2-1.7,1.4c-8.9,3.8-10.6,11.2-10.3,19.8
	c0.2,12.6,5.8,24.5,15.4,32.7l0.9-0.3c-1-2.7-1.5-5.6-3-8c-4.9-7.9-3.6-13.6,1.9-20.5c1.7-2.1,4.2-3.6,5.8-5.8c1.3-1.8,2-4,2.1-6.3
	c-0.1-5.7,1.6-11.4,4.8-16.1c1.1-1.5,2.5-2.7,4.1-3.7c0.4-0.3,1.6,0.2,2.2,0.7c0.2,0.2,0.2,1.3-0.2,1.6c-5.7,6.6-5.5,14.3-4.2,22.2
	c0.5,2.5-0.3,5.1-2.2,6.9c-2,2-4.2,3.9-6.1,6c-4.1,4.5-5.7,9.4-3.4,15.6c1.3,3.5,1.1,7.6,1.7,11.5c0.2,1.1,0.2,2.4,0.8,3.2
	c3.8,5,7.5,10.2,11.8,14.9c3.5,3.9,8.1,6.3,13.6,4.5c1.6-0.6,3-1.5,4.3-2.6c5.5-4.4,11.3-8.1,18.5-9.1c4.1-0.6,7.3,0.8,8.3,4.8
	c1,4.2,3.3,8,6.5,10.8c3.2,2.9,2.2,7.3,2,11.1c-0.1,2.9-0.6,5.7-0.4,8.5c0.2,1.8,1.4,3.6,2.1,5.4c0.5,0.9,0.9,1.9,1.3,2.8
	c2.4,8.2,2.2,16.7,2.2,25.2c0.2,11.9,2,23.6,5.3,35c1.3,4.6,4.4,7.3,9.6,7c6.2-0.4,12.4-1.4,18.4-3c7.7-2.2,14.8-2.2,22,1.8
	c2.8,1.3,6.1,1.6,9.1,0.7c10.2-2.9,20.4-3.9,30.7-1.8c9.6,1.9,19.1,4.3,28.5,6.9c13.2,3.7,22.1,12.9,28.5,24.6
	c5.7,10.4,11,20.9,16.8,31.2c5.4,9.6,11.3,18.8,19.1,26.8c3.1,3.1,5,7.6,6.9,11.7c3.4,7.3,6.4,14.7,9.4,22.2
	c4.5,11,11.5,20.4,18.4,29.9c5.3,7,10.1,14.3,14.6,21.8c6.3,11,11.9,22.4,17.9,33.5c4,7.3,8.5,14.3,12.4,21.7c4.8,9,6.6,18.8,6.7,29
	C440.9,1409.2,441.2,1411.8,441.4,1414.6z"
      />
      <path
        d="M223,783.7c-0.2-2.7-0.5-5.3-0.7-8c-9.7,1.1-13.8,8.7-19.3,15c-1.4-4.3-1.4-4.3,7.3-14l-3.6-2.7l0.2-1
	c1.3,0.1,3-0.2,3.8,0.5c2.1,1.8,4,1.1,6.2,0.5c1.5-0.4,3.1-0.7,4.5-1c0.5-1.8,0.9-3.6,1.4-5.4l1.3,0.1c0.4,2,0.7,4,1.1,6
	c1.5,8.5,2.8,17.3,8.1,24.5c6.1,8.3,13.8,15.4,22.6,20.9c8.8,5.6,18.2,10.3,27.9,14.2c3.5,1.4,7.1,2.6,10.7,3.9
	c0.7,0.3,1.4,0.6,2.7,1.2c-1,0.3-1.4,0.5-1.7,0.5c-9.3-1.4-14.5,4.8-19.2,11c-3.6,4.8-5.7,10.5-6,16.4c-0.2,1.8,0.8,3.6,2.5,4.2
	c2.1,1.1,4.3,2.1,6.4,3.2c7.5-10.8,14.4-22.8,28.9-26.2l0,0c-1,1.5-1.8,3.4-3.2,4.3c-8.1,5.2-14.4,12-19.9,19.7
	c-2.9,4.1-2.8,4.3,1.7,6.5c9.7,4.8,19.3,9.9,28.9,14.7c4.7,2.4,9.7,4.2,14.3,6.8c9.6,5.2,19,10.9,28.7,15.8
	c8.9,4.5,15.6,11.9,23.8,17.1c6,3.8,12,7.4,18.2,10.6c8.1,4.2,16.4,8,24.5,12.1c3.9,2,7.5,4.4,11.2,6.4c4,2.2,8.3,4.1,12.3,6.4
	c6.7,4,13.3,8.1,19.9,12.4c9.8,6.3,19.4,12.7,29.1,19.1c4.9,3.2,10,6.3,14.8,9.7c1.7,1.2,3.1,3,4.6,4.5c-0.2,0.3-0.4,0.6-0.6,0.9
	c-2.8-1.3-5.8-2.2-8.4-3.8c-10.9-6.8-21.6-14-32.6-20.6c-9.2-5.5-18.7-10.6-28.3-15.6c-10.1-5.2-20.3-10.1-30.5-15.2
	c-4.9-2.4-9.8-4.9-14.7-7.1c-1.3-0.6-3-0.4-4.5-0.6c-1.1-0.1-2.1-0.4-3-1l2.6-0.6c-3.9-2.2-7.6-4.5-11.4-6.5
	c-6.6-3.7-12.9-8.1-18.6-13.1c-3.5-2.8-7.3-5.3-11.3-7.5c-10-5.7-20.2-11.4-30.4-16.9c-6.7-3.6-13.5-7-20.2-10.5
	c-7.9-4.1-16-8-23.8-12.3c-4.2-2.3-8.3-4.9-12.3-7.6c-8.2-5.5-16.6-10.9-24.4-16.9c-11.8-8.9-21-20.9-26.6-34.5
	c-0.3-0.9-0.6-1.9-0.8-2.8l0.9-0.5c0.8,1.1,1.6,2.2,2.4,3.3c4.9,6.8,9.4,14.1,14.9,20.5c6.6,7.8,15.2,13.2,24.3,18
	c2.1,1.1,4,2.6,6.3,4.1c1.9-12.4,7.1-22.4,18.3-29c-4.9-2.1-9.4-4-13.9-6c-14.1-6.6-27.1-14.7-37.3-26.7c-4.7-5.5-8.3-11.7-9.5-19
	c-0.2-0.9-0.6-1.8-1-2.6L223,783.7z"
      />
      <path
        d="M227.8,316.1c8.9,1,9.1,1.1,8.9,4.2c-2.8-0.2-5.6-0.5-8.3-0.6c-2.4-0.3-4.7,1.1-5.6,3.3c-1.5,3.4-3.1,6.8-5,10.9
	c-0.9-4.7,1.3-8.1,2.7-12.8c-4.7,2.4-8.8,4.3-12.6,6.5c-7.7,4.6-13.6,10.7-16.1,19.6c-2.7,9.8-0.4,18.8,5.3,26.7
	c4.9,6.8,10.5,13,15.8,19.4c4,4.9,4,4.9,10.7,4.2c0.6-6.6,0.8-13.4,5.1-19.1c-6.3,27.9,5.8,50.1,21.4,71.2
	c-10.4-0.8-23-24.4-26.5-49.9c-4.9,0.7-9.7,0.1-13.6-3.2c-2.4-2.2-4.8-4.5-7-6.9c-6.2-6.5-11.8-13.4-14.8-21.9
	c-5.1-14.3-0.5-29,11.5-38.6c6.5-5.1,13.8-9,21.7-11.5c2.6-0.9,4.5-4,6.6-6.4c-5.9-0.3-11.9,0.8-17.2,3.4c-11,5-20.1,12.4-26.1,23.1
	c-6.6,11.7-8.2,24.3-6.2,37.4c2.8,17.6,10,34.3,20.9,48.4c4.6,6,9.9,11.4,15.5,17.9c-2-0.9-3.5-1.1-4.5-2.1
	c-4.4-4-9.1-7.9-12.9-12.4c-9.1-10.8-14.9-23.5-19-36.9c-5.2-17.2-6.2-34.3,2.1-51c8.8-17.8,28.2-31.1,49.1-31.3
	c1.1,0.1,2.3-0.1,3.2-0.7c10.6-7.9,22.9-10.5,35.7-11.1c3-0.1,6.1,0,9.2,0c0.2-2.3,0.3-4.5,0.5-6.9c-13.7-4.2-25.4,1-37.1,7.9
	c2.2-3,11.4-7.9,17.5-9.6c6.3-1.7,12.9-1.8,19.2-0.2c4.2-5.6,7.7-11.2,12.1-16c5.5-5.9,12.6-9.3,20.3-11.8c10-3.2,20.6-4.1,31-2.7
	c8.5,1,17,2.2,24.6,6.6c2.2,1.3,3.9,3.2,3.5,6.3c-12.6-6.6-26.7-9.7-41-9c-15.1,0.6-29.3,4.5-39.8,16.5c-2.4,3-4.5,6.1-6.3,9.5
	c-0.5,0.8-0.2,2.1-0.3,3.4c-3,0.5-1.7,3.5-2.5,5.7c3.3,1.9,8.2,1.3,10.3,6.4C267.2,298.1,245.9,298.9,227.8,316.1z"
      />
      <path
        d="M476.9,386.3c0.4,0.1,0.9,0.3,1.3,0.6c6.6,6.5,14.7,10.7,22.8,14.9c9.4,4.9,18.9,9.4,28.5,13.8c8.1,3.7,17,5.5,25.9,5.2
	c10-0.4,20.1-0.9,30.1-1.4c1.1-0.1,2.1-0.4,3.1-1c-5.6-0.5-11.1-0.8-16.7-1.4c-16.8-1.9-32.5-6.8-46.8-16.4
	c-19.4-12.9-37.9-26.9-55.6-42c-8.3-7.1-15.5-15.5-23.3-23.3c-4.6-4.6-9.4-9-14.8-14.2c1.6,0.5,3,0.4,3.8,1.1
	c5.7,4.9,11.6,9.5,16.8,14.9c11.7,12.5,24.7,23.8,38.6,33.7c10.9,7.8,21.7,15.8,32.6,23.6c9.3,6.7,19.3,12.2,30.6,14.8
	c8.3,1.9,16.8,3.3,25.2,4.2c7.8,0.7,15.6,1,23.4,0.9c4.4-0.1,8.7-0.7,13-1.7c-12.6-5.4-22.7-14.3-32.9-23.1c1.4,0.7,2.7,1.5,3.9,2.4
	c10.9,9.2,23.4,15.9,35.7,22.9c8.8,5,16.6,11.6,24.8,17.5l-0.6,0.9l-26.8-19.3l-0.1,1c-1.5,0.9-2.9,2.2-4.6,2.7
	c-13.4,4.1-27.3,5.4-41.3,6.2c-7.9,0.5-15.8,0.6-23.8,0.4c-9.7-0.2-18.7-3.4-27.3-7.7c-9.7-4.8-19.5-9.6-28.7-15.3
	c-6.3-3.9-11.7-9.2-17.6-13.9L476.9,386.3z"
      />
      <path
        d="M648.9,365.2c-2-3-3.9-6-5.9-9.1c-7.5-11.8-14.5-23.8-22.5-35.2c-6.5-9.2-13.7-18.1-22.9-24.8c-8.9-6.4-18.8-10.2-29.9-10.2
	c-12.7,0-25.4,0.5-38.1,0.5c-4.5-0.1-9.1-0.5-13.6-1.2c-1.4-0.3-2.7-1.1-3.6-2.3c0.5-0.1,1.1-0.5,1.4-0.3c4.8,3.4,10.4,2.5,15.7,2.5
	c12.8,0,25.6-0.8,38.4-0.4c6.9,0.2,13.8,2.2,19.6,3.3c-10.1-7.3-22.6-12.8-35.3-17.7l0.2-1c1.4,0,2.9,0.2,4.3,0.4
	c15.7,4.4,30.2,11.4,42.8,21.9c3.9,3.2,7.6,6.6,11.4,10c5.3,4.8,12,6.6,18.5,8.6c4.6,1.4,9.5,1.8,14.3,2.7c9.9,1.9,19,6,27.5,11.2
	c12.9,8,24.5,17.8,34.6,29.2c1.1,1.3,2.1,2.7,2.9,4.3c-6.7-6.2-13.3-12.6-20.1-18.7c-11.5-10.4-24.5-18.5-39.2-23.4
	c-3.8-1.2-7.8-1.4-11.7-2.4c-5.9-1.4-11.8-3.1-17.7-4.7c-1-0.3-1.9-0.7-3.2-1.2c4.1,5.6,8,10.8,11.7,16.1c3.5,5.1,6.8,10.5,9.9,15.8
	c1.1,1.8,2,3.1,4.3,3.6c19.7,4.7,35,16.5,48.8,30.7c5.4,5.6,10.6,11.5,15.4,17.6c-3-3-6.1-5.9-9-9c-8.8-9.5-17.8-18.7-28.6-26.1
	c-7.9-5.5-16.7-9.6-26-12.2c-0.8-0.2-1.6-0.3-2.4-0.4l9.2,21.1L648.9,365.2z"
      />
      <path
        d="M385.2,346.7c-0.7-3-1.3-5.4-1.9-7.8c-2.7-11.4-6.4-22.2-15.1-30.6c-9.4-9.1-20.8-13-34.1-13.6c1.9,4.3,3.2,8.1,7.7,10
	c4,1.6,7.6,4.1,11.5,6.1c1.3,0.6,2.7,1,4.1,1c3.4,0,6,1.5,7.1,4.6l-6.5-3.7l-0.6,0.8c10.6,8.3,17.8,18.7,20.6,31.5
	c-3.6-6.5-6.8-13.6-11.1-19.8c-3-4.3-7.5-7.6-11.5-11.3c-0.8-0.8-2-1.2-3.1-1.1c-7.8,2.6-12-2.2-16.2-7.4c-1.1-1.6-2.7-2.8-4.5-3.4
	c-8.1-2.3-16.3-4.3-22.2-5.8l21.1-2.7c-0.4-3.7-0.7-7.1-1.1-10.7c-12.5,0.8-21.2,8.2-30.8,15c4.6,1.4,3.8,4.4,2.7,7.4
	c-1.6,4.7-1.4,4.4,2.7,6.6c3.6,1.9,6.7,4.6,10.1,6.9l-0.7,1c-1.2-0.9-2.3-1.8-3.6-2.5c-2.7-1.7-5.3-3.4-8.2-4.8
	c-2.3-1.1-3.2-2.6-2.5-5c0.2-0.8,0.4-1.6,0.7-2.4c1.2-2.7,1.4-4.8-2-5.9c-0.5-0.2-0.8-0.9-1.3-1.6c9.5-6.5,18.2-14.1,30.1-15.9
	c1.2-0.4,2.1-1.3,2.5-2.4c1.3-4.7,5.1-7,9.6-5.3c3.5,1.3,6.9,2.9,10.3,4.5c0.8,0.5,1.5,1.3,1.7,2.2c0.6,3.6,3.3,4.3,6.2,5.3
	c12.5,4.4,24.3,10,33.3,20.2c5,5.7,8.6,12.6,10.6,19.9c3.6,12,3.7,24.8,0.4,36.8c-0.9,2.8-2.2,5.5-4,7.9c-0.7,1.1-2.4,1.6-3.9,2.1
	c4.3-10.2,7-20.4,6.2-31.3L385.2,346.7z M398.3,340.4c-0.3-1-0.5-2-0.6-3c-0.1-3.7-1.2-7.8,0.1-11s0.5-5.5-0.6-7.9
	c-3.3-7-8.2-13.2-14.4-17.9c-13.6-10.3-29-16.1-45.9-18.1c-4.8-0.6-5.2-0.1-5,4.5c0,0.9,0.2,1.9,0.4,2.8c0.3,2.6,1.5,3.5,4.3,3.7
	c21.4,1.6,36.8,11.7,44.4,32.2c2.3,6.1,3.7,12.5,5.6,19.2L398.3,340.4z M333.4,300.8c-0.7-1.9-1.2-3.4-1.9-4.8
	c-0.3-0.6-1.1-1.5-1.7-1.4c-4.2,0.3-8.4,0.8-12.6,1.2l0,0.7L333.4,300.8z M349,282.8c-5.7-7.4-12-8.2-15.2-2.2L349,282.8z
	 M341,305.3c3.4,5.6,6.6,7.2,11,5.9L341,305.3z"
      />
      <path
        d="M423.7,797.5c7.8,1.8,15.7,3.4,23.4,5.3c12.5,3,24.4,7.3,35.2,14.5c7.2,4.7,13.4,10.7,18.5,17.6c1,1.4,1.9,2.5,4.1,2
	c1.4-0.1,2.7,0.3,3.7,1.2c4.7,3.9,9.1,8.2,13.8,12.1c7.9,6.6,12.1,15.8,17.3,24.4c6.4,10.7,13.6,20.7,25.6,26.5
	c4.7,2.3,8.5,6.5,12.9,9.6c2.1,1.5,4.6,2.3,7,3.4c0.7,0.2,1.4,0.5,2,0.9c4.2,4.2,8.3,8.4,12.5,12.6c0.3,0.5,0.6,0.9,0.8,1.5
	l-0.8,0.8l-13.2-10.9c-0.5-0.4-0.8-1.3-1.3-1.3c-5.9-0.1-8.8-5-13.1-7.8c-4.6-3-8.9-6.5-13.6-9.2c-7.2-4.1-11.9-10.5-16.4-16.9
	s-8-13.9-12.6-20.5c-3.4-4.9-7.8-9.1-12-13.4c-3-3-6.1-5.8-9.4-8.4c-1-0.8-2.7-0.7-4-1.2c-1.6-0.6-3.7-0.9-4.5-2.1
	c-11.8-17.4-29.3-25.8-48.7-31.5c-7.8-2.3-15.7-4.2-23.5-6.4c-1.3-0.5-2.6-1-3.9-1.7L423.7,797.5z"
      />
      <path
        d="M283.3,328.4c3.5,3.4,4.7,8,5.8,12.6c3.4,14.9,4.1,30.2,2,45.3c-2.2,16.3-6.5,32-14.5,46.6c-4.8,8.6-9.9,16.7-17.9,22.7
	c-0.8,0.6-1.6,1.2-2.4,1.8l-0.6-0.5c2.6-3.9,5-7.8,7.8-11.5c10.7-14.6,17.4-30.9,21.2-48.4c4.1-18.9,4-38,0.7-57
	c-0.4-2.5-1.4-4.9-2.1-7.3c-0.4-1.3-0.6-2.6-0.9-3.9L283.3,328.4z"
      />
      <path
        d="M266.8,462.9c1.2,3.5,2.3,6.5,3.2,9.5c2.5,8.3,1,13.5-6.3,18.3c-6.9,4.6-14.2,8.7-21.5,12.8c-8.3,4.7-15.6,10.9-21.6,18.4
	c-1,1.3-2.3,2.8-3.8,1.4c-0.6-0.6-0.6-3,0.1-3.9c3.2-4.5,6.5-8.8,10-13c7.4-8.7,16.5-15.7,25.8-22.3c6.6-4.7,11.3-10.7,13-18.8
	C266,464.5,266.3,463.6,266.8,462.9z M267.3,469.9c-2.5,9.4-9.2,15.4-16.6,20.8c-3.8,2.8-7.5,5.8-11.2,8.6l0.7,1
	c6.1-3.6,12.1-7.3,18.2-10.7C266.1,485.2,267.7,478,267.3,469.9z"
      />
      <path
        d="M357.4,966.8c-17.4-2.5-32.4-10.2-46.4-19.8c-11.2-7.7-21.5-16.7-32.5-24.8c-7.6-5.6-15.7-10.6-23.6-15.8
	c-1.1-0.7-2.2-1.4-3.3-2.1l0.5-1.3c2.9,0.9,6,1.3,8.5,2.9c8.2,5.1,16.5,10.2,24.1,16.1c13.3,10.2,26.4,20.8,41.5,28.3
	c9.2,4.6,18.4,9.3,27.6,14.1C355,965.2,356.2,966,357.4,966.8z"
      />
      <path
        d="M301,331.5c1.2,4.2,2.7,8.4,3.7,12.6c2.7,11.9,2.3,24,1,36c-1.6,14.1-4.1,28.1-8.7,41.5c-2.5,7.5-5.7,14.8-9.6,21.6
	c-2.6,4.5-7.3,7.8-10.9,11.8c-1.6,1.8-2.9,3.9-4.5,5.7c-0.7,0.6-1.4,1.2-2.1,1.7c-0.3-0.9-1.1-2.2-0.8-2.8c1.2-2.2,2.7-4.2,4.5-6
	c7.2-6.8,13.1-14.3,16.4-23.8c5-14.1,8.7-28.7,10.9-43.6c1.7-12,2.9-23.9,1.6-36c-0.6-5.1-1.8-10.2-2.6-15.3c-0.2-1-0.2-2.1-0.3-3.2
	L301,331.5z"
      />
      <path
        d="M637,429.2c0.2,3.5,0.4,7,0.7,10.9c6-0.5,9.7-4.8,13.6-8.6l0.8,0.4c-0.5,1.2-0.7,2.6-1.5,3.6c-3.1,3.8-6.7,6.6-11.6,7.8
	c-1.3,0.3-2.3,1.2-2.9,2.4c-3.1,8.8-9.6,14.3-17.7,18c-11.3,5.2-23.1,5.1-35,2.7l-0.1-0.9c1.6-0.1,3.2-0.2,4.8-0.3
	c5.6-0.2,11.3,0.2,16.8-0.7c10.9-1.7,20.4-6.1,26.4-16.1c0.3-0.5,0.6-1.1,0.8-1.7c0.2-0.6,0.3-1.1,0.4-1.7l-19.3,2.5l-0.2-1l18.7-5
	c-3.3-6.1-8.6-9-15.7-9.9c-22.1-2.9-44.5,0.7-64.5,10.4c-0.2,0.1-0.4,0.1-0.9-0.6c4.5-2.1,8.9-4.5,13.6-6.2
	c17.3-6.4,35.9-8.1,54-5.2c6.8,1.1,11.7,5,15.4,10.9c0.7-4.1,1.4-8,2.1-11.9L637,429.2z"
      />
      <path
        d="M575.5,1178.4c-1.1,2.2-2.3,4.3-3.7,6.2c-7.2,8.8-8.4,19.4-9.4,30c-0.2,2-0.3,4-0.7,5.9c-0.6,2.1,0.3,4.4,2.1,5.6
	c2.8-12.4,4.2-25.2,13.6-34.7l1,0.3c-0.1,1-0.2,2-0.4,3c-2.8,10.6-5.4,21.1-4,32.2c0.3,2.4,1.1,4.7,1.6,7.1c0.3,1.4,0.4,2.8,0.6,4.4
	c-3.3-0.7-5.5-3.4-5.9-7.1c-0.5-5.4-0.8-10.9-1.2-16.6c-1.1,5.5-2.1,10.8-3.2,16c-0.2,0.7-0.5,1.5-0.8,2.2c-0.7-0.5-1.8-0.8-2.1-1.4
	c-3.3-6.4-4.8-13.6-4.2-20.8c1-11.3,4.3-21.8,12.3-30.3c1-1,2.1-1.9,3.1-2.9L575.5,1178.4z"
      />
      <path
        d="M471.7,1036.4c-0.7-0.4-1.5-0.9-2.2-1.3c-11-7.1-21.8-14.4-33-21.3c-6.2-3.8-12.9-6.5-19.3-10.1
	c-7.6-4.3-15.1-8.9-22.5-13.5c-7.7-4.8-15.3-9.7-23-14.6c-0.9-0.6-1.8-1.3-2.6-2l0.5-1c2.2,0.7,4.4,1.6,6.4,2.6
	c10.4,6,20.8,12.2,31.2,18.1c7.8,4.4,15.7,8.6,23.7,12.6c10.8,5.2,20.8,11.8,29.7,19.6c3.4,3,6.9,6,10.3,9c0.4,0.4,0.7,0.9,1,1.3
	L471.7,1036.4z"
      />
      <path
        d="M266.8,561.5c-9,16.2-10.2,32.9-7.1,50.4c3.3,18.6,16.1,29.7,30.1,40.5c-0.6,0-1.2,0-1.8-0.2c-17-6.1-27.2-18.5-31.6-35.7
	c-3.9-15.3-2.9-31.4,3-46.1c1.1-2.6,2.6-5.1,4.3-7.3C264.3,562.3,265.7,562,266.8,561.5z"
      />
      <path
        d="M478.4,933.4c-0.9-0.2-1.9-0.4-2.7-0.8c-4.5-2.7-8.9-4.1-14.4-3.1c-2.8,0.5-6.1-1-9.2-1.9c-4.5-1.3-8.8-3.2-13.3-4.2
	c-8.6-1.7-13-8-17.5-14.6c-2.2-3.3-4.8-6.4-7.1-9.7c-3.4-4.8-8.6-6.6-13.8-8.5c-5.8-2.2-11.7-4.1-17.9-6.8c1.1-0.3,2.5-1.2,3.4-0.9
	c8.4,2.8,17.2,4.8,24.8,9c5,2.8,8.4,8.7,12.3,13.4c2.1,2.6,4.1,5.4,5.9,8.2c3.1,4.8,8.3,5.6,13,7.2c5.5,1.8,11.1,3.4,16.7,5
	c0.9,0.2,1.8,0.2,2.7,0.1c5.3-0.9,10.7,0.4,15.1,3.5c1.1,0.8,1.7,2.1,2.6,3.2L478.4,933.4z"
      />
      <path
        d="M767.7,362.7c0.6,0.7,1.1,1.4,1.5,2.1c5.9,11.2,11,22.6,12.4,35.4c0.2,1.8,0.9,3.4,2,4.8c7.7,9.1,8.5,19.9,7.3,31.1
	c-0.7,6.8-2.8,13.5-6,19.5c0.9-7,1.9-14,2.6-20.9c0.9-8.5,1.2-16.9-3.1-24.8c-0.3-0.5-0.6-1.1-0.9-1.6c-0.2-0.4-0.5-0.7-1-1.4
	c-1.1,4.7,0,9.6-4.2,13.6c1.7-20.6-2.6-39.5-11.2-57.5L767.7,362.7z"
      />
      <path
        d="M245.7,1085.2c-0.6,0.9-1.3,1.7-2,2.5c-3.7,2.9-3.9,6.9-0.4,10.4c2,1.9,3.9,3.9,5.7,6c4.6,5.9,3.8,12.5-2.2,16.9
	c-2,1.2-3.2,3.4-3.2,5.8c0.2,2-1.3,3.8-3.3,4c-0.1,0-0.2,0-0.3,0c-6.6,0.7-7.5,3.6-6.9,10.6c0.3,1.3,0.4,2.7,0.5,4
	c-2.4-3.7-5.8-7-4.8-11.9c0.9-4,4-5.9,7.8-7.2c1.2-0.6,2.2-1.7,2.4-3c0.1-2.3,1.5-4.3,3.6-5.2c8-3.9,5.7-10,1.3-14.2
	c-2.9-2.7-5.6-5.4-6.5-9.5C236,1088.8,239.1,1084.9,245.7,1085.2z"
      />
      <path
        d="M542.3,1031.5c1.8,0.7,3.8,1.1,5.4,2.2c6.2,4.6,12.4,9.4,18.5,14.3c7.1,5.8,14.2,11.7,21,17.8c3.7,3.3,6.8,7.1,10.3,10.6
	c3.3,3.3,6.8,6.4,10.2,9.5c1.6,1.5,3.3,2.9,4.9,4.3l-1,1.2c-2.4-1.7-5.2-3-7-5.2c-7.3-8.8-17-14.8-25.7-22
	c-8.5-7-16.6-14.4-24.9-21.6c-3.2-2.8-6.6-5.3-9.9-8c-0.8-0.6-1.6-1.3-2.4-1.9L542.3,1031.5z"
      />
      <path
        d="M753.3,413.7c-0.9-4.8-1.7-9.5-2.7-14.3c-1.3-6.2-3.6-11.8-9.9-14.6c4.2,0.7,6.7,3.5,9.2,7.3c1.1-6.5,0-12-2-17.4
	c-6.5-17.6-14.3-34.5-24.8-50.1c-0.4-0.6-0.6-1.3-0.5-2c0.6,0.2,1.1,0.6,1.5,1c11.8,15.7,22.2,32.2,27.7,51.3
	c1.1,3.9,2.5,8.8,1.2,12c-3,7.1-0.3,13.6,0.1,20.4c0.1,2.1,0.2,4.2,0.3,6.3H753.3z"
      />
      <path
        d="M260.6,433.5c-17.1-10.6-23.1-26.8-22.6-45.8c0.2-9.5,1.5-19,8.6-26.5c0,0.6-0.1,1.2-0.3,1.7c-6.8,15.8-6.2,31.9-0.5,47.6
	c2,5.5,6.4,10.1,9.7,15.1C257.3,428.3,259,430.9,260.6,433.5z"
      />
      <path
        d="M553.8,1182.2c-1.7,14.5-3.4,29-5.1,43.5c-1.6-1-3.3-2-4.8-3.1c-2.7-1.9-3.2-4.9-2.7-7.6c0.9-4.7,2.2-9.4,3.7-14
	c1.5-4.9,3.3-9.7,5.2-14.5c0.8-1.7,1.7-3.3,2.7-4.8L553.8,1182.2z"
      />
      <path
        d="M253.9,716.2c3.8,2,7.5,4.2,11.3,6c7.4,3.5,15.5,4.8,23.5,5.6c4.5,0.5,9,0.6,13.5,1.3c3.1,0.5,6.1,1.9,9.4,3
	c-5.1,4.2-35.1,0.5-45-4.9C261.5,724.5,256.2,722,253.9,716.2z"
      />
      <path
        d="M416.5,766.7c-2.1,2.2-4.5,2-6.9,1.8c-1.2-0.1-2.7-0.4-3.6,0.2c-7.3,4.7-14.6,9.3-20.2,16.1c-1.8,1.9-2.6,4.4-2.2,7
	c0.8,5-1.5,8.2-5.8,10.5c-2.6,1.3-5.1,2.9-7.8,4.2c-1.1,0.4-2.3,0.5-3.5,0.5l-0.4-1.1c1.7-1.2,3.4-2.5,5.2-3.8
	c1.3-0.9,2.6-1.8,4-2.5c3.2-1.7,5-4,4.3-7.9c-0.9-4.8,1.6-8.7,4.9-11.5c6.3-5.2,12.9-10.1,19.9-14.5
	C408.8,762.8,411.5,763.5,416.5,766.7z"
      />
      <path
        d="M524.8,1169.4c2-1.3,3.7-1.3,5.1,1.1c0.8,1.5,1.8,2.9,2.6,4.4c3,5.7,2.7,8.3-1.9,13c-0.8,0.8-1.9,1.2-3,1.3
	c-4.5-0.2-7.7,2-10.5,5c-6.4,6.8-8.7,6.8-15.7,0.5c-8.1-7.4-9.7-17.1-9.7-27.2c0-4,3.1-6.5,8-6.4c3.4,0,5.3-1.3,7.2-3.8
	c2.9-3.5,6-6.8,9.3-10c0.7-0.7,1.5-1.2,2.4-1.7l0.8,0.8c-2,2.5-3.9,5-5.9,7.4c-1.9,2.5-3.8,5-5.6,7.3l13,4.7l-0.4,1
	c-2.9-1-5.8-2.1-8.8-3c-5.1-1.4-9.6,1.1-11.4,6.3c-0.5,1.7-1.2,3.3-2.2,4.8c-1.8-5.3,3.5-8,4.5-12.4c-7.8-0.8-10.3,1.7-9.6,9.3
	c0.6,7,1.9,13.9,6.9,19.4c1.7,1.9,3.6,3.6,5.7,5.1c3.4,2.5,5.3,2.1,8.2-1c1.8-2.1,3.8-3.9,6-5.5c1.8-1.1,3.8-1.7,5.9-1.9
	c4.5-0.5,8.1-5.8,6.3-9.9c-1-2.3-2.2-4.6-3.8-6.6C527.7,1170.3,526,1170,524.8,1169.4z"
      />
      <path
        d="M834.6,1281.8c-3.6-4.4-7.4-8.6-10.8-13.2c-2.1-2.9-4.5-4.8-8.1-5.4c-5.8-1-9.1-4.7-11-10.2c-1.1-3.1-3-6-4.6-9
	c-1.5-2.7-3.4-5.2-4.6-7.9c-2.7-6.2-7.7-10.7-12.3-15.2c-5.8-5.6-9.9-11.8-11.2-19.7c-0.2-1-0.5-1.9-0.9-2.8c-0.8,0-1.5,0.2-2.2,0.3
	c-2.2,0.8-4.6,0-5.8-1.9c-1.1-1.3-2.3-2.4-3.7-3.5c-2.6-2.3-4.8-3.2-8.1-0.5c-3.5,3-8.9,2.5-13-0.6c0.6,0.1,1.3,0.2,1.9,0.4
	c4.1,1.9,9,0.8,12-2.6c1.8-1.8,3.2-2,5.1-0.5c2.2,1.8,4.8,3.2,6.6,5.3c1.7,1.9,3.2,2.6,5.5,1.6s3.4,0.2,4,2.2
	c0.6,2.2,0.9,4.7,1.9,6.7c1.6,3.7,3.7,7.3,6.1,10.6c3.7,4.6,8.6,8.3,11.9,13.1c4.9,7.2,9.2,14.8,12.9,22.7c2.3,4.9,4.5,8.7,10.2,9.6
	c3.6,0.6,6.8,2.7,8.6,5.9c2.1,3.2,4.8,5.9,7.2,8.9c1.2,1.5,2.5,3.1,3.7,4.6L834.6,1281.8z"
      />
      <path
        d="M467.1,377.2c-1.9-1.9-4.1-3.6-5.7-5.7c-3.5-4.6-6.4-9.6-9.9-14.2c-3.9-5.1-9.2-8.2-15.1-10.4c-3.4-1.3-6.4-3.5-9.6-5.3
	c-1.4-0.8-2.8-1.5-4-2.1c-0.5,2.8-0.8,5.6-1,8.5c0,1.3,1.1,2.5,1.8,3.9c-2.8-0.9-7.4-6.5-7.6-9c-0.2-2.2,1-2.8,3-2.4
	c0-1.1,0.1-2.1,0.3-3.2c0.5-1.5,2-2.7,3.1-1.2c3.8,5.7,10.3,5.5,15.7,7.4c8.8,3,15,8.9,19.9,16.3c3.6,5.4,6.5,11.2,9.8,16.9
	L467.1,377.2z"
      />
      <path
        d="M679.7,1021.3c-1.2-1.8-2.2-3.1-2.9-4.5c-0.2-0.6-0.2-1.2-0.1-1.9c1.1-11.3,2.2-22.7,3.3-34c0.3-1,0.6-1.9,1-2.8
	c1.3,2.2,2.6,3.7,2.9,5.3c0.7,3.4,1,6.9,1,10.3c-0.2,6.6-0.7,13.3-1.5,19.8C683.1,1016.6,681.8,1019.3,679.7,1021.3z"
      />
      <path
        d="M516,1414.3c-5.4,2-7.3-2.6-10.3-4.8c-2.1-1.6-3.9-3.6-6-5.1c-5.7-4-11.7-3.1-17.7,2.5c-1.7,1.6-3.3,3.3-4.8,5
	c-2.5,2.9-2.5,2.9-7.6,2.5c3.8-4,6.8-7.9,10.5-11c2.6-2.2,6-3.7,9.3-5.2c3.1-1.4,6.2-0.6,9.2,0.9
	C505.5,1402.8,511.1,1407.9,516,1414.3z"
      />
      <path
        d="M843.3,1285.3c4.7,2.7,9.5,3.1,14.8,2.9c2.8-0.1,5.7,1.8,8.4,3.2c3.5,1.8,6.7,4,10.1,5.8c3.2,1.7,6,5.8,5.4,9.3
	c-0.2,1.4-1.6,2.8-2.8,3.7c-1.6,1.2-3.7,1.6-5.3,2.7c-5.2,3.4-6.2,8.6-2.5,13.6c1.7,2.2,3.5,4.2,5.5,6.2c3.4,3.5,6.2,7.4,7.2,12.2
	c1.9,9.8,8.2,17,13.8,24.7c1.8,2.4,3.3,5,5.2,7.9c-0.9,0-1.1,0-1.2-0.1c-5.2-7.4-10.6-14.7-15.5-22.3c-1.8-2.8-2.5-6.3-3.4-9.5
	c-1.7-6.2-5.9-10.6-10.2-15.1c-8.2-8.7-6.4-16.7,4.6-21.2c1.3-0.6,3.4-2.4,3.2-3.1c-0.7-2.5-1.7-5.5-3.7-7c-4-2.9-8.6-4.8-12.9-7.4
	c-2.5-1.4-5.4-2-8.2-1.6C850.9,1290.9,846.2,1289,843.3,1285.3z"
      />
      <path
        d="M219.5,373.3c0.6-2.1,0.7-3.4,1.3-4.5c7.4-13.1,16.9-24.2,30.5-31.3c6.4-3.3,16.7-3.8,21.3,1.3c-0.4,0.1-0.8,0.5-1.1,0.4
	c-11.6-3-20.9,1.8-29.3,8.9c-8.2,6.9-15.6,14.5-20.9,24C221,372.3,220.6,372.5,219.5,373.3z"
      />
      <path
        d="M528.5,1285.4c6.8-4.2,13.2-7.9,19.3-12c1.9-1.3,3.3-3.4,4.8-5.3c4.2-5.4,8.6-10.7,12.5-16.3c1.2-1.6,1.1-4.1,1.7-6.4
	c1.7,2.3,3,4.8,1.7,7.3c-2.2,4.3-4.7,8.5-7.4,12.5c-1.7,2.6-3.8,4.9-6.1,6.8c-7.5,6.1-15.2,11.9-24.9,14
	C529.8,1286,529.5,1285.8,528.5,1285.4z"
      />
      <path
        d="M295.7,835c-0.4-2,1.5-2.7,3.5-3c7.1-1,14.2-1.6,21.3-2.8c4.2-0.7,8.2-2.3,12.2-3.5c0.3-2.4-0.4-4.8,2.2-5.7
	c0.8,0,1.5,0.5,1.9,1.1c0.7,2.4,1.7,5-0.8,7c-1,0.8-2.5,1.1-3.6,1.9c-4.9,3.1-10.4,3.7-16,3.9c-2.8,0.1-5.6,1.4-8.4,2.1
	c-0.8,0.2-1.6,0.3-2.4,0.2C302.5,835.9,299.4,835.5,295.7,835z"
      />
      <path
        d="M436,1140.7c-7.2,0.6-11.4-3.4-14.6-8.7c-2.9-4.8,1.4-12.3,7.1-13c5.5-0.7,6.4-0.2,9.3,4.7c0.8,1.2,1.7,2.4,2.8,3.4
	c3,3,3.7,6.8,1.9,11.2C441.2,1141.7,438.1,1140.3,436,1140.7z M439.2,1137.3c1.7-4.9,1.6-5.2-1.8-8.7c-1.1-1.4-2.2-2.8-3.1-4.4
	c-0.4-0.5-0.5-1.3-1-1.6c-1.5-1.2-6,0-8.2,2s-2.4,4-0.8,6.4C427.9,1136.2,432.5,1138.9,439.2,1137.3L439.2,1137.3z"
      />
      <path
        d="M219.3,445.8c-6.9,1.2-10.2,6.4-12.7,12.1c-2.3,5.4-3,11.4-1.9,17.2c0.2-0.8,0.4-1.7,0.5-2.5c1.2-8.3,4.9-15.1,12.1-19.6
	c2-1.1,4.2-1.9,6.5-2.1c1.2-0.2,2.6,0.9,4.2,1.6c-1.3,2-2.5,3.5-3.4,5.1c-2.2,3.8-1.9,4.5,2.1,6.1c0.4,0.3,0.8,0.6,1.2,0.9
	c-0.5,0.5-0.8,1.1-1.3,1.3c-2.1,0.9-1.8,2.3-0.9,3.9c1.1,1.9,2.1,3.8,3.6,6.5l-5.4-0.8l-0.6-1.2l2.1-1.5c-0.9-2.3-4-4.6-1.3-7.9
	c-3.4-2.6-3.3-3.7,1.7-12.1c-2.1,0.1-3.7-0.1-5,0.4c-7.6,3.4-11.9,9.6-13.4,17.7c-0.7,3.9-0.6,8-0.9,12c-0.1,1-0.3,2.1-0.4,3.1
	l-1.2,0.1c-0.6-2.8-1.4-5.5-1.8-8.4c-1.1-8.7-0.6-17.2,4.5-24.7c1.8-2.5,3.9-4.7,6.4-6.5c1.4-1.1,3.4-1.2,5.2-1.7L219.3,445.8z"
      />
      <path
        d="M444.4,381.3c1.7-1.1,3.3,0.3,4.4,1.6c4.4,5,8.8,10,12.9,15.2c7.2,9.4,13.9,19.2,22.8,27.2c16.3,14.9,35.3,22.5,57.5,21.9
	c0.8-0.1,1.7,0,2.5,0.3c-7.3,1.3-14.8,1.1-22.1-0.5c-16.4-3.1-30-11.6-41.3-23.4c-7.5-7.7-13.6-16.7-20.4-25.1
	c-3.7-4.6-7.3-9.3-11.2-13.8C448.1,383.4,446.1,382.5,444.4,381.3z"
      />
      <path
        d="M62.7,865.5c4.1-1.7,8-3.4,11.9-5.1c9.3-3.9,18.6-7.6,28.9-7.1c3.6,0.1,7.1,1.5,9.8,4c-0.4,0.3-0.7,0.6-0.8,0.6
	c-14.6-3.3-27.7,1.5-40.6,7.3c-2.3,1-5.2,0.9-7.9,1.1C63.5,866.3,63,865.7,62.7,865.5z"
      />
      <path
        d="M226,484.5l21.4-13.3c-3.1-4.5-6-8.7-8.7-13.1c-0.6-1.1-0.8-2.4-0.6-3.6c0.2-1.3,0.9-3.1,1.9-3.6s2.8,0.2,4.1,1.1
	c-1,0.5-2.8,1.1-2.8,1.7c0,1.9,0.5,3.7,1.4,5.4c1.5,2.3,3.2,4.4,5.1,6.5c0.8,1,1.8,1.8,2.6,2.8c1.6,2,1.8,3.9-0.6,5.3
	c-5.8,3.3-11.6,6.4-17.4,9.5c-1.8,1-3.8,1.6-5.7,2.4L226,484.5z"
      />
      <path
        d="M503.2,293.3c17.1-0.3,34.2,0.1,50.7,6c7.8,2.8,15.1,6.8,21.6,11.9c10.6,8.1,20.8,16.8,33.9,21l-0.3,0.8
	c-1.2-0.4-2.3-0.8-3.5-1.2s-2.4-0.9-3.5-1.4c-9-3.4-16.4-9.3-23.6-15.4c-16.7-14.2-36.5-19.4-57.8-20.8
	C514.8,293.9,509,293.6,503.2,293.3z"
      />
      <path
        d="M285.5,903.3c3.9,7.2,9.9,12.3,15.8,17.4c2.8,2.2,5.8,4.3,8.8,6.2c7.9,5.2,16.9,5.8,25.9,5.3c4.2-0.2,8.4-0.8,12.7-0.8
	c1.7,0,3.4,0.6,4.8,1.7c2.4,1.9,4.7,3,7.8,2.8c1.7,0,3.4,0.3,4.9,1c5.7,2.5,11.3,5.1,17,7.7l-0.4,1c-2.3-0.9-4.6-1.8-6.9-2.8
	c-2.9-1.3-5.7-2.7-8.6-4c-1.4-0.6-2.8-1.4-4.2-1.3c-4.3,0.3-8.5-1.2-11.5-4.2c-0.5-0.5-1.8-0.5-2.7-0.5c-3.3,0.2-6.5,0.4-9.8,0.8
	c-15.5,1.9-28.8-3-40.1-13.5c-3.8-3.6-7.4-7.5-10.8-11.6C286.8,907.1,286.3,905.1,285.5,903.3z"
      />
      <path
        d="M171.8,1303.8c-0.9-0.2-1.7-0.6-2.5-1c-12.9-8.1-20.7-19.2-21.1-34.9c-0.1-4-1.2-7.9-1.8-11.9c-0.1-0.7-0.1-1.5,0-2.2
	l1.2-0.2c0.8,3.2,1.8,6.4,2.5,9.7c0.9,4.8,1.3,9.7,2.4,14.4c2.1,9.1,7.8,15.7,15.6,20.7c1.7,1.3,3.3,2.7,4.7,4.2
	C172.4,1303.1,172.1,1303.4,171.8,1303.8z"
      />
      <path
        d="M222.6,960.9c-6.4-1.7-12.7-4-19.2-5c-8.1-1.3-16.4-1.6-24.6-2.3c-0.8,0-1.7,0-2.5,0.2c-6.2,1.1-7.9,4.3-5.1,9.9
	s7.5,9,13,11.6c6.8,3,14.1,4.7,21.5,5.1c1.6,0.2,3.2,0.6,4.8,1.3c-12.8,1.2-29.2-4.1-36.6-12c-1.9-2.1-3.4-4.5-4.5-7.2
	c-2.1-4.9-0.3-8.5,5-9.4c4.6-0.7,9.2-0.9,13.9-0.6c10.7,0.7,21.2,3,31.2,6.7c1.1,0.4,2.1,0.9,3.2,1.3L222.6,960.9z"
      />
      <path d="M244.9,1305.4c-0.7,11.5,2.3,22.3,6.2,33.4C241.3,1332.1,238.8,1313.8,244.9,1305.4z" />
      <path
        d="M222.9,783.6c0,0.7,0.2,1.6-0.1,2c-5.6,8-5.1,16.9-4.4,26c0.2,2,0,4.1,0,6.1l-1.5,0.3c-0.6-2.1-1.6-4.2-1.8-6.3
	c-0.4-5.4-0.4-10.9-0.2-16.4c0.1-4,1.8-7.7,4.7-10.4c0.8-0.7,2.2-0.8,3.3-1.2L222.9,783.6z"
      />
      <path
        d="M655.1,367.9c3.9,1.5,4.9,5.4,5.9,8.7c3.2,10,8.4,18.8,13.2,28c0.2,0.5,0.2,1.1,0,1.6c-0.5-0.4-1.1-0.9-1.6-1.4
	c-7.3-7.7-12.3-17.4-14.2-27.9C657.9,373.8,656.2,371,655.1,367.9z"
      />
      <path d="M352.6,367.5c3.9-12.1,7-23.9,3.8-36.6C364.5,336,361.7,361.2,352.6,367.5z" />
      <path
        d="M308,847.7c1.7-0.7,3.5-1.3,5.3-1.7c1.2-0.2,2.3-0.1,3.5,0.2c7.2,1.7,13.7-0.7,20.3-3.3c1.1-0.5,2.6,0,4,0
	c-0.9,1.2-1.5,2.8-2.6,3.4c-7.5,3.4-15.1,5.3-23.4,2.7C312.7,848.3,310.4,847.9,308,847.7L308,847.7z"
      />
      <path d="M233.8,925.1c11.6,4,20.1,12.1,28.1,20.9C250.5,941.9,242.7,932.8,233.8,925.1z" />
      <path
        d="M280.8,347c-14.4,5.3-20.5,17.1-23.5,30.9s-1.5,27,9.5,37.6l-0.8,0.6c-1.2-0.9-2.2-1.8-3.3-2.8c-5.7-6.7-7.9-14.5-8-23.2
	c-0.2-13.9,4-26.3,13.6-36.5C271.7,350.1,275.7,347.4,280.8,347z"
      />
      <path
        d="M570.9,993.5c0.1-0.9,0-2,0.5-2.6c5-5.6,10.4-10.8,17.9-12.8c1.2-0.2,2.4-0.3,3.7-0.3l0.5,0.8c-1.8,1.4-3.6,2.9-5.5,4.3
	c-4.5,3.3-8.9,6.7-13.5,9.9C573.5,993.4,572,993.2,570.9,993.5L570.9,993.5z"
      />
      <path
        d="M160.2,874.6c-3.2-4.6-1.6-8.7,0.4-12.8c0.6-1.1,1-2.4,1-3.8c-0.3-3.7-0.9-7.3-1.3-11c-0.1-1.2,0-2.4,0-4.1
	c0.7,0.6,1.2,0.8,1.3,1.1c2.7,6.3,5.1,12.5,1.8,19.4C161.7,866.9,161.2,870.8,160.2,874.6z"
      />
      <path d="M150.2,849c-4.9,10.4-23.6,12.3-30.7,7.2C130.1,856.4,140.4,855.1,150.2,849z" />
      <path
        d="M597.6,978.8c4.4-3.8,8.4-7.3,12.5-10.7c0.9-0.7,2.4-0.5,3.6-0.8c0.2,1.2,0.8,2.7,0.4,3.7c-1.9,4.9-6.3,6.9-10.8,8.4
	C601.7,979.9,599.6,979,597.6,978.8z"
      />
      <path
        d="M392.9,1098c-0.8,0.9-1.9,2.9-3.6,3.9c-0.7,0.4-3.1-0.9-3.9-2c-1.6-2.2-2.9-4.7-3.7-7.4c-0.3-1.2,0.9-3.3,2.1-4.1
	c0.9-0.6,3,0,4.2,0.7C391,1091.1,392.9,1094.4,392.9,1098z M387.9,1099.3c2.6-3.5,1.1-6.4-3.8-7.7L387.9,1099.3z"
      />
      <path d="M849.5,1352.5c7.5,5.3,13.4,12.6,16.7,21.2C860.6,1371.7,850.8,1359.5,849.5,1352.5z" />
      <path d="M954.9,1356.9c-5.6-0.8-20.8-13.7-21.7-18C939.2,1341.1,949,1349.2,954.9,1356.9z" />
      <path
        d="M413.6,787.2c3.1-2.1,5.5-4.1,8.2-5.5c4.5-2.3,7.7-5.8,10.5-9.8c0.4-0.6,1.7-0.7,2.5-1c0.1,1,0.7,2.1,0.3,2.8
	c-4.3,7.7-11.5,11.5-19.6,13.8C415.1,787.7,414.7,787.4,413.6,787.2z"
      />
      <path
        d="M594.5,1236.3c-1.3-1.5-3.6-2.8-3.7-4.4c-0.5-5.7-0.3-11.4-0.2-17.2c0-0.5,0.6-1,0.9-1.5h1l2.8,22.3
	C594.9,1235.8,594.7,1236.1,594.5,1236.3z"
      />
      <path
        d="M253.5,776.7c2.7-2.7,5.7-2.5,8.8-1.3c3.9,1.4,7.7,2.8,11.7,4.1c5.8,1.9,10.8,0,15-4.1c1.1-1,2-2.2,3-3.2
	c3.4-3.5,8.1-4,12.7-1.2c-0.2,0-0.4,0.2-0.6,0.2c-6.6-2.3-10.8,0.9-14.9,5.7c-3.3,3.9-8.5,5.7-13.5,4.6c-3.3-0.7-6.5-1.7-9.7-2.8
	C262,777.2,258.1,774.9,253.5,776.7z"
      />
      <path
        d="M135.6,839c-0.7,0.4-1.4,0.8-2.2,1c-3.1,0.5-6.1,1-7.7,4.5c-0.4,1-2.8,1.8-4.1,1.6c-5.4-0.7-11-1.2-16.1-3
	c-6.6-2.4-13.8-2.2-20.3,0.4c-3.3,1.3-6.9,1.8-10.3,2.7l-0.2-0.8c4.2-1.3,8.6-2.2,12.7-3.9c6.1-2.5,11.8-1.6,17.8,0.2
	c5.2,1.4,10.5,2.5,15.8,3.1c1.2,0.2,3.4-0.6,3.8-1.5c2.2-4.8,6.7-3.9,10.4-5.2L135.6,839z"
      />
      <path
        d="M778.6,1313.4c-2.7-4-5.3-8.1-8-12.1c-5.6-8.5-12.7-15.9-20.9-22l-2.3-1.7c-0.1-0.1,0-0.2-0.1-0.3
	c7.1,1.8,20.9,14.9,31.9,35.6L778.6,1313.4z"
      />
      <path
        d="M210.4,368.9l4.1,2.8c-7.2-12.6-12.3-24.6-0.2-37.1c-0.1,0.8-0.1,1.1-0.2,1.2c-6.8,7.3-7.5,15.7-3.7,24.4
	c2.1,4.7,5.1,8.9,7.8,13.3c0.5,0.8,1.1,1.6,1.7,2.4l-0.8,0.8l-9-7.4L210.4,368.9z"
      />
      <path d="M651.4,974.8c5.6-4.4,12.8-6.5,19.9-5.7C669.7,972,655.4,976.4,651.4,974.8z" />
      <path
        d="M184.8,766.6c1.2,0.5,2.4,1.2,3.6,1.9c2.1,1.6,3.9,3.5,6.1,4.9c2.2,1.2,4.4,2.2,6.8,3.1c-2.1,1.8-6.4,2.3-8.8,0.4
	C189.3,774.4,185.1,772.5,184.8,766.6z"
      />
      <path d="M151.2,807c7.1-0.5,14,1.4,21.5,1.9C167.9,813.1,158.9,812.3,151.2,807z" />
      <path d="M575,462.9c-12.9,5.7-23.7,13.2-32.2,24.1C543.3,479.6,564.5,463.6,575,462.9z" />
      <path d="M202.2,351.7c-2.9,16.8,4.1,27.9,19.5,35c-6.4-0.3-15.2-7.3-18.5-13.9S199.3,355.9,202.2,351.7z" />
      <path
        d="M748.3,418.3c-1.4-2.7-2.7-5.5-3.7-8.4c-2.9-6.8-7.8-12.5-14.1-16.3c-0.7-0.5-1.4-1-2-1.6l0.8-1.1c2.6,1.7,5.5,3.1,7.7,5.3
	c5.8,5.7,10.1,12.4,11.4,20.7C748.4,417.3,748.4,417.8,748.3,418.3L748.3,418.3z"
      />
      <path d="M310.7,489c-0.8-4.9-1.7-9.7-2.5-14.7C313,475.1,314.8,483.5,310.7,489z" />
      <path
        d="M605.9,1212.7c-0.3,0.3-0.5,0.7-0.8,0.9c-1.4,0.8-3.2,1.5-1.8,3.5c1.2,2,3.8,2.7,5.9,1.7c1.8-0.8,2.7-2.5,1.6-4.1
	c-1.6-1.9-3.3-3.7-5.2-5.3c2.7-0.3,6.9,3.1,7.1,6.4c0.1,2.4-2.6,4.9-5.3,4.8c-3.3-0.1-6.6-2.8-5.8-5.3c0.5-1.4,2.2-2.4,3.4-3.6
	L605.9,1212.7z"
      />
      <path d="M338.5,740.6c-5.1,0.3-12.1-2.7-15.9-6.5C325.7,733.4,329,734.7,338.5,740.6z" />
      <path d="M988,1394.8l-16.3-16l0.9-0.9c5.4,5.3,10.9,10.7,16.4,16L988,1394.8z" />
      <path d="M235.7,889c4.3,1.7,7.8,4.9,10.1,8.9C240.8,896.8,238.2,893,235.7,889z" />
      <path d="M726.3,390l-12.4-17.3C719.7,377.1,724,383.1,726.3,390z" />
      <path d="M561,925.9c3.9,3.5,7.7,6.9,11.5,10.4l-0.7,0.8c-5.2-2-8.2-6.4-11.7-10.2L561,925.9z" />
      <path d="M49.8,1218.8c-3.2,5.5-7.3,10.4-12,14.5l-0.7-0.6l11.8-14.6L49.8,1218.8z" />
      <path d="M470.9,279.4l12.2-1.1C479.9,282.8,473.3,283.6,470.9,279.4z" />
      <path d="M535.8,254.9c-4.8,1.1-9.6-1-10.9-4.6C529.3,250.3,532.2,253,535.8,254.9z" />
      <path d="M555.9,1234.4l8.9,6.7C560.1,1242.3,556.5,1239.6,555.9,1234.4z" />
      <path d="M723,410.5c4.3-3.8,13.3-3.3,17.9,1.5c-1.7-0.6-2.9-1-4-1.5C732.3,408.8,727.6,409.4,723,410.5L723,410.5z" />
      <path d="M543.3,1292.9c-0.4,4.5-0.7,9.1-1.1,13.6l-1.5-0.1c0.2-4.5,0.5-9.1,0.7-13.6L543.3,1292.9z" />
      <path d="M258.7,1337.8c4-1,5,2.9,7.7,4.3C261.6,1343.4,259.6,1342.2,258.7,1337.8z" />
      <path d="M375.5,289c-1.2-3.9-2.4-7.7-3.7-12.1C375.1,279,376.9,284.6,375.5,289z" />
      <path d="M425.8,307.5c-3.8,3.8-4.5,3.8-10.1-1.3L425.8,307.5z" />
      <path d="M383.6,374.4c1.4-7.8,3.5-10.1,6.5-8.1L383.6,374.4z" />
      <path d="M232.6,442.3c-2.9,2.3-5.8,1.9-9.4-0.5l0.1-0.9L232.6,442.3z" />
      <path d="M602.7,517.8l-15.2-0.7c0-0.3,0-0.6,0-0.8l15.1,0.7L602.7,517.8z" />
      <path d="M317.4,838.4h8v0.7l-8,0.4V838.4z" />
      <path d="M72.3,1202.6l-7.1,4.1l-0.7-1.2l7.1-4L72.3,1202.6z" />
      <path d="M780.2,450.9l4.4-7.5l1.1,0.7l-4.7,7.3L780.2,450.9z" />
      <path d="M254.9,714.8c0.9-3.4,1.9-3.6,6.3-1.8L254.9,714.8z" />
      <path d="M611.4,1237.6l4.4,4.4l-1,1.1l-4.6-4.4L611.4,1237.6z" />
      <path
        d="M635,563c0.5,2.8-2.2,5.5-6.2,5.8c-4.6,0.4-9.1-0.7-13-3.1c-6.1-3.7-12.2-7.5-18.6-10.8c-8.8-4.5-18.3-5.8-28.1-5.8
	c-11-0.2-22,1.6-32.3,5.4c-0.6,0.1-1.3,0.1-1.9,0c0.7-0.6,1.5-1.1,2.3-1.5c8.4-2.5,16.6-5.8,25.2-7.4c17.7-3.4,34.1,0.6,49.2,10.4
	c4.8,3.1,9.5,6.8,15.5,7.4C629.6,563.8,632.3,563.2,635,563z"
      />
      <path
        d="M722.9,843.7c-1.8-0.7-4.2-0.8-5.2-2.1c-3.3-4.3-8.6-5-12.8-7.8c-6.1-4-11.6-8.7-15.3-14.8c-4.4-7.2-8-15-12.1-22.5
	c-2.3-4.2-4.9-8.1-7.3-12.2c-0.4-0.7-0.4-1.6-0.1-2.4c0.9,0.7,2.2,1.1,2.8,2c4.1,6.1,8.4,12.1,12.1,18.5c5.1,9,10.1,18.1,18.4,24.6
	c2.9,2.3,6.2,4.1,9,6.4c3.8,3.1,7.3,6.4,10.9,9.6L722.9,843.7z"
      />
      <path
        d="M635.8,582.4c3.8,4.1,4,8.9,2.7,13.6c-1.4,5.3-3.2,10.5-5,15.6c-2.4,7-3.5,14.4-3.3,21.8c0.1,1.2-0.1,2.3-0.5,3.4
	c-1.9-3.4-3.2-7.1-2.6-10.8c1-6.1,2.5-12.1,4-18.1c1.4-5.3,3.3-10.4,4.7-15.7c0.5-2,0-4.2,0-6.2
	C635.8,584.8,635.8,583.6,635.8,582.4z"
      />
      <path
        d="M731.4,720c-2,2.1-4,1.4-6,0.6c-9.7-3.7-15-11-16.4-21.1c-0.5-2.5-0.7-5-0.6-7.6c0.6-9.9-2.1-19-6.6-27.7
	c-1.9-3.9-3.5-7.9-4.8-12.1c-3.3-10.9,2-24.9,11.9-31.3c-0.9,0.9-1.8,1.8-2.6,2.7c-9.2,10.2-12.2,21.5-6,34.5
	c1.5,3.2,2.6,6.7,4.3,9.9c3.8,6.9,4.8,14.3,4.7,22.1c0,6.6,0.7,13.1,3.8,19.1s8.5,9,14.5,11C728.7,720.2,730,720,731.4,720z"
      />
      <path
        d="M631.6,640.5c1.1-7,2.2-13.9,3.4-20.8c0.1-0.6,0.4-1.2,0.8-1.7c5.2-6.1,6.4-13.5,6.7-21.1c0-0.1,0.3-0.3,0.4-0.4
	c1.3,2.5,2,12.9,0.3,14.8c-5.5,6.2-4.5,14.2-6.7,21.3c-0.6,1.9-0.9,3.9-1.5,5.8c-0.5,1-1.2,1.9-1.9,2.8L631.6,640.5z"
      />
      <path
        d="M602.7,610.5c-1,7,1.4,13.2,4.3,19.4c2.6,5.4,4.1,11.1,1.6,17.1c-0.3,0.6-0.7,1.3-1.1,1.9c-0.6-2.7-1-4.8-1.5-6.9
	c-1-4.1-1.7-8.2-3-12.2c-1.8-5-2.6-10.2-2.3-15.5c0.3-1.3,0.7-2.6,1.2-3.9L602.7,610.5z"
      />
      <path
        d="M723.1,455c17.6,28.3,15,57,1.1,85.8c0.4-1.6,0.8-3.2,1.3-4.8s1-3.2,1.6-4.8c3.7-10.1,6.5-20.3,7.3-31.1
	c1-13.6-3.2-26.1-8.6-38.3c-0.9-2.1-1.8-4.2-2.8-6.4C723,455.4,723.1,455.1,723.1,455z"
      />
      <path d="M724.1,810.8c-7.6,5.4-10.1,12.1-6,21.1c-2.7-2.3-3.6-7.2-2.6-11.4C716.7,815.7,720,811.9,724.1,810.8z" />
      <path d="M722.9,546.4c0.9,5.8,1.9,12.8,3.1,20.4C723,559.3,721.1,552.4,722.9,546.4z" />
      <path d="M752.2,726.9c1.7,2.8,0.9,4.8-1.4,6.4C749.2,730.4,749.5,728.9,752.2,726.9z" />
      <path d="M749.9,692.8l1.7-12.5l0.8,0.1c-0.5,4.2-1.1,8.4-1.6,12.6L749.9,692.8z" />
      <path d="M675.6,733.1c0.9-1.7,1.7-3.5,2.6-5.2l1.4,0.7l-2.5,5.5L675.6,733.1z" />
      <path
        d="M406.4,631.9c-10.9-4.9-18.8-12.8-25.5-22.3c-11.6-16.3-14.9-34.9-16.2-54.2c-0.9-14.2,1.2-28.1,2.6-42.1
	c1.1-9.6-0.1-19.4-3.3-28.5c-0.9-2.3-1.6-4.7-2.4-7.1l0.8-0.5c0.7,0.8,1.4,1.6,2,2.5c6,11.9,7.7,24.5,5.9,37.6
	c-3.1,22.1-3.6,44.1,2.3,65.8c4.1,15.4,12.5,28.3,24.2,39c3,2.7,5.8,5.5,8.7,8.3C405.9,630.9,406.2,631.4,406.4,631.9z"
      />
      <path
        d="M391,486c0,1.7-0.1,3.3-0.4,5c-1.8,7.4-3.7,14.8-5.7,22.1c-6.3,22.8-6.8,45.6,0.6,68.3c1.4,4.2,2.2,8.5,3.3,12.8l-0.7,0.4
	c-0.5-0.6-1-1.2-1.4-1.9c-6-9.8-8.2-20.7-9.6-31.9c-2.5-19.2,1.3-37.6,7.1-55.8c1.8-5.7,3.4-11.5,5.1-17.3c0.2-0.6,0.5-1.3,0.8-1.9
	L391,486z"
      />
      <path d="M447.6,735.3c4.1,3.2,5.8,13.5,3.5,18.4C450,747.5,448.8,741.4,447.6,735.3z" />
      <path d="M555.6,753c0.3,2.3,1.3,4.8-2.5,6.4l1.3-6.6L555.6,753z" />
      <path d="M585.6,668.4l9.9-0.5c-3.5,3.6-5.5,3.9-9.8,1.7L585.6,668.4z" />
    </g>
  );
};

export default drawingWoman1Lineart;
