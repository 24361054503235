/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import "../Styles/Artwork.css";
import * as utils from "./Utilities";
import * as shapeUtils from "./ShapeUtilities";

import * as shape2d from "./Resources/Shapes2D";

interface ArtworkMaskedCanvasProps {
  focusImg: HTMLImageElement;
  id: string;
  element?: string;
  position1: number;
  position2: number;
  size?: number;
}

const LayerMaskedCanvas = (directProps: ArtworkMaskedCanvasProps) => {
  const props = utils.getProps();

  const color = utils.selectPalette(props.palette)[`${directProps.id}`];

  const [context, setContext] = useState<CanvasRenderingContext2D>();

  const initialPosX = (directProps.position1 * 10) % props.width;
  const initialPosY = (directProps.position2 * 10) % props.height;
  const canvasRef = useRef(null);

  const fillcolor = utils.colorToHex(color);

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.globalCompositeOperation = "source-over";

    ctx.drawImage(
      directProps.focusImg,
      0,
      0,
      ctx.canvas.width,
      ctx.canvas.height
    );
    ctx.fillStyle = fillcolor;
    ctx.globalCompositeOperation = "source-in";

    // shape2d.shapePolygon(
    //   ctx,
    //   12,
    //   initialPosX,
    //   initialPosX,
    //   initialPosY +
    //     10 *
    //       Math.sin(
    //         frameCount *
    //           0.005 *
    //           parseInt(utils.generateValue(1, directProps.id))
    //       ),
    //   props.width,
    //   props.height,
    //   directProps.size ? directProps.size : 20,
    //   "wiggle",
    //   frameCount * 0.3,
    //   0.02,
    //   1
    // );

    shapeUtils.generateElementShape({
      ctx: ctx,
      elementStyle: props.elementstyle,
      shapeRotation: initialPosX,
      initialPosX: initialPosX,
      initialPosY: initialPosY,
      width: props.width,
      height: props.height,
      shapeSize: directProps.size ? directProps.size : 20,
      animation: "wiggle",
      frameCount: frameCount * 0.3,
      intensity: 0.02,
      depth: 1,
      animationSeed: utils.animationSeed,
    });

    ctx.fill();
    // ctx.strokeStyle = fillcolor;
    // ctx.lineWidth = 20;
    // ctx.stroke();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let animationFrameId = 0;

    const framectElement = document.getElementById("framect");

    //Drawing happens here
    const render = () => {
      if (framectElement) {
        draw(thisContext, parseFloat(framectElement?.innerText));
      }

      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  return (
    <div className={"maskCanvas"} id={directProps.id}>
      <canvas
        id={"canvas-" + directProps.id}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export default LayerMaskedCanvas;
