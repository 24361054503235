import React, { useEffect } from "react";
import "./App.css";
import Main from "./Components/Main";

function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.webrtc-experiment.com/screenshot.js";
    script.async = true;

    document.body.appendChild(script);
  });
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
