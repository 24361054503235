const bgDrawingCitylights = () => {
  return (
    <g>
      <path d="M245.88,0c6.71,11.85-2.88,22.78-12.71,22.27a14.16,14.16,0,0,1-12.6-19.88c.32-.78.53-1.59.79-2.39Z" />
      <path d="M530.92,0c-13.37,16-36,16-50.06,0Z" />
      <path d="M725,1428.56l-1.64-4.65-4.49.3-.63-1,37.24-36.12,1.07.84c-7.06,10.82-17.94,18.61-25.93,28.69l1.86,2c2.69-2.11,5.63-4,8-6.39,5.36-5.4,10.38-11.14,15.7-16.58,1-1,2.75-1.34,4.16-2-.55,1.57-.68,3.52-1.71,4.63-7.37,7.9-15,15.58-22.39,23.45a72.87,72.87,0,0,0-5.13,6.86Z" />
      <path d="M521.73,1428.56c.62-1.14,1.25-2.27,2.26-4.1-4.46-.65-7.52-6.43-12.47-1.38-.32.33-2.74-.66-2.72-.85.72-5.78-4.4-5.27-7.41-7-.72-.4-1.76-.31-2.38-.79a13.87,13.87,0,0,1-2.05-2.43c1.12-.43,2.44-1.46,3.34-1.18a117.6,117.6,0,0,1,12.34,4.54c6.71,3,13.23,6.37,20,9.18,2.73,1.13,5.94,1,8.86,1.75a27.29,27.29,0,0,1,4.76,2.22Z" />
      <path d="M701.79,1142.83c-2.77,4.38-6,5.8-10.18,2.18-.48-.42-1.59-.2-2.39-.12-.47.06-1,.72-1.35.62-5.5-1.66-11-3.21-16.36-5.34-.63-.25-.71-4.78.28-5.49,1.67-1.2,5.33-2,6.49-1.07,2.4,2,4.17,3.42,7.42,2.08.94-.39,2.46.77,3.75,1a26.9,26.9,0,0,0,4.4.31c1.16,0,2.55-.7,3.45-.27,6.69,3.19,11.37.28,16-4.4,11.16-11.24,26.51-13.64,41.43-6.86,12.68,5.75,21.4,20.14,20.77,34.25-.7,15.74-10.06,28.83-24.8,33.36a54.09,54.09,0,0,1-17,1.94c-3.36-.08-4.66.21-5.65,3.61a13.74,13.74,0,0,1-16.85,9.51c-7-1.73-12.17-8.44-10.54-15.2.88-3.67,3.57-7.2,6.24-10,1.86-2,1.92-3.11.66-5.13-5.29-8.48-7-17.53-4.46-27.34.58-2.27.38-4.73.54-7.11Z" />
      <path d="M50.46,796.38c-9.55-.56-19-5.6-25-16.79a15.64,15.64,0,0,0-5.15-5.08c-2.27-1.55-3-2.46-.38-4.45a6.43,6.43,0,0,0,2.12-4.38c.65-21.73,19.6-35.69,40.58-29.8a18.45,18.45,0,0,0,5.44,1c3-.1,4.69,1.09,5.64,4a13.39,13.39,0,0,0,2.71,4.19c8.29,9.59,9.62,20.66,5.37,32C77.31,789.14,65.73,796.34,50.46,796.38Z" />
      <path d="M625.64,370.28c-3.35-2.82-4-6.25-1.5-10.16.38-.59-.41-1.82-.39-2.74.06-2.72.29-5.44.4-8.16.05-1.09.41-2.49-.11-3.21-2.84-3.91-.38-4.8,2.81-5a73.14,73.14,0,0,1,11.17-.23c1.32.1,3.54,1.68,3.6,2.69.25,4.31-1,8.83-.14,12.94,1.58,7.59-.63,11.9-8.33,13.25-.17,0-.19.89-.36,1.76h10.28c-1.19,4.71-2.37,8.91-3.27,13.17-.33,1.59-.6,3.7.16,4.9,1.63,2.57,2.55,4.43-.11,7-1.28,1.25-1.38,3.69-2.19,6.09,1.51.21,3.19-.05,3.85.65.85.92,1.6,3.49,1.19,3.8-6,4.65-5,10.66-3.48,16.73.33,1.32,1.84,2.31,2.35,3.63,2.51,6.42,6,12.77,6.77,19.42.35,2.84-.59,5.72,2.49,8.33-5.48,1.85-6.4,4.66-3,8.8.78.93.68,2.58,1,3.89.7,2.81.39,5.27-2.88,6-3,.68-4.87-.79-5.45-3.85a6.67,6.67,0,0,0-.76-1.52c-4.56-.62-9.18-1.31-13.82-1.84-1.13-.13-2.31.43-3.47.44a40,40,0,0,1-4.08-.3,38.56,38.56,0,0,1,2.15-3.89c.44-.64,1.29-1,2.63-2-1.52-.89-2.53-1.58-3.61-2.11-4.49-2.21-5.52-5.69-2.88-10,.44-.7,1.53-1.63,1.36-2-2.68-5.31,1-9.83,1.67-14.71.13-1,.07-2.32.67-2.89,3.91-3.7,1.47-8.38,2.21-12.55.63-3.58,1.65-7.09,2.3-9.79-1.66-1.07-4.27-2-4.27-2.89,0-3.25,2.21-2.28,4.35-1.78.65.16,2.66-2.12,2.51-2.38-3.25-5.57,0-11.53-1.22-17.12-.48-2.15-1.85-4.13-2.95-6.11-.76-1.36-1.76-2.59-2.66-3.88Z" />
      <path d="M357,1182c-3.06,7.55-3.92,7.9-9.92,4.55-2.36,3.64-5.33,4.41-8.86.94-3,3.87-6.19,4.36-9.47.13,0,.69,0,1.37,0,2.06-2.72.07-5.44.13-8.17.23-.62,0-1.44,0-1.84.34-4.36,3.87-7.27,2.85-8.43-1.89-1.41,1.53-2.83,4.37-4.39,4.45-2.19.12-4.49-1.81-6.73-2.86-2.27,6-4,6.27-9.1,1.3-3-2.9-4.8-5.29-3.65-10.08,1-4.36-.4-9.26-.3-13.92.08-3.44,2.09-5.57,5.79-5.86,2.75-.21,5.73-1.52,8.18-.83,6.67,1.9,12.63-5,19.24-1.15,4.81-4,10.89-2.22,16.19-3.66,3.68-1,6.75-2.64,10.61-.7,1,.51,3.16-.25,4.28-1.09,3.3-2.51,6.62-2.79,9.84-.38s5,5.73,3.42,10.25c-.61,1.73.06,4,.31,5.93.21,1.65,1.17,3.39.85,4.89-.57,2.7-1.29,5,.92,7.48.59.67-.36,3.85-1.45,4.52s-3.38,0-4.81-.76C358.49,1185.39,358.08,1183.79,357,1182Z" />
      <path d="M620.72,592.74c1.24-6.3,2.49-12.59,3.83-19.36l-11.39-.57c-.34,0-.68.07-1,0-2.8-.33-6.14-7.52-4.72-10.86,1.16,1.29,2.2,2.46,2.74,3l8.31-6,4.47,8.48,1.4-.17c.23-7,.33-14,.74-20.93a39.43,39.43,0,0,1,1.64-8.65c.17-.61,2.07-1.19,3-1a3.4,3.4,0,0,1,2.07,2.29c.62,3.49,1.37,7,1.2,10.54-.29,5.94-1.29,11.84-1.85,17.77-.07.78.74,1.65,1.12,2.44l5-3.69c.86,1.85,1.71,3.7,2.23,4.8l7.94.37c-.23,1.54-.36,4.38-.72,4.41-2.18.18-4.41-.27-7.85-.66-2.22,2.32-5.48,1.53-8.52-2.25V588.4c3.14-2.29,5.44-4.06,7.84-5.67.88-.59,2.2-1.33,3-1.05s1.17,1.75,1.37,2.77-.13,1.91-.33,3.89c3.9-1.54,7.27-1.9,9.34-5.72,1-1.76,3.9-4,5.34-3.62,3.14.8,3.35,3.56,1.83,6.59-.65,1.3-.23,3.24,0,4.84.9,6.42-2.21,10.07-8.63,10.24-5.06.13-10.11.52-15.13.8-.66,6.76-1.36,7-7.54,6.47-7.54-.64-15-4-22.68-1.19-2.8,1-5.64,2-8.37,3.17-5.68,2.49-9.75,2.25-12.15-2-1.25-2.25-1.14-5.81-.42-8.44s3.19-3.8,6.16-2.12a4,4,0,0,0,2.46-.09c1.83-.41,3.63-.94,5.45-1.42-1.08-1.55-2.51-3-3.14-4.68-.8-2.17-1.94-5.14-1.06-6.7.77-1.37,4.29-2,6.34-1.64,4.77.9,9.19,3.41,14.25.43,2.45-1.44,6.22,2,5.92,5.06a20.56,20.56,0,0,1-1.13,3.82Z" />
      <path d="M817.13,1175.29c1.3-2.22,2.37-4.62,4-6.53.42-.49,3.44.16,3.85,1,.91,1.82.93,4.1,1.31,6.19l-1.07,1.59c2.35.12,4.83.78,7,.24,3.86-1,5.34.61,6,4,.49,2.38.82,5,2,7,6.21,10.94,3.07,26-7.08,32.87-11.52,7.81-25.47,5.67-34.17-5.24-7.59-9.51-6.67-24.86,2.86-32.87C806.34,1179.81,812.31,1177.83,817.13,1175.29Z" />
      <path d="M906.13,616.1c6.22,4.08,12.15,6.82,16.55,11.13,6.36,6.24,5.73,19.67-.24,26.35-2.24,2.51-4.33,6.4-7.05,7-7.08,1.49-14.52,2.07-21.21-2.35-9.41-6.21-12.12-16.79-7.11-28.35.12-.3.1-.66-.83-.34-2.81-4-6.3-7.65-8.17-12-1.09-2.55-.16-6.41.86-9.33,2-5.85,8.59-8.85,15.59-7.83C902,601.43,905.12,606.08,906.13,616.1Z" />
      <path d="M373.51,1386.59c-.49-.8-.87-2.2-1.49-2.33-5.69-1.12-7.75-4.94-7.74-10l-11,1-3,1.75a18.68,18.68,0,0,1-.81-4.15c.21-5.37.51-10.76,1-16.12.24-2.73,1.27-3.22,4-1.68,4.85,2.72,10.13,4.67,15.21,7,3.84,1.74,7.55,4.7,11.5,5,5.83.52,9.7,7.46,16.48,4.4,1.49-.67,4.36,0,5.73,1.08,6.57,5.33,14.72,7.17,22.26,10.13,22.18,8.68,44.63,16.67,66.94,25,.94.35,1.48,1.74,2.21,2.65-1.14.39-2.47,1.36-3.4,1.07-5.64-1.8-11.22-3.82-16.76-5.91-5.7-2.14-11.33-4.46-17-6.69-.63-.24-1.77-.66-1.88-.5-3.12,4.93-9,.75-12.65,3.85-.48.41-1.46.89-1.83.68-4.77-2.79-10-.38-14.83-1.37-4.31-.88-6.59-5.9-5.07-10.09.5-1.41,1.21-2.75,2.28-5.12l-16.58-6.81c.33,2.6.22,3.49.57,4.13,2.39,4.38,1.43,8.46-2.95,10.65-.66.34-2.68-.71-3.12-1.59s.18-2.46,0-4.14c-2.19,4.75-4.25,6.75-9.8,3.65-3.25-1.82-7.31-1.43-7.93-6.48-.1-.81-2.12-1.4-3.25-2.09l0,1.63-7.26,1.14Z" />
      <path d="M784.44,1006.1c3-2,6.33-1.05,9.35,1.31s2.73,5.74,2.23,9.3c-.76,5.46-1,11-1.25,16.52,0,.74,1.4,1.63,2.26,2.31a36.08,36.08,0,0,0,4.91,3.56c1.38.76,3.06,1,4.57,1.52s4.3,1.2,4.39,2a7.25,7.25,0,0,1-2,5c-1.91,2.07-4.78,3.31-6.48,5.5-3.26,4.18-7.31,5.38-12.18,4.33-4.5-1-7.63-3.44-8.18-8.42a24.36,24.36,0,0,0-1.23-4.12c-11.2,3.31-17.85-2.87-23.8-11.25-.77.45-1.53,1.21-2.36,1.3a13.21,13.21,0,0,1-3.36-.47c.44-1.07.57-2.59,1.37-3.15,3.13-2.19,5-9.51,2.21-11.41-4.75-3.24-1.7-5.07.86-6.67a88.13,88.13,0,0,1,11-5.64,84.85,84.85,0,0,1,12.57-4.66C780.48,1002.63,782.24,1004.67,784.44,1006.1Z" />
      <path d="M157.15,230c-.2,12.08-9.75,21.66-21.39,21.43s-21.48-10.41-21.33-21.8c.16-11.61,10.18-21.41,21.72-21.26C148.4,208.57,157.34,217.79,157.15,230Z" />
      <path d="M285.05,129.68c0,11.86-9.65,21.66-21.26,21.63A21.46,21.46,0,1,1,264,108.4,21.48,21.48,0,0,1,285.05,129.68Z" />
      <path d="M348.65,319A14.86,14.86,0,0,1,334,333.93a14.77,14.77,0,1,1,.13-29.54A14.79,14.79,0,0,1,348.65,319Z" />
      <path d="M82.8,526a21.09,21.09,0,0,1,21,21.17A21.69,21.69,0,0,1,82.67,569c-11.44.2-21.4-9.7-21.53-21.4A21.38,21.38,0,0,1,82.8,526Z" />
      <path d="M582.58,138.56c-.73,6.33-2.71,10.86,1.87,14.59.72.59.09,4.32-1.05,5.36-1.5,1.37-4.95,2.74-6.11,2-3.93-2.66-7.28-.93-11-.08-10.34,2.34-19.91-3-23.71-12.91a20.82,20.82,0,0,1,9.65-25.39c8.53-4.66,20.24-2.23,26,6.09C580.63,131.65,581.59,136.11,582.58,138.56Z" />
      <path d="M57.17,964.72a20,20,0,0,1,24.24-19.51,16.42,16.42,0,0,0,5.93.08c6.52-1.08,11.07,3.45,9.89,9.83a23.34,23.34,0,0,0-.19,7c1.46,11.4-7,22.23-18,23C66.82,985.86,57.08,976.79,57.17,964.72Z" />
      <path d="M38.12,614.34,35,608.42l8.21-3v6.82L48,615a20.21,20.21,0,0,1-.12-2.86c.26-1.51.61-3,.92-4.49.86,1.14,1.72,2.27,2.56,3.41.69.93,1.36,1.88,2.63,3.65,3.12-12.51,6.44,1.31,9.95-1.94l1.54-3.94a23.51,23.51,0,0,0,2.2,2.38A19.65,19.65,0,0,0,71,613.53c3.08-3.52,7.22-1,10.73-1.78,2.32-.5,5.48,1.07,7.73,2.54,3.44,2.26,6.64,2.82,10.25.83a4.18,4.18,0,0,1,1.41-.53c6.83-.91,6.83-.9,11-5.33-.49,7.31,5.56,6.15,9.28,7.37,2.29.76,5.31-.58,3.1-4.53-.52-.94.09-2.52.18-3.8,1,.54,2.66.87,2.93,1.67,1.25,3.62,3.93,2.32,6.35,2.28a24.26,24.26,0,0,1,3.74.54,30.69,30.69,0,0,0-1,3.5c-1.32,8.68-9.42,6.21-14.48,8.68-.68.33-2.07-1.2-3.15-1.24a62,62,0,0,0-7.7.31c-1.57.12-3.12.5-4.69.74a27.72,27.72,0,0,1-4,.53,24.86,24.86,0,0,1-4.47-.41c-1-.18-2.07-.89-3.09-.87-8.15.12-16.3.53-24.45.51-4.42,0-8.84-.88-13.27-1-4.7-.19-9.43.09-14.14,0a68.13,68.13,0,0,1-11.71-1c-1.13-.23-2.88-3.29-2.47-4.09.81-1.58,2.8-2.77,4.56-3.58C34.93,614.2,36.64,614.46,38.12,614.34Z" />
      <path d="M217.85,1185.86c0,6.25.42,12.53-.1,18.73-.68,8.14-8.32,14.36-17,14.57-8.18.19-15.65-5.93-17.47-14.32-1.59-7.31,3.38-16.09,10.25-19.17a18.87,18.87,0,0,0,6.72-5.5c3.17-4,7-5.09,11.69-3.86C216.81,1177.57,218.58,1181.19,217.85,1185.86Z" />
      <path d="M551.46,1024.41c-.06,10.87-8.34,19.12-19.08,19A18.59,18.59,0,0,1,514,1024.25a18.73,18.73,0,0,1,37.46.16Z" />
      <path d="M851.42,822.62a43.79,43.79,0,0,1,4.68-2.89c.22-.1,1.63,1.49,1.52,1.65A56.39,56.39,0,0,1,853.9,826l4.58,2.7c.17,6.75-1.4,8-7.49,6C847.15,841,833.11,844,826,840c-8.55-4.76-12.33-15.19-8.35-23,4.41-8.66,14.94-13,23.3-8.46,3.8,2.07,6.26,6.69,9.16,10.29C850.83,819.74,851,821.19,851.42,822.62Z" />
      <path d="M888.05,349.33a18.18,18.18,0,1,1,36.36,0,18.07,18.07,0,0,1-18.25,18.08A17.81,17.81,0,0,1,888.05,349.33Z" />
      <path d="M110.08,761.34c-.31,1.66-.59,3.16-1.08,5.84-1.81-2.64-2.61-4.72-4.12-5.74-1.74-1.17-4.31-1.06-6.18-2.11a17.73,17.73,0,0,1-8.39-21.44c2.27-6.74,11.12-12.63,17.94-11.93,8.91.91,15.63,7.53,16.41,16.16S120.47,758,111.9,760.71C111.26,760.91,110.64,761.15,110.08,761.34Z" />
      <path d="M506,1384.51c2.67-.2,5.36-.29,8-.62,1.71-.21,3.91-.22,5-1.26,2-1.9,3.24-4.5,5-7.12-3.12-1.44-5.36-3.77-8.69-.26-.92,1-4.65-.73-8.35-1.45-1.41,1.3,3.37,9.31-4.42,6.9-1.25-.38-1.33-4.57-2-7.14l-9.16,1.42,6.89,5H484.14c-1.87,3.39-7.9,5.45-12.23,3.65-5-2.06-2.61-6-2.17-9.21.42-3,2.88-2.5,5.47-2.71,4-.32,8.07.92,12.18-1.58,3-1.83,7.42-1.46,11.2-2.06,1.19-.19,2.58,0,3.54-.59,2.61-1.55,4.57-1.68,6.46,1.16s3.05,1.79,4-1c.6-1.73,1.57-4.11,2.92-4.55,1.14-.38,3.07,1.58,4.63,2.54.56.35,1.15,1.23,1.58,1.15,4.59-.95,9.47-1.37,13.62-3.32,2.59-1.22,4.52-2.73,6.55-.26,2.25,2.74-.16,4.39-2.28,5.5-3.08,1.62-6.33,2.9-9.74,4.43,1.21,6.06-6.37,13.73-12.23,17.24-5.57,3.33-11.58-1.44-16.24,3.19-.5.51-3.9-.89-4.93-2.21a5.22,5.22,0,0,1,0-5c1-1.33,3.23-2.17,5-2.33,1.26-.11,2.65,1.26,4,2C505.66,1385.55,505.82,1385,506,1384.51Z" />
      <path d="M451.15,1281.65c-.12,8-6.78,13.46-15.09,12.75a9.05,9.05,0,0,0-6,2c-3.65,3-8.52,4.06-12.73,1.92a10.76,10.76,0,0,1-5.48-11.1,10.24,10.24,0,0,1,9.49-8.5c3.1-.17,4.71-1.27,6.22-4a12.11,12.11,0,0,1,14.05-5.5C447.17,1270.89,451.23,1276.22,451.15,1281.65Z" />
      <path d="M727.62,907.37A15.41,15.41,0,0,1,712.5,892a14.91,14.91,0,0,1,15.58-15.28c8.7.15,14.93,6.7,14.75,15.48S736,907.44,727.62,907.37Z" />
      <path d="M513.6,1353.24c-5,.64-9,.87-12.8,1.72-3.33.74-6.46,2.3-9.75,3.28a41.31,41.31,0,0,1-8,1.79c-5,.47-10,.57-15,.81-.15,0-.32-.24-.45-.22-4.9.92-9.78,1.94-14.7,2.76a67.93,67.93,0,0,1-6.95.42h-7.39l.06-5.61,8.23.59c.41-.72,1.42-2.34,2.24-4a12.31,12.31,0,0,0,1.58-4.31c.4-6.18,3.67-10,9.55-10.66,5.26-.62,9.75,2.64,11.29,8.21,1.59,5.76,2.76,6.07,8.67,5.41,10.21-1.12,20.49-1.66,30.75-2.35C511.44,1351,512,1351.92,513.6,1353.24Z" />
      <path d="M302.61,552.9c-.47,2.22-.23,5.65-1.55,6.43-4,2.36-8.43,4.79-12.87,5.17-8.33.71-14.83-6.55-14.51-14.79A14.45,14.45,0,0,1,288.55,536C295.11,536,304.1,543,302.61,552.9Z" />
      <path d="M528.67,330.41a14.39,14.39,0,0,1-14.26,14.78,14.68,14.68,0,0,1-14.67-13.92c-.26-7.77,6.44-14.7,14.37-14.85A14.65,14.65,0,0,1,528.67,330.41Z" />
      <path d="M826.82,16.67a15,15,0,0,1-14.67,14.67c-7.49,0-14.2-6.94-14.21-14.61,0-7.37,7-14.39,14.45-14.42S826.86,9.21,826.82,16.67Z" />
      <path d="M782.8,180.49a14.43,14.43,0,0,1,14.43,14.17,14.92,14.92,0,0,1-14.29,14.53c-7.68.12-15-7.11-14.8-14.61A14.62,14.62,0,0,1,782.8,180.49Z" />
      <path d="M64.39,256.31a13.76,13.76,0,0,1-14.08,14.23c-8.41,0-14.71-6.18-14.63-14.37a14.3,14.3,0,0,1,14.52-14A13.91,13.91,0,0,1,64.39,256.31Z" />
      <path d="M944.6,1010.47a14.3,14.3,0,0,1-14.36-13.63c-.24-7.56,6.41-14.68,13.84-14.83s14.48,6.81,14.46,14.26A14.64,14.64,0,0,1,944.6,1010.47Z" />
      <path d="M258.57,935.64A14.06,14.06,0,0,1,244,949.53c-7.88-.25-13.48-6.37-13.28-14.5a14.06,14.06,0,0,1,14.43-14C252.56,921.31,258.83,928.15,258.57,935.64Z" />
      <path d="M450.31,1062.09c-.64,2-1.41,3.93-1.91,5.94a75,75,0,0,0-2.24,10.61c-.4,4.23-2.09,5.79-6.09,4.72-1.3-.34-2.78-.33-3.9-.95-1.37-.77-3.14-1.86-3.47-3.14a4.92,4.92,0,0,1,1.58-4.29c4-2.63,3.47-5.76,2.15-9.55a70.5,70.5,0,0,1-3.08-11.94,9.8,9.8,0,0,1,6.6-11.29c4.87-1.62,10.15.12,12.5,4.12,2.86,4.88,2.06,9.76-2.27,13.68-.37.34-.75.67-1.13,1Z" />
      <path d="M809.32,453.28c-3.28-2.23-6.72-4.94-11.29-1.85-.69.47-2.61-.89-4.81-1.73.2,1.74.8,3.22.41,4.37-.71,2.07-1.66,5.13-3.16,5.58a7.5,7.5,0,0,1-6.47-2c-1-1.16,0-4.13.12-6.3a.6.6,0,0,1,.32-.38c3.12-1.59,1.09-7.75,6.48-7.31l10.4.84c-.17-1.93-.34-3.39-.42-4.84-.31-5.8,3.37-10.6,8.73-11.45a10.94,10.94,0,0,1,12,7.29A10.76,10.76,0,0,1,815.46,449C813.45,449.87,811.78,451.51,809.32,453.28Z" />
      <path d="M371,458c-3.16-2.68-5.51-7-11.41-6.08-1.82.28-4.17-3-6.28-4.63l.9-1.45,6,2.78c.63-2.75,1.21-5.14,1.72-7.54,1.49-7,5.91-10.35,13.17-10,7,.3,11.56,4.54,12.11,11.32.59,7.33-3,12.47-9.73,14.07Z" />
      <path d="M190.66,902.94c-3.21-1.9-5.72-4.6-8-4.43s-4.34,3.67-6.89,4.73c-3.92,1.64-7.93,1.42-11.38-1.83s-4.12-7-2.65-11.17,4.71-6.32,9.22-7c2-.28,3.77-1.89,5.75-2.59,1.57-.56,3.75-1.43,4.89-.79,5.14,2.88,8.55,7.28,9,13.38C190.85,896.24,190.66,899.22,190.66,902.94Z" />
      <path d="M966.39,507.38a12.77,12.77,0,0,1,13-12.84c7.53,0,13,5.64,13.1,13.41.06,6.57-6.62,12.91-13.42,12.72A13.27,13.27,0,0,1,966.39,507.38Z" />
      <path d="M862.5,1200.42l-1.65,4.55-1.06.14c-.24-1.56-.9-4.39-.65-4.48a79,79,0,0,1,9.41-2c-2.39-1.28-3.87-2-5.29-2.86-1.08-.65-2.08-1.44-3.11-2.16,1.06-.54,2.1-1.49,3.18-1.55,5-.29,10.09-.25,15.12-.51,1.26-.07,2.47-1.13,3.73-1.2,3.57-.22,7.15-.16,10.73-.21a13,13,0,0,1-1.12,2.82c-1,1.46-2.42,2.66-3.34,4.17-1.5,2.46-2.47,5.29-4.23,7.5-.53.67-3.05.1-4.49-.39a27.73,27.73,0,0,1-4.52-2.5l-3.62,4.77c0,3.56.91,6.7-.27,8.23-1,1.28-4.41.77-6.75.9-3,.16-6.07.71-8.87,0-2.06-.48-3.72-2.64-5.55-4.06,1.69-1.34,3.24-3.58,5.09-3.85,4-.59,8.2-.18,12.82-.18Z" />
      <path d="M416,784.71a13.19,13.19,0,0,1-12.63-13.84c.41-6.73,7.14-12.63,13.85-12.13s12.41,6.8,12.15,13.34C429.12,779.08,422.8,785.06,416,784.71Z" />
      <path d="M650.64,509.24c-10.24,0-20.53.07-30.82,0-3.63,0-7.84-6-7.36-10s2.87-4.6,8.58-3.81c4.49.62,9.15,0,13.73,0,4.23,0,8.62-.71,12.62.25,1.93.47,3.81,3.86,4.43,6.26C652.37,504,651.14,506.6,650.64,509.24Z" />
      <path d="M973.06,886.49c-1.08-6.47-5.56-6.72-9.33-6.9-5.28-.24-10.61.74-15.89.53-1.57-.06-3-2.38-4.56-3.66,1.62-1.36,3.18-3.79,4.88-3.89,6.08-.35,12.22.42,18.3.1,3.62-.19,5.27.64,6,4.29.28,1.32,2.53,2.93,4,3.12,5.1.65,10.28.68,15.42,1,.54,0,1,.62,3,1.86l-10.36.64c1.3,1.72,2.25,3,3.21,4.25.13-.45.25-.9.37-1.36l7.49.54c-.15,1.57,0,4.4-.5,4.49-2.24.47-4.64.22-7.1.43-2.64,0-5.27-.08-7.9,0-3.79.15-7.58.64-11.36.6a8.59,8.59,0,0,1-5.28-1.57c-.79-.68-.8-3.76,0-4.39,1.3-1.07,3.49-1.3,5.33-1.42C970,885.14,971.23,885.9,973.06,886.49Z" />
      <path d="M747.52,506.42c0,3.86.22,7.73-.16,11.54-.09.92-2.21,1.62-3.4,2.43-.65-1-1.86-2.09-1.86-3.14,0-8.48.16-16.95.35-25.43,0-.93.45-2.6.86-2.65a126.84,126.84,0,0,1,13.88-1.23c.68,0,1.81,2.3,2,3.64a34.39,34.39,0,0,1,0,6.73c-.38,7.12-.6,14.27-1.41,21.35-.18,1.57-2.54,2.88-3.89,4.32-1.5-2-3.88-3.75-4.28-5.91-.71-3.78-.2-7.79-.2-11.7Z" />
      <path d="M700.79,1034.76c-4.34-3.4-9.43-2.29-14.74-2-2.26.14-5.84-3.5-6.84-6.18-.83-2.19.42-6.8,2.25-8a12.62,12.62,0,0,1,9.83-1.15c2.9,1,4.91,1.52,7.88.49,2-.68,4.69,0,6.79.84,2.83,1.1,5.39,2.92,8.07,4.43,2.51,1.41,3,5.59.39,6.81C710.27,1032,705.72,1033.11,700.79,1034.76Z" />
      <path d="M647.62,478c.11,1.44.18,2.95.36,4.45.67,5.63-1.2,7.8-6.91,7.94-.84,0-1.86-.32-2.5,0-7.42,4.14-13.85-.17-19.85-3.18-2.09-1.05-2.4-6.38-2.7-9.82-.09-1,2.57-2.35,4.06-3.41.66-.47,2-.37,2.28-.92,2.7-5.35,6.94-2.52,10.77-2.56-.08.57,0,.81-.14.89-1.56,1.43-3.13,2.84-4.7,4.25,1.94.3,3.87.66,5.82.88C638.47,477.07,642.84,477.5,647.62,478Z" />
      <path d="M841.91,1095.37c.3-1.8.45-3.49.88-5.1a11.39,11.39,0,0,1,15-7.47,11.26,11.26,0,0,1,6,15.6,11.52,11.52,0,0,1-15.71,4.86c-1.75-.9-4.08-.75-6.16-.93-1.73-.15-3.61.29-5.16-.28-.74-.27-1.54-2.8-1.1-3.37,1-1.29,2.7-2,4.19-2.91A7,7,0,0,1,841.91,1095.37Z" />
      <path d="M143.18,119a12.21,12.21,0,0,1-12.24-11.7c-.15-6.14,5.52-12.08,11.77-12.33a12.32,12.32,0,0,1,12.4,11.59A12,12,0,0,1,143.18,119Z" />
      <path d="M66.12,34.31A12.2,12.2,0,0,1,53.88,22.6c-.15-6.14,5.51-12.07,11.77-12.32a12.31,12.31,0,0,1,12.4,11.58A12,12,0,0,1,66.12,34.31Z" />
      <path d="M555.38,200.06a11.78,11.78,0,0,1-11.67,12.19,12.22,12.22,0,0,1-12.3-11.75,12.53,12.53,0,0,1,12.39-12.34A12,12,0,0,1,555.38,200.06Z" />
      <path d="M505.75,181.85c-6.9,0-11.83-4.82-11.92-11.75a11.88,11.88,0,0,1,11.55-12.37c6.05-.2,12.07,5.73,12.14,12A11.93,11.93,0,0,1,505.75,181.85Z" />
      <path d="M39.46,1267a36.73,36.73,0,0,0,2.31-3.7c2-4.32,6.12-6.51,10.4-5.19,4.48,1.39,6.62,4.48,6,9.3a11.47,11.47,0,0,0,0,3.51c.66,3.52-.72,4-4,3.47-4.89-.81-9.69-.29-14,3.86a32.19,32.19,0,0,1,5.37,2.42c.7.47.78,1.87,1.14,2.84-.91.15-2,.72-2.7.39-7.82-3.8-15.58-7.73-24-12,4.28-2.46,7.07-7.61,12.45-2.26-.27,2.73-1.25,6.5,3.52,5.93l3.67-8.73Z" />
      <path d="M267.15,1201.78c-2.83-2.32-6.33-4.17-8.32-7.07-2.67-3.89-1.45-8.3,1.69-11.7,2.91-3.15,6.06-5.54,10.88-2.62,2.41,1.46,5.62,1.65,8.5,2.27s4.34,4.29,2,5.72c-4.71,2.94-3.9,5,0,7.73.66.46.77,2.72.22,3.59a4.21,4.21,0,0,1-3.39,1.57c-3.49-.41-6.92-1.27-10.37-2C268,1200.13,267.56,1201,267.15,1201.78Z" />
      <path d="M99.12,1013.39c6.63,0,11.06,4.54,11,11.28s-5,12-11.24,12c-6.56,0-11.44-5.08-11.39-11.85S92.22,1013.37,99.12,1013.39Z" />
      <path d="M683.57,980.37c-.07,6.87-4.8,11.62-11.51,11.56a11.82,11.82,0,0,1-11.66-11.69A12,12,0,0,1,672,968.92,11.14,11.14,0,0,1,683.57,980.37Z" />
      <path d="M175,818c2,3.18,7.4-3.57,7.81,3.43.57,9.58.21,9.6-6.08,9.18.21,3.76.79,7.53.44,11.21-.14,1.51-2.08,3.55-3.59,4-1,.28-3.8-1.91-3.82-3a118.2,118.2,0,0,1,1-13c-7.34,2-14.31-1.91-16.59-10.44-.23-.84.55-1.94.87-2.92a9.49,9.49,0,0,1,2.32,1.95c2.19,3.58,10.52,5.72,14.91,3.35-2.8-1.67-7.93-1.5-6.07-6.54.45-1.24,3.49-2.85,4.08-2.45C172.15,814.06,173.45,816.21,175,818Z" />
      <path d="M29.31,1309c3-2.67,5.64-2.3,8.23.73.7.83,2.83.4,3.64,1.22,1.66,1.67,3.87,3.75,4,5.74,0,1.54-2.54,3.63-4.4,4.68a15.9,15.9,0,0,1-7,2c-1.92.07-3.88-1.08-5.05-1.44-2.69,0-5.13.69-6.77-.16-2.94-1.5-1.51-3.88-.66-6.49a24.62,24.62,0,0,0,.59-10.06c-.24-2-2-3.81-3.07-5.73-.65-1.17-1.25-2.36-1.87-3.55,1.35-.06,3-.65,4-.08,2.72,1.57,5.67,3.22,7.52,5.63C29.58,1303,29,1306,29.31,1309Z" />
      <path d="M124.49,1360.21c-3.29,2.39-6.28,4.62-9,1.12-1-1.26-1-4.55,0-5.73,2.86-3.48,6-1.46,9,.94,4.22-3.28,7.51-4.09,12,.79,2.89,3.14,8.82,3.7,13.52,4.87,3.61.91,5.35,2.85,5.19,6.37a31.73,31.73,0,0,1-1.26,5.59c-4,.29-7.88.9-10.4-3.35-.46-.78-1.89-1.1-2.93-1.36s-2.75.17-2.91-.21C135.24,1363.21,127.47,1365.66,124.49,1360.21Z" />
      <path d="M832.61,1370.84c4.54-.54,8.27-1.11,12-1.39,2.59-.18,3.21,1,2.2,3.54-3.45,8.43-6.13,8.93-14.69,2.09,4.46,7.71-4.55,10.26-6,16.21-.35-1.13-1.35-2.57-1-3.33a21.25,21.25,0,0,0,1.26-15.46,1.81,1.81,0,0,1,.29-1.45c4.12-4.24,4-9.69,4.77-15,.58-4.15,2.21-8.16,3.86-13.92.55,4.85.94,8.38,1.41,12.55,1.54-.64,2.69-1.45,3.87-1.52a6.15,6.15,0,0,1,4,.91c.79.66,1.39,2.9.92,3.46-.94,1.11-2.94,2.42-4,2.1-4.69-1.41-5.57.11-5.28,4.65C836.37,1366.24,834,1368.4,832.61,1370.84Z" />
      <path d="M700,721.33c.56-6.16,1.08-12.33,1.69-18.49.89-9,1.75-18,2.85-27a12.43,12.43,0,0,1,2.3-5.15c.26-.38,2.79,0,3,.52a13.39,13.39,0,0,1,1,5.54c-.58,8.94-1.11,17.89-2.16,26.78-.83,7-2.27,14-3.74,20.91-.26,1.25-2,2.19-3,3.27-.49-1.45-1-2.88-1.46-4.36a8,8,0,0,1,0-2Z" />
      <path d="M767,40.3a11.27,11.27,0,0,1-10.87-10.73,11.38,11.38,0,0,1,11.09-11.08,10.87,10.87,0,0,1,10.48,11A11,11,0,0,1,767,40.3Z" />
      <path d="M104.32,585.42a11.44,11.44,0,0,0,7.16-13.17c-.51-2-.34-5.28.87-6.18,1.43-1.06,5-1,6.35.13s.7,4.14.85,6.34c.11,1.67-.29,3.45.13,5,1.23,4.46-.85,6.49-5.15,7.8-3.27,1-5.88,4.28-8.66,6.69-.86.74-1.11,2.29-2,2.86a21.45,21.45,0,0,1-4.52,1.62,25.68,25.68,0,0,1,.52-5.37c.25-.85,1.65-1.35,2.72-2.15-6.47-2.51-6.34-2.45-3.21-8.53.56-1.08-.65-3-.79-4.53a19.41,19.41,0,0,1,.32-3.62c1.25.44,2.88.53,3.67,1.39a60.15,60.15,0,0,1,4.55,6.51Z" />
      <path d="M966.25,1241c6,.28,10.2,4.8,9.9,10.63a10.69,10.69,0,0,1-11.08,10.25,10.92,10.92,0,0,1-9.94-10.9C955.3,1245,960.07,1240.67,966.25,1241Z" />
      <path d="M870.73,964.33c-5.4-.13-10.45-5.3-10.37-10.63.08-5.73,5.32-10.84,10.9-10.64s10.4,5.24,10.37,10.62C881.59,959.31,876.31,964.47,870.73,964.33Z" />
      <path d="M452.55,1245c.1,6.15-4.31,10.51-10.57,10.48a10,10,0,0,1-10.27-10.23c0-5.67,4.6-10.59,10-10.69A10.46,10.46,0,0,1,452.55,1245Z" />
      <path d="M726.45,951.42c3.78-3.23,6.84-4.36,11.13-.94,1.7,1.35,6-.32,9-1a83.28,83.28,0,0,0,8.06-2.5c.69,2.3,2.12,4.67,1.89,6.87-.38,3.54-3.1,4.82-6.72,4.61-1.21-.07-2.49,1.18-3.73,1.84s-3.27,2.33-3.82,1.94c-4.6-3.22-11.24-1.23-15.14-6.16-.83-1.05-2.62-1.37-4-2s-3-.82-3.6-1.78c-.43-.65.4-3.05,1.16-3.34,1-.38,2.59.41,3.86.87C725.16,950.07,725.59,950.68,726.45,951.42Z" />
      <path d="M369.08,976.15c1.15,2.08,7.43,4.29-.14,6.14-.39.1-.31,1.75-.59,2.63-.39,1.24-.61,2.78-1.47,3.55s-2.41.72-3.66,1c-.4-6.1-8.86,2.66-9-5.09-.09-3.94-2.63-7.79-3.67-11.78-.23-.89,1.27-3.22,1.77-3.16,4.65.55,9.65-3.31,14,1,.1.1.33.1.49.11l5,.29Z" />
      <path d="M177.07,1145.41c-2.29,3.47-3.58,6.22-5.6,8.26a39.93,39.93,0,0,1-8.13,6,4.49,4.49,0,0,1-4.15-.17c-1.07-.9-1.56-2.78-1.76-4.31-.3-2.31.13-4.72-.21-7-.57-3.93.23-7.54,3.75-9.25,2.6-1.28,6.43-2.21,8.82-1.19C172.58,1139,174.39,1142.43,177.07,1145.41Z" />
      <path d="M83.13,473.87A38.77,38.77,0,0,1,81.85,469c0-.28,1.92-1.11,2-1.05,1,1.52,1.82,3.13,2.87,5.05,6.94,1.14,10,3.64,10.44,8.88A9.46,9.46,0,0,1,90.23,492c-4.58,1.33-8.06-.69-10.58-4.22C76.71,483.63,78.09,478.75,83.13,473.87Z" />
      <path d="M285.15,918.15l3.07,3.34,3.6-4c5.19,4.93,11.25,6.42,17.28,7.81a52.8,52.8,0,0,1,4.21-5.61c.58-.6,2-.4,3.05-.56a9.5,9.5,0,0,1,.16,2.74,15.42,15.42,0,0,1-1.61,4.66c-.8,1.33-2.06,3.13-3.31,3.29a66.35,66.35,0,0,1-10.27.14c-6.54-.14-13.08-.22-19.6-.62-1.62-.1-4.12-1-4.49-2.1s.71-3.51,1.83-4.72C280.56,920.88,282.69,919.85,285.15,918.15Z" />
      <path d="M701,597.77c-4.67,6-6.07,5.8-9.61-.75-.43-.8-2-.92-2.67-1.68-1.38-1.57-3.34-3.32-3.41-5.06-.05-1.11,2.92-3.35,4.46-3.29,2.71.12,4.33-.15,5.75-2.71s3.69-2.54,6-.65c1.62,1.32,3.45,2.37,5.63,3.84.41,2.34,1.83,5.56,1.28,8.41C707.6,600.4,704.13,599.64,701,597.77Z" />
      <path d="M728.5,570.82c-6.18.08-10.31-3.68-10.24-9.33.06-5.24,4.21-9.94,8.82-10a10,10,0,0,1,10,10C737,566.69,733.32,570.76,728.5,570.82Z" />
      <path d="M311.59,1319c5.63.35,11.29,1.14,16.9.88,3.44-.16,6.86-1.82,10.21-3,3.13-1.12,5.18-.08,5.72,3,.6,3.43-1.73,4.48-4.7,4.33s-6.1-.63-9.16-.66c-1.63,0-3.26.73-4.91,1-7.24,1-14.48,2.14-21.76,2.77-1,.09-3.14-2.34-3.17-3.64s1.9-3.07,3.3-3.47c2.36-.69,5-.44,7.5-.58Z" />
      <path d="M494.82,356.32a9.18,9.18,0,0,1-9.21,9.48,9.69,9.69,0,0,1-9.7-9.19,10,10,0,0,1,9.49-10C491,346.54,494.86,350.55,494.82,356.32Z" />
      <path d="M315.5,290.8a6.82,6.82,0,0,1-6.84,7,7.21,7.21,0,0,1-7.2-6.83,7.44,7.44,0,0,1,7.05-7.42C312.65,283.54,315.53,286.52,315.5,290.8Z" />
      <path d="M52,43.33a5,5,0,0,1-5,5.19,5.3,5.3,0,0,1-5.3-5A5.47,5.47,0,0,1,46.88,38,5,5,0,0,1,52,43.33Z" />
      <path d="M842.44,33a5,5,0,0,1-5,5.19,5.3,5.3,0,0,1-5.31-5,5.47,5.47,0,0,1,5.19-5.47A5,5,0,0,1,842.44,33Z" />
      <path d="M136.24,195.65c0,5.14-4.23,9.65-9.15,9.78a9.82,9.82,0,0,1-9.79-9.72,9.69,9.69,0,0,1,9.46-9.38A9.38,9.38,0,0,1,136.24,195.65Z" />
      <path d="M475.49,1037.86c.12,5.5-3.62,9.42-9,9.45a9,9,0,0,1-9.13-8.9,9.21,9.21,0,0,1,9.11-9.52A8.86,8.86,0,0,1,475.49,1037.86Z" />
      <path d="M288.83,726c4.41,0,9,4.76,9.09,9.33a9.27,9.27,0,0,1-18.53.18C279.34,730.69,284,726,288.83,726Z" />
      <path d="M463.93,1170.31c.1,5.41-3.85,9.61-9,9.6-4.48,0-10.05-5.09-10.16-9.26-.1-3.8,5.68-9.07,10.06-9.16A8.66,8.66,0,0,1,463.93,1170.31Z" />
      <path d="M710,1079.35c-1.54,1.84-2.45,4-3.79,4.29a9.84,9.84,0,0,1-6.05-1,36.36,36.36,0,0,0-14.32-4.18c-1.88-.11-4.52-1.24-5.29-2.7s-.44-4.88.72-5.81c1.46-1.18,4.26-1,6.45-.87a49,49,0,0,1,6.81,1.33c.82.16,2.07-.08,2.42.39,2.37,3.17,5.84,3.08,9.19,3.86C707.56,1075,708.5,1077.42,710,1079.35Z" />
      <path d="M998.86,795.7a8.78,8.78,0,0,1-8.84,9.2c-4.6.06-9.2-4.42-9.3-9.06s4.64-9.56,9.47-9.55C995.18,786.3,998.82,790.26,998.86,795.7Z" />
      <path d="M32.87,836c0,5.57-3.76,9.32-9.23,9.21a9.26,9.26,0,0,1-9.32-8.88c-.11-4.7,4.55-9.26,9.54-9.34A8.82,8.82,0,0,1,32.87,836Z" />
      <path d="M581.09,624.1c-2.87,6.11-1.59,13.2-9.3,14.73-1.16.23-1.79,2.5-3,3.15s-3.41,1.22-4.54.63c-1-.5-1.29-2.67-1.45-4.15-.19-1.85.36-3.8.06-5.62a8.11,8.11,0,0,1,6.78-9.58c2-.3,4-1.2,5.89-1.08S579.15,623.39,581.09,624.1Z" />
      <path d="M109.53,1400.23a9.23,9.23,0,0,1-9.24,9c-4.91,0-9.35-4.18-9.42-8.87-.06-5,4.39-9.19,9.63-9.08S109.63,1395.15,109.53,1400.23Z" />
      <path d="M672.29,737.1c-5.54,0-9.15-3.34-9.26-8.62a9.28,9.28,0,0,1,9.17-9.57,9.38,9.38,0,0,1,9,9.2A8.62,8.62,0,0,1,672.29,737.1Z" />
      <path d="M685.36,863.32c3.77.48,8.32.66,12.61,1.79,1.47.39,3.51,3.42,3.21,4.79-.84,3.8-1.85,9.19-4.52,10.53-3.18,1.6-8.15-.34-12.55-.77C684.58,873.5,685,868.69,685.36,863.32Z" />
      <path d="M591.42,1049c-1.29,1.38-2.48,3.72-4.16,4.15-1.89.49-4.25-.65-6.35-1.26-1.56-.46-3-1.3-4.54-1.82-4-1.35-8.09-2.36-11.87-4.1-1-.44-1.72-3.68-1.09-4.62.9-1.31,3.12-2,4.9-2.34a12.53,12.53,0,0,1,6,.12c5.28,1.72,10.47,3.76,15.58,5.93C590.62,1045.37,590.74,1047.13,591.42,1049Z" />
      <path d="M264.43,1173.72c-1.38.63-2.74,1.3-4.14,1.88-3.06,1.28-6.47,2-9.12,3.89-2.31,1.62-3.67,4.55-5.63,6.74-1.67,1.85-3.44,4.55-5.47,4.85-1.57.23-4.93-2.56-5.07-4.21-.17-2,1.92-4.51,3.65-6.13a75.63,75.63,0,0,1,9.52-7.22c3.11-2.09,6.26-4.39,9.75-5.5,1.41-.45,3.75,2,5.67,3.14Z" />
      <path d="M636,297.41c3.9,3,4.18,6.07.68,10.8-2,2.68-.84,7.63-1.37,11.52-.17,1.29-1.19,2.46-1.83,3.69-.76-1.17-1.94-2.25-2.21-3.53-1.06-5,1.45-10.44-2.29-15.44-1-1.31,1.34-5.07,2.48-8.83-.16-3.16-.56-7.75-.46-12.32,0-.81,2.1-2.08,3.3-2.18.79-.06,2.45,1.62,2.45,2.51C636.72,288.11,636.28,292.58,636,297.41Z" />
      <path d="M765.86,559.33c-.78,2.95-1.08,5-1.9,6.78a2.39,2.39,0,0,1-4.63-.92c-.09-7.25-.06-14.51.29-21.76,0-.72,2.64-2.08,3.72-1.82,1.8.45,3.33,2,5.44,3.33-5.94,2.33-3.87,6.64-3.15,12l2.8-6.48,1.49.09c.28,1.8.56,3.6.92,5.85l5.19,1.24c-.29,2.34-.55,4.47-.81,6.54C767.84,565.83,766.9,565.4,765.86,559.33Z" />
      <path d="M251.94,1376.44c-1.6.6-3.36,1.93-4.77,1.63-2.41-.5-5.26-1.47-6.77-3.23-3.39-3.94-8.17-4.14-12.39-5.89-5.58-2.31-11.1-4.76-16.52-7.41-.84-.41-1-2.17-1.52-3.31,1.43-.6,3.19-2,4.22-1.65,3.36,1.34,6.37,3.5,9.66,5,2.18,1,4.72,1.27,6.93,2.26,6,2.7,11.92,5.57,17.83,8.46a23.76,23.76,0,0,1,3.34,2.39C251.94,1375.3,251.94,1375.87,251.94,1376.44Z" />
      <path d="M754.45,1326.62a8.74,8.74,0,0,1-8.23,8.67c-4.18.08-8.61-4.22-8.64-8.38s3.84-8.08,8.17-8.26A8.09,8.09,0,0,1,754.45,1326.62Z" />
      <path d="M678.18,1390a8,8,0,0,1-8.16,8,8.22,8.22,0,0,1-.17-16.43A8.12,8.12,0,0,1,678.18,1390Z" />
      <path d="M199.05,440.53a37,37,0,0,0,5.25,4.25c1.51.86,3.47,1,5,2,3.52,0,7-.12,10.56.16.39,0,.61,2.27.91,3.48-3.19.69-6.38,1.45-9.59,2-.74.13-1.94-.73-2.3-.41-4.39,3.77-5.31-1-7.31-2.92-1.56-1.47-2.91-3.17-4.87-5.32-.17.4.45-.39.26-.75-2.65-5-.12-7.25,4.4-8.34a56.8,56.8,0,0,1,6.13-.83c.16,1.69,1,3.71.33,5-1.28,2.62-3.6,2.75-5.94.86a5.57,5.57,0,0,0-1.75-.6Z" />
      <path d="M404.39,1007.56a8,8,0,0,1,7.84,8.28c0,4.8-3.68,8.14-8.65,7.9-4.78-.23-7.73-3.55-7.49-8.43A7.72,7.72,0,0,1,404.39,1007.56Z" />
      <path d="M11.75,1079.16c-5,0-8.48-3.34-8.41-8.13a8.29,8.29,0,0,1,8.52-8.21c4.58.06,7.79,3.48,7.72,8.23C19.5,1076,16.5,1079.12,11.75,1079.16Z" />
      <path d="M949.93,382.7a8,8,0,0,1-7.66,8.38,8.24,8.24,0,0,1-8.54-8.13,8.1,8.1,0,0,1,8.07-8.06A7.75,7.75,0,0,1,949.93,382.7Z" />
      <path d="M622.31,514.76c4.33-.22,8.69-.49,13.05-.62a17.92,17.92,0,0,1,3,.66c1.36.2,2.77.15,4.1.44,2.76.62,5.89.79,8.14,2.26s.22,6.52-2.9,6.25a156,156,0,0,1-21-3.2C622.79,519.66,622.89,519,622.31,514.76Z" />
      <path d="M36.7,354.27a8,8,0,0,1,.46-16,8.14,8.14,0,0,1,8.24,8A8.52,8.52,0,0,1,36.7,354.27Z" />
      <path d="M714.21,350.21a8.08,8.08,0,0,1-.14,16.16c-4.28,0-8.26-4-8.21-8.13C705.91,354.27,710.16,350.18,714.21,350.21Z" />
      <path d="M991.82,121.56a7.8,7.8,0,0,1-7.75,8.28,8,8,0,0,1-8.23-7.88,7.84,7.84,0,0,1,7.86-8.16C988.52,113.68,991.69,116.71,991.82,121.56Z" />
      <path d="M564.44,482.4c-4.38.13-8.45-3.53-8.57-7.71-.13-4.49,3.28-8,8-8.22,4.92-.22,8.14,2.73,8.25,7.58S569.07,482.25,564.44,482.4Z" />
      <path d="M19,1333.35c3.13-3.37,6.47-6.19,10.1-2.29,1.3,1.4,1.57,5.43.49,7-2.78,4.1-6.42,2.4-9.74-.53-3.12,3.17-6.42,6.25-10.7,2.1-2.43-2.35-1.91-5,.19-7.24C12.55,1328.93,15.67,1330.45,19,1333.35Z" />
      <path d="M630.55,326.57a85.32,85.32,0,0,1,8.87-.55,3.9,3.9,0,0,1,2.88,2.14c.53,1.61.29,3.48.37,5.24a13.53,13.53,0,0,0-3.42,0c-3,.73-6.87.82-8.88,2.74-3.55,3.39-5.81,0-8.47-.62-.72-.18-1.67-3.31-1.17-3.86a37.87,37.87,0,0,1,6.79-5.54c.7-.48,2-.08,3-.08Z" />
      <path d="M640.61,1263.28c5.06,1.86,9.14,2.89,12.73,4.82a4.19,4.19,0,0,1,1,6.93c-1.6,1.49-4.57,2.2-6.86,2.08a13.37,13.37,0,0,1-7.25-2.71c-1.79-1.43-3.55-4.12-3.52-6.23C636.77,1266.41,639.41,1264.69,640.61,1263.28Z" />
      <path d="M969.71,836.86c.18,3.27.53,6.54.49,9.8,0,3.64-2.19,5.93-5.76,5.72s-5.14-2.91-5.05-6.45c.09-2.93.24-5.85.31-8.77.07-3.44,1.59-5.51,5.2-5.56S970.31,833.24,969.71,836.86Z" />
      <path d="M328,580.58l-3,2.17,4.06,5.46-6,.63-2.13-9-6.06,4.2,3.26,5.15c-2.5.81-5,2-7.5,2.15-.56,0-1.38-3.38-2.05-5.22,0-.13.09-.32.1-.49.64-7.54.64-7.56,8-7.19,3.76.19,7.52.47,11.28.71Z" />
      <path d="M154,1378.1c0,1.93-.07,3.86,0,5.79.22,5.27-1.15,10.61,2.48,15.6,2.07,2.84-.32,8.17-3.41,8.87-3.46.8-6.87.31-8.71-3.36a6.31,6.31,0,0,1,3.74-9c3.3-1.08,4-2.85,3.72-6-.34-3.91-.08-7.88-.08-11.82Z" />
      <path d="M923.16,1278.22a28.8,28.8,0,0,1,1.62,3.76,4.82,4.82,0,0,1-.12,2.66c-.08.27-1.44.53-1.76.26-2.23-1.88-4.65-3.69-6.29-6-.48-.68,1.39-4.35,2.19-4.35,4.5,0,9.49-.36,13.36,1.45,3.66,1.71,6.22,5.89,9.08,9.14.38.43,0,2.13-.58,2.6a3.2,3.2,0,0,1-2.67-.06c-2.38-1-4.94-1.9-6.94-3.47-2.39-1.87-4.28-4.39-6.38-6.63Z" />
      <path d="M911.67,1304c2.27-1.44,3.85-3.27,5.24-3.14,4.28.4,9,.62,12.56,2.67s6,6.3,9,9.58c-1.15.74-3,2.34-3.35,2.08-3.64-3-9.64-3-11.3-8.68-.19-.66-2.21-.79-3.39-1.16.13,1,.3,2,.37,3.06s.06,2.21.09,3.32a15.12,15.12,0,0,1-2.69-1.56C916.26,1308.49,914.46,1306.67,911.67,1304Z" />
      <path d="M577.34,1231.93c-2.5,1-4.25,2.37-5.64,2.08-6.17-1.29-12.27-2.92-18.28-4.8-1.17-.36-2.78-2.76-2.48-3.55.51-1.3,2.44-2.92,3.74-2.89,8,.21,12.75,1.6,19.18,5.22C574.89,1228.58,575.54,1229.85,577.34,1231.93Z" />
      <path d="M426.64,448.78c.49-2.12.85-3.66,1.21-5.19l1.2-.33c.81,1.42,1.63,2.84,2.66,4.61,1.14-.28,2.57-1.1,3.58-.74.83.3,1.92,2.38,1.6,3-2.28,4.46-7,5.56-11.18,3.23a5.32,5.32,0,0,0-4.09,0c-1.83.79-3.27,2.64-5.12,3.2a6.57,6.57,0,0,1-5.1-.67c-1-.69-1.49-3.2-1-4.39a5.37,5.37,0,0,1,3.87-2.57C418.29,448.56,422.35,448.78,426.64,448.78Z" />
      <path d="M716.74,1117.09c-.13-3.72,4.17-6.63,7.5-4.62,3.91,2.34,6.46,1.53,9.11-1.82a6.09,6.09,0,0,1,4.32-1.77c.78,0,2.4,2,2.2,2.76-.43,1.55-1.48,3.45-2.82,4.12a133.57,133.57,0,0,1-13.77,5.86A5.07,5.07,0,0,1,716.74,1117.09Z" />
      <path d="M593,626c-.46-1.56-1.44-3.18-1.27-4.66.27-2.28.71-5.25,2.24-6.46,1.27-1,4.89-.67,6.22.5s1.38,4.08,1.52,6.25a24.26,24.26,0,0,1-.57,6.52,48.65,48.65,0,0,1-2.32,7c-.1.24-1.63.35-1.81.07-1.85-2.82-3.54-5.72-5.28-8.6Z" />
      <path d="M305.79,1366.46c-1.57.84-4.06,3.92-6.36-.1a5.27,5.27,0,0,1,.2-4.55c.55-.88,2.57-1,4-1.15a31.26,31.26,0,0,1,6.34-.1c2.73.29,5.42.94,9,1.6-4,2-4.78,4.31-3.4,8,.47,1.27-1.94,4.66-3.71,5.36-1.38.54-3.81-1.59-5.78-2.53l5.43-3.5Z" />
      <path d="M281.55,1159.28c.47,1.73,1.24,3.08,1.05,4.29a4.45,4.45,0,0,1-2.17,3c-1,.43-3.23.23-3.69-.49a40.47,40.47,0,0,1-3.73-7.93c-.13-.33,1.37-1.77,2.25-1.91,8-1.35,16-2.6,24-3.67,1.16-.15,2.47.84,3.71,1.3-1,.81-1.94,2.14-3.07,2.36C294,1157.38,288,1158.25,281.55,1159.28Z" />
      <path d="M137.57,492.08c-2.22-2.35-5.61-4.38-6.39-7.14-1.12-3.93,3.38-7.66,7.71-7.52a7.47,7.47,0,0,1,7.05,7.38C146,488.23,142.66,491.07,137.57,492.08Z" />
      <path d="M271.71,1422c-1.5-2.38-3.6-4.59-4.29-7.18-.5-1.84.9-4.19,1.45-6.31l3.87,4.54c7.28-5.25,7.22,1.79,7.59,5.24l8.78-1.06c.14,1.24.85,3.17.33,3.6-3.85,3.16-8.09,2.76-12.56-.75-2.73-2.14-3.84.3-5.24,2Z" />
      <path d="M741.84,555.25a6.64,6.64,0,0,1-6.67-6.78,7,7,0,0,1,7-7.12,7.42,7.42,0,0,1,7.12,7.12C749.23,552.07,745.68,555.3,741.84,555.25Z" />
      <path d="M453.09,447.25l3.58-6,3,5.51a23.21,23.21,0,0,1,5.47-.12c1,.16,1.82,1.83,2.72,2.82-1,.66-2,1.86-3,1.88-6.38.19-12.76.14-19.15.13-5,0-7.3-3.07-5.86-5.82Z" />
      <path d="M657.93,807.26c0-4,2.65-6.52,6.8-6.52a6.57,6.57,0,0,1,6.82,6.66,7.3,7.3,0,0,1-6.94,6.79A6.92,6.92,0,0,1,657.93,807.26Z" />
      <path d="M986.34,1001.39a3.53,3.53,0,0,1-5.45,3.06c-4.29-2.75-5.82-.33-7.53,3a10.27,10.27,0,0,1-3.76,3.95c-.49.28-3.18-1.6-3.12-2.34.15-1.72,1.3-3.39,2.18-5,.29-.54,1.39-.82,1.44-1.3.68-5.8,3.15-7.08,8-3.58.43.31,1.94-1,3-1.43A3.77,3.77,0,0,1,986.34,1001.39Z" />
      <path d="M407.76,741.67c3.44-.07,7,3.32,7,6.66a7.26,7.26,0,0,1-6.76,6.89,6.76,6.76,0,0,1-6.86-6.62A6.93,6.93,0,0,1,407.76,741.67Z" />
      <path d="M380.45,846.46a6.5,6.5,0,0,1-6.72,6.7,6.74,6.74,0,0,1-6.9-6.63c-.11-3.35,3.31-6.74,6.87-6.84A6.85,6.85,0,0,1,380.45,846.46Z" />
      <path d="M786.23,743.51c-.3-1.39-1-2.84-.78-4.16a4.76,4.76,0,0,1,2.07-3.1,3.22,3.22,0,0,1,3.12.46,4.17,4.17,0,0,1,.16,3.54c-1.35,2.64-2.49,5-.42,7.91.49.7-.37,3.34-1.27,3.79-1.42.71-4.45,1-5,.3a61.4,61.4,0,0,1-5.45-10.76c-.27-.66,1.17-2,1.82-3.07a20,20,0,0,1,2.6,2.51,18.91,18.91,0,0,1,1.55,3.16Z" />
      <path d="M383.12,1036.38c-1,1.08-1.86,2.67-3.06,3s-2.82-.35-4-1.07c-1.77-1.12-3.2-2.76-4.91-4-3.11-2.22-3.46-4.83-1.29-7.83s5.33-3.8,7.65-.88S381.17,1032.49,383.12,1036.38Z" />
      <path d="M814.67,1408c-.84-1.28-2.42-2.65-2.29-3.81s2-2.14,3.22-3c1.57-1,3.73-1.52,4.88-2.88,2.23-2.62,4.91-3.73,7.45-1.67,1.55,1.25,2.83,4.41,2.36,6.22-.75,2.87-3.39,4.22-6.88,3.55-2.62-.5-5.42-.1-8.14-.1Z" />
      <path d="M413.43,1138a44.78,44.78,0,0,1-5-4.23,15.61,15.61,0,0,1-3.2-5c-.5-1.45-.65-4,.19-4.69a6.36,6.36,0,0,1,5.12-.89,10.85,10.85,0,0,1,5.41,3.4c1.38,1.77,2.68,4.42,2.39,6.45C418.07,1134.75,415.46,1136.09,413.43,1138Z" />
      <path d="M342.9,760.43a19.63,19.63,0,0,1,1.21-3.61,21.86,21.86,0,0,1,2.5-3.06c1.11,1.11,3,2.1,3.17,3.34a16.7,16.7,0,0,1-.72,7c-1.09,3.5-5.78,3.53-8.79.2-1-1.08-2.28-2-2.85-3.25a30.15,30.15,0,0,1-2.15-7.12c-.06-.33,2.53-1.76,3.62-1.51,3.42.77,3,3.43,2.34,6.08-.13.52.26,1.18.42,1.78Z" />
      <path d="M574.69,1130.06l-7.46-1.27,3.21-4.82-.84-1.47c-1.81,1-3.59,2-5.42,3-1.2.63-2.46,1.15-3.7,1.72a16.79,16.79,0,0,1,0-4.31c.5-1.91,1.53-5.28,2.26-5.26a71.72,71.72,0,0,1,13,1.85c.45.09.72,2.45.56,3.69A65.3,65.3,0,0,1,574.69,1130.06Z" />
      <path d="M369.87,535.45c-.11,1.57-.19,2.72-.3,4.15-2.3-1.59-4.53-2.58-5.9-4.27-1.07-1.3-2-4.13-1.31-5.11.94-1.35,3.41-1.82,5.32-2.27a10.19,10.19,0,0,1,4.21.18c1.78.36,4.08.55,5.06,1.73.83,1,.74,3.74-.09,4.9s-3,1.17-4.65,1.49C371.64,536.36,371,535.85,369.87,535.45Z" />
      <path d="M796.46,1403.63c.14-2.59-.32-4.45.4-5.43,2.37-3.2,5.27-6,7.8-9.12,4-4.94,7.85-10.06,12-14.94.68-.8,2.37-.76,3.6-1.11a13.12,13.12,0,0,1-1.27,3.2c-4.21,5.67-8.52,11.26-12.8,16.87-2.21,2.89-4.4,5.78-6.66,8.63C799.12,1402.19,798.44,1402.41,796.46,1403.63Z" />
      <path d="M663.54,1135.21c0,3.88-2.92,5.11-6,3.65-3.75-1.78-7.76-3.08-11.37-5.1-1.33-.74-1.85-2.93-2.74-4.46,1.53-.63,3.31-2.1,4.55-1.73a105.4,105.4,0,0,1,13.08,5C662.24,1133.16,663,1134.63,663.54,1135.21Z" />
      <path d="M869.45,895.58c2.4-.29,4.86-1.05,7.16-.71,1.64.25,4.31,2,4.27,3.06-.07,1.73-1.93,4.85-3,4.83a83.45,83.45,0,0,1-13.55-1.86c-.94-.18-1.7-1.3-2.55-2a16.77,16.77,0,0,1,2.8-2.43,38.92,38.92,0,0,1,4.68-1.85Z" />
      <path d="M194.55,766.46c-.43-1.89-1-3.26-1-4.63,0-9.43.09-18.87.31-28.3,0-.83,1.25-1.63,1.92-2.44.66.62,1.9,1.27,1.89,1.87-.35,10.75-.84,21.5-1.34,32.24C196.3,765.4,195.81,765.58,194.55,766.46Z" />
      <path d="M6,1187.07c1.36-1.89,2-3.74,3.09-4.07,1.32-.38,3.73,0,4.42,1,2.11,3,4.25,3.82,7.8,2.64,1.11-.36,3.36,1.2,4.3,2.47.53.71-.15,3.32-1,3.83a7.4,7.4,0,0,1-5,.57,57.69,57.69,0,0,1-9.39-3.1C8.75,1189.78,7.6,1188.38,6,1187.07Z" />
      <path d="M656.22,1303.43c5.66,1.49,10.58,2.59,15.31,4.23.83.29,1.51,3.12,1.06,4.33a4.35,4.35,0,0,1-3.83,1.9,92.05,92.05,0,0,1-12.78-4.35,4.27,4.27,0,0,1-2-3.46C654.06,1305,655.65,1304.05,656.22,1303.43Z" />
      <path d="M919.78,1341.82c3.41,2.79,6.77,5,9.45,7.89,1.71,1.84,2.52,4.57,3.57,7a8.39,8.39,0,0,1,.22,2.68c-1-.06-2.24.21-3-.25-2.57-1.49-5.51-2.82-7.32-5-2.3-2.8-3.72-6.36-5.27-9.7C917.24,1344,918.76,1342.9,919.78,1341.82Z" />
      <path d="M192.91,469.21a23.17,23.17,0,0,1-4.41.94,9.49,9.49,0,0,1-2.87-.95c.64-.81,1.16-2.13,2-2.32,2.26-.53,5-3.26,6.69,1.07.23.58,2,.72,3.14.75,3.58.09,6,1.44,5.76,5.39-.21,3.3-1.94,5.52-5.53,5.26-4-.28-5.68-2.58-5.08-6.56C192.68,472,192.72,471.14,192.91,469.21Z" />
      <path d="M930.44,1384.82c-.47.86-.68,1.8-1.11,1.91a4.56,4.56,0,0,1-2.74-.09c-2.77-1.19-6-2-8-4-2.64-2.62-4.3-6.24-6.38-9.43,1.12-.44,2.46-1.49,3.3-1.18a18,18,0,0,1,5.78,3.39C924.46,1378.34,927.35,1381.61,930.44,1384.82Z" />
      <path d="M936.25,1340.55c-1.29,1.83-1.63,2.86-2.24,3a3.87,3.87,0,0,1-2.7-.35c-2.31-1.25-5-2.31-6.64-4.18a47.89,47.89,0,0,1-5.77-9c-.14-.26,2.27-2.38,2.38-2.28C926.32,1331.9,931.23,1336.19,936.25,1340.55Z" />
      <path d="M587.07,931.13c-1.94,1.67-3.66,4-4.41,3.67-4-1.53-8.36,5.58-11.75-.46-.59-1,0-4,.88-4.4a38.76,38.76,0,0,1,10.66-3.25C583.59,926.54,585.09,929.14,587.07,931.13Z" />
      <path d="M635.72,692.46c-2.2-.28-3.76-.42-5.29-.7-2.25-.41-4.52-1.4-6.73-1.27-3.4.2-5.2-1.56-4.68-4.27.28-1.46,3.06-3.07,5-3.47C630.73,681.32,633.64,685.62,635.72,692.46Z" />
      <path d="M913.82,1358.14c5.59-1.55,7.52,3.37,10.35,5.16,3.17,2,4.81,6.41,7.12,9.77-.4.49-.79,1-1.19,1.49-3.33-2-7.18-3.45-9.86-6.1S916.18,1362.09,913.82,1358.14Z" />
      <path d="M896.91,583.06l4.47-5c.5,1.25,1.34,2.3,1.11,3a74.21,74.21,0,0,1-3.48,8.71c-.15.33-1.93.43-2.33,0a66.39,66.39,0,0,1-8-8.89c-.57-.82.86-4.3,2-4.71,1.36-.49,3.68.73,5.12,1.82C896.61,578.55,896.41,580.41,896.91,583.06Z" />
      <path d="M289.71,78.88c1,1.53,3.27,3.59,3.91,6.05.8,3.08-2.95,6.6-6.08,5.56-2.26-.75-5.14-3.31-5.45-5.4C281.58,81.7,284.1,79.19,289.71,78.88Z" />
      <path d="M940,1301c-.58.67-1,1.68-1.62,1.78a4.7,4.7,0,0,1-2.66-.71c-1.73-.89-3.27-2.37-5.08-2.84-4.71-1.21-5-5.54-6.6-8.87-.18-.35,1.92-2.42,2.16-2.28,3.12,1.76,6.38,3.46,9,5.84C937.15,1295.73,938.29,1298.48,940,1301Z" />
      <path d="M968.94,243.75c2.76,3.76,5.48,5.85,5.74,8.2.38,3.51-2.33,6-6.12,6s-6.32-2.74-5.63-6.31C963.39,249.27,966.07,247.35,968.94,243.75Z" />
      <path d="M819,954.48c-.92.63-2.36,2.35-4,2.56a33.83,33.83,0,0,1-9.58-.26,5.74,5.74,0,0,1-3.7-2.95c-.42-1,.41-3.19,1.37-3.85,1.16-.8,3.74-1.29,4.49-.59,2.16,2,3.65,1.21,5.81.19C816.23,948.25,819.24,950.46,819,954.48Z" />
      <path d="M813.81,143.64c4.51,1.26,7.22,2.4,7.36,6.32.16,4.15-2.57,6-6.06,6s-6.35-2.06-5.77-5.62C809.74,148,812.25,145.88,813.81,143.64Z" />
      <path d="M45.64,1108.93a33.58,33.58,0,0,1-6.27.59,6.45,6.45,0,0,1-4.25-1.94c-.67-.88-.56-3.35.2-4.07,1.14-1.09,3.11-1.45,4.8-1.76a16.45,16.45,0,0,1,5.05-.15c1.54.21,3.53.51,4.36,1.54a5,5,0,0,1,.41,4.49c-.61,1.18-2.55,1.67-3.9,2.47Z" />
      <path d="M267.5,632.2c-6.73-2.35-8.59-10.21-3.48-14.58a5,5,0,0,1,4.55-.38,4.85,4.85,0,0,1,2,4.06A99.79,99.79,0,0,1,267.5,632.2Z" />
      <path d="M119.72,484c.66,6.43-.92,7.89-6.68,6-.94-.3-2.14.29-3.22.31a29,29,0,0,1-5.67-.2c-.26-.05-.66-2.81-.08-3.39,1.44-1.45,3.33-3.1,5.19-3.31C112.53,483.13,115.92,483.78,119.72,484Z" />
      <path d="M923.44,1411.49c-.86.64-1.54,1.55-2.31,1.65-3.78.46-9.51-3.91-10.68-7.77-.32-1.07-1.39-1.92-1.69-3a3.19,3.19,0,0,1,.41-2.66,2.66,2.66,0,0,1,2.54-.44c3.5,3.16,6.85,6.48,10.19,9.8A14.58,14.58,0,0,1,923.44,1411.49Z" />
      <path d="M932.33,1326.43c-5.77-.27-10.78-4.45-11.35-9.08-.1-.8,2.44-2.83,2.79-2.63a77.27,77.27,0,0,1,9.9,6.72c.91.74,1.31,2.75,1.08,4C934.62,1326.09,932.55,1326.35,932.33,1326.43Z" />
      <path d="M132.75,587.15c-.5-1.2-1.51-2.45-1.4-3.59.21-2.27.36-5.52,1.78-6.44s5.82-.25,6.38.93c.9,1.91-.1,4.84-.66,7.25-.22,1-1.4,1.73-2.18,2.56-1,1-2,2-2.94,3l-2-3.12Z" />
      <path d="M975.78,735.25c3.69,1.21,7,1.72,9.51,3.38,1.15.75,1.47,4,.86,5.68-.33.91-3.51,1.42-5.16,1a15.91,15.91,0,0,1-6-3.27c-1.13-.91-2.37-2.74-2.19-3.94S974.9,736,975.78,735.25Z" />
      <path d="M207.32,466.41a27.14,27.14,0,0,1,3.22,1.29c2.54,1.58,2.92,12.3.33,13.86-1.08.64-3.21.85-4.07.2-1.1-.82-1.63-2.66-2-4.16a12.86,12.86,0,0,1-.24-5.47C205.11,470.1,206.4,468.26,207.32,466.41Z" />
      <path d="M928,1399.73c-1.78.36-2.84.93-3.64.67-2.19-.7-4.2-2-6.4-2.62-5-1.44-4.36-6.76-7-9.82-.12-.14.53-1.27,1-1.47a2.5,2.5,0,0,1,1.83.32c2.45,1.36,5.17,2.45,7.18,4.31C923.41,1393.38,925.24,1396.31,928,1399.73Z" />
      <path d="M324.45,601.81c-3.86,1-7.68,2.32-11.6,2.83-1.37.18-3-1.62-4.5-2.52,1-1.47,2-4.17,3-4.19a136.86,136.86,0,0,1,16.18.8c.76.07,1.4,1.51,2.1,2.32l-4.36,2.81Z" />
      <path d="M159.51,514.12c-2.27,1.55-4.46,4.22-6.84,4.4-3.14.24-5.64-2.35-5.41-6,.22-3.5,2.86-5.38,5.91-4.79,2.31.44,4.27,2.66,6.38,4.09Z" />
      <path d="M793,1115.18c.07-1.17-.3-2.69.3-3.45a31.7,31.7,0,0,1,4.51-4,30.7,30.7,0,0,1,3.76,4.66c.46.84-.25,2.28-.26,3.46,0,3.15-1.35,5.77-4.63,5.08-1.85-.39-3.24-3-4.83-4.65Z" />
      <path d="M255.76,1054.49c-2.06,1.42-4,3.7-6.22,4-3,.49-5.51-1.54-5.62-5-.11-3.75,2.4-6,5.68-5.57,2.18.26,4.12,2.67,6.16,4.12Z" />
      <path d="M52.62,1372.79c-2.12,1.52-4.31,4.31-6.32,4.19s-5.25-2.82-5.68-4.87A4.71,4.71,0,0,1,46,1366.4c2.32.33,4.35,2.69,6.51,4.15Z" />
      <path d="M863.21,464.21a44.34,44.34,0,0,1-7-.12c-.92-.15-1.62-1.66-2.42-2.55.88-.58,1.74-1.64,2.63-1.65,6.21-.1,12.43,0,18.64.16.65,0,1.25,1.25,1.87,1.92-.82.64-1.62,1.79-2.48,1.83-3.73.21-7.49.09-11.23.09Z" />
      <path d="M21.06,1306.53c-3.67,2.55-5.95,5.35-8.11,5.26-1.87-.08-5.31-3.82-5-5.27A8.68,8.68,0,0,1,13.6,1301C15.25,1300.65,17.58,1303.79,21.06,1306.53Z" />
      <path d="M800.14,719c-.63,1.51-.83,3.09-1.42,3.25a89.42,89.42,0,0,1-11.44,2.29c-.53.07-1.9-2-1.84-3s1.32-2.1,2.33-2.75a20.63,20.63,0,0,1,5.13-2.49,8.15,8.15,0,0,1,4.71-.31C798.72,716.37,799.38,718.05,800.14,719Z" />
      <path d="M602.44,1076.2c-.05-5.42,1.85-8.13,5.31-7.66,1.88.26,4.32,3.07,4.83,5.14.78,3.21-1.07,6.52-4.78,6.17C605.61,1079.64,603.65,1077.08,602.44,1076.2Z" />
      <path d="M713.06,1231.63l-7.25-8.39c1.75-1.26,3.76-3.8,5.2-3.5,3.17.65,7.82,1.09,7.58,5.81C718.5,1227.28,715.68,1228.87,713.06,1231.63Z" />
      <path d="M288.85,424.93c.17-1.09.35-2.18.54-3.39.72.09,1.41,0,1.62.23,1.26,1.57,2.8,3.09,3.46,4.91.48,1.3-.14,4.35-.52,4.39a57.41,57.41,0,0,1-10.65.22c-.61,0-1.74-2.94-1.48-3.16a62.74,62.74,0,0,1,6.24-4.24Z" />
      <path d="M618.14,1060.46c3.38-3,5.58-6.27,7.2-6,2.31.37,4.2,3.35,6.28,5.21-1.82,1.91-3.41,5-5.51,5.39C624.27,1065.4,621.85,1062.74,618.14,1060.46Z" />
      <path d="M671.94,946.85c1.35,2.21,3.83,4.46,3.74,6.61-.08,1.94-2.8,5.11-4.65,5.34a4.87,4.87,0,0,1-5.57-5.58c.41-2.32,2.86-4.29,4.39-6.42Z" />
      <path d="M12.76,1322c-2.06,1.49-4.11,4.13-6.19,4.15-1.85,0-4.95-2.63-5.32-4.52a4.63,4.63,0,0,1,5.34-5.72c2.16.3,4.07,2.43,6.09,3.73Z" />
      <path d="M821.49,1032.52c-2.77-.14-5.56-.16-8.31-.5-.78-.09-1.43-1.12-2.15-1.72a15.53,15.53,0,0,1,2.17-2.26,29.13,29.13,0,0,1,3.52-1.8,17,17,0,0,1-3-2.23,13.55,13.55,0,0,1-1.54-3c1.18-.17,2.81-.9,3.45-.39,1.58,1.24,2.61,3.17,4.05,4.62,1,1,2.49,1.37,3.33,2.39a5.73,5.73,0,0,1,1.55,3.74c-.09.84-1.79,1.51-2.78,2.26Z" />
      <path d="M350,951.43c1.34-.47,2.81-1.51,4-1.26,1.94.41,3.7,1.66,5.53,2.55-1.34,1.43-2.47,3.22-4.09,4.15-1.28.74-3.17.6-4.78.54s-3.62-.08-4.81-1c-.76-.57-.93-3.07-.31-4,.72-1.11,2.59-1.47,4-2.15Z" />
      <path d="M274.07,1362c1.74-.78,2.72-1.56,3.75-1.63a63.06,63.06,0,0,1,9.52-.12,8.72,8.72,0,0,1,4.7,2.06c.57.5.38,3-.3,3.52-1.27,1-3.27,1.91-4.74,1.64a65.07,65.07,0,0,1-9.86-3.1C276.31,1364,275.67,1363.22,274.07,1362Z" />
      <path d="M949.38,1173.66c2.84-2.3,5-4.89,5.79-4.53,2.5,1.08,5.72,2.95,6.4,5.19a4.29,4.29,0,0,1-5.14,5.22C954.25,1179.1,952.53,1176.41,949.38,1173.66Z" />
      <path d="M574.62,959.83c2.41,0,4.94-.43,7.19.17,1.33.35,2.21,2.35,3.3,3.61-1.45,1.07-2.77,2.71-4.38,3.06a11,11,0,0,1-6.27-.52,6.08,6.08,0,0,1-3.34-3.93c-.17-1.13,1.77-2.58,2.78-3.89Z" />
      <path d="M639,1088.87c1.25,2,3.22,4,3.58,6.15.52,3.09-1.69,5.1-4.88,5.06-3,0-5.61-1.73-5-4.93.42-2.28,2.56-4.24,3.94-6.34Z" />
      <path d="M673.74,591l2.41-3.88a29.79,29.79,0,0,1,1.44,4c.31,1.49,1,3.8.3,4.43a14.37,14.37,0,0,1-6.5,3.58c-1.08.23-3.42-1.78-3.79-3.16s.86-3.42,2-4.43C670.58,590.72,672.5,591.08,673.74,591Z" />
      <path d="M933.13,422.16a37.85,37.85,0,0,1,5.91-1.48,4.61,4.61,0,0,1,3.48,1.12,4.07,4.07,0,0,1,.72,3.54,4.51,4.51,0,0,1-3,2.16,19.79,19.79,0,0,1-7.13.41c-1.55-.29-2.84-2-4.24-3.05L932,420.7Z" />
      <path d="M482.4,1052.9c-.17,2,.12,4.2-.65,5.93-.6,1.32-2.55,2-3.9,3-.92-1.32-2.21-2.52-2.67-4a7.82,7.82,0,0,1,0-4.81c.64-1.68,2.08-4.2,3.26-4.25,1.57-.07,3.24,1.89,4.86,3Z" />
      <path d="M666.12,995.59c1.43,1.9,4.17,4,4,5.66-.26,1.93-3.06,4.74-4.91,4.86-1.62.11-4.55-2.78-4.87-4.69-.29-1.68,2.23-3.84,3.5-5.79Z" />
      <path d="M349.64,607.27c-3.21-2.74-7.14-4.59-7.1-6.35.11-4.81,4.95-3.69,8-3.75,1.36,0,4.09,2.74,3.89,3.84C354.08,602.89,351.94,604.45,349.64,607.27Z" />
      <path d="M451.68,794l4.5,5.91-8.61,6.14c-.76-1.83-2.6-4.13-2.05-5.37C446.52,798.45,449,796.83,451.68,794Z" />
      <path d="M421.2,504c1.89-2.24,3.3-4,4.79-5.64.41-.45,1.32-1.07,1.59-.91.79.48,1.92,1.28,1.95,2q.21,6.6-.11,13.21c0,.53-1.7,1.58-2.11,1.38-.85-.42-2.05-1.67-1.92-2.32C426.26,507.66,425,504.93,421.2,504Z" />
      <path d="M773.43,751.52c-1.86.83-3.8,2.46-5.53,2.26s-3.58-2-4.93-3.48a19.83,19.83,0,0,1-3-5.21,3,3,0,0,1,.14-2.63,2.92,2.92,0,0,1,2.66-.46,35.67,35.67,0,0,1,6.64,3.65c1.09.78,1.62,2.34,2.41,3.54Z" />
      <path d="M878.33,851.31c.48-1.37.74-3.47,1.9-4.78.52-.59,4.09.28,4.13.69a55.39,55.39,0,0,1-.09,10.3c0,.23-3.9.82-4.37.14C878.87,856.17,878.9,854,878.33,851.31Z" />
      <path d="M272,1284.21c-7.63-1.22-15.9,1.68-23.88-3.81C257.24,1278.61,264.92,1279.43,272,1284.21Z" />
      <path d="M869.37,997.5a56.37,56.37,0,0,1-2.21-5.22,46.69,46.69,0,0,1-1.74-7,2.93,2.93,0,0,1,1.2-2.45,3,3,0,0,1,2.56.7,21.33,21.33,0,0,1,4.35,4.61,6.54,6.54,0,0,1,.79,4.58C874,993.85,872.39,994.67,869.37,997.5Z" />
      <path d="M389.39,483c-2.2,1.44-4.24,3.44-6.65,4.12-1.73.49-4-.78-6-1.27.63-1.87,1.17-5.3,1.89-5.33,3.68-.2,7.41.49,11.12.86Z" />
      <path d="M820.33,917a11.46,11.46,0,0,1-.26,11c-.51,1-3.83.43-5.86.59-.08-2.16-.92-4.74,0-6.36C815.3,920.25,817.85,919,820.33,917Z" />
      <path d="M955.83,757.67c4.88-2.19,8.14-4.07,11.66-5,1.23-.32,4.14,1.47,4.37,2.66.26,1.38-1.66,4.57-2.51,4.53C965.26,759.66,961.22,758.64,955.83,757.67Z" />
      <path d="M514.9,708.14c-.85-1.43-3.4-4.47-2.9-5.09,1.85-2.34,4.69-3.91,7.26-5.61a16.88,16.88,0,0,1,2,2.51C518.83,702.21,521.74,707.77,514.9,708.14Z" />
      <path d="M907.25,450.07l9.51-1.57c.16-1.72.31-3.38.47-5l3.92.45c-.42,2.84-.57,4.93-1.09,6.92-.25,1-1.21,2.45-1.84,2.45-3.58,0-7.16-.42-10.74-.68C907.4,451.76,907.33,450.91,907.25,450.07Z" />
      <path d="M394,987.76c-1.84.56-3.56,1.67-4.87,1.31a5.11,5.11,0,0,1-3.05-3.46,4.74,4.74,0,0,1,2-4c1.16-.57,3.18,0,4.58.68a25.85,25.85,0,0,1,5.39,3.48c.76.62,1,1.81,1.56,2.75-1.12.45-2.3,1.35-3.34,1.2S394.63,988.33,394,987.76Z" />
      <path d="M837.85,1041.58c4.17,2.43,9.45,1.29,9.36,6.79-.06,3.8-3.6,3.45-6.23,3C835.24,1050.22,838.43,1046.06,837.85,1041.58Z" />
      <path d="M784.19,899.1c.74,2.56,2.1,5.17,2,7.69-.07,1.38-2.7,3.68-4.12,3.64s-4.07-2.86-3.82-3.86c.68-2.72,2.61-5.12,4-7.65Z" />
      <path d="M308,1354.43a40,40,0,0,1-5.63,1.79c-.88.15-1.94-.7-2.92-1.09.49-.94,1.11-2.74,1.43-2.69,2.67.45,3.71-.82,4.9-3,.56-1,3.45-1.78,4.59-1.22a5.09,5.09,0,0,1,2.19,4.36c-.27,1.36-2.36,2.36-3.65,3.51C308.57,1355.53,308.27,1355,308,1354.43Z" />
      <path d="M686.67,553.94a44.24,44.24,0,0,1,.11-6.52c.12-.83,1.41-1.49,2.16-2.22.68.83,1.88,1.62,1.93,2.49a73.31,73.31,0,0,1-.07,10.79c-.11,1.22-1.51,2.32-2.32,3.47a15,15,0,0,1-1.81-3.51,21.77,21.77,0,0,1-.07-4.5Z" />
      <path d="M182.26,588.5a43.18,43.18,0,0,1-4.65,2.92c-.17.09-1.76-1.78-1.6-2,1.48-2.27,3.2-4.37,5.14-6.89,2.91,3.23,5.31,5.66,7.38,8.34.63.81.38,2.31.52,3.5-1.23-.4-2.79-.46-3.63-1.26C184.13,591.86,183.34,590.12,182.26,588.5Z" />
      <path d="M795,729.73l8.61.48c-.07,1.19.21,3.25-.42,5s-2.19,2.91-3.33,4.34c-1.35-1.28-3.22-2.33-3.91-3.9C795.07,733.68,795.19,731.32,795,729.73Z" />
      <path d="M401.57,1397.06c1.84.63,4.27.73,4.84,1.82a6,6,0,0,1-.32,5.29c-.6.87-3.17.57-4.82.43-.59,0-1.06-1.2-1.64-1.81-1.25-1.33-2.54-2.63-3.82-3.94Z" />
      <path d="M821.31,658.75c2-.26,3.93-.53,5.9-.75,1.52-.18,3.59-1,4.44-.26a4.61,4.61,0,0,1,1,4.35c-.45,1-2.59,1.43-4.06,1.77-.9.22-2-.45-2.91-.29-5.16.91-5.36.71-4.25-5Z" />
      <path d="M360.12,1318.17c-2.63,1.81-5.13,3.87-8,5.18-.54.25-2.45-2.47-3.73-3.82,1.61-1.28,3-3.15,4.87-3.67s4.27.15,6.42.3Z" />
      <path d="M165.26,835.54c0,4,.25,6.48-.12,8.9-.14.88-1.74,1.53-2.67,2.29-.61-.86-1.87-1.83-1.74-2.56.64-3.53,2-7.11-3-8.7a26.19,26.19,0,0,1,.64-3.28Z" />
      <path d="M121.5,529.21a70.91,70.91,0,0,1,.74-7.71c.16-.93,1.63-1.62,2.49-2.42.59.9,1.67,1.79,1.67,2.7a108.44,108.44,0,0,1-.52,11.54c-.09.85-1.33,1.58-2,2.37-.78-.71-1.84-1.29-2.25-2.18S121.5,531.53,121.5,529.21Z" />
      <path d="M773.41,729.88c1.47,2.24,3.5,4.34,4.13,6.79.26,1-2.39,4-3.79,4.06-3.4.14-4.36-2.63-3.83-5.68a37.32,37.32,0,0,1,1.5-4.94Z" />
      <path d="M764.81,727.55c-4.83-3.11-10-.93-9.79-6.64,0-1.32,3.28-3.86,3.63-3.64a36.22,36.22,0,0,1,7.37,6.27C766.5,724.08,765.26,726.17,764.81,727.55Z" />
      <path d="M916.18,1332.47a17.61,17.61,0,0,1,1.27,3.46,14,14,0,0,1-.31,3.37,13,13,0,0,1-2.72-1.41,10.76,10.76,0,0,1-2.77-3.23,10.63,10.63,0,0,1-1.27-5.25c.07-.76,2.78-2.12,3.55-1.73,1.49.76,2.46,2.55,3.64,3.92Z" />
      <path d="M744.84,600.84c-2.85,2.39-4.41,4.39-6.42,5-.86.28-4-2.64-3.74-3.38a10,10,0,0,1,4-5.35C739.5,596.7,741.85,599,744.84,600.84Z" />
      <path d="M143.09,1322.62c3.1,1.92,6,3.58,8.69,5.5a2.66,2.66,0,0,1,0,2.62,3.48,3.48,0,0,1-3,.85,57.17,57.17,0,0,1-7.89-4.35,2.57,2.57,0,0,1-.58-2.47C140.86,1323.91,142,1323.42,143.09,1322.62Z" />
      <path d="M688.73,754.39c-1.84,1-3.75,3-5.51,2.82-1.46-.11-3.88-2.71-3.79-4.07s2.59-3.89,4.06-3.9c1.76,0,3.54,2.07,5.31,3.25C688.78,753.12,688.76,753.76,688.73,754.39Z" />
      <path d="M632.7,1131.19c-1.84-3.34-3.7-5.21-3.6-7,.08-1.39,2.57-2.64,4-4,1.32,1.6,3.49,3.08,3.71,4.82C637,1126.49,634.84,1128.2,632.7,1131.19Z" />
      <path d="M392,890.69c2.05,2.8,4.36,4.67,4,5.82-.49,1.83-2.75,3.17-4.26,4.71-1.43-1.42-3.95-2.8-4-4.28S389.86,893.6,392,890.69Z" />
      <path d="M281.61,504.48c-.78,1.33-1.1,2.4-1.82,3-2.64,2.1-2.37,4.44-1.23,7.21.34.84-.44,2.14-.7,3.23-.71-.75-2-1.43-2.06-2.24a94.1,94.1,0,0,1-.57-11.86c0-.71,2-2.06,2.9-1.89C279.37,502.14,280.39,503.51,281.61,504.48Z" />
      <path d="M962.68,888.14c-2.49,1.93-4.13,4.15-5.33,3.94-2.61-.46-5-2.17-7.47-3.37,2.45-1.48,4.78-3.4,7.44-4.2C958.37,884.19,960.17,886.36,962.68,888.14Z" />
      <path d="M799.71,944.13c-1.87,1.14-3.52,2.94-5,2.79-1.26-.14-3.45-2.77-3.19-3.78.64-2.53,2.44-4.76,3.77-7.12Z" />
      <path d="M916.83,1311.92c.75,1.86,1.49,3.36,1.95,4.95a30.32,30.32,0,0,1,1.14,5.56c.08.81-.56,1.69-.87,2.53a8.32,8.32,0,0,1-2.46-1,20,20,0,0,1-3.56-3.73c-.76-1.11-1.63-2.73-1.32-3.81S913.69,1314.56,916.83,1311.92Z" />
      <path d="M97.38,398.14c-1.74-2.61-3.88-4.53-3.51-5.57.62-1.76,2.82-2.95,4.35-4.39,1.4,1.64,3.47,3.12,3.91,5C102.35,394.14,99.72,395.79,97.38,398.14Z" />
      <path d="M640.82,645.23l-5.13-5.91c1.55-1.29,3.06-3.54,4.65-3.6,1.43-.05,3.9,2.13,4.16,3.63S642.64,642.51,640.82,645.23Z" />
      <path d="M716.75,752.72c2,2.65,4.24,4.38,4,5.71-.28,1.74-2.51,3.17-3.9,4.73-1.44-1.54-3.72-2.92-4-4.66C712.59,757.18,714.83,755.39,716.75,752.72Z" />
      <path d="M49.86,687c4.17-.53,6.75-1.19,9.28-1,1.13.08,2.5,1.64,3.1,2.85a3.24,3.24,0,0,1-.92,3.07c-1.16.67-3.11,1.14-4.15.6C54.9,691.3,53,689.42,49.86,687Z" />
      <path d="M923.61,1297.75c-.59.18-2,1.06-2.59.68a20.48,20.48,0,0,1-5.82-5c-.7-1-.22-3.89.75-4.7s4-.32,4.61.58C922,1291.56,922.47,1294.36,923.61,1297.75Z" />
      <path d="M803.17,788.49c-3,1.44-5,3.21-6.56,2.92s-3.79-2.84-3.63-4.14c.19-1.53,2.91-4.1,4-3.86C798.93,783.8,800.43,786.07,803.17,788.49Z" />
      <path d="M821.43,658.6c-4.9.25-9.79.55-14.69.64-.37,0-1.22-1.74-1-2.38a2.94,2.94,0,0,1,2.25-1.52,47.58,47.58,0,0,1,9.77.11c1.31.2,2.36,2.16,3.54,3.31Z" />
      <path d="M149,1242.78c2.16,2.64,4.45,4.23,4.35,5.64s-2.38,3.77-3.9,3.94c-1.28.15-3.82-2.12-4-3.55S147.28,1245.57,149,1242.78Z" />
      <path d="M990.49,989.46c-2.59,1.75-4.28,3.77-5.85,3.68s-4.2-2.66-4-3.61c.42-1.88,2.48-3.39,3.87-5Z" />
      <path d="M795.89,1187.1c-1.78-3.09-3.73-4.95-3.48-6.43s2.7-3.7,4.16-3.68,3.59,2.59,3.62,4.07S798.08,1184.21,795.89,1187.1Z" />
      <path d="M815,1008.88l7.29,6.53a11,11,0,0,1-.88,3.44c-.39.71-2.36,1.46-2.65,1.18-2-1.87-4.19-3.77-5.23-6.15C813,1012.57,814.55,1010.32,815,1008.88Z" />
      <path d="M837.94,767q1.06-12.9,2.11-25.82l1.92.15-2,25.82Z" />
      <path d="M155.6,1260.29c-3.14,1.71-4.9,3.44-6.53,3.32-1.36-.1-3.81-2.72-3.56-3.59.46-1.65,2.45-3.87,3.93-4S152.63,1258.11,155.6,1260.29Z" />
      <path d="M842.76,1342.67c-1.44-1.13-2.46-1.75-2.39-1.91,1.47-3.44,3.06-6.83,4.63-10.23l3.4,1.68C846.58,1335.58,844.76,1339,842.76,1342.67Z" />
      <path d="M151.12,1222.57c1,1.65,2.76,3.36,2.66,5-.09,1.31-2.43,3.5-3.71,3.45s-3.75-2.21-3.78-3.5c0-1.62,1.85-3.29,2.9-5Z" />
      <path d="M780.94,763c-2.9,1.62-4.67,3.4-6.28,3.27-1.31-.11-3.53-2.69-3.35-3.88.25-1.68,2.33-3.09,3.63-4.62Z" />
      <path d="M860.55,642.24c1.86,3.12,3.79,4.91,3.58,6.4s-2.51,2.78-3.9,4.15c-1.28-1.45-3.47-2.84-3.59-4.38S858.53,645.24,860.55,642.24Z" />
      <path d="M834.93,722.84c1.94,3,3.82,4.71,3.82,6.37,0,1.45-2.12,2.89-3.31,4.33-1.46-1.34-3.89-2.54-4.11-4.06S833.07,726.05,834.93,722.84Z" />
      <path d="M25,514.26c-2.84,2-4.64,4.15-6,3.89-1.56-.3-3.45-2.6-3.74-4.28-.17-1,2.39-3.5,3.76-3.53C20.56,510.31,22.15,512.29,25,514.26Z" />
      <path d="M742.49,1014.93c2.11,2.39,4.46,4,4.26,5.07-.31,1.71-2.35,3.12-3.66,4.66-1.43-1.37-3.59-2.55-4-4.19C738.74,1019.32,740.77,1017.54,742.49,1014.93Z" />
      <path d="M149,1289c-1.9-2.6-4-4.23-3.76-5.46a6,6,0,0,1,3.76-4c1.05-.24,3.82,2.15,3.79,3.31C152.78,1284.54,150.9,1286.18,149,1289Z" />
      <path d="M283.67,525.5c-2.83,1.85-4.44,3.72-6,3.69-1.32,0-3.8-2.49-3.62-3.48.31-1.66,2.19-4,3.66-4.19S280.83,523.49,283.67,525.5Z" />
      <path d="M730.22,1331.69c-3.16,1.76-4.91,3.48-6.57,3.41-1.25-.06-3.53-2.69-3.3-3.73.35-1.6,2.4-3.83,3.83-3.9S727.31,1329.53,730.22,1331.69Z" />
      <path d="M908.86,1394c-.45.24-1.84,1.29-2.06,1.08a35.14,35.14,0,0,1-5.88-6.51c-.46-.72.94-3,2-4,.34-.35,2.86.43,3.25,1.24C907.27,1388.14,907.84,1390.77,908.86,1394Z" />
      <path d="M214,491.46a23.86,23.86,0,0,1-5-.35c-.86-.24-1.45-1.49-2.16-2.28.84-.67,1.64-1.87,2.51-1.92a26.39,26.39,0,0,1,7,.56c1.34.3,2.48,1.51,3.71,2.31-1.35.59-2.67,1.26-4.06,1.72a5.73,5.73,0,0,1-2-.07Z" />
      <path d="M913.23,853.68c2.92-2.13,4.61-4.3,6-4.11,1.59.21,3.63,2.32,4,4,.24,1-2.22,3.58-3.63,3.72S916.33,855.53,913.23,853.68Z" />
      <path d="M103.53,1346.51c3.65,1,6.76,1.67,9.72,2.74.76.28,1.07,1.81,1.58,2.77-1.09.42-2.3,1.39-3.24,1.17a45.38,45.38,0,0,1-8.06-2.76,3.45,3.45,0,0,1-1.58-2.54C101.92,1347.35,103.26,1346.73,103.53,1346.51Z" />
      <path d="M106.56,1244.71c-2.76,1.76-4.49,3.73-5.75,3.47-1.65-.35-2.92-2.47-4.35-3.83,1.53-1.26,3-3.29,4.64-3.53C102.36,1240.63,104,1242.75,106.56,1244.71Z" />
      <path d="M155.66,1237.84c-3.16,1.82-4.81,3.44-6.51,3.5-1.11,0-3.48-2.67-3.22-3.31.68-1.67,2.31-3.64,3.91-4C151,1233.74,152.74,1235.81,155.66,1237.84Z" />
      <path d="M332.18,579.37c4.46-.84,8.44-1.71,12.46-2.25.91-.12,2,.9,3,1.39-.49.93-1,2.65-1.47,2.67C341.54,581.35,336.59,584.19,332.18,579.37Z" />
      <path d="M724.07,1348.51c-1.75-2.64-3.64-4.22-3.48-5.56s2.47-3.75,3.54-3.6c1.64.24,4,2.13,4.21,3.56S726.2,1345.88,724.07,1348.51Z" />
      <path d="M90.14,1159.18c1.8,2.66,3.82,4.35,3.56,5.57-.33,1.63-2.43,2.89-3.78,4.31-1.36-1.29-3.8-2.59-3.8-3.88C86.12,1163.57,88.14,1162,90.14,1159.18Z" />
      <path d="M409.1,1137.13c-3,1.78-4.7,3.64-6.22,3.49s-2.55-2.47-3.8-3.83c1.49-1.29,2.86-3.3,4.54-3.61C404.79,1133,406.39,1135.07,409.1,1137.13Z" />
      <path d="M741.79,582c1.93,2.74,3.87,4.31,3.91,5.93,0,1.31-2.41,3.91-3.43,3.75-1.71-.26-4.21-2.09-4.43-3.55S739.81,584.86,741.79,582Z" />
      <path d="M974,1133.77c-2-3-4.06-4.81-3.85-6.26s2.58-2.78,4-4.16c1.26,1.35,3.46,2.67,3.52,4.07C977.77,1129,975.92,1130.7,974,1133.77Z" />
      <path d="M729.13,589.55c-3.16,1.8-5,3.73-6.59,3.52s-3.38-2.48-3.79-4.16c-.21-.88,2.48-3.58,3.63-3.45C724.28,585.68,726,587.52,729.13,589.55Z" />
      <path d="M614.84,519.57c1.17-1,2.25-2,3.33-2.91,3.4,3.59,3.4,3.59-4.56,7.77a28.11,28.11,0,0,1-2.61-3.77,16.37,16.37,0,0,1-.92-3.77c1.19.53,2.39,1,3.54,1.6A6.88,6.88,0,0,1,614.84,519.57Z" />
      <path d="M148.27,1265.9c2.18,2.46,4.59,4,4.5,5.38-.1,1.56-2.45,3-3.82,4.44-1.4-1.4-3.52-2.62-4-4.28C144.69,1270.26,146.64,1268.48,148.27,1265.9Z" />
      <path d="M125.81,1253.31c-2.52,2.14-4.13,4.43-5.2,4.2-1.64-.35-2.91-2.44-4.33-3.79,1.27-1.3,2.4-3.4,3.86-3.67C121.43,1249.82,123.09,1251.64,125.81,1253.31Z" />
      <path d="M150.35,1189.76c1.78,2.71,3.81,4.51,3.48,5.61a6.07,6.07,0,0,1-4.13,3.67c-1.11.15-3.66-2.37-3.58-3.57C146.22,1193.88,148.25,1192.41,150.35,1189.76Z" />
      <path d="M943.66,845.62c1.89,2.48,4,4,3.82,5.33-.16,1.53-2.23,2.86-3.48,4.28-1.44-1.36-3.66-2.52-4.07-4.14C939.64,850,941.8,848.19,943.66,845.62Z" />
      <path d="M841.1,719.11c-2.64,2.12-4.24,4.38-5.63,4.25s-3.52-2.39-3.81-4c-.21-1.13,2.07-3.78,3.31-3.83C836.57,715.48,838.25,717.33,841.1,719.11Z" />
      <path d="M713.48,638.86c-2.6,1.88-4.27,4-5.53,3.77a5.94,5.94,0,0,1-4-3.87c-.2-1.15,2.24-3.9,3.43-3.85C709.1,635,710.72,636.93,713.48,638.86Z" />
      <path d="M418.82,1404.22c-2.51,1.71-4.25,3.77-5.42,3.48-1.62-.4-2.8-2.56-4.17-4,1.5-1.32,2.88-3.41,4.57-3.71C414.92,1399.83,416.5,1402.18,418.82,1404.22Z" />
      <path d="M150.32,1222.56c-2.11-2.78-4.15-4.31-4.16-5.84,0-1.28,2.51-3.74,3.63-3.6a6.05,6.05,0,0,1,4.09,3.72C154.2,1218,152.18,1219.74,150.32,1222.56Z" />
      <path d="M150.15,1212.24c-2-3-3.87-4.56-3.89-6.18,0-1.19,2.41-3.3,3.78-3.33s3.72,2.07,3.76,3.28C153.84,1207.6,152,1209.25,150.15,1212.24Z" />
      <path d="M214.13,479.09a19.87,19.87,0,0,1,.5-2.87c.77-1.91,1.61-3.79,2.42-5.69.58,1.94,1.22,3.85,1.7,5.81a3.41,3.41,0,0,1-.19,2c-.79,1.88-1.7,3.71-2.56,5.56Z" />
      <path d="M555.24,571.73a5.14,5.14,0,0,1-.16,2c-.76,1.38-1.73,2.66-2.61,4-.67-1.47-1.41-2.92-2-4.44a2.85,2.85,0,0,1,.1-2c.75-1.61,1.67-3.15,2.52-4.71l3,4.59Z" />
      <path d="M422.53,1062.3c2,2.62,4.16,4.28,4,5.6-.21,1.54-2.5,2.79-3.89,4.17-1.35-1.44-3.42-2.71-3.81-4.37C418.54,1066.58,420.64,1064.9,422.53,1062.3Z" />
      <path d="M195.86,1243.12c-2-2.79-3.93-4.28-3.89-5.72s2-4,3.15-4c1.42-.06,4,1.9,4.11,3.16C199.38,1238.2,197.58,1240,195.86,1243.12Z" />
      <path d="M960.74,1147.7c1.87,2.63,4,4.42,3.72,5.53-.45,1.65-2.65,2.81-4.11,4.18-1.25-1.5-3.23-2.89-3.5-4.55C956.66,1151.69,958.8,1150.14,960.74,1147.7Z" />
      <path d="M373.35,1386.43c.35,1.3,1.35,2.92.89,3.82-.8,1.6-2.43,3.74-3.8,3.82s-3.56-2.19-3.74-3.62c-.14-1.2,1.93-3,3.37-4,.76-.53,2.28,0,3.45.11Z" />
      <path d="M919.22,746.6c.88,1.54,2.64,3.24,2.4,4.57s-2.41,2.53-3.73,3.77c-1.1-1.28-3-2.54-3.08-3.86,0-1.48,1.64-3,2.57-4.51Z" />
      <path d="M71.23,1153.27c1.87,2.9,3.74,4.54,3.67,6.08-.07,1.29-2.76,3.68-3.68,3.42a6.31,6.31,0,0,1-3.81-4C67.17,1157.59,69.26,1156,71.23,1153.27Z" />
      <path d="M818.57,810.5c-2.59,1.73-4.27,3.71-5.6,3.5-1.48-.23-2.65-2.46-4-3.82,1.55-1.2,3-3,4.71-3.36C814.75,806.6,816.25,808.65,818.57,810.5Z" />
      <path d="M733.48,855.64c-2.7,1.67-4.38,3.54-5.72,3.32s-3.42-2.56-3.34-3.85,2.34-3.46,3.72-3.52S731,853.62,733.48,855.64Z" />
      <path d="M282.8,877.93c-2.48,1.87-4.31,4.14-5.36,3.83a6.24,6.24,0,0,1-3.63-4.31c-.14-1,2.39-3.39,3.78-3.45S280.44,876,282.8,877.93Z" />
      <path d="M193.17,1346.45c1.67,2.88,3.46,4.58,3.28,6s-2.48,3.54-3.73,3.46c-1.43-.09-3.9-2.37-3.81-3.51C189.06,1350.73,191.07,1349.2,193.17,1346.45Z" />
      <path d="M191.93,1384.31c-2,2.75-3.72,5.4-5.73,7.85-.34.41-2.08.24-2.61-.26a2.77,2.77,0,0,1-.06-2.58,49.08,49.08,0,0,1,4.08-6.33,2.88,2.88,0,0,1,2.42-1.06C190.73,1382.12,191.17,1383.29,191.93,1384.31Z" />
      <path d="M984.23,983.52c-1.84-2.55-4-4.28-3.68-5.37a5.94,5.94,0,0,1,4.11-3.52c1-.16,3.36,2.23,3.39,3.5S986.13,981,984.23,983.52Z" />
      <path d="M274.79,423c1.72,2.71,3.58,4.33,3.38,5.64s-2.42,3.46-3.68,3.41-3.55-2.19-3.63-3.53S272.79,425.67,274.79,423Z" />
      <path d="M645.12,1360.12c4.59,1.15,9.19,2.26,13.76,3.5a1.87,1.87,0,0,1,.91,1.56c0,.5-.88,1.42-1.15,1.34-4.78-1.28-9.52-2.69-14.27-4.07Z" />
      <path d="M72.36,388.36c.87,1.61,2.43,3.24,2.37,4.81,0,1.24-2.09,2.41-3.25,3.61-1.25-1.33-3.33-2.56-3.51-4s1.66-3,2.61-4.52Z" />
      <path d="M668.61,573.68l-8.41.55.51-5.1,8.11,1.75Z" />
      <path d="M841.63,982.72c-1.49-2.94-3-4.61-2.88-6.12.12-1.2,2.61-3.32,3.46-3.07,1.55.47,3.64,2.34,3.7,3.71S843.83,980.07,841.63,982.72Z" />
      <path d="M304.52,798.68c-2.73,1.75-4.23,3.48-5.42,3.28-1.47-.24-2.67-2.09-4-3.24,1.19-1.18,2.32-3.26,3.58-3.33C300.14,795.3,301.69,797,304.52,798.68Z" />
      <path d="M542.45,1266.27a13.09,13.09,0,0,1-2.76,1.14c-3.07.51-6.16.89-9.26,1.18a6.93,6.93,0,0,1-.54-2.09,72.81,72.81,0,0,1,8.75-3.21c.86-.23,2.05.76,3.09,1.19Z" />
      <path d="M555.82,635.84a78,78,0,0,1-9.76,1.57c-.63,0-1.4-2.37-2.1-3.65,1.31-.12,2.62-.27,3.94-.36,1.54-.11,3.07-.19,4.61-.24,1,0,2.09,0,3.14,0C555.7,634.05,555.76,635,555.82,635.84Z" />
      <path d="M914.15,423.66c-3,1.79-4.51,3.45-6,3.41-1.25,0-2.44-2.08-3.66-3.23,1.25-1.3,2.37-3.47,3.77-3.66S911.23,421.83,914.15,423.66Z" />
      <path d="M621.93,692.63c1.8,3.53,8-3.34,7.75,3.53-.18,4.91-4.8.52-7,2l-2.48-3.92Z" />
      <path d="M819.9,1060.28c-1.92-2.75-3.73-4.21-3.72-5.67,0-1.28,2-2.56,3.17-3.84,1.25,1.3,3.15,2.43,3.55,4C823.19,1055.84,821.51,1057.47,819.9,1060.28Z" />
      <path d="M988.28,1255.85c-1.66-2.83-3.48-4.61-3.12-5.65.54-1.61,2.44-3,4.07-3.87.42-.24,3,1.93,2.86,2.44C991.44,1250.81,990.1,1252.64,988.28,1255.85Z" />
      <path d="M943,762.65c-1.62-2.7-3.19-4.16-3.07-5.45s1.83-2.71,2.84-4.05c1.25,1.36,3.14,2.56,3.52,4.13C946.53,758.38,944.72,760,943,762.65Z" />
      <path d="M42,1402.32c3,.44,5,.46,6.78,1.1a3,3,0,0,1,.41,5.73,3.77,3.77,0,0,1-3.52-.48C44.26,1407,43.43,1404.93,42,1402.32Z" />
      <path d="M423.5,657.92c-1.87-2.7-3.86-4.35-3.65-5.62s2.43-3,4-3.28c1-.15,3.22,2.13,3.22,3.31C427,653.8,425.3,655.26,423.5,657.92Z" />
      <path d="M193.65,1251.78c.68,3.9-2.27,8.64-5,8.83a2.87,2.87,0,0,1-2.32-1.31,2.8,2.8,0,0,1,.52-2.6C188.82,1255,191,1253.63,193.65,1251.78Z" />
      <path d="M48.69,689.35c-1.84,1.44-3.41,3.31-4.06,3-1.54-.66-2.66-2.34-3.94-3.6L44.75,685Z" />
      <path d="M976.63,418.31l4.86,3.92c-1,.84-2.51,2.59-2.85,2.38-2-1.22-6.19,1.57-6.15-3C972.5,420.72,974.69,419.81,976.63,418.31Z" />
      <path d="M927.78,424.27c-2.28,1.56-3.72,3.33-5,3.19s-3.56-2.45-3.34-3.21a6.16,6.16,0,0,1,3.63-3.78C924,420.23,925.6,422.41,927.78,424.27Z" />
      <path d="M772.06,1132.45c-1.83-2.17-4-3.7-3.74-4.67a5.75,5.75,0,0,1,3.69-3.58c.88-.2,3.17,2,3.31,3.27S773.65,1130.17,772.06,1132.45Z" />
      <path d="M886.93,752.24l-4.7,3.53c-1-1.49-2.78-2.95-2.84-4.48,0-1.11,2.16-2.3,3.36-3.46Z" />
      <path d="M832.66,686.21c-1,.56-2.09,1.71-3,1.57-3.1-.47-6.14-1.39-9.2-2.14.91-.75,1.81-2.11,2.74-2.14,3.06-.07,6.12.34,9.18.56Z" />
      <path d="M476.1,482.44c-2.69,2-4.24,4.06-5.52,3.9-1.45-.18-2.65-2.34-4-3.64,1.27-1.11,2.43-2.92,3.84-3.14S473.27,480.9,476.1,482.44Z" />
      <path d="M39.88,1407.06c1.9,3,3.57,4.44,3.6,6,0,1.14-2.06,3.22-3.23,3.24s-3.47-1.84-3.54-3C36.62,1411.76,38.2,1410.14,39.88,1407.06Z" />
      <path d="M833.62,1018.6c-3.62,1.71-6,3-8.59,3.91-.28.11-1.81-2.25-1.75-2.3a28.68,28.68,0,0,1,5.88-4.37C829.8,1015.53,831.38,1017.16,833.62,1018.6Z" />
      <path d="M968.5,450.73c-2.32,1.74-3.69,3.55-5,3.5s-2.48-2-3.71-3.11c1.22-1.33,2.24-3.35,3.71-3.75C964.53,447.1,966.16,449.06,968.5,450.73Z" />
      <path d="M462.69,631.38,459.85,637c-.58-2.22-1.16-4.43-1.71-6.65a1.81,1.81,0,0,1,.11-1c.67-1.63,1.37-3.25,2.07-4.87.49,1.64,1,3.28,1.44,4.93a5.85,5.85,0,0,1,0,1.51Z" />
      <path d="M779.48,716.64c-2.37,1.66-3.77,3.4-5,3.26s-3.17-2.14-3.11-3.25a4.73,4.73,0,0,1,3.12-3.42C775.68,713.07,777.1,714.9,779.48,716.64Z" />
      <path d="M562,523.79c-2.5,2-3.87,3.89-5.31,4-1.12,0-3.12-2.16-3.25-3.51-.1-1.1,1.84-3.19,3.11-3.41S559.34,522.23,562,523.79Z" />
      <path d="M684.42,1107c-2.24,2-3.47,4-4.83,4.09s-2.73-1.81-4.1-2.83c1.1-1.25,2-3.23,3.36-3.52S681.84,1105.88,684.42,1107Z" />
      <path d="M600,1251.05c-2.49,1.82-3.83,3.57-5.13,3.53s-2.49-1.86-3.74-2.91c1.13-1.2,2.12-3.13,3.43-3.38S597.32,1249.61,600,1251.05Z" />
      <path d="M627,1074.34l-3.92,6.3c-.75-1.78-2.12-3.57-2.07-5.33,0-1.49,1.7-2.93,2.65-4.39Z" />
      <path d="M58.83,1152.53c-.39,1.19-.41,1.71-.61,1.79-1.13.48-2.7,1.53-3.4,1.13a64.2,64.2,0,0,1-6.87-5.06s.09-.4.2-.43c.87-.3,1.9-1,2.61-.73C53.53,1150.17,56.19,1151.42,58.83,1152.53Z" />
      <path d="M512,891.49c-2.23,1.79-3.66,3.77-4.72,3.59-1.49-.26-2.7-2.12-4-3.29,1.17-1.18,2.23-3.14,3.53-3.32S509.52,890,512,891.49Z" />
      <path d="M39.59,1266.89c-2.86,1.62-5.76,2.12-6.73-1.81-.34-1.39,1.39-3.29,2.17-5a16.11,16.11,0,0,1,3,2.85,18.84,18.84,0,0,1,1.46,4.06Z" />
      <path d="M955.28,1386.06c-2.38,1.54-3.88,3.27-5.13,3.1s-3.15-2.35-3-3.47a4.85,4.85,0,0,1,3.39-3.34C951.72,1382.27,953.07,1384.23,955.28,1386.06Z" />
      <path d="M633.21,529.6c-2,1.45-3.37,3.08-4,2.82-1.61-.71-2.88-2.21-4.29-3.4,1.28-1.12,2.48-3,3.84-3.13C629.91,525.8,631.21,527.82,633.21,529.6Z" />
      <path d="M794.54,772.06c1.75,2.52,2.81,4,3.87,5.54a22.72,22.72,0,0,1-4.86,2.52c-.46.13-2.25-2-2.1-2.82C791.74,775.8,793,774.48,794.54,772.06Z" />
      <path d="M535.2,1079.91c1.67,2.49,3.42,4,3.3,5.26s-2.15,2.35-3.34,3.51c-1.11-1.22-3-2.37-3.12-3.68S533.62,1082.3,535.2,1079.91Z" />
      <path d="M695,512.13c-1.64-2.07-3.57-3.46-3.43-4.6s2.24-3,3.5-3c1.09,0,2.79,2,3.09,3.36C698.3,508.78,696.52,510.12,695,512.13Z" />
      <path d="M684.37,1314.76c-2.5,1.6-3.87,3.15-5.23,3.14-1.12,0-3.27-2.09-3.11-2.86.3-1.41,1.85-3.37,3.1-3.55S681.84,1313.08,684.37,1314.76Z" />
      <path d="M469.77,567.29l-2.86,4c-.59-1.57-1.24-3.12-1.76-4.71a4.25,4.25,0,0,1-.33-2.43,14.8,14.8,0,0,1,1.94-2.94,16,16,0,0,1,2.38,3c.28.58-.3,1.57-.5,2.38Z" />
      <path d="M307.48,1035.89c1.86,1.57,3.85,3.07,5.58,4.83a2.74,2.74,0,0,1,.42,2.61,2.9,2.9,0,0,1-2.54.89C306.15,1043.25,305,1039.92,307.48,1035.89Z" />
      <path d="M119.67,448.76c-.1-1-.65-2.35-.2-2.9a7.23,7.23,0,0,1,3.24-1.79,3.15,3.15,0,0,1,1.13,1.94c-.31,2.76-.88,5.48-1.35,8.22-.88-.5-2.21-.8-2.52-1.53-.5-1.16-.31-2.61-.41-3.93Z" />
      <path d="M911.84,1350.29c-1.78-2.39-3.68-3.85-3.52-5s2.06-2.89,3.51-3.33c.7-.2,2.94,1.92,3,3C914.86,1346.36,913.34,1347.79,911.84,1350.29Z" />
      <path d="M619.6,1141.26c-1.69-1.91-2.75-3.09-3.81-4.28,1.22-1,2.52-3,3.63-2.86,1.63.15,3.12,1.76,4.67,2.74C622.84,1138.09,621.59,1139.32,619.6,1141.26Z" />
      <path d="M9.8,1135.51c.2.64.71,1.4.52,1.88a17.29,17.29,0,0,1-1.81,2.93,35.19,35.19,0,0,1-2.43-2.9c-.2-.29.3-1,.23-1.44-.3-2-2.23-4.54,1.27-5.1.84-.14,2.13,2.49,3.21,3.84Z" />
      <path d="M278.79,1169.83c1.68,2.67,3.38,4.19,3.2,5.42s-2.21,2.31-3.42,3.44c-1-1.16-2.77-2.29-2.81-3.49C275.72,1173.85,277.21,1172.45,278.79,1169.83Z" />
      <path d="M578.81,563.83l-3.93,7a44.73,44.73,0,0,1-.71-7.25c.05-1.11,1.4-2.15,2.16-3.22Z" />
      <path d="M100.63,877.21c1.17,0,2.48-.33,3.47.1.82.35,1.28,1.58,1.9,2.42a10.47,10.47,0,0,1-3,1.53,25.08,25.08,0,0,1-4.38-.27c-1,0-2.16.26-3.07-.08-.44-.16-.91-1.81-.63-2.1a5.65,5.65,0,0,1,2.77-1.54,9.55,9.55,0,0,1,3,.26Z" />
      <path d="M749.55,966c1.74,2.12,3.75,3.77,3.47,4.39-.69,1.54-2.43,2.61-3.75,3.86-1-1.13-2.81-2.27-2.78-3.38C746.52,969.55,748.08,968.22,749.55,966Z" />
      <path d="M303.58,453.4l-4.38-3.07c1.16-.77,2.39-2.25,3.47-2.14,2,.19,3.88,1.32,5.81,2.06Z" />
      <path d="M109.58,1205.57c-1.39.18-3,.89-4.13.4-1.68-.73-3-2.29-4.46-3.5,1.27-.42,2.65-1.37,3.8-1.14,1.81.35,3.47,1.49,5.2,2.29Z" />
      <path d="M947.55,431.2c-.21,3.71-.37,7.44-.72,11.14,0,.32-1.43.73-2.16.66-.44,0-1.23-1-1.15-1.39.62-3.53,1.41-7,2.15-10.54Z" />
      <path d="M683.81,1165.08c1.7,1.93,3.76,3.53,3.5,4.11a7.17,7.17,0,0,1-3.67,3.38c-.58.23-2.95-1.79-2.84-2.53C681,1168.56,682.4,1167.24,683.81,1165.08Z" />
      <path d="M780.55,1302.19l12.69-2.5C793.11,1302.94,785.84,1305.11,780.55,1302.19Z" />
      <path d="M267.67,1357.44a61.44,61.44,0,0,1,6.39-3.84c.94-.43,2.33.13,3.51.24-.37,1-.49,2.5-1.16,2.79-2.62,1.13-5.4,1.87-8.13,2.75C268.08,1358.73,267.87,1358.08,267.67,1357.44Z" />
      <path d="M108.88,1182.8c-1,.48-2.33,1.61-3,1.31a42.65,42.65,0,0,1-5.94-3.59c.92-.71,2-2.16,2.74-2a40,40,0,0,1,6.37,2.53Z" />
      <path d="M207.45,460.43c2.06-.71,3.54-1.78,4.46-1.43,2.88,1.11,4.06.12,4.82-2.54.16-.56,1.49-.78,2.28-1.16.21.72.76,1.54.57,2.13C218.48,461,211.92,463.1,207.45,460.43Z" />
      <path d="M123.27,1274.71c-2.24,1.17-3.69,2.55-4.87,2.36s-2.72-2.26-2.54-3.19a4.31,4.31,0,0,1,3.25-2.7C120.28,1271.23,121.37,1273,123.27,1274.71Z" />
      <path d="M585,1123.77c-1.06,3.57-1.63,6-2.54,8.27a3.16,3.16,0,0,1-2.31,1.44c-.58.06-1.89-1.14-1.8-1.38a39.42,39.42,0,0,1,3-6.6C581.84,1124.75,583.22,1124.57,585,1123.77Z" />
      <path d="M118.74,1173l6.88,4.39c-.76.91-2,2.7-2.2,2.58-2.31-1.16-4.46-2.64-6.66-4Z" />
      <path d="M636.18,1316.47c-1.41-1.9-3-3.16-2.9-4.16s2.17-3,2.91-2.85a5.9,5.9,0,0,1,3.41,3.19C639.84,1313.2,637.84,1314.69,636.18,1316.47Z" />
      <path d="M57.73,1164.24c-2.28.65-4.53,1.63-6.84,1.78-.93.06-2-1.69-3-2.61,1-.92,2.12-2.73,3-2.61,2.42.35,4.72,1.48,7.07,2.3Z" />
      <path d="M330.92,1368.57l-4.67,7c-.55-.85-1.77-2-1.55-2.51a72.05,72.05,0,0,1,3.77-6.67Z" />
      <path d="M74.76,1180.62c-1,.55-2.22,1.76-2.94,1.51A38.75,38.75,0,0,1,66,1179c.91-.75,1.93-2.22,2.72-2.11a33.53,33.53,0,0,1,6.08,2Z" />
      <path d="M912.51,1365a35.11,35.11,0,0,1-5.57-4.1c-.55-.56.27-2.45.46-3.73,1.32.32,3.55.31,3.79,1A38.66,38.66,0,0,1,912.51,1365Z" />
      <path d="M294.7,1137.3l6.14,5.42c-.74.77-1.94,2.31-2.14,2.19a40.1,40.1,0,0,1-5.81-4.34C292.73,1140.43,293.81,1138.88,294.7,1137.3Z" />
      <path d="M908.16,1380.34a46,46,0,0,1-3.85-4.31,27.82,27.82,0,0,1-2.07-4.33l5.6-1.71.35,1.37-4.1,1.45a18.16,18.16,0,0,1,3.14,2.52,31.21,31.21,0,0,1,2.55,4.3Z" />
      <path d="M263.68,1355.44c-1.1,1.27-1.49,2-2.11,2.33-.37.16-1.16-.37-1.65-.74a31.08,31.08,0,0,1-3.92-3.2,10.26,10.26,0,0,1-1.39-2.79c.95-.43,2.36-1.47,2.78-1.17A83.12,83.12,0,0,1,263.68,1355.44Z" />
      <path d="M145.94,424c-2,1.46-3.12,2.74-3.69,2.55a79.71,79.71,0,0,1-7.38-3.32c.64-.66,1.42-2,1.89-1.87C139.45,421.89,142.07,422.8,145.94,424Z" />
      <path d="M180.63,718.43c-1.07.61-2.12,1.64-3.21,1.68s-1.95-1-2.93-1.63c.73-.72,1.35-1.76,2.23-2.08,1.1-.41,2.43-.19,3.64-.31a32.07,32.07,0,0,1,3.87-.44c.18,0,.73,1.88.47,2.08a20.81,20.81,0,0,1-3.46,1.85C181,719.2,180.83,718.82,180.63,718.43Z" />
      <path d="M147.57,448.19l-4.94-3.81c1.37-.53,2.8-1.62,4.08-1.46,1.82.22,3.53,1.37,5.29,2.12Z" />
      <path d="M812.12,773.37c-2,1-3.21,2.1-3.77,1.85a45.62,45.62,0,0,1-5.48-3.44c.78-.72,1.85-2.18,2.31-2A62.83,62.83,0,0,1,812.12,773.37Z" />
      <path d="M162.75,539,158,535.14c1.29-.71,2.74-2.16,3.82-1.93,1.59.34,2.93,1.9,4.37,2.93Z" />
      <path d="M381,822.31l4.92,2.59a44.51,44.51,0,0,1-6.64,2.43c-.61.13-1.61-1.58-2.43-2.45Z" />
      <path d="M454.12,1080.78l-4.09-3.15c1.32-.77,2.64-2.15,3.94-2.13s2.62,1.42,3.93,2.22Z" />
      <path d="M267.26,1310.43l5.72,5.08a22.73,22.73,0,0,1-2.51,2.34,46.16,46.16,0,0,1-5.16-5A20.5,20.5,0,0,1,267.26,1310.43Z" />
      <path d="M812.81,994.89c.17,1.13.81,2.47.43,3.36a39.31,39.31,0,0,1-3.3,5.37c-.5-.87-1.59-1.92-1.39-2.57a55.37,55.37,0,0,1,2.76-6.4Z" />
      <path d="M427.93,1118.18c-1.06,1.79-7.84,3.54-9.27,1.94-.52-.58-.79-2.23-.43-2.55a4,4,0,0,1,2.94-.86C423.36,1117,425.5,1117.62,427.93,1118.18Z" />
      <path d="M728.6,454.84c-2.71,1.4-4,2.58-5.25,2.57-1.05,0-2.08-1.43-3.11-2.23,1-1,1.85-2.68,2.9-2.79C724.4,452.26,725.79,453.49,728.6,454.84Z" />
      <path d="M390.93,1254.06c-3.05.5-6.09,1.09-9.16,1.43-.45,0-1.4-1.07-1.46-1.71s.68-1.89,1.12-1.92c3.18-.17,6.37-.09,9.56-.09Z" />
      <path d="M175.36,440c0-2.83-.22-4.36.09-5.79.18-.86,1.18-1.54,1.81-2.3a8,8,0,0,1,1.52,2.52,16.62,16.62,0,0,1-.11,4.22,10.68,10.68,0,0,1-.68,3.07,2.05,2.05,0,0,1-1.75,0A5.91,5.91,0,0,1,175.36,440Z" />
      <path d="M856.44,1216.87l4.27,3.66c-1.37.67-2.74,1.85-4.1,1.85s-2.58-1.26-3.86-2Z" />
      <path d="M985,1120.86l-3.1-2.94c1.61-1.12,3.11-2.52,4.89-3.22.69-.27,2,1,3,1.64Z" />
      <path d="M963,418.8c-.14,1.69-.15,4-.34,4-2.91.23-5.84.16-8.76.16-.09-.67-.19-1.34-.29-2Z" />
      <path d="M100.1,1214.09a57,57,0,0,1,5.75-2,3,3,0,0,1,2.54.72,2.75,2.75,0,0,1,0,2.61C107.06,1217.3,101.71,1216.41,100.1,1214.09Z" />
      <path d="M108.59,1173.26a56.72,56.72,0,0,1-6.9,0c-.4,0-.6-1.87-.9-2.87h7.63Z" />
      <path d="M254.29,1309c3.58-3.65,6-2.4,8-.69.55.46.19,2,.25,3.09Z" />
      <path d="M314,726.11l-4.31-2.92c1-.58,2.05-1.71,3-1.61a29.69,29.69,0,0,1,5.63,1.67Z" />
      <path d="M676.76,612.76l-4.42-3.45c1-.49,2.23-1.55,3.11-1.34,1.64.4,3.1,1.53,4.64,2.36Z" />
      <path d="M983.74,1132.34l-3.32-3.36c1.12-.68,2.32-2,3.34-1.91,1.52.2,2.91,1.43,4.36,2.22Z" />
      <path d="M987.54,451a11.58,11.58,0,0,1,.75,3.33,4.12,4.12,0,0,1-1.43,2.39,1.85,1.85,0,0,1-1.79-.31,3.75,3.75,0,0,1-.89-2.58c.61-2.23,1.58-4.35,2.41-6.52l2.12,3Z" />
      <path d="M242.51,437.39c-1.23,1.48-2.13,2.59-3,3.7-.56-1.41-1.62-2.85-1.55-4.22s1.34-2.54,2.08-3.81C240.73,434.33,241.47,435.6,242.51,437.39Z" />
      <path d="M766,1389.51c-.91-2.86-1.83-5.71-3.1-9.66l5.94,3.39c-.62,2.17-1.19,4.17-1.75,6.16Z" />
      <path d="M954.71,577.34c0-2.5-.07-5,.06-7.49,0-.44.94-1.25,1.33-1.18.71.11,1.91.81,1.88,1.17-.23,2.58-.72,5.14-1.12,7.7Z" />
      <path d="M406,911.85c-3.31,2.85-6.71,5.42-10.08-.57Z" />
      <path d="M372.86,1110.92c-3.1,1.94-4.7,3.06-6.41,3.94a2.64,2.64,0,0,1-2.25,0,3.4,3.4,0,0,1-.88-2,27.67,27.67,0,0,1,5.49-2.88C369.65,1109.68,370.85,1110.41,372.86,1110.92Z" />
      <path d="M743.7,985c2-.76,3.5-1.73,5-1.73.61,0,1.86,2.24,1.67,3.23-.26,1.3-1.65,2.38-2.56,3.56C747,989.09,746.25,988.12,743.7,985Z" />
      <path d="M217.42,494.87c0,2.54.06,5.08-.08,7.61,0,.28-1.32.88-1.64.69-.77-.46-1.89-1.34-1.85-2a50.29,50.29,0,0,1,1.17-6.26Z" />
      <path d="M481.46,823c1.45,1.87,2.77,2.87,2.91,4,.1.79-1.56,2.58-2.25,2.49-1.19-.15-2.89-1.26-3.14-2.27S480.21,825,481.46,823Z" />
      <path d="M216.43,1380.29c2.2.94,4.47,1.77,6.56,2.92.4.22.44,2,0,2.55a2.62,2.62,0,0,1-2.54.45,50.15,50.15,0,0,1-4.84-4Z" />
      <path d="M116.58,423.4l-4-6,.35-.95,6.8,3.6Z" />
      <path d="M783.33,617.36c2.23-.56,4.46-1.15,6.72-1.58a10.16,10.16,0,0,1,.68,2c-2.17.88-4.39,1.66-6.61,2.44Z" />
      <path d="M942.21,453.48a26.72,26.72,0,0,1,.11-5.3c.17-.88,1.4-1.54,2.16-2.3.57.86,1.76,1.85,1.61,2.54a39.88,39.88,0,0,1-2.2,5.82Z" />
      <path d="M765.27,735.81c-1,.79-2,1.72-2.16,1.6-2.05-1.53-4-3.21-6-4.85.54-.57,1.46-1.7,1.57-1.63C760.84,732.38,762.86,734,765.27,735.81Z" />
      <path d="M346.94,453.51,343.35,451a25.48,25.48,0,0,1,5.17-2.12c.74-.14,1.76,1.21,2.65,1.88Z" />
      <path d="M720.18,598.42l3.24,7.07c-.89.22-2.38.86-2.59.57C719.44,604.15,716.38,602.42,720.18,598.42Z" />
      <path d="M959.18,964.23l2.89,3.61c-1.15.71-2.31,2-3.44,2s-2.27-1.34-3.4-2.09Z" />
      <path d="M881.6,659.19a14.42,14.42,0,0,1-.28-2.75c.15-1.64.5-3.27.76-4.9.74.46,2.13.94,2.11,1.37a64.33,64.33,0,0,1-.83,6.6Z" />
      <path d="M615.7,632.75l3.82,3,.06,1.43c-1.58.26-3.72,1.26-4.6.59s-.78-3-1.1-4.59Z" />
      <path d="M519.28,537.52h-5.77c0-1,.06-2.09.08-3.13l5.84.44Z" />
      <path d="M378.71,540.31l-5.64,3.81c.19-1.27,0-2.86.66-3.74.87-1.13,2.47-1.69,3.76-2.49C377.9,538.69,378.3,539.5,378.71,540.31Z" />
      <path d="M440.44,822.05c-2.2-.72-4.42-1.41-6.58-2.27a25,25,0,0,1,.76-2.58l6.77,2Z" />
      <path d="M730,458.58c.48-2.14.87-3.86,1.62-7.16,1.74,2.16,2.88,3.57,4.52,5.62Z" />
      <path d="M125.24,462.49l-3.59,3.26c-.77-.92-2.28-2-2.14-2.74a4.77,4.77,0,0,1,2.52-2.89C122.49,459.89,123.76,461.35,125.24,462.49Z" />
      <path d="M445,809.58c-2.26,1-3.67,2.06-4.32,1.72-1.25-.66-2.07-2.13-3.07-3.27.87-.59,1.94-1.83,2.56-1.64C441.51,806.83,442.65,808,445,809.58Z" />
      <path d="M588.71,963.48c1.38,1.9,2.9,3.09,2.77,4s-1.75,2.31-2.91,2.56c-.66.15-2.44-1.55-2.43-2.4C586.15,966.55,587.42,965.44,588.71,963.48Z" />
      <path d="M876.21,884.72c-1.76,1.26-3,2.73-3.69,2.51-1.13-.36-1.91-1.83-2.83-2.83,1-.8,1.92-2.12,3-2.22C873.53,882.09,874.54,883.45,876.21,884.72Z" />
      <path d="M904.23,755c-1.09-2.11-2.18-3.23-2-4.19s1.47-1.78,2.29-2.66c.73,1,2,1.85,2.06,2.84S905.46,753,904.23,755Z" />
      <path d="M748.34,570.21c-1.34-1.9-2.6-2.9-2.53-3.8s1.35-1.92,2.1-2.87c.86.82,2.26,1.55,2.44,2.5S749.42,568.11,748.34,570.21Z" />
      <path d="M868.78,884.76c-1.6,1.32-2.77,2.89-3.43,2.7a4.52,4.52,0,0,1-2.75-2.77c-.18-.71,1.49-2.69,2.22-2.63C866,882.15,867,883.47,868.78,884.76Z" />
      <path d="M92.23,1179.8a37.16,37.16,0,0,1-4.66-4c-.42-.5.31-2,.51-3,1,.28,2.47.25,2.87.9a30.92,30.92,0,0,1,2.14,5.27Z" />
      <path d="M246.53,1015.39c-1.27-1.72-2.75-2.95-2.53-3.59a4.56,4.56,0,0,1,2.87-2.61c.66-.15,2.49,1.59,2.43,2.37C249.2,1012.67,247.86,1013.67,246.53,1015.39Z" />
      <path d="M419.15,468a17.39,17.39,0,0,1-1.41-4.29c-.05-.81,1-1.7,1.6-2.56a18.26,18.26,0,0,1,2.2,4c.21.77-.7,1.85-1.11,2.79Z" />
      <path d="M267.91,1029.42c-1.09,1-2,2-2.15,1.91a57.18,57.18,0,0,1-5.18-4.26,9.93,9.93,0,0,1,1.34-1.63C263.84,1026.59,265.66,1027.89,267.91,1029.42Z" />
      <path d="M462.8,1317.09c2.32-.36,4.64-.79,7-1,.31,0,1.12,1.11,1,1.44-.26.66-1,1.54-1.55,1.6a58.79,58.79,0,0,1-5.94.07Z" />
      <path d="M834.09,883.41c-1.78,1-2.93,2.25-3.83,2.09a3.4,3.4,0,0,1-2-2.67,3.34,3.34,0,0,1,2.45-2.24C831.61,880.63,832.5,882,834.09,883.41Z" />
      <path d="M13.77,777.23c2.37.14,4.75.19,7.09.52.44.06,1.06,1.33.95,1.91a2.12,2.12,0,0,1-1.8,1c-2.21-.44-4.36-1.15-6.54-1.77Z" />
      <path d="M739.5,453c2.1,1.05,5.91-.4,6.09,5.21l-7.19-3.42C738.76,454.19,739.13,453.6,739.5,453Z" />
      <path d="M878.94,760.07l-3.05,2.43c-.83-1.06-2-2-2.35-3.22-.16-.55,1.24-1.57,1.94-2.38C876.48,757.83,877.49,758.75,878.94,760.07Z" />
      <path d="M955.31,592.07a30.13,30.13,0,0,1-3.37-3.91c-.13-.19,1.4-1.42,2.16-2.18a29.55,29.55,0,0,1,3.15,3.49C957.41,589.71,956.25,590.85,955.31,592.07Z" />
      <path d="M563.74,1406.59c-1.73,1-2.87,2.19-3.85,2.07-.82-.09-1.46-1.71-2.19-2.66,1-.62,2-1.68,3-1.74C561.46,1404.21,562.31,1405.44,563.74,1406.59Z" />
      <path d="M344.55,584.35l6.36,1.53c-.33.84-.76,2.42-1,2.39-2-.29-4-.85-6.05-1.34C344.12,586.07,344.34,585.21,344.55,584.35Z" />
    </g>
  );
};

export default bgDrawingCitylights;
