import seedrandom from "seedrandom";
import { backgroundArray } from "../Assets/backgrounds/index";
import {
  drawingFill2Array,
  drawingFillArray,
  drawingLineartArray,
} from "../Assets/drawings/index";
import { glitchArray } from "../Assets/png/index";
import { focusfillArray } from "../Assets/svg/index-focusfill";
import { totalXPoints, totalYPoints } from "./ArtworkContainer";
import { useStore } from "./ArtworkPropertyStore";
import { fontsArray } from "./Resources/Fonts";
import { paletteArray } from "./Resources/Palettes";
import { textArray } from "./Resources/Texts";
import { FocusGridType } from "./Resources/Types";

/* eslint-disable react-hooks/rules-of-hooks */
export const getProps = () => {
  const width = useStore((state) => state.width);
  const height = useStore((state) => state.height);

  const theme = useStore((state) => state.theme);
  const elementstyle = useStore((state) => state.elementstyle);
  const palette = useStore((state) => state.paletteCode);

  const position1 = parseInt(useStore((state) => state.position1Code));
  const position2 = parseInt(useStore((state) => state.position2Code));

  const isAnimating = useStore((state) => state.isAnimating);
  const animationLayer1 = useStore((state) => state.animationLayer1);
  const animationLayer2 = useStore((state) => state.animationLayer2);

  const totalXPoints = useStore((state) => state.totalXPoints);
  const totalYPoints = useStore((state) => state.totalYPoints);

  const focusImg = useStore((state) => state.focusImg);
  const focusGrid = useStore((state) => state.focusGrid);
  const isFocusGridDrawn = useStore((state) => state.isFocusGridDrawn);
  const facePosition = useStore((state) => state.facePosition);

  return {
    width: width,
    height: height,

    theme: theme,
    elementstyle: elementstyle,
    palette: palette,

    position1: position1,
    position2: position2,

    isAnimating: isAnimating,
    animationLayer1: animationLayer1,
    animationLayer2: animationLayer2,

    totalXPoints: totalXPoints,
    totalYPoints: totalYPoints,
    facePosition: facePosition,

    focusImg: focusImg,
    focusGrid: focusGrid,
    isFocusGridDrawn: isFocusGridDrawn,
  };
};

export const useTextProps = () => {
  const textBoxPosition = useStore((state) => state.textBoxPosition);
  const textCode = useStore((state) => state.textCode);
  const textFont = useStore((state) => state.textFont);
  const textFontWeight = useStore((state) => state.textFontWeight);
  const textLetterSpacing = useStore((state) => state.textLetterSpacing);

  return {
    textBoxPosition: textBoxPosition,
    textCode: textCode,
    textFont: textFont,
    textFontWeight: textFontWeight,
    textLetterSpacing: textLetterSpacing,
  };
};

export const selectFocusDrawing = (drawingCode: string) => {
  const totalItems = focusfillArray.length;

  const code = parseInt(drawingCode);

  for (let i = 0; i < totalItems; i++) {
    if (
      code > Math.floor(100 / totalItems) * i &&
      code <= Math.floor(100 / totalItems) * (i + 1)
    ) {
      return focusfillArray[i];
    }
  }
  return focusfillArray[0];
};

export const selectDrawing = (
  drawingCode: string,
  drawingElement: "fill" | "fill2" | "lineart"
) => {
  const totalItems = drawingFillArray.length;
  const fillArray = drawingFillArray;
  const fill2Array = drawingFill2Array;
  const lineartArray = drawingLineartArray;

  const code = parseInt(drawingCode);

  for (let i = 0; i < totalItems; i++) {
    if (
      code > Math.floor(100 / totalItems) * i &&
      code <= Math.floor(100 / totalItems) * (i + 1)
    ) {
      if (drawingElement === "fill") {
        return fillArray[i];
      } else {
        if (drawingElement === "lineart") {
          return lineartArray[i];
        } else {
          if (drawingElement === "fill2") {
            return fill2Array[i];
          }
        }
      }
    }
  }
  return drawingFillArray[0];
};

export const selectBackgroundBase = (bgCode: string) => {
  const totalItems = backgroundArray.length;
  const bgBase = backgroundArray;

  const code = parseInt(bgCode);

  for (let i = 0; i < totalItems; i++) {
    if (
      code > Math.floor(100 / totalItems) * i &&
      code <= Math.floor(100 / totalItems) * (i + 1)
    ) {
      return bgBase[i];
    }
  }
  return bgBase[0];
};

export const selectBackgroundImage = (imageCode: string) => {
  const totalItems = glitchArray.length;
  const bgImage = glitchArray;

  const code = parseInt(imageCode);

  for (let i = 0; i < totalItems; i++) {
    if (
      code > Math.floor(100 / totalItems) * i &&
      code <= Math.floor(100 / totalItems) * (i + 1)
    ) {
      // console.log(i);
      return bgImage[i];
    }
  }
  return bgImage[0];
};

export const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);

export const generateValue = (
  digits: number,
  seed: number | string,
  source?: string,
  nozero?: "nozero"
) => {
  // console.log("generate value called from: ", source);
  const nozeroParam = nozero ? nozero : false;

  const generateRandomNumber = seedrandom(seed.toString());
  let result = generateRandomNumber();
  result *= 10 ** digits;

  let resultString = "";
  if (nozeroParam) {
    resultString = result.toString().replace(".", "").slice(0, digits);
  } else {
    resultString = result.toString().slice(0, digits).replace(".", "");
  }
  // console.log("result string: ", resultString, "for digits: ", digits);
  return resultString;
};

const Skip32 = require("skip32").Skip32;

export const generateValueSkip32 = (
  i: number,
  digits: number,
  cipher: "cipher1" | "cipher2" | "cipher3",
  seed: number,
  zero?: "nozero"
) => {
  const zeroAllowed = zero ? (zero === "nozero" ? false : true) : true;

  var cipher1 = new Skip32([
    `0x${seed.toString(16)}`,
    0xb1,
    0x96,
    0xfe,
    0x1a,
    0xcf,
    0x24,
    0x5f,
    0xe4,
    0x93,
  ]);

  var cipher2 = new Skip32([
    0x2c,
    0x94,
    0xd3,
    0xfe,
    `0x${seed.toString(16)}`,
    0xe2,
    0x24,
    0x76,
    0xf4,
    0x39,
  ]);

  var cipher3 = new Skip32([
    0x7a,
    0x94,
    0x86,
    0xfe,
    0xfa,
    0xd2,
    0x33,
    0x76,
    0x5f,
    `0x${seed.toString(16)}`,
  ]);

  let result = 0;
  let resultString = "";

  switch (cipher) {
    default:
      result = cipher1.encrypt(i);
      break;
    case "cipher1":
      result = cipher1.encrypt(i);
      break;
    case "cipher2":
      result = cipher2.encrypt(i);
      break;
    case "cipher3":
      result = cipher3.encrypt(i);
      break;
  }

  resultString =
    i % 9 === 0
      ? ("0" + result.toString()).slice(0, digits)
      : result.toString().slice(0, digits);

  if (resultString[0] === "0") {
    resultString = resultString.slice(1);
    resultString = resultString + result.toString().slice(digits, digits + 1);
  }
  return resultString;
};

export const selectPalette = (paletteCode: number) => {
  const totalItems = paletteArray.length;
  const code = paletteCode;

  for (let i = 0; i < totalItems; i++) {
    if (
      code > Math.floor(100 / totalItems) * i &&
      code <= Math.floor(100 / totalItems) * (i + 1)
    ) {
      return paletteArray[i];
    }
  }
  return paletteArray[0];
};

export const selectFont = (fontCode: number) => {
  const totalItems = fontsArray.length;
  const code = fontCode;

  for (let i = 0; i < totalItems; i++) {
    if (
      code > Math.floor(100 / totalItems) * i &&
      code <= Math.floor(100 / totalItems) * (i + 1)
    ) {
      return fontsArray[i];
    }
  }
  return fontsArray[0];
};

export const colorToHex = (color: string) => {
  switch (color) {
    default: {
      // return "#ea5966";
      return "#ff00ff";
    }
    case "red":
      return "#ea5966";
    case "red-dark":
      return "#a1304a";
    case "red-dark2":
      return "#64233b";

    case "pink":
      return "#fb6ba3";
    case "pink-dark":
      return "#a14481";
    case "pink-dark2":
      return "#5c2e58";

    case "blue":
      return "#74a7a1";
    case "blue-dark":
      return "#47707a";
    case "blue-dark2":
      return "#354551";

    case "green":
      return "#86bb8e";
    case "green-dark":
      return "#467564";
    case "green-dark2":
      return "#344a3b";

    case "orange":
      return "#f68c63";
    case "orange-dark":
      return "#d54e28";
    case "orange-dark2":
      return "#6f2a18";

    case "purple":
      return "#88799a";
    case "purple-dark":
      return "#5d5b8a";
    case "purple-dark2":
      return "#3f3852";

    case "cream":
      return "#efdbc0";
    case "cream-dark":
      return "#f3c78f";
    case "cream-dark2":
      return "#906045";

    case "white":
      return "#ffffff";
    case "brown":
      return "#c08984";
    case "black":
      return "#1d191b";

    case "gray":
      return "#8f7c79";
    case "gray-dark":
      return "#574444";
    case "gray-dark2":
      return "#322b2d";
  }
};

export const filterFocusGridValue = (
  focusGrid: FocusGridType,
  keyToCheck: "value" | "x" | "y",
  filterValue: number,
  operation?: "equals" | "greaterThan" | "lessThan"
) => {
  const result: FocusGridType = [];
  const op = operation ? operation : "equals";

  // console.log("focus grid in filterFocusGrid: ", focusGrid);
  switch (op) {
    default: {
      for (let j = 1; j < focusGrid.length; j++) {
        const filteredArray = focusGrid[j].filter(
          (point) => point[keyToCheck] === filterValue
        );
        if (filteredArray.length >= 1) {
          result.push(filteredArray);
        }
      }
      break;
    }
    case "equals": {
      for (let j = 1; j < focusGrid.length; j++) {
        const filteredArray = focusGrid[j].filter(
          (point) => point[keyToCheck] === filterValue
        );
        if (filteredArray.length >= 1) {
          result.push(filteredArray);
        }
      }
      break;
    }
    case "lessThan": {
      for (let j = 1; j < focusGrid.length; j++) {
        const filteredArray = focusGrid[j].filter(
          (point) => point[keyToCheck] < filterValue
        );
        if (filteredArray.length >= 1) {
          result.push(filteredArray);
        }
      }
      break;
    }
    case "greaterThan": {
      for (let j = 1; j < focusGrid.length; j++) {
        const filteredArray = focusGrid[j].filter(
          (point) => point[keyToCheck] > filterValue
        );
        if (filteredArray.length >= 1) {
          result.push(filteredArray);
        }
      }
      break;
    }
  }

  return result;
};

export const filterFocusGridIsUsed = (
  focusGrid: FocusGridType,
  filterValue: boolean
) => {
  const result = [];

  for (let j = 1; j < focusGrid.length; j++) {
    if (
      focusGrid[j].filter((point) => point.isUsed === filterValue).length >= 1
    ) {
      result.push(focusGrid[j].filter((point) => point.isUsed === filterValue));
    }
  }

  return result;
};

export const pickGridPoint = (
  focusGrid: FocusGridType,
  p1: number,
  p2: number
) => {
  const xlength = focusGrid.length;
  const xpart = xlength / 100;
  let index1 = Math.floor(p1 * xpart);
  index1 = index1 >= 1 ? index1 : 1;
  const xArray = focusGrid[index1];

  const ylength = xArray.length;
  const ypart = ylength / 100;
  let index2 = Math.floor(p2 * ypart);
  index2 = index2 >= 1 ? index2 : 1;
  const point = xArray[index2];

  return point;
};

export const pickGridPointInFilteredGrid = (
  focusGrid: FocusGridType,
  p1: number
) => {
  let clampedindex = clamp(p1, 1, 100);
  const filteredGrid = [];

  // console.log("filtered grid looks like: ", focusGrid);
  for (let i = 0; i < focusGrid.length; i++) {
    for (let j = 0; j < focusGrid[i].length; j++) {
      filteredGrid.push(focusGrid[i][j]);
    }
  }

  const xlength = filteredGrid.length;
  const xpart = xlength / 100;
  let index1 = Math.floor(clampedindex * xpart);
  const point = filteredGrid[index1];

  return point;
};

export const getFacePosition = (focusGrid: FocusGridType) => {
  let fp = "right";

  for (let i = 1; i < totalXPoints / 2; i++) {
    for (let j = 1; j < totalYPoints; j++) {
      if (focusGrid[i][j]?.value && focusGrid[i][j].value >= 128) {
        fp = "left";
      }
    }
  }
  return fp;
};

export const getTextBoxPosition = (
  pos1: number,
  pos2: number,
  focusGrid: FocusGridType
) => {
  const rotation = pos1 <= 25 ? 90 : pos1 > 25 && pos1 < 75 ? 0 : -90;
  let facePosition = "right";
  facePosition = getFacePosition(focusGrid);

  // let posTop = 0;
  let textBoxPosition = "top";

  switch (rotation) {
    default: {
      pos2 <= 50 ? (textBoxPosition = "top") : (textBoxPosition = "bottom");
      break;
    }
    case 0: {
      pos2 <= 50 ? (textBoxPosition = "top") : (textBoxPosition = "bottom");
      break;
    }

    case 90: {
      facePosition === "left"
        ? (textBoxPosition = "right")
        : (textBoxPosition = "left");
      // posTop = pos2;
      break;
    }

    case -90: {
      facePosition === "left"
        ? (textBoxPosition = "right")
        : (textBoxPosition = "left");
      break;
    }
  }
  return textBoxPosition;
};

export const markTextBoxSpaceUsed = (
  textBoxPosition: string,
  totalXPoints: number,
  totalYPoints: number,
  focusGrid: FocusGridType
) => {
  const localFocusGrid = focusGrid;

  switch (textBoxPosition) {
    case "bottom": {
      for (let i = 1; i < totalXPoints; i++) {
        for (let j = Math.floor(totalYPoints * 0.8); j < totalYPoints; j++) {
          localFocusGrid[i][j].isUsed = true;
        }
      }
      useStore.setState({ focusGrid: localFocusGrid });
      break;
    }

    case "top": {
      for (let i = 1; i < totalXPoints; i++) {
        for (let j = 1; j < Math.floor(totalYPoints * 0.2); j++) {
          localFocusGrid[i][j].isUsed = true;
        }
      }
      useStore.setState({ focusGrid: localFocusGrid });
      break;
    }

    case "left": {
      for (let i = 1; i < totalXPoints * 0.35; i++) {
        for (let j = 1; j < totalYPoints; j++) {
          localFocusGrid[i][j].isUsed = true;
        }
      }
      useStore.setState({ focusGrid: localFocusGrid });
      break;
    }

    case "right": {
      for (let i = Math.floor(totalXPoints * 0.8); i < totalXPoints; i++) {
        for (let j = 1; j < totalYPoints; j++) {
          localFocusGrid[i][j].isUsed = true;
        }
      }
      useStore.setState({ focusGrid: localFocusGrid });
      break;
    }
  }
  return localFocusGrid;
};

export const markPointsAroundAsUsed = (
  focusGrid: FocusGridType,
  keyX: number,
  keyY: number,
  pointRange?: number
) => {
  const range = pointRange ? pointRange : 1;
  if (keyX === undefined || keyY === undefined) {
    return {};
  }

  if (focusGrid) {
    for (let i = keyX - range; i <= keyX + range; i++) {
      for (let j = keyY - range; j <= keyY + range; j++) {
        if (i < totalXPoints && j < totalYPoints && i > 0 && j > 0) {
          if (focusGrid[i][j]) focusGrid[i][j].isUsed = true;
        }
      }
    }
  }
  // return focusGrid;
  useStore.setState({ focusGrid: focusGrid });
};

export const selectFromArray = (itemCode: string, targetArray: Array<any>) => {
  const totalItems = targetArray.length;

  const code = parseInt(itemCode);

  for (let i = 0; i < totalItems; i++) {
    if (
      code > Math.floor(100 / totalItems) * i &&
      code <= Math.floor(100 / totalItems) * (i + 1)
    ) {
      return targetArray[i];
    }
  }
  return targetArray[0];
};

export const getDateTitle = () => {
  const fulldate = new Date();
  const date = fulldate.getDate();
  const formattedDate = ("0" + date).slice(-2);
  const month = fulldate.getMonth() + 1;
  const formattedMonth = ("0" + month).slice(-2);
  const hour = fulldate.getHours();
  const minute = fulldate.getMinutes();
  const seconds = fulldate.getSeconds();

  return `${formattedDate}${formattedMonth}-${hour}${minute}${seconds}`;
};

export const selectText = (textCode: number, theme: string) => {
  const objectMatchingTheme = textArray.filter((obj) => {
    return obj.theme === theme;
  });

  const textsMatchingTheme = objectMatchingTheme[0].texts;
  const numberOfTexts = textsMatchingTheme.length;

  for (let i = 0; i < numberOfTexts; i++) {
    if (
      textCode > Math.floor(100 / numberOfTexts) * i &&
      textCode <= Math.floor(100 / numberOfTexts) * (i + 1)
    ) {
      return textsMatchingTheme[i];
    }
  }

  return textsMatchingTheme[0];
};

export const animationSeed: Array<number> = [];
for (let k = 0; k < 50; k++) {
  animationSeed.push(parseInt(generateValue(2, k, "animation seed")));
}
