const backgroundMosaic = () => {
  return (
    <g>
      <path d="M1025.72,1413.79c-.16-18.35-.48-36.7-.47-55q0-236.45.14-472.89,0-176.25-.13-352.5,0-177.81,0-355.64c0-29.33,0-58.67-1.1-88v37.92c-8.6,3.84-16.48,6.41-23.28,10.69-5.4,3.4-8.17,2.68-12.43-1.59C958.4,106.51,928,76.6,897.8,46.45c-1.74-1.74-2.3-4.68-3.41-7.06l38-38c5.38-1.34,10.75-3.74,16.16-3.84,22-.37,44,0,66.06,0,10.21,0,10.21,0,10.07,10.55a49.76,49.76,0,0,0,.29,7V-3.94h-8.47q-245,0-490,0-368.13,0-736.25,0-86.13,0-172.26,0c-2.88,0-5.76-.17-8.64-.27.27-.52.5-1.48.8-1.5,2.09-.13,4.19-.07,6.29-.07H1027.29q0,706.25-.06,1412.48C1027.23,1409.07,1026.25,1411.43,1025.72,1413.79Z" />
      <path d="M827.52,353.24,847,352c3.49-18.92,6.56-37.14,10.34-55.21,1.27-6.08,4.16-11.85,6.58-17.65,4.49-10.79,10.48-19.08,22.46-24.19,21.81-9.3,37-26.4,45.22-48.84,2.5-6.81,5.29-7.33,10.62-3.33a50.87,50.87,0,0,0,14.41,7.91l-17.79-13.08c27.08-15,54.41-23.32,84.47-21.7,5.68,73.1-61.74,170.9-168.42,174.85,13.5,2.23,25,3.22,36.06,6.11,53,13.9,92,45.1,115.2,95.08.9,1.94.31,5.88-1.15,7.4-6.32,6.61-13.36,12.52-19.86,19-3.35,3.32-6,3.36-9.25,0-4-4.11-8.36-7.92-12.52-11.84-9.85,5.86-18.71,20.67-26.38,44.53-1.27-3.25-2.32-5.69-3.19-8.2-13.06-37.67-39.44-58.9-79-63.4-5.77-.65-6-3.67-6-7.87,0-16.77-.12-33.55.09-50.33.06-5.32-.73-8.42-7.08-9.65C833.13,369.86,828.45,363.11,827.52,353.24Z" />
      <path d="M260.69,231.15l6.84-3.15,24.64,31.86c-.68,2.41-.81,5.17-2.12,7.17-32.5,50-78,78.57-137.91,83.1-1.31.1-2.61.22-3.91.37-.19,0-.35.25-1.71,1.27,53.21,2.66,96.32,24,130,63.87,16.69,19.72,27.15,42.59,33.65,66.83-7.59,4.08-14.3,8-21.35,11.23-1.59.72-4.63-.69-6.51-1.88-4.54-2.9-8.66-6.44-13.18-9.36-6.75-4.38.47-7.2,1.1-10.6l-42.77,33.53c-14.62-41.53-42.85-63.33-85.72-67.14-.13-2.75-.37-5.53-.37-8.31.07-29.06,0-58.11.33-87.16.27-24.56,7.13-47.53,17.51-69.63,2.64-5.61,6-6.76,11.12-3.95,10.73,5.86,21.43,11.77,33.16,18.23l4.48-3.5Z" />
      <path d="M495.15,352.2c100.28-2.26,178.48,86.52,174.69,175.31H583.59c-1.34-23.76-9.4-44.84-26.46-61.87-16.89-16.86-37.49-25.39-62-26.47Z" />
      <path d="M495.55,1145.75c-.17-3.24-.39-5.57-.41-7.9q-.22-34.21-.38-68.43c0-10.22.06-10.65,10.57-9.78,70.81,5.86,121.24,41.12,150.81,105.71,10.05,21.93,15.32,50.81,13.6,68.69H583.85c-1.46-23.51-9.64-44.3-26.45-61.17S519.84,1147.59,495.55,1145.75Z" />
      <path d="M739.89,760.87q7.3-12,14.61-24c1.45-2.38,2.45-5.62,4.59-6.9,25.68-15.44,53.59-23.25,85.74-24-2.93,3.35-4.56,5.45-6.42,7.31q-32.14,32.26-64.31,64.52c-3.68,3.7-6.84,7.91-10.21,11.86Q749.5,804.83,735.05,820l-58.59,57.3c-3.88,1.19-7.75,3.37-11.64,3.41-25.85.3-51.71.15-77.56.12a31.64,31.64,0,0,1-4.13-.72c3.35-8.09-6.25-17,3-24.51,8.27-5.79,16.6-11.52,24.81-17.41,9.11-6.53,9.08-7.12,1.15-15.15q-26.92-27.3-53.81-54.65c.07-1.11.15-2.22.22-3.33l18.33-27.87c1.33-2.75,2.65-5.51,4.6-9.54,54.9,34,85,81.81,90.21,145.72.84-6.75.78-13.76,2.67-20.2q7.72-26.3,16.94-52.13c2.47-6.94,7-12.57,15.21-14.5,3.61-.85,6.73-3.83,10.07-5.84Z" />
      <path d="M495.34,532.16c-1.94,13.2-2.46,26.8-6.17,39.48-4.36,14.9-11.27,29.08-17.61,43.34-1.18,2.66-4.57,5-7.48,6.15-25.58,10.09-43.33,27.79-53.46,53.37a16,16,0,0,1-6.25,7.27,153.91,153.91,0,0,1-86.54,22.39c.93-10,1.3-19.37,2.77-28.56,3.33-20.87,9.94-40.68,21.4-58.59a16.93,16.93,0,0,1,7.18-6.36c24.07-9.91,40.66-26.9,51.06-50.74,2.14-4.88,7.62-9.06,12.54-11.84,23.54-13.32,49.47-18.22,76.12-19.77,2-.12,4.15,2.64,6.23,4.07Z" />
      <path d="M589.67,94.08,559.52,60.9l23-39.53c53.52,33.07,84.29,80.72,88.83,145,3.86-58.16,30.2-103.8,77.39-137.39C777.54,8.49,810-1.42,846.86-2.11c-1.74,2.15-2.41,3.14-3.25,4q-82.93,82.8-166,165.49c-2.3,2.28-6.12,3-9.24,4.49l-16.9-16.39-33.14-32.23Z" />
      <path d="M146.14,1065.19c-4.47-4.58-3.41-8.75.76-12.9q58.82-58.5,117.55-117.08,22-21.91,43.73-44c2-2,3.46-4.63,5.18-7l2.22,1.24c0,5.47.52,11-.1,16.4-2.74,24-9.7,46.64-22.43,67.27a18.27,18.27,0,0,1-7.88,6.81c-23.6,10.08-40.4,26.67-50.15,50.5a17.32,17.32,0,0,1-6.23,7.53c-21.83,14.2-46.23,20.58-71.88,22.89-2.61.23-5.23.33-7.84.55-.49,0-1,.37-3.55,1.44,66.34,3.42,114,34.29,148.47,89.49-5.58,2.59-10.17,4.29-14.33,6.74a160.23,160.23,0,0,0-19,12.43c-4.48,3.57-7.28,3.35-11.25-.62q-39.12-39.26-78.61-78.14c-7.62-7.53-15.57-14.72-23.36-22Z" />
      <path d="M404.48,351.75c5.44,47.41,29.65,77.16,78.07,88.64-38.21,7.78-61.74,28.95-73.95,64.8-9-6.44-17.56-12-25.48-18.42C342.75,454.15,321.7,411.6,318.42,360c-.37-5.88.63-8.33,7.44-8.21,23.31.41,46.63.07,69.95,0Z" />
      <path d="M344.64,1329.3c-3.16-5.46-1.11-8.67,4.35-10.86,24.84-10,42-27.37,52.34-52.18,2.13-5.09,8.13-9.33,13.32-12.25,22.11-12.46,46.7-15.75,71.47-18.29,7-.72,11.06,3.45,15.58,7.2l6.51,6.64-11,9-2.63-14.9c-2.21,23.63-6.26,45.45-16.05,65.86-4.6,9.59-10.16,17-20.93,21.53-21.58,9-36.73,25.42-45.84,47.13-3.88,9.24-3.86,9.26-12.91,4.77-2.08-1-4.1-2.16-6.11-3.21C373,1366.47,357.91,1348.76,344.64,1329.3Z" />
      <path d="M606.32,593.53c1.72-.18,4.16-1.19,5-.42a94.21,94.21,0,0,0,57,23.59c.47,0,.9.5,2,1.13,0,19.48,0,39.33,0,59.18,0,7.06-.12,14.13,0,21.2.09,4.32-1.68,6.13-6.06,6.07-29.79-.41-57.25-8.9-82.65-24.13-2.48-1.49-3.4-5.57-5-8.43q-9.11-13.68-18.22-27.39l.27-3.44L584,613.75Z" />
      <path d="M837.59,440.16c-38.29,6.71-62.88,28.59-75.26,66.07-7.3-4.85-14.19-9-20.61-13.77a163.87,163.87,0,0,1-44-48.71c-1.19-2-1.72-5.78-.64-7.56a175.05,175.05,0,0,1,61.52-60.67,18.46,18.46,0,0,1,3.38-1.06C774.27,411.16,799.48,432.93,837.59,440.16Z" />
      <path d="M583.45,185.49c6.46,38,29,61.8,65.93,75.13-8,10.83-14.3,21.66-22.85,30.18-12.18,12.12-25.92,22.7-39.36,33.49-2,1.59-6.73,2-8.89.75-26.33-15.44-46.5-36.91-61.75-64.1C553.79,248.76,576,223.7,583.45,185.49Z" />
      <path d="M54.53,1082.21c12.24,35,36.16,57.24,73.55,64.82-36.75,7.84-61.17,29.19-73,65.57-3.08-1.68-5.8-3-8.34-4.55A164.82,164.82,0,0,1-9.35,1151c-1.19-2-1.68-5.81-.59-7.61A170.15,170.15,0,0,1,51.52,1083,14.36,14.36,0,0,1,54.53,1082.21Z" />
      <path d="M583.07,894.84c7.81,36.4,29.37,60.29,65.28,72.63-3.14,5.18-5.72,9.9-8.74,14.33a170.88,170.88,0,0,1-52,49.55c-2.26,1.39-6.4,2.42-8.29,1.3-26.41-15.71-47.1-37.05-62.52-64.69C553.05,956,575,931.93,583.07,894.84Z" />
      <path d="M762,1082.74c12.1,34.74,35.75,56.24,74,64.44-38.25,8-62.06,29.84-73.84,65.53-27.6-15.15-48.93-35.32-64.29-61.7-1.08-1.86-1.52-5.31-.51-7a171.37,171.37,0,0,1,61.3-60.68C759.21,1083,760.09,1083.06,762,1082.74Z" />
      <path d="M55.17,505.36c-28-15.72-49.58-36.55-65.44-63.24a6.52,6.52,0,0,1,.24-5.58c15.74-26.13,37-46.63,64.25-62.34,12.44,36.55,37.13,58.64,74.94,66C91.71,447.12,67.51,468.72,55.17,505.36Z" />
      <path d="M136.41,705.6l-173,173.06c2.52-15.16,4.24-29,7.29-42.56,2-8.88,5.66-17.42,9-25.92,4.71-11.83,11.18-20.9,24.38-26.42,20.78-8.7,34.5-25.78,43.1-46.82a18.48,18.48,0,0,1,7.17-8.53C79.74,713.65,107.26,706.4,136.41,705.6Z" />
      <path d="M99.69,606.16,123.78,617c-3,.79-6.2,1.45-9.29,2.5C86.85,628.82,67.56,647,56.88,674.22c-1,2.58-2.7,4.89-4.07,7.33L9.24,646c-5.88-8.3-11.71-16.63-17.66-24.88-3.41-4.74-2.84-7.84,3-10.06,25.33-9.68,42.91-27.13,52.61-52.57,1-2.56,3.67-4.45,6.45-7.67,6.53,17.66,13.93,32.79,28,43.4Z" />
      <path d="M896.79,1372.18c-.38-2.06-1.81-5-.95-6a133.66,133.66,0,0,1,13.23-13.38c.79-.71,3.11.28,4,.39.68-2.12.73-5.27,2.33-6.88q52.73-52.85,105.78-105.37c.48-.48,1.27-.64,3.62-1.75-1.29,10.11-2,19.13-3.63,28-3.53,19.74-9.64,38.64-20.55,55.65a18.78,18.78,0,0,1-7.9,6.8c-23.62,10-40.54,26.43-49.92,50.46-3.95,10.1-19,16.13-28.19,11.47Z" />
      <path d="M403.91,1084.19c2,1,5,1.39,5.88,3,6.59,11.45,12.77,23.13,19.68,35.84-10.08,10-21.32,21.25-32.63,32.41-5,5-9.72,12.9-15.39,13.72-5.25.75-11.59-6.22-17.49-9.73-2.47-1.47-4.89-3.4-7.58-4.05-10.91-2.63-15.69-10.91-20-20.12-9.49-20.35-16-41.44-16.72-64-.07-2.27,2.05-4.62,3.12-7,13.06,13,26.37,25.93,39.17,39.3,5,5.21,8.45,5.66,14.22.78C384.89,1097,394.62,1090.84,403.91,1084.19Z" />
      <path d="M997.91,964.5c2.63,5.57.51,8.58-4.95,10.83-24.21,10-41.4,26.94-51.17,51.49-1.38,3.46-5.09,6.54-8.46,8.57-23.93,14.42-50.09,21-77.93,21-.68,0-1.36-.51-2.75-1.06a58.22,58.22,0,0,1,4-4.9q54.61-54.4,109.31-108.75c7-6.94,7.22-6.92,13.83.6,5.88,6.68,11.47,13.6,17.18,20.41Z" />
      <path d="M387.5,759.17c4.39-7.38,8.64-14.84,13.23-22.09,2-3.15,4.07-6.87,7.1-8.64,25.52-15,53.19-22.41,82.63-23Q435,761.61,380.57,816.62l-39.34-22.51c2.63-1.84,5.27-4.33,8.38-5.77,12.67-5.84,25.17-11.78,33.08-24.1Z" />
      <path d="M380.36,109.27c-8.55-5.58-16.51-10.57-24.21-15.93-2.43-1.69-6.18-4.91-5.77-6.5a14.48,14.48,0,0,1,6.49-8.24c12.88-7,24.35-15.55,32.67-27.67C394,44.5,397.1,37.2,401.26,30.56c2.25-3.58,4.81-7.64,8.29-9.66,24.93-14.44,52-21.51,80.14-22.42Z" />
      <path d="M738,116.83c.6-2.82.23-6.59,1.93-8.32q51.26-52.16,103-103.87c.51-.51,1.33-.71,3-1.58,0,5.36.5,10.2-.08,14.91-2.91,23.67-10,46-22.25,66.58-1.26,2.1-3.18,4.44-5.33,5.26-24.82,9.44-42.46,26.33-53.4,50.42-.2.44-.7.75-2.38,2.51-4.06-4.08-8.16-8.07-12.11-12.19-3.79-4-7.43-8-11.12-12.06C738.81,118,738.4,117.39,738,116.83Z" />
      <path d="M-82.33,94.08-112.48,60.9l23-39.53C-36,54.44-5.24,102.09-.7,166.42,3.16,108.26,29.5,62.62,76.69,29,105.54,8.49,138-1.42,174.86-2.11c-1.74,2.15-2.41,3.14-3.25,4q-82.94,82.8-166,165.49c-2.3,2.28-6.12,3-9.24,4.49l-16.9-16.39-33.14-32.23Z" />
      <path d="M66,116.83c.6-2.82.23-6.59,1.93-8.32q51.26-52.16,103-103.87c.51-.51,1.33-.71,3-1.58,0,5.36.5,10.2-.08,14.91-2.91,23.67-10,46-22.25,66.58-1.26,2.1-3.18,4.44-5.33,5.26-24.82,9.44-42.46,26.33-53.4,50.42-.2.44-.7.75-2.38,2.51-4.06-4.08-8.16-8.07-12.11-12.19-3.79-4-7.43-8-11.12-12.06C66.81,118,66.4,117.39,66,116.83Z" />
      <path d="M644.23,1329.66c6-7.11,15.52-9.4,23.28-5.19,1.37.75,2.47,3.31,2.48,5,.17,25.69.24,51.38-.08,77.06,0,1.72-3.35,4.89-5.11,4.87-26.45-.31-51.21-7.37-74.56-19.62-3.07-1.61-4.55-3.72-1.14-6.55C611.65,1370.88,629.59,1351.91,644.23,1329.66Z" />
      <path d="M9.24,646l43.57,35.54c-.41,7.19-.81,14.39-1.27,22.51H.85c-9.95,0-19.92.27-29.85-.26-2.09-.11-5.8-3.18-5.77-4.82a166.29,166.29,0,0,1,21.43-79.16Z" />
      <path d="M941.61,1142.92c-3.11-.39-7.37.4-9.15-1.34-26.59-26-52.86-52.4-79.19-78.71a10,10,0,0,1-1-2.29,34.65,34.65,0,0,1,5.76-.75c41.51,1.77,77.25,17.46,108,45,3.29,2.94,6.12,6,3,10.74Z" />
      <path d="M644.23,1329.66c-14.64,22.25-32.58,41.22-55.13,55.61-5.26,3-9.08,2.33-11.11-4a64.72,64.72,0,0,0-3.49-7.58l-15.69-21.92c1.42-2.33,2.47-5,4.33-6.92,13-13.25,26.16-26.3,39.18-39.52,1.94-2,3.33-4.49,5-6.76l-.23.28a56,56,0,0,1,5.46,2.64c11.19,6.92,22.33,13.92,34.23,21.36Z" />
      <path d="M344.64,1329.3c13.27,19.46,28.36,37.17,48.06,50.42l10.22,9.08a29.22,29.22,0,0,1-3.85,3,176.76,176.76,0,0,1-71.49,19.77c-6.45.39-8.76-1.47-8.56-8.22.74-25.41,7.6-49.1,19-71.61C339.36,1329.12,340.61,1325.11,344.64,1329.3Z" />
      <path d="M558.25,768.42q26.89,27.33,53.81,54.65c7.93,8,8,8.62-1.15,15.15-8.21,5.89-16.54,11.62-24.81,17.41l-5.61,2.16c-10.83-33.39-33.6-54.27-69.32-64.22,6.88-2.58,11.68-3.7,15.78-6C537.55,781.45,547.83,774.83,558.25,768.42Z" />
      <path d="M427.43,939.55c3.28,3.67,1.37,6.44-1.42,9.27-7.95,8.07-15.79,16.23-23.66,24.36l.26-.22c-9.31,9.06-18.54,18.21-28,27.1-2.09,2-5,3-7.6,4.47l-21.54-27.18c-4.45-9.19-4.19-9.58,5.23-13.61,28.39-12.16,46.49-33.07,53.46-63.34a8,8,0,0,1,.74-1.69l11.66,23.63Z" />
      <path d="M18.05,1363.25c.67-2.33.63-5.36,2.12-6.87,18.45-18.62,37.12-37,55.76-55.45a12.34,12.34,0,0,1,2.61-1.39c13,11.29,27.69,20.12,46.55,23.64-5.36,1.7-9.5,2.86-13.53,4.33-25.87,9.44-43.69,27.17-54.09,52.58-1.39,3.38-1.77,8.29-7.64,6.79Z" />
      <path d="M727.38,1364.85c.09-2.76-1-6.61.43-8.12,18.42-19.58,37.2-38.83,56.28-58.57,15.25,12.91,30.12,21.75,49.52,25.11-5,1.42-8.62,2.27-12.09,3.45-27.11,9.24-46,27.18-56.86,53.67-1,2.44-3.49,4.28-5.29,6.39-2.63-1.06-5.61-1.65-7.83-3.28C743.35,1377.47,735.42,1371.1,727.38,1364.85Z" />
      <path d="M260.69,231.15,208,283.93c-2.14.26-4.92,1.55-6.39.67-11.7-7.07-23.15-14.56-35.51-22.45a41.76,41.76,0,0,1,5.92-3.5q33.09-13.17,49.46-44.75c1.19-2.28,2.92-4.28,4.32-6.46,3.51-5.44,7.65-5.5,12.46-1.68,6.75,5.35,13.91,10.25,20.18,16.11C260.4,223.68,260,228,260.69,231.15Z" />
      <path d="M735.05,820q14.44-15.18,28.84-30.35c8.92-8.31,18.14-16.39,26.7-25.12,4.28-4.36,7.16-5.31,11.1,0,4.25,5.76,8.95,11.19,13.46,16.76l-2.21,2-4.89-8.6-20,21.8,6.63,4.37-5.44,7.21.76,1.33,7.93-3.82c-15.15,12.34-27.28,26.65-34.19,45-1.69,4.47-4.61,4.44-7.89,1.54Z" />
      <path d="M727.38,1364.85c8,6.25,16,12.62,24.16,18.65,2.22,1.63,5.2,2.22,7.83,3.28v24.78H674.11c1.15-2.24,1.88-3.67,2.62-5.09q10.89-11.54,21.81-23.07L721,1360.88C722.87,1362.05,725.13,1363.45,727.38,1364.85Z" />
      <path d="M18.05,1363.25l31.78,23.63c1.21,2.58,3.44,5.18,3.44,7.77,0,5.43-1.21,10.86-2,16.86H-34.29a44.22,44.22,0,0,1,3.5-4.72c13.48-13.56,26.92-27.17,40.67-40.46C11.8,1364.47,15.29,1364.23,18.05,1363.25Z" />
      <path d="M365.53,296.11c-.24,2.32.42,5.49-.85,6.82C350,318.23,335,333.25,318.75,349.74V263.33c8.75-.36,17.59-5.58,25.26,2.67Z" />
      <path d="M403.91,1084.19c-9.29,6.65-19,12.79-27.72,20.15-5.77,4.88-9.23,4.43-14.22-.78-12.8-13.37-26.11-26.26-39.17-39.3,1.37-1.42,2.67-4.13,4-4.15,24.48-.41,49-.6,73.45-.44,1.78,0,5.1,3.19,5,4.75C404.91,1071,411.08,1078.16,403.91,1084.19Z" />
      <path d="M757.16,679.93c1.16,2.8,3.23,5.58,3.27,8.39.06,4.88-1.13,9.78-1.88,15.3H674a38.13,38.13,0,0,1,3.51-4.89c13.45-13.53,26.88-27.05,40.55-40.35,1.58-1.54,4.51-1.69,6.81-2.49Z" />
      <path d="M618.31,123.23l33.14,32.23,16.9,16.39c-1.8.7-3.6,2-5.4,2-25.05,0-50.11-.14-75.16-.28a18.28,18.28,0,0,1-2.93-.49c-4.05-12.46-3.21-23.19,9.34-30.23Z" />
      <path d="M719,295.32c.16,2,1.26,4.95.32,5.93-15,15.59-30.26,30.94-45.46,46.34l-1.93-.93q0-21.35,0-42.7c0-11.54.1-23.07-.07-34.6-.06-4.09,1-6.29,5.58-6.49s9.26-1.29,14.06-2l14,18.31Z" />
      <path d="M231.59,729.46c-5.6-2.28-3.63-6.62-3-10.67,2.23-13.39,2.21-13.41,15.84-13.39q32.6,0,65.19.14a14.9,14.9,0,0,1,2.47.23l-47.83,48-9.88-7Z" />
      <path d="M267.34,757.92c.56-1.13.87-2.51,1.71-3.36,15.24-15.4,30.54-30.73,47.94-45.86,0,10.51.05,21,0,31.54-.1,14.63-.63,29.27-.27,43.89.16,6.72-1.2,10.28-8.72,10.13-3.18-.06-6.4,1.74-9.61,2.69l.21-.22-6.49-6.62Z" />
      <path d="M345.45,977.35,367,1004.53c.25,1.09.51,2.19.77,3.29l.28-.28-49.76,48.58c0-11.79,0-21.6,0-31.4,0-15.43.36-30.87,0-46.3-.16-6.25,1.62-8.19,8.05-9.11C334.91,968.1,341.73,968.24,345.45,977.35Z" />
      <path d="M753.52,324c11.83,6.38,3.1,16.73,5.63,25.7H676l-1.52-1.95c4.58-4.11,9.32-8.05,13.71-12.36C698.47,325.32,708.46,315,718.84,305c1.65-1.59,4.69-1.72,7.08-2.55Z" />
      <path d="M676.55,1056.38l46.6-46.6c10.47,7.74,21.75,15.65,32.42,24.32,2.28,1.85,2.91,6.41,3.22,9.83.35,3.83-.53,7.77-.94,12.45Z" />
      <path d="M292.18,613.88c-1.62,3.67-3.12,7.39-4.88,11s-3.67,6.85-5.53,10.27l-2.49,2.66-12.81,13.56-34.05-36.89c9.61-9.69,20.81-21,32.09-32.25.62-.62,1.94-.54,2.93-.78Z" />
      <path d="M165.35,1148.22h57.12l.77,2.71c-5.81,5.87-11.47,11.91-17.57,17.46-1,.91-4.61.44-5.93-.68a94.82,94.82,0,0,0-52.68-22.35c-1.86-.19-4.77-3.44-4.87-5.38a291.42,291.42,0,0,1,0-31.42c.13-2.22,3.54-5,6.07-6.18,6.43-3.07,13.22-5.4,19.87-8,.93-.37,2.36-1.41,2.68-1.13,4.66,4.13,10.47,7.73,13.25,12.92,1.76,3.29.25,9.82-2,13.5-5.43,8.75-12.2,16.66-18.43,24.92ZM210,1158.77c-7.94-3-9.06-2-9.73,7.44Z" />
      <path d="M735.05,820l20.78,32.18c-1.29,2.93-1.75,7.39-4,8.6-22,12-45.66,18-70.56,19.27-1.58.09-3.25-1.76-4.86-2.73Z" />
      <path d="M584,613.75l-25.37,27.14c-2.8-1.8-5.55-3.68-8.39-5.41-9.56-5.8-19.15-11.55-29.39-17.71h53.5c0-.55,0-1.1,0-1.65H519.22l-1.12-1.74q46.89-15.45,62.27-62.09l2.1.62c0,2.94-.05,5.87,0,8.8q.48,25.51,1,51A7.91,7.91,0,0,0,584,613.75Z" />
      <path d="M99.69,606.16l-18-11.93q.19-3.65.36-7.29l8.51-9.75c5.06-4.5,9.89-9.34,15.33-13.32,1.85-1.35,5.58-1.42,7.91-.59,7,2.52,13.89,5.66,20.7,8.8,1.84.85,4.65,2.51,4.69,3.86.36,12.95.21,25.91.21,39.12C124.19,616.57,112.21,610.16,99.69,606.16Z" />
      <path d="M757.16,679.93l-32.32-24c1-2.47,1.49-5.5,3.22-7.32,8.75-9.21,17.67-18.29,27-27,1.82-1.7,5.51-1.4,8.31-2.06l19.55,19.56c-.51,2.23-.54,4.74-1.63,6.65-5.63,9.89-11.36,19.73-17.47,29.32C762.41,677.23,759.41,678.34,757.16,679.93Z" />
      <path d="M267.34,757.92l24.76,32.19c-1.61,2.84-2.52,6.83-5,8.34-10.34,6.36-21.14,12-31.57,17.76-7.2-7.4-15.27-15.71-24.16-24.84Z" />
      <path d="M292.17,259.86,267.53,228l3.49-7.41,8.49-8.15,32.12-32L306.38,177c2-.5,3.58-1.39,4.22-.95,2,1.37,5.23,3.24,5.23,4.93,0,7.3.25,14.94-1.74,21.83-5.12,17.77-11.3,35.24-17.32,52.74C296.17,257.33,293.75,258.45,292.17,259.86Z" />
      <path d="M365.53,296.11,344,266c.9-2.55,1.05-6.36,2.85-7.44,10.9-6.54,22.17-12.46,33.19-18.51l23.89,23.79c-10.39,10.36-21.2,21.31-32.31,32C370.51,296.86,367.6,296.05,365.53,296.11Z" />
      <path d="M937.68,617.38q15.41-15.66,30.8-31.34c2.72-2.77,5.43-4.18,8.63-.85l20.09,24.95c3,5.29,2.16,9.06-3.62,12.17-9.37,5-18.38,10.77-27.56,16.17-1.55.91-3.21,1.64-4.82,2.45l.15.25-23.91-23.91Z" />
      <path d="M234.64,1329.34c-3-3.72-2.51-7,.89-10.32,9-8.77,17.61-17.87,26.81-26.37,1.56-1.45,5.24-.61,7.92-.85L283,1307c2.4,4.33,4.71,8.71,7.23,13s.76,6.32-3.22,8.44c-8.93,4.77-17.78,9.75-26.22,15.31-4.24,2.79-7.49,3.1-11.15-.17Z" />
      <path d="M231.59,729.46l22.83,17.29,8.94,8.55-35.2,34.76c-6.57-6.88-14.41-15.11-21.46-22.51,6-11.13,12.23-23.08,18.89-34.78C226.56,731.07,229.54,730.53,231.59,729.46Z" />
      <path d="M719,295.32l-13.48-16.15c-2.43-4.3-4.72-8.7-7.33-12.89s-1.48-6.43,2.72-8.6c11-5.72,21.89-11.82,32.9-17.8l22.79,24c-9.88,9.87-20.82,21-32,31.76C723.69,296.49,720.94,295.46,719,295.32Z" />
      <path d="M941.61,1142.92,969,1115.59c2.51,0,6.32-1.1,7.37.13,8.5,10.06,16.48,20.55,25.42,31.94-5.61,2.76-9.41,4.34-12.91,6.42-7.15,4.24-14.44,8.35-21.08,13.3-3.88,2.89-6.46,3.62-9.94-.1-5-5.33-10.64-10.1-15.24-15.74C941.1,1149.65,941.89,1145.85,941.61,1142.92Z" />
      <path d="M292.18,613.88l-24.74-32.44c.74-1.55,1.12-3.45,2.26-4.6q22.07-22.33,44.34-44.46a12.91,12.91,0,0,1,2.43-1.32c.72,21.74-4.21,41.83-11.72,61.39a180.9,180.9,0,0,1-8.26,17.76C295.68,611.76,293.65,612.68,292.18,613.88Z" />
      <path d="M228.92,681.05l-22.36-39L229,619.76l36,33.7Z" />
      <path d="M997.2,610.14l-20.09-24.95c-.13-2.37-1.39-5.76-.27-7,14.44-16.09,29.22-31.88,43.92-47.75l4,2.52c-1.11,8.63-1.18,17.55-3.55,25.82-4.67,16.29-10.51,32.25-16.11,48.26C1003.86,610.51,1001.87,614.31,997.2,610.14Z" />
      <path d="M400.72,1032c-.08,1.8.58,4.55-.35,5.27-14.57,11.28-58.25,21.89-78,18.66,2.17-2.57,3.75-4.7,5.61-6.57,11.63-11.71,23.36-23.32,34.94-35.07,2-2,3.41-4.5,5.09-6.78l-.28.28,10.45,6.29Z" />
      <path d="M997.91,964.5l-1-1.76c-6-8.21-11.81-16.51-17.94-24.61-2.16-2.85-4.51-5-1.09-8.63,14.34-15.12,28.54-30.38,44.06-44.53a149.9,149.9,0,0,1-11.19,62.76,108.59,108.59,0,0,1-7.09,14.84C1002.89,963.89,999.89,963.89,997.91,964.5Z" />
      <path d="M283,1307l-12.77-15.24c.27-2.72-.57-6.49.91-8,13.91-14.55,28.21-28.73,43.41-42.54,1.82,28.43-7.17,53.83-20.1,79.6Z" />
      <path d="M-13,266l25.6,32.89L-35,346.07C-34.68,317.63-26.44,291.33-13,266Z" />
      <path d="M400.72,1032l-22.51-17.92-8-8.5c5.14-4.58,10.24-8.62,14.75-13.23,6.11-6.25,11.76-12.93,17.61-19.42l-.26.22c3,1,6.08,2.06,9.08,3.05q9.45,9.07,18.95,18.22c-4.84,7.26-9.82,14.44-14.46,21.83a34.51,34.51,0,0,0-4.54,9.85C409.86,1032.33,406.51,1034.11,400.72,1032Zm5.77-52.66-.53,0v48.39h.53Z" />
      <path d="M38.74,1041.13c-21.63,9.52-43.88,16.12-68.38,15.15l46.06-45.67C25.68,1017.48,36,1025,46,1032.79c.72.56-.45,3.53-.71,5.36Z" />
      <path d="M935.83,791.89c-7.3-7.73-13.68-14.29-19.77-21.11-1.11-1.25-2-4.17-1.35-5.29,7-11.46,14.41-22.72,22.72-35.63,9.26,7.21,19.21,14.66,28.66,22.71,1.14,1,.52,6-1,7.69C956.05,770.59,946.49,780.5,935.83,791.89Z" />
      <path d="M-33.29,1054.88c-3.39-19.64,8.78-64.68,20.88-80.73l26.23,34.12Z" />
      <path d="M959,717.82c17.17-3.57,34.32-7.18,51.5-10.63,2.17-.44,4.5-.06,8.51-.06-6.31,6.59-11.37,11.9-16.47,17.17-7.43,7.69-15.19,15.1-22.24,23.13-4.65,5.28-8.08,6.9-13.92,1.23s-13.15-10.37-19.85-15.41c-2.78-2.09-6.11-3.84-3-8Z" />
      <path d="M782.87,639.11l-19.55-19.56c.06-2-.78-4.94.28-6.1,6.12-6.73,12.55-13.2,19.22-19.38,1.39-1.29,4.28-1,6.48-1.38l-.31-.39c6,4.85,11.44,10.56,18,14.33,7.36,4.22,15.72,6.7,23.06,9.7-7.55,3.09-16.18,6.09-24.28,10.14-5.76,2.89-10.7,7.39-16.23,10.81C787.68,638.45,785.12,638.53,782.87,639.11Z" />
      <path d="M234.64,1329.34l15,14.25c.08,3.51,1.43,7.67,0,10.45-4.75,9.48-10.42,18.5-15.86,27.62-1.25,2.09-3,3.86-5.46,6.9-6.88-15-11.86-29.78-25.1-38.92h0c6.95-6.37,14-12.59,20.77-19.19C227.56,1327,230.68,1325.65,234.64,1329.34Z" />
      <path d="M430.38,994.45q-9.48-9.13-18.95-18.22c.11-3.44-1.2-8,.51-10.2,5.6-7.19,12.31-13.52,17.41-18.93,13.75,7.14,26,13.36,38,20,.91.51,1,5.35.06,5.91C455.2,980.39,442.76,987.37,430.38,994.45Z" />
      <path d="M405.75,259.83,382.9,237.27q11.55-19.38,23-38.64L428.47,237Z" />
      <path d="M735.68,238.83c8-14,14.86-26.18,21.86-38.25.4-.68,1.89-.73,2.58-1,7.3,12.6,14.52,25.08,22.6,39-6.12,5.86-13.12,12.82-20.5,19.37-1.11,1-4.67.83-5.87-.21C748.89,251.37,741.78,244.51,735.68,238.83Z" />
      <path d="M558.66,1299.56a37.48,37.48,0,0,1,2.13-4.79c7-11.23,14.12-22.42,21.73-34.47,7.83,2.2,6.83,12.29,11.32,18.4,4.75,6.46,8.84,13.42,13.21,20.16l.24-.29L588,1317c-3.3,3.14-6.73,5.2-10.9,1.52Zm23.87,15.65H583V1267h-.45Z" />
      <path d="M961.2,640.93C954,653,946.82,665.1,939.48,677.1c-.69,1.14-2.4,1.66-3.22,2.2-7.5-12.64-14.77-24.91-22.25-37.51l23.67-24.41-.24-.11,23.91,23.91Z" />
      <path d="M558.66,1299.56l18.45,18.94c.14,2.89,1.65,7,.28,8.6-6.16,7.37-13.14,14.05-19,20.12l-39.74-23.69Zm15.69,24.61c-.42-.63-.56-1-.7-1q-24.57,0-49.15,0c0,.34,0,.68,0,1Z" />
      <path d="M932.37,1.39l-38,38-12.86-8.65C886.35,19,890.46,9.05,895.15-2.33c10.65,0,23.14,0,35.62.16C931.32-2.16,931.84.15,932.37,1.39Z" />
      <path d="M404.56,711.12c-.18,9.29-1.71,18.77-13,25.78a131.53,131.53,0,0,0-43,43.1c-6.59,10.76-15.84,10.83-26.22,11.8Z" />
      <path d="M618.31,123.23,594.2,142.85l-9.73,4.2V88.53l5.16,5.52Z" />
      <path d="M753.52,324l-27.6-21.57c.42-1.85.2-4.32,1.33-5.48,9.58-9.93,19.4-19.62,29.93-30.16.63,2.42,1.28,3.77,1.29,5.12q.1,22.71,0,45.42C758.46,320.67,759,324.61,753.52,324Z" />
      <path d="M407.46,882.14c11.53.13,23.06.46,34.59.28,4.93-.07,7,1.12,8.4,6.46,2.17,8.19,6.31,15.85,10,24.66L450,923.43l-1.88,2.25q-20.38-21.76-40.76-43.53Z" />
      <path d="M725.35,1007.81l32-31.38v55.83Z" />
      <path d="M236.13,702.05l38.35-37.57c.8.69,2.49,2.26,4.3,3.65,4.41,3.41,5.31,6.52,1.58,11.69-3.42,4.73-4.71,10.95-7.39,16.29-1.15,2.28-3.38,5.57-5.27,5.67C257.4,702.34,247,702.05,236.13,702.05Z" />
      <path d="M576.8,737.22l-18.33,27.87c-3.39-2.42-6.92-4.67-10.15-7.29s-6.44-5.79-9.64-8.71v0c7.65-7.34,15.33-14.63,22.93-22,3.14-3.06,5.44-6.17,11.07-3.32,5.19,2.62,5.74,5.74,4.59,10.4C577,735.16,577,736.2,576.8,737.22Z" />
      <path d="M14.1,1002-9.08,974.69c.42-.8.85-1.6,1.27-2.41h58c-6.69,5.36-13.56,10.52-20,16.17-3.24,2.84-5.68,6.57-8.45,9.87.05-2.24.07-4.46.09-6.68-2,.73-5,.89-5.88,2.33C14.58,996.2,14.64,999.29,14.1,1002Z" />
      <path d="M427.43,939.55l-10.89-17.21c-1.82-4.5-4.08-8.88-5.26-13.54-.57-2.23.91-5,1.46-7.5l5.09-2.71c2.13.74,4.78.91,6.28,2.32,8.36,7.81,16.44,15.93,26.79,26.07-5.81,4.8-10.77,9.38-16.28,13.13C433.07,941.17,429.87,939.8,427.43,939.55Z" />
      <path d="M313,658.23c-1.21-10.45-10.52-19.11-7.29-30.37l-3.26,1.72-5.2-10.09,14.32-3.25,0-1H298.38c6-15.87,11.67-30.79,16.92-44.65v87.29Z" />
      <path d="M90.51,577.19,82,586.94c-2.6-1-6.42-1.21-7.62-3.1a302.48,302.48,0,0,1-15.67-27.67c-.91-1.83.47-6.08,2.2-7.64,2.72-2.44,6.39-4.59,10.22-.5,6.05,6.46,12.41,12.63,18.72,18.84C93.21,570.19,93.5,573.55,90.51,577.19Z" />
      <path d="M203.29,1349.64c4.33,6.51,9.09,12.78,12.87,19.6,3.38,6.09,5.69,12.77,8.37,19-9.89,7.53-9.81,7.44-17.5.1-6.79-6.47-13.8-12.71-20.64-19l16.91-19.64Z" />
      <path d="M894.24,660.83,910.5,645c9.86,9.32,16.21,20.76,19.76,33.74.42,1.54-1.86,4.94-3.64,5.68-2.41,1-6.63,1.5-8.19.1C910.28,677.24,902.72,669.28,894.24,660.83Z" />
      <path d="M584,613.75a7.91,7.91,0,0,1-.52-1V555.24l2.31-.47c1,1.89,1.9,3.83,3,5.66,4.92,8.29,10,16.49,14.77,24.88,1.4,2.47,1.87,5.46,2.76,8.22Z" />
      <path d="M789,592.3l.31.39c4.38-5.59,8.65-11.27,13.31-16.6.42-.48,3.76.78,5,2,6.06,5.78,11.71,12,17.84,17.71,5.76,5.37,4.27,9.79-1.25,14.91-.76-1.78-1.3-3.05-1-2.29-6.27-1.52-11.57-1.85-15.91-4.07C800.88,601,795.08,596.38,789,592.3Z" />
      <path d="M785.85,287.41l-25.19,36.31V266.3l25.44,21.2Z" />
      <path d="M50.2,972.28c-4.31-.8-8.61-2.19-12.93-2.28-13.07-.27-26.15,0-39.22,0-2.45,0-4.89-.36-9-.69l38.84-23.1,22.26,26.1Z" />
      <path d="M937.67,90.08c-.59,3.51-1.65,7-1.69,10.52-.18,16-.08,32-.08,49.16l-24.06-40.91L935,88.16l-.22.22,2.67,1.9Z" />
      <path d="M739.89,760.87,716.5,780.73,702.86,786l-1.24-1.59a170.72,170.72,0,0,1,48.57-48.89l2.42,1.46Z" />
      <path d="M896.79,1372.18l17.83,19.35c-.2,2.26.31,6.14-.67,6.57-6.63,2.87-13.57,5-20,7.26-4.33-8.75-8.12-16.42-12.38-25C886.5,1378.23,887.27,1368.05,896.79,1372.18Z" />
      <path d="M786.1,287.5l-22.57-23.39c3.4-.28,6-.66,8.68-.68,14.88-.09,29.76.06,44.63-.23,3.47-.07,6.91-1.54,10.37-2.37l0,0c-1.08,1.41-1.88,3.63-3.28,4.11-14.24,4.92-27.27,11.8-38.09,22.43Z" />
      <path d="M584.47,88.5c-.82,3.72-2.29,7.43-2.35,11.17-.26,16.45-.11,32.91-.11,49.93l-23-39.72,25.42-21.34A.23.23,0,0,0,584.47,88.5Z" />
      <path d="M932.91,85.71c.61.9,1.23,1.81,1.86,2.69l.24-.24-24.92,22.2c-11.72-10.8-25.32-18.72-41.1-22.55l.84-2.12-.07-.22c7.78.41,15.57,1.2,23.36,1.15,13.31-.11,26.62-.74,39.93-1.15Z" />
      <path d="M753.74,615.12H701c-.19-.57-.39-1.14-.58-1.7,1.8-1,3.66-1.88,5.41-3,7.52-4.64,15.28-9,22.41-14.14,4.25-3.09,7-3.3,10.57.65,5.17,5.7,10.78,11,16.21,16.47Z" />
      <path d="M869.83,85.69l42.69-22.45,20.39,22.47.14-.24H869.76Z" />
      <path d="M53.68,973.5l20.84,22.79c-6.66,11.18-13.76,23.11-20.84,35Z" />
      <path d="M54.11,966.61l-24.66-20.3c7-13.14,13.06-24.85,19.49-36.34,1.38-2.45,4.17-4.92,6,.23-1.13,1.93-3.21,3.85-3.25,5.8q-.49,21.45-.17,42.92C51.51,961.49,53.18,964,54.11,966.61Z" />
      <path d="M827.21,260.83c-10.67.39-21.33.93-32,1.13-10.09.2-20.18.05-30.47.05l22.8-21.66c10.41,8.72,23.33,17.85,39.64,20.5Z" />
      <path d="M937.46,90.28l23,20.83c-7.6,12.61-14.57,24.17-22.84,37.87V90.08Z" />
      <path d="M387.5,759.17l-4.81,5.07c-7.57,5.57-15,11.3-22.77,16.61A29.28,29.28,0,0,1,349.06,785c12.35-20.48,28.55-36.93,50.07-48.84Q393.33,747.66,387.5,759.17Z" />
      <path d="M295.09,640.29c4.3-4.41,7.53-4.08,9.48,2.1.31,1,.69,2,1,2.93,4.64,13,4.59,12.91-7.53,19-3.91,2-7.5,4.57-11.89,7.29-3-5-12.12-6.3-8.1-15.27Z" />
      <path d="M555.82,107.57,522.57,87.71h52.48Z" />
      <path d="M538.68,749.09l-10.34-12.91,12.24-23.9L564,720c-9,10.4-17.18,19.73-25.3,29.07Z" />
      <path d="M762.2,974.61l19.64,20.6L762.2,1028.1Z" />
      <path d="M959,717.82l-15.47,7.38c-5.71,1.83-7.61,0-6.73-6.07C938.71,706,938.52,706,952.34,706h38.35Z" />
      <path d="M230.72,699.81c-1.79-10.27.36-18.88,10.72-25.27,8.58-5.27,16.05-12.34,24.5-19l5.57,5Z" />
      <path d="M761.93,553.63c.62,1.88,1.23,3.76,2.39,7.34l-6.05-5.61v45.36c-7.73-2.82-13.21-4.34-18.1-6.93-1.31-.69-1.95-5.28-1-7.1,5.12-9.89,10.85-19.45,16.3-29.17,1.14-2,2-4.21,3-6.32Z" />
      <path d="M938.13,167.34c-.2-3.83-.49-9.77-.79-15.7l1.59-.09.83,2.85c10.45-10.86,20.69-22.33,37.16-24.55Z" />
      <path d="M38.74,1041.13l6.51-3c2.09-.44,4.2-.9,7-1.51-.47,7.08-.86,13-1.3,19.68H4.56Z" />
      <path d="M295.09,640.29l-17,16c-5.29,5.46-7.14-.38-10.17-2.74l11.35-15.77,2.5-2.67,11.75-14.3C301.33,629.4,301.47,631.51,295.09,640.29Z" />
      <path d="M738,116.83c.41.56.82,1.12,1.23,1.66,3.39,5.82,7,11.54,10.12,17.53,2.79,5.4.93,7.93-4.93,7.42-6.25-.55-18.36-12-19-18C724.85,120.57,731.66,115.88,738,116.83Z" />
      <path d="M976.27,1366.66c-1.94,3.3-3.37,7.09-5.93,9.83-8,8.6-16.53,16.79-24.79,25.19-1.94,2-3.67,4.17-7.15,8.18h37.32l0,1.69c-10.18,0-20.37-.14-30.54.06-5.49.11-7.64-2.19-7.11-7.58,1-10,3.33-19,13.77-23.53,2.61-1.13,4.93-3,7.35-4.43-1.33,1.84-2.62,3.65-3.91,5.47l2.39,2.34Z" />
      <path d="M899.22,754.9l-5.39-5.67c8.66-8.6,16.27-17.13,25-24.23,2.28-1.84,8.2.82,12.44,1.41v2.82Z" />
      <path d="M315.81,37.6c0,13.24-.21,26.48.1,39.71.13,5.73-2,8.09-7.66,8.08-3.55,0-7.11.63-13.58,1.27,6.51-10.91,12.06-19.89,17.23-29.09a19.67,19.67,0,0,0,1.92-8c.32-4,.08-8,.08-12Z" />
      <path d="M558.37,644.33q9.11,13.71,18.22,27.39c.68,5.49-2,4.43-4.78,2.28-8.23-6.37-16.34-12.88-26-20.5Z" />
      <path d="M558.81,1351.77l15.69,21.92.75,7.33-1.91,1.26c-8.57-6.84-17.13-13.69-27.26-21.8Z" />
      <path d="M271.5,834.12c-.23-2.31-1.53-5.42-.5-6.8,7.09-9.54,14.67-18.73,22.1-28,6,5.15,6.15,9.43.42,15.81-6,6.73-12.36,13.22-18.55,19.81Z" />
      <path d="M119.51,531.91c1.22-1,1.4-1.29,1.62-1.34,17.17-3.66,19.3-2,18.57,15-.18,4.12-.62,8.22-.76,10-1.57-1.6-3.75-5-6.84-6.72C125.18,544.92,121.44,539.23,119.51,531.91Z" />
      <path d="M279.51,212.44,271,220.59c-6.13,4.66-9.93,0-12.39-4.26-.72-1.24,3-6.19,5.69-8,5.37-3.63,10.72-8.54,19.7-5.39Z" />
      <path d="M650.28,175c5.32,0,10.71-.25,16,.17,1.4.11,3.73,2.28,3.75,3.53.08,5.61-.51,11.22-.86,16.8C657.53,195.08,649.86,186.85,650.28,175Z" />
      <path d="M437.44,940.6l13.31-12.11c8.22,8.23,16.51,16,24,24.54,1.34,1.51.1,6.22-1.22,8.81-.69,1.34-4.44,2.24-6.29,1.67-5.94-1.83-11.63-4.44-17.4-6.74,5.22-1.26,10.49-2.34,15.57-4,.72-.23.54-3.25.78-5l-8.48-8.48c-3.55-1-7.14-2.9-10.63-2.71C443.81,936.79,440.65,939.18,437.44,940.6Z" />
      <path d="M846.08,1391.58a13.08,13.08,0,0,1,.93,2.6c1.86,18.1-1,20.38-19.08,15.49C828.12,1399.52,834.29,1393.18,846.08,1391.58Z" />
      <path d="M847.87,1079c-12.8-1.21-18.39-6.39-20.09-18.18,6.2,0,14.28-2.33,17.7.67S847.25,1072.7,847.87,1079Z" />
      <path d="M119.56,1060.37l20.37-1v19.87C128.4,1078.55,122.64,1073.45,119.56,1060.37Z" />
      <path d="M119.52,525.83c1.29-11.88,8.34-18.5,20.38-18.85v19.73c-4,0-7.26.11-10.54,0C126.27,526.55,123.18,526.16,119.52,525.83Z" />
      <path d="M846.65,507.47c.24,4.32.47,8.68.72,13s-1.92,6.15-6.16,6c-4.4-.12-8.8,0-13.09,0C827,515.54,835,507.5,846.65,507.47Z" />
      <path d="M495.78,902.27V882.36l19.4,1C513.63,895.58,507.38,901.67,495.78,902.27Z" />
      <path d="M474.18,526.24c.31-11.66,7.32-18.46,19.42-18.95-.4,5.87,1.35,14.13-1.71,16.73C488.13,527.22,480.36,525.7,474.18,526.24Z" />
      <path d="M120.77,1409.38c.84-10.77,7.41-17,17.71-16.84C140.08,1412.1,142.1,1413.14,120.77,1409.38Z" />
      <path d="M118.8,1232.13c3.13-5,4.66-9.1,7.54-11.63s7.15-3.5,10.81-5.16c.7,3.43,1.59,6.83,2.06,10.28.81,6.05-1.89,8.91-8.08,8.14C127.66,1233.32,124.2,1232.85,118.8,1232.13Z" />
      <path d="M514.61,176.8c-1.82,11.24-6.54,16-18.17,18.12,0-6.14-2.34-14.55.65-17.45S508.47,176.8,514.61,176.8Z" />
      <path d="M698.54,1383.4q-10.9,11.53-21.81,23.07c-1-4.8-2.28-9.57-2.7-14.41-.26-3,.88-6.1,2.34-9.31.49,2.82,1,5.63,1.59,9.06,5.64-2.15,13-.88,13.89-10.22.23-2.43,3.65-4.55,5.61-6.82l2.37.77Z" />
      <path d="M514.11,525c-18.68,3.09-20.48,1.37-17.27-16C505.63,507.4,512.83,513.89,514.11,525Z" />
      <path d="M364.76,707.2h35.11c-7.89,11.91-8.5,12.08-21.33,7-4.66-1.85-9.4-3.51-14.11-5.26Z" />
      <path d="M65.85,972H113.5L90.94,983.11c2-10.55-4.72-10.07-11.35-9.86-4.69.15-9.38,0-14.07,0Z" />
      <path d="M457.72,939.32l8.48,8.48-18.59,4.36,2.31,4.61L435.44,945C444.41,949.18,450.93,943.71,457.72,939.32Z" />
      <path d="M850.55,1412.18l14.31-14.42,5.79,12.51Z" />
      <path d="M850.11-2.52,870.7-.95c-2.15,5-3.82,8.93-6,14.1Z" />
      <path d="M834.24,542.59,848.16,532c-1.25,5.61-2.46,11.08-3.68,16.55l-10.29-3.23C834.21,544.41,834.23,543.5,834.24,542.59Z" />
      <path d="M508.21,1249.56l-6.51-6.64c-1.11-1.82-2.26-3.68-4.15-6.73l17.45,1,1.62,1.95Z" />
      <path d="M478.54,1074.16c-2.27-5.06-3.87-8.62-6.12-13.6l20.07-1.11Z" />
      <path d="M502.22,535.8c-.88-1.72-1.76-3.44-3.27-6.41l15.63.74c-1.27,11-2.58,12-10.37,8.55Z" />
      <path d="M478.24,897.07l-6.78-14,21.16-.94-14.38,15Z" />
      <path d="M511.23,718.85,497.9,705.36l18.78,1.28Z" />
      <path d="M833.72,337.44c2.41-5.71,7.26-6.52,13.35-6.74v16.59l-13.58-10Z" />
      <path d="M315.48,505c.38,8.09.66,13.85.94,19.82l-14.59-12.72Z" />
      <path d="M669.86,533.5c-.29,5-.57,10-1,16.69l-12.06-5.62L668,532.92Z" />
      <path d="M693.32,176,688,189.6,675.34,176Z" />
      <path d="M834.66,689.89l8.19,12.84H828.31l-1.83-2.17,8.42-10.3Z" />
      <path d="M684.64,192.05l-11.15,4.59c-.39-6.78-.7-11.93-.89-15.31Z" />
      <path d="M869.76,707c-2,4.71-3.35,8.1-4.81,11.65L852.39,705.8Z" />
      <path d="M864.67,691.4c1.49,3.42,2.85,6.53,4.8,11l-17.1,1.5Z" />
      <path d="M320.55,887.88l11.31,9.71L320.55,903Z" />
      <path d="M157.83,1399.2l4.73,10.53-18.83,1.93Z" />
      <path d="M833.49,337.26l11,12H827.86l-1.7-2q3.78-4.92,7.57-9.83Z" />
      <path d="M223,843.18c1-1.67,2.79-3.32,2.84-5a245.07,245.07,0,0,0-.17-26.56c-.23-2.93-3.29-5.63-5-8.43l6.34-4.59v58.15C225.24,850.94,224.11,847.06,223,843.18Z" />
      <path d="M302.77,158.05l12.07-5.39c.43,5.87.76,10.54,1.1,15.2L314.37,169Z" />
      <path d="M864.59,160.21l4.5,11.65-17.4,1.64Z" />
      <path d="M849.88,521.67c.2-4.4.4-8.8.67-14.69l11.32,4.94-9.75,10.56Z" />
      <path d="M321.41,180.18l9.5,10.52-10.59,5.47c-.37-6-.64-10.51-.91-15Z" />
      <path d="M502.22,535.8l2,2.88c3.19,6.85,2.54,7.83-6.9,9.28q-1-7.9-2-15.79l-.21.2Z" />
      <path d="M473,353.85l15.46-1,1.4,2-12.45,8.45C476.58,361.63,475.13,358.52,473,353.85Z" />
      <path d="M862.54,158.08,850,169.47V153.15Z" />
      <path d="M834.66,689.89l.24.37,10.68-7.7v17.17Z" />
      <path d="M138.4,1037.71v16.12l-10.13-12Z" />
      <path d="M666,530.32,654.68,542.6l-4.47-12.28Z" />
      <path d="M134.22,1056.13H119.83c1.47-4.22,2.64-7.59,4.36-12.55Z" />
      <path d="M318.9,168.88l2.34-13.54,8.85,2.16.6,2.35Z" />
      <path d="M478.24,897.11,492.15,887v16.23c-5.43-2.41-9.66-4.28-13.9-6.13C478.24,897.07,478.24,897.11,478.24,897.11Z" />
      <path d="M417.83,898.59l-5.09,2.71c-1.68-2-4.4-3.84-4.84-6.1-.82-4.2-.32-8.66-.4-13a.31.31,0,0,0-.13,0c2.77,3.38,5.75,6.62,8.25,10.21C416.83,894.11,417.12,896.49,417.83,898.59Z" />
      <path d="M374.08,733.17c-11.34,14-14.18,15.07-18.25,6Z" />
      <path d="M146.14,1065.19l1.31,1.51c6.87,8.36,6.3,10.62-5.51,12.8C143.53,1074.09,144.83,1069.64,146.14,1065.19Z" />
      <path d="M850.14,2.4l10.7,12-10.7,4.28Z" />
      <path d="M296.27,525.54c1.69-3.92,2.93-6.76,5.22-12l10.32,12Z" />
      <path d="M842.44,1057.12l-13.92-2.79c.76-2.84,1.51-5.68,2.72-10.23Z" />
      <path d="M314.67,860.59v13.92l-11.33-9Z" />
      <ellipse cx="795.59" cy="973.13" rx="33.34" ry="1.19" />
      <path d="M491,372.54c-3.3-2.76-6.7-4.71-6.44-5.56a15.45,15.45,0,0,1,6.44-8.23Z" />
      <path d="M923.16,720l9.85-9.14,1.81.69-3.3,11Z" />
      <path d="M324.5,750.53c-5.47,5.39-5.6,28,.33,32.46l-4.36,3.92C317.46,768.09,319.25,752.73,324.5,750.53Z" />
      <ellipse cx="669.06" cy="58.52" rx="0.42" ry="31.35" />
      <path d="M298.38,797l13.69-1.3c-3.46,4.17-5.68,6.85-8.95,10.81-2-4.33-3.27-7-4.53-9.73Z" />
      <path d="M829.87,1232.07c12.28-.68,12.28-.68,15.58-14C847,1234.15,844.78,1236.19,829.87,1232.07Z" />
      <path d="M830.5,609.89c3.09-5.88,5-5.29,12.43,5.59Z" />
      <path d="M63.47,927.58c-9.9-3.52-10.85-5.4-8.83-17.58Q59.05,918.79,63.47,927.58Z" />
      <path d="M314.45,832.79V800.53h.63v32.31Z" />
      <ellipse cx="669.06" cy="108.8" rx="0.35" ry="24.72" />
    </g>
  );
};

export default backgroundMosaic;
