import * as utils from "../Utilities";

const globalAnimSeed = [
  54, 63, 92, 33, 59, 72, 8, 76, 12, 60, 98, 42, 4, 96, 66, 49, 34, 70, 13, 23,
  39, 22, 44, 64, 19, 58, 36, 61, 57, 75, 56, 71, 74, 83, 69, 7, 88, 78, 28, 51,
  9, 87, 3, 45, 25, 84, 15, 50, 97, 10, 93, 99, 26, 37, 30, 40, 32, 46, 86, 38,
  55, 80, 21,
];

export const shapePolygon = (
  ctx: CanvasRenderingContext2D,
  numberOfPoints: number,
  rotation: number,
  xPos: number,
  yPos: number,
  width: number,
  height: number,
  size: number,
  // animation?: "hoverRotate" | "wiggle" | "hoverUpDown",
  animation?: string,
  frameCount?: number,
  intensity?: number,
  depth?: number,
  animationSeed?: Array<number>
) => {
  const r = size * 0.01 * width;
  const points = [];
  const intens = intensity ? intensity : 0.05;
  const dep = depth ? depth : 1;
  const framec = frameCount ? frameCount : 1;
  let rot = rotation ? rotation : 0;

  const animSeed = animationSeed ? animationSeed : globalAnimSeed;

  if (animation && animation === "rotateClockwise") {
    if (rot < 180) {
      rot += framec * 0.5;
    } else {
      rot = 0;
    }
  }

  if (animation && animation === "rotateCounterClockwise") {
    if (rot < 0) {
      rot -= framec * 0.5;
    } else {
      rot = 180;
    }
  }

  for (let l = 0; l < numberOfPoints; l++) {
    points.push({
      x: Math.round(
        xPos +
          r *
            Math.cos(
              l * ((Math.PI * 2) / numberOfPoints) + (rot / 180) * Math.PI
            )
      ),
      y: Math.round(
        yPos +
          r *
            Math.sin(
              l * ((Math.PI * 2) / numberOfPoints) + (rot / 180) * Math.PI
            )
      ),
    });
  }

  switch (animation) {
    default: {
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(points[l].x, points[l].y);
      ctx.closePath();
      break;
    }

    case "none": {
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(points[l].x, points[l].y);
      ctx.closePath();
      break;
    }

    case "hoverCircle": {
      ctx.beginPath();
      ctx.moveTo(
        points[0].x + Math.sin(framec * intens) * size,
        points[0].y + Math.cos(framec * intens) * size
      );
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(
          points[l].x + Math.sin(framec * intens) * size,
          points[l].y + Math.cos(framec * intens) * size
        );
      ctx.closePath();
      break;
    }

    case "wiggle": {
      ctx.beginPath();
      ctx.moveTo(
        points[0].x + Math.sin(framec * intens) * (size * dep),
        points[0].y + Math.cos(framec * intens) * (size * dep)
      );
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(
          points[l].x +
            Math.sin(framec * intens * animSeed[l] * 0.04) * (size * dep),
          points[l].y +
            Math.cos(framec * intens * animSeed[animSeed.length - l] * 0.04) *
              (size * dep)
        );
      ctx.closePath();
      break;
    }

    case "hoverUpDown": {
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y + Math.cos(framec * intens) * size);
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(points[l].x, points[l].y + Math.cos(framec * intens) * size);
      ctx.closePath();
      break;
    }
  }
};

export const shapeWave = (
  ctx: CanvasRenderingContext2D,
  numberOfPoints: number,
  xPos1: number,
  yPos1: number,
  xPos2: number,
  yPos2: number,
  width: number,
  height: number,
  size: number,
  animation?: "growShrink" | "pulseCos" | "growShrinkRandom" | "wave",
  frameCount?: number,
  intensity?: number,
  depth?: number,
  animationSeed?: Array<number>
) => {
  const r = size * 0.01 * width;
  const points = [];
  const intens = intensity ? intensity : 0.05;
  const dep = depth ? depth : 1;
  const framec = frameCount ? frameCount : 1;
  const theta = Math.atan((yPos2 - yPos1) / (xPos2 - xPos1));

  const animSeed = animationSeed ? animationSeed : globalAnimSeed;

  for (let l = 1; l < numberOfPoints; l++) {
    const distanceX = Math.round(((xPos2 - xPos1) / numberOfPoints) * l);
    const distanceY = Math.round(((yPos2 - yPos1) / numberOfPoints) * l);

    const xOffset =
      l % 2 === 0
        ? Math.cos(theta + 0.5 * Math.PI)
        : Math.cos(theta - 0.5 * Math.PI);

    const yOffset =
      l % 2 === 0
        ? Math.sin(theta + 0.5 * Math.PI)
        : Math.sin(theta - 0.5 * Math.PI);

    switch (animation) {
      default: {
        const pointx = xPos1 + distanceX + xOffset * r;
        const pointy = yPos1 + distanceY + yOffset * r;

        points.push({
          x: pointx,
          y: pointy,
        });
        break;
      }

      case "growShrink": {
        const pointx =
          xPos1 + distanceX + xOffset * r * Math.cos(framec * intens);
        const pointy =
          yPos1 + distanceY + yOffset * r * Math.cos(framec * intens);

        points.push({
          x: pointx,
          y: pointy,
        });
        break;
      }

      case "pulseCos": {
        const pointx =
          xPos1 +
          distanceX +
          xOffset * r * Math.cos(framec * intens + animSeed[l]);
        const pointy =
          yPos1 + distanceY + yOffset * r * Math.cos(framec * intens);

        points.push({
          x: pointx,
          y: pointy,
        });
        break;
      }

      case "wave": {
        const pointx =
          xPos1 +
          distanceX +
          xOffset * r * Math.cos(framec * intens + animSeed[l]);
        const pointy =
          yPos1 +
          distanceY +
          yOffset * r * Math.cos(framec * intens + animSeed[l]);

        points.push({
          x: pointx,
          y: pointy,
        });
        break;
      }

      case "growShrinkRandom": {
        const noise = 0.05 * animSeed[l];

        const pointx =
          xPos1 +
          distanceX +
          xOffset * r * Math.cos(framec * noise * intens) * 0.01 * animSeed[l];
        const pointy =
          yPos1 +
          distanceY +
          yOffset * r * Math.cos(framec * noise * intens) * 0.01 * animSeed[l];

        points.push({
          x: pointx,
          y: pointy,
        });
        break;
      }
    }
  }

  ctx.beginPath();
  ctx.moveTo(xPos1, yPos1);
  for (let l = 1; l < points.length; l++) ctx.lineTo(points[l].x, points[l].y);
  // ctx.closePath();
};

export const shapePolygonSplit = (
  ctx: CanvasRenderingContext2D,
  numberOfPoints: number,
  numberOfSplits: number,
  rotation: number,
  xPos: number,
  yPos: number,
  width: number,
  height: number,
  size: number,
  animation?: "hoverRotate" | "wiggle" | "hoverUpDown",
  frameCount?: number,
  intensity?: number,
  depth?: number,
  animationSeed?: Array<number>
) => {
  const r = size * 0.01 * width;
  const basepoints = [];
  const smallpoints = [];
  const intens = intensity ? intensity : 0.05;
  const dep = depth ? depth : 1;
  const framec = frameCount ? frameCount : 1;

  const animSeed = animationSeed ? animationSeed : globalAnimSeed;

  for (let l = 0; l < numberOfPoints; l++) {
    basepoints.push({
      x: Math.round(
        xPos +
          r *
            Math.cos(
              l * ((Math.PI * 2) / numberOfPoints) + (rotation / 180) * Math.PI
            )
      ),
      y: Math.round(
        yPos +
          r *
            Math.sin(
              l * ((Math.PI * 2) / numberOfPoints) + (rotation / 180) * Math.PI
            )
      ),
    });
  }

  for (let l = 0; l < numberOfPoints; l++) {
    for (let k = 0; k < numberOfSplits; k++) {
      smallpoints.push({});
    }
  }

  switch (animation) {
    default: {
      ctx.beginPath();
      ctx.moveTo(basepoints[0].x, basepoints[0].y);
      for (let l = 1; l < basepoints.length; l++)
        ctx.lineTo(basepoints[l].x, basepoints[l].y);
      ctx.closePath();
      break;
    }
  }
};

export const shapeBlob = (
  ctx: CanvasRenderingContext2D,
  numberOfPoints: number,
  rotation: number,
  xPos: number,
  yPos: number,
  width: number,
  height: number,
  size: number
  // animation?: "hoverRotate" | "wiggle" | "hoverUpDown",
  // frameCount?: number,
  // intensity?: number,
  // depth?: number
) => {
  const r = size * 0.01 * width;
  const points = [];
  const controlPoints = [];
  // const intens = intensity ? intensity : 0.05;
  // const dep = depth ? depth : 1;
  // const framec = frameCount ? frameCount : 1;

  for (let l = 0; l < numberOfPoints; l++) {
    controlPoints.push({
      x: Math.round(
        xPos +
          r *
            Math.cos(
              l * ((Math.PI * 2) / numberOfPoints) + (rotation / 180) * Math.PI
            )
      ),
      y: Math.round(
        yPos +
          r *
            Math.sin(
              l * ((Math.PI * 2) / numberOfPoints) + (rotation / 180) * Math.PI
            )
      ),
    });
  }

  for (let l = 0; l < numberOfPoints - 1; l++) {
    points.push({
      x: (controlPoints[l].x + controlPoints[l + 1].x) / 2,
      y: (controlPoints[l].y + controlPoints[l + 1].y) / 2,
    });
  }

  ctx.beginPath();
  ctx.moveTo(points[0].x, points[0].y);
  for (let l = 1; l < points.length; l++) {
    const cp1x = (controlPoints[l - 1].x * 3 + controlPoints[l].x) / 4;
    const cp1y = (controlPoints[l - 1].y * 3 + controlPoints[l].y) / 4;

    const cp2x = (controlPoints[l - 1].x + controlPoints[l].x * 3) / 4;
    const cp2y = (controlPoints[l - 1].y + controlPoints[l].y * 3) / 4;

    ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, points[l].x, points[l].y);
  }

  const cp1x = (controlPoints[points.length].x * 3 + controlPoints[0].x) / 4;
  const cp1y = (controlPoints[points.length].y * 3 + controlPoints[0].y) / 4;

  const cp2x = (controlPoints[points.length].x + controlPoints[0].x * 3) / 4;
  const cp2y = (controlPoints[points.length].y + controlPoints[0].y * 3) / 4;

  ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, points[0].x, points[0].y);
  ctx.closePath();
};

export const shapeStar = (
  ctx: CanvasRenderingContext2D,
  numberOfPoints: number,
  rotation: number,
  xPos: number,
  yPos: number,
  width: number,
  height: number,
  size: number,
  // animation?: "hoverRotate" | "wiggle" | "hoverUpDown",
  animation?: string,
  frameCount?: number,
  intensity?: number,
  depth?: number,
  animationSeed?: Array<number>
) => {
  const r = size * 0.01 * width;
  const points = [];
  const intens = intensity ? intensity : 0.05;
  const dep = depth ? depth : 1;
  const framec = frameCount ? frameCount : 1;
  let rot = rotation ? rotation : 0;

  const animSeed = animationSeed ? animationSeed : globalAnimSeed;

  if (animation && animation === "rotateClockwise") {
    if (rot < 180) {
      rot += framec * 0.5;
    } else {
      rot = 0;
    }
  }

  if (animation && animation === "rotateCounterClockwise") {
    if (rot < 0) {
      rot -= framec * 0.5;
    } else {
      rot = 180;
    }
  }

  for (let l = 0; l < numberOfPoints * 2; l += 2) {
    points.push({
      x: Math.round(
        xPos +
          r *
            Math.cos(
              l * ((Math.PI * 2) / (numberOfPoints * 2)) + (rot / 180) * Math.PI
            )
      ),
      y: Math.round(
        yPos +
          r *
            Math.sin(
              l * ((Math.PI * 2) / (numberOfPoints * 2)) + (rot / 180) * Math.PI
            )
      ),
    });

    points.push({
      x: Math.round(
        xPos +
          (r / 2) *
            Math.cos(
              (l + 1) * ((Math.PI * 2) / (numberOfPoints * 2)) +
                (rot / 180) * Math.PI
            )
      ),
      y: Math.round(
        yPos +
          (r / 2) *
            Math.sin(
              (l + 1) * ((Math.PI * 2) / (numberOfPoints * 2)) +
                (rot / 180) * Math.PI
            )
      ),
    });
  }

  switch (animation) {
    default: {
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(points[l].x, points[l].y);
      ctx.closePath();
      break;
    }

    case "none": {
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y);
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(points[l].x, points[l].y);
      ctx.closePath();
      break;
    }

    case "hoverCircle": {
      ctx.beginPath();
      ctx.moveTo(
        points[0].x + Math.sin(framec * intens) * size,
        points[0].y + Math.cos(framec * intens) * size
      );
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(
          points[l].x + Math.sin(framec * intens) * size,
          points[l].y + Math.cos(framec * intens) * size
        );
      ctx.closePath();
      break;
    }

    case "wiggle": {
      ctx.beginPath();
      ctx.moveTo(
        points[0].x + Math.sin(framec * intens) * (size * dep),
        points[0].y + Math.cos(framec * intens) * (size * dep)
      );
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(
          points[l].x +
            Math.sin(framec * intens * animSeed[l] * 0.04) * (size * dep),
          points[l].y +
            Math.cos(framec * intens * animSeed[animSeed.length - l] * 0.04) *
              (size * dep)
        );
      ctx.closePath();
      break;
    }

    case "hoverUpDown": {
      ctx.beginPath();
      ctx.moveTo(points[0].x, points[0].y + Math.cos(framec * intens) * size);
      for (let l = 1; l < points.length; l++)
        ctx.lineTo(points[l].x, points[l].y + Math.cos(framec * intens) * size);
      ctx.closePath();
      break;
    }
  }
};
