import { useStore } from "./ArtworkPropertyStore";
import LayerCanvas from "./LayerCanvas";
import LayerSvg from "./LayerSvg";
import LayerSvgIcon from "./LayerSvgIcon";
import LayerWordBlob from "./LayerWordBlob";
import { svgIconsAbstract } from "./Resources/SvgIcons";
import { FocusGridPointType, FocusGridType } from "./Resources/Types";
import * as utils from "./Utilities";

const GroupSvgIconLine = () => {
  const props = utils.getProps();

  const pos1X = props.position1;
  const pos1Y = props.position2;

  const icons = [];

  const selectSvgIcon = (iconCode: number) => {
    const numberOfIcons = svgIconsAbstract.length;

    for (let i = 0; i < numberOfIcons; i++) {
      if (
        iconCode > Math.floor(100 / numberOfIcons) * i &&
        iconCode <= Math.floor(100 / numberOfIcons) * (i + 1)
      ) {
        return svgIconsAbstract[i];
      }
    }

    return svgIconsAbstract[0];
  };

  let numberofIcons = Math.round((pos1X + pos1Y) / 40);
  numberofIcons = utils.clamp(numberofIcons, 4, 7);

  let filteredFocusPoints: FocusGridType = [];

  if (props.isFocusGridDrawn) {
    filteredFocusPoints = utils.filterFocusGridValue(
      props.focusGrid,
      "value",
      64,
      "lessThan"
    );

    const size =
      props.width / 60 +
      (parseInt(
        utils.generateValue(2, pos1X * 2 * 10, " group svg icons line")
      ) *
        props.width) /
        2000;

    filteredFocusPoints = utils.filterFocusGridIsUsed(
      filteredFocusPoints,
      false
    );

    const point1 = utils.pickGridPointInFilteredGrid(
      filteredFocusPoints,
      parseInt(utils.generateValue(2, pos1X * 2 * 20, " group svg icons"))
    );
    utils.markPointsAroundAsUsed(props.focusGrid, point1.keyX, point1.keyY, 1);

    let point2: FocusGridPointType;
    if (props.isFocusGridDrawn) {
      if (point1.keyX + 4 < props.totalXPoints) {
        point2 = props.focusGrid[point1.keyX + 4][point1.keyY];
      } else {
        if (point1.keyX - 4 < props.totalXPoints && point1.keyX > 0) {
          point2 = props.focusGrid[point1.keyX - 4][point1.keyY];
        } else {
          if (point1.keyY + 4 < props.totalYPoints) {
            point2 = props.focusGrid[point1.keyX][point1.keyY + 4];
          } else {
            if (point1.keyY - 4 < props.totalYPoints && point1.keyY > 0) {
              point2 = props.focusGrid[point1.keyX][point1.keyY - 4];
            } else {
              point2 = point1;
              numberofIcons = 1;
            }
          }
        }
      }

      for (let i = 1; i < numberofIcons + 1; i++) {
        let xpos =
          (point1.x * i + point2.x * (numberofIcons - i)) / numberofIcons;
        let ypos =
          (point1.y * i + point2.y * (numberofIcons - i)) / numberofIcons;

        icons.push({
          key: i,
          x: xpos,
          y: ypos,
          color1: utils.selectPalette(props.palette)["drawingLineart"],
          color2: utils.selectPalette(props.palette)["drawingLineart"],

          size: size,
          svgIcon: selectSvgIcon(
            parseInt(utils.generateValue(2, pos1Y, " group svg icons")) * 2
          ),
        });
      }
    }
  }
  const iconItems = icons.map((icon) => (
    <LayerSvgIcon
      key={icon.key}
      id={"accent"}
      color1={icon.color1 ? icon.color1 : "red"}
      color2={icon.color2 ? icon.color2 : "cream"}
      size={icon.size}
      x={icon.x}
      y={icon.y}
      svgIcon={icon.svgIcon}
    />
  ));

  return <div className="svgLayer">{iconItems}</div>;
};

export default GroupSvgIconLine;
