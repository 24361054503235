import drawingWoman1Fill from "./woman1/drawing-woman1-fill";
import drawingWoman1Fill2 from "./woman1/drawing-woman1-fill2";
import drawingWoman1Lineart from "./woman1/drawing-woman1-lineart";

import drawingMan1Fill from "./man1/drawing-man1-fill";
import drawingMan1Fill2 from "./man1/drawing-man1-fill2";
import drawingMan1Lineart from "./man1/drawing-man1-lineart";

import drawingWoman2Fill from "./woman2/drawing-woman2-fill";
import drawingWoman2Fill2 from "./woman2/drawing-woman2-fill2";
import drawingWoman2Lineart from "./woman2/drawing-woman2-lineart";

import drawingMan2Lineart from "./man2/drawing-man2-lineart";
import drawingMan2Fill from "./man2/drawing-man2-fill";
import drawingMan2Fill2 from "./man2/drawing-man2-fill2";

import drawingWoman3Fill from "./woman3/drawing-woman3-fill";
import drawingWoman3Fill2 from "./woman3/drawing-woman3-fill2";
import drawingWoman3Lineart from "./woman3/drawing-woman3-lineart";

import drawingMan3Fill from "./man3/drawing-man3-fill";
import drawingMan3Fill2 from "./man3/drawing-man3-fill2";
import drawingMan3Lineart from "./man3/drawing-man3-lineart";

import drawingMan4Fill from "./man4/drawing-man4-fill";
import drawingMan4Fill2 from "./man4/drawing-man4-fill2";
import drawingMan4Lineart from "./man4/drawing-man4-lineart";

import drawingWoman4Fill from "./woman4/drawing-woman4-fill";
import drawingWoman4Fill2 from "./woman4/drawing-woman4-fill2";
import drawingWoman4Lineart from "./woman4/drawing-woman4-lineart";

import drawingWoman5Fill from "./woman5/drawing-woman5-fill";
import drawingWoman5Fill2 from "./woman5/drawing-woman5-fill2";
import drawingWoman5Lineart from "./woman5/drawing-woman5-lineart";

import drawingMan5Fill from "./man5/drawing-man5-fill";
import drawingMan5Fill2 from "./man5/drawing-man5-fill2";
import drawingMan5Lineart from "./man5/drawing-man5-lineart";

import drawingWoman6Fill from "./woman6/drawing-woman6-fill";
import drawingWoman7Fill from "./woman7/drawing-woman7-fill";
import drawingWoman8Fill from "./woman8/drawing-woman8-fill";

import drawingWoman6Fill2 from "./woman6/drawing-woman6-fill2";
import drawingWoman7Fill2 from "./woman7/drawing-woman7-fill2";
import drawingWoman8Fill2 from "./woman8/drawing-woman8-fill2";

import drawingWoman6Lineart from "./woman6/drawing-woman6-lineart";
import drawingWoman7Lineart from "./woman7/drawing-woman7-lineart";
import drawingWoman8Lineart from "./woman8/drawing-woman8-lineart";

import drawingMan6Fill from "./man6/drawing-man6-fill";
import drawingMan7Fill from "./man7/drawing-man7-fill";
import drawingMan8Fill from "./man8/drawing-man8-fill";

import drawingWoman9Fill from "./woman9/drawing-woman9-fill";
import drawingWoman10Fill from "./woman10/drawing-woman10-fill";
import drawingWoman11Fill from "./woman11/drawing-woman11-fill";
import drawingWoman12Fill from "./woman12/drawing-woman12-fill";

import drawingMan7Fill2 from "./man7/drawing-man7-fill2";
import drawingMan6Fill2 from "./man6/drawing-man6-fill2";
import drawingMan8Fill2 from "./man8/drawing-man8-fill2";
import drawingWoman9Fill2 from "./woman9/drawing-woman9-fill2";

import drawingWoman10Fill2 from "./woman10/drawing-woman10-fill2";
import drawingWoman11Fill2 from "./woman11/drawing-woman11-fill2";
import drawingWoman12Fill2 from "./woman12/drawing-woman12-fill2";

import drawingMan6Lineart from "./man6/drawing-man6-lineart";
import drawingMan7Lineart from "./man7/drawing-man7-lineart";
import drawingMan8Lineart from "./man8/drawing-man8-lineart";

import drawingWoman9Lineart from "./woman9/drawing-woman9-lineart";
import drawingWoman10Lineart from "./woman10/drawing-woman10-lineart";
import drawingWoman11Lineart from "./woman11/drawing-woman11-lineart";
import drawingWoman12Lineart from "./woman12/drawing-woman12-lineart";
import drawingMan10Fill from "./man10/drawing-man10-fill";
import drawingMan9Fill from "./man9/drawing-man9-fill";
import drawingWoman13Fill from "./woman13/drawing-woman13-fill";
import drawingWoman14Fill from "./woman14/drawing-woman14-fill";
import drawingWoman13Fill2 from "./woman13/drawing-woman13-fill2";
import drawingWoman14Fill2 from "./woman14/drawing-woman14-fill2";
import drawingWoman13Lineart from "./woman13/drawing-woman13-lineart";
import drawingWoman14Lineart from "./woman14/drawing-woman14-lineart";
import drawingMan9Lineart from "./man9/drawing-man9-lineart";
import drawingMan10Lineart from "./man10/drawing-man10-lineart";
import drawingMan9Fill2 from "./man9/drawing-man9-fill2";
import drawingMan10Fill2 from "./man10/drawing-man10-fill2";

const drawingFillArray = [
  drawingWoman1Fill,
  drawingMan1Fill,

  drawingWoman2Fill,
  drawingMan2Fill,

  drawingWoman3Fill,
  drawingMan3Fill,

  drawingWoman4Fill,
  drawingMan4Fill,

  drawingWoman5Fill,
  drawingMan5Fill,

  drawingWoman6Fill,
  drawingMan6Fill,

  drawingWoman7Fill,
  drawingMan7Fill,

  drawingWoman8Fill,
  drawingMan8Fill,

  drawingWoman9Fill,
  drawingMan9Fill,

  drawingWoman10Fill,
  drawingMan10Fill,

  drawingWoman11Fill,

  drawingWoman12Fill,

  drawingWoman13Fill,

  drawingWoman14Fill,
];

const drawingFill2Array = [
  drawingWoman1Fill2,
  drawingMan1Fill2,

  drawingWoman2Fill2,
  drawingMan2Fill2,

  drawingWoman3Fill2,
  drawingMan3Fill2,

  drawingWoman4Fill2,
  drawingMan4Fill2,

  drawingWoman5Fill2,
  drawingMan5Fill2,

  drawingWoman6Fill2,
  drawingMan6Fill2,

  drawingWoman7Fill2,
  drawingMan7Fill2,

  drawingWoman8Fill2,
  drawingMan8Fill2,

  drawingWoman9Fill2,
  drawingMan9Fill2,

  drawingWoman10Fill2,
  drawingMan10Fill2,

  drawingWoman11Fill2,

  drawingWoman12Fill2,

  drawingWoman13Fill2,

  drawingWoman14Fill2,
];

const drawingLineartArray = [
  drawingWoman1Lineart,
  drawingMan1Lineart,

  drawingWoman2Lineart,
  drawingMan2Lineart,

  drawingWoman3Lineart,
  drawingMan3Lineart,

  drawingWoman4Lineart,
  drawingMan4Lineart,

  drawingWoman5Lineart,
  drawingMan5Lineart,

  drawingWoman6Lineart,
  drawingMan6Lineart,

  drawingWoman7Lineart,
  drawingMan7Lineart,

  drawingWoman8Lineart,
  drawingMan8Lineart,

  drawingWoman9Lineart,
  drawingMan9Lineart,

  drawingWoman10Lineart,
  drawingMan10Lineart,

  drawingWoman11Lineart,
  drawingWoman12Lineart,
  drawingWoman13Lineart,
  drawingWoman14Lineart,
];

export { drawingFillArray, drawingFill2Array, drawingLineartArray };
