import { SvgIconTypeMap } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { useEffect, useRef, useState } from "react";
import { JsxElement } from "typescript";
import "../Styles/Artwork.css";
import { useStore } from "./ArtworkPropertyStore";
import * as utils from "./Utilities";

interface SvgIconProps {
  id?: string | number;
  element?: string;
  size?: number;
  color1: string;
  color2: string;
  x: number;
  y: number;
  svgIcon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
}

const LayerSvg = (directProps: SvgIconProps) => {
  const props = utils.getProps();
  const color = utils.colorToHex(directProps.color1);
  const [context, setContext] = useState<CanvasRenderingContext2D>();
  const canvasRef = useRef(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  let svgElement: SVGElement | null;
  svgElement = document.querySelector(`#svgicon-${directProps.id}`);

  let imageForCanvas = new Image();
  let svgString = "";
  const serializer = new XMLSerializer();

  const iconStyle = {
    // height: directProps.size ? directProps.size : props.width / 100,
    // width: directProps.size ? directProps.size : props.width / 100,
    borderRadius: `${props.width / 100}px`,
    color: color,
    fill: color,

    zIndex: 999,
  } as React.CSSProperties;

  const draw = (ctx: CanvasRenderingContext2D, frameCount: number) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.globalCompositeOperation = "source-over";

    if (isImageLoaded) {
      ctx.drawImage(
        imageForCanvas,
        directProps.x,
        directProps.y,
        props.width / 18,
        props.width / 18
      );
    }
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    // @ts-ignore: Object is possibly 'null'.
    const thisContext = canvas.getContext("2d");
    setContext(thisContext);

    let animationFrameId = 0;
    const framectElement = document.getElementById("framect");

    //Drawing happens here
    const render = () => {
      if (framectElement) {
        draw(thisContext, parseFloat(framectElement?.innerText));
      }

      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [draw]);

  useEffect(() => {
    if (svgElement !== null) {
      svgString = serializer.serializeToString(svgElement);
    }

    // make it base64
    var svg64 = btoa(svgString);
    var b64Start = "data:image/svg+xml;base64,";
    var image64 = b64Start + svg64;

    imageForCanvas.onload = function () {
      setIsImageLoaded(true);
    };

    imageForCanvas.src = image64;
    // console.log("image for canvas", svgElement?.id);
    // }
  }, [imageForCanvas]);

  return (
    // <div className="svgIconLayer">
    //   <directProps.svgIcon style={iconStyle} id={`svgicon-${directProps.id}`} />
    // </div>

    <div className={"canvas"}>
      <div className="svgLayer">
        <directProps.svgIcon
          style={iconStyle}
          id={`svgicon-${directProps.id}`}
        />
      </div>

      <canvas
        id={`canvas-${directProps.id}`}
        ref={canvasRef}
        width={props.width}
        height={props.height}
      />
    </div>
  );
};

export default LayerSvg;
